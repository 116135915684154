import { COLOR } from '@rocket-mono/libs'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  wrap: {
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderColor: COLOR.gray.g150,
  },

  unformatted: {
    backgroundColor: COLOR.gray.g300,
    borderWidth: 0,
  },

  error: {
    backgroundColor: COLOR.dimmed.a60,
    borderWidth: 0,
  },
})
