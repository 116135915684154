import React, { useMemo } from 'react'

import { View } from '@rocket-mono/foundations'
import {
  WorkFile,
  useAstro,
  useAuth,
  useCurrentUser,
  usePreview,
  useWorkAssignee,
  useWorkCard,
  useWorkChannel,
  useWorkFile,
  useWorkProject,
} from '@rocket-mono/providers'
import type { CardCollectionViewPermission } from '@rocket/types'
import { useTranslation } from 'react-i18next'
import { CardRowGroup } from '../../components/CardRowGroup'
import FileViewer from '../../components/FileViewer'
import { ImagePreviewList } from '../../components/ImagePreviewList'
import { WorkCardEditor } from '../../components/WorkCardEditor'
import { WorkCardLayout } from '../../components/WorkCardLayout'
import CardGathering from './components/CardGathering'
import { CardLinkParams } from './components/CardGathering/item'
import { FileItemType } from './components/CardGatheringItem'
import type { Preview, ViewProps } from './types'

const ScreenView: React.FC<ViewProps> = ({ ...layoutProps }) => {
  const { t } = useTranslation()
  const { isAnonymous, showLoginModal } = useAuth()
  const { astro, option: astroOption } = useAstro()

  const { currentProject, projectMemberList } = useWorkProject()
  const { currentUser } = useCurrentUser()
  const { currentChannel, currentChannelMembers } = useWorkChannel()
  const { currentCard } = useWorkCard()
  const { openPreview } = usePreview()

  const { assigneeList } = useWorkAssignee()

  const { fileList, fileDownload } = useWorkFile()

  const assigneeIds = React.useMemo(
    () =>
      assigneeList.map(({ id, userId, userEmail }) => ({
        id,
        userId: String(userId ?? ''),
        userEmail: userEmail ?? '',
      })),
    [assigneeList],
  )

  const dateProps = useMemo(() => {
    let isTime = false
    let cardDate = {}
    if (currentCard && (currentCard.fromPeriodDate || currentCard.toPeriodDate)) {
      isTime = true
      const { fromPeriodDate, toPeriodDate, isFromPeriodDateTime, isToPeriodDateTime } = currentCard
      const startDate = fromPeriodDate ? new Date(fromPeriodDate) : undefined
      const endDate = toPeriodDate ? new Date(toPeriodDate) : undefined
      const isStartTime = isFromPeriodDateTime !== null ? isFromPeriodDateTime : false
      const isEndTime = isToPeriodDateTime !== null ? isToPeriodDateTime : false
      cardDate = { startDate, endDate, isStartTime, isEndTime }
    }
    return { isTime, cardDate }
  }, [currentCard])

  const currentChannelMember = useMemo(
    () => currentChannelMembers.find(({ userId }) => userId === String(currentUser.id)),
    [currentUser, currentChannelMembers],
  )

  const isViewPermission = useMemo(() => {
    if (currentCard && currentCard.user && String(currentCard.user.no) === String(currentUser.id)) return true
    if (currentChannelMember && currentChannelMember.isManager) return true

    return false
  }, [currentUser, currentCard])

  const isProjectOwner = React.useMemo(() => {
    if (projectMemberList === null) return false
    return projectMemberList.some(
      (member) => member.userId === String(currentUser.id) && (member.auth === 'OWNER' || member.auth === 'MANAGER'),
    )
  }, [projectMemberList, currentUser])

  const isChannelOwner = React.useMemo(() => {
    return currentChannelMembers.some(
      (member) => member.userId === String(currentUser.id) && (member.isOwner || member.isManager),
    )
  }, [currentChannelMembers, currentUser])

  const getFileAccess = React.useCallback(
    (viewPermission: CardCollectionViewPermission, userId: string) => {
      if (viewPermission === 'EVERYONE') return true
      else if (viewPermission === 'WRITER_OR_AUTHORITY') {
        if (isProjectOwner) return true
        else if (isChannelOwner) return true
        else if (userId === String(currentUser.id)) return true
        else return false
      } else return false
    },
    [isProjectOwner, isChannelOwner, currentUser],
  )

  const onPressItemPreview = React.useCallback(
    (file: WorkFile) => {
      if (!currentProject || !currentChannel || !currentCard) return
      if (!file.mimeType?.includes('image') && !file.mimeType?.includes('pdf')) return
      const previewList: Preview[] = []
      if (!currentCard?.gatherings) return
      currentCard.gatherings
        .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
        .map(({ type, pieces, viewPermission }) => {
          if (type === 'FILE') {
            if (pieces) {
              pieces.map((piece) => {
                piece.list?.map((piece) => {
                  if (
                    piece.file &&
                    getFileAccess(viewPermission, String(piece.file.user?.id)) &&
                    (piece.file.mimeType?.includes('image') || piece.file.mimeType?.includes('pdf'))
                  ) {
                    const id = piece.file.id
                    const title = piece.file.originalName || ''
                    const uri = `${piece.file.presignedUrl}`
                    const regDate: string = piece.file.createdAt as unknown as string
                    const uploadDate = t('format.date.LLL', { date: new Date(regDate) })
                    const uploaderName = piece.userName ?? ''
                    const uploaderProfile = `${astroOption.secureCdnUrl}/profile/${piece.userEmail}`
                    const mimeType = piece.file.mimeType || ''
                    const workType = currentProject.type.code
                    const workName = currentProject.title
                    const boardName = currentChannel.roomName
                    const cardName = currentCard.title

                    previewList.push({
                      id,
                      title,
                      uri,
                      uploadDate,
                      uploaderName,
                      uploaderProfile,
                      mimeType,
                      workType,
                      workName,
                      boardName,
                      cardName,
                    })
                  }
                })
              })
            }
          }
        })

      const filePreviewIndex = previewList.findIndex((preview) => preview.id === file.id)
      openPreview(
        'card',
        file.relatedDomain || '',
        file.name || '',
        previewList,
        currentProject.type.code,
        currentProject.title,
        filePreviewIndex,
      )
    },
    [currentProject, currentChannel, currentCard, getFileAccess],
  )

  const EXT_MAP = {
    doc: ['txt', 'doc', 'hwp'],
    ppt: ['ppt', 'pptx'],
    xls: ['xls', 'xlsx'],
    zip: ['zip', 'rar', 'alz', '7z'],
    pdf: ['pdf'],
  }

  const getFileType = React.useCallback(
    (originalFilename: string, mimeType?: string, extension?: string) => {
      if (!mimeType) return originalFilename.split('.').pop() ?? ''
      if (!extension) return originalFilename.split('.').pop() ?? ''
      if (mimeType.startsWith('image')) {
        return 'preview'
      } else if (EXT_MAP.doc.includes(extension)) {
        return 'doc'
      } else if (EXT_MAP.ppt.includes(extension)) {
        return 'ppt'
      } else if (EXT_MAP.xls.includes(extension)) {
        return 'xls'
      } else if (mimeType.startsWith('video')) {
        return 'video'
      } else if (EXT_MAP.zip.includes(extension)) {
        return 'zip'
      } else if (EXT_MAP.pdf.includes(extension)) {
        return 'pdf'
      } else {
        return originalFilename.split('.').pop() ?? ''
      }
    },
    [EXT_MAP],
  )

  const gatherings = useMemo(() => {
    if (!currentCard?.gatherings) return []

    return currentCard.gatherings
      .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
      .map(({ id, type, title, pieces, viewPermission, targetOption, assigneeList }) => {
        let items
        let disabled = false
        if (targetOption === 'SPECIFY') {
          if (assigneeList && pieces) {
            if (!assigneeList.map(({ userId }) => String(userId)).includes(String(currentUser.id))) {
              disabled = true
            }
            items = assigneeList.map((assignee) => {
              const { userId, userEmail, userName } = assignee
              const member = currentChannelMembers.find(({ userId }) => String(userId) === String(assignee.userId))
              const profileImage = `${astroOption.secureCdnUrl}/profile/${member?.email ?? userEmail}`

              const piece = pieces.find(({ userId }) => String(userId) === String(assignee.userId))

              let id, groupId
              let content = ''
              let isDone = false

              if (piece) {
                if (piece.list && piece.list.length > 0) {
                  content = piece.list[0]?.content ?? ''
                  isDone = piece.isDone
                }
                groupId = piece?.id
              }

              const file: FileItemType[] = []

              const isWriter = String(assignee.userId) === String(currentUser.id)
              let permission = isWriter
              if (permission === false) permission = viewPermission === 'WRITER_OR_AUTHORITY' ? isViewPermission : true

              if (type === 'FILE') {
                piece?.list?.map((piece) => {
                  const pieceFile = piece.file
                  if (pieceFile) {
                    const regDate: string = pieceFile.createdAt as unknown as string
                    const access: boolean = permission
                    const date = new Date(regDate)

                    const uploader = member?.name ?? userName ?? ''
                    const fileUrl = `${pieceFile.presignedUrl}`
                    const fileName = pieceFile.originalName || ''
                    const ext = fileName.split('.').pop() ?? ''
                    const fileSize = Number(pieceFile.byteSize)
                    const fileType = getFileType(fileName, pieceFile.mimeType, ext)
                    const fileSource = 'GATHERING'
                    const onPressItem = () => {
                      onPressItemPreview(pieceFile)
                    }
                    const onPressDownload = () => {
                      fileDownload(pieceFile)
                    }

                    file.push({
                      id: piece.id,
                      small: true,
                      access,
                      fileUrl,
                      fileType,
                      fileName,
                      fileSize,
                      fileSource,
                      date,
                      uploader,
                      onPressItem,
                      onPressDownload,
                    })
                  }
                })
              }

              return {
                id,
                groupId,
                userId,
                type,
                user: member?.name ?? userName ?? '',
                profileImage,
                complete: isDone,
                isWriter,
                content,
                file,
                permission,
              }
            })
          } else {
            // return []
          }
        } else {
          items = pieces?.map(({ id: groupId, type, list, isDone }) => {
            if (list && list.length > 0) {
              const piece = list[0]
              const { content, userId, userName, userEmail } = piece
              const member = currentChannelMembers.find((o) => o.userId === String(userId))
              const profileImage = `${astroOption.secureCdnUrl}/profile/${member?.email ?? userEmail}`

              const isWriter = String(userId) === String(currentUser.id)
              let permission = isWriter
              if (permission === false) permission = viewPermission === 'WRITER_OR_AUTHORITY' ? isViewPermission : true

              const file: FileItemType[] = []

              if (type === 'FILE') {
                list.map((piece) => {
                  const pieceFile = piece.file
                  if (pieceFile) {
                    const regDate: string = pieceFile.createdAt as unknown as string
                    const access: boolean = permission
                    const date = new Date(regDate)

                    const uploader = member?.name ?? userName ?? ''
                    const fileUrl = `${pieceFile.presignedUrl}`
                    const fileName = pieceFile.originalName || ''
                    const ext = fileName.split('.').pop() ?? ''
                    const fileSize = Number(pieceFile.byteSize)
                    const fileType = getFileType(fileName, pieceFile.mimeType, ext)
                    const fileSource = 'GATHERING'
                    const onPressItem = () => {
                      onPressItemPreview(pieceFile)
                    }
                    const onPressDownload = () => {
                      fileDownload(pieceFile)
                    }

                    file.push({
                      id: piece.id,
                      small: true,
                      access,
                      fileUrl,
                      fileType,
                      fileName,
                      fileSize,
                      fileSource,
                      date,
                      uploader,
                      onPressItem,
                      onPressDownload,
                    })
                  }
                })
              }

              return {
                id: piece.id,
                groupId,
                userId,
                type,
                user: member?.name ?? userName ?? '',
                profileImage,
                complete: isDone,
                isWriter,
                content,
                file,
                permission,
              }
            }
            // return
          })
        }

        return {
          id,
          type,
          targetOption,
          disabled,
          title,
          items: items.filter((o) => o !== undefined),
        }
      })
  }, [currentCard, currentUser, currentChannelMembers, isViewPermission, onPressItemPreview, getFileType])

  const onPressCardLink = React.useCallback(({ cardNo, channelNo }: CardLinkParams) => {
    // console.log('onCardLinkPress', { cardNo, cardName, channelNo })
    // astro.readCard(cardNo, '').then((card) => console.log('card', { card }))
    window.open(`/card/${cardNo}?channelId=${channelNo}`, '_blank')
  }, [])
  // const onMentionPress = React.useCallback((memberNo: string, memberName: string) => {
  //   console.log('onMentionPress', { memberNo, memberName })
  // }, [])

  const [content, setContent] = React.useState<string | null>()
  React.useEffect(() => {
    setContent(undefined)
    if (currentCard !== null && currentCard.content) {
      setTimeout(() => setContent(currentCard.content), 200)
    } else {
      setContent(null)
    }
  }, [currentCard])

  if (currentCard === null) return null
  return (
    <WorkCardLayout
      edit={false}
      cardType="COLLECTION"
      title={currentCard?.title || ''}
      optionList={[]}
      assigneeIds={assigneeIds}
      {...dateProps}
      {...layoutProps}
    >
      {() => (
        <View style={{ flex: 1 }}>
          {content !== undefined && content !== null && <WorkCardEditor readonly content={content} />}

          {currentCard.contentFiles && currentCard.contentFiles.length > 0 && (
            <div style={{ marginRight: 10, marginLeft: 10 }}>
              <ImagePreviewList
                list={currentCard.contentFiles.map((file: any) => {
                  return {
                    id: file.no,
                    uri: `${astroOption.secureCdnUrl}/images/${file.fileKey}/${file.filename}`,
                    // onPress: () => previewFile(file),
                  }
                })}
              />
            </div>
          )}

          {gatherings && gatherings.length > 0 && (
            <CardRowGroup
              title={t('card.collect.id')}
              iconName="file-check-o"
              iconContainerStyle={{
                alignItems: 'stretch',
              }}
            >
              <View style={{ width: '100%' }}>
                <CardGathering
                  isAnonymous={isAnonymous}
                  onPressAnonymous={showLoginModal}
                  list={gatherings}
                  onPressDownload={() => {}}
                  onPressCardLink={onPressCardLink}
                />
              </View>
            </CardRowGroup>
          )}

          {/* {currentCard && currentChannel && (
          <WorkCardGatheringScreen
            appApiLegacyUrl={astroOption.appApiLegacyUrl || ''}
            secureCdnUrl={astroOption.secureCdnUrl || ''}
            onPressDownload={(file) => {
              const fileType = {
                ...file,
                no: Number(file.id),
                fileSize: 0,
                regDate: file.regDateStr,
                user: {
                  no: file.user.id,
                  userEmail: '',
                  userName: file.user.username,
                },
              }
              // api.fileDownload(fileType)
            }}
            cardId={String(currentCard.no)}
            astro={astro}
            channelId={currentChannel.id}
            onPressClose={() => {}}
          />
        )} */}
          <FileViewer files={fileList} fileSource="GATHERING" />
        </View>
      )}
    </WorkCardLayout>
  )
}

export default ScreenView
