import React from 'react'
import { Animated, StyleSheet, View, useWindowDimensions } from 'react-native'

import { Tooltip } from '@rocket-atoms/tooltip'
import { COLOR, getBoxShadow } from '@rocket-mono/libs'
import { useTranslation } from 'react-i18next'
import { Resizable } from './components/Resizable'
import { WorkCardLayoutProps } from './types'
import { WorkCardView } from './view'

export const WorkCardLayout: React.FC<WorkCardLayoutProps> = ({
  isFold: defaultFold,
  currentProject,
  opened,
  modal,
  maxWidth = 1000,
  onClose,
  ...props
}) => {
  const { t } = useTranslation()
  const [isAnimated, setIsAnimated] = React.useState(false)
  const { width: deviceWidth } = useWindowDimensions()
  const contentsWidth = React.useMemo(() => (deviceWidth < 800 ? deviceWidth - 40 : 800), [deviceWidth])
  const animatedWidth = React.useRef(new Animated.Value(opened ? contentsWidth : 0)).current
  React.useEffect(() => {
    const duration = 400
    Animated.timing(animatedWidth, {
      toValue: contentsWidth,
      duration,
      useNativeDriver: true,
    }).start()
    setTimeout(() => {
      setIsAnimated(true)
    }, duration)
  }, [contentsWidth])

  const handlePressClose = React.useCallback(() => {
    const proc = () => {
      const duration = props.edit ? 0 : 400
      Animated.timing(animatedWidth, {
        toValue: 0,
        duration,
        useNativeDriver: true,
      }).start(() => {
        props.onPressClose?.()
      })
    }

    if (onClose)
      onClose().then((bool) => {
        if (bool) proc()
      })
    else proc()

    // }
  }, [onClose, props.edit, props.onPressClose])

  const [isFold, setIsFold] = React.useState(false)

  const handlePressHold = React.useCallback(() => {
    const duration = 400
    Animated.timing(animatedWidth, {
      toValue: 0,
      duration,
      useNativeDriver: true,
    }).start()
    setTimeout(() => {
      props.onPressHold && props.onPressHold()
      setIsFold(true)
    }, duration)
  }, [props.onPressHold])

  const handlePressOpen = React.useCallback(() => {
    const duration = 400
    setIsFold(false)
    Animated.timing(animatedWidth, {
      toValue: contentsWidth,
      duration,
      useNativeDriver: true,
    }).start()
    setTimeout(() => {
      props.onPressOpen && props.onPressOpen()
    }, duration)
  }, [contentsWidth])

  // if (currentProject === null) return null

  React.useEffect(() => {
    if (defaultFold === true) {
      handlePressHold()
    } else if (defaultFold === false) {
      handlePressOpen()
    }
  }, [defaultFold])

  console.log('maxWidth', maxWidth, contentsWidth)

  if (modal)
    return (
      <>
        <View style={[styles.centeredView, isFold && { display: 'none' }]}>
          <Resizable isAnimated={isAnimated} maxWidth={maxWidth} animatedWidth={animatedWidth}>
            <Animated.View style={[styles.modalView, { width: animatedWidth }]}>
              <WorkCardView
                currentProject={currentProject}
                {...props}
                onPressClose={handlePressClose}
                onPressHold={handlePressHold}
              />
            </Animated.View>
          </Resizable>
        </View>
        {isFold && (
          <View style={{ position: 'absolute', width: 120, height: 48, right: 0, top: 218 }}>
            <Tooltip
              gab={10}
              darkType
              direction={'bottom-center'}
              text={props.edit ? t('card.writing') : t('card.viewing')}
              onPressTarget={handlePressOpen}
            >
              {(props) => (
                <View style={{ width: 120, height: 48 }} {...props}>
                  <View
                    style={{
                      position: 'absolute',
                      right: 0,
                      borderTopLeftRadius: 8,
                      borderBottomLeftRadius: 8,
                      backgroundColor: '#2A3237',
                      width: 50,
                      height: 48,
                      opacity: 0.8,
                    }}
                  />
                  <View
                    style={{
                      position: 'absolute',
                      right: 10,
                      top: 8,
                      backgroundColor: COLOR.sub.pink,
                      width: 32,
                      height: 32,
                      borderRadius: 8,
                      borderWidth: 2,
                      borderColor: COLOR.sub.pink200,
                    }}
                    {...props}
                  />
                </View>
              )}
            </Tooltip>
          </View>
        )}
      </>
    )
  else return <WorkCardView currentProject={currentProject} window={true} {...props} />
}

const styles = StyleSheet.create({
  centeredView: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    zIndex: 9,
  },
  modalView: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: COLOR.mono.white,
    ...getBoxShadow(0, 0, 0, 0.05, 5, -7, 5, 0),
  },
})
