import React, { useCallback, useEffect, useState } from 'react'

import { faHand } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Icon } from '@rocket-atoms/icon'
import { Tooltip } from '@rocket-atoms/tooltip'
import { COLOR, FONT, getRealColor } from '@rocket-mono/libs'
import { Avatar } from '@rui/atoms'
import { Animated, Easing, Platform, Pressable, StyleSheet, View } from 'react-native'
import SummaryLabel from '../../screens/CardCollectionScreen/components/CardGathering/SummaryLabel'
import Checkbox from '../Checkbox'
import TodoText from '../TodoText'
import { FileListItem, LabelItem, TagItem } from './Items'
import type { AssignmentType, TodoItemType } from './type'
import { useAstro } from '@rocket-mono/providers'
import { FileField } from '@rui/molecules'

interface Props {
  isAnonymous: boolean
  onAnonymousPress: () => void
  item: TodoItemType
  onCardLinkPress?: (cardNo: string, cardName: string, channelNo: string) => void
  onMentionPress?: (memberNo: string, memberName: string) => void
  onLinkPress?: (url: string, text?: string) => void

  onPressItem?: (id: string) => void
}
const Item = ({
  isAnonymous,
  onAnonymousPress,
  item,
  onCardLinkPress,
  onMentionPress,
  onLinkPress,
  onPressItem,
}: Props) => {
  const { astro } = useAstro()

  const [uploadPercent, setUploadPercent] = useState<number>(0)
  const [fold, setFold] = React.useState(item.fileUpload)
  const singleAssign = item.assignment && item.period === undefined && item.fileUpload === false
  //  &&
  // item.fileUpload === false &&
  // item.file?.length === 0

  const singlePriod = item.period && item.assignment === undefined && item.fileUpload === false
  //  &&
  // item.fileUpload === false &&
  // item.file?.length === 0

  React.useEffect(() => {
    // item.fileUpload && setFold(false)
    // item.file && setFold(false)
    // singleAssign && setFold(true)
    // singlePriod && setFold(true)
  }, [])

  const AssignListComponent = ({ fileUpload, assignment }: { fileUpload: boolean; assignment?: AssignmentType[] }) => {
    console.log('AssignListComponent', fileUpload, assignment)
    if (!assignment) return <></>
    return (
      <View style={[styles.flexBox, fileUpload ? styles.ifExtraFile : undefined]}>
        {assignment.map((item, idx) => (
          <TagItem item={item} key={`assign-${idx}`} />
        ))}
      </View>
    )
  }

  const [todoPercent, setTodoPercent] = useState(0)
  const gaugeAnim = React.useRef(new Animated.Value(0)).current

  const handleAnimation = useCallback(() => {
    Animated.timing(gaugeAnim, {
      toValue: todoPercent,
      duration: 300,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start()
  }, [todoPercent])

  React.useEffect(() => {
    handleAnimation()
  }, [item.checked, todoPercent])

  const gaugeChange = gaugeAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [`0%`, Platform.OS === 'web' ? `100%` : '105%'],
  })

  // const completeTodo = false //할일 완료
  // const entryDisabled = true //파일 입력 닫기

  // console.log('item', item, item.contents, { singleAssign, fold, singlePriod })

  // useEffect(() => {
  //   Animated.timing(gaugeAnim, {
  //     toValue: todoPercent,
  //     duration: 100,
  //     useNativeDriver: false,
  //   }).start()
  // }, [item.checked, todoPercent])

  useEffect(() => {
    console.log('todo-item', item)
  }, [item])

  useEffect(() => {
    if (item.id)
      astro
        .readTodoList('todo', item.id)
        .then((o) => {
          const todoCount = o.length
          const doneCount = o.filter(({ phaseInfo }) => phaseInfo?.phase === 'COMPLETED').length
          let percent = todoCount > 0 ? doneCount / todoCount : 0

          if (todoCount === 0 && doneCount === 0) {
            percent = item.checked ? 1 : 0
          }

          console.log('todo-item', item, { percent, todoCount, doneCount })

          return percent
        })
        .then(setTodoPercent)
  }, [item.id, item.checked])

  return (
    <View style={styles.container}>
      <View
        style={styles.contents}
        ref={(ref) => {
          if (ref && Platform.OS === 'web') {
            const $ref = ref as unknown as HTMLDivElement
            $ref.style.setProperty('z-index', '999', 'important')
          }
        }}
      >
        <Pressable
          style={[styles.itemTitle, item.optionVisible && { marginBottom: 6 }]}
          onPress={() => onPressItem && item.id && onPressItem(item.id)}
        >
          <Pressable
            onPress={() => {
              if (isAnonymous) onAnonymousPress()
              else item.onChecked && item.onChecked(), handleAnimation()
            }}
            style={{ marginTop: 3 }}
          >
            <Checkbox checked={item.checked} size={18} type="TODO" />
          </Pressable>
          {/* <Text style={styles.title}>{item.contents}</Text> */}
          <View style={{ width: 8 }} />
          <TodoText
            content={item.contents}
            fontColor="mono.black"
            onCardLinkPress={onCardLinkPress}
            onMentionPress={onMentionPress}
            onLinkPress={onLinkPress}
          />
          {item.complete.value && (
            <View style={styles.ifmemberHasCompeleted}>
              <Tooltip text={item.complete.userName} textStyle={{ ...FONT.txtSmMedium }} direction="left" darkType>
                {(props) => (
                  <View {...props} style={styles.profileArea}>
                    {item.complete.userProfile ? (
                      <Avatar size="xsmall" profileUrl={item.complete.userProfile} />
                    ) : (
                      <View
                        style={styles.profileCover}
                        ref={(ref) => {
                          if (ref && Platform.OS === 'web') {
                            const $ref = ref as unknown as HTMLDivElement
                            $ref.style.setProperty('z-index', '9', 'important')
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faHand} size={12} color={getRealColor('mono.white')} />
                      </View>
                    )}
                  </View>
                )}
              </Tooltip>
            </View>
          )}
        </Pressable>
        {item.label && (
          <View style={[styles.labelArea, styles.flexBox]}>
            {item.label.map((item, idx) => (
              <View style={{ marginRight: 4 }} key={`label-${idx}`}>
                <LabelItem item={item} />
              </View>
            ))}
          </View>
        )}

        {item.optionVisible ? (
          <View style={styles.submissionArea}>
            {singleAssign ? (
              <AssignListComponent fileUpload={item.fileUpload} assignment={item.assignment} />
            ) : (
              <Pressable style={styles.submitTab} onPress={() => !singlePriod && setFold(!fold)}>
                <View>
                  {item.period && (
                    <SummaryLabel
                      icon={'time-o'}
                      text={item.period}
                      iconColor={item.periodColor}
                      textColor={item.periodColor}
                    />
                  )}
                </View>
                <View style={styles.flexBox}>
                  <View style={styles.flexBox}>
                    {item.assignment && <SummaryLabel icon={'user-o'} text={String(item.assignment.length)} />}
                    {item.fileUpload && item.file ? (
                      <SummaryLabel icon={'attachment'} text={String(item.file.length)} />
                    ) : (
                      <></>
                    )}
                    {item.subTask && <SummaryLabel icon={'check-square-o'} text={item.subTask} />}
                    {item.thread && <SummaryLabel icon={'forum-o'} text={String(item.thread.length)} />}
                  </View>
                  {singleAssign ? (
                    <AssignListComponent fileUpload={item.fileUpload} assignment={item.assignment} />
                  ) : (
                    <View style={styles.foldButton}>
                      {singlePriod ? (
                        <></>
                      ) : (
                        <Icon name={fold ? 'angle-up' : 'angle-down'} size={12} color={'gray.g450'} />
                      )}
                    </View>
                  )}
                </View>
              </Pressable>
            )}
            {fold ? (
              <View style={!singlePriod && { paddingBottom: 4 }}>
                {!singleAssign && <AssignListComponent fileUpload={item.fileUpload} assignment={item.assignment} />}

                {item.fileUpload ? (
                  <View>
                    <View style={styles.entryFile}>
                      <FileField
                        multiple
                        size="SMALL"
                        progress={uploadPercent}
                        disabled={item.entryDisabled}
                        // disabledInfo={t('todoitem.cannotupload')}
                        onPress={item.entryDisabled ? () => {} : isAnonymous ? onAnonymousPress : undefined}
                        onLoadFile={(list) =>
                          item.onLoadFile?.(list, setUploadPercent).then(() => {
                            setTimeout(() => setUploadPercent(0), 500)
                          })
                        }
                        onDropFile={(list) =>
                          item.onDropFile?.(list, setUploadPercent).then(() => {
                            setTimeout(() => setUploadPercent(0), 500)
                          })
                        }
                        // onPasteFile={onLoadFile}
                      />
                      {/* <File
                        size="SMALL"
                        progress={0}
                        disabled={item.entryDisabled}
                        disabledInfo={t('todoitem.cannotupload')}
                        multiple={true}
                        onPress={
                          item.entryDisabled
                            ? () => {
                                console.log('disabled')
                              }
                            : isAnonymous
                            ? onAnonymousPress
                            : item.onPressFile
                        }
                        onLoadFile={item.onLoadFile}
                        onDropFile={item.onDropFile}
                        // onPasteFile={handleFile}
                      /> */}
                    </View>
                    {item.file && (
                      <View style={styles.ifFileList}>
                        {item.file.map((item, idx) => (
                          <View style={{ marginTop: 4 }} key={`todoFile-${idx}`}>
                            <FileListItem item={item} isAnonymous={isAnonymous} onAnonymousPress={onAnonymousPress} />
                          </View>
                        ))}
                      </View>
                    )}
                  </View>
                ) : (
                  <></>
                )}
              </View>
            ) : (
              <></>
            )}
          </View>
        ) : (
          <></>
        )}
      </View>
      <Animated.View
        style={[
          styles.todoGauge,
          {
            width: gaugeChange, // 완료 시 풀 게이지
            backgroundColor: item.checked ? COLOR.todo.completeBar : COLOR.gray.g150,
          },
        ]}
      ></Animated.View>
    </View>
  )
}

export default Item

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: COLOR.gray.g050,
    padding: 8,
  },
  contents: {
    position: 'relative',
    zIndex: 999,
  },
  todoGauge: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    backgroundColor: COLOR.gray.g150,
    zIndex: 99,
  },
  itemTitle: {
    position: 'relative',
    flexDirection: 'row',
    paddingVertical: 4,
  },
  title: {
    flex: 1,
    ...FONT.txtSm,
    marginLeft: 8,
  },
  ifExtraFile: {
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g100,
    paddingBottom: 12,
    marginBottom: 12,
  },
  entryFile: { height: 60 },
  submissionArea: {
    backgroundColor: COLOR.mono.white,
    padding: 8,
    paddingTop: 8,
    paddingBottom: 4,
    borderRadius: 4,
  },
  submitTab: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 4,
  },
  foldButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    marginLeft: 4,
  },

  ifmemberHasCompeleted: {
    marginLeft: 8,
  },
  profileArea: {
    overflow: 'hidden',
    position: 'relative',
    borderRadius: 30,
  },
  profileCover: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLOR.dimmed.a60,
    zIndex: 9,
  },

  labelArea: {
    marginBottom: 4,
  },
  ifFileList: {
    marginTop: 4,
  },
})
