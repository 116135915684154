import React from 'react'
import { Checkbox, FAIcon } from '@rocket/atoms'
import { StyleSheet, View, Text, Pressable } from 'react-native'
import { COLOR, FONT } from '@rocket-mono/libs'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

interface Props {
  allChecked: boolean
  setAllChecked: (arg0: boolean) => void
  sectionLabel: string
  count?: string
  open?: boolean
  onPress?: () => void
}
const Tab = ({
  allChecked,
  setAllChecked,
  sectionLabel,
  count,
  open,
  onPress
}: Props) => {
  return(
    <Pressable 
      style={styles.container}
      onPress={onPress && onPress}
    >
      <View style={styles.flexbox}>
        <Pressable 
          onPress={()=>setAllChecked(!allChecked)}
          style={styles.input}
        >
         <Checkbox checked={allChecked}/>
        </Pressable>
        <Text style={styles.label}>{sectionLabel}</Text>
        {count &&
          <Text style={styles.count}>{count}</Text>
        }
      </View>
      <FAIcon iconName={open? faAngleUp : faAngleDown} size={'xs'} color='gray.g600' />
    </Pressable>
  )
}

export default Tab

const styles = StyleSheet.create({
  container:{
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'center',
    padding: 8,
    borderTopWidth:1,
    borderColor:COLOR.gray.g200
  },
  flexbox:{
    flexDirection:'row',
    alignItems:'center',
  },
  input:{
    marginRight:4
  },
  label:{
    ...FONT.txtXsMedium,
  },
  count:{
    ...FONT.txtXsMedium,
    color:COLOR.main.red,
    marginLeft:4
  }
})