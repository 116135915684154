import * as React from 'react'
import { StyleSheet, View, Modal, DimensionValue } from 'react-native'
import { COLOR, getBoxShadow } from '@rocket-mono/libs'
import ClickOutside from '../ClickOutside'
import SelectItemGroup from './ItemGroup'
import type { SelectItemType } from './ItemGroup'

export interface SelectType {
  beforeDiv: boolean
  afterDiv: boolean
  group: SelectItemType[]
}

interface Props {
  list: SelectType[]
  visible?: boolean
  setVisible?: (arg0: boolean) => void
  mode?: 'LIGHT' | 'DARK'
  width?: DimensionValue
}

/**
 * @deprecated
 */
const Select = ({ list, visible, setVisible, mode, width }: Props) => {
  if (!visible) return <></>
  return (
    <ClickOutside
      onClickOutside={() => {
        setVisible && setVisible(false)
      }}
    >
      <View style={[styles.container, mode === 'LIGHT' && styles.lightContainer, { width }]}>
        {list.map((item, idx) => (
          <SelectItemGroup mode={mode} list={item} key={`selectItemGroup-${idx}`} />
        ))}
      </View>
    </ClickOutside>
  )
}

export default Select

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    width: 156,
    backgroundColor: COLOR.darkmode.warm600,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: COLOR.darkmode.cool600,
    ...getBoxShadow(0, 0, 0, 0.1, 4, 0, 0, 0),
  },
  lightContainer: {
    backgroundColor: COLOR.mono.white,
    borderColor: COLOR.gray.g200,
  },
})
