import { Icon } from '@rocket-atoms/icon'
import { COLOR, FONT, IColors, IconName } from '@rocket-mono/libs'
import * as React from 'react'
import { StyleSheet, Text } from 'react-native'
import Button from '../Button'

interface Props {
  icon: IconName
  iconSize?: number
  iconColor: IColors
  onPress?: () => void
  text?: string
  stateOff?: boolean
  isValued?: boolean
  readOnly?: boolean
}

export const TodoItemOptionButton: React.FC<Props> = ({
  icon,
  iconSize = 16,
  iconColor,
  onPress,
  text,
  stateOff,
  isValued,
  readOnly,
}) => {
  return (
    <Button
      size="small"
      style={[
        styles.buttons,
        styles.optionButtonActive,
        stateOff && styles.iconButtons,
        stateOff && styles.optionButton,
        isValued && { backgroundColor: COLOR.gray.g050 },
        readOnly && { backgroundColor: COLOR.transparent.base, opacity: 0.8 },
      ]}
      onPress={onPress && onPress}
    >
      <Icon name={icon} size={iconSize} color={iconColor} style={styles.icon} />
      {text && <Text style={styles.optionText}>{text}</Text>}
    </Button>
  )
}

const styles = StyleSheet.create({
  buttons: {
    flexDirection: 'row',
    minWidth: 28,
    height: 28,
    paddingHorizontal: 8,
    marginRight: 4,
  },
  optionButton: {
    backgroundColor: COLOR.transparent.base,
  },
  optionButtonActive: {
    flexDirection: 'row',
    backgroundColor: COLOR.mono.white,
  },
  iconButtons: {
    width: 32,
    paddingHorizontal: 0,
    marginRight: 8,
  },
  icon: {
    marginTop: -2,
  },
  optionText: {
    ...FONT.txtXs,
    color: COLOR.gray.g700,
    marginLeft: 2,
  },
})
