import React from 'react'

interface Props {
  children: React.ReactNode
}
const Providers: React.FC<Props> = ({ children }) => {
  return <>{children}</>
}

export default Providers
