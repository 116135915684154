import React from 'react'

import { Props } from './types'
import Providers from '../providers'
import { LayoutNavView } from './view'

export const LayoutNav: React.FC<Props> = (props) => {
  return (
    <Providers>
      <LayoutNavView {...props} />
    </Providers>
  )
}
