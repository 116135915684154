import { DropdownButton } from '@rocket-atoms/dropdown'
import { SelectorBoard, SelectorBoardType } from '@rocket-molecules/popselector'
import { ClickOutside } from '@rocket-mono/libs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DimensionValue, StyleSheet, Text, View } from 'react-native'
import { commonStyles } from './style'

const zIndex = 1

interface Props {
  boardList: SelectorBoardType[]
  selectedBoardList: string[]
  onSelectedBoardList: (list: string[]) => void
}

const RuleBoardSelectView = ({ boardList, selectedBoardList, onSelectedBoardList }: Props) => {
  const { t } = useTranslation()
  const [activated, setActivated] = React.useState(false)
  const [buttonStandard, setButtonStandard] = React.useState<{ width: DimensionValue; height: number }>({
    width: 0,
    height: 0,
  })

  return (
    <View>
      <Text style={commonStyles.labelText}>{t('rule.boards.select')}</Text>
      <View style={styles.container}>
        <DropdownButton
          type={'DEFAULT'}
          configuration={'DEFAULT'}
          size={'md'}
          disabled={false}
          activated={activated}
          setActivated={setActivated}
          setButtonStandard={setButtonStandard}
          isButtonBackgroundColorWhite
        >
          <View style={styles.boardLabel}>
            {selectedBoardList.length !== 0 ? (
              <Text style={commonStyles.initText}>
                {t('rule.boards.selected', { count: selectedBoardList.length })}
              </Text>
            ) : (
              <Text style={commonStyles.initText}>{t('rule.boards.select')}</Text>
            )}
          </View>
        </DropdownButton>
        {activated && (
          <ClickOutside onClickOutside={() => setActivated(false)}>
            <View style={{ position: 'absolute', top: buttonStandard.height + 4, zIndex, width: '100%' }}>
              <SelectorBoard
                board={boardList}
                selectedItemList={selectedBoardList}
                // width={buttonStandard.width}
                onPressEdit={(selectedItemList) => {
                  onSelectedBoardList(selectedItemList)
                  setActivated(false)
                }}
                onClose={() => setActivated(false)}
              />
            </View>
          </ClickOutside>
        )}
      </View>
    </View>
  )
}

export default RuleBoardSelectView

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  boardLabel: {
    overflow: 'hidden',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 4,
    paddingRight: 6,
    borderRadius: 4,
  },
})
