import { ClickOutside } from '@rui/foundations'
import React from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'
import Whisper from '../Whisper'
import { useMention } from '../hooks/useMention'

interface Props {
  containerStyle?: ViewStyle
  direction: 'upside' | 'downside'
  whisperHeight?: number
  marginTop?: number
  marginBottom?: number
  marginLeft?: number
  marginRight?: number
  onPicked?: (index: number, id: string) => void
  onCanceled?: () => void
}

export const MentionTargetPicker: React.FC<Props> = ({
  containerStyle,
  direction,
  whisperHeight,
  marginTop = -230,
  marginBottom = -230,
  marginLeft,
  marginRight,
  onCanceled,
  onPicked,
}) => {
  const { mentionList, mentionListOpen, cursorPosition, pickMentionItem, closeMention } = useMention()

  const ref = React.useRef<View>(null)

  // selectedUserId by cursorPosition in mentionList
  const selectedUserId = React.useMemo(() => {
    const mention = mentionList.find((_item, idx) => idx === cursorPosition)?.name
    return mention
  }, [mentionList, cursorPosition])

  const dynamicStyles = React.useMemo(
    () => ({
      container: [
        styles.container,
        mentionListOpen && direction === 'upside'
          ? [styles.upside, { marginTop }]
          : [styles.downside, { marginBottom }],
        mentionListOpen ? styles.opened : styles.closed,
        { marginLeft, marginRight },
      ],
    }),
    [direction, marginBottom, marginLeft, marginRight, marginTop, mentionListOpen],
  )

  React.useEffect(() => {
    console.debug('MentionTargetPicker:: mentionListOpen', {
      mentionListOpen,
      dynamicStyles,
    })
  }, [dynamicStyles, mentionListOpen])

  const findIndexInMentionList = React.useCallback(
    (id: string) => {
      return mentionList.findIndex((o) => o.id === id || o.name === id)
    },
    [mentionList],
  )
  return (
    <ClickOutside
      onClickOutside={() => {
        if (mentionListOpen) {
          closeMention(true)
          onCanceled?.()
        }
      }}
    >
      {mentionListOpen ? (
        <View style={[dynamicStyles.container, containerStyle, { zIndex: 10000 }]} ref={ref}>
          <Whisper
            type="mention"
            height={whisperHeight}
            users={[
              ...mentionList.map((item) => ({
                id: item.name,
                name: item.name,
              })),
            ]}
            selectedUserId={selectedUserId}
            onHoverInUser={(user) => {
              const selectedIndex = findIndexInMentionList(user.name)
              if (selectedIndex !== undefined) {
                pickMentionItem(selectedIndex)
                onPicked?.(selectedIndex, user.name)
              }
            }}
            setSelectedUserId={(id) => {
              console.debug('set selected user id', id)
              const selectedIndex = findIndexInMentionList(id)
              if (selectedIndex !== undefined) {
                pickMentionItem(selectedIndex, true)
              }
            }}
          />
        </View>
      ) : (
        <></>
      )}
    </ClickOutside>
  )
}

export default React.memo(MentionTargetPicker)

const styles = StyleSheet.create({
  container: {
    paddingBottom: 4,
    paddingRight: 8,
    paddingLeft: 8,
  },
  opened: {
    display: 'flex',
  },
  upside: {
    marginTop: -230,
    marginBottom: 0,
  },
  downside: {
    marginTop: 0,
    marginBottom: -230,
  },
  closed: {
    display: 'none',
    marginTop: 0,
    marginBottom: 0,
  },
})
