import React, { useMemo } from 'react'

import { View } from '@rocket-mono/foundations'
import { useWork, GroupType } from '@rocket-mono/providers'
import { useMobileView } from '@rui/foundations'

import WorkCardGroup from '../../../components/WorkCardGroup'
import { Droppable } from 'react-beautiful-dnd-grid-support'
import { useRecoilValue } from 'recoil'
import { draggingIdState } from '../../../recoil'
import { DROPABLE_GROUP_CARD_ID, DROPABLE_GROUP_VIEW_ID, workCardConvert } from '../../../utils'
import { WorkCardType } from '../../../components/WorkCard'
import WorkspaceMainCardList from '../list'

interface Props {
  group: GroupType
  onPressProject: (projectId: string) => void
}

const WorkspaceMainGroup: React.FC<Props> = ({ group, onPressProject }) => {
  const mobileView = useMobileView(500)
  const mobileWebView = useMobileView(768)
  const draggingId = useRecoilValue(draggingIdState)

  const {
    positionList,
    projectList,
    updateGroupTitle,
    deleteGroup,
    favoriteList,
    deleteFavorite,
    addFavorite: createFavorite,
  } = useWork()

  const positionProjectList = useMemo(
    () =>
      positionList
        ?.filter(({ groupId }) => groupId === String(group.groupNo))
        .sort((a, b) => a.projectRow - b.projectRow),
    [positionList, group.groupNo],
  )

  const filteredList = useMemo<WorkCardType[] | undefined>(() => {
    if (projectList !== undefined && positionProjectList !== undefined)
      return projectList
        .filter(({ id }) => positionProjectList.map(({ projectId }) => projectId).includes(id))
        .map((project, idx) => {
          const position = positionProjectList.find(({ projectId }) => projectId === project.id)
          const o = workCardConvert(project, onPressProject, favoriteList, createFavorite, deleteFavorite)
          const index = position ? position.projectRow : idx
          return { ...o, index }
        })
        .sort((a, b) => a.index - b.index)
    // return positionProjectList
    //   .map(({ projectId }) => {
    //     const project = projectList.find(({ id }) => id === projectId)
    //     return workCardConvert(project, onPressProject, favoriteList, deleteFavorite)
    //   })
    //   .filter((o) => o !== undefined)
  }, [positionProjectList, projectList, onPressProject, favoriteList, deleteFavorite])

  const skeleton = useMemo(() => filteredList === undefined, [filteredList])

  if (filteredList === undefined) return null
  return (
    <View
      style={[
        { marginBottom: 12, marginLeft: 12 },
        mobileWebView && { width: '100%', marginLeft: '2%' },
        mobileWebView && filteredList.length > 5 && { marginLeft: '1%' },
        mobileView && { width: '100%', marginLeft: 0 },
      ]}
    >
      <WorkCardGroup
        defaultInputFocus={group.name === ''}
        skeleton={skeleton}
        title={group.name ?? ''}
        onChangeTitle={(title) => {
          updateGroupTitle(group.groupNo, title)
        }}
        onPressDelete={() => {
          deleteGroup(String(group.groupNo))
        }}
      >
        <View
          style={[
            { flexDirection: 'row', flexWrap: 'wrap', marginLeft: -2 },
            mobileWebView && filteredList.length > 5 && { marginLeft: '-1.3%' },
          ]}
        >
          {filteredList.length > 5 && !mobileView ? (
            <>
              {Array(Math.ceil(filteredList.length / 5))
                .fill(0)
                .map((_, idx) => {
                  const start = idx * 5
                  const end = start + 5
                  return (
                    <Droppable
                      key={idx}
                      isDropDisabled={[DROPABLE_GROUP_VIEW_ID].includes(draggingId) || filteredList.length === 20}
                      droppableId={DROPABLE_GROUP_CARD_ID + `${idx}-` + String(group.groupNo)}
                    >
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            width: mobileView ? '100%' : mobileWebView ? '49%' : '',
                            marginLeft: mobileWebView ? '1%' : '',
                            marginBottom: mobileWebView && idx < 2 ? 12 : 0,
                          }}
                        >
                          <WorkspaceMainCardList
                            list={filteredList.slice(start, end < filteredList.length ? end : undefined)}
                            containerStyle={{ paddingLeft: mobileWebView ? 0 : 4 }}
                            workItemStyle={
                              mobileWebView
                                ? { minWidth: '100%', maxWidth: '100%', marginBottom: 4 }
                                : { marginBottom: 4 }
                            }
                          >
                            {provided.placeholder}
                          </WorkspaceMainCardList>
                        </div>
                      )}
                    </Droppable>
                  )
                })}
            </>
          ) : (
            <Droppable
              isDropDisabled={[DROPABLE_GROUP_VIEW_ID].includes(draggingId) || filteredList.length === 20}
              droppableId={DROPABLE_GROUP_CARD_ID + String(group.groupNo)}
            >
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    width: mobileView ? '100%' : mobileWebView ? '100%' : '',
                    marginLeft: mobileWebView ? '1%' : '',
                  }}
                >
                  <WorkspaceMainCardList
                    list={filteredList}
                    wrapStyle={{ marginLeft: mobileWebView ? 0 : -4 }}
                    containerStyle={{ marginLeft: mobileWebView ? 0 : 4 }}
                    workItemStyle={
                      mobileWebView ? { minWidth: '100%', maxWidth: '100%', marginBottom: 4 } : { marginBottom: 4 }
                    }
                  >
                    {provided.placeholder}
                  </WorkspaceMainCardList>
                </div>
              )}
            </Droppable>
          )}
        </View>
      </WorkCardGroup>
    </View>
  )
}
export default WorkspaceMainGroup
