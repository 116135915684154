import * as React from 'react'
import { Path, Svg } from 'react-native-svg'

const Marker: React.FC = () => (
  <Svg width={10} height={14} viewBox="0 0 10 14" fill="none">
    <Path
      d="M5 0.671875C4.35417 0.671875 3.74479 0.796875 3.17188 1.04688C2.60938 1.28646 2.11458 1.61979 1.6875 2.04688C1.27083 2.46354 0.9375 2.95312 0.6875 3.51562C0.447917 4.07812 0.328125 4.68229 0.328125 5.32812C0.328125 6.20312 0.572917 7.15104 1.0625 8.17188C1.55208 9.18229 2.08854 10.1198 2.67188 10.9844C3.25521 11.849 3.78646 12.5677 4.26562 13.1406C4.75521 13.7135 5 14 5 14C5 14 5.23958 13.7135 5.71875 13.1406C6.20833 12.5677 6.74479 11.849 7.32812 10.9844C7.91146 10.1198 8.44792 9.18229 8.9375 8.17188C9.42708 7.15104 9.67188 6.20312 9.67188 5.32812C9.67188 4.68229 9.54688 4.07812 9.29688 3.51562C9.05729 2.95312 8.72396 2.46354 8.29688 2.04688C7.88021 1.61979 7.38542 1.28646 6.8125 1.04688C6.25 0.796875 5.64583 0.671875 5 0.671875ZM5 7.32812C4.44792 7.32812 3.97396 7.13542 3.57812 6.75C3.19271 6.35417 3 5.88021 3 5.32812C3 4.77604 3.19271 4.30729 3.57812 3.92188C3.97396 3.52604 4.44792 3.32812 5 3.32812C5.55208 3.32812 6.02083 3.52604 6.40625 3.92188C6.80208 4.30729 7 4.77604 7 5.32812C7 5.88021 6.80208 6.35417 6.40625 6.75C6.02083 7.13542 5.55208 7.32812 5 7.32812Z"
      fill="#80909B"
    />
  </Svg>
)
export default Marker
