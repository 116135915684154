import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'

import { useRecoilState, useRecoilValue } from 'recoil'
import { sideLayoutTypeState, unreadMessageCountState, unreadNotificationCountState } from '../../recoils'

import { useAstro, useCurrentUser, useStomp, useWork } from '@rocket-mono/providers'
import { LayoutTop } from '@rocket-screens/layout'

import { faBell, faCommentDots } from '@fortawesome/free-regular-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export type UserState = 'Active' | 'Busy' | 'Off'
const TOP_SIZE = 52
interface Props {
  size?: number
}

const Top: React.FC<Props> = ({ size = TOP_SIZE }) => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { astro, option } = useAstro()
  const { client } = useStomp()
  const { currentUser } = useCurrentUser()

  const { projectList, archiveList, addRecent } = useWork()

  const unreadMessageCount = useRecoilValue(unreadMessageCountState)
  const unreadNotificationCount = useRecoilValue(unreadNotificationCountState)

  const [sideLayoutType, setSideLayoutType] = useRecoilState(sideLayoutTypeState)

  const [stateData, setStateData] = useState<{
    state: 'active' | 'busy' | 'off' | 'invisible' | 'away'
    busyStartDate?: number
    busyEndDate?: number
    offStartDate?: number
    offEndDate?: number
  }>()

  const currentWork = useMemo<{ currentWork: string; currentWorkId?: string; currentWorkType?: string }>(() => {
    let currentWork = 'WORKSPACE'
    let currentWorkId: string | undefined = undefined
    let currentWorkType: string | undefined = undefined
    if (projectList !== undefined && archiveList !== undefined && projectId !== undefined) {
      const project = [...projectList, ...archiveList].find(({ id }) => id === projectId)
      if (project !== undefined) {
        currentWorkId = project.id
        currentWork = project.title
        currentWorkType = project.type?.code
      }
    }

    return { currentWork, currentWorkId, currentWorkType }
  }, [projectList, archiveList, projectId])

  const projectPath = useCallback(
    (naviType: string) => {
      const path = pathname.split('/').pop() || ''
      if (naviType === 'TABLEORDER') return ['member', 'pos', 'menu'].includes(path) ? path : 'pos'
      return ['user', 'board', 'calendar'].includes(path) ? path : 'board'
    },
    [pathname],
  )

  const handlePressWork = useCallback(
    (projectId: string, path?: string) => {
      const type = projectList?.find(({ id }) => id === projectId)?.type.code === 'TOP' ? 'TABLEORDER' : 'DEFAULT'

      if (projectId) {
        addRecent(projectId)
        navigate(`/project/${projectId}/${path ?? projectPath(type)}`)
      }
    },
    [projectList],
  )

  const handlePressHome = useCallback(() => {
    navigate(`/`)
  }, [])

  const handlePressSetting = useCallback(() => {
    navigate('/settings')
  }, [])

  const handleChangeUserState = useCallback(
    (state: UserState, startDate?: Date, endDate?: Date) => {
      console.log('state', currentUser, state)

      if (state === 'Active') {
        if (client) {
          client.publish({
            destination: '/publish/state',
            body: JSON.stringify({
              userNo: currentUser.id,
              platform: 'web',
              userState: 1,
            }),
          })
        }
        setStateData((prev) => ({
          ...prev,
          state: 'active',
        }))
      } else if (state === 'Busy' && endDate) {
        astro
          .createUserStatePeriods({
            userId: String(currentUser.id),
            applyUserState: 3,
            applyStartTimestamp: new Date().getTime(),
            applyEndTimestamp: endDate.getTime(),
            rollbackUserState: currentUser.userState === 'active' ? 1 : 0,
            updatedAt: new Date(),
          })
          .then(() => {
            setStateData((prev) => ({
              ...prev,
              state: 'busy',
              busyEndDate: endDate.getTime(),
            }))
          })
      } else if (state === 'Off' && startDate && endDate) {
        astro
          .createUserStatePeriods({
            userId: String(currentUser.id),
            applyUserState: 4,
            applyStartTimestamp: startDate.getTime(),
            applyEndTimestamp: endDate.getTime(),
            rollbackUserState: currentUser.userState === 'active' ? 1 : 0,
            updatedAt: new Date(),
          })
          .then(() => {
            setStateData((prev) => ({
              ...prev,
              state: 'off',
              offStartDate: startDate.getTime(),
              offEndDate: endDate.getTime(),
            }))
          })
      }
    },
    [currentUser],
  )

  const getState = () => {
    if (currentUser) {
      astro.readUserState(String(currentUser.id)).then((state) => {
        console.log('readUserState', state)
        astro
          .readUserStatePeriods(String(currentUser.id))
          .then((periods) => {
            const busyPeriods = periods.filter((o) => o.applyUserState === 3)
            const offPeriods = periods.filter((o) => o.applyUserState === 4)
            setStateData({
              state,
              busyStartDate:
                busyPeriods.length > 0 && state === 'busy' ? busyPeriods[0].applyStartTimestamp : undefined,
              busyEndDate: busyPeriods.length > 0 && state === 'busy' ? busyPeriods[0].applyEndTimestamp : undefined,
              offStartDate: offPeriods.length > 0 ? offPeriods[0].applyStartTimestamp : undefined,
              offEndDate: offPeriods.length > 0 ? offPeriods[0].applyEndTimestamp : undefined,
            })
          })
          .catch(() => {
            setStateData({ state })
          })
      })
    }
  }

  const profile = useMemo(() => {
    const { userName: name } = currentUser
    const image = option.secureCdnUrl + '/profile/' + currentUser.userEmail
    // const state: UserState = userState === 'active' ? 'Active' : userState === 'busy' ? 'Busy' : 'Off'
    return {
      name,
      image,
      state: stateData
        ? stateData.state === 'active'
          ? ('Active' as UserState)
          : stateData.state === 'busy'
          ? ('Busy' as UserState)
          : ('Off' as UserState)
        : ('Active' as UserState),
      busyApplyStartTimestamp: stateData ? stateData.busyStartDate : undefined,
      busyApplyEndTimestamp: stateData ? stateData.busyEndDate : undefined,
      offApplyStartTimestamp: stateData ? stateData.offStartDate : undefined,
      offApplyEndTimestamp: stateData ? stateData.offEndDate : undefined,
    }
  }, [currentUser, stateData])

  const activityMenuList = useMemo(() => {
    return [
      {
        code: 'search',
        iconName: faSearch,
        isCount: undefined,
      },
      {
        code: 'bell',
        iconName: faBell,
        isCount: unreadNotificationCount > 0 ? unreadNotificationCount : undefined,
      },
      {
        code: 'message',
        iconName: faCommentDots,
        isCount: unreadMessageCount > 0 ? unreadMessageCount : undefined,
      },
    ]
  }, [unreadMessageCount, unreadNotificationCount])

  const handlePressCode = useCallback((code: string) => {
    console.log('handlePressCode', code)
    setSideLayoutType((prev) => {
      console.log('prev', prev)
      return prev === code ? '' : code
    })
  }, [])

  useEffect(() => {
    getState()
  }, [])

  useEffect(() => {
    let iv
    let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']")
    if (unreadMessageCount > 0) {
      iv = setInterval(() => {
        if (!link) {
          link = document.createElement('link')
          link.rel = 'icon'
          document.getElementsByTagName('head')[0].appendChild(link)
        }

        if (link.href.endsWith('new.ico')) {
          link.href = '/favicon.ico'
        } else {
          link.href = `/new.ico`
        }

        document.title =
          document.title === 'Rocket Work' ? `(${unreadMessageCount}) ${t('alert.newmessage')}` : 'Rocket Work'
      }, 1000)
    }

    return () => {
      if (iv) clearInterval(iv)
      if (link) link.href = '/favicon.ico'
      document.title = 'Rocket Work'
    }
  }, [unreadMessageCount])

  return (
    <View style={{ height: size, position: 'absolute', top: 0, left: 0, right: 0 }}>
      <LayoutTop
        {...currentWork}
        theme={pathname === '/' ? 'dark' : 'light'}
        workList={projectList || []}
        navMenu={[]}
        activityMenuList={activityMenuList}
        code={sideLayoutType}
        onCode={handlePressCode}
        profile={profile}
        onPressLogo={handlePressHome}
        onPressProject={handlePressWork}
        onUpdateUserState={handleChangeUserState}
        onProfileSetting={handlePressSetting}
      />
    </View>
  )
}

export default Top
export { TOP_SIZE }
