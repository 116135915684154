import * as React from 'react'

import { Pressable, StyleSheet, View, Text } from 'react-native'
import { COLOR, FONT } from '@rui/foundations'
import { Avatar } from '@rui/atoms'
import Skeleton from './Skeleton'

interface ActivitiySearchItemUser {
  profileImage: string
  userName: string
  userEmail: string
  userState: string
  onPress: () => void
  isSkeleton: boolean
}
const ActivitySearchItemUser = ({
  profileImage,
  userName,
  userEmail,
  userState,
  onPress,
  isSkeleton,
}: ActivitiySearchItemUser) => {
  const [isHover, setIsHover] = React.useState(false)

  return isSkeleton ? (
    <Skeleton />
  ) : (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      onPress={onPress}
      style={[styles.container, isHover && { backgroundColor: COLOR.gray.g050 }]}
    >
      <View style={styles.profileArea}>
        <Avatar
          profileUrl={profileImage}
          size="medium"
          // alt={userName}
        />
      </View>
      <View>
        <Text style={styles.userName}>{userName}</Text>
        <Text style={styles.info}>{userEmail}</Text>
      </View>
    </Pressable>
  )
}

export default ActivitySearchItemUser

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: 20,
    paddingVertical: 8,
  },
  profileArea: {
    marginRight: 8,
  },
  userName: {
    ...FONT.txtSmMedium,
  },
  info: {
    ...FONT.txtXs,
    color: COLOR.gray.g500,
    marginTop: 2,
  },
})
