import * as React from 'react'
import { StyleSheet, View, ViewStyle, Pressable, Text, Platform } from 'react-native'
import { COLOR, FONT } from '@rocket-mono/libs'
import { FAIcon } from '@rocket-atoms/icon'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
// import { useTranslation } from 'react-i18next'

interface Props {
  // code: string
  isOpen?: boolean
  // onOpen?: (open: boolean) => void
  onOffsetY?: (y: number) => void
  // onDrop?: (dropped: boolean) => void
  containerStyle?: ViewStyle
  isEnable?: boolean
  title?: string
  onPress?: () => void
  onResize?: (pageY: number) => void
}
/**
 * @deprecated
 */
function DropdownSecondaryButton({
  // code,
  isOpen,
  // onOpen,
  onOffsetY,
  // dropdownList,
  // onDrop,
  onPress,
  containerStyle,
  isEnable,
  title,
  onResize,
}: Props) {
  // const { t } = useTranslation()
  const mainButtonRef = React.useRef<View>(null)
  // const useSelectedItem = React.useMemo(() => {
  //   const selectItems = dropdownList.filter((item) => item.code === code)
  //   if (selectItems.length > 0) {
  //     return selectItems[0].title
  //   } else {
  //     return t('dropdown.initcode')
  //   }
  // }, [dropdownList, code, t])
  React.useEffect(() => {
    const listener = () => {
      onResize &&
        mainButtonRef.current &&
        mainButtonRef.current.measure((_x, _y, _width, _height, _pageX, pageY) => onResize(pageY))
    }
    if (Platform.OS === 'web') {
      window.addEventListener('resize', listener)
    }
    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('resize', listener)
      }
    }
  }, [onResize])

  return (
    <View style={[styles.container, containerStyle, { opacity: isEnable ? 1 : 0.5 }]}>
      <Pressable
        ref={mainButtonRef}
        onLayout={() => {
          if (mainButtonRef.current && mainButtonRef.current.measure) {
            mainButtonRef.current.measure((_x, _y, _width, _height, _pageX, pageY) => {
              onOffsetY && onOffsetY(pageY)
            })
          }
        }}
        style={[
          styles.dropbutton,
          isOpen
            ? {
                backgroundColor: COLOR.gray.g100,
              }
            : undefined,
        ]}
        onPress={onPress}
      >
        <Text style={styles.buttonText}>{title ? title : ''}</Text>
        <View style={{ marginLeft: 4 }}>
          <FAIcon iconName={isOpen ? faAngleUp : faAngleDown} size={'xs'} color={'gray.g600'} />
        </View>
      </Pressable>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {},
  dropbutton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: COLOR.gray.g050,
    height: 34,
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 4,
  },
  listButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
    paddingHorizontal: 10,
    borderColor: '#D5D5D5',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    height: 35,
  },
  buttonText: {
    ...FONT.txtSm,
  },
  listText: {
    ...FONT.textRegular,
    fontSize: 12,
    color: COLOR.mono.black,
  },
})

export default React.memo(DropdownSecondaryButton)
