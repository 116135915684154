import * as React from 'react'
import { View } from 'react-native'

import { useTranslation } from 'react-i18next'

import { useWorkAssignee, useWorkCard } from '@rocket-mono/providers'
import { WorkCardLayout } from '../../components/WorkCardLayout'
import type { ViewProps } from './types'
import { useCardTodoDetail } from '@rui/screen-card'

const ScreenView: React.FC<ViewProps> = ({ fallback, children, discussionView = <></>, ...layoutProps }) => {
  const { t } = useTranslation()
  const { currentCard } = useWorkCard()
  const { onClose } = useCardTodoDetail()

  const { assigneeList } = useWorkAssignee()

  const assigneeIds = React.useMemo(
    () =>
      assigneeList.map(({ id, userId, userEmail }) => ({
        id,
        userId: String(userId ?? ''),
        userEmail: userEmail ?? '',
      })),
    [assigneeList],
  )

  const dateProps = React.useMemo(() => {
    let isTime = false
    let cardDate = {}
    if (currentCard && (currentCard.fromPeriodDate || currentCard.toPeriodDate)) {
      isTime = true
      const { fromPeriodDate, toPeriodDate, isFromPeriodDateTime, isToPeriodDateTime } = currentCard
      const startDate = fromPeriodDate ? new Date(fromPeriodDate) : undefined
      const endDate = toPeriodDate ? new Date(toPeriodDate) : undefined
      const isStartTime = isFromPeriodDateTime !== null ? isFromPeriodDateTime : false
      const isEndTime = isToPeriodDateTime !== null ? isToPeriodDateTime : false
      cardDate = { startDate, endDate, isStartTime, isEndTime }
    }
    return { isTime, cardDate }
  }, [currentCard])

  const [cardNo, setCardNo] = React.useState<string>()
  const [relatedDomainId, setRelatedDomainId] = React.useState<string>()

  const [content, setContent] = React.useState<string | null>()
  React.useEffect(() => {
    setContent(undefined)
    if (currentCard !== null && currentCard.content) {
      setTimeout(() => setContent(currentCard.content), 200)
    } else {
      setContent(null)
    }
  }, [currentCard])

  React.useEffect(() => {
    setCardNo(undefined)
    if (currentCard) {
      setTimeout(() => setCardNo(String(currentCard.no)), 50)
    }
  }, [currentCard])

  React.useEffect(() => {
    console.log('relatedDomainId', cardNo)
    if (cardNo) {
      setRelatedDomainId(cardNo)
    }
  }, [cardNo])

  if (currentCard === null) return fallback

  return (
    <WorkCardLayout
      edit={false}
      {...dateProps}
      {...layoutProps}
      onClose={onClose}
      cardType="TODO"
      title={currentCard?.title || ''}
      optionList={[]}
      assigneeIds={assigneeIds}
      discussionView={discussionView}
    >
      {(width) => <View style={{ flex: 1, width: '100%' }}>{children?.(width)}</View>}
    </WorkCardLayout>
  )
}

export default ScreenView
