import { COLOR } from '@rocket-mono/libs'
import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import ButtonIcon from '../../ButtonIcon'

const BUTTON_SIZE = 30
const styles = StyleSheet.create({
  controlButton: {
    width: BUTTON_SIZE,
    height: BUTTON_SIZE,
    borderRadius: 0,
    borderColor: COLOR.mono.gray,
    borderWidth: 1,
  },
})

interface Props {
  isExpand: boolean
  mapHeight: number
  expand: boolean
  onZoomPlus: () => void
  onZoomMinus: () => void
  onExpand?: () => void
  onExternal?: () => void
  onLocation?: () => void
}
export const ControlButtons: React.FC<Props> = ({
  isExpand,
  mapHeight,
  expand,
  onZoomPlus,
  onZoomMinus,
  onExpand,
  onExternal,
  onLocation,
}): JSX.Element => {
  return (
    <>
      {expand && (
        <ButtonIcon
          style={[styles.controlButton, { position: 'absolute', top: 10, right: 10 }]}
          containerSize={0}
          iconName={isExpand ? 'compress' : 'expand'}
          iconSize={14}
          iconColor="mono.black"
          backgroundColor="mono.white"
          onPress={onExpand}
        />
      )}
      {onLocation && (
        <ButtonIcon
          style={[styles.controlButton, { position: 'absolute', left: 10, bottom: 10 }]}
          containerSize={0}
          iconName="my-location"
          iconSize={14}
          iconColor="mono.black"
          backgroundColor="mono.white"
          onPress={onLocation}
        />
      )}
      {onExternal && (
        <ButtonIcon
          style={[styles.controlButton, { position: 'absolute', right: 10, bottom: 10 }]}
          containerSize={0}
          iconName="external-link"
          iconSize={14}
          iconColor="mono.black"
          backgroundColor="mono.white"
          onPress={onExternal}
        />
      )}
      <View
        style={{
          position: 'absolute',
          right: 10,
          bottom: mapHeight / 2 - BUTTON_SIZE,
        }}
      >
        <ButtonIcon
          style={styles.controlButton}
          containerSize={0}
          iconName="plus"
          iconSize={14}
          iconColor="mono.black"
          backgroundColor="mono.white"
          onPress={onZoomPlus}
        />
        <View style={{ height: 12 }} />
        <ButtonIcon
          style={styles.controlButton}
          containerSize={0}
          iconName="minus"
          iconSize={14}
          iconColor="mono.black"
          backgroundColor="mono.white"
          onPress={onZoomMinus}
        />
      </View>
    </>
  )
}
