import * as React from 'react'
import { StyleSheet, Platform, Pressable } from 'react-native'

import { COLOR, FONT } from '@rocket-mono/libs'
import { Text } from '@rocket/atoms'

interface Props {
  name: string
  buttonColor: 'main.blue' | 'mono.white'
  onPress?: () => void
}
export const DialogButton: React.FC<Props> = ({ name, buttonColor, onPress }) => {
  return (
    <Pressable
      onPress={onPress}
      // backgroundColor={buttonColor}
      // borderColor={buttonColor === 'mono.white' ? 'mono.gray' : undefined}
      // size="large"
      style={[
        styles.button,
        {
          ...Platform.select({
            native: {
              height: 44,
            },
          }),
        },
      ]}
    >
      <Text
        fontColor={buttonColor === 'mono.white' ? 'gray.g700' : 'mono.white'}
        style={{
          ...Platform.select({
            web: {
              ...FONT.txtMd,
            },
            native: {
              ...FONT.txtMd,
            },
          }),
        }}
      >
        {name}
      </Text>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  button: {
    minWidth: '100%',
    height: 44,
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 8,
    marginVertical: 4,
    color: COLOR.mono.white,
  },
  buttonCancel: {
    width: '100%',
    marginTop: 12,
  },
})
