import { Button } from '@rocket-atoms/button'
import { SearchTextField } from '@rocket-atoms/text'
import { Combobox } from '@rui/molecules'
import { COLOR, FONT } from '@rocket-mono/libs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { SectionAccordion, SectionAccordionType } from '../Components/SectionAccordion'
import { Props, SelectorWorkType } from './types'

const SelectorWork: React.FC<Props> = ({
  list,
  defaultIndex,
  checkedType,
  width,
  bodyHeight,
  onPressSave,
  onPressCancel,
}) => {
  const { t } = useTranslation()
  const [searchKeyword, setSearchKeyword] = React.useState('')
  const [item, setItem] = React.useState<SectionAccordionType>()

  const previewList = React.useMemo(() => {
    return list.map((item) => {
      return {
        ...item,
        sectionList: item.sectionList.filter((section) => section.label.includes(searchKeyword)),
      }
    })
  }, [list, searchKeyword])

  const body = React.useMemo(() => {
    if (previewList.length > 0 && previewList[0].sectionList.length > 0) {
      return previewList.map((item, idx) => (
        <SectionAccordion
          defaultIndex={defaultIndex}
          checkedType={checkedType}
          item={item}
          key={`accordion-list${idx}`}
          onSelected={(item) => {
            setItem(item)
          }}
        />
      ))
    } else if (previewList.length > 0 && previewList[0].sectionList.length === 0) {
      return (
        <View style={styles.blank}>
          <Text style={styles.blankText}>{t('nocontentsicon.search.text')}</Text>
        </View>
      )
    } else {
      return <></>
    }
  }, [previewList])

  return (
    <Combobox visible={true} width={width ? width : 280}>
      <Combobox.Header style={styles.comboHeaderStyle}>
        <SearchTextField
          value={searchKeyword}
          placeholder={t('userselected.placeholder')}
          onChangeText={setSearchKeyword}
          onPressSubmit={() => {
            console.log('검색')
          }}
          autoFocus={true}
        />
      </Combobox.Header>
      <Combobox.Body>
        <View style={{ maxHeight: bodyHeight }}>{body}</View>
      </Combobox.Body>
      <Combobox.Footer>
        <View style={styles.comboFooterStyle}>
          <Button type={'SECONDARYWHITE'} size={'sm'} text={t('common.cancel')} onPress={onPressCancel} />
          <Button size={'sm'} text={t('setting.button.apply')} onPress={() => onPressSave?.(item)} />
        </View>
      </Combobox.Footer>
    </Combobox>
  )
}

export default SelectorWork
export type { SelectorWorkType }

const styles = StyleSheet.create({
  comboHeaderStyle: {
    padding: 8,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g100,
  },
  comboFooterStyle: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 8,
    borderTopWidth: 1,
    borderColor: COLOR.gray.g200,
  },
  blank: {
    minHeight: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
  blankText: {
    ...FONT.txtSm,
    color: COLOR.gray.g500,
  },
})
