import { Icon } from '@rocket-atoms/icon'
import { FileSourceType } from '@rocket-mono/libs'
import { COLOR } from '@rocket-mono/libs'
import React from 'react'
import { StyleSheet, View } from 'react-native'

const XEIcon = Icon

interface Props {
  type?: string | undefined | false
  label?: FileSourceType
}

export const FileSourceLabel: React.FC<Props> = ({ type, label }) => {
  return (
    <View
      style={[
        styles.icon,
        type === 'small' && styles.iconSmall,

        label === 'MESSAGE' && { backgroundColor: COLOR.main.red },
        label === 'MISSION' && { backgroundColor: COLOR.main.turquoise },
        label === 'MEETING' && { backgroundColor: COLOR.main.yellow },
        label === 'DISCUSSION' && { backgroundColor: COLOR.main.darkNavy },
        label === 'GATHERING' && { backgroundColor: COLOR.sub.purple },
      ]}
    >
      {label === 'MESSAGE' && <XEIcon name="message-o" size={14} color={'mono.white'} />}
      {label === 'MISSION' && <XEIcon name="check-square-o" size={14} color={'mono.white'} />}
      {label === 'MEETING' && <XEIcon name="calendar" size={14} color={'mono.white'} />}
      {label === 'DISCUSSION' && <XEIcon name="forum-o" size={14} color={'mono.white'} />}
      {label === 'GATHERING' && <XEIcon name="folder-check-o" size={14} color={'mono.white'} />}
    </View>
  )
}

const styles = StyleSheet.create({
  icon: {
    position: 'absolute',
    marginRight: 1,
    marginBottom: 1,
    bottom: 0,
    right: 0,
    padding: 4,
    paddingRight: 6,
    paddingBottom: 6,
    backgroundColor: '#FF5A5A',
    borderTopLeftRadius: 7,
    borderBottomRightRadius: 17,
  },
  iconSmall: {
    padding: 2,
    paddingRight: 4,
    paddingBottom: 4,
    borderBottomRightRadius: 12,
  },
})
