import React from 'react'
import { View } from 'react-native'
import type { CardCollectionType, CardLinkEntry } from '@rocket/types'
import CardItem, { CardLinkParams } from './item'
import { CardGatheringItemType, FileItemType } from '../CardGatheringItem'
import { CardCollectionTargetOption } from '@rocket/types'

export interface CardGatheringListType {
  id: string
  type: CardCollectionType
  targetOption: CardCollectionTargetOption
  disabled: boolean
  title: string
  items: CardGatheringItemType[]
}

interface Props {
  isAnonymous: boolean
  onPressAnonymous: () => void
  list: CardGatheringListType[]
  onPressDownload: (file: FileItemType) => void
  onPressCardLink?: (params: CardLinkParams) => void
  onPressMention?: () => void
}

const CardGathering: React.FC<Props> = ({ list, ...itemProps }) => {
  console.log('gathering-list', list)

  return (
    <View>
      {list.map((item, idx) => (
        <View key={`gathering-card${idx}`} style={{ marginBottom: 8 }}>
          <CardItem item={item} type={item.type} {...itemProps} />
        </View>
      ))}
    </View>
  )
}

export default CardGathering
