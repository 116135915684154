import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TextInput, View } from 'react-native'
import { AcknowledgeButton } from './AcknowledgeButton'
import { AcknowledgeTermButton } from './AcknowledgeTermButton'
import { Text } from '@rocket-atoms/text'
import Button from '../Button'

interface Props {
  whenPressSignUp: (email: string, password: string, name: string) => void
  onTerms?: () => void
  onPrivacy?: () => void
}

export const SignupForm: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const [termsAgreed, setTermsAgreed] = useState<boolean>(false)
  const [privacyAgreed, setPrivacyAgreed] = useState<boolean>(false)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')

  const allAgreed = useCallback(() => {
    return termsAgreed && privacyAgreed
  }, [privacyAgreed, termsAgreed])

  const doEmailSignup = () => {
    if (allAgreed()) {
      props.whenPressSignUp(email, password, name)
    } else {
      console.error('You must agree to the terms and privacy policy')
    }
  }

  return (
    <>
      <View style={{ height: 26 }} />
      <View style={style.inputWrap}>
        <Text fontName="subTextRegular" fontColor="mono.paleBlack" style={[style.heading, style.textCenter]}>
          {t(`pro.signup.email`)}
        </Text>
      </View>

      <View style={style.inputWrap}>
        <TextInput
          style={{ height: 34 }}
          textContentType="emailAddress"
          onChangeText={(text) => setEmail(text)}
          placeholder={t(`pro.signup.enteremail`)}
        />
      </View>

      <View style={style.inputWrap}>
        <TextInput
          style={{ height: 34 }}
          textContentType="password"
          secureTextEntry={true}
          onChangeText={(text) => setPassword(text)}
          placeholder={t(`pro.signup.password`)}
        />
      </View>

      <View style={style.inputWrap}>
        <TextInput
          style={{ height: 34 }}
          textContentType="password"
          secureTextEntry={true}
          placeholder={t(`pro.signup.confirmpassword`)}
        />
      </View>

      <View style={style.inputWrap}>
        <TextInput style={{ height: 34 }} onChangeText={(text) => setName(text)} placeholder={t(`pro.signup.name`)} />
      </View>
      <View style={{ height: 24 }} />
      <Text style={[style.heading]}>{t(`pro.signup.asktoagree`)}</Text>
      <AcknowledgeButton
        bordered={true}
        opaqueIcon={true}
        text={t(`pro.signup.agreeall`)}
        checked={allAgreed()}
        onPress={() => {
          if (!termsAgreed || !privacyAgreed) {
            setTermsAgreed(true)
            setPrivacyAgreed(true)
          } else {
            setTermsAgreed(false)
            setPrivacyAgreed(false)
          }
        }}
      />

      <View>
        <AcknowledgeTermButton
          text={t(`pro.signup.rocketterms`)}
          underline={true}
          checked={termsAgreed}
          onText={props.onTerms}
          onPress={() => {
            setTermsAgreed((prev) => !prev)
          }}
        />
      </View>

      <View>
        <AcknowledgeTermButton
          text={t(`pro.signup.privacyterms`)}
          underline={true}
          checked={privacyAgreed}
          onText={props.onPrivacy}
          onPress={() => {
            setPrivacyAgreed((prev) => !prev)
          }}
        />
      </View>

      <View style={style.inputWrap}>
        <Button
          wide={true}
          disabled={!allAgreed()}
          onPress={() => {
            doEmailSignup()
          }}
        >
          {t(`signup.signup`)}
        </Button>
      </View>
    </>
  )
}

const style = StyleSheet.create({
  heading: {
    marginVertical: 5,
  },
  textCenter: {
    textAlign: 'center',
  },
  inputWrap: {
    marginVertical: 5,
  },
})
