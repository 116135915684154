import { useNavigate, useParams } from 'react-router-dom'
import ProjectMemberScreen from '@rocket-mono/screen-project-member'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const ProjectMember: React.FC = () => {
  const { i18n } = useTranslation()
  const { projectId } = useParams()
  const navigate = useNavigate()

  const handleProjectLeave = useCallback(() => {
    navigate('/')
  }, [])

  if (projectId === undefined) return <></>
  return <ProjectMemberScreen i18n={i18n} projectId={projectId} onProjectLeave={handleProjectLeave} />
}

export default ProjectMember
