import * as React from 'react'
import { Pressable, StyleSheet } from 'react-native'

import { View } from '@rocket-mono/foundations'
import { COLOR } from '@rocket-mono/libs'

import { Text, WorkIcon } from '@rocket/atoms'
import { LayoutSideNavWorkItemType } from '../types'

interface Props {
  workData: LayoutSideNavWorkItemType
  onPress?: (work: LayoutSideNavWorkItemType) => void
}

const WorkGroup = ({ workData, onPress }: Props) => {
  const [isHover, setIsHover] = React.useState(false)
  const isType = React.useMemo(() => {
    return workData.type === 'DSP' || workData.type === 'WFP' || workData.type === 'CSP' || workData.type === 'TOP'
  }, [workData])
  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      style={[styles.workItemContainer, isHover && { backgroundColor: COLOR.opacity.w10 }]}
      onPress={() => onPress && onPress(workData)}
    >
      {isType ? (
        <View style={styles.icon}>
          <WorkIcon type={workData.type} size={16} />
        </View>
      ) : (
        <></>
      )}

      <Text fontName="txtSm" fontColor="mono.white" numberOfLines={1} ellipsizeMode="tail" style={styles.itemText}>
        {workData.name}
      </Text>
    </Pressable>
  )
}

export default WorkGroup

const styles = StyleSheet.create({
  workItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 28,
    paddingVertical: 8,
    paddingRight: 12,
    zIndex: 0,
  },
  icon: {
    width: 16,
    height: 16,
  },
  itemText: {
    marginLeft: 4,
  },
})
