import { useContext } from 'react'
import { DiscussionContext, DiscussionProviderContext } from './discussionContext'

export function useDiscussionUser() {
  const context = useContext<DiscussionProviderContext | undefined>(DiscussionContext)

  if (context === undefined)
    throw new Error('There must be a DiscussionProvider as Ancestor of all Discussion Hooks and HOCs')

  return context.user
}

export default useDiscussionUser
