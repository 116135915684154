import React from 'react'
import { Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { COLOR } from '@rui/foundations'
import HoverIndication from './HoverIndication'

interface Props {
  style?: StyleProp<ViewStyle>
  hoverStyle?: StyleProp<ViewStyle>
  onPress?: () => void
  children: React.ReactNode
}

const Container: React.FC<Props> = ({ style, hoverStyle, onPress, children }) => {
  const [isHover, setIsHover] = React.useState(false)
  return (
    <Pressable
      onPress={onPress}
      onHoverIn={() => onPress !== undefined && setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      style={[
        styles.container,
        isHover === true && (hoverStyle === undefined ? { backgroundColor: COLOR.gray.g050 } : hoverStyle),
        style,
      ]}
    >
      {children}

      {isHover && <HoverIndication />}
    </Pressable>
  )
}

export default Container

const styles = StyleSheet.create({
  container: {},
})
