import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { COLOR } from '@rocket-mono/libs'
import { FAIcon, Text } from '@rocket/atoms'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, ScrollView, StyleSheet, View } from 'react-native'
import RuleStepView from '../../../components/RuleStepView'
import { useRule } from '../../hooks'

interface Props {
  onClose: () => void
  children: JSX.Element | JSX.Element[]
}

const CreateModalView: React.FC<Props> = ({ onClose, children }) => {
  const { t } = useTranslation()
  const { createStep, selectedBoard, selectedTrigger, selectedRule, selectedAction } = useRule()

  const title = React.useMemo(() => {
    if (createStep === 'APPLY-BOARD') {
      return t('rule.modal.target') // 'Applyboard'
    } else if (createStep === 'TRIGGERS') {
      return t('rule.modal.trigger') // 'Triggers'
    } else if (createStep === 'TRIGGERS-TIME') {
      return t('rule.modal.trigger') // 'Triggers'
    } else if (createStep === 'RULES') {
      return t('rule.modal.rule') // 'Rules'
    } else if (createStep === 'AUTOMATION') {
      return t('rule.modal.automation') // 'Automation'
    } else {
      return ''
    }
  }, [createStep])

  const active = React.useMemo(() => {
    let applyBoard = false
    let triggers = false
    let rules = false
    let action = false

    if (selectedBoard) {
      applyBoard = true
    }

    if (selectedTrigger && selectedTrigger.name) {
      triggers = true
    }

    if (selectedRule) {
      rules = true
    }

    if (selectedAction) {
      action = true
    }

    return { applyBoard, triggers, rules, action }
  }, [selectedBoard, selectedTrigger, selectedRule, selectedAction])

  const current = React.useMemo(() => {
    const applyBoard = createStep === 'APPLY-BOARD'
    const triggers = createStep === 'TRIGGERS' || createStep === 'TRIGGERS-TIME'
    const rules = createStep === 'RULES'
    const action = createStep === 'AUTOMATION'

    return { applyBoard, triggers, rules, action }
  }, [createStep])

  return (
    <>
      <View style={styles.backdrop} />
      <View style={styles.mobileContainer}>
        <View style={styles.mobileHeaderContainer}>
          <Pressable style={styles.btnClose} onPress={onClose}>
            <FAIcon iconName={faTimes} size="xl" color="gray.g450" />
          </Pressable>
          <Text fontName="txtMdMedium" fontColor="mono.realBlack">
            {title}
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <ScrollView style={{ flex: 1 }}>
            <View style={styles.stepperContainer}>
              <View style={styles.stepperView}>
                <RuleStepView active={active} current={current} />
              </View>
            </View>
            <View style={styles.bodyContainer}>{children}</View>
          </ScrollView>
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  backdrop: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    backgroundColor: COLOR.gray.g900,
    opacity: 0.4,
  },
  mobileContainer: {
    backgroundColor: COLOR.mono.white,
    marginTop: 50,
    flex: 1,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  mobileHeaderContainer: {
    height: 52,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomColor: COLOR.gray.g150,
    borderBottomWidth: 1,
  },
  btnClose: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 52,
    height: 52,
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepperContainer: {
    marginVertical: 10,
    alignItems: 'center',
  },
  stepperView: {
    width: 220,
    padding: 10,
  },
  bodyContainer: {
    paddingHorizontal: 16,
  },
})

export default CreateModalView
