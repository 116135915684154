import * as React from 'react'
import { Pressable, StyleSheet } from 'react-native'

import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { View } from '@rocket-mono/foundations'
import { COLOR } from '@rocket-mono/libs'
import { FAIcon, Text } from '@rocket/atoms'

export const WorkGroupSection = ({
  label,
  initIsShow,
  onPress,
}: {
  label: string
  initIsShow: boolean
  onPress: (isShow: boolean) => void
}) => {
  const [isTabHover, setIsTabHover] = React.useState(false)
  const [isShow, setIsShow] = React.useState(initIsShow)
  return (
    <Pressable
      onHoverIn={() => setIsTabHover(true)}
      onHoverOut={() => setIsTabHover(false)}
      onPress={() => {
        setIsShow(!isShow)
        onPress(!isShow)
      }}
      style={styles.groupTab}
    >
      <View style={styles.iconArea}>
        <FAIcon iconName={isShow ? faAngleUp : faAngleDown} size={'2xs'} color={'gray.g400'} />
      </View>
      <Text fontName="txt2Xs" fontColor={isTabHover ? 'gray.g300' : 'gray.g400'}>
        {label}
      </Text>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  iconArea: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 12,
    height: 12,
    marginRight: 8,
  },
  groupTab: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 4,
    paddingHorizontal: 8,
    backgroundColor: COLOR.darkmode.warm800,
    zIndex: 0,
  },
})
