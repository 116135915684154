import React, { useState, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { RNSvg } from '@rocket/atoms'
import { FAIcon } from '@rocket/atoms'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faStarOfLife, faBoltLightning } from '@fortawesome/free-solid-svg-icons'
import { COLOR, IColors, getRealColor } from '@rocket-mono/libs'
import type { RuleType } from './RuleStep'

const { Svg, Path } = RNSvg

interface Props{
  type: RuleType,
  active?: boolean
  size?: number
  native?: boolean
  current?: boolean
}
const RuleStepIcon = ({type, active, size, native, current} : Props) => {
  const [iconStyle, setIconStyle] = useState<{iconName:IconProp, color: IColors}>({iconName:faStarOfLife, color:'mono.white'})

  useEffect(() => {
    type === 'Applyboard' ? setIconStyle({iconName:faStarOfLife, color:'sub.green'}) :
    type === 'Triggers' ? setIconStyle({iconName:faStarOfLife, color:'primary.blue500'}) :
    type === 'Rules' ? setIconStyle({iconName:faBoltLightning, color:'sub.pink'}) :
    setIconStyle({iconName:faBoltLightning, color:'sub.purple'})
  },[type])

  return(
    <View style={[
      styles.container,
      active ? { backgroundColor: getRealColor(iconStyle.color) } : { backgroundColor: COLOR.gray.g300 },
      size ? { width:size, height:size } : {},
      native ? { position:'relative', width: 34, height: 34 } : {},
      native && !active ? styles.nativeNotActive : {}
    ]}>
      <View style={type !== 'Triggers' && { marginTop:1 }}> 
        {type === 'Applyboard' ?
          <Svg width={native? 16 : 14} height={native? 16 : 14} viewBox="0 0 20 20" fill="none">
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.0498 3.96052C2.0498 3.01583 2.6073 2.5 3.552 2.5H5.26252C6.20722 2.5 6.80536 3.01583 6.80536 3.96052V11.6579C6.80536 12.6026 6.20722 13.0889 5.26252 13.0889H3.552C2.6073 13.0889 2.0498 12.6026 2.0498 11.6579V3.96052Z"
              fill={native && !active ? '#B4BEC5' : '#fff'}
            />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.2637 3.96052C13.2637 3.01583 13.8212 2.5 14.7659 2.5H16.4764C17.4211 2.5 18.0192 3.01583 18.0192 3.96052V9.99122C18.0192 10.9359 17.4211 11.4222 16.4764 11.4222H14.7659C13.8212 11.4222 13.2637 10.9359 13.2637 9.99122V3.96052Z"
              fill={native && !active ? '#B4BEC5' : '#fff'}
            />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.63867 4.13254C7.63867 3.18785 8.25541 2.50537 9.20011 2.50537H10.9106C11.8553 2.50537 12.3942 3.18785 12.3942 4.13254V15.251C12.3942 16.1957 11.8553 16.797 10.9106 16.797H9.20011C8.25541 16.797 7.63867 16.1957 7.63867 15.251V4.13254Z"
              fill={native && !active ? '#B4BEC5' : '#fff'}
            />
          </Svg>
        :
          <FAIcon iconName={iconStyle.iconName} size={native ? 'xs' :'2xs'}  color={native && !active ? 'gray.g300' : 'mono.white'}/>
        }
      </View>
      { current && native &&
        <View style={[styles.activeBorder,{ backgroundColor: getRealColor(iconStyle.color)}]}/>}
    </View>
  )
}

export default RuleStepIcon

const styles = StyleSheet.create({
  container:{
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    borderRadius: 40,
  },
  activeBorder:{
    position: 'absolute',
    opacity: .1,
    width: 44,
    height: 44,
    borderRadius: 22,
    top: -5,
    left: -5,
  },
  nativeNotActive:{
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: COLOR.gray.g200,
  }
})