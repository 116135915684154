import type { Client, messageCallbackType, StompHeaders, StompSubscription } from '@stomp/stompjs'

export const stompHeaders = {
  'content-type': 'application/json',
}

export interface StompSessionSubscription {
  destination: string
  callback: messageCallbackType
  headers: StompHeaders
  subscription?: StompSubscription
}

export interface ContextProps {
  client?: Client
  subscribe: (destination: string, callback: messageCallbackType, headers?: StompHeaders) => () => void
}
