import React from 'react'

interface Props {
  filteredList
  defaultCount
  searchState
  children
}

export const SearchResultSkeleton: React.FC<Props> = ({ defaultCount, searchState, filteredList, children }) => {
  return (
    <>
      {filteredList.length < 2 && (defaultCount === undefined || defaultCount > 2) && children}
      {filteredList.length < 1 && children}
      {children}
    </>
  )
}
