import { COLOR } from '@rocket-mono/libs'
import { ClickOutside } from '@rui/foundations'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, View } from 'react-native'
import LabelInput from '../LabelInput'
import DatePicker from './DatePicker'
import { DatePickerModal, Props } from './types'

// const MODAL_MAX_HEIGHT = 124

const initialDate = (date?: Date) => {
  if (!date) return undefined
  return new Date(date)
}

const WorkCardTodoDateModalView = ({
  initialStartDate,
  initialEndDate,
  initialIsFromTime,
  initialIsToTime,
  onStartDateChange,
  onEndDateChange,
  onIsTimeChange,
  onSave,
  onClose,
}: Props) => {
  const { t } = useTranslation()
  const [startDate, setStartDate] = React.useState<Date | undefined>(initialDate(initialStartDate))
  const [endDate, setEndDate] = React.useState<Date | undefined>(initialDate(initialEndDate))

  const [isFromTime, setIsFromTime] = React.useState(initialIsFromTime)
  const [isToTime, setIsToTime] = React.useState(initialIsToTime)

  const [datePickerModal, setDatePickerModal] = React.useState<DatePickerModal>()

  const startDateValue = React.useMemo(() => {
    const dateList: string[] = []
    if (startDate) {
      dateList.push(t('format.date.L', { date: startDate }))
      if (isFromTime) {
        dateList.push(' ')
        dateList.push(t('format.date.T', { date: startDate }))
      }
    }
    return dateList
  }, [startDate, isFromTime])

  const endDateValue = React.useMemo(() => {
    const dateList: string[] = []
    if (endDate) {
      dateList.push(t('format.date.L', { date: endDate }))
      if (isToTime) {
        dateList.push(' ')
        dateList.push(t('format.date.T', { date: endDate }))
      }
    }
    return dateList
  }, [endDate, isToTime])

  const handleClose = () => {
    onClose()
    onSave && onSave(startDate, endDate, isFromTime, isToTime)
  }

  return (
    <ClickOutside
      onClickOutside={() => {
        if (!datePickerModal) {
          handleClose()
        }
      }}
    >
      <View>
        <Pressable style={styles.backdrop} onPress={handleClose} />
        <View style={[styles.container]}>
          <LabelInput
            border
            labelIcon="calendar"
            labelText={t('workcardtododatemodal.startdate')}
            placeholder={t('workcardtododatemodal.select')}
            dateValue={startDateValue}
            height={52}
            onPress={() => {
              const initialStart = startDate ? t('format.date.L', { date: startDate }) : undefined
              const initialTime = startDate ? t('format.date.T', { date: startDate }) : undefined
              setDatePickerModal({
                type: 'start',
                initialStart,
                initialTime,
                initialIsTime: isFromTime,
                maxDate: endDate,
              })
            }}
          />
          <View style={styles.dir} />
          <LabelInput
            border
            labelIcon="calendar"
            labelText={t('workcardtododatemodal.enddate')}
            placeholder={t('workcardtododatemodal.select')}
            dateValue={endDateValue}
            height={52}
            onPress={() => {
              const initialStart = endDate ? t('format.date.L', { date: endDate }) : undefined
              const initialTime = endDate ? t('format.date.T', { date: endDate }) : undefined
              setDatePickerModal({
                type: 'end',
                initialStart,
                initialTime,
                initialIsTime: isToTime,
                minDate: startDate,
              })
            }}
          />
          {datePickerModal ? (
            <DatePicker
              type={datePickerModal.type}
              initialStart={datePickerModal.initialStart}
              initialTime={datePickerModal.initialTime}
              initialIsTime={datePickerModal.initialIsTime}
              minDate={datePickerModal.minDate}
              maxDate={datePickerModal.maxDate}
              onClose={() => setDatePickerModal(undefined)}
              onChangeStart={(date) => {
                if (date) {
                  if (datePickerModal.type === 'start') {
                    const $date = t('format.date.L', { date })
                    const $time = startDate ? t('format.date.T', { date: startDate }) : '12:00'
                    setStartDate(new Date(`${$date} ${$time}`))
                    onStartDateChange && onStartDateChange(new Date(`${$date} ${$time}`))
                  } else if (datePickerModal.type === 'end') {
                    const $date = t('format.date.L', { date })
                    const $time = endDate ? t('format.date.T', { date: endDate }) : '12:00'
                    setEndDate(new Date(`${$date} ${$time}`))
                    onEndDateChange && onEndDateChange(new Date(`${$date} ${$time}`))
                  }
                }
              }}
              onChangeTime={(time) => {
                if (time) {
                  if (datePickerModal.type === 'start') {
                    if (startDate) {
                      const $date = t('format.date.L', {
                        date: startDate,
                      })
                      const $time = t('format.date.T', { date: time })
                      setStartDate(new Date(`${$date} ${$time}`))
                      onStartDateChange && onStartDateChange(new Date(`${$date} ${$time}`))
                    }
                  } else if (datePickerModal.type === 'end') {
                    if (endDate) {
                      const $date = t('format.date.L', { date: endDate })
                      const $time = t('format.date.T', { date: time })
                      setEndDate(new Date(`${$date} ${$time}`))
                      onEndDateChange && onEndDateChange(new Date(`${$date} ${$time}`))
                    }
                  }
                }
              }}
              onChangeIsTime={(isTime) => {
                if (datePickerModal.type === 'start') {
                  setIsFromTime(isTime)
                  onIsTimeChange && onIsTimeChange(isTime, isToTime)
                } else if (datePickerModal.type === 'end') {
                  setIsToTime(isTime)
                  onIsTimeChange && onIsTimeChange(isFromTime, isTime)
                }
              }}
              onDelete={() => {
                if (datePickerModal.type === 'start') {
                  setStartDate(undefined)
                } else if (datePickerModal.type === 'end') {
                  setEndDate(undefined)
                }
                setDatePickerModal(undefined)
              }}
            />
          ) : (
            <></>
          )}
        </View>
      </View>
    </ClickOutside>
  )
}

export default WorkCardTodoDateModalView

const styles = StyleSheet.create({
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  container: {
    width: 346,
    height: 124,
    padding: 8,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    borderRadius: 4,
    backgroundColor: COLOR.mono.white,
  },
  dir: {
    height: 4,
  },
})
