import { Combobox, FileField } from '@rui/molecules'
import { Platform, StyleSheet, View } from 'react-native'
import FileItem from './FileItem'

import { COLOR, FileSourceType, removeItemAtIndex } from '@rocket-mono/libs'
import { useAstro, useCurrentUser } from '@rocket-mono/providers'
import { Legacy } from '@rocket/types'
import { ClickOutside } from '@rui/foundations'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardRowGroup } from './CardRowGroup'

const EXT_MAP = {
  doc: ['txt', 'doc', 'hwp', 'mdj'],
  ppt: ['ppt', 'pptx'],
  xls: ['xls', 'xlsx'],
  zip: ['zip', 'rar', 'alz', '7z'],
  pdf: ['pdf'],
}

export type FileType = {
  no?: string
  file?: Legacy.LegacyCardFile
  uri: string
  type: string
  name: string
  size: number
  userName?: string
  regDate?: Date
  blob?: File
}

interface Props {
  title: string
  fileList: FileType[]
  // fileSource: 'MESSAGE' | 'MISSION' | 'MEETING' | 'DISCUSSION' | 'GATHERING' | undefined
  fileSource: FileSourceType
  onChangeFilelist: (fileList: FileType[]) => void
  addDeleteFilelist: (id: string) => void
}
const FileUploadView: React.FC<Props> = ({ title, fileList, fileSource, onChangeFilelist, addDeleteFilelist }) => {
  const { t } = useTranslation()
  const { astro } = useAstro()
  const { currentUser } = useCurrentUser()

  const [vislbleIdx, setVisibleIdx] = useState<number | null>(null)

  const fileType = useCallback((mimeType?: string, extension?: string) => {
    if (!mimeType) return ''
    if (!extension) return ''
    if (mimeType.startsWith('image')) {
      return 'preview'
    } else if (EXT_MAP.doc.includes(extension)) {
      return 'doc'
    } else if (EXT_MAP.ppt.includes(extension)) {
      return 'ppt'
    } else if (EXT_MAP.xls.includes(extension)) {
      return 'xls'
    } else if (mimeType.startsWith('video')) {
      return 'video'
    } else if (EXT_MAP.zip.includes(extension)) {
      return 'zip'
    } else if (EXT_MAP.pdf.includes(extension)) {
      return 'pdf'
    } else {
      return extension
    }
  }, [])

  const list = useMemo(() => {
    return fileList.map((fileItem) => {
      const { file, uri, type, name } = fileItem
      const ext = name.split('.').pop()
      return {
        small: false,
        access: true,
        border: true,
        fileUrl: uri,
        fileType: fileType(type, ext),
        fileName: fileItem.name,
        fileSize: fileItem.size,
        fileSource: fileSource,
        date: moment(fileItem.regDate).format('YYYY-MM-DD'),
        uploader: fileItem.userName || '',
        download: file !== undefined,
        onPressItem: () => {},
        onPressDownload:
          file !== undefined
            ? () => {
                const { fileKey, filename, originalFilename } = file
                astro.fileDownload({ fileKey, filename, originalFilename })
              }
            : undefined,
      }
    })
  }, [fileList])

  const handlePressEtc = useCallback((idx: number) => {
    setVisibleIdx((prev) => {
      if (prev === idx) return null
      return idx
    })
  }, [])

  const handleDelete = useCallback(
    (idx) => {
      const file = fileList[idx]

      if (file) {
        if (file.no !== undefined) {
          addDeleteFilelist(file.no)
        }
        onChangeFilelist(removeItemAtIndex(fileList, idx))
      }
    },
    [fileList],
  )

  const handleLoad = useCallback(
    (list) => {
      const files: FileType[] = []
      for (let i = 0; i < list.length; i++) {
        const blob = list.item(i)
        if (blob) {
          const { name, size, type } = blob
          const uri = URL.createObjectURL(blob)
          files.push({ name, size, type, uri, regDate: new Date(), userName: currentUser.userName, blob })
        }
      }
      onChangeFilelist([...files, ...fileList])
    },
    [fileList],
  )

  return (
    <CardRowGroup
      title={title}
      iconName="attachment"
      iconContainerStyle={{
        alignItems: 'stretch',
      }}
      containerStyle={Platform.select({
        native: {
          height: 110,
        },
      })}
    >
      <View
        style={{
          flex: 1,
          ...Platform.select({
            native: {
              height: 85,
            },
          }),
        }}
      >
        {/* <FileUploader
          list={}
          onLoad={(list) => {
            const files: FileType[] = []
            for (let i = 0; i < list.length; i++) {
              const blob = list.item(i)
              if (blob) {
                const { name, size, type } = blob
                const uri = URL.createObjectURL(blob)
                files.push({ name, size, type, uri, regDate: new Date(), userName: currentUser.userName, blob })
              }
            }
            onChangeFilelist([...files, ...fileList])
          }}
        /> */}
        <View style={styles.container}>
          <View style={styles.fileUploaderArea}>
            <FileField
              size="SMALL"
              progress={0}
              multiple
              // onPress={onPress}
              onLoadFile={handleLoad}
              onDropFile={handleLoad}
              onPasteFile={handleLoad}
            />
          </View>
          <View>
            {vislbleIdx !== null && (
              <ClickOutside onClickOutside={() => setVisibleIdx(null)}>
                <View style={{ top: vislbleIdx * 84 + 38, right: 16, zIndex: 14, position: 'absolute' }}>
                  <Combobox visible>
                    <Combobox.Body>
                      <Combobox.Item
                        text={t('cardauthority.delete')}
                        onPress={() => {
                          handleDelete(vislbleIdx)
                          setVisibleIdx(null)
                        }}
                      />
                    </Combobox.Body>
                  </Combobox>
                </View>
              </ClickOutside>
            )}
            {list.map((item, key) => (
              <View style={styles.itemWrap} key={`fileItem-${key}`}>
                <FileItem {...item} onPressEtc={() => handlePressEtc(key)} />
              </View>
            ))}
          </View>
        </View>
      </View>
    </CardRowGroup>
  )
}

export default FileUploadView

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    backgroundColor: COLOR.mono.white,
    borderRadius: 4,
    padding: 12,
  },
  itemWrap: {
    paddingTop: 8,
  },
  fileUploaderArea: {
    minHeight: 60,
  },
})
