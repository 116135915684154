import React, { useCallback, useEffect, useState } from 'react'
import { LayoutChangeEvent, LayoutRectangle, View } from 'react-native'

import { COLOR } from '@rui/foundations'

import SearchIcon from '../components/SearchIcon'
import { SearchViewProps } from './types'
import { useSearchScreen } from './provider'
import { SearchFieldView } from './parts/SearchFieldView'
import { SearchResultCategoryView } from './parts/SearchResultCategoryView'
import {
  SearchResultCardView,
  SearchResultChatView,
  SearchResultFileView,
  SearchResultMessageView,
  SearchResultView,
  SearchResultWorkView,
} from './parts/SearchResultView'
import { useTranslation } from 'react-i18next'

export const SearchView: React.FC<SearchViewProps> = ({ bottomSheetOffset }) => {
  const { t } = useTranslation()
  const {
    tab,
    noContents,
    searchCount,
    searchAllState,
    searchWorkState,
    searchWorkList,
    searchChatState,
    searchChatList,
    searchMessageState,
    searchMessageList,
    searchCardState,
    searchCardList,
    searchFileState,
    searchFileList,
  } = useSearchScreen()

  return (
    <View style={{ flex: 1 }}>
      <SearchFieldView bottomSheetOffset={bottomSheetOffset} />
      <SearchResultCategoryView
        countWork={searchCount.work}
        countBoard={searchCount.chat}
        countMessage={searchCount.message}
        countCard={searchCount.card}
        countFile={searchCount.file}
      />

      <View style={{ flex: 1, borderTopWidth: 4, borderColor: COLOR.gray.g100 }}>
        {searchAllState === 'ready' && (
          <View style={{ flex: 1, paddingTop: 136 }}>
            <SearchIcon type={'searching'} textSize={'medium'} />
          </View>
        )}
        {tab === 'ALL' && ['done', 'searching'].includes(searchAllState) && (
          <>
            {noContents ? (
              <View style={{ flex: 1, paddingTop: 136 }}>
                <SearchIcon type={'all'} text={t('nocontentsicon.search.text')} />
              </View>
            ) : (
              <SearchResultView
                defaultCount={2}
                searchState={searchAllState}
                workList={searchWorkList}
                chatList={searchChatList}
                messageList={searchMessageList}
                cardList={searchCardList}
                fileList={searchFileList}
              />
            )}
          </>
        )}

        {['done', 'searching', 'last'].includes(searchAllState) && (
          <>
            {tab === 'PROJECT' && <SearchResultWorkView isFilter searchState={searchWorkState} list={searchWorkList} />}
            {tab === 'BOARD' && <SearchResultChatView isFilter searchState={searchChatState} list={searchChatList} />}
            {tab === 'CHAT_MESSAGE' && (
              <SearchResultMessageView isFilter searchState={searchMessageState} list={searchMessageList} />
            )}
            {tab === 'CARD' && <SearchResultCardView isFilter searchState={searchCardState} list={searchCardList} />}
            {tab === 'FILE' && <SearchResultFileView isFilter searchState={searchFileState} list={searchFileList} />}
          </>
        )}
      </View>
    </View>
  )
}
