import { Text } from '@rocket-atoms/text'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, View } from 'react-native'
import Checkbox from '../Checkbox'
import OptionButton from './OptionButton'

interface Props {
  selectedItems: string[]
  totalItems?: number
  onCheckAll: () => void
  onCopy: () => void
  onDelete: () => void
  onMove?: () => void
  onNewCard?: () => void
}

const WorkCardOptionHeader: React.FC<Props> = ({
  selectedItems,
  totalItems,
  onCheckAll,
  onCopy,
  onDelete,
  onMove,
  onNewCard,
}) => {
  const { t } = useTranslation()
  return (
    <View style={styles.container}>
      <View style={styles.checkContainer}>
        <Pressable onPress={() => onCheckAll()}>
          <Checkbox
            checked={typeof totalItems !== 'undefined' ? selectedItems.length === totalItems : false}
            containerSize={18}
            size={14}
            type="TODO"
          />
        </Pressable>
      </View>
      <View style={styles.bodyContainer}>
        <Text fontName="txtXs" fontColor="mono.paleBlack">
          {t('workcardoptionheader.selecteditem', {
            count: selectedItems.length,
          })}
        </Text>
        <View style={styles.buttonGroup}>
          <OptionButton iconName={'library-books-o'} label={t('workcardoptionheader.copy')} onPress={onCopy} />
          <OptionButton iconName={'trash-o'} label={t('workcardoptionheader.delete')} onPress={onDelete} />
          {onMove ? <OptionButton iconName={'share'} label={t('workcardoptionheader.move')} onPress={onMove} /> : <></>}
          {onNewCard ? (
            <OptionButton iconName={'library-books-o'} label={t('workcardoptionheader.newcard')} onPress={onNewCard} />
          ) : (
            <></>
          )}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  checkContainer: {
    width: 14,
    height: 14,
  },
  bodyContainer: {
    marginLeft: 11,
  },
  buttonGroup: {
    flexDirection: 'row',
    marginTop: 4,
  },
})

export default WorkCardOptionHeader
