import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, View } from 'react-native'

import { Button, TextField } from '@rocket/atoms'
import { Icon } from '@rocket-atoms/icon'
 
import { COLOR, FONT } from '@rocket-mono/libs'
import LanguageSelect, { SelectListType } from '../LanguageSelect'
import { SnsType, SocialJoinType } from '../types'
import { TermsHandler } from './TermsHandler'

import { SocialJoinPayload, SocialLoginPayload } from '@rocket/types'
import AuthButton from '../components/AuthButton'

interface Props {
  invitedEmail?: string
  socialList: SocialJoinType[]
  languageList: SelectListType[]
  snsSelect?: 'GOOGLE' | 'NAVER' | 'KAKAO' | 'FACEBOOK' | 'APPLE'
  onSocialPress: (data: SocialLoginPayload | SocialJoinPayload, snsType: SnsType) => void
  onSignUpPress: (email: string, password: string, name: string) => void
  onLanguagePress: (lang: string) => void
  onNavigatePress: (type: string) => void
  onTermsPress: (type: string) => void
}

const SignUpView = ({
  invitedEmail,
  socialList,
  languageList,
  snsSelect,
  onSocialPress,
  onSignUpPress,
  onLanguagePress,
  onNavigatePress,
  onTermsPress,
}: Props) => {
  const { t } = useTranslation()

  const [isLanguageOpen, setIsLanguageOpen] = React.useState(false)
  const [allTermsCheck, setAllTermsCheck] = React.useState(false)

  // const [emailDisabled, setEmailDisabled] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [name, setName] = React.useState('')

  const [errorMessage, setErrorMessage] = React.useState<{
    email?: string
    password?: string
    confirmPassword?: string
    name?: string
  }>({})

  React.useEffect(() => {
    if (invitedEmail) {
      setEmail(invitedEmail)
    }
  }, [invitedEmail])

  const invalid = () => {
    let is = false

    if (!email) {
      setErrorMessage((prev) => ({ ...prev, email: t('pro.signup.error.enteremail') }))
      is = true
    } else {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
      if (!emailRegex.test(email)) {
        setErrorMessage((prev) => ({ ...prev, email: t('pro.signup.error.invalidemail') }))
        is = true
      } else {
        setErrorMessage((prev) => ({ ...prev, email: '' }))
      }
    }
    if (!password) {
      setErrorMessage((prev) => ({ ...prev, password: t('pro.signup.error.enterpassword') }))
      is = true
    } else {
      setErrorMessage((prev) => ({ ...prev, password: '' }))
    }
    if (!confirmPassword) {
      setErrorMessage((prev) => ({ ...prev, confirmPassword: t('pro.signup.error.enterconfirmpassword') }))
      is = true
    } else {
      setErrorMessage((prev) => ({ ...prev, confirmPassword: '' }))
    }
    if (!name) {
      setErrorMessage((prev) => ({ ...prev, name: t('pro.signup.error.entername') }))
      is = true
    } else {
      setErrorMessage((prev) => ({ ...prev, name: '' }))
    }
    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        setErrorMessage((prev) => ({ ...prev, confirmPassword: t('pro.signup.error.passwordnotmatch') }))
        is = true
      } else {
        setErrorMessage((prev) => ({ ...prev, confirmPassword: '' }))
      }
    }

    return is
  }

  return (
    <View>
      <Text style={styles.titleText}>Join us</Text>
      <View style={styles.selectArea}>
        <LanguageSelect
          list={languageList}
          isOpen={isLanguageOpen}
          setIsOpen={setIsLanguageOpen}
          onLanguagePress={onLanguagePress}
        />
      </View>
      <View>
        <View style={styles.labelStyle}>
          <Text style={{ color: COLOR.gray.g500 }}>{t('pro.signup.label')}</Text>
        </View>
        <View style={styles.snsArea}>
          {socialList.slice(0, 2).map(({ provider, onProc }, idx) => {
            return (
              <AuthButton 
                key={idx}
                provider={provider}
                onPress={() => {
                  onProc().then((data) => {
                    onSocialPress(data, provider)
                  })
                }}
              />
            )
          })}
        </View>
        <View>
          {!snsSelect && (
            <>
              <View style={styles.emailLoginButton}>
                <Text style={{ color: COLOR.gray.g500 }}>{t('pro.signup.email')}</Text>
              </View>
              <View style={styles.formGroup}>
                <View style={styles.formInline}>
                  <TextField
                    // disabled={emailDisabled}
                    value={email}
                    placeholder={t('pro.signup.enteremail')}
                    onChangeValue={(text) => setEmail(text)}
                  />
                  {errorMessage.email ? (
                    <View style={styles.errorMessageContainer}>
                      <Icon name="info" color="status.busy" size="16" />
                      <Text style={styles.errorMessage}>{errorMessage.email}</Text>
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
                <View style={styles.formInline}>
                  <TextField
                    value={password}
                    secureTextEntry={true}
                    placeholder={t('pro.signup.password')}
                    onChangeValue={(text) => setPassword(text)}
                  />
                  {errorMessage.password ? (
                    <View style={styles.errorMessageContainer}>
                      <Icon name="info" color="status.busy" size="16" />
                      <Text style={styles.errorMessage}>{errorMessage.password}</Text>
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
                <View style={styles.formInline}>
                  <TextField
                    value={confirmPassword}
                    secureTextEntry={true}
                    placeholder={t('pro.signup.confirmpassword')}
                    onChangeValue={(text) => setConfirmPassword(text)}
                  />
                  {errorMessage.confirmPassword ? (
                    <View style={styles.errorMessageContainer}>
                      <Icon name="info" color="status.busy" size="16" />
                      <Text style={styles.errorMessage}>{errorMessage.confirmPassword}</Text>
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
                <View style={styles.formInline}>
                  <TextField value={name} placeholder={t('pro.signup.name')} onChangeValue={(text) => setName(text)} />
                  {errorMessage.name ? (
                    <View style={styles.errorMessageContainer}>
                      <Icon name="info" color="status.busy" size="16" />
                      <Text style={styles.errorMessage}>{errorMessage.name}</Text>
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
              </View>
            </>
          )}
          <View style={styles.formGroup}>
            <Text style={{ color: COLOR.gray.g500 }}>{t('pro.signup.asktoagree')}</Text>
            <View style={{ marginTop: 8 }}>
              <TermsHandler
                onTermsPress={onTermsPress}
                allTermsCheck={allTermsCheck}
                setAllTermsCheck={setAllTermsCheck}
              />
            </View>
            <Button
              text={t('signup.signup')}
              disabled={allTermsCheck ? false : true}
              onPress={() => {
                if (snsSelect) {
                  onSignUpPress('', '', '')
                } else {
                  if (invalid()) return
                  onSignUpPress(email, password, name)
                }
              }}
            />
          </View>
        </View>
        <View style={styles.labelStyle}>
          <Text style={styles.metaText}>
            <Pressable onPress={() => onNavigatePress('login')}>
              <Text style={[styles.metaText, { color: COLOR.primary.blue500, marginLeft: 4, marginTop: 10 }]}>
                {t('pro.signup.login')}
              </Text>
            </Pressable>{' '}
            {t('pro.signup.goback')}
          </Text>
        </View>
      </View>
    </View>
  )
}

export default SignUpView

const styles = StyleSheet.create({
  titleText: {
    ...FONT.H4Bold,
  },
  selectArea: {
    marginTop: 16,
    marginBottom: 8,
    zIndex: 999,
    backgroundColor: COLOR.mono.white,
  },
  snsArea: {
    marginBottom: 4,
  },
  snsButton: {
    marginHorizontal: 6,
  },
  emailLoginButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 4,
    paddingVertical: 12,
  },
  formGroup: {
    position: 'relative',
    paddingBottom: 20,
  },
  formInline: {
    marginBottom: 8,
  },
  labelStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 8,
  },
  signUpArea: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  metaText: {
    ...FONT.txtSm,
    color: COLOR.gray.g700,
  },
  termsText: {
    ...FONT.txtXs,
    color: COLOR.gray.g500,
    textDecorationLine: 'underline',
  },
  termsInline: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 12,
    paddingRight: 8,
  },
  buttonIconArea: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
  },
  errorMessageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 3,
  },
  errorMessage: {
    ...FONT.txtXsMedium,
    color: COLOR.status.busy,
  },
})
