import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Icon } from '@rocket-atoms/icon'
import type { IconName } from '@rocket-mono/libs'
import { COLOR, getRealColor } from '@rocket-mono/libs'
import React from 'react'
import { Pressable, StyleSheet } from 'react-native'

interface Props {
  fontawesomeIcon?: IconProp
  iconName?: IconName
  onPress?: () => void
  border?: boolean
}

const IconCircleButton = ({ fontawesomeIcon, iconName, onPress, border }: Props) => {
  const [isHover, setIsHover] = React.useState(false)
  return (
    <Pressable
      style={[
        styles.circle,
        border && styles.borderCircle,
        isHover && { backgroundColor: COLOR.gray.g100 },
        border && isHover && { backgroundColor: COLOR.gray.g050 },
      ]}
      onPress={onPress}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
    >
      {fontawesomeIcon && <FontAwesomeIcon icon={fontawesomeIcon} size={13} color={getRealColor('gray.g600')} />}
      {iconName && <Icon name={iconName} size={16} color={'gray.g600'} style={{ marginTop: -2 }} />}
    </Pressable>
  )
}

export default IconCircleButton

const styles = StyleSheet.create({
  circle: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 28,
    backgroundColor: COLOR.gray.g050,
    borderRadius: 50,
  },
  borderCircle: {
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderColor: COLOR.gray.g150,
  },
})
