import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FlatList, ScrollView, View } from 'react-native'

import {
  ActivitySearchList,
  ActivitySearchItemChat,
  ActivitySearchSectionSortChatlist,
  ActivitySearchItemChatProps,
} from '@rocket-molecules/activity'

import { ResultViewProps } from './types'
import { SearchResultSkeleton } from './skeleton'
import { useTranslation } from 'react-i18next'
import { useSearchScreen } from '../../provider'
import SearchIcon from '../../../components/SearchIcon'
import { IndexedBoardType } from '../../types'

interface Props extends ResultViewProps {
  list?: IndexedBoardType[]
}

export const SearchResultChatView: React.FC<Props> = ({ defaultCount, searchState, isFilter = false, list = [] }) => {
  const { t } = useTranslation()
  const { onSearchNext, onChangeTab, openChat, onChangeSearchCount } = useSearchScreen()

  const convert = useCallback((value: IndexedBoardType): ActivitySearchItemChatProps => {
    let type: 'BOARD' | 'DIRECT' | 'DISCUSS' = 'BOARD'
    if (value.roomType === 'D') type = 'DIRECT'
    if (value.isDiscusstionRoom) type = 'DISCUSS'

    return {
      work: { type: value.projectTypeCode, name: value.projectTitle },
      board: { color: value.color, count: value.attenderCount, name: value.roomName, type },
      archive: value.isClosed,
      onPress: () => {
        console.log('convertChat:onPress', value)
        const payload = { channelRoomId: value.roomId }
        openChat(payload)
      },
    }
  }, [])

  const [showCount, setShowCount] = useState<number | undefined>(defaultCount)
  const [searchArchive, setSearchArchive] = useState(false)

  const filteredList = useMemo(() => {
    return list.map(convert).filter(({ archive }) => (searchArchive === true ? true : archive !== true))
  }, [list, searchArchive, showCount, convert])

  useEffect(() => {
    console.log('filteredList-chat', filteredList)
    onChangeSearchCount('chat', filteredList.length)
  }, [onChangeSearchCount, filteredList.length])

  const isMore = useMemo(() => {
    if (showCount === undefined) return false
    return (filteredList?.length ?? 0) > showCount
  }, [showCount, filteredList])

  const handlePressMore = useCallback(() => {
    onChangeTab('BOARD')
  }, [])

  const [page, setPage] = useState(0)
  useEffect(() => {
    if (page > 0) {
      onSearchNext('BOARD', page)
    }
  }, [page, onSearchNext])

  return (
    <ActivitySearchList type="CHAT" onPressMore={isMore ? handlePressMore : undefined}>
      {isFilter && (
        <View
        // style={['done', 'last'].includes(searchState) && isFilter && { height: 100 }}
        >
          <ActivitySearchSectionSortChatlist
            isSwitchOn={searchArchive}
            onChangeSwitchOn={setSearchArchive}
            defaultIndex={0}
          />
        </View>
      )}

      <FlatList
        scrollEnabled={isFilter}
        contentContainerStyle={[!isFilter && { flex: 1 }, isFilter && { paddingBottom: 24 }]}
        data={filteredList.slice(0, showCount)}
        renderItem={({ item }) => {
          return <ActivitySearchItemChat {...item} />
        }}
        onEndReached={() => {
          if (isFilter && searchState === 'done') setPage((prev) => prev + 1)
        }}
        onEndReachedThreshold={1}
        ListEmptyComponent={
          <>
            {searchState === 'ready' && (
              <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <SearchIcon type={'searching'} textSize={'medium'} />
              </View>
            )}
            {['done', 'last'].includes(searchState) && isFilter && (
              <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <SearchIcon type="chat" text={t('nocontentsicon.search.text')} />
              </View>
            )}
          </>
        }
        ListFooterComponent={
          <>
            {searchState === 'searching' && (
              <SearchResultSkeleton filteredList={filteredList} defaultCount={defaultCount} searchState={searchState}>
                <ActivitySearchItemChat />
              </SearchResultSkeleton>
            )}
          </>
        }
      />
    </ActivitySearchList>
  )
}
