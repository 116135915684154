import type { IDiscussion, IFile } from '@rocket-mono/libs'
import { FONT, getRealColor } from '@rocket-mono/libs'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View, ViewStyle } from 'react-native'
import Input from '../Input'
import DiscussionList from './discussionList'
import { ListMoreButtonType } from './types'

interface Props {
  /**
   * web only
   */
  discussionCount?: number
  /**
   * web only
   */
  historyCount?: number
  /**
   * web only
   */
  initState?: string
  /**
   * web only
   */
  onState?: (state: string) => void
  list: IDiscussion[]
  onPopupState: (index: number, state: string, item?: IDiscussion) => void
  itemContainerStyle: ViewStyle
  text: string
  onText?: (text: string) => void
  onSubmit?: () => void
  historyEnabled: boolean
  onFileupload?: () => void
  onSend?: () => void
  onFileShare?: (item?: IFile) => void
  onFileExport?: (item?: IFile) => void
  onFileDownload?: (item?: IFile) => void
  onFilePreview?: (item?: IFile) => void
  selectedIndex?: number
  onLinkPress?: (url: string, text?: string | undefined) => void
  onScroll?: () => void
  onPressMoreButton?: (payload: ListMoreButtonType) => void
}

function DiscussionBorderBoard({
  text,
  onText,
  list,
  onPopupState,
  itemContainerStyle,
  onSubmit,
  onFileupload,
  onSend,
  onFileShare,
  onFileExport,
  onFileDownload,
  onFilePreview,
  selectedIndex,
  onLinkPress,
  onScroll,
  onPressMoreButton,
}: Props) {
  const { t } = useTranslation()

  const inputRef = React.useRef<any>(null)

  const handleSubmit = React.useCallback(() => {
    if (inputRef.current && inputRef.current.innerText) {
      inputRef.current.innerText = ''
    }
    onSubmit && onSubmit()
  }, [inputRef])

  const handleSend = React.useCallback(() => {
    if (inputRef.current && inputRef.current.innerText) {
      inputRef.current.innerText = ''
    }
    onSend && onSend()
  }, [inputRef])

  return (
    <View>
      <View style={styles.tobBorder} />
      <View style={styles.tobDescription}>
        <Text style={styles.desciptionText}>{t('discussion.desc_native')}</Text>
      </View>
      <View style={styles.inputContainer}>
        <Input
          inputRef={inputRef}
          value={text}
          onChangeText={onText}
          placeholder={t('discussion.enter')}
          onEnterSubmit={handleSubmit}
          height={34}
          blurOnSubmit={false}
          rightIcon={
            onFileupload
              ? {
                  name: 'cloud-upload-o',
                  color: 'mono.paleBlack',
                  onPress: onFileupload,
                }
              : undefined
          }
          rightIconSize={16}
          rightIconStyle={styles.rightIcon}
          rightIcon2={
            onSend
              ? {
                  name: 'send',
                  color: 'mono.paleBlack',
                  onPress: handleSend,
                }
              : undefined
          }
          rightIconSize2={18}
          rightIconStyle2={styles.rightIcon2}
        />
      </View>
      <DiscussionList
        list={list}
        onPopupState={({ index, state, item }) => onPopupState(index, state, item)}
        itemContainerStyle={itemContainerStyle}
        onFileShare={onFileShare}
        onFileExport={onFileExport}
        onFileDownload={onFileDownload}
        onFilePreview={onFilePreview}
        selectedIndex={selectedIndex}
        onLinkPress={onLinkPress}
        onScroll={onScroll}
        onPressMoreButton={onPressMoreButton}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  tobBorder: {
    borderBottomColor: getRealColor('mono.lightGray'),
    borderBottomWidth: 1,
    marginBottom: 10,
  },
  tobDescription: {
    marginHorizontal: 15,
  },
  desciptionText: {
    ...FONT.textRegular,
    color: getRealColor('mono.black'),
  },
  inputContainer: {
    height: 40,
    margin: 20,
  },
  rightIcon: {
    height: 26,
    width: 26,
    backgroundColor: getRealColor('mono.pale'),
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 10,
    paddingBottom: 5,
  },
  rightIcon2: {
    paddingBottom: 2,
  },
})

export default DiscussionBorderBoard
