import { ClickOutside } from '@rocket-mono/libs'
import { Icon, Switch, Text } from '@rocket/atoms'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, View } from 'react-native'
import ListApplyBoardItem from '../../../components/ListApplyBoardItem'
import ListFlowItem from '../../../components/ListFlowItem'
import SelectBox from '../../../components/SelectBox'
import { useRule } from '../../hooks'
import { RuleListItemType } from './types'

const zIndex = 100

interface Props {
  item: RuleListItemType
}

const Item: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation()
  const { isAuth, isMobile, boardList, elementList, onRuleListChange, onItemMore, onModifyRule, onRuleListRemove } =
    useRule()

  const mobileViewRef = React.useRef<View>(null)
  const [isMore, setIsMore] = React.useState(false)

  const { board, element } = React.useMemo(() => {
    const board = boardList.find((board) => board.id === item.applyBoard)
    const element = elementList.find((element) => element.id === board?.projectElementId)
    return { board, element }
  }, [item, elementList])

  const activated = React.useMemo(() => {
    if (typeof item.isActive === 'undefined') return false
    return item.isActive
  }, [item])

  const handleActiveChange = React.useCallback((activated: boolean) => {
    onRuleListChange({ ...item, isActive: activated })
  }, [])

  if (isMobile) {
    return (
      <View style={styles.rootContainer} ref={mobileViewRef}>
        <View style={styles.container}>
          <View style={styles.targetContainer}>
            <ListApplyBoardItem board={board} element={element} />
          </View>
          <View style={styles.flowContainer}></View>
          <View style={styles.stateContainer}>
            <View style={styles.switchContainer}>
              {isAuth ? (
                <View style={styles.switchView}>
                  <Switch activated={activated} onChange={handleActiveChange} size="sm" />
                </View>
              ) : (
                <></>
              )}
              <Text fontName="txtXsMedium" fontColor={activated ? 'gray.g800' : 'gray.g400'}>
                {activated ? 'on' : 'off'}
              </Text>
            </View>
            {isAuth ? (
              <View>
                <Pressable
                  onPress={() => {
                    mobileViewRef.current?.measure((x, y, _width, _height, pageX, pageY) => {
                      onItemMore({ item, xPos: x, yPos: y, pageX, pageY })
                    })
                  }}
                >
                  <Icon name="ellipsis-h" size={16} color="gray.g450" />
                </Pressable>
              </View>
            ) : (
              <></>
            )}
          </View>
        </View>
        <View style={styles.container}>
          <View style={styles.flowContainer}>
            <ListFlowItem item={item} />
          </View>
        </View>
      </View>
    )
  }

  return (
    <View style={styles.rootContainer}>
      <View style={styles.container}>
        <View style={styles.targetContainer}>
          <ListApplyBoardItem board={board} element={element} />
        </View>
        <View style={styles.flowContainer}>
          <ListFlowItem item={item} />
        </View>
        <View style={styles.stateContainer}>
          <View style={styles.switchContainer}>
            {isAuth ? (
              <View style={styles.switchView}>
                <Switch activated={activated} onChange={handleActiveChange} size="sm" />
              </View>
            ) : (
              <></>
            )}
            <Text fontName="txtXsMedium" fontColor={activated ? 'gray.g800' : 'gray.g400'}>
              {activated ? 'on' : 'off'}
            </Text>
          </View>
          {isAuth ? (
            <Pressable onPress={() => setIsMore(true)}>
              <Icon name="ellipsis-h" size={16} color="gray.g450" />
            </Pressable>
          ) : (
            <></>
          )}
        </View>
      </View>
      {isMore && (
        <ClickOutside onClickOutside={() => setIsMore(false)}>
          <SelectBox style={[styles.selectBox, { zIndex }]}>
            <SelectBox.Item
              text={t('rule.boards.edit')}
              onPress={() => {
                setIsMore(false)
                onModifyRule(item)
              }}
            />
            {/* <SelectBox.Item text="복사" onPress={() => console.log(item.id, 'COPY')} /> */}
            <SelectBox.DividingLine />
            <SelectBox.Item
              text={t('workcardoptionheader.delete')}
              onPress={() => {
                setIsMore(false)
                onRuleListRemove(item.id)
              }}
            />
          </SelectBox>
        </ClickOutside>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  rootContainer: {
    position: 'relative',
  },
  container: {
    flexDirection: 'row',
  },
  targetContainer: {
    width: 100,
    paddingTop: 12,
    paddingLeft: 15,
    paddingBottom: 8,
    flexDirection: 'row',
  },
  flowContainer: {
    flex: 1,
    paddingTop: 12,
    paddingLeft: 15,
    paddingBottom: 8,
  },
  stateContainer: {
    width: 120,
    paddingTop: 12,
    paddingHorizontal: 15,
    paddingBottom: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  switchContainer: {
    flexDirection: 'row',
  },
  switchView: {
    height: 22,
    marginRight: 5,
  },
  selectBox: {
    top: 34,
    right: 5,
  },
})

export default Item
