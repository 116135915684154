import { Icon } from '@rocket-atoms/icon'
import { COLOR, getBoxShadow } from '@rocket-mono/libs'
import { Text } from '@rui/atoms'
import React, { useState } from 'react'
import {
  FlatList,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'

const DAY_WIDTH = 31
const DAY_HEIGHT = 31

const pad = (n: number, size = 2) => {
  return String(n).padStart(size, '0')
}

type CalendarItem = {
  date: Date
  isToday: boolean
  isCurrentMonth: boolean
}

interface Props {
  today?: Date
  startDate?: Date
  endDate?: Date
}

const CalendarView = ({ today, startDate, endDate }: Props) => {
  let baseDate = new Date()

  if (startDate && !endDate) {
    baseDate = startDate
  }
  if (!startDate && endDate) {
    baseDate = endDate
  }
  if (startDate && endDate) {
    const $today = today ? today : new Date()

    if ($today.getTime() < startDate.getTime()) {
      baseDate = startDate
    }
    if ($today.getTime() > endDate.getTime()) {
      baseDate = endDate
    }
    if ($today.getTime() >= startDate.getTime() && $today.getTime() <= endDate.getTime()) {
      baseDate = $today
    }
  }

  const [, setSelectedDate] = useState<Date | null>(null)
  const [currentMonth, setCurrentMonth] = useState(baseDate.getMonth())
  const [currentYear, setCurrentYear] = useState(baseDate.getFullYear())
  const [dayWidth, setDayWidth] = useState<number | undefined>()
  const containerRef = React.useRef<View>(null)

  const generateHeaderData = () => {
    return ['S', 'M', 'T', 'W', 'T', 'F', 'S']
  }

  const getIsStartDate = (date: Date) => {
    if (!startDate) {
      return false
    }
    const $startDate = new Date(startDate)
    $startDate.setHours(0, 0, 0, 0)
    return date.getTime() === $startDate.getTime()
  }
  const getIsEndDate = (date: Date) => {
    if (!endDate) {
      return false
    }
    const $endDate = new Date(endDate)
    $endDate.setHours(0, 0, 0, 0)
    return date.getTime() === $endDate.getTime()
  }

  // 월간 달력 데이터 생성 함수
  const generateCalendarData = () => {
    const currentDate = today ? today : new Date()
    const year = currentYear
    const month = currentMonth
    const firstDay = new Date(year, month, 1)
    const lastDay = new Date(year, month + 1, 0)
    const daysInMonth = lastDay.getDate()
    const startDay = firstDay.getDay()

    const calendarData: CalendarItem[] = []
    const prevMonth = month === 0 ? 11 : month - 1
    const prevYear = month === 0 ? year - 1 : year
    const prevLastDay = new Date(year, month, 0)
    const prevDaysInMonth = prevLastDay.getDate()

    // 이전 달 데이터 추가
    for (let i = prevDaysInMonth - startDay + 1; i <= prevDaysInMonth; i++) {
      const date = new Date(prevYear, prevMonth, i)
      calendarData.push({
        date,
        isToday: false,
        isCurrentMonth: false,
      })
    }

    // 현재 달 데이터 추가
    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(year, month, i)
      const isToday = date.toDateString() === currentDate.toDateString()
      calendarData.push({
        date,
        isToday,
        isCurrentMonth: true,
      })
    }

    // 다음 달 데이터 추가
    const nextMonth = month === 11 ? 0 : month + 1
    const nextYear = month === 11 ? year + 1 : year

    const nextDaysCount = 42 - daysInMonth - startDay
    for (let i = 1; i <= nextDaysCount; i++) {
      const date = new Date(nextYear, nextMonth, i)
      calendarData.push({
        date,
        isToday: false,
        isCurrentMonth: false,
      })
    }

    return calendarData
  }

  // 날짜 선택 핸들러
  const handleDateSelect = (date: Date) => {
    setSelectedDate(date)
    console.log(date.toDateString())
  }

  // 이전 달로 이동
  const goToPrevMonth = () => {
    setCurrentMonth((prevMonth) => {
      const newMonth = prevMonth === 0 ? 11 : prevMonth - 1
      setCurrentYear((prevYear) => (prevMonth === 0 ? prevYear - 1 : prevYear))
      return newMonth
    })
  }

  // 다음 달로 이동
  const goToNextMonth = () => {
    setCurrentMonth((prevMonth) => {
      const newMonth = prevMonth === 11 ? 0 : prevMonth + 1
      setCurrentYear((prevYear) => (prevMonth === 11 ? prevYear + 1 : prevYear))
      return newMonth
    })
  }

  const headerData = generateHeaderData()
  // 달력 데이터 가져오기
  const calendarData = generateCalendarData()

  const startDatePosition = React.useMemo(() => {
    if (!startDate) {
      return undefined
    }
    if (!dayWidth) {
      return undefined
    }
    if (endDate) {
      const $startDate = new Date(startDate)
      $startDate.setHours(0, 0, 0, 0)
      const $endDate = new Date(endDate)
      $endDate.setHours(0, 0, 0, 0)
      if ($startDate.getTime() === $endDate.getTime()) {
        return undefined
      }
    }
    const $startDate = new Date(startDate)
    $startDate.setHours(0, 0, 0, 0)
    const index = calendarData.findIndex((item) => {
      return item.date.getTime() === $startDate.getTime()
    })
    if (index === -1) {
      return undefined
    }
    const row = Math.floor(index / 7)
    const col = index % 7
    return {
      left: col * dayWidth + dayWidth / 2 + DAY_WIDTH / 2 + 7,
      top: 20 + 24 + (row + 1) * (DAY_HEIGHT + 12) + DAY_HEIGHT / 2 - 4,
    }
  }, [startDate, endDate, calendarData, dayWidth])

  const endDatePosition = React.useMemo(() => {
    if (!endDate) {
      return undefined
    }
    if (!dayWidth) {
      return undefined
    }
    if (startDate) {
      const $startDate = new Date(startDate)
      $startDate.setHours(0, 0, 0, 0)
      const $endDate = new Date(endDate)
      $endDate.setHours(0, 0, 0, 0)
      if ($startDate.getTime() === $endDate.getTime()) {
        return undefined
      }
    }
    const $endDate = new Date(endDate)
    $endDate.setHours(0, 0, 0, 0)
    const index = calendarData.findIndex((item) => {
      return item.date.getTime() === $endDate.getTime()
    })
    if (index === -1) {
      return undefined
    }
    const row = Math.floor(index / 7)
    const col = index % 7
    return {
      left: col * dayWidth + dayWidth / 2 - DAY_WIDTH / 2 + 1,
      top: 20 + 24 + (row + 1) * (DAY_HEIGHT + 12) + DAY_HEIGHT / 2 - 4,
    }
  }, [startDate, endDate, calendarData, dayWidth])

  // 날짜 범위 텍스트 생성 함수
  // const generateDateRangeText = () => {
  //   if (!startDate || !endDate) {
  //     return ''
  //   }
  //   const startDateText = `${startDate.getMonth() + 1}/${startDate.getDate()}`
  //   const endDateText = `${endDate.getMonth() + 1}/${endDate.getDate()}`
  //   return `${startDateText} - ${endDateText}`
  // }

  // 날짜 범위 표시 스타일 생성 함수
  const generateDateRangeStyle = (date: Date) => {
    if (!startDate || !endDate) {
      return null
    }
    const $startDate = new Date(startDate)
    $startDate.setHours(0, 0, 0, 0)
    const $endDate = new Date(endDate)
    $endDate.setHours(0, 0, 0, 0)
    const isInRange = date >= $startDate && date <= $endDate

    if (isInRange) {
      return [styles.dateRangeCell]
    }

    return null
  }

  const getStartWeek = (date: Date, index: number) => {
    if (!startDate || !endDate) {
      return null
    }
    const $startDate = new Date(startDate)
    $startDate.setHours(0, 0, 0, 0)
    const $endDate = new Date(endDate)
    $endDate.setHours(0, 0, 0, 0)
    const isInRange = date >= $startDate && date <= $endDate
    const isStartWeek = index % 7 === 0
    return isInRange && isStartWeek ? styles.dateRangeCellStart : null
  }

  const getEndWeek = (date: Date, index: number) => {
    if (!startDate || !endDate) {
      return null
    }
    const $startDate = new Date(startDate)
    $startDate.setHours(0, 0, 0, 0)
    const $endDate = new Date(endDate)
    $endDate.setHours(0, 0, 0, 0)
    const isInRange = date >= $startDate && date <= $endDate
    const isEndWeek = index % 7 === 6
    return isInRange && isEndWeek ? styles.dateRangeCellEnd : null
  }

  const renderHeaderItem = ({ item }: { item: string }) => {
    return (
      <View style={[styles.headerCell, { width: dayWidth }]}>
        <Text style={styles.headerRowText} fontName="txtSm">
          {item}
        </Text>
      </View>
    )
  }

  // 각 날짜를 렌더링하는 함수
  const renderCalendarItem = ({ item, index }: { item: CalendarItem; index: number }) => {
    const { date, isCurrentMonth /*isToday*/ } = item
    const containerStyles: StyleProp<ViewStyle> = [
      styles.marginBottom12,
      styles.dateContainer,
      { flexDirection: 'row' },
    ]
    const rangeStyles: StyleProp<ViewStyle> = [
      styles.dateContainer,
      { position: 'absolute' },
      getIsStartDate(date) || getIsEndDate(date) ? styles.rangeDateCell : null,
    ]
    const textStyles: StyleProp<TextStyle> = [
      styles.dateText,
      getIsStartDate(date) || getIsEndDate(date) ? styles.dateWhiteText : null,
    ]

    // if (isToday) {
    //   containerStyles.push(styles.todayContainer)
    //   textStyles.push(styles.todayText)
    // }

    if (!isCurrentMonth) {
      textStyles.push(styles.nonCurrentMonthText)
    }

    const rightContainerStyles: StyleProp<ViewStyle> = [
      {
        width: dayWidth ? dayWidth / 2 : 0,
        height: DAY_HEIGHT,
      },
      generateDateRangeStyle(date),
      getStartWeek(date, index),
      getIsStartDate(date) ? { backgroundColor: COLOR.mono.white } : null,
    ]

    const leftContainerStyles: StyleProp<ViewStyle> = [
      {
        width: dayWidth ? dayWidth / 2 : 0,
        height: DAY_HEIGHT,
      },
      generateDateRangeStyle(date),
      getEndWeek(date, index),
      getIsEndDate(date) ? { backgroundColor: COLOR.mono.white } : null,
    ]

    return (
      <View style={containerStyles}>
        <View style={rightContainerStyles} />
        <View style={leftContainerStyles} />
        <Pressable style={rangeStyles} onPress={() => handleDateSelect(date)}>
          <Text style={textStyles} fontName="txtSm">
            {pad(date.getDate())}
          </Text>
        </Pressable>
      </View>
    )
  }

  const getDayWidth = () => {
    if (containerRef.current) {
      containerRef.current.measure((_x, _y, width /*, _height, _pageX, _pageY*/) => {
        // console.log('PlatForm', Platform.OS)
        // console.log('x', x)
        // console.log('y', y)
        // console.log('width', width)
        // console.log('height', height)
        // console.log('pageX', pageX)
        // console.log('pageY', pageY)
        setDayWidth((width - 20) / 7)
      })
    }
  }

  React.useEffect(() => {
    if (Platform.OS === 'web') {
      window.addEventListener('resize', getDayWidth)
    }
    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('resize', getDayWidth)
      }
    }
  }, [])

  return (
    <View style={styles.container} ref={containerRef} collapsable={false} onLayout={getDayWidth}>
      <View style={styles.header}>
        <TouchableOpacity onPress={goToPrevMonth}>
          <Icon name="angle-left" size={15} color="gray.g700" />
        </TouchableOpacity>
        <Text style={styles.headerText} fontName="H7">
          {currentYear}.{pad(currentMonth + 1)}
        </Text>
        <TouchableOpacity onPress={goToNextMonth}>
          <Icon name="angle-right" size={15} color="gray.g700" />
        </TouchableOpacity>
      </View>
      {/* <Text style={styles.dateRangeText}>{generateDateRangeText()}</Text> */}
      {startDatePosition ? (
        <View
          style={[
            styles.triangle,
            styles.triangleStart,
            {
              top: startDatePosition.top,
              left: startDatePosition.left,
              zIndex: 2,
            },
          ]}
        />
      ) : (
        <></>
      )}
      {endDatePosition ? (
        <View
          style={[
            styles.triangle,
            styles.triangleEnd,
            { top: endDatePosition.top, left: endDatePosition.left, zIndex: 2 },
          ]}
        />
      ) : (
        <></>
      )}
      {dayWidth ? (
        <View style={styles.headerRow}>
          <FlatList
            data={headerData}
            renderItem={renderHeaderItem}
            keyExtractor={(_, index) => String(index)}
            horizontal
          />
        </View>
      ) : (
        <></>
      )}
      <FlatList
        data={calendarData}
        renderItem={renderCalendarItem}
        keyExtractor={(item) => item.date.toISOString()}
        numColumns={7}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 20,
    paddingHorizontal: 10,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
    width: '100%',
    height: 24,
  },
  headerRow: {
    flexDirection: 'row',
    height: 31,
  },
  headerCell: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerRowText: {
    color: COLOR.gray.g400,
    fontSize: 11,
  },
  headerText: {
    marginHorizontal: 30,
  },
  // dateRangeText: {
  //   fontSize: 16,
  //   marginBottom: 10,
  // },
  marginBottom12: {
    marginBottom: 12,
  },
  dateContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: 31,
  },
  dateText: {
    // fontSize: 16,
  },
  dateWhiteText: {
    color: COLOR.mono.white,
  },
  // todayContainer: {
  //   backgroundColor: 'lightblue',
  // },
  // todayText: {
  //   fontWeight: 'bold',
  // },
  nonCurrentMonthText: {
    color: '#ccc',
  },
  dateRangeCell: {
    flex: 1,
    backgroundColor: COLOR.primary.blue05,
  },
  dateRangeCellStart: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  dateRangeCellEnd: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  rangeDateCell: {
    width: '100%',
    maxWidth: DAY_WIDTH,
    // flex: 1,
    backgroundColor: COLOR.main.navy,
    borderRadius: 8,
    zIndex: 3,
    ...getBoxShadow(31, 86, 164, 0.14, 3, -2, 2, 2),
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 6,
    borderRightWidth: 6,
    borderBottomWidth: 6,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: COLOR.main.blue,
  },
  triangleStart: {
    position: 'absolute',
    transform: [{ rotate: '90deg' }],
    zIndex: 2,
  },
  triangleEnd: {
    position: 'absolute',
    transform: [{ rotate: '270deg' }],
    zIndex: 2,
  },
})

export default CalendarView
