import { StyleSheet } from 'react-native'

export const commonStyles = StyleSheet.create({
  commonWrap: {
    position: 'relative',
    minWidth: 200,
    maxWidth: 200,
    borderRadius: 4,
  },
})
