import React, { useCallback } from 'react'
import { Image, StyleSheet, View } from 'react-native'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button, Text } from '@rui/atoms'
import { COLOR } from '@rui/foundations'

const Error404: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handlePressBack = useCallback(() => {
    navigate(-1)
  }, [])
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLOR.gray.g050,
        width: '100vw',
        height: '100vh',
      }}
    >
      <View style={styles.container}>
        <View>
          <Text
            style={{ fontFamily: 'Pretendard', fontWeight: '700', fontSize: 36, color: '#041727', letterSpacing: -2 }}
          >
            Oopss!
          </Text>
          <Text
            style={{
              fontFamily: 'Pretendard',
              fontWeight: '900',
              fontSize: 120,
              color: '#041727',
              letterSpacing: -5,
              opacity: 0.1,
            }}
          >
            404 <Text style={{ fontSize: 48, letterSpacing: 0.5 }}>Error</Text>
          </Text>
          <Text fontName="H7Medium" fontColor="gray.g900">
            {t('error.nopage.message')}
          </Text>
          <Button
            style={{ marginTop: 40 }}
            size="lg"
            type="PRIMARY"
            text={t(`setting.back`)}
            onPress={handlePressBack}
          />
        </View>
        <View style={{ marginLeft: 60 }}>
          <Image
            source={{ uri: 'https://rocket.is/files/file_box/6dd4f9258823486c89aa1b1e381dcac4.png' }}
            style={styles.image}
          />
        </View>
      </View>
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
  },
  image: {
    width: 840 / 2,
    height: 538 / 2,
  },
})

export default Error404
