import { Avatar } from '@rui/atoms'
import * as React from 'react'
import DropdownItemFilled from '../DropdownItemFilled'
import { ListMember } from './types'

interface Props {
  item: ListMember
  fontSize?: 'SM' | 'MD'
  paddingHorizontal?: number
  onChange?: (item: ListMember) => void
}

const MemberList: React.FC<Props> = ({ item, fontSize = 'SM', paddingHorizontal, onChange }) => {
  return (
    <DropdownItemFilled
      label={item.name}
      selected={item.selected}
      onChangeSelected={(selected) => {
        onChange && onChange({ ...item, selected })
      }}
      fontSize={fontSize}
      paddingHorizontal={paddingHorizontal}
      isCheck
    >
      <Avatar size="xsmall" profileUrl={item.uri} />
    </DropdownItemFilled>
  )
}

export default MemberList
