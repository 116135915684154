import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, StyleProp, StyleSheet, TextInput, TextStyle } from 'react-native'
import { useMention } from '../../hooks/useMention.web'
import { getCurrentCaretPosition, isDirectionalKey, restoreCaretPosition } from '../../utils/keyboard.web'

import { FONT } from '@rocket-mono/libs'

export interface InputProps {
  onChangeText: (text: string) => void
  onPressInputEnterKey?: (text: string) => void
  defaultValue?: string
  clearWhenSubmit?: boolean
  style?: StyleProp<TextStyle>
  onFocus?: () => void
  onBlur?: () => void
  mentionList?: string[]
  inputRef: React.RefObject<TextInput>
}

const Input = ({ onPressInputEnterKey, onChangeText, clearWhenSubmit, defaultValue, inputRef }: InputProps) => {
  const { t } = useTranslation()

  // FIXME: src/components/Input/index.web.tsx 의 구현으로 복사됐음
  const {
    startMention,
    closeMention,
    mentionListOpen,
    forwardKeyEvent,
    forwardFormEvent,
    forwardCompositionEvent,
    forwardBlurEvent,
    mentionList,
    state: mentionState,
    matched,
    startMatching,
  } = useMention()
  const [buffer] = React.useState(defaultValue ?? '')

  const currentText: string | JSX.Element[] = React.useMemo(() => {
    return buffer
  }, [buffer])

  const ref = React.useRef<HTMLDivElement>(null)

  const onKeyPress = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const { keyCode, altKey, ctrlKey, shiftKey, metaKey } = e.nativeEvent
      if (e.key == '@' && !mentionListOpen && ref) {
        // check current caret position and precending character is space
        const htmlDivCurrent = e.currentTarget
        const insertion = getCurrentCaretPosition(htmlDivCurrent)
        if (insertion === 0) {
          startMention('', mentionList.map(({ name }) => name) ?? [], ref)
        } else {
          const precendingCharacterCode = htmlDivCurrent.innerText[insertion - 1].charCodeAt(0)
          if (precendingCharacterCode === 32 || precendingCharacterCode === 160 || insertion === 0) {
            startMention('', mentionList.map(({ name }) => name) ?? [], ref)
          }
        }
      }

      if (keyCode === 13 && !altKey && !ctrlKey && !shiftKey && !metaKey && onPressInputEnterKey) {
        console.debug('enteryKey')
        if (mentionListOpen) {
          closeMention()
        } else {
          onPressInputEnterKey(ref.current ? ref.current.innerText : buffer)
          if (clearWhenSubmit) {
            if (ref.current) ref.current.innerText = ''
            // setBuffer('')
          }
        }
        e.preventDefault()
      }
    },
    [buffer, clearWhenSubmit, closeMention, mentionList, mentionListOpen, onPressInputEnterKey, ref, startMention],
  )

  React.useEffect(() => {
    if (!ref.current) return

    if (mentionState === 'start') {
      // setBuffer(ref.current?.innerText)
      startMatching()
    } else if (mentionState === 'matched') {
      const insertion = getCurrentCaretPosition(ref.current)

      const htmlDivCurrent = ref.current as unknown as HTMLDivElement
      htmlDivCurrent.innerHTML =
        htmlDivCurrent.innerText.substring(0, insertion) +
        matched[0].name +
        '\u00A0' +
        htmlDivCurrent.innerText.substring(insertion)

      console.log('matched[0].name', matched[0].name)

      restoreCaretPosition(ref.current, insertion + matched[0].name.length + 1)

      // insert matched[0] to text at lastCaretPosition
      if (onChangeText) onChangeText(ref.current.innerText)

      closeMention()
    }
  }, [closeMention, matched, mentionState, onChangeText, startMatching])

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.clear = () => {
        // console.debug('clear override')
        if (ref.current) ref.current.innerText = ''
        inputRef.current?.setNativeProps({ text: '' })
      }

      inputRef.current.focus = () => {
        if (ref.current) ref.current.focus()
      }
    }
    // console.debug('inputRef', inputRef.current)
  }, [inputRef])

  return (
    <>
      <TextInput
        ref={inputRef}
        onChangeText={(text) => {
          console.debug('text input', text)
        }}
        value={defaultValue}
        style={{ display: 'none' }}
      />
      <div
        ref={ref}
        style={{ ...styles.container, overflowWrap: 'anywhere' }}
        placeholder={t('chatinput.placeholder')}
        contentEditable={true}
        onPaste={(e) => {
          e.preventDefault()
          const text = e.clipboardData.getData('text/plain')
          document.execCommand('insertText', false, text)
        }}
        onKeyPress={onKeyPress}
        onKeyDown={(e) => {
          if (mentionListOpen && isDirectionalKey(e.code)) {
            e.preventDefault()
          }
        }}
        onKeyUp={(e) => {
          // NOTE: arrow key 이벤트는 여기서 처리돼야 함
          if (mentionListOpen) {
            forwardKeyEvent(e)
          }
        }}
        onBlur={(e) => {
          if (mentionListOpen) {
            forwardBlurEvent(e)
          }
        }}
        onInput={(e) => {
          // onInput은 IME에 관계없이 모든 입력에 대해 실행됨
          if (mentionListOpen) {
            forwardFormEvent(e)
          } else {
            if (onChangeText) {
              onChangeText(e.currentTarget.innerText)
            }
          }
        }}
        onCompositionUpdate={(e) => {
          // CJK IME인 경우 IME에 의해 문자를 입력중인 경우, 키 입력에 대해 onCompositionUpdate 이벤트가 발생함
          if (mentionListOpen) {
            // NOTE: composition 시에는 키 입력을 모두 포워딩한다
            forwardCompositionEvent(e, true)
          }
          if (onChangeText) {
            onChangeText(e.currentTarget.innerText)
          }
        }}
        onCompositionEnd={(e) => {
          // CJK IME인 경우 완성된 문자가 입력되면 onCompositionEnd 이벤트가 발생하고, onInput 이벤트가 onCompositionEnd 이전에 발생함
          if (mentionListOpen) {
            forwardCompositionEvent(e, false)
          }
          if (onChangeText) {
            onChangeText(e.currentTarget.innerText)
          }
        }}
        suppressContentEditableWarning={true}
      >
        {currentText}
      </div>
    </>
  )
}

export default Input

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    ...FONT.textRegular,
    lineHeight: 'auto',
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
    }),
  },
})
