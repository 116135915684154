import * as React from 'react'

import { useTranslation } from 'react-i18next'
import { CardRowGroup } from '../../components/CardRowGroup'
import type { ViewProps } from './types'

import { useAstro, useCurrentUser, useWork, useWorkCard, useWorkChannel, useWorkProject } from '@rocket-mono/providers'
import { CardSearchCardItemType } from '@rocket-screens/card'
import { CardLinkEntry } from '@rocket/types'
import {
  RichTextFieldProvider,
  SearchCardView,
  SearchViewProvider,
  SearchWorkView,
  TriggerCardLinkType,
  TriggerType,
} from '@rui/rich-text-field'
import { LayoutChangeEvent, Platform, View } from 'react-native'
import { CardTodoEdit } from '../../components/CardTodoEdit'
import { SearchView } from '../../components/WorkCardEditor/search-view'
import TodoInputEdit from '../../components/old-todo-input'
import { WorkCardTodo } from '../../components/providers'
import TodoInput from '../../components/todo-input'

interface CardEntryType extends CardLinkEntry {
  source: string
}

const ScreenView: React.FC<ViewProps> = ({ todoParam, onTodoParam, getFiles, window }) => {
  const { t, i18n } = useTranslation()

  const [editIndex, setEditIndex] = React.useState<number>()
  const textEditorRef = React.useRef(null)
  const editEditorRef = React.useRef([])

  const { astro } = useAstro()
  const { projectList } = useWork()
  const { currentProject } = useWorkProject()
  const { currentChannel } = useWorkChannel()
  const { currentCard, saveCacheTodo, readCacheTodo } = useWorkCard()

  const { currentUser } = useCurrentUser()

  const [isLoading, setLoading] = React.useState(false)
  const [isWindow, setIsWindow] = React.useState<boolean>()

  const [cardEntries, setCardEntries] = React.useState<CardEntryType[]>([])

  const [oldTodo, setOldTodo] = React.useState<WorkCardTodo>()
  const [fileLoad, setFileLoad] = React.useState<any>()
  const [oldTodoVisible, setOldTodoVisible] = React.useState(false)
  const [newTodoVisible, setNewTodoVisible] = React.useState(false)

  const editOldTodoEditorRef = React.useRef(null)
  const textNewTodoEditorRef = React.useRef(null)

  React.useEffect(() => {
    readCacheTodo().then((data) => {
      if (data) {
        if (onTodoParam) {
          onTodoParam({ state: 'READ' })
          setIsWindow(true)
        }
      } else {
        setIsWindow(false)
      }
      setLoading(true)
    })
    return () => {
      setLoading(false)
    }
  }, [currentProject])

  const [visibleSearchCard, setVisibleSearchCard] = React.useState(false)
  const [visibleSearchWork, setVisibleSearchWork] = React.useState(false)

  const handlePressItem = React.useCallback(
    (card: CardSearchCardItemType) => {
      console.log('handlePressItem', card)

      setVisibleSearchCard(false)
      setVisibleSearchWork(false)

      const cardEntry: CardEntryType = {
        cardNo: card.cardId,
        cardName: card.cardTitle,
        channelNo: card.boardId,
        source: card.cardType,
      }
      setCardEntries((prev) => {
        const idx = prev.findIndex((o) => o.cardNo === cardEntry.cardNo)
        return idx < 0 ? [...prev, cardEntry] : prev
      })

      const res: TriggerCardLinkType = {
        type: 'CARD_LINK',
        key: card.cardId,
        cardName: card.cardTitle,
        channelId: card.boardId,
      }
      console.log('textEditorRef', editIndex, textEditorRef, editEditorRef)
      if (editIndex !== undefined) {
        // @ts-ignore
        editEditorRef.current[editIndex].onTrigger(res)
        setEditIndex(undefined)
      } else {
        // @ts-ignore
        textEditorRef.current?.onTrigger(res)
      }
    },
    [editIndex],
  )

  const triggerList: TriggerType[] = React.useMemo(() => {
    return [
      {
        key: '#',
        list: [],
        onTrigger: () => {
          console.log('trigger', 'show Cards')
          setVisibleSearchCard(true)
        },
        onDismiss: () => {
          console.log('trigger', 'hide Cards')
          setVisibleSearchCard(false)
          setTimeout(() => {}, 100)
        },
      },
    ]
  }, [])

  console.log('cardEntries', cardEntries)

  const [layoutWidth, setLayoutWidth] = React.useState(0)
  const handleLayout = React.useCallback(({ nativeEvent }: LayoutChangeEvent) => {
    setLayoutWidth(nativeEvent.layout.width)
  }, [])

  const handlePressNewTodo = React.useCallback(() => {
    console.log('handlePressNewTodo')
    setNewTodoVisible(true)
  }, [])

  const handlePressOldTodo = React.useCallback((item: WorkCardTodo) => {
    console.log('handlePressOldTodo', item)
    setOldTodo(item)
    setOldTodoVisible(true)
  }, [])

  const handleFileLoad = React.useCallback(() => {
    console.log('handleFileLoad')
    getFiles?.().then((res) => {
      setFileLoad(res)
    })
  }, [])

  return (
    <CardRowGroup
      title={t(`card.todo.todo`)}
      iconName="check-square-o"
      iconContainerStyle={{
        alignItems: 'stretch',
      }}
    >
      <View style={{ flexDirection: 'column', width: '100%' }} onLayout={handleLayout}>
        <CardTodoEdit
          inputRef={textEditorRef}
          editRef={editEditorRef}
          astro={astro}
          currentUser={currentUser}
          param={todoParam || { state: 'IDLE' }}
          onParam={onTodoParam || (() => console.log('onTodoParam'))}
          projectId={Number(currentProject?.id)}
          boardRoomId={currentCard?.roomIds}
          channelId={String(currentChannel?.id)}
          relatedDomain={'card'}
          relatedDomainId={String(currentCard?.no)}
          window={isWindow}
          saveCacheTodo={saveCacheTodo}
          readCacheTodo={readCacheTodo}
          onChangeEditIndex={setEditIndex}
          cardEntries={cardEntries}
          triggerList={triggerList}
          fileLoad={fileLoad}
          onFileLoad={handleFileLoad}
          onPressNewTodo={handlePressNewTodo}
          onPressOldTodo={handlePressOldTodo}
        >
          {Platform.OS === 'ios' || Platform.OS === 'android' ? (
            <>
              <RichTextFieldProvider ref={editOldTodoEditorRef}>
                {oldTodo && (
                  <TodoInputEdit
                    inputRef={editOldTodoEditorRef}
                    triggerList={triggerList}
                    todo={oldTodo}
                    visible={oldTodoVisible}
                    onChangeVisible={setOldTodoVisible}
                    onChangeTodo={setOldTodo}
                    channelId={String(currentChannel?.id)}
                    relatedDomain="card"
                    relatedDomainId={String(currentCard?.no)}
                    fileLoad={fileLoad}
                    onFileLoad={handleFileLoad}
                    // onChange={() => setChanged(true)}
                  />
                )}
              </RichTextFieldProvider>
              <TodoInput
                inputRef={textNewTodoEditorRef}
                triggerList={triggerList}
                visible={newTodoVisible}
                onChangeVisible={setNewTodoVisible}
                channelId={String(currentChannel?.id)}
                relatedDomain="card"
                relatedDomainId={String(currentCard?.no)}
                fileLoad={fileLoad}
                onFileLoad={handleFileLoad}
                // onAdd={() => setChanged(true)}
              />
            </>
          ) : (
            <></>
          )}
        </CardTodoEdit>

        {currentProject && currentChannel && (
          <SearchViewProvider
            i18n={i18n}
            astro={astro}
            projectId={currentProject.id}
            boardId={currentChannel.id}
            projectList={projectList ?? []}
            onPressItem={handlePressItem}
          >
            {visibleSearchCard && (
              <SearchCardView
                maxWidth={layoutWidth - 24}
                // offsetLayout={offsetLayout ?? {}}
                offsetLayout={{ zIndex: 999, top: 20 }}
                onPressClose={() => setVisibleSearchCard(false)}
                onPressWork={() => setVisibleSearchWork(true)}
              />
            )}
            {visibleSearchWork && <SearchWorkView onDissmiss={() => setVisibleSearchWork(false)} />}
          </SearchViewProvider>
        )}
        {/* {visibleSearchCard && currentProject && currentChannel && (
          <SearchView
            maxWidth={layoutWidth - 60}
            layout={{ top: 90, left: 0 }}
            projectId={currentProject.id}
            boardId={currentChannel.id}
            onPressItem={(card) => {
              console.log('work-card-editor', card)
              handlePressItem(card)
            }}
          />
        )} */}
      </View>
    </CardRowGroup>
  )
}

export default ScreenView
