import { Text } from '@rocket/atoms'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { Action } from '../../RuleScreen/types'

interface Props {
  action?: Action
}

const FlowActionView: React.FC<Props> = ({ action }) => {
  const { t } = useTranslation()

  const actionName = React.useMemo(() => {
    if (action === undefined) return ''
    if (action.event === 'MAIL') {
      return t('rule.automation.mail')
    } else if (action.event === 'INCHARGE') {
      return t('rule.automation.incharge')
    } else if (action.event === 'COPY') {
      return t('rule.automation.copy')
    } else if (action.event === 'COOP') {
      return t('addcontainer.coop')
    }
    return ''
  }, [action])

  if (!action) return <></>

  // if (action.event === 'INCHARGE') {
  //   return <View>담당자 지정</View>
  // } else {
  return (
    <View>
      <Text fontName="txtSmMedium" fontColor="gray.g800">
        {actionName}
      </Text>
    </View>
  )
  // }
}

export default FlowActionView
