import { faEllipsis, faThumbTack } from '@fortawesome/free-solid-svg-icons'
import { WorkIcon } from '@rocket-atoms/icon'
import { Select, SelectType } from '@rocket-mono/foundations'
import { useAstro, useCurrentUser, useModalDialog, useWork } from '@rocket-mono/providers'
import { useToast } from '@rui/atoms'
import { COLOR, FONT, moment } from '@rui/foundations'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, View, ViewStyle } from 'react-native'
import OptionButton from './OptionButton'
import WorkName from './WorkName'
import WorkStateBar from './WorkStateBar'
import { commonStyles } from './styles'

export interface WorkCardType {
  key?: string
  id: string
  isShared: boolean
  isFavorite: boolean
  isLatest: boolean
  isFavoriteMark: boolean
  workspaceName: string
  workType: 'DSP' | 'WFP' | 'CSP' | 'TOP' | string
  workName: string
  onPressCard: () => void
  onPressFavorite: () => void
  isLearnMoreSelect: boolean
  lastActivityAt: Date | null
  // learnMoreList: SelectType[]
}

interface Props {
  index: number
  item: WorkCardType
  style?: ViewStyle
}

const WorkCard: React.FC<Props> = ({ index, item, style }) => {
  const { t } = useTranslation()
  const [isHover, setIsHover] = useState(false)
  const [isLearnMore, setIsLeanMore] = useState(false)

  const { show: showToastMessage } = useToast()
  const { showDialogMessage, hideDialogMessage } = useModalDialog()

  const { astro, astroNav } = useAstro()
  const { currentUser } = useCurrentUser()
  const { projectList, deleteFavorite, onNewMemberInviteModal } = useWork()

  const workLevel = useMemo(() => {
    let workState: '1' | '2' | '3' | '4' | undefined = undefined
    let lastActivityAt = ''

    if (item !== undefined && item.lastActivityAt) {
      const diffMinute = moment().diff(item.lastActivityAt, 'minutes')
      const diffHour = moment().diff(item.lastActivityAt, 'hours')
      const diffDay = moment().diff(item.lastActivityAt, 'days')

      workState = diffHour < 12 ? '1' : diffHour < 72 ? '2' : diffHour < 168 ? '3' : diffHour < 336 ? '4' : undefined
      lastActivityAt =
        diffHour < 1
          ? `${diffMinute}${t('workspace.activetooltip.min')}`
          : diffHour < 24
          ? `${diffHour}${t('workspace.activetooltip.hour')}`
          : diffDay < 14
          ? `${diffDay}${t('workspace.activetooltip.day')}`
          : t('workspace.activetooltip.ago')
    }

    return { workState, lastActivityAt }
  }, [item])

  const currentWorkOwner = useMemo(() => {
    const work = projectList?.find(({ id }) => id === item.id)
    return work?.members.find(({ auth }) => auth === 'OWNER')
  }, [currentUser, projectList, item.id])

  const currentWorkMember = useMemo(() => {
    const work = projectList?.find(({ id }) => id === item.id)
    return work?.members.find(({ userId }) => userId === String(currentUser.id))
  }, [currentUser, projectList, item.id])

  const learnMoreList = useMemo<SelectType[]>(() => {
    if (currentWorkMember === undefined) return []

    const group: any[] = []
    // if (['OWNER'].includes(currentWorkMember.auth ?? '')) {
    //   group.push({
    //     type: 'COMMON',
    //     label: t('more.transfer'),
    //     onPress: () => {
    //       // setVisibleMore(false)
    //       // onPressDelete()
    //     },
    //   })
    // }

    if (item.isFavorite) {
      group.push({
        type: 'COMMON',
        label: t('workpace.undofavorite'),
        onPress: () => {
          deleteFavorite(item.id)
          // setVisibleMore(false)
          // onPressDelete()
        },
      })
    }

    if (['MANAGER', 'OWNER'].includes(currentWorkMember.auth ?? '')) {
      group.push({
        type: 'COMMON',
        label: t('more.invite'),
        onPress: () => {
          onNewMemberInviteModal && onNewMemberInviteModal(item.id)
          setIsLeanMore(false)
        },
      })
      group.push({
        type: 'COMMON',
        label: t('more.edit'),
        onPress: () => {
          const action = 'project'
          const params = {
            projectId: item.id,
          }
          astroNav.emit(action, {
            type: 'update_position',
            params,
          })
        },
      })
      group.push({
        type: 'COMMON',
        label: t('more.end'),
        onPress: () => {
          const title = t('dashboard.floatingbar.askarchive')
          const list = [
            {
              name: t('dashboard.floatingbar.end'),
              action: () => {
                hideDialogMessage()
                astro.archiveProject(item.id).then(() => {
                  const duration = 1000
                  showToastMessage(
                    {
                      type: 'Success',
                      title: t('toast.confirmed'),
                      position: 'BOTTOM_CENTER',
                    },
                    duration,
                  )
                })
              },
            },
          ]

          showDialogMessage({
            type: 'BOTTOM',
            title,
            list,
            cancelText: t('dashboard.floatingbar.cancel'),
            onCancel: hideDialogMessage,
          })
        },
      })
    }
    if (['MEMBER', 'MANAGER'].includes(currentWorkMember.auth ?? '')) {
      group.push({
        type: 'COMMON',
        label: t('more.leave'),
        onPress: () => {
          const title = t('dashboard.floatingbar.askleave')
          const list = [
            {
              name: t('dashboard.floatingbar.leave'),
              action: () => {
                if (currentWorkOwner) {
                  hideDialogMessage()
                  astro
                    .createProjectWithdrawalSelf({
                      projectId: String(item.id),
                      requestUserId: currentWorkMember.userId,
                      targetUserId: currentWorkOwner.userId,
                      requestDate: new Date(),
                      expirationDate: null,
                    })
                    .then(() => {
                      showToastMessage({
                        type: 'Success',
                        title: t('toast.confirmed'),
                        position: 'BOTTOM_CENTER',
                      })
                    })
                    .catch((e) => {
                      e.json().then((e) => console.error('createProjectWithdrawal', e))
                      if (e.status === 409) {
                        hideDialogMessage()
                        showToastMessage({
                          type: 'Danger',
                          title: '처리 되지 않은 요청이 존재합니다',
                          position: 'BOTTOM_CENTER',
                        })
                      }
                    })
                }
              },
            },
          ]

          showDialogMessage({
            type: 'BOTTOM',
            title,
            list,
            cancelText: t('dashboard.floatingbar.cancel'),
            onCancel: hideDialogMessage,
          })
        },
      })
    }

    return [
      {
        beforeDiv: false,
        afterDiv: false,
        group,
      },
    ]
  }, [item, currentWorkMember, currentWorkOwner])

  const handleHoverIn = useCallback(() => {
    setIsHover(true)
  }, [])
  const handleHoverout = useCallback(() => {
    setIsHover(false)
  }, [])

  return (
    <>
      <Pressable
        onHoverIn={handleHoverIn}
        onHoverOut={handleHoverout}
        onPress={item.onPressCard}
        style={[
          commonStyles.commonWrap,
          styles.container,
          isHover && { backgroundColor: '#3B4850' },
          item.isLatest && styles.containerShortcuts,
          item.isFavorite && styles.containerShortcuts,
          style,
        ]}
      >
        <View style={{ position: 'absolute', top: 0, left: 0 }}>
          {workLevel !== undefined && (
            <WorkStateBar
              isLatest={item.isLatest}
              isFavorite={item.isFavorite}
              state={workLevel.workState}
              lastActivityAt={workLevel.lastActivityAt}
            />
          )}
        </View>
        <View>
          <View style={[styles.innerContainer, item.isLatest || item.isFavorite ? {} : { alignItems: 'center' }]}>
            <View style={styles.workIconArea}>
              <WorkIcon type={item.workType} size={24} />
            </View>
            <WorkName onHoverIn={handleHoverIn} workname={item.workName} shared={item.isShared} />
          </View>
          {item.isLatest || item.isFavorite ? (
            <Text style={styles.workspaceText} numberOfLines={1}>
              {item.workspaceName}
            </Text>
          ) : (
            <></>
          )}
        </View>

        <View style={styles.optArea}>
          {item.isFavoriteMark || item.isFavorite || (!item.isFavorite && isHover) ? (
            <OptionButton
              iconName={faThumbTack}
              iconColor={item.isFavoriteMark || item.isFavorite ? 'primary.blue500' : 'gray.g600'}
              onPress={item.onPressFavorite}
              onHoverIn={handleHoverIn}
            />
          ) : (
            <></>
          )}
          {isHover && (
            <View style={styles.learnButtonArea}>
              <OptionButton
                iconName={faEllipsis}
                iconColor={'darkmode.cool300'}
                onPress={() => setIsLeanMore(!isLearnMore)}
                onHoverIn={handleHoverIn}
              />
            </View>
          )}
        </View>
      </Pressable>
      {isLearnMore && (
        <View
          style={[
            styles.learnButtonPosition,
            {
              zIndex: 99,
            },
          ]}
        >
          <Select list={learnMoreList} visible={isLearnMore} setVisible={setIsLeanMore} />
        </View>
      )}
    </>
  )
}

export default WorkCard

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    height: 54,
    justifyContent: 'center',
    backgroundColor: COLOR.darkmode.cool800,
    paddingLeft: 12,
    paddingRight: 28,
  },
  containerShortcuts: {
    maxWidth: 160,
    minWidth: 'auto',
    height: 68,
  },
  containerSkeleton: {
    height: 54,
    backgroundColor: COLOR.darkmode.warm700,
  },
  innerContainer: {
    flexDirection: 'row',
  },
  workIconArea: {
    marginRight: 8,
  },
  workName: {
    flex: 1,
    flexDirection: 'row',
  },
  shareLabel: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 14,
    height: 14,
    marginTop: 2,
    marginRight: 4,
    borderRadius: 2,
    paddin: 4,
    backgroundColor: `rgba(0,0,0, .75)`,
  },
  iconWrap: {
    width: 8,
    height: 8,
    marginTop: -1,
  },
  nameText: {
    flex: 1,
    ...FONT.txtSmMedium,
    color: COLOR.mono.white,
    lineHeight: 16,
  },
  workspaceText: {
    marginTop: 4,
    ...FONT.txt2Xs,
    color: COLOR.darkmode.warm300,
  },
  optArea: {
    position: 'absolute',
    height: '100%',
    right: 4,
    paddingVertical: 4,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  learnButtonArea: {
    position: 'relative',
  },
  learnButtonPosition: {
    position: 'absolute',
    top: 36,
    right: 32,
  },
})
