import React, { useCallback, useMemo, useState } from 'react'
import { StyleSheet, Pressable, View, Text } from 'react-native'

import { COLOR, FONT } from '@rui/foundations'
import { TabType } from '../../../types'

interface Props {
  currentTab: TabType
  tab: TabType
  onPress?: (tab: TabType) => void
  children?: (isActive: boolean) => JSX.Element | JSX.Element[]
  count?: number
}

export const TabItem: React.FC<Props> = ({ tab, currentTab, onPress, children, count }) => {
  const [isHover, setIsHover] = useState(false)

  const isActive = useMemo(() => tab === currentTab, [tab, currentTab])

  const handlePress = useCallback(() => {
    onPress && onPress(tab)
  }, [tab, onPress])

  return (
    <Pressable
      onPress={handlePress}
      style={[
        {
          minWidth: 40,
          height: 40,
          borderRadius: 20,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: COLOR.gray.g050,
          marginRight: 4,
        },
        isHover && { backgroundColor: COLOR.gray.g100 },
        isActive && { backgroundColor: COLOR.sub.darkNavy },
      ]}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
    >
      {children && children(isActive)}
      {count !== undefined && count > 0 && (
        <View style={styles.countLabel}>
          <Text style={styles.countText}>{count}</Text>
          <View style={styles.countBg} />
        </View>
      )}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  countLabel: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: -2,
    bottom: 0,
    overflow: 'hidden',
    borderRadius: 10,
    margin: 1,
    paddingHorizontal: 4,
    paddingVertical: 1,
    minWidth: 18,
    height: 18,
  },
  countText: {
    ...FONT.txt2XsMedium,
    color: COLOR.mono.white,
    zIndex: 1,
  },
  countBg: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: COLOR.opacity.blk60,
  },
})
