import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DimensionValue, StyleSheet, TextInput, View } from 'react-native'

import { faCircleXmark, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import TextField from '../TextField'
import IconPress from './iconPress'

interface Props {
  inputRef?: React.RefObject<TextInput> | undefined
  value: string
  ifGroup?: boolean
  dropdownWidth?: DimensionValue
  placeholder?: string
  autoFocus?: boolean
  onChangeText?: (arg0: string) => void
  onPressClear?: () => void
  onPressSubmit?: () => void
}

const SearchTextField: React.FC<Props> = ({
  inputRef,
  value,
  ifGroup,
  dropdownWidth,
  placeholder,
  autoFocus,
  onChangeText,
  onPressClear,
  onPressSubmit,
}) => {
  const { t } = useTranslation()
  const [rightAreaWidth, setRightAreaWidth] = useState(0)
  const [isDelete, setIsDelete] = useState(false)
  const [fieldLeftPadding, setFieldLeftPadding] = useState(dropdownWidth)

  const handlePressClear = useCallback(() => {
    onChangeText && onChangeText('')
    onPressClear && onPressClear()
  }, [onPressClear])

  const handleKeyPress = useCallback(
    (e) => {
      if (e.nativeEvent.key === 'Enter' && e.nativeEvent.isComposing === false) {
        onPressSubmit?.()
      } else if (e.nativeEvent.key === 'Escape') {
        onChangeText?.('')
      }
    },
    [onPressSubmit, onChangeText],
  )

  useEffect(() => {
    value !== '' ? setIsDelete(true) : setIsDelete(false)
    setFieldLeftPadding(fieldLeftPadding && Number(dropdownWidth) + 8)
  }, [value])

  return (
    <View style={styles.wrap}>
      <TextField
        inputRef={inputRef}
        value={value}
        placeholder={placeholder ? placeholder : t('inputtodo.placeholder')}
        style={{
          width: '100%',
          paddingRight: rightAreaWidth + 8,
          paddingLeft: ifGroup ? (dropdownWidth ? Number(dropdownWidth) + 8 : 92) : 8,
        }}
        autoFocus={autoFocus}
        onChangeValue={onChangeText}
        onKeyPress={handleKeyPress}
        // onSubmitEditing={() => {
        //   console.log('handleKeyPress-submitEditing')
        //   onPressSubmit?.()
        // }}
      />
      <View style={styles.rightArea} onLayout={(e) => setRightAreaWidth(e.nativeEvent.layout.width)}>
        {isDelete && <IconPress icon={faCircleXmark} color={'gray.g600'} onPress={handlePressClear} />}
        <IconPress icon={faMagnifyingGlass} color={'gray.g700'} onPress={onPressSubmit} />
      </View>
    </View>
  )
}

export default SearchTextField

const styles = StyleSheet.create({
  wrap: {
    height: 36,
    width: '100%',
  },
  rightArea: {
    height: 36,
    position: 'absolute',

    flexDirection: 'row',
    right: 8,
    alignItems: 'center',
  },
  icon: {
    width: 24,
    height: 24,
    marginLeft: 8,
  },
})
