import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { View, Platform, Keyboard } from 'react-native'

import { useTranslation } from 'react-i18next'

import { TextFieldGroup } from '@rocket-atoms/text'
import { useSearchScreen } from '../../provider'
import { SearchLayerView } from '../../../SearchLayerView'

interface Props {
  bottomSheetOffset?: number
}

export const SearchFieldView: React.FC<Props> = ({ bottomSheetOffset }) => {
  const { t } = useTranslation()
  const {
    defaultProjectId,
    searchFieldData,
    searchType,
    onChangeSearchType,
    searchProjectId,
    onChangeSearchProjectId,
    onSearch,
    onSearchClear,
  } = useSearchScreen()

  const [listData, setListData] = useState([
    {
      label: t('workmember.table.auth.all'),
      code: 'WORKSPACE',
    },
    {
      label: t('search.searchbar.inthiswork'),
      code: 'WORK',
    },
  ])

  const currentIndex = useMemo(() => {
    const idx = listData.findIndex(({ code }) => code === searchType)
    return idx < 0 ? 0 : idx
  }, [listData, searchType])

  const [searchKeyword, setSearchKeyword] = React.useState('')

  const handlePressClear = useCallback(() => {
    onChangeSearchProjectId([])
  }, [onChangeSearchProjectId])

  const handlePressSubmit = useCallback(() => {
    Keyboard.dismiss()
    onSearch({ searchType, searchKeyword, searchProjectId })
  }, [onSearch, searchProjectId, searchType, searchKeyword])

  useEffect(() => {
    console.log('defaultProjectId', defaultProjectId)
    setListData(
      defaultProjectId === undefined
        ? [
            {
              label: t('workmember.table.auth.all'),
              code: 'WORKSPACE',
            },
          ]
        : [
            {
              label: t('workmember.table.auth.all'),
              code: 'WORKSPACE',
            },
            {
              label: t('search.searchbar.inthiswork'),
              code: 'WORK',
            },
          ],
    )
  }, [defaultProjectId])

  return (
    <View style={{ minHeight: 36, marginHorizontal: 12, marginTop: 8, marginBottom: 4 }}>
      <TextFieldGroup
        label={searchProjectId.length > 0 ? t('search.result.part') : undefined}
        value={searchKeyword}
        onChangeValue={setSearchKeyword}
        onPressClear={onSearchClear}
        onPressSubmit={handlePressSubmit}
        defaultIndex={currentIndex}
        currentIndex={currentIndex}
        listData={listData}
        buttonWidth={Platform.OS === 'web' ? 120 : 92}
      >
        {({ close, visible }) => {
          const handlePressApply = (list) => {
            onChangeSearchProjectId && onChangeSearchProjectId(list)
            close()
          }

          return (
            <SearchLayerView
              bottomSheetOffset={bottomSheetOffset}
              visible={visible}
              projectId={defaultProjectId}
              type={searchType}
              onChangeType={onChangeSearchType}
              containerStyle={{}}
              data={searchFieldData}
              onPressClear={handlePressClear}
              onPressApply={handlePressApply}
              onPressSearch={handlePressSubmit}
              onPressCancel={close}
            />
          )
        }}
      </TextFieldGroup>
    </View>
  )
}
