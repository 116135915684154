import * as React from 'react'
import type { CardLinkEntry, CardLinkState } from '.'
import {
  codeFromEvent,
  getCurrentCaretPosition,
  isCompleteKey,
  isDirectionalKey,
  isEscapeKey,
  restoreCaretPosition,
} from '../utils/keyboard.web'
import { CardLinkContext } from './context.web'

import type { TextInputCompositionEvent, TextInputFormEvent, TextInputKeyboardEvent } from '../RichTextInput/types.web'
import { CARD_LINK_TEMPLATE } from '../utils/message'

type InputRef = React.RefObject<HTMLDivElement>
interface Props {
  entries: CardLinkEntry[]
  children: React.ReactNode
}

export const CardLinkProvider: React.FC<Props> = (props) => {
  const [input, setInput] = React.useState('')
  const [state, setState] = React.useState<CardLinkState>('idle')
  const [cardLinkListOpen, setCardLinkListOpen] = React.useState(false)
  const [cursorPosition, setCursorPosition] = React.useState<number | null>(null)

  const cardLinkList = React.useMemo(() => {
    return input.length > 0 ? props.entries.filter((entry) => entry.cardNo.indexOf(input) > -1) : props.entries
  }, [props.entries, input])

  const [lastCaretPosition, setLastCaretPosition] = React.useState(-1)

  const resetCaretPosition = React.useCallback(() => {
    setLastCaretPosition(-1)
  }, [])

  const [inputRef, setInputRef] = React.useState<InputRef>()

  const startCardLink = React.useCallback((text: string, users: CardLinkEntry[], _inputRef: InputRef) => {
    console.debug('startCardLink', { text, users })
    // FIXME: 멘션 시작을 다르게 하는 경우 활용하기 위한 변수들
    setCardLinkListOpen(true)
    if (_inputRef?.current) {
      setInputRef(_inputRef)
    }
    setState('start')
  }, [])
  const iterateCardLinkList = React.useCallback(
    (direction: string) => {
      if (cursorPosition === null) {
        setCursorPosition(0)
        return
      } else {
        let nextPosition = cursorPosition
        if (direction === 'prev') {
          nextPosition = cursorPosition - 1
        } else if (direction === 'next') {
          nextPosition = cursorPosition + 1
        }
        if (nextPosition < 0) {
          nextPosition = cardLinkList.length - 1
        } else if (nextPosition >= cardLinkList.length) {
          nextPosition = 0
        }

        setCursorPosition(nextPosition)
      }
    },
    [cursorPosition, setCursorPosition, cardLinkList],
  )
  const pickCardLinkItem = React.useCallback(
    (index: number, completeMention?: boolean) => {
      setCursorPosition(index)
      if (completeMention) setState('matched')
    },
    [setCursorPosition],
  )
  const closeCardLink = React.useCallback(
    (isClear?: boolean) => {
      console.debug('CardLinkProvider:: close mention with cursor:', {
        cursorPosition,
      })
      const onClosed = () => {
        if (cursorPosition !== null) {
          setState('matched')
        } else {
          setState('cancelled')
          setInput('')
        }
      }
      setCardLinkListOpen(false)
      if (typeof isClear === 'undefined') {
        onClosed()
      } else {
        if (isClear) {
          setState('cancelled')
          setInput('')
        }
      }
    },
    [cursorPosition],
  )

  const getKeystroke = React.useCallback((text: string) => {
    console.debug('getKeystroke: ', text)
    if (text === '#') {
      // NOTE: 멘션 상태에서 #을 입력하는 경우, 멘션을 종료한다
      // setState('cancelled')
    } else {
      setInput((s) => s + text)
    }
  }, [])

  const getCompositionStroke = React.useCallback((text: string, composing: boolean) => {
    console.debug('getCompositionStroke: ', { text, composing })
    if (composing === false) {
      setInput((s) => s + text)
    }
  }, [])

  const matched = React.useMemo(() => {
    if (state === 'matched' || state === 'finish') {
      if (cursorPosition === null) return []
      const entry = cardLinkList[cursorPosition]
      if (entry === null || entry === undefined) return []
      const keyword = cardLinkList[cursorPosition].cardNo
      console.debug('completion with keyword: ', keyword)

      const matched = cardLinkList
        .filter((e) => {
          return e.cardNo == keyword
        })
        .map((e) => e.cardNo)

      console.debug('matched: ', matched)

      return matched
    } else {
      return []
    }
  }, [cursorPosition, cardLinkList, state])

  const doneMatching = React.useCallback(() => {
    console.debug('done matching')
    setState('finish')
  }, [])

  const startMatching = React.useCallback(() => {
    console.debug('start matching')
    setState('composing')
    if (inputRef?.current) {
      restoreCaretPosition(inputRef.current, lastCaretPosition)
    }
  }, [inputRef, lastCaretPosition])

  const finishCardLink = React.useCallback(() => {
    // insert selected mention,
    // restore caret position
    console.debug('finish card link')
    // if(ref === null || inputRef.current === null) return
    // reset
    setInput('')
    setState('idle')
    setCursorPosition(null)
    setLastCaretPosition(-1)
  }, [])

  const getBackspaceStroke = React.useCallback(() => {
    // remove last character
    console.debug('backspace stroke', { input })
    if (input.length > 0) {
      setInput((s) => s.slice(0, -1))
    } else {
      closeCardLink()
    }
  }, [closeCardLink, input])

  const forwardKeyEvent = React.useCallback(
    (e: TextInputKeyboardEvent) => {
      const keyCode: string = codeFromEvent(e)
      console.debug('forward key event: ', {
        nativeEvent: e.nativeEvent,
        keyCode,
      })

      // if escaping, close mention list
      if (isEscapeKey(keyCode)) {
        // NOTE: 멘션에서만 나가도록 처리
        closeCardLink()
      } else if (isCompleteKey(keyCode)) {
        // if complete key, complete mention
        closeCardLink()
      } else if (isDirectionalKey(keyCode)) {
        // if directional key, iterate mention list
        // up = prev, down = next
        if (keyCode === 'ArrowUp' || keyCode === 'ArrowDown') {
          const direction = keyCode === 'ArrowUp' ? 'prev' : 'next'
          iterateCardLinkList(direction)
          e.preventDefault()
        }
      }
    },
    [closeCardLink, iterateCardLinkList],
  )

  const forwardFormEvent = React.useCallback(
    (e: TextInputFormEvent) => {
      if (e.nativeEvent.type == 'input') {
        const inputEvent = e.nativeEvent as InputEvent
        if (inputEvent.inputType === 'deleteContentBackward' && inputEvent.isComposing == false) {
          getBackspaceStroke()
        }
        if (inputEvent.data && inputEvent.isComposing == false) {
          getKeystroke(inputEvent.data)
        }
      }
    },
    [getBackspaceStroke, getKeystroke],
  )

  const forwardCompositionEvent = React.useCallback(
    (e: TextInputCompositionEvent, isComposing: boolean) => {
      getCompositionStroke(e.data, isComposing)
    },
    [getCompositionStroke],
  )

  const forwardBlurEvent = React.useCallback(() => {
    console.debug('blur')
    if (state === 'composing') {
      if (inputRef?.current && inputRef?.current) {
        const caretPosition = getCurrentCaretPosition(inputRef?.current)
        setLastCaretPosition(caretPosition)
        restoreCaretPosition(inputRef?.current, caretPosition)
      }
    }
  }, [inputRef, state])

  const query = React.useCallback((text: string) => {
    // update input by params
    setInput(text.replace('#', ''))
  }, [])

  React.useEffect(() => {
    console.debug(':::::CardLinkProvider::::: state: ', state)
    if (state === 'start') {
      if (inputRef?.current && inputRef?.current) {
        setLastCaretPosition(getCurrentCaretPosition(inputRef?.current))
      }
    } else if (state === 'composing') {
      // if (inputRef?.current && inputRef?.current) {
      //   setLastCaretPosition(getCurrentCaretPosition(inputRef?.current))
      // }
    } else if (state === 'picked') {
      setCardLinkListOpen(false)
      setState('matched')
    } else if (state === 'cancelled') {
      setState('finish')
    } else if (state === 'matched') {
      setLastCaretPosition(-1)
      setState('finish')
    } else if (state === 'finish') {
      // reset?
    }
  }, [state, inputRef, lastCaretPosition, matched, input])

  React.useEffect(() => {
    console.debug('input changed: ', input, lastCaretPosition)
    if (lastCaretPosition > -1 && input.length > 0) {
      if (inputRef?.current && inputRef?.current) {
        if (inputRef?.current) restoreCaretPosition(inputRef?.current)
      }
      resetCaretPosition()
    }
  }, [input, inputRef, lastCaretPosition, resetCaretPosition])

  React.useEffect(() => {
    console.debug('MentionProvider:: mentionListOpen changed: ', cardLinkListOpen)
  }, [cardLinkListOpen])

  return (
    <CardLinkContext.Provider
      value={{
        state: state,
        input,
        matched,
        cardLinkList,
        cardLinkListOpen,
        startCardLink,
        cursorPosition,
        iterateCardLinkList,
        forwardKeyEvent,
        forwardBlurEvent,
        forwardFormEvent,
        forwardCompositionEvent,
        closeCardLink,
        startMatching,
        doneMatching,
        finishCardLink,
        getKeystroke,
        getBackspaceStroke,
        getCompositionStroke,
        pickCardLinkItem,
        query,
        CARD_LINK_TEMPLATE,
      }}
    >
      {props.children}
    </CardLinkContext.Provider>
  )
}
