import React from 'react'
import { StyleSheet, View } from 'react-native'

import { faGripVertical, faXmark } from '@fortawesome/free-solid-svg-icons'

import { Text } from '@rocket-atoms/text'
import { FAIcon } from '@rocket-atoms/icon'
import { COLOR, useMobileView } from '@rocket-mono/libs'

import { SkeletonBar, SkeletonSquare } from '../SkeletomParts'

const SkeletonEditWorkModal: React.FC = () => {
  const mobileView = useMobileView(980)
  return (
    <View style={[styles.container, !!mobileView && styles.mobileContainer]}>
      <View style={[styles.workInfoSection, !mobileView && { minHeight: 300 }]}>
        <View>
          <View>
            <View style={[{ marginBottom: 6 }]}>
              <SkeletonBar width={55} height={16} />
            </View>
            <SkeletonSquare width={'100%'} height={36} />
            <View style={{ height: 20 }}></View>
          </View>
          <View style={[styles.labelSection, mobileView ? { paddingBottom: 50 } : { paddingBottom: 32 }]}>
            <View>
              <View style={[{ marginBottom: 6 }]}>
                <SkeletonBar width={66} height={16} />
              </View>
            </View>

            {/* item */}
            <View style={{ marginBottom: 8 }}>
              <View style={styles.itemGroup}>
                <View style={styles.dragIcon}>
                  <View style={{ width: 28 }}>
                    <FAIcon iconName={faGripVertical} size={'2xs'} color={'gray.g150'} />
                  </View>
                </View>
                <View style={[styles.inputGroup]}>
                  <View style={{ position: 'absolute', top: 4, left: 4 }}>
                    <SkeletonSquare width={28} height={28} />
                  </View>
                  <SkeletonSquare width={'100%'} height={36} />
                  <View style={{ width: 32 }}>
                    <View style={[styles.deleteArea]}>
                      <View style={[styles.removeIcon]}>
                        <FAIcon iconName={faXmark} size={'2xs'} color={'mono.white'} />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            {/* item */}
            <View style={{ marginBottom: 8 }}>
              <View style={styles.itemGroup}>
                <View style={styles.dragIcon}>
                  <View style={{ width: 28 }}>
                    <FAIcon iconName={faGripVertical} size={'2xs'} color={'gray.g150'} />
                  </View>
                </View>
                <View style={[styles.inputGroup]}>
                  <View style={{ position: 'absolute', top: 4, left: 4 }}>
                    <SkeletonSquare width={28} height={28} />
                  </View>
                  <SkeletonSquare width={'100%'} height={36} />
                  <View style={{ width: 32 }}>
                    <View style={[styles.deleteArea]}>
                      <View style={[styles.removeIcon]}>
                        <FAIcon iconName={faXmark} size={'2xs'} color={'mono.white'} />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            {/* item */}
            <View style={{ marginBottom: 8 }}>
              <View style={styles.itemGroup}>
                <View style={styles.dragIcon}>
                  <View style={{ width: 28 }}>
                    <FAIcon iconName={faGripVertical} size={'2xs'} color={'gray.g150'} />
                  </View>
                </View>
                <View style={[styles.inputGroup]}>
                  <View style={{ position: 'absolute', top: 4, left: 4 }}>
                    <SkeletonSquare width={28} height={28} />
                  </View>
                  <SkeletonSquare width={'100%'} height={36} />
                  <View style={{ width: 32 }}>
                    <View style={[styles.deleteArea]}>
                      <View style={[styles.removeIcon]}>
                        <FAIcon iconName={faXmark} size={'2xs'} color={'mono.white'} />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            {/* item */}
            <View style={{ marginBottom: 8 }}>
              <View style={styles.itemGroup}>
                <View style={styles.dragIcon}>
                  <View style={{ width: 28 }}>
                    <FAIcon iconName={faGripVertical} size={'2xs'} color={'gray.g150'} />
                  </View>
                </View>
                <View style={[styles.inputGroup]}>
                  <View style={{ position: 'absolute', top: 4, left: 4 }}>
                    <SkeletonSquare width={28} height={28} />
                  </View>
                  <SkeletonSquare width={'100%'} height={36} />
                  <View style={{ width: 32 }}>
                    <View style={[styles.deleteArea]}>
                      <View style={[styles.removeIcon]}>
                        <FAIcon iconName={faXmark} size={'2xs'} color={'mono.white'} />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.bottomFixedSection}>
        <View style={styles.dateSection}>
          <View style={[styles.flexBox, { alignItems: 'center' }]}>
            <View>
              <SkeletonBar width={150} height={16} />
            </View>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 8 }}>
            <View style={{ flex: 1 }}>
              <SkeletonSquare width={'100%'} height={36} />
            </View>
            <View>
              <Text fontColor="gray.g200"> ~ </Text>
            </View>
            <View style={{ flex: 1 }}>
              <SkeletonSquare width={'100%'} height={36} />
            </View>
          </View>
        </View>
        <View style={{ marginTop: 36 }}>
          <SkeletonBar width={'100%'} height={32} />
        </View>
      </View>
    </View>
  )
}

export default SkeletonEditWorkModal

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal:24,
    marginTop: 56
  },
  mobileContainer: {
    flexDirection: 'column',
  },
  workInfoSection: {
    flex: 1,
  },
  flexBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  labelSection: {
    marginTop: 12,
  },
  dateSection: {
    borderTopWidth: 1,
    borderColor: COLOR.gray.g200,
    paddingTop: 12,
  },
  bottomFixedSection: {
    justifyContent: 'flex-end',
  },
  itemGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: COLOR.mono.white,
  },
  dragIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
  },
  inputGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  deleteArea: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
  },
  removeIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 16,
    height: 16,
    backgroundColor: COLOR.gray.g200,
    borderRadius: 30,
  },
})
