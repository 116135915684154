import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { RecoilRoot } from 'recoil'
import { i18n } from 'i18next'
interface Props {
  i18n: i18n
  children?: JSX.Element | JSX.Element[]
}
const Providers: React.FC<Props> = ({ i18n, children }) => {
  return (
    <RecoilRoot>
      <I18nextProvider i18n={i18n}>
        <>{children}</>
      </I18nextProvider>
    </RecoilRoot>
  )
}

export default Providers
