import { useAstro } from '@rocket-mono/providers'
import { CurrentUserProvider } from '@rocket/components'
import { Skeleton } from '@rocket/skeleton'
import * as React from 'react'
import { Platform } from 'react-native'
import ComponentsBaseProviders from './components-base'

interface Props {
  children?: JSX.Element
}

const ComponentsProviders: React.FC<Props> = ({ children }) => {
  const { astro } = useAstro()

  return (
    <CurrentUserProvider
      astro={astro}
      skeletonComponent={Platform.OS === 'web' ? <Skeleton path={location.pathname} /> : <></>}
    >
      <ComponentsBaseProviders>
        <>{children}</>
      </ComponentsBaseProviders>
    </CurrentUserProvider>
  )
}

export default ComponentsProviders
