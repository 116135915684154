import { useCallback, useEffect, useMemo, useState } from 'react'
// import { useToast } from '..'

interface Props<T1, T2> {
  initialValues: T1
  onSubmit: (values: T1) => Promise<string>
  validate: (values: T1) => T2
}
export type FormProcType = {
  onSuccess?: (id: string) => void
  onFailed?: () => void
}
export const useForm = <T1, T2>({ initialValues, onSubmit, validate }: Props<T1, T2>) => {
  const [changeValueKey, setChangeValueKey] = useState<string[]>([])
  const [proc, setProc] = useState<FormProcType>()
  const [values, setValues] = useState<T1>(initialValues)
  const [errors, setErrors] = useState<T2>()
  const [isLoading, setIsLoading] = useState(false)

  const isChangeValue = useMemo(() => changeValueKey.length > 0, [changeValueKey])

  const handleChange = useCallback(
    (name: string, value: unknown) => {
      if (initialValues[name] === value) {
        setChangeValueKey((prev) => [...prev.filter((o) => o !== name)])
      } else {
        setChangeValueKey((prev) => [...prev.filter((o) => o !== name), name])
      }
      setValues((values) => ({ ...values, [name]: value }))
    },
    [values],
  )

  const handleSubmit = (proc?: FormProcType) => {
    setProc(proc)
    setIsLoading(true)
    setErrors(validate(values))
  }

  const handleSuccess = (id: string) => {
    setChangeValueKey([])
    if (proc && proc.onSuccess) proc.onSuccess(id)
  }

  const handleFailed = () => {
    if (proc && proc.onFailed) proc.onFailed()
  }

  useEffect(() => {
    if (isLoading && errors) {
      console.log(isLoading, errors)
      if (Object.keys(errors).length === 0) {
        onSubmit(values).then(handleSuccess).catch(handleFailed)
      } else {
        const message = Object.values(errors)[0] as string
        // showToastMessage({
        //   message,
        //   type: 'error',
        //   viewPosition: 'TOP_RIGHT',
        // })
      }
      setIsLoading(false)
    }
  }, [proc, isLoading, errors, values, onSubmit])

  useEffect(() => {
    console.log('useeffect.isChangeValue', { isChangeValue })
  }, [isChangeValue])

  return {
    values,
    errors,
    isChangeValue,
    isLoading,
    handleChange,
    handleSubmit,
    setValues,
  }
}
