import React from 'react'
import { StyleSheet, View } from 'react-native'
import { FAIcon } from '@rocket-atoms/icon'
import { COLOR } from '@rui/foundations'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const HoverIndication: React.FC = () => {
  return (
    <View style={styles.hoverIndi}>
      <FAIcon iconName={faArrowRight} size={'sm'} color={'gray.g600'} />
    </View>
  )
}

export default HoverIndication

const styles = StyleSheet.create({
  hoverIndi: {
    position: 'absolute',
    top: '50%',
    right: 20,
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 28,
    marginTop: -14,
    borderRadius: 28,
    backgroundColor: COLOR.gray.g100,
  },
})
