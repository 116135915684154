import React from 'react'
import { StyleSheet, View } from 'react-native'

import { useTranslation } from 'react-i18next'

import { COLOR, FONT } from '@rui/foundations'
import { WorkIcon } from '@rocket-atoms/icon'
import { Text } from '@rocket-atoms/text'

import { SkeletonBar, SkeletonSquare } from '@rocket/skeleton'

import Container from '../container'
import { ItemWorkType } from '../types'

export interface ActivitySearchItemWorkProps {
  work?: ItemWorkType
  archive?: boolean
  onPress?: () => void
}

const ActivitySearchItemWork: React.FC<ActivitySearchItemWorkProps> = ({ work, archive = false, onPress }) => {
  const { t } = useTranslation()

  if (work === undefined) {
    return (
      <View style={styles.container}>
        <View style={styles.iconArea}>
          <SkeletonSquare width={24} height={24} color={'gray.g200'} />
        </View>
        <SkeletonBar width={'70%'} height={16} />
      </View>
    )
  }

  return (
    <Container onPress={onPress} style={[styles.container]}>
      <View style={styles.iconArea}>
        <WorkIcon type={work.type} size={20} />
      </View>
      {archive && (
        <View style={styles.block}>
          <Text style={styles.blockText}>{t('workinvite.memberoption.board.archive')}</Text>
        </View>
      )}
      <Text style={styles.labelStyle}>{work.name}</Text>
    </Container>
  )
}

export default ActivitySearchItemWork

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 20,
  },
  iconArea: {
    marginRight: 4,
  },
  labelStyle: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g900,
  },
  block: {
    padding: 4,
    backgroundColor: COLOR.gray.g050,
    borderRadius: 4,
    marginRight: 4,
  },
  blockText: {
    ...FONT.txt2Xs,
    color: COLOR.gray.g600,
  },
  hoverIndi: {
    position: 'absolute',
    top: '50%',
    right: 20,
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 28,
    borderRadius: 28,
    backgroundColor: COLOR.gray.g100,
  },
})
