'use client'
"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  BoardScreen: () => BoardScreen_default,
  MenuScreen: () => MenuScreen_default,
  OrderAdditionalScreen: () => OrderAdditionalScreen_default,
  OrderCustomerScreen: () => OrderCustomerScreen_default
});
module.exports = __toCommonJS(src_exports);

// src/screens/BoardScreen.tsx
var import_providers = require("@rocket-mono/providers");
var import_components = require("@rocket/components");

// src/providers.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var Providers = ({ children }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_jsx_runtime.Fragment, { children });
};
var providers_default = Providers;

// src/screens/BoardScreen.tsx
var import_jsx_runtime2 = require("react/jsx-runtime");
var BoardScreen = ({ shopId }) => {
  const { astro } = (0, import_providers.useAstro)();
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(providers_default, { children: /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(import_components.TableorderBoardScreen, { shopId, astro, subscribe: import_providers.useSubscription }) });
};
var BoardScreen_default = BoardScreen;

// src/screens/MenuScreen/view.tsx
var import_providers3 = require("@rocket-mono/providers");
var import_components2 = require("@rocket/components");
var import_jsx_runtime3 = require("react/jsx-runtime");
var MenuView = ({ shopId, ...blockProps }) => {
  const { astro } = (0, import_providers3.useAstro)();
  const { currentUser } = (0, import_components2.useCurrentUser)();
  if (currentUser === void 0)
    return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(import_jsx_runtime3.Fragment, {});
  return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(import_components2.TableorderMenuScreen, { shopId, astro, currentUser, ...blockProps });
};
var view_default = MenuView;

// src/screens/MenuScreen/index.tsx
var import_jsx_runtime4 = require("react/jsx-runtime");
var MenuScreen = ({ shopId, ...blockProps }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(providers_default, { children: /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(view_default, { shopId, ...blockProps }) });
};
var MenuScreen_default = MenuScreen;

// src/screens/OrderAdditionalScreen.tsx
var React = __toESM(require("react"));
var import_components3 = require("@rocket/components");
var import_react_div_100vh = require("react-div-100vh");
var import_providers5 = require("@rocket-mono/providers");
var import_jsx_runtime5 = require("react/jsx-runtime");
var OrderAdditionalScreen = ({ shopId, tableId }) => {
  const { astro } = (0, import_providers5.useAstro)();
  const height = (0, import_react_div_100vh.use100vh)();
  const handlePressClose = React.useCallback(() => {
    window.close();
  }, []);
  if (!tableId)
    return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(import_jsx_runtime5.Fragment, {});
  return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(providers_default, { children: /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(import_components3.View, { style: { height: height || "100%" }, children: /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(
    import_components3.TableorderOrderAdditionalScreen,
    {
      shopId,
      tableId,
      astro,
      subscribe: import_providers5.useSubscription,
      onPressClose: handlePressClose
    }
  ) }) });
};
var OrderAdditionalScreen_default = OrderAdditionalScreen;

// src/screens/OrderCustomerScreen.tsx
var import_providers7 = require("@rocket-mono/providers");
var import_components4 = require("@rocket/components");
var import_react_div_100vh2 = require("react-div-100vh");
var import_jsx_runtime6 = require("react/jsx-runtime");
var OrderCustomerScreen = ({ shopId, tableId }) => {
  const { astro } = (0, import_providers7.useAstro)();
  const height = (0, import_react_div_100vh2.use100vh)();
  if (!tableId)
    return /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_jsx_runtime6.Fragment, {});
  return /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(providers_default, { children: /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_components4.View, { style: { height: height || "100%" }, children: /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_components4.TableorderOrderCustomerScreen, { astro, subscribe: import_providers7.useSubscription, shopId, tableId }) }) });
};
var OrderCustomerScreen_default = OrderCustomerScreen;
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  BoardScreen,
  MenuScreen,
  OrderAdditionalScreen,
  OrderCustomerScreen
});
