import React from 'react'
import { View, Text, StyleSheet, Pressable, TextInput } from 'react-native'

import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { COLOR, FONT, getRealColor } from '@rocket-mono/libs'
import { FAIcon } from '@rocket-atoms/icon'

export interface LabelFieldProps {
  type?: 'DEFAULT' | 'SECONDARY'
  editable?: boolean
  label: string | JSX.Element
  value: string
  hasBorder?: boolean
  placeholder?: string
  isDisabled?: boolean
  iconName?: IconProp
  onChangeValue?: (value: string) => void
  onPress?: () => void
}

export function LabelField({
  type = 'DEFAULT',
  editable = false,
  label,
  value,
  hasBorder = true,
  placeholder,
  isDisabled = false,
  iconName,
  onChangeValue,
  onPress,
}: LabelFieldProps) {
  const [isPressed, setIsPressed] = React.useState(false)
  const [isHovered, setIsHovered] = React.useState(false)
  const [isFocused, setIsFocused] = React.useState(false)
  const handleChange = (text: string) => {
    if (onChangeValue) {
      onChangeValue(text)
    }
  }
  const handleFocus = () => {
    setIsFocused(true)
  }
  const handleBlur = () => {
    setIsFocused(false)
  }
  const Containerstyle = [
    styles.container,
    isPressed && styles.pressed,
    isHovered && styles.hovered,
    isDisabled && styles.disabled,
    hasBorder && styles.border,
    hasBorder && styles.borderContainer,
    type === 'SECONDARY' && styles.secondaryContainer,
    type === 'SECONDARY' && isHovered && styles.secondaryHovered,
    type === 'SECONDARY' && isPressed && styles.secondaryPressed,
  ]
  const wrapperStyle = [styles.wrapper, isFocused && styles.focused]

  const inputStyle = {
    ...styles.input,
    color: value ? COLOR.mono.black : COLOR.gray.g400,
  }

  const renderInput = () => {
    if (editable) {
      return (
        <TextInput
          style={inputStyle}
          value={value}
          onChangeText={handleChange}
          editable={!isDisabled}
          placeholder={value ? value : placeholder}
          placeholderTextColor={getRealColor('gray.g450')}
        />
      )
    } else {
      return <Text style={inputStyle && styles.notEditable}>{value ? value : placeholder}</Text>
    }
  }

  return (
    <View style={wrapperStyle}>
      <Pressable
        onPress={onPress}
        onHoverIn={() => setIsHovered(true)}
        onHoverOut={() => setIsHovered(false)}
        onPressIn={() => setIsPressed(true)}
        onPressOut={() => setIsPressed(false)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={isDisabled}
      >
        <View style={Containerstyle} testID="label-field">
          <View style={styles.rowContainer}>
            {iconName && (
              <View style={styles.iconContainer}>
                <FAIcon iconName={iconName} size={'xs'} color={'gray.g400'} />
              </View>
            )}
            <Text style={styles.label}>{label}</Text>
          </View>
          {renderInput()}
        </View>
      </Pressable>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 182,
    height: 64,
    paddingTop: 8,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
    backgroundColor: COLOR.mono.white,
  },
  border: {
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    borderRadius: 4,
  },
  borderContainer: {
    paddingTop: 7,
    paddingBottom: 3,
    paddingLeft: 7,
    paddingRight: 7,
  },
  secondaryContainer: {
    borderRadius: 4,
    backgroundColor: COLOR.gray.g050,
  },
  wrapper: {
    borderWidth: 2,
    borderColor: COLOR.transparent.base,
    width: 190,
    height: 72,
    borderRadius: 4,
    padding: 2,
  },
  focused: {
    borderColor: COLOR.primary.blue400,
  },
  hovered: {
    borderRadius: 4,
    backgroundColor: COLOR.gray.g050,
  },
  secondaryHovered: {
    borderRadius: 4,
    backgroundColor: COLOR.gray.g100,
  },
  pressed: {
    borderRadius: 4,
    backgroundColor: COLOR.gray.g050,
  },
  secondaryPressed: {
    borderRadius: 4,
    backgroundColor: COLOR.gray.g100,
  },
  disabled: {
    borderRadius: 4,
    backgroundColor: COLOR.opacity.blk05,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    ...FONT.txtXs,
    color: COLOR.gray.g400,
  },
  input: {
    width: 166,
    height: 36,
  },
  iconContainer: {
    marginRight: 4,
  },
  notEditable: {
    paddingTop: 10,
  },
})

export default LabelField
