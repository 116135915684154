import * as React from 'react'
import {
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleProp,
  ViewStyle,
} from 'react-native'

import { View } from '@rocket-mono/foundations'

interface Props {
  scrollEnabled?: boolean
  containerStyle?: StyleProp<ViewStyle>
  onScroll?: (e: NativeSyntheticEvent<NativeScrollEvent>) => void
  onLayout?: (e: LayoutChangeEvent) => void
  children?: React.ReactNode
}

export const DynamicScrollView: React.FC<Props> = ({
  scrollEnabled = true,
  containerStyle,
  onScroll,
  onLayout,
  children,
}) => {
  if (scrollEnabled) {
    return (
      <ScrollView
        style={{ width: '100%' }}
        contentContainerStyle={[containerStyle]}
        scrollEventThrottle={1}
        onScroll={onScroll}
      >
        {children}
      </ScrollView>
    )
  } else {
    return (
      <View style={containerStyle} onLayout={onLayout}>
        {children}
      </View>
    )
  }
}
