import * as React from 'react'
import { Platform, Pressable, ScrollView, ScrollViewProps, StyleSheet, View } from 'react-native'

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FAIcon } from '@rocket-atoms/icon'
import { Tooltip } from '@rocket-atoms/tooltip'
import { COLOR, IColors, getRealColor } from '@rocket-mono/libs'
import BaseItem from './SelectItem/BaseItem'
import SelectAllItem from './SelectItem/SelectAllItem'

interface Props extends Omit<ScrollViewProps, 'children'> {
  initialSelectedIndexes?: number[]
  initialDisabledIndexes?: number[]
  disabledReason?: string
  dividerColor?: IColors
  isCheckbox?: boolean
  selectedBackground?: IColors
  onPressItem?: (index: number) => void
  onPressSelectAllItem?: (selectedAll: boolean) => void
  children?:
    | React.ReactElement<{ selected: boolean; isCheckbox?: boolean }>
    | React.ReactElement<{ selected: boolean; isCheckbox?: boolean }>[]
    | (JSX.Element | JSX.Element[])[]
}

const SelectList = ({
  initialSelectedIndexes,
  initialDisabledIndexes,
  disabledReason,
  selectedBackground,
  dividerColor = COLOR.mono.white as IColors,
  isCheckbox,
  onPressItem,
  onPressSelectAllItem,
  children,
  style,
  ...props
}: Props) => {
  const items = useConvertToOneDepthReactElementArr(children)
  const { selectedIndexes, disabledIndexes, isSelectedAll, _onPressItem, _onPressSelectAllItem } = useSelected({
    items,
    initialSelectedIndexes,
    initialDisabledIndexes,
    onPressItem,
    onPressSelectAllItem,
  })

  return (
    <View style={[styles.container, style]}>
      <ScrollView {...props}>
        {items.map(
          (
            ChildComponent: React.ReactElement<{
              selected: boolean
              isCheckbox?: boolean
            }>,
            index,
          ) => {
            const isLast = items.length - 1 === index
            const borderBottomColor = isLast ? 'transparent' : dividerColor
            const selected = selectedIndexes.has(index)
            const disabled = disabledIndexes?.has(index)
            const backgroundColor = selectedBackground
              ? isSelectedAll || selected
                ? getRealColor(selectedBackground)
                : COLOR.mono.white
              : isSelectedAll || selected
              ? COLOR.primary.blue05
              : COLOR.mono.white
            const onPress = ChildComponent.type === SelectAllItem ? _onPressSelectAllItem : _onPressItem(index)
            return (
              <Pressable
                key={index}
                style={[
                  styles.pressable,
                  {
                    backgroundColor,
                    borderBottomColor,
                  },
                  disabled && { backgroundColor: COLOR.opacity.blk05 },
                ]}
                onPress={disabled ? undefined : onPress}
              >
                <>
                  {React.cloneElement(ChildComponent, {
                    selected: isSelectedAll || selected,
                    isCheckbox: isCheckbox,
                  })}
                  {disabled && (
                    <Tooltip darkType direction={'bottom'} text={disabledReason}>
                      {(props) => (
                        <View {...props}>
                          <FAIcon iconName={faInfoCircle} size={'xs'} color={'gray.g600'} />
                        </View>
                      )}
                    </Tooltip>
                  )}
                </>
              </Pressable>
            )
          },
        )}
      </ScrollView>
    </View>
  )
}

SelectList.Item = BaseItem
SelectList.SelectAllItem = SelectAllItem

export default SelectList

const useConvertToOneDepthReactElementArr = (
  children?:
    | React.ReactElement<{ selected: boolean; isCheckbox?: boolean }>
    | React.ReactElement<{ selected: boolean; isCheckbox?: boolean }>[]
    | (JSX.Element | JSX.Element[])[],
): React.ReactElement<{ selected: boolean; isCheckbox?: boolean }>[] => {
  return React.useMemo(() => {
    let result: React.ReactElement<{ selected: boolean; isCheckbox?: boolean }>[]
    if (Array.isArray(children)) {
      result = children.flat(1)
    } else if (!children) {
      result = []
    } else {
      result = [children]
    }
    return result
  }, [children])
}

interface UseSelectedArgs {
  items: React.ReactElement<{ selected: boolean; isCheckbox?: boolean }>[]
  initialSelectedIndexes?: number[]
  initialDisabledIndexes?: number[]
  onPressItem?: (index: number) => void
  onPressSelectAllItem?: (selectedAll: boolean) => void
}

const useSelected = ({
  items,
  initialSelectedIndexes,
  initialDisabledIndexes,
  onPressItem,
  onPressSelectAllItem,
}: UseSelectedArgs) => {
  const hasSelectAllItem = React.useMemo(() => {
    return !!items.find((Component) => Component.type === SelectAllItem)
  }, [items])

  const [selectedIndexes, setSelectedIndexes] = React.useState<Set<number>>(
    hasSelectAllItem
      ? convertArrToSetExcludingSelectAllItem(initialSelectedIndexes)
      : convertArrToSet(initialSelectedIndexes),
  )

  const [disabledIndexes, setDisabledIndexes] = React.useState<Set<number>>()

  const [isSelectedAll, setSelectedAll] = React.useState(false)

  React.useEffect(() => {
    if (!initialSelectedIndexes) {
      return
    }

    syncSelectedIndexes()
    setDisabledIndexes(convertArrToSet(initialDisabledIndexes))

    function syncSelectedIndexes() {
      const newSelectedIndexes = hasSelectAllItem
        ? convertArrToSetExcludingSelectAllItem(initialSelectedIndexes)
        : convertArrToSet(initialSelectedIndexes)
      setSelectedIndexes(newSelectedIndexes)
      setSelectedAll(hasSelectAllItem && newSelectedIndexes.size === items.length - 1)
    }
  }, [initialSelectedIndexes, hasSelectAllItem, items.length])

  const totalSelectedIndexes = React.useMemo(() => {
    return new Set(
      new Array(items.length)
        .fill(0)
        .map((_, index) => index)
        .slice(1),
    )
  }, [])

  const _onPressItem = (index: number) => () => {
    const copy = new Set(selectedIndexes)
    if (copy.has(index)) {
      copy.delete(index)
    } else {
      copy.add(index)
    }
    setSelectedIndexes(copy)
    setSelectedAll(hasSelectAllItem && copy.size === items.length - 1)
    onPressItem && onPressItem(hasSelectAllItem ? index - 1 : index)
  }

  const _onPressSelectAllItem = () => {
    if (isSelectedAll) {
      setSelectedIndexes(new Set([]))
    } else {
      setSelectedIndexes(totalSelectedIndexes)
    }
    onPressSelectAllItem && onPressSelectAllItem(!isSelectedAll)
    setSelectedAll(!isSelectedAll)
  }

  return { isSelectedAll, selectedIndexes, disabledIndexes, _onPressItem, _onPressSelectAllItem }
}

const convertArrToSet = (arr: number[] = []) => {
  return new Set(arr)
}

const convertArrToSetExcludingSelectAllItem = (arr: number[] = []) => {
  return new Set(arr.map((index) => index + 1))
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR.mono.white,
  },
  pressable: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 8,
    width: '100%',
    height: 32,
    borderBottomWidth: 1,
    ...Platform.select({
      web: {
        paddingVertical: 12,
      },
    }),
  },
})
