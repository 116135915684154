import React, { useEffect } from 'react'
import { ScrollView } from 'react-native'

import { ResultViewProps } from './types'
import { SearchResultWorkView } from './work'
import { SearchResultChatView } from './chat'
import { SearchResultMessageView } from './message'
import { SearchResultCardView } from './card'
import { SearchResultFileView } from './file'
import {
  IndexedBoardType,
  IndexedChatMessageType,
  IndexedProjectType,
  SearchCardType,
  SearchFileType,
} from '../../types'
import { useSearchScreen } from '../../provider'

interface Props extends ResultViewProps {
  workList?: IndexedProjectType[]
  chatList?: IndexedBoardType[]
  messageList?: IndexedChatMessageType[]
  cardList?: SearchCardType[]
  fileList?: SearchFileType[]
}

export const SearchResultView: React.FC<Props> = ({
  workList,
  chatList,
  messageList,
  cardList,
  fileList,
  ...viewProps
}) => {
  const { searchWorkState, searchChatState, searchMessageState, searchCardState, searchFileState } = useSearchScreen()

  return (
    <ScrollView style={{ flex: 1, paddingBottom: 32 }}>
      {workList !== undefined && <SearchResultWorkView {...viewProps} list={workList} searchState={searchWorkState} />}
      {chatList !== undefined && <SearchResultChatView list={chatList} {...viewProps} searchState={searchChatState} />}
      {messageList !== undefined && (
        <SearchResultMessageView list={messageList} {...viewProps} searchState={searchMessageState} />
      )}
      {cardList !== undefined && <SearchResultCardView list={cardList} {...viewProps} searchState={searchCardState} />}
      {fileList !== undefined && <SearchResultFileView list={fileList} {...viewProps} searchState={searchFileState} />}
    </ScrollView>
  )
}
