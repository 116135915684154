import { Icon } from '@rocket-atoms/icon'
import { FONT, IColors, IconName, getRealColor } from '@rocket-mono/libs'
import React from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'

interface Props {
  icon: IconName
  iconColor?: IColors
  text: string
  textColor?: IColors
}
const SummaryLabel: React.FC<Props> = ({ icon, iconColor = 'gray.g500', text, textColor = 'gray.g500' }) => {
  return (
    <View style={[styles.tabLabel, styles.labelIcon]}>
      <Icon name={icon} color={iconColor} size={11} style={Platform.OS === 'web' ? { marginTop: -2 } : {}} />
      <Text style={[styles.labelText, { color: getRealColor(textColor) }]}>{text}</Text>
    </View>
  )
}

export default SummaryLabel

const styles = StyleSheet.create({
  tabLabel: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelText: {
    ...FONT.txtXs,
    marginLeft: 1,
    ...Platform.select({
      web: {
        lineHeight: 12,
      },
    }),
  },
  labelIcon: {
    marginLeft: 8,
  },
})
