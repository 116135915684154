import * as React from 'react'
import { StyleSheet, Platform } from 'react-native'
import Button from '../Button'
import Text from '../Text'
import { COLOR, FONT } from '@rocket-mono/libs'
interface Props {
  name: string
  buttonColor: 'main.blue' | 'mono.white'
  onPress?: () => void
}

/**
 * @deprecated
 */
export const DialogButton: React.FC<Props> = ({ name, buttonColor, onPress }) => {
  return (
    <Button
      onPress={onPress}
      backgroundColor={buttonColor}
      borderColor={buttonColor === 'mono.white' ? 'mono.gray' : undefined}
      size="large"
      style={[
        styles.button,
        {
          ...Platform.select({
            native: {
              height: 44,
            },
          }),
        },
      ]}
    >
      <Text
        fontColor={buttonColor === 'mono.white' ? 'gray.g700' : 'mono.white'}
        style={{
          ...Platform.select({
            web: {
              ...FONT.txtMd,
            },
            native: {
              ...FONT.txtMd,
            },
          }),
        }}
      >
        {name}
      </Text>
    </Button>
  )
}

const styles = StyleSheet.create({
  button: {
    minWidth: '100%',
    height: 40,
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 8,
    marginVertical: 4,
    color: COLOR.mono.white,
  },
  buttonCancel: {
    width: '100%',
    marginTop: 12,
  },
})
