import * as React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { COLOR, FONT } from '@rui/foundations'
import GuideCloseButton from '../GuideCloseButton'

interface Props {
  guide: string
  onPressClose: () => void
}

const GuideComment: React.FC<Props> = ({ guide, onPressClose }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.guideText}>{guide}</Text>
      <View style={{ marginLeft: 12 }}>
        <GuideCloseButton onPress={onPressClose} />
      </View>
    </View>
  )
}

export default GuideComment

const styles = StyleSheet.create({
  container: {
    alignSelf: 'flex-start',
    height: 32,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
    paddingLeft: 12,
    paddingRight: 8,
    borderRadius: 4,
    backgroundColor: COLOR.darkmode.warm800,
  },
  guideText: {
    flex: 1,
    ...FONT.txtXsMedium,
    color: COLOR.gray.g400,
  },
})
