import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import { Icon, NoContentsIcon } from '@rocket-atoms/icon'
import AxisButton from '../../../../components/AxisButton'

import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd'
import type { CardGatheringItemType } from '../CardGatheringEditItem'
import CardGatheringEditItem from '../CardGatheringEditItem'

interface Props {
  list: CardGatheringItemType[]
  onChangeItemAtIndex: (from: number, to: number) => void
  onGatheringsChangeText?: (text: string, index: number) => void
  onPressAdd: () => void
}

const CardGatheringEdit: React.FC<Props> = ({ list, onChangeItemAtIndex, onGatheringsChangeText, onPressAdd }) => {
  const { t } = useTranslation()

  const onDragEnd = (result: DropResult) => {
    if (result.destination) onChangeItemAtIndex(result.source.index, result.destination.index)
  }

  const reunderItem = useCallback(
    (provided, _snapshot, rubric) => (
      <div ref={provided.innerRef} {...provided.draggableProps}>
        <CardGatheringEditItem
          item={list[rubric.source.index]}
          index={rubric.source.index}
          onGatheringsChangeText={onGatheringsChangeText}
          dragElement={
            <div {...provided.dragHandleProps}>
              <View
                style={{
                  paddingVertical: 8,
                  paddingHorizontal: 4,
                }}
              >
                <Icon name={'drag-vertical'} size={16} color={'gray.g700'} />
              </View>
            </div>
          }
        />
      </div>
    ),
    [list, onGatheringsChangeText],
  )

  return (
    <View>
      {list?.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" renderClone={reunderItem}>
            {(provided) => {
              return (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {list.map((item, idx) => (
                    <Draggable key={`card-gatering-${idx}`} draggableId={`${idx}`} index={idx}>
                      {reunderItem}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )
            }}
          </Droppable>
        </DragDropContext>
      ) : (
        <View style={styles.contensPanel}>
          <NoContentsIcon
            type={'gathering'}
            textSize={'medium'}
            text={t('card.collect.title')}
            subText={t('card.collect.message')}
            iconWidth={72}
          />
        </View>
      )}

      <View style={styles.buttonArea}>
        <AxisButton wide onPress={onPressAdd} addText={t('board.add')} />
      </View>
    </View>
  )
}

export default CardGatheringEdit

const styles = StyleSheet.create({
  contensPanel: {
    justifyContent: 'center',
    minHeight: 200,
  },
  buttonArea: {
    marginTop: 8,
  },
})
