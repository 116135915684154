import React from 'react'
import { LoginModal as Modal, Props } from './LoginModal'
import { SystemProvider } from '../../../SystemProvider'

const LoginModal = (props: Props) => {
  return (
    <SystemProvider>
      <Modal {...props} />
    </SystemProvider>
  )
}

export default LoginModal
