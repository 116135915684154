import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { COLOR, FONT } from '@rocket-mono/libs'
import { Pressable, StyleSheet, Text, View } from 'react-native'

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FAIcon } from '@rocket/atoms'
import TermsAllCheck from './TermsAllCheck'

interface Props {
  onTermsPress: (type: string) => void
  allTermsCheck: boolean
  setAllTermsCheck: (arg0: boolean) => void
}

export const TermsHandler = ({ onTermsPress, allTermsCheck, setAllTermsCheck }: Props) => {
  const { t } = useTranslation()
  const [termsCheck, setTermsCheck] = React.useState({ terms1: false, terms2: false })

  useEffect(() => {
    if (termsCheck.terms1 && termsCheck.terms2) {
      setAllTermsCheck(true)
    } else {
      setAllTermsCheck(false)
    }
  }, [termsCheck])

  useEffect(() => {
    allTermsCheck ? setTermsCheck({ terms1: true, terms2: true }) : setTermsCheck({ terms1: false, terms2: false })
  }, [allTermsCheck])

  return (
    <View>
      <TermsAllCheck label={t('pro.signup.agreeall')} checked={allTermsCheck} onPress={setAllTermsCheck} />
      <View style={{ paddingTop: 12, paddingBottom: 16 }}>
        <View style={styles.termsInline}>
          <Pressable onPress={() => onTermsPress('terms')}>
            <Text style={styles.termsText}>{t('pro.signup.rocketterms')}</Text>
          </Pressable>
          <Pressable
            onPress={() => {
              termsCheck.terms1 === false
                ? setTermsCheck({ ...termsCheck, terms1: true })
                : setTermsCheck({ ...termsCheck, terms1: false })
            }}
            style={styles.buttonIconArea}
          >
            <FAIcon iconName={faCheck} size={'sm'} color={termsCheck.terms1 ? 'primary.blue500' : 'gray.g300'} />
          </Pressable>
        </View>
        <View style={[styles.termsInline, { paddingTop: 4 }]}>
          <Pressable onPress={() => onTermsPress('privacy')}>
            <Text style={styles.termsText}>{t('pro.signup.privacyterms')}</Text>
          </Pressable>
          <Pressable
            onPress={() => {
              termsCheck.terms2 === false
                ? setTermsCheck({ ...termsCheck, terms2: true })
                : setTermsCheck({ ...termsCheck, terms2: false })
            }}
            style={styles.buttonIconArea}
          >
            <FAIcon iconName={faCheck} size={'sm'} color={termsCheck.terms2 ? 'primary.blue500' : 'gray.g300'} />
          </Pressable>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  formInline: {
    marginBottom: 8,
  },
  termsText: {
    ...FONT.txtXs,
    color: COLOR.gray.g500,
    textDecorationLine: 'underline',
  },
  termsInline: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 12,
    paddingRight: 8,
  },
  buttonIconArea: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
  },
})
