import * as React from 'react'

import { COLOR, FONT } from '@rocket-mono/libs'
import { useTranslation } from 'react-i18next'
import { Animated, Pressable, StyleSheet, Text, View } from 'react-native'

import { Icon, IconSns } from '@rocket-atoms/icon'
import { Button, TextField } from '@rocket/atoms'
import LanguageSelect, { SelectListType } from '../LanguageSelect'
import AuthButton from '../components/AuthButton'
import { SocialLoginType } from '../types'
import MoreButton from './MoreButton'

// import { useSharedValue, withSpring } from 'react-native-reanimated'

const EMAIL_FORM_HEIGHT = 196

const OPTION = {
  overshootClamping: true,
}

interface Props {
  initialEmail: string
  socialList: SocialLoginType[]
  languageList: SelectListType[]
  onSocialPress: (response: Response) => void
  onEmailPress: (email: string, password: string) => void
  onLanguagePress: (lang: string) => void
  onJoinPress: () => void
  onFindPasswordPress: () => void
  onTermsPress: (type: string) => void
  isReset?: boolean
  mobileView?: boolean
}

const SignInView = ({
  initialEmail,
  socialList,
  languageList,
  onSocialPress,
  onEmailPress,
  onLanguagePress,
  onJoinPress,
  onFindPasswordPress,
  onTermsPress,
  isReset,
  mobileView,
}: Props) => {
  const { t } = useTranslation()
  const [isLanguageOpen, setIsLanguageOpen] = React.useState(false)
  const [isForm, setIsForm] = React.useState(false)
  const [isMore, setIsMore] = React.useState(false)

  const [email, setEmail] = React.useState(initialEmail)
  const [password, setPassword] = React.useState('')

  const [errorMessage, setErrorMessage] = React.useState<{
    email?: string
    password?: string
  }>({})

  const [isAnimationReady, setIsAnimationReady] = React.useState(true)

  // const height = useSharedValue(0)
  // const opacity = useSharedValue(0)

  React.useEffect(() => {
    if (isReset) {
      setEmail('')
      setPassword('')
      setErrorMessage({})
    }
  }, [isReset])

  const invalid = () => {
    let is = false

    if (!email) {
      setErrorMessage((prev) => ({ ...prev, email: t('pro.login.error.enteremail') }))
      is = true
    } else {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
      if (!emailRegex.test(email)) {
        setErrorMessage((prev) => ({ ...prev, email: t('pro.login.error.invalidemail') }))
        is = true
      } else {
        setErrorMessage((prev) => ({ ...prev, email: '' }))
      }
    }
    if (!password) {
      setErrorMessage((prev) => ({ ...prev, password: t('pro.login.error.enterpassword') }))
      is = true
    } else {
      setErrorMessage((prev) => ({ ...prev, password: '' }))
    }
    return is
  }

  return (
    <View>
      {!mobileView && <Text style={styles.titleText}>Log in</Text>}
      <View style={[styles.selectArea, mobileView && { marginBottom: 8 }]}>
        <LanguageSelect
          list={languageList}
          isOpen={isLanguageOpen}
          setIsOpen={setIsLanguageOpen}
          onLanguagePress={onLanguagePress}
          lessType={mobileView && true}
        />
      </View>
      <View>
        {socialList.slice(0, 2).map(({ provider, onProc }, idx) => {
          return (
            <AuthButton
              key={idx}
              provider={provider}
              onPress={() => {
                onProc().then(onSocialPress)
              }}
              appType={mobileView && true}
            />
          )
        })}
      </View>
      {socialList.length > 2 ? (
        <View style={styles.snsArea}>
          <MoreButton isActive={isMore ? true : false} onPress={() => setIsMore(!isMore)} />
          {isMore &&
            socialList.slice(2).map(({ provider, onProc }, idx) => {
              return (
                <Pressable
                  key={idx}
                  style={styles.snsButton}
                  onPress={() => {
                    onProc().then(onSocialPress)
                  }}
                >
                  <IconSns type={provider} shape={'ROUND'} />
                </Pressable>
              )
            })}
        </View>
      ) : (
        <></>
      )}
      <View style={[styles.loginForm, mobileView && { marginBottom: 4 }]}>
        <View style={styles.emailLoginButton}>
          <Pressable
            onPress={() => {
              setIsAnimationReady(false)
              // if (isForm) {
              //   height.value = withSpring(0, OPTION)
              //   opacity.value = withSpring(0, OPTION)
              // } else {
              //   height.value = withSpring(EMAIL_FORM_HEIGHT, OPTION)
              //   opacity.value = withSpring(1, OPTION)
              // }
              setIsForm(!isForm)
            }}
            style={{ padding: 4 }}
          >
            <Text style={{ color: COLOR.gray.g600, ...FONT.txtSmMedium }}>{t('pro.login.email')}</Text>
          </Pressable>
        </View>
        <Animated.View
          style={[
            isForm ? styles.formGroup : isAnimationReady ? { display: 'none' } : {},
            // { opacity, height },
          ]}
        >
          <View style={styles.formInline}>
            <TextField value={email} placeholder={t('pro.login.enteremail')} onChangeValue={(text) => setEmail(text)} />
            {errorMessage.email ? (
              <View style={styles.errorMessageContainer}>
                <Icon name="info" color="status.busy" size="16" />
                <Text style={styles.errorMessage}>{errorMessage.email}</Text>
              </View>
            ) : (
              <></>
            )}
          </View>
          <View style={styles.formInline}>
            <TextField
              value={password}
              secureTextEntry={true}
              placeholder={t('pro.login.password')}
              onChangeValue={(text) => setPassword(text)}
              onKeyPress={(e) => {
                if (e.nativeEvent.key === 'Enter') {
                  if (invalid()) return
                  onEmailPress(email, password)
                }
              }}
            />
            {errorMessage.password ? (
              <View style={styles.errorMessageContainer}>
                <Icon name="info" color="status.busy" size="16" />
                <Text style={styles.errorMessage}>{errorMessage.password}</Text>
              </View>
            ) : (
              <></>
            )}
          </View>
          <View style={{ marginTop: 4 }}>
            <Button
              wide
              disabled={email && password ? false : true}
              text={t('pro.login.signin')}
              onPress={() => {
                if (invalid()) return
                onEmailPress(email, password)
              }}
            />
          </View>
          <View style={styles.signUpArea}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text style={styles.metaText}>{t('pro.login.notmember')}</Text>
              <Pressable onPress={onJoinPress}>
                <Text style={[styles.metaText, { color: COLOR.primary.blue500, marginLeft: 4 }]}>
                  {t('pro.login.signup')}
                </Text>
              </Pressable>
            </View>
            <Pressable onPress={onFindPasswordPress}>
              <Text style={styles.metaText}>{t('pro.login.findpw')}</Text>
            </Pressable>
          </View>
        </Animated.View>
      </View>
      <View style={styles.termsArea}>
        <Text style={styles.termsText}>
          {t('pro.login.termsinfo1')}
          {`\r\n`}
          {t('pro.login.termsinfo2')}{' '}
          <Pressable onPress={() => onTermsPress('terms')}>
            <Text style={styles.underlineText}>{t('pro.login.termsinfo3')}</Text>
          </Pressable>{' '}
          {t('pro.login.termsinfo4')}{' '}
          <Pressable onPress={() => onTermsPress('privacy')}>
            <Text style={styles.underlineText}>{t('pro.login.termsinfo5')}</Text>
          </Pressable>
          {`\r\n`}
          {t('pro.login.termsinfo6')}
        </Text>
      </View>
    </View>
  )
}

export default SignInView

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
  },
  titleText: {
    ...FONT.H4Bold,
  },
  selectArea: {
    marginTop: 12,
    marginBottom: 24,
    zIndex: 999,
  },
  snsArea: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 4,
  },
  snsButton: {
    marginHorizontal: 6,
  },
  snsTextButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderColor: COLOR.gray.g300,
    borderRadius: 4,
    marginBottom: 8,
  },
  loginForm: {
    borderTopWidth: 1,
    borderColor: COLOR.gray.g150,
    marginTop: 24,
    marginBottom: 12,
  },
  emailLoginButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 8,
    paddingVertical: 12,
  },
  formGroup: {
    position: 'relative',
    paddingBottom: 30,
  },
  formInline: {
    marginBottom: 8,
  },
  signUpArea: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 16,
  },
  metaText: {
    ...FONT.txtSm,
    color: COLOR.gray.g700,
  },
  termsArea: {
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
  },
  termsText: {
    ...FONT.txtXs,
    color: COLOR.gray.g500,
    textAlign: 'center',
  },
  underlineText: {
    ...FONT.txtXs,
    color: COLOR.gray.g500,
    textDecorationLine: 'underline',
  },
  errorMessageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 3,
  },
  errorMessage: {
    ...FONT.txtXsMedium,
    color: COLOR.status.busy,
  },
})
