import React, { useEffect, useMemo, useState } from 'react'
import { Pressable, View } from 'react-native'
import { CardBoardType } from '../type'
import CardSearchBoardDepth from '../CardSearchBoardDepth'
import CardSearchCardItem from '../CardSearchCardItem'
import { Card } from '@rocket/types'
import { useAstro } from '@rocket-mono/providers'
import { CardItemType } from '.'
import { COLOR } from '@rocket-mono/libs'

interface Props {
  archive?: boolean
  item: CardBoardType
  onPressItem?: (card: CardItemType) => void
}

const SectionBoardList = ({ archive, item, onPressItem }: Props) => {
  const { astro } = useAstro()
  const [isShow, setIsShow] = useState(false)

  const [cardList, setCardList] = useState<CardItemType[]>()

  const isActived = useMemo(() => isShow && cardList !== undefined, [isShow, cardList])

  useEffect(() => {
    if (cardList === undefined && isShow) {
      astro
        .readCardList(item.boardId)
        .then((list: Card[]) =>
          list.map(({ cardId, channelId: boardId, cardType, cardTitle }) => ({
            boardId,
            cardId,
            cardTitle,
            cardType,
            closed: false,
          })),
        )
        .then(setCardList)
    }
  }, [item, cardList, isShow])

  return (
    <View style={archive && { backgroundColor: COLOR.gray.g050 }}>
      <Pressable onPress={() => setIsShow((is) => !is)}>
        <CardSearchBoardDepth archive={item.closed} isActived={isActived} boardTitle={item.boardTitle} />
      </Pressable>
      {isActived &&
        cardList &&
        cardList.map((card, idx) => (
          <CardSearchCardItem
            key={`carditem-${idx}`}
            style={{ paddingLeft: 30 }}
            {...card}
            archive={item.closed}
            onPress={() => {
              onPressItem && onPressItem(card)
            }}
          />
        ))}
    </View>
  )
}

export default SectionBoardList
