import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { unstable_useBlocker as useBlocker } from 'react-router-dom'

import { WorkCardProvider, WorkChannelProvider } from '@rocket-mono/providers'
import { WorkCardEditScreen, WorkCardViewScreen } from '@rocket-mono/screen-card'
import { useRecoilState } from 'recoil'
import { cardLayoutPayloadState } from '../recoils'
import { useTranslation } from 'react-i18next'
import { Skeleton, SkeletonWorkCardNewTabView } from '@rocket/skeleton'
import CardTodoDetailView from '../pages/Card/todo-detail'

interface Props {
  maxWidth?: number
}

export const CardModal: React.FC<Props> = ({ maxWidth }: Props) => {
  const { i18n } = useTranslation()
  const [opened, setOpened] = useState(false)
  const [isBlock, setIsBlock] = useState(true)
  const blocker = useBlocker(isBlock)
  const blocked = useMemo(() => (blocker.state === 'blocked' ? true : false), [blocker])

  const [cardLayoutPayload, setCardLayoutPayload] = useRecoilState(cardLayoutPayloadState)

  const handlePressWindow = useCallback(() => {
    if (cardLayoutPayload) {
      const cardUrl = `/card${cardLayoutPayload.isEdit ? '/edit' : ''}${
        cardLayoutPayload.cardId !== undefined ? `/${cardLayoutPayload.cardId}` : ''
      }`
      const cardType = cardLayoutPayload.cardId === undefined ? `&cardType=${cardLayoutPayload.cardType}` : ''
      const todoId = cardLayoutPayload.todoId !== undefined ? `&todoId=${cardLayoutPayload.todoId}` : ''
      window.open(`${cardUrl}?channelId=${cardLayoutPayload.channelId}${cardType}${todoId}`)
      setCardLayoutPayload(undefined)
    }
  }, [cardLayoutPayload])

  const handleUnauthorized = useCallback(() => {
    if (cardLayoutPayload?.isEdit) {
      setCardLayoutPayload((prev) => (prev !== undefined ? { ...prev, isEdit: false } : undefined))
    }
  }, [cardLayoutPayload])

  const handleSuccess = useCallback((cardId: string) => {
    setCardLayoutPayload((prev) => (prev !== undefined ? { ...prev, cardId, isEdit: false } : undefined))
  }, [])
  const handleFail = useCallback(() => {
    // window.close()
  }, [])

  const handlePressEdit = useCallback(() => {
    setCardLayoutPayload((prev) => (prev !== undefined ? { ...prev, isEdit: true } : undefined))
  }, [])

  const handlePressOpen = useCallback(() => {
    setCardLayoutPayload((prev) => (prev !== undefined ? { ...prev, isFold: false } : undefined))
  }, [])

  const handlePressHold = useCallback(() => {
    setCardLayoutPayload((prev) => {
      return prev !== undefined ? { ...prev, isFold: true } : undefined
    })
  }, [])

  const handlePressTodo = useCallback((todoId) => {
    setCardLayoutPayload((prev) => {
      return prev !== undefined ? { ...prev, todoId } : undefined
    })
  }, [])

  const handlePressClose = useCallback(() => {
    if (cardLayoutPayload?.isEdit) {
      setCardLayoutPayload((prev) => (prev !== undefined ? { ...prev, isEdit: false } : undefined))
    } else {
      setCardLayoutPayload(undefined)
    }
  }, [cardLayoutPayload])

  useEffect(() => {
    let block = true

    if (cardLayoutPayload === undefined) block = false
    else if (cardLayoutPayload !== undefined && cardLayoutPayload.isFold) block = false
    else block = true

    console.log('cardLayoutPayload', block, cardLayoutPayload)

    setIsBlock(block)
    if (cardLayoutPayload && !cardLayoutPayload.isFold)
      setTimeout(() => setOpened(cardLayoutPayload !== undefined), 500)
  }, [cardLayoutPayload])

  useEffect(() => {
    if (blocked) {
      handlePressHold()
      blocker.proceed && blocker.proceed()
    }
  }, [blocked])
  const handleDelete = useCallback(
    (cardId: string) => {
      if (cardLayoutPayload !== undefined && cardLayoutPayload.cardId === cardId) {
        setCardLayoutPayload(undefined)
      }
    },
    [cardLayoutPayload],
  )

  return (
    <>
      {cardLayoutPayload !== undefined && (
        <>
          <WorkChannelProvider
            channelId={cardLayoutPayload.channelId}
            fallback={
              location.pathname.endsWith('/board') ? (
                <SkeletonWorkCardNewTabView isModal />
              ) : (
                <Skeleton path={location.pathname} />
              )
            }
          >
            <WorkCardProvider
              cardId={cardLayoutPayload.cardId}
              onUnauthorized={() => {
                console.log('unauthorized')
              }}
              onDelete={handleDelete}
              fallback={
                location.pathname.endsWith('/board') ? (
                  <SkeletonWorkCardNewTabView isModal />
                ) : (
                  <Skeleton path={location.pathname} />
                )
              }
            >
              <>
                {cardLayoutPayload.isEdit ? (
                  <WorkCardEditScreen
                    i18n={i18n}
                    modal
                    {...cardLayoutPayload}
                    currentProjectId={cardLayoutPayload.projectId}
                    opened={opened}
                    maxWidth={maxWidth}
                    onSuccess={handleSuccess}
                    onFail={handleFail}
                    onUnauthorized={handleUnauthorized}
                    onPressWindow={handlePressWindow}
                    onPressHold={handlePressHold}
                    onPressOpen={handlePressOpen}
                    onPressClose={handlePressClose}
                  />
                ) : cardLayoutPayload.todoId !== undefined ? (
                  <CardTodoDetailView
                    cardId={cardLayoutPayload.cardId}
                    todoId={cardLayoutPayload.todoId}
                    currentProjectId={cardLayoutPayload.projectId}
                    modal
                    opened={opened}
                    maxWidth={maxWidth}
                    {...cardLayoutPayload}
                    onPressWindow={handlePressWindow}
                    onPressBack={() =>
                      setCardLayoutPayload((prev) => {
                        return prev !== undefined ? { ...prev, todoId: undefined } : undefined
                      })
                    }
                    onPressClose={handlePressClose}
                  />
                ) : cardLayoutPayload.cardId !== undefined && !cardLayoutPayload.isEdit ? (
                  <WorkCardViewScreen
                    i18n={i18n}
                    modal
                    {...cardLayoutPayload}
                    currentProjectId={cardLayoutPayload.projectId}
                    opened={opened}
                    maxWidth={maxWidth}
                    onPressWindow={handlePressWindow}
                    onPressHold={handlePressHold}
                    onPressOpen={handlePressOpen}
                    onPressEdit={handlePressEdit}
                    onPressClose={handlePressClose}
                    onPressTodo={handlePressTodo}
                  />
                ) : (
                  <></>
                )}
              </>
            </WorkCardProvider>
          </WorkChannelProvider>
        </>
      )}
    </>
  )
}
