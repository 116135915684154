import * as React from 'react'


import { View, StyleSheet } from 'react-native'
import { COLOR } from '@rocket-mono/libs'
import FileIcon from '../FileIcon'
import { FileIconType } from "../FileIcon"

interface Props{
  type: FileIconType
  thumbnailType?: 'DEFAULT' | 'DEFAULT-GRAYSCALE' | 'DARKGRAY' | 'GRAY'
  size?: 'md' | 'sm'
  isSkeleton?: boolean
}

const FileThumbnail = ({thumbnailType, type, size, isSkeleton}: Props) => {
  const [iconSize, setIconSize] = React.useState({
    iconSize:0,
    padding:0,
    borderRadius:0
  })
  React.useEffect(()=>{
    if(size==='sm'){
      setIconSize({iconSize:24, padding:8, borderRadius:13})
    } else {
      setIconSize({iconSize:28, padding:10, borderRadius:16})
    }
  },[size])

  return(
    <View style={[
      styles.wrap,
      thumbnailType === 'DEFAULT-GRAYSCALE' && {backgroundColor:COLOR.gray.g050},
      thumbnailType === 'DARKGRAY' && {backgroundColor:COLOR.dimmed.a60, borderWidth:0},
      thumbnailType === 'GRAY' && {backgroundColor:COLOR.gray.g300, borderWidth:0},
      isSkeleton && {backgroundColor:COLOR.gray.g150},
      {
        padding:iconSize.padding,
        borderRadius:iconSize.borderRadius
      }
      ]}>
      {isSkeleton ?
        <View style={{width:iconSize.iconSize, height:iconSize.iconSize}}></View>
      :
        <FileIcon size={iconSize.iconSize} type={type} grayScale={thumbnailType === 'DEFAULT-GRAYSCALE' && true} />
      }
    </View>
  )
}

export default FileThumbnail

const styles = StyleSheet.create({
  wrap:{
    alignSelf:'flex-start',
    borderWidth:1,
    borderColor:COLOR.gray.g150,
    backgroundColor:COLOR.mono.white
  },
  
})