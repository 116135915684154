import { DropdownButton } from '@rocket-atoms/dropdown'
import { SectionAccordionType, SelectorBoardType, SelectorWork, SelectorWorkType } from '@rocket-molecules/popselector'
import { ClickOutside } from '@rocket-mono/libs'
import { WorkIcon } from '@rocket/atoms'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import RuleBoardSelectView from './RuleBoardSelectView'
import { commonStyles } from './style'

const zIndex = 1

const getInitialWork = (workList: SelectorWorkType[], selectedWork?: string) => {
  const selectedWorkItem: SectionAccordionType[] = []
  if (selectedWork) {
    workList.map((work) =>
      work.sectionList.map((section: SectionAccordionType) => {
        if (section.code === selectedWork) {
          selectedWorkItem.push(section)
        }
      }),
    )
  }
  return selectedWorkItem
}

interface Props {
  workList: SelectorWorkType[]
  selectedWork?: string
  boardList: SelectorBoardType[]
  selectedBoardList: string[]
  onSelectedBoardList: (list: string[]) => void
  onSelectedBoardListBySelectedWork: (item: SectionAccordionType) => void
  isTopLabelMarginTop?: boolean
}

const RuleWorkSelectView = ({
  workList,
  selectedWork,
  boardList,
  selectedBoardList,
  onSelectedBoardList,
  onSelectedBoardListBySelectedWork,
  isTopLabelMarginTop = true,
}: Props) => {
  const { t } = useTranslation()
  const [activated, setActivated] = React.useState(false)
  const [buttonStandard, setButtonStandard] = React.useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  })
  const [workSelected, setWorkSelected] = React.useState<SectionAccordionType[]>(getInitialWork(workList, selectedWork))

  const getIcon = (item: SectionAccordionType) => {
    if (!item.beforeWorkIcon) return 'DSP'
    if (item.beforeWorkIcon === 'TOP') return 'TOP'
    if (item.beforeWorkIcon === 'CSP') return 'CSP'
    if (item.beforeWorkIcon === 'WFP') return 'WFP'
    if (item.beforeWorkIcon === 'DSP') return 'DSP'
    return 'DSP'
  }

  return (
    <View>
      <Text style={[commonStyles.labelText, isTopLabelMarginTop ? { marginTop: 20 } : { marginTop: 0 }]}>
        {t('rule.works.select')}
      </Text>
      <View style={styles.container}>
        <DropdownButton
          type={'DEFAULT'}
          configuration={'DEFAULT'}
          size={'md'}
          disabled={false}
          activated={activated}
          setActivated={setActivated}
          setButtonStandard={setButtonStandard}
          isButtonBackgroundColorWhite
        >
          <View style={styles.workLabel}>
            {workSelected.length !== 0 ? (
              <>
                <WorkIcon type={getIcon(workSelected[0])} size={16} />
                <Text style={{ marginLeft: 6 }}>{workSelected[0] ? workSelected[0].label : 'work'}</Text>
              </>
            ) : (
              <Text style={commonStyles.initText}>{t('dropdown.initcode')}</Text>
            )}
          </View>
        </DropdownButton>
        {activated && (
          <ClickOutside onClickOutside={() => setActivated(false)}>
            <View style={{ position: 'absolute', top: buttonStandard.height + 4, zIndex }}>
              <SelectorWork
                list={workList}
                defaultIndex={selectedWork}
                checkedType={'RADIOBOX'}
                width={buttonStandard.width}
                bodyHeight={200}
                onPressSave={(item) => {
                  if (item) {
                    setWorkSelected([item])
                    onSelectedBoardListBySelectedWork(item)
                    setActivated(false)
                  }
                }}
                onPressCancel={() => {
                  setActivated(false)
                }}
              />
            </View>
          </ClickOutside>
        )}
      </View>
      {workSelected.length > 0 ? (
        <RuleBoardSelectView
          boardList={boardList}
          selectedBoardList={selectedBoardList}
          onSelectedBoardList={onSelectedBoardList}
        />
      ) : (
        <></>
      )}
    </View>
  )
}

export default RuleWorkSelectView

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  workLabel: {
    overflow: 'hidden',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 4,
    paddingRight: 6,
    borderRadius: 4,
  },
})
