import React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'

import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { COLOR, FONT } from '@rui/foundations'
import { Checkbox } from '@rui/atoms'

import { FAIcon } from '@rui/icons'

import type { SearchLayerSectionHeaderProps } from './types'

const SearchLayerSectionHeader: React.FC<SearchLayerSectionHeaderProps> = ({
  section,
  data,
  onFold,
  onSectionSelect,
}) => {
  const count = React.useMemo(() => {
    const header = data.find((item) => item.key === section.key)
    return header?.data.length ?? 0
  }, [data, section])
  const allChecked = React.useMemo(() => {
    if (data.length === 0) return false
    const header = data.find((item) => item.key === section.key)
    return header?.data.every((item) => item.isSelected) ?? false
  }, [data, section])
  return (
    <Pressable style={styles.container} onPress={() => onFold?.(section.key)}>
      <View style={styles.flexbox}>
        <Pressable
          onPress={() => {
            onSectionSelect?.(section.key, allChecked)
          }}
          style={styles.input}
        >
          <Checkbox checked={allChecked} />
        </Pressable>
        <Text style={styles.label}>{section.title}</Text>
        <Text style={styles.count}>{count}</Text>
      </View>
      <FAIcon iconName={section.isFold ? faAngleUp : faAngleDown} size={'xs'} color="gray.g600" />
    </Pressable>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
    borderTopWidth: 1,
    borderColor: COLOR.gray.g200,
    backgroundColor: COLOR.mono.white,
  },
  flexbox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    marginRight: 4,
  },
  label: {
    ...FONT.txtXsMedium,
  },
  count: {
    ...FONT.txtXsMedium,
    color: COLOR.main.red,
    marginLeft: 4,
  },
})

export default SearchLayerSectionHeader
