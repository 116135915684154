import * as React from 'react'
import { Defs, LinearGradient, Stop, Path, Rect, Svg } from 'react-native-svg'
import type { WorkIconProps } from './types'

export const WorkIconCSP: React.FC<WorkIconProps> = ({ size = 359 }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 359 359">
      <Rect width="359" height="359" rx={80} fill="#3067A8" />
      <Rect x="54" y="92" width="251" height="153" rx="76.5" fill="#3067A8" />
      <Path
        d="M153 287.938C176.614 273.68 174.229 247.179 173.085 236.211C191.161 235.297 233.451 235.018 234 237.211C215.878 279.986 172.449 288.852 153 287.938Z"
        fill="url(#paint0_linear_2288_116922)"
      />
      <Rect x="54" y="92" width="251" height="153" rx="76.5" fill="white" />
      <Path
        opacity="0.7"
        d="M138 169.5C138 184.136 125.912 196 111 196C96.0883 196 84 184.136 84 169.5C84 154.864 96.0883 143 111 143C125.912 143 138 154.864 138 169.5Z"
        fill="#3067A8"
      />
      <Path
        d="M173.277 147.018C175.598 142.994 181.402 142.994 183.723 147.018L206.183 185.947C208.504 189.97 205.603 195 200.96 195H156.04C151.397 195 148.496 189.97 150.817 185.947L173.277 147.018Z"
        fill="#3067A8"
      />
      <Path
        opacity="0.7"
        d="M220 150.538C220 146.927 222.87 144 226.41 144H263.59C267.13 144 270 146.927 270 150.538V188.462C270 192.073 267.13 195 263.59 195H226.41C222.87 195 220 192.073 220 188.462V150.538Z"
        fill="#3067A8"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_2288_116922"
          x1="193.5"
          y1="235.53"
          x2="193.5"
          y2="288"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#3C72B1" />
          <Stop offset="0.901042" stopColor="white" />
        </LinearGradient>
      </Defs>
    </Svg>
  )
}
