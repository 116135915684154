import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FAIcon } from '@rocket-atoms/icon'
import { COLOR } from '@rui/foundations'
import React from 'react'
import { StyleSheet, View } from 'react-native'

const MessageTypeIcon: React.FC<{ icon: IconProp }> = ({ icon }) => {
  return (
    <View style={styles.wrap}>
      <FAIcon iconName={icon} size={'xs'} nativeSize={10} color={'gray.g600'} />
    </View>
  )
}

export default MessageTypeIcon

const styles = StyleSheet.create({
  wrap: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    backgroundColor: COLOR.gray.g100,
    borderRadius: 4,
  },
})
