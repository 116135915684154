import React from 'react'

import { RecoilRoot } from 'recoil'
import { RouterProvider } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import { ModalProvider } from '@rui/molecules'

import './index.css'

import router from './router'
const App: React.FC = () => {
  return (
    <RecoilRoot>
      <HelmetProvider>
        <ModalProvider>
          <RouterProvider router={router} />
        </ModalProvider>
      </HelmetProvider>
    </RecoilRoot>
  )
}

export default App
