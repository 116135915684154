import React from 'react'
import { View } from 'react-native'
import { Outlet } from 'react-router-dom'

const LayoutEmpty = () => {
  return (
    <View style={{ flex: 1, height: '100%' }}>
      <Outlet />
    </View>
  )
}

export default LayoutEmpty
