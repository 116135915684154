import React from 'react'
import { Icon } from '@rocket-atoms/icon'
import { Text } from '@rocket-atoms/text'
import { COLOR } from '@rocket-mono/libs'
import { TouchableOpacity, StyleSheet, View } from 'react-native'

interface Props {
  text: string
  underline?: boolean
  bordered?: boolean
  opaqueIcon?: boolean
  checked?: boolean
  onPress?: () => void
}

export const AcknowledgeButton: React.FC<Props> = ({ text, underline, bordered, checked, onPress }) => {
  return (
    <TouchableOpacity
      style={[
        style.container,
        {
          borderColor: bordered ? (checked === true ? COLOR.main.blue : '#eee') : 'transparent',
        },
      ]}
      onPress={onPress}
    >
      <Text
        fontName="subTextRegular"
        fontColor="mono.paleBlack"
        style={{
          textDecorationLine: underline === true ? 'underline' : 'none',
        }}
      >
        {text}
      </Text>
      <View
        style={{
          width: 23,
          height: 23,
          borderRadius: 25,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: checked === true ? COLOR.main.blue : '#F8F8F8',
        }}
      >
        <Icon size={13} color={checked === true ? 'mono.white' : 'mono.lightGray'} name="check" />
      </View>
    </TouchableOpacity>
  )
}

const style = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    minHeight: 34,
    flexDirection: 'row',
    paddingVertical: 7,
    paddingHorizontal: 12,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderWidth: 1,
    borderRadius: 5,
  },
})
