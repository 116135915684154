import { FONT, getRealColor } from '@rocket-mono/libs'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, Text, View } from 'react-native'
import ButtonIcon from '../ButtonIcon'
import { MentionTargetPicker } from '../SendTargetPicker'
import DiscussInput from './DiscussInput'

interface Props {
  text: string
  bottom?: number
  visible?: boolean
  onSubmit: (text: string) => void
  onCancel: () => void
  onFileUpload?: () => void
}

const DiscussEdit: React.FC<Props> = ({ text, bottom = 0, visible = false, onSubmit, onCancel, onFileUpload }) => {
  const { t } = useTranslation()
  const [modalText, setModalText] = React.useState(text)
  React.useEffect(() => {
    setModalText(text)
  }, [text])

  const submit = React.useCallback(
    (text: string) => {
      if (text.length > 0) {
        onSubmit && onSubmit(text)
      }
    },
    [onSubmit],
  )

  return (
    <View style={[styles.container, { bottom }]}>
      {visible && (
        <View
          style={[
            styles.modalView,
            {
              minHeight: 120,
            },
          ]}
        >
          <View style={styles.modalHeader}>
            <Text style={styles.modalHeaderText}>{t('discussedit.title')}</Text>
            <ButtonIcon
              iconName="close"
              onPress={onCancel}
              style={{ alignSelf: 'center' }}
              iconColor="mono.white"
              iconSize={20}
              containerSize={20}
            />
          </View>

          <MentionTargetPicker direction={'downside'} />
          <View style={styles.modalMain}>
            <DiscussInput
              value={modalText}
              onSubmit={submit}
              onChangeText={(text: string) => setModalText(text)}
              style={styles.input}
            />
            <View style={styles.buttons}>
              {onFileUpload && (
                <View style={styles.button}>
                  <ButtonIcon
                    rounded
                    iconName="cloud-upload-o"
                    backgroundColor="mono.pale"
                    iconColor="mono.paleBlack"
                    containerSize={24}
                    iconSize={15}
                    iconViewbox="0 -100 1000 1000"
                    onPress={onFileUpload}
                  />
                </View>
              )}
              <View style={styles.button}>
                <ButtonIcon
                  iconName="send"
                  iconSize={24}
                  containerSize={24}
                  iconColor="mono.paleBlack"
                  onPress={() => {
                    submit(modalText)
                  }}
                />
              </View>
            </View>
          </View>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flex: 1,
    justifyContent: 'flex-end',
    position: 'absolute',
  },
  modalView: {
    justifyContent: 'center',
    width: '100%',
    minHeight: Platform.select({
      ios: 80,
      default: 50,
    }),
    backgroundColor: getRealColor('mono.white'),
  },
  modalHeader: {
    height: 40,
    width: '100%',
    flexDirection: 'row',
    backgroundColor: getRealColor('mono.paleBlack'),
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 12,
    paddingRight: 19,
  },
  modalHeaderText: {
    ...FONT.textRegular,
    color: getRealColor('mono.white'),
  },
  modalMain: {
    flexDirection: 'row',
    paddingLeft: 12,
    marginVertical: 8,
  },
  input: {
    paddingTop: 0,
    paddingLeft: 0,
    height: 64,
    flex: 1,
    ...FONT.textRegular,
    ...Platform.select({
      android: { lineHeight: FONT.textRegular.fontSize },
      web: {
        outlineWidth: 0,
      },
    }),
  },
  buttons: {
    height: 64,
    justifyContent: 'center',
    marginRight: 12,
    marginLeft: 12,
  },
  button: {
    height: 25,
  },
})

export default DiscussEdit
