/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react'

import { COLOR } from '@rocket-mono/libs'
import { Pressable, PressableProps, ViewStyle } from 'react-native'

export interface Props extends Omit<PressableProps, 'style'> {
  children: React.ReactNode
}

const AnyItem = ({ children, ...pressableProps }: Props) => {
  return (
    <Pressable style={pressableStyle} {...pressableProps}>
      {children}
    </Pressable>
  )
}

export default AnyItem

// @ts-ignore hovered only used in web
const pressableStyle = ({ hovered, pressed }: PressableStateCallbackType) => {
  return [
    {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingVertical: 12,
      paddingHorizontal: 12,
    },
    hovered && { backgroundColor: COLOR.gray.g050 },
    pressed && {
      opacity: 0.5,
      backgroundColor: COLOR.gray.g100,
    },
  ] as ViewStyle[]
}
