const _escape = (text: string) => {
  let res = ''
  for (let i = 0; i < text.length; i++) {
    const c = text.charCodeAt(i)
    if (c < 256) {
      res += encodeURIComponent(text[i])
    } else {
      res += '%u' + c.toString(16).toUpperCase()
    }
  }
  return res
}

export function getByteLength(text: string): number {
  const text_val = text //입력한 문자
  const text_len = text_val.length //입력한 문자수
  let totalByte = 0
  for (let i = 0; i < text_len; i++) {
    const each_char = text_val.charAt(i)
    const uni_char = _escape(each_char) //유니코드 형식으로 변환
    if (uni_char.length > 4) {
      // 한글 : 2Byte
      totalByte += 2
    } else {
      // 영문,숫자,특수문자 : 1Byte
      totalByte += 1
    }
  }

  return totalByte
}

export function cutByteString(text: string, maxByte: number): string {
  const text_val = text //입력한 문자
  const text_len = text_val.length //입력한 문자수
  let totalByte = 0
  for (let i = 0; i < text_len; i++) {
    let curByte = 0
    const each_char = text_val.charAt(i)
    const uni_char = _escape(each_char) //유니코드 형식으로 변환
    if (uni_char.length > 4) {
      // 한글 : 2Byte
      curByte += 2
    } else {
      // 영문,숫자,특수문자 : 1Byte
      curByte += 1
    }

    if (maxByte >= totalByte + curByte) {
      totalByte += curByte
    } else {
      return text.substring(0, i)
    }
  }

  return text
}
