import * as React from 'react'
import { StyleSheet, View, Text, Pressable, Image } from 'react-native'
import { COLOR, FONT } from '@rui/foundations'
import { useTranslation } from 'react-i18next'
// import MediaPlayIcon from './MediaPlayIcon'

export interface TemplateItemType {
  type: 'MEDIA' | 'DSP' | 'WFP' | 'CSP' | 'TOP' | string
  label?: string
  title?: string
  imgUrl?: string
  onPress: () => void
}

interface Props {
  item: TemplateItemType
  size?: 'md' | 'sm'
  skeleton?: boolean
}

const TemplateItem = ({ item, size, skeleton }: Props) => {
  const { t } = useTranslation()
  const [bgColor, setBgColor] = React.useState('')

  React.useEffect(() => {
    BgSetting()
  }, [item.type])
  const BgSetting = () => {
    return item.type === 'DSP'
      ? setBgColor('#22286C')
      : item.type === 'WFP'
      ? setBgColor('#FB585D')
      : item.type === 'CSP'
      ? setBgColor('#4260CA')
      : item.type === 'TOP'
      ? setBgColor('#783EB9')
      : setBgColor('')
  }

  return skeleton ? (
    <View style={[styles.container, styles.skeletonStyle, size === 'sm' && { width: 150, height: 105 }]}></View>
  ) : item.type === 'MEDIA' ? (
    <Pressable onPress={item.onPress} style={[styles.container, size === 'sm' && { width: 150, height: 105 }]}>
      <View style={styles.mediaArea}>
        {/* <MediaPlayIcon size={50}/> */}
        <Image
          style={styles.imageStyle}
          source={{
            uri: item.imgUrl,
          }}
        />
      </View>
    </Pressable>
  ) : (
    <Pressable
      onPress={item.onPress}
      style={[styles.container, { backgroundColor: bgColor }, size === 'sm' && { width: 150, height: 105 }]}
    >
      <View style={styles.titleArea}>
        <View style={styles.label}>
          <Text style={[styles.txt, size === 'sm' && { ...FONT.txt2XsBold }]}>{t(item.label ?? '')}</Text>
        </View>
        <Text style={[styles.projectTit, size === 'sm' && { ...FONT.txtXsBold }]}>{t(item.title ?? '')}</Text>
      </View>
      <View style={[styles.imageWrap, size === 'sm' && { width: 100, height: 74 }]}>
        <Image
          style={styles.imageStyle}
          source={{
            uri: item.imgUrl,
          }}
        />
      </View>
    </Pressable>
  )
}

export default TemplateItem

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    width: 216,
    height: 150,
    borderRadius: 4,
  },
  titleArea: {
    padding: 8,
  },
  label: {
    alignSelf: 'flex-start',
    backgroundColor: COLOR.darkmode.warm800,
    borderRadius: 4,
    paddingHorizontal: 4,
    paddingVertical: 2,
  },
  txt: {
    ...FONT.txtXsBold,
    color: COLOR.darkmode.cool300,
  },
  imageWrap: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 150,
    height: 110,
  },
  imageStyle: {
    flex: 1,
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  projectTit: {
    ...FONT.txtMdBold,
    color: COLOR.mono.white,
    marginTop: 2,
    paddingLeft: 2,
  },
  mediaArea: {
    position: 'relative',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    borderRadius: 4,
  },
  skeletonStyle: {
    backgroundColor: COLOR.darkmode.warm800,
  },
})
