import { Text } from '@rui/atoms'
import { TFunction } from 'i18next'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import CalendarView from '../CalendarView'

interface Props {
  startDate?: Date
  endDate?: Date
  isStartTime?: boolean
  isEndTime?: boolean
  calendarVisible?: boolean
}

const Row = () => <View style={styles.row} />

const RowItem = ({ children }: { children: React.ReactNode }) => <View style={styles.rowItem}>{children}</View>
const RightItem = ({ width, locale, children }: { width: number; locale: string; children: React.ReactNode }) => (
  <View style={[styles.rightItem, { width: width * (locale === 'ko' ? 20 : 10) }]}>{children}</View>
)
const LeftItem = ({ children }: { children: React.ReactNode }) => <View style={styles.leftItem}>{children}</View>

const getDate = ({ t, date, isTime }: { t: TFunction; date?: Date; isTime: boolean }) => {
  if (!date) {
    return ''
  }
  if (isTime) {
    return t('format.date.LLL', { date })
  } else {
    return t('format.date.L', { date })
  }
}

const WorkCardCalendar = ({
  startDate,
  endDate,
  isStartTime = false,
  isEndTime = false,
  calendarVisible = true,
}: Props) => {
  const { t, i18n } = useTranslation()
  const startdayStr = t('workcardcalendar.startday')
  const enddayStr = t('workcardcalendar.endday')
  const repeatStr = t('workcardcalendar.repeat')

  const maxLength = React.useMemo(() => {
    const startdayLen = startdayStr.length
    const enddayLen = enddayStr.length
    const repeatLen = repeatStr.length
    return Math.max(startdayLen, enddayLen, repeatLen)
  }, [startdayStr, enddayStr, repeatStr])

  const resolvedLanguage = useMemo(() => i18n.resolvedLanguage ?? 'en', [i18n])
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <RowItem>
          <RightItem width={maxLength} locale={resolvedLanguage}>
            <Text fontName="txtSm" fontColor="gray.g450">
              {startdayStr}
            </Text>
          </RightItem>
          <LeftItem>
            <Text fontName="txtSm" fontColor="gray.g900">
              {getDate({ t, date: startDate, isTime: isStartTime })}
            </Text>
          </LeftItem>
        </RowItem>
        <Row />
        <RowItem>
          <RightItem width={maxLength} locale={resolvedLanguage}>
            <Text fontName="txtSm" fontColor="gray.g450">
              {enddayStr}
            </Text>
          </RightItem>
          <LeftItem>
            <Text fontName="txtSm" fontColor="gray.g900">
              {getDate({ t, date: endDate, isTime: isEndTime })}
            </Text>
          </LeftItem>
        </RowItem>
      </View>
      {calendarVisible && <CalendarView startDate={startDate} endDate={endDate} />}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    margin: 10,
  },
  row: {
    height: 12,
  },
  rowItem: {
    flexDirection: 'row',
  },
  rightItem: {
    // flex: 1,
  },
  leftItem: {
    flex: 1,
  },
})

export default WorkCardCalendar
