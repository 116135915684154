import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import { COLOR, FONT } from '@rocket-mono/libs'
import { StateCircle } from './StateCircle'
import { TodoStepType } from '../../type'

interface Props {
  currentStep?: TodoStepType
}


const StateView = ({currentStep}: Props) => {
  const { t } = useTranslation()

  return(
    <View style={styles.stateViewArea}>
      {currentStep === 'waiting' &&
        <>
          <View style={styles.dot}>
            <StateCircle step={currentStep} active={true} done={false}/>
            <Text style={styles.label}>{t('subtask.status.wait')}</Text>
          </View>
          <View style={styles.dash}/>
          <View>
            <StateCircle step={currentStep} active={false} done={false} />
            <Text style={styles.label}>{t('subtask.status.doing')}</Text>
          </View>
          <View style={styles.dash}/>
          <View>
            <StateCircle step={currentStep} active={false} done={false} />
            <Text style={styles.label}>{t('subtask.status.complete')}</Text>
          </View>
        </>
      }
      {currentStep === 'scheduled' &&
        <>
          <View>
            <StateCircle step={currentStep} active={true} done={false} />
            <Text style={styles.label}>{t('subtask.status.scheduled')}</Text>
          </View>
          <View style={styles.dash}/>
          <View>
            <StateCircle step={currentStep} active={false} done={false} />
            <Text style={styles.label}>{t('subtask.status.doing')}</Text>
          </View>
          <View style={styles.dash}/>
          <View>
            <StateCircle step={currentStep} active={false} done={false} />
            <Text style={styles.label}>{t('subtask.status.complete')}</Text>
          </View>
        </>
      }
      {currentStep === 'progress' && 
        <>
          <View style={styles.dot}>
            <StateCircle step={currentStep} active={true} done={true}/>
            <Text style={styles.label}>{t('subtask.status.wait')}</Text>
          </View>
          <View style={[styles.dash, styles.dashDone]}/>
          <View>
            <StateCircle step={currentStep} active={true} done={false} />
            <Text style={styles.label}>{t('subtask.status.doing')}</Text>
          </View>
          <View style={styles.dash}/>
          <View>
            <StateCircle step={currentStep} active={false} done={false} />
            <Text style={styles.label}>{t('subtask.status.complete')}</Text>
          </View>
        </>
      }
      {currentStep === 'completed' && 
        <>
          <View style={styles.dot}>
            <StateCircle step={currentStep} active={true} done={true}/>
            <Text style={styles.label}>{t('subtask.status.wait')}</Text>
          </View>
          <View style={[styles.dash, styles.dashDone]}/>
          <View>
            <StateCircle step={currentStep} active={true} done={true} />
            <Text style={styles.label}>{t('subtask.status.doing')}</Text>
          </View>
          <View style={[styles.dash, styles.dashDone]}/>
          <View>
            <StateCircle step={currentStep} active={true} done={true} />
            <Text style={styles.label}>{t('subtask.status.complete')}</Text>
          </View>
        </>
      }
      {currentStep === 'waitingPending' && 
        <>
          <View>
            <StateCircle step={currentStep} active={true} done={false} style={{backgroundColor:COLOR.main.darkNavy}}/>
            <Text style={styles.label}>{t('subtask.status.holding')}</Text>
          </View>
          <View style={styles.dash}/>
          <View>
            <StateCircle step={currentStep} active={false} done={false} />
            <Text style={styles.label}>{t('subtask.status.doing')}</Text>
          </View>
          <View style={styles.dash}/>
          <View>
            <StateCircle step={currentStep} active={false} done={false} />
            <Text style={styles.label}>{t('subtask.status.complete')}</Text>
          </View>
        </>
      }
      {currentStep === 'progressPending' && 
        <>
          <View style={styles.dot}>
            <StateCircle step={currentStep} active={true} done={true}/>
            <Text style={styles.label}>{t('subtask.status.wait')}</Text>
          </View>
          <View style={[styles.dash, styles.dashDone]}/>
          <View>
            <StateCircle step={currentStep} active={true} done={false} style={{backgroundColor:COLOR.main.darkNavy}}/>
            <Text style={styles.label}>{t('subtask.status.holding')}</Text>
          </View>
          <View style={styles.dash}/>
          <View>
            <StateCircle step={currentStep} active={false} done={false} />
            <Text style={styles.label}>{t('subtask.status.complete')}</Text>
          </View>
        </>
      }
    </View>
  )
}

export default StateView

const styles = StyleSheet.create({
  stateViewArea: {
    flexDirection: 'row',
    justifyContent:'space-between',
  },
  dash:{
    flex: 1,
    height: 1,
    borderTopWidth: 1,
    borderStyle: 'dashed',
    borderColor: COLOR.gray.g300,
    marginTop: 12,
  },
  dashDone: {
    borderTopWidth: 2,
    borderStyle:'solid',
    borderColor: COLOR.main.turquoise,
    marginTop: 11
  },
  dot:{
    position: 'relative',
    alignItems: 'center'
  },
  label:{
    position: 'absolute',
    width: 200,
    top: 26,
    textAlign: 'center',
    left: -88,
    ...FONT.txtXsMedium,
    color: COLOR.gray.g700
  },
})