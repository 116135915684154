/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react'
import { StyleProp, StyleSheet, View, ViewProps, ViewStyle } from 'react-native'

import AnyItem from './Item/AnyItem'
import DisabledItem from './Item/DisabledItem'
import DividingLine from './Item/DividingLine'
import Item from './Item/Item'

import { COLOR, getBoxShadow } from '@rocket-mono/libs'

interface Props extends ViewProps {
  style?: StyleProp<ViewStyle>
}

const SelectBox = ({ style, children }: Props) => {
  return <View style={[styles.container, style]}>{children}</View>
}

export default SelectBox

SelectBox.Item = Item
SelectBox.AnyItem = AnyItem
SelectBox.DisabledItem = DisabledItem
SelectBox.DividingLine = DividingLine

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    overflow: 'hidden',
    alignSelf: 'flex-start',
    minWidth: 156,
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: COLOR.gray.g200,
    ...getBoxShadow(0, 0, 0, 0.1, 4, 0, 0, 0),
  },
})
