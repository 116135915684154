import React from 'react'
import { StyleSheet, View } from 'react-native'

import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'

import { COLOR } from '@rocket-mono/libs'
import { FAIcon } from '@rocket-atoms/icon'

import { SkeletonBar, SkeletonCircle, SkeletonSquare } from '../SkeletomParts'

interface Props {
  columnPoint: boolean
  onClose: () => void
}

const SkeletonMemberInviteScreen: React.FC<Props> = ({ columnPoint }) => {
  const popBodyHeight = 470
  return (
    <View style={[{ width: '100%' }, columnPoint ? { height: '100%' } : { height: 552 }]}>
      <View>
        <View style={styles.headerTopContainer}>
          <SkeletonBar width={100} height={20} />
        </View>
        <View style={styles.headerBottomContainer}>
          <View style={[styles.labelContainer]}>
            <View style={{ marginRight: 4 }}>
              <SkeletonSquare width={20} height={20} />
            </View>
            <SkeletonBar width={120} height={20} />
          </View>
        </View>
      </View>
      <View style={[styles.bodyArea, columnPoint && { flexDirection: 'column', flex: 1, paddingBottom: 320 }]}>
        <View
          style={[
            styles.searchArea,
            { height: popBodyHeight },
            columnPoint && { flex: 1, width: '100%', height: '100%' },
            !columnPoint && { borderBottomLeftRadius: 8 },
          ]}
        >
          <View style={styles.emailSearch}>
            <View style={{ marginBottom: 8 }}>
              <SkeletonBar width={90} height={16} />
            </View>
            <View style={{ marginBottom: 4, opacity: 0.9 }}>
              <SkeletonSquare width={'100%'} height={36} color={'gray.g200'} />
            </View>
            <View style={{ marginBottom: 8, opacity: 0.8 }}>
              <SkeletonSquare width={'100%'} height={36} color={'gray.g200'} />
            </View>
            <SkeletonBar width={'70%'} height={12} />
          </View>
          <View style={{ flex: 1 }}>
            <View style={{ marginBottom: 8 }}>
              <SkeletonBar width={90} height={16} />
            </View>
            <View style={{ position: 'relative', marginBottom: 8, opacity: 0.7 }}>
              <View style={{ position: 'absolute', top: 11, left: 11 }}>
                <SkeletonSquare width={22} height={22} />
              </View>
              <SkeletonSquare width={'100%'} height={46} color={'gray.g200'} />
            </View>
            <View style={{ position: 'relative', marginBottom: 8, opacity: 0.6 }}>
              <View style={{ position: 'absolute', top: 11, left: 11 }}>
                <SkeletonSquare width={22} height={22} />
              </View>
              <SkeletonSquare width={'100%'} height={46} color={'gray.g200'} />
            </View>
            <View style={{ position: 'relative', marginBottom: 8, opacity: 0.5 }}>
              <View style={{ position: 'absolute', top: 11, left: 11 }}>
                <SkeletonSquare width={22} height={22} />
              </View>
              <SkeletonSquare width={'100%'} height={46} color={'gray.g200'} />
            </View>
            <View style={{ position: 'relative', marginBottom: 8, opacity: 0.3 }}>
              <View style={{ position: 'absolute', top: 11, left: 11 }}>
                <SkeletonSquare width={22} height={22} />
              </View>
              <SkeletonSquare width={'100%'} height={46} color={'gray.g200'} />
            </View>
            <View style={{ position: 'relative', marginBottom: 8, opacity: 0.1 }}>
              <View style={{ position: 'absolute', top: 11, left: 11 }}>
                <SkeletonSquare width={22} height={22} />
              </View>
              <SkeletonSquare width={'100%'} height={46} color={'gray.g200'} />
            </View>
          </View>
        </View>
        <View style={[styles.inviteListArea, columnPoint && styles.inviteListColumnArea]}>
          <View
            style={{
              flexDirection: 'row',
              paddingVertical: 12,
              borderBottomWidth: 1,
              borderColor: COLOR.gray.g100,
              opacity: 1,
            }}
          >
            <View>
              <SkeletonCircle width={36} height={36} />
            </View>
            <View style={{ flex: 1, marginLeft: 8 }}>
              <View style={{ marginBottom: 4 }}>
                <SkeletonBar width={'80%'} height={20} />
              </View>
              <View style={{ marginBottom: 16 }}>
                <SkeletonBar width={100} height={12} />
              </View>
              <View>
                <SkeletonSquare width={'100%'} height={40} />
              </View>
            </View>
            <View>
              <FAIcon iconName={faCircleXmark} color={'gray.g200'} />
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              paddingVertical: 12,
              borderBottomWidth: 1,
              borderColor: COLOR.gray.g100,
              opacity: 0.8,
            }}
          >
            <View>
              <SkeletonCircle width={36} height={36} />
            </View>
            <View style={{ flex: 1, marginLeft: 8 }}>
              <View style={{ marginBottom: 4 }}>
                <SkeletonBar width={130} height={20} />
              </View>
              <View style={{ marginBottom: 16 }}>
                <SkeletonBar width={100} height={12} />
              </View>
              <View>
                <SkeletonSquare width={'100%'} height={40} />
              </View>
            </View>
            <View>
              <FAIcon iconName={faCircleXmark} color={'gray.g200'} />
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              paddingVertical: 12,
              borderBottomWidth: 1,
              borderColor: COLOR.gray.g100,
              opacity: 0.6,
            }}
          >
            <View>
              <SkeletonCircle width={36} height={36} />
            </View>
            <View style={{ flex: 1, marginLeft: 8 }}>
              <View style={{ marginBottom: 4 }}>
                <SkeletonBar width={60} height={20} />
              </View>
              <View style={{ marginBottom: 16 }}>
                <SkeletonBar width={100} height={12} />
              </View>
              <View>
                <SkeletonSquare width={'100%'} height={40} />
              </View>
            </View>
            <View>
              <FAIcon iconName={faCircleXmark} color={'gray.g200'} />
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              paddingVertical: 12,
              borderBottomWidth: 1,
              borderColor: COLOR.gray.g100,
              opacity: 0.4,
            }}
          >
            <View>
              <SkeletonCircle width={36} height={36} />
            </View>
            <View style={{ flex: 1, marginLeft: 8 }}>
              <View style={{ marginBottom: 4 }}>
                <SkeletonBar width={160} height={20} />
              </View>
              <View style={{ marginBottom: 16 }}>
                <SkeletonBar width={100} height={12} />
              </View>
            </View>
            <View>
              <FAIcon iconName={faCircleXmark} color={'gray.g200'} />
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default SkeletonMemberInviteScreen

const styles = StyleSheet.create({
  container: {
    width: 424,
    minHeight: 560,
    backgroundColor: COLOR.mono.white,
    borderRadius: 8,
  },
  headerTopContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 4,
    width: 350,
  },
  headerBottomContainer: {
    marginTop: 4,
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  bodyArea: {
    flexDirection: 'row',
  },
  searchArea: {
    width: '50%',
    backgroundColor: COLOR.gray.g050,
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  emailSearch: {
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g150,
    paddingBottom: 20,
    marginBottom: 20,
  },
  inviteListArea: {
    width: '50%',
    paddingHorizontal: 16,
    paddingTop: 8,
    paddingVertical: 24,
  },
  inviteListColumnArea: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 300,
    backgroundColor: COLOR.mono.white,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    zIndex: 999,
  },
  inviteList: {
    paddingTop: 8,
    paddingBottom: 60,
    paddingHorizontal: 24,
  },
})
