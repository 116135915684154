import { COLOR, ClickOutside } from '@rocket-mono/libs'
import { Text } from '@rocket/atoms'
import { debounce } from 'lodash'
import React from 'react'
import { FlatList, Pressable, StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native'

type TimeType = {
  hour: string
  minute: string
}

const getInitialTime = (time?: string) => {
  if (!time) {
    return {
      hour: '09',
      minute: '00',
    }
  }
  const timeSplit = time.split(':')
  if (timeSplit.length !== 2) {
    return {
      hour: '09',
      minute: '00',
    }
  }
  return {
    hour: timeSplit[0],
    minute: timeSplit[1],
  }
}

const pad = (n: number, size = 2) => {
  return String(n).padStart(size, '0')
}

const hours = Array.from(Array(24).keys()).map((hour) => `${pad(hour)}`)
const minutes = Array.from(Array(60).keys()).map((minute) => `${pad(minute)}`)

const zIndex = 1

interface Props {
  initialTime?: string
  onTimeChange?: (time: string) => void
}

const TimePicker: React.FC<Props> = ({ initialTime, onTimeChange }) => {
  const [visible, setVisible] = React.useState(false)
  const [time, setTime] = React.useState<TimeType>(getInitialTime(initialTime))
  const [timePickerHover, setTimePickerHover] = React.useState<string>()
  const hourFlatListRef = React.useRef<FlatList<string>>(null)
  const minuteFlatListRef = React.useRef<FlatList<string>>(null)

  const TimePickerItem = ({
    type,
    column,
    onChange,
  }: {
    type: string
    column: string
    onChange: (column: string) => void
  }) => {
    const buttonStyle: StyleProp<ViewStyle> = [styles.timepickerItem]
    const textStyle: StyleProp<TextStyle> = [styles.timepickerItemText]

    if (timePickerHover === `${type}-${column}`) {
      buttonStyle.push(styles.timepickerItemHover)
      textStyle.push(styles.timepickerItemHoverText)
    }

    if (type === 'hour') {
      if (time.hour === column) {
        buttonStyle.push(styles.timepickerItemActive)
        textStyle.push(styles.timepickerItemActiveText)
      }
    }
    if (type === 'minute') {
      if (time.minute === column) {
        buttonStyle.push(styles.timepickerItemActive)
        textStyle.push(styles.timepickerItemActiveText)
      }
    }
    return (
      <Pressable
        style={buttonStyle}
        onPress={() => onChange(column)}
        onHoverIn={() => setTimePickerHover(`${type}-${column}`)}
        onHoverOut={() => setTimePickerHover(undefined)}
      >
        <Text fontName="txtSmMedium" style={textStyle}>
          {column}
        </Text>
      </Pressable>
    )
  }

  return (
    <>
      <Pressable
        style={styles.pickerStyle}
        onPress={() => {
          const hourIndex = hours.findIndex((hour) => hour === time.hour)
          const minuteIndex = minutes.findIndex((minute) => minute === time.minute)
          setTimeout(() => {
            if (hourFlatListRef.current) {
              if (hourIndex > -1 && hourIndex - 2 > -1) {
                hourFlatListRef.current.scrollToIndex({
                  index: hourIndex - 2,
                  animated: false,
                })
              }
            }
          }, 200)
          setTimeout(() => {
            if (minuteFlatListRef.current) {
              if (minuteIndex > -1 && minuteIndex - 2 > -1) {
                minuteFlatListRef.current.scrollToIndex({
                  index: minuteIndex - 2,
                  animated: false,
                })
              }
            }
          }, 200)
          setVisible(true)
        }}
      >
        <Text>{`${time.hour}:${time.minute}`}</Text>
      </Pressable>
      {visible ? (
        <View style={[styles.container, { zIndex }]}>
          <ClickOutside onClickOutside={() => setVisible(false)}>
            <View style={styles.timepickerContainer}>
              <View style={styles.timepickerBodyContainer}>
                <View style={styles.timepickerColumnContainer}>
                  <FlatList
                    ref={hourFlatListRef}
                    onLayout={() => {
                      const hourIndex = hours.findIndex((hour) => hour === time.hour)
                      if (hourFlatListRef.current) {
                        if (hourIndex > -1 && hourIndex - 2 > -1) {
                          hourFlatListRef.current.scrollToOffset({
                            offset: (hourIndex - 2) * 32,
                            animated: false,
                          })
                        }
                      }
                    }}
                    onScrollToIndexFailed={debounce(() => {
                      const hourIndex = hours.findIndex((hour) => hour === time.hour)
                      if (hourFlatListRef.current) {
                        if (hourIndex > -1 && hourIndex - 2 > -1) {
                          hourFlatListRef.current.scrollToIndex({
                            index: hourIndex - 2,
                            animated: false,
                          })
                        }
                      }
                    }, 100)}
                    data={hours}
                    keyExtractor={(item) => `hours-${item}`}
                    renderItem={({ item }: { item: string }) => (
                      <TimePickerItem
                        type="hour"
                        column={item}
                        onChange={(hour) => {
                          setTime((prev) => ({ ...prev, hour }))
                          onTimeChange?.(`${hour}:${time.minute}`)
                        }}
                      />
                    )}
                  />
                </View>
                <View style={styles.timepickerColumnContainer}>
                  <FlatList
                    ref={minuteFlatListRef}
                    onLayout={() => {
                      const minuteIndex = minutes.findIndex((minute) => minute === time.minute)
                      if (minuteFlatListRef.current) {
                        if (minuteIndex > -1 && minuteIndex - 2 > -1) {
                          minuteFlatListRef.current.scrollToOffset({
                            offset: (minuteIndex - 2) * 32,
                            animated: false,
                          })
                        }
                      }
                    }}
                    onScrollToIndexFailed={debounce(() => {
                      const minuteIndex = minutes.findIndex((minute) => minute === time.minute)
                      if (minuteFlatListRef.current) {
                        if (minuteIndex > -1 && minuteIndex - 2 > -1) {
                          minuteFlatListRef.current.scrollToIndex({
                            index: minuteIndex - 2,
                            animated: false,
                          })
                        }
                      }
                    }, 100)}
                    data={minutes}
                    keyExtractor={(item) => `minutes-${item}`}
                    renderItem={({ item }) => (
                      <TimePickerItem
                        type="minute"
                        column={item}
                        onChange={(minute) => {
                          setTime((prev) => ({ ...prev, minute }))
                          onTimeChange?.(`${time.hour}:${minute}`)
                        }}
                      />
                    )}
                  />
                </View>
              </View>
              <View style={styles.timepickerFooterContainer}>
                <Text fontName="txtSmMedium" fontColor="gray.g800">
                  {time.hour}:{time.minute}
                </Text>
              </View>
            </View>
          </ClickOutside>
        </View>
      ) : (
        <></>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  pickerStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 124,
    height: 36,
    borderWidth: 1,
    borderColor: COLOR.transparent.base,
    backgroundColor: COLOR.opacity.blk05,
    borderRadius: 4,
    marginTop: 6,
  },
  container: {
    height: 200,
    width: 124,
    marginTop: 0,
    marginBottom: -200,
  },
  timepickerContainer: {
    height: 200,
    width: 124,
    backgroundColor: COLOR.mono.white,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    flex: 1,
    marginBottom: 200,
  },
  timepickerBodyContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  timepickerColumnContainer: {
    flex: 1,
  },
  timepickerItem: {
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  timepickerItemActive: {
    backgroundColor: COLOR.primary.blue600,
  },
  timepickerItemHover: {
    backgroundColor: COLOR.gray.g100,
  },
  timepickerItemText: {
    color: COLOR.gray.g500,
  },
  timepickerItemActiveText: {
    color: COLOR.mono.white,
  },
  timepickerItemHoverText: {
    color: COLOR.gray.g800,
  },
  timepickerFooterContainer: {
    height: 40,
    borderTopWidth: 1,
    borderTopColor: COLOR.gray.g200,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default TimePicker
