import * as React from 'react'
import { View, ViewStyle } from 'react-native'

import { COLOR } from '@rocket-mono/libs'

import XEIcon from '../XEIcon'

interface Props {
  style?: ViewStyle
}

const IconProfileLabelLeader: React.FC<Props> = ({ style }) => {
  return (
    <View
      style={[
        {
          backgroundColor: COLOR.main.red,
          borderRadius: 30,
          paddingTop: 1,
          paddingBottom: 3,
          paddingHorizontal: 2,
          borderWidth: 2,
          borderColor: COLOR.mono.white,
          width: 20,
        },
        style,
      ]}
    >
      <XEIcon name="crown" color="mono.white" size={12} />
    </View>
  )
}

export default React.memo(IconProfileLabelLeader)
