import React, { useCallback, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'

import { faUser } from '@fortawesome/free-regular-svg-icons'

import { COLOR, ClickOutside } from '@rui/foundations'
import { Button } from '@rocket-atoms/button'
import { FileCircleIcon } from '@rocket-atoms/icon'
import { DropdownItemProps } from '@rocket-atoms/dropdown'

import { SearchCombobox } from './SearchCombobox'
import { FileIconType } from '../../ActivitySearchItem/types'
import { Pressable } from 'react-native'
import { FAIcon } from '@rocket-atoms/icon'
interface Props {
  searchFileList: FileIconType[]
  defaultIndex: number
  searchMemberList: DropdownItemProps[]
  isSearchMember: boolean
  onChangeCode?: (code: string) => void
  onChangeMember?: (codes: string[]) => void
}

const ActivitySearchSectionSortFile: React.FC<Props> = ({
  defaultIndex,
  searchFileList,
  searchMemberList,
  isSearchMember,
  onChangeCode,
  onChangeMember,
}) => {
  const [code, setCode] = useState<string>(searchFileList[defaultIndex])
  const [memberIndex, setMemberIndex] = useState<number[]>([])
  const [isCombobox, setIsCombobox] = useState(false)

  const handlePressClear = useCallback(() => {
    setMemberIndex([])
    onChangeMember && onChangeMember([])
    setIsCombobox(false)
  }, [onChangeMember])

  const handlePressApply = useCallback(
    (codes) => {
      setMemberIndex(
        searchMemberList
          .map(({ code }, idx) => ({ code, idx }))
          .filter(({ code }) => codes.includes(code))
          .map(({ idx }) => idx),
      )
      onChangeMember && onChangeMember(codes)
      setIsCombobox(false)
    },
    [searchMemberList, onChangeMember],
  )

  const handlePressCode = useCallback(
    (code) => {
      console.log('handlePressCode', code)
      setCode(code)
      onChangeCode && onChangeCode(code)
    },
    [onChangeCode],
  )

  return (
    <View style={styles.container}>
      <View style={styles.fileListArea}>
        <ScrollView horizontal showsVerticalScrollIndicator={false}>
          {searchFileList.map((item, idx) => (
            <View key={idx} style={styles.fileItemStyle}>
              <FileCircleIcon type={item} onPress={() => {}} code={code} setCode={handlePressCode} />
            </View>
          ))}
        </ScrollView>
        <View style={styles.grdCover}></View>
      </View>
      {searchMemberList.length > 0 && (
        <>
          <View style={styles.divLine} />
          <View>
            <Pressable
              onPress={() => setIsCombobox(true)}
              style={[
                {
                  width: 36,
                  height: 36,
                  borderWidth: 1,
                  borderRadius: 4,
                  borderColor: COLOR.gray.g400,
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                isSearchMember && { borderColor: COLOR.primary.blue500 },
              ]}
            >
              <FAIcon iconName={faUser} size={'sm'} color={isSearchMember ? 'primary.blue500' : 'gray.g600'} />
            </Pressable>
            {isCombobox && (
              <ClickOutside onClickOutside={() => setIsCombobox(false)}>
                <View style={{ position: 'absolute', minWidth: 244, top: 40, right: 0, zIndex: 99 }}>
                  <SearchCombobox
                    defaultIndex={memberIndex}
                    resultList={searchMemberList}
                    onPressClear={handlePressClear}
                    onPressApply={handlePressApply}
                  />
                </View>
              </ClickOutside>
            )}
          </View>
        </>
      )}
    </View>
  )
}

export default ActivitySearchSectionSortFile

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 20,
    marginBottom: 8,
  },
  fileListArea: {
    flex: 1,
    paddingRight: 4,
  },
  divLine: {
    width: 1,
    height: 28,
    backgroundColor: COLOR.gray.g300,
    marginLeft: 4,
    marginRight: 12,
  },
  grdCover: {
    position: 'absolute',
    top: '50%',
    right: 0,
    width: 24,
    height: 36,
    marginTop: -18,
    zIndex: 9,
    backgroundColor: COLOR.mono.white,
    opacity: 0.5,
  },
  fileItemStyle: {
    marginRight: 4,
  },
})
