import { CardUnAuthorizedScreen } from '@rocket-mono/screen-card'
import React, { useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const CardUnAuthorized: React.FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const handlePressLogin = useCallback(() => {
    const redirectUrl = searchParams.get('redirect_url') ?? ''
    navigate(`/login?redirect_url=${encodeURIComponent(redirectUrl)}`)
  }, [])
  return (
    <CardUnAuthorizedScreen
      onPressLogin={handlePressLogin}
      onPressSignup={() => {
        navigate('/join')
      }}
    />
  )
}

export default CardUnAuthorized
