import React from 'react'
import { Animated, StyleSheet, View } from 'react-native'

import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import { FAIcon } from '@rocket-atoms/icon'
import { COLOR, getBoxShadow } from '@rocket-mono/libs'

import { use100vh } from 'react-div-100vh'
import { SkeletonBar, SkeletonSquare } from '../SkeletomParts'
import SkeletonWorkCardView from '../WorkCardView'

interface Props {
  isModal?: boolean
}

const SkeletonWorkCardNewTabView: React.FC<Props> = ({ isModal = false }) => {
  const height = use100vh()
  const [spinAnim, setSpinAnim] = React.useState(new Animated.Value(0))
  const spin = spinAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  })

  React.useEffect(() => {
    Animated.loop(
      Animated.timing(spinAnim, {
        toValue: 1,
        duration: 1500,
        useNativeDriver: false,
      }),
    ).start()
  }, [])

  const style = React.useMemo(() => {
    if (isModal)
      return {
        width: 800,
        ...getBoxShadow(0, 0, 0, 0.05, 5, -7, 5, 0),
        zIndex: 1,
        position: 'absolute',
        right: 0,
      } as const
  }, [height, isModal])

  return (
    <View style={[style, { backgroundColor: COLOR.mono.white, height: height ?? '100%' }]}>
      <View style={styles.header}>
        <SkeletonBar width={130} height={20} />
        <View style={{ marginTop: 4, opacity: 0.9 }}>
          <SkeletonSquare width={'100%'} height={48} />
        </View>
      </View>
      <View style={styles.bodyArea}>
        <View style={styles.contentsArea}>
          <View style={{ maxWidth: 900, width: '100%' }}>
            <SkeletonWorkCardView />
          </View>
        </View>
        {isModal ? (
          <></>
        ) : (
          <View style={styles.sideArea}>
            <Animated.View style={{ transform: [{ rotate: spin }] }}>
              <FAIcon iconName={faSpinner} size={'2x'} color={'gray.g200'} />
            </Animated.View>
          </View>
        )}
      </View>
    </View>
  )
}

export default SkeletonWorkCardNewTabView

const styles = StyleSheet.create({
  header: {
    height: 125.5,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g100,
    padding: 20,
  },
  bodyArea: {
    flex: 1,
    flexDirection: 'row',
  },
  contentsArea: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
  },
  sideArea: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 340,
    borderLeftWidth: 1,
    borderColor: COLOR.gray.g100,
  },
})
