import * as React from 'react'
import { Platform, StyleSheet, TouchableOpacity, View, ViewStyle, Pressable } from 'react-native'

import { faArrowRight, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { COLOR, ClickOutside, FONT, getRealColor } from '@rocket-mono/libs'
import { Text } from '@rocket/atoms'
import { CardIcon } from '@rocket-atoms/icon'
import { CardType } from '@rocket/types'

export type Axis = {
  x: number
  y: number
}

export type AppList = {
  cardType?: string | CardType
  title: string
  state: string
  disabled?: boolean
  divider?: boolean
}

interface Props {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  appList?: AppList[]
  onPopupState?: (state: string) => void
  onAppsPress?: () => void
  containerStyle?: ViewStyle
  axis?: Axis
  bottomText?: string
  marginTop?: number
  marginRight?: number
  popAxisPosition?: string
  AxisWide?: number
}

interface AddCardButtonProps {
  disabled?: boolean
  state: string
  title?: string
  onPopupState?: (state: string) => void
  setIsOpen: (arg0: boolean) => void
  type?: CardType
}

const AddCardButton = ({ disabled, state, type, title, onPopupState, setIsOpen }: AddCardButtonProps) => {
  const [isHover, setIsHover] = React.useState(false)

  return (
    <Pressable
      onPress={() => {
        if (!disabled) {
          if (onPopupState) {
            onPopupState(state)
          }
          setIsOpen(false)
        }
      }}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      style={[styles.flexbox, styles.button, isHover && { backgroundColor: COLOR.gray.g050 }]}
    >
      {type && type !== 'TODO' && <CardIcon variant="ROUNDSQUARE" type={type} size={'sm'} />}
      <Text style={styles.listText} fontColor={disabled ? 'mono.gray' : 'mono.black'}>
        {title}
      </Text>
    </Pressable>
  )
}

function AddPopup({
  isOpen,
  setIsOpen,
  appList,
  onPopupState,
  onAppsPress,
  containerStyle,
  // axis = { x: 0, y: 0 },
  bottomText,
  // marginTop = 30,
  // marginRight = 0,
  popAxisPosition,
  AxisWide,
}: Props) {
  // const useAxis = React.useMemo(() => {
  //   console.log('axis', axis)
  //   return axis
  // }, [axis])

  if (!isOpen) {
    return <></>
  }

  if (!appList || appList.length === 0) return <></>

  return (
    <View
      testID="addPopup.view"
      style={[
        styles.container,
        containerStyle,
        AxisWide ? { width: AxisWide + 20 } : {},
        Platform.select({
          ios: {
            shadowColor: 'rgb(50, 50, 50)',
            shadowOpacity: 0.5,
            shadowRadius: 4,
            shadowOffset: {
              height: 0,
              width: 0,
            },
          },
          android: {
            elevation: 10,
          },
          web: {
            boxShadow: `0px 0px 5px rgba(0, 0, 0, 0.1)`,
            zIndex: 5,
          },
        }),
        popAxisPosition === 'left' && {
          // top: useAxis.y + marginTop + 5,
          top: 25,
          left: -180,
        },
        // !popAxisPosition && {
        //   top: useAxis.y + marginTop + 5,
        //   left: Dimensions.get('window').width - 180 > useAxis.x ? useAxis.x : useAxis.x - 138 - marginRight,
        // },
      ]}
    >
      <ClickOutside onClickOutside={() => setIsOpen(false)}>
        {appList.map((app, idx) => (
          <View key={`app-${idx}`}>
            {app.divider && <View style={styles.line} />}
            <AddCardButton
              disabled={app.disabled}
              state={app.state}
              type={app.cardType as CardType}
              title={app.title}
              onPopupState={onPopupState}
              setIsOpen={setIsOpen}
            />
          </View>
        ))}

        {bottomText && (
          <>
            <View style={styles.bottomTextArea}>
              <TouchableOpacity onPress={onAppsPress} style={[styles.button, styles.bottomButton]}>
                <View style={styles.flexbox}>
                  {appList && appList[0].cardType && (
                    <View style={styles.addIcon}>
                      <FontAwesomeIcon icon={faPlus} size={11} color={getRealColor('gray.g400')} />
                    </View>
                  )}
                  <Text style={styles.buttonText}>{bottomText}</Text>
                </View>

                <View>
                  <FontAwesomeIcon icon={faArrowRight} size={11} color={getRealColor('gray.g400')} />
                </View>
              </TouchableOpacity>
            </View>
          </>
        )}
      </ClickOutside>
    </View>
  )
}

AddPopup.defaultProps = {
  containerStyle: {},
}

export default React.memo(AddPopup)

const styles = StyleSheet.create({
  flexbox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rootContainer: {
    flex: 1,
  },
  backContainer: {
    flex: 1,
  },
  container: {
    overflow: 'hidden',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: COLOR.gray.g200,
    backgroundColor: COLOR.mono.white,
    width: 200,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 50,
  },
  ifCardIcon: {
    marginRight: 8,
  },
  bottomTextArea: {
    paddingVertical: 4,
    borderTopWidth: 1,
    borderColor: COLOR.gray.g150,
  },
  line: {
    borderTopWidth: 1,
    borderTopColor: COLOR.gray.g150,
  },
  button: {
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  listText: {
    ...FONT.txtSm,
    marginLeft: 8,
  },
  buttonText: {
    ...FONT.txtXs,
    color: COLOR.gray.g600,
  },
  bottomButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addIcon: {
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: COLOR.gray.g300,
    borderStyle: 'dashed',
    borderRadius: 6,
    marginRight: 8,
  },
})
