import { useEffect, useState } from 'react'
import { Keyboard } from 'react-native'

const useKeyboard = () => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const onKeyboardDidShow = () => {
      setVisible(true)
    }
    const onKeyboardDidhide = () => {
      setVisible(false)
    }

    const showSubscription = Keyboard.addListener(
      'keyboardDidShow',
      onKeyboardDidShow,
    )
    const hideSubscription = Keyboard.addListener(
      'keyboardDidHide',
      onKeyboardDidhide,
    )

    return () => {
      showSubscription.remove()
      hideSubscription.remove()
    }
  }, [])

  return {
    visible,
  }
}

export default useKeyboard
