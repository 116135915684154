import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyleSheet, View } from 'react-native'
import { COLOR } from '@rui/foundations'
import { faSwatchbook } from '@fortawesome/free-solid-svg-icons'
import GuideCloseButton from '../GuideCloseButton'
import TemplateItem from '../TemplateItem'
import { TemplateItemType } from '../TemplateItem'
import { FAIcon, Text } from '@rocket/atoms'
import { SkeletonCircle, SkeletonBar } from '@rocket/skeleton'

interface Props {
  skeleton?: boolean
  mediaUrl: string
  templateList: TemplateItemType[]
  onPressClose: () => void
  size: 'md' | 'sm'
}

const TemplateGuide: React.FC<Props> = ({ skeleton, templateList, onPressClose, size }) => {
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <View style={styles.titleArea}>
        {skeleton ? (
          <>
            <View style={{ marginRight: 4 }}>
              <SkeletonCircle width={22} height={22} color={'darkmode.warm500'} />
            </View>
            <SkeletonBar width={230} height={15} color={'darkmode.warm500'} />
          </>
        ) : (
          <>
            <FAIcon iconName={faSwatchbook} color={'darkmode.cool300'} />
            <Text fontName="txtMdBold" fontColor="darkmode.cool300" style={styles.text}>
              {t('workmodel.subtitle')}
            </Text>
            <View style={styles.closeButton}>
              <GuideCloseButton onPress={onPressClose} />
            </View>
          </>
        )}
      </View>
      <View style={styles.listArea}>
        {templateList.map((item, idx) => (
          <View key={idx} style={styles.listItemWrap}>
            <TemplateItem skeleton={skeleton} size={size} item={item} key={`template-${idx}`} />
          </View>
        ))}
      </View>
    </View>
  )
}

export default TemplateGuide

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingTop: 12,
    paddingBottom: 8,
    backgroundColor: COLOR.darkmode.warm700,
  },
  text: {
    marginLeft: 4,
  },
  titleArea: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
  },
  listArea: {
    marginTop: 12,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  listItemWrap: {
    marginRight: 8,
    marginBottom: 8,
  },
})
