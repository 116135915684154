import * as React from 'react'
import { Platform } from 'react-native'
import ButtonIconWithTooltip, { ButtonIconWithTooltipProps } from './ButtonIconWithTooltip'
import PlainButtonIcon from './PlainButtonIcon'

export type BadgeSide = 'left' | 'right'
export type BadgeType = 'MAX_COUNT_AND_CURRENT_COUNT' | 'CURRENT_COUNT_ONLY'

type Props = ButtonIconWithTooltipProps

const ButtonIcon: React.FC<Props> = ({ tooltipText, tooltipPosition, ...props }: Props) => {
  if (Platform.OS === 'web' && tooltipText) {
    return <ButtonIconWithTooltip tooltipText={tooltipText} tooltipPosition={tooltipPosition} {...props} />
  }

  return <PlainButtonIcon {...props} />
}

export default ButtonIcon
