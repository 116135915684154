import type { TargetUserState } from '@rocket/types'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native'

import { COLOR, FONT } from '@rocket-mono/libs'

export type WhisperTargetState = TargetUserState

interface Props {
  userName?: string
  status?: WhisperTargetState
  onPress: () => void
}

const WhisperButton: React.FC<Props> = ({ userName, status = 'inactive', onPress }) => {
  const { t } = useTranslation()
  return (
    <TouchableOpacity style={styles.whisperButton} onPress={onPress}>
      {!!userName && <View style={[styles.status, { backgroundColor: COLOR.status[status] }]}></View>}
      <Text style={styles.text} numberOfLines={1} selectable={false}>
        {userName ?? t('chat.everyone')}
      </Text>
    </TouchableOpacity>
  )
}

export default WhisperButton

const styles = StyleSheet.create({
  whisperButton: {
    alignSelf: 'center',
    width: 84,
    height: 30,
    backgroundColor: COLOR.mono.white,
    borderRadius: 99,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: COLOR.mono.lightGray,
    borderWidth: 1,
    marginRight: 10,
    ...Platform.select({
      web: {
        paddingHorizontal: 10,
      },
      native: {
        paddingHorizontal: 14,
      },
    }),
  },
  text: {
    ...FONT.subTextRegular,
    color: COLOR.mono.paleBlack,
    ...Platform.select({ native: { paddingTop: 2 } }),
  },
  status: {
    width: 10,
    height: 10,
    borderRadius: 10,
    marginRight: 5,
    marginTop: 2,
  },
})
