import type { ColorValue } from 'react-native'
import { COLOR } from '../styles/theme.style'
import { getRealColor, IColors } from '..'

const COLOR_SET: { backgroundColor: ColorValue; color: ColorValue }[] = [
  {
    backgroundColor: COLOR.sub.green,
    color: COLOR.mono.white,
  },
  {
    backgroundColor: COLOR.sub.sky,
    color: COLOR.mono.white,
  },
  {
    backgroundColor: COLOR.sub.darkPurple,
    color: COLOR.mono.white,
  },
  {
    backgroundColor: COLOR.sub.ocean,
    color: COLOR.mono.black,
  },
  {
    backgroundColor: COLOR.sub.pink,
    color: COLOR.mono.white,
  },
  {
    backgroundColor: COLOR.sub.paleYellow,
    color: COLOR.mono.white,
  },
  {
    backgroundColor: COLOR.sub.purple,
    color: COLOR.mono.white,
  },
  {
    backgroundColor: COLOR.sub.lightGreen,
    color: COLOR.mono.white,
  },
  {
    backgroundColor: COLOR.sub.paleBlue,
    color: COLOR.mono.white,
  },
  {
    backgroundColor: COLOR.sub.paleSky,
    color: COLOR.mono.white,
  },
  {
    backgroundColor: COLOR.sub.lightYellow,
    color: COLOR.mono.black,
  },
  {
    backgroundColor: COLOR.sub.darkNavy,
    color: COLOR.mono.white,
  },
  {
    backgroundColor: COLOR.sub.lightBlue,
    color: COLOR.mono.white,
  },
]

export const getRandomColorSet = (
  filterColor?: IColors,
): {
  backgroundColor: ColorValue
  color: ColorValue
} => {
  const colorSetList = COLOR_SET.filter((o) =>
    filterColor ? o.color === getRealColor(filterColor) : true,
  )
  const randomIndex = Math.floor(Math.random() * colorSetList.length)
  return colorSetList[randomIndex]
}
