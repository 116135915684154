import { Outlet, useOutletContext, useParams, useSearchParams } from 'react-router-dom'

import { useAstro } from '@rocket-mono/providers'
import { CardType } from '@rocket/types'
import { Skeleton } from '@rocket/skeleton'
import React, { useEffect, useState } from 'react'
import { Platform } from 'react-native'
import Provider from './provider'
import { useRecoilValue } from 'recoil'
import { isAnonymousState } from '../../recoils'

type ContextType = {
  projectId: string
  channelId: string
  cardType: CardType
  cardId?: string
  cardTitle?: string
  todoId?: string
}

const Layout: React.FC = () => {
  const { cardId } = useParams()
  const [searchParams] = useSearchParams()
  const isAnonymous = useRecoilValue(isAnonymousState)

  const { astro } = useAstro()

  const [cardTitle, setCardTitle] = useState<string>()
  const [cardType, setCardType] = useState<CardType>()
  const [channelId, setChannelId] = useState<string>()
  const [projectId, setProjectId] = useState<string>()
  const [todoId, setTodoId] = useState<string>()

  useEffect(() => {
    const channelId = searchParams.get('channelId') || searchParams.get('channelNo')
    const cardType = searchParams.get('cardType')
    const todoId = searchParams.get('todoId')

    console.log('searchParams', searchParams, { channelId, cardType, todoId })
    setChannelId(channelId ?? undefined)
    setTodoId(todoId ?? undefined)
    setTimeout(() => setCardType((cardType as CardType) ?? undefined), 100)
  }, [searchParams])

  useEffect(() => {
    if (channelId && projectId === undefined)
      astro.readChannel(Number(channelId)).then((channel) => setProjectId(channel.projectId))
  }, [channelId, projectId])

  useEffect(() => {
    if (cardType === undefined && cardId) {
      astro
        .readCard(cardId)
        .then((card) => {
          console.log('readCard', isAnonymous, card, todoId)

          setCardType(card.type as CardType)
          setCardTitle(card.title)
          setProjectId(String(card.projectNo))
          const roomId = card.roomIds.split(',')[0]
          if (channelId === undefined)
            astro.readChannel(roomId).then((channel) => {
              console.log('channel', channel)
              setChannelId(channel.id)
            })
        })
        .catch((err) => {
          console.error('CardLayout-readCard', err)
          if (err.status === 403) {
            // navigate('/error/card/unauthorized')
          }
        })
    }
  }, [cardType, cardId, channelId])

  if (channelId === undefined || projectId === undefined) {
    if (Platform.OS === 'web') {
      return <Skeleton path={location.pathname} />
    } else {
      return <></>
    }
  }
  return (
    <Provider channelId={channelId} projectId={projectId}>
      <Outlet context={{ projectId, channelId, cardId, cardType, cardTitle, todoId }} />
    </Provider>
  )
}

export const useCardLayout = () => {
  return useOutletContext<ContextType>()
}

export default Layout
