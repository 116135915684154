import { Icon } from '@rocket-atoms/icon'
import type { IColors, IconName } from '@rocket-mono/libs'
import { COLOR, FONT, getRealColor } from '@rocket-mono/libs'
import React from 'react'
import { StyleProp, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native'

export interface BarButtonProps {
  title: string
  iconName?: IconName
  callToAction?: boolean
  disabled?: boolean
  action?: () => void
  backgroundColor?: IColors
  contanerStyle?: StyleProp<ViewStyle>
}

const BarButton: React.FC<BarButtonProps> = ({
  title,
  iconName,
  callToAction,
  disabled = false,
  action,
  backgroundColor,
  contanerStyle,
}) => {
  return (
    <TouchableOpacity
      style={[
        styles.container,
        callToAction ? { backgroundColor: COLOR.main.navy } : { backgroundColor: COLOR.main.red },
        disabled && styles.disableButton,
        contanerStyle,
        backgroundColor && { backgroundColor: getRealColor(backgroundColor) },
      ]}
      disabled={disabled}
      onPress={action}
    >
      {iconName && (
        <View style={styles.iconContainer}>
          <Icon name={iconName} color="mono.white" size={16} />
        </View>
      )}
      <Text style={styles.textStyle}>{title}</Text>
    </TouchableOpacity>
  )
}

export default BarButton

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: 48,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconContainer: {
    marginRight: 4,
  },
  textStyle: {
    ...FONT.listRegular,
    color: COLOR.mono.white,
  },
  disableButton: {
    backgroundColor: COLOR.mono.lightGray,
  },
})
