import React from 'react'
import { StyleSheet, View } from 'react-native'
import IconFile from '../../IconFile'
import { FileIconProps } from './types'

const FileIcon: React.FC<FileIconProps> = ({ mimeType }) => {
  const { width, height } = React.useMemo(() => {
    return { width: 44, height: 44 }
  }, [])
  if (mimeType.includes('text') || mimeType.includes('msword') || mimeType.includes('.document')) {
    return (
      <View style={[{ width, height }, styles.container]}>
        <IconFile.Doc.Large />
      </View>
    )
  } else if (mimeType.includes('zip') || mimeType.includes('bzip') || mimeType.includes('gzip')) {
    return (
      <View style={[{ width, height }, styles.container]}>
        <IconFile.Zip.Large />
      </View>
    )
  } else if (mimeType.includes('ms-excel') || mimeType.includes('.sheet')) {
    return (
      <View style={[{ width, height }, styles.container]}>
        <IconFile.Xls.Large />
      </View>
    )
  } else if (mimeType.includes('ms-powerpoint') || mimeType.includes('.presentation')) {
    return (
      <View style={[{ width, height }, styles.container]}>
        <IconFile.Ppt.Large />
      </View>
    )
  } else if (mimeType.includes('pdf')) {
    return (
      <View style={[{ width, height }, styles.container]}>
        <IconFile.Pdf.Large />
      </View>
    )
  } else if (mimeType.includes('video')) {
    return (
      <View style={[{ width, height }, styles.container]}>
        <IconFile.Mp4.Large />
      </View>
    )
  } else {
    return (
      <View style={[{ width, height }, styles.container]}>
        <IconFile.Unknown.Large />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -5,
    marginLeft: -5,
  },
})

export default FileIcon
