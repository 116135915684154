import { ClickOutside } from '@rocket-mono/foundations'
import { COLOR, FONT, getBoxShadow } from '@rocket-mono/libs'
import { Tooltip, WorkIcon } from '@rocket/atoms'
import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import AngleIconButton from '../AngleIconButton'
import WorkItem from './WorkItem'
import { Props } from './types'

const LayoutTopWorkList: React.FC<Props> = ({ theme, workName, workType, currentWorkId, onPressProject, workList }) => {
  const [isFold, setIsFold] = React.useState(false)

  const list = React.useMemo(() => {
    return [
      {
        id: 1,
        groupName: '그룹',
        work: workList.map((project) => {
          const type = project.type.code
          const name = project.title
          const active = project.id === currentWorkId
          const onPress = () => {
            onPressProject && onPressProject(project.id)
          }
          return { type, name, onPress, active }
        }),
      },
    ]
  }, [currentWorkId, workList, onPressProject])

  return (
    <View style={styles.container}>
      {isFold && <View style={styles.foldCover} />}
      <View style={styles.titleArea}>
        <View style={{ width: 20 }}>{workType && <WorkIcon type={workType} size={20} />}</View>
        <View style={{ flexShrink: 1 }}>
          <Tooltip gab={0} darkType={true} direction={'bottom'} text={workName}>
            {(props) => (
              <View style={{ width: '100%' }} {...props}>
                <Text
                  style={[
                    styles.workTitle,
                    theme === 'dark' ? { color: COLOR.mono.white } : { color: COLOR.mono.black },
                  ]}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {workName}
                </Text>
              </View>
            )}
          </Tooltip>
        </View>
        <View style={styles.fixWidth}>
          <AngleIconButton theme={theme} isFold={isFold} setIsFold={setIsFold} />
        </View>
      </View>
      {isFold && (
        <ClickOutside onClickOutside={() => setIsFold(false)}>
          <View style={[styles.workListContainer, { zIndex: 999 }]}>
            {list.map((item, idx) => (
              <WorkItem item={item} key={`workitem-${idx}`} />
            ))}
          </View>
        </ClickOutside>
      )}
    </View>
  )
}

export default LayoutTopWorkList

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    minWidth: 120,
  },
  fixWidth: {
    flexGrow: 1,
  },
  titleArea: {
    flex: 1,
    height: 24,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  workTitle: {
    flex: 1,
    ...FONT.H7Bold,
    marginHorizontal: 4,
    marginRight: 8,
  },
  workListContainer: {
    position: 'absolute',
    overflow: 'scroll',
    top: 28,
    left: -8,
    width: 290,
    maxHeight: 360,
    borderRadius: 4,
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderColor: COLOR.gray.g150,
    padding: 12,
    paddingBottom: 4,
    ...getBoxShadow(0, 0, 0, 0.1, 4, 0, 1, 0),
  },
  foldCover: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 30,
    backgroundColor: COLOR.transparent.base,
    zIndex: 9,
  },
})
