import React from 'react'
import { StyleSheet, View } from 'react-native'
import { COLOR, IColors, getRealColor } from '@rocket-mono/libs'

interface Props {
  size?: number
  color?: IColors
}

const BoardLabel: React.FC<Props> = ({ size, color = 'sub.pink' }) => {
  return <View style={[styles.wrap, { width: size, height: size, backgroundColor: getRealColor(color) }]} />
}

export default BoardLabel

export const styles = StyleSheet.create({
  wrap: {
    borderRadius: 4,
    borderWidth: 2,
    borderColor: COLOR.opacity.w30,
  },
})
