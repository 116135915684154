import { Image } from '@rocket-mono/foundations'
import { COLOR } from '@rocket-mono/libs'
import * as React from 'react'
import { View } from 'react-native'
import { styles } from './styles'
import type { FileThumbnailProps } from './type'

export const FileThumbPREVIEW: React.FC<FileThumbnailProps> = ({ width = 48, height = 48, fileUrl }) => {
  const borderRadius = width / 3
  return (
    <View style={[styles.wrap, { width, height, borderRadius }]}>
      <Image
        size={{ width, height }}
        source={{ uri: fileUrl }}
        style={{
          width,
          height,
          borderRadius,
          borderWidth: 1,
          borderColor: COLOR.gray.g150,
        }}
      />
    </View>
  )
}
