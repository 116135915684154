import React from 'react'
import { StyleSheet, Pressable, Animated } from 'react-native'
import { COLOR } from '@rocket-mono/libs'

interface Props {
  activated: boolean
  onChange: (activated: boolean) => void
  size: 'md' | 'sm'
}

const Switch: React.FC<Props> = ({ activated, onChange, size }) => {
  const [isHover, setIsHover] = React.useState(false)
  const moveAni = React.useRef(new Animated.Value(0)).current

  const onSwitch = () => {
    onChange(!activated)
  }

  React.useEffect(() => {
    if (activated) {
      Animated.timing(moveAni, {
        toValue: size === 'md' ? 20 : 8,
        duration: 100,
        useNativeDriver: true,
      }).start()
    } else {
      Animated.timing(moveAni, {
        toValue: size === 'md' ? 0 : 0,
        duration: 100,
        useNativeDriver: true,
      }).start()
    }
  }, [activated, moveAni])

  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      style={[
        styles.container,
        {
          width: size === 'md' ? 496 : 30,
          padding: size === 'md' ? 3 : 2,
          borderColor: activated ? COLOR.primary.blue100 : COLOR.gray.g150,
          backgroundColor: activated ? COLOR.primary.blue05 : COLOR.gray.g100,
        },
        !activated &&
          isHover && {
            borderColor: COLOR.gray.g200,
            backgroundColor: COLOR.gray.g150,
          },
      ]}
      onPress={onSwitch}
    >
      <Animated.View
        style={[
          styles.switchHandler,
          {
            width: size === 'md' ? 28 : 16,
            height: size === 'md' ? 28 : 16,
            backgroundColor: activated ? COLOR.main.blue : COLOR.mono.white,
            transform: [{ translateX: moveAni }],
          },
        ]}
      ></Animated.View>
    </Pressable>
  )
}

export default Switch

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 50,
  },
  switchHandler: {
    borderRadius: 50,
  },
})
