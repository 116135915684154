import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Keyboard, StyleSheet, View } from 'react-native'

import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Portal } from '@gorhom/portal'
import { COLOR, LayoutPayload } from '@rocket-mono/libs'
import { useAstro, useCurrentUser } from '@rocket-mono/providers'
import { Button, FAIcon } from '@rocket/atoms'
import { ChannelMember, Assignee as RNAssignee, Todo as RNTodo } from '@rocket/types'
import { BottomSheet } from '@rui/molecules'
import { RichTextField, RichTextFieldProvider, TriggerType } from '@rui/rich-text-field'
import { useTranslation } from 'react-i18next'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { CardTodoEditFile, useCardTodoEdit } from '../components/CardTodoEdit'
import { TodoItemOptionButton } from './TodoItemOptionButton'
import { useWorkCardTodo } from './providers'

export type Assignee = Omit<RNAssignee, 'id' | 'createdAt' | 'updatedAt'> & {
  id?: string
}

export type Todo = Omit<RNTodo, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy'> & {
  id?: string
  key?: string
  assignees?: Assignee[]
  files?: CardTodoEditFile[]
  isAssigneeCheck: boolean
  isDateCheck: boolean
  isFileCheck: boolean
}
interface Props {
  todo?: Todo
  visible: boolean
  onChangeVisible: (visible: boolean) => void
  channelId: string
  relatedDomain?: string
  relatedDomainId?: string
  fileLoad: globalThis.File
  onFileLoad: () => void
  inputRef: React.RefObject<any>
  triggerList: TriggerType[]
  onAdd?: () => void
}
const TodoInput: FC<Props> = ({
  triggerList,
  visible,
  onChangeVisible,
  channelId,
  relatedDomain,
  relatedDomainId,
  fileLoad,
  inputRef,
  onFileLoad,
  onAdd,
}) => {
  const { t, i18n } = useTranslation()
  const { astro } = useAstro()
  const { currentUser } = useCurrentUser()
  const { newTodo, newTodoDate, resetNewTodo, onChangeNewTodo, setShowModal } = useCardTodoEdit()

  const { add } = useWorkCardTodo()

  const viewRef = React.useRef<View>(null)
  // const inputRef = React.useRef<TextInput>(null);
  const [isFocused, setIsFocused] = React.useState(false)

  const [channelList, setChannelList] = React.useState<ChannelMember[]>([])

  React.useEffect(() => {
    void astro
      .readChannelMemberList(channelId)
      .then((channelList) => setChannelList(channelList.filter((member) => member.joined && member.userId)))
    // setChannelList(channelMemberListDummy as unknown as ChannelMember[])
  }, [])

  useEffect(() => {
    if (visible) {
      inputRef.current?.focus()
    }
  }, [inputRef, visible])

  const insets = useSafeAreaInsets()
  const [keyboardHeight, setKeyboardHeight] = useState(insets.bottom)

  useEffect(() => {
    const keyboardShowListener = Keyboard.addListener('keyboardWillShow', (e) => {
      setKeyboardHeight(e.endCoordinates.height)
    })
    const keyboardHideListener = Keyboard.addListener('keyboardWillHide', () => {
      setKeyboardHeight(insets.bottom)
    })
    return () => {
      keyboardShowListener.remove()
      keyboardHideListener.remove()
    }
  }, [])

  const users = useMemo(() => (newTodo.assignees ? newTodo.assignees.length : 0), [newTodo.assignees])

  const isFileUpload = useMemo(() => !!newTodo.files, [newTodo.files])

  const handlePressUser = useCallback(
    (payload: LayoutPayload) => {
      if (newTodo.isAssigneeCheck) {
        onChangeNewTodo &&
          onChangeNewTodo({
            ...newTodo,
            isAssigneeCheck: false,
          })
      } else {
        onChangeVisible(false)
        setTimeout(() => {
          setShowModal &&
            setShowModal({
              astro,
              type: 'assignee',
              layoutPayload: payload,
              todo: newTodo,
              users: channelList,
              relatedDomain,
              relatedDomainId,
              i18n,
              currentUserId: Number(currentUser?.id ?? 0),
              onClose: () => {
                console.log('onClose')
                setShowModal(undefined)
                setTimeout(() => onChangeVisible(true), 100)
              },
              onSave: (todo) => {
                console.log('onSave', todo)
                onChangeNewTodo && onChangeNewTodo(todo)
                setShowModal(undefined)
                setTimeout(() => onChangeVisible(true), 100)
              },
            })
        }, 100)
      }
    },
    [newTodo, channelList, currentUser],
  )

  const handlePressDate = useCallback(
    (payload: LayoutPayload) => {
      if (newTodo.isDateCheck) {
        onChangeNewTodo &&
          onChangeNewTodo({
            ...newTodo,
            isDateCheck: false,
          })
      } else {
        onChangeVisible(false)
        setTimeout(() => {
          setShowModal &&
            setShowModal({
              astro,
              type: 'date',
              layoutPayload: payload,
              todo: newTodo,
              users: channelList,
              relatedDomain,
              relatedDomainId,
              i18n,
              currentUserId: Number(currentUser?.id ?? 0),
              onClose: () => {
                console.log('onClose')
                setShowModal(undefined)
                setTimeout(() => onChangeVisible(true), 100)
              },
              onSave: (todo) => {
                console.log('onSave', todo)
                onChangeNewTodo && onChangeNewTodo(todo)
                setShowModal(undefined)
                setTimeout(() => onChangeVisible(true), 100)
              },
            })
        }, 100)
      }
    },
    [newTodo, channelList, currentUser],
  )
  const handlePressFile = useCallback(
    (payload: LayoutPayload) => {
      if (newTodo.isFileCheck) {
        onChangeNewTodo &&
          onChangeNewTodo({
            ...newTodo,
            isFileCheck: false,
          })
      } else {
        onChangeVisible(false)
        setTimeout(() => {
          setShowModal &&
            setShowModal({
              astro,
              type: 'file',
              layoutPayload: payload,
              todo: newTodo,
              users: channelList,
              relatedDomain,
              relatedDomainId,
              i18n,
              currentUserId: Number(currentUser?.id ?? 0),
              onClose: () => {
                console.log('onClose')
                setShowModal(undefined)
                setTimeout(() => onChangeVisible(true), 100)
              },
              onSave: (todo) => {
                console.log('onSave', todo)
                onChangeNewTodo && onChangeNewTodo(todo)
                setShowModal(undefined)
                setTimeout(() => onChangeVisible(true), 100)
              },
              fileLoad,
              onFileLoad,
            })
        }, 100)
      }
    },
    [newTodo, channelList, currentUser, fileLoad],
  )

  console.log('textEditorRef-2', inputRef)

  return (
    <Portal hostName="TodoInputPortal">
      <BottomSheet
        dragable
        headerVariant="COMMON"
        visible={visible}
        onClose={() => {
          resetNewTodo && resetNewTodo()
          onChangeVisible(false)
        }}
        buttonChild={
          <View
            style={{
              backgroundColor: COLOR.mono.white,
            }}
          >
            <View style={[styles.flexBox, { marginTop: 24, marginBottom: 12 }]}>
              <TodoItemOptionButton
                icon={'user-o'}
                iconSize={20}
                iconColor={'gray.g500'}
                text={users ? `@${users}` : undefined}
                onPress={() => {
                  if (viewRef && viewRef.current) {
                    viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                      handlePressUser({ x, y, width, height, pageX, pageY })
                    })
                  }
                }}
                isValued={newTodo.isAssigneeCheck}
                stateOff={!newTodo.isAssigneeCheck}
              />
              <TodoItemOptionButton
                icon={'time-o'}
                iconSize={20}
                iconColor={'gray.g500'}
                text={newTodoDate}
                onPress={() => {
                  if (viewRef && viewRef.current) {
                    viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                      handlePressDate({ x, y, width, height, pageX, pageY })
                    })
                  }
                }}
                isValued={newTodo.isDateCheck}
                stateOff={!newTodo.isDateCheck}
              />
              <TodoItemOptionButton
                icon={'cloud-upload-o'}
                iconSize={20}
                iconColor={'gray.g500'}
                onPress={() => {
                  if (viewRef && viewRef.current) {
                    viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                      handlePressFile({ x, y, width, height, pageX, pageY })
                    })
                  }
                }}
                isValued={newTodo.isFileCheck && isFileUpload}
                stateOff={!(newTodo.isFileCheck && isFileUpload)}
              />
            </View>
            <Button
              onPress={() => {
                const {
                  content,
                  assignees,
                  files,
                  periodOption,
                  fileOption,
                  isAssigneeCheck,
                  isDateCheck,
                  isFileCheck,
                } = newTodo
                add(
                  content ?? '',
                  !!assignees,
                  isAssigneeCheck,
                  isDateCheck,
                  isFileCheck,
                  assignees,
                  files,
                  periodOption,
                  fileOption,
                )
                onAdd?.()
                resetNewTodo && resetNewTodo()
                inputRef.current?.clear()
                onChangeVisible(false)
              }}
            >
              <FAIcon iconName={faPlus} size={'xs'} color={'mono.white'} />
            </Button>
          </View>
        }
      >
        <RichTextFieldProvider ref={inputRef}>
          <View ref={viewRef} style={{ margin: 20, marginTop: 0 }}>
            <RichTextField
              scrollEnabled={false}
              autoFocus
              placeholder={t('inputtodo.placeholder')}
              value={newTodo.content ?? ''}
              onChangeValue={(content: string) => {
                onChangeNewTodo && onChangeNewTodo({ ...newTodo, content })
              }}
              triggerList={triggerList}
            />
          </View>
        </RichTextFieldProvider>
      </BottomSheet>
    </Portal>
  )
}

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 8,
  },
})

export default TodoInput
