import { faFileArrowDown, faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import { Icon } from '@rocket-atoms/icon'
import { ButtonIcon } from '@rocket-mono/foundations'
import { COLOR, FONT, FileItemType, printFileSize } from '@rocket-mono/libs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import IconCircleButton from '../IconCircleButton'
import { FileSourceLabel } from './FileSourceLabel'
import { FileThumbnail } from './IconFileThumbnailPreview'

const FileItem = ({
  small,
  access,
  border,
  download,
  fileUrl,
  fileType,
  fileName,
  fileSize,
  fileSource,
  date,
  errorMessage,
  uploader,
  onPressItem,
  onPressEtc,
  onPressDownload,
  onPressFileOpen,
  isExists,
}: // progress,
FileItemType) => {
  const { t } = useTranslation()
  const viewRef = React.useRef<View>(null)
  const ThumbnailSize = small ? 40 : 52

  const [exists, setExists] = useState(false)

  useEffect(() => {
    console.log('isExists', isExists)
    if (isExists !== undefined) {
      isExists().then(setExists)
    }
  }, [isExists])

  const isDownload = React.useMemo(() => {
    if (typeof download === 'undefined') {
      return true
    } else {
      return download
    }
  }, [download])

  const previewDate = React.useMemo(() => {
    if (typeof date === 'string') {
      return date
    } else {
      return t('format.date.L', { date })
    }
  }, [date])

  return (
    <>
      <View
        style={[styles.container, border && styles.borderContainer, !access && border && styles.boarderContainerV2]}
        ref={viewRef}
      >
        {!access ? (
          <FileThumbnail.UNKNOWN width={ThumbnailSize} height={ThumbnailSize} />
        ) : (
          <Pressable onPress={onPressItem}>
            <View style={{ width: ThumbnailSize, height: ThumbnailSize }}>
              {['doc', 'docx', 'txt'].includes(fileType) && (
                <FileThumbnail.DOC width={ThumbnailSize} height={ThumbnailSize} />
              )}
              {['zip', 'gz'].includes(fileType) && <FileThumbnail.ZIP width={ThumbnailSize} height={ThumbnailSize} />}
              {['xls', 'xlsx', 'csv'].includes(fileType) && (
                <FileThumbnail.XLS width={ThumbnailSize} height={ThumbnailSize} />
              )}
              {['jpg', 'png', 'jpeg'].includes(fileType) && (
                <FileThumbnail.JPG width={ThumbnailSize} height={ThumbnailSize} />
              )}
              {['ppt', 'pptx'].includes(fileType) && <FileThumbnail.PPT width={ThumbnailSize} height={ThumbnailSize} />}
              {fileType === 'pdf' && <FileThumbnail.PDF width={ThumbnailSize} height={ThumbnailSize} />}
              {fileType === 'video' && <FileThumbnail.MP4 width={ThumbnailSize} height={ThumbnailSize} />}

              {/* 미리보기 가능한 경우  */}
              {fileType === 'preview' && (
                <FileThumbnail.PREVIEW fileUrl={fileUrl} width={ThumbnailSize} height={ThumbnailSize} />
              )}

              {fileType === 'errorDanger' && <FileThumbnail.ERRORDANGER width={ThumbnailSize} height={ThumbnailSize} />}
              {fileType === 'errorCaution' && (
                <FileThumbnail.ERRORCAUTION width={ThumbnailSize} height={ThumbnailSize} />
              )}

              {!errorMessage && access && <FileSourceLabel type={small && 'small'} label={fileSource} />}
            </View>
          </Pressable>
        )}
        <View style={styles.infoArea}>
          <Pressable onPress={onPressItem} style={styles.detailInfo}>
            <View style={{ flexDirection: 'row' }}>
              <Text
                style={[styles.fileTitle, !access && styles.fileTitleNonAccess, small && styles.fileTitleSmall]}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {access ? fileName : t('fileitem.notpermissionfile')}
              </Text>
            </View>

            {/* 에러메세지가 있다면 에러메세지 보여주기 */}
            {access && errorMessage ? (
              <Text
                style={[styles.fileSubTitle, styles.fileSubTitleV2, small && { ...FONT.txt2Xs }]}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {errorMessage}
              </Text>
            ) : (
              !small &&
              access && (
                <Text style={styles.fileSubTitle} numberOfLines={1} ellipsizeMode="tail">
                  @{uploader}
                </Text>
              )
            )}

            <View style={styles.meta}>
              {errorMessage || small ? (
                access && (
                  <View style={{ marginRight: 4 }}>
                    <Text style={styles.metaText} numberOfLines={1} ellipsizeMode="tail">
                      {uploader}
                    </Text>
                  </View>
                )
              ) : (
                <></>
              )}
              <Text style={styles.metaText}>{printFileSize(fileSize)}</Text>
              <View style={styles.divLine} />
              <Text style={styles.metaText}>{previewDate}</Text>
            </View>
          </Pressable>
          <View style={[styles.rightArea, small && styles.rightAreaSmall]}>
            <View style={[small ? { top: -6, right: -4 } : { top: -4, right: -4 }]}>
              {onPressEtc ? (
                <ButtonIcon
                  icon={<Icon name="ellipsis-h" size={12} />}
                  onPress={() => {
                    if (viewRef && viewRef.current) {
                      viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                        onPressEtc({ x, y, width, height, pageX, pageY })
                      })
                    }
                  }}
                  style={{ width: 28, height: 28 }}
                />
              ) : (
                <View style={{ width: 28, height: 28 }} />
              )}
            </View>
            <View style={!small && { height: 30 }}>
              {/*
            임의:
            progress가 없다면 다운로드 버튼,
            progress가 100보다 작으면 다운로드 진행,
            progress가 100이면 폴더열기 버튼
            */}
              {/* {access &&
              (errorMeexssage ? (
                <View style={!small && { marginTop: -6 }}>
                  <SupportButton
                    isButton
                    iconName={'rotate-right'}
                    text={'재시도'}
                    onPress={onPressEtc}
                  />
                </View>
              ) : progress === 100 ? (
                <IconCircleButton
                  border
                  iconName="folder-open"
                  onPress={() => onPressDownload && onPressDownload()}
                />
              ) : progress ? (
                progress < 100 && (
                  <View style={!small && { marginTop: -6 }}>
                    <SupportButton
                      iconName={'spinner-5'}
                      text={`${progress}%`}
                    />
                  </View>
                )
              ) : (
                <IconCircleButton
                  iconName="download"
                  onPress={() => onPressDownload && onPressDownload()}
                />
              ))} */}

              {access && isDownload ? (
                exists ? (
                  <IconCircleButton fontawesomeIcon={faFolderOpen} onPress={onPressFileOpen} />
                ) : (
                  <IconCircleButton border fontawesomeIcon={faFileArrowDown} onPress={onPressDownload} />
                )
              ) : (
                <></>
              )}
            </View>
          </View>
        </View>
      </View>
    </>
  )
}

export default FileItem

const styles = StyleSheet.create({
  divLine: {
    width: 1,
    height: 8,
    backgroundColor: COLOR.gray.g150,
    marginHorizontal: 4,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  borderContainer: {
    borderWidth: 1,
    borderColor: COLOR.gray.g100,
    borderRadius: 8,
    padding: 8,
  },
  boarderContainerV2: {
    backgroundColor: COLOR.gray.g050,
    borderWidth: 0,
  },
  rightArea: {
    alignItems: 'flex-end',
    paddingRight: 4,
  },
  rightAreaSmall: {
    flexDirection: 'row-reverse',
    alignItems: 'flex-start',
  },
  infoArea: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginLeft: 8,
  },
  detailInfo: {
    flex: 1,
  },
  fileTitle: {
    ...FONT.txtSmMedium,
  },
  fileTitleNonAccess: {
    color: COLOR.gray.g500,
    ...FONT.txtSm,
  },
  fileTitleSmall: {
    ...FONT.txtXs,
  },
  fileSubTitle: {
    ...FONT.txtXs,
    color: COLOR.gray.g600,
  },
  fileSubTitleV2: {
    color: COLOR.gray.g800,
  },
  meta: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 1,
  },
  metaText: {
    ...FONT.txt2Xs,
    color: COLOR.gray.g450,
  },
})
