import type { IColors } from '@rocket-mono/libs'
import { removeItemAtIndex, replaceItemAtIndex } from '@rocket-mono/libs'
import * as React from 'react'

import { useForm } from '@rocket-mono/libs'

import type { RangeDateType } from '@rocket-molecules/date'
import { ProjectElement } from '@rocket/types'
import Context from './context'
import type { ElementType, FormType, FormValidationProps, ProviderProps } from './types'

const Provider = ({ isArchive, initialValues, shopData, astro, setModalBottomAlert, children }: ProviderProps) => {
  const [deleteInfoVisible, setDeleteInfoVisible] = React.useState(false)
  const { values, isChangeValue, handleChange, handleSubmit } = useForm<FormType, FormValidationProps>({
    initialValues,
    onSubmit: (values: FormType) => {
      return new Promise((resolve, reject) => {
        console.log('WorkModelProvider:submit', values)
        if (values.id !== undefined) {
          const { id: projectId, title, goal, typeCode, rangeDate, etc } = values
          const elementList: ElementType[] = []
          if (values.startElement) elementList.push(values.startElement)
          elementList.push(...values.projectElements)
          if (values.endElement) elementList.push(values.endElement)
          astro
            .updateProject({ id: projectId, title, goal, typeCode, etc })
            .then(() => {
              const startDate = rangeDate.startDate || null
              const dueDate = rangeDate.endDate || null
              return astro
                .readProjectPeriod(projectId)
                .then(({ id, completedAt }) => {
                  astro.updateProjectPeriod(projectId, {
                    id,
                    startDate,
                    dueDate,
                    completedAt,
                  })
                })
                .catch(() => {
                  astro.createProjectPeriod(projectId, { startDate, dueDate })
                })
            })
            .then(() =>
              astro
                .readProjectElements(projectId)
                .then((list) =>
                  list.filter(
                    ({ id }) =>
                      !elementList
                        .filter((o) => o.id !== undefined)
                        .map(({ id }) => id)
                        .includes(id),
                  ),
                )
                .then((deleteElements) => {
                  console.log('WorkModelProvider:deleteElements', deleteElements)

                  // astro.readChannelList({ type: 'G', projectId }).then((channelList) =>
                  //   Promise.all(
                  //     deleteElements.filter((element) => {
                  //       const channel = channelList.find((o) => o.projectElementId === element.id && o.closed === false)
                  //       if (channel) astro.deleteChannel(channel.id)
                  //       return astro.deleteProjectElement(projectId, element.id)
                  //     }),
                  //   ),
                  // )
                }),
            )
            .then(() => {
              const projectElements: ProjectElement[] = elementList.map(
                ({ id, isMovable, labelObject, name }, order) => {
                  const maxOrder = elementList.length
                  return {
                    id: id || '',
                    projectId,
                    isMovable,
                    labelObject,
                    name,
                    order,
                    maxOrder,
                  }
                },
              )
              return Promise.all([
                astro.updateProjectElements(
                  projectId,
                  projectElements.filter((o) => o.id),
                ),
                astro.createProjectElements(
                  projectId,
                  projectElements.filter((o) => o.id === ''),
                ),
              ])
            })
            .then(() => resolve(projectId))
            .catch((err) => reject(err))
        } else {
          reject()
        }
      })
    },
    validate: ({ title }: FormType) => {
      console.log('validate', { title })
      const errors: FormValidationProps = {}
      return errors
    },
  })

  const updateTitle = React.useCallback(
    (title: string) => {
      handleChange('title', title)
    },
    [handleChange],
  )

  const updateRangeDate = React.useCallback(
    (rangeDate: RangeDateType) => {
      handleChange('rangeDate', rangeDate)
    },
    [handleChange],
  )

  const createElement = React.useCallback(
    (element: ElementType) => {
      handleChange('projectElements', [...values.projectElements, element])
    },
    [values, handleChange],
  )

  const updateElements = React.useCallback(
    (elements: ElementType[]) => {
      handleChange('projectElements', elements)
    },
    [handleChange],
  )

  const updateElement = React.useCallback(
    (idx: number, item: { text?: string; labelColor?: IColors; focused?: boolean }) => {
      const { projectElements } = values
      const name = item.text || ''
      const labelObject = item.labelColor ? String(item.labelColor) : null
      const focused = item.focused
      const newItem = { ...projectElements[idx], name, labelObject, focused }
      updateElements(replaceItemAtIndex(projectElements, idx, newItem))
    },
    [values],
  )

  const deleteElement = React.useCallback(
    (index: number) => {
      handleChange('projectElements', removeItemAtIndex(values.projectElements, index))
    },
    [values, handleChange],
  )

  const updateStartElement = React.useCallback(
    (element: ElementType) => {
      handleChange('startElement', element)
    },
    [handleChange],
  )

  const updateEndElement = React.useCallback(
    (element: ElementType) => {
      handleChange('endElement', element)
    },
    [handleChange],
  )

  return (
    <Context.Provider
      value={{
        isArchive,
        isChangeValue,
        data: values,
        shopData,
        handleSubmit,
        updateTitle,
        updateRangeDate,
        createElement,
        updateElement,
        updateElements,
        deleteElement,
        updateStartElement,
        updateEndElement,
        deleteInfoVisible,
        setDeleteInfoVisible,
        setModalBottomAlert,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
