import { COLOR } from '@rocket-mono/libs'
import { Text } from '@rocket/atoms'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { useRule } from '../../hooks'
import List from './list'
import { ListViewType, RuleListItemType } from './types'

interface Props {
  type: ListViewType
  list: RuleListItemType[]
}

const Group: React.FC<Props> = ({ type, list }) => {
  const { t } = useTranslation()
  const { isMobile } = useRule()
  const containerStyle = React.useMemo(() => {
    const side: StyleProp<ViewStyle> = [styles.container]
    if (type === 'DO') {
      side.push({
        borderLeftWidth: 4,
        borderLeftColor: COLOR.main.yellow,
      })
    } else if (type === 'DOING') {
      side.push({
        borderLeftWidth: 4,
        borderLeftColor: COLOR.main.turquoise,
      })
    } else if (type === 'DONE') {
      side.push({
        borderLeftWidth: 4,
        borderLeftColor: COLOR.main.blue,
      })
    }
    return side
  }, [type])
  return (
    <View style={containerStyle}>
      {isMobile ? (
        <></>
      ) : (
        <View style={styles.header}>
          <View style={styles.targetContainer}>
            <Text fontName="txtXs" fontColor="gray.g700">
              {t('rule.modal.target')}
            </Text>
          </View>
          <View style={styles.flowContainer}>
            <Text fontName="txtXs" fontColor="gray.g700">
              {t('rule.modal.flow')}
            </Text>
          </View>
          <View style={styles.stateContainer}>
            <Text fontName="txtXs" fontColor="gray.g700">
              {t('workmember.table.header.status')}
            </Text>
          </View>
        </View>
      )}
      <List list={list} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    borderRadius: 4,
    marginBottom: 8,
    marginHorizontal: 20,
  },
  header: {
    flexDirection: 'row',
    // height: 36,
    borderBottomColor: COLOR.gray.g150,
    borderBottomWidth: 1,
  },
  targetContainer: {
    width: 100,
    paddingTop: 12,
    paddingLeft: 15,
    paddingBottom: 8,
  },
  flowContainer: {
    flex: 1,
    paddingTop: 12,
    paddingLeft: 15,
    paddingBottom: 8,
  },
  stateContainer: {
    width: 120,
    paddingTop: 12,
    paddingLeft: 15,
    paddingBottom: 8,
  },
})

export default Group
