import React, { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'

import { Breadcrumbs, Text, SkeletonBar, SkeletonCircle, SkeletonSquare } from '@rui/atoms'
import { BoardLabel, WorkIcon } from '@rui/icons'

import { faClock } from '@fortawesome/free-solid-svg-icons'
import { CardIcon, FAIcon } from '@rocket-atoms/icon'
import { COLOR, FONT } from '@rui/foundations'
import OptionCountLabel from '../../OptionCountLabel'

import { Checkbox } from '@rocket-atoms/checkbox'
import Container from '../container'
import { ItemBoardType, ItemCardType, ItemTodoType, ItemWorkType } from '../types'
export interface ActivitySearchItemCardProps {
  work?: ItemWorkType
  board?: ItemBoardType
  card?: ItemCardType
  todo?: ItemTodoType
  onPress?: () => void
}

const ActivitySearchItemCard: React.FC<ActivitySearchItemCardProps> = ({ work, board, card, todo, onPress }) => {
  const breadcrumsList = useMemo(() => {
    const list: any[] = []

    if (work !== undefined && !!work.name && !!work.type) {
      list.push({
        text: work.name,
        children: <WorkIcon type={work.type} size={16} />,
      })
    }

    if (board !== undefined && !!board.name) {
      list.push({
        text: board.name,
        children: <BoardLabel color={board.color ?? undefined} size={16} />,
      })
    }

    return list
  }, [work, board])

  if (card === undefined && todo === undefined) {
    return (
      <View style={styles.container}>
        <Breadcrumbs loading={true} list={breadcrumsList} />
        <View style={[styles.flexBox, { marginTop: 8 }]}>
          <View style={{ marginRight: 4 }}>
            <SkeletonCircle width={20} height={20} />
          </View>
          <SkeletonSquare width={'60%'} height={16} color={'gray.g200'} />
        </View>
        <View style={[styles.optionalArea, styles.flexBox]}>
          <SkeletonBar width={'75%'} height={8} />
          <SkeletonBar width={26} height={16} />
        </View>
      </View>
    )
  }

  return (
    <Container onPress={onPress} style={styles.container} hoverStyle={{ borderColor: COLOR.gray.g200 }}>
      {breadcrumsList.length > 0 && <Breadcrumbs list={breadcrumsList} />}
      <View>
        <View style={styles.titleWrap}>
          {card && (
            <>
              <CardIcon variant="CIRCLE" type={card.type} size={'sm'} />
              <Text style={styles.titleArea}>{card.title}</Text>
            </>
          )}
          {todo && (
            <>
              <Checkbox checked={todo.checked} type={'TODO'} />
              <Text style={styles.titleArea}>{todo.title}</Text>
            </>
          )}
        </View>
        <Text style={styles.cardTextArea} numberOfLines={1} ellipsizeMode="tail">
          {card && card.content}
        </Text>
      </View>
      <View style={[styles.optionalArea, styles.flexBox]}>
        {card && card.data && (
          <View style={styles.flexBox}>
            <FAIcon iconName={faClock} size={'2xs'} color={'gray.g600'} />
            <Text style={styles.dataArea}>{card.data}</Text>
          </View>
        )}
        {card && card.assigneeCount > 0 && <OptionCountLabel count={card.assigneeCount} />}
      </View>
    </Container>
  )
}

export default ActivitySearchItemCard

export const styles = StyleSheet.create({
  container: {
    position: 'relative',
    flexDirection: 'column',
    paddingTop: 12,
    paddingBottom: 8,
    paddingHorizontal: 12,
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderColor: COLOR.gray.g100,
    borderRadius: 8,

    marginBottom: 8,
    marginHorizontal: 20,
  },
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
  },
  titleArea: {
    ...FONT.txtSmMedium,
    marginLeft: 4,
  },
  cardTextArea: {
    flex: 1,
    ...FONT.txtXs,
    color: COLOR.gray.g500,
    marginTop: 4,
  },
  optionalArea: {
    justifyContent: 'space-between',
    marginTop: 4,
  },
  dataArea: {
    ...FONT.txt2XsMedium,
    color: COLOR.gray.g500,
    marginLeft: 4,
  },
})
