import React, { useState } from 'react'
import { Pressable, StyleSheet } from 'react-native'

import { FAIcon } from '@rocket-atoms/icon'

const IconPress = ({ icon, color, onPress }) => {
  const [isHover, setIsHover] = useState(false)

  return (
    <Pressable
      style={[styles.icon, isHover && { opacity: 0.8 }]}
      onPress={onPress}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
    >
      <FAIcon iconName={icon} color={color} />
    </Pressable>
  )
}

export default IconPress

const styles = StyleSheet.create({
  icon: {
    width: 24,
    height: 24,
    marginLeft: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
