import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { COLOR, FONT } from '@rui/foundations'
import { StyleSheet, View, Text, Pressable, Platform, DimensionValue } from 'react-native'
import { useMobileView } from '@rui/foundations'

interface Props {
  width: DimensionValue
  onPress: () => void
}

const SearchInput = ({ width, onPress }: Props) => {
  const { t } = useTranslation()
  const mobileView = useMobileView(450)
  const [isHover, setIsHover] = React.useState(false)

  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === 'Slash') onPress()
    }
    if (Platform.OS === 'web') window.addEventListener('keydown', handleKeyDown)

    return () => {
      if (Platform.OS === 'web') window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <Pressable
      onPress={onPress}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      style={[styles.container, { width }]}
    >
      <View style={[styles.input, isHover && { borderColor: COLOR.darkmode.warm400 }]}>
        <Text style={styles.placeholderText}>{t('workspace.search')}</Text>
      </View>
      {!mobileView && (
        <View style={styles.hotkeyWrap}>
          <View style={styles.hotkey}>
            <Text style={styles.key}>/</Text>
          </View>
        </View>
      )}
    </Pressable>
  )
}

export default SearchInput

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  input: {
    padding: 8,
    paddingRight: 36,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: COLOR.darkmode.warm500,
    backgroundColor: COLOR.darkmode.cool900,
  },
  placeholderText: {
    ...FONT.txtSm,
    color: COLOR.gray.g400,
  },
  hotkeyWrap: {
    position: 'absolute',
    justifyContent: 'center',
    right: 8,
    height: 36,
  },
  hotkey: {
    justifyContent: 'center',
    width: 20,
    height: 20,
    borderWidth: 1,
    borderColor: COLOR.darkmode.warm400,
    borderRadius: 4,
  },
  key: {
    ...FONT.txtXs,
    color: COLOR.gray.g450,
    textAlign: 'center',
  },
})
