import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutChangeEvent, View } from 'react-native'
import { CardRowGroup } from './CardRowGroup'
import WorkCardCalendar from './WorkCardCalendar'

export type CardDateType = {
  startDate?: Date
  endDate?: Date
  isStartTime?: boolean
  isEndTime?: boolean
}

interface Props {
  date: CardDateType
}

const WorkCardCalendarView: React.FC<Props> = ({ date }) => {
  const { t } = useTranslation()
  const [layoutWidth, setLayoutWidth] = useState<number>(0)
  const handleLayout = useCallback((event: LayoutChangeEvent) => {
    setLayoutWidth(event.nativeEvent.layout.width)
  }, [])

  return (
    <View>
      <CardRowGroup
        onLayout={handleLayout}
        layoutWidth={layoutWidth}
        title={t('discussionheader.meeting')}
        iconName="time-o"
        iconContainerStyle={{
          alignItems: 'stretch',
          flexDirection: 'row',
          marginTop: 8,
        }}
        marginTop={12}
      />
      <WorkCardCalendar
        startDate={date.startDate}
        endDate={date.endDate}
        isStartTime={date.isStartTime}
        isEndTime={date.isEndTime}
      />
    </View>
  )
}

export default WorkCardCalendarView
