import * as React from 'react'

import { COLOR } from '@rocket-mono/libs'
import { StyleSheet, View } from 'react-native'
import { ClipPath, Defs, G, Path, Rect, Svg } from 'react-native-svg'

interface Props {
  type: 'GOOGLE' | 'NAVER' | 'KAKAO' | 'FACEBOOK' | 'APPLE'
  shape: 'ROUND' | 'ROUNDSQUARE'
  iconSize?: number
  shapeIconSize?: number
  notUsed?: boolean
}

const IconSns: React.FC<Props> = ({ type, shape, iconSize = 22, shapeIconSize = 46, notUsed }: Props) => {
  if (type === 'GOOGLE') {
    return (
      <View
        style={[
          shape === 'ROUND' && styles.shapeRound,
          shape === 'ROUNDSQUARE' && styles.shapeRoundSquare,
          { width: shapeIconSize, height: shapeIconSize },
          { backgroundColor: COLOR.mono.white, borderWidth: 1, borderColor: COLOR.gray.g150 },
          notUsed && styles.notUsedStyle,
        ]}
      >
        <Svg width={iconSize} height={iconSize} viewBox="0 0 22 22" fill="none">
          <G clipPath="url(#clip0_6313_10486)">
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22 11.2497C22 10.4694 21.9281 9.72021 21.7959 9H11.2247V13.2549H17.2659C17.0057 14.6301 16.2148 15.7948 15.0262 16.5751V19.3347H18.6543C20.7766 17.4197 22.0011 14.6 22.0011 11.2497H22Z"
              fill={notUsed ? '#ffffff' : '#3E82F1'}
            />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.2247 21.9999C14.2553 21.9999 16.7963 21.0144 18.6532 19.3346L15.0251 16.5751C14.0195 17.2352 12.7347 17.6248 11.2236 17.6248C8.29983 17.6248 5.82543 15.6901 4.94247 13.0901H1.19385V15.9398C3.04119 19.5346 6.83634 21.9999 11.2247 21.9999Z"
              fill={notUsed ? '#ffffff' : '#32A753'}
            />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.94352 13.0901C4.71935 12.43 4.5914 11.7253 4.5914 11C4.5914 10.2746 4.71935 9.5699 4.94352 8.9098V6.06006H1.19385C0.43355 7.54503 0 9.22483 0 11C0 12.7751 0.43355 14.4549 1.19385 15.9399L4.94352 13.0901Z"
              fill={notUsed ? '#ffffff' : '#F9BB00'}
            />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.2247 4.37513C12.8722 4.37513 14.3526 4.93057 15.5158 6.01969L18.7357 2.86425C16.7911 1.09016 14.25 0 11.2247 0C6.83634 0 3.04119 2.46529 1.19385 6.0601L4.94352 8.90985C5.82648 6.30985 8.30089 4.37513 11.2247 4.37513Z"
              fill={notUsed ? '#ffffff' : '#E74133'}
            />
          </G>
          <Defs>
            <ClipPath id="clip0_6313_10486">
              <Rect width={22} height={22} fill="white" />
            </ClipPath>
          </Defs>
        </Svg>
      </View>
    )
  }
  if (type === 'NAVER') {
    return (
      <View
        style={[
          shape === 'ROUND' && styles.shapeRound,
          shape === 'ROUNDSQUARE' && styles.shapeRoundSquare,
          { width: shapeIconSize, height: shapeIconSize },
          { backgroundColor: '#2DB404' },
          notUsed && styles.notUsedStyle,
        ]}
      >
        <Svg width={iconSize} height={iconSize} viewBox="0 0 22 23" fill="none">
          <Path
            d="M13.8594 3.76562V12.0859L8.16406 3.76562H2V20.2422H8.14062V11.9219L13.8359 20.2422H20V3.76562H13.8594Z"
            fill="white"
          />
        </Svg>
      </View>
    )
  }
  if (type === 'KAKAO') {
    return (
      <View
        style={[
          shape === 'ROUND' && styles.shapeRound,
          shape === 'ROUNDSQUARE' && styles.shapeRoundSquare,
          { width: shapeIconSize, height: shapeIconSize },
          { backgroundColor: '#F6E44C' },
          notUsed && styles.notUsedStyle,
        ]}
      >
        <Svg width={iconSize} height={iconSize} viewBox="0 0 22 20" fill="none">
          <Path
            d="M11 0.992188C9.625 0.992188 8.32812 1.20312 7.10938 1.625C5.89062 2.04688 4.82812 2.61719 3.92188 3.33594C3.01562 4.05469 2.29688 4.89844 1.76562 5.86719C1.25 6.83594 0.992188 7.86719 0.992188 8.96094C0.992188 9.66406 1.10156 10.3438 1.32031 11C1.53906 11.6406 1.84375 12.25 2.23438 12.8281C2.64062 13.3906 3.125 13.9141 3.6875 14.3984C4.25 14.8672 4.86719 15.2812 5.53906 15.6406L4.78906 19.5547L9.125 16.7891C9.42188 16.8359 9.72656 16.875 10.0391 16.9062C10.3516 16.9219 10.6719 16.9297 11 16.9297C12.375 16.9297 13.6719 16.7266 14.8906 16.3203C16.1094 15.8984 17.1719 15.3281 18.0781 14.6094C18.9844 13.875 19.6953 13.0312 20.2109 12.0781C20.7422 11.1094 21.0078 10.0703 21.0078 8.96094C21.0078 7.86719 20.7422 6.83594 20.2109 5.86719C19.6953 4.89844 18.9844 4.05469 18.0781 3.33594C17.1719 2.61719 16.1094 2.04688 14.8906 1.625C13.6719 1.20312 12.375 0.992188 11 0.992188Z"
            fill={notUsed ? '#ffffff' : '#402021'}
          />
        </Svg>
      </View>
    )
  }
  if (type === 'FACEBOOK') {
    return (
      <View
        style={[
          shape === 'ROUND' && styles.shapeRound,
          shape === 'ROUNDSQUARE' && styles.shapeRoundSquare,
          { width: shapeIconSize, height: shapeIconSize },
          { backgroundColor: '#3067A8' },
          notUsed && styles.notUsedStyle,
        ]}
      >
        <Svg width={iconSize} height={iconSize} viewBox="0 0 23 23" fill="none">
          <Path
            d="M6.79688 8.32031H9.89062V5.46094C9.89062 5.46094 9.92188 5.09375 9.98438 4.35938C10.0625 3.625 10.4531 2.90625 11.1562 2.20312C11.875 1.48438 12.7188 1.10156 13.6875 1.05469C14.6719 0.992188 15.8438 1.02344 17.2031 1.14844V4.33594H15.1875C15.1875 4.33594 15.0312 4.35156 14.7188 4.38281C14.4062 4.41406 14.1484 4.53906 13.9453 4.75781C13.7578 4.99219 13.6484 5.24219 13.6172 5.50781C13.6016 5.77344 13.5938 5.94531 13.5938 6.02344V8.32031H17.0859L16.6406 11.8594H13.5703V21H9.89062V11.8594H6.79688V8.32031Z"
            fill="white"
          />
        </Svg>
      </View>
    )
  }
  if (type === 'APPLE') {
    return (
      <View
        style={[
          shape === 'ROUND' && styles.shapeRound,
          shape === 'ROUNDSQUARE' && styles.shapeRoundSquare,
          { width: shapeIconSize, height: shapeIconSize },
          { backgroundColor: '#000000' },
          notUsed && styles.notUsedStyle,
        ]}
      >
        <Svg width={iconSize} height={iconSize} viewBox="0 0 23 23" fill="none">
          <Path
            d="M18.8281 17.4844C18.4219 18.1094 17.9766 18.6797 17.4922 19.1953C17.0234 19.6953 16.4531 19.9531 15.7812 19.9688C15.1094 19.9844 14.5781 19.8594 14.1875 19.5938C13.8125 19.3125 13.25 19.1719 12.5 19.1719C11.7344 19.1719 11.1562 19.3047 10.7656 19.5703C10.375 19.8203 9.85938 19.9609 9.21875 19.9922C8.5625 20.0234 7.97656 19.7656 7.46094 19.2188C6.96094 18.6562 6.5 18.0703 6.07812 17.4609C5.65625 16.8359 5.26562 16.1094 4.90625 15.2812C4.5625 14.4375 4.3125 13.5625 4.15625 12.6562C4 11.7344 3.96094 10.8203 4.03906 9.91406C4.11719 8.99219 4.375 8.14844 4.8125 7.38281C5.25 6.61719 5.83594 6.01562 6.57031 5.57812C7.30469 5.125 8.09375 4.89062 8.9375 4.875C9.57812 4.85938 10.1875 5 10.7656 5.29688C11.3438 5.59375 11.8359 5.74219 12.2422 5.74219C12.6328 5.74219 13.1719 5.57031 13.8594 5.22656C14.5469 4.88281 15.2734 4.75 16.0391 4.82812C16.3672 4.84375 16.9062 4.96875 17.6562 5.20312C18.4219 5.42188 19.0938 5.96094 19.6719 6.82031C19.6406 6.85156 19.2734 7.17969 18.5703 7.80469C17.8672 8.41406 17.5234 9.35156 17.5391 10.6172C17.5547 12.1328 18 13.1875 18.875 13.7812C19.75 14.3594 20.1953 14.6562 20.2109 14.6719C20.1953 14.7031 20.0781 15.0078 19.8594 15.5859C19.6562 16.1641 19.3125 16.7969 18.8281 17.4844ZM13.1328 1.5C13.4922 1.07812 13.9453 0.726562 14.4922 0.445312C15.0391 0.164062 15.5625 0.015625 16.0625 0C16.125 0.578125 16.0547 1.14844 15.8516 1.71094C15.6484 2.27344 15.375 2.76562 15.0312 3.1875C14.6875 3.60938 14.25 3.96094 13.7188 4.24219C13.1875 4.52344 12.6406 4.64062 12.0781 4.59375C12 4.03125 12.0703 3.46875 12.2891 2.90625C12.5234 2.34375 12.8047 1.875 13.1328 1.5Z"
            fill="white"
          />
        </Svg>
      </View>
    )
  }
}

export default IconSns

const styles = StyleSheet.create({
  shapeRound: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
  },
  shapeRoundSquare: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
  },
  notUsedStyle: {
    backgroundColor: COLOR.gray.g200,
  },
})
