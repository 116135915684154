import React, { useEffect, useMemo } from 'react'

import { WithdrawalModal, useModal, useToast } from '@rocket/components'
import { useTranslation } from 'react-i18next'
import { useAstro } from '@rocket-mono/providers'
import { useWorkProject } from '@rocket-mono/providers'

export type ModalState = 'OPEN' | 'CLOSE'

interface Props {
  projectId: string | null
  userId: string
  state: ModalState
  onClose: () => void
}

const ModalView: React.FC<Props> = ({ projectId, userId, state, onClose }) => {
  const { t } = useTranslation()
  const { astro } = useAstro()
  const { Modal, visible, close, open } = useModal('WithdrawalModal')
  const { showToastMessage } = useToast()

  const { currentProjectMember, projectMemberList } = useWorkProject()

  useEffect(() => {
    if (state === 'OPEN') {
      open()
    }
  }, [state])
  const members = useMemo(() => {
    if (projectMemberList && currentProjectMember) {
      console.log('projectMember', currentProjectMember)
      const auths =
        currentProjectMember.auth === 'MEMBER' || currentProjectMember.auth === 'GUEST'
          ? ['MANAGER', 'OWNER']
          : ['OWNER']
      return projectMemberList.filter((o) => auths.includes(o.auth))
    } else {
      return []
    }
  }, [projectMemberList, currentProjectMember])
  return (
    <Modal visible={visible}>
      <WithdrawalModal
        memberList={members}
        onClickCloseButton={() => {
          close()
          onClose()
        }}
        onClickRequestButton={async (targetUserId) => {
          try {
            await astro.createProjectWithdrawalSelf({
              projectId: projectId || '',
              requestUserId: userId,
              targetUserId,
              requestDate: new Date(),
              expirationDate: null,
            })
            location.href = '/project'
          } catch (error) {
            if (!(error instanceof Response)) {
              console.error(error)
              showToastMessage({
                type: 'error',
                message: t('toast.error500'),
                viewPosition: 'BOTTOM_CENTER',
              })
              return
            }

            const response = await error.json()
            console.error(response)
            if (error.status === 409) {
              showToastMessage({
                type: 'error',
                message: t('toast.alreadyaskleave'),
                viewPosition: 'BOTTOM_CENTER',
              })
              return
            }
            if (error.status >= 500) {
              showToastMessage({
                type: 'error',
                message: t('toast.error500'),
                viewPosition: 'BOTTOM_CENTER',
              })

              return
            }
            showToastMessage({
              type: 'error',
              message: response.message,
              viewPosition: 'BOTTOM_CENTER',
            })
          }
          close()
          onClose()
        }}
      />
    </Modal>
  )
}

export default ModalView
