import React, { useCallback, useState } from 'react'
import {
  View,
  Text,
  LayoutChangeEvent,
  StyleProp,
  ViewStyle,
  TextStyle,
  StyleSheet,
  Modal,
  GestureResponderEvent,
} from 'react-native'
import { COLOR, FONT } from '@rocket-mono/libs'
import TargetDetector from './TargetDetector'
import Dimmed from './Dimmed'
import useDimenstions from './useDimensions'

import type { DirectionType } from './types'
import CopiedTarget from './CopiedTarget'

interface Props {
  text?: string
  gab?: number
  darkType?: boolean
  direction?: DirectionType
  containerStyle?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  onHoverTarget?: () => void
  onBlurTarget?: () => void
  onPressTarget?: null | ((e: GestureResponderEvent) => void)
  children?: ({
    onLayout,
    ref,
  }: {
    onLayout: (e: LayoutChangeEvent) => void
    ref: React.RefObject<any>
  }) => React.ReactNode
  render?: () => JSX.Element
}

const Tooltip: React.FC<Props> = ({
  text = '',
  gab = 10,
  darkType,
  direction = 'bottom-center',
  onHoverTarget,
  onBlurTarget,
  onPressTarget,
  containerStyle,
  textStyle,
  children,
  render,
}): JSX.Element | null => {
  const [visible, setVisible] = useState(false)
  const onShow = useCallback(() => {
    setVisible(true)
  }, [])
  const onDismiss = useCallback(() => {
    setVisible(false)
    onBlurTarget && onBlurTarget()
  }, [onBlurTarget])

  const { onLayout, ref, dimensions, onChangeTooltipLayout, getTooltipPosition } = useDimenstions({ direction, gab })

  const handlePress = useCallback(
    (e: GestureResponderEvent) => {
      setVisible(false)
      onPressTarget && onPressTarget(e)
    },
    [onPressTarget],
  )

  if (!children) {
    return null
  }

  if (!text) {
    return children({ onLayout, ref }) as JSX.Element
  }

  return (
    <>
      <Modal visible={visible} transparent onRequestClose={onDismiss}>
        <Dimmed onDismiss={onDismiss} />
        <CopiedTarget dimensions={dimensions} onHover={onHoverTarget} onPress={handlePress} />
        <View
          onLayout={onChangeTooltipLayout}
          style={[
            styles.container,
            !!render && {
              borderWidth: 1,
              borderColor: COLOR.gray.g200,
              padding: 0,
            },
            darkType ? styles.tooltipDarkContainer : styles.tooltipContainer,
            containerStyle,
            getTooltipPosition(),
          ]}
        >
          <>
            {render && render()}
            <Text style={[darkType ? styles.darkText : styles.text, textStyle, !!render && { padding: 8 }]}>
              {text}
            </Text>
          </>
        </View>
      </Modal>
      <TargetDetector onPress={handlePress} onShow={onShow} onLayout={onLayout}>
        {children && children({ ref, onLayout })}
      </TargetDetector>
    </>
  )
}

export default Tooltip

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    shadowOpacity: 0.16,
    shadowRadius: 6,
    elevation: 4,
    padding: 5,
    minHeight: 23,
    borderRadius: 4,
    shadowColor: '#000',
    shadowOffset: { width: 1, height: 2 },
  },
  tooltipContainer: {
    backgroundColor: COLOR.mono.white,
  },
  tooltipDarkContainer: {
    backgroundColor: 'rgba(0,0,0, .9)',
  },
  text: {
    ...FONT.textRegular,
    color: COLOR.mono.black,
  },
  darkText: {
    ...FONT.txt2Xs,
    color: COLOR.mono.white,
  },
})
