import { COLOR, FileItemType } from '@rocket-mono/libs'
import * as React from 'react'
import { FlatList, StyleSheet, View } from 'react-native'
import FileItem from '../FileItem'
import { FileField } from '@rui/molecules'

interface Props {
  list: FileItemType[]
  isBorder?: boolean
  backgroundColor?: string
  progress?: number
  isMultiple?: boolean
  onPress?: () => void
  onLoadFile?: (fileList: FileList) => void
  onDropFile?: (fileList: FileList) => void
  onPasteFile?: (fileList: FileList) => void
}

const Item = ({ item }: { item: FileItemType }) => {
  return (
    <FileItem
      small={item.small}
      access={item.access}
      download={item.download}
      border={item.border}
      fileUrl={item.fileUrl}
      fileType={item.fileType}
      filePath={item.filePath}
      fileName={item.fileName}
      fileSize={item.fileSize}
      fileSource={item.fileSource}
      errorMessage={item.errorMessage}
      date={item.date}
      uploader={item.uploader}
      onPressItem={item.onPressItem}
      onPressEtc={item.onPressEtc}
      progress={item.progress}
    />
  )
}

const FileUploader: React.FC<Props> = ({
  list,
  backgroundColor = COLOR.mono.white,
  isBorder = true,
  progress = 0,
  isMultiple,
  onPress,
  onLoadFile,
  onDropFile,
  onPasteFile,
}) => {
  return (
    <View style={[styles.container, { backgroundColor }, isBorder ? styles.containerBorder : null]}>
      <View style={styles.fileUploaderArea}>
        <FileField
          size="SMALL"
          multiple={isMultiple}
          onPress={onPress}
          onLoadFile={onLoadFile}
          onDropFile={onDropFile}
          onPasteFile={onPasteFile}
          progress={progress}
        />
      </View>
      <View style={styles.bodyContainer}>
        <FlatList
          data={list}
          keyExtractor={(_, index) => `fileItem-${index}`}
          renderItem={(item) => (
            <View style={styles.itemWrap}>
              <Item item={item.item} />
            </View>
          )}
        />
      </View>
    </View>
  )
}

export default FileUploader

const styles = StyleSheet.create({
  container: {
    padding: 12,
    flex: 1,
  },
  containerBorder: {
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    borderRadius: 4,
  },
  itemWrap: {
    paddingTop: 8,
  },
  fileUploaderArea: {
    minHeight: 60,
  },
  bodyContainer: {
    flex: 1,
  },
})
