import React from 'react'
import { Platform, Text, TouchableOpacity } from 'react-native'

import { COLOR, FONT } from '../styles'

const MENTION_TEMPLATE = '<m chatRoomMemberNo=(?<chatRoomMemberNo>\\d+|all), memberName="(?<memberName>[^"]+)">'

const CARD_LINK_TEMPLATE =
  '<m cardNo=(?<cardNo>\\d+|all), cardName="(?<cardName>[^"]+)", channelNo=(?<channelNo>\\d+|all)>'

type MessageTextReplacer = (source: string, alias: string, index: number, ref?: React.RefObject<Text>) => JSX.Element

type ParserRule = {
  format: Readonly<string>
  replacer: MessageTextReplacer
}

function parseMessage(source: string, entries: ParserRule[], textAttrs: Record<string, unknown> = {}): JSX.Element[] {
  if (!source)
    return [
      <Text key={`empty-token-${Date.now()}-${source.length}`} {...textAttrs}>
        {''}
      </Text>,
    ]

  const elements: JSX.Element[] = []
  const timestamp = Date.now()

  const matcher = new RegExp(entries.map(({ format }) => format).join('|'), 'g')
  const matches = source.match(matcher)

  if (matches) {
    matches.forEach((match, index) => {
      if (!source) return

      const replacer = entries.find(({ format }) => {
        const matcher = new RegExp(format, 'g')
        const matches = match.match(matcher)
        return matches && matches?.length > 0 ? true : false
      })?.replacer

      const [before, _] = source.split(match)

      const after = source.replace(before + match, '')

      if (before)
        elements.push(
          <Text key={`token-${timestamp}-${source.length}-${index}`} {...textAttrs}>
            {before}
          </Text>,
        )

      replacer && elements.push(replacer(match, `${timestamp}`, index, React.createRef<Text>()))

      source = after

      if (!source || source === '') {
        return
      }
    })
  }

  if (source) {
    elements.push(
      <Text key={`last-token-${timestamp}-${source.length}`} {...textAttrs}>
        {source}
      </Text>,
    )
  }

  return elements
}

export const mentionReplacer: MessageTextReplacer = (source, alias, index) => {
  console.log('mentionReplacer', source, alias, index)
  const { chatRoomMemberNo: memberNo, memberName } = new RegExp(MENTION_TEMPLATE).exec(source)?.groups ?? {
    chatRoomMemberNo: '',
    memberName: '',
  }

  if (memberNo && memberName) {
    return (
      <TouchableOpacity
        key={`mention-${memberNo}-${alias}-${index}-activator`}
        onPress={() => {
          // NOOP
        }}
        style={[
          {
            backgroundColor: 'transparent',
            ...Platform.select({
              native: {
                marginTop: -2.5,
              },
            }),
          },
        ]}
      >
        <Text
          style={{ ...FONT.chatTextBold, color: COLOR.primary.blue500 }}
          key={`mention-${memberNo}-${alias}-${index}`}
        >
          @{memberName}
        </Text>
      </TouchableOpacity>
    )
  } else {
    return <Text key={`token-${alias}-${index}`}>{source}</Text>
  }
}

export const getMessageParse = (bodyText: string) => {
  const replacements: ParserRule[] = [
    {
      format: '<b>(.*?)<\\/b>',
      replacer: (source, timestamp, index) => (
        <Text style={{ ...FONT.chatTextBold }} key={`token-${timestamp}-${index}`}>
          {source.replace(/<b>(.*?)<\/b>/g, '$1')}
        </Text>
      ),
    },
    // NOTE: 사용자 멘션 마크업에 대한 처리
    // example: <m chatRoomMemberNo=20905, memberName="김 로켓">
  ]
  if (MENTION_TEMPLATE)
    replacements.push({
      format: MENTION_TEMPLATE,
      replacer: mentionReplacer,
    })
  return parseMessage(bodyText, replacements, {
    // fontColor,
  })
}
