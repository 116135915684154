import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleProp, StyleSheet, TextInput, View, ViewStyle } from 'react-native'
import { COLOR, FONT } from '@rocket-mono/libs'
import Button from '../Button'

interface Props {
  style?: StyleProp<ViewStyle>
  whenPressLogin: (email: string, password: string) => void
}
export const LoginForm: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  return (
    <View style={props.style}>
      <View style={style.inputWrap}>
        <TextInput
          style={[style.input, { height: 34 }]}
          textContentType="emailAddress"
          onChangeText={(text) => setEmail(text)}
          placeholder={t(`pro.login.enteremail`) ?? ''}
          placeholderTextColor={'gray.g450'}
        />
      </View>
      <View style={style.inputWrap}>
        <TextInput
          style={[style.input, { height: 34 }]}
          textContentType="password"
          secureTextEntry={true}
          onChangeText={(text) => setPassword(text)}
          placeholder={t(`pro.login.password`) ?? ''}
          placeholderTextColor={'gray.g450'}
          onSubmitEditing={() => {
            if (props.whenPressLogin) {
              props.whenPressLogin(email, password)
            }
          }}
        />
      </View>
      <View style={[style.inputWrap, { paddingTop: 2 }]}>
        <Button
          onPress={() => {
            if (props.whenPressLogin) {
              props.whenPressLogin(email, password)
            }
          }}
          style={{ width:'100%', height: 34 }}
        >
          {t(`pro.login.signin`)}
        </Button>
      </View>
    </View>
  )
}

const style = StyleSheet.create({
  inputWrap: {
    marginVertical: 2,
  },
  input: {
    borderWidth:1,
    borderColor: COLOR.gray.g200,
    borderRadius: 4,
    paddingHorizontal:12,
    ...FONT.txtSmMedium,
  }

})
