import React from 'react'
import { CardLinkContext } from '../cardLink/context.web'
import type { CardLinkContextType } from '../cardLink/types.web'

export function useCardLink() {
  const context = React.useContext<CardLinkContextType>(CardLinkContext)
  return {
    ...context,
  }
}
