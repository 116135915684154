import React from 'react'
import { MentionContext } from '../mention/context.web'
import type { MentionContextType } from '../mention/types.web'

export function useMention() {
  const context = React.useContext<MentionContextType>(MentionContext)
  return {
    ...context,
  }
}
