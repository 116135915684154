import React, { useState } from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { COLOR, FONT } from '@rocket-mono/libs'
import { FAIcon } from '@rocket-atoms/icon'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

interface Props {
  workTitle: string
  isFold: boolean
  onPress?: (isFold: boolean) => void
}

const CardSearchWorkTab = ({ workTitle, isFold, onPress }: Props) => {
  return (
    <Pressable
      onPress={() => {
        // setIsShow(!isShow)
        onPress && onPress(!isFold)
      }}
      style={[styles.container]}
    >
      <Text style={styles.nameText} numberOfLines={1}>
        {workTitle}
      </Text>
      <View style={styles.buttonArea}>
        <FAIcon iconName={!isFold ? faCaretUp : faCaretDown} size={'xs'} color={'gray.g600'} />
      </View>
    </Pressable>
  )
}

export default CardSearchWorkTab

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderTopWidth: 1,
    borderColor: COLOR.gray.g150,
    backgroundColor: COLOR.mono.white,
  },
  buttonArea: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    backgroundColor: COLOR.gray.g050,
    borderRadius: 4,
  },
  nameText: {
    ...FONT.txtSmBold,
    color: COLOR.gray.g900,
    marginLeft: 4,
  },
})
