import { Avatar } from '@rui/atoms'
import { DropdownButton } from '@rocket-atoms/dropdown'
import { ListMember, SelectorUser } from '@rocket-molecules/popselector'
import { COLOR, ClickOutside, FONT } from '@rocket-mono/libs'
import { Tag } from '@rocket/atoms'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { useRule } from '../../RuleScreen/hooks'
import { commonStyles } from '../style'

const zIndex = 100

interface Props {
  userList: ListMember[]
  onSelected: (item: ListMember[]) => void
}

const FlowRuleUserView: React.FC<Props> = ({ userList, onSelected }) => {
  const { t } = useTranslation()
  const { isAuth } = useRule()

  const [activated, setActivated] = React.useState(false)
  const selected = React.useMemo(() => {
    return userList.filter((u) => u.selected)
  }, [userList])
  return (
    <View>
      <View style={styles.container}>
        <View style={styles.dropButtonContainer}>
          <Pressable onPress={()=> isAuth && setActivated(true)}>
            <View style={styles.labelArea}>
              {selected.length !== 0 ? (
                <>
                  {selected.slice(0, 1).map((item, idx) => (
                    <View key={`member-${idx}`} style={{ marginRight: 2 }}>
                      <Tag
                        name={item.name}
                        size="SMALL"
                        disabled={!isAuth}
                        onPress={() => {
                          if (isAuth) setActivated(true)
                        }}
                      >
                        <Avatar profileUrl={item.uri} size={'xxsmall'} />
                      </Tag>
                    </View>
                  ))}
                  {selected.length - 1 > 0 && (
                    <Text style={commonStyles.valueText}>
                      {t('rule.automation.others', { count: selected.length - 1 })}
                    </Text>
                  )}
                </>
              ) : (
                <Text style={commonStyles.initText}>{t('rule.automation.incharge')}</Text>
              )}
            </View>
          </Pressable>
          <Text style={styles.dropdownButtonSideText}>{t('rule.rules.touser')}</Text>
        </View>
        {activated && (
          <ClickOutside onClickOutside={() => setActivated(false)}>
            <View style={{ position: 'absolute', zIndex }}>
              <SelectorUser
                tagListStyle={{ maxHeight: 55 }}
                listMember={userList}
                visible
                onClose={() => setActivated(false)}
                onChangeUsers={(changeUsers) => onSelected?.(changeUsers)}
              />
            </View>
          </ClickOutside>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  dropButtonContainer: {
    flexDirection: 'row',
  },
  labelArea: {
    overflow: 'hidden',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: -2,
    borderRadius: 4,
  },
  dropdownButtonSideText: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g800,
    marginLeft: 4,
  },
})

export default FlowRuleUserView
