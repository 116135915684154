import { COLOR, EventProvider } from '@rocket-mono/libs'
import { Channel } from '@rocket/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, Platform, ScrollView, StyleSheet, View } from 'react-native'
import ActionView from '../../../components/ActionView'
import ApplyBoardView from '../../../components/ApplyBoardView'
import RuleStepperView from '../../../components/RuleStep/RuleStepperView'
import {
  RuleValueToBoardProps,
  RuleValueToCardProps,
  RuleValueToUserProps,
  RuleValueToWorkProps,
} from '../../../components/RuleStep/RuleValueTemplate'
import RuleView from '../../../components/RuleView'
import TriggerCertainTimeView from '../../../components/TriggerCertainTimeView'
import TriggerView from '../../../components/TriggerView'
import { useRule } from '../../hooks'
import { StepperAction, StepperRule, Trigger } from '../../types'
import CreateModalView from './modalView'

const getInitialBoard = (selectedBoard?: Channel) => {
  if (selectedBoard) {
    return selectedBoard.id
  }
  return ''
}

const CreateView: React.FC = () => {
  const { t } = useTranslation()
  const {
    ruleList,
    modifiedRuleId,
    selectedBoardId,
    currentProject,
    isMobile,
    visible,
    boardList,
    elementList,
    createStep,
    selectedBoard,
    selectedTrigger,
    selectedRule,
    selectedAction,
    cardListOfSelectedBoard,
    memberListOfSelectedBoard,
    unArchiveBoardListOfSelectedWork,
    archiveBoardListOfSelectedWork,
    onChangeStep,
    getLabel,
    uuid4,
    onVisible,
    getProfileUrl,
    onCreateRuleStep,
    onSelectedBoard,
    onSelectedTrigger,
    onSelectedRule,
    onSelectedAction,
    onActionViewRender,
    onActionBoardBySelectedWork,
    onRuleListAdd,
    onRuleListChange,
  } = useRule()

  const [code, setCode] = React.useState<string>(getInitialBoard(selectedBoard))

  const previewBoardList = React.useMemo(() => {
    const isWFP = currentProject.type.code === 'WFP'
    return boardList
      .filter((board) => (selectedBoardId ? board.id === selectedBoardId : true))
      .map((board) => {
        const element = elementList.find((element) => element.id === board.projectElementId)
        return {
          label: board.roomName,
          code: board.id,
          beforeBoardLabel: getLabel({ isWFP, element }),
        }
      })
  }, [boardList, elementList, currentProject])

  const applyBoardDefaultIndex = React.useMemo(() => {
    return selectedBoard
      ? boardList
          .filter((board) => (selectedBoardId ? board.id === selectedBoardId : true))
          .findIndex((board) => board.id === selectedBoard.id)
      : undefined
  }, [selectedBoard, boardList])

  const view = React.useMemo(() => {
    switch (createStep) {
      case 'APPLY-BOARD':
        return (
          <ApplyBoardView
            boardList={previewBoardList}
            onItemPress={(item) => {
              setCode(item.code)
            }}
            defaultIndex={applyBoardDefaultIndex}
            onPressNext={() => {
              if (code) {
                const board = boardList.find((board) => board.id === code)
                onSelectedBoard(board)
                onCreateRuleStep('TRIGGERS')
                if (selectedTrigger) {
                  onSelectedTrigger(undefined)
                }
                if (selectedRule) {
                  onSelectedRule(undefined)
                }
                if (selectedAction) {
                  onSelectedAction(undefined)
                }
              }
            }}
            isMobile={isMobile}
          />
        )
      case 'TRIGGERS':
        return (
          <TriggerView
            selectedTriggerType={selectedTrigger?.type}
            onEvent={(type) => {
              if (selectedRule) {
                onSelectedRule(undefined)
              }
              if (selectedAction) {
                onSelectedAction(undefined)
              }
              if (type === 'NEWCARD') {
                onSelectedTrigger({ name: t('rule.trigger.title'), type })
                onCreateRuleStep('RULES')
              } else if (type === 'TIME') {
                onSelectedTrigger({ name: t('rule.repeat.title'), type })
                onCreateRuleStep('TRIGGERS-TIME')
              } else {
                console.log('no event')
              }
            }}
            onPressCancel={() => onCreateRuleStep('APPLY-BOARD')}
          />
        )
      case 'TRIGGERS-TIME':
        return (
          <TriggerCertainTimeView
            initialWeek={selectedTrigger?.isTime?.weekly}
            initialMonth={selectedTrigger?.isTime?.monthly}
            initialYear={selectedTrigger?.isTime?.yearly}
            initialTime={selectedTrigger?.isTime?.time}
            onPressCancel={() => {
              onSelectedTrigger({})
              onCreateRuleStep('TRIGGERS')
            }}
            onPressNext={({ type, week, month, day, time }) => {
              let data = {}
              if (type === 'WEEK') {
                if (week) {
                  data = { weekly: week }
                }
              } else if (type === 'MONTH') {
                if (day) {
                  data = { monthly: day }
                }
              } else if (type === 'YEAR') {
                if (month && day) {
                  data = { yearly: { month, day } }
                }
              }
              if (time) {
                data = { ...data, time }
              }
              if (selectedRule) {
                onSelectedRule(undefined)
              }
              if (selectedAction) {
                onSelectedAction(undefined)
              }
              onSelectedTrigger({ ...selectedTrigger, isTime: data })
              onSelectedRule({ event: 'CARD' })
              onCreateRuleStep('RULES')
            }}
          />
        )
      case 'RULES':
        return (
          <RuleView
            triggerType={selectedTrigger?.type}
            isMobile={isMobile}
            onPressCancel={() => {
              if (selectedTrigger && selectedTrigger.isTime) {
                onCreateRuleStep('TRIGGERS-TIME')
              } else {
                onCreateRuleStep('TRIGGERS')
              }
            }}
            onPressNext={() => {
              if (selectedAction) {
                onSelectedAction(undefined)
              }
              if (selectedRule?.event === 'CARD') {
                onSelectedAction({ selectedWork: currentProject, selectedBoard: [] })
                onActionBoardBySelectedWork(currentProject.id)
              }
              onCreateRuleStep('AUTOMATION')
            }}
          ></RuleView>
        )
      case 'AUTOMATION':
        return (
          <ActionView
            isMobile={isMobile}
            onPressCancel={() => {
              onCreateRuleStep('RULES')
            }}
            onPressCreate={() => {
              // console.log('Rule Create')
              // console.log('selectedBoard', selectedBoard)
              // console.log('selectedTrigger', selectedTrigger)
              // console.log('selectedRule', selectedRule)
              // console.log('selectedAction', selectedAction)

              if (modifiedRuleId) {
                const item = ruleList?.find((rule) => rule.id === modifiedRuleId)
                if (item) {
                  onRuleListChange(
                    {
                      id: modifiedRuleId,
                      applyBoard: selectedBoard?.id,
                      triggers: selectedTrigger,
                      rules: selectedRule,
                      action: selectedAction,
                      isActive: item.isActive,
                      cardList: cardListOfSelectedBoard,
                      memberList: memberListOfSelectedBoard,
                      unArchiveBoardList: unArchiveBoardListOfSelectedWork,
                      archiveBoardList: archiveBoardListOfSelectedWork,
                    },
                    () => {
                      onSelectedBoard(undefined)
                      onSelectedTrigger(undefined)
                      onSelectedRule(undefined)
                      onSelectedAction(undefined)

                      onChangeStep('VIEW')
                      onCreateRuleStep('APPLY-BOARD')
                    },
                  )
                }
              } else {
                onRuleListAdd(
                  {
                    id: uuid4(),
                    applyBoard: selectedBoard?.id,
                    triggers: selectedTrigger,
                    rules: selectedRule,
                    action: selectedAction,
                    isActive: true,
                  },
                  () => {
                    onSelectedBoard(undefined)
                    onSelectedTrigger(undefined)
                    onSelectedRule(undefined)
                    onSelectedAction(undefined)

                    onChangeStep('VIEW')
                    onCreateRuleStep('APPLY-BOARD')
                  },
                )
              }
            }}
          ></ActionView>
        )
    }
  }, [
    previewBoardList,
    createStep,
    selectedBoard,
    selectedTrigger,
    selectedAction,
    code,
    isMobile,
    cardListOfSelectedBoard,
    memberListOfSelectedBoard,
    unArchiveBoardListOfSelectedWork,
    archiveBoardListOfSelectedWork,
    onRuleListAdd,
  ])

  const stepperProps = React.useMemo(() => {
    let applyBoardValue: RuleValueToBoardProps[] | undefined = undefined
    let triggersValue: Trigger | undefined = undefined
    let rulesValue: StepperRule | undefined = undefined
    let actionValue: StepperAction | undefined = undefined

    if (selectedBoard) {
      const isWFP = currentProject.type.code === 'WFP'
      const element = elementList.find((element) => element.id === selectedBoard.projectElementId)
      applyBoardValue = [
        {
          color: getLabel({ isWFP, element }),
          name: selectedBoard.roomName,
        },
      ]
    } else if (createStep === 'APPLY-BOARD') {
      applyBoardValue = []
    }

    if (selectedTrigger) {
      triggersValue = {
        name: selectedTrigger.name,
        isTime: selectedTrigger.isTime,
      }
    } else if (createStep === 'TRIGGERS') {
      triggersValue = {}
    }

    if (selectedRule) {
      const { event, selectedUsers, selectedCard, selectedBoard, selectedWork } = selectedRule
      let name = ''
      let toUser: RuleValueToUserProps[] | undefined = undefined
      let toCard: RuleValueToCardProps | undefined = undefined
      let toBoard: RuleValueToBoardProps[] | undefined = undefined
      let toWork: RuleValueToWorkProps | undefined = undefined
      if (event === 'CARD') {
        name = t('rule.cards.title')
      } else if (event === 'SPECIFIC_USER') {
        name = t('rule.users.title')
      } else if (event === 'SPECIFIC_BOARD') {
        name = t('rule.boards.title')
      } else if (event === 'ANOTHER_WORK_BOARD') {
        name = t('rule.works.title')
      }
      if (selectedUsers) {
        toUser = selectedUsers.map((user) => {
          return {
            name: user.name,
            profile: getProfileUrl(user.email),
          }
        })
      }
      if (selectedCard) {
        toCard = {
          name: selectedCard.cardTitle,
          type: selectedCard.cardType === 'ATTEND' ? 'MEETING' : selectedCard.cardType,
        }
      }
      if (selectedWork) {
        toWork = {
          name: selectedWork.title,
          type: selectedWork.type.code,
        }
      }
      if (selectedBoard) {
        const isWFP = selectedWork ? selectedWork.type.code === 'WFP' : currentProject.type.code === 'WFP'
        toBoard = selectedBoard.map((board) => {
          const element = elementList.find((element) => element.id === board.projectElementId)
          return {
            name: board.roomName,
            color: getLabel({ isWFP, element }),
          }
        })
      }
      rulesValue = {
        name,
        toUser,
        toCard,
        toBoard,
        toWork,
      }
    } else if (createStep === 'RULES') {
      rulesValue = {}
    }

    if (selectedAction) {
      const { event, selectedWork, selectedBoard } = selectedAction
      let name = ''
      let toName = ''
      let toWork: RuleValueToWorkProps | undefined = undefined
      let toBoard: RuleValueToBoardProps[] | undefined = undefined
      if (event === 'MAIL') {
        name = t('rule.automation.mail')
      } else if (event === 'INCHARGE') {
        name = t('rule.automation.incharge')
      } else if (event === 'COPY') {
        name = t('rule.automation.copy')
      } else if (event === 'COOP') {
        name = t('addcontainer.coop')
      }
      if (selectedWork || selectedBoard) {
        toName = t('rule.works.title')
      }
      if (selectedWork) {
        toWork = {
          name: selectedWork.title,
          type: selectedWork.type.code,
        }
      }
      if (selectedBoard) {
        const isWFP = selectedWork?.type.code === 'WFP'
        toBoard = selectedBoard.map((board) => {
          const element = elementList.find((element) => element.id === board.projectElementId)
          return {
            name: board.roomName,
            color: getLabel({ isWFP, element }),
          }
        })
      }
      actionValue = {
        name,
        toName,
        toWork,
        toBoard,
      }
    } else if (createStep === 'AUTOMATION') {
      actionValue = {}
    }

    return { applyBoardValue, triggersValue, rulesValue, actionValue }
  }, [createStep, selectedBoard, selectedTrigger, selectedRule, selectedAction, elementList, currentProject])

  return (
    <>
      <View
        style={styles.container}
        onLayout={() => {
          if (isMobile) {
            onVisible(true)
          }
        }}
      >
        <ScrollView
          style={[styles.sideContainer, isMobile ? { height: Dimensions.get('screen').height - 52 } : undefined]}
        >
          <RuleStepperView
            {...stepperProps}
            onPress={(type) => {
              onVisible(true)
              if (type === 'Applyboard') {
                onCreateRuleStep('APPLY-BOARD')
              } else if (type === 'Triggers' && selectedTrigger) {
                if (selectedTrigger.type === 'TIME') {
                  onCreateRuleStep('TRIGGERS-TIME')
                } else {
                  onCreateRuleStep('TRIGGERS')
                }
              } else if (type === 'Rules' && selectedRule) {
                onCreateRuleStep('RULES')
              } else if (type === 'Action' && selectedAction) {
                onCreateRuleStep('AUTOMATION')
              }
            }}
            onCancel={(type) => {
              if (type === 'Applyboard') {
                if (selectedBoard) {
                  onSelectedBoard(undefined)
                }
                if (selectedTrigger) {
                  onSelectedTrigger(undefined)
                }
                if (selectedRule) {
                  onSelectedRule(undefined)
                }
                if (selectedAction) {
                  onSelectedAction(undefined)
                }
                onCreateRuleStep('APPLY-BOARD')
              } else if (type === 'Triggers') {
                if (selectedTrigger) {
                  onSelectedTrigger(undefined)
                }
                if (selectedRule) {
                  onSelectedRule(undefined)
                }
                if (selectedAction) {
                  onSelectedAction(undefined)
                }
                onCreateRuleStep('TRIGGERS')
              } else if (type === 'Rules') {
                if (selectedRule) {
                  onSelectedRule(undefined)
                }
                if (selectedAction) {
                  onSelectedAction(undefined)
                }
                onCreateRuleStep('RULES')
              } else if (type === 'Action') {
                if (selectedAction) {
                  onSelectedAction(undefined)
                  onActionViewRender(false)
                  setTimeout(() => {
                    onActionViewRender(true)
                  }, 1)
                }

                onCreateRuleStep('AUTOMATION')
              }
            }}
          />
        </ScrollView>
        {isMobile ? (
          visible ? (
            Platform.OS === 'web' ? (
              <div
                style={{
                  position: 'fixed',
                  display: 'flex',
                  zIndex: 10,
                  marginTop: -62,
                  height: '100%',
                  width: '100%',
                }}
              >
                <CreateModalView onClose={() => onVisible(false)}>{view}</CreateModalView>
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )
        ) : (
          <View style={styles.mainContainer}>
            <View style={styles.innerContainer}>{view}</View>
          </View>
        )}
      </View>
      {visible && Platform.OS !== 'web' ? (
        <View style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
          <EventProvider>
            <CreateModalView onClose={() => onVisible(false)}>{view}</CreateModalView>
          </EventProvider>
        </View>
      ) : (
        <></>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
  },
  sideContainer: {
    flex: 1,
    paddingVertical: 16,
    paddingHorizontal: 20,
  },
  mainContainer: {
    flex: 2,
    backgroundColor: COLOR.gray.g050,
    paddingTop: 16,
    paddingBottom: 24,
    paddingHorizontal: 20,
  },
  innerContainer: {
    height: '100%',
  },
})

export default CreateView
