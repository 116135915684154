import { Icon } from '@rocket-atoms/icon'
import { COLOR } from '@rocket-mono/libs'
import * as React from 'react'
import { StyleSheet, View } from 'react-native'

interface Props {
  checked?: boolean
  disabled?: boolean
  containerSize?: number
  size?: number
  type?: 'DEFAULT' | 'TODO' | 'SCHEDULE' | 'GATHERING'
}

const Checkbox: React.FC<Props> = ({ checked = false, disabled = false, containerSize = 18, size = 14, type }) => {
  if (disabled) {
    return <View style={[styles.base, { height: containerSize, width: containerSize }]}></View>
  }
  if (checked) {
    return (
      <View
        style={[
          styles.base,
          { height: containerSize, width: containerSize },
          { borderWidth: 0, backgroundColor: COLOR.primary.blue500 },
          type === 'TODO' && { backgroundColor: COLOR.main.turquoise },
          type === 'SCHEDULE' && { backgroundColor: COLOR.main.yellow },
          type === 'GATHERING' && { backgroundColor: COLOR.sub.purple },
        ]}
      >
        <Icon name="check-min" color="mono.white" size={size} viewBox="100 20 800 800" />
      </View>
    )
  }

  return <View style={[styles.base, { height: containerSize, width: containerSize }]}></View>
}

export default Checkbox

const styles = StyleSheet.create({
  base: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 18,
    height: 18,
    borderWidth: 1,
    borderColor: COLOR.gray.g300,
    borderRadius: 4,
    backgroundColor: COLOR.mono.white,
  },
})
