import * as React from 'react'
import { View as RNView, ViewProps as RNViewProps } from 'react-native'

interface ViewProps extends RNViewProps {}
/**
 * @deprecated
 */
const View = React.forwardRef(({ children, ...viewProps }: ViewProps, ref: React.LegacyRef<RNView>) => {
  return (
    <RNView style={{ zIndex: undefined }} ref={ref} {...viewProps}>
      {children}
    </RNView>
  )
})

export default View
