import React from 'react'
import { View } from 'react-native'

export const Divider = () => {
  return (
    <View
      style={{
        height: 1,
        backgroundColor: '#EEEEEE',
      }}
    />
  )
}
