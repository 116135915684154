import React, { useCallback, useMemo } from 'react'
import { SignUpScreen } from '@rocket-screens/auth'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SocialJoinType } from './types'
import { useAstro } from '@rocket-mono/providers'

const SignupScreen: React.FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const invitedEmail = searchParams.get('invitedEmail') || undefined

  const { astro } = useAstro()

  const socialList = useMemo<SocialJoinType[]>(() => {
    return [
      {
        provider: 'GOOGLE',
        onProc: () => {
          return astro.joinByGoogle()
        },
      },
      {
        provider: 'APPLE',
        onProc: () => {
          return astro.joinByApple()
        },
      },
      {
        provider: 'NAVER',
        onProc: () => {
          return astro.joinByNaver()
        },
      },
      {
        provider: 'KAKAO',
        onProc: () => {
          return astro.joinByKakao()
        },
      },
      {
        provider: 'FACEBOOK',
        onProc: () => {
          return astro.joinByFacebook()
        },
      },
    
    ]
  }, [])

  const handlePressLogin = useCallback(() => {
    navigate(`/login`)
  }, [])

  return <SignUpScreen socialList={socialList} invitedEmail={invitedEmail} onPressLogin={handlePressLogin} />
}

export default SignupScreen
