import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { COLOR } from '@rocket-mono/libs'
import { IColors } from '@rocket-mono/libs'
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import SelectItem from './Item'

export interface SelectItemType {
  type: 'COMMON' | 'SWITCH' | 'TOGGLE' | 'INFO'
  label: string
  labelSize?: number
  aboutLabel?: string
  beforeIconName?: IconProp
  beforeIconSize?: SizeProp
  beforeIconColor?: IColors
  afterIconName?: IconProp
  afterIconSize?: SizeProp
  afterIconColor?: IColors
  onPress?: () => void
  selected?: boolean
}

export interface SelectItemGroupType{
  beforeDiv?: boolean
  afterDiv?: boolean
  group: SelectItemType[]
}

interface Props{
  list:SelectItemGroupType
  mode?: 'LIGHT' | 'DARK'
}

const SelectItemGroup = ({ list, mode }: Props) => {
  return(
    <View style={styles.itemGroup}>
      {list.beforeDiv &&
        <View style={[styles.divLine, mode==='LIGHT' && styles.divLightLine]}></View>
      }
        {list.group.map((item, idx)=>(
          <SelectItem mode={mode} item={item} key={`selectItem-${idx}`}/>
        ))}
      {list.afterDiv &&
        <View style={[styles.divLine, mode==='LIGHT' && styles.divLightLine]}></View>
      }
    </View>
  )
}

export default SelectItemGroup

const styles = StyleSheet.create({
  itemGroup:{
    position:'relative',
  },
  divLine:{
    width:'100%',
    height:1,
    backgroundColor:COLOR.darkmode.cool600
  },
  divLightLine:{
    backgroundColor:COLOR.gray.g150
  },
})