import React from 'react'
import Context from './context'
import Provider from './provider'
import { ScreenProps } from './types'
import View from './view'

const RuleScreen: React.FC<ScreenProps> = ({ projectId, selectedBoardId, onPressClose, visible }) => {
  if (!visible) return <></>
  return (
    <Provider projectId={projectId} selectedBoardId={selectedBoardId}>
      <Context.Consumer>{(context) => context && <View onPressClose={onPressClose} />}</Context.Consumer>
    </Provider>
  )
}

export default RuleScreen
