import { DropdownItemProps, DropdownListItem } from '@rocket-atoms/dropdown'
import { SearchTextField } from '@rocket-atoms/text'
import { Combobox } from '@rui/molecules'
import { COLOR } from '@rocket-mono/libs'
import React from 'react'
import { Props } from './types'

const SelectorCard: React.FC<Props> = ({ cardList, defaultIndex, width, placeholder = '입력하세요', onSelected }) => {
  const [searchKeyword, setSearchKeyword] = React.useState('')
  const [selected, setSelected] = React.useState(defaultIndex ? [cardList[Number(defaultIndex)]] : [])

  const onPressItem = React.useCallback((item: DropdownItemProps) => {
    if (onSelected) {
      onSelected(item)
      setSelected([item])
    } else {
      setSelected([item])
    }
  }, [])

  const previewCardList = React.useMemo(() => {
    return cardList.filter((item) => item.label.includes(searchKeyword))
  }, [cardList, searchKeyword])

  return (
    <Combobox visible={true} width={width}>
      <Combobox.Header style={{ padding: 8 }}>
        <SearchTextField
          value={searchKeyword}
          placeholder={placeholder}
          onChangeText={setSearchKeyword}
          onPressSubmit={() => {
            console.log('검색')
          }}
          autoFocus={true}
        />
      </Combobox.Header>
      <Combobox.Body>
        {previewCardList.map((item, idx) => (
          <DropdownListItem
            item={item}
            itemType={'DEFAULT'}
            itemVariant={'DEFAULT'}
            itemStyle={idx !== 0 ? { borderTopWidth: 1, borderColor: COLOR.gray.g100 } : {}}
            selected={selected}
            onPressItem={onPressItem}
            key={`cardList-${idx}`}
          />
        ))}
      </Combobox.Body>
    </Combobox>
  )
}

export default SelectorCard
