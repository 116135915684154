import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

interface Props {
  color?: string
  opacity?: number
}

const RocketIcon: React.FC<Props> = ({ color = 'black', opacity = 0.7 }) => (
  <Svg width={15} height={15} viewBox="0 0 15 15" fill="none">
    <Path
      d="M4.47859 11.428L3.62609 10.5755C3.40609 10.3555 3.32359 10.0255 3.40609 9.69546C3.48859 9.44796 3.59859 9.14546 3.73609 8.76046H0.848594C0.601094 8.76046 0.381094 8.65046 0.271094 8.43046C0.133594 8.23796 0.133594 7.99046 0.271094 7.77046L1.70109 5.35046C2.05859 4.74546 2.71859 4.36046 3.40609 4.36046H5.68859C5.74359 4.25046 5.79859 4.16796 5.88109 4.05796C8.13609 0.730462 11.4911 0.620462 13.4711 1.00546C13.8011 1.06046 14.0486 1.30796 14.1036 1.63796C14.4886 3.61796 14.3786 6.97296 11.0511 9.22796C10.9411 9.31046 10.8311 9.36546 10.7486 9.42046V11.703C10.7486 12.3905 10.3636 13.0505 9.75859 13.408L7.33859 14.838C7.11859 14.948 6.87109 14.9755 6.67859 14.838C6.45859 14.728 6.34859 14.508 6.34859 14.2605V11.318C5.93609 11.4555 5.60609 11.5655 5.35859 11.648C5.05609 11.758 4.69859 11.6755 4.47859 11.428ZM10.7486 5.46046C11.3536 5.46046 11.8486 4.99296 11.8486 4.36046C11.8486 3.75546 11.3536 3.26046 10.7486 3.26046C10.1161 3.26046 9.64859 3.75546 9.64859 4.36046C9.64859 4.99296 10.1161 5.46046 10.7486 5.46046Z"
      fill={color}
      fillOpacity={opacity}
    />
  </Svg>
)
export default RocketIcon
