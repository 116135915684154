import React from 'react'

import { useTranslation } from 'react-i18next'
import { Image, StyleSheet, Text, View } from 'react-native'

import { COLOR, FONT } from '@rocket-mono/libs'

const NoCardView: React.FC = () => {
  const { t } = useTranslation()
  return (
    <View style={styles.container}>
      <View style={{ marginTop: -100 }}>
        <Image
          source={{ uri: 'https://rocket.is/files/file_box/6dd4f9258823486c89aa1b1e381dcac4.png' }}
          style={styles.image}
        />
      </View>
      <View>
        <Text style={styles.header}>Oopss!</Text>
      </View>
      <View style={{ marginTop: 8 }}>
        <Text style={styles.description}>{t('chat.deletecard')}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  image: {
    width: 376,
    height: 260,
  },
  header: {
    ...FONT.H3Bold,
    color: COLOR.gray.g800,
  },
  description: {
    ...FONT.H7Medium,
    color: COLOR.gray.g900,
  },
})

export default NoCardView
