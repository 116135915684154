import { ProjectPosition } from '@rocket/types'
import { atom, atomFamily } from 'recoil'

export const draggingIdState = atom<string>({
  key: 'draggingIdState',
  default: '',
})

export const positionListState = atomFamily<ProjectPosition[], string>({
  key: 'positionListState',
  default: [],
})

export const draggablePlaceHolderState = atom<{ index: number; boardId: string; boardIndex: number } | undefined>({
  key: 'draggablePlaceHolderState',
  default: undefined,
})
