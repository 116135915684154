import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, View } from 'react-native'
import { Divider } from './Divider'
import { Header } from './Header'
import { LoginButton } from './LoginButton'
import { RecoveryDone } from './RecoveryDone'
import { RecoveryForm } from './RecoveryForm'
import { Row } from './Row'
import { SignupForm } from './SignupForm'
import { TextButton } from './TextButton'
import { TextLink } from './TextLink'
import { LoginForm } from './LoginForm'
import LanguageSelect from '../LanguageSelect'
import { useSystem } from '../../../SystemProvider'
import { Text } from '@rocket-atoms/text'
import { COLOR } from '@rocket-mono/libs'

type LoginProvider = 'google' | 'apple'

export type LOGIN_STATE = 'initial' | 'login.email' | 'signup.email' | 'recovery' | 'recovery.done'

export interface Props {
  redirectUrl?: string
  whenPressDismiss?: () => void
  whenLoginSuccess?: (token: string) => void
  whenLoginFail?: (error: unknown) => void
  whenSendPasswordFail?: (error: unknown) => void
  astro: {
    loginByGoogle: () => Promise<Response>
    loginByNaver: () => Promise<Response>
    loginByKakao: () => Promise<Response>
    loginByApple: () => Promise<Response>
    loginByFacebook: () => Promise<Response>
    loginByEmail: (p: { type: 'base' | 'pro'; email: string; password: string }) => Promise<Response>
    joinByEmail: (p: { type: 'base' | 'pro'; email: string; password: string; name: string }) => Promise<Response>
    sendMailIssueTempPassword(email: string): Promise<Response>
  }
  /**이용약관 */
  onTerms?: () => void
  /**개인정보약관 */
  onPrivacy?: () => void
  /**dimmed */
  dimmed?: boolean
  isJoinNextLogin?: boolean
  loginStateMove?: LOGIN_STATE
  retryLogin?: boolean
}

export const LoginModal: React.FC<Props> = ({
  astro,
  whenPressDismiss,
  whenLoginSuccess,
  whenLoginFail,
  whenSendPasswordFail,
  onTerms,
  onPrivacy,
  dimmed,
  isJoinNextLogin = false,
  loginStateMove,
  retryLogin,
}: Props) => {
  const { i18n, t } = useTranslation()
  const { language } = useSystem()

  const [activeLoginProviders] = useState<LoginProvider[]>([
    'google',
    // 'naver',
    // 'kakaotalk',
    // 'facebook',
    'apple',
  ])

  const [loginState, setLoginState] = useState<LOGIN_STATE>('initial')
  const [recoveryEmail, setRecoveryEmail] = useState<string>('')

  const [languageList, setLanguageList] = React.useState([
    {
      itemCode: 'en',
      label: 'English',
      active: false,
    },
    {
      itemCode: 'ko',
      label: '한국어',
      active: true,
    },
    {
      itemCode: 'es',
      label: 'Español',
      active: false,
    },
    {
      itemCode: 'de',
      label: 'Deutsch',
      active: false,
    },
    {
      itemCode: 'vi',
      label: 'Tiếng Việt',
      active: false,
    },
    {
      itemCode: 'mn',
      label: 'Монгол',
      active: false,
    },
    {
      itemCode: 'ja',
      label: '日本語',
      active: false,
    },
  ])
  const [isLanguageOpen, setIsLanguageOpen] = React.useState(false)

  const [emailPayload, setEmailPayload] = useState<{
    email: string
    password: string
  }>()

  const onLanguage = (lang: string) => {
    const newList = languageList.map((item) => {
      if (item.itemCode === lang) {
        return { ...item, active: true }
      } else {
        return { ...item, active: false }
      }
    })
    setLanguageList(newList)
    void i18n.changeLanguage(lang)
  }

  useEffect(() => {
    if (language) {
      onLanguage(language)
    }
  }, [language])

  useEffect(() => {
    if (loginStateMove) {
      setLoginState(loginStateMove)
    }
  }, [loginStateMove])

  useEffect(() => {
    if (retryLogin && emailPayload) {
      astro
        .loginByEmail({ type: 'pro', ...emailPayload })
        .then((response) => {
          if (response.status === 200) {
            const token = response.headers.get('X-Auth-Token')
            if (token != null) {
              if (whenLoginSuccess) whenLoginSuccess(token)
            } else {
              if (whenLoginFail) whenLoginFail(response)
            }
          } else {
            if (whenLoginFail) whenLoginFail(response)
          }
        })
        .catch((error) => {
          // TODO: 로그인 실패시 인터랙션
          if (whenLoginFail) whenLoginFail(error)
        })
    }
  }, [retryLogin, emailPayload])

  const HeaderText = useCallback(() => {
    const headerTitle = {
      initial: 'Log in',
      'login.email': 'Log in',
      'signup.email': '',
      recovery: t('findpasswordmodal.title'),
      'recovery.done': t('widget.login.title.recoverydone'),
    }

    return headerTitle[loginState]
  }, [loginState, t])

  const HelpFooter = useCallback(() => {
    return (
      <>
        <Divider />
        <View style={style.helpContainer}>
          <Text fontName="txtXs" fontColor="gray.g500">
            {t('pro.login.termsinfo1')}
          </Text>
          <Text fontName="txtXs" fontColor="gray.g500">
            {t('pro.login.termsinfo2')}{' '}
            <Pressable onPress={onTerms}>
              <Text fontName="txtXs" fontColor="gray.g500" style={style.underlineText}>{t('pro.login.termsinfo3')}</Text>
            </Pressable>{' '}
            {t('pro.login.termsinfo4')}{' '}
            <Pressable onPress={onPrivacy}>
              <Text fontName="txtXs" fontColor="gray.g500" style={style.underlineText}>{t('pro.login.termsinfo5')}</Text>
            </Pressable>
          </Text>
          <Text fontName="txtXs" fontColor="gray.g500">
            {t('pro.login.termsinfo6')}
          </Text>
        </View>
      </>
    )
  }, [t, onTerms, onPrivacy])

  const ReturnFooter = useCallback(() => {
    return (
      <View style={{ marginTop: 26, marginBottom: 16 }}>
        <Text style={[style.textCenter]}>
          {/* <Text>{t(`widget.login.returnto.prefix`)}</Text> */}
          <TextLink
            onPress={() => {
              setLoginState('initial')
            }}
          >
            {t(`findpasswordmodal.footer.login`)}
          </TextLink>
          <Text>{t(`findpasswordmodal.footer.back`)}</Text>
        </Text>
      </View>
    )
  }, [t])

  const LoginStepWizard = useCallback(() => {
    const signInWith = (provider: string) => {
      switch (provider) {
        case 'google':
          astro
            .loginByGoogle()
            .then((response) => {
              if (response.status === 200) {
                const token = response.headers.get('X-Auth-Token')
                if (token != null) {
                  if (whenLoginSuccess) whenLoginSuccess(token)
                } else {
                  if (whenLoginFail) whenLoginFail(response)
                }
              } else {
                if (whenLoginFail) whenLoginFail(response)
              }
            })
            .catch((error: any) => {
              // TODO: 구글 로그인 실패시 인터랙션
              if (whenLoginFail) whenLoginFail(error)
            })
          break
        case 'naver':
          astro
            .loginByNaver()
            .then((response) => {
              if (response.status === 200) {
                const token = response.headers.get('X-Auth-Token')
                if (token != null) {
                  if (whenLoginSuccess) whenLoginSuccess(token)
                } else {
                  if (whenLoginFail) whenLoginFail(response)
                }
              } else {
                if (whenLoginFail) whenLoginFail(response)
              }
            })
            .catch((error: any) => {
              // TODO: 네이버 로그인 실패시 인터랙션
              if (whenLoginFail) whenLoginFail(error)
            })
          break
        case 'kakaotalk':
          astro
            .loginByKakao()
            .then((response) => {
              if (response.status === 200) {
                const token = response.headers.get('X-Auth-Token')
                if (token != null) {
                  if (whenLoginSuccess) whenLoginSuccess(token)
                } else {
                  if (whenLoginFail) whenLoginFail(response)
                }
              } else {
                if (whenLoginFail) whenLoginFail(response)
              }
            })
            .catch((error: any) => {
              // TODO: 카카오톡 로그인 실패시 인터랙션
              if (whenLoginFail) whenLoginFail(error)
            })
          break
        case 'facebook':
          astro
            .loginByFacebook()
            .then((response) => {
              if (response.status === 200) {
                const token = response.headers.get('X-Auth-Token')
                if (token != null) {
                  if (whenLoginSuccess) whenLoginSuccess(token)
                } else {
                  if (whenLoginFail) whenLoginFail(response)
                }
              } else {
                if (whenLoginFail) whenLoginFail(response)
              }
            })
            .catch((error: any) => {
              // TODO: 페이스북 로그인 실패시 인터랙션
              if (whenLoginFail) whenLoginFail(error)
            })
          break
        case 'apple':
          astro
            .loginByApple()
            .then((response) => {
              if (response.status === 200) {
                const token = response.headers.get('X-Auth-Token')
                if (token != null) {
                  if (whenLoginSuccess) whenLoginSuccess(token)
                } else {
                  if (whenLoginFail) whenLoginFail(response)
                }
              } else {
                if (whenLoginFail) whenLoginFail(response)
              }
            })
            .catch((error: any) => {
              // TODO: 애플 로그인 실패시 인터랙션
              if (whenLoginFail) whenLoginFail(error)
            })
          break
      }
    }
    switch (loginState) {
      case 'initial':
        return (
          <>
            <View style={style.languageView}>
              <LanguageSelect
                list={languageList}
                isOpen={isLanguageOpen}
                setIsOpen={setIsLanguageOpen}
                onLanguagePress={onLanguage}
              />
            </View>
            <View style={{ flexDirection: 'column' }}>
              {activeLoginProviders.map((provider) => (
                <View style={{ marginBottom: 4 }}>
                  <LoginButton
                    key={`login-${provider}`}
                    onPress={() => {
                      signInWith(provider)
                    }}
                    provider={provider}
                  />
                </View>
              ))}
            </View>
            <View style={[style.block, { marginTop: 16 }]}>
              <TextButton
                onPress={() => {
                  setLoginState('login.email')
                }}
                text={t(`pro.login.email`)}
              />
            </View>
            <HelpFooter />
          </>
        )
      case 'login.email':
        return (
          <>
            <View style={style.languageView}>
              <LanguageSelect
                list={languageList}
                isOpen={isLanguageOpen}
                setIsOpen={setIsLanguageOpen}
                onLanguagePress={onLanguage}
              />
            </View>
            <View style={{ flexDirection: 'column' }}>
              {activeLoginProviders.map((provider) => (
                <View style={{ marginBottom: 4 }}>
                  <LoginButton
                    key={`login-${provider}`}
                    onPress={() => {
                      signInWith(provider)
                    }}
                    provider={provider}
                  />
                </View>
              ))}
            </View>
            <View style={[style.block, style.subLoginEmail]}>
              <Text fontName="txtXs" style={[style.subHeading, style.textCenter]}>
                {t(`pro.login.email`)}
              </Text>
            </View>
            <LoginForm
              whenPressLogin={function (email: string, password: string): void {
                astro
                  .loginByEmail({ type: 'pro', email, password })
                  .then((response) => {
                    if (response.status === 200) {
                      const token = response.headers.get('X-Auth-Token')
                      if (token != null) {
                        if (whenLoginSuccess) whenLoginSuccess(token)
                      } else {
                        if (whenLoginFail) whenLoginFail(response)
                      }
                    } else {
                      setEmailPayload({ email, password })
                      if (whenLoginFail) whenLoginFail(response)
                    }
                  })
                  .catch((error) => {
                    // TODO: 로그인 실패시 인터랙션
                    if (whenLoginFail) whenLoginFail(error)
                  })
              }}
              style={{ marginTop: 8, marginBottom: 4 }}
            />
            <Row style={{ flex: 1, marginBottom: 30 }}>
              <Text>
                <Text fontName={'txtXs'} fontColor={'gray.g800'} >{t(`pro.login.notmember`)}</Text>
                {` `}
                <TextLink
                  onPress={() => {
                    setLoginState('signup.email')
                  }}
                >
                  {t(`pro.login.signup`)}
                </TextLink>
              </Text>
              <TextLink
                onPress={() => {
                  setLoginState('recovery')
                }}
                color="mono.paleBlack"
              >
                {t(`pro.login.findpw`)}
              </TextLink>
            </Row>
            <HelpFooter />
          </>
        )
      case 'signup.email':
        return (
          <>
            <Row style={{ paddingHorizontal: 14 }}>
              {activeLoginProviders.map((provider) => (
                <LoginButton
                  key={`signup-${provider}`}
                  onPress={() => {
                    signInWith(provider)
                  }}
                  provider={provider}
                />
              ))}
            </Row>
            <SignupForm
              whenPressSignUp={(email: string, password: string, name: string) => {
                astro
                  .joinByEmail({
                    type: 'pro',
                    email,
                    password,
                    name,
                  })
                  .then((ready) => {
                    if (ready) {
                      // TODO: 이메일 가입 성공시 추가 인터랙션 (알림 등)
                      if (isJoinNextLogin) {
                        astro
                          .loginByEmail({ type: 'pro', email, password })
                          .then((respnose) => {
                            const token = respnose.headers.get('X-Auth-Token')
                            if (token) {
                              if (whenLoginSuccess) whenLoginSuccess(token)
                            } else {
                              if (whenLoginFail) whenLoginFail(respnose)
                            }
                          })
                          .catch((error) => {
                            // TODO: 로그인 실패시 인터랙션
                            if (whenLoginFail) whenLoginFail(error)
                          })
                      } else {
                        setLoginState('initial')
                      }
                    }
                  })
                  .catch((error) => {
                    // TODO: 이메일 가입 실패시 인터랙션
                    if (whenLoginFail) whenLoginFail(error)
                  })
              }}
              onTerms={onTerms}
              onPrivacy={onPrivacy}
            />
            <ReturnFooter />
          </>
        )
      case 'recovery':
        return (
          <>
            <RecoveryForm
              whenPressRecovery={(email) => {
                setRecoveryEmail(email)
                astro
                  .sendMailIssueTempPassword(email)
                  .then((response) => {
                    if (response.status === 200) {
                      setLoginState('recovery.done')
                    } else {
                      // TODO: 초기화 이메일 전송 실패시 인터랙션
                      if (whenSendPasswordFail) whenSendPasswordFail(response)
                    }
                  })
                  .catch((error) => {
                    // TODO: 초기화 이메일 전송 실패시 인터랙션
                    if (whenLoginFail) whenLoginFail(error)
                  })
              }}
            />
            <ReturnFooter />
          </>
        )
      case 'recovery.done':
        return (
          <>
            <RecoveryDone
              email={recoveryEmail}
              whenDone={function (): void {
                setRecoveryEmail('')
                setLoginState('initial')
              }}
            />
          </>
        )
    }
  }, [
    HelpFooter,
    ReturnFooter,
    activeLoginProviders,
    astro,
    loginState,
    recoveryEmail,
    t,
    whenLoginFail,
    whenLoginSuccess,
    onTerms,
    onPrivacy,
    languageList,
    isLanguageOpen,
  ])

  const Subheading = useCallback(() => {
    switch (loginState) {
      case 'signup.email':
        return (
          <>
            <View style={{ height: 4 }} />
            <View style={style.languageView}>
              <LanguageSelect
                list={languageList}
                isOpen={isLanguageOpen}
                setIsOpen={setIsLanguageOpen}
                onLanguagePress={onLanguage}
              />
            </View>
            <View style={style.block}>
              <Text fontName="subTextRegular" style={[style.subHeading, style.textCenter]}>
                {t(`pro.signup.label`)}
              </Text>
            </View>
          </>
        )
      case 'recovery':
        return (
          <View style={style.block}>
            <Text fontName="subTextRegular" style={[style.subHeading, style.textCenter]}>
              {t(`findpasswordmodal.desc1`)}
            </Text>
            <Text fontName="subTextRegular" style={[style.subHeading, style.textCenter]}>
              {t(`findpasswordmodal.desc2`)}
            </Text>
          </View>
        )
      default:
        return <></>
    }
  }, [loginState, t, isLanguageOpen, languageList])

  return (
    <View
      style={{
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 9999,
      }}
    >
      {dimmed && (
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: COLOR.mono.realBlack,
            opacity: 0.8,
          }}
        />
      )}
      <View
        style={{
          width: 400,
          minHeight: 250,
          borderRadius: 8,
          padding: 24,
          margin: 'auto',
          backgroundColor: 'white',
          shadowRadius: 15,
          shadowOpacity: 0.15,
          alignSelf: 'center',
        }}
      >
        <Header
          loginState={loginState}
          title={HeaderText()}
          whenPressDismiss={() => {
            if (whenPressDismiss) {
              whenPressDismiss()
            }
          }}
        />

        {Subheading()}
        {LoginStepWizard()}
      </View>
    </View>
  )
}

const style = StyleSheet.create({
  block: {
    marginVertical: 8,
    width: '100%',
  },
  helpContainer: {
    // flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: 12,
  },
  underlineText: {
    textDecorationLine: 'underline',
  },
  subHeading: {
    color: '#666',
  },
  socialLogin: {
    marginVertical: 10,
    paddingHorizontal: 14,
  },
  textCenter: {
    textAlign: 'center',
  },
  // inputWrap: {
  //   marginVertical: 5,
  // },
  subLoginEmail: {
    paddingTop: 12,
    marginBottom: 0,
  },
  languageView: {
    marginBottom: 16,
    zIndex: 1,
  },
})
