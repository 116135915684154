import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import {
  Context,
  IndexedBoardType,
  IndexedChatMessageType,
  IndexedProjectType,
  SearchCardType,
  SearchFileType,
  SearchPayloadType,
  SearchStateType,
  type ContextProps,
  type ProviderProps,
} from './types'
import { SearchCountType, SearchDateType, SearchType, TabType, WorkType } from '../types'
import { AstroSearchParam, Project, SearchMethod } from '@rocket/types'
import { SearchLayerSectionList } from '../SearchLayerView'

// import { DatePicker, SelectedDate } from '@rocket-molecules/date'
import { DatePicker, type SelectedDate } from '@rui/molecules'
import { uuid } from '@rui/utils'

import { ClickOutside, JSONBig, addItem, moment, replaceItemAtIndex } from '@rocket-mono/libs'
import { LayoutChangeEvent, LayoutRectangle, Platform, View } from 'react-native'

const SearchScreenProvider = ({
  astro,
  defaultTab = 'ALL',
  defaultProjectId,
  currentUser,
  projectList,
  i18n,
  subscribe,
  children,
  fallback,
  ...props
}: ProviderProps) => {
  const [tab, setTab] = useState<TabType>(defaultTab)
  const [requestRandomKey, setRequestRandomKey] = useState('')

  const [currentProject, setCurrentProject] = useState<Project>()

  const [searchType, setSearchType] = useState<SearchType>('WORK')
  const [searchCount, setSearchCount] = useState<SearchCountType>({})
  const onChangeSearchCount = useCallback((key: string, count?: number) => {
    setSearchCount((prev) => {
      const newCount = { ...prev }
      newCount[key] = count
      return newCount
    })
  }, [])
  const [searchProjectId, setSearchProjectId] = useState<string[]>([])
  const [searchDate, setSearchDate] = useState<SearchDateType>()
  const [searchPayload, setSearchPayload] = useState<SearchPayloadType>()

  const [searchWorkState, setSearchWorkState] = useState<SearchStateType>('ready')
  const [searchChatState, setSearchChatState] = useState<SearchStateType>('ready')
  const [searchMessageState, setSearchMessageState] = useState<SearchStateType>('ready')
  const [searchCardState, setSearchCardState] = useState<SearchStateType>('ready')
  const [searchFileState, setSearchFileState] = useState<SearchStateType>('ready')

  const [searchWorkList, setSearchWorkList] = useState<IndexedProjectType[]>()
  const [searchChatList, setSearchChatList] = useState<IndexedBoardType[]>()
  const [searchMessageList, setSearchMessageList] = useState<IndexedChatMessageType[]>()
  const [searchCardList, setSearchCardList] = useState<SearchCardType[]>()
  const [searchFileList, setSearchFileList] = useState<SearchFileType[]>()
  const resetSearchList = useCallback(() => {
    setSearchWorkList(undefined)
    setSearchChatList(undefined)
    setSearchMessageList(undefined)
    setSearchCardList(undefined)
    setSearchFileList(undefined)
  }, [])

  const searchAllState = useMemo<SearchStateType>(() => {
    const state = [searchWorkState, searchChatState, searchMessageState, searchCardState, searchFileState]
    if (state.filter((o) => o === 'ready').length === state.length) return 'ready'
    if (state.filter((o) => o === 'searching').length > 0) return 'searching'
    else return 'done'
  }, [searchWorkState, searchChatState, searchMessageState, searchCardState, searchFileState])

  const onSearch = useCallback(
    (searchPayload, paramDate?: SearchDateType) => {
      console.log('onSearch', searchPayload)
      const { searchType, searchKeyword, searchProjectId } = searchPayload
      resetSearchList()
      setSearchPayload(searchPayload)

      let projectIds = searchProjectId.map((o) => Number(o))
      setSearchProjectId(searchProjectId)
      if (searchProjectId.length === 0) {
        if (searchType === 'WORKSPACE') {
          setCurrentProject(undefined)
        } else if (searchType === 'WORK' && defaultProjectId && projectList) {
          projectIds = [Number(defaultProjectId)]
          setCurrentProject(projectList.find(({ id }) => id === defaultProjectId))
        }
      }

      setSearchWorkState('searching')
      setSearchChatState('searching')
      setSearchCardState('searching')
      setSearchMessageState('searching')
      setSearchFileState('searching')

      const payload: AstroSearchParam = {
        method: 'ALL',
        requestRandomKey,
        requesterId: String(currentUser.id),
        keyword: searchKeyword,
        projectIds,
        from: 0,
        size: 20,
      }

      const payloadDate = paramDate ?? searchDate
      if (payloadDate && payloadDate.startDate && payloadDate.endDate) {
        payload.startDateTime = payloadDate.startDate
        payload.endDateTime = payloadDate.endDate
      }

      astro.search(payload).catch((err) => {
        console.log('err', err)
      })
    },
    [requestRandomKey, currentUser, searchDate, searchProjectId, defaultProjectId, projectList],
  )

  const [isSearch, setIsSearch] = useState(false)
  const [searchList, setSearchList] = useState<SearchMethod[]>([])
  useEffect(() => {
    if (!isSearch && searchPayload && searchList.length > 0) {
      setIsSearch(true)
      const list = [...searchList]
      const method = list.pop()
      setSearchList(list)
      console.log('searchList', searchList, list, method)

      if (method) {
        const { searchType, searchKeyword, searchProjectId } = searchPayload

        let projectIds = searchProjectId.map((o) => Number(o))
        if (searchProjectId.length === 0) {
          if (searchType === 'WORKSPACE') {
            setCurrentProject(undefined)
          } else if (searchType === 'WORK' && defaultProjectId && projectList) {
            projectIds = [Number(defaultProjectId)]
            setCurrentProject(projectList.find(({ id }) => id === defaultProjectId))
          }
        }
        let from = 0

        if (method === 'PROJECT') {
          from = searchWorkList?.length ?? 0
          setSearchWorkState('searching')
        }
        if (method === 'BOARD') {
          from = searchChatList?.length ?? 0
          setSearchChatState('searching')
        }
        if (method === 'CARD') {
          from = searchCardList?.length ?? 0
          setSearchCardState('searching')
        }
        if (method === 'CHAT_MESSAGE') {
          from = searchMessageList?.length ?? 0
          setSearchMessageState('searching')
        }
        if (method === 'FILE') {
          from = searchFileList?.length ?? 0
          setSearchFileState('searching')
        }

        const payload: AstroSearchParam = {
          method,
          requestRandomKey,
          requesterId: String(currentUser.id),
          keyword: searchKeyword,
          projectIds,
          from,
          size: 20,
        }

        const payloadDate = searchDate
        if (payloadDate && payloadDate.startDate && payloadDate.endDate) {
          payload.startDateTime = payloadDate.startDate
          payload.endDateTime = payloadDate.endDate
        }

        astro
          .search(payload)
          .catch((err) => {
            console.log('err', err)
          })
          .finally(() => {
            setIsSearch(false)
          })
      }
    }
  }, [
    requestRandomKey,
    isSearch,
    searchList,
    searchPayload,
    searchWorkList,
    searchChatList,
    searchMessageList,
    searchCardList,
    searchFileList,
  ])

  const onSearchNext = useCallback((method: SearchMethod, _page: number) => {
    // console.log('onSearchNext', method)
    setSearchList((prev) => {
      const idx = prev.findIndex((o) => o === method)
      return idx < 0 ? [...prev, method] : replaceItemAtIndex(prev, idx, method)
    })
  }, [])

  const onSearchClear = useCallback(() => {
    setSearchCount({})
    setSearchWorkState('ready')
    setSearchChatState('ready')
    setSearchMessageState('ready')
    setSearchCardState('ready')
    setSearchFileState('ready')

    setSearchPayload(undefined)
    setSearchProjectId([])
    resetSearchList()
  }, [resetSearchList])

  const doneSearchState = useCallback(() => {
    setSearchWorkState('done')
    setSearchChatState('done')
    setSearchMessageState('done')
    setSearchCardState('done')
    setSearchFileState('done')
  }, [])

  const onChangeDate = useCallback(
    (date) => {
      setSearchDate(date)
      if (searchPayload) onSearch(searchPayload, date)
    },
    [searchPayload, setSearchDate, onSearch],
  )

  const noContents = useMemo(() => {
    if (
      searchAllState === 'done' &&
      searchWorkList === undefined &&
      searchChatList === undefined &&
      searchMessageList === undefined &&
      searchCardList === undefined &&
      searchFileList === undefined
    )
      return true

    if (!searchWorkList || !searchChatList || !searchMessageList || !searchCardList || !searchFileList) return false

    return (
      searchWorkList.length +
        searchChatList.length +
        searchMessageList.length +
        searchCardList.length +
        searchFileList.length ===
      0
    )
  }, [searchAllState, searchWorkList, searchChatList, searchMessageList, searchCardList, searchFileList])

  const searchFieldData: SearchLayerSectionList[] = useMemo(() => {
    if (projectList === undefined) return []
    return [
      {
        key: '1',
        title: 'My Workspace',
        isFold: false,
        data: projectList.map(({ id, title, type }) => ({
          id,
          title,
          type: type.code as WorkType,
          isSelected: searchProjectId.includes(id),
        })),
      },
    ]
  }, [projectList, searchProjectId])

  const projectNameList: string[] = useMemo(() => {
    if (searchProjectId.length > 0)
      return searchFieldData
        .flatMap(({ data }) => data)
        .filter(({ id }) => searchProjectId.includes(id))
        .map(({ title }) => title)

    if (defaultProjectId !== undefined && searchType === 'WORK') return [i18n.t('search.searchbar.inthiswork')]
    return []
  }, [currentProject, searchProjectId, searchFieldData])

  console.log('projectNameList', searchProjectId, projectNameList, searchFieldData)

  useEffect(() => {
    setTab(defaultTab)
  }, [defaultTab])

  useEffect(() => {
    setSearchType(defaultProjectId === undefined ? 'WORKSPACE' : 'WORK')
  }, [defaultProjectId])

  useEffect(() => {
    if (searchType === 'WORKSPACE') {
      setSearchProjectId([])
    } else if (searchType === 'WORK') {
      setSearchProjectId([])
    }
  }, [searchType])

  const [visibleDatePicker, setVisibleDatePicker] = useState(false)
  const [layoutSize, setLayoutSize] = useState<LayoutRectangle>()

  const [startDate, setStartDate] = useState<SelectedDate>(null)
  const [endDate, setEndDate] = useState<SelectedDate>(null)

  const pickerTop = useMemo(() => {
    console.log('layoutSize', layoutSize)
    return Platform.OS === 'web' ? (layoutSize ? layoutSize.x + layoutSize.height : 0) : 48
  }, [layoutSize])
  const resetCalendar = useCallback(() => {
    setStartDate(null)
    setEndDate(null)
    onChangeDate({ startDate: null, endDate: null })
  }, [])

  const onClickOutside = useCallback(() => {
    setVisibleDatePicker(false)
    onChangeDate({ startDate, endDate })
  }, [startDate, endDate])

  const onLayoutCalendar = useCallback((e: LayoutChangeEvent) => {
    setLayoutSize(e.nativeEvent.layout)
  }, [])

  useEffect(() => {
    console.log('searchAllState', searchAllState)
    let t

    if (searchAllState === 'searching') {
      t = setTimeout(() => {
        doneSearchState()
      }, 3000)
    }

    return () => {
      clearTimeout(t)
    }
  }, [searchAllState, doneSearchState])

  useEffect(() => {
    setRequestRandomKey(uuid())

    return () => {
      setRequestRandomKey('')
    }
  }, [])

  subscribe &&
    subscribe(requestRandomKey ? `/subscribe/search/${requestRandomKey}` : '', ({ body }) => {
      const { indexName, data, isLast } = JSONBig.parse(body)
      console.log('onSearchNext', 'subscribe', JSONBig.parse(body))

      if (indexName === 'projects') {
        if (data)
          setSearchWorkList((prev) => {
            if (prev === undefined) return [data]
            const idx = prev.findIndex(({ id }) => String(id) === String(data.id))
            return idx < 0 ? addItem(prev, data) : replaceItemAtIndex(prev, idx, data)
          })

        if (isLast) {
          setSearchWorkState(!!data ? 'done' : 'last')
        }
      } else if (indexName === 'boards') {
        if (data)
          setSearchChatList((prev) => {
            if (prev === undefined) return [data]

            const idx = prev.findIndex(({ id }) => String(id) === String(data.id))
            return idx < 0 ? addItem(prev, data) : replaceItemAtIndex(prev, idx, data)
          })
        if (isLast) {
          setTimeout(() => setSearchChatState(data ? 'done' : 'last'), 50)
        }
      } else if (indexName === 'chat_messages') {
        if (data)
          setSearchMessageList((prev) => {
            if (prev === undefined) return [data]

            const idx = prev.findIndex(({ id }) => String(id) === String(data.id))
            return idx < 0 ? addItem(prev, data) : replaceItemAtIndex(prev, idx, data)
          })
        if (isLast) {
          setTimeout(() => setSearchMessageState(data ? 'done' : 'last'), 50)
        }
      } else if (['cards, todos', 'cards', 'todos'].includes(indexName)) {
        if (data)
          setSearchCardList((prev) => {
            const item = { indexName, data }
            if (prev === undefined) return [item]

            const idx = prev.findIndex((o) => String(o.data.id) === String(data.id))
            return idx < 0 ? addItem(prev, item) : replaceItemAtIndex(prev, idx, item)
          })
        if (isLast) {
          setTimeout(() => setSearchCardState(data ? 'done' : 'last'), 50)
        }
      } else if (['chat_files, files', 'chat_files', 'files'].includes(indexName)) {
        if (data)
          setSearchFileList((prev) => {
            const item = { indexName, data }
            if (prev === undefined) return [item]

            const idx = prev.findIndex((o) => String(o.data.id) === String(data.id))
            return idx < 0 ? addItem(prev, item) : replaceItemAtIndex(prev, idx, item)
          })
        if (isLast) {
          setTimeout(() => setSearchFileState(data ? 'done' : 'last'), 50)
        }
      }
    })

  return (
    <Context.Provider
      value={{
        defaultProjectId,
        astro,
        tab,
        searchDate,
        searchType,
        searchCount,
        searchAllState,
        searchProjectId,
        searchFieldData,
        searchWorkState,
        searchWorkList,
        searchChatState,
        searchChatList,
        searchMessageState,
        searchMessageList,
        searchCardState,
        searchCardList,
        searchFileState,
        searchFileList,
        projectNameList,
        noContents,
        onChangeTab: setTab,
        onChangeSearchType: setSearchType,
        onChangeSearchCount,
        onChangeSearchProjectId: setSearchProjectId,
        onSearch,
        onSearchNext,
        onSearchClear,
        onChangeDate,
        resetCalendar,
        onChangeVisibleCalendar: setVisibleDatePicker,
        onLayoutCalendar,
        ...props,
      }}
    >
      <I18nextProvider i18n={i18n}>
        <>
          {children}

          {visibleDatePicker && (
            <View style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, zIndex: 20 }}>
              <ClickOutside onClickOutside={onClickOutside}>
                <View
                  style={{
                    position: 'absolute',
                    zIndex: 100,
                    right: 8,
                    top: pickerTop,
                  }}
                >
                  <DatePicker
                    i18n={i18n}
                    type="range"
                    initialStart={
                      searchDate && searchDate.startDate ? moment(searchDate.startDate).format('YYYY-MM-DD') : undefined
                    }
                    initialEnd={
                      searchDate && searchDate.endDate ? moment(searchDate.endDate).format('YYYY-MM-DD') : undefined
                    }
                    onChangeStart={setStartDate}
                    onChangeEnd={setEndDate}
                    onPressDelete={resetCalendar}
                  />
                </View>
              </ClickOutside>
            </View>
          )}
        </>
      </I18nextProvider>
    </Context.Provider>
  )
}

export const useSearchScreen = () => {
  const context = useContext<ContextProps | undefined>(Context)

  if (context === undefined) throw new Error('There must be a SearchScreenProvider as Ancestor of all Hooks and HOCs')

  return context
}

export default SearchScreenProvider
