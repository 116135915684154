import { COLOR } from '@rocket-mono/libs'
import { Avatar } from '@rui/atoms'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TextInput, View } from 'react-native'
import Input from '../Input/index.web'

import type {
  TextInputCompositionEvent,
  TextInputFocusEvent,
  TextInputFormEvent,
  TextInputKeyboardEvent,
} from '../RichTextInput/types.web'
import { useMention } from '../hooks/useMention.web'
import { clearTextInput, getTextFromFormEvent, getTextFromTextInput } from '../utils/input.web'
import { getCurrentCaretPosition, isDirectionalKey, restoreCaretPosition } from '../utils/keyboard.web'

interface WorkCardDiscussionInputType {
  bottomOffset: number
  defaultValue?: string
  profileUrl: string
  isArchive?: boolean
  onText?: (text: string) => void
  onSubmit?: (text: string) => void
  onFileupload?: () => void
  onSend?: () => void
}
const WorkCardDiscussionInput = React.forwardRef(
  (
    {
      bottomOffset,
      // defaultValue = '',
      profileUrl,
      isArchive = false,
      onText,
      onSubmit,
      onFileupload,
      onSend,
    }: WorkCardDiscussionInputType,
    ref,
  ): JSX.Element => {
    const { t } = useTranslation()

    const inputRef = React.useRef<HTMLDivElement>(null)

    React.useImperativeHandle(ref, () => {
      return {
        clear: () => {
          if (inputRef.current) inputRef.current.innerText = ''
          onText && onText('')
          clearTextInput(inputRef)
        },
      }
    })

    const onSubmitHandler = React.useCallback(() => {
      console.log('onSubmitHandler')
      if (inputRef !== null) {
        const text = getTextFromTextInput(inputRef)
        if (onSubmit && text) {
          if (inputRef.current) inputRef.current.innerText = ''
          onSubmit(text)
          clearTextInput(inputRef)
        }
      }
    }, [onSubmit, inputRef])

    const onSendHandler = React.useCallback(() => {
      console.log('onSendHandler')
      if (onSend) {
        if (inputRef.current) inputRef.current.innerText = ''
        onSend()
      }
    }, [onSend, inputRef])

    // FIXME: src/components/ChatInput/Input/index.web.tsx 로부터 복사된 코드
    const {
      startMention,
      closeMention,
      finishMention,
      mentionListOpen,
      forwardKeyEvent,
      forwardFormEvent,
      forwardCompositionEvent,
      forwardBlurEvent,
      mentionList,
      state: mentionState,
      matched,
      startMatching,
    } = useMention()

    const onKeyPress = React.useCallback(
      (e: React.KeyboardEvent<TextInput>) => {
        const { keyCode, altKey, ctrlKey, shiftKey, metaKey } = e.nativeEvent
        if (e.key == '@' && !mentionListOpen) {
          if (inputRef) startMention('', mentionList.map(({ name }) => name) ?? [], inputRef)
        }

        if (keyCode === 13 && !altKey && !ctrlKey && !shiftKey && !metaKey) {
          if (mentionListOpen) {
            closeMention()
          } else {
            onSubmitHandler()
          }
          e.preventDefault()
        }
      },
      [mentionListOpen, startMention, mentionList, closeMention, onSubmitHandler],
    )

    React.useEffect(() => {
      if (!inputRef.current) return

      if (mentionState === 'start') {
        startMatching()
      } else if (mentionState === 'matched') {
        const insertion = getCurrentCaretPosition(inputRef.current)

        const htmlDivCurrent = inputRef.current as unknown as HTMLDivElement
        htmlDivCurrent.innerText =
          htmlDivCurrent.innerText.substring(0, insertion) +
          matched[0].name +
          '\u00A0' +
          htmlDivCurrent.innerText.substring(insertion)

        restoreCaretPosition(inputRef.current, insertion + matched[0].name.length + 1)

        // insert matched[0] to text at lastCaretPosition
        // if (onChangeText) onChangeText(ref.current.innerText)

        closeMention()
      } else if (mentionState === 'finish') {
        // insert matched[0] to text at lastCaretPosition
        finishMention()
      }
    }, [closeMention, finishMention, matched, mentionState, startMatching])

    return (
      <View style={[styles.container, { bottom: bottomOffset }]}>
        <Avatar profileUrl={profileUrl} size="small" />
        <View style={styles.inputArea}>
          <Input
            inputRef={inputRef}
            placeholder={isArchive ? t('discussion.archive') : t('discussion.enter')}
            editable={!isArchive}
            onEnterSubmit={onSubmitHandler}
            height={34}
            blurOnSubmit={false}
            rightIcon={
              onFileupload
                ? {
                    name: 'cloud-upload-o',
                    color: 'mono.paleBlack',
                    onPress: onFileupload,
                  }
                : undefined
            }
            rightIconSize={16}
            rightIconStyle={styles.rightIcon}
            rightIcon2={
              onSend
                ? {
                    name: 'send',
                    color: 'mono.paleBlack',
                    onPress: onSendHandler,
                  }
                : undefined
            }
            rightIconSize2={18}
            rightIconStyle2={styles.rightIcon2}
            onKeyPress={(e) => {
              const keyboardEvent = e as React.KeyboardEvent<TextInput>
              onKeyPress(keyboardEvent)
            }}
            onKeyDown={(e) => {
              const keyboardEvent = e as React.KeyboardEvent<TextInput>
              if (mentionListOpen && isDirectionalKey(keyboardEvent.code)) {
                keyboardEvent.preventDefault()
              }
            }}
            onKeyUp={(e) => {
              const keyboardEvent = e as TextInputKeyboardEvent
              if (mentionListOpen) {
                forwardKeyEvent(keyboardEvent)
              }
            }}
            onBlur={(e) => {
              const focusEvent = e as TextInputFocusEvent
              if (mentionListOpen) {
                forwardBlurEvent(focusEvent)
              }
            }}
            onInput={(e) => {
              const formEvent = e as TextInputFormEvent
              if (mentionListOpen) {
                forwardFormEvent(formEvent)
              } else {
                if (onText) {
                  onText(getTextFromFormEvent(formEvent))
                }
              }
            }}
            onCompositionUpdate={(e) => {
              const compositionEvent = e as TextInputCompositionEvent
              // console.debug('onCompositionUpdate', compositionEvent)
              // CJK IME인 경우 IME에 의해 문자를 입력중인 경우, 키 입력에 대해 onCompositionUpdate 이벤트가 발생함
              if (mentionListOpen) {
                // NOTE: composition 시에는 키 입력을 모두 포워딩한다
                forwardCompositionEvent(compositionEvent, true)
              }
            }}
            onCompositionEnd={(e) => {
              const compositionEvent = e as TextInputCompositionEvent
              // console.debug('onCompositionEnd', compositionEvent)
              if (mentionListOpen) {
                // NOTE: composition 시에는 키 입력을 모두 포워딩한다
                forwardCompositionEvent(compositionEvent, false)
              }
            }}
          />
        </View>
      </View>
    )
  },
)

export default WorkCardDiscussionInput

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    borderTopWidth: 1,
    borderColor: COLOR.gray.g150,
    backgroundColor: COLOR.mono.white,
    padding: 8,
  },
  inputArea: {
    flex: 1,
    marginLeft: 8,
  },
  rightIcon: {
    height: 28,
    width: 28,
    backgroundColor: COLOR.mono.pale,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 10,
    paddingBottom: 2,
  },
  rightIcon2: {
    paddingBottom: 2,
  },
})
