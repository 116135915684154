import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FlatList, View } from 'react-native'

import {
  ActivitySearchList,
  ActivitySearchItemMessage,
  ActivitySearchItemMessageProps,
  ActivitySearchSectionSortMessage,
} from '@rocket-molecules/activity'

import SearchIcon from '../../../components/SearchIcon'

import { ResultViewProps } from './types'
import { DropdownItemProps } from '@rocket/atoms'
import { SearchResultSkeleton } from './skeleton'
import { useTranslation } from 'react-i18next'
import { useSearchScreen } from '../../provider'
import { IndexedChatMessageType } from '../../types'

interface Props extends ResultViewProps {
  list?: IndexedChatMessageType[]
}

export const SearchResultMessageView: React.FC<Props> = ({
  defaultCount,
  searchState,
  isFilter = false,
  list = [],
}) => {
  const { t } = useTranslation()
  const { cdnUrl, onSearchNext, onChangeTab, openChat, onChangeSearchCount } = useSearchScreen()

  const convert = useCallback(
    (value: IndexedChatMessageType): ActivitySearchItemMessageProps => {
      let type: 'BOARD' | 'DIRECT' | 'ME' | 'DISCUSS' = 'BOARD'
      let isMe = false
      if (value.boardRoomType === 'M') isMe = true
      if (value.boardRoomType === 'D') type = 'DIRECT'
      if (value.boardIsDiscussionRoom) type = 'DISCUSS'
      const profile = `${cdnUrl}/images/profile/${value.profile}`

      const date = t('format.date.LL', { date: new Date(value.createdAt) })

      return {
        work: { type: value.projectTypeCode, name: value.projectTitle },
        board: {
          color: value.boardColor,
          count: value.boardAttenderCount,
          name: isMe ? t('screen.chats.tome') : type === 'DIRECT' ? value.username : value.boardRoomName,
          type,
        },
        message: { message: value.message, date },
        user: { id: String(value.userId), name: value.username, profile },
        onPress: () => {
          console.log('convertMessage:onPress', value)
          const payload = { channelRoomId: value.boardRoomId, messageId: value.id }
          openChat(payload)
        },
      }
    },
    [t],
  )

  const [showCount, setShowCount] = useState<number | undefined>(defaultCount)
  const [searchBoardType, setSearchBoardType] = useState<string>('ALL')
  const [searchMember, setSearchMember] = useState<string[]>([])

  const filteredList = useMemo(
    () =>
      list
        .map(convert)
        ?.filter(({ board }) => {
          if (searchBoardType === 'ALL') return true
          if (board && searchBoardType === 'MESSAGE') return ['DIRECT', 'BOARD'].includes(board.type)
          if (board && searchBoardType === 'DISCUSS') return ['DISCUSS'].includes(board.type)
          return false
        })
        .filter(({ user }) => {
          if (searchMember.length === 0) return true
          if (user && searchMember.length > 0) return searchMember.includes(user.id)
          return false
        }),
    [list, searchBoardType, searchMember],
  )

  useEffect(() => {
    console.log('filteredList-message', filteredList)
    onChangeSearchCount('message', filteredList.length)
  }, [onChangeSearchCount, filteredList.length])

  const searchMemberList = useMemo(() => {
    if (!list) return []

    const users: DropdownItemProps[] = []

    list.map(convert).map(({ user }) => {
      const is = users.filter(({ code }) => user && code === user.id).length > 0
      if (!is && user !== undefined) users.push({ code: user.id, label: user.name, beforeProfileImage: user.profile })
    })

    return users
  }, [list, convert])

  const isMore = useMemo(() => {
    if (showCount === undefined) return false
    return (filteredList?.length ?? 0) > showCount
  }, [showCount, filteredList])

  const handlePressMore = useCallback(() => {
    onChangeTab('CHAT_MESSAGE')
  }, [])

  const [page, setPage] = useState(0)
  useEffect(() => {
    console.log('chat_messages, page', page)
    if (page > 0) {
      onSearchNext('CHAT_MESSAGE', page)
    }
  }, [page, onSearchNext])

  return (
    <ActivitySearchList type="MESSAGE" onPressMore={isMore ? handlePressMore : undefined}>
      <View style={{ zIndex: 10 }}>
        {isFilter && (
          <View
          // style={['done', 'last'].includes(searchState) && isFilter && { height: 100 }}
          >
            <ActivitySearchSectionSortMessage
              defaultIndex={0}
              onChangeCode={setSearchBoardType}
              isSearchMember={searchMember.length > 0}
              searchMemberList={searchMemberList}
              onChangeMember={setSearchMember}
            />
          </View>
        )}
      </View>

      <FlatList
        scrollEnabled={isFilter}
        contentContainerStyle={[!isFilter && { flex: 1 }, isFilter && { paddingBottom: 24 }]}
        data={filteredList.slice(0, showCount)}
        renderItem={({ item }) => {
          return <ActivitySearchItemMessage {...item} />
        }}
        onEndReached={() => {
          if (isFilter && searchState === 'done') setPage((prev) => prev + 1)
        }}
        onEndReachedThreshold={0.2}
        ListEmptyComponent={
          <>
            {searchState === 'ready' && (
              <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <SearchIcon type={'searching'} textSize={'medium'} />
              </View>
            )}
            {['done', 'last'].includes(searchState) && isFilter && (
              <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <SearchIcon type="message" text={t('nocontentsicon.search.text')} />
              </View>
            )}
          </>
        }
        ListFooterComponent={
          <>
            {searchState === 'searching' && (
              <SearchResultSkeleton filteredList={filteredList} defaultCount={defaultCount} searchState={searchState}>
                <ActivitySearchItemMessage />
              </SearchResultSkeleton>
            )}
          </>
        }
      />
    </ActivitySearchList>
  )
}
