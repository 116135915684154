import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FlatList, View } from 'react-native'

import { ActivitySearchList, ActivitySearchItemCard, ActivitySearchItemCardProps } from '@rocket-molecules/activity'

import { ResultViewProps } from './types'
import { ActivitySearchSectionSortCard } from '@rocket-molecules/activity'
import { SearchResultSkeleton } from './skeleton'
import { useTranslation } from 'react-i18next'
import { useSearchScreen } from '../../provider'
import SearchIcon from '../../../components/SearchIcon'
import { IndexedCardType, IndexedTodoType, SearchCardType } from '../../types'
import { CardType } from '@rocket/types'

interface Props extends ResultViewProps {
  list?: SearchCardType[]
}

export const SearchResultCardView: React.FC<Props> = ({ defaultCount, searchState, isFilter = false, list = [] }) => {
  const { t } = useTranslation()
  const { onSearchNext, onChangeTab, openCard, onChangeSearchCount } = useSearchScreen()

  const convert = useCallback(
    (value: SearchCardType): ActivitySearchItemCardProps => {
      let boardType: 'BOARD' | 'DIRECT' | 'DISCUSS' = 'BOARD'
      if (value.data.boardRoomType === 'D') boardType = 'DIRECT'

      const work = { type: value.data.projectTypeCode, name: value.data.projectTitle }
      const board = {
        color: value.data.boardColor,
        name: value.data.boardRoomName,
        type: boardType,
      }

      if (value.indexName === 'todos') {
        const data = value.data as IndexedTodoType
        const parseContent = data.content?.replace(/<[^>]*>?/g, '')

        return {
          work,
          board,
          todo: { title: parseContent, checked: data.isDone },

          onPress: () => {
            const payload = {
              isWindow: true,
              isEdit: false,
              isFold: false,
              cardId: String(data.relatedDomainId),
              cardType: 'MISSION' as CardType,
              channelId: String(data.boardId),
              projectId: String(data.projectId),
            }
            openCard(payload)
          },
        }
      } else {
        const data = value.data as IndexedCardType
        const { assigneeCount, type, title, content, fromPeriodDate, toPeriodDate } = data
        const parseContent = content?.replace(/<[^>]*>?/g, '')

        let cardData

        if (fromPeriodDate && toPeriodDate) {
          cardData = `${t('format.date.L', { date: new Date(fromPeriodDate) })} ~ ${t('format.date.L', {
            date: new Date(toPeriodDate),
          })}`
        }
        return {
          work,
          board,
          card: { assigneeCount, type, content: parseContent, title, data: cardData },

          onPress: () => {
            const payload = {
              isWindow: true,
              isEdit: false,
              isFold: false,
              cardId: String(data.id),
              cardType: type as CardType,
              channelId: String(data.boardId),
              projectId: String(data.projectId),
            }

            openCard(payload)
          },
        }
      }
    },
    [openCard],
  )

  const [showCount, setShowCount] = useState<number | undefined>(defaultCount)
  const [searchCardType, setSearchCardType] = useState<string>('ALL')
  const filteredList = useMemo(
    () =>
      list.map(convert).filter(({ card, todo }) => {
        if (searchCardType === 'ALL') return true
        if (todo && searchCardType === 'MISSION') return true
        if (card && searchCardType === 'MISSION') return ['MISSION'].includes(card.type)
        if (card && searchCardType === 'MEETING') return ['ATTEND', 'MEETING'].includes(card.type)
        if (card && searchCardType === 'COLLECTION') return ['COLLECTION'].includes(card.type)
        return false
      }),
    [list, searchCardType, convert],
  )

  useEffect(() => {
    console.log('filteredList-card', filteredList)
    onChangeSearchCount('card', filteredList.length)
  }, [onChangeSearchCount, filteredList.length])

  const isMore = useMemo(() => {
    if (showCount === undefined) return false
    return (filteredList?.length ?? 0) > showCount
  }, [showCount, filteredList])

  const handlePressMore = useCallback(() => {
    onChangeTab('CARD')
    setShowCount((prev) => (prev === undefined ? 2 : prev + 2))
  }, [onChangeTab])

  const [page, setPage] = useState(0)
  useEffect(() => {
    if (page > 0) {
      onSearchNext('CARD', page)
    }
  }, [page, onSearchNext])

  return (
    <ActivitySearchList type="CARD" onPressMore={isMore ? handlePressMore : undefined}>
      <View style={{ zIndex: 10 }}>
        {isFilter && (
          <View
          // style={['done', 'last'].includes(searchState) && isFilter && { height: 100 }}
          >
            <ActivitySearchSectionSortCard defaultIndex={0} onChangeCode={setSearchCardType} />
          </View>
        )}
      </View>

      <FlatList
        scrollEnabled={isFilter}
        contentContainerStyle={[!isFilter && { flex: 1 }, isFilter && { paddingBottom: 24 }]}
        data={filteredList.slice(0, showCount)}
        renderItem={({ item }) => {
          return <ActivitySearchItemCard {...item} />
        }}
        onEndReached={() => {
          if (isFilter && searchState === 'done') setPage((prev) => prev + 1)
        }}
        onEndReachedThreshold={1}
        ListEmptyComponent={
          <>
            {searchState === 'ready' && (
              <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <SearchIcon type={'searching'} textSize={'medium'} />
              </View>
            )}
            {['done', 'last'].includes(searchState) && isFilter && (
              <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <SearchIcon type="card" text={t('nocontentsicon.search.text')} />
              </View>
            )}
          </>
        }
        ListFooterComponent={
          <>
            {searchState === 'searching' && (
              <SearchResultSkeleton filteredList={filteredList} defaultCount={defaultCount} searchState={searchState}>
                <ActivitySearchItemCard />
              </SearchResultSkeleton>
            )}
          </>
        }
      />
    </ActivitySearchList>
  )
}
