import * as React from 'react'
import { LayoutChangeEvent, Pressable, Image as RNImage, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

type ImageItem = {
  id: string
  uri: string
  size: { width: number; height: number }
  headers?: { [key: string]: string }
  onPress?: () => void
}

interface Props {
  list: Omit<ImageItem, 'size'>[]
  containerStyle?: StyleProp<ViewStyle>
  scrollViewStyle?: StyleProp<ViewStyle>
}

export const ImagePreviewList = ({ list, containerStyle }: Props) => {
  const [imageList1, setImageList1] = React.useState<ImageItem[]>([])
  const [imageList2, setImageList2] = React.useState<ImageItem[]>([])
  const [layoutWidth, setLayoutWidth] = React.useState<number>(0)

  const onLayout = React.useCallback((e: LayoutChangeEvent) => {
    setLayoutWidth(e.nativeEvent.layout.width)
  }, [])

  const resize = React.useCallback((layoutWidth: number, imageList: ImageItem[]) => {
    const minHeight = Math.min(...imageList.map((o) => o.size.height))
    const containerWidth = layoutWidth - (imageList.length - 1) * 20

    const list = imageList.map((o) => ({
      ...o,
      size: {
        width: (minHeight / o.size.height) * o.size.width,
        height: minHeight,
      },
    }))

    const imageWidth = list.reduce((a, b) => a + b.size.width, 0)
    const widthRatio = containerWidth / imageWidth

    return list.map((o) => {
      const width = o.size.width * widthRatio
      const height = (width / o.size.width) * o.size.height
      return {
        ...o,
        size: {
          width,
          height,
        },
      }
    })
  }, [])

  const previewImageList1 = React.useMemo<ImageItem[]>(() => {
    return resize(layoutWidth, imageList1)
  }, [layoutWidth, imageList1])

  const previewImageList2 = React.useMemo<ImageItem[]>(() => {
    return resize(layoutWidth, imageList2)
  }, [layoutWidth, imageList2])

  React.useEffect(() => {
    Promise.all(
      list.map((o) => {
        return new Promise<ImageItem>((resolve) => {
          RNImage.getSize(o.uri, (width, height) => {
            console.log(o.uri, width, height)
            resolve({ ...o, size: { width, height } })
          })
        })
      }),
    ).then((res) => {
      if (res.length === 4) {
        setImageList1(res.splice(0, 2))
        setImageList2(res)
      } else if (res.length === 5) {
        setImageList1(res.splice(0, 3))
        setImageList2(res)
      } else {
        setImageList1(res)
      }
    })
  }, [list])

  return (
    <View onLayout={onLayout} style={[styles.container, containerStyle]}>
      {previewImageList1.map(({ id, onPress, uri, headers, size }, index) => {
        return (
          <Pressable key={id} onPress={onPress}>
            <RNImage
              style={{ marginLeft: index > 0 ? 20 : 0, ...size }}
              source={{
                uri,
                headers,
              }}
            />
          </Pressable>
        )
      })}
      {previewImageList2.map(({ id, onPress, uri, headers, size }, index) => {
        return (
          <Pressable key={id} onPress={onPress}>
            <RNImage style={{ marginTop: 10, marginLeft: index > 0 ? 20 : 0, ...size }} source={{ uri, headers }} />
          </Pressable>
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
})
