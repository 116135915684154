import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { IColors, getRealColor } from '@rocket-mono/libs'
import * as React from 'react'

export interface Props {
  iconName: IconProp
  size?: number
  color?: IColors
  style?: React.CSSProperties
  nativeSize?: number
  ifSearchSize?: number
}

const FAIconNative: React.FC<Props> = ({ iconName, color, nativeSize }) => {
  return <FontAwesomeIcon icon={iconName} size={nativeSize} color={getRealColor(color ?? 'mono.black')} />
}

export default FAIconNative
