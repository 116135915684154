import { useCurrentUser, useWorkCard } from '@rocket-mono/providers'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, Pressable, StyleSheet, TextInput, View } from 'react-native'
import type { CardGatheringListType } from '.'
import CardGatheringItem, { FileItemType } from '../CardGatheringItem'
import SubmitButton from './SubmitButton'
import SummaryLabel from './SummaryLabel'

import { Icon } from '@rocket-atoms/icon'
import { COLOR, FONT } from '@rocket-mono/libs'
import { RichText } from '@rui/rich-text-field'
import { FileField } from '@rui/molecules'

export type CardLinkParams = {
  cardNo: string
  channelNo: string
}

interface ItemProps {
  isAnonymous: boolean
  onPressAnonymous: () => void
  item: CardGatheringListType
  type: 'OPINION' | 'FILE'
  onPressDownload: (file: FileItemType) => void
  onPressCardLink?: (param: CardLinkParams) => void
  onPressMention?: () => void
}

const CardItem = ({
  isAnonymous,
  onPressAnonymous,
  item,
  type,
  onPressDownload,
  onPressCardLink,
  onPressMention,
}: ItemProps) => {
  const { currentUser } = useCurrentUser()

  const [buttonActive, setButtonActive] = React.useState(false)
  const [fold, setFold] = React.useState(false)
  const [iptFocused, setIptFocused] = React.useState(false)
  const [text, setText] = React.useState('')

  const { t } = useTranslation()

  const completeTodo = false //할일 완료
  const entryDisabled = item.disabled //입력 닫기
  const { saveGatheringPiece } = useWorkCard()

  const handleFile = useCallback(
    (fileList: FileList) => {
      const fileFormData = new FormData()

      const groupId = item.items.find(({ userId }) => String(userId) === String(currentUser.id))?.groupId ?? ''

      for (let i = 0; i < fileList.length; i++) {
        const file = fileList.item(i)
        if (file) fileFormData.append('file', file)
      }

      saveGatheringPiece({
        groupId,
        collectionId: item.id,
        content: text,
        type,
        userId: String(currentUser.id),
        fileFormData,
      })
    },
    [currentUser, item],
  )

  const handlePressSubmit = useCallback(() => {
    if (text.trim()) {
      saveGatheringPiece({
        groupId: '',
        collectionId: item.id,
        content: text,
        type,
        userId: String(currentUser.id),
      }).then(() => setText(''))
    }
  }, [text, saveGatheringPiece])

  React.useEffect(() => {
    text !== '' ? setButtonActive(true) : setButtonActive(false)
  }, [text])

  console.log('item.items', item)

  return (
    <View style={styles.container}>
      <View style={styles.contents}>
        <View style={styles.gatheringTitle}>
          {/* <Text style={styles.title}>{item.title}</Text> */}
          {!!item.title ? (
            <RichText onPressCardLink={onPressCardLink} onPressMention={onPressMention}>
              {item.title}
            </RichText>
          ) : (
            <View style={{ height: 20 }} />
          )}
        </View>
        <View style={styles.entryArea}>
          {type === 'FILE' && (
            <View style={styles.entryFile}>
              <FileField
                size="SMALL"
                progress={0}
                disabled={entryDisabled}
                // disabledInfo={t('todoitem.cannotupload')}

                onLoadFile={handleFile}
                onDropFile={handleFile}
                onPasteFile={handleFile}
                onPress={
                  entryDisabled
                    ? () => {
                        console.log('disabled')
                      }
                    : isAnonymous
                    ? onPressAnonymous
                    : undefined
                }
              />
            </View>
          )}
          {type === 'OPINION' && (
            <View
              style={[
                styles.OpinionEntry,
                iptFocused && { borderColor: COLOR.gray.g500 },
                entryDisabled && { backgroundColor: COLOR.gray.g050 },
              ]}
            >
              <TextInput
                multiline
                editable={!entryDisabled && !isAnonymous}
                selectTextOnFocus={entryDisabled ? false : true}
                value={entryDisabled ? t('fileitem.notpermissionfile') : text}
                placeholder={t('card.collectunit.placeholder')}
                onChangeText={(text) => setText(text)}
                onFocus={() => {
                  if (isAnonymous) {
                    onPressAnonymous()
                  } else setIptFocused(true)
                }}
                onBlur={() => setIptFocused(false)}
                style={[
                  styles.textForm,
                  entryDisabled && {
                    color: COLOR.gray.g450,
                    ...Platform.select({
                      web: {
                        cursor: 'not-allowed',
                        paddingTop: 2,
                      },
                    }),
                  },
                ]}
              />
              {!entryDisabled && <SubmitButton active={buttonActive} onPress={handlePressSubmit} />}
            </View>
          )}
        </View>
        <View style={styles.submissionArea}>
          <Pressable style={styles.submitTab} onPress={() => setFold(!fold)}>
            <View>{/* <SummaryLabel icon={'time-o'} text={'2023/09/02 13:00'} /> */}</View>
            <View style={styles.flexBox}>
              <View style={styles.flexBox}>
                {item.targetOption === 'SPECIFY' && <SummaryLabel icon={'user-o'} text={String(item.items.length)} />}
                {item.type === 'FILE' && (
                  <SummaryLabel
                    icon={'attachment'}
                    text={String(
                      item.items
                        .filter((o) => o.type === 'FILE')
                        .reduce((prev, cur) => prev + (cur.file?.length || 0), 0),
                    )}
                  />
                )}
                {item.type === 'OPINION' && (
                  <SummaryLabel
                    icon={'message-o'}
                    text={String(item.items.filter(({ complete }) => complete).length)}
                  />
                )}
              </View>
              <View style={styles.foldButton}>
                <Icon name={fold ? 'angle-down' : 'angle-up'} size={12} />
              </View>
            </View>
          </Pressable>
          {!fold && (
            <View>
              <CardGatheringItem
                collectionId={item.id}
                list={item.items}
                isAnonymous={isAnonymous}
                onPressDownload={onPressDownload}
              />
            </View>
          )}
        </View>
      </View>
      <View
        style={[
          styles.todoGauge,
          completeTodo && {
            width: '200%', // 완료 시 풀 게이지
            backgroundColor: COLOR.todo.completeBar,
          },
        ]}
      ></View>
    </View>
  )
}

export default CardItem

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    // overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: COLOR.gray.g050,
    padding: 8,
  },
  contents: {
    zIndex: 999,
  },
  todoGauge: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    backgroundColor: COLOR.gray.g150,
    zIndex: 99,
  },
  gatheringTitle: {
    flexDirection: 'row',
    paddingVertical: 4,
    marginBottom: 6,
  },
  title: {
    flex: 1,
    ...FONT.txtSm,
    marginLeft: 8,
  },
  entryArea: {
    minHeight: 44,
    marginBottom: 8,
  },
  entryFile: { height: 60 },
  submissionArea: {
    backgroundColor: COLOR.mono.white,
    padding: 8,
    paddingTop: 4,
    borderRadius: 4,
  },
  submitTab: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  //   tabLabel: {
  //     flexDirection: 'row',
  //     alignItems: 'center',
  //   },
  //   labelText: {
  //     ...FONT.txtXs,
  //     color: COLOR.gray.g500,
  //     marginLeft: 1,
  //   },
  //   labelIcon: {
  //     marginLeft: 8,
  //   },
  foldButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    marginLeft: 4,
  },
  OpinionEntry: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    borderRadius: 4,
    padding: 8,
  },
  textForm: {
    flex: 1,
    marginRight: 12,
    minHeight: 24,
    ...Platform.select({
      web: {
        outline: 'none',
      },
      native: {
        marginBottom: 4,
      },
    }),
  },
})
