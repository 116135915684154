import React, { useCallback } from 'react'

import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import { WorkCardViewScreen } from '@rocket-mono/screen-card'

import { useCardLayout } from '../../layout'
import CardTodoDetailView from './todo-detail'

const CardView: React.FC = () => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const { projectId, cardId, cardType, cardTitle, todoId } = useCardLayout()

  const handleAuthorized = useCallback(() => {
    navigate('/error/403')
  }, [])
  const handlePressEdit = useCallback(() => {
    navigate(`/card/edit/${cardId}`)
  }, [])
  const handlePressClose = useCallback(() => {
    window.close()
  }, [])

  return (
    <>
      <Helmet>
        <title>{cardTitle ?? ''} </title>
        <meta name="description" content="여기를 눌러 카드를 확인하세요" />
        <meta property="og:title" content={cardTitle ?? ''} />
        <meta property="og:description" content="여기를 눌러 카드를 확인하세요" />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="400" />
        <meta property="og:image" content="https://work.rocket.is/assets/search-card.png" />
        <meta property="og:site_name" content="RocketWORK" />
      </Helmet>
      {todoId ? (
        <CardTodoDetailView
          window
          isFold={false}
          todoId={todoId}
          currentProjectId={projectId}
          opened={false}
          onPressBack={() => navigate(`/card/${cardId}`)}
          onPressClose={handlePressClose}
          onUnAuthorized={handleAuthorized}
        />
      ) : (
        <WorkCardViewScreen
          i18n={i18n}
          isFold={false}
          window
          currentProjectId={projectId}
          opened={false}
          cardType={cardType}
          onPressEdit={handlePressEdit}
          onPressClose={handlePressClose}
          onPressTodo={(todoId) => navigate(`/card/${cardId}?todoId=${todoId}`)}
          onUnAuthorized={handleAuthorized}
        />
      )}
    </>
  )
}

export default CardView
