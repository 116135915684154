import * as React from 'react'
import Svg, { ClipPath, Defs, G, LinearGradient, Path, Rect, Stop } from 'react-native-svg'

interface Props {
  width: number
}
const SignTopLogo: React.FC<Props> = ({ width }) => {
  return (
    <Svg width={width} height={width} viewBox="0 0 48 48" fill="none">
      <G clipPath="url(#clip0_3680_432353)">
        <Path
          d="M34.2445 48H13.7568C6.15961 48 0 41.8416 0 34.2432V13.7568C0 6.15835 6.15835 0 13.7568 0H34.2445C41.8416 0 48.0013 6.15835 48.0013 13.7568V34.2445C48.0013 41.8416 41.8429 48.0013 34.2445 48.0013V48Z"
          fill="url(#paint0_linear_3680_432353)"
        />
        <Path
          d="M11.2436 24.8394C10.9586 24.4276 10.99 23.8739 11.275 23.4784L14.6763 19.2385H11.1018C11.1018 19.2385 8.91841 21.9115 8.69744 22.1965C8.27056 22.7666 8.06466 23.4621 8.08098 24.174C8.08098 24.9813 8.33459 25.756 8.84057 26.3887C9.07786 26.6888 11.0553 29.1258 11.0553 29.1258H14.6938L11.2449 24.8394H11.2436Z"
          fill="white"
        />
        <Path
          d="M27.1736 26.3423C27.0317 26.1527 27.0782 25.8677 27.269 25.7095H27.2853C27.8553 25.2663 28.3613 24.7603 28.8196 24.2229C29.4524 23.4006 29.8001 22.3723 29.8001 21.3277C29.8001 18.2278 27.4586 16.2026 24.1691 16.2026H19.7082C18.9172 16.2026 18.3008 16.8517 18.3008 17.6264V30.6136C18.3008 31.4209 18.9336 32.0373 19.7082 32.0373H21.4961V19.0502H24.154C25.6406 19.0502 26.5107 19.983 26.5107 21.3126C26.5107 22.0559 26.1779 22.7364 25.5929 23.1796C24.8019 23.8287 24.2482 24.4138 24.1063 25.442C23.9958 26.2807 24.1227 26.9763 24.5495 27.6091C24.8345 28.0046 27.7762 32.0223 27.7762 32.0223H31.3984C31.4147 32.0386 27.3016 26.5168 27.1748 26.3435L27.1736 26.3423Z"
          fill="white"
        />
        <Path d="M35.4555 31.9206H39.2673L36.927 29.0103H33.0977L35.4555 31.9206Z" fill="white" />
        <Path
          d="M38.5404 17.1744L39.2209 16.3357H35.4555L34.1586 17.9654L33.0977 19.246H36.8944L38.5404 17.1744Z"
          fill="white"
        />
      </G>
      <Defs>
        <LinearGradient
          id="paint0_linear_3680_432353"
          x1={4.11561}
          y1={3.94361}
          x2={43.8844}
          y2={44.0576}
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset={0.02} stopColor="#151616" />
          <Stop offset={0.05} stopColor="#151616" />
          <Stop offset={1} stopColor="#111212" />
        </LinearGradient>
        <ClipPath id="clip0_3680_432353">
          <Rect width={48} height={48} fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default SignTopLogo
