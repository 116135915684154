import * as React from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

interface Props {
  x: number
  y: number
  radius: number
  dotColor: string
  style?: StyleProp<ViewStyle>
}

const getStyles = ({ x, y, radius, dotColor }: Props) => ({
  left: x,
  top: y,
  width: radius * 2,
  height: radius * 2,
  borderRadius: radius,
  backgroundColor: dotColor,
})

const Dot: React.FC<Props> = (props: Props) => <View style={[styles.container, props.style, getStyles(props)]} />

export default Dot

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
  },
})
