import React, { useState } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { ClickOutside } from '@rocket-mono/libs'
import TodoViewInput from '../TodoViewInput'
import { ListMember, SelectorUser } from '@rocket-molecules/popselector'
import { useTranslation } from 'react-i18next'
import { Tag } from '@rocket/atoms'
import { Avatar } from '@rui/atoms'
import { commonStyles } from '../style'

interface Props {
  listMember: ListMember[]
  defaultValue?: string[]
  isAnonymous: boolean
  onPressAnonymous: () => void
}

const TodoAssignessView = ({ listMember, defaultValue, isAnonymous, onPressAnonymous }: Props) => {
  const { t } = useTranslation()
  const [isSelect, setIsSelect] = useState(false)

  return (
    <View style={commonStyles.conContainer}>
      <View style={commonStyles.labelArea}>
        <Text style={commonStyles.labelText}>{t('selectpeopleincharge.guide')}</Text>
      </View>
      <View style={commonStyles.contentsArea}>
        <TodoViewInput
          placeHolder={t('selectpeopleincharge.guide')}
          onPress={isAnonymous ? onPressAnonymous : () => setIsSelect(true)}
          isChildren={defaultValue ? true : false}
        >
          <View style={styles.horibox}>
            {listMember
              ?.filter((item) => defaultValue?.includes(item.id))
              .map((item, idx) => (
                <View key={idx} style={styles.tagArea}>
                  <Tag name={item.name} size={'MEDIUM'}>
                    <Avatar size={'xxsmall'} profileUrl={item.uri} />
                  </Tag>
                </View>
              ))}
          </View>
        </TodoViewInput>
        {isSelect && (
          <ClickOutside onClickOutside={() => setIsSelect(false)}>
            <View style={styles.selectArea}>
              <SelectorUser
                listMember={listMember}
                visible={true}
                onClose={() => {
                  console.log('close')
                }}
              />
            </View>
          </ClickOutside>
        )}
      </View>
    </View>
  )
}

export default TodoAssignessView

const styles = StyleSheet.create({
  selectArea: {
    position: 'absolute',
    top: 40,
    left: 0,
  },
  horibox: {
    flexDirection: 'row',
  },
  tagArea: {
    marginRight: 4,
  },
})
