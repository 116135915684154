import React from 'react'
import { StyleSheet, View } from 'react-native'
import { SkeletonBar, SkeletonCircle, SkeletonSquare } from '../SkeletomParts'
import { COLOR } from '@rocket-mono/libs'

const SkeletonWorkflowRuleList: React.FC = () => {
  return (
    <View>
      <View style={[styles.rowFlexbox, {justifyContent: 'space-between'}]}>
        <SkeletonSquare width={'50%'} height={36} color={'gray.g100'}/>
        <SkeletonSquare width={73} height={36} />
      </View>
      
      <View style={{ marginTop: 12 }}>
        <View style={[styles.rowFlexbox, styles.box]}>
          <View style={{width:'20%'}}>
            <SkeletonSquare width={35} height={16} />
            <View style={{marginTop:24}}>
              <SkeletonSquare width={90} height={20} color={'gray.g150'}/>
              <View style={{marginTop: 10}}>
                <SkeletonSquare width={55} height={20} color={'gray.g150'}/>
              </View>
            </View>
          </View>
          <View style={{width:'64%'}}>
            <SkeletonSquare width={35} height={16} />
            <View style={{marginTop:24}}>
              <SkeletonSquare width={'70%'} height={20} color={'gray.g150'}/>
              <View style={{marginTop: 10}}>
                <SkeletonSquare width={'80%'} height={20} color={'gray.g150'}/>
              </View>
            </View>
          </View>
          <View style={{width:'16%'}}>
            <SkeletonSquare width={'40%'} height={16} />
            <View style={{marginTop:24}}>
              <SkeletonSquare width={'100%'} height={20} color={'gray.g150'}/>
              <View style={{marginTop: 10}}>
                <SkeletonSquare width={'100%'} height={20} color={'gray.g150'}/>
              </View>
            </View>
          </View>
        </View>

        <View style={[styles.rowFlexbox, styles.box, {opacity:.7}]}>
          <View style={{width:'20%'}}>
            <SkeletonSquare width={35} height={16} />
            <View style={{marginTop:24}}>
              <SkeletonSquare width={90} height={20} color={'gray.g150'}/>
              <View style={{marginTop: 10}}>
                <SkeletonSquare width={55} height={20} color={'gray.g150'}/>
              </View>
            </View>
          </View>
          <View style={{width:'64%'}}>
            <SkeletonSquare width={35} height={16} />
            <View style={{marginTop:24}}>
              <SkeletonSquare width={'70%'} height={20} color={'gray.g150'}/>
              <View style={{marginTop: 10}}>
                <SkeletonSquare width={'80%'} height={20} color={'gray.g150'}/>
                <View style={{marginTop: 4}}>
                  <SkeletonSquare width={'60%'} height={20} color={'gray.g150'}/>
                </View>
              </View>
            </View>
          </View>
          <View style={{width:'16%'}}>
            <SkeletonSquare width={'40%'} height={16} />
            <View style={{marginTop:24}}>
              <SkeletonSquare width={'100%'} height={20} color={'gray.g150'}/>
              <View style={{marginTop: 10}}>
                <SkeletonSquare width={'100%'} height={20} color={'gray.g150'}/>
              </View>
            </View>
          </View>
        </View>

        <View style={[styles.rowFlexbox, styles.box, {opacity:.5}]}>
          <View style={{width:'20%'}}>
            <SkeletonSquare width={35} height={16} />
            <View style={{marginTop:24}}>
              <SkeletonSquare width={90} height={20} color={'gray.g150'}/>
            </View>
          </View>
          <View style={{width:'64%'}}>
            <SkeletonSquare width={35} height={16} />
            <View style={{marginTop:24}}>
              <SkeletonSquare width={'60%'} height={20} color={'gray.g150'}/>
            </View>
          </View>
          <View style={{width:'16%'}}>
            <SkeletonSquare width={'40%'} height={16} />
            <View style={{marginTop:24}}>
              <SkeletonSquare width={'100%'} height={20} color={'gray.g150'}/>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default SkeletonWorkflowRuleList

const styles = StyleSheet.create({
  rowFlexbox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  box:{
    flex: 1,
    alignItems:'flex-start',
    backgroundColor: COLOR.gray.g050,
    borderRadius: 8,
    borderLeftWidth: 4,
    borderColor: COLOR.gray.g200,
    padding: 16,
    marginBottom: 12
  }
})
