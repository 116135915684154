import * as React from 'react'
import { View } from 'react-native'

import { COLOR, removeItemAtIndex } from '@rocket-mono/libs'

import Context from './context'
import type { ProviderProps } from './types'

export const ModalProvider = ({ children }: ProviderProps) => {
  const [visibleList, setVisibleList] = React.useState<string[]>([])
  const [backdropList, setBackdropList] = React.useState<string[]>([])

  const show = React.useCallback((key: string, backdrop: boolean) => {
    setVisibleList((prev) => {
      const idx = prev.findIndex((o) => o === key)
      return idx < 0 ? [...prev, key] : prev
    })
    if (backdrop)
      setBackdropList((prev) => {
        const idx = prev.findIndex((o) => o === key)
        return idx < 0 ? [...prev, key] : prev
      })
  }, [])
  const hide = React.useCallback((key: string) => {
    setVisibleList((prev) => {
      const idx = prev.findIndex((o) => o === key)
      return idx < 0 ? prev : removeItemAtIndex(prev, idx)
    })
    setBackdropList((prev) => {
      const idx = prev.findIndex((o) => o === key)
      return idx < 0 ? prev : removeItemAtIndex(prev, idx)
    })
  }, [])

  console.log('backdropList', backdropList)

  return (
    <Context.Provider value={{ visibleList, show, hide }}>
      {children}
      {backdropList.length > 0 && (
        <View
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            backgroundColor: COLOR.mono.black,
            opacity: 0.7,
            zIndex: 999,
          }}
        />
      )}
    </Context.Provider>
  )
}

export * from './hooks'
