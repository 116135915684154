import { Combobox } from '@rui/molecules'
import { View } from '@rocket-mono/foundations'
import { COLOR } from '@rocket-mono/libs'
import {
  useAstro,
  useAuth,
  useCurrentUser,
  useModalDialog,
  useSubscription,
  useWork,
  useWorkCard,
  useWorkChannel,
  useWorkFile,
} from '@rocket-mono/providers'
import { CardShare, CardShareType, Project, TodoUpdate, TodoViewPermission } from '@rocket/types'
import { useToast } from '@rui/atoms'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  Pressable,
  useWindowDimensions,
} from 'react-native'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { DiscussionListMoreButtonType } from '../../components/Discussion'
import { MentionTargetPicker } from '../../components/SendTargetPicker'
import WorkCardAuthorityModal from '../../components/WorkCardAuthorityModal'
import WorkCardDiscussionInput from '../../components/WorkCardDiscussionInput'
import WorkCardShareView from '../../components/WorkCardShareView'
import { MentionEntry, MentionProvider } from '../../components/mention'
import {
  discussChannelIdState,
  discussSelectIndexState,
  replyDiscussMessageState,
  replyDiscussSelectedIndexState,
  updateDiscussMessageState,
} from '../../recoil'
import { WorkCardCollectionScreen } from '../CardCollectionScreen'
import { WorkCardScheduleScreen } from '../CardScheduleScreen'
import { WorkCardTodoScreen } from '../CardTodoScreen'
import { WorkCardTodoDetailScreen } from '../CardTodoDetailScreen'
import DiscussionView from './discuss'
import { Props } from './types'
import { Todo } from '@rocket/types'

type AuthorityType = {
  isPublic: boolean
}
type ShareType = {
  labelIcon?: any
  label: string
  key: string
}

const CardDetailView: React.FC<Props> = ({
  currentProjectId,
  cardType,
  window,
  onPressEdit,
  shortenerHashCode,
  todoId,
  // discussionView,
  ...screenProps
}) => {
  const { t } = useTranslation()
  const { astro, option } = useAstro()
  const { currentUser } = useCurrentUser()
  const { projectList, archiveList } = useWork()
  const { fetchFile } = useWorkFile()

  const { isAnonymous, showLoginModal } = useAuth()
  const currentProject = useMemo(
    () => [...(projectList ?? []), ...(archiveList ?? [])].find(({ id }) => id === currentProjectId),
    [currentProjectId],
  )
  const { currentChannel, currentChannelMembers } = useWorkChannel()
  const { currentCard, updateCard, deleteCard, unshareCard } = useWorkCard()
  const { height: windowHeight, width: windowWidth } = useWindowDimensions()

  const [currentTodo, setCurrentTodo] = useState<Todo>()

  useEffect(() => {
    if (todoId !== undefined) astro.readTodo(todoId).then(setCurrentTodo)
  }, [todoId])

  const { show: showToastMessage } = useToast()
  const { showDialogMessage, hideDialogMessage } = useModalDialog()

  const [discussY, setDiscussY] = useState(0)
  const [visibleDiscussInput, setVisibleDiscussInput] = useState(false)
  const [discussChannelId, setDiscussChannelId] = useRecoilState(discussChannelIdState)
  const [discussMessage, setDiscussMessage] = useState('')

  const [discussMenu, setDiscussMenu] = useState<DiscussionListMoreButtonType>()

  const setDiscussSelectIndex = useSetRecoilState(discussSelectIndexState)
  const setReplyDiscussSelectIndex = useSetRecoilState(replyDiscussSelectedIndexState)
  const setUpdateDiscussMessage = useSetRecoilState(updateDiscussMessageState)
  const setReplyDiscussMessage = useSetRecoilState(replyDiscussMessageState)

  const handleScroll = useCallback(
    (e: NativeSyntheticEvent<NativeScrollEvent>) => {
      const discussCurrentY = windowHeight + e.nativeEvent.contentOffset.y - 56
      if (windowHeight < e.nativeEvent.contentSize.height) {
        if (windowWidth <= 800) setVisibleDiscussInput(discussCurrentY < discussY)
      }
      setDiscussMenu(undefined)
    },
    [windowWidth, windowHeight, discussY],
  )

  const handleDiscussViewScroll = useCallback(() => {
    setDiscussMenu(undefined)
  }, [])

  useEffect(() => {
    if (windowWidth <= 800) setVisibleDiscussInput(windowHeight - 56 < discussY)
  }, [windowWidth, windowHeight, discussY])

  const currentChannelMember = useMemo(
    () => currentChannelMembers.find(({ userId }) => userId === String(currentUser.id)),
    [currentChannelMembers, currentUser],
  )
  const currentProjectMember = useMemo(
    () => currentProject?.members.find(({ userId }) => userId === String(currentUser.id)),
    [currentProject, currentUser],
  )

  const isEditAuth = useMemo(() => {
    if (currentCard && currentCard.user && currentCard.user.no === currentUser.id) return true
    if (currentChannel?.roomType === 'D') return false
    if (currentProject?.isArchive) return false

    if (currentChannelMember === undefined && currentProjectMember === undefined) return false

    if (currentCard && currentCard.isPublic) return true
    return currentChannelMember?.isOwner || ['OWNER', 'MANAGER'].includes(currentProjectMember?.auth || '')
  }, [currentUser, currentCard, currentChannel, currentProject, currentChannelMember, currentProjectMember])

  const SHARE_LIST: { key: CardShareType; label: string }[] = [
    { key: 'BOARD_MEMBER', label: t('sharemodal.linkauthority1') },
    {
      key: 'WORK_MEMBER',
      label: t('sharemodal.linkauthority2'),
    },
    { key: 'EVERYONE', label: t('sharemodal.linkauthority3') },
  ]
  const [shareVisible, setShareVisible] = useState(false)
  const [authorityVisible, setAuthorityVisible] = useState(false)
  const shareType = useMemo(
    () =>
      SHARE_LIST.find(({ key }) => key === (currentTodo ? currentTodo.viewPermission : currentCard?.cardShare?.type))
        ?.label ?? '',
    [currentCard, currentTodo],
  )

  const shareLink = useMemo(() => {
    if (shortenerHashCode) return option.shortenerBaseUrl + '/' + shortenerHashCode
    return currentCard?.cardShare ? option.shortenerBaseUrl + '/' + currentCard.cardShare.shortUrlPath : ''
  }, [currentCard, shortenerHashCode])

  const handlePressEdit = useMemo(() => (isEditAuth ? onPressEdit : undefined), [isEditAuth, onPressEdit])

  const handlePressShare = useMemo(() => {
    if (currentProject?.isArchive) return undefined
    if (currentChannelMember === undefined && currentProjectMember === undefined) return undefined
    return currentCard?.cardShare !== undefined ? () => setShareVisible(true) : undefined
  }, [currentProject, currentChannelMember, currentProjectMember, currentCard])

  const handlePressTodoEtc = useMemo(() => {
    if (currentTodo && currentCard?.isPublic === true) {
      return () => setAuthorityVisible(true)
    }
  }, [currentTodo, currentCard])

  const handlePressEtc = useMemo(() => {
    if (currentCard && currentCard.user && currentCard.user.no === currentUser.id)
      return () => setAuthorityVisible(true)
    if (currentProject?.isArchive) return undefined
    if (currentChannelMember === undefined && currentProjectMember === undefined) return undefined

    if (
      currentProjectMember?.auth === 'MANAGER' ||
      currentProjectMember?.auth === 'OWNER' ||
      currentChannelMember?.isManager ||
      currentChannelMember?.isOwner
    ) {
      return currentCard?.cardShare !== undefined ? () => setAuthorityVisible(true) : undefined
    } else {
      return undefined
    }
  }, [currentUser, currentProject, currentChannelMember, currentProjectMember, currentCard, currentTodo])

  const handleShare = useCallback(
    (shareData: ShareType) => {
      setShareKey(shareData.key)
      showToastMessage({ type: 'Success', title: t('setting.toast.success'), position: 'TOP_CENTER' })
      if (currentTodo) {
        const cardShareType = shareData.key as CardShareType
        const payload: TodoUpdate = {
          ...currentTodo,
          viewPermission: cardShareType,
        }
        astro.updateTodo(payload)
      } else {
        if (currentCard !== null) {
          const cardShareType = shareData.key as CardShareType
          updateCard(String(currentCard.no), { cardShareType })
        }
      }
    },
    [updateCard, currentTodo, currentCard],
  )

  const handleAuthority = useCallback(
    (authorityData: AuthorityType) => {
      if (currentCard !== null) {
        setAuthorityData(authorityData)
        showToastMessage({ type: 'Success', title: t('toast.confirmed'), position: 'TOP_CENTER' })
        const { isPublic } = authorityData
        updateCard(String(currentCard.no), { isPublic })
      }
    },
    [updateCard, currentCard],
  )

  const handlePressCopy = useCallback(() => {
    navigator.clipboard.writeText(shareLink)
    showToastMessage({ type: 'Success', title: t('toast.copied'), position: 'TOP_CENTER' })
  }, [shareLink])

  const handlePressDelete = useCallback(() => {
    if (todoId) {
      setAuthorityVisible(false)
      showDialogMessage({
        title: t('chat.askdelete'),
        list: [
          {
            name: t('common.confirm'),
            action: () => {
              astro.deleteTodo(todoId).then(() => {
                showToastMessage({ type: 'Success', title: t('toast.delete'), position: 'TOP_CENTER' })

                setTimeout(() => {
                  screenProps.onPressBack
                    ? screenProps.onPressBack()
                    : screenProps.onPressClose && screenProps.onPressClose()
                }, 500)
              })
            },
          },
        ],
        cancelText: t('common.cancel'),
        onCancel: hideDialogMessage,
      })
    } else {
      if (currentCard !== null && currentChannel) {
        console.log('handlePressDelete', currentCard, currentChannel)
        const roomIdList = currentCard.roomIds.split(',')
        if (roomIdList.length > 0) {
          let title, list
          if (roomIdList.length === 1) {
            title = t('system.delete')
            list = [
              {
                name: t('todolist.delete'),
                action: () => {
                  deleteCard(String(currentCard.no)).then(() => {
                    setAuthorityVisible(false)
                    screenProps.onPressClose && screenProps.onPressClose()
                  })
                },
              },
            ]
          } else {
            title = t('card.dialog.shareddelete.title')
            list = [
              {
                name: t('card.dialog.shareddelete.all'),
                action: () => {
                  deleteCard(String(currentCard.no)).then(() => {
                    setAuthorityVisible(false)
                    screenProps.onPressClose && screenProps.onPressClose()
                  })
                },
              },
              {
                name: t('card.dialog.shareddelete.here'),
                action: () => {
                  unshareCard(String(currentCard.no), String(currentChannel.id)).then(() => {
                    setAuthorityVisible(false)
                    screenProps.onPressClose && screenProps.onPressClose()
                  })
                },
              },
            ]
          }

          setAuthorityVisible(false)
          showDialogMessage({
            title,
            list,
            cancelText: t('setting.button.cancel'),
            onCancel: hideDialogMessage,
          })
        }
      }
    }
  }, [todoId, currentCard, currentChannel])

  const initDiscussChannelId = useCallback(() => {
    console.log('readCardDiscussionChannelId-init')
    if (currentChannel) {
      astro
        .readCardDiscussionChannelId(currentChannel.roomId, String(currentCard?.no))
        .then((res) => {
          console.log('readCardDiscussionChannelId-res', res)
          if (res) {
            setDiscussMessage('')
            setDiscussChannelId(res)
          }
        })
        .catch((err) => {
          console.log('readCardDiscussionChannelId-err', err)
          setDiscussMessage('')
          setDiscussChannelId('')
        })
    }
  }, [currentChannel?.roomId, currentCard?.no])

  const [isSending, setIsSending] = useState(false)

  const onDiscussSubmit = useCallback(
    (message?: string) => {
      if (!isSending) {
        setIsSending(true)
        const formatted = astro.formatMention(
          message ?? discussMessage,
          currentChannelMembers
            .map((m) => ({
              displayName: m.name ?? '',
              username: m.name ?? '',
              channelMember: {
                userId: `${m.userId ?? 0}`,
              },
            }))
            .filter((o) => o.channelMember.userId !== `${currentUser?.id}`),
        )

        console.log('formatted', { message, discussMessage, currentChannelMembers, formatted, currentUser })

        if (discussChannelId) {
          return astro
            .createCardDiscussionMessage(discussChannelId, String(currentCard?.no), {
              message: formatted,
              cardTitle: currentCard?.title,
            })
            .then((res) => {
              console.log('createCardDiscussionMessage-res', res)
              setDiscussMessage('')
              setIsSending(false)
            })
            .catch((err) => {
              console.error('createCardDiscussionMessage-err', err)
              setDiscussMessage('')
              setIsSending(false)
              // Alert.alert('메시지 전송이 실패하였습니다.')
            })
        } else {
          if (currentChannel)
            return astro
              .startCardDiscussion(currentChannel.roomId, String(currentCard?.no), formatted, currentCard?.title)
              .then((res) => {
                setDiscussMessage('')
                initDiscussChannelId()
                return res
              })
              .then(() => {
                setIsSending(false)
              })
        }
      }
    },
    [isSending, discussMessage, currentChannelMembers, currentUser, currentChannel, currentCard],
  )

  useEffect(() => {
    initDiscussChannelId()

    return () => {
      setDiscussChannelId('')
    }
  }, [currentChannel?.roomId, currentCard?.no])

  useSubscription([`/subscribe/v2/${currentUser.id}/rooms`], () => {
    if (discussChannelId === '') initDiscussChannelId()
  })

  useSubscription([currentCard ? `/subscribe/card/${String(currentCard.no)}/update/` : ''], () => {
    if (currentCard) {
      setTimeout(() => fetchFile('card', String(currentCard.no)), 500)
    }
  })

  useSubscription(currentCard ? `/subscribe/v2/card/${String(currentCard.no)}/todo/updated` : '', () => {
    if (todoId !== undefined) astro.readTodo(todoId).then(setCurrentTodo)
  })

  const mentionableUsers: MentionEntry[] = useMemo(() => {
    // 토론에 들어올 수 있는 것은 보드멤버이므로, 토론 멘션에서 띄워주는 리스트는 해당 보드의 모든 멤버이다.
    console.debug('DEBUG: mentionable:  ', currentChannel)
    return currentChannelMembers
      .filter((o) => o.name)
      .map(({ id, name, email }) => ({
        id: String(id),
        name,
        thumbnail: `${option.secureCdnUrl}/profile/${email}`,
      }))
  }, [currentChannelMembers])

  useEffect(() => {
    if (Platform.OS === 'web') {
      const discussEl = document.getElementById('discuss-view')
      if (discussEl) {
        setTimeout(() => {
          setDiscussY(discussEl.getBoundingClientRect().top)
        }, 500)
      }
    }
  }, [discussY])

  const DiscussMenu = ({
    payload,
    window,
    onClose,
  }: {
    payload: DiscussionListMoreButtonType
    window?: boolean
    onClose: () => void
  }) => {
    const { moreList, item, parentIndex, index, replyItem, layoutPayload } = payload
    if (typeof moreList === 'undefined') return <></>
    const states = moreList.map((m) => m.state)
    const viewRef = React.useRef<any>(null)

    const handle = React.useCallback((event: unknown) => {
      const mouseEvent = event as React.MouseEvent
      if (viewRef.current && !viewRef.current.contains(mouseEvent.target)) {
        onClose()
      }
    }, [])

    React.useEffect(() => {
      document.addEventListener('mousedown', handle)
      return () => {
        document.removeEventListener('mousedown', handle)
      }
    }, [viewRef])

    const list1: any[] = []
    const list2: any[] = []

    if (states.includes('copy')) {
      list1.push({
        title: t('chat.copy'),
        onPress: () => {
          if (replyItem) {
            navigator.clipboard.writeText(replyItem.message).then(() => {
              showToastMessage({ type: 'Success', title: t('toast.copied'), position: 'TOP_CENTER' })
            })
          } else if (item) {
            navigator.clipboard.writeText(item.message).then(() => {
              showToastMessage({ type: 'Success', title: t('toast.copied'), position: 'TOP_CENTER' })
            })
          } else {
            console.log('no item')
          }
          onClose()
        },
      })
    }
    if (states.includes('modify')) {
      list1.push({
        title: t('chat.edit'),
        onPress: () => {
          setReplyDiscussMessage(null)
          if (replyItem) {
            setReplyDiscussSelectIndex(`${parentIndex}-reply-${index}`)

            setUpdateDiscussMessage({ ...replyItem, no: 0, type: 'msg', username: '', deleted: false })
          } else if (item) {
            if (typeof parentIndex !== 'undefined') setDiscussSelectIndex(parentIndex)
            else setDiscussSelectIndex(null)
            setUpdateDiscussMessage(item)
          } else {
            console.log('no item')
          }
          onClose()
        },
      })
    }
    if (states.includes('delete')) {
      list1.push({
        title: t('chat.delete'),
        onPress: () => {
          if (replyItem) {
            const title = t('system.delete')
            const list = [
              {
                name: t('todolist.delete'),
                action: () => {
                  astro
                    .deleteCardDiscussionMessage(discussChannelId, String(replyItem.no))
                    .then((res) => console.log('onDeleteDiscuss-res', res))
                    .catch((res) => console.log('onDeleteDiscuss-catch', res))
                },
              },
            ]
            showDialogMessage({
              title,
              list,
              cancelText: t('setting.button.cancel'),
              onCancel: () => {
                hideDialogMessage()
              },
            })
          } else if (item) {
            const title = t('system.delete')
            const list = [
              {
                name: t('todolist.delete'),
                action: () => {
                  astro
                    .deleteCardDiscussionMessage(discussChannelId, String(item.no))
                    .then((res) => console.log('onDeleteDiscuss-res', res))
                    .catch((res) => console.log('onDeleteDiscuss-catch', res))
                },
              },
            ]
            showDialogMessage({
              title,
              list,
              cancelText: t('setting.button.cancel'),
              onCancel: () => {
                hideDialogMessage()
              },
            })
          } else {
            console.log('no item')
          }
          onClose()
        },
      })
    }

    if (states.includes('reply')) {
      list2.push({
        title: t('chat.reply'),
        onPress: () => {
          if (item) {
            setUpdateDiscussMessage(null)
            if (typeof index !== 'undefined') setDiscussSelectIndex(index)
            else setDiscussSelectIndex(null)
            setReplyDiscussMessage({
              userEmail: item.writer?.userEmail || '',
              userName: item.username,
              message: item.message,
              no: String(item.no),
              channelNo: discussChannelId,
              roomId: discussChannelId,
              recipient: item.recipient,
            })
          }
          onClose()
        },
      })
    }

    if (list1.length === 0 && list2.length === 0) return <></>

    return (
      <div ref={viewRef}>
        <Combobox visible>
          <Combobox.Body
            style={{
              top: layoutPayload.pageY + 10 - (window ? 0 : 50),
              right: 10,
            }}
          >
            <>
              {list1.map((item, index) => (
                <Combobox.Item key={index} text={item.title} onPress={item.onPress} />
              ))}
              {list2.length > 0 ? <Combobox.Line /> : <></>}
              {list2.map((item, index) => (
                <Combobox.Item key={index} text={item.title} onPress={item.onPress} />
              ))}
            </>
          </Combobox.Body>
        </Combobox>
      </div>
    )
  }

  const [shareKey, setShareKey] = useState('WORK_MEMBER')
  const [authorityData, setAuthorityData] = useState<AuthorityType>({ isPublic: currentCard?.isPublic ?? false })

  useEffect(() => {
    setShareKey(
      currentTodo ? currentTodo.viewPermission ?? 'WORK_MEMBER' : currentCard?.cardShare?.type ?? 'WORK_MEMBER',
    )
    setAuthorityData({ isPublic: currentCard?.isPublic ?? false })
  }, [currentTodo, currentCard])

  console.log('currentCard', cardType, currentCard, handlePressEtc, handlePressTodoEtc)

  return (
    <>
      {cardType === 'MISSION' ? (
        <WorkCardTodoScreen
          {...screenProps}
          currentProject={currentProject as Project}
          onScroll={handleScroll}
          onDiscussViewScroll={handleDiscussViewScroll}
          onPressEdit={handlePressEdit}
          onPressShare={handlePressShare}
          onPressEtc={handlePressEtc}
          discussionView={
            <DiscussionView
              cardId={String(currentCard?.no)}
              discussChannelId={discussChannelId}
              discussMessage={discussMessage}
              setDiscussMessage={setDiscussMessage}
              onSubmit={onDiscussSubmit}
              onLayout={(e: LayoutChangeEvent) => {
                // console.log('onLayout-3', e, e.nativeEvent, e.nativeEvent.layout.top)
                // @ts-ignore
                setDiscussY(e.nativeEvent.layout.top)
              }}
              setDiscussMenu={setDiscussMenu}
            />
          }
        />
      ) : cardType === 'MEETING' || cardType === 'ATTEND' ? (
        <WorkCardScheduleScreen
          {...screenProps}
          currentProject={currentProject as Project}
          onScroll={handleScroll}
          onDiscussViewScroll={handleDiscussViewScroll}
          onPressEdit={handlePressEdit}
          onPressShare={handlePressShare}
          onPressEtc={handlePressEtc}
          discussionView={
            <DiscussionView
              cardId={String(currentCard?.no)}
              discussChannelId={discussChannelId}
              discussMessage={discussMessage}
              setDiscussMessage={setDiscussMessage}
              onSubmit={onDiscussSubmit}
              onLayout={(e: LayoutChangeEvent) => {
                // console.log('onLayout-3', e, e.nativeEvent, e.nativeEvent.layout.top)
                // @ts-ignore
                setDiscussY(e.nativeEvent.layout.top)
              }}
              setDiscussMenu={setDiscussMenu}
            />
          }
        />
      ) : cardType === 'COLLECTION' ? (
        <WorkCardCollectionScreen
          {...screenProps}
          currentProject={currentProject as Project}
          onScroll={handleScroll}
          onDiscussViewScroll={handleDiscussViewScroll}
          onPressEdit={handlePressEdit}
          onPressShare={handlePressShare}
          onPressEtc={handlePressEtc}
          discussionView={
            <DiscussionView
              cardId={String(currentCard?.no)}
              discussChannelId={discussChannelId}
              discussMessage={discussMessage}
              setDiscussMessage={setDiscussMessage}
              onSubmit={onDiscussSubmit}
              onLayout={(e: LayoutChangeEvent) => {
                // console.log('onLayout-3', e, e.nativeEvent, e.nativeEvent.layout.top)
                // @ts-ignore
                setDiscussY(e.nativeEvent.layout.top)
              }}
              setDiscussMenu={setDiscussMenu}
            />
          }
        />
      ) : cardType === 'TODO' ? (
        <WorkCardTodoDetailScreen
          {...screenProps}
          currentProject={currentProject as Project}
          onScroll={handleScroll}
          // onDiscussViewScroll={handleDiscussViewScroll}
          // onPressEdit={handlePressEdit}
          onPressShare={handlePressShare}
          onPressEtc={handlePressTodoEtc ?? handlePressEtc}
          discussionView={<></>}
        />
      ) : (
        <></>
      )}
      {visibleDiscussInput && windowWidth <= 800 && (
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: COLOR.mono.white,
          }}
        >
          <MentionProvider entries={mentionableUsers}>
            <MentionTargetPicker marginTop={-280} direction={'upside'} />
            <WorkCardDiscussionInput
              bottomOffset={0}
              defaultValue={discussMessage}
              onText={setDiscussMessage}
              // onPressFile={console.log}
              onSend={onDiscussSubmit}
              onSubmit={(message) => {
                setDiscussMessage(message)
                onDiscussSubmit(message)
              }}
              profileUrl={`${option.secureCdnUrl}/profile/${currentUser?.userEmail || 'undefined'}`}
            />
          </MentionProvider>
          {isAnonymous && <Pressable style={{ width: '100%', height: 53 }} onPress={showLoginModal} />}
        </View>
      )}
      {shareVisible && (
        <WorkCardShareView
          visible={shareVisible}
          top={48}
          right={10}
          onPressClose={() => setShareVisible(false)}
          onPressCopy={handlePressCopy}
          shareLink={shareLink}
          shareType={shareType}
        />
      )}

      {currentCard !== null && (
        <WorkCardAuthorityModal
          isAuth={handlePressEtc !== undefined ? true : false}
          cardType={cardType}
          visible={authorityVisible}
          top={window ? 50 : 100}
          right={10}
          onPressClose={() => {
            setAuthorityVisible(false)
          }}
          shareKey={shareKey}
          shareData={SHARE_LIST}
          authorityData={authorityData}
          onPressCopy={handlePressCopy}
          onPressDelete={handlePressDelete}
          onChangeShareData={handleShare}
          onChangeAuthorityData={handleAuthority}
        />
      )}

      {discussMenu && <DiscussMenu payload={discussMenu} window={window} onClose={() => setDiscussMenu(undefined)} />}
    </>
  )
}

export default CardDetailView
