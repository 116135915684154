import { COLOR, FONT, getBoxShadow } from '@rocket-mono/libs'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  wrap: {
    position: 'relative',
    width: '100%',
  },
  cardBase: {
    width: '100%',
    minHieght: 60,
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderColor: '#E1E5EB',
    borderRadius: 8,
    paddingVertical: 10,
    paddingHorizontal: 12,
  },
  cardFadeStyle: {
    borderColor: COLOR.transparent.base,
  },
  cardCloseStyle: {
    backgroundColor: '#E4E7E9',
    borderColor: COLOR.transparent.base,
  },
  cardBaseHover: {
    borderColor: '#DBDEE4',
    ...getBoxShadow(0, 0, 0, 0.05, 5, 0, 2, 0),
  },
  cardBasePress: {
    opacity: 0.8,
  },
  cardTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  cardState: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  summaryArea: {
    flex: 1,
    maxWidth: '100%',
    marginBottom: 4
  },
  optionArea: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  optionGap: {
    marginLeft: 4,
  },
  cardLabel: {
    position: 'absolute',
    top: 9,
    right: -17,
  },
  scheduleSummary: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  date: {
    marginLeft: 4,
    ...FONT.txt2Xs,
    color: COLOR.gray.g450,
  },
  title: {
    ...FONT.txtSmMedium,
    marginRight: 20,
  },
  mobileTitle: {
    ...FONT.txtMdMedium,
  },
  closedTitle: {
    color: COLOR.gray.g700,
  },
})
