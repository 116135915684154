import { COLOR, FONT, getRealColor } from '@rocket-mono/libs'
import { Channel, ProjectElement } from '@rocket/types'
import React from 'react'

import { Platform, StyleSheet, Text, View } from 'react-native'

interface Props {
  board?: Channel
  element?: ProjectElement
}

const ListApplyBoardItem: React.FC<Props> = ({ board, element }) => {
  const color = React.useMemo(() => {
    if (!element) {
      return 'sub.pink'
    }
    const isFirst = element.order === 0
    const isLast = element.order === element.maxOrder
    if (isFirst) {
      return 'main.yellow'
    } else if (isLast) {
      return 'main.blue'
    } else {
      return 'main.turquoise'
    }
  }, [element])

  const roomName = React.useMemo(() => {
    if (board?.roomName) {
      return board.roomName.length > 8 ? board.roomName.slice(0, 8) + '...' : board.roomName
    }
    return ''
  }, [board])
  return (
    <View style={styles.boardLabel}>
      <Text style={styles.labelText}>{roomName}</Text>
      <View
        style={[
          styles.colorBack,
          Platform.select({
            web: { width: '100%' },
          }),
          {
            backgroundColor: getRealColor(color),
          },
        ]}
      ></View>
    </View>
  )
}

const styles = StyleSheet.create({
  boardLabel: {
    overflow: 'hidden',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 4,
    paddingRight: 6,
    borderRadius: 4,
    height: 24,
  },
  labelText: {
    position: 'relative',
    ...FONT.txtXsMedium,
    color: COLOR.gray.g900,
    marginLeft: 4,
    zIndex: 2,
  },
  colorBack: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    opacity: 0.15,
  },
})

export default ListApplyBoardItem
