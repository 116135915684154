import { CardType } from '@rocket/types'
import { atom } from 'recoil'

export const isAnonymousState = atom<boolean>({
  key: 'isAnonymousState',
  default: false,
})

export const authTokenState = atom<string>({
  key: 'authTokenState',
  default: '',
})

export const sideLayoutTypeState = atom<string>({
  key: 'sideLayoutTypeState',
  default: '',
})

export const cardLayoutPayloadState = atom<
  | {
      isFold: boolean
      isEdit: boolean
      projectId: string
      channelId: string
      cardType: CardType
      cardId?: string
      todoId?: string
    }
  | undefined
>({ key: 'cardLayoutPayloadState', default: undefined })

export const unreadMessageCountState = atom<number>({
  key: 'unreadMessageCountState',
  default: 0,
})

export const unreadNotificationCountState = atom<number>({
  key: 'unreadNotificationCountState',
  default: 0,
})
