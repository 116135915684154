import * as React from 'react'
import { ListMember } from './types'
import { DropdownListItem } from '@rocket-atoms/dropdown'

const MemberList = ({
  item,
  fontSize = 'SM',
  paddingHorizontal,
  onChange,
}: {
  item: ListMember
  fontSize?: 'SM' | 'MD'
  paddingHorizontal?: number
  onChange?: (item: ListMember) => void
}) => {
  return (
    <DropdownListItem 
      item={{label:item.name, code:item.name, beforeProfileImage:item.uri}}
      itemType={'CHECKED'} 
      itemVariant={'FILLED'}
      checked={item.selected}
      onChangeChecked={(selected) => {
        onChange && onChange({ ...item, selected })
      }}
      ifAfterChecked
    />
  )
}

export default MemberList
