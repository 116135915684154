/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react'
import {
  GestureResponderEvent,
  Platform,
  Pressable,
  PressableProps,
  PressableStateCallbackType,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native'

import type { IColors, IconName } from '@rocket-mono/libs'

import { Icon } from '@rocket-atoms/icon'
import { COLOR, FONT } from '@rocket-mono/libs'

import { getRealColor } from '@rocket-mono/libs'
import { BadgeSide, BadgeType } from '.'

interface Props extends Omit<PressableProps, 'style'> {
  containerSize?: number
  iconName: IconName
  iconSize?: number
  iconColor?: IColors
  iconViewbox?: string
  backgroundColor?: IColors
  rounded?: boolean
  style?: StyleProp<ViewStyle>
  hoverStyle?: StyleProp<ViewStyle>
  pressedStyle?: StyleProp<ViewStyle>
  disabledStyle?: StyleProp<ViewStyle>
  badgeSide?: BadgeSide
  badgeType?: BadgeType
  badgeMaxCount?: number
  badgeCount?: number
}

const PlainButtonIcon = ({
  containerSize = 35,
  iconName,
  iconSize = containerSize * 0.5,
  iconViewbox = '0 -40 1000 1000',
  backgroundColor = 'transparent',
  iconColor = 'mono.black',
  rounded = false,
  onPressIn,
  onPressOut,
  style,
  hoverStyle,
  pressedStyle,
  disabledStyle,
  badgeSide = 'right',
  badgeType = 'CURRENT_COUNT_ONLY',
  badgeMaxCount,
  badgeCount,
  disabled,
  ...props
}: Props) => {
  const isBadgeTotalCountAndCurrentCount = React.useMemo(() => {
    return badgeCount !== undefined && badgeCount !== 0 && badgeType === 'MAX_COUNT_AND_CURRENT_COUNT'
  }, [badgeType, badgeCount])

  const _onPressIn = React.useCallback(
    (event: GestureResponderEvent) => {
      onPressIn && onPressIn(event)
    },
    [onPressIn],
  )

  const _onPressOut = React.useCallback(
    (event: GestureResponderEvent) => {
      onPressOut && onPressOut(event)
    },
    [onPressOut],
  )

  const onStyle = React.useCallback(
    // @ts-ignore hovered only used in web
    ({ hovered, pressed }: PressableStateCallbackType) => {
      return [
        styles.pressable,
        {
          borderRadius: rounded ? containerSize : getBorderRadius(containerSize),
          backgroundColor: getRealColor(backgroundColor),
        },
        !disabled && hovered && (hoverStyle ?? styles.hover),
        !disabled && pressed && (pressedStyle ?? styles.pressed),
        disabled && (disabledStyle ?? styles.disabled),
        isBadgeTotalCountAndCurrentCount ? styles.flexDirectionRow : undefined,
      ] as ViewStyle[]
    },
    [
      containerSize,
      rounded,
      backgroundColor,
      hoverStyle,
      pressedStyle,
      disabledStyle,
      disabled,
      isBadgeTotalCountAndCurrentCount,
    ],
  )

  const badgeTypeComponent = React.useCallback((badgeType: BadgeType, badgeCount: number, badgeMaxCount?: number) => {
    if (badgeType === 'CURRENT_COUNT_ONLY') {
      return (
        <View style={styles.iconCountContainer}>
          <Text style={styles.iconCountText}>{badgeCount}</Text>
        </View>
      )
    } else if (badgeType === 'MAX_COUNT_AND_CURRENT_COUNT' && badgeMaxCount) {
      return (
        <View style={styles.countContainer}>
          <Text style={styles.countText}>
            {badgeCount}/{badgeMaxCount}
          </Text>
        </View>
      )
    } else {
      return <></>
    }
  }, [])

  const leftBadgeComponent = React.useMemo(() => {
    if (badgeCount === undefined) return <></>
    if (badgeCount === 0) return <></>
    if (badgeSide === 'right') return <></>
    return badgeTypeComponent(badgeType, badgeCount, badgeMaxCount)
  }, [badgeSide, badgeType, badgeMaxCount, badgeCount, badgeTypeComponent])

  const rightBadgeComponent = React.useMemo(() => {
    if (badgeCount === undefined) return <></>
    if (badgeCount === 0) return <></>
    if (badgeSide === 'left') return <></>
    return badgeTypeComponent(badgeType, badgeCount, badgeMaxCount)
  }, [badgeSide, badgeType, badgeMaxCount, badgeCount, badgeTypeComponent])

  const badgeCountWidth = React.useMemo(() => {
    if (badgeCount === undefined) return 0
    const countStr = String(badgeCount)
    return countStr.length * 7
  }, [badgeCount])

  const backMaxCountWidth = React.useMemo(() => {
    if (badgeMaxCount === undefined) return 0
    const countStr = String(badgeMaxCount)
    return countStr.length * 7
  }, [badgeMaxCount])

  const containerWidth = React.useMemo(() => {
    if (isBadgeTotalCountAndCurrentCount) return badgeCountWidth + backMaxCountWidth + 4 + 28
    else return containerSize
  }, [isBadgeTotalCountAndCurrentCount, badgeCountWidth, backMaxCountWidth, containerSize])

  return (
    <View
      style={[
        styles.container,
        {
          height: containerSize,
          width: containerWidth,
          borderRadius: rounded ? containerSize : getBorderRadius(containerSize),
        },
        style,
      ]}
    >
      <Pressable style={onStyle} onPressIn={_onPressIn} onPressOut={_onPressOut} disabled={disabled} {...props}>
        {leftBadgeComponent}
        <Icon name={iconName} size={iconSize} color={iconColor} viewBox={iconViewbox} />
        {rightBadgeComponent}
      </Pressable>
    </View>
  )
}

export default PlainButtonIcon

const getBorderRadius = (containerSize: number) => {
  return containerSize * 0.2
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
  },
  pressable: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
    height: '100%',
    width: '100%',
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
    }),
  },
  hover: {
    opacity: 0.8,
  },
  pressed: {
    opacity: 0.5,
  },
  disabled: {
    backgroundColor: COLOR.mono.lightGray,
  },
  flexDirectionRow: {
    flexDirection: 'row',
  },
  iconCountContainer: {
    backgroundColor: getRealColor('main.red'),
    borderRadius: 15,
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  iconCountText: {
    ...FONT.textRegular,
    fontSize: 10,
    color: getRealColor('mono.white'),
  },
  countContainer: {
    marginRight: 4,
  },
  countText: {
    ...FONT.txtXsMedium,
    color: getRealColor('gray.g700'),
  },
})
