import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect, Svg } from 'react-native-svg'

interface Props {
  size: number
  theme?: 'dark' | 'light'
}

const LayoutTopLogo: React.FC<Props> = ({ size, theme }) => {
  return (
  <Svg
    width={size}
    height={size}
    viewBox="0 0 149 149"
    fill="none"
    >
    <G clipPath="url(#clip0_13_11556)">
      <Path
        d="M139.331 0H110.356L43.1885 67.1676V105.812H81.8324L149 38.6439V9.66904C149 4.33496 144.665 0 139.331 0Z"
        fill={theme === 'dark' ? '#ffffff' : '#020118'}
      />
      <Path
        d="M0 109.228H39.772V149H9.66904C4.33496 149 0 144.681 0 139.331V109.228Z"
        fill={theme === 'dark' ? '#ffffff' : '#020118'}
      />
      <Path
        d="M0 38.6747L39.2563 0.0146484H77.2065V17.7234L35.7567 59.1731H0.0483452H0V38.6747Z"
        fill={theme === 'dark' ? '#ffffff' : '#020118'}
      />
      <Path
        d="M110.34 149L149 109.744V71.7936H131.291L89.8416 113.243V148.952V149H110.34Z"
        fill={theme === 'dark' ? '#ffffff' : '#020118'}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_13_11556">
        <Rect width={149} height={149} fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
  )
}

export default LayoutTopLogo
