import { Combobox } from '@rui/molecules'
import { ClickOutside } from '@rui/foundations'
import * as React from 'react'
import { Modal, StyleSheet, View } from 'react-native'
import type { HandlerProps } from '.'
import WorkCardAuthorityView from '../WorkCardAuthorityView'

const WorkCardAuthorityModal: React.FC<HandlerProps> = ({ visible, onPressClose, top = 20, right = 20, ...props }) => {
  return visible ? (
    <Modal transparent>
      <ClickOutside onClickOutside={onPressClose}>
        <View style={{ width: 394, top, right, zIndex: 30, position: 'absolute', ...styles.container }}>
          <Combobox visible width={394} containerStyle={{ overflow: 'visible' }}>
            <Combobox.Body isDirect style={{ width: 394, paddingVertical: 4 }}>
              <WorkCardAuthorityView {...props} visible={visible} onPressClose={onPressClose} />
            </Combobox.Body>
          </Combobox>
        </View>
      </ClickOutside>
    </Modal>
  ) : (
    <></>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
  },
})

export default WorkCardAuthorityModal
