'use client'
"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __commonJS = (cb, mod) => function __require() {
  return mod || (0, cb[__getOwnPropNames(cb)[0]])((mod = { exports: {} }).exports, mod), mod.exports;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// ../../../node_modules/void-elements/index.js
var require_void_elements = __commonJS({
  "../../../node_modules/void-elements/index.js"(exports, module2) {
    module2.exports = {
      "area": true,
      "base": true,
      "br": true,
      "col": true,
      "embed": true,
      "hr": true,
      "img": true,
      "input": true,
      "link": true,
      "meta": true,
      "param": true,
      "source": true,
      "track": true,
      "wbr": true
    };
  }
});

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  CreateWorkScreen: () => CreateWorkScreen_default,
  MemberInviteEmail: () => MemberInviteEmail_default,
  MemberInviteList: () => MemberInviteList_default,
  MemberInviteScreen: () => contents_default,
  MemberInviteScreenModal: () => MemberInviteScreenModal,
  MemberInviteWorkDetail: () => MemberInviteWorkDetail_default,
  MemberInviteWorkList: () => MemberInviteWorkList_default,
  MessageListScreen: () => MessageListScreen,
  MetaText: () => MetaText_default,
  NotificationListScreen: () => NotificationListScreen,
  WorkSettingScreen: () => WorkSettingScreen_default,
  WorkmodelCreateScreen: () => WorkmodelCreateScreen,
  WorkmodelEditScreen: () => WorkmodelEditScreen
});
module.exports = __toCommonJS(src_exports);

// src/providers.tsx
var import_components = require("@rocket/components");
var import_components2 = require("@rocket/components");
var import_providers = require("@rocket-mono/providers");

// ../../../node_modules/react-i18next/dist/es/Trans.js
var import_react3 = require("react");

// ../../../node_modules/react-i18next/dist/es/TransWithoutContext.js
var import_react = require("react");

// ../../../node_modules/html-parse-stringify/dist/html-parse-stringify.module.js
var import_void_elements = __toESM(require_void_elements());

// ../../../node_modules/react-i18next/dist/es/utils.js
function warn() {
  if (console && console.warn) {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    if (typeof args[0] === "string")
      args[0] = `react-i18next:: ${args[0]}`;
    console.warn(...args);
  }
}
var alreadyWarned = {};
function warnOnce() {
  for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    args[_key2] = arguments[_key2];
  }
  if (typeof args[0] === "string" && alreadyWarned[args[0]])
    return;
  if (typeof args[0] === "string")
    alreadyWarned[args[0]] = /* @__PURE__ */ new Date();
  warn(...args);
}
var loadedClb = (i18n, cb) => () => {
  if (i18n.isInitialized) {
    cb();
  } else {
    const initialized = () => {
      setTimeout(() => {
        i18n.off("initialized", initialized);
      }, 0);
      cb();
    };
    i18n.on("initialized", initialized);
  }
};
function loadNamespaces(i18n, ns, cb) {
  i18n.loadNamespaces(ns, loadedClb(i18n, cb));
}
function loadLanguages(i18n, lng, ns, cb) {
  if (typeof ns === "string")
    ns = [ns];
  ns.forEach((n) => {
    if (i18n.options.ns.indexOf(n) < 0)
      i18n.options.ns.push(n);
  });
  i18n.loadLanguages(lng, loadedClb(i18n, cb));
}
function oldI18nextHasLoadedNamespace(ns, i18n) {
  let options = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
  const lng = i18n.languages[0];
  const fallbackLng = i18n.options ? i18n.options.fallbackLng : false;
  const lastLng = i18n.languages[i18n.languages.length - 1];
  if (lng.toLowerCase() === "cimode")
    return true;
  const loadNotPending = (l, n) => {
    const loadState = i18n.services.backendConnector.state[`${l}|${n}`];
    return loadState === -1 || loadState === 2;
  };
  if (options.bindI18n && options.bindI18n.indexOf("languageChanging") > -1 && i18n.services.backendConnector.backend && i18n.isLanguageChangingTo && !loadNotPending(i18n.isLanguageChangingTo, ns))
    return false;
  if (i18n.hasResourceBundle(lng, ns))
    return true;
  if (!i18n.services.backendConnector.backend || i18n.options.resources && !i18n.options.partialBundledLanguages)
    return true;
  if (loadNotPending(lng, ns) && (!fallbackLng || loadNotPending(lastLng, ns)))
    return true;
  return false;
}
function hasLoadedNamespace(ns, i18n) {
  let options = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
  if (!i18n.languages || !i18n.languages.length) {
    warnOnce("i18n.languages were undefined or empty", i18n.languages);
    return true;
  }
  const isNewerI18next = i18n.options.ignoreJSONStructure !== void 0;
  if (!isNewerI18next) {
    return oldI18nextHasLoadedNamespace(ns, i18n, options);
  }
  return i18n.hasLoadedNamespace(ns, {
    lng: options.lng,
    precheck: (i18nInstance2, loadNotPending) => {
      if (options.bindI18n && options.bindI18n.indexOf("languageChanging") > -1 && i18nInstance2.services.backendConnector.backend && i18nInstance2.isLanguageChangingTo && !loadNotPending(i18nInstance2.isLanguageChangingTo, ns))
        return false;
    }
  });
}

// ../../../node_modules/react-i18next/dist/es/unescape.js
var matchHtmlEntity = /&(?:amp|#38|lt|#60|gt|#62|apos|#39|quot|#34|nbsp|#160|copy|#169|reg|#174|hellip|#8230|#x2F|#47);/g;
var htmlEntities = {
  "&amp;": "&",
  "&#38;": "&",
  "&lt;": "<",
  "&#60;": "<",
  "&gt;": ">",
  "&#62;": ">",
  "&apos;": "'",
  "&#39;": "'",
  "&quot;": '"',
  "&#34;": '"',
  "&nbsp;": " ",
  "&#160;": " ",
  "&copy;": "\xA9",
  "&#169;": "\xA9",
  "&reg;": "\xAE",
  "&#174;": "\xAE",
  "&hellip;": "\u2026",
  "&#8230;": "\u2026",
  "&#x2F;": "/",
  "&#47;": "/"
};
var unescapeHtmlEntity = (m) => htmlEntities[m];
var unescape = (text) => text.replace(matchHtmlEntity, unescapeHtmlEntity);

// ../../../node_modules/react-i18next/dist/es/defaults.js
var defaultOptions = {
  bindI18n: "languageChanged",
  bindI18nStore: "",
  transEmptyNodeValue: "",
  transSupportBasicHtmlNodes: true,
  transWrapTextNodes: "",
  transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p"],
  useSuspense: true,
  unescape
};
function getDefaults() {
  return defaultOptions;
}

// ../../../node_modules/react-i18next/dist/es/i18nInstance.js
var i18nInstance;
function getI18n() {
  return i18nInstance;
}

// ../../../node_modules/react-i18next/dist/es/context.js
var import_react2 = require("react");
var I18nContext = (0, import_react2.createContext)();
var ReportNamespaces = class {
  constructor() {
    this.usedNamespaces = {};
  }
  addUsedNamespaces(namespaces) {
    namespaces.forEach((ns) => {
      if (!this.usedNamespaces[ns])
        this.usedNamespaces[ns] = true;
    });
  }
  getUsedNamespaces() {
    return Object.keys(this.usedNamespaces);
  }
};

// ../../../node_modules/react-i18next/dist/es/useTranslation.js
var import_react4 = require("react");
var usePrevious = (value, ignore) => {
  const ref = (0, import_react4.useRef)();
  (0, import_react4.useEffect)(() => {
    ref.current = ignore ? ref.current : value;
  }, [value, ignore]);
  return ref.current;
};
function alwaysNewT(i18n, language, namespace, keyPrefix) {
  return i18n.getFixedT(language, namespace, keyPrefix);
}
function useMemoizedT(i18n, language, namespace, keyPrefix) {
  return (0, import_react4.useCallback)(alwaysNewT(i18n, language, namespace, keyPrefix), [i18n, language, namespace, keyPrefix]);
}
function useTranslation(ns) {
  let props = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
  const {
    i18n: i18nFromProps
  } = props;
  const {
    i18n: i18nFromContext,
    defaultNS: defaultNSFromContext
  } = (0, import_react4.useContext)(I18nContext) || {};
  const i18n = i18nFromProps || i18nFromContext || getI18n();
  if (i18n && !i18n.reportNamespaces)
    i18n.reportNamespaces = new ReportNamespaces();
  if (!i18n) {
    warnOnce("You will need to pass in an i18next instance by using initReactI18next");
    const notReadyT = (k, optsOrDefaultValue) => {
      if (typeof optsOrDefaultValue === "string")
        return optsOrDefaultValue;
      if (optsOrDefaultValue && typeof optsOrDefaultValue === "object" && typeof optsOrDefaultValue.defaultValue === "string")
        return optsOrDefaultValue.defaultValue;
      return Array.isArray(k) ? k[k.length - 1] : k;
    };
    const retNotReady = [notReadyT, {}, false];
    retNotReady.t = notReadyT;
    retNotReady.i18n = {};
    retNotReady.ready = false;
    return retNotReady;
  }
  if (i18n.options.react && i18n.options.react.wait !== void 0)
    warnOnce("It seems you are still using the old wait option, you may migrate to the new useSuspense behaviour.");
  const i18nOptions = {
    ...getDefaults(),
    ...i18n.options.react,
    ...props
  };
  const {
    useSuspense,
    keyPrefix
  } = i18nOptions;
  let namespaces = ns || defaultNSFromContext || i18n.options && i18n.options.defaultNS;
  namespaces = typeof namespaces === "string" ? [namespaces] : namespaces || ["translation"];
  if (i18n.reportNamespaces.addUsedNamespaces)
    i18n.reportNamespaces.addUsedNamespaces(namespaces);
  const ready = (i18n.isInitialized || i18n.initializedStoreOnce) && namespaces.every((n) => hasLoadedNamespace(n, i18n, i18nOptions));
  const memoGetT = useMemoizedT(i18n, props.lng || null, i18nOptions.nsMode === "fallback" ? namespaces : namespaces[0], keyPrefix);
  const getT = () => memoGetT;
  const getNewT = () => alwaysNewT(i18n, props.lng || null, i18nOptions.nsMode === "fallback" ? namespaces : namespaces[0], keyPrefix);
  const [t, setT] = (0, import_react4.useState)(getT);
  let joinedNS = namespaces.join();
  if (props.lng)
    joinedNS = `${props.lng}${joinedNS}`;
  const previousJoinedNS = usePrevious(joinedNS);
  const isMounted = (0, import_react4.useRef)(true);
  (0, import_react4.useEffect)(() => {
    const {
      bindI18n,
      bindI18nStore
    } = i18nOptions;
    isMounted.current = true;
    if (!ready && !useSuspense) {
      if (props.lng) {
        loadLanguages(i18n, props.lng, namespaces, () => {
          if (isMounted.current)
            setT(getNewT);
        });
      } else {
        loadNamespaces(i18n, namespaces, () => {
          if (isMounted.current)
            setT(getNewT);
        });
      }
    }
    if (ready && previousJoinedNS && previousJoinedNS !== joinedNS && isMounted.current) {
      setT(getNewT);
    }
    function boundReset() {
      if (isMounted.current)
        setT(getNewT);
    }
    if (bindI18n && i18n)
      i18n.on(bindI18n, boundReset);
    if (bindI18nStore && i18n)
      i18n.store.on(bindI18nStore, boundReset);
    return () => {
      isMounted.current = false;
      if (bindI18n && i18n)
        bindI18n.split(" ").forEach((e2) => i18n.off(e2, boundReset));
      if (bindI18nStore && i18n)
        bindI18nStore.split(" ").forEach((e2) => i18n.store.off(e2, boundReset));
    };
  }, [i18n, joinedNS]);
  (0, import_react4.useEffect)(() => {
    if (isMounted.current && ready) {
      setT(getT);
    }
  }, [i18n, keyPrefix, ready]);
  const ret = [t, i18n, ready];
  ret.t = t;
  ret.i18n = i18n;
  ret.ready = ready;
  if (ready)
    return ret;
  if (!ready && !useSuspense)
    return ret;
  throw new Promise((resolve) => {
    if (props.lng) {
      loadLanguages(i18n, props.lng, namespaces, () => resolve());
    } else {
      loadNamespaces(i18n, namespaces, () => resolve());
    }
  });
}

// ../../../node_modules/react-i18next/dist/es/withTranslation.js
var import_react5 = require("react");

// ../../../node_modules/react-i18next/dist/es/I18nextProvider.js
var import_react6 = require("react");
function I18nextProvider(_ref) {
  let {
    i18n,
    defaultNS,
    children
  } = _ref;
  const value = (0, import_react6.useMemo)(() => ({
    i18n,
    defaultNS
  }), [i18n, defaultNS]);
  return (0, import_react6.createElement)(I18nContext.Provider, {
    value
  }, children);
}

// ../../../node_modules/react-i18next/dist/es/withSSR.js
var import_react8 = require("react");

// ../../../node_modules/react-i18next/dist/es/useSSR.js
var import_react7 = require("react");

// src/providers.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var Providers = ({ children, i18n }) => {
  const { astro } = (0, import_providers.useAstro)();
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(I18nextProvider, { i18n, children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_components.CurrentUserProvider, { astro, children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_components2.BackdropProvider, { children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_components2.Modal.Provider, { children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_components2.ModalDialog.Provider, { children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_components2.Toast.Provider, { children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_components2.ModalBottom.Provider, { children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_jsx_runtime.Fragment, { children }) }) }) }) }) }) }) });
};
var providers_default = Providers;

// src/screens/MessageListScreen/provider.tsx
var import_components3 = require("@rocket/components");
var import_providers2 = require("@rocket-mono/providers");
var import_jsx_runtime2 = require("react/jsx-runtime");
var Provider = ({ children }) => {
  const { astro, option } = (0, import_providers2.useAstro)();
  const { projectList, guestList } = (0, import_providers2.useWork)();
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
    import_components3.ChatsScreen.Provider,
    {
      projectListData: projectList,
      guestListData: guestList,
      astro,
      secureCdnUrl: option.secureCdnUrl || "",
      subscribe: import_providers2.useSubscription,
      children: /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(import_jsx_runtime2.Fragment, { children })
    }
  );
};
var provider_default = Provider;

// src/screens/MessageListScreen/view.tsx
var import_components4 = require("@rocket/components");
var import_providers3 = require("@rocket-mono/providers");
var import_react9 = require("react");
var import_jsx_runtime3 = require("react/jsx-runtime");
var MessageListView = ({ isShow, setUnreadCount, onPressButtonCreate }) => {
  const { astro, astroNav, option } = (0, import_providers3.useAstro)();
  const chatsScreenProps = (0, import_components4.useChatsData)();
  const handlePressItem = (0, import_react9.useCallback)(
    (itemKey) => {
      const { itemList } = chatsScreenProps;
      const channel = itemList?.find((o) => o.key === itemKey);
      console.log("channel", channel);
      if (channel) {
        const action = "openChat";
        const payload = { channelRoomId: channel.channelRoomId };
        astroNav.emit(action, payload);
      }
    },
    [astroNav, chatsScreenProps]
  );
  (0, import_react9.useEffect)(() => {
    setUnreadCount(chatsScreenProps.itemList?.map((o) => o.unreadCount).reduce((a, b) => a + b, 0) || 0);
  }, [setUnreadCount, chatsScreenProps.itemList]);
  if (!isShow)
    return null;
  return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
    import_components4.ChatsScreen,
    {
      ...chatsScreenProps,
      secureCdnUrl: option.secureCdnUrl || "",
      titleVisible: false,
      onPressItem: handlePressItem,
      onPressButtonCreate,
      astro,
      subscribe: import_providers3.useSubscription
    }
  );
};

// src/screens/MessageListScreen/index.tsx
var import_jsx_runtime4 = require("react/jsx-runtime");
var MessageListScreen = ({ i18n, isShow, setUnreadCount, onPressButtonCreate }) => {
  console.log("MessageListScreen");
  return /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(providers_default, { i18n, children: /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(provider_default, { children: /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(MessageListView, { isShow, setUnreadCount, onPressButtonCreate }) }) });
};

// src/screens/NotificationListScreen/provider.tsx
var import_components5 = require("@rocket/components");
var import_providers5 = require("@rocket-mono/providers");
var import_jsx_runtime5 = require("react/jsx-runtime");
var NotificationListProvider = ({ children }) => {
  const { option, token } = (0, import_providers5.useAstro)();
  return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(import_components5.AstroProvider, { option, token, children: /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(import_components5.NotificationProvider, { children }) });
};
var provider_default2 = NotificationListProvider;

// src/screens/NotificationListScreen/view.tsx
var import_components6 = require("@rocket/components");
var import_react10 = __toESM(require("react"));
var import_jsx_runtime6 = require("react/jsx-runtime");
function isExpired(date) {
  return date.getTime() - Date.now() < 0;
}
var NotificationListView = ({ isShow, setUnreadCount }) => {
  const { checked, unchecked } = (0, import_components6.useNotification)();
  (0, import_react10.useEffect)(() => {
    setUnreadCount(unchecked?.length || 0);
  }, [checked, unchecked]);
  const convert = import_react10.default.useCallback(
    (item) => {
      const period = item.expirationDate ? new Date(item.expirationDate) : void 0;
      switch (item.type) {
        case "REQUEST_ATTEND":
        case "REQUEST_LEAVE":
        case "REQUEST_TRANSFER_OWNERSHIP":
        case "REQUEST_JOIN":
          return {
            id: item.id,
            profileUrl: item.imageSrc || "",
            profileAlt: item.userName || "",
            type: item.type,
            user: item.userName,
            work: item.projectName,
            // proposal: 'item.proposal',
            time: item.createdAt,
            // humanized
            period,
            newUnread: !item.isCheck,
            isExpired: period ? isExpired(period) : false
          };
        case "NOTIFY_LEAVE":
          console.log("NOTIFY_LEAVE", item);
          return {
            id: item.id,
            profileUrl: item.imageSrc || "",
            profileAlt: item.userName || "",
            type: item.type,
            user: item.userName,
            work: item.projectName,
            // proposal: 'item.proposal',
            time: item.createdAt,
            // humanized
            period,
            newUnread: !item.isCheck,
            isExpired: period ? isExpired(period) : false
          };
        case "APPROVE_ATTEND":
        case "APPROVE_LEAVE":
        case "APPROVE_LEAVE_SELF":
        case "APPROVE_TRANSFER_OWNERSHIP":
        case "APPROVE_JOIN":
          return {
            id: item.id,
            profileUrl: item.imageSrc || "",
            profileAlt: item.userName || "",
            type: item.type,
            user: item.userName,
            work: item.projectName,
            // proposal: 'item.proposal',
            time: item.createdAt,
            // humanized
            newUnread: !item.isCheck,
            period,
            isExpired: period ? isExpired(period) : false
          };
        case "DENY_ATTEND":
        case "DENY_ATTEND_2":
        case "DENY_LEAVE":
        case "DENY_TRANSFER_OWNERSHIP":
          return {
            id: item.id,
            profileUrl: item.imageSrc || "",
            profileAlt: item.userName || "",
            type: item.type,
            user: item.userName,
            work: item.projectName,
            // proposal: 'item.proposal',
            time: item.createdAt,
            // humanized
            newUnread: !item.isCheck,
            period,
            isExpired: period ? isExpired(period) : false
          };
        case "DENY_JOIN":
          return {
            id: item.id,
            profileUrl: item.imageSrc || "",
            profileAlt: item.userName || "",
            type: item.type,
            user: item.userName,
            work: item.projectName,
            // proposal: 'item.proposal',
            time: item.createdAt,
            // humanized
            newUnread: !item.isCheck,
            period: void 0,
            isExpired: false
          };
        default:
          return {
            id: "item.id",
            profileUrl: "",
            profileAlt: "",
            type: "NOTIFY_LEAVE",
            user: "item.user",
            work: "item.projectName",
            // proposal: 'item.proposal',
            time: /* @__PURE__ */ new Date(),
            // humanized
            period,
            newUnread: false,
            isExpired: period ? isExpired(period) : false
          };
      }
    },
    [checked, unchecked]
  );
  import_react10.default.useEffect(() => {
    console.log("noti screen: ", { checked, unchecked });
  }, [checked, unchecked]);
  const notificationList = (0, import_react10.useMemo)(() => {
    if (checked === void 0 || unchecked === void 0)
      return void 0;
    return [...unchecked.reverse(), ...checked.reverse()].map(convert);
  }, [checked, unchecked, convert]);
  if (!isShow)
    return null;
  return /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_components6.NotificationList, { titleVisible: false, list: notificationList });
};
var view_default = NotificationListView;

// src/screens/NotificationListScreen/index.tsx
var import_jsx_runtime7 = require("react/jsx-runtime");
var NotificationListScreen = ({ i18n, ...props }) => {
  console.log("NotificationListScreen");
  return /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(providers_default, { i18n, children: /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(provider_default2, { children: /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(view_default, { ...props }) }) });
};

// src/screens/WorkmodelCreateScreen/view.tsx
var import_components7 = require("@rocket/components");
var import_react_native = require("react-native");
var import_providers7 = require("@rocket-mono/providers");
var import_foundations = require("@rocket-mono/foundations");
var import_libs = require("@rocket-mono/libs");
var import_react11 = require("react");
var import_atoms = require("@rocket/atoms");
var import_jsx_runtime8 = require("react/jsx-runtime");
var WorkmodelCreateView = ({ onPressCreateWork, onPressClose }) => {
  const { t, i18n } = useTranslation();
  const { currentUser } = (0, import_providers7.useCurrentUser)();
  const businessData = (0, import_react11.useMemo)(() => {
    const data = [
      {
        id: "3",
        title: t("workmodel.business.csp.title"),
        description: t("workmodel.business.csp.description"),
        iconProps: {
          icon: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_atoms.WorkIcon.CSP, { size: 24 }),
          backgroundColor: "main.blue"
        },
        imageProps: {
          width: 164,
          marginRight: 20,
          uri: "https://rocket.is/files/file_box/69e6749834cc477cb426c2506704180d.png"
        },
        labelProps: {
          list: [
            t("workmodel.en"),
            t("workmodel.es"),
            t("workmodel.de"),
            t("workmodel.ko"),
            t("workmodel.vi"),
            t("workmodel.mn"),
            t("workmodel.jp")
          ]
        },
        onPress: () => {
          onPressCreateWork("CSP");
        }
      }
    ];
    data.push({
      id: "4",
      title: t("workmodel.business.top.title"),
      description: t("workmodel.business.top.description"),
      iconProps: {
        icon: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_atoms.WorkIcon.TOP, { size: 24 }),
        backgroundColor: "sub.darkPurple"
      },
      imageProps: {
        width: 164,
        marginRight: 20,
        uri: "https://rocket.is/files/file_box/b4f3cab221d7425d9e4a87ee763ba64a.png"
      },
      labelProps: {
        list: [
          t("workmodel.en"),
          t("workmodel.es"),
          t("workmodel.de"),
          t("workmodel.ko"),
          t("workmodel.vi"),
          t("workmodel.mn"),
          t("workmodel.jp")
        ]
      },
      onPress: () => {
        onPressCreateWork("TOP");
      }
    });
    return data;
  }, [currentUser]);
  const { width } = (0, import_react_native.useWindowDimensions)();
  const [workModelList, setWorkModelList] = (0, import_react11.useState)([
    {
      title: t("workmodel.product.category"),
      isFold: false,
      data: [
        {
          id: "1",
          title: t("workmodel.product.dsp.title"),
          description: t("workmodel.product.dsp.description"),
          iconProps: {
            icon: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_atoms.WorkIcon.DSP, { size: 24 }),
            backgroundColor: "main.navy"
          },
          imageProps: {
            width: 164,
            marginRight: 20,
            uri: "https://rocket.is/files/file_box/692e2d68a21944498cbce1927933e8d5.png"
          },
          labelProps: {
            list: [
              t("workmodel.en"),
              t("workmodel.es"),
              t("workmodel.de"),
              t("workmodel.ko"),
              t("workmodel.vi"),
              t("workmodel.mn"),
              t("workmodel.jp")
            ]
          },
          onPress: () => {
            onPressCreateWork("DSP");
          }
        },
        {
          id: "2",
          title: t("workmodel.product.wfp.title"),
          description: t("workmodel.product.wfp.description"),
          iconProps: {
            icon: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_atoms.WorkIcon.WFP, { size: 24 }),
            backgroundColor: "main.red"
          },
          imageProps: {
            width: 164,
            marginRight: 20,
            uri: "https://rocket.is/files/file_box/348bf215f84540d29bd8f171c75e0e5b.png"
          },
          labelProps: {
            list: [
              t("workmodel.en"),
              t("workmodel.es"),
              t("workmodel.de"),
              t("workmodel.ko"),
              t("workmodel.vi"),
              t("workmodel.mn"),
              t("workmodel.jp")
            ]
          },
          onPress: () => {
            onPressCreateWork("WFP");
          }
        }
      ]
    },
    {
      title: t("workmodel.business.category"),
      isFold: false,
      data: businessData
    }
  ]);
  return /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(
    import_foundations.View,
    {
      style: [
        { width: 560, backgroundColor: import_libs.COLOR.mono.white, borderRadius: 8 },
        560 > width && { width: "100%", minWidth: "100%" }
      ],
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
          import_foundations.Modal.Header,
          {
            textAlign: "left",
            closeIcon: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_components7.Icon, { name: "close", size: 20, color: "gray.g700" }),
            onPressClose,
            children: t("nav.makework")
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_foundations.Modal.Body, { style: { paddingTop: 0 }, children: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
          import_components7.WorkModelList,
          {
            title: t("workmodel.subtitle"),
            list: workModelList,
            onPressSection: (idx, isFold) => {
              setWorkModelList(
                (prev) => prev.map((item, index) => {
                  if (index === idx) {
                    return {
                      ...item,
                      isFold
                    };
                  } else {
                    return item;
                  }
                })
              );
            }
          }
        ) })
      ]
    }
  );
};

// src/screens/WorkmodelCreateScreen/index.tsx
var import_jsx_runtime9 = require("react/jsx-runtime");
var WorkmodelCreateScreen = ({ i18n, ...props }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(providers_default, { i18n, children: /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(WorkmodelCreateView, { ...props }) });
};

// src/screens/WorkmodelEditScreen/view.tsx
var import_components8 = require("@rocket/components");
var import_react_native2 = require("react-native");
var import_providers9 = require("@rocket-mono/providers");
var import_libs2 = require("@rocket-mono/libs");
var import_skeleton = require("@rocket/skeleton");
var import_jsx_runtime10 = require("react/jsx-runtime");
var WorkmodelEditView = ({ projectId, onPressClose, onBack, onSuccess, goHome }) => {
  const { t } = useTranslation();
  const { astro } = (0, import_providers9.useAstro)();
  const { width } = (0, import_react_native2.useWindowDimensions)();
  return /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(
    import_react_native2.View,
    {
      style: [
        { width: 560, backgroundColor: import_libs2.COLOR.mono.white, borderRadius: 8 },
        560 > width && { width: "100%", minWidth: "100%" }
      ],
      children: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(
        import_components8.WorkmodelUpdateView,
        {
          astro,
          containerStyle: { width: "100%" },
          style: { width: "100%" },
          id: projectId,
          onSuccess,
          onPressClose,
          onBack,
          goHome,
          skeletonComponent: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(import_react_native2.View, { style: { width: 530, height: 680 }, children: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(import_skeleton.SkeletonEditWorkModal, {}) })
        }
      )
    }
  );
};

// src/screens/WorkmodelEditScreen/index.tsx
var import_jsx_runtime11 = require("react/jsx-runtime");
var WorkmodelEditScreen = ({ i18n, ...props }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(providers_default, { i18n, children: /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(WorkmodelEditView, { ...props }) });
};

// src/screens/CreateWorkScreen/view.tsx
var import_react_div_100vh = require("react-div-100vh");
var import_foundations2 = require("@rocket-mono/foundations");
var import_components9 = require("@rocket/components");
var import_providers11 = require("@rocket-mono/providers");

// src/utils/explainmodel_translation.ts
var translation = {
  // 프로젝트 관리
  dsp_ko: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/dsp_ko_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/dsp_ko_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/dsp_ko_03.png"
  },
  dsp_en: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/dsp_en_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/dsp_en_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/dsp_en_03.png"
  },
  dsp_mn: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/dsp_mn_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/dsp_mn_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/dsp_mn_03.png"
  },
  dsp_vi: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/dsp_vi_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/dsp_vi_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/dsp_vi_03.png"
  },
  dsp_de: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/dsp_de_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/dsp_de_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/dsp_de_03.png"
  },
  dsp_es: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/dsp_es_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/dsp_es_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/dsp_es_03.png"
  },
  dsp_ja: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/dsp_jp_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/dsp_jp_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/dsp_jp_03.png"
  },
  // 워크플로우
  wfp_ko: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/wfp_ko_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/wfp_ko_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/wfp_ko_03.png"
  },
  wfp_en: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/wfp_en_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/wfp_en_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/wfp_en_03.png"
  },
  wfp_mn: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/wfp_mn_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/wfp_mn_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/wfp_mn_03.png"
  },
  wfp_vi: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/wfp_vi_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/wfp_vi_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/wfp_vi_03.png"
  },
  wfp_de: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/wfp_de_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/wfp_de_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/wfp_de_03.png"
  },
  wfp_es: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/wfp_es_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/wfp_es_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/wfp_es_03.png"
  },
  wfp_ja: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/wfp_jp_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/wfp_jp_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/wfp_jp_03.png"
  },
  // 일대다 상담 채팅
  csp_ko: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/csp_ko_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/csp_ko_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/csp_ko_03.png"
  },
  csp_en: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/csp_en_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/csp_en_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/csp_en_03.png"
  },
  csp_mn: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/csp_mn_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/csp_mn_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/csp_mn_03.png"
  },
  csp_vi: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/csp_vi_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/csp_vi_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/csp_vi_03.png"
  },
  csp_de: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/csp_de_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/csp_de_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/csp_de_03.png"
  },
  csp_es: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/csp_es_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/csp_es_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/csp_es_03.png"
  },
  csp_ja: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/csp_jp_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/csp_jp_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/csp_jp_03.png"
  },
  // 테이블 오더
  top_ko: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/top_ko_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/top_ko_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/top_ko_03.png"
  },
  top_en: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/top_en_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/top_en_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/top_en_03.png"
  },
  top_mn: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/top_mn_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/top_mn_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/top_mn_03.png"
  },
  top_vi: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/csp_vi_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/csp_vi_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/csp_vi_03.png"
  },
  top_de: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/top_de_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/top_de_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/top_de_03.png"
  },
  top_es: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/top_es_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/top_es_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/top_es_03.png"
  },
  top_ja: {
    image1: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/top_jp_01.png",
    image2: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/top_jp_02.png",
    image3: "https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/top_jp_03.png"
  }
};

// src/screens/CreateWorkScreen/view.tsx
var import_react_native3 = require("react-native");
var import_libs3 = require("@rocket-mono/libs");
var import_react12 = require("react");
var import_jsx_runtime12 = require("react/jsx-runtime");
var CreateWorkView = ({ typeCode, position, onPressBack, onSaveFailed, onSaveSuccess }) => {
  const { t, i18n } = useTranslation();
  const height = (0, import_react_div_100vh.use100vh)();
  const { astro } = (0, import_providers11.useAstro)();
  const [detailView, setDetailView] = (0, import_react12.useState)(true);
  const modelTranslation = (0, import_react12.useMemo)(() => {
    const tr = translation[`${typeCode.toLowerCase()}_${i18n.language}`];
    return tr === void 0 ? translation[`${typeCode.toLowerCase()}_en`] : tr;
  }, [typeCode, i18n.language]);
  console.log("modelTranslation", modelTranslation);
  const [contentOffsetY, setContentOffsetY] = (0, import_react12.useState)(0);
  const handleScroll = (0, import_react12.useCallback)((e2) => {
    const contentOffsetY2 = e2.nativeEvent.contentOffset.y;
    setContentOffsetY(contentOffsetY2 > 100 ? 100 : 1);
  }, []);
  return /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_foundations2.View, { style: { height: height ?? "100%", backgroundColor: import_libs3.COLOR.mono.white }, children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
    import_react_native3.ScrollView,
    {
      onScroll: handleScroll,
      scrollEventThrottle: 8,
      contentContainerStyle: { flex: 1, backgroundColor: import_libs3.COLOR.mono.white },
      children: detailView ? !!typeCode && /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
        import_components9.ExplainWorkModel,
        {
          contentOffsetY,
          workIcon: typeCode,
          workTitle: typeCode === "DSP" ? t(`workmodel.product.dsp.title`) : typeCode === "WFP" ? t(`workmodel.product.wfp.title`) : typeCode === "CSP" ? t(`workmodel.business.csp.title`) : typeCode === "TOP" ? t(`workmodel.business.top.title`) : "",
          workInfo: typeCode === "DSP" ? t(`workmodel.product.dsp.description`) : typeCode === "WFP" ? t(`workmodel.product.wfp.description`) : typeCode === "CSP" ? t(`workmodel.business.csp.description`) : typeCode === "TOP" ? t(`workmodel.business.top.description`) : "",
          workPrice: typeCode === "DSP" ? t(`workmodel.product.dsp.price`) : typeCode === "WFP" ? t(`workmodel.product.wfp.price`) : typeCode === "CSP" ? t(`workmodel.business.csp.price`) : typeCode === "TOP" ? t(`workmodel.business.top.price`) : "",
          workDescription: typeCode === "DSP" ? `${t(`workmodel.product.dsp.information`)}\r
${t(`workmodel.product.dsp.information1`)}\r
${t(
            `workmodel.product.dsp.information2`
          )}}` : typeCode === "WFP" ? `${t(`workmodel.product.wfp.information`)}\r
${t(`workmodel.product.wfp.information1`)}\r
${t(
            `workmodel.product.wfp.information2`
          )}}` : typeCode === "CSP" ? t(`workmodel.business.csp.information`) : typeCode === "TOP" ? `${t(`workmedel.business.top.information`)}\r
${t(`workmedel.business.top.information1`)}` : "",
          cardButtonItem: [
            {
              title: t(`workmodel.templates.empty.title`),
              info: t(`workmodel.templates.empty.info`),
              uri: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_foundations2.View, { style: styles.cardButtonContainer, children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_components9.EmptyTemplate, {}) }),
              ctaButton: true,
              ctaText: t(`explainworkmodel.header.start`),
              onPress: function() {
                setDetailView(false);
              }
            }
          ],
          carouselItem: [
            { id: 0, uri: modelTranslation.image1 },
            { id: 1, uri: modelTranslation.image2 },
            { id: 2, uri: modelTranslation.image3 }
          ],
          onBackPress: onPressBack,
          onStartPress: () => {
            setDetailView(false);
          },
          parentElement: "explain-modal",
          headerOffsetTop: 70
        }
      ) : /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
        import_components9.WorkmodelCreateScreen.Provider,
        {
          astro,
          onPressBack: () => {
            setDetailView(true);
          },
          onSaveSuccess,
          onSaveFailed,
          children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_components9.WorkmodelCreateScreen.Consumer, { children: (context) => context ? /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_components9.WorkmodelCreateScreen, { ...context, typeCode }) : /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_jsx_runtime12.Fragment, {}) })
        }
      )
    }
  ) });
};
var view_default2 = CreateWorkView;
var styles = import_react_native3.StyleSheet.create({
  cardButtonContainer: {
    marginTop: 40,
    width: 289,
    alignItems: "center"
  }
});

// src/screens/CreateWorkScreen/index.tsx
var import_jsx_runtime13 = require("react/jsx-runtime");
var CreateWorkScreen = ({ i18n, ...props }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(providers_default, { i18n, children: /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(view_default2, { ...props }) });
};
var CreateWorkScreen_default = CreateWorkScreen;

// src/screens/WorkSettingScreen/index.tsx
var import_react19 = require("react");
var import_libs10 = require("@rocket-mono/libs");

// src/screens/WorkSettingScreen/components/UserInfoView/index.tsx
var import_react13 = require("react");
var import_components10 = require("@rocket/components");
var import_providers13 = require("@rocket-mono/providers");
var import_jsx_runtime14 = require("react/jsx-runtime");
var UserInfoView = ({ onPressBack }) => {
  const { astro } = (0, import_providers13.useAstro)();
  const { fetchCurrentUser } = (0, import_providers13.useCurrentUser)();
  const [file, setFile] = (0, import_react13.useState)();
  return /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
    import_components10.WorkSettingUserInfoScreen,
    {
      astro,
      profileFile: file,
      onProfile: () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.onchange = (e2) => {
          const files = e2.target.files;
          if (files && files.length > 0) {
            setFile(files[0]);
          }
        };
        input.click();
      },
      onPressBack,
      onNicknameDone: () => {
        fetchCurrentUser();
      }
    }
  );
};
var UserInfoView_default = UserInfoView;

// src/screens/WorkSettingScreen/screen.tsx
var import_components12 = require("@rocket/components");
var import_react_native8 = require("react-native");
var import_foundations8 = require("@rocket-mono/foundations");
var import_libs9 = require("@rocket-mono/libs");

// src/screens/WorkSettingScreen/view.tsx
var import_foundations7 = require("@rocket-mono/foundations");
var import_libs8 = require("@rocket-mono/libs");
var import_providers14 = require("@rocket-mono/providers");
var import_components11 = require("@rocket/components");
var import_react15 = require("react");
var import_react_native7 = require("react-native");

// src/screens/WorkSettingScreen/components/SettingItem/index.tsx
var import_libs6 = require("@rocket-mono/libs");
var import_foundations5 = require("@rocket-mono/foundations");

// src/screens/WorkSettingScreen/components/SettingItem/mobile.tsx
var import_react_native4 = require("react-native");
var import_libs4 = require("@rocket-mono/libs");
var import_foundations3 = require("@rocket-mono/foundations");
var import_atoms2 = require("@rocket/atoms");
var import_jsx_runtime15 = require("react/jsx-runtime");
var SettingItemMobile = ({ item, onPress }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_react_native4.Pressable, { onPress: () => onPress && onPress(item.tab), children: /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)(import_foundations3.View, { style: [styles2.nav], children: [
    /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_foundations3.View, { style: { marginRight: 8 }, children: /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_atoms2.Icon, { name: item.icon, size: 20, color: "gray.g300", style: { marginTop: -2 } }) }),
    /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)(import_foundations3.View, { style: [styles2.menuArea, item.tab === "logout" && { borderBottomWidth: 0 }], children: [
      /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_foundations3.View, { children: /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_atoms2.Text, { fontName: "txtMdMedium", children: item.title }) }),
      /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)(import_foundations3.View, { style: styles2.opt, children: [
        /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)(import_foundations3.View, { style: { alignItems: "flex-end" }, children: [
          /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_atoms2.Text, { fontName: "txtXs", fontColor: "gray.g500", children: item.label }),
          item.tab === "notice" && /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_atoms2.Text, { fontName: "txtXs", fontColor: "primary.blue500", children: item.update })
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_foundations3.View, { style: { marginHorizontal: 4 }, children: /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_atoms2.Icon, { name: "angle-right-min", size: 16, color: "gray.g450" }) })
      ] })
    ] })
  ] }) });
};
var mobile_default = SettingItemMobile;
var styles2 = import_react_native4.StyleSheet.create({
  nav: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center"
  },
  menuArea: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderColor: import_libs4.COLOR.gray.g150
  },
  opt: {
    flexDirection: "row",
    alignItems: "center",
    textAlign: "right"
  }
});

// src/screens/WorkSettingScreen/components/SettingItem/web.tsx
var import_react14 = require("react");
var import_react_native5 = require("react-native");
var import_libs5 = require("@rocket-mono/libs");
var import_foundations4 = require("@rocket-mono/foundations");
var import_icon = require("@rocket-atoms/icon");
var import_atoms3 = require("@rocket/atoms");
var import_jsx_runtime16 = require("react/jsx-runtime");
var SettingItemWeb = ({ item, tab, onPress }) => {
  const [isHover, setIsHover] = (0, import_react14.useState)(false);
  return /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(
    import_react_native5.Pressable,
    {
      onHoverIn: () => setIsHover(true),
      onHoverOut: () => setIsHover(false),
      onPress: () => onPress && onPress(item.tab),
      children: /* @__PURE__ */ (0, import_jsx_runtime16.jsxs)(
        import_foundations4.View,
        {
          style: [
            styles3.nav,
            styles3.navWeb,
            isHover && { backgroundColor: import_libs5.COLOR.gray.g100 },
            item.tab === tab && { backgroundColor: import_libs5.COLOR.primary.blue500 }
          ],
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(import_foundations4.View, { style: { marginRight: 8 }, children: /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(
              import_icon.Icon,
              {
                name: item.icon,
                size: 20,
                color: item.tab === tab ? "mono.white" : "gray.g300",
                style: { marginTop: -2 }
              }
            ) }),
            /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(import_foundations4.View, { children: /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(import_foundations4.View, { children: /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(import_atoms3.Text, { fontName: "txtMd", fontColor: item.tab === tab ? "mono.white" : "mono.black", children: item.title }) }) })
          ]
        }
      )
    }
  );
};
var web_default = SettingItemWeb;
var styles3 = import_react_native5.StyleSheet.create({
  nav: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center"
  },
  navWeb: {
    height: 48,
    borderRadius: 8,
    marginBottom: 4,
    paddingHorizontal: 12
  },
  menuArea: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderColor: import_libs5.COLOR.gray.g150
  }
});

// src/screens/WorkSettingScreen/components/SettingItem/index.tsx
var import_jsx_runtime17 = require("react/jsx-runtime");
var SettingItem = ({ list, tab, onPress }) => {
  const isMobile = (0, import_libs6.useMobileView)(960);
  return /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(import_foundations5.View, { children: list.map((item, idx) => {
    const key = `menu-${idx}`;
    const props = { item, key, tab, onPress };
    return isMobile ? /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(mobile_default, { ...props }) : /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(web_default, { ...props });
  }) });
};
var SettingItem_default = SettingItem;

// src/screens/WorkSettingScreen/components/title.tsx
var import_foundations6 = require("@rocket-mono/foundations");
var import_libs7 = require("@rocket-mono/libs");
var import_atoms4 = require("@rocket/atoms");
var import_react_native6 = require("react-native");
var import_jsx_runtime18 = require("react/jsx-runtime");
var SettingTitle = ({ title, buttonIconElements, style }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime18.jsxs)(import_foundations6.View, { style: [styles4.container, style], children: [
    /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(import_atoms4.Text, { style: styles4.text, fontName: "H5Bold", fontColor: "mono.black", children: title }),
    buttonIconElements && /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(import_foundations6.View, { style: styles4.buttonContainer, children: buttonIconElements })
  ] });
};
var title_default = SettingTitle;
var styles4 = import_react_native6.StyleSheet.create({
  container: {
    height: 64,
    backgroundColor: import_libs7.COLOR.mono.white,
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 20
  },
  buttonContainer: {
    flexDirection: "row",
    marginRight: -4
  },
  text: {
    flex: 1
  }
});

// src/screens/WorkSettingScreen/type.ts
var langData = [
  { label: "English", key: "en" },
  { label: "\uD55C\uAD6D\uC5B4", key: "ko" },
  { label: "Espa\xF1ol", key: "es" },
  { label: "Deutsch", key: "de" },
  { label: "Ti\u1EBFng Vi\u1EC7t", key: "vi" },
  { label: "\u041C\u043E\u043D\u0433\u043E\u043B", key: "mn" },
  { label: "\u65E5\u672C\u8A9E", key: "ja" }
];

// src/screens/WorkSettingScreen/view.tsx
var import_atoms5 = require("@rocket/atoms");
var import_jsx_runtime19 = require("react/jsx-runtime");
var stateData = [
  {
    labelIcon: { name: "full-moon", size: 14, color: "status.active" },
    label: "Active",
    key: "1"
  },
  {
    labelIcon: { name: "full-moon", size: 14, color: "status.busy" },
    label: "Busy",
    key: "2"
  },
  {
    labelIcon: { name: "full-moon", size: 14, color: "status.off" },
    label: "Off",
    key: "3"
  }
];
var SettingView = ({ tab, width = 300, onPressItem, onPressBack, onLogout }) => {
  const isMobile = (0, import_libs8.useMobileView)(960);
  const { t } = useTranslation();
  const { astro, option } = (0, import_providers14.useAstro)();
  const { currentUser } = (0, import_providers14.useCurrentUser)();
  const { showDialogMessage } = (0, import_providers14.useModalDialog)();
  const userData = (0, import_react15.useMemo)(() => {
    const { userName: name, userEmail: email, userState } = currentUser;
    const profile = `${option.secureCdnUrl}/profile/${email}`;
    return { name, email, profile, userState };
  }, [currentUser]);
  const menuData = (0, import_react15.useMemo)(
    () => [
      {
        title: t("setting.userinfo.title"),
        // '내 정보 수정',
        icon: "user",
        label: "",
        update: "",
        tab: "edit"
      },
      // {
      //   title: t('setting.menu.social'), // '소셜 로그인 연동',
      //   icon: 'all' as const,
      //   label: '',
      //   update: '',
      //   tab: 'sns',
      // },
      {
        title: t("setting.language.title"),
        // '언어',
        icon: "font",
        label: langData.find(({ key }) => key === currentUser.lang)?.label,
        update: "",
        tab: "lang"
      },
      {
        title: t("setting.timezone.title"),
        // '타임존',
        icon: "time",
        label: t("setting.timezone.auto"),
        update: "",
        tab: "time"
      },
      // {
      //   title: t('setting.menu.notice'), // '공지사항 및 업데이트',
      //   icon: 'shield-checked' as const,
      //   label: '2.4v',
      //   update: '업데이트 (2023/11/11)',
      //   tab: 'notice',
      // },
      {
        title: t("setting.menu.logout"),
        // '로그아웃',
        icon: "log-out",
        label: "",
        update: "",
        tab: "logout"
      }
    ],
    [t, currentUser]
  );
  const handlePressItem = (0, import_react15.useCallback)((tab2) => {
    if (tab2 === "logout") {
      showDialogMessage({
        title: t("setting.logout.title"),
        message: t("setting.logout.message"),
        list: [
          {
            name: t("setting.logout.alllogout"),
            action: async () => {
              astro.logoutByAllSection().then(onLogout);
            }
          },
          {
            name: t("setting.logout.thislogout"),
            action: async () => {
              astro.logoutByCurrentSection().then(onLogout);
            }
          },
          {
            name: t("setting.logout.thiswidoutlogout"),
            action: async () => {
              astro.logoutByExceptCurrentSection();
            }
          }
        ],
        subList: [],
        cancelText: t("setting.logout.cancel"),
        onCancel: () => console.log("onCancel")
      });
    } else {
      onPressItem(tab2);
    }
  }, []);
  return isMobile ? /* @__PURE__ */ (0, import_jsx_runtime19.jsxs)(import_foundations7.View, { style: { paddingHorizontal: 20 }, children: [
    /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(
      title_default,
      {
        title: t("setting.title"),
        style: { paddingLeft: 0 },
        buttonIconElements: /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(import_components11.ButtonIcon, { iconName: "close", iconSize: 20, iconColor: "gray.g700", onPress: onPressBack })
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(import_components11.SettingProfile, { data: userData, stateData }),
    /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(import_foundations7.View, { style: styles5.menu, children: /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(SettingItem_default, { list: menuData, tab, onPress: handlePressItem }) })
  ] }) : /* @__PURE__ */ (0, import_jsx_runtime19.jsxs)(
    import_foundations7.View,
    {
      style: [
        { height: "100%", backgroundColor: import_libs8.COLOR.gray.g050, paddingHorizontal: 8, paddingVertical: 20 },
        { width }
      ],
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime19.jsxs)(import_components11.Button, { backgroundColor: "gray.g150", style: styles5.button, onPress: onPressBack, children: [
          /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(import_components11.Icon, { name: "arrow-left", size: 16, color: "gray.g700" }),
          /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(import_atoms5.Text, { children: t("setting.back") })
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(import_foundations7.View, { style: styles5.userInfo, children: /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(import_components11.SettingProfile, { data: userData, stateData }) }),
        /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(
          import_foundations7.View,
          {
            style: [
              styles5.menu,
              {
                paddingTop: 20,
                borderTopWidth: 1,
                borderColor: import_libs8.COLOR.gray.g150
              }
            ],
            children: /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(SettingItem_default, { list: menuData, tab, onPress: handlePressItem })
          }
        )
      ]
    }
  );
};
var view_default3 = SettingView;
var styles5 = import_react_native7.StyleSheet.create({
  menu: {
    paddingTop: 8
  },
  userInfo: {
    marginTop: 32
  },
  button: {
    flexDirection: "row",
    paddingHorizontal: 12
  }
});

// src/screens/WorkSettingScreen/screen.tsx
var import_jsx_runtime20 = require("react/jsx-runtime");
var Screen = ({ children, ...viewProps }) => {
  const isMobile = (0, import_libs9.useMobileView)(960);
  return isMobile ? /* @__PURE__ */ (0, import_jsx_runtime20.jsxs)(import_jsx_runtime20.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(view_default3, { ...viewProps }),
    children
  ] }) : /* @__PURE__ */ (0, import_jsx_runtime20.jsxs)(import_foundations8.View, { style: styles6.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(view_default3, { ...viewProps }),
    /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(import_foundations8.View, { style: styles6.contentsArea, children: /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(import_foundations8.View, { style: styles6.conpanel, children }) }),
    /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(import_foundations8.View, { style: styles6.closeButton, children: /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(import_components12.ButtonIcon, { iconName: "close", iconSize: 20, iconColor: "gray.g700", onPress: viewProps.onPressBack }) })
  ] });
};
var screen_default = Screen;
var styles6 = import_react_native8.StyleSheet.create({
  container: {
    flexDirection: "row",
    flex: 1
  },
  contentsArea: {
    flex: 1,
    alignItems: "center",
    paddingTop: 124,
    paddingHorizontal: 32
  },
  conpanel: {
    maxWidth: 700,
    width: "100%"
  },
  closeButton: {
    position: "absolute",
    top: 24,
    right: 24
  }
});

// src/screens/WorkSettingScreen/provider.tsx
var import_react16 = require("react");
var import_components13 = require("@rocket/components");
var import_providers15 = require("@rocket-mono/providers");
var import_components14 = require("@rocket/components");
var import_jsx_runtime21 = require("react/jsx-runtime");
var Provider2 = ({ children }) => {
  const { i18n } = useTranslation();
  const { astro } = (0, import_providers15.useAstro)();
  const { client: stompClient } = (0, import_providers15.useStomp)();
  const { currentUser } = (0, import_providers15.useCurrentUser)();
  (0, import_react16.useEffect)(() => {
    console.log("i18n::-provider", i18n.language);
  }, [i18n]);
  return /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(
    import_components13.WorkSettingProvider,
    {
      astro,
      publishState: (userState, userStateDate) => {
        console.log("test");
        stompClient && stompClient.publish({
          destination: "/publish/state",
          body: JSON.stringify({
            userNo: currentUser.id,
            platform: "web",
            userState,
            stateDate: userStateDate
          }),
          headers: {
            "content-type": "application/json"
          }
        });
      },
      children: /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(import_components14.FoundationProvider, { language: i18n.language, children: /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(import_jsx_runtime21.Fragment, { children }) })
    }
  );
};
var provider_default3 = Provider2;

// src/screens/WorkSettingScreen/components/LanguageView.tsx
var import_react17 = require("react");
var import_components15 = require("@rocket/components");
var import_providers16 = require("@rocket-mono/providers");
var import_jsx_runtime22 = require("react/jsx-runtime");
var LanguageView = ({ onPressBack }) => {
  const { t } = useTranslation();
  const { astro } = (0, import_providers16.useAstro)();
  const { showToastMessage } = (0, import_components15.useToast)();
  const { currentUser, fetchCurrentUser } = (0, import_providers16.useCurrentUser)();
  const [currentUserLang, setCurrentUserLang] = (0, import_react17.useState)(currentUser.lang || "en");
  const [currentLangIndex, setCurrentLangIndex] = (0, import_react17.useState)(langData.findIndex(({ key }) => key === currentUserLang));
  (0, import_react17.useEffect)(() => {
    console.log("currentUser.lang", currentUser.lang);
  }, [currentUser]);
  return /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(
    import_components15.WorkSettingLanguage,
    {
      defaultIndex: currentLangIndex,
      data: langData,
      onSelected: (code) => {
        console.log("onSelected", code);
        setCurrentUserLang(code);
      },
      onPressSave: () => {
        const lang = currentUserLang;
        astro.updateMe({
          userName: currentUser.userName,
          password: "",
          passwordConfirm: "",
          lang
        }).then(fetchCurrentUser).then(() => {
          const duration = 1e3;
          showToastMessage({
            message: t("toast.confirmed"),
            viewPosition: "BOTTOM_CENTER",
            duration
          });
        });
        setCurrentLangIndex(langData.findIndex((item) => item.key === lang));
      },
      onDismiss: onPressBack
    }
  );
};
var LanguageView_default = LanguageView;

// src/screens/WorkSettingScreen/components/TimezoneView.tsx
var import_react18 = require("react");
var import_components16 = require("@rocket/components");
var import_jsx_runtime23 = require("react/jsx-runtime");
var TimezoneView = ({ onPressBack }) => {
  const { t, i18n } = useTranslation();
  const timezoneData = (0, import_react18.useMemo)(
    () => [
      { label: t("setting.timezone.auto"), key: "1" }
      // {label: '한국 표준 시', key: '2'},
      // {label: 'USA 표준 시', key: '3'},
      // {label: '인도차이나 표준시', key: '4'},
    ],
    [t]
  );
  const timezoneDropdownList = (0, import_react18.useMemo)(
    () => [
      {
        group: [
          {
            id: 1,
            title: t("setting.timezone.auto"),
            // '자동으로 설정',
            code: "1"
          }
        ]
      }
      // {
      //   group: [
      //     {
      //       id: 1,
      //       title: '한국 표준 시',
      //       code: '2',
      //     },
      //   ],
      // },
      // {
      //   group: [
      //     {
      //       id: 1,
      //       title: 'USA 표준 시',
      //       code: '3',
      //     },
      //   ],
      // },
      // {
      //   group: [
      //     {
      //       id: 1,
      //       title: '인도차이나 표준시',
      //       code: '4',
      //     },
      //   ],
      // },
    ],
    [t]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(
    import_components16.WorkSettingTimezone,
    {
      data: timezoneData,
      appData: timezoneDropdownList,
      defaultIndex: 0,
      onSelected: (code) => console.log("time onSelected", code),
      onPressSave: () => console.log("time onPressSave"),
      onDismiss: onPressBack
    }
  );
};
var TimezoneView_default = TimezoneView;

// src/screens/WorkSettingScreen/index.tsx
var import_jsx_runtime24 = require("react/jsx-runtime");
var WorkSettingScreen = ({ i18n, ...props }) => {
  const isMobile = (0, import_libs10.useMobileView)(960);
  const [tab, setTab] = (0, import_react19.useState)(isMobile ? "" : "edit");
  return /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(providers_default, { i18n, children: /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(provider_default3, { children: /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(screen_default, { tab, onPressItem: setTab, ...props, children: tab === "edit" ? /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(UserInfoView_default, { onPressBack: () => setTab("") }) : tab === "lang" ? /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(LanguageView_default, { onPressBack: () => setTab("") }) : tab === "time" ? /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(TimezoneView_default, { onPressBack: () => setTab("") }) : /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(import_jsx_runtime24.Fragment, {}) }) }) });
};
var WorkSettingScreen_default = WorkSettingScreen;

// src/MemberInviteEmail/index.tsx
var import_react20 = __toESM(require("react"));

// ../../../node_modules/@fortawesome/free-solid-svg-icons/index.mjs
var faUser = {
  prefix: "fas",
  iconName: "user",
  icon: [448, 512, [128100, 62144], "f007", "M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"]
};
var faPen = {
  prefix: "fas",
  iconName: "pen",
  icon: [512, 512, [128394], "f304", "M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"]
};
var faAngleDown = {
  prefix: "fas",
  iconName: "angle-down",
  icon: [448, 512, [8964], "f107", "M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"]
};
var faPlus = {
  prefix: "fas",
  iconName: "plus",
  icon: [448, 512, [10133, 61543, "add"], "2b", "M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"]
};
var faXmark = {
  prefix: "fas",
  iconName: "xmark",
  icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"]
};
var faAngleUp = {
  prefix: "fas",
  iconName: "angle-up",
  icon: [448, 512, [8963], "f106", "M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"]
};
var faCheck = {
  prefix: "fas",
  iconName: "check",
  icon: [448, 512, [10003, 10004], "f00c", "M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"]
};
var faAngleLeft = {
  prefix: "fas",
  iconName: "angle-left",
  icon: [320, 512, [8249], "f104", "M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"]
};
var faCircleXmark = {
  prefix: "fas",
  iconName: "circle-xmark",
  icon: [512, 512, [61532, "times-circle", "xmark-circle"], "f057", "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"]
};

// src/MemberInviteEmail/index.tsx
var import_foundations9 = require("@rocket-mono/foundations");
var import_libs11 = require("@rocket-mono/libs");
var import_atoms6 = require("@rocket/atoms");
var import_components17 = require("@rocket/components");
var import_react_native9 = require("react-native");
var import_jsx_runtime25 = require("react/jsx-runtime");
var MemberInviteEmail = ({ onPressAdd }) => {
  const { t } = useTranslation();
  const [isHover, setIsHover] = (0, import_react20.useState)(false);
  const inputNameRef = (0, import_react20.useRef)(null);
  const inputEmailRef = (0, import_react20.useRef)(null);
  const [errorMailFiled, setErrorMailFiled] = import_react20.default.useState("");
  const [validationEmail, setValidationEmail] = import_react20.default.useState(false);
  const [inputNameValue, setInputNameValue] = import_react20.default.useState("");
  const [inputEmailValue, setInputEmailValue] = import_react20.default.useState("");
  const emailCheck = (email) => {
    const regEmail = new RegExp(/^([0-9a-zA-Z_\\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/);
    regEmail.exec(email) ? setValidationEmail(true) : setValidationEmail(false);
  };
  const allChecked = () => {
    if (!inputEmailValue) {
      setErrorMailFiled(t("emailinvitemember.error.email"));
    } else {
      if (validationEmail) {
        setErrorMailFiled("");
        onPressAdd && onPressAdd(inputNameValue, inputEmailValue);
        setInputNameValue("");
        setInputEmailValue("");
        if (inputNameRef.current) {
          inputNameRef.current.innerText = "";
        }
        if (inputEmailRef.current) {
          inputEmailRef.current.innerText = "";
        }
        inputNameRef.current?.focus();
      } else {
        setErrorMailFiled(t("emailinvitemember.error.emailvalid"));
      }
    }
  };
  const handleChangeMailChange = import_react20.default.useCallback((value) => {
    emailCheck(value);
    setInputEmailValue(value);
  }, []);
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_foundations9.View, { style: styles7.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_atoms6.Text, { style: styles7.sectionTitle, children: t("workinvite.invite.formemail.label") }),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_foundations9.View, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_foundations9.View, { children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
        import_components17.Input,
        {
          inputRef: inputNameRef,
          value: inputNameValue,
          autoFocus: true,
          height: 36,
          placeholder: t("workinvite.invite.formemail.placeholder.name"),
          borderType: "solid",
          containerStyle: styles7.inputStyle,
          onChangeText: (value) => setInputNameValue(value),
          onKeyDown: (e2) => {
            if (e2.key === "Enter") {
              inputEmailRef.current?.focus();
            }
          }
        }
      ) }),
      /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_foundations9.View, { style: styles7.formStyle, children: [
        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
          import_components17.Input,
          {
            inputRef: inputEmailRef,
            value: inputEmailValue,
            height: 36,
            onChangeText: handleChangeMailChange,
            placeholder: t("workinvite.invite.formemail.placeholder.email"),
            errorMessage: errorMailFiled,
            borderType: "solid",
            containerStyle: styles7.inputStyle,
            onKeyDown: (e2) => {
              if (e2.nativeEvent.key === "Enter") {
                allChecked();
              }
            }
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
          import_react_native9.Pressable,
          {
            onPress: () => allChecked(),
            onHoverIn: () => setIsHover(true),
            onHoverOut: () => setIsHover(false),
            style: [styles7.buttonArea, isHover && { opacity: 0.7 }],
            children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_atoms6.FAIcon, { iconName: faPlus, color: "gray.g700" })
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_atoms6.Text, { style: styles7.refText, children: t("workinvite.invite.formemail.forminfo") })
  ] });
};
var MemberInviteEmail_default = MemberInviteEmail;
var styles7 = import_react_native9.StyleSheet.create({
  container: {
    position: "relative"
  },
  sectionTitle: {
    ...import_libs11.FONT.txtSmMedium,
    color: import_libs11.COLOR.gray.g600,
    marginBottom: 8
  },
  inputStyle: {
    borderRadius: 4,
    borderColor: import_libs11.COLOR.gray.g200
  },
  formStyle: {
    position: "relative",
    marginTop: 4
  },
  buttonArea: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    width: 32,
    height: 32,
    top: 2,
    right: 4
  },
  refText: {
    marginTop: 4,
    ...import_libs11.FONT.txt2XsMedium,
    color: import_libs11.COLOR.gray.g500
  }
});

// src/MemberInviteWorkList/index.tsx
var import_react21 = require("react");
var import_foundations10 = require("@rocket-mono/foundations");
var import_libs12 = require("@rocket-mono/libs");
var import_atoms7 = require("@rocket/atoms");
var import_react_native10 = require("react-native");
var import_jsx_runtime26 = require("react/jsx-runtime");
var Item = ({ item, onPress }) => {
  const [isHover, setIsHover] = (0, import_react21.useState)(false);
  return /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(
    import_react_native10.Pressable,
    {
      onPress: () => onPress(item),
      onHoverIn: () => setIsHover(true),
      onHoverOut: () => setIsHover(false),
      style: [styles8.itemContainer, isHover && styles8.itemHoverContainer],
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(import_foundations10.View, { style: styles8.nameArea, children: [
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(import_atoms7.WorkIcon, { type: item.type, size: 20 }),
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(import_atoms7.Text, { style: styles8.worknameStyle, numberOfLines: 1, ellipsizeMode: "tail", children: item.name })
        ] }),
        item.active && /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(import_foundations10.View, { style: styles8.stateLabel, children: [
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(import_atoms7.FAIcon, { iconName: faUser, size: "xs", color: "gray.g400" }),
          /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(import_atoms7.Text, { style: { marginLeft: 2, ...import_libs12.FONT.txtSm, color: import_libs12.COLOR.gray.g600 }, children: item.active })
        ] })
      ]
    }
  );
};
var MemberInviteWorkList = ({ scrollViewHeight, workList, onPress }) => {
  const { t } = useTranslation();
  return /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(import_foundations10.View, { style: styles8.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(import_atoms7.Text, { style: styles8.sectionTitle, children: t("workinvite.invite.fromwork.label") }),
    /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
      import_react_native10.ScrollView,
      {
        style: { paddingHorizontal: 2, height: scrollViewHeight },
        contentContainerStyle: { flex: 1, height: "100%" },
        children: workList.map((item, idx) => /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(import_foundations10.View, { style: styles8.itemWrap, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(Item, { item, onPress }) }, `workList-item${idx}`))
      }
    )
  ] });
};
var MemberInviteWorkList_default = MemberInviteWorkList;
var styles8 = import_react_native10.StyleSheet.create({
  container: {
    position: "relative"
  },
  itemContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 12,
    paddingVertical: 10,
    borderRadius: 8,
    backgroundColor: import_libs12.COLOR.mono.white
  },
  nameArea: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center"
  },
  itemHoverContainer: {
    ...(0, import_libs12.getBoxShadow)(0, 0, 0, 0.15, 2, 0, 0, 0)
  },
  itemWrap: {
    marginBottom: 8
  },
  sectionTitle: {
    ...import_libs12.FONT.txtSmMedium,
    color: import_libs12.COLOR.gray.g600,
    marginBottom: 8
  },
  worknameStyle: {
    ...import_libs12.FONT.txtMdMedium,
    marginTop: 2,
    marginLeft: 8
  },
  stateLabel: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 4,
    borderRadius: 4,
    backgroundColor: import_libs12.COLOR.gray.g100
  }
});

// src/MemberInviteList/index.tsx
var import_foundations12 = require("@rocket-mono/foundations");
var import_libs16 = require("@rocket-mono/libs");
var import_react_native14 = require("react-native");

// src/InviteMemberItem/index.tsx
var import_react22 = __toESM(require("react"));
var import_foundations11 = require("@rocket-mono/foundations");
var import_libs15 = require("@rocket-mono/libs");
var import_atoms8 = require("@rocket/atoms");
var import_components18 = require("@rocket/components");
var import_react_native13 = require("react-native");

// ../../foundations/src/Dropdown/Button.tsx
var React5 = __toESM(require("react"));
var import_react_native11 = require("react-native");
var import_libs13 = require("@rocket-mono/libs");
var import_icon2 = require("@rocket-atoms/icon");
var import_jsx_runtime27 = require("react/jsx-runtime");
function DropdownButton({
  text,
  code,
  border = true,
  isOpen,
  onOpen,
  onbuttonSize,
  onOffsetY,
  dropdownList,
  onDrop,
  containerStyle,
  isEnable,
  initTitle,
  notification,
  beforeIcon,
  style,
  txtStyle,
  divide
}) {
  const { t } = useTranslation();
  const mainButtonRef = React5.useRef(null);
  const [isHover, setIsHover] = React5.useState(false);
  const useSelectedItem = React5.useMemo(() => {
    const selectItems = dropdownList.filter((item) => item.code === code);
    if (selectItems.length > 0) {
      return selectItems[0].title;
    } else {
      return initTitle ? initTitle : t("dropdown.initcode");
    }
  }, [dropdownList, code, t]);
  return /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_react_native11.View, { style: [styles9.container, containerStyle, { opacity: isEnable ? 1 : 0.5 }], children: /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)(
    import_react_native11.Pressable,
    {
      ref: mainButtonRef,
      onLayout: () => {
        if (mainButtonRef.current && mainButtonRef.current.measure) {
          mainButtonRef.current.measure((_x, _y, _width, _height, _pageY) => {
            onbuttonSize && onbuttonSize({ width: _width, height: _height });
            onOffsetY && onOffsetY(_pageY);
          });
        }
      },
      style: [
        styles9.dropbutton,
        border && { borderWidth: 1 },
        style,
        !divide && isOpen ? {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0
        } : void 0,
        isHover && { borderColor: import_libs13.COLOR.gray.g450 },
        notification && { borderColor: import_libs13.COLOR.primary.blue500 }
      ],
      onPress: () => {
        if (mainButtonRef.current && mainButtonRef.current.measure) {
          mainButtonRef.current.measure((_x, _y, _width, _height, _pageY) => {
            onbuttonSize && onbuttonSize({ width: _width, height: _height });
            onOffsetY && onOffsetY(_pageY);
          });
        }
        if (isEnable) {
          onOpen && onOpen(!isOpen);
          onDrop && onDrop(!isOpen);
        }
      },
      onHoverIn: () => setIsHover(true),
      onHoverOut: () => setIsHover(false),
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)(import_react_native11.View, { style: { flexDirection: "row" }, children: [
          beforeIcon && /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_react_native11.View, { style: { marginTop: 2, marginRight: 4 }, children: /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_icon2.FAIcon, { iconName: beforeIcon, size: "xs", color: notification ? "primary.blue500" : "gray.g800" }) }),
          /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_react_native11.Text, { style: [styles9.buttonText, txtStyle], children: text ? text : useSelectedItem })
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_react_native11.View, { style: { marginTop: 2 }, children: /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_icon2.FAIcon, { iconName: isOpen ? faAngleUp : faAngleDown, color: "mono.black", size: "xs" }) })
      ]
    }
  ) });
}
var styles9 = import_react_native11.StyleSheet.create({
  container: {
    width: "100%"
  },
  dropbutton: {
    backgroundColor: import_libs13.COLOR.mono.white,
    borderColor: import_libs13.COLOR.gray.g300,
    borderRadius: 4,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 8,
    paddingHorizontal: 8,
    height: 34
  },
  buttonText: {
    ...import_libs13.FONT.txtSm,
    marginTop: -2,
    color: import_libs13.COLOR.mono.black
  }
});
var Button_default = React5.memo(DropdownButton);

// ../../foundations/src/Dropdown/SecondaryButton.tsx
var React6 = __toESM(require("react"));
var import_react_native12 = require("react-native");
var import_libs14 = require("@rocket-mono/libs");
var import_icon3 = require("@rocket-atoms/icon");
var import_jsx_runtime28 = require("react/jsx-runtime");
function DropdownSecondaryButton({
  // code,
  isOpen,
  // onOpen,
  onOffsetY,
  // dropdownList,
  // onDrop,
  onPress,
  containerStyle,
  isEnable,
  title,
  onResize
}) {
  const mainButtonRef = React6.useRef(null);
  React6.useEffect(() => {
    const listener = () => {
      onResize && mainButtonRef.current && mainButtonRef.current.measure((_x, _y, _width, _height, _pageX, pageY) => onResize(pageY));
    };
    if (import_react_native12.Platform.OS === "web") {
      window.addEventListener("resize", listener);
    }
    return () => {
      if (import_react_native12.Platform.OS === "web") {
        window.removeEventListener("resize", listener);
      }
    };
  }, [onResize]);
  return /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(import_react_native12.View, { style: [styles10.container, containerStyle, { opacity: isEnable ? 1 : 0.5 }], children: /* @__PURE__ */ (0, import_jsx_runtime28.jsxs)(
    import_react_native12.Pressable,
    {
      ref: mainButtonRef,
      onLayout: () => {
        if (mainButtonRef.current && mainButtonRef.current.measure) {
          mainButtonRef.current.measure((_x, _y, _width, _height, _pageX, pageY) => {
            onOffsetY && onOffsetY(pageY);
          });
        }
      },
      style: [
        styles10.dropbutton,
        isOpen ? {
          backgroundColor: import_libs14.COLOR.gray.g100
        } : void 0
      ],
      onPress,
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(import_react_native12.Text, { style: styles10.buttonText, children: title ? title : "" }),
        /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(import_react_native12.View, { style: { marginLeft: 4 }, children: /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(import_icon3.FAIcon, { iconName: isOpen ? faAngleUp : faAngleDown, size: "xs", color: "gray.g600" }) })
      ]
    }
  ) });
}
var styles10 = import_react_native12.StyleSheet.create({
  container: {},
  dropbutton: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: import_libs14.COLOR.gray.g050,
    height: 34,
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 4
  },
  listButton: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 8,
    paddingHorizontal: 10,
    borderColor: "#D5D5D5",
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    height: 35
  },
  buttonText: {
    ...import_libs14.FONT.txtSm
  },
  listText: {
    ...import_libs14.FONT.textRegular,
    fontSize: 12,
    color: import_libs14.COLOR.mono.black
  }
});
var SecondaryButton_default = React6.memo(DropdownSecondaryButton);

// src/InviteMemberItem/index.tsx
var import_jsx_runtime29 = require("react/jsx-runtime");
var InviteMemberItem = ({
  item,
  idx,
  // authorityValue,
  // renderAuthorityPop,
  // renderBoardPop,
  unarchiveList,
  archiveList,
  handleRemove,
  handleValueChange,
  handleBoardPopup,
  secureCdnUrl
}) => {
  const { t } = useTranslation();
  const dimensions = import_react_native13.Dimensions.get("window");
  const [isEdit, setIsEdit] = (0, import_react22.useState)(false);
  const [isEditButtonHover, setIsEditButtonHover] = (0, import_react22.useState)(false);
  const [deleteButtonHover, setDeleteButtonHover] = (0, import_react22.useState)(false);
  const [boardPop, setBoardPop] = (0, import_react22.useState)(false);
  const [authorityPop, setAuthorityPop] = (0, import_react22.useState)(false);
  const [selectedBoardList, setSelectedBoardList] = (0, import_react22.useState)([]);
  const authority = (0, import_react22.useMemo)(() => {
    if (item.authority === "admin") {
      return t("workinvite.memberoption.authority.admin");
    } else if (item.authority === "normal") {
      return t("workinvite.memberoption.authority.normal");
    } else if (item.authority === "guest") {
      return t("workinvite.memberoption.authority.guest");
    } else {
      return "";
    }
  }, [item.authority]);
  const boardButton = (0, import_react22.useMemo)(() => {
    if (item.isPriorityInvite) {
      return "\uC6B0\uC120 \uCD08\uB300";
    } else if (item.selectedBoardList.length > 0) {
      return t("workinvite.memberoption.board.invite", { count: item.selectedBoardList.length });
    } else {
      return t("workinvite.memberoption.board.select");
    }
  }, [item.isPriorityInvite, item.selectedBoardList]);
  const initUnarchiveListIndex = (0, import_react22.useCallback)(() => {
    const selectedList = [];
    if (!unarchiveList)
      return selectedList;
    unarchiveList.map((item2, index) => {
      if (selectedBoardList.includes(item2.projectElementId)) {
        selectedList.push(index);
      }
    });
    return selectedList;
  }, [selectedBoardList, unarchiveList]);
  const initArchiveListIndex = (0, import_react22.useCallback)(() => {
    const selectedList = [];
    if (!archiveList)
      return selectedList;
    archiveList.map((item2, index) => {
      if (selectedBoardList.includes(item2.projectElementId)) {
        selectedList.push(index);
      }
    });
    return selectedList;
  }, [selectedBoardList, archiveList]);
  import_react22.default.useEffect(() => {
    handleBoardPopup(selectedBoardList);
  }, [selectedBoardList]);
  const fadeAddIndi = import_react22.default.useRef(new import_react_native13.Animated.Value(0)).current;
  import_react22.default.useEffect(() => {
    handleAnimation();
  }, [item.addIndication]);
  const handleAnimation = () => {
    import_react_native13.Animated.timing(fadeAddIndi, {
      toValue: 1,
      duration: 800,
      useNativeDriver: false
    }).start();
  };
  const colorChange = fadeAddIndi.interpolate({
    inputRange: [0, 1],
    outputRange: [`rgba(194,224,255, 1)`, `rgba(255,255,255, 1)`]
  });
  return /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
    import_react_native13.Animated.View,
    {
      style: [
        { zIndex: 1e4 - idx },
        item.addIndication && { backgroundColor: colorChange },
        deleteButtonHover && { backgroundColor: import_libs15.COLOR.gray.g050 }
      ],
      children: /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(import_foundations11.View, { style: styles11.container, children: [
        /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_components18.ImageProfile, { size: 36, uri: `${secureCdnUrl}/profile/${item.email}` }),
        /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(import_foundations11.View, { style: styles11.contentsArea, children: [
          /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_foundations11.View, { style: styles11.nameInfoArea, children: /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(import_foundations11.View, { style: styles11.nameInfo, children: [
            /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(import_foundations11.View, { style: styles11.nameInput, children: [
              isEdit ? /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
                import_foundations11.TextInput,
                {
                  value: item.name,
                  autoFocus: true,
                  onBlur: () => setIsEdit(false),
                  onChangeText: (text) => handleValueChange("name", text),
                  onKeyPress: (e2) => {
                    if (e2.nativeEvent.key === "Enter") {
                      setIsEdit(false);
                    }
                  },
                  inputStyle: { width: "auto", ...import_libs15.FONT.txtSmMedium, height: "auto", padding: 0 }
                }
              ) : /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_react_native13.Pressable, { onPress: () => setIsEdit(true), children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_atoms8.Text, { style: { ...import_libs15.FONT.txtSmMedium }, children: item.name }) }),
              !isEdit && /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
                import_react_native13.Pressable,
                {
                  onPress: () => setIsEdit(true),
                  onHoverIn: () => setIsEditButtonHover(true),
                  onHoverOut: () => setIsEditButtonHover(false),
                  style: styles11.editButton,
                  children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_atoms8.FAIcon, { iconName: faPen, size: "2xs", color: isEditButtonHover ? "gray.g600" : "gray.g300" })
                }
              )
            ] }),
            /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_atoms8.Text, { style: styles11.emailText, children: item.email })
          ] }) }),
          /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(import_foundations11.View, { style: styles11.settingArea, children: [
            /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(import_foundations11.View, { style: styles11.boardSetting, children: [
              boardPop && /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_foundations11.View, { style: { position: "absolute", top: 0, right: 0, bottom: 0, left: 0, zIndex: 9 } }),
              /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
                Button_default,
                {
                  text: boardButton,
                  isOpen: boardPop,
                  onOpen: (open) => {
                    setBoardPop(open);
                    setSelectedBoardList(item.selectedBoardList);
                  },
                  dropdownList: [],
                  isEnable: true,
                  divide: true
                }
              ),
              boardPop && /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_foundations11.View, { style: { position: "absolute", width: "100%", top: 36, left: 0 }, children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
                import_foundations11.ClickOutside,
                {
                  onClickOutside: () => {
                    setBoardPop(false);
                    handleValueChange("errorMessage", "");
                  },
                  children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_foundations11.View, { style: styles11.popover, children: /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(import_foundations11.View, { style: { overflow: "scroll", maxHeight: dimensions.height / 4 }, children: [
                    /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
                      import_components18.PopListSectionTabs,
                      {
                        label: t("workinvite.memberoption.board.progress"),
                        itemCount: unarchiveList ? unarchiveList.length : 0,
                        countTextColor: "main.red",
                        children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
                          import_components18.SelectList,
                          {
                            initialSelectedIndexes: initUnarchiveListIndex(),
                            onPressItem: (unarchiveListIndex) => {
                              if (unarchiveList) {
                                setSelectedBoardList((prev) => {
                                  const board = unarchiveList[unarchiveListIndex];
                                  const newList = [...prev];
                                  const index = newList.findIndex((item2) => item2 === board.projectElementId);
                                  if (index > -1) {
                                    return (0, import_libs15.removeItemAtIndex)(newList, index);
                                  } else {
                                    newList.push(board.projectElementId);
                                    return newList;
                                  }
                                });
                              }
                            },
                            children: unarchiveList && unarchiveList.map((board) => /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_components18.SelectList.Item, { text: board.roomName }))
                          }
                        )
                      }
                    ),
                    /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
                      import_components18.PopListSectionTabs,
                      {
                        label: t("workinvite.memberoption.board.archive"),
                        itemCount: archiveList ? archiveList.length : 0,
                        countTextColor: "gray.g450",
                        divStyle: true,
                        children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
                          import_components18.SelectList,
                          {
                            initialSelectedIndexes: initArchiveListIndex(),
                            onPressItem: (archiveListIndex) => {
                              if (archiveList) {
                                setSelectedBoardList((prev) => {
                                  const board = archiveList[archiveListIndex];
                                  const newList = [...prev];
                                  const index = newList.findIndex((item2) => item2 === board.projectElementId);
                                  if (index > -1) {
                                    newList.splice(index, 1);
                                  } else {
                                    newList.push(board.projectElementId);
                                  }
                                  return newList;
                                });
                              }
                            },
                            children: archiveList && archiveList.map((board) => /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_components18.SelectList.Item, { text: board.roomName }))
                          }
                        )
                      }
                    )
                  ] }) })
                }
              ) })
            ] }),
            /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(import_foundations11.View, { style: { position: "relative", marginLeft: 8 }, children: [
              authorityPop && /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_foundations11.View, { style: { position: "absolute", top: 0, right: 0, bottom: 0, left: 0, zIndex: 9 } }),
              /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
                SecondaryButton_default,
                {
                  onPress: () => setAuthorityPop(!authorityPop),
                  isOpen: authorityPop,
                  title: authority,
                  isEnable: true
                }
              ),
              authorityPop && /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_foundations11.View, { style: { position: "absolute", top: 36, right: 0 }, children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_foundations11.ClickOutside, { onClickOutside: () => setAuthorityPop(false), children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
                import_foundations11.Select,
                {
                  mode: "LIGHT",
                  width: 200,
                  list: [
                    {
                      beforeDiv: false,
                      afterDiv: true,
                      group: [
                        {
                          type: "COMMON",
                          label: t("workinvite.memberoption.authority.admin"),
                          aboutLabel: t("workinvite.memberoption.authority.aboutadmin"),
                          afterIconName: faCheck,
                          selected: item.authority === "admin",
                          onPress: () => handleValueChange("authority", "admin")
                        }
                      ]
                    },
                    {
                      beforeDiv: false,
                      afterDiv: true,
                      group: [
                        {
                          type: "COMMON",
                          label: t("workinvite.memberoption.authority.normal"),
                          aboutLabel: t("workinvite.memberoption.authority.aboutnormal"),
                          afterIconName: faCheck,
                          selected: item.authority === "normal",
                          onPress: () => handleValueChange("authority", "normal")
                        }
                      ]
                    },
                    {
                      beforeDiv: false,
                      afterDiv: true,
                      group: [
                        {
                          type: "COMMON",
                          label: t("workinvite.memberoption.authority.guest"),
                          aboutLabel: t("workinvite.memberoption.authority.aboutguest"),
                          afterIconName: faCheck,
                          selected: item.authority === "guest",
                          onPress: () => handleValueChange("authority", "guest")
                        }
                      ]
                    }
                    // FIXME: 권한 정보는 다음 마일스톤으로 보류됨
                    // {
                    //   beforeDiv: false,
                    //   afterDiv: false,
                    //   group: [
                    //     {
                    //       type: 'INFO',
                    //       beforeIconName: faCircleInfo,
                    //       beforeIconSize: '2xs',
                    //       beforeIconColor: 'gray.g400',
                    //       label: t('workinvite.memberoption.authority.info'),
                    //       labelSize: 12,
                    //       onPress: () => console.log('탭'),
                    //     },
                    //   ],
                    // },
                  ],
                  visible: true
                }
              ) }) })
            ] })
          ] }),
          item.errorMessage ? /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_foundations11.View, { style: styles11.errorMessageContainer, children: boardPop || authorityPop ? /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_foundations11.View, { style: styles11.errorMessageEmptyContainer }) : /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(import_jsx_runtime29.Fragment, { children: [
            /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_atoms8.Icon, { name: "info", size: 16, color: "status.busy" }),
            /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_atoms8.Text, { fontName: "txtXsMedium", fontColor: "status.busy", children: item.errorMessage })
          ] }) }) : /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_jsx_runtime29.Fragment, {})
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
          import_react_native13.Pressable,
          {
            onPress: () => handleRemove(idx),
            onHoverIn: () => setDeleteButtonHover(true),
            onHoverOut: () => setDeleteButtonHover(false),
            style: styles11.deleteButton,
            children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_atoms8.FAIcon, { iconName: faCircleXmark, color: deleteButtonHover ? "gray.g600" : "gray.g400" })
          }
        )
      ] })
    }
  );
};
var InviteMemberItem_default = InviteMemberItem;
var styles11 = import_react_native13.StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: import_libs15.COLOR.gray.g150,
    paddingVertical: 12
  },
  contentsArea: {
    flex: 1,
    marginLeft: 8
  },
  nameInfoArea: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  nameInfo: {
    flex: 1,
    marginLeft: 4,
    marginRight: 8
  },
  nameInput: {
    flexDirection: "row",
    alignItems: "center"
  },
  emailText: {
    ...import_libs15.FONT.txtXs,
    color: import_libs15.COLOR.gray.g500
  },
  settingArea: {
    flexDirection: "row",
    flex: 1,
    marginTop: 8
  },
  boardSetting: {
    position: "relative",
    flex: 1
  },
  deleteButton: {
    margin: 4
  },
  editButton: {
    padding: 4
  },
  popover: {
    backgroundColor: import_libs15.COLOR.mono.white,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: import_libs15.COLOR.gray.g200,
    paddingTop: 4,
    ...(0, import_libs15.getBoxShadow)(0, 0, 0, 0.05, 7, 0, 0, 0),
    zIndex: 100
  },
  errorMessageContainer: {
    flexDirection: "row",
    marginTop: 7
  },
  errorMessageEmptyContainer: {
    height: 16
  }
});

// src/MemberInviteList/index.tsx
var import_jsx_runtime30 = require("react/jsx-runtime");
var MemberInviteList = ({
  secureCdnUrl,
  list,
  unarchiveList,
  archiveList,
  handleRemove,
  handleValueChange,
  handleBoardPopup
}) => {
  return /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(import_foundations12.View, { children: list?.map((item, idx) => /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
    InviteMemberItem_default,
    {
      item,
      idx,
      secureCdnUrl,
      unarchiveList,
      archiveList,
      handleRemove,
      handleValueChange: (key, value, type) => handleValueChange(idx, key, value, type),
      handleBoardPopup: (list2) => handleBoardPopup(idx, list2)
    },
    `invite-member-item-${item.key}`
  )) });
};
var MemberInviteList_default = MemberInviteList;
var styles12 = import_react_native14.StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: import_libs16.COLOR.gray.g150,
    paddingVertical: 12
  },
  contentsArea: {
    flex: 1,
    marginLeft: 8
  },
  nameInfoArea: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  nameInfo: {
    flex: 1,
    marginLeft: 4,
    marginRight: 8
  },
  nameInput: {
    flexDirection: "row",
    alignItems: "center"
  },
  emailText: {
    ...import_libs16.FONT.txtXs,
    color: import_libs16.COLOR.gray.g500
  },
  settingArea: {
    flexDirection: "row",
    paddingRight: 40,
    marginTop: 8
  },
  deleteButton: {
    margin: 4
  },
  editButton: {
    padding: 4
  },
  popover: {
    backgroundColor: import_libs16.COLOR.mono.white,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: import_libs16.COLOR.gray.g200,
    paddingVertical: 4,
    ...(0, import_libs16.getBoxShadow)(0, 0, 0, 0.05, 7, 0, 0, 0),
    zIndex: 100
  }
});

// src/MemberInviteWorkDetail/index.tsx
var import_react23 = require("react");
var import_foundations13 = require("@rocket-mono/foundations");
var import_libs19 = require("@rocket-mono/libs");
var import_atoms11 = require("@rocket/atoms");
var import_components21 = require("@rocket/components");
var import_react_native17 = require("react-native");

// src/MemberList/selectMemberList.tsx
var import_libs18 = require("@rocket-mono/libs");
var import_atoms10 = require("@rocket/atoms");
var import_components20 = require("@rocket/components");
var React8 = __toESM(require("react"));
var import_react_native16 = require("react-native");

// src/MemberList/Item.tsx
var import_react_native15 = require("react-native");
var import_libs17 = require("@rocket-mono/libs");
var import_components19 = require("@rocket/components");
var import_atoms9 = require("@rocket/atoms");
var import_jsx_runtime31 = require("react/jsx-runtime");
var MemberItem = ({ secureCdnUrl, item }) => {
  const { t } = useTranslation();
  const statePretty = (state) => {
    switch (state) {
      case "\uB098":
        return t("memberlist.state.me");
      case "\uAD00\uB9AC\uC790":
        return t("memberlist.state.admin");
      case "\uC18C\uC720\uC790":
        return t("memberlist.state.owner");
      case "\uCD08\uB300":
        return t("memberlist.state.invite");
      case "\uBA64\uBC84":
        return t("memberlist.state.member");
      default:
        return "";
    }
  };
  return /* @__PURE__ */ (0, import_jsx_runtime31.jsxs)(import_react_native15.View, { style: styles13.rowCenterContainer, children: [
    /* @__PURE__ */ (0, import_jsx_runtime31.jsxs)(import_react_native15.View, { style: styles13.imageProfileContainer, children: [
      item.userId && item.userId !== "null" ? /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(import_components19.ImageProfile, { uri: `${secureCdnUrl}/profile/${item.email}`, alt: item.name, size: 40 }) : /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(import_react_native15.View, { style: { width: 40, height: 40, backgroundColor: import_libs17.COLOR.gray.g150, borderRadius: 40 } }),
      item.positionLeader && /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(import_react_native15.View, { style: styles13.positionLabel, children: /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(import_atoms9.IconProfileLabelLeader, {}) })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime31.jsxs)(import_react_native15.View, { style: styles13.userInfoStyle, children: [
      /* @__PURE__ */ (0, import_jsx_runtime31.jsxs)(import_react_native15.View, { style: styles13.userNameStyle, children: [
        item.state ? /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
          import_react_native15.View,
          {
            style: [
              styles13.userStateStyle,
              item.state === "\uB098" && {
                backgroundColor: import_libs17.COLOR.main.blue,
                borderColor: import_libs17.COLOR.transparent.base
              }
            ],
            children: /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
              import_react_native15.Text,
              {
                style: [
                  styles13.userStateText,
                  item.state === "\uB098" && {
                    color: import_libs17.COLOR.mono.white
                  }
                ],
                children: statePretty(item.state)
              }
            )
          }
        ) : /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(import_jsx_runtime31.Fragment, {}),
        /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(import_react_native15.Text, { style: styles13.userNameText, numberOfLines: 1, children: item.name }),
        item.isNewInvite ? /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(import_react_native15.View, { style: styles13.userInvite }) : /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(import_jsx_runtime31.Fragment, {})
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(import_react_native15.Text, { style: styles13.userMailText, numberOfLines: 1, children: item.email })
    ] })
  ] });
};
var Item_default = MemberItem;
var styles13 = import_react_native15.StyleSheet.create({
  rowCenterContainer: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    justifyContent: "flex-start",
    flexShrink: 1
  },
  imageProfileContainer: {
    position: "relative"
  },
  positionLabel: {
    position: "absolute",
    bottom: -2,
    right: -4
  },
  userInfoStyle: { marginLeft: 8, marginRight: 16, flex: 1 },
  userNameStyle: {
    flexDirection: "row",
    marginBottom: 1,
    flex: 1
  },
  userStateStyle: {
    height: 18,
    borderWidth: 1,
    borderColor: import_libs17.COLOR.mono.lightGray,
    borderRadius: 30,
    paddingHorizontal: 4,
    marginTop: 2,
    marginRight: 4
  },
  userStateText: {
    ...import_libs17.FONT.chatSubTextThin,
    color: import_libs17.COLOR.mono.paleBlack,
    fontWeight: "400",
    lineHeight: 15
  },
  userNameText: {
    ...import_libs17.FONT.textMedium,
    color: import_libs17.COLOR.mono.black,
    ...import_react_native15.Platform.select({
      android: {
        alignSelf: "center"
      }
    })
  },
  userInvite: {
    height: 6,
    width: 6,
    backgroundColor: (0, import_libs17.getRealColor)("main.red"),
    borderRadius: 25
  },
  userMailText: {
    ...import_libs17.FONT.subTextRegular,
    color: import_libs17.COLOR.mono.darkGray,
    fontWeight: "400"
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
  }
});

// src/MemberList/selectMemberList.tsx
var import_jsx_runtime32 = require("react/jsx-runtime");
var Item2 = ({ secureCdnUrl, item }) => {
  const { t } = useTranslation();
  const [isHover, setIsHover] = React8.useState(false);
  return /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(
    import_react_native16.Pressable,
    {
      onPress: () => {
        if (item.state === "\uB098")
          return;
        if (item.selected || typeof item.memberSelected !== void 0 && !item.memberSelected) {
          item.onInviteMember && item.onInviteMember(item.userId);
        }
      },
      onHoverIn: () => setIsHover(true),
      onHoverOut: () => setIsHover(false),
      style: [isHover && { backgroundColor: import_libs18.COLOR.gray.g050 }],
      children: /* @__PURE__ */ (0, import_jsx_runtime32.jsxs)(import_react_native16.View, { style: styles14.itemContainer, children: [
        /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(Item_default, { secureCdnUrl, item }),
        item.state !== "\uB098" ? item.selected || typeof item.memberSelected !== void 0 && !item.memberSelected ? /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(import_react_native16.View, { style: styles14.rightAreaContainer, children: /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(import_react_native16.View, { children: /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(
          import_react_native16.Pressable,
          {
            style: [{ marginBottom: 4 }, item.selected ? styles14.iconCheck : styles14.iconCheckNone],
            onPress: () => {
              item.onInviteMember && item.onInviteMember(item.userId);
            },
            children: /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(import_atoms10.FAIcon, { iconName: faCheck, size: "sm", color: "mono.white" })
          }
        ) }) }) : /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(import_jsx_runtime32.Fragment, {}) : /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(import_react_native16.View, { children: /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(import_components20.Text, { fontName: "textMedium", children: t("checkparticipants.me") }) })
      ] })
    }
  );
};
var SelectMemberList = ({ secureCdnUrl, inviteList }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(
    import_react_native16.FlatList,
    {
      style: { paddingHorizontal: 20 },
      data: inviteList,
      keyExtractor: (_, index) => `member-list-invite-item_${index}`,
      renderItem: ({ item }) => /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(Item2, { secureCdnUrl, item })
    }
  );
};
var selectMemberList_default = SelectMemberList;
var styles14 = import_react_native16.StyleSheet.create({
  itemContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 8
  },
  rightAreaContainer: {
    flexWrap: "wrap",
    flexDirection: "row",
    alignContent: "center"
  },
  iconCheckNone: {
    width: 28,
    height: 28,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: import_libs18.COLOR.mono.lightGray
  },
  iconCheck: {
    width: 28,
    height: 28,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: import_libs18.COLOR.main.blue,
    borderRadius: 30
  }
});

// src/MemberInviteWorkDetail/index.tsx
var import_jsx_runtime33 = require("react/jsx-runtime");
var MemberInviteWorkDetail = ({ workName, workType, secureCdnUrl, height, inviteList, onPressClose }) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = (0, import_react23.useState)("");
  return /* @__PURE__ */ (0, import_jsx_runtime33.jsxs)(import_foundations13.View, { style: [styles15.container, { maxHeight: height }], children: [
    /* @__PURE__ */ (0, import_jsx_runtime33.jsxs)(import_foundations13.View, { style: styles15.headerArea, children: [
      /* @__PURE__ */ (0, import_jsx_runtime33.jsxs)(import_foundations13.View, { style: styles15.workTitle, children: [
        /* @__PURE__ */ (0, import_jsx_runtime33.jsxs)(import_foundations13.View, { style: styles15.workName, children: [
          /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(import_atoms11.WorkIcon, { type: workType, size: 20 }),
          /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(import_atoms11.Text, { style: styles15.workNameText, numberOfLines: 1, ellipsizeMode: "tail", children: workName })
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(import_react_native17.Pressable, { onPress: onPressClose, children: /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(import_atoms11.FAIcon, { iconName: faXmark, color: "gray.g600" }) })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(import_foundations13.View, { children: /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(
        import_components21.Input,
        {
          placeholder: t("workinvite.invite.fromwork.detail.searchplaceholder"),
          height: 36,
          rightIcon: searchText ? {
            name: "close-circle",
            color: "gray.g400",
            onPress: () => setSearchText("")
          } : void 0,
          rightIconSize: 16,
          rightIcon2: { name: "search", color: "gray.g700" },
          rightIconSize2: 16,
          rightIconStyle2: { marginTop: -2, marginLeft: 8 },
          onChangeText: (text) => {
            setSearchText(text);
          }
        }
      ) })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(import_react_native17.ScrollView, { style: styles15.listArea, children: /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(selectMemberList_default, { secureCdnUrl: secureCdnUrl || "", inviteList }) })
  ] });
};
var MemberInviteWorkDetail_default = MemberInviteWorkDetail;
var styles15 = import_react_native17.StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 8,
    ...(0, import_libs19.getBoxShadow)(0, 0, 0, 0.05, 7, 0, 0, 0),
    paddingVertical: 12,
    backgroundColor: import_libs19.COLOR.mono.white
  },
  headerArea: {
    backgroundColor: import_libs19.COLOR.mono.white,
    paddingBottom: 12,
    paddingHorizontal: 12
  },
  workTitle: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 12
  },
  workName: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center"
  },
  workNameText: {
    ...import_libs19.FONT.txtMdMedium,
    marginLeft: 4
  },
  listArea: {
    flex: 1
  }
});

// src/MemberInviteScreen/contents.tsx
var import_react24 = require("react");
var import_foundations14 = require("@rocket-mono/foundations");
var import_libs20 = require("@rocket-mono/libs");
var import_atoms12 = require("@rocket/atoms");
var import_components22 = require("@rocket/components");
var import_react_native18 = require("react-native");
var import_jsx_runtime34 = require("react/jsx-runtime");
var MemberInviteScreen = ({
  currentProject,
  columnPoint,
  onPressClose,
  inviteWorkList,
  inviteMemberList,
  secureCdnUrl,
  unarchiveList,
  archiveList,
  onEmailInvite,
  inviteMemberHandleRemove,
  inviteMemberHandleValueChange,
  inviteMemberHandleBoardPopup,
  onInvite
}) => {
  const { t } = useTranslation();
  const windowHeight = import_react_native18.Dimensions.get("window").height;
  const workListDynamicHeight = windowHeight - 350;
  const inviteListDynamicHeight = windowHeight - 350;
  const popBodyHeight = 470;
  const [selectedId, setSelectedId] = (0, import_react24.useState)();
  const [isMemberOptionScreen, setIsMemberOptionScreen] = (0, import_react24.useState)(false);
  const workDetail = (0, import_react24.useMemo)(() => {
    return inviteWorkList.find((item) => item.id === selectedId);
  }, [inviteWorkList, selectedId]);
  const submitButtonContainer = () => {
    return /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_foundations14.View, { style: styles16.bottomButtonArea, children: /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
      import_foundations14.Button,
      {
        wide: true,
        disabled: inviteMemberList.length > 0 ? false : true,
        style: [
          {
            height: 50,
            borderRadius: 0,
            borderBottomRightRadius: 8
          },
          columnPoint && { borderBottomRightRadius: 0 }
        ],
        onPress: onInvite,
        children: /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_atoms12.Text, { style: { ...import_libs20.FONT.H7Medium, color: import_libs20.COLOR.mono.white }, children: inviteMemberList.length > 0 ? `${inviteMemberList.length}${t("workinvite.memberoption.submit.isValue")}` : t("workinvite.memberoption.submit.normal") })
      }
    ) });
  };
  return /* @__PURE__ */ (0, import_jsx_runtime34.jsxs)(import_foundations14.View, { style: [{ width: "100%" }, columnPoint ? { height: "100%" } : { height: 552 }], children: [
    /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
      import_foundations14.Modal.Header,
      {
        height: 82,
        textAlign: "left",
        closeIcon: /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_components22.Icon, { name: "close" }),
        onPressClose: () => {
          onPressClose();
        },
        children: /* @__PURE__ */ (0, import_jsx_runtime34.jsxs)(import_foundations14.View, { style: { width: "100%" }, children: [
          /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_foundations14.View, { style: styles16.headerTopContainer, children: /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_atoms12.Text, { fontName: "H7Medium", fontColor: "gray.g900", children: t("workinvite.title") }) }),
          /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_foundations14.View, { style: styles16.headerBottomContainer, children: /* @__PURE__ */ (0, import_jsx_runtime34.jsxs)(import_foundations14.View, { style: [styles16.labelContainer], children: [
            /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_atoms12.WorkIcon, { type: currentProject?.type.code || "", size: 20 }),
            /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
              import_atoms12.Text,
              {
                fontName: "txtMdMedium",
                fontColor: "gray.g900",
                style: { marginLeft: 4, marginTop: 2 },
                numberOfLines: 1,
                ellipsizeMode: "tail",
                children: currentProject?.title
              }
            )
          ] }) })
        ] })
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
      import_foundations14.Modal.Body,
      {
        style: { position: "relative", padding: 0 },
        maxHeight: "100%",
        innerStyle: { width: "100%", height: "100%" },
        contentContainerStyle: { flex: 1 },
        children: /* @__PURE__ */ (0, import_jsx_runtime34.jsxs)(import_foundations14.View, { style: [styles16.bodyArea, columnPoint && { flexDirection: "column", flex: 1 }], children: [
          isMemberOptionScreen ? /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_foundations14.View, { style: { position: "relative" }, children: /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
            import_foundations14.View,
            {
              style: {
                backgroundColor: import_libs20.COLOR.mono.white,
                marginHorizontal: 20,
                marginBottom: 12,
                borderBottomWidth: 1,
                borderColor: import_libs20.COLOR.gray.g050,
                paddingVertical: 8
              },
              children: /* @__PURE__ */ (0, import_jsx_runtime34.jsxs)(
                import_foundations14.Button,
                {
                  onPress: () => {
                    setIsMemberOptionScreen(false);
                    setSelectedId(void 0);
                  },
                  backgroundColor: "transparent",
                  style: { flexDirection: "row", alignItems: "center" },
                  children: [
                    /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_atoms12.FAIcon, { iconName: faAngleLeft, size: "xs", color: "gray.g400" }),
                    /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_atoms12.Text, { style: { marginLeft: 4 }, children: t("workinvite.title") })
                  ]
                }
              )
            }
          ) }) : /* @__PURE__ */ (0, import_jsx_runtime34.jsxs)(
            import_foundations14.View,
            {
              style: [
                styles16.searchArea,
                { height: popBodyHeight },
                columnPoint && { flex: 1, width: "100%", paddingBottom: 70 },
                !columnPoint && { borderBottomLeftRadius: 8 }
              ],
              children: [
                /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_foundations14.View, { style: styles16.emailSearch, children: /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(MemberInviteEmail_default, { onPressAdd: onEmailInvite }) }),
                /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_foundations14.View, { style: { flex: 1 }, children: inviteWorkList.length > 0 ? /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
                  MemberInviteWorkList_default,
                  {
                    scrollViewHeight: columnPoint ? workListDynamicHeight : 230,
                    workList: inviteWorkList,
                    onPress: (item) => setSelectedId(item.id)
                  }
                ) : /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_foundations14.View, { style: styles16.itemCenter, children: /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
                  import_atoms12.NoContentsIcon,
                  {
                    iconWidth: 80,
                    type: "board",
                    text: t("workinvite.invite.fromwork.empty"),
                    textSize: "normal"
                  }
                ) }) }),
                selectedId && workDetail ? /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_foundations14.View, { style: [styles16.workDetailLayer, columnPoint && { bottom: 70 }], children: /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
                  MemberInviteWorkDetail_default,
                  {
                    secureCdnUrl,
                    workName: workDetail.name,
                    workType: workDetail.type,
                    inviteList: workDetail.detailWork,
                    onPressClose: () => setSelectedId(void 0)
                  }
                ) }) : /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_jsx_runtime34.Fragment, {}),
                columnPoint && !isMemberOptionScreen && /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
                  import_foundations14.View,
                  {
                    style: [
                      {
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%"
                      }
                    ],
                    children: /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
                      import_foundations14.Button,
                      {
                        wide: true,
                        disabled: inviteMemberList.length > 0 ? false : true,
                        style: [
                          {
                            height: 50,
                            borderRadius: 0
                          }
                        ],
                        onPress: () => setIsMemberOptionScreen(true),
                        children: /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_atoms12.Text, { style: { ...import_libs20.FONT.H7Medium, color: import_libs20.COLOR.mono.white }, children: inviteMemberList.length > 0 ? `${inviteMemberList.length}${t("workinvite.memberoption.submit.isValue")}` : t("workinvite.memberoption.submit.normal") })
                      }
                    )
                  }
                )
              ]
            }
          ),
          !columnPoint || columnPoint && isMemberOptionScreen ? /* @__PURE__ */ (0, import_jsx_runtime34.jsxs)(
            import_foundations14.View,
            {
              style: [
                styles16.inviteListArea,
                columnPoint && styles16.inviteListColumnArea,
                columnPoint && isMemberOptionScreen && { display: "flex", height: "100%", zIndex: 999 }
              ],
              children: [
                columnPoint && /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
                  import_atoms12.Text,
                  {
                    style: [
                      styles16.sectionTitle,
                      { paddingLeft: 24 },
                      columnPoint && { ...import_libs20.FONT.txtMdMedium, color: import_libs20.COLOR.gray.g700 }
                    ],
                    children: t("workinvite.memberoption.label")
                  }
                ),
                inviteMemberList.length > 0 ? /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
                  import_react_native18.ScrollView,
                  {
                    style: [
                      styles16.inviteList,
                      { maxHeight: columnPoint ? "100%" : popBodyHeight - 50 },
                      columnPoint && { height: inviteListDynamicHeight }
                    ],
                    children: /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
                      MemberInviteList_default,
                      {
                        secureCdnUrl,
                        list: inviteMemberList,
                        unarchiveList,
                        archiveList,
                        handleRemove: inviteMemberHandleRemove,
                        handleValueChange: inviteMemberHandleValueChange,
                        handleBoardPopup: inviteMemberHandleBoardPopup
                      }
                    )
                  }
                ) : /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_foundations14.View, { style: [styles16.itemCenter, { marginTop: -50 }], children: /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
                  import_atoms12.NoContentsIcon,
                  {
                    iconWidth: 100,
                    type: "member",
                    text: t("workinvite.memberoption.empty"),
                    textSize: "normal"
                  }
                ) }),
                !columnPoint && submitButtonContainer()
              ]
            }
          ) : /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_jsx_runtime34.Fragment, {}),
          columnPoint && isMemberOptionScreen && submitButtonContainer()
        ] })
      }
    )
  ] });
};
var contents_default = MemberInviteScreen;
var styles16 = import_react_native18.StyleSheet.create({
  container: {
    width: 424,
    minHeight: 560,
    backgroundColor: import_libs20.COLOR.mono.white,
    borderRadius: 8
  },
  headerTopContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 4,
    width: 350
  },
  headerBottomContainer: {
    marginTop: 4
  },
  labelContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center"
  },
  footerButton: {
    height: 50,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6
  },
  bodyArea: {
    flexDirection: "row",
    height: "100%"
  },
  searchArea: {
    width: "50%",
    backgroundColor: import_libs20.COLOR.gray.g050,
    paddingVertical: 16,
    paddingHorizontal: 24
  },
  emailSearch: {
    borderBottomWidth: 1,
    borderColor: import_libs20.COLOR.gray.g150,
    paddingBottom: 20,
    marginBottom: 20
  },
  inviteListArea: {
    width: "50%",
    zIndex: 99
  },
  inviteListColumnArea: {
    flex: 1,
    width: "100%",
    height: "50%",
    backgroundColor: import_libs20.COLOR.mono.white,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    zIndex: 999
  },
  inviteList: {
    paddingTop: 8,
    paddingBottom: 80,
    paddingHorizontal: 24
  },
  itemCenter: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  workDetailLayer: {
    position: "absolute",
    zIndex: 99,
    top: 16,
    right: 20,
    bottom: 20,
    left: 20
  },
  sectionTitle: {
    ...import_libs20.FONT.txtSmMedium,
    color: import_libs20.COLOR.gray.g600,
    marginBottom: 8
  },
  bottomButtonArea: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    backgroundColor: import_libs20.COLOR.mono.white,
    borderBottomRightRadius: 8,
    zIndex: 999
  },
  dimmedCover: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 99,
    backgroundColor: import_libs20.COLOR.dimmed.a40
  }
});

// src/MemberInviteScreen/index.tsx
var import_react25 = require("react");
var import_foundations15 = require("@rocket-mono/foundations");
var import_libs21 = require("@rocket-mono/libs");
var import_skeleton2 = require("@rocket/skeleton");
var import_react_native19 = require("react-native");
var import_jsx_runtime35 = require("react/jsx-runtime");
var MemberInviteScreenModal = ({
  i18n,
  skeleton = true,
  secureCdnUrl,
  currentProject,
  onPressClose,
  inviteWorkList,
  inviteMemberList,
  // inviteWorkDetail,
  unarchiveList,
  archiveList,
  onEmailInvite,
  inviteMemberHandleRemove,
  inviteMemberHandleValueChange,
  inviteMemberHandleBoardPopup,
  onInvite
}) => {
  const windowWidth = import_react_native19.Dimensions.get("window").width;
  const [modalWidth, setModalWidth] = (0, import_react25.useState)(820);
  const [modalHeight, setModalHeight] = (0, import_react25.useState)(522);
  const columnPoint = (0, import_libs21.useMobileView)(768);
  (0, import_react25.useEffect)(() => {
    if (820 > windowWidth) {
      setModalWidth("100%");
    } else {
      setModalWidth(820);
    }
    columnPoint ? setModalHeight("100%") : setModalHeight(552);
  }, [import_react_native19.Dimensions, columnPoint]);
  return /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(providers_default, { i18n, children: /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(
    import_foundations15.Modal,
    {
      animationType: "fade",
      visible: true,
      containerStyle: {
        width: modalWidth,
        height: modalHeight,
        maxWidth: 820,
        minWidth: "auto",
        backgroundColor: import_libs21.COLOR.mono.white,
        borderRadius: columnPoint ? 0 : 8
      },
      children: skeleton ? /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(import_jsx_runtime35.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(import_skeleton2.SkeletonMemberInviteScreen, { columnPoint, onClose: onPressClose }) }) : /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(
        contents_default,
        {
          currentProject,
          secureCdnUrl,
          columnPoint,
          inviteWorkList,
          inviteMemberList,
          onPressClose,
          unarchiveList,
          archiveList,
          onEmailInvite,
          inviteMemberHandleRemove,
          inviteMemberHandleValueChange,
          inviteMemberHandleBoardPopup,
          onInvite
        }
      )
    }
  ) });
};
var styles17 = import_react_native19.StyleSheet.create({
  container: {
    width: 424,
    minHeight: 560,
    backgroundColor: import_libs21.COLOR.mono.white
  },
  headerTopContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 4,
    width: 350
  },
  headerBottomContainer: {
    marginTop: 4
  },
  labelContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  footerButton: {
    height: 50,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6
  },
  bodyArea: {
    flexDirection: "row"
  },
  searchArea: {
    flex: 1,
    flexGrow: 1,
    backgroundColor: import_libs21.COLOR.gray.g050,
    paddingVertical: 16,
    paddingHorizontal: 24
  },
  emailSearch: {
    borderBottomWidth: 1,
    borderColor: import_libs21.COLOR.gray.g150,
    paddingBottom: 20,
    marginBottom: 20
  },
  inviteListArea: {
    flex: 1
  },
  inviteList: {
    paddingTop: 8,
    paddingBottom: 60,
    paddingHorizontal: 24
  },
  itemCenter: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  workDetailLayer: {
    position: "absolute",
    zIndex: 99,
    top: 16,
    right: 20,
    bottom: 20,
    left: 20
  }
});

// src/MetaText/index.tsx
var import_react_native20 = require("react-native");
var import_foundations16 = require("@rocket-mono/foundations");
var import_libs22 = require("@rocket-mono/libs");
var import_atoms13 = require("@rocket/atoms");
var import_jsx_runtime36 = require("react/jsx-runtime");
var MetaText = ({ label, afterDivLine }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime36.jsxs)(import_foundations16.View, { style: styles18.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(import_atoms13.Text, { style: styles18.text, children: label }),
    /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(import_foundations16.DivLine, { height: 8, marginHorizontal: 4 })
  ] });
};
var MetaText_default = MetaText;
var styles18 = import_react_native20.StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center"
  },
  text: {
    ...import_libs22.FONT.txt2Xs,
    color: import_libs22.COLOR.gray.g500
  }
});
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  CreateWorkScreen,
  MemberInviteEmail,
  MemberInviteList,
  MemberInviteScreen,
  MemberInviteScreenModal,
  MemberInviteWorkDetail,
  MemberInviteWorkList,
  MessageListScreen,
  MetaText,
  NotificationListScreen,
  WorkSettingScreen,
  WorkmodelCreateScreen,
  WorkmodelEditScreen
});
