import React, { useCallback, useEffect } from 'react'

import { DropdownButton, DropdownItemProps, DropdownList } from '@rocket-atoms/dropdown'
import { ClickOutside } from '@rocket-mono/libs'
import { DimensionValue, StyleSheet, View } from 'react-native'
import SearchTextField from '../SearchTextField'

interface Props {
  label?: string
  defaultIndex: number | number[]
  currentIndex?: number | number[]
  listData: DropdownItemProps[]
  width?: DimensionValue
  buttonWidth?: DimensionValue
  children?: (o: { close: () => void; visible: boolean }) => JSX.Element
  value?: string
  onChangeValue?: (value) => void
  onPressClear?: () => void
  onPressSubmit?: () => void
}

const TextFieldGroup: React.FC<Props> = ({
  label,
  defaultIndex,
  currentIndex,
  listData,
  width,
  buttonWidth,
  children,
  value = '',
  onChangeValue,
  onPressClear,
  onPressSubmit,
}) => {
  const [actived, setActived] = React.useState(false)
  const [selected, setSelected] = React.useState<DropdownItemProps[]>(
    defaultIndex !== undefined && typeof defaultIndex === 'number'
      ? [listData[defaultIndex]]
      : listData.filter((_, idx) => defaultIndex?.includes(idx)),
  )

  const close = useCallback(() => setActived(false), [])

  useEffect(() => {
    if (currentIndex !== undefined)
      setSelected(
        typeof currentIndex === 'number'
          ? [listData[currentIndex]]
          : listData.filter((_, idx) => currentIndex?.includes(idx)),
      )
  }, [listData, currentIndex])

  return (
    <View style={[styles.container, { width }]}>
      <View style={[[styles.sortArea, { zIndex: 99 }]]}>
        <DropdownButton
          type={'DEFAULT'}
          configuration={'DEFAULT'}
          size={'md'}
          label={label !== undefined ? label : selected.length !== 0 ? selected[0].label : '초기 값'}
          buttonWidth={buttonWidth ? buttonWidth : 84}
          ifGroup={true}
          activated={actived}
          setActivated={(is) => {
            console.log('setActivated', is)
            setActived(is)
          }}
        />
      </View>
      <SearchTextField
        value={value}
        ifGroup={true}
        dropdownWidth={buttonWidth ? buttonWidth : 84}
        onChangeText={onChangeValue}
        onPressSubmit={onPressSubmit}
        onPressClear={onPressClear}
        autoFocus={false}
      />

      {children ? (
        children({ close, visible: actived })
      ) : (
        <ClickOutside onClickOutside={() => setActived(false)}>
          <DropdownList
            list={listData}
            configuration={'DEFAULT-BORDER'}
            itemType={'DEFAULT'}
            itemVariant={'DEFAULT'}
            selected={selected}
            setSelected={setSelected}
            setActived={setActived}
          />
        </ClickOutside>
      )}
      {/* )} */}
    </View>
  )
}

export default TextFieldGroup

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    flex: 1,
    flexDirection: 'row',
    width: '100%',
  },
  sortArea: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
})
