import React from 'react'
import { Platform, Pressable, StyleSheet } from 'react-native'

interface Props {
  onDismiss?: () => void
}

const Dimmed: React.FC<Props> = ({ onDismiss }) => {
  return <Pressable onHoverIn={onDismiss} onPressIn={onDismiss} style={styles.dimmed} />
}

export default Dimmed

const styles = StyleSheet.create({
  dimmed: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
    }),
  },
})
