import { FileIconType } from './types'

export const getFileType = (ext: string): FileIconType => {
  ext = ext.toLowerCase()

  if (['doc', 'docx', 'txt', 'hwp'].includes(ext)) {
    return 'DOC'
  } else if (['xls', 'xlsx'].includes(ext)) {
    return 'XLS'
  } else if (['ppt', 'pptx'].includes(ext)) {
    return 'PPT'
  } else if (['pdf'].includes(ext)) {
    return 'PDF'
  } else if (['jpg', 'jpeg', 'png'].includes(ext)) {
    return 'IMG'
  } else if (['mp4', 'mov'].includes(ext)) {
    return 'MP4'
  } else if (['zip', 'tar', 'gz', 'tgz'].includes(ext)) {
    return 'ZIP'
  } else return 'ETC'
}
