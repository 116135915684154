import React from 'react'

import { useTranslation } from 'react-i18next'
import { Image, Pressable, StyleSheet, Text, View } from 'react-native'

import { COLOR, FONT } from '@rocket-mono/libs'

interface Props {
  onBack: () => void
}

const ExternalServerView: React.FC<Props> = ({ onBack }) => {
  const { t } = useTranslation()
  return (
    <View style={styles.container}>
      <View style={{ marginTop: -100 }}>
        <Image
          source={{ uri: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/error/Error500_01.svg' }}
          style={styles.image}
        />
      </View>
      <View>
        <Text style={styles.header}>Oopss!</Text>
      </View>
      <View style={{ marginTop: 8 }}>
        <Text style={styles.description}>{t('error.externalserver.description')}</Text>
      </View>
      <View style={{ marginTop: 14 }}>
        <Pressable style={styles.button} onPress={onBack}>
          <Text style={styles.buttonText}>{t(`setting.back`)}</Text>
        </Pressable>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  image: {
    width: 376,
    height: 260,
  },
  header: {
    ...FONT.H3Bold,
    color: COLOR.gray.g800,
  },
  description: {
    ...FONT.H7Medium,
    color: COLOR.gray.g900,
  },
  button: {
    height: 40,
    backgroundColor: COLOR.main.blue,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    justifyContent: 'center',
    paddingHorizontal: 12,
  },
  buttonText: {
    ...FONT.txtMdBold,
    color: COLOR.mono.white,
    fontSize: 15,
  },
})

export default ExternalServerView
