import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyleSheet, View, Text } from 'react-native'
import { COLOR, FONT } from '@rui/foundations'

interface Props {
  mobileView?: boolean
}

const EmptyItem: React.FC<Props> = ({ mobileView }) => {
  const { t } = useTranslation()

  return (
    <View style={[styles.container, mobileView && { minWidth: '100%' }]}>
      <Text style={styles.text}>
        {t('workspace.howtofavorite')}
        {/* 워크를 끌어다 놓으면
        <br></br>
        추가됩니다. */}
      </Text>
    </View>
  )
}

export default EmptyItem

const styles = StyleSheet.create({
  container: {
    minWidth: 328,
    width: '100%',
    height: 68,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: COLOR.darkmode.cool700,
  },
  text: {
    ...FONT.txtXsMedium,
    color: COLOR.gray.g600,
    textAlign: 'center',
  },
})
