import { Tag } from '@rocket-atoms/tag'
import { Lozenge } from '@rui/molecules'
import React from 'react'
import { Image } from 'react-native'
import FileItem from '../FileItem'
import { FileListItemProps, LabelItemProps, TagItemProps } from './type'
import { IColors } from '@rocket-mono/libs'

export const LabelItem: React.FC<LabelItemProps> = ({ item }) => {
  if (item.type === 'DEFAULT' || item.type === 'BOLD') {
    return <Lozenge label={item.label} labelType={item.color as IColors} type={item.type} />
  } else {
    return <></>
  }
}

export const TagItem: React.FC<TagItemProps> = ({ item }) => {
  return (
    <Tag name={item.name} size="MEDIUM">
      <Image source={{ uri: item.userProfile }} style={{ width: 16, height: 16, borderRadius: 10 }} />
    </Tag>
  )
}

export const FileListItem: React.FC<FileListItemProps> = ({ isAnonymous, onAnonymousPress, item }) => {
  return (
    <FileItem
      small
      access={item.access}
      border
      fileUrl={item.url}
      fileType={item.type}
      filePath={item.path}
      fileName={item.name}
      fileSize={item.size}
      fileSource={item.source}
      date={item.date}
      uploader={item.uploader}
      onPressItem={() => {
        if (isAnonymous) onAnonymousPress()
        else item.onPressItem()
      }}
      onPressDownload={() => {
        console.log('onPressDownload-FileItem', { isAnonymous, item })
        if (isAnonymous) onAnonymousPress()
        else {
          item.onPressDownload && item.onPressDownload()
        }
      }}
      onPressFileOpen={() => {
        console.log('onPressDownload-FileItem', { isAnonymous, item })
        if (isAnonymous) onAnonymousPress()
        else {
          item.onPressFileOpen && item.onPressFileOpen()
        }
      }}
      isExists={item.isExists}
      onPressEtc={item.onPressEtc}
      progress={undefined}
    />
  )
}
