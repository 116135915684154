import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { COLOR, FONT } from '@rui/foundations'
import { StyleSheet, View, Pressable } from 'react-native'
import { Text, WorkIcon } from '@rocket/atoms'

type ItemType = {
  type: 'DSP' | 'WFP' | 'TOP' | 'CSP' | string
  workName: string
  workspaceName: string
  onPress: () => void
}
interface Props {
  item: ItemType
}

const SearchSuggestionsItem: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation()
  const [isHover, setIsHover] = useState(false)

  return (
    <Pressable
      onPress={item.onPress}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      style={[styles.container, isHover && { backgroundColor: COLOR.darkmode.warm700 }]}
    >
      <View style={styles.workArea}>
        <View style={styles.workIconWrap}>
          <WorkIcon type={item.type} size={16} />
        </View>
        <View>
          <Text fontName="txtSm" fontColor="mono.white">
            {item.workName}
          </Text>
          <Text fontName="txt2Xs" fontColor="darkmode.cool400">
            {item.workspaceName}
          </Text>
        </View>
      </View>
      {isHover && (
        <View style={styles.linkTextWrap}>
          <Text style={styles.linkText}>{t('addcontainer.move')}</Text>
        </View>
      )}
    </Pressable>
  )
}

export default SearchSuggestionsItem

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    borderRadius: 4,
    backgroundColor: COLOR.darkmode.cool900,
  },
  workArea: {
    flexDirection: 'row',
    flex: 1,
  },
  workIconWrap: {
    marginRight: 8,
    marginTop: 2,
  },
  linkTextWrap: {
    alignItems: 'center',
    paddingRight: 4,
  },
  linkText: {
    ...FONT.txtSm,
    color: COLOR.primary.blue500,
  },
})
