import React from 'react'
import { View, StyleSheet } from 'react-native'

import { COLOR } from '@rocket-mono/libs'

interface Props {
  theme?: 'dark' | 'light'
  height: 8 | 12 | 16 | 18 | 20
  marginHorizontal: 4 | 8 | 12 | 16
}
/**
 * @deprecated
 */
const DivLine: React.FC<Props> = ({ theme, height, marginHorizontal }) => {
  return (
    <View
      style={[styles.style, { height, marginHorizontal }, theme === 'dark' && { backgroundColor: COLOR.opacity.w10 }]}
    />
  )
}

export default DivLine

const styles = StyleSheet.create({
  style: {
    width: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
})
