import { COLOR } from '@rocket-mono/libs'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import RuleStepIcon from '../RuleStep/RuleStepIcon'

export type RuleStep = {
  applyBoard: boolean
  triggers: boolean
  rules: boolean
  action: boolean
}

interface Props {
  active: RuleStep
  current: RuleStep
}

const RuleStepView: React.FC<Props> = ({ active, current }) => {
  return (
    <View style={styles.container}>
      <RuleStepIcon type={'Applyboard'} active={active.applyBoard} current={current.applyBoard} native />
      <View style={styles.dash} />
      <RuleStepIcon type={'Triggers'} active={active.triggers} current={current.triggers} native />
      <View style={styles.dash} />
      <RuleStepIcon type={'Rules'} active={active.rules} current={current.rules} native />
      <View style={styles.dash} />
      <RuleStepIcon type={'Action'} active={active.action} current={current.action} native />
    </View>
  )
}

export default RuleStepView

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    flexDirection: 'row',
  },
  dash: {
    flex: 1,
    width: '100%',
    height: 1,
    marginTop: 16,
    borderColor: COLOR.gray.g150,
    borderTopWidth: 1,
  },
})
