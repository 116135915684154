import * as React from 'react'
import { StyleSheet, Pressable, View } from 'react-native'
import { COLOR } from '@rocket-mono/libs'
import FileIcon, { FileIconType } from '.'


const FileCircleIcon = ({
    type, 
    onPress,
    code,
    setCode
  }
  : {
    type: FileIconType, 
    onPress: () => void,
    code: string,
    setCode: (arg0:string) => void,
  }) => {
  const [isHover, setIsHover] = React.useState(false)

  return(
    <Pressable 
      style={[
        styles.container, 
        code === type && {borderColor:COLOR.primary.blue500}
      ]}
      onPress={()=>setCode(type)}
      onHoverIn={()=>setIsHover(true)}
      onHoverOut={()=>setIsHover(false)}
    >
      <View style={[
        styles.wrap, 
        isHover && {borderColor:COLOR.gray.g200},
        code === type && {borderColor:COLOR.transparent.base}
        ]}>
       <FileIcon type={type} size={20}/>
      </View>
    </Pressable>
  )
}


export default FileCircleIcon

const styles = StyleSheet.create({
  container:{
    borderWidth: 3,
    borderColor: COLOR.transparent.base,
    borderRadius: 50
  },
  wrap:{
    justifyContent:'center',
    alignItems:'center',
    width:36,
    height:36,
    borderWidth:1,
    borderColor: COLOR.gray.g100,
    borderRadius:50,
    backgroundColor:COLOR.mono.white
  }
})