import { useNavigate, useParams } from 'react-router-dom'
import { WorkProjectProvider } from '@rocket-mono/providers'
import React, { useCallback } from 'react'
import { CardModal } from '../../modal'
import { Skeleton } from '@rocket/skeleton'

interface Props {
  maxWidth?: number
  children: JSX.Element | JSX.Element[]
}

const Provider: React.FC<Props> = ({ maxWidth, children }) => {
  const { projectId } = useParams()
  const navigate = useNavigate()

  const handleUnauthorized = useCallback(() => {
    navigate('/')
  }, [])

  const handleDeleteWork = useCallback(() => {
    navigate('/')
  }, [])
  return (
    <WorkProjectProvider
      projectId={projectId}
      onUnauthorized={handleUnauthorized}
      onDeleted={handleDeleteWork}
      fallback={<Skeleton path={location.pathname} />}
    >
      <>
        {children}
        <CardModal maxWidth={maxWidth} />
      </>
    </WorkProjectProvider>
  )
}

export default Provider
