import React from 'react'
import { StyleSheet, View } from 'react-native'

import { ViewProps } from './types'

const AuthView: React.FC<ViewProps> = ({}) => {
  return <View style={styles.container}></View>
}

export default AuthView

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
