import * as React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { COLOR, FONT } from '@rocket-mono/libs'
import Switch from '.'

interface Props {
  label: string
  activated: boolean
  setActivated: (activated: boolean) => void
  justifyContent: 'flex-start' | 'space-between'
}
const SwitchLabel = ({ label, activated, setActivated, justifyContent }: Props) => {
  return (
    <View style={[styles.switchLabel, { justifyContent: justifyContent }]}>
      <Text style={[styles.label, !activated && { color: COLOR.gray.g450 }]}>{label}</Text>
      <Switch size={'small'} activated={activated} onChange={setActivated} />
    </View>
  )
}

export default SwitchLabel

const styles = StyleSheet.create({
  switchLabel: {
    flexDirection: 'row',
    paddingVertical: 8,
  },
  label: {
    ...FONT.txtSm,
    marginRight: 4,
  },
})
