import React, { useCallback } from 'react'

import {
  StyleSheet,
  TextInput as RNTextInput,
  TextInputProps as RNTextInputProps,
  ViewStyle,
  TextStyle,
  Platform,
  NativeSyntheticEvent,
  TextInputFocusEventData,
} from 'react-native'

import { COLOR, FONT, IColors, cutByteString, getByteLength, getRealColor } from '@rocket-mono/libs'

import View from '../View'
import Text from '../Text'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FAIcon } from '@rocket-atoms/icon'

interface Props extends RNTextInputProps {
  maxByteLength?: number
  borderType?: 'solid' | 'underline'
  borderColor?: IColors
  containerStyle?: ViewStyle
  inputStyle?: TextStyle
  errorMessage?: string
}
/**
 * @deprecated
 */
const TextInput: React.FC<Props> = ({
  maxByteLength = 0,
  borderType,
  borderColor,
  containerStyle,
  inputStyle,
  errorMessage,
  placeholderTextColor,
  onChangeText,
  onFocus,
  onBlur,
  ...inputProps
}) => {
  const [isFocus, setIsFocus] = React.useState(false)
  const handleChangeText = (text: string) => {
    if (onChangeText) onChangeText(maxByteLength > 0 ? cutByteString(text, maxByteLength) : text)
  }

  const handleFocus = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      onFocus && onFocus(e)
      setIsFocus(true)
    },
    [onFocus],
  )
  const handleBlur = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      onBlur && onBlur(e)
      setIsFocus(false)
    },
    [onFocus],
  )

  return (
    <View>
      <View
        style={[
          styles.container,
          borderType === 'solid' ? styles.solidBorder : borderType === 'underline' ? styles.underlineBorder : undefined,
          borderColor && { borderColor: getRealColor(borderColor) },
          containerStyle,
          !!errorMessage && { borderColor: COLOR.main.red },
        ]}
      >
        <RNTextInput
          {...inputProps}
          placeholderTextColor={placeholderTextColor ?? COLOR.gray.g450}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={[
            styles.inputStyle,
            inputProps.style,
            inputStyle,
            isFocus && { borderWidth: 2, borderColor: COLOR.primary.blue400 },
          ]}
          onChangeText={handleChangeText}
        />
      </View>
      {maxByteLength > 0 && (
        <View style={[styles.alignFlexEnd]}>
          <Text fontName="subTextRegular" fontColor="mono.paleBlack">{`( ${getByteLength(
            inputProps.value || '',
          )} / ${maxByteLength} )`}</Text>
        </View>
      )}

      {errorMessage && (
        <View style={styles.errorArea}>
          <View
            style={{
              ...Platform.select({
                web: {
                  marginTop: 2,
                },
              }),
            }}
          >
            <FAIcon iconName={faCircleExclamation} size={'xs'} color={'status.busy'} />
          </View>
          <Text style={styles.errorMessage}>{errorMessage}</Text>
        </View>
      )}
    </View>
  )
}

export default TextInput

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    height: 36,
    backgroundColor: COLOR.mono.white,
  },
  inputStyle: {
    flex: 1,
    height: 36,
  },
  alignFlexEnd: { alignItems: 'flex-end' },
  solidBorder: {
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    borderRadius: 5,
  },
  underlineBorder: {
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g200,
  },
  errorArea: {
    flexDirection: 'row',
    marginTop: 4,
  },
  errorMessage: {
    ...FONT.txtXsMedium,
    color: COLOR.status.busy,
    marginLeft: 2,
  },
})
