import * as React from 'react'
import { I18nextProvider } from 'react-i18next'

import { AstroProvider, ModalDialogProvider, SystemProvider, ThemeProvider } from '@rocket-mono/providers'

import i18nInstance from '../i18n'
import { options } from './env'

interface Props {
  children?: JSX.Element | JSX.Element[]
}

const AuthProviders: React.FC<Props> = ({ children }) => {
  return (
    <React.Suspense>
      <I18nextProvider i18n={i18nInstance}>
        <ThemeProvider>
          <AstroProvider token="" option={options}>
            <ModalDialogProvider>
              <SystemProvider>
                <>{children}</>
              </SystemProvider>
            </ModalDialogProvider>
          </AstroProvider>
        </ThemeProvider>
      </I18nextProvider>
    </React.Suspense>
  )
}

export default AuthProviders
