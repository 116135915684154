import * as React from 'react'
import { Dimensions, Modal, StyleSheet, View } from 'react-native'

import { COLOR, FONT } from '@rocket-mono/libs'

import type { ViewProps } from './types'
import Button from '../Button'
import Text from '../Text'

const DialogView: React.FC<ViewProps> = ({ visible, title, message, cancelText, onCancel, list, insetsBottom = 0 }) => {
  const dimensions = Dimensions.get('window')
  const buttonType = React.useMemo(() => {
    const buttonCount = list.length + (onCancel !== undefined ? 1 : 0)
    return buttonCount === 1 ? 'WIDE' : buttonCount === 2 ? 'ROW_GROUP' : 'COLUMN_GROUP'
  }, [list, onCancel])

  return (
    <Modal animationType="slide" transparent visible={visible}>
      <View
        style={[
          styles.container,
          {
            bottom: insetsBottom + 20,
            width: dimensions.width - 40,
            alignSelf: 'center',
          },
        ]}
      >
        <View style={{ overflow: 'hidden', paddingTop: 5 }}>
          <View style={[styles.modalView]}>
            <View style={styles.messageTextContainer}>
              <Text fontName="H7Medium" fontColor="gray.g900">
                {title}
              </Text>
              {!!message && (
                <Text fontName="txtSm" fontColor="gray.g700" style={styles.messageText}>
                  {message}
                </Text>
              )}
            </View>
            <View
              style={[
                buttonType === 'ROW_GROUP' && {
                  flexDirection: 'row-reverse',
                  flex: 1,
                },
              ]}
            >
              {list.map((item, idx) => (
                <View key={`button-${idx}`} style={{ flex: 1, marginBottom: 4 }}>
                  <Button
                    wide
                    key={`confirm-btn-${idx}`}
                    color="main.blue"
                    onPress={() => {
                      item.action && item.action()
                    }}
                  >
                    <Text fontName="txtSm" fontColor="mono.white">
                      {item.name}
                    </Text>
                  </Button>
                </View>
              ))}
              {buttonType === 'ROW_GROUP' && <View style={{ width: 8 }} />}
              {onCancel && (
                <View style={{ flex: 1, marginBottom: 4 }}>
                  <Button
                    wide
                    borderStyle="solid"
                    borderColor="main.blue"
                    onPress={onCancel}
                    backgroundColor="mono.white"
                    color="main.blue"
                  >
                    <Text fontName="txtSm" fontColor="mono.black">
                      {cancelText || '취소'}
                    </Text>
                  </Button>
                </View>
              )}
            </View>
          </View>
        </View>
      </View>
    </Modal>
  )
}

export default DialogView

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    flex: 1,
  },
  modalView: {
    paddingVertical: 24,
    paddingHorizontal: 20,
    paddingBottom: 20,
    borderRadius: 16,
    backgroundColor: COLOR.mono.white,
  },
  messageTextContainer: {
    flex: 1,
    marginBottom: 20,
  },
  messageText: {
    marginTop: 8,
  },
  // button: {
  //   minWidth: '100%',
  //   paddingHorizontal: 12,
  //   paddingVertical: 7,
  //   borderRadius: 40,
  //   marginVertical: 4,
  //   color: getRealColor('mono.white'),
  // },
  buttonCancel: {
    marginTop: 12,
  },
  buttonText: {
    ...FONT.subTitleBold,
    fontWeight: '400',
    lineHeight: 19.24,
    color: COLOR.mono.black,
  },
})
