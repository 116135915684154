import { useAstro } from '@rocket-mono/providers'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const ChannelView: React.FC = () => {
  const { option, token } = useAstro()
  const { channelRoomId } = useParams()

  useEffect(() => {
    console.log('channelRoomId', channelRoomId)

    let uri = option.projectApiLegacyUrl + '/api/channel/join'
    const headers = { 'X-AUTH-TOKEN': token }
    const method = 'POST'
    const body = new FormData()
    body.append('channelId', channelRoomId ?? '')
    body.append('roomId', channelRoomId ?? '')

    fetch(uri, { method, headers, body }).then(() => {
      uri = option.talkApiLegacyUrl + '/api/talk/token'

      fetch(uri, { method, headers, body })
        .then((res) => res.text())
        .then((data) => {
          location.href = option.talkUrl + '/chat/' + data
        })
        .catch((err) => console.error(err))
    })
  }, [])

  return (
    <>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css"
      />
      <style>
        {`
		html {
			font-size: 18px;
			font-family: 'Pretendard', 'Apple SD Gothic Neo', sans-serif;
		}

		body,
		p,
		h1,
		h2,
		h3,
		span,
		h5,
		h6,
		ul,
		ol,
		li,
		dl,
		dt,
		dd,
		table,
		th,
		td,
		form,
		fieldset,
		legend,
		input,
		textarea,
		button,
		select {
			margin: 0;
			padding: 0;
		}

		body,
		input,
		textarea,
		select,
		button,
		table {
			font-family: 'canada-type-gibson', 'pretendard', 'Apple SD Gothic Neo',
				sans-serif;
		}

		body {
			-ms-word-break: break-all;
			word-break: keep-all;
			word-wrap: break-word;
			font-size: 1rem;
			letter-spacing: -0.025em;
			color: #000;
		}

		a,
		a:hover,
		a:focus,
		a:active {
			text-decoration: none;
			color: inherit;
		}

		a,
		button,
		input,
		textarea {
			transition: border-color 0.4s, background 0.4s, color 0.4s, opacity 0.4s,
				transform 0.4s, box-shadow 0.4s;
		}

		li {
			list-style: none;
		}

		address,
		em {
			font-style: normal;
		}

		label,
		[role='button'] {
			cursor: pointer;
		}

		table {
			width: 100%;
		}

		textarea {
			resize: none;
		}

		strong,
		b {
			font-weight: 500;
		}

		img {
			vertical-align: top;
		}

		br {
			font-family: arial;
		}

		small,
		.sm {
			font-size: 75%;
		}

		button {
			background: none;
			border: 0;
		}

		figure {
			margin-bottom: 0;
		}

		/* animated */
		@keyframes leftAnim {
			0% {
				transform: translate(-25px, 0);
				opacity: 0.5;
			}

			40% {
				transform: translate(20px, 0);
				opacity: 0.8;
			}

			100% {
				transform: translate(-25px, 0);
				opacity: 0.5;
			}
		}

		@keyframes rightAnim {
			0% {
				transform: translate(25px, 0);
				opacity: 0.5;
			}

			40% {
				transform: translate(-20px, 0);
				opacity: 0.8;
			}

			100% {
				transform: translate(25px, 0);
				opacity: 0.5;
			}
		}

		/* contents */
		.container {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			width: 100%;
			height: 100vh;
			background-color: #f6f8f9;
		}

		.inner-contents {
			text-align: center;
			margin-top: -60px;
		}

		.icon-logo {
			display: inline-block;
			font-size: 0;
			width: 50px;
			height: 28px;
			background-size: 100% auto;
			background-image: url('https://rocket-base-static.cdn.ntruss.com/files/file_box/defaf4dceb0447f99ae579b445023828.svg');
			background-repeat: no-repeat;
			background-position: 50% 50%;
			margin-bottom: 50px;
		}

		.object-area {
			position: relative;
			height: 40px;
		}

		.tit-txt {
			display: block;
			font-size: 24px;
			color: #2a3237;
			margin-top: 30px;
		}

		.info-txt {
			display: block;
			font-size: 16px;
			color: #4d5a61;
			margin-top: 8px;
		}

		.obj-common {
			display: inline-block;
			position: 'absolute';
			top: 0;
			width: 40px;
			height: 40px;
			border-radius: 40px;
			opacity: 0.8;
			animation-duration: 2s;
			animation-iteration-count: infinite;
		}

		.obj-common.i1 {
			background-color: #001b57;
			animation-name: leftAnim;
		}

		.obj-common.i2 {
			background-color: #5ebcca;
			animation-name: rightAnim;
		}

		@media (max-width: 500px) {
			.icon-logo {
				width: 40px;
				height: 30px;
				margin-bottom: 35px;
			}

			.tit-txt {
				font-size: 20px;
			}

			.info-txt {
				font-size: 14px;
			}
		}`}
      </style>
      <div className="container">
        <div className="inner-contents">
          <div className="object-area">
            <i className="obj-common i1"></i>
            <i className="obj-common i2"></i>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChannelView
