import React, { useCallback, useEffect, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'

import { COLOR, replaceItemAtIndex } from '@rui/foundations'
import { Combobox, SearchTextField } from '@rui/molecules'
import { Button, DropdownItem } from '@rui/atoms'

import type { SearchLayerSectionList, SearchLayerSectionListItem } from './components/SearchLayerSectionList'
import SearchLayerSectionListView from './components/SearchLayerSectionList'
import type { SearchLayerViewProps } from './types'

const SearchLayer: React.FC<SearchLayerViewProps> = ({
  visible,
  projectId,
  containerStyle,
  data,
  type,
  onChangeType,
  onPressClear,
  onPressApply,
  onPressCancel,
}) => {
  const { t } = useTranslation()
  const [isMore, setIsMore] = React.useState<boolean>(false)
  const [sectionListData, setSectionListData] = React.useState<SearchLayerSectionList[] | undefined>(data)
  const [searchKeyword, setSearchKeyword] = React.useState('')
  // const [close, setClose] = React.useState(true)
  const previewSectionListData = React.useMemo(() => {
    if (sectionListData === undefined) return undefined
    const $sectionListData: SearchLayerSectionList[] = []
    for (const data of sectionListData) {
      const $data: SearchLayerSectionListItem[] = []
      for (const item of data.data) {
        if (item.title.includes(searchKeyword)) {
          $data.push(item)
        }
      }
      if ($data.length > 0) {
        $sectionListData.push({ ...data, data: $data })
      }
    }
    return $sectionListData
  }, [sectionListData, searchKeyword])

  const selectedIdList = useMemo(
    () =>
      sectionListData
        ?.flatMap(({ data }) => data)
        .filter(({ isSelected }) => isSelected)
        .map(({ id }) => id) ?? [],
    [sectionListData],
  )

  const handlePressClear = useCallback(() => {
    setIsMore(false)
    setSectionListData((prev) => {
      if (prev === undefined) return prev
      return prev.map((section) => {
        return {
          ...section,
          data: section.data.map((item) => {
            return {
              ...item,
              isSelected: false,
            }
          }),
        }
      })
    })
    onPressClear && onPressClear()
  }, [onPressApply])

  const handlePressApply = useCallback(() => {
    setIsMore(false)
    if (onPressApply && selectedIdList && selectedIdList.length > 0) {
      onPressApply(selectedIdList)
    }
  }, [onPressApply, selectedIdList])

  useEffect(() => {
    setSectionListData(data)
  }, [data])

  if (!visible) return null
  return (
    <View style={{ position: 'absolute', top: 40, left: 0, zIndex: 99 }}>
      <View style={[containerStyle, { maxHeight: 500 }]}>
        <Combobox visible={visible} width={280}>
          <Combobox.Header style={styles.comboHeaderStyle}>
            <DropdownItem
              id="WORKSPACE"
              label={t('workmember.table.auth.all')}
              actived={type === 'WORKSPACE' && selectedIdList.length === 0}
              onPress={() => {
                handlePressClear()
                onChangeType && onChangeType('WORKSPACE')
                onPressCancel?.()
              }}
            />
            <>
              {projectId !== undefined && (
                <DropdownItem
                  id="WORK"
                  label={t('search.searchbar.inthiswork')}
                  actived={type === 'WORK' && selectedIdList.length === 0}
                  onPress={() => {
                    handlePressClear()
                    onChangeType && onChangeType('WORK')
                    onPressCancel?.()
                  }}
                />
              )}
            </>
            <View />
            {isMore ? (
              <SearchTextField
                value={searchKeyword}
                placeholder={t('inputtodo.placeholder')}
                onChangeText={setSearchKeyword}
                onPressSubmit={() => {
                  console.log('검색')
                }}
                autoFocus={true}
              />
            ) : (
              <></>
            )}
          </Combobox.Header>
          {isMore ? (
            <Combobox.Body>
              <View style={styles.comboBodyStyle}>
                <SearchLayerSectionListView
                  data={previewSectionListData}
                  onFold={(sectionKey) => {
                    setSectionListData((prev) => {
                      if (prev === undefined) return prev
                      const index = prev.findIndex((item) => item.key === sectionKey)
                      const item = prev[index]
                      if (item === undefined) return prev

                      return replaceItemAtIndex(prev, index, item)
                    })
                  }}
                  onSectionSelect={(sectionKey: string, allChecked: boolean) => {
                    setSectionListData((prev) => {
                      if (prev === undefined) return prev
                      const sectionIndex = prev.findIndex((item) => item.key === sectionKey)
                      const section = prev[sectionIndex]
                      if (section === undefined) return prev
                      section.data.forEach((item) => {
                        item.isSelected = !allChecked
                      })

                      return replaceItemAtIndex(prev, sectionIndex, section)
                    })
                  }}
                  onItemSelect={(sectionKey: string, item: SearchLayerSectionListItem) => {
                    setSectionListData((prev) => {
                      if (prev === undefined) return prev
                      const sectionIndex = prev.findIndex((item) => item.key === sectionKey)
                      const section = prev[sectionIndex]
                      if (section === undefined) return prev
                      const itemIndex = section.data.findIndex((data) => data.id === item.id)
                      const selectedItem = section.data[itemIndex]
                      if (selectedItem) {
                        selectedItem.isSelected = !selectedItem.isSelected
                        section.data[itemIndex] = selectedItem
                      }

                      return replaceItemAtIndex(prev, sectionIndex, section)
                    })
                  }}
                />
              </View>
            </Combobox.Body>
          ) : (
            <></>
          )}
          <Combobox.Footer>
            {isMore ? (
              <View style={styles.comboFooterStyle}>
                <Button
                  type={'SECONDARYWHITE'}
                  size={'sm'}
                  text={t('filelist.uploadpicker.clear')}
                  onPress={handlePressClear}
                />
                <Button size={'sm'} text={t('placesearch.save')} onPress={handlePressApply} />
              </View>
            ) : (
              <View style={styles.comboFooterStyle}>
                <Button
                  size={'sm'}
                  text={t('explainworkmodel.more')}
                  onPress={() => {
                    setIsMore(true)
                  }}
                />
              </View>
            )}
          </Combobox.Footer>
        </Combobox>
      </View>
    </View>
  )
}

export default SearchLayer

const styles = StyleSheet.create({
  comboHeaderStyle: {
    padding: 8,
    borderBottomWidth: 0,
  },
  comboBodyStyle: {
    // height: 500,
  },
  comboFooterStyle: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 8,
    borderTopWidth: 1,
    borderColor: COLOR.gray.g200,
  },
})
