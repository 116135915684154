/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react'
import {
  Platform,
  Pressable,
  PressableProps,
  PressableStateCallbackType,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { COLOR, FONT, IColors, getRealColor } from '@rocket-mono/libs'

export interface Props extends Omit<PressableProps, 'style'> {
  text?: string
  color?: IColors
  beforeIcon?: IconProp
  beforeIconColor?: IColors
  afterIcon?: IconProp
  afterIconColor?: IColors
}

const Item: React.FC<Props> = ({
  text,
  color = 'gray.g800',
  beforeIcon,
  beforeIconColor = 'gray.g600',
  afterIcon,
  afterIconColor = 'gray.g600',
  ...pressableProps
}) => {
  return (
    <Pressable style={pressableStyle} {...pressableProps}>
      <View style={styles.beforeArea}>
        {beforeIcon && (
          <View style={{ marginRight: 8 }}>
            <FontAwesomeIcon icon={beforeIcon} size={14} color={getRealColor(beforeIconColor)} />
          </View>
        )}
        <Text style={[styles.text, { color: getRealColor(color) }]}>{text}</Text>
      </View>
      {afterIcon && <FontAwesomeIcon icon={afterIcon} size={11} color={getRealColor(afterIconColor)} />}
    </Pressable>
  )
}

export default Item

// @ts-ignore hovered only used in web
const pressableStyle = ({ hovered, pressed }: PressableStateCallbackType) => {
  return [
    {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingVertical: 12,
      paddingHorizontal: 12,
    },
    hovered && { backgroundColor: COLOR.gray.g050 },
    pressed && {
      opacity: 0.5,
      backgroundColor: COLOR.gray.g100,
    },
  ] as ViewStyle[]
}

const styles = StyleSheet.create({
  text: {
    ...FONT.txtSm,
    color: COLOR.gray.g800,
    ...Platform.select({ native: { paddingTop: 3 } }),
  },
  beforeArea: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
