import React from 'react'
import { View, ViewStyle } from 'react-native'

type Props = {
  style?: ViewStyle
  children: JSX.Element | JSX.Element[]
}

export const Row: React.FC<Props> = ({ style, children }) => {
  return (
    <View
      style={[
        style,
        {
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          flex: 1,
        },
      ]}
    >
      {children}
    </View>
  )
}
