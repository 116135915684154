import { createContext } from 'react'

import type { MentionContextType } from './types.web'

import { MENTION_TEMPLATE } from '../utils/message'

export const MentionContext = createContext<MentionContextType>({
  input: '',
  state: 'idle',
  mentionList: [],
  matched: [],
  mentionListOpen: false,
  cursorPosition: null,
  getKeystroke: () => {
    // no default action
  },
  getCompositionStroke: () => {
    // no default action
  },
  getBackspaceStroke: () => {
    // no default action
  },
  startMention: () => {
    // no default action
  },
  iterateMentionList: () => {
    // no default action
  },
  closeMention: () => {
    // no default action
  },
  startMatching: () => {
    // no default action
  },
  pickMentionItem: () => {
    // no default action
  },
  doneMatching: () => {
    // no default action
  },
  finishMention: () => {
    // no default action
  },
  forwardKeyEvent: () => {
    // no default action
  },
  forwardFormEvent: () => {
    // no default action
  },
  forwardCompositionEvent: () => {
    // no default action
  },
  forwardBlurEvent: () => {
    // no default action
  },
  query: () => {
    // no default action
  },
  MENTION_TEMPLATE,
})
