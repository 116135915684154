import React from 'react'
import { StyleSheet, View } from 'react-native'
import { SkeletonBar, SkeletonCircle, SkeletonSquare } from '../SkeletomParts'

const SkeletonWorkCardView: React.FC = () => {
  return (
    <View>
      <View style={{ marginBottom: 24 }}>
        <View style={{ marginBottom: 12 }}>
          <View>
            <SkeletonCircle width={28} height={28} />
          </View>
          <View style={{ marginTop: 4 }}>
            <SkeletonBar width={200} height={20} />
          </View>
        </View>
        <View style={[styles.rowFlexbox, { marginBottom: 12 }]}>
          <View style={{ minWidth: 84 }}>
            <SkeletonBar width={50} height={16} />
          </View>
          <View style={[styles.rowFlexbox, { opacity: 0.8 }]}>
            <View style={styles.rowFlexbox}>
              <SkeletonSquare width={60} height={28} />
            </View>
            <View style={{ marginLeft: 4 }}>
              <SkeletonSquare width={60} height={28} />
            </View>
            <View style={{ marginLeft: 4 }}>
              <SkeletonSquare width={60} height={28} />
            </View>
          </View>
        </View>
        <View style={[styles.rowFlexbox, { marginBottom: 12 }]}>
          <View style={{ minWidth: 84 }}>
            <SkeletonBar width={36} height={16} />
          </View>
          <View style={[styles.rowFlexbox, { flex: 1, opacity: 0.7 }]}>
            <View style={[styles.rowFlexbox, { flex: 1 }]}>
              <SkeletonSquare width={'100%'} height={50} />
            </View>
            <View style={{ flex: 1, marginLeft: 4 }}>
              <SkeletonSquare width={'100%'} height={50} />
            </View>
          </View>
        </View>
      </View>

      <View style={{ marginBottom: 24 }}>
        <View style={{ marginBottom: 8 }}>
          <SkeletonBar width={36} height={16} />
        </View>
        <View style={{ opacity: 0.6 }}>
          <SkeletonSquare width={'100%'} height={200} />
        </View>
      </View>

      <View style={{ marginBottom: 20 }}>
        <View style={{ marginBottom: 8 }}>
          <SkeletonBar width={24} height={16} />
        </View>
        <View>
          <View style={{ marginBottom: 4, opacity: 0.5 }}>
            <SkeletonSquare width={'100%'} height={32} />
          </View>
          <View style={{ marginBottom: 4, opacity: 0.4 }}>
            <SkeletonSquare width={'100%'} height={80} />
          </View>
          <View style={{ marginBottom: 4, opacity: 0.3 }}>
            <SkeletonSquare width={'100%'} height={80} />
          </View>
          <View style={{ marginBottom: 4, opacity: 0.2 }}>
            <SkeletonSquare width={'100%'} height={80} />
          </View>
          <View style={{ marginBottom: 4, opacity: 0.1 }}>
            <SkeletonSquare width={'100%'} height={80} />
          </View>
        </View>
      </View>
    </View>
  )
}

export default SkeletonWorkCardView

const styles = StyleSheet.create({
  rowFlexbox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
