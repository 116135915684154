import { Icon } from '@rocket-atoms/icon'
import { Text as FText } from '@rocket-atoms/text'
import { getRealColor, useMobileView } from '@rocket-mono/libs'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Pressable, SafeAreaView, ScrollView, StyleSheet, Text, View } from 'react-native'
import Input from '../Input'
import Map from '../Map'
import Marker from './Marker'
import { AddressType, Props } from './types'

export const PlaceSearch: React.FC<Props> = ({
  // modalHeight = 400,
  location,
  onSearch,
  onChangeAddress,
}): JSX.Element => {
  const mobileView = useMobileView(500)
  const [init, setInit] = React.useState(false)
  const [isVisible, setIsVisible] = React.useState(false)
  const [searchText, setSearchText] = React.useState('')
  const [searchList, setSearchList] = React.useState<AddressType[]>([])
  const [address, setAddress] = React.useState<string>()
  const [addressEtc, setAddressEtc] = React.useState<string>()
  const [selectAddress, setSelectAddress] = React.useState<AddressType>()
  const [isMapView, setIsMapView] = React.useState(false)
  const { t } = useTranslation()

  const search = React.useCallback(async () => {
    setSelectAddress(undefined)
    const list = await onSearch(searchText)
    setSearchList(list)
    setIsVisible(true)
  }, [searchText])

  const onSave = React.useCallback(() => {
    setIsVisible(false)
    setIsMapView(false)
    if (selectAddress) {
      setSearchText('')
      setAddress(selectAddress.address)
      setAddressEtc(selectAddress.addressEtc)
      if (onChangeAddress) onChangeAddress(selectAddress)
    }
  }, [selectAddress])

  const onClose = React.useCallback(() => {
    setIsVisible(false)
    setIsMapView(false)
  }, [])

  const resetAddress = React.useCallback(() => {
    setAddress(undefined)
    setAddressEtc(undefined)
    setIsMapView(false)
    setTimeout(() => {
      setAddress(undefined)
    }, 50)
    if (onChangeAddress) onChangeAddress()
  }, [])

  const onSelectAddress = React.useCallback((addressObj: AddressType) => {
    setIsMapView(true)
    setSelectAddress(undefined)
    setTimeout(() => {
      setSelectAddress({
        ...addressObj,
        addressEtc: removeHtmlTag(addressObj.title + addressObj.addressEtc),
      })
    }, 50)
  }, [])

  const onChangeAddressEtc = React.useCallback(
    (addressEtc: string) => {
      setAddressEtc(addressEtc)
      if (onChangeAddress && selectAddress)
        onChangeAddress({
          ...selectAddress,
          addressEtc,
        })
    },
    [selectAddress],
  )

  React.useEffect(() => {
    if (location && !init) {
      setInit(true)
      const { location: address, locationEtc: addressEtc, locationX: x, locationY: y } = location
      setAddress(address)
      setAddressEtc(addressEtc)
      setSelectAddress({
        address,
        addressEtc,
        x,
        y,
        id: '0',
        title: addressEtc,
      })
    } else {
      // setAddress('')
    }
  }, [location])

  return (
    <SafeAreaView>
      <Modal transparent visible={isVisible}>
        <View style={styles.backdrop} /> {/* onPress={onClose}  */}
        <View style={[styles.viewContainer, mobileView && { width: '95%' }]}>
          <View style={styles.headerContainer}>
            <FText fontName="txtMd" fontColor="gray.g900">
              {t(`placesearch.title`)}
            </FText>
            <Pressable
              style={styles.closeButton}
              onPress={() => {
                console.log('closeButton')
                onClose()
              }}
            >
              <Icon name="close" color="gray.g700" size={20} />
            </Pressable>
          </View>
          <View
            style={{
              marginHorizontal: 20,
              marginVertical: 10,
              height: 36,
            }}
          >
            <Input
              value={searchText}
              onChangeText={setSearchText}
              onEndEditing={search}
              onEnterSubmit={search}
              placeholder={t(`placesearch.placeholder1`)}
              placeholderTextColor="gray.g200"
              backgroundColor="mono.white"
              rightIcon={
                searchText
                  ? {
                      name: 'close-circle',
                      color: 'mono.darkGray',
                      onPress: () => {
                        setSearchText('')
                        setIsMapView(false)
                        setSelectAddress(undefined)
                      },
                    }
                  : undefined
              }
              rightIconSize={16}
              height={36}
              riched={false}
            />
          </View>

          {selectAddress ? (
            <View
              style={{
                width: '100%',
                flexDirection: 'row',
                paddingHorizontal: 20,
                paddingVertical: 10,
                backgroundColor: getRealColor('mono.paleWhite'),
                alignItems: 'center',
              }}
            >
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ width: 10, height: 14 }}>
                  <Marker />
                </View>
                <Text style={{ color: getRealColor('mono.black') }}> {selectAddress.address}</Text>
              </View>
              <Pressable
                onPress={() => {
                  setIsMapView(false)
                  setSelectAddress(undefined)
                }}
              >
                <Icon name="close-circle" color="mono.darkGray" size={16} />
              </Pressable>
            </View>
          ) : (
            <></>
          )}
          {selectAddress && (
            <View>
              <Map
                label={removeHtmlTag(selectAddress.title)}
                longitude={selectAddress.x}
                latitude={selectAddress.y}
                height={194}
              />
            </View>
          )}
          {!selectAddress && isMapView && <View style={{ height: 240 }} />}
          <ScrollView
            style={{
              flex: 1,
              backgroundColor: getRealColor('mono.white'),
            }}
          >
            {searchList.map((o) => {
              return (
                <Pressable
                  key={o.id}
                  style={{
                    flexDirection: 'row',
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    justifyContent: 'center',
                    borderTopWidth: 1,
                    borderTopColor: getRealColor('mono.pale'),
                  }}
                  onPress={() => onSelectAddress(o)}
                >
                  <View style={{ flex: 1 }}>
                    <Text>{removeHtmlTag(o.title)}</Text>
                    <Text>{o.address}</Text>
                  </View>
                  <View style={{ justifyContent: 'center' }}>
                    {o.id === selectAddress?.id ? (
                      <View style={styles.active}>
                        <Icon name="check" color="mono.white" size={18} />
                      </View>
                    ) : (
                      <View style={styles.inactive} />
                    )}
                  </View>
                </Pressable>
              )
            })}
          </ScrollView>
          <Pressable onPress={onSave} style={styles.saveButton}>
            <FText fontName="H7Medium" fontColor="mono.white">
              {t(`placesearch.save`)}
            </FText>
          </Pressable>
        </View>
      </Modal>
      <View style={{ width: '100%' }}>
        <View style={{ height: 36 }}>
          {address !== undefined ? (
            <Input
              key={'address'}
              height={34}
              value={address}
              editable={false}
              button={{
                text: t(`placesearch.search`),
                textColor: 'mono.paleBlack',
                buttonColor: 'mono.paleWhite',
                borderColor: 'mono.gray',
                onPress: search,
              }}
              rightIcon={{
                name: 'close-circle',
                color: 'mono.darkGray',
                onPress: resetAddress,
              }}
              rightIconSize={16}
            />
          ) : (
            <Input
              key={'search'}
              height={34}
              value={searchText}
              onChangeText={setSearchText}
              onEnterSubmit={search}
              placeholder={t(`placesearch.placeholder1`)}
              button={{
                text: '',
                icon: 'search',
                textColor: 'mono.paleBlack',
                buttonColor: 'mono.paleWhite',
                borderColor: 'mono.gray',
                style: { minWidth: 36 },
                onPress: search,
              }}
              riched={false}
            />
          )}
        </View>
        <View style={{ marginTop: 6, height: 36 }}>
          {addressEtc !== undefined && (
            <Input
              height={34}
              value={addressEtc}
              onChangeText={onChangeAddressEtc}
              placeholder={t('placesearch.placeholder2')}
            />
          )}
        </View>

        {location && location.locationX !== 0 && location.locationY !== 0 && !isVisible && (
          <View style={{ marginTop: 10 }}>
            <Map label={location.locationEtc} longitude={location.locationX} latitude={location.locationY} />
          </View>
        )}
      </View>
    </SafeAreaView>
  )
}

function removeHtmlTag(htmlString?: string | null) {
  htmlString = htmlString ?? ''
  return htmlString.replace(/<(?:.|\s)*?>/g, '')
}

const styles = StyleSheet.create({
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: getRealColor('dimmed.a60'),
  },
  headerContainer: {
    height: 55,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: getRealColor('gray.g150'),
  },
  viewContainer: {
    width: 480,
    height: 666,
    backgroundColor: getRealColor('mono.white'),
    margin: 'auto',
    borderRadius: 8,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 55,
    height: 55,
    justifyContent: 'center',
    alignItems: 'center',
  },
  saveButton: {
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: getRealColor('main.blue'),
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  active: {
    width: 28,
    height: 28,
    borderWidth: 1,
    borderColor: getRealColor('main.blue'),
    backgroundColor: getRealColor('main.blue'),
    borderRadius: 14,
    justifyContent: 'center',
    alignItems: 'center',
  },
  inactive: {
    width: 28,
    height: 28,
    borderWidth: 1,
    borderColor: getRealColor('mono.gray'),
    borderRadius: 14,
  },
})
