import React, { useMemo, useState } from 'react'
import { Clipboard, Text, View } from 'react-native'

import { Button } from '@rocket-atoms/button'
import { COLOR, FONT } from '@rocket-mono/libs'
import { LocationType, useAstro, useWorkAssignee, useWorkCard, useWorkFile } from '@rocket-mono/providers'
import { useToast } from '@rui/atoms'
import { useTranslation } from 'react-i18next'
import { CardRowGroup } from '../../components/CardRowGroup'
import FileViewer from '../../components/FileViewer'
import { ImagePreviewList } from '../../components/ImagePreviewList'
import Map from '../../components/Map'
import { WorkCardEditor } from '../../components/WorkCardEditor'
import { WorkCardLayout } from '../../components/WorkCardLayout'
import type { ViewProps } from './types'

const ScreenView: React.FC<ViewProps> = ({ ...layoutProps }) => {
  const { t } = useTranslation()
  const { show: showToastMessage } = useToast()
  const { currentCard } = useWorkCard()
  const { option: astroOption } = useAstro()

  const { assigneeList } = useWorkAssignee()

  const { fileList } = useWorkFile()

  const assigneeIds = React.useMemo(
    () =>
      assigneeList.map(({ id, userId, userEmail }) => ({
        id,
        userId: String(userId ?? ''),
        userEmail: userEmail ?? '',
      })),
    [assigneeList],
  )

  const dateProps = useMemo(() => {
    let isTime = false
    let cardDate = {}
    if (currentCard && (currentCard.fromPeriodDate || currentCard.toPeriodDate)) {
      isTime = true
      const { fromPeriodDate, toPeriodDate, isFromPeriodDateTime, isToPeriodDateTime } = currentCard
      const startDate = fromPeriodDate ? new Date(fromPeriodDate) : undefined
      const endDate = toPeriodDate ? new Date(toPeriodDate) : undefined
      const isStartTime = isFromPeriodDateTime !== null ? isFromPeriodDateTime : false
      const isEndTime = isToPeriodDateTime !== null ? isToPeriodDateTime : false
      cardDate = { startDate, endDate, isStartTime, isEndTime }
    }
    return { isTime, cardDate }
  }, [currentCard])

  const [content, setContent] = useState<string | null>()
  const [location, setLocation] = useState<LocationType | null>()

  React.useEffect(() => {
    setContent(undefined)

    if (currentCard !== null && currentCard.content) {
      setTimeout(() => setContent(currentCard.content), 200)
    } else {
      setContent(null)
    }
  }, [currentCard])

  React.useEffect(() => {
    setLocation(undefined)

    if (currentCard !== null && currentCard.location) {
      setTimeout(() => {
        const { location, locationEtc, locationX, locationY } = currentCard
        setLocation({ location, locationEtc, locationX, locationY })
      }, 50)
    } else {
      setLocation(null)
    }
  }, [currentCard?.no])

  if (currentCard === null) return null
  return (
    <WorkCardLayout
      edit={false}
      cardType="ATTEND"
      title={currentCard?.title || ''}
      optionList={[]}
      assigneeIds={assigneeIds}
      {...dateProps}
      {...layoutProps}
    >
      {() => (
        <View style={{ flex: 1 }}>
          {content !== undefined && content !== null && <WorkCardEditor readonly content={content} />}
          {currentCard.contentFiles && currentCard.contentFiles.length > 0 && (
            <div style={{ marginRight: 10, marginLeft: 10 }}>
              <ImagePreviewList
                list={currentCard.contentFiles.map((file: any) => {
                  return {
                    id: file.no,
                    uri: `${astroOption.secureCdnUrl}/images/${file.fileKey}/${file.filename}`,
                    // onPress: () => previewFile(file),
                  }
                })}
              />
            </div>
          )}
          {location && (
            <CardRowGroup
              iconName="map-o"
              title={t('board.card.location')}
              iconSize={16}
              titleSize={16}
              containerStyle={{
                marginBottom: 30,
              }}
              headerContainerStyle={{
                marginHorizontal: 0,
                marginBottom: 0,
              }}
              iconContainerStyle={{
                alignItems: 'stretch',
              }}
              titleStyle={{
                fontWeight: '500',
              }}
              childrenContainerStyle={{
                marginTop: 0,
                // paddingHorizontal: 15,
              }}
              // renderExtraComponent={}
            >
              <div style={{ width: '100%' }}>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'flex-start', paddingBottom: 8 }}>
                  <Text style={{ flex: 1, ...FONT.txtMdMedium, color: COLOR.gray.g800, marginRight: 10, marginTop: 2 }}>
                    {`${location.location} ${location.locationEtc ? location.locationEtc : ''}`}
                  </Text>
                  <Button
                    type={'SECONDARY'}
                    size={'sm'}
                    onPress={() => {
                      Clipboard.setString(`${location.location} ${location.locationEtc ? location.locationEtc : ''}`)
                      showToastMessage({
                        type: 'Success',
                        title: t('cardtodoedit.dialog.copy.button'),
                        position: 'BOTTOM_CENTER',
                      })
                    }}
                  >
                    <Text style={{ ...FONT.txtSmMedium, color: '#333' }}>{t('cardtodoedit.dialog.copy.button')}</Text>
                  </Button>
                </View>
                {location.locationX && location.locationY && (
                  <Map
                    label={location.locationEtc || ''}
                    longitude={location.locationX}
                    latitude={location.locationY}
                  />
                )}
              </div>
            </CardRowGroup>
          )}
          <FileViewer files={fileList} fileSource="MEETING" />
        </View>
      )}
    </WorkCardLayout>
  )
}

export default ScreenView
