import { Combobox } from '@rui/molecules'
import { COLOR, FONT } from '@rocket-mono/libs'
import { Avatar } from '@rui/atoms'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, Pressable, ScrollView, StyleSheet, Text, View } from 'react-native'
import DropdownItemFilled from '../DropdownItemFilled'
import Input from '../Input'
import MemberList from './MemberList'
import TagUser from './TagUser'
import { Props } from './types'

// const MODAL_MAX_HEIGHT = 430

const UserSelectedComponent = ({ containerStyle, listMember, visible, onClose, onChangeUsers }: Props) => {
  const { t } = useTranslation()
  const inputRef = React.useRef(null)
  const [searchText, setSearchText] = React.useState('')

  const selectedUser = React.useMemo(() => {
    if (!listMember) return undefined
    return listMember.filter((item) => item.selected)
  }, [listMember])

  const previewUsers = React.useMemo(() => {
    if (!listMember) return undefined
    return listMember.filter((item) => item.name.indexOf(searchText) > -1)
  }, [listMember, searchText])

  return (
    <View>
      <Pressable style={styles.backdrop} onPress={onClose} />
      <View style={[styles.container, containerStyle]}>
        <Combobox visible={visible} width={312}>
          <Combobox.Header isDirect>
            <View style={styles.headerContainer}>
              <View>
                <Text style={styles.text}>
                  {t('userselected.prefixassign')}
                  <Text style={{ color: COLOR.main.red }}>{selectedUser ? selectedUser.length : 0}</Text>
                  {t('userselected.suffixassign')}
                </Text>
              </View>
              <View style={styles.tagList}>
                {selectedUser ? (
                  selectedUser.map((item, idx) => (
                    <TagUser
                      item={item}
                      onRemove={() => {
                        if (onChangeUsers) {
                          if (!listMember) return undefined
                          const newList = listMember.map((user) => {
                            if (user.id === item.id) {
                              return { ...user, selected: !user.selected }
                            } else {
                              return user
                            }
                          })
                          onChangeUsers(newList)
                        }
                      }}
                      key={`selectedMemberList-${idx}`}
                    />
                  ))
                ) : (
                  <></>
                )}
              </View>
            </View>
          </Combobox.Header>
          <Combobox.Body>
            <View style={styles.body}>
              <Input
                riched={false}
                inputRef={inputRef}
                height={36}
                value={searchText}
                placeholder={t('userselected.placeholder')}
                rightIcon={
                  searchText
                    ? {
                        name: 'close-circle',
                        color: 'gray.g700',
                        onPress: () => {
                          setSearchText('')
                          if (inputRef && inputRef.current && Platform.OS === 'web') {
                            const $inputRef = inputRef.current as HTMLDivElement
                            $inputRef.innerText = ''
                            $inputRef.focus()
                          }
                        },
                      }
                    : undefined
                }
                onChangeText={(text) => {
                  setSearchText(text)
                }}
                rightIconSize={20}
              />
            </View>
            <ScrollView style={styles.scrollArea}>
              <DropdownItemFilled
                label={t('userselected.boardallmember')}
                selected={listMember && selectedUser ? listMember.length === selectedUser.length : false}
                onChangeSelected={function () {
                  if (!listMember) return
                  if (!selectedUser) return
                  if (listMember.length === selectedUser.length) {
                    if (onChangeUsers) {
                      onChangeUsers(
                        listMember.map((item) => ({
                          ...item,
                          selected: false,
                        })),
                      )
                    }
                  } else {
                    if (onChangeUsers) {
                      onChangeUsers(
                        listMember.map((item) => ({
                          ...item,
                          selected: true,
                        })),
                      )
                    }
                  }
                }}
                isCheck
              >
                <Avatar size="xsmall" />
              </DropdownItemFilled>
              {previewUsers ? (
                previewUsers.map((item, idx) => (
                  <MemberList
                    item={item}
                    key={`memberList-${idx}`}
                    onChange={(user) => {
                      if (onChangeUsers) {
                        if (!listMember) return
                        const newList = listMember.map((item) => {
                          if (item.id === user.id) {
                            return { ...item, selected: !item.selected }
                          } else {
                            return item
                          }
                        })
                        onChangeUsers(newList)
                      }
                    }}
                  />
                ))
              ) : (
                <></>
              )}
            </ScrollView>
          </Combobox.Body>
        </Combobox>
      </View>
    </View>
  )
}

export default UserSelectedComponent

const styles = StyleSheet.create({
  headerContainer: {
    width: '100%',
    padding: 8,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g150,
  },
  container: {
    // position: 'absolute',
    width: 312,
  },
  backdrop: {
    position: 'absolute',
    // top: 0,
    // left: 0,
    width: '100%',
    height: '100%',
  },
  body: {
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
  scrollArea: {
    overflow: 'scroll',
    maxHeight: 220,
    marginBottom: 4,
  },
  text: {
    ...FONT.txtXsMedium,
    color: COLOR.gray.g700,
    marginBottom: 8,
  },
  tagList: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    maxHeight: 110,
    overflow: 'scroll',
    alignContent: 'flex-start',
  },
})
