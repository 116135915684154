import { FONT } from '../styles/theme.style'
import type { FontName } from '../types'

export function getFontStyle(fontName: FontName): {
  fontFamily?: string
  fontSize: number
  fontWeight?: 'normal' | 'bold' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900'
  lineHeight?: number
} {
  // @ts-ignore
  return FONT[fontName]
}
