import { Icon } from '@rocket-atoms/icon'
import type { IChatMessageReply } from '@rocket-mono/libs'
import { FONT, getRealColor } from '@rocket-mono/libs'
import moment from 'moment'
import 'moment/min/locales'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, Image, Platform, StyleSheet, Text, View, ViewStyle } from 'react-native'
import Hyperlink from 'react-native-hyperlink'
import MoreButton from '../Components/MoreButton'
import { ItemMoreButtonType } from '../types'
import ChatbubblesOutline from './chatbubblesOutline'

interface Props {
  chatMessageReply: IChatMessageReply[]
  replySelectedIndex?: string
  topIndex: number
  onPopupReplyState?: ({
    parentIndex,
    index,
    state,
    item,
  }: {
    parentIndex: number
    index: number
    state: string
    item?: IChatMessageReply
  }) => void
  replyContainerStyle?: ViewStyle
  onLinkPress?: (url: string, text?: string | undefined) => void
  onPressMoreButton?: (payload: ItemMoreButtonType) => void
}

const ReplyList: React.FC<Props> = ({
  chatMessageReply,
  replySelectedIndex,
  topIndex,
  replyContainerStyle,
  onLinkPress,
  onPressMoreButton,
}) => {
  const { i18n, t } = useTranslation()
  moment.locale(i18n.resolvedLanguage)
  return (
    <>
      {chatMessageReply.map((item, index) => {
        return (
          <View
            style={[
              styles.replyContainer,
              {
                backgroundColor:
                  replySelectedIndex === `${topIndex}-reply-${index}` ? '#e4eef9' : getRealColor('transparent'),
              },
              replyContainerStyle,
            ]}
            key={`${topIndex}-reply-${index}`}
          >
            {/* image container */}
            <View style={styles.replyImageContainer}>
              <View style={styles.replyIconContainer}>
                <Icon name="subdirectory-arrow" size={14} style={styles.replyIcon} color="mono.paleBlack" />
              </View>
              <Image source={{ uri: item.imageUrl }} style={styles.replyImage} />
            </View>
            <View style={styles.rightContainer}>
              <View style={styles.topContainer}>
                <View style={styles.usernameContainer}>
                  <Text style={styles.userName}>{item.name}</Text>
                  <Text style={styles.time}>{t('format.date.LLL', { date: item.regDate })}</Text>
                  {item.recipient ? (
                    <View style={styles.whisperContainer}>
                      <ChatbubblesOutline color={getRealColor('mono.darkGray')} />
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
                {item.moreList && item.moreList.length > 0 && (
                  <View style={styles.moreButton}>
                    <MoreButton
                      onPress={(layoutPayload) => {
                        onPressMoreButton?.({
                          parentIndex: topIndex ?? 0,
                          index,
                          replyItem: item,
                          moreList: item.moreList,
                          layoutPayload,
                        })
                      }}
                    />
                    {/* <AxisContainer
                      isOpen={isReplyOpenMap[`reply-${index}`] ?? false}
                      axisIconName="ellipsis-h"
                      setIsOpen={(isOpen) => {
                        if (
                          typeof isReplyOpenMap[`reply-${index}`] === undefined
                        ) {
                          isReplyOpenMap[`reply-${index}`] = isOpen
                        } else {
                          isReplyOpenMap[`reply-${index}`] =
                            !isReplyOpenMap[`reply-${index}`]
                        }
                        setIsReplyOpenMap({ ...isReplyOpenMap })
                      }}
                      appList={item.moreList}
                      onPopupState={(state) => {
                        if (onPopupReplyState) {
                          onPopupReplyState({
                            parentIndex: topIndex ?? 0,
                            index,
                            state,
                            item,
                          })
                        }
                      }}
                      addText=""
                      buttonAddButtonStyle={{
                        borderWidth: 0,
                        backgroundColor: getRealColor('transparent'),
                        marginRight: 0,
                        height: 10,
                      }}
                      addPopupMarginTop={10}
                      addPopupMarginRight={0}
                      isIconOnly
                    /> */}
                  </View>
                )}
              </View>
              <View style={styles.messageContainer}>
                <View style={{ flex: 1, width: '100%' }}>
                  <Hyperlink
                    linkStyle={{
                      color: '#2980b9',
                      textDecorationLine: 'underline',
                      ...Platform.select({
                        web: {
                          cursor: 'pointer',
                        },
                      }),
                    }}
                    onPress={onLinkPress}
                  >
                    <Text style={styles.message}>
                      <Text
                        style={[
                          replySelectedIndex === `${topIndex}-reply-${index}`
                            ? { ...FONT.textBold }
                            : { ...FONT.textRegular },
                        ]}
                      >
                        {item.message}
                      </Text>
                    </Text>
                  </Hyperlink>
                </View>
              </View>
            </View>
          </View>
        )
      })}
    </>
  )
}

const styles = StyleSheet.create({
  replyContainer: {
    marginTop: 10,
    paddingLeft: 10,
    flexDirection: 'row',
  },
  replyIcon: {
    marginRight: 5,
  },
  replyImage: {
    width: 28,
    height: 28,
    borderRadius: 25,
    alignSelf: 'flex-start',
  },
  replyImageContainer: {
    flexDirection: 'row',
    marginRight: 10,
  },
  replyIconContainer: {
    height: 28,
    justifyContent: 'center',
  },
  rightContainer: {
    flex: 1,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  usernameContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: 19,
  },
  moreButton: {
    width: 35,
  },
  userName: {
    ...FONT.subTextBold,
    lineHeight: Platform.select({
      native: 19.24,
      default: 12.64,
    }),
    color: getRealColor('mono.black'),
    marginRight: 5,
  },
  time: {
    ...FONT.subTextThin,
    color: getRealColor('mono.darkGray'),
  },
  messageContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 3,
  },
  mentionUserName: {
    ...FONT.textRegular,
    color: getRealColor('main.blue'),
  },
  message: {
    ...FONT.textRegular,
    color: getRealColor('mono.paleBlack'),
    ...Platform.select({
      web: {
        wordBreak: 'break-all',
        width: '100%',
      },
      android: {
        width: Dimensions.get('window').width - 120,
      },
    }),
  },
  whisperContainer: {
    width: 12,
    height: 12,
    marginBottom: Platform.select({
      native: 2,
      default: 1,
    }),
    marginLeft: 5,
  },
})

export default ReplyList
