import React from 'react'
import { Animated } from 'react-native'

import { getRealColor } from '@rocket-mono/libs'

import { Props } from './types'
import { styles } from './styles'

export const SkeletonBar: React.FC<Props> = ({ width = 40, height = 40, color }) => {
  const [opacityAnim] = React.useState(new Animated.Value(1))
  const [animatedLoopCheck, setAnimatedLoopCheck] = React.useState(1)

  React.useEffect(() => {
    if (animatedLoopCheck === 0) {
      Animated.timing(opacityAnim, {
        toValue: 1,
        duration: 2000,
        useNativeDriver: false,
      }).start()
      setTimeout(() => {
        setAnimatedLoopCheck(1)
      }, 2500)
    } else {
      Animated.timing(opacityAnim, {
        toValue: 0.5,
        duration: 2000,
        useNativeDriver: false,
      }).start()
      setTimeout(() => {
        setAnimatedLoopCheck(0)
      }, 2500)
    }
  }, [animatedLoopCheck])

  return (
    <Animated.View
      style={[
        styles.skeletonBox,
        { width, height, opacity: opacityAnim },
        color && { backgroundColor: getRealColor(color) },
      ]}
    ></Animated.View>
  )
}
