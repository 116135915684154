import { StyleSheet } from 'react-native'
import { COLOR, FONT } from '@rocket-mono/libs'

export const commonStyles = StyleSheet.create({
  conContainer:{
    position: 'relative',
    flexDirection: 'row',
    marginTop: 12
  },
  labelArea:{
    marginTop: 10,
    minWidth: 92,
    marginRight: 4,
  },
  labelText:{
    ...FONT.txtSm,
    color: COLOR.gray.g700
  },
  contentsArea:{
    position: 'relative',
    flex: 1,
  },
})