import React from 'react'
import { View, Text } from 'react-native'
import EmptyItem from './EmptyItem'
import { Props } from './types'
import { styles } from './styles'

const WorkcardBookmarkGroup: React.FC<Props> = ({ sectionName, children, containerStyle }) => {
  return (
    <View style={[styles.container, containerStyle]}>
      <View>
        <Text style={styles.sectionText}>{sectionName}</Text>
      </View>
      <View style={styles.listArea}>{children ? children : <EmptyItem />}</View>
    </View>
  )
}

export default WorkcardBookmarkGroup
