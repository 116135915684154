import React from 'react'
import Context from './context'
import Provider from './provider'
import { ScreenProps } from './types'
import View from './view'

const ListViewScreen: React.FC<ScreenProps> = () => {
  return (
    <Provider>
      <Context.Consumer>{(context) => context && <View />}</Context.Consumer>
    </Provider>
  )
}

export default ListViewScreen

export * from './types'
