import * as RNSvg from 'react-native-svg'
import XEIcon from './XEIcon'
import FAIcon from './FAIcon'
import FAIconNative from './FAIcon/FAIconNative'
import IconProfileLabelLeader from './IconProfileLabelLeader'
import IconStateCircle from './IconStateCircle'
import WorkIcon from './WorkIcon'
import LayoutTopLogo from './LayoutTopLogo'
import NoContentsIcon from './NoContentsIcon'
import NoProfileImage from './NoProfileImage'
import IconSns from './IconSns'
import SignTopLogo from './SignTopLogo'
import BoardLabel from './BoardLabel'
import CardIcon from './CardIcon'
import FileIcon from './FileIcon'
import FileThumbnail from './FileThumbnail'
import FileCircleIcon from './FileIcon/FileCircleIcon'
import IconButton from './IconButton'
import RocketWorkLogo from './RocketWorkLogo'
import IconBoard from './IconBoard'
import IconButtonNative from './IconButton/IconButtonNative'
import { TooltipPreviewWorkEditArchive } from './TooltipPreview'

import { LayoutTopWorkspaceIcon, LayoutTopArchiveIcon, LayoutTopRocketIcon } from './LayoutTop'

const Icon = Object.assign(XEIcon, {
  FA: FAIcon,
  XE: XEIcon,
})

export {
  RNSvg,
  Icon,
  XEIcon,
  FAIcon,
  FAIconNative,
  IconProfileLabelLeader,
  NoProfileImage,
  IconStateCircle,
  WorkIcon,
  LayoutTopLogo,
  NoContentsIcon,
  IconSns,
  SignTopLogo,
  BoardLabel,
  CardIcon,
  FileIcon,
  LayoutTopWorkspaceIcon,
  LayoutTopArchiveIcon,
  LayoutTopRocketIcon,
  FileThumbnail,
  FileCircleIcon,
  IconButton,
  RocketWorkLogo,
  IconBoard,
  IconButtonNative,
  TooltipPreviewWorkEditArchive,
}
