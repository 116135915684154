import React from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'
import {
  NoContentBoard,
  NoContentBoardArchive,
  NoContentBoardCard,
  NoContentChat,
  NoContentDiscusstion,
  NoContentFile,
  NoContentGathering,
  NoContentGuestWork,
  NoContentMember,
  NoContentMemberAll,
  NoContentMemberExcluded,
  NoContentNoti,
  NoContentSearch,
} from './icons'
import { COLOR, FONT } from '@rocket-mono/libs'

interface Props {
  type:
    | 'work'
    | 'work-guest'
    | 'member'
    | 'member-all'
    | 'member-excluded'
    | 'board'
    | 'board-archive'
    | 'board-card'
    | 'discussion'
    | 'file'
    | 'chat'
    | 'notification'
    | 'gathering'
    | 'search'
  textSize: 'normal' | 'medium'
  text?: string
  subText?: string
  iconWidth: number
}
const NoContentsIcon: React.FC<Props> = ({ type, textSize, text, subText, iconWidth = 115 }) => {
  return (
    <View style={styles.container}>
      <View style={{ marginBottom: 4 }}>
        {type === 'work-guest' && <NoContentGuestWork width={iconWidth} />}
        {type === 'member' && <NoContentMember width={iconWidth} />}
        {type === 'member-all' && <NoContentMemberAll width={iconWidth} />}
        {type === 'member-excluded' && <NoContentMemberExcluded width={iconWidth} />}
        {type === 'board' && <NoContentBoard width={iconWidth} />}
        {type === 'board-archive' && <NoContentBoardArchive width={iconWidth} />}
        {type === 'board-card' && <NoContentBoardCard width={iconWidth} />}
        {type === 'discussion' && <NoContentDiscusstion width={iconWidth} />}
        {type === 'file' && <NoContentFile width={iconWidth} />}
        {type === 'chat' && <NoContentChat width={iconWidth} />}
        {type === 'notification' && <NoContentNoti width={iconWidth} />}
        {type === 'gathering' && <NoContentGathering width={iconWidth} />}
        {type === 'search' && <NoContentSearch />}
      </View>

      <Text
        style={[
          styles.basicStyle,
          textSize === 'normal' && { ...FONT.txtSmMedium },
          textSize === 'medium' && { ...FONT.txtMdMedium },
          textSize === 'normal' && { color: COLOR.gray.g450 },
          textSize === 'medium' && { color: COLOR.gray.g700 },
        ]}
      >
        {text}
      </Text>
      <Text
        style={[
          styles.basicStyle,
          textSize === 'normal' && { ...FONT.txtXs },
          textSize === 'medium' && { ...FONT.txtSm },
          textSize === 'normal' && { color: COLOR.gray.g600 },
          textSize === 'medium' && { color: COLOR.gray.g450 },
        ]}
      >
        {subText}
      </Text>
    </View>
  )
}

export default NoContentsIcon

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  basicStyle: {
    textAlign: 'center',
    marginTop: 0,
    ...Platform.select({
      native: { marginTop: 0 },
    }),
  },
})
