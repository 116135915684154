import { Icon } from '@rocket-atoms/icon'
import { COLOR, FONT, LayoutPayload } from '@rocket-mono/libs'
import { RichText } from '@rui/rich-text-field'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, Pressable, StyleSheet, View } from 'react-native'
import ButtonIcon from '../../../../components/ButtonIcon'
import { TodoItemOptionButton } from '../../../../components/TodoItemOptionButton'

export interface CardGatheringItemType {
  id?: string
  contents?: string
  type?: 'OPINION' | 'FILE'
  memberCount: number | string
  isPublic: boolean
  onPressMore?: (payload: LayoutPayload) => void
  onPressEdit?: (payload: LayoutPayload) => void
}

interface Props {
  item: CardGatheringItemType
  index: number
  onGatheringsChangeText?: (text: string, index: number) => void

  dragElement?: JSX.Element
}

const CardGatheringEditItem: React.FC<Props> = ({ item, dragElement }) => {
  const { t } = useTranslation()
  const viewRef = React.useRef<View>(null)
  const [isDragPress, setIsDragPress] = React.useState(false)
  // const [height, setHeight] = React.useState(32)

  console.log('item.contents', item.contents)

  return (
    <View style={[styles.wrap, isDragPress && styles.wrapDragPress]}>
      {dragElement ? (
        dragElement
      ) : (
        <Pressable
          onPressIn={() => setIsDragPress(true)}
          onPressOut={() => setIsDragPress(false)}
          style={styles.dragArea}
        >
          <Icon name={'drag-vertical'} size={16} color={'gray.g700'} />
        </Pressable>
      )}
      <View style={styles.container} ref={viewRef}>
        <Pressable
          onPress={() => {
            if (!viewRef.current) {
              return
            }
            viewRef.current.measure((x, y, width, height, pageX, pageY) => {
              item.onPressEdit && item.onPressEdit({ x, y, width, height, pageX, pageY })
            })
          }}
        >
          {/* <Text style={styles.itemText}>{item.contents}</Text> */}
          {/* {!!item.contents && <RichText style={styles.itemText}>{item.contents}</RichText>} */}
          {!!item.contents ? <RichText>{item.contents}</RichText> : <View style={{ height: 20 }} />}
        </Pressable>
        <View style={styles.optionArea}>
          <View style={styles.buttonList}>
            <>
              {item.memberCount ? (
                <TodoItemOptionButton
                  icon={'user-o'}
                  iconSize={20}
                  iconColor={'gray.g700'}
                  text={`@${item.memberCount}`}
                  readOnly
                  onPress={() => {
                    if (!viewRef.current) return
                    viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                      item.onPressEdit && item.onPressEdit({ x, y, width, height, pageX, pageY })
                    })
                  }}
                />
              ) : (
                <TodoItemOptionButton
                  icon={'user-o'}
                  iconSize={20}
                  iconColor={'gray.g300'}
                  // stateOffonPress={() => {
                  //   if (!viewRef.current) return
                  //   viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                  //     item.onPressEdit && item.onPressEdit({ x, y, width, height, pageX, pageY })
                  //   })
                  // }}
                />
              )}
            </>
            <>
              {item.type === 'OPINION' && (
                <TodoItemOptionButton
                  icon={'message-o'}
                  iconColor={'gray.g700'}
                  text={`${t('card.collectunit.type')}+': '+${t('card.collectunit.opinion')}`}
                  readOnly
                  onPress={() => {
                    if (!viewRef.current) return
                    viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                      item.onPressEdit && item.onPressEdit({ x, y, width, height, pageX, pageY })
                    })
                  }}
                />
              )}
              {item.type === 'FILE' && (
                <TodoItemOptionButton
                  icon={'file-o'}
                  iconColor={'gray.g700'}
                  text={`${t('card.collectunit.type')}+': '+${t('card.collectunit.file')}`}
                  readOnly
                  onPress={() => {
                    if (!viewRef.current) return
                    viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                      item.onPressEdit && item.onPressEdit({ x, y, width, height, pageX, pageY })
                    })
                  }}
                />
              )}
              {!item.type && (
                <TodoItemOptionButton
                  icon={'folder-check-o'}
                  iconSize={20}
                  iconColor={'gray.g300'}
                  readOnly
                  onPress={() => {
                    if (!viewRef.current) return
                    viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                      item.onPressEdit && item.onPressEdit({ x, y, width, height, pageX, pageY })
                    })
                  }}
                  stateOff
                />
              )}
            </>
            <>
              {item.isPublic ? (
                <TodoItemOptionButton
                  icon={'lock-o'}
                  iconColor={'gray.g700'}
                  readOnly
                  onPress={() => {
                    if (!viewRef.current) return
                    viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                      item.onPressEdit && item.onPressEdit({ x, y, width, height, pageX, pageY })
                    })
                  }}
                />
              ) : (
                <TodoItemOptionButton
                  icon={'lock-o'}
                  iconSize={20}
                  iconColor={'gray.g300'}
                  readOnly
                  onPress={() => {
                    if (!viewRef.current) return
                    viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                      item.onPressEdit && item.onPressEdit({ x, y, width, height, pageX, pageY })
                    })
                  }}
                />
              )}
            </>
          </View>
          <ButtonIcon
            iconName="ellipsis-h"
            iconSize={14}
            onPress={() => {
              if (!viewRef.current) return
              viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                item.onPressMore && item.onPressMore({ x, y, width, height, pageX, pageY })
              })
            }}
            style={{ marginTop: -6 }}
          />
        </View>
      </View>
    </View>
  )
}

export default CardGatheringEditItem

const styles = StyleSheet.create({
  wrap: {
    flexDirection: 'row',
    width: '100%',
    zIndex: 100,
  },
  wrapDragPress: {
    opacity: 0.5,
  },
  container: {
    flex: 1,
    borderWidth: 1,
    borderColor: COLOR.gray.g050,
    backgroundColor: COLOR.gray.g050,
    borderRadius: 4,
    padding: 8,
    marginBottom: 8,
  },
  optionArea: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 12,
  },
  textarea: {
    marginTop: 0,
    ...Platform.select({
      web: {
        outlineWidth: 0,
        cursor: 'default',
      },
    }),
  },
  itemText: {
    ...FONT.txtSm,
  },
  buttonList: {
    flexDirection: 'row',
  },
  dragArea: {
    paddingVertical: 8,
    paddingHorizontal: 4,
  },
})
