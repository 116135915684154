import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { StyleSheet, Text, Pressable, ViewStyle } from 'react-native'
import { COLOR, FONT } from '@rui/foundations'
import { commonStyles } from './styles'

interface Props {
  onPressCard?: () => void
  style?: ViewStyle
}

const CreateWorkCard: React.FC<Props> = ({ onPressCard, style }) => {
  const { t } = useTranslation()
  const [isHover, setIsHover] = useState(false)

  return (
    <Pressable
      style={[commonStyles.commonWrap, styles.container, isHover && { backgroundColor: COLOR.darkmode.warm900 }, style]}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      onPress={onPressCard}
    >
      <Text style={styles.text}>{t('layoutsidenav.newwork')}</Text>
    </Pressable>
  )
}

export default CreateWorkCard

const styles = StyleSheet.create({
  container: {
    height: 54,
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: COLOR.darkmode.warm800,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: COLOR.gray.g800,
    paddingLeft: 12,
    paddingRight: 28,
  },
  text: {
    textAlign: 'center',
    ...FONT.txtXs,
    paddingLeft: 8,
    color: COLOR.darkmode.cool500,
  },
})
