import * as React from 'react'

import { DropdownItemProps, DropdownList } from '@rocket-atoms/dropdown'
import { COLOR } from '@rocket-mono/libs'
import { StyleSheet, View } from 'react-native'
import { SelectorWorkType } from '../../SelectorWork'
import Tab from './Tab'

export type SectionAccordionType = {
  beforeWorkIcon?: 'DSP' | 'WFP' | 'CSP' | 'TOP'
  label: string
  code: string
}

interface Props {
  checkedType?: 'CHECKED' | 'RADIOBOX'
  item: SelectorWorkType
  // code 값
  defaultIndex?: string | string[]
  onSelected?: (selectedItem: SectionAccordionType) => void
}

const getInitialSelected = ({ defaultIndex, item }: { defaultIndex?: string | string[]; item: SelectorWorkType }) => {
  if (!defaultIndex) return []
  if (typeof defaultIndex === 'string') {
    return item.sectionList.filter((section) => section.code === defaultIndex)
  }
  return item.sectionList.filter((section) => defaultIndex.includes(section.code))
}

export const SectionAccordion = ({ checkedType, item, defaultIndex, onSelected }: Props) => {
  const [sectionAllChecked, setSectionAllChecked] = React.useState(true)
  const [open, setOpen] = React.useState(true)
  const [selected, setSelected] = React.useState<DropdownItemProps[]>(getInitialSelected({ defaultIndex, item }))

  return (
    <View style={styles.container}>
      {item.sectionLabel && (
        <Tab
          allChecked={sectionAllChecked}
          setAllChecked={setSectionAllChecked}
          sectionLabel={item.sectionLabel}
          count={item.count}
          open={open}
          onPress={() => setOpen(!open)}
        />
      )}
      {open && (
        <>
          <View>
            <DropdownList
              list={item.sectionList}
              configuration={'NONE'}
              itemType={checkedType === 'RADIOBOX' ? 'RADIOBOX' : 'CHECKED'}
              itemVariant={'DEFAULT'}
              selected={selected}
              setSelected={setSelected}
              setActived={() => {
                console.log('test')
              }}
              onSelect={(item) => {
                onSelected && onSelected(item)
              }}
              itemStyle={{ paddingLeft: 16, borderBottomWidth: 1, borderColor: COLOR.gray.g100 }}
              // itemTextStyle={{...FONT.txtSm}}
            />
          </View>
          <View style={styles.divLine} />
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 3,
    borderColor: COLOR.gray.g150,
  },
  divLine: {
    height: 4,
    backgroundColor: COLOR.gray.g150,
  },
})
