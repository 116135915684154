import React from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { COLOR, FONT } from '@rocket-mono/libs'

interface Props {
  label?: string
  checked?: boolean
  disabled?: boolean
}

const Radio: React.FC<Props> = ({ label, checked, disabled }) => {
  if (disabled) {
    return (
      <View style={styles.container}>
        <View style={[styles.base, styles.disabledStyle]}></View>
        {label && 
          <Text style={[styles.label, styles.disabledLabel]}>{label}</Text>
        }
      </View>
    )
  }
  if (checked) {
    return (
      <View style={styles.container}>
        <View style={[styles.base, { borderWidth: 0, backgroundColor: COLOR.primary.blue500 }]}>
          <View style={styles.checkIcon} />
        </View>
        {label && 
          <Text style={styles.label}>{label}</Text>
        }
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <View style={styles.base}></View>
      {label && 
        <Text style={styles.label}>{label}</Text>
      }
    </View>
  )
}

export default Radio

const styles = StyleSheet.create({
  container:{
    flexDirection:'row',
    alignItems:'center',
  },
  base: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 18,
    height: 18,
    borderWidth: 1,
    borderColor: COLOR.gray.g300,
    borderRadius: 18,
    backgroundColor: COLOR.mono.white,
  },
  disabledStyle: {
    backgroundColor: COLOR.opacity.blk10,
  },
  checkIcon: {
    width: 6,
    height: 6,
    borderRadius: 6,
    backgroundColor: COLOR.mono.white,
  },
  label:{
    ...FONT.txtSmMedium,
    color: COLOR.gray.g900,
    marginLeft: 4
  },
  disabledLabel:{
    color: COLOR.gray.g450
  }
})
