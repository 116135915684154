import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import NavIcon from './NavIcon'

const BottomNav = () => {
  return (
    <View>
      <NavIcon type={'home'} active={true} darkmode={false} />
      <NavIcon type={'message'} active={false} darkmode={false} />
      <NavIcon type={'bell'} active={false} darkmode={false} />
      <NavIcon type={'search'} active={false} darkmode={false} />
    </View>
  )
}

export default BottomNav

const styles = StyleSheet.create({
  container: {

  },
})
