import { View } from '@rocket-mono/foundations'
import { COLOR } from '@rocket-mono/libs'
import * as React from 'react'
import {
  Animated,
  Dimensions,
  GestureResponderHandlers,
  PanResponder,
  Platform,
  StyleSheet,
  useWindowDimensions,
} from 'react-native'
import { DargHandler } from './drag'

interface Props {
  isAnimated: boolean
  maxWidth: number
  minWidth?: number
  animatedWidth: Animated.Value
  children?: JSX.Element[] | JSX.Element
}

// const DargHandler = ({ panHandlers }: { panHandlers: GestureResponderHandlers }) => {
//   return <div style={styles.handler} {...panHandlers}></div>
// }

export const Resizable: React.FC<Props> = ({ isAnimated, maxWidth, minWidth = 435, animatedWidth, children }) => {
  const { width: deviceWidth } = useWindowDimensions()
  const [contentsWidth, setContentsWidth] = React.useState(deviceWidth < 800 ? deviceWidth : 800)
  const [moveX, setMoveX] = React.useState<number | undefined>()

  const panResponder = React.useRef(
    PanResponder.create({
      // onStartShouldSetPanResponder: () => true,
      // onMoveShouldSetPanResponder: () => true,
      onStartShouldSetPanResponder: () => {
        console.log('start')
        return true
      },
      onStartShouldSetPanResponderCapture: () => {
        console.log('start capture')
        return true
      },
      onMoveShouldSetPanResponder: () => {
        console.log('move')
        return true
      },
      onMoveShouldSetPanResponderCapture: () => {
        console.log('move capture')
        return true
      },
      // onPanResponderMove: () => {
      //   console.log('moving')
      // },
      onPanResponderStart: (e) => {
        console.log('start', e)
      },
      onPanResponderMove: (_e, g) => {
        console.log('move', g)
        setMoveX(g.moveX)
      },
      onPanResponderRelease: () => {
        console.log('release')
        setMoveX(undefined)
      },
    }),
  ).current

  React.useEffect(() => {
    if (moveX !== undefined) {
      setContentsWidth(moveX > deviceWidth - 40 ? 40 : deviceWidth - moveX)
    }
  }, [moveX, deviceWidth])

  React.useEffect(() => {
    let width = contentsWidth
    if (contentsWidth > maxWidth) width = maxWidth
    else if (contentsWidth < minWidth) width = minWidth
    if (isAnimated) animatedWidth.setValue(width)
  }, [isAnimated, maxWidth, contentsWidth])

  return (
    <View style={styles.content}>
      {Platform.OS === 'web' && <DargHandler setMoveX={setMoveX} />}
      <Animated.View style={[{ width: animatedWidth, minWidth: 435 }]}>
        <>{children}</>
      </Animated.View>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    ...Platform.select({
      // web: {
      //   height: '100vh',
      //   width: '100vw',
      // },
      native: {
        height: Dimensions.get('window').height,
        width: '100%',
      },
    }),
  },
  handler: {
    left: 0,
    alignItems: 'center',
    ...Platform.select({
      native: {
        display: 'none',
      },
      web: {
        width: 16,
        height: '100%',
        backgroundColor: COLOR.transparent.base,
        // transform: [{ translateX: 8 }],
        cursor: 'ew-resize',
        zIndex: 999,
      },
    }),
  },
})
