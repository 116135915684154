import { COLOR, FONT } from '@rocket-mono/libs'
import { StyleSheet } from 'react-native'

export const commonStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contents: {
    flex: 1,
    paddingBottom: 12,
  },
  buttonArea: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  ctaButton: {
    marginLeft: 8,
  },
  titleArea: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  title: {
    ...FONT.txtMdBold,
    marginLeft: 6,
  },
  initText: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g600,
  },
  labelText: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g600,
    marginTop: 20,
    marginBottom: 8,
  },
  infoTextArea: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
  },
  infoText: {
    ...FONT.txtXsMedium,
    color: COLOR.gray.g600,
    marginLeft: 4,
  },
  infoTextLabel: {
    color: COLOR.gray.g900,
    marginRight: 4,
  },
  valueText: {
    position: 'relative',
    ...FONT.txtSm,
    color: COLOR.gray.g900,
    marginLeft: 4,
  },
  labelWrap:{
    borderRadius: 4,
    padding: 4,
    marginTop: -2,
    backgroundColor:COLOR.gray.g050,
  },
  labelWrapHover:{
    backgroundColor:COLOR.gray.g100
  }
})
