import { useContext } from 'react'
import { Context } from './context'
import type { ContextProps } from './types'

function useModalBottom() {
  const context = useContext<ContextProps | undefined>(Context)

  if (context === undefined) throw new Error('There must be a ModalBottom as Ancestor of all Stomp Hooks and HOCs')

  return context
}

export default useModalBottom
