import { AutomationResponse, ProjectMember, type Project } from '@rocket/types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useAstro } from '../../AstroProvider'
import { useCurrentUser } from '../../CurrentUserProvider'
import { useSubscription } from '../../StompProvider'
import { useWork } from '../WorkProvider'
import Context from './context'
import type { ProviderProps } from './types'

export const WorkProjectProvider: React.FC<ProviderProps> = ({
  projectId,
  onUnauthorized,
  onDeleted,
  children,
  fallback = <></>,
}: ProviderProps) => {
  const { astro } = useAstro()
  const { currentUser } = useCurrentUser()

  const { fetchProjectList } = useWork()
  const [currentProjectId, setCurrentProjectId] = useState<string>()
  const [currentProject, setCurrentProject] = useState<Project | null>()
  const [projectMemberList, setProjectMemberList] = useState<ProjectMember[] | null>()

  const [currentAutomationList, setCurrentAutomationList] = useState<AutomationResponse[] | null>()

  const [totalCardCount, setTotalCardCount] = useState<number>(0)
  const [doneCardCount, setDoneCardCount] = useState<number>(0)

  const currentProjectMember = useMemo(() => {
    if (projectMemberList === null || projectMemberList === undefined) return null
    return projectMemberList.find(({ userId }) => userId === String(currentUser.id)) ?? null
  }, [currentUser, projectMemberList])

  const authorized = useMemo(() => {
    if (currentProject && projectMemberList && currentProjectMember === null) {
      return false
    } else if (currentProject && projectMemberList && currentProjectMember) {
      return true
    } else {
      return undefined
    }
  }, [currentProject, projectMemberList, currentProjectMember])

  const fetchAutomation = useCallback((projectId: string) => {
    astro
      .readAutomation(projectId)
      .then(setCurrentAutomationList)
      .catch(() => setCurrentAutomationList(null))
  }, [])

  const fetchProject = useCallback((projectId: string) => {
    console.log('fetchProject', projectId)
    fetchProjectList()
    astro
      .readProject(projectId)
      .then(setCurrentProject)
      .catch((e) => {
        console.error('fetchProject', e)
        setCurrentProject(null)
      })
    astro
      .readProjectMemberList(projectId, false, '&')
      .then(setProjectMemberList)
      .catch(() => setProjectMemberList(null))
    fetchAutomation(projectId)
    astro
      .readCardRelationCount(Number(projectId))
      .then((count: string) => {
        setTotalCardCount(Number(count))
      })
      .catch(() => undefined)
    astro
      .readCardRelationCount(Number(projectId), undefined, true)
      .then((count: string) => {
        setDoneCardCount(Number(count))
      })
      .catch(() => undefined)
  }, [])

  useEffect(() => {
    const id = currentProjectId ?? projectId

    if (id !== undefined) fetchProject(id)
    else {
      setCurrentProject(null)
      setProjectMemberList(null)
    }
  }, [projectId, currentProjectId])

  useEffect(() => {
    if (authorized !== undefined && authorized === false) {
      const duration = 1500
      // showToastMessage({
      //   message: t('workboardmemberview.toast.nopermission'),
      //   viewPosition: 'TOP_CENTER',
      //   duration,
      // })
      setTimeout(() => onUnauthorized(), duration)
    }
  }, [authorized])

  useEffect(() => {
    if (currentProject?.deleted) {
      onDeleted()
    }
  }, [currentProject])

  // useSubscription(`/subscribe/${currentUser.id}/project`, ({ body }) => {
  //   console.log(`useSubscription - /subscribe/${currentUser.id}/project`, body, projectId, projectId === body)
  //   if (projectId === body) fetchProject(projectId)
  // })

  useSubscription([`/subscribe/${currentUser.id}/project`, `/subscribe/project/${projectId}/update`], ({ body }) => {
    console.log(`useSubscription - /subscribe/${currentUser.id}/project`, body, projectId, projectId === body)
    console.log(`useSubscription - /subscribe/project/${projectId}/update`, body)
    if (projectId === body) fetchProject(projectId)
  })

  useSubscription(
    [
      `/subscribe/v2/project/${projectId}/automation/created`,
      `/subscribe/v2/project/${projectId}/automation/updated`,
      `/subscribe/v2/project/${projectId}/automation/deleted`,
    ],
    ({ body }) => {
      console.log(`useSubscription ${projectId} automation - created, updated, deleted`, body)
      const automation = JSON.parse(body) as AutomationResponse
      fetchAutomation(String(automation.workId))
    },
  )

  useEffect(() => {
    console.debug('provider-WorkProjectProvider', { projectId, currentProject, projectMemberList })
  }, [projectId, currentProject, projectMemberList])

  if (currentProject === undefined || projectMemberList === undefined) {
    return fallback
    // if (Platform.OS === 'web') {
    //   return <Skeleton path={location.pathname} />
    // } else {
    //   return <></>
    // }
  }

  return (
    <Context.Provider
      value={{
        projectId,
        currentProject,
        currentProjectMember,
        projectMemberList,
        currentAutomationList,
        totalCardCount,
        doneCardCount,
        setCurrentProjectId,
        fetchAutomation,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export * from './hooks'
