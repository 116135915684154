import React, { useState } from 'react'
import { StyleSheet, View, Text, Pressable } from 'react-native'
import { FAIcon } from '@rocket-atoms/icon'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { COLOR, FONT } from '@rocket-mono/libs'


interface Props {
  iconName: IconProp
  text: string
  onPress: () => void
}

const ChangeButton = ({iconName, text, onPress}: Props) => {
  const [isHover, setIsHover] = useState(false)

  return(
    <Pressable
      onPress={onPress}
      onHoverIn={()=>setIsHover(true)}
      onHoverOut={()=>setIsHover(false)}
      style={[styles.container, isHover && styles.hoverContainer]}
    >
      <FAIcon iconName={iconName} size={'xs'} color={'gray.g600'}/>
      <Text style={styles.label}>{text}</Text>
    </Pressable>
  )
}

export default ChangeButton

const styles = StyleSheet.create({
  container:{
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderWidth: 1,
    borderColor: COLOR.gray.g300,
    borderRadius: 4,
    marginHorizontal: 4
  },
  hoverContainer:{
    borderColor: COLOR.gray.g400,
    backgroundColor: COLOR.gray.g050
  },
  label:{
    ...FONT.txtSm,
    color: COLOR.gray.g700,
    marginLeft:4
  },
})