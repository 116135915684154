import * as React from 'react'
import { Platform } from 'react-native'

import { COLOR } from '@rocket-mono/libs'

import { Context } from './context'
import type { ProviderProps, MessageType } from './types'

import View from './view'
import RCView from '../View'

export const Provider = ({ insetsBottom, children }: ProviderProps) => {
  const [message, setMessage] = React.useState<MessageType>()
  // const { show: showBackdrop, hide: hideBackDrop } = useBackdrop()

  const show = React.useCallback((message: MessageType) => {
    // if (Platform.OS === 'web') showBackdrop('ModalBottom')
    setMessage(message)
  }, [])

  const hide = React.useCallback(() => {
    // if (Platform.OS === 'web') hideBackDrop('ModalBottom')
    setMessage(undefined)
  }, [])

  return (
    <Context.Provider value={{ show, hide }}>
      {children}
      {message !== undefined && (
        <>
          {Platform.OS !== 'web' && (
            <RCView
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                backgroundColor: COLOR.mono.black,
                opacity: 0.7,
                zIndex: 999,
              }}
            />
          )}
          <View {...message} insetsBottom={insetsBottom} visible={!!message} />
        </>
      )}
    </Context.Provider>
  )
}
