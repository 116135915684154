import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { EventProvider } from '@rocket-mono/libs'
import { Button } from '@rocket/atoms'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useRule } from '../../hooks'
import Group from './group'
import { RuleListItemType } from './types'

const ListView: React.FC = () => {
  const { t } = useTranslation()
  const { boardList, ruleList, elementList, onCreateRule } = useRule()

  const handleCreateRule = React.useCallback(() => {
    onCreateRule()
  }, [])

  const { doList, doingList, doneList } = React.useMemo(() => {
    const doList: RuleListItemType[] = []
    const doingList: RuleListItemType[] = []
    const doneList: RuleListItemType[] = []

    if (!ruleList) return { doList, doingList, doneList }

    boardList.forEach((board) => {
      const element = elementList.find((element) => element.id === board.projectElementId)
      const isFirst = element?.order === 0
      const isLast = element?.order === element?.maxOrder

      const filterRuleList = ruleList.filter((rule) => rule.applyBoard === board.id)

      if (filterRuleList.length > 0) {
        if (isFirst) {
          doList.push(...filterRuleList)
        } else if (isLast) {
          doneList.push(...filterRuleList)
        } else {
          doingList.push(...filterRuleList)
        }
      }
    })
    return { doList, doingList, doneList }
  }, [boardList, ruleList, elementList])

  return (
    <View style={styles.scrollContainer}>
      <EventProvider>
        <ScrollView>
          <View style={styles.btnContainer}>
            <Button type="PRIMARY" beforeIcon={faPlus} text={t('rule.modal.make')} onPress={handleCreateRule} />
          </View>
          {doList.length > 0 ? <Group type="DO" list={doList} /> : <></>}
          {doingList.length > 0 ? <Group type="DOING" list={doingList} /> : <></>}
          {doneList.length > 0 ? <Group type="DONE" list={doneList} /> : <></>}
        </ScrollView>
      </EventProvider>
    </View>
  )
}

const styles = StyleSheet.create({
  scrollContainer: {
    flex: 1,
  },
  btnContainer: {
    paddingTop: 16,
    paddingHorizontal: 20,
    flexDirection: 'row',
    marginBottom: 12,
  },
})

export default ListView
