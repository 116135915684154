import { BookmarkType, ProjectType } from '@rocket-mono/providers'

export const DROPABLE_GROUP_VIEW_ID = 'droppable-group-view'
export const DROPABLE_CREATE_VIEW_ID = 'droppable-create-view'
export const DROPABLE_GROUP_CARD_ID = `droppable-group-card-`

export const DROPABLE_FAVORITE_VIEW_ID = 'droppable-favorite-view'

export const workCardConvert = (
  project: ProjectType,
  pressCard?: (id: string) => void,
  favoriteList?: BookmarkType[],
  createFavorite?: (projectId: string) => void,
  deleteFavorite?: (projectId: string) => void,
) => {
  const { id, shared: isShared, title: workName, lastActivityAt } = project

  const isFavorite = false
  const isLatest = false
  const isFavoriteMark = favoriteList !== undefined && favoriteList.map(({ refKey }) => refKey).includes(id)
  const workspaceName = 'workspaceName'
  const workType = project.type.code
  const onPressFavorite = () => {
    if (isFavoriteMark) {
      deleteFavorite && deleteFavorite(id)
    } else {
      createFavorite && createFavorite(id)
    }
  }
  const onPressCard = () => {
    pressCard && pressCard(id)
  }
  const isLearnMoreSelect = false

  return {
    id,
    isShared,
    isFavorite,
    isLatest,
    isFavoriteMark,
    workspaceName,
    workType,
    workName,
    onPressCard,
    onPressFavorite,
    isLearnMoreSelect,
    lastActivityAt,
  }
}
