import * as React from 'react'
import { View } from 'react-native'
import IconFile from '../../IconFile'
import { styles } from './styles'
import type { FileThumbnailProps } from './type'

export const FileThumbUNKNOWN: React.FC<FileThumbnailProps> = ({ width = 48, height = 48 }) => {
  const borderRadius = width / 3
  return (
    <View style={[styles.wrap, styles.unformatted, { width, height, borderRadius }]}>
      <IconFile.Unknown.Medium />
    </View>
  )
}
