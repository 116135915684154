import * as React from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAngleDown, faAngleUp, faGlobe } from '@fortawesome/free-solid-svg-icons'
import { COLOR, FONT, getBoxShadow } from '@rocket-mono/libs'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import LanguageSelectItem from './Item'
import { FAIcon } from '@rocket-atoms/icon'

export interface SelectListType {
  itemCode: string
  label: string
  afterIconName?: IconProp
  active: boolean
}
interface Props {
  list: SelectListType[]
  isOpen: boolean
  setIsOpen: (arg0: boolean) => void
  onLanguagePress: (lang: string) => void
}

const LanguageSelect = ({ list, isOpen, setIsOpen, onLanguagePress }: Props) => {
  const [isHover, setIsHover] = React.useState(false)

  const selectedItem = React.useMemo(() => {
    const item = list.find((item) => item.active)
    return item ? item : list[0]
  }, [list])

  return (
    <View style={styles.container}>
      <Pressable
        onHoverIn={() => setIsHover(true)}
        onHoverOut={() => setIsHover(false)}
        onPress={() => setIsOpen(!isOpen)}
        style={[styles.buttonStyle, isHover && { borderColor: COLOR.gray.g200 }]}
      >
        <View style={styles.flexBox}>
          <FAIcon iconName={faGlobe} size={'2xs'} color={'primary.blue500'} />
          <Text style={styles.valueStyle}>{selectedItem.label}</Text>
        </View>
        <FAIcon iconName={isOpen ? faAngleUp : faAngleDown} size={'2xs'} color={'gray.g400'} />
      </Pressable>
      {isOpen && (
        <View style={styles.box} nativeID="language-select-view">
          {list.map((item, idx) => (
            <LanguageSelectItem
              item={item}
              itemCode={selectedItem.itemCode}
              onPressButton={(code) => onLanguagePress(code)}
              setIsOpen={setIsOpen}
              key={`selectItem-${idx}`}
            />
          ))}
        </View>
      )}
    </View>
  )
}

export default LanguageSelect

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  flexBox: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 36,
    paddingHorizontal: 8,
    borderWidth: 1,
    borderColor: COLOR.gray.g150,
    borderRadius: 4,
  },
  valueStyle: {
    ...FONT.txtSm,
    color: COLOR.gray.g800,
    marginLeft: 4,
  },
  box: {
    position: 'absolute',
    top: 40,
    overflow: 'hidden',
    width: '100%',
    backgroundColor: COLOR.mono.white,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    paddingVertical: 4,
    ...getBoxShadow(0, 0, 0, 0.1, 4, 0, 0, 0),
  },
})
