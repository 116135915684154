import { Icon } from '@rocket-atoms/icon'
import { Text } from '@rocket-atoms/text'
import { COLOR } from '@rocket-mono/libs'
import * as React from 'react'
import { Platform, Pressable, StyleSheet, View } from 'react-native'
import CardTypeIcon from '../CardTypeIcon'
import type { DropdownItemType } from './types'

interface Props {
  isActive: boolean
  item: DropdownItemType
  onPress: () => void
  listType?: 'line' | 'fill'
  isIconCheck?: boolean
}

const DropdownItem: React.FC<Props> = ({ isActive, item, onPress, listType, isIconCheck = true }) => {
  const [isHover, setIsHover] = React.useState(false)

  return (
    <Pressable
      style={[
        styles.item,
        {
          backgroundColor: isActive
            ? listType === 'line'
              ? COLOR.mono.white
              : COLOR.main.blue
            : isHover
            ? COLOR.gray.g050
            : COLOR.mono.white,
        },
      ]}
      onPress={onPress}
      onHoverIn={() => {
        setIsHover(true)
      }}
      onHoverOut={() => setIsHover(false)}
    >
      <View style={styles.innerItem}>
        {item.key === 'TASKREPORT' ? (
          <View style={styles.ifCardType}>
            <CardTypeIcon size={16} type={'MISSION'} />
          </View>
        ) : item.key === 'MEETING' ? (
          <View style={styles.ifCardType}>
            <CardTypeIcon size={16} type={item.key} />
          </View>
        ) : item.key === 'GATHERING' ? (
          <View style={styles.ifCardType}>
            <CardTypeIcon size={16} type={item.key} />
          </View>
        ) : (
          <>
            {!!item.labelIcon && (
              <Icon
                name={item.labelIcon.name}
                size={item.labelIcon.size}
                color={isActive && isIconCheck ? 'primary.blue500' : item.labelIcon.color}
                style={{
                  marginRight: 4,
                  ...Platform.select({
                    native: {
                      marginTop: 4,
                    },
                    web: {
                      marginTop: 3,
                    },
                  }),
                }}
              />
            )}
          </>
        )}
        <Text
          fontName="txtSm"
          fontColor={isActive ? (listType === 'line' ? 'primary.blue500' : 'mono.white') : 'mono.black'}
        >
          {item.label}
        </Text>
      </View>
      {isActive && listType === 'fill' && <Icon name="check" color="mono.white" size={12} />}
      {isActive && listType === 'line' && <Icon name="check" color="primary.blue500" size={12} />}
    </Pressable>
  )
}

export default DropdownItem

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    borderColor: COLOR.gray.g200,
    height: 36,
  },
  innerItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  ifCardType: {
    marginTop: 2,
    marginRight: 4,
  },
})
