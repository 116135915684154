import { useContext } from 'react'

import Context from './context'
import type { ContextProps } from './types'

export const useWorkAssignee = () => {
  const context = useContext<ContextProps | undefined>(Context)

  if (context === undefined)
    throw new Error('There must be a rocket-mono WorkAssigneeProvider as Ancestor of all Hooks and HOCs')

  return context
}
