import * as React from 'react'

import { IColors, LayoutPayload, removeItemAtIndex, replaceItemAtIndex } from '@rocket-mono/libs'
import { AssigneeResponse, ChannelMember, File, ProjectMember } from '@rocket/types'
import { useToast } from '@rui/atoms'
import { useTranslation } from 'react-i18next'
import { Platform } from 'react-native'
import DropdownListGroupV2 from '../DropdownListGroup'
import { TodoItemType } from '../TodoItem/type'
import { uuidV4 } from '../utils'
import Context from './context'
import type { AddFileType, BriefUser, DropDown, ProviderProps, RemotePathType, Todo } from './types'

const Provider = ({
  astro,
  subscribe,
  channelId,
  relatedDomain,
  relatedDomainId,
  projectId,
  boardRoomId,
  children,
  currentUser,
  fileLoad,
  onFileLoad,
  clearFileLoad,
  onPreview,
  onPressDownload,
  onPressFileOpen,
  isExists,
}: ProviderProps) => {
  const { t } = useTranslation()
  const [todoList, setTodoList] = React.useState<Todo[]>()
  const [remotePathList, setRemotePathList] = React.useState<RemotePathType[]>([])
  const [users, setUsers] = React.useState<{ id: string; user: BriefUser | undefined }[]>([
    { id: String(currentUser.id), user: currentUser },
  ])
  const [userState, setUserState] = React.useState<'idle' | 'send'>('idle')
  const [channelList, setChannelList] = React.useState<ChannelMember[]>()
  const [projectMemberList, setProjectMemberList] = React.useState<ProjectMember[]>()
  const [dropdown, setDropdown] = React.useState<DropDown>()
  const [addFileList, setAddFileList] = React.useState<AddFileType[]>([])

  const { show: showToastMessage } = useToast()

  React.useEffect(() => {
    if (userState === 'idle') return
    void Promise.all(users.filter(({ user }) => !user).map((user) => astro.readUser(user.id))).then((list) => {
      setUsers((prev) => {
        return prev.map((user) => {
          const index = list.findIndex((item) => String(item.no || 0) === user.id)
          if (index > -1) {
            return {
              ...user,
              user: list[index],
            }
          } else {
            return user
          }
        })
      })
      setUserState('idle')
    })
  }, [users, userState])

  const fetchFileDownloadUrl = React.useCallback((file: File) => {
    setUsers((prev) => {
      const index = prev.findIndex((user) => user.id === String(file.userId))
      if (index > -1) {
        return prev
      } else {
        return [...prev, { id: String(file.userId), user: undefined }]
      }
    })
    if (file.path)
      astro.readFilePresignedUrl(file.path).then((url) => {
        setRemotePathList((prev) => [
          ...prev,
          {
            id: file.id,
            url,
          },
        ])
      })
    return {
      ...file,
    }
  }, [])

  const fetchAssignee = React.useCallback((assignee: AssigneeResponse) => {
    setUsers((prev) => {
      const index = prev.findIndex((user) => user.id === String(assignee.userId))
      if (index > -1) {
        return prev
      } else {
        return [...prev, { id: String(assignee.userId), user: undefined }]
      }
    })
    return {
      ...assignee,
    }
  }, [])

  const fetchExtra = React.useCallback(async (todo: Todo): Promise<Todo> => {
    const [assignees, files] = await Promise.all([
      astro.readAssignee('todo', todo.id || ''),
      astro.readFile('todo', todo.id || ''),
    ])
    if (todo.doneInfo && todo.doneInfo.userId) {
      setUsers((prev) => {
        const index = prev.findIndex((user) => user.id === String(todo.doneInfo?.userId))
        if (index > -1) {
          return prev
        } else {
          return [...prev, { id: String(todo.doneInfo?.userId), user: undefined }]
        }
      })
    }
    return {
      ...todo,
      key: uuidV4(),
      assignees: assignees.map((assignee) => fetchAssignee(assignee)),
      files: files.map((file) => fetchFileDownloadUrl(file)),
    }
  }, [])

  const getList = React.useCallback(() => {
    if (relatedDomain === undefined || relatedDomainId === undefined) {
      setTodoList([])
      return
    }
    void astro
      .readTodoList(relatedDomain, relatedDomainId)
      .then((list: Todo[]) => {
        return Promise.all(
          list
            .sort((a, b) => {
              if (typeof a.sequenceNumber === 'undefined' || typeof b.sequenceNumber === 'undefined') return 0
              return a.sequenceNumber - b.sequenceNumber
            })
            .map((todo) => fetchExtra(todo)),
        )
      })
      .then((list) => {
        console.log('setTodoList', list)
        setTodoList(list)
        setUserState('send')
      })
  }, [astro, relatedDomain, relatedDomainId])

  React.useEffect(() => {
    getList()
    // setTodoList(todoListDummy as unknown as Todo[])
  }, [relatedDomainId])

  React.useEffect(() => {
    if (projectId) {
      void astro.readProjectMemberList(String(projectId), false, '&').then(setProjectMemberList)
    }
    // setProjectMemberList(projectMemberListDummy as unknown as ProjectMember[])
  }, [astro, projectId])

  React.useEffect(() => {
    void astro.readChannelMemberList(channelId).then(setChannelList)
    // setChannelList(channelListDummy as unknown as ChannelMember[])
  }, [astro, channelId])

  const getPeriod = React.useCallback(
    (todo: Todo) => {
      if (!todo.periodOption) return undefined
      if (todo.periodOption.fromPeriodDate && todo.periodOption.toPeriodDate) {
        const from = new Date(todo.periodOption.fromPeriodDate)
        const to = new Date(todo.periodOption.toPeriodDate)
        const fromFormat = todo.periodOption.isFromTime ? 'format.date.LLL' : 'format.date.L'
        const toFormat = todo.periodOption.isToTime ? 'format.date.LLL' : 'format.date.L'
        return `${t(fromFormat, { date: from })} - ${t(toFormat, {
          date: to,
        })}`
      } else if (todo.periodOption.fromPeriodDate) {
        const from = new Date(todo.periodOption.fromPeriodDate)
        const fromFormat = todo.periodOption.isFromTime ? 'format.date.LLL' : 'format.date.L'
        return `${t(fromFormat, { date: from })} - `
      } else if (todo.periodOption.toPeriodDate) {
        const to = new Date(todo.periodOption.toPeriodDate)
        const toFormat = todo.periodOption.isToTime ? 'format.date.LLL' : 'format.date.L'
        return ` - ${t(toFormat, {
          date: to,
        })}`
      } else {
        return undefined
      }
    },
    [t],
  )

  const getPeriodColor = React.useCallback((todo: Todo): IColors | undefined => {
    if (!todo.periodOption) return undefined
    if (!todo.periodOption.toPeriodDate) return undefined
    const to = new Date(todo.periodOption.toPeriodDate).getTime() - 172800000 // 2일
    const now = new Date().getTime()
    if ((todo.doneInfo && todo.doneInfo.doneAt) || to < now) {
      return 'main.red'
    } else {
      return undefined
    }
  }, [])

  const EXT_MAP = {
    doc: ['txt', 'doc', 'hwp'],
    ppt: ['ppt', 'pptx'],
    xls: ['xls', 'xlsx'],
    zip: ['zip', 'rar', 'alz', '7z'],
    pdf: ['pdf'],
  }

  const fileType = React.useCallback((mimeType?: string, extension?: string) => {
    if (!mimeType) return ''
    if (!extension) return ''
    if (mimeType.startsWith('image')) {
      return 'preview'
    } else if (EXT_MAP.doc.includes(extension)) {
      return 'doc'
    } else if (EXT_MAP.ppt.includes(extension)) {
      return 'ppt'
    } else if (EXT_MAP.xls.includes(extension)) {
      return 'xls'
    } else if (mimeType.startsWith('video')) {
      return 'video'
    } else if (EXT_MAP.zip.includes(extension)) {
      return 'zip'
    } else if (EXT_MAP.pdf.includes(extension)) {
      return 'pdf'
    } else {
      return ''
    }
  }, [])

  const getCompleteUserName = React.useCallback(
    (userId?: string | number) => {
      if (!userId) return ''
      const projectMember = projectMemberList?.find((member) => member.userId === String(userId))
      if (projectMember) {
        return projectMember.name
      }
      const user = users.find((user) => user.id === String(userId))
      if (user) {
        return user.user?.userName || ''
      }
      return ''
    },
    [projectMemberList, users],
  )

  const getUserName = React.useCallback(
    (userId?: string | number) => {
      if (!userId) return ''
      const user = users.find((user) => user.id === String(userId))
      return user?.user?.userName || ''
    },
    [users],
  )

  const getUserEmail = React.useCallback(
    (userId?: string | number) => {
      if (!userId) return ''
      const user = users.find((user) => user.id === String(userId))
      return user?.user?.userEmail || ''
    },
    [users],
  )

  const isProjectOwner = React.useMemo(() => {
    if (projectMemberList === undefined) return false
    return projectMemberList.some(
      (member) => member.userId === String(currentUser.id) && (member.auth === 'OWNER' || member.auth === 'MANAGER'),
    )
  }, [projectMemberList, currentUser])

  const isChannelOwner = React.useMemo(() => {
    if (channelList === undefined) return false
    return channelList.some(
      (member) => member.userId === String(currentUser.id) && (member.isOwner || member.isManager),
    )
  }, [channelList, currentUser])

  const isPermission = React.useMemo(() => {
    if (isProjectOwner) return true
    else if (isChannelOwner) return true
    else return false
  }, [isProjectOwner, isChannelOwner])

  const getFileAccess = React.useCallback(
    (todo: Todo, file: File) => {
      if (!todo.fileOption) return true
      else if (todo.fileOption.accessPermission === 'EVERYONE') return true
      else if (todo.fileOption.accessPermission === 'UPLOADER_OR_AUTHORITY') {
        if (isProjectOwner) return true
        else if (isChannelOwner) return true
        else if (file.userId === currentUser.id) return true
        else return false
      } else return false
    },
    [isProjectOwner, isChannelOwner, currentUser],
  )

  const fileDownload = React.useCallback(
    (file: File) => {
      if (onPressDownload !== undefined) onPressDownload(file)
      else if (Platform.OS === 'web') {
        const uri = remotePathList.find((remotePath) => remotePath.id === file.id)?.url || ''
        fetch(`${uri}`, {
          method: 'GET',
          headers: { 'Cache-Control': 'no-cache' },
        })
          .then((res) => res.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = file.originalName ?? ''
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      }
    },
    [remotePathList, onPressDownload],
  )

  const getEntryDisabled = React.useCallback((todo: Todo) => {
    if (!todo.fileOption) return true
    else if (todo.fileOption.isUploadable) return false
    else return true
  }, [])

  const getOptionVisible = React.useCallback((todo: Todo) => {
    let is = false
    if (
      (todo.assignees && todo.assignees.length > 0) ||
      (todo.files && todo.files.length > 0) ||
      (todo.fileOption && todo.fileOption.accessPermission !== null) ||
      (todo.periodOption && (todo.periodOption.fromPeriodDate || todo.periodOption.toPeriodDate))
    ) {
      is = true
    }
    return is
  }, [])

  const getFileVisible = React.useCallback((todo: Todo) => {
    let is = false
    if ((todo.files && todo.files.length > 0) || (todo.fileOption && todo.fileOption.accessPermission !== null)) {
      is = true
    }
    return is
  }, [])

  type LocalFile = globalThis.File & {
    uri?: string
  }

  const addFile = React.useCallback(
    (
      filePayload: {
        file: LocalFile
        formData?: FormData
        currentUserId: number
        todoId?: string
        boardRoomId?: string
        projectId?: number
      },
      onPercent?: (percent: number) => void,
    ) => {
      const { file, formData, currentUserId, todoId, boardRoomId, projectId } = filePayload

      console.log('addFile', filePayload)

      let fileFormData

      const extension = file.name.split('.').pop()
      const payload = {
        originalName: file.name,
        extension: extension || '',
        mimeType: file.type,
        byteSize: String(file.size),
        userId: currentUserId,
        projectId,
        boardRoomId,
        isDeleted: false,
      }

      if (formData === undefined) {
        fileFormData = new FormData()
        fileFormData.append('file', file)
      } else {
        fileFormData = formData
      }

      return astro
        .createFile({
          ...payload,
          fileFormData,
          relatedDomain: 'todo',
          relatedDomainId: todoId || '',
          onPercent,
        })
        .then((file) => {
          return file.path
            ? astro.readFilePresignedUrl(file.path).then((url) => {
                setRemotePathList((prev) => [
                  ...prev,
                  {
                    id: file.id,
                    url,
                  },
                ])

                return file
              })
            : file
        })
        .then((file) => {
          if (todoId) {
            return astro
              .readTodo(todoId)
              .then((res) => astro.updateTodo({ ...res, id: todoId }))
              .then(() => file)
          }
          return file
        })
      // .catch((e) => console.error('createFile', e))

      // const url = file.uri ? file.uri : Platform.OS !== 'android' ? URL.createObjectURL(file) : ''
      // setRemotePathList((prev) => [...prev, { id: key, url: url }])
      // return {
      //   id: key,
      //   createdAt: new Date(),
      //   updatedAt: new Date(),
      //   ...payload,
      //   // localPath: url,
      //   // localFile: file,
      // }
    },
    [],
  )

  React.useEffect(() => {
    if (fileLoad) {
      console.log('fileLoad-effect', {
        fileLoad,
        currentUser,
        boardRoomId,
        projectId,
      })

      const { file, todoId, fileFormData } = fileLoad
      addFile({
        file,
        formData: fileFormData,
        currentUserId: Number(currentUser.id),
        todoId,
        boardRoomId,
        projectId,
      }).then((newFile) => {
        setTodoList((prev) => {
          if (!prev) return []
          const index = prev.findIndex((item) => item.id === fileLoad.todoId)
          if (index === -1) return prev
          const item = prev[index]
          item.files = [newFile, ...(item.files || [])]

          return replaceItemAtIndex(prev, index, item)
        })
        clearFileLoad?.()
      })
    }
  }, [clearFileLoad, fileLoad, currentUser, boardRoomId, projectId])

  const list: TodoItemType[] = React.useMemo(() => {
    if (todoList === undefined) return []
    console.log('todoList', todoList)
    return todoList.map((todo) => {
      return {
        id: todo.id,
        contents: todo.content || '',
        period: getPeriod(todo),
        periodColor: getPeriodColor(todo),
        assignment:
          todo.assignees && todo.assignees.length > 0
            ? todo.assignees.map((assignee) => ({
                id: assignee.id,
                name: getCompleteUserName(assignee.userId),
                userProfile: `${astro.option.secureCdnUrl || ''}/profile/${
                  assignee.userEmail ?? getUserEmail(assignee.userId)
                }`,
              }))
            : undefined,
        entryDisabled: getEntryDisabled(todo),
        file: todo.files
          ? todo.files.map((file) => ({
              access: getFileAccess(todo, file),
              url: remotePathList.find((remotePath) => remotePath.id === file.id)?.url || '',
              type: fileType(file.mimeType, file.extension),
              path: '',
              name: file.originalName || '',
              size: Number(file.byteSize),
              date: t('format.date.LLL', { date: new Date(file.createdAt) }),
              source: 'MISSION',
              uploader: getUserName(file.userId),
              onPressItem: () => {
                console.log('onPressItem', file)
                const fileList: File[] = []
                todoList?.map(($todo) => {
                  $todo.files?.map(($file) => {
                    if (getFileAccess($todo, $file)) fileList.push($file)
                  })
                })
                onPreview?.(
                  fileList
                    .filter(
                      (file) => file.mimeType && (file.mimeType.includes('image') || file.mimeType.includes('pdf')),
                    )
                    .map((file) => {
                      const uri = remotePathList.find((remotePath) => remotePath.id === file.id)?.url || ''
                      return {
                        file,
                        uri,
                      }
                    })
                    .map((item) => ({
                      id: item.file.id,
                      title: item.file.originalName || '',
                      uri: item.uri || '',
                      uploadDate: t('format.date.LLL', {
                        date: new Date(item.file.createdAt),
                      }),
                      uploaderName: getUserName(item.file.userId),
                      uploaderProfile: `${astro.option.secureCdnUrl || ''}/profile/${getUserEmail(item.file.userId)}`,
                      mimeType: item.file.mimeType || '',
                    })),
                  file,
                )
              },
              onPressDownload: () => fileDownload(file),
              onPressFileOpen: () => onPressFileOpen && onPressFileOpen(file),
              isExists: () => (isExists !== undefined ? isExists(file) : Promise.resolve(false)),
              onPressEtc: getFileAccess(todo, file)
                ? (payload: LayoutPayload) => {
                    console.log('onPressEtc', payload)
                    if (!isPermission) return
                    setDropdown({
                      layoutPayload: payload,
                      selectedTodoId: todo.id || '',
                      selectedFileKey: file.id,
                      dropdownList: [
                        {
                          group: [
                            {
                              id: 1,
                              title: t('cardtodoedit.dropdown.delete'),
                              code: 'delete',
                            },
                          ],
                        },
                      ],
                    })
                  }
                : undefined,
            }))
          : [],
        checked: todo.phaseInfo ? todo.phaseInfo.phase === 'COMPLETED' : false,
        complete: {
          value: todo.phaseInfo ? !!todo.phaseInfo.userId : false,
          userName: todo.phaseInfo ? getCompleteUserName(todo.phaseInfo.userId) : '',
          userProfile: todo.phaseInfo
            ? `${astro.option.secureCdnUrl || ''}/profile/${getUserEmail(todo.phaseInfo.userId)}`
            : undefined,
        },
        onChecked: () => {
          if (todo.id) {
            astro
              .updateTodoPhase(todo.id, {
                phase: todo.phaseInfo?.phase === 'COMPLETED' ? 'NONE' : 'COMPLETED',
                userId: String(currentUser.id),
              })
              .then((todo) => {
                setTodoList((prev) => {
                  if (prev === undefined) return [todo]
                  const idx = prev?.findIndex(({ id }) => id === todo.id)
                  return idx < 0 ? [...prev, todo] : replaceItemAtIndex(prev, idx, todo)
                })
              })
          }
        },
        onPressFile: () => {
          onFileLoad && onFileLoad(todo.id || '')
        },
        onLoadFile: (files: FileList, onPercent?: (percent: number) => void) => {
          const isFileSizeOver = Array.from(files).some((file) => file.size > 1024 * 1024 * 50)
          // console.log('onLoadFile', files)
          for (let i = 0; i < files.length; i++) {
            const $file = files.item(i)
            if ($file) {
              if ($file.size < 1024 * 1024 * 50) {
                addFile(
                  {
                    file: $file,
                    currentUserId: Number(currentUser.id),
                    todoId: todo.id,
                    boardRoomId,
                    projectId,
                  },
                  onPercent,
                ).then((file) => {
                  setTodoList((prev) => {
                    if (!prev) return []
                    const index = prev.findIndex((item) => item.id === todo.id)
                    if (index === -1) return prev
                    const item = prev[index]

                    item.files = [file, ...(item.files || [])]
                    return replaceItemAtIndex(prev, index, item)
                  })
                })
              }
            }
          }
          if (isFileSizeOver) {
            showToastMessage({
              type: 'Danger',
              title: t('toast.file.size.over'),
              position: 'TOP_CENTER',
            })
          }
          return Promise.resolve()
        },
        onDropFile: (files: FileList, onPercent?: (percent: number) => void) => {
          const isFileSizeOver = Array.from(files).some((file) => file.size > 1024 * 1024 * 50)
          // console.log('onDropFile', files)
          for (let i = 0; i < files.length; i++) {
            const $file = files.item(i)
            if ($file) {
              if ($file.size < 1024 * 1024 * 50) {
                addFile(
                  {
                    file: $file,
                    currentUserId: Number(currentUser.id),
                    todoId: todo.id,
                    boardRoomId,
                    projectId,
                  },
                  onPercent,
                ).then((file) => {
                  setTodoList((prev) => {
                    if (!prev) return []
                    const index = prev.findIndex((item) => item.id === todo.id)
                    if (index === -1) return prev
                    const item = prev[index]
                    item.files = [file, ...(item.files || [])]
                    return replaceItemAtIndex(prev, index, item)
                  })
                })
              }
            }
          }
          if (isFileSizeOver) {
            showToastMessage({
              type: 'Danger',
              title: t('toast.file.size.over'),
              position: 'TOP_CENTER',
            })
          }
          return Promise.resolve()
        },
        optionVisible: getOptionVisible(todo),
        fileUpload: getFileVisible(todo),
      }
    })
  }, [
    getPeriod,
    getCompleteUserName,
    getUserName,
    getUserEmail,
    getFileAccess,
    getPeriodColor,
    fileDownload,
    getEntryDisabled,
    getOptionVisible,
    getFileVisible,
    addFile,
    isPermission,
    todoList,
    astro,
    remotePathList,
    fileType,
    boardRoomId,
    projectId,
    currentUser,
    onFileLoad,
    onPreview,
    showToastMessage,
    t,
  ])

  // 할일 완료 여부 변경
  subscribe(
    relatedDomain && relatedDomainId ? `/subscribe/v2/${relatedDomain}/${relatedDomainId}/todo/updated` : '',
    ({ body }: any) => {
      console.log('Todo updated subscribe', body)
      const jsonBody = JSON.parse(body)
      // getList()
      const todoId = jsonBody.id
      void astro
        .readTodo(todoId)
        .then((todo) => {
          fetchExtra(todo).then((todo) => {
            const { phaseInfo } = todo
            setTodoList((prev) => {
              if (!prev) return []
              const index = prev.findIndex((item) => item.id === todoId)
              // if (index === -1) return prev
              return index < 0 ? prev : replaceItemAtIndex(prev, index, todo)
            })
            if (phaseInfo && phaseInfo.userId) {
              void astro.readUser(String(phaseInfo.userId)).then((user) => {
                setUsers((prev) => {
                  const index = prev.findIndex((user) => user.id === String(phaseInfo.userId))
                  if (index > -1) {
                    return prev
                  } else {
                    return [...prev, { id: String(phaseInfo.userId), user }]
                  }
                })
              })
            }
          })
        })
        .catch((err) => {
          console.error('readTodo', err)
        })
    },
  )

  // 할일 완료 여부 변경
  subscribe(
    relatedDomain && relatedDomainId ? `/subscribe/v2/${relatedDomain}/${relatedDomainId}/todo/deleted` : '',
    ({ body }: any) => {
      console.log('Todo deleted subscribe', body)
      const jsonBody = JSON.parse(body)
      // getList()
      const todoId = jsonBody.id

      setTodoList((prev) => {
        if (prev === undefined) return prev
        const idx = prev.findIndex(({ id }) => id === todoId)
        return idx < 0 ? prev : removeItemAtIndex(prev, idx)
      })
    },
  )

  // 할일 완료 여부 변경
  subscribe(
    todoList?.map(({ relatedDomain, id }) =>
      relatedDomain && id ? `/subscribe/v2/${relatedDomain}/${id}/todo/updated` : '',
    ) ?? '',
    ({ body }: any) => {
      console.log('subtodo update subscribe', body)
      // getList()
      // const todoId = body as string

      // setTodoList((prev) => {
      //   if (prev === undefined) return prev
      //   const idx = prev.findIndex(({ id }) => id === todoId)
      //   return idx < 0 ? prev : removeItemAtIndex(prev, idx)
      // })
    },
  )

  if (todoList === undefined) return <></>

  return (
    <Context.Provider value={{ list }}>
      {children}
      {dropdown ? (
        <DropdownListGroupV2
          code={''}
          isOpen={true}
          layoutPayload={dropdown.layoutPayload}
          dropdownList={dropdown.dropdownList}
          onCode={(code) => {
            // setDropDowncode(code)
            // const keyList = [...dropdown.selectedKeyList]
            if (code === 'delete') {
              const addItem = addFileList.find((add) => add.fileKey === dropdown.selectedFileKey)
              if (addItem) {
                void astro.deleteFile(addItem.fileId)
                setTodoList((prev) => {
                  if (!prev) return []
                  const index = prev.findIndex((item) => item.id === dropdown.selectedTodoId)
                  if (index === -1) return prev
                  const item = prev[index]
                  item.files = item.files?.filter((item) => item.id !== addItem.fileKey)
                  return replaceItemAtIndex(prev, index, item)
                })
              } else {
                void astro.deleteFile(dropdown.selectedFileKey)
                setTodoList((prev) => {
                  if (!prev) return []
                  const index = prev.findIndex((item) => item.id === dropdown.selectedTodoId)
                  if (index === -1) return prev
                  const item = prev[index]
                  item.files = item.files?.filter((item) => item.id !== dropdown.selectedFileKey)
                  return replaceItemAtIndex(prev, index, item)
                })
              }
            }
            setDropdown(undefined)
          }}
          onDismiss={() => {
            setDropdown(undefined)
          }}
        />
      ) : (
        <></>
      )}
    </Context.Provider>
  )
}

export default Provider
