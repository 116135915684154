import type { IUserType } from '@rocket-mono/libs'
import * as React from 'react'
import { DiscussionContext } from './discussionContext'

interface Props {
  user: IUserType | undefined
  children: JSX.Element
}

export const DiscussionProvider: React.FC<Props> = ({ user, children }) => {
  const [, setCurrentUser] = React.useState<IUserType>()

  React.useEffect(() => {
    setCurrentUser(() => user)
  }, [user])

  return <DiscussionContext.Provider value={{ user }}>{children}</DiscussionContext.Provider>
}
