import { Platform } from 'react-native'
import type { IColors, ColorDepth1, ColorDepth2 } from '../types'
import { COLOR } from '../styles/theme.style'

export function seperateColorDepth(color: IColors): [ColorDepth1, ColorDepth2] {
  const depth = color.split('.') as [ColorDepth1, ColorDepth2]
  return depth
}

export function getHexToRgba(hex: string, opacity: number) {
  if (hex.length === 7) {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  } else if (hex.length === 4) {
    const r = parseInt(hex.slice(1, 2), 16)
    const g = parseInt(hex.slice(2, 3), 16)
    const b = parseInt(hex.slice(3, 4), 16)
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  } else {
    return 'rgba(0, 0, 0, 0)'
  }
}

export function getRealColor(color: IColors): string {
  if (color === 'transparent' || color === null || color === undefined) {
    return color
  }
  const [depth1, depth2] = seperateColorDepth(color)
  return COLOR[depth1][depth2]
}

//https://ethercreative.github.io/react-native-shadow-generator/
export function getBoxShadow(
  r: number,
  g: number,
  b: number,
  opacity: number,
  radius: number,
  offsetWidth: number,
  offsetHeight: number,
  elevation: number,
) {
  return Platform.select({
    ios: {
      shadowColor: `rgb(${r},${g},${b})`,
      shadowOpacity: opacity,
      shadowRadius: radius,
      shadowOffset: {
        height: offsetHeight,
        width: offsetWidth,
      },
    },
    android: {
      elevation,
    },
    web: {
      boxShadow: `${offsetWidth}px ${offsetHeight}px ${radius}px  rgba(${r},${g},${b}, ${opacity})`,
    },
  })
}
