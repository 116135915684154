import React from 'react'
import { useRule } from '../../hooks'
import Context from './context'
import { ProviderProps } from './types'

const Provider: React.FC<ProviderProps> = ({ children }) => {
  const { onRuleListChange } = useRule()

  return (
    <Context.Provider
      value={{
        onRuleListChange,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
