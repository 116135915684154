/* [reference] https://github.com/watadarkstar/react-native-typing-animation */

import * as React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import Dot from './Dot'

interface Props {
  dotAmplitude?: number
  dotSpeed?: number
  dotY?: number
  dotX: number
  show: boolean
  dotColor: string
  dotMargin: number
  dotRadius: number
  dotStyles?: StyleProp<ViewStyle>
  style?: StyleProp<ViewStyle>
}

interface State {
  currentAnimationTime: number
  y1: number
  y2: number
  y3: number
}

class TypingAnimation extends React.Component<Props, State> {
  _animation: () => void
  frameAnimationRequest: number

  constructor(props: Props) {
    super(props)

    const { dotAmplitude = 3, dotSpeed = 0.15, dotY = 6 } = props

    this.state = {
      y1: 0,
      y2: 0,
      y3: 0,
      currentAnimationTime: 0,
    }

    this._animation = () => {
      this.setState((prevState) => ({
        y1: dotY + dotAmplitude * Math.sin(prevState.currentAnimationTime),
        y2: dotY + dotAmplitude * Math.sin(prevState.currentAnimationTime - 1),
        y3: dotY + dotAmplitude * Math.sin(prevState.currentAnimationTime - 2),
        currentAnimationTime: prevState.currentAnimationTime + dotSpeed,
      }))
      this.frameAnimationRequest = requestAnimationFrame(this._animation)
    }
    this.frameAnimationRequest = requestAnimationFrame(this._animation)
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.frameAnimationRequest)
  }

  render() {
    const { dotStyles, style, show, dotColor, dotMargin, dotRadius, dotX } =
      this.props
    if (!show) return null
    return (
      <View style={style}>
        <Dot
          x={dotX - dotRadius - dotMargin}
          y={this.state.y1}
          radius={dotRadius}
          style={dotStyles}
          dotColor={dotColor}
        />
        <Dot
          x={dotX}
          y={this.state.y2}
          radius={dotRadius}
          style={dotStyles}
          dotColor={dotColor}
        />
        <Dot
          x={dotX + dotRadius + dotMargin}
          y={this.state.y3}
          radius={dotRadius}
          style={dotStyles}
          dotColor={dotColor}
        />
      </View>
    )
  }
}

export default TypingAnimation
