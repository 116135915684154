import React, { useCallback } from 'react'
import { Image, StyleSheet, View } from 'react-native'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button, Text } from '@rui/atoms'
import { COLOR } from '@rui/foundations'

const Error403: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handlePressBack = useCallback(() => {
    navigate(-1)
  }, [])
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLOR.gray.g050,
        width: '100vw',
        height: '100vh',
      }}
    >
      <View style={styles.container}>
        <View style={{ marginTop: -100 }}>
          <Image
            source={{ uri: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/error/code=403.svg' }}
            style={styles.image}
          />
        </View>
        <View>
          <Text fontName="H3Bold" fontColor="gray.g800">
            Oopss!
          </Text>
        </View>
        <View style={{ marginTop: 8 }}>
          <Text fontName="H7Medium" fontColor="gray.g900">
            {t('fileitem.notpermissionfile')}
          </Text>
        </View>
        <Button style={{ marginTop: 30 }} size="lg" type="PRIMARY" text={t(`setting.back`)} onPress={handlePressBack} />
      </View>
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  image: {
    width: 371,
    height: 243,
  },
})

export default Error403
