import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FlatList, ScrollView, View } from 'react-native'

import {
  ActivitySearchList,
  ActivitySearchSectionSortWork,
  ActivitySearchItemWork,
  ActivitySearchItemWorkProps,
} from '@rocket-molecules/activity'

import SearchIcon from '../../../components/SearchIcon'

import { ResultViewProps } from './types'
import { SearchResultSkeleton } from './skeleton'
import { useTranslation } from 'react-i18next'
import { useSearchScreen } from '../../provider'
import { IndexedProjectType } from '../../types'

interface Props extends ResultViewProps {
  list?: IndexedProjectType[]
}

export const SearchResultWorkView: React.FC<Props> = ({ defaultCount, searchState, isFilter = false, list = [] }) => {
  const { t } = useTranslation()
  const { onSearchNext, onChangeTab, openProject, onChangeSearchCount } = useSearchScreen()

  const convert = useCallback(
    (value: IndexedProjectType): ActivitySearchItemWorkProps => {
      return {
        work: { type: value.typeCode, name: value.title },
        archive: value.isArchived,
        onPress: () => {
          console.log('convertWork:onPress', value)
          const payload = { projectId: String(value.id) }
          openProject(payload)
        },
      }
    },
    [openProject],
  )

  const [showCount, setShowCount] = useState<number | undefined>(defaultCount)
  const [searchArchive, setSearchArchive] = useState(false)

  const filteredList = useMemo(() => {
    return list.map(convert).filter(({ archive }) => (searchArchive === true ? true : archive !== true))
  }, [list, searchArchive, showCount, convert])

  useEffect(() => {
    console.log('filteredList-work', filteredList)
    onChangeSearchCount('work', filteredList.length)
  }, [onChangeSearchCount, filteredList.length])

  const isMore = useMemo(() => {
    if (showCount === undefined) return false
    return (filteredList?.length ?? 0) > showCount
  }, [showCount, filteredList])

  const handlePressMore = useCallback(() => {
    onChangeTab('PROJECT')
  }, [onChangeTab])

  useEffect(() => {
    if (isFilter === false) {
      setSearchArchive(false)
    }
  }, [isFilter])

  const [page, setPage] = useState(0)
  useEffect(() => {
    if (page > 0) {
      onSearchNext('PROJECT', page)
    }
  }, [page, onSearchNext])

  return (
    <ActivitySearchList type="WORK" onPressMore={isMore ? handlePressMore : undefined}>
      {isFilter && (
        <View
        // style={['done', 'last'].includes(searchState) && isFilter && { height: 100 }}
        >
          <ActivitySearchSectionSortWork
            isSwitchOn={searchArchive}
            onChangeSwitchOn={setSearchArchive}
            defaultIndex={0}
          />
        </View>
      )}

      <FlatList
        scrollEnabled={isFilter}
        contentContainerStyle={[!isFilter && { flex: 1 }, isFilter && { paddingBottom: 24 }]}
        data={filteredList.slice(0, showCount)}
        renderItem={({ item }) => {
          return <ActivitySearchItemWork {...item} />
        }}
        onEndReached={() => {
          if (isFilter && searchState === 'done') setPage((prev) => prev + 1)
        }}
        onEndReachedThreshold={1}
        ListEmptyComponent={
          <>
            {searchState === 'ready' && (
              <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <SearchIcon type={'searching'} textSize={'medium'} />
              </View>
            )}
            {['done', 'last'].includes(searchState) && isFilter && (
              <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <SearchIcon type="work" text={t('nocontentsicon.search.text')} />
              </View>
            )}
          </>
        }
        ListFooterComponent={
          <>
            {searchState === 'searching' && (
              <SearchResultSkeleton filteredList={filteredList} defaultCount={defaultCount} searchState={searchState}>
                <ActivitySearchItemWork />
              </SearchResultSkeleton>
            )}
          </>
        }
      />
    </ActivitySearchList>
  )
}
