import React from 'react'
import { StyleSheet, View } from 'react-native'

import { SkeletonBar, SkeletonSquare } from '@rocket/skeleton'

import { COLOR, FONT } from '@rui/foundations'
import { Text } from '@rocket-atoms/text'

import OptionCountLabel from '../../OptionCountLabel'
import WorkLabel from '../../WorkLabel'
import Container from '../container'
import { ItemBoardType, ItemWorkType } from '../types'
import { useTranslation } from 'react-i18next'

export interface ActivitySearchItemChatProps {
  work?: ItemWorkType
  board?: ItemBoardType
  archive?: boolean
  onPress?: () => void
}

const ActivitySearchItemChat: React.FC<ActivitySearchItemChatProps> = ({ work, board, archive, onPress }) => {
  const { t } = useTranslation()
  if (board === undefined) {
    return (
      <View style={styles.container}>
        <View style={styles.labelArea}>
          <SkeletonSquare width={60} height={20} color={'gray.g200'} />
        </View>
        <View style={styles.flexLine}>
          <SkeletonBar width={80} height={16} />
          <SkeletonBar width={30} height={16} />
        </View>
      </View>
    )
  }

  return (
    <Container onPress={onPress} style={[styles.container]}>
      <View style={styles.labelArea}>{work && <WorkLabel type={work.type} label={work.name} />}</View>

      <View style={[styles.flexLine]}>
        <View style={{ flex: 1, flexDirection: 'row' }}>
          {archive && (
            <View style={styles.block}>
              <Text style={styles.blockText}>{t('workinvite.memberoption.board.archive')}</Text>
            </View>
          )}
          <Text style={styles.labelStyle} numberOfLines={1} ellipsizeMode="tail">
            {board.name}
          </Text>
        </View>
        {board.count !== undefined && board.count > 0 && <OptionCountLabel count={board.count} />}
      </View>
    </Container>
  )
}

export default ActivitySearchItemChat

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    paddingVertical: 8,
    paddingHorizontal: 20,
  },
  labelStyle: {
    flex: 1,
    ...FONT.txtSmMedium,
    color: COLOR.gray.g900,
  },
  labelArea: {
    alignSelf: 'flex-start',
    marginBottom: 4,
  },
  flexLine: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  block: {
    padding: 4,
    backgroundColor: COLOR.gray.g050,
    borderRadius: 4,
    marginRight: 4,
  },
  blockText: {
    ...FONT.txt2Xs,
    color: COLOR.gray.g600,
  },
})
