import React, { useCallback, useEffect, useState } from 'react'

import { useAstro, useWorkCard } from '@rocket-mono/providers'
import { useTranslation } from 'react-i18next'
import { Platform, View } from 'react-native'
import { CardRowGroup } from '../../components/CardRowGroup'
import { AddressType, PlaceSearch } from '../../components/PlaceSearch'
import type { EditProps } from './types'

const ScreenView: React.FC<EditProps> = ({ location, setLocation }) => {
  const { t } = useTranslation()
  const { astro } = useAstro()
  const { currentCard } = useWorkCard()
  const [init, setInit] = useState(false)

  useEffect(() => {
    if (currentCard) {
      const { location, locationEtc, locationX, locationY } = currentCard
      if (location !== null && locationEtc !== null) setLocation({ location, locationEtc, locationX, locationY })
    }
    setInit(true)
  }, [currentCard])

  const onChangeAddress = useCallback((value?: AddressType) => {
    if (value) {
      const { address: location, addressEtc: locationEtc, x: locationX, y: locationY } = value
      setLocation({ location, locationEtc, locationX, locationY })
    } else setLocation(undefined)
  }, [])

  const onPlaceSearch = useCallback(async (search: string) => {
    const list1 = await astro.googleSearchPlace(search)
    const list2 = await astro.googleSearchAddress(search)

    return [...list1, ...list2].map((o, index) => ({
      ...o,
      id: String(index),
      title: o.title.replace(/<[^>]*>?/g, ''),
      addressEtc: '',
    }))
  }, [])
  if (!init) return null

  return (
    <CardRowGroup
      title={t(`card.meeting.place`)}
      iconName="map-o"
      iconContainerStyle={{
        alignItems: 'stretch',
      }}
    >
      {Platform.OS === 'web' ? (
        <div style={{ width: '100%' }}>
          <PlaceSearch
            location={location}
            onSearch={onPlaceSearch}
            onChangeAddress={onChangeAddress}
            modalHeight={640}
          />
        </div>
      ) : (
        <View style={{ width: '100%' }}>
          <PlaceSearch
            location={location}
            onSearch={onPlaceSearch}
            onChangeAddress={onChangeAddress}
            modalHeight={640}
          />
        </View>
      )}
    </CardRowGroup>
  )
}

export default ScreenView
