import { createContext } from 'react'

import type { CardLinkContextType } from './types.web'

import { CARD_LINK_TEMPLATE } from '../utils/message'

export const CardLinkContext = createContext<CardLinkContextType>({
  input: '',
  state: 'idle',
  cardLinkList: [],
  matched: [],
  cardLinkListOpen: false,
  cursorPosition: null,
  getKeystroke: () => {
    // no default action
  },
  getCompositionStroke: () => {
    // no default action
  },
  getBackspaceStroke: () => {
    // no default action
  },
  startCardLink: () => {
    // no default action
  },
  iterateCardLinkList: () => {
    // no default action
  },
  closeCardLink: () => {
    // no default action
  },
  startMatching: () => {
    // no default action
  },
  pickCardLinkItem: () => {
    // no default action
  },
  doneMatching: () => {
    // no default action
  },
  finishCardLink: () => {
    // no default action
  },
  forwardKeyEvent: () => {
    // no default action
  },
  forwardFormEvent: () => {
    // no default action
  },
  forwardCompositionEvent: () => {
    // no default action
  },
  forwardBlurEvent: () => {
    // no default action
  },
  query: () => {
    // no default action
  },
  CARD_LINK_TEMPLATE,
})
