import { Icon } from '@rocket-atoms/icon'
import { Text } from '@rocket-atoms/text'
import { COLOR, IconName } from '@rocket-mono/libs'
import * as React from 'react'
import { Pressable, StyleSheet } from 'react-native'

interface Props {
  iconName: IconName
  label: string
  onPress: () => void
}

const OptionButton = ({ iconName, label, onPress }: Props) => {
  const [isHover, setIsHover] = React.useState(false)

  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      onPress={onPress}
      style={[styles.button, isHover && { backgroundColor: COLOR.gray.g050 }]}
    >
      <Icon name={iconName} size={12} color={'gray.g600'} style={{ marginRight: 2 }} />
      <Text fontName="txtXsMedium" fontColor="gray.g800">
        {label}
      </Text>
    </Pressable>
  )
}

export default OptionButton

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 20,
    padding: 2,
    marginRight: 8,
    borderRadius: 4,
  },
})
