import React, { useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'

import { useAstro } from '@rocket-mono/providers'
import { CardSearchCardItemType, CardSearchView } from '@rocket-screens/card'
import { Card, CardType } from '@rocket/types'
import { WorkSearchView } from './search-work-view'

export type BoardItemType = { projectId: string; boardId: string; boardTitle: string; closed: boolean }
export type CardItemType = { boardId: string; cardId: string; cardTitle: string; cardType: CardType; closed: boolean }

export interface Props {
  maxWidth: number
  layout?: { top: number; left: number }
  projectId: string
  boardId: string
  onPressItem: (card: CardSearchCardItemType) => void
  onPressClose?: () => void
}

export const SearchView: React.FC<Props> = ({
  maxWidth,
  layout: initialLayout,
  projectId,
  boardId,
  onPressItem,
  onPressClose,
}) => {
  const { astro } = useAstro()

  const [visibleWork, setVisibleWork] = useState(false)
  const [layout, setLayout] = useState<{ top: number; left: number } | undefined>(initialLayout)
  const [searchKeyword, setKeywork] = useState('')
  const [boardList, setBoardList] = useState<BoardItemType[]>()
  const [cardList, setCardList] = useState<CardItemType[]>()

  const list = useMemo(() => {
    if (boardList && cardList) {
      return boardList.map((o) => ({
        ...o,
        data: cardList
          .filter(({ cardTitle }) => cardTitle.indexOf(searchKeyword) >= 0)
          .filter(({ boardId }) => o.boardId === boardId),
      }))
    }

    return []
  }, [searchKeyword, boardList, cardList])

  useEffect(() => {
    const selection = window.getSelection()
    if (selection && selection.focusNode && initialLayout === undefined) {
      const el1 = selection.focusNode as HTMLElement
      const el2 = selection.focusNode.parentElement
      console.log('selection', { selection, el1, el2 }, el1.offsetTop, el2?.offsetTop, el2?.offsetHeight)

      const top =
        el1 && el1.offsetTop ? el1.offsetTop + el1.offsetHeight + 45 : el2 ? el2.offsetTop + el2.offsetHeight + 45 : 0

      setLayout({ top, left: 30 })
    }
    return () => {
      setLayout(undefined)
    }
  }, [])

  useEffect(() => {
    if (boardList) {
      Promise.all(boardList.map(({ boardId }) => astro.readCardList(boardId)))
        .then((res) => {
          console.log('res', boardList, res.flat())
          return res.flat()
        })
        .then((list: Card[]) =>
          list.map(({ cardId, channelId: boardId, cardType, cardTitle, cardIsDone }) => ({
            boardId,
            cardId,
            cardTitle,
            cardType,
            closed: cardIsDone !== undefined ? cardIsDone : false,
          })),
        )
        .then(setCardList)
    }
  }, [boardList])

  useEffect(() => {
    astro
      .readChannelList({ type: 'G', projectId })
      .then((o) => {
        console.log('readChannelList', o)
        return o.map(({ id: boardId, roomName: boardTitle, closed }) => ({ projectId, boardId, boardTitle, closed }))
      })
      .then(setBoardList)
  }, [projectId])

  if (layout === undefined || boardList === undefined || cardList === undefined) return <></>

  return (
    <>
      <View style={{ position: 'absolute', zIndex: 999, left: layout.left, top: layout.top, width: maxWidth }}>
        <CardSearchView
          keyword={searchKeyword}
          onChangeKeyword={setKeywork}
          currentBoardId={boardId}
          list={list}
          onPressItem={onPressItem}
          onPressWork={() => {
            setVisibleWork(true)
          }}
          onPressClose={onPressClose}
        />
      </View>
      {visibleWork && (
        <WorkSearchView
          boardId={boardId}
          projectId={projectId}
          onPressItem={onPressItem}
          onDissmiss={() => setVisibleWork(false)}
        />
      )}
    </>
  )
}
