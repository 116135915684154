import React from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'
import {
  SearchIconAll,
  SearchIconCard,
  SearchIconChat,
  SearchIconFile,
  SearchIconMessage,
  SearchIconSearching,
  SearchIconUser,
  SearchIconWork,
} from './icons'
import { COLOR, FONT } from '@rui/foundations'

interface Props {
  type: 'searching' | 'all' | 'work' | 'chat' | 'message' | 'card' | 'file' | 'user'
  textSize?: 'normal' | 'medium'
  text?: string
  subText?: string
}
const SearchIcon: React.FC<Props> = ({ type, textSize = 'normal', text, subText }) => {
  return (
    <View style={styles.container}>
      <View style={{ marginBottom: 4 }}>
        {type === 'searching' && <SearchIconSearching />}
        {type === 'all' && <SearchIconAll />}
        {type === 'work' && <SearchIconWork />}
        {type === 'chat' && <SearchIconChat />}
        {type === 'message' && <SearchIconMessage />}
        {type === 'card' && <SearchIconCard />}
        {type === 'file' && <SearchIconFile />}
        {type === 'user' && <SearchIconUser />}
      </View>

      <Text
        style={[
          styles.basicStyle,
          textSize === 'normal' && { ...FONT.txtSmMedium },
          textSize === 'medium' && { ...FONT.txtMdMedium },
          textSize === 'normal' && { color: COLOR.gray.g450 },
          textSize === 'medium' && { color: COLOR.gray.g700 },
        ]}
      >
        {text}
      </Text>
      <Text
        style={[
          styles.basicStyle,
          textSize === 'normal' && { ...FONT.txtXs },
          textSize === 'medium' && { ...FONT.txtSm },
          textSize === 'normal' && { color: COLOR.gray.g600 },
          textSize === 'medium' && { color: COLOR.gray.g450 },
        ]}
      >
        {subText}
      </Text>
    </View>
  )
}

export default SearchIcon

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  basicStyle: {
    textAlign: 'center',
    marginTop: 0,
    ...Platform.select({
      native: { marginTop: 0 },
    }),
  },
})
