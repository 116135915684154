import * as React from 'react'

import { ClickOutside } from '@rui/foundations'
import { Platform } from 'react-native'
import UserSelectedComponent from '../../UserSelectedComponent'
import { WorkCardTodo } from '../../providers'
import type { ShowModal } from '../types'

const CardTodoAssigneeModal = ({
  astro,
  layoutPayload,
  type,
  todo: modalTodo,
  users: modalUsers,
  relatedDomain,
  relatedDomainId,
  onClose,
  onSave,
}: ShowModal) => {
  const [todo, setTodo] = React.useState<WorkCardTodo>(modalTodo)
  const users = React.useMemo(() => {
    if (!modalUsers) return []
    return modalUsers.map((user) => ({
      id: user.userId,
      name: user.name,
      selected: todo.assignees
        ? todo.assignees.filter((assignee) => String(assignee.userId) === user.userId).length > 0
        : false,
      uri: `${astro.option.secureCdnUrl || ''}/profile/${user.email}`,
    }))
  }, [modalUsers, todo, astro])
  return (
    <ClickOutside
      onClickOutside={() => {
        onClose()
        if (Platform.OS === 'web') {
          onSave &&
            onSave({
              ...todo,
              isAssigneeCheck: todo.assignees ? todo.assignees.length > 0 : false,
            })
        }
      }}
    >
      <UserSelectedComponent
        layoutPayload={layoutPayload}
        listMember={users} // users
        visible={type === 'assignee'}
        onClose={() => {
          onClose()
          if (Platform.OS === 'web') {
            onSave &&
              onSave({
                ...todo,
                isAssigneeCheck: todo.assignees ? todo.assignees.length > 0 : false,
              })
          }
        }}
        onSave={() =>
          onSave &&
          onSave({
            ...todo,
            isAssigneeCheck: todo.assignees ? todo.assignees.length > 0 : false,
          })
        }
        onChangeUsers={(users) => {
          // setUsers(users)
          // console.log('users', users)
          if (!modalUsers) return
          const currentSelected = todo.assignees ? todo.assignees.filter((assignee) => assignee.id) : []
          const selected = users.filter((user) => user.selected).map((user) => user.id)

          const deleteUsers = currentSelected.filter((assignee) => !selected.includes(String(assignee.userId)))

          if (deleteUsers.length > 0) {
            void astro.deleteAssignees(deleteUsers.map((user) => ({ ...user, id: user.id || '' })))
          }

          const assignees = modalUsers
            .filter((user) => selected.includes(user.userId))
            .map((user) => {
              const is = todo.assignees
                ? todo.assignees.filter((assignee) => String(assignee.userId) === user.userId)
                : undefined
              return {
                id: is && is.length > 0 ? is[0].id : undefined,
                userId: Number(user.userId),
                name: user.name,
                userEmail: user.email,
                relatedDomain,
                relatedDomainId,
              }
            })
          setTodo((prev) => {
            return {
              ...prev,
              assignees,
            }
          })
        }}
      />
    </ClickOutside>
  )
}

export default CardTodoAssigneeModal
