import React, { useState } from 'react'

import { StyleSheet, ViewStyle } from 'react-native'
import { COLOR, useMobileView } from '@rui/foundations'
import { Draggable } from 'react-beautiful-dnd-grid-support'
import WorkCard, { WorkCardType } from '../../components/WorkCard'
import { View } from '@rocket-mono/foundations'

function getStyle(style, snapshot) {
  if (!snapshot.isDropAnimating) {
    return style
  }
  const { moveTo, curve, duration } = snapshot.dropAnimation
  // move to the right spot
  const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`
  // add a bit of turn for fun
  const rotate = 'rotate(-0.01turn)'

  // patching the existing style
  return {
    ...style,
    transform: `${translate} ${rotate}`,
    // slowing down the drop because we can
    transition: `all ${curve} ${duration + 1}s`,
  }
}

interface Props {
  item: WorkCardType
  index: number
  containerStyle?: ViewStyle
  shortcutsWrap?: ViewStyle
  workItemStyle?: ViewStyle
}
const WorkspaceMainCardItem: React.FC<Props> = ({ item, index, containerStyle, shortcutsWrap, workItemStyle }) => {
  const mobileWebView = useMobileView(768)
  const mobileView = useMobileView(500)

  const [isDragable, setIsDragable] = useState(false)

  return (
    <Draggable key={item.key ?? item.id} draggableId={item.key ?? item.id} index={index}>
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            // style={getStyle(provided.draggableProps.style, snapshot)}
          >
            <View
              style={[
                styles.contentsWrap,
                {
                  borderWidth: 1,
                  borderRadius: 8,
                  borderColor: 'transparent',
                },
                !!snapshot.combineTargetFor && {
                  borderColor: COLOR.gray.g800,
                },
                (snapshot.isDragging || isDragable) && {
                  transform: 'rotate(0.01turn)',
                },
                containerStyle,
                shortcutsWrap,
                mobileWebView && { paddingHorizontal: 0 },
              ]}
              // onPress={() => {
              //   console.log('onPress')
              //   // item.onPressCard && item.onPressCard()
              // }}
              // onLongPress={() => {
              //   console.log('longPress')
              //   setIsDragable(true)
              // }}
              // onPressOut={() => setIsDragable(false)}
            >
              <WorkCard index={index} item={item} style={workItemStyle} />
            </View>
          </div>
        )
      }}
    </Draggable>
  )
}

export default WorkspaceMainCardItem

const styles = StyleSheet.create({
  contentsWrap: {},
})
