import React, { createRef, useEffect, useMemo, useRef, useState } from 'react'
import { StyleSheet, View, Text, Pressable, SectionList, Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import { COLOR, FONT } from '@rocket-mono/libs'
import { Combobox } from '@rui/molecules'
import { SearchTextField, Switch } from '@rocket/atoms'
import { FAIcon, NoContentsIcon } from '@rocket-atoms/icon'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import CardSearchCardItem from '../CardSearchCardItem'
import CardSearchBoardTab from '../CardSearchBoardTab'
import { CardBoardType, CardSearchCardItemType } from '../type'

interface Props {
  keyword: string
  onChangeKeyword: (keyword: string) => void
  onPressWork?: () => void
  currentBoardId: string
  list: CardBoardType[]
  onPressItem: (item: CardSearchCardItemType) => void
  onPressClose?: () => void
}

// 이 컴포넌트는 여러곳에 공통으로 쓰입니다..!
const CardSearchView = ({
  keyword,
  onChangeKeyword,
  onPressWork,
  currentBoardId,
  list,
  onPressItem,
  onPressClose,
}: Props) => {
  const { t } = useTranslation()
  const [archiveActived, setArchiveActived] = React.useState(false)

  const filteredList = useMemo(() => {
    const current = list.filter(({ boardId }) => boardId === currentBoardId)
    const opened = list.filter(({ boardId, closed }) => !closed && boardId !== currentBoardId)
    const closed = list.filter(({ boardId, closed }) => closed && boardId !== currentBoardId)
    let index = 0
    const l = [...current, ...opened, ...closed]
      .filter(({ closed }) => {
        if (archiveActived === true) return true

        return closed === false
      })
      .map((o, sectionIndex) => {
        const data = o.data.map((o, itemIndex) => ({ ...o, sectionIndex, itemIndex, index: index++ }))
        return { ...o, data }
      })
      .filter((o) => o.data.length > 0)

    return l
  }, [list, archiveActived])

  const renderSection = React.useCallback(
    ({ section }: { section: CardBoardType }) => {
      return (
        <CardSearchBoardTab
          archive={section.closed}
          boardTitle={section.boardTitle}
          current={currentBoardId === section.boardId ? true : false}
        />
      )
    },
    [filteredList],
  )

  const sectionListRef = useRef<SectionList<CardSearchCardItemType, CardBoardType>>(null)

  const [selectIndex, setSelectIndex] = useState<number>()

  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      console.log('onKeydown', e)
      const itemList = filteredList.flatMap((o) => o.data)
      if (e.key === 'ArrowUp') {
        e.preventDefault()
        setSelectIndex((index) => (index !== undefined && index > 0 ? index - 1 : index))
      }
      if (e.key === 'ArrowDown') {
        e.preventDefault()
        setSelectIndex((index) => {
          if (index === undefined) return 0
          if (itemList.length > index + 1) return index + 1
          return index
        })
      }

      if (e.key === 'Enter') {
        setSelectIndex((index) => {
          if (index !== undefined) {
            e.preventDefault()
            const item = itemList.find((o) => o.index === index)
            if (item) onPressItem(item)
          }

          return undefined
        })
      }

      if (e.key === 'Escape') {
        e.preventDefault()
        onPressClose && onPressClose()
      }
      return false
    }

    if (Platform.OS === 'web') window.addEventListener('keydown', onKeydown, true)

    return () => {
      if (Platform.OS === 'web') window.removeEventListener('keydown', onKeydown, true)
    }
  }, [filteredList, onPressClose])

  useEffect(() => {
    if (selectIndex !== undefined) {
      const item = filteredList.flatMap((o) => o.data).find(({ index }) => index === selectIndex)
      if (item) {
        const { sectionIndex, itemIndex } = item

        console.log('selectIndex', selectIndex, sectionIndex, itemIndex, item)
        sectionListRef.current?.scrollToLocation({ sectionIndex, itemIndex: itemIndex + 1, viewOffset: 50 })
      }
    }
  }, [filteredList, selectIndex])

  return (
    <Combobox visible={true} width={'100%'}>
      <Combobox.Header style={styles.header}>
        <Text style={styles.title}>{t('chatuserlist.cardLink')}</Text>
        <SearchTextField value={keyword} placeholder={t('inputtodo.placeholder')} onChangeText={onChangeKeyword} />
        <View style={styles.sort}>
          <View style={styles.flexbox}>
            <Switch size={'sm'} activated={archiveActived} onChange={setArchiveActived} />
            <Pressable onPress={() => setArchiveActived(!archiveActived)}>
              <Text style={styles.labelText}>{t('search.result.includearchive')}</Text>
            </Pressable>
          </View>
          <Pressable style={styles.flexbox} onPress={onPressWork}>
            <Text style={styles.labelText}>{t('search.result.otherwork')}</Text>
            <FAIcon iconName={faArrowRight} size={'xs'} color={'gray.g300'} />
          </Pressable>
        </View>
      </Combobox.Header>
      <Combobox.Body style={styles.body}>
        <SectionList
          ref={sectionListRef}
          sections={filteredList}
          keyExtractor={(_item, idx) => `card-result-${idx}`}
          onScrollToIndexFailed={(i) => {
            console.log('selectIndex-failed', i)
          }}
          renderItem={({ item, section }) => {
            return (
              <CardSearchCardItem
                {...item}
                archive={section.closed}
                selected={selectIndex !== undefined && item.index === selectIndex}
                onPress={() => {
                  onPressItem(item)
                }}
              />
            )
          }}
          renderSectionHeader={renderSection}
          stickySectionHeadersEnabled
          style={{ height: 208 }}
          ListEmptyComponent={
            <View style={{ marginTop: 50 }}>
              <NoContentsIcon
                type="search"
                textSize={'medium'}
                text={''}
                subText={t('nocontentsicon.search.text')}
                iconWidth={115}
              />
            </View>
          }
        />
      </Combobox.Body>
    </Combobox>
  )
}

export default CardSearchView

const styles = StyleSheet.create({
  flexbox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    paddingTop: 16,
    paddingBottom: 8,
    paddingHorizontal: 12,
    borderBottomWidth: 0,
  },
  body: {
    height: 208,
    borderTopWidth: 1,
    borderColor: COLOR.gray.g150,
  },
  title: {
    ...FONT.txtXs,
    marginBottom: 10,
    color: COLOR.gray.g800,
  },
  sort: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 4,
    marginTop: 8,
  },
  labelText: {
    marginHorizontal: 4,
    ...FONT.txtSmMedium,
    color: COLOR.gray.g800,
  },
})
