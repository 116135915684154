import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { Modal } from '@rocket-mono/foundations'
import { COLOR } from '@rocket-mono/libs'
import { CardGatheringFormType, useWorkChannel } from '@rocket-mono/providers'
import { CardSearchCardItemType } from '@rocket-screens/card'
import { FAIcon } from '@rocket/atoms'
import { TriggerCardLinkType, TriggerType } from '@rui/rich-text-field'
import { CardGatheringCreateView, I18nProvider } from '@rui/screen-card'
import { LayoutChangeEvent, StyleSheet, View } from 'react-native'
import BarButton from '../../../../components/BarButton'
import CardAssigneeView from '../../../../components/CardAssigneeView'
import { SearchView } from '../../../../components/WorkCardEditor/search-view'
import { UpdateGatheringType } from '../../edit'

interface Props {
  updateGathering?: UpdateGatheringType
  setIsDismiss: (is: boolean) => void
  onPressSave?: (form: CardGatheringFormType) => void
  onPressClose?: () => void
}

const CardGatheringCreateModal: React.FC<Props> = ({ updateGathering, setIsDismiss, onPressSave, onPressClose }) => {
  const { t, i18n } = useTranslation()
  const { currentChannel, currentChannelMembers } = useWorkChannel()

  const textEditorRef = useRef(null)

  const [values, setValues] = useState<CardGatheringFormType>(
    updateGathering === undefined
      ? {
          title: '',
          type: 'FILE',
          sequenceNumber: 0,
          targetOption: 'PUBLIC',
          viewPermission: 'WRITER_OR_AUTHORITY',
          assigneeIds: [],
          assigneeList: [],
        }
      : updateGathering.gathering,
  )

  const handleChange = useCallback(
    (name: string, value: unknown) => {
      setValues((values) => ({ ...values, [name]: value }))
    },
    [values],
  )

  const handleSubmit = useCallback(() => {
    console.log('handleSubmit', values)
    onPressSave && onPressSave(values)
    onPressClose && onPressClose()
  }, [values])

  const handlePressClose = useCallback(() => {
    onPressClose && onPressClose()
  }, [])

  const disabled = useMemo(() => {
    console.log('values', values)
    if (values.title === '') return true

    if (values.targetOption === 'SPECIFY' && values.assigneeIds.length === 0) return true

    return false
  }, [values])

  const [visibleSearchCard, setVisibleSearchCard] = React.useState(false)

  const triggerList: TriggerType[] = React.useMemo(() => {
    return [
      {
        key: '#',
        list: [],
        onTrigger: () => {
          console.log('trigger', 'show Cards')
          setVisibleSearchCard(true)
        },
        onDismiss: () => {
          console.log('trigger', 'hide Cards')
          setVisibleSearchCard(false)
        },
      },
    ]
  }, [])

  const [layoutWidth, setLayoutWidth] = React.useState(0)

  const handleLayout = React.useCallback(({ nativeEvent }: LayoutChangeEvent) => {
    setLayoutWidth(nativeEvent.layout.width)
  }, [])

  const handlePressItem = React.useCallback((card: CardSearchCardItemType) => {
    console.log('handlePressItem', card)
    const res: TriggerCardLinkType = {
      type: 'CARD_LINK',
      key: card.cardId,
      cardName: card.cardTitle,
      channelId: card.boardId,
    }
    // @ts-ignore
    textEditorRef.current?.onTrigger(res)
  }, [])

  const closeSearchCard = React.useCallback(() => {
    setVisibleSearchCard(false)

    // @ts-ignore
    textEditorRef.current?.reset()
  }, [])

  useEffect(() => {
    setIsDismiss(values.targetOption === 'PUBLIC' && !visibleSearchCard)
  }, [values.targetOption, visibleSearchCard])

  return (
    <View style={styles.container}>
      <Modal.Header
        textAlign="center"
        onPressClose={handlePressClose}
        closeIcon={<FAIcon iconName={faXmark} size={'lg'} color={'gray.g700'} />}
      >
        {t('card.collectunit.title')}
      </Modal.Header>
      <Modal.Body>
        <View onLayout={handleLayout}>
          <I18nProvider i18n={i18n}>
            <CardGatheringCreateView
              textEditorRef={textEditorRef}
              values={values}
              handleChange={handleChange}
              triggerList={triggerList}
              currentChannelMembers={currentChannelMembers}
              CardAssigneeViewComponent={
                <CardAssigneeView
                  readonly={false}
                  assigneeIds={values.assigneeIds}
                  onChangeAssigneeIds={(ids) => handleChange('assigneeIds', ids)}
                />
              }
            />
          </I18nProvider>
          {visibleSearchCard && currentChannel && (
            <SearchView
              maxWidth={layoutWidth - 30}
              projectId={currentChannel.projectId}
              boardId={currentChannel.id}
              onPressItem={handlePressItem}
              onPressClose={closeSearchCard}
            />
          )}
        </View>
      </Modal.Body>
      <Modal.Footer>
        <BarButton
          disabled={disabled}
          contanerStyle={{
            height: 50,
          }}
          backgroundColor={disabled ? undefined : 'main.blue'}
          title={t('card.collectunit.create')}
          action={() => {
            console.log('action')
            handleSubmit()
          }}
          callToAction
        />
      </Modal.Footer>
    </View>
  )
}

export default CardGatheringCreateModal

const styles = StyleSheet.create({
  container: {
    width: '100%',
    minHeight: 420,
    maxWidth: 600,
    backgroundColor: COLOR.mono.white,
  },
  footer: {
    flex: 1,
  },
})
