import { COLOR, getBoxShadow } from '@rocket-mono/libs'
import React from 'react'
import { Modal, StyleSheet, View } from 'react-native'
import Body from './components/body'
import Header from './components/header'
import { useRule } from './hooks'
import { ViewProps } from './types'

const ScreenView: React.FC<ViewProps> = ({ onPressClose }) => {
  const { isMobile, step, onCreateParameterClear } = useRule()
  const handleClose = React.useCallback(() => {
    if (step === 'VIEW') {
      onPressClose()
    } else {
      onCreateParameterClear()
    }
  }, [step])
  return (
    <Modal transparent>
      {isMobile ? (
        <View style={styles.mobileContainer}>
          <Header onPressClose={handleClose} />
          <Body />
        </View>
      ) : (
        <>
          <View style={styles.backdrop} />
          <View style={styles.webContainer}>
            <Header onPressClose={handleClose} />
            <Body />
          </View>
        </>
      )}
    </Modal>
  )
}

const styles = StyleSheet.create({
  backdrop: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: COLOR.gray.g900,
    opacity: 0.4,
    zIndex: 999,
  },
  webContainer: {
    maxWidth: 980,
    maxHeight: 600,
    width: '100%',
    height: '100%',
    backgroundColor: COLOR.mono.white,
    margin: 'auto',
    borderRadius: 8,
    ...getBoxShadow(0, 0, 0, 0.35, 15, 3, 3, 0),
  },
  mobileContainer: {
    backgroundColor: COLOR.mono.white,
    height: '100%',
  },
})

export default ScreenView
