import { InitOptions } from 'i18next'
import { GridlyBackendOptions } from 'i18next-gridly-backend'
import moment from 'moment'

const gridlyOptions: GridlyBackendOptions = {
  apiKey: 'kv8IkLtIE9YzVe', // Use read-only API key in production env for security reason
  viewId: 'yi4i7vrzsims5',
}

const fallbackLng = 'en'
type StringArrayToUnion<T extends readonly string[]> = T[number]
export const SUPPORTED_LANGS = ['en', 'ko', 'vi', 'mn', 'de', 'es', 'ja'] as const
export type LangType = StringArrayToUnion<typeof SUPPORTED_LANGS>

export function updateMomentLocale(lang: LangType) {
  console.debug('updateMomentLocale', lang)
  const LANGUAGE_FORMAT_MACRO = Object.freeze({
    en: {
      longDateFormat: {
        LT: 'A hh:mm',
        LTS: 'HH:mm:ss',
        L: 'MMM/DD/YYYY',
        LL: 'MMM/DD/YYYY',
        LLL: 'MMM/DD/YYYY HH:mm',
        LLLL: 'DD.MM.YY HH:mm',
      },
    },
    ko: {
      longDateFormat: {
        LT: 'a hh:mm',
        LTS: 'HH:mm:ss',
        L: 'YYYY/MM/DD',
        LL: 'MM/DD/YYYY',
        LLL: 'YYYY/MM/DD HH:mm',
        LLLL: 'YY.MM.DD HH:mm',
      },
      meridiem: (e: number) => {
        if (e > 12) {
          return '오후'
        }
        return '오전'
      },
    },
  } as const)

  moment.updateLocale(lang, LANGUAGE_FORMAT_MACRO[lang === 'ko' ? 'ko' : 'en'])
}

const CUSTOM_FORMAT = {
  shortLL: {
    ko: 'YY.MM.DD',
    en: 'DD.MM.YY',
  },
  shortLLLL: {
    ko: 'MM.DD HH:mm',
    en: 'DD.MM HH:mm',
  },
  MD: {
    ko: 'MM.DD',
    en: 'DD.MM',
  },
}

function isDefinedFormat(format?: string): format is keyof typeof CUSTOM_FORMAT {
  return !!format && Object.keys(CUSTOM_FORMAT).includes(format)
}

function isDefinedLang(lang?: string): lang is LangType {
  return !!lang && ['ko', 'en', 'vi', 'mn', 'de', 'es', 'ja'].includes(lang)
}

function convertCustomFormat(format?: string, lang?: string) {
  const _lang: LangType = isDefinedLang(lang) ? lang : 'en'

  if (format === 'T') {
    return 'HH:mm'
  }

  if (isDefinedFormat(format)) {
    return CUSTOM_FORMAT[format][_lang]
  }

  return format
}

export const options: InitOptions = {
  debug: true,
  fallbackLng,
  saveMissing: false,
  compatibilityJSON: 'v3',
  backend: { ...gridlyOptions, loadPath: '/locales/{{lng}}.json' },
  supportedLngs: SUPPORTED_LANGS,
  interpolation: {
    escapeValue: false,
    format: (value, format, lang) => {
      if (value instanceof Date) {
        const customFormat = convertCustomFormat(format, lang)
        if (moment.isDate(value)) {
          const result = moment(value).format(customFormat)
          // console.debug({ format, value, customFormat, result })
          return result
        }
        return value
      }
      return value
    },
  },
  react: {
    useSuspense: true,
  },
}
