import { FileThumbUNKNOWN } from './unknown'
import { FileThumbDOC } from './doc'
import { FileThumbERRORCAUTION } from './errorCaution'
import { FileThumbERRORDANGER } from './errorDanger'
import { FileThumbJPG } from './jpg'
import { FileThumbMP4 } from './mp4'
import { FileThumbPDF } from './pdf'
import { FileThumbPPT } from './ppt'
import { FileThumbZIP } from './zip'
import { FileThumbXLS } from './xls'
import { FileThumbPREVIEW } from './preview'

const FileThumbnail = {
  DOC: FileThumbDOC,
  ZIP: FileThumbZIP,
  XLS: FileThumbXLS,
  JPG: FileThumbJPG,
  PPT: FileThumbPPT,
  PDF: FileThumbPDF,
  MP4: FileThumbMP4,
  PREVIEW: FileThumbPREVIEW,
  UNKNOWN: FileThumbUNKNOWN,
  ERRORDANGER: FileThumbERRORDANGER,
  ERRORCAUTION: FileThumbERRORCAUTION,
}

export { FileThumbnail }
