import { Icon } from '@rocket-atoms/icon'
import { TextInput } from '@rocket-mono/foundations'
import { COLOR, FONT, IconName } from '@rocket-mono/libs'
import * as React from 'react'
import { Platform, Pressable, StyleSheet, Text, TouchableOpacity, View } from 'react-native'

interface LabelInputType {
  readonly?: boolean
  border: boolean
  labelIcon: IconName
  labelText: string
  dateValue: string[]
  height?: number
  placeholder?: string
  onPress: () => void
}

const LabelInput = ({
  readonly = false,
  border,
  labelIcon,
  labelText,
  dateValue,
  height = 64,
  placeholder = '설정하세요.',
  onPress,
}: LabelInputType) => {
  const [isHover, setIsHover] = React.useState(false)

  return (
    <Pressable
      style={[
        styles.container,
        { height },
        border && styles.borderContainer,
        isHover && { backgroundColor: COLOR.gray.g050 },
      ]}
      onHoverIn={readonly ? undefined : () => setIsHover(true)}
      onHoverOut={readonly ? undefined : () => setIsHover(false)}
    >
      <TouchableOpacity style={[styles.coverPress, { zIndex: 9 }]} onPress={readonly ? undefined : onPress} />
      <View style={styles.labelArea}>
        <Icon name={labelIcon} size={12} color={'gray.g450'} style={{ marginTop: -4, marginRight: 4 }} />
        <Text style={styles.labelText}>{labelText}</Text>
      </View>
      <TextInput
        editable={false}
        value={dateValue.join('')}
        placeholder={placeholder}
        placeholderTextColor={'gray.g400'}
        style={styles.inputStyle}
        containerStyle={{ backgroundColor: 'transparent' }}
      />
    </Pressable>
  )
}

export default LabelInput

const styles = StyleSheet.create({
  container: {
    positoin: 'relative',
    padding: 8,
    paddingBottom: 4,
    borderRadius: 4,
  },
  coverPress: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  borderContainer: {
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
  },
  labelArea: {
    flexDirection: 'row',
    alignItems: 'center',
    // marginBottom: 8,
  },
  labelText: {
    ...FONT.txtXs,
    color: COLOR.gray.g450,
  },
  inputStyle: {
    height: 20,
    ...FONT.txtSm,
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
    }),
  },
})
