import * as React from 'react'
import { View } from 'react-native'
import TodoItem from '../TodoItem'
import { useCardTodo } from './hooks'
import { ViewProps } from './types'

const CardTodoView: React.FC<ViewProps> = ({
  isAnonymous,
  onAnonymousPress,
  onCardLinkPress,
  onMentionPress,
  onLinkPress,
  onPressTodo,
}) => {
  const { list } = useCardTodo()

  if (list === undefined) return <></>

  return (
    <View>
      <TodoItem
        isAnonymous={isAnonymous}
        onAnonymousPress={onAnonymousPress}
        list={list}
        onCardLinkPress={onCardLinkPress}
        onMentionPress={onMentionPress}
        onLinkPress={onLinkPress}
        onPressItem={onPressTodo}
      />
    </View>
  )
}

export default CardTodoView
