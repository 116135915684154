import * as React from 'react'
import {
  View,
  Text,
  Animated,
  StyleSheet,
  StyleProp,
  ViewStyle,
  TextStyle,
} from 'react-native'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { COLOR, FONT } from '@rocket-mono/libs'
import { FAIcon } from '@rocket-atoms/icon'

interface Props {
  progress: number
  danger?: boolean
  small?: boolean
  containerStyle?: StyleProp<ViewStyle>
  progressStyle?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  timeLimit?: string
  showText?: string
}

const Progress = ({
  small,
  progress,
  danger,
  timeLimit,
  showText,
  containerStyle,
  progressStyle,
  textStyle,
}:Props) => {
  const progressValue = React.useRef(new Animated.Value(progress)).current

  React.useEffect(() => {
    Animated.timing(progressValue, {
      toValue: progress,
      duration: 500,
      useNativeDriver: false,
    }).start()
  }, [progress, progressValue])

  return (
    <View style={[styles.container, containerStyle]}>
        <View
        style={[
          styles.progressBar,
          { backgroundColor: COLOR.gray.g100 },
          { height: small ? 4 : 8 },
          danger && {backgroundColor:`rgba(248, 90, 94, .4)`},
          progressStyle,
        ]}
      >
        <Animated.View
          style={[
            StyleSheet.absoluteFill,
            { backgroundColor: COLOR.gray.g500 },
            {
              borderRadius:99,
              width: progressValue.interpolate({
                inputRange: [0, 100],
                outputRange: ['0%', '100%'],
                extrapolate: 'clamp',
              }),
            },
            danger && {backgroundColor: COLOR.main.redHsb},
            progress === 100 && {backgroundColor: COLOR.main.blue}
          ]}
        />
      </View>
      {progress !== 100 && timeLimit && 
      <View style={{marginLeft:4}}>
        <FAIcon iconName={faClock} size={small ? '2xs' : 'xs'} color={danger ? 'main.redHsb' : 'gray.g600' }/>
      </View>
      }
      {showText && (
        <Text
          style={[styles.text, textStyle]}
        >
          {showText}{showText && '('}{printClampProgress(progress)}%{showText && ')'}
        </Text>
      )}
    </View>
  )
}

export default Progress

export const printClampProgress = (progress: number) => {
  return Math.round(Math.max(Math.min(progress, 100), 0))
}



const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  progressBar: {
    flex: 1,
    width: '100%',
    borderRadius:999,
  },
  text: {
    marginLeft: 4,
    ...FONT.txt2Xs,
    color:COLOR.gray.g450
  },
})