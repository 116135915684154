import { TriggerCardLinkType, TriggerMentionType } from '@rui/rich-text-field'

export const triggerConvert = (res: TriggerMentionType | TriggerCardLinkType) => {
  if (res.type === 'MENTION') {
    // return `@${res.key}`
    return `[@${res.key}](${res.key})`
    // return `<m type=\"${res.type}\" chatRoomMemberNo=${res.userId} memberName=\"${res.key}\"/>`
  } else if (res.type === 'CARD_LINK') {
    // return `#${res.key}`
    return `[#${res.key}]{${res.cardName}}(/card/${res.key}?channelId=${res.channelId})`
    // return `<a href="${location.origin}/card/${res.key}?channelId=${res.channelId}}" target="_blank" title="${res.cardName}">#${res.key}</a>`
    // return `<m type=\"${res.type}\" cardNo=${res.key} cardName=\"${res.cardName}\" channelNo=${res.channelId}/>`
  }

  return ''
}

export function getCaret(el: HTMLDivElement): number {
  let caretAt = 0
  const sel = window.getSelection()

  if (!sel) return 0

  if (sel.rangeCount === 0) {
    return caretAt
  }

  const range = sel.getRangeAt(0)
  const preRange = range.cloneRange()
  preRange.selectNodeContents(el)
  preRange.setEnd(range.endContainer, range.endOffset)
  caretAt = preRange.toString().length

  return caretAt
}

export function setCaret(el: HTMLDivElement, offset: number): void {
  let sel = window.getSelection()
  let range = document.createRange()

  if (!sel) return
  range.setStart(el.childNodes[0] as Node, offset)
  range.collapse(true)
  sel.removeAllRanges()
  sel.addRange(range)
}
