import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text } from 'react-native'

import TypingAnimation from './TypingAnimation'

import { COLOR, FONT } from '@rocket-mono/libs'

interface Props {
  writerNames: string[]
  isEllipsis?: boolean
  visible?: boolean
}

const Typings: React.FC<Props> = ({ writerNames, isEllipsis = false, visible = true }) => {
  const { t } = useTranslation()

  return (
    <>
      <TypingAnimation
        style={styles.typingAni}
        dotX={8}
        dotY={-2}
        show
        dotColor={COLOR.mono.black}
        dotRadius={1.5}
        dotMargin={5}
      />
      {visible && (
        <>
          <Text style={styles.writer}>{printWriters({ writerNames, isEllipsis })}</Text>
          <Text style={styles.typingOthers}>
            {t(getI18nKey(writerNames), {
              othersCount: writerNames.slice(2).length,
            })}
          </Text>
        </>
      )}
    </>
  )
}

export default Typings

function getI18nKey(writerNames: string[]) {
  return `chatinput.typing${getPlural()}`

  function getPlural() {
    if (writerNames.length === 1) {
      return 'one'
    }

    if (writerNames.length === 2) {
      return 'two'
    }

    return 'others'
  }
}

function printWriters({ writerNames, isEllipsis = false }: Props) {
  let [firstWriterName, secondWriterName] = writerNames

  if (isEllipsis) {
    firstWriterName = applyEllipsis(firstWriterName)
    secondWriterName = applyEllipsis(secondWriterName)
  }

  return join(firstWriterName, secondWriterName)

  function applyEllipsis(string = '') {
    const SLICE_END_INDEX = 4
    const PAD_MAX_LENGTH = 7
    if (string.length <= 3) {
      return string
    }

    return string.slice(0, SLICE_END_INDEX).padEnd(PAD_MAX_LENGTH, '...')
  }

  function join(string1: string, string2: string) {
    if (!string2) {
      return string1
    }

    return `${string1}, ${string2}`
  }
}

const styles = StyleSheet.create({
  typingAni: {
    width: 30,
  },
  writer: {
    ...FONT.subTextBold,
    color: COLOR.mono.black,
    lineHeight: 20,
  },
  typingOthers: {
    ...FONT.subTextRegular,
    fontSize: 13,
    color: COLOR.mono.paleBlack,
    lineHeight: 20,
  },
})
