import { Icon } from '@rocket-atoms/icon'
import { Text } from '@rocket-atoms/text'
import { COLOR } from '@rocket-mono/libs'
import * as React from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import type { DropdownItemType } from './types'

interface Props {
  isActive: boolean
  item: DropdownItemType
  onPress: () => void
}

const DropdownBasicItem: React.FC<Props> = ({ isActive, item, onPress }) => {
  const [isHover, setIsHover] = React.useState(false)

  return (
    <Pressable
      style={[
        styles.item,
        {
          backgroundColor: isHover ? COLOR.gray.g050 : COLOR.mono.white,
        },
      ]}
      onPress={onPress}
      onHoverIn={() => {
        setIsHover(true)
      }}
      onHoverOut={() => setIsHover(false)}
    >
      <View style={styles.itemArea}>{isActive && <Icon name="check" color="mono.black" size={16} />}</View>
      <Text fontName="txtMd">{item.label}</Text>
    </Pressable>
  )
}

export default DropdownBasicItem

const styles = StyleSheet.create({
  itemArea: {
    width: 24,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 8,
    minWidth: 132,
    height: 36,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g200,
  },
})
