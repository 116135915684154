import React from 'react'
import { Pressable, ScrollView, View } from 'react-native'

import { COLOR } from '@rui/foundations'
import { FAIcon, FAIconProp } from '@rui/icons'

interface Props {
  iconName?: FAIconProp
  onPress?: () => void
  children?: JSX.Element | JSX.Element[]
  actived?: boolean
}

export const HorizontalTabGroup: React.FC<Props> = ({ iconName = 'check', onPress, children, actived }) => {
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <ScrollView
        horizontal
        style={{
          flexDirection: 'row',
          flex: 1,
        }}
      >
        {children}
      </ScrollView>
      <View style={{ width: 1, height: 28, backgroundColor: COLOR.gray.g300, marginRight: 12 }} />
      <Pressable
        onPress={onPress}
        style={[
          {
            width: 36,
            height: 36,
            borderWidth: 1,
            borderRadius: 4,
            borderColor: COLOR.gray.g400,
            alignItems: 'center',
            justifyContent: 'center',
          },
          actived && { borderColor: COLOR.primary.blue500 },
        ]}
      >
        <FAIcon iconName={iconName} size={'sm'} color={actived ? 'primary.blue500' : 'gray.g600'} />
      </Pressable>
    </View>
  )
}
