import { useAstro } from '@rocket-mono/providers'
import React, { useEffect } from 'react'

const SignOutScreen: React.FC = () => {
  const { astro } = useAstro()

  useEffect(() => {
    astro
      .logoutByCurrentSection()
      .then(() => {
        localStorage.clear()
        sessionStorage.clear()
        location.href = '/'
      })
      .catch(() => {
        localStorage.clear()
        sessionStorage.clear()
        location.href = '/'
      })
  }, [])

  return <></>
}

export default SignOutScreen
