import { Button } from '@rocket-atoms/button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { TriggerType } from '../RuleScreen/types'
import RuleStepIcon from './RuleStep/RuleStepIcon'
import TriggerItem from './TriggerList/TriggerItem'
import { commonStyles } from './style'

interface Props {
  selectedTriggerType?: TriggerType
  onEvent: (type: TriggerType) => void
  onPressCancel: () => void
}

const TriggerView: React.FC<Props> = ({ selectedTriggerType, onEvent, onPressCancel }) => {
  const { t } = useTranslation()

  return (
    <View style={commonStyles.container}>
      <View style={commonStyles.contents}>
        {/* 컨텐츠 시작 */}
        <View style={commonStyles.titleArea}>
          <RuleStepIcon type="Triggers" size={20} active />
          <Text style={commonStyles.title}>{t('rule.modal.trigger')}</Text>
        </View>
        <View>
          {/* 새 카드가 나타나면 */}
          <View style={styles.itemWrap}>
            <TriggerItem
              type={'NEWCARD-APPEAR'}
              title={t('rule.trigger.title')}
              text={t('rule.trigger.description')}
              selected={selectedTriggerType === 'NEWCARD'}
              onPress={() => onEvent('NEWCARD')}
            />
          </View>
          {/* 할일 카드가 완료되면 - 추후 마일스톤에서 처리 */}
          {/* <View style={styles.itemWrap}>
            <TriggerItem
              type={'TODO-COMPLETED'}
              title={t('rule.trigger.title')}
              text={t('rule.trigger.description')}
              onPress={() => {
                console.log('event')
              }}
            />
          </View> */}
          {/* 일정 카드가 일정이 지나면 - 추후 마일스톤에서 처리 */}
          {/* <View style={styles.itemWrap}>
            <TriggerItem
              type={'SCHEDULE-PASSES'}
              title={t('rule.trigger.title')}
              text={t('rule.trigger.description')}
              onPress={() => {
                console.log('event')
              }}
            />
          </View> */}
          {/* 특정 시간이 되면 */}
          <View>
            <TriggerItem
              type={'TIME-CERTAIN'}
              title={t('rule.repeat.title')}
              text={t('rule.repeat.description')}
              selected={selectedTriggerType === 'TIME'}
              onPress={() => onEvent('TIME')}
            />
          </View>
        </View>
        {/* 컨텐츠 종료 */}
      </View>
      <View style={commonStyles.buttonArea}>
        <Button type={'SECONDARY'} variant={'FILL'} text={t('workmodal.basic.before')} onPress={onPressCancel} />
      </View>
    </View>
  )
}

export default TriggerView

const styles = StyleSheet.create({
  itemWrap: {
    marginBottom: 8,
  },
})
