import React, { memo } from 'react'

import { RNSvg } from '@rocket/atoms'
import { StyleProp, ViewStyle } from 'react-native'

const { Svg, Line } = RNSvg

interface Props {
  width: number
  height: number
  style?: StyleProp<ViewStyle>
}

const SlashMark: React.FC<Props> = ({ width, height, style }) => {
  const list: number[] = React.useMemo(() => {
    const cx = width && height ? (width > height ? width * 2 : height * 2) : 600
    const forList: number[] = []

    for (let x: number = 0; x < cx; x += 20) {
      forList.push(x)
    }

    return forList
  }, [width, height])

  return (
    <Svg style={style} width={width} height={height}>
      {list.map((value, idx) => (
        <Line key={`line-${idx}`} x1={value} y1={0} x2={0} y2={value} stroke="#E5E8EB" strokeWidth={8} />
      ))}
    </Svg>
  )
}

export default memo(SlashMark)
