import React from 'react'
import { StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'

import { faUser } from '@fortawesome/free-regular-svg-icons'
import { faCashRegister, faIndent, faOutdent, faUtensils } from '@fortawesome/free-solid-svg-icons'
import { COLOR } from '@rocket-mono/libs'
import { ItemButton } from './components/ItemButton'
import { View } from '@rocket-mono/foundations'
import { Tooltip } from '@rocket/atoms'

interface Props {
  type?: 'DEFAULT' | 'TABLEORDER' | 'GUEST'
  side?: 'left' | 'right'
  state?: string
  onState?: (state: string) => void
  onSide?: (side: string) => void
  iconHeight?: number
}

export const LayoutNaviView: React.FC<Props> = ({
  type = 'DEFAULT',
  side = 'right',
  state = 'user',
  onState,
  onSide,
  iconHeight = 24,
}) => {
  const { t } = useTranslation()
  const Hr = () => <View style={{ height: iconHeight }} />

  return (
    <View style={styles.container}>
      <ItemButton
        iconName={side === 'right' ? faOutdent : faIndent}
        // iconColor="gray.g400"
        backgroundColor="mono.white"
        onPress={() => onSide && onSide(side === 'left' ? 'right' : 'left')}
      />
      <Hr />
      {type === 'DEFAULT' && (
        <>
          <Tooltip
            darkType
            text={t('layout.navi.tooltip.member')}
            gab={4}
            direction="bottom"
            onPressTarget={() => onState && onState('user')}
          >
            {(props) => (
              <View {...props}>
                <ItemButton onPress={() => onState && onState('user')} iconName={faUser} selected={state === 'user'} />
              </View>
            )}
          </Tooltip>
          <Hr />
          <Tooltip
            darkType
            text={t('layout.navi.tooltip.board')}
            gab={4}
            direction="bottom"
            onPressTarget={() => onState && onState('board')}
          >
            {(props) => (
              <View {...props}>
                <ItemButton
                  isBoard
                  // iconSize={20}
                  iconName={faUser}
                  selected={state === 'board'}
                  onPress={() => onState && onState('board')}
                />
              </View>
            )}
          </Tooltip>
          {/* <Hr />
          <Tooltip
            darkType
            text={t('layout.navi.tooltip.calendar')}
            gab={4}
            direction="bottom"
            onPressTarget={() => onState && onState('calendar')}
          >
            {(props) => (
              <View {...props}>
                <ItemButton
                  iconName={faCalendar}
                  selected={state === 'calendar'}
                  onPress={() => onState && onState('calendar')}
                />
              </View>
            )}
          </Tooltip> */}
        </>
      )}
      {type === 'TABLEORDER' && (
        <>
          <Tooltip
            darkType
            text={t('layout.navi.tooltip.member')}
            gab={4}
            direction="bottom"
            onPressTarget={() => onState && onState('member')}
          >
            {(props) => (
              <View {...props}>
                <ItemButton
                  iconName={faUser}
                  selected={state === 'member'}
                  onPress={() => onState && onState('member')}
                />
              </View>
            )}
          </Tooltip>
          <Hr />
          <Tooltip
            darkType
            text={t('layout.navi.tooltip.pos')}
            gab={4}
            direction="bottom"
            onPressTarget={() => onState && onState('pos')}
          >
            {(props) => (
              <View {...props}>
                <ItemButton
                  iconName={faCashRegister}
                  selected={state === 'pos'}
                  onPress={() => onState && onState('pos')}
                />
              </View>
            )}
          </Tooltip>
          <Hr />
          <Tooltip
            darkType
            text={t('layout.navi.tooltip.menu')}
            gab={4}
            direction="bottom"
            onPressTarget={() => onState && onState('menu')}
          >
            {(props) => (
              <View {...props}>
                <ItemButton
                  iconName={faUtensils}
                  selected={state === 'menu'}
                  onPress={() => onState && onState('menu')}
                />
              </View>
            )}
          </Tooltip>
        </>
      )}
      {type === 'GUEST' && (
        <>
          <Tooltip
            darkType
            text={t('layout.navi.tooltip.board')}
            gab={4}
            direction="bottom"
            onPressTarget={() => onState && onState('board')}
          >
            {(props) => (
              <View {...props}>
                <ItemButton
                  isBoard
                  // iconSize={20}
                  iconName={faUser}
                  selected={state === 'board'}
                  onPress={() => onState && onState('board')}
                />
              </View>
            )}
          </Tooltip>
        </>
      )}
      {/* <Hr />
      <Tooltip
        darkType
        text={'로드맵'}
        gab={4}
        direction="bottom"
        onPressTarget={() => onState && onState('roadmap')}
      >
        {(props) => (
          <View {...props}>
            <ItemButton
              iconSize={20}
              iconName={faTimeline}
              selected={state === 'roadmap'}
            />
          </View>
        )}
      </Tooltip> */}
      <Hr />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 40,
    height: '100%',
    paddingTop: 12,
    paddingLeft: 4,
    backgroundColor: COLOR.mono.white,
    borderRightWidth: 1,
    borderColor: COLOR.gray.g150,
  },
  buttonStyle: {
    borderRadius: 8,
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
