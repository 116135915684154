import { useAstro } from '@rocket-mono/providers'
import { SignInScreen } from '@rocket-screens/auth'
import { ProjectInvitation, ProjectInvitationAddon } from '@rocket/types'
import React, { useCallback, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SocialLoginType } from './types'
import { useTranslation } from 'react-i18next'

type MemberInvitation = ProjectInvitation & {
  addon?: ProjectInvitationAddon
  projectTitle?: string
  requestUserUserName?: string
}

const SigninScreen: React.FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const { i18n } = useTranslation()

  const inviteInfoToken = searchParams.get('inviteInfoToken')
  const [invitedEmail, setInvitedEmail] = React.useState<string | undefined>(undefined)
  const { astro } = useAstro()

  const socialList = useMemo<SocialLoginType[]>(() => {
    return [
      {
        provider: 'GOOGLE',
        onProc: () => {
          return astro.loginByGoogle()
        },
      },
      {
        provider: 'APPLE',
        onProc: () => {
          return astro.loginByApple()
        },
      },
      {
        provider: 'NAVER',
        onProc: () => {
          return astro.loginByNaver()
        },
      },
      {
        provider: 'KAKAO',
        onProc: () => {
          return astro.loginByKakao()
        },
      },
      {
        provider: 'FACEBOOK',
        onProc: () => {
          return astro.loginByFacebook()
        },
      },
    ]
  }, [])

  const handleLoginSuccess = useCallback((token: string) => {
    const redirectUrl = searchParams.get('redirect_url')
    if (token) {
      localStorage.setItem('authToken', token)
      location.href = redirectUrl ? redirectUrl : '/'
    }
  }, [])

  const handlePressJoin = useCallback(() => {
    navigate(`/join${invitedEmail ? `?invitedEmail=${invitedEmail}` : ''}`)
  }, [invitedEmail])

  const handlePressLogo = useCallback(() => {
    location.href = '/'
  }, [])

  const handleError = useCallback(() => {
    navigate('/error/external')
  }, [])

  React.useEffect(() => {
    const authToken = localStorage.getItem('authToken')
    if (inviteInfoToken && authToken) {
      astro.token(authToken)
      Promise.all([
        astro.readByInvitedInfoToken(inviteInfoToken).catch(() => undefined),
        astro.readMe().catch(() => undefined),
      ]).then(([data, me]) => {
        if (data && me) {
          if (data.addon?.email !== me.userEmail) {
            localStorage.setItem('MemberInvitation', JSON.stringify({ ...data, inviteInfoToken }))
            setInvitedEmail(data.addon?.email || '')
          }
          handleLoginSuccess(authToken)
        } else {
          if (data) {
            localStorage.setItem('MemberInvitation', JSON.stringify({ ...data, inviteInfoToken }))
            setInvitedEmail(data.addon?.email || '')
          } else {
            setInvitedEmail('')
          }
          if (me) {
            handleLoginSuccess(authToken)
          }
        }
      })
    } else if (authToken) {
      astro.token(authToken)
      astro
        .readMe()
        .then(() => {
          handleLoginSuccess(authToken)
        })
        .catch(() => {
          setInvitedEmail('')
        })
    } else if (inviteInfoToken) {
      astro
        .readByInvitedInfoToken(inviteInfoToken)
        .then((data: MemberInvitation) => {
          localStorage.setItem('MemberInvitation', JSON.stringify({ ...data, inviteInfoToken }))
          setInvitedEmail(data.addon?.email || '')
        })
        .catch(() => {
          setInvitedEmail('')
        })
    } else {
      setInvitedEmail('')
    }
  }, [inviteInfoToken])

  return (
    <SignInScreen
      i18n={i18n}
      invitedEmail={invitedEmail}
      socialList={socialList}
      onLoginSuccess={handleLoginSuccess}
      onPressJoin={handlePressJoin}
      onPressLogo={handlePressLogo}
      onError={handleError}
    />
  )
}

export default SigninScreen
