import { AstroOption } from '@rocket/types'

export const stompUrl =
  process.env.REACT_APP_STOMP_URL ?? 'https://rocket-v1-rocket-is-stomp.develop.gitops.mycroft.kr/websocket'

export const previewUrl =
  process.env.REACT_APP_PREVIEW_URL || 'https://rocket-v2-rocket-page-preview.develop.gitops.mycroft.kr'

const legacyUrl = {
  talkUrl: process.env.REACT_APP_LEGACY_TALK_URL ?? `https://rocket-v1-rocket-is-talk-react.develop.gitops.mycroft.kr`,
  projectApiLegacyUrl:
    process.env.REACT_APP_LEGACY_PROJECT_API ?? 'https://rocket-v1-rocket-is-api-project.develop.gitops.mycroft.kr',
  workLegacyUrl: process.env.REACT_APP_LEGACY_WORK_URL ?? 'https://rocket-v2-rocket-is-work.develop.gitops.mycroft.kr',
  appApiLegacyUrl:
    process.env.REACT_APP_LEGACY_APP_API ?? 'https://rocket-v1-rocket-is-api-app.develop.gitops.mycroft.kr',
  talkApiLegacyUrl:
    process.env.REACT_APP_LEGACY_TALK_API ?? 'https://rocket-v1-rocket-is-api-talk.develop.gitops.mycroft.kr',
}

const tableorderUrl = {
  shopBaseUrl:
    process.env.REACT_APP_TABLEORDER_SHOP_BASE_URL ??
    `https://rocket-work-models-table-order-shop.develop.gitops.mycroft.kr`,
  shopLayoutBaseUrl:
    process.env.REACT_APP_TABLEORDER_SHOP_LAYOUT_BASE_URL ??
    `https://rocket-work-models-table-order-shop-layout.develop.gitops.mycroft.kr`,
  menuBaseUrl:
    process.env.REACT_APP_TABLEORDER_MENU_BASE_URL ??
    `https://rocket-work-models-table-order-menu.develop.gitops.mycroft.kr`,
  menuCategoryBaseUrl:
    process.env.REACT_APP_TABLEORDER_MENU_CATEGORY_BASE_URL ??
    `https://rocket-work-models-table-order-menu-category.develop.gitops.mycroft.kr`,
  menuCatalogBaseUrl:
    process.env.REACT_APP_TABLEORDER_MENU_CATALOG_BASE_URL ??
    `https://rocket-work-models-table-order-menu-catalog.develop.gitops.mycroft.kr`,
  menuLabelBaseUrl:
    process.env.REACT_APP_TABLEORDER_MENU_LABEL_BASE_URL ??
    `https://rocket-work-models-table-order-menu-label.develop.gitops.mycroft.kr`,
  menuOptionBaseUrl:
    process.env.REACT_APP_TABLEORDER_MENU_OPTION_BASE_URL ??
    `https://rocket-work-models-table-order-menu-option.develop.gitops.mycroft.kr`,
  orderBaseUrl:
    process.env.REACT_APP_TABLEORDER_ORDER_BASE_URL ??
    `https://rocket-work-models-table-order-order.develop.gitops.mycroft.kr`,
  tableBaseUrl:
    process.env.REACT_APP_TABLEORDER_TABLE_BASE_URL ??
    `https://rocket-work-models-table-order-table.develop.gitops.mycroft.kr`,
  basketBaseUrl:
    process.env.REACT_APP_TABLEORDER_BASKET_BASE_URL ??
    `https://rocket-work-models-table-order-basket.develop.gitops.mycroft.kr`,
  tableorderFileBaseUrl:
    process.env.REACT_APP_TABLEORDER_FILE_BASE_URL ??
    `https://rocket-work-models-table-order-file.develop.gitops.mycroft.kr`,
}

const authOption = {
  authKakaoAppKey: '6662fcda770bdad0f26302dafb36cd9f',
  authNaverAppKey: 'fzmzY25zyRzFRFtfIooN',
  authFirebaseAppKey: 'AIzaSyDIhtC_tgYCv-AHPBmgu6LxXTT6IPY6NIo',
  authFirebaseAppId: '466233700305',
  authFirebaseProjectId: 'rocket-183622',
  authFirebaseSenderId: '1:466233700305:web:ac0a2ca56540b35cae3af0',
}

export const options: AstroOption = {
  ...legacyUrl,
  ...authOption,
  ...tableorderUrl,
  environment: 'production',
  talkBaseUrl: legacyUrl.talkApiLegacyUrl,
  userBaseUrl: legacyUrl.appApiLegacyUrl,
  baseUrl: process.env.REACT_APP_BASE_URL ?? 'https://rocket-v1-rocket-is-api-project.develop.gitops.mycroft.kr',
  authBaseUrl:
    process.env.REACT_APP_AUTH_BASE_URL ?? 'https://rocket-v2-rocket-authentication.develop.gitops.mycroft.kr',
  secureCdnUrl: process.env.REACT_APP_SECURE_CDN ?? 'https://rocket-v1-rocket-is-api-app.develop.gitops.mycroft.kr',
  cardBaseUrl: process.env.REACT_APP_CARD_BASE_URL ?? 'https://rocket-v2-rocket-card.develop.gitops.mycroft.kr',
  shortenerBaseUrl:
    process.env.REACT_APP_SHORTENER_BASE_URL ?? 'https://rocket-v2-url-shortener.develop.gitops.mycroft.kr',
  previewerBaseUrl:
    process.env.REACT_APP_PREVIEWER_BASE_URL ?? 'https://rocket-v2-rocket-previewer.develop.gitops.mycroft.kr',
  todoBaseUrl: process.env.REACT_APP_TODO_BASE_URL ?? 'https://rocket-v2-todo.develop.gitops.mycroft.kr',
  fileBaseUrl: process.env.REACT_APP_FILE_BASE_URL ?? 'https://rocket-v2-file.develop.gitops.mycroft.kr',
  assigneeBaseUrl: process.env.REACT_APP_ASSIGNEE_BASE_URL ?? `https://rocket-v2-assignee.develop.gitops.mycroft.kr`,
  notificationBaseUrl:
    process.env.REACT_APP_NOTIFICATION_BASE_URL ?? 'https://rocket-v2-rocket-notification.develop.gitops.mycroft.kr',
  bookmarkBaseUrl:
    process.env.REACT_APP_BOOKMARK_BASE_URL ?? 'https://rocket-v2-rocket-bookmark.develop.gitops.mycroft.kr',
  googleApiUrl:
    process.env.REACT_APP_GOOGLE_API_URL ?? 'https://rocket-v2-services-google-apis.develop.gitops.mycroft.kr',
  searchBaseUrl: process.env.REACT_APP_SEARCH_BASE_URL ?? 'https://rocket-v2-rocket-search.develop.gitops.mycroft.kr',
  automationBaseUrl:
    process.env.REACT_APP_AUTOMATION_BASE_URL ?? 'https://rocket-v2-rocket-automation.develop.gitops.mycroft.kr',
}
