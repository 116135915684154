import { COLOR } from '@rocket-mono/libs'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  skeletonCircle: {
    borderRadius: 30,
    backgroundColor: COLOR.gray.g150,
  },
  skeletonSquare: {
    borderRadius: 5,
    backgroundColor: COLOR.gray.g200,
  },
  skeletonBox: {
    borderRadius: 2,
    backgroundColor: COLOR.gray.g100,
  },
})
