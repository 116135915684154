import * as React from 'react'
import { Pressable, StyleSheet, Text } from 'react-native'
import { COLOR, FONT } from '@rocket-mono/libs'


const NoneButton = ({text, onPress}: {text: string, onPress: ()=>void}) => {
  const [ isHover, setIsHover ] = React.useState(false)
  return(
    <Pressable 
      onPress={onPress}
      onHoverIn={()=>setIsHover(true)}
      onHoverOut={()=>setIsHover(false)}
      style={[
        styles.noneButton,
        isHover && styles.nonButtonHover
      ]}
    >
      <Text style={styles.noneButtonText}>{text}</Text>
    </Pressable>
  )
}
export default NoneButton


const styles = StyleSheet.create({
  noneButton:{
    justifyContent: 'center',
    alignItem: 'center',
    height: 20,
    paddingHorizontal: 4,
    borderWidth: 1,
    borderStyle:'dashed',
    borderColor: COLOR.gray.g300,
    borderRadius: 10,
  },
  nonButtonHover:{
    borderColor: COLOR.gray.g400,
    backgroundColor: COLOR.gray.g150
  },
  noneButtonText:{
    ...FONT.txt2XsMedium,
    color: COLOR.gray.g400
  }
})
