import React from 'react'

import { SearchView } from './view'
import SearchScreenProvider, { useSearchScreen } from './provider'
import { SearchViewProps } from './types'

interface Props extends SearchViewProps {}

export const SearchScreen: React.FC<Props> = ({ ...viewProps }) => {
  return <SearchView {...viewProps} />
}

export default SearchScreen

export { SearchScreenProvider, useSearchScreen }
