import { COLOR, FONT } from '@rocket-mono/libs'
import { Platform, StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  buttonStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 36,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    borderRadius: 4,
    paddingHorizontal: 8,
  },
  buttonHoverStyle: {
    borderColor: COLOR.gray.g200,
  },
  buttonOpenStyle: {
    borderColor: COLOR.gray.g300,
  },
  buttonIfGorupStyle: {
    borderWidth: 0,
  },
  buttonDisabledStyle: {
    backgroundColor: COLOR.opacity.blk10,
    borderColor: COLOR.opacity.blk10,
  },
  configurationOneButton: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  buttonSizeSm: {
    height: 24,
  },
  buttonTypeSecondaryStyle: {
    borderWidth: 0,
    backgroundColor: COLOR.gray.g050,
  },
  buttonTypeSecondaryHoverStyle: {
    backgroundColor: COLOR.gray.g100,
  },
  buttonTypeSecondaryOpenStyle: {
    backgroundColor: COLOR.gray.g100,
  },
  buttonTypeQuietStyle: {
    borderWidth: 0,
    backgroundColor: COLOR.transparent.base,
  },
  buttonTypeQuietHoverStyle: {
    backgroundColor: COLOR.gray.g050,
  },
  buttonTypeQuietOpenStyle: {
    backgroundColor: COLOR.gray.g050,
  },
  inner: {
    position: 'relative',
    ...(Platform.OS === 'web' ? { flex: 1 } : {}),
    flexDirection: 'row',
    alignItems: 'center',
  },
  slotArea: {
    marginRight: 4,
  },
  textStyle: {
    flex: 1,
    ...FONT.txtSm,
    paddingRight: 12,
  },
  arrowArea: {
    position: 'absolute',
    right: 8,
  },
  textDisabled: {
    color: COLOR.gray.g400,
  },
  textSizeSm: {
    ...FONT.txtXs,
  },
  listListType: {
    borderTopWidth: 1,
    borderColor: COLOR.gray.g100,
  },
  buttonBackgroundColorWhite: {
    backgroundColor: COLOR.mono.white,
  },
})
