import { IDiscussion } from '@rocket-mono/libs'
import { atom } from 'recoil'

export const discussSelectIndexState = atom<number | null>({
  key: 'discussSelectIndexState',
  default: null,
})

export const replyDiscussSelectedIndexState = atom<string | undefined>({
  key: 'replyDiscussSelectedIndexState',
  default: undefined,
})

export const discussChannelIdState = atom<string>({
  key: 'discussChannelIdState',
  default: '',
})
export const updateDiscussMessageState = atom<IDiscussion | null>({
  key: 'updateDiscussMessageState',
  default: null,
})

export const replyDiscussMessageState = atom<{
  userEmail: string
  userName: string
  message: string
  no: string
  channelNo: string
  roomId: string
  recipient?: {
    no: number
  }
} | null>({
  key: 'replyDiscussMessageState',
  default: null,
})
