import * as React from 'react'
import { SafeAreaView, useWindowDimensions } from 'react-native'
import { ControlButtons } from './ControlButton'
import GoogleMap from './GoogleMap'

const MAP_HEIGHT = 300

interface Props {
  label: string
  latitude: number
  longitude: number
  zoom?: number
  expand?: boolean
  onExternal?: () => void
  onZoomChange?: (zoom: number) => void
  onLocation?: () => void
  height?: number
}
const Map: React.FC<Props> = ({
  latitude,
  longitude,
  label,
  zoom,
  expand = false,
  onExternal,
  onZoomChange,
  onLocation,
  height = MAP_HEIGHT,
}): JSX.Element => {
  const { width: deviceWidth, height: deviceHeight } = useWindowDimensions()

  const [isExpand, setIsExpand] = React.useState(false)
  const [zoomValue, setZoomValue] = React.useState(zoom || 16)

  const mapHeight = React.useMemo(() => {
    return isExpand ? deviceHeight : height
  }, [isExpand, deviceHeight])

  React.useEffect(() => {
    if (onZoomChange) onZoomChange(zoomValue)
  }, [zoomValue])

  return (
    <SafeAreaView
      style={
        isExpand
          ? {
              width: deviceWidth,
              height: mapHeight,
              position: 'absolute',
              left: 0,
              top: 0,
              padding: 0,
            }
          : { minWidth: '100%', width: '100%', height, padding: 0 }
      }
    >
      <GoogleMap
        zoom={zoomValue}
        latitude={latitude}
        longitude={longitude}
        label={label}
      />

      <ControlButtons
        mapHeight={mapHeight}
        expand={expand}
        isExpand={isExpand}
        onExpand={() => setIsExpand((is) => !is)}
        onZoomPlus={() => setZoomValue((zoom) => (zoom < 20 ? zoom + 1 : 20))}
        onZoomMinus={() => setZoomValue((zoom) => (zoom > 0 ? zoom - 1 : 0))}
        onExternal={onExternal}
        onLocation={onLocation}
      />
    </SafeAreaView>
  )
}

export default Map
