import * as React from 'react'

import { COLOR, FONT } from '@rocket-mono/libs'
import { StyleSheet, Pressable, Text, } from 'react-native'

import { FAIcon } from '@rocket-atoms/icon'
import { faXmark, faEllipsis } from '@fortawesome/free-solid-svg-icons'


interface Props {
  isActive: boolean
  onPress: () => void
}
const MoreButton = ({isActive, onPress}: Props) => {
  const [isHover, setIsHover] = React.useState(false)

  return(
    <Pressable 
      onPress={onPress}
      onHoverIn={()=>setIsHover(true)}
      onHoverOut={()=>setIsHover(false)}
      style={[
        styles.moreButton,
        isHover && styles.moreButtonHover,
        isActive && styles.moreButtonActive
      ]}
    >
      <Text><FAIcon iconName={isActive ? faXmark : faEllipsis} size={'lg'} color={isActive? 'gray.g300' : 'gray.g600'}/></Text>
    </Pressable>
  )
}

export default MoreButton

const styles = StyleSheet.create({
  moreButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 46,
    height: 46,
    borderWidth:1,
    borderColor: COLOR.gray.g100,
    backgroundColor: COLOR.mono.white,
    borderRadius: 25,
    marginHorizontal: 4
  },
  moreButtonHover:{
    borderColor: COLOR.gray.g200,
  },
  moreButtonActive:{
    borederColor: COLOR.transparent.base,
    backgroundColor: COLOR.gray.g050,
  },
})