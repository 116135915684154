import * as React from 'react'

import { Platform } from 'react-native'
import WorkCardTodoDateModalView from '../../WorkCardTodoDateModalView'
import { WorkCardTodo } from '../../providers'
import type { ShowModal } from '../types'

const initFromTime = (todo: WorkCardTodo) => {
  if (todo.periodOption === null) return false
  if (typeof todo.periodOption === 'undefined') return false
  if (typeof todo.periodOption.isFromTime === 'undefined') return false
  return todo.periodOption.isFromTime
}
const initToTime = (todo: WorkCardTodo) => {
  if (todo.periodOption === null) return false
  if (typeof todo.periodOption === 'undefined') return false
  if (typeof todo.periodOption.isToTime === 'undefined') return false
  return todo.periodOption.isToTime
}

const CardTodoDateModal = ({ layoutPayload, type, todo, onClose, onSave }: ShowModal) => {
  // const [todo, setTodo] = React.useState<Todo>(modalTodo)
  const [startDate, setStartDate] = React.useState<Date | undefined>()
  const [endDate, setEndDate] = React.useState<Date | undefined>()
  const [isFromTime, setIsFromTime] = React.useState(initFromTime(todo))
  const [isToTime, setIsToTime] = React.useState(initToTime(todo))
  return (
    <WorkCardTodoDateModalView
      visible={type === 'date'}
      layoutPayload={layoutPayload}
      onClose={() => {
        onClose()
        if (Platform.OS === 'web') {
          onSave &&
            onSave({
              ...todo,
              periodOption: {
                fromPeriodDate: startDate,
                toPeriodDate: endDate,
                isFromTime,
                isToTime,
              },
              isDateCheck: startDate || endDate ? true : false,
            })
        }
      }}
      initialStartDate={todo.periodOption?.fromPeriodDate}
      initialEndDate={todo.periodOption?.toPeriodDate}
      initialIsFromTime={isFromTime}
      initialIsToTime={isToTime}
      onStartDateChange={(date) => {
        setStartDate(date)
      }}
      onEndDateChange={(date) => {
        setEndDate(date)
      }}
      onIsTimeChange={($isFromTime, $isToTime) => {
        setIsFromTime($isFromTime)
        setIsToTime($isToTime)
      }}
      onSave={(startDate, endDate, $isFromTime, $isToTime) => {
        onSave &&
          onSave({
            ...todo,
            periodOption: {
              fromPeriodDate: startDate,
              toPeriodDate: endDate,
              isFromTime: $isFromTime,
              isToTime: $isToTime,
            },
            isDateCheck: startDate || endDate ? true : false,
          })
      }}
    />
  )
}

export default CardTodoDateModal
