import * as React from 'react'
import { StyleSheet, TextStyle } from 'react-native'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  value?: string
  inputRef?: React.RefObject<HTMLDivElement>
  editable?: boolean
  multiline?: boolean
  textContentType?: string
  placeholderTextColor?: string
  underlineColorAndroid?: string
  onChangeText?: (text: string) => void
  onEnterSubmit?: () => void
  // 이벤트는 unkonwn으로 넘기고, 플랫폼 별로 캐스팅해서 사용
  // onKeyPress?: (e: unknown) => void
  // onKeyUp?: (e: unknown) => void
  // onKeyDown?: (e: unknown) => void
  // onBlur?: (e: unknown) => void
  // onInput?: (e: unknown) => void
  // onCompositionUpdate?: (e: unknown) => void
  // onCompositionEnd?: (e: unknown) => void
}

interface MarshalStyle extends TextStyle {
  outlineWidth: number
}

class RichTextInput extends React.Component<Props> {
  // divStyle: React.CSSProperties = {}
  divStyle: any = {}
  value = ''

  constructor(props: Props) {
    super(props)

    const flatten = StyleSheet.flatten(props.style) as MarshalStyle

    this.divStyle.flex = flatten.flex
    this.divStyle.fontSize = flatten.fontSize
    this.divStyle.fontWeight = flatten.fontWeight
    this.divStyle.lineHeight = flatten.lineHeight
    this.divStyle.fontFamily = flatten.fontFamily
    this.divStyle.paddingLeft = flatten.paddingLeft
    this.divStyle.height = flatten.height
    this.divStyle.overflowX = 'auto'
    this.divStyle.overflowWrap = 'normal'
    this.divStyle.display = 'flex'

    if (!props.multiline) {
      this.divStyle.alignItems = 'center'
      // NOTE: lineHeight 적용에 대해서는 논의 필요
      this.divStyle.lineHeight = typeof flatten.height === 'number' ? `${flatten.height}px` : flatten.height
    } else {
      this.divStyle.marginTop = 8
      this.divStyle.marginBottom = 8
    }

    // extened styles
    this.divStyle.outlineWidth = flatten.outlineWidth
    this.divStyle.overflow = 'hidden'
    this.divStyle.paddingTop = flatten.paddingTop
    this.divStyle.paddingBottom = flatten.paddingBottom
    this.divStyle.paddingTop = flatten.paddingVertical
    this.divStyle.paddingBottom = flatten.paddingVertical

    this.divStyle.color = String(flatten.color)
    this.value = props.value || ''
  }

  render(): JSX.Element {
    return (
      <>
        <style>
          {`
            [placeholder]:empty:before{
              content: attr(placeholder);
              color: #888;
            }
            [security=password]:not(:empty) {
              -webkit-text-security: disc;
            }
        `}
        </style>
        <div
          ref={this.props.inputRef}
          security={this.props.textContentType}
          contentEditable={this.props.editable}
          placeholder={this.props.placeholder}
          suppressContentEditableWarning={true}
          style={this.divStyle}
          onInput={(e) => {
            this.props.onChangeText?.(e.currentTarget.textContent ?? '')
            this.props.onInput?.(e)
          }}
          onKeyDown={(e) => {
            // console.log(
            //   'onKeyDown',
            //   e,
            //   this.props.onEnterSubmit,
            //   !this.props.multiline && e.key === 'Enter',
            // )
            if (!this.props.multiline && e.key === 'Enter') {
              if (this.props.onEnterSubmit) {
                this.props.onEnterSubmit()
              }
              e.preventDefault()
            }
            this.props.onKeyDown?.(e)
          }}
          onKeyPress={this.props.onKeyPress}
          onKeyUp={this.props.onKeyUp}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          onCompositionUpdate={this.props.onCompositionUpdate}
          onCompositionEnd={this.props.onCompositionEnd}
        >
          {this.value}
        </div>
      </>
    )
  }
}

export default RichTextInput

export * from './types.web'
