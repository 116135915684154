import { faClock, faFileArrowDown, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { Progress } from '@rocket-atoms/progress'
import { OptionCountLabel } from '@rocket-molecules/activity'
import { LayoutPayload, getRealColor } from '@rocket-mono/libs'
import { CardIcon, FAIcon } from '@rocket/atoms'
import { SkeletonBoardCard } from '@rocket/skeleton'
import { CardType } from '@rocket/types'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, Text, View } from 'react-native'
import CardLabel from './CardLabel'
import { styles } from './styls'
import { ScheduleColor } from './types'

export interface CardItemProps {
  isCopy?: string
  isShare?: string
  isOrigin?: boolean
  type: CardType
  title: string
  timeLimit?: string
  task?: {
    total?: number
    complete?: number
    progress: number
  }
  option: {
    assign?: string
    location?: boolean
    gathering?: string
  }
  fade?: boolean
  closed?: boolean
}

export interface CardProps {
  native?: boolean
  /** 날짜가 지났을 경우 */
  timeRed?: boolean
  /** 날짜가 일주일 이내 */
  timeGray?: boolean
  isSkeleton?: boolean
  item: CardItemProps
  onPress?: () => void
  onLayout?: (payload: LayoutPayload) => void
}

export const Card: React.FC<CardProps> = ({ native, timeRed, timeGray, isSkeleton, item, onPress, onLayout }) => {
  const { t } = useTranslation()

  const [isCardHover, setIsCardHover] = React.useState(false)
  const [isCardPress, setIsCardPress] = React.useState(false)

  const { scheduleIconColor, scheduleTextColor }: ScheduleColor = React.useMemo(() => {
    if (timeRed) {
      return { scheduleIconColor: 'main.redHsb', scheduleTextColor: getRealColor('main.redHsb') }
    } else if (timeGray) {
      return { scheduleIconColor: 'gray.g300', scheduleTextColor: getRealColor('gray.g400') }
    } else {
      return { scheduleIconColor: 'gray.g600', scheduleTextColor: getRealColor('gray.g450') }
    }
  }, [timeRed, timeGray])

  return isSkeleton ? (
    <SkeletonBoardCard />
  ) : (
    <View
      style={styles.wrap}
      onLayout={(e) => {
        const { width, height } = e.nativeEvent.layout
        onLayout && onLayout({ width, height, x: 0, y: 0, pageX: 0, pageY: 0 })
      }}
    >
      <Pressable
        onHoverIn={() => setIsCardHover(true)}
        onHoverOut={() => setIsCardHover(false)}
        onPressIn={() => setIsCardPress(true)}
        onPressOut={() => setIsCardPress(false)}
        onPress={onPress}
        style={[
          styles.cardBase,
          isCardHover && styles.cardBaseHover,
          isCardPress && styles.cardBasePress,
          item.fade && styles.cardFadeStyle,
          item.closed && styles.cardCloseStyle,
        ]}
      >
        <View style={styles.cardTitle}>
          <Text
            numberOfLines={3}
            ellipsizeMode="tail"
            style={[styles.title, item.closed && styles.closedTitle, native && styles.mobileTitle]}
          >
            {item.title}
          </Text>
          <View style={item.fade && { opacity: 0.7 }}>
            {item.type !== 'TODO' && <CardIcon variant="CIRCLE" type={item.type} size="sm" />}
          </View>
        </View>
        <View style={styles.cardState}>
          <View style={[styles.summaryArea, item.fade || item.closed ? { opacity: 0.7 } : {}]}>
            {item.task && (
              <Progress
                small
                danger={timeRed ? true : false}
                showText={`${item.task.complete}/${item.task.total}`}
                progress={item.task.progress}
                timeLimit={item.timeLimit}
              />
            )}
            {item.timeLimit &&
            ((!item.task && item.type === 'MISSION') || item.type === 'MEETING' || item.type === 'COLLECTION') ? (
              <View style={styles.scheduleSummary}>
                <FAIcon iconName={faClock} size={'2xs'} color={scheduleIconColor} />
                <Text style={[styles.date, { color: scheduleTextColor }]}>{item.timeLimit}</Text>
              </View>
            ) : (
              <></>
            )}
          </View>
        </View>
        <View style={styles.optionArea}>
          {item.option.location && (
            <View style={styles.optionGap}>
              <OptionCountLabel iconName={faLocationDot} variant={item.closed ? 'close' : ''} />
            </View>
          )}
          {item.option.gathering && (
            <View style={styles.optionGap}>
              <OptionCountLabel
                iconName={faFileArrowDown}
                count={item.option.gathering}
                variant={item.closed ? 'close' : ''}
              />
            </View>
          )}
          {item.option.assign && (
            <View style={styles.optionGap}>
              <OptionCountLabel count={item.option.assign} variant={item.closed ? 'close' : ''} />
            </View>
          )}
        </View>
      </Pressable>

      <View style={[styles.cardLabel, item.fade || item.closed ? { opacity: 0.7 } : {}]}>
        {item.isCopy && <CardLabel type="COPY" originSource={item.isCopy} />}
        {item.isShare && (
          <CardLabel
            type="SHARE"
            isOrigin={item.isOrigin && true}
            originSource={item.isOrigin ? t('card.origin') : item.isShare}
          />
        )}
      </View>
    </View>
  )
}
