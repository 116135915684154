import React, { useCallback, useEffect, useState } from 'react'
import type { ProviderProps } from './types'
import Context from './context'
import { useWindowDimensions } from 'react-native'

const LAYOUT_NAV_VISIBLE = 'layout-nav-visible'
const LAYOUT_SIDE_NAV_WIDTH = 220
export const LayoutNavProvider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
  const { width: windowWidth } = useWindowDimensions()
  const [visible, setVisible] = useState(windowWidth < 1366 ? false : true)
  const [width, setWidth] = useState(visible ? LAYOUT_SIDE_NAV_WIDTH : 0)

  const show = useCallback(() => {
    sessionStorage.setItem(LAYOUT_NAV_VISIBLE, 'true')
    setVisible(true)
  }, [])
  const hide = useCallback(() => {
    sessionStorage.setItem(LAYOUT_NAV_VISIBLE, 'false')
    setVisible(false)
  }, [])

  useEffect(() => {
    setWidth(visible ? LAYOUT_SIDE_NAV_WIDTH : 0)
  }, [visible])

  useEffect(() => {
    if (windowWidth < 1366) setVisible(false)
    else {
      const visibleValue = sessionStorage.getItem(LAYOUT_NAV_VISIBLE)
      setVisible(visibleValue !== null && visibleValue === 'false' ? false : true)
    }
  }, [windowWidth])

  return <Context.Provider value={{ width, visible, show, hide }}>{children}</Context.Provider>
}

export * from './hooks'
