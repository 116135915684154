import * as React from 'react'
import {
  GestureResponderEvent,
  Pressable,
  PressableProps,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native'

import type { DirectionType } from '@rocket-atoms/tooltip'
import { Tooltip } from '@rocket-atoms/tooltip'

import type { IColors, IconName } from '@rocket-mono/libs'

import { Icon } from '@rocket-atoms/icon'
import { COLOR } from '@rocket-mono/libs'

import { getRealColor } from '@rocket-mono/libs'
import { BadgeSide, BadgeType } from '.'

export interface ButtonIconWithTooltipProps extends Omit<PressableProps, 'style' | 'onPressIn' | 'onPressOut'> {
  containerSize?: number
  iconName: IconName
  iconSize?: number
  iconColor?: IColors
  iconViewbox?: string
  backgroundColor?: IColors
  rounded?: boolean
  tooltipText?: string
  tooltipPosition?: DirectionType
  style?: StyleProp<ViewStyle>
  hoverStyle?: StyleProp<ViewStyle>
  pressedStyle?: StyleProp<ViewStyle>
  disabledStyle?: StyleProp<ViewStyle>
  tooltipStyle?: StyleProp<ViewStyle>
  tooltipTextStyle?: StyleProp<TextStyle>
  badgeSide?: BadgeSide
  badgeType?: BadgeType
  badgeMaxCount?: number
  badgeCount?: number
}

const ButtonIconWithTooltip = ({
  containerSize = 35,
  iconName,
  iconSize = containerSize * 0.5,
  iconViewbox = '0 -40 1000 1000',
  backgroundColor = 'transparent',
  iconColor = 'mono.black',
  rounded = false,
  onPress,
  tooltipText = '',
  tooltipPosition = 'bottom-center',
  style,
  hoverStyle,
  pressedStyle,
  disabledStyle,
  tooltipStyle,
  tooltipTextStyle,
  disabled,
  ...props
}: ButtonIconWithTooltipProps) => {
  const [hovered, setHovered] = React.useState(false)
  const [pressed, setPressed] = React.useState(false)

  const _onHoverIn = React.useCallback(() => {
    setHovered(true)
  }, [])

  const _onHoverOut = React.useCallback(() => {
    setHovered(false)
  }, [])

  const _onPress = React.useCallback(
    (event: GestureResponderEvent) => {
      onPress && onPress(event)
      setPressed((prev) => !prev)
    },
    [onPress],
  )

  return (
    <Pressable
      style={[
        styles.container,
        {
          height: containerSize,
          width: containerSize,
          borderRadius: rounded ? containerSize : getBorderRadius(containerSize),
          backgroundColor: getRealColor(backgroundColor),
        },
        style,
        !disabled && hovered && (hoverStyle ?? styles.hover),
        !disabled && pressed && (pressedStyle ?? styles.pressed),
        disabled && (disabledStyle ?? styles.disabled),
      ]}
      // @ts-ignore
      onHoverOut={_onHoverOut}
      onPress={_onPress}
      onPressOut={_onPress}
      disabled={disabled}
      {...props}
    >
      <Tooltip
        darkType
        text={tooltipText}
        direction={tooltipPosition}
        containerStyle={tooltipStyle}
        textStyle={tooltipTextStyle}
        onPressTarget={onPress}
        onHoverTarget={_onHoverIn}
        onBlurTarget={_onHoverOut}
      >
        {(tooltipProps) => (
          <View style={styles.pressable} {...tooltipProps}>
            <Icon name={iconName} size={iconSize} color={iconColor} viewBox={iconViewbox} />
          </View>
        )}
      </Tooltip>
    </Pressable>
  )
}

export default ButtonIconWithTooltip

const getBorderRadius = (containerSize: number) => {
  return containerSize * 0.2
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
  },
  pressable: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
    height: '100%',
    width: '100%',
  },
  hover: {
    opacity: 0.8,
  },
  pressed: {
    opacity: 0.5,
  },
  disabled: {
    backgroundColor: COLOR.mono.lightGray,
  },
})
