import * as React from 'react'
import type { LayoutChangeEvent, ViewStyle, View } from 'react-native'
import type { DirectionType } from './types'

interface UseDimensionsArgs {
  direction: DirectionType
  gab: number
}

export default function useDimenstions({ direction, gab }: UseDimensionsArgs) {
  const [tooltipDimensions, setTooltipDimensions] = React.useState({
    width: 0,
    height: 23,
  })
  const onChangeTooltipLayout = React.useCallback((e: LayoutChangeEvent) => {
    setTooltipDimensions({
      width: e.nativeEvent.layout.width,
      height: e.nativeEvent.layout.height,
    })
  }, [])

  const [dimensions, setDimensions] = React.useState({
    x: 0,
    y: 0,
    pageX: 0,
    pageY: 0,
    width: 0,
    height: 0,
  })
  const ref: React.LegacyRef<View> = React.useRef(null)

  const onLayout = React.useCallback(() => {
    if (ref?.current) {
      ref.current.measure((x, y, width, height, pageX, pageY) => {
        setDimensions({ x, y, pageX, pageY, width, height })
      })
    }
  }, [])

  const getTooltipPosition = React.useCallback((): ViewStyle => {
    if (direction === 'top-center') {
      return {
        top: dimensions.pageY - tooltipDimensions.height - gab,
        left:
          dimensions.pageX +
          dimensions.width * 0.5 -
          tooltipDimensions.width * 0.5,
      }
    }

    if (direction === 'top') {
      return {
        top: dimensions.pageY - tooltipDimensions.height - gab,
        left: dimensions.pageX,
      }
    }

    if (direction === 'bottom-center') {
      return {
        top: dimensions.pageY + dimensions.height + gab,
        left:
          dimensions.pageX +
          dimensions.width * 0.5 -
          tooltipDimensions.width * 0.5,
      }
    }

    if (direction === 'bottom') {
      return {
        top: dimensions.pageY + dimensions.height + gab,
        left: dimensions.pageX,
      }
    }

    if (direction === 'left') {
      return {
        top:
          dimensions.pageY +
          dimensions.height * 0.5 -
          tooltipDimensions.height * 0.5,
        left: dimensions.pageX - tooltipDimensions.width - gab,
      }
    }

    return {
      top:
        dimensions.pageY +
        dimensions.height * 0.5 -
        tooltipDimensions.height * 0.5,
      left: dimensions.pageX + dimensions.width + gab,
    }
  }, [direction, gab, tooltipDimensions, dimensions])

  return {
    dimensions,
    onChangeTooltipLayout,
    onLayout,
    ref,
    getTooltipPosition,
  }
}
