import { View } from '@rocket-mono/foundations'
import {
  useAstro,
  useAuth,
  useCurrentUser,
  useModalDialog,
  useSubscription,
  useWorkChannel,
  useWorkProject,
} from '@rocket-mono/providers'
import { Discussion } from '@rocket/types'
import { useToast } from '@rui/atoms'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutChangeEvent, useWindowDimensions } from 'react-native'
import { useRecoilState } from 'recoil'
import { DiscussionBoard, DiscussionListMoreButtonType, DiscussionProvider } from '../../components/Discussion'
import { MentionEntry, MentionProvider } from '../../components/mention'
import {
  discussSelectIndexState,
  replyDiscussMessageState,
  replyDiscussSelectedIndexState,
  updateDiscussMessageState,
} from '../../recoil'

interface Props {
  cardId: string
  discussChannelId: string
  discussMessage: string
  setDiscussMessage: (m: string) => void
  onSubmit: () => void
  onLayout: (e: LayoutChangeEvent) => void
  setDiscussMenu: (menu: DiscussionListMoreButtonType) => void
}

const DiscussionView: React.FC<Props> = ({
  cardId,
  discussChannelId,
  setDiscussMessage,
  onSubmit,
  onLayout,
  setDiscussMenu,
}) => {
  const { t } = useTranslation()
  const { width: screenWidth } = useWindowDimensions()

  const { isAnonymous, showLoginModal } = useAuth()
  const { show: showToastMessage } = useToast()
  const { showDialogMessage, hideDialogMessage } = useModalDialog()

  const { astro, option } = useAstro()
  const { currentUser } = useCurrentUser()
  const { currentProject } = useWorkProject()
  const { currentChannel, currentChannelMembers } = useWorkChannel()

  const [discussSelectIndex, setDiscussSelectIndex] = useRecoilState(discussSelectIndexState)
  const [replyDiscussSelectIndex, setReplyDiscussSelectIndex] = useRecoilState(replyDiscussSelectedIndexState)
  const [updateDiscussMessage, setUpdateDiscussMessage] = useRecoilState(updateDiscussMessageState)
  const [replyDiscussMessage, setReplyDiscussMessage] = useRecoilState(replyDiscussMessageState)

  const inputRef = useRef(null)

  const isArchive = useMemo(() => currentProject?.isArchive || currentChannel?.closed, [currentProject, currentChannel])

  const isChannelOwner = useMemo(() => {
    if (currentChannel?.roomType === 'D') return false
    const channelMember = currentChannelMembers.find(({ userId }) => userId === String(currentUser.id))
    const projectMember = currentProject?.members.find(({ userId }) => userId === String(currentUser.id))
    return channelMember?.isOwner || ['OWNER', 'MANAGER'].includes(projectMember?.auth || '')
  }, [currentChannel, currentChannelMembers, currentProject])

  const mentionableUsers: MentionEntry[] = useMemo(() => {
    // 토론에 들어올 수 있는 것은 보드멤버이므로, 토론 멘션에서 띄워주는 리스트는 해당 보드의 모든 멤버이다.
    console.debug('DEBUG: mentionable:  ', currentChannel)
    return currentChannelMembers
      .filter((o) => o.name)
      .map(({ id, name, email }) => ({
        id: String(id),
        name,
        thumbnail: `${option.secureCdnUrl}/profile/${email}`,
      }))
  }, [currentChannelMembers])

  const [discussCount, setDiscussCount] = useState<number>()
  const [discussList, setDiscussList] = useState<Discussion[] | null>()

  const previewDiscussList = useMemo<Discussion[]>(() => {
    if (discussList === undefined || discussList === null || currentUser === null) return []

    const appList = [{ title: t('chat.copy'), state: 'copy' }]

    const chatReply = discussList.filter((d) => d.chatMessageReply)
    const withoutChatReply = discussList.filter((d) => !d.chatMessageReply)

    return withoutChatReply.map((o) => {
      const moreList = ['msg', 'whisper'].includes(o.type) ? [...appList] : []
      if (o.writer.no === currentUser.id) {
        if (['msg', 'whisper'].includes(o.type)) moreList.push({ title: t('chat.edit'), state: 'modify' })
        moreList.push({ title: t('chat.delete'), state: 'delete' })
      } else if (isChannelOwner) {
        moreList.push({ title: t('chat.delete'), state: 'delete' })
      }
      moreList.push({ title: t('chat.reply'), state: 'reply' })

      let chatMessageReply: any = []

      for (const item of chatReply) {
        if (item.chatMessageReply?.no === o.no) {
          const moreReplyList = ['msg', 'whisper'].includes(item.type) ? [...appList] : []
          if (item.writer.no === currentUser.id) {
            if (['msg', 'whisper'].includes(item.type))
              moreReplyList.push({
                title: t('chat.edit'),
                state: 'modify',
              })
            moreReplyList.push({
              title: t('chat.delete'),
              state: 'delete',
            })
          } else if (isChannelOwner) {
            moreReplyList.push({
              title: t('chat.delete'),
              state: 'delete',
            })
          }
          chatMessageReply.push({
            no: item.no,
            name: item.name ? item.name : item.writer.nickName,
            imageUrl: `${option.secureCdnUrl}/profile/${item.writer.userEmail}`,
            message: item.message,
            messageSymbol: item.messageSymbol,
            regDate: new Date(item.regDate),
            type: item.type,
            file: {
              ...item.file,
              url: `${option.secureCdnUrl}/downloads/${item.file?.fileKey}/${item.file?.filename}`,
            },
            recipient: item.recipient,
            writer: item.writer,
            deleted: item.deleted,
            moreList: moreReplyList,
          })
        }
      }

      return {
        no: o.no,
        type: o.type,
        deleted: o.deleted,
        username: o.writer.userName,
        imageUrl: `${option.secureCdnUrl}/profile/${o.writer.userEmail}`,
        message: o.message,
        regDate: new Date(o.regDate),
        messageSymbol: o.messageSymbol,
        file: {
          ...o.file,
          url: `${option.secureCdnUrl}/downloads/${o.file?.fileKey}/${o.file?.filename}`,
        },
        recipient: o.recipient,
        writer: o.writer,
        chatMessageReply,
        moreList,
      }
    })
  }, [discussList, currentUser, isChannelOwner])

  console.log('previewdiscusstionList', previewDiscussList)

  const getDiscussMessageList = useCallback(() => {
    if (currentChannel)
      astro
        .readCardDiscussionMessageList(currentChannel.roomId, cardId, 0, 1000)
        .then((res) => {
          console.log('discusstionList-res', res, inputRef.current)
          //@ts-ignore
          inputRef.current?.clear()
          if (res) setDiscussList(res as any)
          else setDiscussList([])
        })
        .catch((err) => {
          console.log('discusstionList-err', err)
          setDiscussList(null)
        })
  }, [inputRef, currentChannel, cardId])

  useEffect(() => {
    console.log('discussChannelId,', discussChannelId)
    getDiscussMessageList()
  }, [inputRef, discussChannelId])

  useEffect(() => {
    if (currentChannel)
      astro
        .readCardDiscussionMessageCount(currentChannel.roomId, cardId)
        .then((res) => {
          const count = res - 1
          setDiscussCount(count < 0 ? 0 : count)
        })
        .catch(() => setDiscussCount(0))
  }, [previewDiscussList, currentChannel])

  useEffect(() => {
    if (replyDiscussMessage) {
      const idx = previewDiscussList.findIndex((o) => o.no === replyDiscussMessage.no)
      setDiscussSelectIndex(idx)
    }
  }, [previewDiscussList, replyDiscussMessage, updateDiscussMessage])

  useSubscription(
    [
      `/subscribe/v2/messages/${discussChannelId}`,
      `/subscribe/v2/messages/${discussChannelId}/update`,
      `/subscribe/v2/messages/${discussChannelId}/delete`,
    ],
    getDiscussMessageList,
  )

  return (
    <>
      <DiscussionProvider
        user={{
          ...currentUser,
          no: currentUser.id,
          userState:
            currentUser.userState === 'active'
              ? 1
              : currentUser.userState === 'away'
              ? 2
              : currentUser.userState === 'busy'
              ? 3
              : currentUser.userState === 'off'
              ? 4
              : 0,
          lang: currentUser.lang ?? undefined,
        }}
      >
        <MentionProvider entries={mentionableUsers}>
          <View nativeID="discuss-view" style={{ marginBottom: 50 }} onLayout={onLayout}>
            <DiscussionBoard
              ref={inputRef}
              isAnonymous={isAnonymous}
              onPressAnonymous={showLoginModal}
              mentionDirection={screenWidth > 800 ? 'downside' : 'upside'}
              isArchive={isArchive}
              list={previewDiscussList}
              discussionCount={discussCount ?? 0}
              onText={setDiscussMessage}
              onPopupState={(index, state, item) => {
                console.log('onPopupState', state, index, item)
                switch (state) {
                  case 'copy':
                    if (item) {
                      navigator.clipboard.writeText(item.message).then(() => {
                        showToastMessage({ type: 'Success', title: t('toast.copied'), position: 'TOP_CENTER' })
                      })
                    }
                    break
                  case 'modify':
                    console.log('onPopupState-modify', state, index, item)
                    if (item) {
                      setReplyDiscussMessage(null)
                      setDiscussSelectIndex(index)
                      setUpdateDiscussMessage(item)
                    }
                    break
                  case 'delete':
                    console.log('onPopupState-delete', state, index, item)
                    if (item) {
                      const title = t('system.delete')
                      const list = [
                        {
                          name: t('todolist.delete'),
                          action: () => {
                            astro
                              .deleteCardDiscussionMessage(discussChannelId, String(item.no))
                              .then((res) => console.log('onDeleteDiscuss-res', res))
                              .catch((res) => console.log('onDeleteDiscuss-catch', res))
                          },
                        },
                      ]
                      showDialogMessage({
                        title,
                        list,
                        cancelText: t('setting.button.cancel'),
                        onCancel: () => {
                          hideDialogMessage()
                        },
                      })
                    }
                    break
                  case 'reply':
                    console.log('onPopupState-reply', state, index, item)
                    if (item) {
                      setUpdateDiscussMessage(null)
                      setDiscussSelectIndex(index)
                      setReplyDiscussMessage({
                        userEmail: item.writer?.userEmail || '',
                        userName: item.username,
                        message: item.message,
                        no: String(item.no),
                        channelNo: discussChannelId,
                        roomId: discussChannelId,
                      })
                    }
                    break
                }
              }}
              onPopupReplyState={(parentIndex, index, state, item: any) => {
                console.log('onPopupReplyState', parentIndex, index, state, item)
                switch (state) {
                  case 'copy':
                    if (item) {
                      navigator.clipboard.writeText(item.message).then(() => {
                        showToastMessage({ type: 'Success', title: t('toast.copied'), position: 'TOP_CENTER' })
                      })
                    }
                    break
                  case 'modify':
                    if (item) {
                      setReplyDiscussMessage(null)
                      setDiscussSelectIndex(index)
                      setUpdateDiscussMessage(item)
                    }
                    break
                  case 'delete':
                    if (item) {
                      const title = t('system.delete')
                      const list = [
                        {
                          name: t('todolist.delete'),
                          action: () => {
                            astro
                              .deleteCardDiscussionMessage(discussChannelId, String(item.no))
                              .then((res) => console.log('onDeleteDiscuss-res', res))
                              .catch((res) => console.log('onDeleteDiscuss-catch', res))
                          },
                        },
                      ]
                      showDialogMessage({
                        title,
                        list,
                        cancelText: t('setting.button.cancel'),
                        onCancel: () => {
                          hideDialogMessage()
                        },
                      })
                    }
                    break
                }
              }}
              onSubmit={onSubmit}
              // onFileupload={clickFile}
              onSend={onSubmit}
              itemContainerStyle={{
                paddingVertical: 5,
                paddingHorizontal: 20,
              }}
              onFileDownload={(file) => {
                if (file) {
                  console.log('file', file)
                  // fileDownload(file)
                  const { fileKey, filename, originalFilename } = file
                  astro.fileDownload({ fileKey, filename, originalFilename })
                }
              }}
              selectedIndex={discussSelectIndex ?? undefined}
              replySelectedIndex={replyDiscussSelectIndex}
              itemReplyContainerStyle={{
                paddingLeft: 35,
                paddingRight: 20,
              }}
              // replySelectedIndex={discussSelectIndex}
              onFilePreview={(file) => {
                if (file) {
                  const payload = {
                    file,
                    channelRoomId: discussChannelId,
                    type: 'channel',
                    workType: currentProject?.type.code,
                    workName: currentProject?.title,
                    boardName: currentChannel?.roomName,
                  }
                  window.postMessage({ action: 'openPopupPreview', payload }, '*')
                }
              }}
              onLinkPress={(link) => {
                window.open(link, '_blank')
              }}
              onPressMoreButton={(payload) => {
                setDiscussMenu(payload)
              }}
            />
          </View>
        </MentionProvider>
      </DiscussionProvider>
    </>
  )
}

export default DiscussionView
