import * as React from 'react'

import { StyleSheet, View, Text, Pressable } from 'react-native'
import { COLOR, FONT } from '@rocket-mono/libs'

import { FAIcon } from '@rocket/atoms'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

interface Props{
  label: string,
  checked: boolean
  onPress: (arg0: boolean) => void
}

const TermsAllCheck = ({label, checked, onPress}: Props) => {
  const [isHover, setIsHover] = React.useState(false)
  return(
    <Pressable 
      onHoverIn={()=>setIsHover(true)}
      onHoverOut={()=>setIsHover(false)}
      onPress={()=>onPress(!checked)}
      style={[styles.wrap, isHover && {borderColor: COLOR.gray.g200}, checked && {borderColor: COLOR.primary.blue500}]}
    >
      <Text style={styles.labelStyle}>{label}</Text>
      <View style={[styles.checkIcon, checked && styles.checkActiveIcon]}>
        <FAIcon iconName={faCheck} size={'sm'} color={checked? 'mono.white' :'gray.g300'}/>
      </View>
    </Pressable>
  )
}

export default TermsAllCheck


const styles = StyleSheet.create({
  wrap:{
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'center',
    height:36,
    paddingLeft:12,
    paddingRight:8,
    borderWidth:1,
    borderColor:COLOR.gray.g150,
    borderRadius:4
  },
  labelStyle:{
    ...FONT.txtXs,
    color:COLOR.gray.g900
  },
  checkIcon:{
    justifyContent:'center',
    alignItems:'center',
    width:24,
    height:24,
    backgroundColor:COLOR.gray.g050,
    borderRadius:30
  },
  checkActiveIcon:{
    backgroundColor:COLOR.primary.blue500
  }
})