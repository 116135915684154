import React, { useMemo } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import { LOGIN_STATE } from './LoginModal'
import { Icon } from '@rocket-atoms/icon'

interface Props {
  loginState: LOGIN_STATE
  title: string
  whenPressDismiss: () => void
}

export const Header: React.FC<Props> = ({ loginState, whenPressDismiss }) => {
  const justifyContent = useMemo(() => {
    if (loginState === 'initial' || loginState === 'login.email' || loginState === 'signup.email') {
      return 'space-between'
    } else {
      return 'center'
    }
  }, [loginState])
  // const fontTitle = useMemo(() => {
  //   if (
  //     loginState === 'initial' ||
  //     loginState === 'login.email' ||
  //     loginState === 'signup.email'
  //   ) {
  //     return FONT.H4Bold
  //   } else {
  //     return FONT.listBold
  //   }
  // }, [loginState])
  const CloseButton = (props: any) => {
    return (
      <View {...props}>
        <TouchableOpacity
          onPress={() => {
            if (whenPressDismiss) {
              whenPressDismiss()
            }
          }}
        >
          <Icon name={'close'} color={'mono.black'} size={20} />
        </TouchableOpacity>
      </View>
    )
  }
  return (
    <View style={[style.container, { justifyContent }]}>
      {/* <Text style={{ ...fontTitle }}>{title}</Text> */}
      <CloseButton style={[style.button]} />
    </View>
  )
}

const style = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
  // headerText: {
  //   ...FONT.listBold,
  //   marginBottom: 10,
  //   // textAlign: 'center',
  //   color: '#333',
  // },
  button: {
    flex: 0,
    position: 'absolute',
    right: -10,
    top: -12,
  },
})
