import React from 'react'

import { RNSvg } from '@rocket-atoms/icon'

const { Svg, Circle, Path } = RNSvg

interface Props {
  type: string
  size: number
}

const CardTypeIcon: React.FC<Props> = ({ type, size }) => {
  return (
    <>
      {type === 'MISSION' && (
        <Svg width={size} height={size} viewBox="0 0 52 52" fill="none">
          <Circle cx={26} cy={26} r={26} fill="#55B3C2" />
          <Path
            d="M24.8516 27.7188L21.8164 24.6836L20.1758 26.3242L24.8242 31L31.8242 24L30.2109 22.332L24.8516 27.7188ZM35.3242 36.8242H16.6758C16.3477 36.8242 16.0651 36.7148 15.8281 36.4961C15.6094 36.2591 15.5 35.9857 15.5 35.6758V17C15.5 16.6719 15.6094 16.3984 15.8281 16.1797C16.0651 15.9427 16.3477 15.8242 16.6758 15.8242H35.3242C35.6523 15.8242 35.9258 15.9427 36.1445 16.1797C36.3815 16.3984 36.5 16.6719 36.5 17V35.6758C36.5 35.9857 36.3815 36.2591 36.1445 36.4961C35.9258 36.7148 35.6523 36.8242 35.3242 36.8242ZM17.8242 34.5H34.1758V18.1758H17.8242V34.5Z"
            fill="white"
          />
        </Svg>
      )}
      {(type === 'MEETING' || type === 'ATTEND') && (
        <Svg width={size} height={size} viewBox="0 0 52 52" fill="none">
          <Path
            d="M52 26C52 40.3594 40.3594 52 26 52C11.6406 52 0 40.3594 0 26C0 11.6406 11.6406 0 26 0C40.3594 0 52 11.6406 52 26Z"
            fill="#F2D166"
          />
          <Path
            d="M32.9999 16.833V15.6572H30.6757V16.833H21.3241V15.6572H18.9999V16.833H15.4999V36.6572H36.4999V16.833H32.9999ZM17.8241 34.333V22.6572H34.1757V34.333H17.8241Z"
            fill="white"
          />
        </Svg>
      )}
      {type === 'COLLECTION' && (
        <Svg width={size} height={size} viewBox="0 0 52 52" fill="none">
          <Path
            d="M52 26C52 40.3594 40.3594 52 26 52C11.6406 52 0 40.3594 0 26C0 11.6406 11.6406 0 26 0C40.3594 0 52 11.6406 52 26Z"
            fill="#B48BD3"
          />
          <Path
            d="M36.4999 19.1572H24.8241L23.4296 17.2979C23.3202 17.152 23.1835 17.0426 23.0194 16.9697C22.8553 16.8786 22.6822 16.833 22.4999 16.833H15.4999C15.1718 16.833 14.8892 16.9515 14.6522 17.1885C14.4335 17.4072 14.3241 17.6807 14.3241 18.0088V34.333C14.3241 34.6611 14.4335 34.9437 14.6522 35.1807C14.8892 35.3994 15.1718 35.5088 15.4999 35.5088H36.4999C36.828 35.5088 37.1014 35.3994 37.3202 35.1807C37.5572 34.9437 37.6757 34.6611 37.6757 34.333V20.333C37.6757 20.0049 37.5572 19.7314 37.3202 19.5127C37.1014 19.2757 36.828 19.1572 36.4999 19.1572ZM35.3241 33.1572H16.6757V19.1572H21.9257L23.3202 21.0439C23.4296 21.1898 23.5663 21.3083 23.7303 21.3994C23.8944 21.4723 24.0676 21.5088 24.2499 21.5088H35.3241V33.1572ZM21.3241 27.333L24.8514 30.833L30.6757 25.0088L29.0077 23.3408L24.8514 27.5244L22.9921 25.6924L21.3241 27.333Z"
            fill="white"
          />
        </Svg>
      )}
    </>
  )
}

export default CardTypeIcon
