import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Image, Pressable, View as RNView, StyleSheet, View } from 'react-native'

import { Text } from '@rocket-atoms/text'
import { COLOR, LayoutPayload } from '@rocket-mono/libs'
import { AssigneeIdType, useAstro, useWorkAssignee, useWorkChannel } from '@rocket-mono/providers'
import { Tag } from '@rui/atoms'
import { ClickOutside } from '@rui/foundations'
import UserSelectedComponent from '../UserSelectedComponent'

interface Props {
  readonly?: boolean
  assigneeIds?: AssigneeIdType[]
  onChangeAssigneeIds?: (o: AssigneeIdType[]) => void
}
const CardAssigneeView: React.FC<Props> = ({ readonly = true, assigneeIds, onChangeAssigneeIds }) => {
  const { t } = useTranslation()
  const [layoutPayload, setLayoutPayload] = useState<LayoutPayload>()
  const { option } = useAstro()
  const { currentChannelMembers } = useWorkChannel()
  const { assigneeList } = useWorkAssignee()

  const [visible, setVisible] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const members = useMemo(() => {
    return currentChannelMembers
      .filter(({ userId }) => userId !== 'null')
      .map(({ userId: id, name, email }) => {
        const uri = option.secureCdnUrl + '/profile/' + email
        const selected =
          assigneeIds !== undefined
            ? assigneeIds.filter(({ userId }) => String(userId) === String(id)).length > 0
            : assigneeList.map(({ userId }) => userId).filter((o) => o === Number(id)).length > 0
        return { id: String(id), name: name, uri, selected }
      })
  }, [currentChannelMembers, assigneeList, assigneeIds])

  const filteredMembers = useMemo(() => members.filter(({ selected }) => selected), [members])

  const buttonRef = useRef<RNView>(null)
  const handlePress = useCallback(() => {
    if (buttonRef.current) {
      buttonRef.current.measure((x, y, width, height, pageX, pageY) => {
        setLayoutPayload({
          x: 0,
          y: 0,
          width,
          height,
          pageX: 0,
          pageY: -36,
        })
      })
    }
    setVisible(true)
  }, [buttonRef.current])

  if (readonly)
    return (
      <View style={styles.inputValueStyle}>
        {filteredMembers.map((item, idx) => (
          <View key={`selectedMemberList-${idx}`} style={styles.container}>
            <Tag
              text={item.name}
              icon={<Image source={{ uri: item.uri }} style={{ height: 16, width: 16, borderRadius: 16 }} />}
              size="md"
            />
          </View>
        ))}
      </View>
    )

  return (
    <View>
      <Pressable
        ref={buttonRef}
        style={[
          styles.inputStyle,
          visible && { backgroundColor: COLOR.gray.g050 },
          isHover && { backgroundColor: COLOR.gray.g050 },
        ]}
        onPress={handlePress}
        onHoverIn={() => setIsHover(true)}
        onHoverOut={() => setIsHover(false)}
      >
        {filteredMembers.length > 0 ? (
          <View style={styles.inputValueStyle}>
            {filteredMembers.map((item, idx) => (
              <View key={`selectedMemberList-${idx}`} style={styles.container}>
                <Tag
                  text={item.name}
                  icon={<Image source={{ uri: item.uri }} style={{ height: 16, width: 16, borderRadius: 16 }} />}
                  size="md"
                  onPress={handlePress}
                />
              </View>
            ))}
          </View>
        ) : (
          <Text fontName="txtSm" fontColor="gray.g450" style={styles.inputPlaceHolderStyle}>
            {t('peopleincharge.personincharge')}
          </Text>
        )}
      </Pressable>

      <ClickOutside
        onClickOutside={() => {
          setVisible(false)
        }}
      >
        <UserSelectedComponent
          layoutPayload={layoutPayload}
          visible={visible}
          listMember={members}
          onClose={() => setVisible(false)}
          onChangeUsers={(users) => {
            console.log(
              'onChangeUsers',
              users,
              currentChannelMembers
                .filter(({ userId }) =>
                  users
                    .filter(({ selected }) => selected)
                    .map(({ id }) => id)
                    .includes(userId),
                )
                .map(({ userId, email: userEmail }) => ({ userId, userEmail })),
            )
            onChangeAssigneeIds &&
              onChangeAssigneeIds(
                currentChannelMembers
                  .filter(({ userId }) =>
                    users
                      .filter(({ selected }) => selected)
                      .map(({ id }) => id)
                      .includes(userId),
                  )
                  .map(({ userId, email: userEmail }) => ({ userId, userEmail })),
              )
          }}
        />
      </ClickOutside>
    </View>
  )
}

export default CardAssigneeView

const styles = StyleSheet.create({
  container: {
    marginRight: 4,
    marginVertical: 2,
    height: 28,
  },
  inputStyle: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 36,
    borderRadius: 4,
  },
  inputValueStyle: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    padding: 4,
  },
  inputPlaceHolderStyle: {
    height: 36,
    lineHeight: 36,
    paddingHorizontal: 12,
  },
})
