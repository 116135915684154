import React, { useCallback, useState } from 'react'
import { StyleSheet, View, Pressable } from 'react-native'
import { IColors } from '@rui/foundations'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FAIcon } from '@rui/icons'

interface Props {
  iconName: IconProp
  iconColor: IColors
  onPress?: () => void
  onHoverIn?: () => void
  onHoverOut?: () => void
}

const OptionButton: React.FC<Props> = ({ iconName, iconColor, onPress, onHoverOut, onHoverIn }) => {
  const [isHover, setIsHover] = useState(false)

  const handleHovverIn = useCallback(() => {
    onHoverIn && onHoverIn()
    setIsHover(true)
  }, [])
  const handleHovverout = useCallback(() => {
    onHoverOut && onHoverOut()
    setIsHover(false)
  }, [])

  return (
    <Pressable
      style={[styles.container, isHover && { backgroundColor: `rgba(0,0,0, .75)` }]}
      onHoverIn={handleHovverIn}
      onHoverOut={handleHovverout}
      onPress={onPress}
    >
      <View style={styles.iconWrap}>
        <FAIcon iconName={iconName} color={iconColor} />
      </View>
    </Pressable>
  )
}

export default OptionButton

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    width: 16,
    height: 16,
    padding: 2,
    borderRadius: 2,
  },
  iconWrap: {
    width: 10,
    height: 10,
    marginTop: -1,
  },
})
