import { CardRelationResponse } from '@rocket/types'
import { atom, atomFamily } from 'recoil'

export const bottomAlertMessageState = atom<
  | {
      message: string
      apply: {
        text: string
        action: () => void
      }
      cancel: {
        text: string
        action: () => void
      }
    }
  | undefined
>({
  key: 'bottomAlertMessageState',
  default: undefined,
})

export const cardListState = atomFamily<CardRelationResponse[], string>({
  key: 'cardListState',
  default: [],
})

export const placeholderPropsState = atom<
  | {
      draggableId: string
      sourceId: string
      sourceIndex: number
      destinationId?: string
      destinationIndex?: number
    }
  | undefined
>({
  key: 'placeholderPropsState',
  default: undefined,
})

export const dropAnimatingState = atom<{ channelId: string; cardId: string; index: number } | undefined>({
  key: 'dropAnimatingState',
  default: undefined,
})

export const qrLinkState = atom<string | null>({
  key: 'qrLinkState',
  default: null,
})
