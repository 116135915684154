import { Button, ButtonGroupRadio } from '@rocket-atoms/button'
import { Radio } from '@rocket-atoms/radio'
import { COLOR, FONT } from '@rocket-mono/libs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import DropNumberPicker from './DropNumberPicker'
import RuleStepIcon from './RuleStep/RuleStepIcon'
import TimePicker from './TimePicker'
import TriggerItem from './TriggerList/TriggerItem'
import { commonStyles } from './style'

type TriggerCertainTimeViewType = 'WEEK' | 'MONTH' | 'YEAR'

const getMonthDayNumber = (month: string) => {
  if (month === '02') {
    return 29
  }
  if (['04', '06', '09', '11'].includes(month)) {
    return 30
  }
  return 31
}

const getInitialIf = ({
  weekly,
  monthly,
  yearly,
  placeholder,
}: {
  weekly?: string[]
  monthly?: string
  yearly?: {
    month: string
    day: string
  }
  placeholder: TriggerCertainTimeViewType
}) => {
  if (!weekly && !monthly && !yearly) {
    return placeholder
  }
  if (weekly) {
    return 'WEEK'
  }
  if (monthly) {
    return 'MONTH'
  }
  if (yearly) {
    return 'YEAR'
  }
  return 'WEEK'
}

const getInitialWeek = (weekly: string[] | undefined) => {
  if (weekly) {
    return weekly
  }
  return []
}

const getInitialData = ({ data, placeholder }: { data?: string; placeholder: string }) => {
  return data ? data : placeholder
}

const getInitialNumber = ({ data }: { data?: string }) => {
  return data ? getMonthDayNumber(data) : 31
}

export type TimeViewNextType = {
  type: 'WEEK' | 'MONTH' | 'YEAR'
  week?: string[]
  month?: string
  day?: string
  time?: string
}

interface Props {
  initialWeek?: string[]
  initialMonth?: string
  initialYear?: {
    month: string
    day: string
  }
  initialTime?: string
  onPressCancel: () => void
  onPressNext: (payload: TimeViewNextType) => void
}

const TriggerCertainTimeView = ({
  initialWeek,
  initialMonth,
  initialYear,
  initialTime,
  onPressCancel,
  onPressNext,
}: Props) => {
  const { t } = useTranslation()
  const [weekChecked, setWeekChecked] = React.useState<string[]>(getInitialWeek(initialWeek))
  const [triggerCertainTimeState, setTriggerCertainTimeState] = useState<'WEEK' | 'MONTH' | 'YEAR'>(
    getInitialIf({ weekly: initialWeek, monthly: initialMonth, yearly: initialYear, placeholder: 'WEEK' }),
  )

  const [everyWeekTime, setEveryWeekTime] = useState<string>(
    getInitialData({
      data: initialTime,
      placeholder: '09:00',
    }),
  )
  const [everyMonthTime, setEveryMonthTime] = useState<string>(
    getInitialData({
      data: initialTime,
      placeholder: '09:00',
    }),
  )
  const [everyYearTime, setEveryYearTime] = useState<string>(
    getInitialData({
      data: initialTime,
      placeholder: '09:00',
    }),
  )

  const [everyMonthDay, setEveryMonthDay] = useState<string>(getInitialData({ data: initialMonth, placeholder: '01' }))

  const [everyYearMonth, setEveryYearMonth] = useState<string>(
    getInitialData({ data: initialYear?.month, placeholder: '01' }),
  )
  const [everyYearDayNumber, setEveryYearDayNumber] = useState<number>(getInitialNumber({ data: initialYear?.month }))
  const [everyYearDay, setEveryYearDay] = useState<string>(
    getInitialData({ data: initialYear?.day, placeholder: '01' }),
  )

  const disabled = React.useMemo(() => {
    if (triggerCertainTimeState === 'WEEK') {
      return weekChecked.length === 0
    } else {
      return false
    }
  }, [triggerCertainTimeState, weekChecked])

  return (
    <View style={commonStyles.container}>
      <View style={commonStyles.contents}>
        {/* 컨텐츠 시작 */}
        <View style={commonStyles.titleArea}>
          <RuleStepIcon type="Triggers" size={20} active />
          <Text style={commonStyles.title}>{t('rule.modal.trigger')}</Text>
        </View>
        <View>
          <View>
            <TriggerItem
              type={'TIME-CERTAIN'}
              title={t('rule.repeat.title')}
              text={t('rule.repeat.description')}
              onPress={onPressCancel}
              selected
            />
          </View>
          <Text style={commonStyles.labelText}>{t('board.setsettings')}</Text>
          {/* 매주 */}
          <View style={styles.section}>
            <Pressable
              onPress={() => {
                setTriggerCertainTimeState('WEEK')
              }}
              style={styles.flexBox}
            >
              <Radio checked={triggerCertainTimeState === 'WEEK'} label={t('rule.repeat.everyweek')} />
            </Pressable>
            {triggerCertainTimeState === 'WEEK' && (
              <View style={styles.conpanel}>
                <View style={{ marginTop: 4 }}>
                  <ButtonGroupRadio
                    checked={weekChecked}
                    setChecked={setWeekChecked}
                    list={[
                      { text: 'MON', code: 'MON' },
                      { text: 'TUE', code: 'TUE' },
                      { text: 'WED', code: 'WED' },
                      { text: 'THU', code: 'THU' },
                      { text: 'FRI', code: 'FRI' },
                      { text: 'SAT', code: 'SAT' },
                      { text: 'SUN', code: 'SUN' },
                    ]}
                  />
                </View>
                {weekChecked.length > 0 ? (
                  <>
                    <View style={commonStyles.infoTextArea}>
                      <Text style={[commonStyles.infoText, commonStyles.infoTextLabel]}>{t('rule.repeat.time')}</Text>
                      <Text style={commonStyles.infoText}>{t('rule.repeat.timedescription')}</Text>
                    </View>
                    <TimePicker
                      initialTime={everyWeekTime}
                      onTimeChange={(time) => {
                        setEveryWeekTime(time)
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </View>
            )}
          </View>
          {/* 매월 */}
          <View style={styles.section}>
            <Pressable
              onPress={() => {
                setTriggerCertainTimeState('MONTH')
              }}
              style={styles.flexBox}
            >
              <Radio checked={triggerCertainTimeState === 'MONTH'} label={t('rule.repeat.everymonth')} />
            </Pressable>
            {triggerCertainTimeState === 'MONTH' && (
              <View style={styles.conpanel}>
                <View style={[styles.flexBox, { marginTop: 4 }]}>
                  <DropNumberPicker
                    number={31}
                    selectedNumber={everyMonthDay}
                    onNumberChange={(number) => {
                      setEveryMonthDay(number)
                    }}
                  />
                  <Text style={[styles.text, { marginLeft: 4 }]}>{t('rule.repeat.date')}</Text>
                </View>

                <View style={commonStyles.infoTextArea}>
                  <Text style={[commonStyles.infoText, commonStyles.infoTextLabel]}>{t('rule.repeat.time')}</Text>
                  <Text style={commonStyles.infoText}>{t('rule.repeat.timedescription')}</Text>
                </View>
                <TimePicker
                  initialTime={everyMonthTime}
                  onTimeChange={(time) => {
                    setEveryMonthTime(time)
                  }}
                />
              </View>
            )}
          </View>
          {/* 매년 */}
          <View style={styles.section}>
            <Pressable
              onPress={() => {
                setTriggerCertainTimeState('YEAR')
              }}
              style={styles.flexBox}
            >
              <Radio checked={triggerCertainTimeState === 'YEAR'} label={t('rule.repeat.everyyear')} />
            </Pressable>
            {triggerCertainTimeState === 'YEAR' && (
              <View style={styles.conpanel}>
                <View style={[styles.flexBox, { marginTop: 4 }]}>
                  <DropNumberPicker
                    number={12}
                    selectedNumber={everyYearMonth}
                    onNumberChange={(number) => {
                      setEveryYearMonth(number)
                      setEveryYearDayNumber(getMonthDayNumber(number))
                      setEveryYearDay('01')
                    }}
                  />
                  <Text style={[styles.text, { marginHorizontal: 4 }]}>{t('ruel.repeat.month')}</Text>
                  <DropNumberPicker
                    number={everyYearDayNumber}
                    selectedNumber={everyYearDay}
                    onNumberChange={(number) => {
                      setEveryYearDay(number)
                    }}
                  />
                  <Text style={[styles.text, { marginLeft: 4 }]}>{t('rule.repeat.date')}</Text>
                </View>

                <View style={commonStyles.infoTextArea}>
                  <Text style={[commonStyles.infoText, commonStyles.infoTextLabel]}>{t('rule.repeat.time')}</Text>
                  <Text style={commonStyles.infoText}>{t('rule.repeat.timedescription')}</Text>
                </View>
                <TimePicker
                  initialTime={everyYearTime}
                  onTimeChange={(time) => {
                    setEveryYearTime(time)
                  }}
                />
              </View>
            )}
          </View>
        </View>
        {/* 컨텐츠 종료 */}
      </View>
      <View style={commonStyles.buttonArea}>
        <Button type={'SECONDARY'} variant={'FILL'} text={t('workmodal.basic.before')} onPress={onPressCancel} />
        <View style={commonStyles.ctaButton}>
          <Button
            type={'PRIMARY'}
            text={t('workmodal.basic.next')}
            disabled={disabled}
            onPress={() => {
              if (triggerCertainTimeState === 'WEEK') {
                onPressNext?.({
                  type: 'WEEK',
                  week: weekChecked,
                  time: everyWeekTime,
                })
              } else if (triggerCertainTimeState === 'MONTH') {
                onPressNext?.({
                  type: 'MONTH',
                  day: everyMonthDay,
                  time: everyMonthTime,
                })
              } else if (triggerCertainTimeState === 'YEAR') {
                onPressNext?.({
                  type: 'YEAR',
                  month: everyYearMonth,
                  day: everyYearDay,
                  time: everyYearTime,
                })
              }
            }}
          />
        </View>
      </View>
    </View>
  )
}

export default TriggerCertainTimeView

const styles = StyleSheet.create({
  selectItemArea: {
    marginBottom: 20,
  },
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  section: {
    marginBottom: 20,
  },
  conpanel: {
    paddingLeft: 20,
  },
  pickerStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 124,
    height: 36,
    borderWidth: 1,
    borderColor: COLOR.transparent.base,
    backgroundColor: COLOR.opacity.blk05,
    borderRadius: 4,
  },
  pickerStyleV2: {
    width: 72,
    paddingHorizontal: 12,
    borderColor: COLOR.gray.g200,
    backgroundColor: COLOR.mono.white,
  },
  text: {
    ...FONT.txtSm,
  },
})
