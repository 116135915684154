import React, { useState } from 'react'
import { Pressable, StyleSheet } from 'react-native'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

import { View } from '@rocket-mono/foundations'
import { COLOR, IColors } from '@rocket-mono/libs'
import { FAIcon, Text } from '@rocket/atoms'

interface WorkGroupButtonType {
  text: string
  iconName: IconProp
  size: number
  color: IColors
  onPress?: () => void
}
const WorkGroupButton: React.FC<WorkGroupButtonType> = ({ text, iconName, color, onPress }) => {
  const [isHover, setIsHover] = useState(false)
  return (
    <Pressable
      onPress={onPress}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      style={[styles.workGroupButton, isHover && { backgroundColor: COLOR.opacity.w10 }]}
    >
      <View style={styles.flexBox}>
        <View style={styles.iconAreaStyle}>
          <FAIcon iconName={iconName} color={color} />
        </View>
        <Text fontName="txtXs" fontColor="mono.white">
          {text}
        </Text>
      </View>
      <FAIcon iconName={faAngleRight} color={'gray.g500'} />
    </Pressable>
  )
}

export default WorkGroupButton

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  workGroupButton: {
    height: 32,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 8,
    zIndex: 0,
  },
  iconAreaStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 12,
    height: 12,
    marginRight: 4,
  },
})
