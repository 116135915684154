import React from 'react'
import Context from './context'
import { Action, ItemMore, ProjectCodeType, ProviderProps, Rule, RuleCreateStep, RuleStep, Trigger } from './types'

import { IColors, removeItemAtIndex, replaceItemAtIndex, useMobileView } from '@rocket-mono/libs'
import { useAstro, useCurrentUser, useModalDialog, useWork, useWorkProject } from '@rocket-mono/providers'
import { AutomationResponse, Card, Channel, ChannelMember, Project, ProjectElement, ProjectMember } from '@rocket/types'
import { useTranslation } from 'react-i18next'
import { RuleListItemType } from './view/ListView'

import { useToast } from '@rui/atoms'
import { convertAutomation, revertAutomation } from './util'

const Provider: React.FC<ProviderProps> = ({ projectId, selectedBoardId, children }) => {
  const viewTypeCodeList = ['TOP', 'CSP', 'WFP', 'DSP']
  const { astro, option } = useAstro()
  const { projectList } = useWork()
  const { currentProject, projectMemberList } = useWorkProject()
  const { currentUser } = useCurrentUser()
  const isMobile = useMobileView(500)
  const { t } = useTranslation()
  const { showDialogMessage, hideDialogMessage } = useModalDialog()
  const { show: showToastMessage } = useToast()

  const [step, setStep] = React.useState<RuleStep>('VIEW')
  const [createStep, setCreateStep] = React.useState<RuleCreateStep>('APPLY-BOARD')
  const [visible, setVisible] = React.useState<boolean>(false)

  const [errorBottomText, setErrorBottomText] = React.useState<string>()

  const [originalRuleList, setOriginalRuleList] = React.useState<AutomationResponse[]>([])
  const [ruleList, setRuleList] = React.useState<RuleListItemType[]>()

  const [boardList, setBoardList] = React.useState<Channel[]>([])
  const [archiveBoardList, setArchiveBoardList] = React.useState<Channel[]>([])

  const [currentWorkBoardGroupMemberList, setCurrentWorkBoardGroupMemberList] = React.useState<ChannelMember[]>()

  const [elementList, setElementList] = React.useState<ProjectElement[]>([])

  const [selectedBoard, setSelectedBoard] = React.useState<Channel>()
  const [selectedTrigger, setSelectedTrigger] = React.useState<Trigger>()
  const [selectedRule, setSelectedRule] = React.useState<Rule>()
  const [selectedAction, setSelectedAction] = React.useState<Action>()

  const [cardListOfSelectedBoard, setCardListOfSelectedBoard] = React.useState<Card[]>()
  const [memberListOfSelectedBoard, setMemberListOfSelectedBoard] = React.useState<ChannelMember[]>()

  const [unArchiveBoardListOfSelectedWork, setUnArchiveBoardListOfSelectedWork] = React.useState<Channel[]>([])
  const [archiveBoardListOfSelectedWork, setArchiveBoardListOfSelectedWork] = React.useState<Channel[]>([])

  const [isActionViewRender, setIsActionViewRender] = React.useState<boolean>(true)
  const [unArchiveBoardListOfSelectedWorkByAction, setUnArchiveBoardListOfSelectedWorkByAction] = React.useState<
    Channel[]
  >([])
  const [archiveBoardListOfSelectedWorkByAction, setArchiveBoardListOfSelectedWorkByAction] = React.useState<Channel[]>(
    [],
  )

  const [modifiedRuleId, setModifiedRuleId] = React.useState<string>()

  const [itemMore, setItemMore] = React.useState<ItemMore>()

  const isAuth = React.useMemo(() => {
    let selectedMember: ProjectMember | undefined = undefined
    let selectedBoardMember: ChannelMember | undefined = undefined
    if (projectMemberList && currentProject) {
      selectedMember = projectMemberList.find(
        (item) => item.userId === String(currentUser.id) && (item.auth === 'OWNER' || item.auth === 'MANAGER'),
      )
    }
    if (currentWorkBoardGroupMemberList) {
      const selectedBoardMemberList = currentWorkBoardGroupMemberList.filter(
        (item) => item.channelId === selectedBoardId && (item.isManager || item.isOwner),
      )
      selectedBoardMember = selectedBoardMemberList.find((item) => item.userId === String(currentUser.id))
    }
    if (selectedMember) return true
    else if (selectedBoardMember) return true
    else return false
  }, [projectMemberList, currentProject, currentWorkBoardGroupMemberList])

  const addElements = React.useCallback((elements: ProjectElement[]) => {
    setElementList((prev) => {
      const ids = prev.map((item) => item.id)
      const newElements = elements.filter((item) => !ids.includes(item.id))
      return [...prev, ...newElements]
    })
  }, [])

  const onChangeStep = React.useCallback((step: RuleStep) => {
    setStep(step)
  }, [])
  const onCreateRule = React.useCallback(() => {
    if (isAuth) {
      if (selectedBoardId) {
        onChangeStep('CREATE')
        const outerSelectedBoard = boardList.find((item) => item.id === selectedBoardId)
        onSelectedBoard(outerSelectedBoard)
        onCreateRuleStep('TRIGGERS')
      } else {
        onChangeStep('CREATE')
      }
    } else {
      showToastMessage({
        title: t('workboardmemberview.toast.nopermission'),
        type: 'Danger',
        position: 'BOTTOM_CENTER',
      })
    }
  }, [isAuth, boardList])
  const onModifyRule = React.useCallback(
    (listItem: RuleListItemType) => {
      if (isAuth) {
        const selectedBoard = boardList.find((item) => item.id === listItem.applyBoard)
        onSelectedBoard(selectedBoard)
        onSelectedTrigger(listItem.triggers)
        onSelectedRule(listItem.rules)
        onSelectedAction(listItem.action)
        if (listItem.rules?.event === 'CARD') {
          setUnArchiveBoardListOfSelectedWorkByAction(listItem.unArchiveBoardList || [])
          setArchiveBoardListOfSelectedWorkByAction(listItem.archiveBoardList || [])
        }
        setModifiedRuleId(listItem.id)
        onChangeStep('CREATE')
        onCreateRuleStep('AUTOMATION')
      } else {
        showToastMessage({
          title: t('workboardmemberview.toast.nopermission'),
          type: 'Danger',
          position: 'BOTTOM_CENTER',
        })
      }
    },
    [isAuth, boardList],
  )
  const onCreateRuleStep = React.useCallback((step: RuleCreateStep) => {
    setCreateStep(step)
  }, [])
  const onSelectedBoard = React.useCallback((board?: Channel) => {
    setSelectedBoard(board)
    if (board) {
      astro.readChannelMemberList(board.id).then((members) => {
        setMemberListOfSelectedBoard(members)
      })
      astro.readCardList(board.id).then((cards) => {
        setCardListOfSelectedBoard(cards)
      })
    } else {
      setCardListOfSelectedBoard(undefined)
      setMemberListOfSelectedBoard(undefined)
    }
  }, [])
  const onSelectedTrigger = React.useCallback((trigger?: Trigger) => {
    setSelectedTrigger(trigger)
  }, [])
  const onSelectedRule = React.useCallback((rule?: Rule) => {
    setSelectedRule(rule)
  }, [])

  const onVisible = React.useCallback((visible: boolean) => {
    setVisible(visible)
  }, [])

  const onRuleBoardBySelectedWork = React.useCallback((projectId: string) => {
    Promise.all([
      astro.readChannelList({ type: 'G', isArchive: false, projectId }),
      astro.readChannelList({ type: 'G', isArchive: true, projectId }),
      astro.readProjectElements(projectId),
    ]).then(([unArchive, archive, elements]) => {
      setUnArchiveBoardListOfSelectedWork(unArchive)
      setArchiveBoardListOfSelectedWork(archive)
      addElements(elements)
    })
  }, [])

  const onActionBoardBySelectedWork = React.useCallback((projectId: string) => {
    Promise.all([
      astro.readChannelList({ type: 'G', isArchive: false, projectId }),
      astro.readChannelList({ type: 'G', isArchive: true, projectId }),
      astro.readProjectElements(projectId),
    ]).then(([unArchive, archive, elements]) => {
      setUnArchiveBoardListOfSelectedWorkByAction(unArchive)
      setArchiveBoardListOfSelectedWorkByAction(archive)
      addElements(elements)
    })
  }, [])

  const getProfileUrl = React.useCallback(
    (email: string) => {
      return `${option.secureCdnUrl}/profile/${email}`
    },
    [option],
  )

  const getIcon = (code?: string): ProjectCodeType => {
    if (!code) return 'DSP'
    if (code === 'TOP') return 'TOP'
    if (code === 'CSP') return 'CSP'
    if (code === 'WFP') return 'WFP'
    if (code === 'DSP') return 'DSP'
    return 'DSP'
  }

  const getLabel = React.useCallback(({ isWFP, element }: { isWFP: boolean; element?: ProjectElement }): IColors => {
    if (!element) return 'mono.gray'
    if (isWFP) {
      const isFirst = element.order === 0
      const isLast = element.order === element.maxOrder
      if (isFirst) return 'main.yellow'
      if (isLast) return 'main.blue'
      return 'main.turquoise'
    } else {
      if (element.labelObject) {
        return element.labelObject as IColors
      } else {
        return 'mono.gray'
      }
    }
  }, [])

  const uuid4 = React.useCallback(() => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }, [])

  const onSelectedAction = React.useCallback((action?: Action) => {
    setSelectedAction(action)
  }, [])

  const onActionViewRender = React.useCallback((isRender: boolean) => {
    setIsActionViewRender(isRender)
  }, [])

  const onRuleListAdd = React.useCallback(
    (item: RuleListItemType, onSuccess: () => void) => {
      const revertItem = revertAutomation({ rule: item, workId: Number(projectId), isEnabled: item.isActive })
      astro
        .createAutomation(revertItem)
        .then((automation) => {
          setRuleList((prev) => {
            if (!prev) return []
            return [
              ...prev,
              {
                ...item,
                id: automation.id,
                cardList: cardListOfSelectedBoard,
                memberList: memberListOfSelectedBoard,
                unArchiveBoardList:
                  item.rules?.event === 'ANOTHER_WORK_BOARD' ? unArchiveBoardListOfSelectedWork : boardList,
                archiveBoardList:
                  item.rules?.event === 'ANOTHER_WORK_BOARD' ? archiveBoardListOfSelectedWork : archiveBoardList,
              },
            ]
          })
          showToastMessage({
            type: 'Success',
            title: t('notification.confirmed'),
            position: 'BOTTOM_CENTER',
          })
          setOriginalRuleList((prev) => {
            return [...prev, automation]
          })
          setErrorBottomText(undefined)
          onSuccess()
        })
        .catch((error: Response) => {
          error.json().then((data) => {
            const { errorType } = data
            if (errorType === 'ALREADY_EXISTS') {
              // setErrorBottomText(t('rule.modal.errorconflict'))
              showToastMessage({
                title: t('rule.modal.errorconflict'),
                type: 'Danger',
                position: 'BOTTOM_CENTER',
              })
            }
          })
        })
    },
    [
      cardListOfSelectedBoard,
      memberListOfSelectedBoard,
      unArchiveBoardListOfSelectedWork,
      archiveBoardListOfSelectedWork,
    ],
  )

  const onRuleListChange = React.useCallback(
    (item: RuleListItemType, onSuccess?: () => void) => {
      const originalRuleItem = originalRuleList.find((originalItem) => originalItem.id === item.id)
      if (!originalRuleItem) return
      const revertItem = revertAutomation({ rule: item, workId: Number(projectId), isEnabled: item.isActive })
      astro
        .updateAutomation(item.id, {
          ...revertItem,
          rule:
            item.rules?.event === 'SPECIFIC_BOARD' || item.rules?.event === 'ANOTHER_WORK_BOARD'
              ? item.unArchiveBoardList && item.archiveBoardList
                ? revertItem.rule
                : originalRuleItem.rule
              : revertItem.rule,
          action: item.unArchiveBoardList && item.archiveBoardList ? revertItem.action : originalRuleItem.action,
        })
        .then(() => {
          setRuleList((prev) => {
            if (!prev) return []
            const index = prev.findIndex((prevItem) => prevItem.id === item.id)
            return replaceItemAtIndex(prev, index, item)
          })
          setModifiedRuleId(undefined)
          onSuccess && onSuccess()
        })
    },
    [projectId, originalRuleList],
  )

  const onRuleListChangeByAnotherWork = React.useCallback((item: RuleListItemType, workId: string) => {
    Promise.all([
      astro.readChannelList({ type: 'G', isArchive: false, projectId: workId }),
      astro.readChannelList({ type: 'G', isArchive: true, projectId: workId }),
      astro.readProjectElements(workId),
    ]).then(([boards, archives, elements]) => {
      addElements(elements)
      setRuleList((prev) => {
        if (!prev) return []
        const index = prev.findIndex((prevItem) => prevItem.id === item.id)
        return replaceItemAtIndex(prev, index, { ...item, unArchiveBoardList: boards, archiveBoardList: archives })
      })
    })
  }, [])

  const onRuleListRemove = React.useCallback(
    (id: string) => {
      const ruleItem = ruleList?.find((item) => item.id === id)
      const originalRuleItem = originalRuleList.find((item) => item.id === id)
      if (ruleItem && originalRuleItem) {
        const revertItem = revertAutomation({ rule: ruleItem, workId: Number(projectId) })
        console.log('revertItem', revertItem)
        console.log('revertItem', originalRuleItem)
      }
      if (isAuth) {
        showDialogMessage({
          type: 'BOTTOM',
          backdrop: false,
          title: t('system.delete'),
          list: [
            {
              name: t('common.confirm'),
              action: () => {
                astro.deleteAutomation(id).then(() => {
                  setRuleList((prev) => {
                    if (!prev) return []
                    const index = prev.findIndex((prevItem) => prevItem.id === id)
                    return removeItemAtIndex(prev, index)
                  })
                  showToastMessage({
                    title: t('toast.delete'),
                    type: 'Success',
                    position: 'BOTTOM_CENTER',
                  })
                  hideDialogMessage()
                })
              },
            },
          ],
          cancelText: t('common.cancel'),
          onCancel: () => {
            console.log('onCancel')
          },
        })
      } else {
        showToastMessage({
          title: t('workboardmemberview.toast.nopermission'),
          type: 'Danger',
          position: 'BOTTOM_CENTER',
        })
      }
    },
    [isAuth, ruleList, originalRuleList],
  )

  const onCreateParameterClear = React.useCallback(() => {
    onChangeStep('VIEW')
    onCreateRuleStep('APPLY-BOARD')
    onSelectedBoard(undefined)
    onSelectedTrigger(undefined)
    onSelectedRule(undefined)
    onSelectedAction(undefined)
    setErrorBottomText(undefined)
    setModifiedRuleId(undefined)
  }, [])

  const onItemMore = React.useCallback((item?: ItemMore) => {
    setItemMore(item)
  }, [])

  const readAutomationExtra = React.useCallback(
    async (
      automation: AutomationResponse,
      projectList: Project[],
      currentWorkBoards: Channel[],
      currentWorkArchives: Channel[],
    ) => {
      const { applyBoardId, rule, action } = automation
      console.log('automation', automation)
      let cardList: Card[] | undefined = undefined
      let memberList: ChannelMember[] | undefined = undefined
      let archiveBoardList: Channel[] | undefined = undefined
      let unArchiveBoardList: Channel[] | undefined = undefined

      if (applyBoardId !== undefined) {
        if (rule.type === 'TO_SPECIFIC_CARD') {
          cardList = await astro.readCardList(String(automation.applyBoardId)).catch(() => undefined)
        } else if (rule.type === 'TO_SPECIFIC_USER') {
          memberList = await astro.readChannelMemberList(String(automation.applyBoardId)).catch(() => undefined)
        }
      }
      if (rule.type === 'TO_SPECIFIC_BOARD') {
        if (rule.workId !== undefined && rule.workId !== null) {
          const workId = String(rule.workId)
          const boards = await astro
            .readChannelList({ type: 'G', isArchive: false, projectId: workId })
            .catch(() => undefined)
          const archives = await astro
            .readChannelList({ type: 'G', isArchive: true, projectId: workId })
            .catch(() => undefined)
          const elements = await astro.readProjectElements(workId).catch(() => undefined)
          archiveBoardList = archives
          unArchiveBoardList = boards
          if (elements) {
            addElements(elements)
          }
        } else {
          archiveBoardList = currentWorkArchives
          unArchiveBoardList = currentWorkBoards
        }
      }

      if (action.type === 'COPY_CARD_TO_SPECIFIC_BOARD' && action.workId !== undefined && action.workId !== null) {
        const workId = String(action.workId)
        const boards = await astro
          .readChannelList({ type: 'G', isArchive: false, projectId: workId })
          .catch(() => undefined)
        const archives = await astro
          .readChannelList({ type: 'G', isArchive: true, projectId: workId })
          .catch(() => undefined)
        const elements = await astro.readProjectElements(workId).catch(() => undefined)
        archiveBoardList = archives
        unArchiveBoardList = boards
        if (elements) {
          addElements(elements)
        }
      }

      return convertAutomation({
        automation,
        cardList,
        memberList,
        archiveBoardList,
        unArchiveBoardList,
        projectList,
        t,
      })
    },
    [],
  )

  React.useEffect(() => {
    if (projectList && projectId) {
      Promise.all([
        astro.readChannelList({ type: 'G', isArchive: false, projectId }),
        astro.readChannelList({ type: 'G', isArchive: true, projectId }),
        astro.readProjectElements(projectId),
      ]).then(([boards, archives, elements]) => {
        setBoardList(boards)
        setArchiveBoardList(archives)
        setElementList(elements)
        const boardIds = [...boards, ...archives].map((item) => Number(item.id))

        Promise.all(boardIds.map((boardId) => astro.readChannelMemberList(String(boardId)))).then(
          (boardMemberGroup) => {
            const memberList = boardMemberGroup.reduce((prev, current) => {
              return [...prev, ...current]
            }, [])
            setCurrentWorkBoardGroupMemberList(memberList)
            astro
              .readAutomation(projectId)
              .then((automationList) => {
                setOriginalRuleList(automationList)
                const filteredAutomationList = automationList.filter((item) => boardIds.includes(item.applyBoardId))
                return Promise.all(
                  filteredAutomationList.map((a) => readAutomationExtra(a, projectList, boards, archives)),
                )
              })
              .then((automation) => {
                if (selectedBoardId) {
                  setRuleList(automation.filter((item) => item.applyBoard === selectedBoardId))
                } else {
                  setRuleList(automation)
                }
              })
          },
        )

        // setRuleList(['1'])
      })
    }
  }, [projectList])

  // React.useEffect(() => {
  //   console.log('20240226 ruleList', ruleList)
  // }, [ruleList])
  // React.useEffect(() => {
  //   console.log('20240226 isAuth', isAuth)
  // }, [isAuth])

  React.useEffect(() => {
    console.log('20240322 currentWorkBoardGroupMemberList', currentWorkBoardGroupMemberList)
  }, [currentWorkBoardGroupMemberList])

  if (currentProject === null) return <></>

  return (
    <Context.Provider
      value={{
        modifiedRuleId,
        viewTypeCodeList,
        isAuth,
        selectedBoardId,
        projectList,
        currentProject,
        isMobile,
        visible,
        errorBottomText,
        ruleList,
        boardList,
        archiveBoardList,
        elementList,
        step,
        createStep,
        cardListOfSelectedBoard,
        memberListOfSelectedBoard,
        selectedBoard,
        selectedTrigger,
        selectedRule,
        selectedAction,
        unArchiveBoardListOfSelectedWork,
        archiveBoardListOfSelectedWork,
        unArchiveBoardListOfSelectedWorkByAction,
        archiveBoardListOfSelectedWorkByAction,
        isActionViewRender,
        itemMore,
        getProfileUrl,
        getIcon,
        getLabel,
        uuid4,
        onVisible,
        onChangeStep,
        onCreateRule,
        onCreateRuleStep,
        onModifyRule,
        onSelectedBoard,
        onSelectedTrigger,
        onSelectedRule,
        onRuleBoardBySelectedWork,
        onSelectedAction,
        onActionBoardBySelectedWork,
        onActionViewRender,
        onRuleListAdd,
        onRuleListChange,
        onRuleListChangeByAnotherWork,
        onRuleListRemove,
        onCreateParameterClear,
        onItemMore,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
