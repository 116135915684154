import { COLOR, FONT } from '@rui/foundations'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    backgroundColor: COLOR.darkmode.cool900,
    paddingHorizontal: 16,
    paddingTop: 12,
    paddingBottom: 16,
    alignSelf: 'flex-start',
  },
  shortcutsArea: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  shortcutsText: {
    flex: 1,
    ...FONT.txtSmMedium,
    color: COLOR.gray.g450,
    marginRight: 12,
  },
  sectionText: {
    ...FONT.txtSmMedium,
    color: COLOR.darkmode.cool300,
  },
  listArea: {
    marginTop: 8,
  },
})
