import * as React from 'react'
import { Platform, View, ImageBackground } from 'react-native'
import { useTranslation } from 'react-i18next'
import { FONT, getRealColor, useMobileView } from '@rocket-mono/libs'
import { useCurrentUser, useWork, useWorkCard, useWorkChannel } from '@rocket-mono/providers'
import { ButtonIcon, CardTypeIcon, Gauge, Icon, Tooltip, useToast } from '@rocket/components'
import { WorkCardDetailHeaderType } from './types'
import { Text, IconButton, FAIcon, WorkIcon } from '@rocket/atoms'
import { Button } from '@rocket-mono/foundations'
import {
  faPen,
  faIndent,
  faAngleLeft,
  faWindowRestore,
  faArrowUpRightFromSquare,
  faEllipsis,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import CardInfoBox from './parts/info-box'
import { styles, webStyles } from './styles'

const WorkCardDetailHeader = ({
  currentProjectId,
  isWindow,
  projectType,
  projectName,
  channelName,
  channelColor,
  cardType,
  cardId,
  cardTitle,
  cardProgress,
  cardCreatedName,
  cardCreatedAt,
  cardUpdatedName,
  cardUpdatedAt,
  offsetTop,
  headerOffsetTop = 0,
  children,
  onStick,
  onPressWindow,
  onPressEdit,
  onPressShare,
  onPressEtc,
  onPressHide,
  onPressClose,
}: WorkCardDetailHeaderType) => {
  const { t } = useTranslation()
  const mobileView = useMobileView(960)
  const [ifScroll, setIfScroll] = React.useState(offsetTop)
  const isScroll = React.useMemo(() => {
    return ifScroll > headerOffsetTop
  }, [ifScroll, headerOffsetTop])

  const isAgent = React.useMemo(() => navigator.userAgent === 'RNWorkWebView', [navigator])

  const { showToastMessage } = useToast()
  const { currentCard, doneCard } = useWorkCard()
  const imageSource = { uri: '' }
  const imageDoneSource = { uri: 'https://rocket.is/files/file_box/d72859d048cf4ec6b4ddf2e92de96dbb.png' }

  const { currentUser } = useCurrentUser()
  const { projectList, archiveList } = useWork()
  const currentProject = React.useMemo(
    () => [...(projectList ?? []), ...(archiveList ?? [])].find(({ id }) => id === currentProjectId),
    [currentProjectId],
  )

  const { currentChannel, currentChannelMembers } = useWorkChannel()

  const currentChannelMember = React.useMemo(
    () => currentChannelMembers.find(({ userId }) => userId === String(currentUser.id)),
    [currentChannelMembers, currentUser],
  )
  const currentProjectMember = React.useMemo(
    () => currentProject?.members.find(({ userId }) => userId === String(currentUser.id)),
    [currentProject, currentUser],
  )
  const isAuth = React.useMemo(() => {
    if (currentCard && currentCard.user && currentCard.user.no === currentUser.id) return true
    if (currentChannel?.roomType === 'D') return false

    if (currentChannelMember === undefined && currentProjectMember === undefined) return false
    return currentChannelMember?.isOwner || ['OWNER', 'MANAGER'].includes(currentProjectMember?.auth || '')
  }, [currentUser, currentCard, currentChannel, currentProject, currentChannelMember, currentProjectMember])

  const handlePressDone = React.useCallback(() => {
    if (currentCard) {
      doneCard(String(currentCard.no), !currentCard.isDone).then(() => {
        showToastMessage({
          message: t('toast.confirmed'),
        })
      })
    }
  }, [currentCard])

  React.useEffect(() => {
    setIfScroll(offsetTop)
  }, [offsetTop])

  React.useEffect(() => {
    onStick && onStick(isScroll)
  }, [onStick, isScroll])

  const titleBoard = () => {
    return (
      <>
        {!isWindow && <IconButton iconName={faIndent} iconSize={'lg'} iconColor={'gray.g400'} onPress={onPressHide} />}
        <Tooltip gab={8} darkType={true} direction={'bottom'} text={projectName}>
          {(props) => (
            <View {...props}>
              <WorkIcon type={projectType} size={20} />
            </View>
          )}
        </Tooltip>
        <Icon
          name={'angle-right'}
          size={12}
          color={'gray.g300'}
          style={{
            ...Platform.select({
              web: {
                paddingHorizontal: 4,
              },
              native: {
                paddingHorizontal: 12,
              },
            }),
          }}
        />
        <View style={[styles.flexbox, styles.boardTitleArea]}>
          {channelColor !== undefined && channelColor !== null && (
            <View style={[styles.boardLabel, { backgroundColor: getRealColor(channelColor) }]} />
          )}
          <View style={{ flex: 1 }}>
            <Tooltip gab={0} darkType={true} direction={'bottom'} text={channelName}>
              {(props) => (
                <View {...props}>
                  <Text style={styles.boardName} numberOfLines={1} ellipsizeMode="tail">
                    {channelName}
                  </Text>
                </View>
              )}
            </Tooltip>
          </View>
        </View>
      </>
    )
  }

  const titleTask = () => {
    return (
      <View style={styles.taskTitle}>
        {!isWindow && <IconButton iconName={faIndent} iconSize={'lg'} iconColor={'gray.g400'} onPress={onPressHide} />}
        <CardTypeIcon type={cardType} size={20} />
        <Text style={styles.issueNumber}>#{cardId}</Text>
        <View style={{ flex: 1 }}>
          <Tooltip gab={0} darkType={true} direction={'bottom'} text={cardTitle}>
            {(props) => (
              <View {...props}>
                <Text style={styles.issueTitle} numberOfLines={1} ellipsizeMode="tail">
                  {cardTitle}
                </Text>
              </View>
            )}
          </Tooltip>
        </View>
      </View>
    )
  }

  return (
    <View>
      <div style={webStyles.stickyContainer}>
        <ImageBackground
          source={currentCard?.isDone ? imageDoneSource : imageSource}
          imageStyle={{ resizeMode: 'cover' }}
          style={[styles.headerStyle]}
        >
          <View style={[styles.header, isWindow && { marginLeft: 8 }]}>
            <View style={[styles.flexbox, { flex: 1 }]}>
              {isWindow && Platform.OS !== 'web' && (
                <IconButton iconName={faAngleLeft} iconSize={'lg'} iconColor={'gray.g400'} onPress={onPressClose} />
              )}
              <View style={[styles.flexbox, { flex: 1, marginLeft: 4 }, isScroll && { justifyContent: 'flex-start' }]}>
                {titleTask()}
              </View>
            </View>
            <View style={[styles.flexbox, mobileView && styles.controlMobileArea]}>
              <View style={styles.flexbox}>
                {onPressEdit !== undefined && (
                  <Tooltip
                    darkType
                    text={t('card.tooltip.edit')}
                    textStyle={{ ...FONT.txtSmMedium }}
                    direction="bottom"
                    onPressTarget={onPressEdit}
                  >
                    {(props) => (
                      <View {...props} style={styles.controlButton}>
                        <Button
                          size="small"
                          backgroundColor="mono.white"
                          borderColor="gray.g150"
                          onPress={onPressEdit}
                          style={{ minWidth: 32, height: 32, paddingHorizontal: 4, borderRadius: 8 }}
                        >
                          <FAIcon iconName={faPen} size={'xs'} color={'gray.g600'} />
                        </Button>
                      </View>
                    )}
                  </Tooltip>
                )}
                {!isWindow && (
                  <Tooltip
                    darkType
                    text={t('card.tooltip.window')}
                    textStyle={{ ...FONT.txtSmMedium }}
                    direction="bottom"
                    onPressTarget={onPressWindow}
                  >
                    {(props) => (
                      <View {...props} style={styles.controlButton}>
                        <IconButton
                          iconName={faWindowRestore}
                          iconSize={'sm'}
                          iconColor={'gray.g450'}
                          onPress={onPressWindow}
                        />
                      </View>
                    )}
                  </Tooltip>
                )}
                {onPressShare !== undefined && (
                  <Tooltip
                    darkType
                    text={t('card.tooltip.share')}
                    textStyle={{ ...FONT.txtSmMedium }}
                    direction="bottom"
                    onPressTarget={onPressShare}
                  >
                    {(props) => (
                      <View {...props} style={[styles.controlButton, mobileView && { marginLeft: 4 }]}>
                        <IconButton
                          iconName={faArrowUpRightFromSquare}
                          iconSize={'sm'}
                          iconColor={'gray.g450'}
                          onPress={onPressShare}
                        />
                      </View>
                    )}
                  </Tooltip>
                )}
                {onPressEtc !== undefined && (
                  <Tooltip
                    darkType
                    text={t('explainworkmodel.more')}
                    textStyle={{ ...FONT.txtSmMedium }}
                    direction="bottom-center"
                    onPressTarget={onPressEtc}
                  >
                    {(props) => (
                      <View {...props} style={styles.controlButton}>
                        <IconButton
                          iconName={faEllipsis}
                          iconSize={'sm'}
                          iconColor={'gray.g450'}
                          onPress={onPressEtc}
                        />
                      </View>
                    )}
                  </Tooltip>
                )}
                {(!isWindow || Platform.OS !== 'web') && (
                  <View style={[styles.controlButton, mobileView && { marginLeft: 4 }]}>
                    <IconButton iconName={faXmark} iconSize={'sm'} iconColor={'gray.g450'} onPress={onPressClose} />
                  </View>
                )}
                {isAgent && (
                  <View style={[styles.controlButton, mobileView && { marginLeft: 4 }]}>
                    <ButtonIcon
                      iconName="close"
                      iconSize={16}
                      iconColor="gray.g700"
                      onPress={() => {
                        // @ts-ignore
                        window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'close' }))
                      }}
                    />
                  </View>
                )}
              </View>
            </View>
          </View>
          <View style={styles.detailContents}>
            {/* {!isScroll && isWindow && titleTask()} */}
            {cardProgress === undefined ? (
              !isScroll && (
                <View
                  style={[
                    styles.cardBox,
                    { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' },
                  ]}
                >
                  <View>
                    <CardInfoBox
                      workType={projectType as 'DSP' | 'WFP' | 'CSP' | 'TOP'}
                      workName={projectName}
                      boardName={channelName}
                      boardColor={channelColor}
                      created={t('format.date.L', { date: cardCreatedAt })}
                      userCreated={cardCreatedName}
                      lastEdited={cardUpdatedAt ? t('format.date.L', { date: cardUpdatedAt }) : undefined}
                      userEdited={cardUpdatedName}
                      isDone={currentCard?.isDone}
                    />
                  </View>
                  {isAuth && (
                    <Button
                      size={'tiny'}
                      style={{ paddingHorizontal: 4, height: 20 }}
                      onPress={handlePressDone}
                      backgroundColor={currentCard?.isDone ? 'main.red' : 'primary.blue500'}
                    >
                      <Text fontName="txt2Xs" fontColor="mono.white">
                        {currentCard?.isDone ? t('card.reopen') : t('card.close')}
                      </Text>
                    </Button>
                  )}
                </View>
              )
            ) : (
              <View style={styles.cardBox}>
                {!isScroll && (
                  <CardInfoBox
                    workType={projectType as 'DSP' | 'WFP' | 'CSP' | 'TOP'}
                    workName={projectName}
                    boardName={channelName}
                    boardColor={channelColor}
                    created={t('format.date.L', { date: cardCreatedAt })}
                    userCreated={cardCreatedName}
                    lastEdited={cardUpdatedAt ? t('format.date.L', { date: cardUpdatedAt }) : undefined}
                    userEdited={cardUpdatedName}
                    isDone={currentCard?.isDone}
                  />
                )}
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <View style={{ flex: 1, marginRight: 8 }}>
                    <Gauge
                      showText={true}
                      small
                      rounded
                      fillColor="gray.g450"
                      unfillColor="gray.g050"
                      textColor={'gray.g450'}
                      progress={cardProgress}
                    />
                  </View>
                  {isAuth && (
                    <Button
                      size={'tiny'}
                      style={{ paddingHorizontal: 4, height: 20 }}
                      onPress={handlePressDone}
                      backgroundColor={currentCard?.isDone ? 'main.red' : 'primary.blue500'}
                    >
                      <Text fontName="txt2Xs" fontColor="mono.white">
                        {currentCard?.isDone ? t('card.reopen') : t('card.close')}
                      </Text>
                    </Button>
                  )}
                </View>
              </View>
            )}
          </View>
        </ImageBackground>
      </div>
      {children}
    </View>
  )
}

export default WorkCardDetailHeader
