import { StyleSheet } from 'react-native'
import { COLOR, FONT } from '@rocket-mono/libs'

export const webStyles = {
  stickyContainer: {
    position: 'sticky' as const,
    top: 0,
    zIndex: 0,
    borderBottom: '1px solid #000',
    borderColor: COLOR.gray.g150,
    backgroundColor: COLOR.mono.white,
  },
}

export const styles = StyleSheet.create({
  container: {
    paddingVertical: 8,
    paddingTop: 4,
    paddingLeft: 4,
    paddingRight: 8,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g150,
    backgroundColor: COLOR.mono.white,
  },
  headerStyle: {
    width: '100%',
    height: '100%',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 4,
    paddingRight: 8,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 4,
    marginBottom: 4,
  },
  flexbox: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  controlMobileArea: {
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  controlButton: {
    marginLeft: 8,
  },
  boardTitleArea: {
    flex: 1,
    justifyContent: 'flex-start',
    paddingRight: 20,
  },
  boardLabel: {
    width: 20,
    height: 20,
    borderRadius: 4,
    marginRight: 4,
  },
  boardName: {
    ...FONT.txtMdBold,
  },
  detailContents: {
    paddingHorizontal: 12,
  },
  taskTitle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  issueNumber: {
    ...FONT.txtMdBold,
    color: COLOR.gray.g450,
    marginHorizontal: 4,
  },
  issueTitle: {
    flex: 1,
    ...FONT.txtMdMedium,
  },
  cardBox: {
    borderWidth: 1,
    borderColor: COLOR.gray.g100,
    borderRadius: 8,
    padding: 8,
    marginTop: 4,
    marginBottom: 4,
    backgroundColor: COLOR.mono.white,
  },
  cardInfoWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 4,
  },
  cardInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  divLine: {
    width: 1,
    height: 8,
    backgroundColor: COLOR.gray.g200,
    marginHorizontal: 4,
  },
  cardInfoText: {
    ...FONT.txt2Xs,
    color: COLOR.gray.g450,
  },
  cardInfoUserText: {
    minWidth: 40,
    marginLeft: 2,
  },
  iconWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 16,
    height: 16,
    backgroundColor: COLOR.gray.g050,
    borderRadius: 20,
    marginRight: 4,
  },
})
