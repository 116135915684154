import { DatePicker as DateRangePickerV2 } from '@rocket-molecules/date'
import { COLOR } from '@rocket-mono/libs'
import moment from 'moment'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, Modal, Platform, Pressable, StyleSheet, View } from 'react-native'
import { DatePickerProps } from './types'

const DatePicker = ({
  minDate,
  maxDate,
  type,
  initialStart,
  initialTime,
  initialIsTime,
  onChangeStart,
  onChangeTime,
  onChangeIsTime,
  onClose,
  onDelete,
}: DatePickerProps) => {
  const { t } = useTranslation()
  const title = React.useMemo(() => {
    if (type === 'start') {
      return t('workcardtododatemodal.datepicker.starttitle')
    } else if (type === 'end') {
      return t('workcardtododatemodal.datepicker.endtitle')
    } else {
      return ''
    }
  }, [type, t])
  return (
    <Modal transparent>
      <Pressable style={styles.backdrop} onPress={onClose} />
      {/* <View style={styles.container}>
        <View style={styles.headerContainer}>
          <Text fontName="txtMd" fontColor="mono.realBlack">
            {title}
          </Text>
        </View>
        <View style={styles.headerDir} />
        <View style={styles.bodyContainer}>
          <DateRangePickerV2
            type="date"
            min={minDate !== undefined ? moment(minDate).format('YYYY-MM-DD') : undefined}
            max={maxDate !== undefined ? moment(maxDate).format('YYYY-MM-DD') : undefined}
            initialStart={initialStart}
            initialTime={initialTime}
            initialIsTime={initialIsTime}
            onChangeStart={onChangeStart}
            onChangeTime={onChangeTime}
            onChangeIsTime={onChangeIsTime}
          />
        </View>
        <View style={styles.footerDir} />
        <View style={styles.footerContainer}>
          <Pressable style={styles.deleteButton} onPress={onDelete}>
            <Text fontName="txtSm" fontColor="gray.g800">
              {t('workcardtododatemodal.datepicker.delete')}
            </Text>
          </Pressable>
        </View>
      </View> */}
      <View style={styles.container}>
        <DateRangePickerV2
          type="date"
          min={minDate !== undefined ? moment(minDate).format('YYYY-MM-DD') : undefined}
          max={maxDate !== undefined ? moment(maxDate).format('YYYY-MM-DD') : undefined}
          initialStart={initialStart}
          initialTime={initialTime}
          initialIsTime={initialIsTime}
          onChangeStart={onChangeStart}
          onChangeTime={onChangeTime}
          onChangeIsTime={onChangeIsTime}
        />
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: COLOR.dimmed.a60,
  },
  container: {
    width: 300,
    // height: 500,
    backgroundColor: COLOR.mono.white,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    ...Platform.select({
      web: {
        margin: 'auto',
      },
      native: {
        top: Dimensions.get('window').height / 2 - 250,
        left: Dimensions.get('window').width / 2 - 150,
      },
    }),
  },
  headerContainer: {
    marginTop: 8,
    height: 42,
    paddingTop: 8,
    paddingLeft: 10,
    paddingRight: 20,
  },
  bodyContainer: {
    flex: 1,
  },
  footerContainer: {
    height: 36,
    marginTop: 8,
    marginBottom: 12,
  },
  deleteButton: {
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
  headerDir: {
    borderTopColor: COLOR.gray.g100,
    borderTopWidth: 1,
  },
  footerDir: {
    borderTopColor: COLOR.gray.g200,
    borderTopWidth: 1,
  },
})

export default DatePicker
