import * as React from 'react'
import { Dimensions, Modal, Platform, Pressable, StyleSheet, Text, View, ViewStyle } from 'react-native'

import { COLOR, FONT, IColors, getRealColor } from '@rocket-mono/libs'
import { useTranslation } from 'react-i18next'
import { Icon } from '@rocket/atoms'

export type Axis = {
  x: number
  y: number
}

interface Props {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  containerStyle?: ViewStyle
  axis?: Axis
  labelColor: IColors | undefined
  onLabelColor: (color: IColors) => void
}

const styles = StyleSheet.create({
  rootContainer: {
    // position: 'absolute',
    // top: 0,
    // left: 0,
    // width: Dimensions.get('screen').width,
    // height: Dimensions.get('screen').height,
    // zIndex: 99,
    flex: 1,
  },
  backContainer: {
    flex: 1,
  },
  container: {
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    backgroundColor: COLOR.mono.white,
    borderRadius: 8,
    width: 215,
    position: 'absolute',
    top: 0,
    left: 0,
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 5,
  },
  line: {
    borderTopWidth: 1,
    borderTopColor: COLOR.mono.gray,
  },
  button: {
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  buttonText: {
    ...FONT.textRegular,
  },
  labelContainer: {
    width: 30,
    height: 30,
    margin: 5,
    borderRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noneLabelContainer: {
    padding: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noneLabelText: {
    ...FONT.textRegular,
    fontSize: 10,
    lineHeight: 12,
    color: getRealColor('mono.darkGray'),
  },
  checkContainer: {
    width: 18,
    height: 18,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: getRealColor('mono.white'),
    justifyContent: 'center',
    alignItems: 'center',
  },
})

function LabelPopup({
  isOpen,
  setIsOpen,
  containerStyle,
  axis = { x: 0, y: 0 /*width: 0, height: 0, top: 0, left: 0 */ },
  labelColor,
  onLabelColor,
}: Props) {
  const labels: IColors[] = [
    'mono.realBlack',
    'sub.green',
    'sub.darkPurple',
    'sub.ocean',
    'sub.pink',
    'sub.paleYellow',
    'sub.purple',
    'sub.lightGreen',
    'sub.paleBlue',
    'sub.paleSky',
    'sub.lightYellow',
    'sub.darkNavy',
    'sub.lightBlue',
    'main.blue',
  ]
  const useAxis = React.useMemo(() => {
    return axis
  }, [axis])
  const { t } = useTranslation()

  return (
    <Modal style={styles.rootContainer} transparent visible={isOpen}>
      <Pressable style={styles.backContainer} onPress={() => setIsOpen(false)} />
      <View
        testID="addPopup.view"
        style={[
          styles.container,
          containerStyle,
          Platform.select({
            ios: {
              shadowColor: 'rgb(50, 50, 50,)',
              shadowOpacity: 0.2,
              shadowRadius: 4,
              shadowOffset: {
                height: 0,
                width: 0,
              },
            },
            android: {
              elevation: 5,
            },
            web: {
              boxShadow: `0px 0px 5px rgba(50, 50, 50, 0.1)`,
            },
          }),
          {
            top: useAxis.y + 30 + 5,
            left: Dimensions.get('window').width - 150 > useAxis.x ? useAxis.x : Dimensions.get('window').width - 180,
          },
        ]}
      >
        <Pressable
          style={[
            styles.labelContainer,
            styles.noneLabelContainer,
            {
              backgroundColor: getRealColor('transparent'),
            },
          ]}
          onPress={() => {
            onLabelColor('transparent')
          }}
        >
          <Text style={styles.noneLabelText}>{t(`popup.label1`)}</Text>
          <Text style={styles.noneLabelText}>{t(`popup.label2`)}</Text>
        </Pressable>
        {labels.map((label, idx) => (
          <Pressable
            key={`label-${idx}`}
            style={[
              styles.labelContainer,
              {
                backgroundColor: getRealColor(label),
              },
            ]}
            onPress={() => {
              onLabelColor(label)
              setIsOpen(false)
            }}
          >
            {label === labelColor && (
              <View style={styles.checkContainer}>
                <Icon name="check" size={12} color="mono.white" />
              </View>
            )}
          </Pressable>
        ))}
      </View>
    </Modal>
  )
}

export default React.memo(LabelPopup)
