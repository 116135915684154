import React from 'react'
import { StyleSheet, View } from 'react-native'

import { useTranslation } from 'react-i18next'

import { Switch } from '@rocket-atoms/toggle'
import { Text } from '@rocket-atoms/text'

interface Props {
  isSwitchOn: boolean
  onChangeSwitchOn?: (is: boolean) => void
  onChangeCode?: (code: string) => void
  defaultIndex: number
}

const ActivitySearchSectionSortChatlist: React.FC<Props> = (
  { isSwitchOn, onChangeSwitchOn }
) => {
  const { t } = useTranslation()
  return (
    <View style={styles.container}>
      <View></View>
      <View style={styles.switchArea}>
        <Switch activated={isSwitchOn} size={'sm'} onChange={(is) => onChangeSwitchOn && onChangeSwitchOn(is)} />
        <Text fontName="txtXsMedium" fontColor="gray.g800" style={styles.switchLabel}>
          {t('search.result.includearchive')}
        </Text>
      </View>
    </View>
  )
}

export default ActivitySearchSectionSortChatlist

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: 20,
    marginBottom: 8,
  },
  dropdownArea: {
    width: '30%',
  },
  switchArea: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  switchLabel: {
    marginLeft: 4,
  },
})
