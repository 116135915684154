import React, { useMemo, useState } from 'react'
import { Pressable, StyleSheet, View, ViewStyle } from 'react-native'
import { Text } from '@rocket-atoms/text'
import { COLOR, getRealColor } from '@rui/foundations'
import { RNSvg } from '@rocket/atoms'
import { FAIcon, XEIcon } from '@rocket-atoms/icon'

import { faList, faPaperclip, faTableColumns, faUser } from '@fortawesome/free-solid-svg-icons'
import { faCommentDots } from '@fortawesome/free-regular-svg-icons'
import { useTranslation } from 'react-i18next'

export interface sectionType {}

interface Props {
  style?: ViewStyle
  type: 'WORK' | 'CHAT' | 'MESSAGE' | 'CARD' | 'FILE' | 'USER'
  onPressMore?: () => void
  children?: React.ReactNode
}

const ActivitySearchList: React.FC<Props> = ({ style, type, onPressMore, children }) => {
  const { t } = useTranslation()
  const [isHover, setIsHover] = useState(false)

  const { icon, label } = useMemo(() => {
    let icon, label

    if (type === 'WORK') {
      icon = 'apps'
      label = t('workmodeledit.workname')
    } else if (type === 'CHAT') {
      icon = faList
      label = t('search.result.chats')
    } else if (type === 'MESSAGE') {
      icon = faCommentDots
      label = t('screen.chats.title')
    } else if (type === 'CARD') {
      icon = faTableColumns
      label = t('search.result.cards')
    } else if (type === 'FILE') {
      icon = faPaperclip
      label = t('board.card.attachfiles')
    } else if (type === 'USER') {
      icon = faUser
      label = '유저명'
    }

    return { icon, label }
  }, [type])

  return (
    <View style={[styles.container, style, !onPressMore && { flex: 1 }]}>
      <View style={[styles.title, styles.elWrap]}>
        {icon === faTableColumns ? (
          <RNSvg.Svg width="12" height="12" viewBox="0 0 16 16" fill="none">
            <RNSvg.Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.87381 2.295V6.14269H14.1262V2.295H1.87381ZM0.988095 0.816406C0.580457 0.816406 0.25 1.11731 0.25 1.48849V6.9492C0.25 7.32038 0.580456 7.62128 0.988095 7.62128H15.0119C15.4195 7.62128 15.75 7.32038 15.75 6.9492V1.48849C15.75 1.11731 15.4195 0.816406 15.0119 0.816406H0.988095Z"
              fill={getRealColor('gray.g600')}
            />
            <RNSvg.Circle cx="3.65258" cy="4.21813" r="1.13415" fill={getRealColor('gray.g600')} />
            <RNSvg.Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.87381 9.85554V13.7032H14.1262V9.85554H1.87381ZM0.988095 8.37695C0.580457 8.37695 0.25 8.67786 0.25 9.04904V14.5097C0.25 14.8809 0.580456 15.1818 0.988095 15.1818H15.0119C15.4195 15.1818 15.75 14.8809 15.75 14.5097V9.04904C15.75 8.67786 15.4195 8.37695 15.0119 8.37695H0.988095Z"
              fill={getRealColor('gray.g600')}
            />
            <RNSvg.Circle cx="3.65258" cy="11.7787" r="1.13415" fill={getRealColor('gray.g600')} />
          </RNSvg.Svg>
        ) : typeof icon === 'string' ? (
          <XEIcon name={'apps'} size={12} color="gray.g600" />
        ) : (
          <FAIcon iconName={icon} size={'xs'} nativeSize={10} color={'gray.g600'} />
        )}
        <Text fontName="txtXsMedium" fontColor="gray.g600" style={styles.name}>
          {label}
        </Text>
      </View>
      <View style={[styles.list, !onPressMore && { flex: 1 }]}>{children}</View>
      {onPressMore && (
        <View style={styles.elWrap}>
          <Pressable
            onPress={onPressMore}
            onHoverIn={() => setIsHover(true)}
            onHoverOut={() => setIsHover(false)}
            style={[styles.moreButton, isHover && { backgroundColor: COLOR.gray.g100 }]}
          >
            <Text fontName="txtXsMedium" fontColor="gray.g450">
              + {label} {t('explainworkmodel.more')}
            </Text>
          </Pressable>
        </View>
      )}
    </View>
  )
}

export default ActivitySearchList

const styles = StyleSheet.create({
  container: {
    marginTop: 4,
    paddingTop: 8,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g100,
  },
  elWrap: {
    paddingHorizontal: 20,
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    marginLeft: 4,
  },
  list: {
    paddingTop: 8,
  },
  moreButton: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 24,
    borderRadius: 4,
    backgroundColor: COLOR.gray.g050,
  },
})
