import { useContext } from 'react'

import { ContextProps } from './types'
import { ThemeContext } from '.'

export function useTheme() {
  const context = useContext<ContextProps | undefined>(ThemeContext)

  if (context === undefined) throw new Error('There must be a ThemeProvider as Ancestor of all Theme Hooks and HOCs')
  return context
}
