import React from 'react'
import Input from '../../ChatInput/Input'

/**
 * DiscussInput은 Input의 파운데이션화 작업이 이루어지기 전까지,
 * 코드의 파편화를 막기 위해 임시로 만들어진 컴포넌트입니다.
 * ChatInput의 Input 컴포넌트를 그대로 사용합니다.
 */
interface Props {
  value: string
  onSubmit: (text: string) => void
  // onKeyPress
  onChangeText: (text: string) => void
  onCancel?: () => void
  style: any
}
const DiscussInput: React.FC<Props> = ({
  value,
  onCancel,
  onSubmit,
  onChangeText,
  style,
}) => {
  const inputRef = React.useRef(null)
  return (
    <Input
      key={onCancel ? 'modify' : 'normal'}
      inputRef={inputRef}
      defaultValue={value}
      onChangeText={(text) => onChangeText(text)}
      clearWhenSubmit={true}
      onPressInputEnterKey={(text) => {
        console.debug({ text })
        onSubmit(text)
      }}
      style={[style]}
    />
  )
}

export default DiscussInput
