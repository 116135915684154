import React from 'react'
import { Pressable, StyleSheet } from 'react-native'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCircleUser } from '@fortawesome/free-solid-svg-icons'
import { FAIcon } from '@rocket-atoms/icon'
import { Text } from '@rocket-atoms/text'
import { COLOR, FONT } from '@rui/foundations'

interface Props {
  iconName?: IconProp
  count?: string | number
  tooltipText?: string
  variant?: string
}
const OptionCountLabel: React.FC<Props> = ({ iconName = faCircleUser, count, variant }) => {
  return (
    // <Tooltip gab={0} darkType={true} direction={'bottom'} text={tooltipText}>
    //   {(props) => (
    <Pressable
      // {...props}
      style={[styles.wrap, variant === 'close' && { borderColor: COLOR.gray.g200 }]}
    >
      <FAIcon iconName={iconName} size={'xs'} nativeSize={12} color={'gray.g400'} />
      {count && <Text style={styles.text}>{count}</Text>}
    </Pressable>
    //   )}
    // </Tooltip>
  )
}

export default OptionCountLabel

const styles = StyleSheet.create({
  wrap: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 22,
    minHeight: 22,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: COLOR.gray.g100,
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  text: {
    ...FONT.txtXsMedium,
    color: COLOR.gray.g800,
    marginLeft: 4,
  },
})
