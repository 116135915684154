import React, { useState } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { IconButton, Select, SelectType } from '@rocket-mono/foundations'
import { faRocket, faUserPlus, faCaretUp, faCaretDown, faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { FONT, COLOR } from '@rui/foundations'
import { FAIcon } from '@rocket/atoms'
import { SkeletonBar, SkeletonCircle } from '@rocket/skeleton'

interface Props {
  skeleton?: boolean
  mobileView?: boolean
  type: 'MY' | 'TEAM'
  title: string
  isFold: boolean
  onPressFold: (arg0: boolean) => void
  learnMoreList: SelectType[]
}
const WorkspaceTitleTab: React.FC<Props> = ({
  skeleton,
  type,
  title,
  isFold,
  onPressFold,
  learnMoreList,
  mobileView,
}) => {
  const [isLearnMore, setIsLeanMore] = useState(false)

  return (
    <View style={[styles.flexBox, type === 'TEAM' && styles.containerTeam]}>
      <View style={[styles.flexBox, styles.nameArea]}>
        {skeleton ? (
          <>
            <View style={[styles.workspaceIcon, { backgroundColor: COLOR.transparent.base }]}>
              <SkeletonCircle width={22} height={22} color={'darkmode.warm700'} />
            </View>
            <SkeletonBar width={230} height={15} color={'darkmode.warm700'} />
          </>
        ) : (
          <>
            {/* 워크스페이스 아이콘: 정의 전, 추후 교체! */}
            <View style={[styles.workspaceIcon, mobileView && { width: 20, height: 20 }]}>
              <View
                style={[
                  { width: 16, height: 16, marginTop: 1, marginLeft: -1 },
                  mobileView && { width: 12, height: 12 },
                ]}
              >
                <FAIcon iconName={faRocket} color={'opacity.blk70'} />
              </View>
            </View>
            {/* 까지 */}
            <Text style={[styles.text, mobileView && { ...FONT.H7Bold }]} ellipsizeMode="tail">
              {title}
            </Text>
          </>
        )}
      </View>

      {!skeleton && (
        <View style={styles.buttonArea}>
          {/* {mobileView && (
            <View style={styles.buttonAlign}>
              <IconButton darkmode type={'ROUNDSQUARE'} iconName={faPlus} size={'large'} iconColor={'gray.g450'} />
            </View>
          )} */}
          {type === 'TEAM' && (
            <View style={styles.buttonAlign}>
              <IconButton darkmode type={'ROUNDSQUARE'} iconName={faUserPlus} size={'large'} iconColor={'gray.g450'} />
            </View>
          )}
          <View style={styles.buttonAlign}>
            <IconButton
              darkmode
              type={'ROUNDSQUARE'}
              iconName={isFold ? faCaretUp : faCaretDown}
              size={'large'}
              iconColor={'gray.g450'}
              onPress={() => onPressFold(!isFold)}
            />
          </View>
          {type === 'TEAM' && (
            <View style={[styles.buttonAlign, styles.learnMoreButton]}>
              <IconButton
                darkmode
                type={'ROUNDSQUARE'}
                iconName={faEllipsis}
                size={'large'}
                iconColor={'gray.g450'}
                onPress={() => setIsLeanMore(!isLearnMore)}
              />
              {isLearnMore && (
                <View style={styles.learnButtonPosition}>
                  <Select list={learnMoreList} visible={isLearnMore} setVisible={setIsLeanMore} />
                </View>
              )}
            </View>
          )}
        </View>
      )}
    </View>
  )
}

export default WorkspaceTitleTab

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  containerTeam: {
    paddingTop: 20,
    borderTopWidth: 1,
    borderColor: COLOR.gray.g900,
  },
  nameArea: {
    flex: 1,
  },
  workspaceIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 25,
    height: 25,
    borderRadius: 50,
    backgroundColor: COLOR.sub.darkPurple,
    marginRight: 4,
  },
  text: {
    ...FONT.H6Bold,
    color: COLOR.darkmode.cool300,
  },
  buttonArea: {
    flexDirection: 'row',
  },
  buttonAlign: {
    marginLeft: 8,
  },
  learnMoreButton: {
    position: 'relative',
  },
  learnButtonPosition: {
    position: 'absolute',
    top: 20,
    right: 0,
    zIndex: 99,
  },
})
