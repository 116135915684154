import * as React from 'react'
import { StyleSheet, View, Text, ViewStyle, Pressable } from 'react-native'
import { COLOR, FONT } from '@rocket-mono/libs'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FAIcon } from '@rocket-atoms/icon'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

export type DropdownItem = {
  id: number
  title: string
  code: string
}

export type DropdownGroupItem = {
  group: {
    id: number
    title: string
    code: string
  }[]
}
interface Props {
  text?: string | JSX.Element
  code?: string
  border?: boolean
  isOpen: boolean
  onOpen?: (open: boolean) => void
  onbuttonSize?: (y: { width: number; height: number }) => void
  onOffsetY?: (y: number) => void
  dropdownList: DropdownItem[]
  onDrop?: (dropped: boolean) => void
  containerStyle?: ViewStyle
  isEnable?: boolean
  initTitle?: string
  notification?: boolean
  beforeIcon?: IconProp
  style?: ViewStyle
  txtStyle?: ViewStyle
  divide?: boolean
}
/**
 * @deprecated
 */
function DropdownButton({
  text,
  code,
  border = true,
  isOpen,
  onOpen,
  onbuttonSize,
  onOffsetY,
  dropdownList,
  onDrop,
  containerStyle,
  isEnable,
  initTitle,
  notification,
  beforeIcon,
  style,
  txtStyle,
  divide,
}: Props) {
  const { t } = useTranslation()
  const mainButtonRef = React.useRef<View>(null)
  const [isHover, setIsHover] = React.useState(false)
  const useSelectedItem = React.useMemo(() => {
    const selectItems = dropdownList.filter((item) => item.code === code)
    if (selectItems.length > 0) {
      return selectItems[0].title
    } else {
      return initTitle ? initTitle : t('dropdown.initcode')
    }
  }, [dropdownList, code, t])
  return (
    <View style={[styles.container, containerStyle, { opacity: isEnable ? 1 : 0.5 }]}>
      <Pressable
        ref={mainButtonRef}
        onLayout={() => {
          if (mainButtonRef.current && mainButtonRef.current.measure) {
            mainButtonRef.current.measure((_x, _y, _width, _height, _pageY) => {
              onbuttonSize && onbuttonSize({ width: _width, height: _height })
              onOffsetY && onOffsetY(_pageY)
            })
          }
        }}
        style={[
          styles.dropbutton,
          border && { borderWidth: 1 },
          style,
          !divide && isOpen
            ? {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }
            : undefined,
          isHover && { borderColor: COLOR.gray.g450 },
          notification && { borderColor: COLOR.primary.blue500 },
        ]}
        onPress={() => {
          if (mainButtonRef.current && mainButtonRef.current.measure) {
            mainButtonRef.current.measure((_x, _y, _width, _height, _pageY) => {
              onbuttonSize && onbuttonSize({ width: _width, height: _height })
              onOffsetY && onOffsetY(_pageY)
            })
          }
          if (isEnable) {
            onOpen && onOpen(!isOpen)
            onDrop && onDrop(!isOpen)
          }
        }}
        onHoverIn={() => setIsHover(true)}
        onHoverOut={() => setIsHover(false)}
      >
        <View style={{ flexDirection: 'row' }}>
          {beforeIcon && (
            <View style={{ marginTop: 2, marginRight: 4 }}>
              <FAIcon iconName={beforeIcon} size={'xs'} color={notification ? 'primary.blue500' : 'gray.g800'} />
            </View>
          )}
          <Text style={[styles.buttonText, txtStyle]}>{text ? text : useSelectedItem}</Text>
        </View>
        <View style={{ marginTop: 2 }}>
          <FAIcon iconName={isOpen ? faAngleUp : faAngleDown} color="mono.black" size={'xs'} />
        </View>
      </Pressable>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  dropbutton: {
    backgroundColor: COLOR.mono.white,
    borderColor: COLOR.gray.g300,
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
    paddingHorizontal: 8,
    height: 34,
  },
  buttonText: {
    ...FONT.txtSm,
    marginTop: -2,
    color: COLOR.mono.black,
  },
})

export default React.memo(DropdownButton)
