import * as React from 'react'
import { Pressable, StyleSheet, View, Text } from 'react-native'

import { IconDefinition, faCheck, faCircle, faGear, faPen } from '@fortawesome/free-solid-svg-icons'
import { ClickOutside } from '@rocket-mono/foundations'
import { FONT, COLOR, IColors } from '@rocket-mono/libs'
import { Avatar } from '@rui/atoms'
import { useTranslation } from 'react-i18next'
import { UserState } from '../../types'
import SelectBox from '../SelectBox'

type LayoutTopProfileItemType = {
  text: string
  color?: IColors
  beforeIcon: IconDefinition
  beforeIconColor: IColors
  afterIcon: IconDefinition
  afterIconColor: IColors
  onPress: () => void
}

interface LayoutTopProfileType {
  userName: string
  userState: UserState
  userImageUrl: string
  updateUserState: (state: UserState) => void
  onProfileSetting?: () => void
}

const LayoutTopProfile = ({
  userName,
  userState,
  userImageUrl,
  updateUserState,
  onProfileSetting,
}: LayoutTopProfileType) => {
  const { t } = useTranslation()
  const [isHover, setIsHover] = React.useState(false)
  const [isPop, setIsPop] = React.useState(false)
  const [isStatusPop, setIsStatusPop] = React.useState(false)

  const [statusColor, setStatusColor] = React.useState('')

  const [buttonHeight, setButtonHeight] = React.useState(0)
  const [statusOffset, setStatusOffset] = React.useState(0)

  React.useEffect(() => {
    setStatusColor(
      userState === 'Active'
        ? 'status.active'
        : userState === 'Busy'
        ? 'status.busy'
        : userState === 'Off'
        ? 'status.off'
        : 'status.off',
    )
  }, [userState])

  const items: LayoutTopProfileItemType[] = React.useMemo(() => {
    return [
      {
        text: 'Active',
        color: userState === 'Active' ? 'primary.blue500' : undefined,
        beforeIcon: faCircle,
        beforeIconColor: 'status.active',
        afterIcon: faCheck,
        afterIconColor: userState === 'Active' ? 'primary.blue500' : 'transparent.base',
        onPress: () => {
          updateUserState('Active')
          setIsStatusPop(false)
          setIsPop(false)
        },
      },
      {
        text: 'Busy',
        color: userState === 'Busy' ? 'primary.blue500' : undefined,
        beforeIcon: faCircle,
        beforeIconColor: 'status.busy',
        afterIcon: faCheck,
        afterIconColor: userState === 'Busy' ? 'primary.blue500' : 'transparent.base',
        onPress: () => {
          updateUserState('Busy')
          setIsStatusPop(false)
          setIsPop(false)
        },
      },
      {
        text: 'Off',
        color: userState === 'Off' ? 'primary.blue500' : undefined,
        beforeIcon: faCircle,
        beforeIconColor: 'status.off',
        afterIcon: faCheck,
        afterIconColor: userState === 'Off' ? 'primary.blue500' : 'transparent.base',
        onPress: () => {
          updateUserState('Off')
          setIsStatusPop(false)
          setIsPop(false)
        },
      },
    ]
  }, [updateUserState, userState])

  const profileState = React.useMemo(() => {
    switch (userState) {
      case 'Active':
        return 'active'
      case 'Busy':
        return 'busy'
      case 'Off':
        return 'off'
      default:
        return 'off'
    }
  }, [userState])

  return (
    <View style={styles.wrap}>
      <Pressable
        onHoverIn={() => setIsHover(true)}
        onHoverOut={() => setIsHover(false)}
        onPress={() => setIsPop(!isPop)}
        style={[styles.wrapButton, isHover && { backgroundColor: COLOR.gray.g050 }]}
        onLayout={(e) => {
          setButtonHeight(e.nativeEvent.layout.height)
        }}
      >
        <Avatar
          size="small"
          userName={userImageUrl ? undefined : userName}
          profileUrl={userImageUrl}
          state={profileState}
          stateIconSize={12}
        />
      </Pressable>

      <ClickOutside
        onClickOutside={() => {
          setIsPop(false), setIsStatusPop(false)
        }}
      >
        {isPop && (
          <View
            // ref={popRef}
            style={[styles.accountPop, { zIndex: 999, top: buttonHeight, right: 220 }]}
          >
            <SelectBox style={{ minWidth: 220 }}>
              <SelectBox.AnyItem
                onLayout={(e) => {
                  setStatusOffset(e.nativeEvent.layout.height)
                }}
              >
                <View style={styles.usernameArea}>
                  <Avatar size="medium" profileUrl={userImageUrl} state={profileState} stateIconSize={14} />
                  <Text style={styles.userName} numberOfLines={1} ellipsizeMode="tail">
                    {userName}
                  </Text>
                </View>
              </SelectBox.AnyItem>
              <SelectBox.DividingLine />
              <SelectBox.Item
                text={userState}
                beforeIcon={faCircle}
                beforeIconColor={statusColor as IColors}
                afterIcon={faPen}
                onPress={() => {
                  setIsStatusPop(!isStatusPop)
                }}
              />
              <SelectBox.DividingLine />
              <SelectBox.Item
                text={t('layouttopprofile.setting')}
                beforeIcon={faGear}
                onPress={() => {
                  // console.log('계정 설정 탭')
                  onProfileSetting && onProfileSetting()
                }}
              />
            </SelectBox>
          </View>
        )}
        {isPop && isStatusPop && (
          <View
            // ref={popRef}
            style={[styles.accountPop, { zIndex: 999, top: statusOffset + buttonHeight, right: 156 * 2 + 4 }]}
          >
            <SelectBox>
              {items.map((aItem, index) => (
                <SelectBox.Item key={index} {...aItem} />
              ))}
            </SelectBox>
          </View>
        )}
      </ClickOutside>
    </View>
  )
}

export default LayoutTopProfile

const styles = StyleSheet.create({
  wrap: {
    position: 'relative',
    alignSelf: 'flex-start',
  },
  wrapButton: {
    alignSelf: 'flex-start',
    borderRadius: 8,
    padding: 4,
  },
  usernameArea: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  userName: {
    marginLeft: 8,
    ...FONT.txtSmBold,
    color: COLOR.gray.g900,
  },
  accountPop: {
    position: 'absolute',
  },
})
