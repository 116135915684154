import { useContext } from 'react'

import { Astro } from '@rocket/astronaut'
import AstroNav from '@rocket/astronaut-nav'

import { ContextProps } from './types'
import { AstroContext } from '.'

export function useAstro() {
  const context = useContext<ContextProps | undefined>(AstroContext)

  if (context === undefined) throw new Error('There must be a AstroProvider as Ancestor of all Astro Hooks and HOCs')

  const { option, token } = context
  const astro: Astro = new Astro(option)
  astro.token(token)
  // astro.readMe()

  const astroNav = new AstroNav({
    workUrl: option.workLegacyUrl || '',
    talkUrl: option.talkApiLegacyUrl || '',
    talkApiUrl: option.talkApiLegacyUrl || '',
  })

  return { astro, astroNav, option, token }
}
