import type React from 'react'

export function getTextFromFormEvent(e: React.FormEvent<HTMLDivElement>) {
  return (e.target as HTMLDivElement).innerText
}

export function clearTextInput(ref: React.RefObject<HTMLDivElement>) {
  if (ref.current) ref.current.innerText = ''
}

export function getTextFromTextInput(e: React.RefObject<HTMLDivElement>) {
  return e.current?.innerText
}
