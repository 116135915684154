import { DropdownButton } from '@rocket-atoms/dropdown'
import { SelectorBoard } from '@rocket-molecules/popselector'
import { COLOR, ClickOutside, FONT } from '@rocket-mono/libs'
import { BoardLabel } from '@rocket/atoms'
import { Channel } from '@rocket/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { useRule } from '../../RuleScreen/hooks'
import { commonStyles } from '../style'

const zIndex = 100

interface Props {
  selectedApplyBoard?: string
  unArchiveBoardList?: Channel[]
  archiveBoardList?: Channel[]
  selectedBoardList: string[]
  onSelectedBoardList: (list: string[]) => void
}

const FlowRuleBoardView: React.FC<Props> = ({
  selectedApplyBoard,
  unArchiveBoardList,
  archiveBoardList,
  selectedBoardList,
  onSelectedBoardList,
}) => {
  const { t } = useTranslation()
  const [activated, setActivated] = React.useState(false)
  const [labelWrapHover, setLabelWrapHover] = React.useState(false)


  const { getLabel, elementList } = useRule()

  const boardList = React.useMemo(() => {
    if (!unArchiveBoardList || !archiveBoardList) return []
    return [
      ...unArchiveBoardList
        .filter((board) => (selectedApplyBoard ? selectedApplyBoard !== board.id : true))
        .map((board) => {
          const element = elementList.find((e) => e.id === board.projectElementId)
          return {
            id: Number(board.id),
            title: board.roomName,
            code: board.id,
            isArchive: false,
            beforeBoardLabel: getLabel({ isWFP: true, element }),
          }
        }),
      ...archiveBoardList
        .filter((board) => (selectedApplyBoard ? selectedApplyBoard !== board.id : true))
        .map((board) => {
          const element = elementList.find((e) => e.id === board.projectElementId)
          return {
            id: Number(board.id),
            title: board.roomName,
            code: board.id,
            isArchive: true,
            beforeBoardLabel: getLabel({ isWFP: true, element }),
          }
        }),
    ]
  }, [unArchiveBoardList, archiveBoardList, elementList, selectedApplyBoard])

  const selected = React.useMemo(() => {
    return boardList.filter((b) => selectedBoardList.includes(b.code))
  }, [boardList, selectedBoardList])
  return (
    <View>
      <View style={styles.container}>
        <View style={styles.dropButtonContainer}>
        <Pressable 
          onPress={()=> setActivated(true)}
          onHoverIn={()=>setLabelWrapHover(true)}
          onHoverOut={()=>setLabelWrapHover(false)}
          style={[commonStyles.labelWrap, labelWrapHover && commonStyles.labelWrapHover]}
        >
            <View style={styles.labelArea}>
              {selected.length !== 0 ? (
                <>
                  <BoardLabel
                    color={selected[0].beforeBoardLabel ? selected[0].beforeBoardLabel : 'sub.pink'}
                    size={16}
                  />
                  <Text style={styles.labelText}>{selected[0].title}</Text>
                  {selected.length - 1 > 0 && (
                    <Text style={{fontSize:12, marginLeft:4}}>
                      {t('rule.boards.others', { count: selected.length - 1 })}
                    </Text>
                  )}
                </>
              ) : (
                // <Text style={commonStyles.initText}>{t('rule.boards.selected', { count: selected.length })}</Text>
                <Text style={commonStyles.valueText}>{t('rule.boards.select')}</Text>
              )}
            </View>
          </Pressable>
          <Text style={styles.dropdownButtonSideText}>{t('rule.rules.toboard')}</Text>
        </View>
        {activated && (
          <ClickOutside onClickOutside={() => setActivated(false)}>
            <View style={{ position: 'absolute', zIndex }}>
              <SelectorBoard
                board={boardList}
                selectedItemList={selectedBoardList}
                width={300}
                onPressEdit={(selectedItemList) => {
                  onSelectedBoardList(selectedItemList)
                  setActivated(false)
                }}
                onClose={() => setActivated(false)}
              />
            </View>
          </ClickOutside>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  dropButtonContainer: {
    flexDirection: 'row',
  },
  labelArea: {
    overflow: 'hidden',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelText: {
    position: 'relative',
    ...FONT.txtXsMedium,
    color: COLOR.gray.g900,
    marginLeft: 4,
    zIndex: 2,
  },
  dropdownButtonSideText: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g800,
    marginLeft: 4,
  },
})

export default FlowRuleBoardView
