import * as React from 'react'

import Providers from './providers'
import WorkspaceView from './view'
import { Props } from './types'

const WorkspaceScreen: React.FC<Props> = ({ i18n, ...viewProps }) => {
  return (
    <Providers i18n={i18n}>
      <WorkspaceView i18n={i18n} {...viewProps} />
    </Providers>
  )
}

export default WorkspaceScreen

export { default as SearchInput } from './components/SearchInput'
export { default as SearchSuggestions } from './components/SearchSuggestions'
export { default as SearchSuggestionsItem } from './components/SearchSuggestionsItem'
export { default as WorkCard } from './components/WorkCard'
export { default as CreateWorkCard } from './components/WorkCard/CreateCard'
export { default as WorkCardList } from './components/WorkCardList'
export { default as GuideComment } from './components/GuideComment'
export { default as WorkspaceTitleTab } from './components/WorkspaceTitleTab'
export { default as WorkCardGroup } from './components/WorkCardGroup'
export { default as TemplateItem } from './components/TemplateItem'
export { default as TemplateGuide } from './components/TemplateGuide'
export { default as WorkcardBookmarkGroup } from './components/WorkcardBookmarkGroup'
export { default as WorkBoardSwitch } from './components/WorkBoardSwitch'
export { default as BottomNav } from './components/BottomNav'
export { default as BottomInfoModal } from './components/BottomInfoModal'