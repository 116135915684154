import { Avatar } from '@rui/atoms'
import { DropdownListItem } from '@rocket-atoms/dropdown'
import { Tag } from '@rocket-atoms/tag'
import { SearchTextField } from '@rocket-atoms/text'
import { Combobox } from '@rui/molecules'
import { COLOR, FONT } from '@rocket-mono/libs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, Text, View } from 'react-native'
import MemberList from './MemberList'
import { Props } from './types'

/**
 * @deprecated
 */
const SelectorUser = ({ containerStyle, tagListStyle, listMember, visible, onClose, onChangeUsers }: Props) => {
  const { t } = useTranslation()
  const inputRef = React.useRef(null)
  const [searchText, setSearchText] = React.useState('')

  const selectedUser = React.useMemo(() => {
    if (!listMember) return undefined
    return listMember.filter((item) => item.selected)
  }, [listMember])

  const previewUsers = React.useMemo(() => {
    if (!listMember) return undefined
    return listMember.filter((item) => item.name.indexOf(searchText) > -1)
  }, [listMember, searchText])

  return (
    <View>
      <View style={[styles.container, containerStyle]}>
        <Combobox visible={visible ? visible : true} width={'100%'}>
          <Combobox.Header isDirect>
            <View style={styles.headerContainer}>
              <View>
                <Text style={styles.text}>
                  {t('userselected.prefixassign')}
                  <Text style={{ color: COLOR.main.red }}>{selectedUser ? selectedUser.length : 0}</Text>
                  {t('userselected.suffixassign')}
                </Text>
              </View>
              <View style={[styles.tagList, tagListStyle]}>
                {selectedUser ? (
                  selectedUser.map((item, idx) => (
                    <View style={{ marginVertical: 2, marginRight: 4 }} key={`selectedMemberList-${idx}`}>
                      <Tag
                        name={item.name}
                        size={'MEDIUM'}
                        onPress={() => {
                          console.log('?')
                        }}
                        onRemove={() => {
                          if (onChangeUsers) {
                            if (!listMember) return undefined
                            const newList = listMember.map((user) => {
                              if (user.id === item.id) {
                                return { ...user, selected: !user.selected }
                              } else {
                                return user
                              }
                            })
                            onChangeUsers(newList)
                          }
                        }}
                      >
                        <Avatar size={'xxsmall'} />
                      </Tag>
                    </View>
                  ))
                ) : (
                  <></>
                )}
              </View>
            </View>
          </Combobox.Header>
          <Combobox.Body>
            <View style={styles.body}>
              <SearchTextField
                value={searchText}
                inputRef={inputRef}
                placeholder={t('userselected.placeholder')}
                onChangeText={(text) => {
                  setSearchText(text)
                }}
              />
            </View>
            <ScrollView style={styles.scrollArea}>
              <DropdownListItem
                item={{ label: t('userselected.boardallmember'), code: '홀리쩆' }}
                itemType={'CHECKED'}
                itemVariant={'FILLED'}
                checked={listMember && selectedUser ? listMember.length === selectedUser.length : false}
                onChangeChecked={function () {
                  if (!listMember) return
                  if (!selectedUser) return
                  if (listMember.length === selectedUser.length) {
                    if (onChangeUsers) {
                      onChangeUsers(
                        listMember.map((item) => ({
                          ...item,
                          selected: false,
                        })),
                      )
                    }
                  } else {
                    if (onChangeUsers) {
                      onChangeUsers(
                        listMember.map((item) => ({
                          ...item,
                          selected: true,
                        })),
                      )
                    }
                  }
                }}
                ifAfterChecked
              />
              {previewUsers ? (
                previewUsers.map((item, idx) => (
                  <MemberList
                    item={item}
                    key={`memberList-${idx}`}
                    onChange={(user) => {
                      if (onChangeUsers) {
                        if (!listMember) return
                        const newList = listMember.map((item) => {
                          if (item.id === user.id) {
                            return { ...item, selected: !item.selected }
                          } else {
                            return item
                          }
                        })
                        onChangeUsers(newList)
                      }
                    }}
                  />
                ))
              ) : (
                <></>
              )}
            </ScrollView>
          </Combobox.Body>
        </Combobox>
      </View>
    </View>
  )
}

export default SelectorUser

const styles = StyleSheet.create({
  headerContainer: {
    width: '100%',
    padding: 8,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g150,
  },
  container: {
    width: 312,
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  body: {
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
  scrollArea: {
    overflow: 'scroll',
    maxHeight: 220,
    marginBottom: 4,
  },
  text: {
    ...FONT.txtXsMedium,
    color: COLOR.gray.g600,
    marginBottom: 8,
  },
  tagList: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    maxHeight: 110,
    overflow: 'scroll',
    alignContent: 'flex-start',
  },
  headerWrap: {
    width: '100%',
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g150,
  },
  bodyWrap: {
    width: '100%',
  },
})
