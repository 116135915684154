import { StyleSheet, View } from 'react-native'
import FileItem from './FileItem'

import { COLOR } from '@rocket-mono/libs'
import {
  useAstro,
  useAuth,
  usePreview,
  useWorkCard,
  useWorkChannel,
  useWorkFile,
  useWorkProject,
  type WorkFile,
} from '@rocket-mono/providers'
import moment from 'moment'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { CardRowGroup } from './CardRowGroup'

interface Props {
  files: WorkFile[]
  fileSource: 'MESSAGE' | 'MISSION' | 'MEETING' | 'DISCUSSION' | 'GATHERING' | 'TODO'
}
const FileViewer: React.FC<Props> = ({ files, fileSource }) => {
  const { t } = useTranslation()
  const { astro, option } = useAstro()
  const { isAnonymous, showLoginModal } = useAuth()
  const { currentProject, projectMemberList } = useWorkProject()
  const { currentChannel } = useWorkChannel()
  const { currentCard } = useWorkCard()
  const { openPreview } = usePreview()
  const { fileDownload } = useWorkFile()

  const EXT_MAP = {
    doc: ['txt', 'doc', 'hwp', 'mdj'],
    ppt: ['ppt', 'pptx'],
    xls: ['xls', 'xlsx'],
    zip: ['zip', 'rar', 'alz', '7z'],
    pdf: ['pdf'],
  }

  const fileType = useCallback((mimeType?: string, extension?: string) => {
    if (!mimeType) return ''
    if (!extension) return ''
    if (mimeType.startsWith('image')) {
      return 'preview'
    } else if (EXT_MAP.doc.includes(extension)) {
      return 'doc'
    } else if (EXT_MAP.ppt.includes(extension)) {
      return 'ppt'
    } else if (EXT_MAP.xls.includes(extension)) {
      return 'xls'
    } else if (mimeType.startsWith('video')) {
      return 'video'
    } else if (EXT_MAP.zip.includes(extension)) {
      return 'zip'
    } else if (EXT_MAP.pdf.includes(extension)) {
      return 'pdf'
    } else {
      return extension
    }
  }, [])

  const getProjectUserName = useCallback(
    (userId: string, userName: string) => {
      if (projectMemberList === null) return ''
      const member = projectMemberList.find((member) => member.userId === userId)
      return member ? member.name : userName ? userName : ''
    },
    [projectMemberList],
  )

  if (files.length === 0) return null
  return (
    <CardRowGroup
      iconName="file-o"
      title={t('board.card.attachfiles')}
      iconSize={16}
      titleSize={16}
      containerStyle={{
        marginBottom: 30,
      }}
      headerContainerStyle={{
        marginHorizontal: 0,
      }}
      iconContainerStyle={{
        alignItems: 'stretch',
      }}
      titleStyle={{
        fontWeight: '500',
      }}
    >
      <View style={{ flex: 1 }}>
        {files.map((fileItem, index) => {
          const extension = fileItem.originalName?.split('.').pop()
          const item = {
            fileUrl: `${fileItem.presignedUrl}`,
            fileType: fileType(fileItem.mimeType, extension),
            fileName: fileItem.originalName || '',
            fileSize: Number(fileItem.byteSize),
            date: moment(fileItem.createdAt).format('YYYY-MM-DD'),
            uploader: fileItem.user?.userName || '',
            onPressItem: () => {
              if (!currentProject) return
              if (!currentChannel) return
              if (!currentCard) return
              if (fileItem.mimeType && !fileItem.mimeType.includes('image') && !fileItem.mimeType.includes('pdf'))
                return
              const fileList = files
                .filter((file) => file.mimeType?.includes('image') || file.mimeType?.includes('pdf'))
                .map((file) => {
                  return {
                    id: file.id,
                    title: file.originalName || '',
                    uri: `${file.presignedUrl}`,
                    uploadDate: t('format.date.LLL', { date: new Date(file.createdAt) }),
                    uploaderName: getProjectUserName(String(file.user?.id), file.user?.userName || ''),
                    uploaderProfile: `${option.secureCdnUrl}/profile/${file.user?.userEmail}`,
                    mimeType: file.mimeType || '',
                    workType: currentProject.type.code,
                    workName: currentProject.title,
                    boardName: currentChannel.roomName,
                    cardName: currentCard.title,
                  }
                })
              const fileIndex = fileList.findIndex((file) => file.id === String(fileItem.id))
              openPreview(
                'card',
                fileItem.relatedDomain || '',
                fileItem.originalName || '',
                fileList,
                currentProject.type.code,
                currentProject.title,
                fileIndex,
              )
            },
            onPressDownload: () => {
              if (isAnonymous) {
                showLoginModal()
              } else {
                fileDownload(fileItem)
              }
            },
          }

          return (
            <View key={`file-item-${index}`} style={{ marginBottom: 8 }}>
              <FileItem {...item} small={false} access border fileSource={fileSource} />
            </View>
          )
        })}
      </View>
    </CardRowGroup>
  )
}

export default FileViewer

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    backgroundColor: COLOR.mono.white,
    borderRadius: 4,
    padding: 12,
  },
  itemWrap: {
    paddingTop: 8,
  },
  fileUploaderArea: {
    minHeight: 60,
  },
})
