import { Tooltip } from '@rui/atoms'
import { getBoxShadow } from '@rui/foundations'
import * as React from 'react'
import { StyleSheet, View } from 'react-native'

interface Props {
  isFavorite?: boolean
  isLatest?: boolean
  state?: '1' | '2' | '3' | '4'
  lastActivityAt: string
}
const WorkStateBar: React.FC<Props> = ({ isLatest, isFavorite, state, lastActivityAt }) => {
  return (
    <Tooltip
      // gab={0}
      // darkType={true}
      align="center"
      direction={'bottom'}
      text={lastActivityAt}
    >
      <View
        style={[
          styles.stateBar,
          isLatest && { height: 68 },
          isFavorite && { height: 68 },
          state === '1' && { backgroundColor: '#0456E8', ...getBoxShadow(0, 103, 255, 0.4, 6, 2, 0, 0) },
          state === '2' && { backgroundColor: '#2ADD56', ...getBoxShadow(42, 221, 86, 0.35, 6, 2, 0, 0) },
          state === '3' && { backgroundColor: '#FFE831', ...getBoxShadow(255, 255, 56, 0.35, 6, 2, 0, 0) },
          state === '4' && { backgroundColor: '#52626D' },
        ]}
      ></View>
    </Tooltip>
  )
}

export default WorkStateBar

const styles = StyleSheet.create({
  stateBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 4,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    height: 54,
  },
})
