import * as React from 'react'
import { WorkIconCSP } from './csp'
import { WorkIconDSP } from './dsp'
import { WorkIconWFP } from './wfp'
import { WorkIconTOP } from './top'

interface props {
  type: string
  size?: number
}

const WorkIconBase: React.FC<props> = ({ type, size }) => {
  if (type === 'DSP') return <WorkIconDSP size={size} />
  if (type === 'WFP') return <WorkIconWFP size={size} />
  if (type === 'CSP') return <WorkIconCSP size={size} />
  if (type === 'TOP') return <WorkIconTOP size={size} />
  else return <></>
}

const WorkIcon = Object.assign(WorkIconBase, {
  DSP: WorkIconDSP,
  WFP: WorkIconWFP,
  CSP: WorkIconCSP,
  TOP: WorkIconTOP,
})

export default WorkIcon
