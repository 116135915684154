import React, { useState } from 'react'
import { StyleSheet, Pressable, Text } from 'react-native'
import { COLOR, FONT } from '@rocket-mono/libs'
import { Tag } from '@rocket/atoms'
import { CardIcon } from '@rocket-atoms/icon'
import { CardSearchCardItemType } from './type'

interface Props extends CardSearchCardItemType {
  archive?: boolean
  selected?: boolean
}

const CardSearchCardItem: React.FC<Props> = ({
  archive,
  selected,
  cardType,
  cardId,
  cardTitle,
  closed,
  style,
  onPress,
}) => {
  const [isHover, setIsHover] = useState(false)

  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      style={[
        styles.container,
        (isHover || selected) && { backgroundColor: COLOR.gray.g050 },
        archive && { backgroundColor: COLOR.gray.g050 },
        style,
      ]}
      onPress={onPress}
    >
      <CardIcon variant={'ROUNDSQUARE'} size={'sm'} type={cardType} />
      <Text style={styles.noText}>#{cardId}</Text>
      {closed && <Tag name="closed" containerStyle={{ backgroundColor: COLOR.gray.g050, marginRight: 4 }} />}
      <Text numberOfLines={1} ellipsizeMode="tail" style={styles.titleText}>
        {cardTitle}
      </Text>
    </Pressable>
  )
}

export default CardSearchCardItem

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  noText: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g500,
    marginHorizontal: 4,
  },
  titleText: {
    flex: 1,
    ...FONT.txtMdMedium,
    color: COLOR.gray.g900,
  },
})
