import { NoContentsIcon } from '@rocket-atoms/icon'
import { COLOR, FONT } from '@rocket-mono/libs'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, Pressable, StyleSheet, Text, View } from 'react-native'

interface Props {
  discussionCount: number
  onPress: () => void
}

const DiscusstionInvisible: React.FC<Props> = ({ discussionCount, onPress }) => {
  const { t } = useTranslation()
  return (
    <View style={styles.container}>
      <View style={{ position: 'relative' }}>
        <NoContentsIcon type="chat" textSize="normal" iconWidth={60} />
        <View style={styles.iconArea}>
          <Text style={{ ...FONT.txt2XsBold, color: COLOR.mono.white }}>{discussionCount}</Text>
        </View>
      </View>
      <Text
        style={[
          styles.commonText,
          Platform.select({
            native: {
              ...FONT.txtXs,
            },
          }),
        ]}
      >
        {discussionCount} {t('discussion.nonmember.text')}
        <Pressable onPress={onPress}>
          <Text
            style={[
              styles.linkText,
              Platform.select({
                native: {
                  ...FONT.txtXsMedium,
                },
              }),
            ]}
          >
            {t('discussion.nonmember.login')}
          </Text>
        </Pressable>
      </Text>
    </View>
  )
}
export default DiscusstionInvisible

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: 20,
    paddingBottom: 40,
  },
  iconArea: {
    position: 'absolute',
    right: 0,
    bottom: 20,
    backgroundColor: COLOR.primary.blue500,
    borderRadius: 50,
    paddingHorizontal: 4,
    paddingTop: 1,
  },
  commonText: {
    ...FONT.txtSm,
    color: COLOR.gray.g600,
  },
  linkText: {
    ...FONT.txtSmMedium,
    color: COLOR.primary.blue500,
    textDecorationLine: 'underline',
  },
})
