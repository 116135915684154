import React, { ReactNode, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CardContentsProvider } from '@rui/screen-card'

import { usePreview, useWork, useWorkCard } from '@rocket-mono/providers'
import { useAstro, useWorkChannel, useWorkProject } from '@rocket-mono/providers'
import { uuid } from '@rui/utils'
import { LayoutChangeEvent, View } from 'react-native'

interface Props {
  children: ReactNode
}

export const WorkCardEditorProvider: React.FC<Props> = ({ children }) => {
  const { i18n } = useTranslation()

  const { astro } = useAstro()
  const { projectList } = useWork()
  const { currentProject } = useWorkProject()
  const { currentChannel } = useWorkChannel()
  const { currentCard } = useWorkCard()
  const { openPreview } = usePreview()

  const handleLoadFile = useCallback(
    (file: globalThis.File | Blob): Promise<string> => {
      console.log('handleLoadFile', file)

      const fileFormData = new FormData()
      fileFormData.append('file', file)

      const relatedDomain = 'textarea'
      const relatedDomainId = uuid()

      return new Promise((resolve, reject) => {
        astro
          .createFile({ relatedDomain, relatedDomainId, fileFormData })
          .then((res: any) => {
            console.log('handleLoadFile-res', res)
            if (res.path)
              astro.readFilePresignedUrl(res.path).then((url: string) => {
                console.log('handleLoadFile-readFilePresignedUrl', url)
                resolve(url)
              })
            else reject()
          })
          .catch((err: any) => {
            reject()
            console.log('handleLoadFile-err', err)
          })
      })
    },
    [astro],
  )

  const [offset, setOffset] = useState(0)
  const [maxWidth, setMaxWidth] = useState(0)

  const handleLayout = useCallback(({ nativeEvent }: LayoutChangeEvent) => {
    console.log('handleLayout', nativeEvent.layout)

    // @ts-ignore
    setOffset(-nativeEvent.layout.top + 20)
    setMaxWidth(nativeEvent.layout.width)
  }, [])

  if (!currentProject || !currentChannel) return null

  return (
    <View onLayout={handleLayout}>
      <CardContentsProvider
        offset={offset}
        maxWidth={maxWidth}
        projectId={currentProject.id ?? ''}
        boardId={currentChannel.id ?? ''}
        astro={astro}
        i18n={i18n}
        projectList={projectList ?? []}
        onLoadFile={handleLoadFile}
        currentProject={currentProject}
        currentChannel={currentChannel}
        currentCard={currentCard}
        openPreview={openPreview}
      >
        {children}
      </CardContentsProvider>
    </View>
  )
}
