import React from 'react'
import { View } from 'react-native'
import { Outlet } from 'react-router-dom'

import { TOP_SIZE } from '../base'
import Top from '../base/top'

import Provider from './provider'
import Side from '../base/side'

const Layout: React.FC = () => {
  return (
    <Provider>
      <View style={{ flex: 1, marginTop: TOP_SIZE }}>
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <Outlet />
          <Side />
        </View>
      </View>
      <Top size={TOP_SIZE} />
    </Provider>
  )
}

export default Layout
