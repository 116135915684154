import i18n from 'i18next'
import backend from 'i18next-gridly-backend'
import XHR from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import { LangType, options, updateMomentLocale } from './options'

const i18nInstance = i18n.createInstance()

i18nInstance.use(initReactI18next)

const isProduction = process.env.NODE_ENV === 'production'

if (isProduction) {
  i18nInstance.use(XHR)
} else {
  i18nInstance.use(backend)
}

i18nInstance.init(options, () => {
  i18nInstance.on('languageChanged', (lng) => {
    console.log('languageChanged', lng)
    window.localStorage.setItem('i18nextLng', lng)
    updateMomentLocale(lng as LangType)
  })
})

export default i18nInstance
