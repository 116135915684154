import React from 'react'
import { Pressable, StyleSheet } from 'react-native'

import { faClose } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { View } from '@rocket-mono/foundations'
import { FAIcon, Text } from '@rocket/atoms'

interface Props {
  iconName: IconProp
  title: string
  onPressClose?: () => void
}
const SideViewHeader = ({ iconName, title, onPressClose }: Props) => {
  const [isCloseHover, setIsCloseHover] = React.useState(false)
  return (
    <View>
      <View style={styles.header}>
        <View style={styles.flexbox}>
          <View style={styles.iconArea}>
            <FAIcon iconName={iconName} color={'gray.g800'} />
          </View>
          <Text fontName="txtMdBold" style={styles.title}>
            {title}
          </Text>
        </View>
        <Pressable
          onPress={onPressClose}
          onHoverIn={() => setIsCloseHover(true)}
          onHoverOut={() => setIsCloseHover(false)}
          style={styles.iconArea}
        >
          <FAIcon iconName={faClose} color={isCloseHover ? 'gray.g600' : 'gray.g450'} />
        </Pressable>
      </View>
    </View>
  )
}

export default SideViewHeader

export const styles = StyleSheet.create({
  flexbox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 40,
    paddingRight: 8,
    paddingLeft: 12,
  },
  iconArea: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 28,
  },
  title: {
    marginLeft: 4,
  },
})
