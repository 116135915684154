import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { View } from '@rocket-mono/foundations'

import { useWork } from '@rocket-mono/providers'
import { useMobileView } from '@rui/foundations'
import { Droppable } from 'react-beautiful-dnd-grid-support'
import { StyleSheet } from 'react-native'
import WorkcardBookmarkGroup from '../../../components/WorkcardBookmarkGroup'
import EmptyItem from '../../../components/WorkcardBookmarkGroup/EmptyItem'
import { DROPABLE_FAVORITE_VIEW_ID } from '../../../utils'
import WorkspaceMainCardList from '../list'

interface Props {
  onPressProject?: (projectId: string) => void
  worspaceContentsWidth: number
  setShortcutsFavoriteContentsWidth?: (width: number) => void
  favoritSliderMode: boolean
}

const WorkspaceFavoriteView: React.FC<Props> = ({
  onPressProject,
  worspaceContentsWidth,
  setShortcutsFavoriteContentsWidth,
  favoritSliderMode,
}) => {
  const { t } = useTranslation()
  const mobileWebView = useMobileView(768) //브라우저 웹:모바일 뷰 크기

  const { projectList, favoriteList, deleteFavorite } = useWork()

  const favoriteWorkList = useMemo(() => {
    if (!favoriteList) return []
    return favoriteList.map((item) => {
      const project = projectList?.find((o) => o.id === item.project.id)
      return {
        key: `favorite-${item.project.id}`,
        id: item.project.id,
        isShared: false,
        isFavorite: true,
        isLatest: false,
        isFavoriteMark: true,
        workspaceName: `My WorkSpace`,
        workType: item.project.type.code,
        workName: item.project.title,
        onPressCard: () => {
          onPressProject && onPressProject(item.project.id)
        },
        onPressFavorite: () => {
          deleteFavorite(item.project.id)
        },
        isLearnMoreSelect: false,
        learnMoreList: [],
        lastActivityAt: project ? project.lastActivityAt : item.project.lastActivityAt,
      }
    })
  }, [projectList, favoriteList, deleteFavorite])

  return (
    <View style={[{ marginBottom: 8 }, mobileWebView && { flex: 1, width: worspaceContentsWidth }]}>
      <Droppable droppableId={DROPABLE_FAVORITE_VIEW_ID} direction="horizontal">
        {(provided) => (
          <WorkcardBookmarkGroup
            mobileView={mobileWebView}
            sectionName={t('workspace.favorite')}
            containerStyle={mobileWebView ? { overflow: 'hidden', alignSelf: 'auto', width: '100%' } : {}}
          >
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <View style={{ flexDirection: 'row' }}>
                {favoriteList?.length !== 0 ? (
                  <WorkspaceMainCardList
                    list={favoriteWorkList ?? []}
                    setInnerWidth={setShortcutsFavoriteContentsWidth}
                    sliderMode={favoritSliderMode}
                    shortcuts
                    wrapStyle={{ flexDirection: 'row' }}
                    containerStyle={{ padding: 0, marginHorizontal: 4 }}
                    workItemStyle={{ width: 160 }}
                  />
                ) : (
                  <EmptyItem mobileView={mobileWebView} />
                )}
                <View style={{ width: 1, height: 1 }}>{provided.placeholder}</View>
              </View>
            </div>
          </WorkcardBookmarkGroup>
        )}
      </Droppable>
    </View>
  )
}

export default WorkspaceFavoriteView

const styles = StyleSheet.create({
  shortcutsContainer: {
    flexDirection: 'row',
  },
})
