import { CardType } from '@rocket/types'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutChangeEvent, Platform, View } from 'react-native'
import { CardRowGroup } from './CardRowGroup'
import DatePicker from './DatePicker'
import LabelInput from './LabelInput'
import moment from 'moment'

export type CardDateType = {
  startDate?: Date
  endDate?: Date
  isStartTime?: boolean
  isEndTime?: boolean
}

type DatePickerModal = {
  type: 'start' | 'end'
  scale?: 'start' | 'end'
  initialStart?: Date
  initialTime?: string
  initialIsTime?: boolean
  minDate?: Date
  maxDate?: Date
}

interface Props {
  readonly?: boolean
  cardType?: string | CardType
  date: CardDateType
  onChangeDate?: (date: CardDateType) => void
}
const CardDateInput: React.FC<Props> = ({ readonly = true, cardType, date, onChangeDate }) => {
  const { t } = useTranslation()

  const [layoutWidth, setLayoutWidth] = useState<number>(0)
  const handleLayout = useCallback((event: LayoutChangeEvent) => {
    setLayoutWidth(event.nativeEvent.layout.width)
  }, [])

  const [startDate, setStartDate] = React.useState<Date | undefined>(date.startDate)
  const [endDate, setEndDate] = React.useState<Date | undefined>(date.endDate)

  const [isFromTime, setIsFromTime] = React.useState(date.isStartTime)
  const [isToTime, setIsToTime] = React.useState(date.isEndTime)

  const startDateValue = React.useMemo(() => {
    const dateList: string[] = []
    if (startDate) {
      dateList.push(t('format.date.L', { date: startDate }))
      if (isFromTime) {
        dateList.push(' ')
        dateList.push(t('format.date.T', { date: startDate }))
      }
    }
    return dateList
  }, [startDate, isFromTime])

  const endDateValue = React.useMemo(() => {
    const dateList: string[] = []
    if (endDate) {
      dateList.push(t('format.date.L', { date: endDate }))
      if (isToTime) {
        dateList.push(' ')
        dateList.push(t('format.date.T', { date: endDate }))
      }
    }
    return dateList
  }, [endDate, isToTime])

  const [datePickerModal, setDatePickerModal] = React.useState<DatePickerModal>()

  return (
    <CardRowGroup
      onLayout={handleLayout}
      layoutWidth={layoutWidth}
      title={cardType && ['ATTEND', 'MEETING'].includes(cardType) ? t('discussionheader.meeting') : t('card.period')}
      iconName="time-o"
      iconContainerStyle={{
        alignItems: 'stretch',
        flexDirection: 'row',
        marginTop: 8,
      }}
      marginTop={12}
      containerStyle={Platform.select({
        native: {
          height: 90,
        },
      })}
      renderExtraComponent={
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <LabelInput
              border={false}
              labelIcon="calendar"
              labelText={t('workcardtododatemodal.startdate')}
              placeholder={t('workcardtododatemodal.select')}
              dateValue={startDateValue}
              height={52}
              onPress={() => {
                if (readonly) {
                  return
                }
                const initialStart = startDate
                const initialTime = startDate ? t('format.date.T', { date: startDate }) : undefined
                setDatePickerModal({
                  type: 'start',
                  scale: 'start',
                  initialStart,
                  initialTime,
                  initialIsTime: isFromTime,
                  maxDate: endDate,
                })
              }}
            />
          </View>
          <View style={{ width: 4 }} />
          <View style={{ flex: 1 }}>
            <LabelInput
              border={false}
              labelIcon="calendar"
              labelText={t('workcardtododatemodal.enddate')}
              placeholder={t('workcardtododatemodal.select')}
              dateValue={endDateValue}
              height={52}
              onPress={() => {
                if (readonly) {
                  return
                }
                const initialStart = endDate
                const initialTime = endDate ? t('format.date.T', { date: endDate }) : undefined
                setDatePickerModal({
                  type: 'end',
                  scale: 'end',
                  initialStart,
                  initialTime,
                  initialIsTime: isToTime,
                  minDate: startDate,
                })
              }}
            />
          </View>
          {datePickerModal ? (
            <DatePicker
              type={datePickerModal.type}
              scale={datePickerModal.scale}
              initialStart={datePickerModal.initialStart}
              initialTime={datePickerModal.initialTime}
              initialIsTime={datePickerModal.initialIsTime}
              minDate={datePickerModal.minDate}
              maxDate={datePickerModal.maxDate}
              onClose={() => {
                setDatePickerModal(undefined)
                onChangeDate && onChangeDate({ startDate, endDate, isStartTime: isFromTime, isEndTime: isToTime })
              }}
              onChangeStart={(date) => {
                if (date) {
                  if (datePickerModal.type === 'start') {
                    const $date = moment(date).format('YYYY-MM-DD')
                    const $time = startDate ? t('format.date.T', { date: startDate }) : '12:00'
                    setStartDate(new Date(`${$date} ${$time}`))
                  } else if (datePickerModal.type === 'end') {
                    const $date = moment(date).format('YYYY-MM-DD')
                    const $time = endDate ? t('format.date.T', { date: endDate }) : '12:00'
                    setEndDate(new Date(`${$date} ${$time}`))
                  }
                }
                // setEnable(false)
              }}
              onChangeTime={(time) => {
                console.log('onChangeTime', time)
                if (time) {
                  if (datePickerModal.type === 'start') {
                    if (startDate) {
                      const $date = moment(startDate).format('YYYY-MM-DD')
                      const $time = t('format.date.T', { date: time })
                      setStartDate(new Date(`${$date} ${$time}`))
                    }
                  } else if (datePickerModal.type === 'end') {
                    if (endDate) {
                      const $date = moment(endDate).format('YYYY-MM-DD')
                      const $time = t('format.date.T', { date: time })
                      setEndDate(new Date(`${$date} ${$time}`))
                    }
                  }
                }
                // setEnable(false)
              }}
              onChangeIsTime={(isTime) => {
                if (datePickerModal.type === 'start') {
                  setIsFromTime(isTime)
                  // onIsTimeChange && onIsTimeChange(isTime, isToTime)
                } else if (datePickerModal.type === 'end') {
                  setIsToTime(isTime)
                  // onIsTimeChange && onIsTimeChange(isFromTime, isTime)
                }
              }}
              onDelete={() => {
                if (datePickerModal.type === 'start') {
                  setStartDate(undefined)
                  onChangeDate && onChangeDate({ startDate: undefined, endDate })
                } else if (datePickerModal.type === 'end') {
                  setEndDate(undefined)
                  onChangeDate && onChangeDate({ startDate, endDate: undefined })
                }
                setDatePickerModal(undefined)
                // setEnable(false)
              }}
            />
          ) : (
            <></>
          )}
        </View>
      }
    />
  )
}

export default CardDateInput
