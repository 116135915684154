import * as React from 'react'

// import '@toast-ui/editor/dist/toastui-editor.css'
import '@toast-ui/editor/dist/toastui-editor-viewer.css'
import { Viewer as ToastViewer, Editor as ToastEditor } from '@toast-ui/react-editor'

interface Props {
  initialValue: string
}

const Viewer: React.FC<Props> = ({ initialValue }) => {
  const viewerRef = React.useRef<ToastEditor>(null)

  React.useEffect(() => {
    viewerRef.current?.getInstance().setMarkdown(initialValue)
    // viewerRef.current?.getInstance().
  }, [viewerRef, initialValue])
  return (
    <>
      <style>{`
        div {
          height: auto;
        }
        .toastui-editor-defaultUI {
          border: 0;
        }
        .toastui-editor-toolbar {
          display: none;
        }
        .toastui-editor-main .toastui-editor-md-tab-style > .active {
          display: none;
        }
        .toastui-editor-main .toastui-editor-md-tab-style > .toastui-editor-md-preview {
          display: block;
        }
        .toastui-editor-main-container {
          position: relative
        }
        .toastui-editor-contents {
          font-size: 16px;
        }
        .toastui-editor-contents p {
          margin: 0;
        }
        .toastui-editor-contents * {
          user-select: text;
        }
        .toastui-editor-contents table {
          width: 100%;
        }
        .toastui-editor-contents .tableWrapper {
          width: 100%;
          overflow: auto;
        }
        .toastui-editor-contents .toastui-editor-ww-code-block:after {
          content: none;
        }
        .toastui-editor-contents :not(table) {
          line-height: 170%;
        }
        .card-link {
          display: inline;
          background-color: rgba(42, 50, 55, 0.6);
          padding-left: 4px;
          padding-right: 4px;
          border-radius: 4px;
        }
        .card-link > a {
          color: white;
          text-decoration: none;
          line-height: 20px;
          font-size: 14px;
          font-weight: 500;
        }
        .card-link > a:hover {
          color: white;
        }
      `}</style>
      <ToastViewer usageStatistics={false} initialValue={initialValue} />
      {/* <ToastEditor
        // ref={viewerRef}
        height={undefined}
        viewer={true}
        hideModeSwitch
        previewHighlight={false}
        // initialEditType="markdown"

        toolbarItems={[]}
        usageStatistics={false}
        initialValue={initialValue}
        widgetRules={[
          {
            rule: reWidgetRule,
            toDOM(text) {
              const rule = reWidgetRule
              const matched = text.match(rule)
              const span = document.createElement('span')
              span.className = 'card-link'

              console.log('matched', reWidgetRule, matched)

              if (matched)
                span.innerHTML = `<a href="${location.origin}${matched[3]}" target="_blank" title="${matched[2]}">${matched[1]}</a>`
              return span
            },
          },
        ]}
      /> */}
    </>
  )
}

export default Viewer
