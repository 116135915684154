import { ClickOutside } from '@rui/foundations'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import Whisper from '../Whisper'
import { useCardLink } from '../hooks/useCardLink'

interface Props {
  direction: 'upside' | 'downside'
  whisperHeight?: number
  marginTop?: number
  marginBottom?: number
  marginLeft?: number
  marginRight?: number
  onPicked?: (index: number, id: string) => void
  onCanceled?: () => void
}

export const CardLinkTargetPicker: React.FC<Props> = ({
  direction,
  whisperHeight,
  marginTop = -230,
  marginBottom = -230,
  marginLeft,
  marginRight,
  onCanceled,
  onPicked,
}) => {
  const { cardLinkList, cardLinkListOpen, cursorPosition, pickCardLinkItem, closeCardLink } = useCardLink()

  const [searchText, setSearchText] = React.useState('')
  const ref = React.useRef<View>(null)

  // selectedUserId by cursorPosition in mentionList
  const selectedUserId = React.useMemo(() => {
    const mention = cardLinkList.find((_item, idx) => idx === cursorPosition)
    return mention
  }, [cardLinkList, cursorPosition])

  const dynamicStyles = React.useMemo(
    () => ({
      container: [
        styles.container,
        cardLinkListOpen && direction === 'upside'
          ? [styles.upside, { marginTop }]
          : [styles.downside, { marginBottom }],
        cardLinkListOpen ? styles.opened : styles.closed,
        { marginLeft, marginRight },
      ],
    }),
    [cardLinkListOpen, direction, marginTop, marginBottom, marginLeft, marginRight],
  )

  React.useEffect(() => {
    console.debug('CardLinkTargetPicker:: cardLinkListOpen', {
      cardLinkListOpen,
      dynamicStyles,
    })
  }, [dynamicStyles, cardLinkListOpen])

  const findIndexInMentionList = React.useCallback(
    (id: string) => {
      return cardLinkList.findIndex((item) => item.cardNo === id)
    },
    [cardLinkList],
  )
  return (
    <ClickOutside
      onClickOutside={() => {
        if (cardLinkListOpen) {
          closeCardLink(true)
          onCanceled?.()
        }
      }}
    >
      {cardLinkListOpen ? (
        <View style={dynamicStyles.container} ref={ref}>
          <Whisper
            type="cardLink"
            height={whisperHeight}
            users={[
              ...cardLinkList.map((item) => ({
                id: item.cardNo,
                name: item.cardName,
                source: item.source,
                invisibleProfile: true,
              })),
            ]}
            selectedUserId={selectedUserId?.cardNo}
            onHoverInUser={(user) => {
              const selectedIndex = findIndexInMentionList(user.id)
              if (selectedIndex !== undefined) {
                pickCardLinkItem(selectedIndex)
                onPicked?.(selectedIndex, user.name)
              }
            }}
            setSelectedUserId={(id) => {
              console.debug('set selected user id', id)
              const selectedIndex = findIndexInMentionList(id)
              if (selectedIndex !== undefined) {
                pickCardLinkItem(selectedIndex, true)
              }
            }}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </View>
      ) : (
        <></>
      )}
    </ClickOutside>
  )
}

export default React.memo(CardLinkTargetPicker)

const styles = StyleSheet.create({
  container: {
    zIndex: 10000,
  },
  opened: {
    display: 'flex',
  },
  upside: {
    marginTop: -230,
    marginBottom: 0,
  },
  downside: {
    marginTop: 0,
    marginBottom: -230,
  },
  closed: {
    display: 'none',
    marginTop: 0,
    marginBottom: 0,
  },
})
