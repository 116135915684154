import React, { useEffect, useMemo, useState } from 'react'
import { Modal, StyleSheet, View } from 'react-native'

import { COLOR, removeItemAtIndex } from '@rocket-mono/libs'
import { useAstro, useWork } from '@rocket-mono/providers'
import { BoardSearchView, CardSearchCardItemType } from '@rocket-screens/card'
import { Channel } from '@rocket/types'
import { ClickOutside } from '@rui/foundations'
import { BoardItemType } from './search-view'

export interface Props {
  projectId: string
  boardId: string
  onPressItem: (card: CardSearchCardItemType) => void
  onDissmiss?: () => void
}

export const WorkSearchView: React.FC<Props> = ({ projectId, boardId, onPressItem, onDissmiss }) => {
  const { astro } = useAstro()
  const { projectList } = useWork()

  const [layout, setLayout] = useState<{ top: number; left: number }>()
  const [searchKeyword, setKeywork] = useState('')

  const [foldList, setFoldList] = useState<string[]>([])
  const [boardList, setBoardList] = useState<BoardItemType[]>()

  const list = useMemo(() => {
    if (projectList && boardList) {
      console.log('res-project', projectList)
      return projectList.map((o) => {
        const data = boardList.filter(({ projectId }) => o.id === projectId).map((o) => ({ ...o, data: [] }))

        return {
          projectId: o.id,
          projectTitle: o.title,
          data,
          show: false,
          isFold: foldList.find((id) => id === o.id) ? false : true,
          isArchive: o.isArchive,
        }
      })
    }

    return []
  }, [searchKeyword, projectList, boardList, foldList])

  useEffect(() => {
    const selection = window.getSelection()
    if (selection && selection.focusNode) {
      const el1 = selection.focusNode as HTMLElement
      const el2 = selection.focusNode.parentElement
      console.log('selection', selection, el1.offsetTop, el2?.offsetTop)

      const top = el1 ? el1.offsetTop + el1.offsetHeight + 45 : el2?.offsetTop ?? 0
      const left = el1 ? el1.offsetLeft : el2?.offsetLeft ?? 0

      setLayout({ top, left })
    }
    return () => {
      setLayout(undefined)
    }
  }, [])

  useEffect(() => {
    if (projectList) {
      Promise.all(projectList.map(({ id: projectId }) => astro.readChannelList({ type: 'G', projectId })))
        .then((res) => res.flat())
        .then((list: Channel[]) => {
          return list.map(({ projectId, id: boardId, roomName: boardTitle, closed }) => ({
            projectId,
            boardId,
            boardTitle,
            closed,
          }))
        })
        .then(setBoardList)
    }
  }, [projectList])

  useEffect(() => {
    console.log('useEffect', 'readChannelList')
    astro
      .readChannelList({ type: 'G', isArchive: false, projectId })
      .then((o) =>
        o.map(({ id: boardId, roomName: boardTitle, closed }) => ({ projectId, boardId, boardTitle, closed })),
      )
      .then(setBoardList)
  }, [projectId])

  if (layout === undefined || projectList === undefined || boardList === undefined) return <></>

  return (
    <Modal visible transparent style={{ flex: 1 }}>
      <View style={[styles.containerView]}>
        <ClickOutside
          onClickOutside={() => {
            onDissmiss && onDissmiss()
          }}
        >
          <View style={[styles.modalView]}>
            <BoardSearchView
              keyword={searchKeyword}
              onChangeKeyword={setKeywork}
              list={list}
              onPressItem={onPressItem}
              onPressProject={(id) => {
                setFoldList((prev) => {
                  const idx = prev.findIndex((o) => o === id)
                  return idx < 0 ? [...prev, id] : removeItemAtIndex(prev, idx)
                })
              }}
              onPressSearch={() => {}}
              onPressClose={onDissmiss}
            />
          </View>
        </ClickOutside>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  containerView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLOR.dimmed.a40,
  },
  modalView: {
    minWidth: 400,
    maxWidth: 600,
    height: 570,
    alignItems: 'center',
  },
})
