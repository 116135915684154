import * as React from 'react'

import { DialogView } from '@rocket-mono/foundations'
import { addItemAtIndex, changeItemAtIndex, replaceItemAtIndex } from '@rocket-mono/libs'
import {
  Card,
  Channel,
  ProjectMember,
  Todo as RNTodo,
  TodoFeature,
  TodoFeatureFileOption,
  TodoFeaturePeriodOption,
} from '@rocket/types'
import { useToast, Text } from '@rui/atoms'
import { useTranslation } from 'react-i18next'
import { Modal, StyleSheet, View } from 'react-native'
import Context from './context'
import type { Assignee, Dialog, File, MoveTodoList, ProviderProps, Todo } from './types'
import { useModal } from '@rui/molecules'

const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const initTodo = () => ({
  content: '',
  files: [],
  fileOption: {
    isUploadable: true,
    accessPermission: 'EVERYONE' as const,
  },
  isAssigneeCheck: false,
  isDateCheck: false,
  isFileCheck: false,
  key: uuid(),
})

const Provider = ({
  astro,
  projectId,
  param,
  onParam,
  window,
  saveCacheTodo,
  readCacheTodo,
  clearCacheTodo,
  relatedDomain,
  relatedDomainId,
  cardEntryList = [],
  children,
}: ProviderProps) => {
  const { t } = useTranslation()

  const [keyword, setKeyword] = React.useState<string>('')
  const [todoList, setTodoList] = React.useState<Todo[]>()
  // const [deletedList, setDeletedList] = React.useState<string[]>()
  const [projectMemberList, setProjectMemberList] = React.useState<ProjectMember[]>()
  const [cardList, setCardList] = React.useState<Card[]>([])
  const [movedList, setMovedList] = React.useState<MoveTodoList[]>()

  const [newTodo, setNewTodo] = React.useState<Todo>(initTodo())

  const { show: showToastMessage } = useToast()
  const { showModalDialog, hideModal } = useModal()

  // React.useEffect(() => {
  //   console.log('projectMemberList', JSON.stringify(projectMemberList))
  // }, [projectMemberList])
  // React.useEffect(() => {
  //   console.log('cardList', JSON.stringify(cardList))
  // }, [cardList])

  const fetchExtra = React.useCallback((todo: RNTodo): Promise<Todo> => {
    return Promise.all([astro.readAssignee('todo', todo.id || ''), astro.readFile('todo', todo.id || '')]).then(
      ([assignees, files]) => ({
        ...todo,
        key: uuid(),
        isAssigneeCheck: assignees.length > 0,
        isDateCheck: todo.periodOption?.fromPeriodDate || todo.periodOption?.toPeriodDate ? true : false,
        isFileCheck: files.length > 0 || todo.fileOption?.accessPermission ? true : false,
        assignees,
        files: files.map((file) => ({ ...file, key: uuid() })),
      }),
    )
  }, [])

  const getList = React.useCallback(() => {
    if (relatedDomain === undefined || relatedDomainId === undefined) {
      setTodoList([])
      return
    }
    void astro
      .readTodoList(relatedDomain, relatedDomainId)
      .then((list: RNTodo[]) => {
        return Promise.all(
          list
            .sort((a, b) => {
              if (typeof a.sequenceNumber === 'undefined' || typeof b.sequenceNumber === 'undefined') return 0
              return a.sequenceNumber - b.sequenceNumber
            })
            .map((todo) => fetchExtra(todo)),
        )
      })
      .then((list) => {
        setTodoList(list)
      })
  }, [astro, relatedDomain, relatedDomainId])

  const fetchProjectMemberList = React.useCallback(() => {
    if (!projectId) return
    void astro.readProjectMemberList(String(projectId), false, '&').then(setProjectMemberList)
  }, [astro, projectId])

  const extraCard = React.useCallback((channel: Channel) => {
    return astro.readCardList(channel.id).then((cards) => ({
      ...channel,
      cards,
    }))
  }, [])

  const fetchCardList = React.useCallback(() => {
    if (!projectId) return
    void astro
      .readChannelList({
        type: 'G',
        isArchive: false,
        keyword: '',
        projectId: String(projectId),
      })
      .then((channels) => Promise.all(channels.map((channel) => extraCard(channel))))
      .then((channels) => {
        const $cardList: Card[] = []
        for (const channel of channels) {
          $cardList.push(...channel.cards)
        }
        setCardList($cardList)
      })
  }, [astro, projectId, extraCard])

  React.useEffect(() => {
    if (!window) {
      getList()
    }
    fetchProjectMemberList()
    fetchCardList()
    // setTodoList(todoListDummy as unknown as Todo[])
    // setProjectMemberList(projectMemberListDummy as unknown as ProjectMember[])
    // setCardList(cardListDummy as unknown as Card[])
  }, [])

  const projectMemberEntries = React.useMemo(() => {
    if (!projectMemberList) return []
    return projectMemberList
      .filter((member) => member.isJoined)
      .map((member) => ({
        id: member.userId,
        name: member.name,
      }))
  }, [projectMemberList])

  const cardEntries = React.useMemo(() => {
    console.log({ cardEntries: [...cardList, ...cardEntryList] })
    return [
      ...cardList
        .filter((card) => card.cardIsDel === 'N')
        .map((card) => ({
          cardNo: card.cardId,
          cardName: card.cardTitle,
          channelNo: card.channelId,
          source: card.cardType,
        })),
      ...cardEntryList,
    ]
  }, [cardList, cardEntryList])

  const add = React.useCallback(
    (
      content: string,
      isAssignee: boolean,
      isAssigneeCheck: boolean,
      isDateCheck: boolean,
      isFileCheck: boolean,
      assignees?: Assignee[],
      files?: File[],
      periodOption?: TodoFeaturePeriodOption,
      fileOption?: TodoFeatureFileOption,
    ) => {
      console.log('add', {
        content,
        isAssignee,
        isAssigneeCheck,
        isDateCheck,
        isFileCheck,
        assignees,
        files,
        periodOption,
        fileOption,
      })
      if (!todoList) return
      const activeFeatures: TodoFeature[] = isAssignee ? ['ASSIGNEE'] : []
      if (periodOption) {
        activeFeatures.push('PERIOD')
      }
      if (fileOption) {
        activeFeatures.push('FILE')
      }
      const todo: Todo = {
        key: uuid(),
        content,
        activeFeatures,
        periodOption,
        fileOption,
        assignees,
        files,
        isAssigneeCheck,
        isDateCheck,
        isFileCheck,
        // createdAt: new Date(),
        // updatedAt: new Date(),
        // createdBy: 0,
        // updatedBy: 0,
      }
      setTodoList([todo, ...todoList])
    },
    [todoList],
  )

  const copy = React.useCallback(
    (keyList: string[]) => {
      const duration = 3000
      const duplicateList = todoList ? [...todoList] : []
      for (const key of keyList) {
        setTodoList((prev) => {
          if (!prev) return undefined
          const index = prev.findIndex((todo) => todo.key === key)
          const item = prev[index]
          if (index > -1) {
            return addItemAtIndex(prev, index, {
              ...item,
              id: undefined,
              key: uuid(),
            })
          } else {
            return prev
          }
        })
      }
      showToastMessage(
        {
          type: 'Success',
          title: t('cardtodoedit.toast.copy'),
          position: 'BOTTOM_CENTER',
          // squareType: true,
          // TODO: 취소 기능 추가
          // cancelText: t('cardtodoedit.toast.copycancel'),
          // onCancel: () => {
          //   setTodoList(duplicateList)
          // },
        },
        duration,
      )
    },
    [showToastMessage, todoList],
  )

  const deleteTodo = React.useCallback(
    (list: Todo[]) => {
      if (!todoList) return
      const duration = 3000
      const duplicateList = todoList ? [...todoList] : []
      const delayDelete = setTimeout(() => {
        for (const item of list) {
          if (item.id) {
            void astro.deleteTodo(item.id)
          }
        }
      }, duration)
      const newList = todoList.filter((todo) => {
        const index = list.findIndex((item) => item.key === todo.key)
        if (index > -1) return false
        else return true
      })
      setTodoList(newList)
      showToastMessage(
        {
          type: 'Success',
          title: t('cardtodoedit.toast.delete'),
          position: 'BOTTOM_CENTER',
          // squareType: true,
          // TODO: 취소 기능 추가
          // cancelText: t('cardtodoedit.toast.deletecancel'),
          // onCancel: () => {
          //   setTodoList(duplicateList)
          //   clearTimeout(delayDelete)
          // },
        },
        duration,
      )
      // const _deletedList = list
      //   .filter((todo) => todo.id)
      //   .map((todo) => todo.id || '')
      // const deletedItemList = new Set(
      //   deletedList ? [...deletedList, ..._deletedList] : [..._deletedList],
      // )
      // setDeletedList(Array.from(deletedItemList))
    },
    [showToastMessage, todoList],
  )
  const move = React.useCallback(
    (list: Todo[], targetDomain: string, targetDomainId: string) => {
      if (!todoList) return
      const newMovedList = [...(movedList || [])]
      for (const item of list) {
        if (item.id) {
          const index = newMovedList.findIndex(
            (moved) => moved.targetDomain === targetDomain && moved.targetDomainId === targetDomainId,
          )
          if (index > -1) {
            newMovedList[index].list.push(item)
          } else {
            newMovedList.push({
              targetDomain,
              targetDomainId,
              list: [item],
              isId: true,
            })
          }
        } else {
          const index = newMovedList.findIndex(
            (moved) => moved.targetDomain === targetDomain && moved.targetDomainId === targetDomainId,
          )
          if (index > -1) {
            newMovedList[index].list.push(item)
          } else {
            newMovedList.push({
              targetDomain,
              targetDomainId,
              list: [item],
              isId: false,
            })
          }
        }
      }
      // deleteTodo(list)
      setMovedList(newMovedList)
    },
    [todoList, movedList],
  )

  const save = React.useCallback(
    async (relatedDomain?: string, relatedDomainId?: string, $newTodo?: Todo) => {
      if (param.state !== 'SAVE') return
      if (todoList) {
        const $todoList = $newTodo ? [$newTodo, ...todoList] : todoList
        console.log('save $todoList', $todoList)
        for (const item of $todoList) {
          let content = astro.formatMention(
            item.content ? item.content.trim() : '',
            projectMemberEntries.map((member) => ({
              username: member.name,
              displayName: member.name,
              channelMember: {
                userId: member.id,
              },
            })),
          )
          content = astro.formatCardLink(content, cardEntries)
          const index = $todoList.findIndex((todo) => todo.key === item.key)
          if (item.id) {
            const sendedTodo = await astro.updateTodo({
              ...item,
              content,
              sequenceNumber: index,
              fileOption: item.isFileCheck ? item.fileOption : {},
            })
            if (item.isAssigneeCheck) {
              if (item.assignees) {
                for (const assignee of item.assignees) {
                  if (!assignee.id) {
                    await astro.createAssignee({
                      ...assignee,
                      relatedDomain: 'todo',
                      relatedDomainId: sendedTodo.id,
                    })
                  }
                }
              }
            } else {
              if (item.assignees) {
                const assigneeList = item.assignees
                  .filter((assignee) => assignee.id)
                  .map((assignee) => ({ ...assignee, id: assignee.id || '' }))
                if (assigneeList.length > 0) {
                  await astro.deleteAssignees(assigneeList)
                }
              }
            }
            if (item.isFileCheck) {
              if (item.files) {
                for (const file of item.files) {
                  if (!file.id) {
                    const fileFormData = new FormData()
                    if (file.localFile) {
                      fileFormData.append('file', file.localFile)
                    }
                    await astro.createFile({
                      ...file,
                      fileFormData,
                      relatedDomain: 'todo',
                      relatedDomainId: sendedTodo.id,
                    })
                  }
                }
              }
            } else {
              if (item.files) {
                for (const file of item.files) {
                  if (file.id) {
                    await astro.deleteFile(file.id)
                  }
                }
              }
            }
          } else {
            const sendedTodo = await astro.createTodo({
              ...item,
              content,
              sequenceNumber: index,
              relatedDomain,
              relatedDomainId,
              fileOption: item.isFileCheck ? item.fileOption : {},
            })
            if (item.isAssigneeCheck) {
              if (item.assignees) {
                for (const assignee of item.assignees) {
                  await astro.createAssignee({
                    ...assignee,
                    relatedDomain: 'todo',
                    relatedDomainId: sendedTodo.id,
                  })
                }
              }
            } else {
              if (item.assignees) {
                const assigneeList = item.assignees
                  .filter((assignee) => assignee.id)
                  .map((assignee) => ({ ...assignee, id: assignee.id || '' }))
                if (assigneeList.length > 0) {
                  await astro.deleteAssignees(assigneeList)
                }
              }
            }
            if (item.isFileCheck) {
              if (item.files) {
                for (const file of item.files) {
                  const fileFormData = new FormData()
                  if (file.localFile) {
                    fileFormData.append('file', file.localFile)
                  }
                  await astro.createFile({
                    ...file,
                    fileFormData,
                    relatedDomain: 'todo',
                    relatedDomainId: sendedTodo.id,
                  })
                }
              }
            } else {
              if (item.files) {
                for (const file of item.files) {
                  if (file.id) {
                    await astro.deleteFile(file.id)
                  }
                }
              }
            }
          }
        }
      }
      // if (deletedList) {
      //   for (const id of deletedList) {
      //     await astro.deleteTodo(id)
      //   }
      // }
      // TODO: 다음 마일스톤
      // if (movedList) {
      //   const _moved: TodoMovePayload[] = []
      //   for (const moved of movedList) {
      //     if (moved.isId) {
      //       for (const item of moved.list) {
      //         _moved.push({
      //           sourceId: item.id || '',
      //           targetDomain: moved.targetDomain,
      //           targetDomainId: moved.targetDomainId,
      //         })
      //       }
      //     } else {
      //       const targetList = await astro.readTodoList(
      //         moved.targetDomain,
      //         moved.targetDomainId,
      //       )
      //       for (const item of moved.list) {
      //         const index = moved.list.findIndex(
      //           (todo) => todo.key === item.key,
      //         )
      //         promises.push(
      //           astro.createTodo({
      //             ...item,
      //             relatedDomain: moved.targetDomain,
      //             relatedDomainId: moved.targetDomainId,
      //             sequenceNumber: index + targetList.length,
      //           }),
      //         )
      //       }
      //     }
      //   }
      //   promises.push(astro.moveTodoList(_moved))
      // }
      getList()
    },
    [todoList, astro, getList, projectMemberEntries, cardEntries, param],
  )

  const change = React.useCallback(
    (item: Todo, index: number) => {
      if (!todoList) return
      setTodoList((prev) => {
        return prev ? replaceItemAtIndex(prev, index, item) : undefined
      })
    },
    [todoList],
  )

  const changeIndex = React.useCallback(
    (from: number, to: number) => {
      if (!todoList) return
      setTodoList(changeItemAtIndex(todoList, from, to))
    },
    [todoList],
  )

  const changeKeyword = React.useCallback((keyword: string) => {
    setKeyword(keyword)
  }, [])

  const list = React.useMemo(() => {
    if (!todoList) return
    return todoList.filter((todo) => (todo.content ? todo.content.indexOf(keyword) > -1 : true))
  }, [todoList, keyword])

  React.useEffect(() => {
    if (param.state === 'SAVE') {
      if (newTodo.content) {
        showModalDialog({
          children: (
            <View style={{ minHeight: 40, maxWidth: 320 }}>
              <Text fontName="H7Medium">{t('card.dialog.todoadd.title')}</Text>
              <Text>{t('card.dialog.todoadd.message', { todo: newTodo.content })}</Text>
            </View>
          ),
          dialogButtons: [
            {
              text: t('card.dialog.todoadd.add'),
              noWrapper: true,
              onPress: () => {
                hideModal()
                void save(param.relatedDomain, param.relatedDomainId, newTodo).then(() => {
                  onParam({
                    state: 'COMPLETE',
                    relatedDomain: param.relatedDomain,
                    relatedDomainId: param.relatedDomainId,
                  })
                  // setNewTodo(initTodo())
                })
              },
            },
          ],
          dialogCancelButton: {
            text: t('card.dialog.todoadd.noadd'),
            noWrapper: true,
            onPress: () => {
              hideModal()
              void save(param.relatedDomain, param.relatedDomainId).then(() => {
                onParam({
                  state: 'COMPLETE',
                  relatedDomain: param.relatedDomain,
                  relatedDomainId: param.relatedDomainId,
                })
                // setNewTodo(initTodo())
              })
            },
          },
          onPressClose: hideModal,
        })
      } else {
        void save(param.relatedDomain, param.relatedDomainId).then(() => {
          onParam({
            state: 'COMPLETE',
            relatedDomain: param.relatedDomain,
            relatedDomainId: param.relatedDomainId,
          })
        })
      }
    } else if (param.state === 'ARCHIVE') {
      void saveCacheTodo?.(todoList).then(() => {
        onParam({
          state: 'ARCHIVE_COMPLETE',
          relatedDomain: param.relatedDomain,
          relatedDomainId: param.relatedDomainId,
        })
      })
    } else if (param.state === 'READ') {
      void readCacheTodo?.().then((data) => {
        setTodoList(data)
        onParam({
          state: 'READ_COMPLETE',
          relatedDomain: param.relatedDomain,
          relatedDomainId: param.relatedDomainId,
        })
      })
    } else if (param.state === 'REMOVE') {
      void clearCacheTodo?.().then(() => {
        onParam({
          state: 'REMOVE_COMPLETE',
          relatedDomain: param.relatedDomain,
          relatedDomainId: param.relatedDomainId,
        })
      })
    }
  }, [param, newTodo, todoList])

  return (
    <Context.Provider
      value={{
        keyword,
        changeKeyword,
        list,
        add,
        copy,
        deleteTodo,
        move,
        save,
        change,
        changeIndex,
        projectMemberEntries,
        cardEntries,
        newTodo,
        setNewTodo,
      }}
    >
      {children}
    </Context.Provider>
  )
}

const styles = StyleSheet.create({
  dimmed: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
})

export default Provider
