import * as React from 'react'

import { View } from '@rocket-mono/foundations'
import {
  useAstro,
  useAuth,
  useCurrentUser,
  usePreview,
  useSubscription,
  useWorkAssignee,
  useWorkCard,
  useWorkChannel,
  useWorkFile,
  useWorkProject,
} from '@rocket-mono/providers'
import { File } from '@rocket/types'
import { useToast } from '@rui/atoms'
import { useTranslation } from 'react-i18next'
import { CardRowGroup } from '../../components/CardRowGroup'
import { CardTodo, CardTodoPreview } from '../../components/CardTodo'
import FileViewer from '../../components/FileViewer'
import { ImagePreviewList } from '../../components/ImagePreviewList'
import { WorkCardEditor } from '../../components/WorkCardEditor'
import { WorkCardLayout } from '../../components/WorkCardLayout'
import type { ViewProps } from './types'

function sleep(ms: number) {
  return new Promise((r) => setTimeout(r, ms))
}

const ScreenView: React.FC<ViewProps> = ({ onPressTodo, ...layoutProps }) => {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()
  const { currentProject } = useWorkProject()
  const { currentChannel, channelId } = useWorkChannel()
  const { currentCard, todoList } = useWorkCard()
  const { option: astroOption, astro } = useAstro()
  const { isAnonymous, showLoginModal } = useAuth()

  const { assigneeList } = useWorkAssignee()
  const { fileList } = useWorkFile()

  const { show: showToastMessage } = useToast()

  const { openPreview } = usePreview()

  const [fileLoad, setFileLoad] = React.useState<{ file: globalThis.File; todoId: string }>()

  const assigneeIds = React.useMemo(
    () =>
      assigneeList.map(({ id, userId, userEmail }) => ({
        id,
        userId: String(userId ?? ''),
        userEmail: userEmail ?? '',
      })),
    [assigneeList],
  )

  const dateProps = React.useMemo(() => {
    let isTime = false
    let cardDate = {}
    if (currentCard && (currentCard.fromPeriodDate || currentCard.toPeriodDate)) {
      isTime = true
      const { fromPeriodDate, toPeriodDate, isFromPeriodDateTime, isToPeriodDateTime } = currentCard
      const startDate = fromPeriodDate ? new Date(fromPeriodDate) : undefined
      const endDate = toPeriodDate ? new Date(toPeriodDate) : undefined
      const isStartTime = isFromPeriodDateTime !== null ? isFromPeriodDateTime : false
      const isEndTime = isToPeriodDateTime !== null ? isToPeriodDateTime : false
      cardDate = { startDate, endDate, isStartTime, isEndTime }
    }
    return { isTime, cardDate }
  }, [currentCard])

  const onCardLinkPress = React.useCallback((cardNo: string, cardName: string, channelNo: string) => {
    // console.log('onCardLinkPress', { cardNo, cardName, channelNo })
    // astro.readCard(cardNo, '').then((card) => console.log('card', { card }))
    window.open(`/card/${cardNo}?channelId=${channelNo}`, '_blank')
  }, [])
  const onMentionPress = React.useCallback((memberNo: string, memberName: string) => {
    console.log('onMentionPress', { memberNo, memberName })
  }, [])
  const onLinkPress = React.useCallback((url: string) => {
    // console.log('onLinkPress', { url })
    window.open(url, '_blank')
  }, [])

  const [cardNo, setCardNo] = React.useState<string>()
  const [relatedDomainId, setRelatedDomainId] = React.useState<string>()

  const [content, setContent] = React.useState<string | null>()
  React.useEffect(() => {
    setContent(undefined)
    if (currentCard !== null && currentCard.content) {
      setTimeout(() => setContent(currentCard.content), 200)
    } else {
      setContent(null)
    }
  }, [currentCard])

  React.useEffect(() => {
    setCardNo(undefined)
    if (currentCard) {
      setTimeout(() => setCardNo(String(currentCard.no)), 50)
    }
  }, [currentCard])

  React.useEffect(() => {
    console.log('relatedDomainId', cardNo)
    if (cardNo) {
      setRelatedDomainId(cardNo)
    }
  }, [cardNo])

  if (currentCard === null) return null

  return (
    <WorkCardLayout
      edit={false}
      {...dateProps}
      cardType="MISSION"
      title={currentCard?.title || ''}
      optionList={[]}
      assigneeIds={assigneeIds}
      {...layoutProps}
    >
      {() => (
        <>
          <View style={{ flex: 1 }}>
            {content !== undefined && content !== null && <WorkCardEditor readonly content={content} />}
            {currentCard.contentFiles && currentCard.contentFiles.length > 0 && (
              <div style={{ marginRight: 10, marginLeft: 10 }}>
                <ImagePreviewList
                  list={currentCard.contentFiles.map((file: any) => {
                    return {
                      id: file.no,
                      uri: `${astroOption.secureCdnUrl}/images/${file.fileKey}/${file.filename}`,
                      // onPress: () => previewFile(file),
                    }
                  })}
                />
              </div>
            )}
            {!!currentCard.sendDateEnd && (
              <CardRowGroup
                iconName="time-o"
                title={t('board.card.due')}
                iconSize={16}
                titleSize={16}
                containerStyle={{
                  marginBottom: 30,
                }}
                headerContainerStyle={{
                  marginHorizontal: 0,
                }}
                iconContainerStyle={{
                  alignItems: 'stretch',
                }}
                titleStyle={{
                  fontWeight: '500',
                }}
                childrenContainerStyle={
                  {
                    // paddingHorizontal: 15,
                  }
                }
              >
                <section className="v2">
                  <div>
                    <div
                      style={{
                        fontSize: 17,
                        color: '#F85A5E',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      {t('format.date.LLL', { date: currentCard.sendDateEnd })}
                    </div>
                  </div>
                </section>
              </CardRowGroup>
            )}
            {todoList && todoList.length > 0 ? (
              <CardRowGroup
                iconName="check-square-o"
                title={t('board.card.todo')}
                iconSize={16}
                titleSize={16}
                containerStyle={{
                  marginBottom: 30,
                }}
                headerContainerStyle={{
                  marginHorizontal: 0,
                }}
                iconContainerStyle={{
                  alignItems: 'stretch',
                }}
                titleStyle={{
                  fontWeight: '500',
                }}
              >
                <div style={{ width: '100%' }}>
                  <div
                    style={{
                      marginBottom: currentCard.files.length > 0 ? 0 : '5rem',
                    }}
                  >
                    <CardTodo
                      isAnonymous={isAnonymous}
                      onAnonymousPress={showLoginModal}
                      astro={astro}
                      subscribe={useSubscription}
                      currentUser={currentUser}
                      projectId={currentCard.projectNo}
                      boardRoomId={currentChannel?.roomId}
                      channelId={channelId}
                      relatedDomain={'card'}
                      relatedDomainId={relatedDomainId}
                      onCardLinkPress={onCardLinkPress}
                      onMentionPress={onMentionPress}
                      onLinkPress={onLinkPress}
                      fileLoad={fileLoad}
                      clearFileLoad={() => setFileLoad(undefined)}
                      onPressTodo={onPressTodo}
                      onPreview={(previewList: CardTodoPreview[], file: File) => {
                        if (!currentProject || !currentChannel || !currentCard) return

                        if (file.mimeType && !file.mimeType.includes('image') && !file.mimeType.includes('pdf')) return

                        const fileKey = file.relatedDomain || ''
                        const filename = file.name || ''
                        const fileIndex = previewList.findIndex((item) => item.id === file.id)

                        openPreview(
                          'card',
                          fileKey,
                          filename,
                          previewList.map((item) => ({
                            ...item,
                            workType: currentProject.type.code,
                            workName: currentProject.title,
                            boardName: currentChannel.roomName,
                            cardName: currentCard.title,
                          })),
                          currentProject.type.code,
                          currentProject.title,
                          fileIndex,
                        )
                      }}
                      onFileLoad={(id: string) => {
                        console.log('onFileLoad', id)
                        const inputRef = document.createElement('input')
                        const change = async (e: Event) => {
                          let isFileSizeOver = false
                          if (e && e.target) {
                            const target = e.target as HTMLInputElement
                            if (target.files && target.files.length > 0) {
                              for (const file of target.files) {
                                if (file.size < 1024 * 1024 * 50) {
                                  setFileLoad({ todoId: id, file })
                                  await sleep(100)
                                } else {
                                  isFileSizeOver = true
                                }
                              }
                            }
                            if (isFileSizeOver) {
                              showToastMessage({
                                title: t('toast.file.size.over'),
                                type: 'Danger',
                                position: 'TOP_CENTER',
                              })
                            }
                          }
                          inputRef.removeEventListener('change', change)
                        }
                        inputRef.type = 'file'
                        inputRef.style.display = 'none'
                        inputRef.multiple = true
                        inputRef.addEventListener('change', change)
                        document.body.appendChild(inputRef)
                        inputRef.click()
                      }}
                    />
                  </div>
                </div>
              </CardRowGroup>
            ) : (
              <></>
            )}
          </View>
          <FileViewer files={fileList} fileSource="MISSION" />
        </>
      )}
    </WorkCardLayout>
  )
}

export default ScreenView
