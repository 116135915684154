import React, { useEffect } from 'react'
import Provider from './provider'
import CardView from './view'
import { Props } from './types'

const CardViewScreen: React.FC<Props> = ({ i18n, onUnAuthorized, todoId, ...screenProps }) => {
  useEffect(() => {
    console.debug('CardViewScreen-useEffect-index', screenProps)
  }, [])
  return (
    <Provider i18n={i18n} todoId={todoId} onUnAuthorized={onUnAuthorized}>
      <CardView i18n={i18n} todoId={todoId} {...screenProps} />
    </Provider>
  )
}

export default CardViewScreen
