import type { IChatMessageReply, IUser } from '@rocket-mono/libs'
import { FONT, getRealColor } from '@rocket-mono/libs'
import moment from 'moment'
import 'moment/min/locales'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, Image, ImageSourcePropType, Platform, StyleSheet, Text, View, ViewStyle } from 'react-native'
import Hyperlink from 'react-native-hyperlink'
import type { AppList } from '../../AddPopup'
import MoreButton from '../Components/MoreButton'
import { ItemMoreButtonType } from '../types'
import { useDiscussionUser } from '../useDiscussionUser'
import ChatbubblesOutline from './chatbubblesOutline'
import ReplyList from './reply'

interface Props {
  image: ImageSourcePropType
  userName: string
  message: string
  containerStyle?: ViewStyle
  replyContainerStyle?: ViewStyle
  moreList?: AppList[]
  regDate: string
  writer?: IUser
  recipient?: IUser
  chatMessageReply?: IChatMessageReply[]
  onPopupState: (state: string) => void
  onPopupReplyState?: ({
    parentIndex,
    index,
    state,
    item,
  }: {
    parentIndex: number
    index: number
    state: string
    item?: IChatMessageReply
  }) => void
  selected: boolean
  replySelectedIndex?: string
  onLinkPress?: (url: string, text?: string | undefined) => void
  topIndex: number
  onPressMoreButton?: (payload: ItemMoreButtonType) => void
}

const WhisperItem = ({
  image,
  userName,
  message,
  containerStyle,
  replyContainerStyle,
  moreList,
  regDate,
  writer,
  recipient,
  chatMessageReply = [],
  onPopupReplyState,
  selected,
  replySelectedIndex,
  onLinkPress,
  topIndex,
  onPressMoreButton,
}: Props) => {
  const currentUser = useDiscussionUser()
  const { i18n, t } = useTranslation()
  moment.locale(i18n.resolvedLanguage)
  const isMe = React.useMemo(() => {
    return currentUser ? currentUser.no === writer?.no : false
  }, [writer, currentUser])
  return (
    <View style={[styles.container]}>
      <View
        style={[
          styles.commentContainer,
          containerStyle,
          {
            backgroundColor: selected ? '#e4eef9' : getRealColor('mono.white'),
          },
        ]}
      >
        {/* image container */}
        <View style={styles.imageContainer}>
          <Image source={image} style={styles.image} />
        </View>
        <View style={styles.rightContainer}>
          <View style={styles.topContainer}>
            <View style={styles.usernameContainer}>
              <Text style={styles.userName}>{userName}</Text>
              <Text style={styles.time}>{t('format.date.LLL', { date: regDate })}</Text>
              <View style={styles.whisperContainer}>
                <ChatbubblesOutline color={getRealColor('mono.darkGray')} />
              </View>
            </View>
            {moreList && moreList.length > 0 && (
              <View style={styles.moreButton}>
                <MoreButton
                  onPress={(layoutPayload) => {
                    onPressMoreButton?.({
                      parentIndex: topIndex,
                      moreList,
                      layoutPayload,
                    })
                  }}
                />
              </View>
            )}
          </View>
          <View style={styles.messageContainer}>
            <View style={{ flex: 1, width: '100%' }}>
              <Hyperlink
                linkStyle={{
                  color: '#2980b9',
                  textDecorationLine: 'underline',
                  ...Platform.select({
                    web: {
                      cursor: 'pointer',
                    },
                  }),
                }}
                onPress={onLinkPress}
              >
                <Text style={styles.message}>
                  {isMe && <Text style={styles.recipientUserName}>{`(To. ${recipient?.userName ?? ''}) `}</Text>}

                  <Text style={[selected ? { ...FONT.textBold } : { ...FONT.textRegular }]}>{message}</Text>
                </Text>
              </Hyperlink>
            </View>
          </View>
        </View>
      </View>
      <ReplyList
        chatMessageReply={chatMessageReply}
        replySelectedIndex={replySelectedIndex}
        topIndex={topIndex}
        onPopupReplyState={onPopupReplyState}
        replyContainerStyle={replyContainerStyle}
        onLinkPress={onLinkPress}
        onPressMoreButton={onPressMoreButton}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: getRealColor('mono.white'),
  },
  commentContainer: {
    flexDirection: 'row',
  },
  replyContainer: {
    marginTop: 10,
    marginLeft: 10,
    flexDirection: 'row',
  },
  imageContainer: {
    marginRight: 10,
  },
  replyImageContainer: {
    flexDirection: 'row',
    marginRight: 10,
  },
  image: {
    width: 40,
    height: 40,
    borderRadius: 25,
  },
  rightContainer: {
    flex: 1,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  usernameContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: 19,
  },
  moreButton: {
    width: 35,
  },
  userName: {
    ...FONT.subTextBold,
    lineHeight: Platform.select({
      native: 19.24,
      default: 12.64,
    }),
    color: getRealColor('mono.black'),
    marginRight: 5,
  },
  time: {
    ...FONT.subTextThin,
    color: getRealColor('mono.darkGray'),
    marginRight: 5,
  },
  messageContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 3,
  },
  recipientUserName: {
    ...FONT.textRegular,
    color: getRealColor('main.blue'),
  },
  message: {
    ...FONT.textRegular,
    color: getRealColor('mono.paleBlack'),
    ...Platform.select({
      web: {
        display: 'flex',
        flexWrap: 'wrap',
        wordBreak: 'break-all',
        // width: 'calc(100vw - 100px)',
        width: '100%',
      },
      android: {
        width: Dimensions.get('window').width - 100,
      },
    }),
  },
  whisperContainer: {
    width: 12,
    height: 12,
    marginBottom: Platform.select({
      native: 2,
      default: 1,
    }),
  },
})

export default WhisperItem
