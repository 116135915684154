import * as React from 'react'

import { View, StyleSheet } from 'react-native'
import Svg, { Path, Rect } from 'react-native-svg'

interface Props {
  variant: 'ROUNDSQUARE' | 'CIRCLE'
  type: 'ATTEND' | 'MEETING' | 'MISSION' | 'COLLECTION' | 'TASKREPORT' | 'TOTAL' | 'DISCUSSION' | 'MESSAGE' | 'TODO'
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xlg'
}

const CardIcon: React.FC<Props> = ({ variant, type, size }) => {
  const setSize = () => {
    return size === 'xs' ? 16 : size === 'sm' ? 20 : size === 'md' ? 28 : size === 'lg' ? 40 : 52 //xlg
  }

  return (
    <View
      style={[
        variant === 'ROUNDSQUARE' && {
          borderRadius: size === 'xs' ? 6 : size === 'sm' ? 7 : size === 'md' ? 9 : size === 'lg' ? 13 : 18,
        },
        variant === 'CIRCLE' && { borderRadius: 50 },
        styles.container,
      ]}
    >
      {type === 'TOTAL' && (
        <Svg width={setSize()} height={setSize()} viewBox="0 0 20 20" fill="none">
          <Rect width={20} height={20} fill="#E6E9ED" />
          <Path
            d="M13.0586 7.19629C14.4648 7.19629 15.625 8.33887 15.625 9.74512V10.2549C15.625 11.6611 14.4648 12.8037 13.0586 12.8213C12.3906 12.8213 11.7402 12.54 11.2656 12.0654L10 10.7998L8.7168 12.0654C8.24219 12.54 7.5918 12.8037 6.92383 12.8037C5.51758 12.8037 4.375 11.6611 4.375 10.2549V9.74512C4.375 8.33887 5.51758 7.19629 6.92383 7.17871C7.5918 7.17871 8.24219 7.45996 8.7168 7.93457L10 9.2002L11.2656 7.93457C11.7402 7.45996 12.3906 7.19629 13.0586 7.19629ZM7.92578 11.2744L9.19141 10.0088L7.92578 8.72559C7.66211 8.46191 7.29297 8.32129 6.92383 8.32129C6.13281 8.32129 5.5 8.9541 5.5 9.74512V10.2549C5.5 11.0459 6.13281 11.6787 6.92383 11.6963C7.29297 11.6963 7.66211 11.5381 7.92578 11.2744ZM14.5 10.2549V9.74512C14.5 8.9541 13.8496 8.30371 13.0586 8.32129C12.6895 8.32129 12.3203 8.46191 12.0566 8.72559L10.791 10.0088L12.0566 11.2744C12.3203 11.5381 12.6895 11.6963 13.0586 11.6963C13.8496 11.6963 14.5 11.0459 14.5 10.2549Z"
            fill="#5D6D78"
          />
        </Svg>
      )}
      {type === 'MISSION' && (
        <Svg width={setSize()} height={setSize()} viewBox="0 0 52 52" fill="none">
          <Rect width={52} height={52} fill="#5EBCCA" />
          <Path
            d="M17.4286 16.5714C16.9464 16.5714 16.5714 17 16.5714 17.4286V34.5714C16.5714 35.0536 16.9464 35.4286 17.4286 35.4286H34.5714C35 35.4286 35.4286 35.0536 35.4286 34.5714V17.4286C35.4286 17 35 16.5714 34.5714 16.5714H17.4286ZM14 17.4286C14 15.5536 15.5 14 17.4286 14H34.5714C36.4464 14 38 15.5536 38 17.4286V34.5714C38 36.5 36.4464 38 34.5714 38H17.4286C15.5 38 14 36.5 14 34.5714V17.4286ZM32.0536 23.4821L25.1964 30.3393C24.6607 30.875 23.8571 30.875 23.375 30.3393L19.9464 26.9107C19.4107 26.4286 19.4107 25.625 19.9464 25.1429C20.4286 24.6071 21.2321 24.6071 21.7679 25.1429L24.2857 27.6607L30.2321 21.6607C30.7143 21.1786 31.5179 21.1786 32 21.6607C32.5357 22.1964 32.5357 23 32 23.4821H32.0536Z"
            fill="white"
          />
        </Svg>
      )}
      {type === 'MEETING' && (
        <Svg width={setSize()} height={setSize()} viewBox="0 0 52 52" fill="none">
          <Rect width={52} height={52} fill="#F7C954" />
          <Path
            d="M22.5714 16.381H29.4286V14.4762C29.4286 13.8572 29.9047 13.3334 30.5714 13.3334C31.1905 13.3334 31.7143 13.8572 31.7143 14.4762V16.381H33.619C35.2857 16.381 36.6666 17.7619 36.6666 19.4286V34.6667C36.6666 36.381 35.2857 37.7143 33.619 37.7143H18.3809C16.6666 37.7143 15.3333 36.381 15.3333 34.6667V19.4286C15.3333 17.7619 16.6666 16.381 18.3809 16.381H20.2857V14.4762C20.2857 13.8572 20.7619 13.3334 21.4286 13.3334C22.0476 13.3334 22.5714 13.8572 22.5714 14.4762V16.381ZM17.619 34.6667C17.619 35.0953 17.9524 35.4286 18.3809 35.4286H33.619C34 35.4286 34.3809 35.0953 34.3809 34.6667V22.4762H17.619V34.6667Z"
            fill="white"
          />
        </Svg>
      )}
      {type === 'COLLECTION' && (
        <Svg width={setSize()} height={setSize()} viewBox="0 0 52 52" fill="none">
          <Rect width={52} height={52} fill="#B48BD3" />
          <Path
            d="M35.75 18.3334C36.9688 18.3334 38 19.3646 38 20.5834V34.0834C38 35.349 36.9688 36.3334 35.75 36.3334H16.25C14.9844 36.3334 14 35.349 14 34.0834V17.5834C14 16.3646 14.9844 15.3334 16.25 15.3334H23.75L26.75 18.3334H35.75ZM29.75 28.4584C30.3594 28.4584 30.875 27.9896 30.875 27.3334C30.875 26.724 30.3594 26.2084 29.7031 26.2084H27.0781V23.5834C27.0781 22.974 26.6094 22.4584 26 22.4584C25.3438 22.4584 24.875 22.974 24.8281 23.5834V26.2552H22.2031C21.5938 26.2552 21.125 26.724 21.125 27.3334C21.125 27.9896 21.5938 28.4584 22.2031 28.4584H24.875V31.1302C24.875 31.7396 25.3438 32.2084 26 32.2084C26.6094 32.2084 27.0781 31.7396 27.125 31.0834V28.4584H29.75Z"
            fill="white"
          />
        </Svg>
      )}
      {type === 'DISCUSSION' && (
        <Svg width={setSize()} height={setSize()} viewBox="0 0 52 52" fill="none">
          <Rect width={52} height={52} fill="#1F2940" />
          <Path
            d="M20.5417 14.1666C25.5885 14.1666 29.75 17.664 29.75 21.9583C29.75 26.2968 25.5885 29.75 20.5417 29.75C19.7005 29.75 18.9036 29.6614 18.1068 29.4843C16.7786 30.3255 14.8307 31.1666 12.3958 31.1666C11.9531 31.1666 11.5547 30.9453 11.4219 30.5026C11.2448 30.1041 11.2891 29.6614 11.599 29.3073C11.6432 29.3073 12.6172 28.2448 13.3255 26.8281C12.0859 25.5 11.3333 23.8177 11.3333 21.9583C11.3333 17.664 15.4505 14.1666 20.5417 14.1666ZM18.5937 27.4036C19.2578 27.5807 19.8776 27.625 20.5417 27.625C24.4375 27.625 27.625 25.1015 27.625 21.9583C27.625 18.8593 24.4375 16.2916 20.5417 16.2916C16.6016 16.2916 13.4583 18.8593 13.4583 21.9583C13.4583 23.552 14.2109 24.7031 14.875 25.3671L15.9375 26.4739L15.2292 27.8463C15.0521 28.1119 14.875 28.4218 14.6979 28.7317C15.4948 28.5104 16.2474 28.1562 17 27.6692L17.7526 27.2265L18.5937 27.4036ZM30.8568 19.8776C35.7708 20.0546 39.6667 23.4635 39.6667 27.625C39.6667 29.4843 38.8698 31.1666 37.6302 32.4948C38.3385 33.9114 39.3125 34.9739 39.3568 34.9739C39.6667 35.3281 39.7109 35.7708 39.5339 36.1692C39.401 36.6119 39.0026 36.8333 38.5599 36.8333C36.125 36.8333 34.1771 35.9921 32.849 35.151C32.0521 35.3281 31.2552 35.4166 30.4583 35.4166C26.8281 35.4166 23.6849 33.6458 22.1797 31.0781C22.9323 30.9895 23.6849 30.8125 24.349 30.5468C25.5885 32.2291 27.8464 33.2916 30.4583 33.2916C31.0781 33.2916 31.6979 33.2474 32.362 33.0703L33.2031 32.8932L33.9557 33.3359C34.7083 33.8229 35.4609 34.177 36.2578 34.3984C36.0807 34.0885 35.9036 33.7786 35.7266 33.513L35.0182 32.1406L36.0807 31.0338C36.7448 30.3698 37.5417 29.2187 37.5417 27.625C37.5417 24.7031 34.7083 22.3125 31.1224 22.0026L31.1667 21.9583C31.1667 21.25 31.0339 20.5416 30.8568 19.8776Z"
            fill="white"
          />
        </Svg>
      )}
      {type === 'MESSAGE' && (
        <Svg width={setSize()} height={setSize()} viewBox="0 0 52 52" fill="none">
          <Rect width={52} height={52} fill="#F85A5E" />
          <Path
            d="M20.3134 23.6216C19.3976 23.6216 18.6853 24.3848 18.6853 25.2498C18.6853 26.1656 19.3976 26.878 20.3134 26.878C21.1784 26.878 21.8907 26.1656 21.8907 25.2498C21.8907 24.3848 21.1784 23.6216 20.3134 23.6216ZM26.0121 23.6216C25.0962 23.6216 24.3839 24.3339 24.3839 25.2498C24.3839 26.1148 25.1471 26.8271 26.0121 26.8271C26.8262 26.8271 27.5894 26.0639 27.5894 25.2498C27.5894 24.3848 26.877 23.6216 26.0121 23.6216ZM31.7107 23.6216C30.7949 23.6216 30.0825 24.3848 30.0825 25.2498C30.0825 26.1656 30.8457 26.878 31.7107 26.878C32.5757 26.878 33.288 26.1656 33.288 25.2498C33.3389 24.3848 32.5757 23.6216 31.7107 23.6216ZM26.0121 14.6666C18.787 14.6666 12.9866 19.3985 12.9866 25.2498C12.9866 27.6412 14.0042 29.8799 15.6833 31.6608C14.9201 33.6451 13.3428 35.375 13.3428 35.375C12.9866 35.7312 12.9358 36.24 13.0884 36.6979C13.241 37.2067 13.699 37.4611 14.2078 37.4611C17.3115 37.4611 19.7538 36.1891 21.2802 35.1206C22.7048 35.5786 24.333 35.833 26.0121 35.833C33.1862 35.833 38.9866 31.1011 38.9866 25.3007C38.9866 19.5003 33.1862 14.6666 26.0121 14.6666ZM26.0121 33.3907C24.6383 33.3907 23.2645 33.1872 21.9925 32.7801L20.8222 32.424L19.8555 33.1363C19.1432 33.6451 18.1256 34.2048 16.9044 34.6118C17.3115 34.0013 17.6677 33.2889 17.9221 32.5766L18.4817 31.1519L17.4132 30.0326C16.4974 29.0658 15.4289 27.4377 15.4289 25.2498C15.4289 20.7723 20.1608 17.1089 25.9612 17.1089C31.7616 17.1089 36.4935 20.7723 36.4935 25.2498C36.4935 29.7782 31.8125 33.3907 26.0121 33.3907Z"
            fill="white"
          />
        </Svg>
      )}
    </View>
  )
}

export default CardIcon

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
  },
})
