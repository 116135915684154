/* eslint-disable @typescript-eslint/ban-ts-comment */
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { COLOR, FONT, getRealColor } from '@rocket-mono/libs'
import { FAIcon } from '@rocket/atoms'
import * as React from 'react'
import { Platform, Pressable, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native'
import type { Axis } from './AddPopup'

interface Props {
  iconName?: IconProp
  iconSize?: number
  onPress?: () => void
  onAxis?: React.Dispatch<React.SetStateAction<Axis>>
  style?: ViewStyle
  isWeb?: boolean
  addText?: string
  addTextStyle?: TextStyle
  wide?: boolean
  isIconOnly?: boolean
}

function AxisButton({
  iconName = faPlus,
  onPress,
  onAxis,
  style,
  isWeb,
  addText,
  addTextStyle,
  wide = false,
  isIconOnly = false,
}: Props) {
  const buttonRef = React.useRef<View>(null)
  const onStyle = React.useCallback(
    // @ts-ignore hovered only used in web
    ({ hovered }: PressableStateCallbackType) => {
      return [
        styles.container,
        isIconOnly
          ? {
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-start',
              backgroundColor: '#f8f8f8',
              borderWidth: 0,
            }
          : {},
        style,
        hovered && styles.hover,
        { width: wide ? '100%' : 30 },
        { flexDirection: 'row' },
      ] as ViewStyle[]
    },
    [isIconOnly, style, wide],
  )

  if (Platform.OS === 'web')
    React.useEffect(() => {
      const onScroll = () => {
        if (onAxis) {
          buttonRef?.current?.measureInWindow((x, y) => {
            onAxis({ x, y })
          })
        }
      }

      const onResize = () => {
        onScroll()
        setTimeout(onScroll, 500)
      }
      document.addEventListener('scroll', onScroll)
      window.addEventListener('resize', onResize)
      return () => {
        document.removeEventListener('scroll', onScroll)
        window.removeEventListener('resize', onResize)
      }
    }, [])

  return (
    <Pressable
      onPress={() => {
        onPress && onPress()
        if (onAxis) {
          buttonRef?.current?.measureInWindow((x, y) => {
            onAxis({ x, y })
          })
        }
      }}
      ref={buttonRef}
      style={onStyle}
      onLayout={() => {
        if (onAxis) {
          buttonRef?.current?.measureInWindow((x, y) => {
            onAxis({ x, y })
          })
        }
      }}
    >
      <FAIcon iconName={iconName} size={'xs'} color={'gray.g500'} />
      {addText && !isIconOnly ? (
        <View style={{ marginLeft: 4 }}>
          <Text style={addTextStyle ? addTextStyle : styles.addText}>{addText}</Text>
        </View>
      ) : (
        <></>
      )}
    </Pressable>
  )
}

AxisButton.defaultProps = {
  style: {},
}

export default React.memo(AxisButton)
const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderStyle: 'dotted',
    borderRadius: 5,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: COLOR.gray.g300,
    backgroundColor: COLOR.transparent.base,
  },
  hover: {
    borderColor: COLOR.gray.g400,
    backgroundColor: COLOR.gray.g150,
  },
  addText: {
    ...FONT.txtSm,
    color: getRealColor('mono.paleBlack'),
  },
})
