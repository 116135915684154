import React from 'react'
import { FAIcon } from '@rocket-atoms/icon'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IColors } from '@rocket-mono/libs'

interface Props {
  iconName: IconProp
  iconColor?: IColors
  variant?: 'DEFAULT' | 'FILLED'
  isSelected: boolean | undefined
}
const Icon: React.FC<Props> = ({ iconName, iconColor, variant, isSelected }) => {
  return (
    <FAIcon
      iconName={iconName}
      size={'sm'}
      color={iconColor ? iconColor  : variant === 'FILLED' && isSelected ? 'mono.white' : isSelected ? 'primary.blue500' : 'gray.g300'}
    />
  )
}

export default Icon
