import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { useRecoilValue } from 'recoil'
import { draggingIdState } from '../../../recoil'

import { Droppable } from 'react-beautiful-dnd-grid-support'
import { View } from '@rocket-mono/foundations'
import { useWork } from '@rocket-mono/providers'
import { useMobileView } from '@rui/foundations'

import CreateWorkCard from '../../../components/WorkCard/CreateCard'
import { DROPABLE_CREATE_VIEW_ID, DROPABLE_GROUP_VIEW_ID, workCardConvert } from '../../../utils'
import { Props } from './types'
import WorkspaceMainCardList from '../list'

const WorkspaceMainCreateView: React.FC<Props> = ({ onPressCreateWork, onPressProject }) => {
  const mobileWebView = useMobileView(768)
  const mobileView = useMobileView(500)

  const draggingId = useRecoilValue(draggingIdState)

  const { positionList, projectList, favoriteList, deleteFavorite, addFavorite: createFavorite } = useWork()

  const filteredList = useMemo(() => {
    if (projectList !== undefined && positionList !== undefined)
      return projectList
        .filter(({ id }) => !positionList.map(({ projectId }) => projectId).includes(id))
        .map((res) => {
          return workCardConvert(res, onPressProject, favoriteList, createFavorite, deleteFavorite)
        })
  }, [positionList, projectList, workCardConvert, onPressProject, favoriteList, deleteFavorite, createFavorite])

  const skeleton = useMemo(() => filteredList === undefined, [filteredList])

  return (
    <View style={[styles.workArea, mobileWebView && styles.workAreaMobile]}>
      {!skeleton && (
        <View style={[{ marginBottom: 4 }, mobileWebView && { margin: 4, marginRight: 0 }]}>
          <CreateWorkCard
            onPressCard={onPressCreateWork}
            style={mobileWebView ? { minWidth: '100%', maxWidth: '100%' } : { marginRight: 6 }}
          />
        </View>
      )}
      {projectList !== undefined && (
        <Droppable
          isDropDisabled={[DROPABLE_GROUP_VIEW_ID].includes(draggingId)}
          droppableId={DROPABLE_CREATE_VIEW_ID}
          direction={mobileWebView ? 'vertical' : 'horizontal'}
          isCombineEnabled
        >
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <WorkspaceMainCardList
                list={filteredList ?? []}
                sliderMode={false}
                wrapStyle={
                  mobileView
                    ? { flexDirection: 'column' }
                    : mobileWebView
                    ? { flexWrap: 'wrap', width: '100%', flexDirection: 'row' }
                    : { flexWrap: 'wrap', width: '100%', flexDirection: 'row' }
                }
                containerStyle={mobileWebView ? { width: '100%' } : {}}
                workItemStyle={
                  mobileView
                    ? { width: '100%', maxWidth: '100%', marginBottom: 4 }
                    : mobileWebView
                    ? { minWidth: '100%', maxWidth: '100%', width: 200, marginBottom: 4, marginLeft: 4 }
                    : { marginRight: 4, marginBottom: 4 }
                }
                shortcuts={mobileView ? false : true}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </View>
  )
}

export default WorkspaceMainCreateView

const styles = StyleSheet.create({
  workArea: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 4,
  },
  workAreaMobile: {
    flexDirection: 'column-reverse',
  },
  // shortcutsContainer: {
  //   flexDirection: 'row',
  // },
  // shortcutsWrap: {
  //   padding: 4,
  // },
})
