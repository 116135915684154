import { Provider } from './provider'
import useModalBottom from './hooks'
import View from './view'
/**
 * @deprecated
 */
const ModalBottom = Object.assign(View, {
  Provider: Provider,
})

export { ModalBottom, useModalBottom }
