import * as React from 'react'
import { LayoutChangeEvent, NativeScrollEvent, NativeSyntheticEvent, StyleSheet, View } from 'react-native'

import { PortalHost } from '@gorhom/portal'
import { COLOR, IColors, IconName } from '@rocket-mono/libs'
import { AssigneeIdType, CardDateType, useWorkCard, useWorkChannel, useWorkProject } from '@rocket-mono/providers'
import { CardType, Project } from '@rocket/types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { WorkCardEditBottomOptionType } from '../WorkCardOptionList'
import { WorkCardLayoutBody } from './body'
import WorkCardDetailHeader from './parts/WorkCardDetailHeader'
import WorkCardEditBottom from './parts/WorkCardEditBottom'
import WorkCardEditHeader from './parts/WorkCardEditHeader'

export interface WorkCardModalProps {
  currentProject: Project | null
  edit?: boolean
  isTime: boolean
  cardDate?: CardDateType
  window?: boolean
  children?: (width: number) => React.ReactNode
  title: string
  assigneeIds?: AssigneeIdType[]
  todoTitle?: string
  cardType: CardType
  optionList: WorkCardEditBottomOptionType[]
  sideHeader?: React.ReactNode
  sideView?: React.ReactNode
  discussionView: React.ReactNode
  onChangeTitle?: (title: string) => void
  onChangeAssigneeIds?: (ids: AssigneeIdType[]) => void
  onChangeDate?: (cardDate: CardDateType) => void
  onChangeCardType?: (cardType: CardType) => void
  onPressShare?: () => void
  onPressEtc?: () => void
  onPressEdit?: () => void
  onPressPublish?: () => void
  onPressBack?: () => void
  onPressClose?: () => void
  onPressHold?: () => void
  onPressWindow?: () => void
  onScroll?: (e: NativeSyntheticEvent<NativeScrollEvent>) => void
  onDiscussViewScroll?: (e: NativeSyntheticEvent<NativeScrollEvent>) => void
}

export const WorkCardView: React.FC<WorkCardModalProps> = ({
  currentProject,
  edit = false,
  isTime = false,
  cardDate = {},
  window,
  children,
  title,
  assigneeIds,
  todoTitle,
  cardType,
  optionList,
  sideHeader,
  sideView,
  discussionView,
  onChangeTitle,
  onChangeAssigneeIds,
  onChangeDate,
  onChangeCardType,
  onPressShare,
  onPressEtc,
  onPressEdit,
  onPressPublish,
  onPressBack,
  onPressClose,
  onPressHold,
  onPressWindow,
  onScroll,
  onDiscussViewScroll,
}) => {
  const { t } = useTranslation()

  const { projectMemberList } = useWorkProject()
  const { currentChannel } = useWorkChannel()
  const { currentCard, todoList } = useWorkCard()

  const cardCreatedName = React.useMemo(() => {
    if (currentCard && currentCard.user && currentCard.user.no) {
      const member = projectMemberList?.find((o) => String(o.userId) === String(currentCard.user?.no))
      return member !== undefined ? member.name : currentCard.user.userName
    }
    return currentCard?.user?.userName ?? ''
  }, [currentCard, projectMemberList])
  const cardUpdatedName = React.useMemo(() => {
    if (currentCard && currentCard?.updateUserNo) {
      const member = projectMemberList?.find((o) => String(o.userId) === String(currentCard.updateUserNo))
      return member !== undefined ? member.name : currentCard.updateUserName
    }
  }, [currentCard, projectMemberList])

  const channelColor = React.useMemo(() => {
    console.log('channelColor', currentProject, currentChannel)
    if (currentProject === null || currentProject === undefined) return undefined
    const isWFP = currentProject.type.code === 'WFP'
    const element = currentProject?.elements.find(({ id }) => id === currentChannel?.projectElementId)
    if (element === undefined) return undefined

    if (isWFP) {
      const isFirst = element.order === 0
      const isLast = element.order === element.maxOrder
      if (isFirst) {
        return 'main.yellow' as IColors
      } else if (isLast) {
        return 'main.blue' as IColors
      } else {
        return 'main.turquoise' as IColors
      }
    } else {
      return element.labelObject as IColors
    }
  }, [currentProject, currentChannel])

  const [layoutWidth, setLayoutWidth] = React.useState(0)

  const onLayout = React.useCallback((e: LayoutChangeEvent) => {
    setLayoutWidth(e.nativeEvent.layout.width)
  }, [])

  const progress = React.useMemo(() => {
    if (currentCard === null) return undefined

    if (currentCard.type !== 'MISSION') return undefined

    if (todoList === undefined) return undefined

    const totalTodos = todoList.length
    const doneTodos = todoList.filter(({ phaseInfo }) => phaseInfo?.phase === 'COMPLETED').length
    let percent: number
    if (totalTodos === 0) {
      percent = 0
    } else if (doneTodos > totalTodos) {
      percent = 0
    } else if (doneTodos == totalTodos) {
      percent = 100
    } else {
      percent = Math.round((doneTodos * 100) / totalTodos)
    }

    return percent
  }, [currentCard, todoList])

  const [ifScroll, setIfScroll] = React.useState(0)

  const dataIcon = [
    {
      labelIcon: {
        name: 'check-square-o' as IconName,
        size: 12,
        color: 'gray.g700' as IColors,
      },
      label: t('system.atask'),
      key: 'MISSION',
    },
    {
      labelIcon: {
        name: 'calendar' as IconName,
        size: 12,
        color: 'gray.g700' as IColors,
      },
      label: t('system.ameeting'),
      key: 'ATTEND',
    },
    {
      labelIcon: {
        name: 'folder-check-o' as IconName,
        size: 12,
        color: 'gray.g700' as IColors,
      },
      label: t('system.asurvey'),
      key: 'COLLECTION',
    },
  ]

  const handleScroll = React.useCallback(
    (e: NativeSyntheticEvent<NativeScrollEvent>) => {
      const contentOffsetY = e.nativeEvent.contentOffset.y
      setIfScroll(contentOffsetY)
      onScroll && onScroll(e)
    },
    [onScroll],
  )

  return (
    <View style={styles.headerWrap} onLayout={onLayout}>
      {cardType !== 'TODO' && <PortalHost name="search-card" />}
      {edit ? (
        <WorkCardEditHeader
          window={window}
          projectType={currentProject?.type?.code ?? ''}
          projectName={currentProject?.title ?? ''}
          channelName={currentChannel?.roomName ?? ''}
          channelColor={channelColor}
          onPress={onPressPublish}
          onPressEtc={onPressEtc}
          onPressWindow={onPressWindow}
          onPressClose={onPressClose}
          onPressHide={onPressHold}
        />
      ) : !edit && currentCard !== null ? (
        <WorkCardDetailHeader
          currentProjectId={currentProject?.id ?? ''}
          window={window}
          projectType={currentProject?.type?.code ?? ''}
          projectName={currentProject?.title ?? ''}
          channelName={currentChannel?.roomName ?? ''}
          channelColor={channelColor}
          todoTitle={todoTitle}
          cardId={currentCard.no}
          cardType={cardType}
          cardTitle={currentCard.title}
          cardProgress={progress}
          cardCreatedAt={moment(currentCard.regDate).toDate()}
          cardCreatedName={cardCreatedName}
          cardUpdatedAt={cardUpdatedName ? moment(currentCard.modDate).toDate() : undefined}
          cardUpdatedName={cardUpdatedName}
          offsetTop={ifScroll}
          onPressWindow={onPressWindow}
          onPressEdit={onPressEdit}
          onPressShare={onPressShare}
          onPressEtc={onPressEtc}
          onPressBack={onPressBack}
          onPressClose={onPressClose}
          onPressHide={onPressHold}
        />
      ) : (
        <></>
      )}
      <WorkCardLayoutBody
        edit={edit}
        isTime={isTime}
        cardDate={cardDate}
        iconList={optionList}
        cardType={cardType}
        dataIcon={dataIcon}
        onChangeType={
          edit && !currentCard
            ? ({ key }) => {
                onChangeCardType && onChangeCardType(key as CardType)
              }
            : undefined
        }
        title={title}
        assigneeIds={assigneeIds}
        onChangeTitle={onChangeTitle}
        onChangeAssigneeIds={onChangeAssigneeIds}
        onChangeDate={onChangeDate}
        wrapWidth={layoutWidth}
        sideHeader={sideHeader}
        sideView={sideView}
        discussionView={discussionView}
        onScroll={handleScroll}
        onDiscussViewScroll={onDiscussViewScroll}
      >
        {children?.(layoutWidth)}
      </WorkCardLayoutBody>
      {edit && (
        <WorkCardEditBottom
          bottomOffset={0}
          iconList={optionList}
          flexDirection={{ flexDirection: 'row' }}
          gap={{ marginRight: 8 }}
          onPressPublish={onPressPublish}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  headerWrap: {
    height: '100%',
    overflow: 'hidden',
  },
  divLine: {
    width: '100%',
    height: 1,
    backgroundColor: COLOR.gray.g150,
  },
})
