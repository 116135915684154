import React from 'react'

import { faCopy, faLink } from '@fortawesome/free-solid-svg-icons'
import { COLOR } from '@rocket-mono/libs'
import { FAIcon, Tooltip } from '@rocket/atoms'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Defs, LinearGradient, Rect, Stop, Svg } from 'react-native-svg'

interface Props {
  type: 'COPY' | 'SHARE'
  isOrigin?: boolean
  originSource?: string
}
const CardLabel: React.FC<Props> = ({ type, isOrigin, originSource }) => {
  const { t } = useTranslation()

  return (
    <Tooltip
      gab={0}
      darkType={true}
      direction={'bottom-center'}
      text={
        type === 'COPY'
          ? `${t('board.card.copiedlabel', { boardName: originSource })}`
          : `${isOrigin ? originSource : t('board.card.sharedlabel', { boardName: originSource })}`
      }
    >
      {(props) => (
        <View
          {...props}
          style={[
            styles.wrap,
            type === 'SHARE' && { backgroundColor: `rgba(35,49,72, .8)` },
            type === 'SHARE' && isOrigin && { backgroundColor: COLOR.main.redHsb },
          ]}
        >
          <View style={{ position: 'absolute', left: 0 }}>
            <Svg width={4} height={20} viewBox="0 0 4 20" fill="none">
              <Rect width={4} height={20} fill="url(#paint0_linear_313_12945)" />
              <Defs>
                <LinearGradient
                  id="paint0_linear_313_12945"
                  x1={-7.63683e-8}
                  y1={8.75}
                  x2={4}
                  y2={8.75}
                  gradientUnits="userSpaceOnUse"
                >
                  <Stop stopColor="#1C2B40" stopOpacity={0.3} />
                  <Stop offset={1} stopColor="#1C2B40" stopOpacity={0.05} />
                </LinearGradient>
              </Defs>
            </Svg>
          </View>
          <FAIcon iconName={type === 'COPY' ? faCopy : faLink} size={'2xs'} color={'mono.white'} />
        </View>
      )}
    </Tooltip>
  )
}

export default CardLabel

const styles = StyleSheet.create({
  wrap: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 17,
    height: 20,
    marginBottom: 2,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: '#97A4AC',
  },
})
