import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import {
  cardLayoutPayloadState,
  sideLayoutTypeState,
  unreadMessageCountState,
  unreadNotificationCountState,
} from '../../recoils'
import { LayoutSideView } from '@rocket-screens/layout'
import { MessageListScreen, NotificationListScreen } from '@rocket-mono/screen-work'
import { useWindowDimensions } from 'react-native'
import { TOP_SIZE } from './top'
import { useAstro, useCurrentUser, usePreview, useSubscription, useWork } from '@rocket-mono/providers'
import { useTranslation } from 'react-i18next'

import { faBell, faCommentDots } from '@fortawesome/free-regular-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { SearchScreen, SearchScreenProvider } from '@rocket-screens/search'
import { useLocation, useParams } from 'react-router-dom'

const Side: React.FC = () => {
  const { projectId } = useParams()
  const { t, i18n } = useTranslation()
  const { height } = useWindowDimensions()
  const { pathname } = useLocation()

  const { currentUser } = useCurrentUser()
  const { astro, astroNav, option } = useAstro()
  const { projectList } = useWork()
  const { openPreview } = usePreview()

  const [cardLayoutPayload, setCardLayoutPayload] = useRecoilState(cardLayoutPayloadState)
  const [sideLayoutType, setSideLayoutType] = useRecoilState(sideLayoutTypeState)
  const setUnreadMessageCount = useSetRecoilState(unreadMessageCountState)
  const setUnreadNotificationCount = useSetRecoilState(unreadNotificationCountState)

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(!!sideLayoutType)
  }, [sideLayoutType])

  useEffect(() => {
    if (visible && cardLayoutPayload && !cardLayoutPayload.isFold) {
      setCardLayoutPayload((prev) => (prev !== undefined ? { ...prev, isFold: true } : undefined))
    }
  }, [visible, cardLayoutPayload])

  const handleClose = useCallback(() => {
    setSideLayoutType('')
  }, [])

  const handlePressWorkCreate = useCallback(() => {
    astroNav.emit('project', {
      type: 'create_position',
      params: {},
    })
  }, [])

  return (
    <>
      <LayoutSideView
        theme={pathname === '/' ? 'dark' : 'light'}
        title={t('placesearch.search')}
        icon={faSearch}
        isShow={sideLayoutType === 'search'}
        height={height - TOP_SIZE}
        onClose={handleClose}
      >
        <SearchScreenProvider
          i18n={i18n}
          astro={astro}
          cdnUrl={option.secureCdnUrl ?? ''}
          currentUser={currentUser}
          defaultProjectId={projectId}
          projectList={projectList}
          subscribe={useSubscription}
          openProject={(payload) => {
            astroNav.emit('openProject', payload)
          }}
          openCard={(payload) => {
            astroNav.emit('openCard', payload)
          }}
          openChat={(payload) => {
            astroNav.emit('openChat', payload)
          }}
          openPreview={openPreview}
          onDownload={(url, saveTo) => {
            // astro.fileDownload({ fileKey, filename, originalFilename })
            fetch(`${url}`, {
              method: 'GET',
              headers: { 'Cache-Control': 'no-cache' },
            })
              .then((res) => res.blob())
              .then((blob) => {
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.style.display = 'none'
                a.href = url
                a.download = saveTo ?? ''
                document.body.appendChild(a)
                a.click()
                window.URL.revokeObjectURL(url)
              })
              .catch((err) => {
                return Promise.reject(err)
              })
          }}
        >
          <SearchScreen />
        </SearchScreenProvider>
      </LayoutSideView>
      <LayoutSideView
        theme={pathname === '/' ? 'dark' : 'light'}
        title={t('screen.chats.title')}
        icon={faCommentDots}
        isShow={sideLayoutType === 'message'}
        height={height - TOP_SIZE}
        onClose={handleClose}
      >
        <>
          <MessageListScreen
            i18n={i18n}
            isShow
            setUnreadCount={setUnreadMessageCount}
            onPressButtonCreate={handlePressWorkCreate}
          />
        </>
      </LayoutSideView>
      <LayoutSideView
        theme={pathname === '/' ? 'dark' : 'light'}
        title={t('notification.title')}
        icon={faBell}
        isShow={sideLayoutType === 'bell'}
        height={height - TOP_SIZE}
        onClose={handleClose}
      >
        <>
          <NotificationListScreen i18n={i18n} isShow setUnreadCount={setUnreadNotificationCount} />
        </>
      </LayoutSideView>
    </>
  )
}

export default Side
