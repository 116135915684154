import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { StyleSheet, View, Text, TextInput, LayoutChangeEvent } from 'react-native'
import { COLOR, FONT } from '@rui/foundations'
import { Select, ClickOutside } from '@rocket-mono/foundations'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'
import OptionButton from '../WorkCard/OptionButton'

import { SkeletonBar } from '@rocket/skeleton'

interface Props {
  defaultInputFocus?: boolean
  skeleton?: boolean
  title: string
  onChangeTitle: (text: string) => void
  onPressDelete: () => void
  inDraggable?: boolean
  isDraggingOver?: boolean
  children: React.ReactNode
}
const WorkCardGroup = ({
  defaultInputFocus = false,
  skeleton,
  title,
  onChangeTitle,
  onPressDelete,
  inDraggable = false,
  isDraggingOver = false,
  children,
}: Props) => {
  const { t } = useTranslation()
  const [value, setValue] = useState('')
  const [inputFocus, setInputFocus] = useState(defaultInputFocus)
  const [visibleMore, setVisibleMore] = useState(false)
  const [moreButtonArea, setMoreButtonArea] = useState(0)

  const onLayout = React.useCallback(
    (e: LayoutChangeEvent) => {
      const { layout } = e.nativeEvent
      setMoreButtonArea(layout.x)
    },
    [visibleMore],
  )

  const handleBlur = useCallback(() => {
    console.log('value-blur', value)
    if (value.length > 0) {
      onChangeTitle(value)
    }
    setInputFocus(false)
  }, [value, onChangeTitle])

  useEffect(() => {
    if (inputFocus) setValue(title)
  }, [title, inputFocus])

  useEffect(() => {
    console.log('value', value)
  }, [value])

  return (
    <View
      style={[
        styles.container,
        inputFocus && { borderColor: COLOR.primary.blue400 },
        inDraggable && { borderColor: COLOR.gray.g500 },
        isDraggingOver && { borderColor: COLOR.mono.white },
        skeleton && { borderWidth: 0, backgroundColor: COLOR.darkmode.warm800 },
      ]}
    >
      {skeleton ? (
        <SkeletonBar width={150} height={15} color={'darkmode.warm600'} />
      ) : (
        <View style={styles.titleArea}>
          {inputFocus ? (
            <View style={{ backgroundColor: COLOR.transparent.base }}>
              <TextInput
                autoFocus
                value={value}
                onChangeText={setValue}
                onSubmitEditing={() => {
                  onChangeTitle(value)
                  setInputFocus(false)
                }}
                style={[
                  styles.input,
                  inputFocus && { backgroundColor: COLOR.gray.g900, borderColor: COLOR.primary.blue500 },
                ]}
                placeholderTextColor={'gray.g500'}
                placeholder={t('workspace.newgroup')}
                onBlur={handleBlur}
              />
            </View>
          ) : (
            <View style={styles.titleLabel}>
              <Text style={styles.titleLabelText} numberOfLines={1} ellipsizeMode="tail">
                {title}
              </Text>
            </View>
          )}
          <View onLayout={onLayout}>
            <OptionButton iconName={faEllipsis} iconColor={'darkmode.cool300'} onPress={() => setVisibleMore(true)} />
          </View>
        </View>
      )}
      <View style={styles.listArea}>{children}</View>

      {visibleMore && (
        <View
          style={{
            position: 'absolute',
            top: 40,
            right: moreButtonArea < 130 ? -120 : 8,
            zIndex: 99,
          }}
        >
          <ClickOutside onClickOutside={() => setVisibleMore(false)}>
            <Select
              visible
              list={[
                {
                  beforeDiv: false,
                  afterDiv: true,
                  group: [
                    {
                      type: 'COMMON',
                      label: t('workspace.groupedit'),
                      onPress: () => {
                        setVisibleMore(false)
                        setInputFocus(true)
                      },
                    },
                    {
                      type: 'COMMON',
                      label: t('workspace.ungroup'),
                      onPress: () => {
                        setVisibleMore(false)
                        onPressDelete()
                      },
                    },
                  ],
                },
              ]}
            />
          </ClickOutside>
        </View>
      )}
    </View>
  )
}

export default WorkCardGroup

const styles = StyleSheet.create({
  container: {
    // alignSelf:'flex-start',
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingTop: 12,
    paddingBottom: 8,
    borderWidth: 1,
    borderColor: COLOR.gray.g800,
  },
  titleArea: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  learnMoreButton: {
    width: 12,
    height: 12,
  },
  input: {
    flex: 1,
    height: 28,
    marginRight: 8,
    borderRadius: 4,
    paddingHorizontal: 8,
    paddingVertical: 8,
    borderWidth: 1,
    borderColor: COLOR.transparent.base,
    ...FONT.txtSm,
    color: COLOR.mono.white,
  },
  titleLabel: {
    flex: 1,
    height: 28,
  },
  titleLabelText: {
    flex: 1,
    ...FONT.txtSm,
    marginRight: 12,
    lineHeight: 28,
    color: COLOR.mono.white,
  },
  listArea: {
    marginTop: 8,
  },
})
