import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useToast } from '@rocket/components'
import { useAstro, useModal, useWork } from '@rocket-mono/providers'
import { WorkmodelCreateScreen, WorkmodelEditScreen } from '@rocket-mono/screen-work'
import { CreateWorkScreen } from '@rocket-mono/screen-work'
import { Project } from '@rocket/types'
import { useTranslation } from 'react-i18next'

export const WorkmodelCreateModal: React.FC = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { astroNav } = useAstro()

  const { showToastMessage } = useToast()
  const { addRecent } = useWork()

  const {
    Modal: CreateModal,
    visible: createVisible,
    open: createOpen,
    close: createClose,
  } = useModal('WorkmodelCreateModal')

  const {
    Modal: CreateWorkModal,
    visible: createWorkVisible,
    open: createWorkOpen,
    close: createWorkClose,
  } = useModal('WorkmodelCreateWorkModal')

  const {
    Modal: UpdateModal,
    visible: updateVisible,
    open: updateOpen,
    close: updateClose,
  } = useModal('WorkmodelUpdateModal')

  const [workType, setWorkType] = useState<string>('')

  const handlePressCreateWork = useCallback((type: string) => {
    setWorkType(type)
  }, [])

  const [projectId, setProjectId] = useState<string>()

  useEffect(() => {
    astroNav.on('project', ({ payload }) => {
      const { type, params } = payload

      if (type === 'create_position') {
        if (params.type) {
          setWorkType(params.type)
        } else {
          createOpen()
        }
      } else if (type === 'update_position') {
        setProjectId(params.projectId)
        updateOpen()
      }
    })
  }, [])

  const handleHome = useCallback(() => {
    updateClose()
    navigate('/')
  }, [])

  const handlePressBack = useCallback(() => {
    setWorkType('')
  }, [])
  const handleSaveSuccess = useCallback((project: Project) => {
    showToastMessage({
      message: t('toast.newwork'),
      viewPosition: 'TOP_CENTER',
    })
    setWorkType('')
    createClose()

    setTimeout(() => {
      addRecent(project.id)
      navigate(`/project/${project.id}/${project.type.code === 'TOP' ? 'pos' : 'board'}`)
    }, 500)
  }, [])
  const handleSaveFailed = useCallback((message: string) => {
    showToastMessage({
      message,
      type: 'error',
      viewPosition: 'BOTTOM_CENTER',
      viewOffset: 100,
    })
  }, [])

  useEffect(() => {
    if (workType) {
      createWorkOpen()
    } else {
      createWorkClose()
    }
  }, [workType])

  const handleUpdateSuccess = useCallback(() => {
    const duration = 3000
    showToastMessage({
      message: t('screen.workmodelupdate.toast.confirmed'),
      viewPosition: 'TOP_RIGHT',
      duration,
    })
    updateClose()
  }, [])

  return (
    <>
      <CreateModal visible={createVisible}>
        <WorkmodelCreateScreen i18n={i18n} onPressClose={createClose} onPressCreateWork={handlePressCreateWork} />
      </CreateModal>
      <CreateWorkModal
        modalType="full"
        animationType="none"
        visible={createWorkVisible}
        containerStyle={{ minWidth: '100%', width: '100%' }}
      >
        <CreateWorkScreen
          i18n={i18n}
          typeCode={workType}
          onPressBack={handlePressBack}
          onSaveFailed={handleSaveFailed}
          onSaveSuccess={handleSaveSuccess}
        />
      </CreateWorkModal>

      <UpdateModal visible={updateVisible}>
        {projectId !== undefined ? (
          <WorkmodelEditScreen
            i18n={i18n}
            projectId={projectId}
            onPressClose={updateClose}
            onBack={updateClose}
            onSuccess={handleUpdateSuccess}
            goHome={handleHome}
          />
        ) : (
          <></>
        )}
      </UpdateModal>
    </>
  )
}
