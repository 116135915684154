import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { View, Animated, DimensionValue, StyleSheet, useWindowDimensions, Easing } from 'react-native'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCommentDots } from '@fortawesome/free-regular-svg-icons'

import SideViewHeader from './SideViewHeader'
import { COLOR, getBoxShadow } from '@rocket-mono/libs'
import Providers from '../providers'

interface LayoutSideViewType {
  theme?: 'dark' | 'light'
  title?: string
  icon?: IconProp
  isShow: boolean
  onShow?: () => void
  onClose?: () => void
  height?: DimensionValue
  children: JSX.Element
}

export const LayoutSideView: FC<LayoutSideViewType> = ({
  theme,
  title = '메세지',
  icon = faCommentDots,
  isShow,
  onShow,
  onClose,
  height = '100%',
  children,
}) => {
  const [show, setShow] = useState(false)
  const { width } = useWindowDimensions()

  const layoutWidth = useMemo(() => {
    if (width < 440) return width - 40
    return 440
  }, [width])

  const duration = 400
  const fadeAnim = React.useRef(new Animated.Value(isShow ? layoutWidth : 0)).current
  const fadeRight = React.useRef(new Animated.Value(isShow ? 0 : -layoutWidth)).current
  const colorAnim = useRef(new Animated.Value(0)).current

  const colorChange = colorAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [`rgba(255,255,255, 1)`, `rgba(30,30,31, 1)`],
  })

  const handleShow = useCallback(() => {
    setShow(true)
    Animated.timing(fadeAnim, {
      toValue: layoutWidth,
      duration,
      useNativeDriver: true,
    }).start()
    Animated.timing(fadeRight, {
      toValue: 0,
      duration,
      useNativeDriver: true,
    }).start()
    setTimeout(() => {
      onShow && onShow()
    }, duration)
  }, [layoutWidth])

  const handleHide = useCallback(() => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration,
      useNativeDriver: true,
    }).start()
    Animated.timing(fadeRight, {
      toValue: -layoutWidth,
      duration,
      useNativeDriver: true,
    }).start()
  }, [])

  const handleClose = useCallback(() => {
    handleHide()
    setTimeout(() => {
      setShow(false)
      onClose && onClose()
    }, duration)
  }, [onClose])

  React.useEffect(() => {
    if (isShow) {
      handleShow()
    } else {
      handleHide()
    }
  }, [handleShow, handleHide, isShow])

  const handleAnimation = () => {
    if (theme === 'dark') {
      Animated.timing(colorAnim, {
        toValue: 1,
        duration: 300,
        easing: Easing.linear,
        useNativeDriver: true,
      }).start()
    } else {
      Animated.timing(colorAnim, {
        toValue: 0,
        duration: 300,
        easing: Easing.linear,
        useNativeDriver: true,
      }).start()
    }
  }

  useEffect(() => {
    handleAnimation()
  }, [theme])

  return (
    <Providers>
      {show && <Animated.View style={{ width: fadeAnim, backgroundColor: colorChange }} />}
      <Animated.View
        style={[
          styles.container,
          show && { right: fadeRight, width: layoutWidth, height, borderLeftWidth: 1, paddingTop: 10 },
          show && getBoxShadow(0, 0, 0, 0.05, 5, -7, 5, 0),
        ]}
      >
        <View style={[{ flex: 1 }]}>
          <SideViewHeader iconName={icon} title={title} onPressClose={handleClose} />
          {children}
        </View>
      </Animated.View>
    </Providers>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    overflow: 'hidden',
    width: 0,
    right: 0,
    borderColor: COLOR.gray.g150,
    backgroundColor: COLOR.mono.white,
  },
})
