import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Animated, Platform, SectionList, SectionListData, SectionListRenderItemInfo, StyleSheet } from 'react-native'

import { faUser } from '@fortawesome/free-regular-svg-icons'
import { faGear, faRocket } from '@fortawesome/free-solid-svg-icons'
import { View } from '@rocket-mono/foundations'
import { COLOR } from '@rocket-mono/libs'
import { LayoutSideNavProps, LayoutSideNavWorkItemType, LayoutSideNavWorkType } from '../types'
import { CapacityArea } from './CapacityArea'
import { InfoLinkButton } from './InfoLinkButton'
import WorkGroup from './WorkGroup'
import WorkGroupButton from './WorkGroupButton'
import { WorkGroupSection } from './WorkGroupSection'
import { Button, Text } from '@rocket/atoms'

const SECTION_END_BUTTON_COUNT = 1
const SECTION_END_CONTAINER_HEIGHT = 32 * SECTION_END_BUTTON_COUNT

const LayoutSideNav = ({
  width = 220,
  team,
  workInfo,
  onTeamMembers,
  onTeamManage,
  onWorkCreate,
  onWork,
  // onFavorite,
  onGuest,
  onPlanManage,
  guestList,
  storageUse,
  storageMax,
}: LayoutSideNavProps) => {
  const { t } = useTranslation()
  const animation = React.useRef(new Animated.Value(width)).current

  React.useEffect(() => {
    startAnimation()
  }, [width])

  const startAnimation = () => {
    const toValue = width === 0 ? 0 : 1
    Animated.timing(animation, {
      toValue,
      duration: 300,
      // easing: Animated.Easing.ease,
      useNativeDriver: false,
    }).start()
  }

  const widthInterpolation = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 220],
  })

  const initialShowMap = React.useCallback(
    (workInfo: LayoutSideNavWorkType[]) => {
      if (workInfo.length === 0) return {}
      return workInfo.map((o) => ({ [o.key]: o.isShow })).reduce((a, b) => ({ ...a, ...b }))
    },
    [workInfo],
  )

  const [showMap, setShowMap] = React.useState<{ [key: string]: boolean }>()

  React.useEffect(() => {
    setShowMap(initialShowMap(workInfo))
  }, [])

  const renderSection = React.useCallback(
    ({ section }: { section: SectionListData<LayoutSideNavWorkItemType, LayoutSideNavWorkType> }) => {
      return (
        <WorkGroupSection
          label={section.label}
          key={section.key}
          initIsShow={section.isShow}
          onPress={(isShow) => {
            setShowMap((prev) => ({
              ...prev,
              [section.key]: isShow,
            }))
          }}
        />
      )
    },
    [],
  )

  const renderSectionItem = React.useCallback(
    ({ item, section }: SectionListRenderItemInfo<LayoutSideNavWorkItemType, LayoutSideNavWorkType>) => {
      if (showMap === undefined || !showMap[section.key]) return <></>
      return <WorkGroup workData={item} onPress={onWork} />
    },
    [onWork, showMap],
  )

  const ListFooterComponent = React.useCallback(
    () => (
      <>
        {guestList.length > 0 ? (
          <WorkGroupButton
            text={t('layoutsidenav.guest') /*'게스트 참여'*/}
            iconName={faRocket}
            size={12}
            color="sub.paleBlue"
            onPress={onGuest}
          />
        ) : (
          <></>
        )}
        {/* <WorkGroupButton
          text={t('layoutsidenav.archive') '종료된 워크'}
          iconName={faBoxArchive}
          size={12}
          color={getRealColor('gray.g450')}
          onPress={onArchive}
        /> */}
      </>
    ),
    [],
  )

  return (
    <Animated.View
      style={[
        styles.container,
        {
          width: widthInterpolation,
        },
      ]}
    >
      <View style={styles.topArea}>
        {team && (
          <View style={styles.teamInfo}>
            <Text fontName="txtSm" fontColor="mono.white" style={styles.teamName}>
              {team.name}
            </Text>
            <InfoLinkButton
              text={
                t('layoutsidenav.teammember', {
                  count: team.members,
                }) /*`멤버 ${team.members}`*/
              }
              iconName={faUser}
              size={12}
              color={'gray.g400'}
              onPress={onTeamMembers}
            />
            <View style={{ marginTop: 4 }}>
              <InfoLinkButton
                text={t('layoutsidenav.membermanage')}
                iconName={faGear}
                size={12}
                color={'gray.g400'}
                onPress={onTeamManage}
              />
            </View>
          </View>
        )}

        <View style={styles.flexBox}>
          <View style={{ flex: 1 }}>
            <Button
              wide
              type="SECONDARY"
              variant="LINE"
              onPress={onWorkCreate}
              style={{ backgroundColor: COLOR.opacity.w10 }}
              text={
                <Text fontName="txtXs" fontColor="mono.white">
                  {t('layoutsidenav.newwork')}
                </Text>
              }
            />
          </View>
          {/* {width !== 0 ? (
            <Button
              backgroundColor="opacity.w10"
              style={{ minWidth: 32, width: 32, marginLeft: 4 }}
              onPress={onWorkCreate}
            >
              <FontAwesomeIcon
                icon={faNoteSticky}
                size={16}
                color={getRealColor('gray.g150')}
              />
            </Button>
          ) : (
            <></>
          )} */}
        </View>
      </View>
      <View style={styles.listArea}>
        {/* <WorkGroupButton
          text={t('layoutsidenav.favorite')}
          iconName={faStar}
          size={12}
          color={getRealColor('main.yellow')}
          onPress={onFavorite}
        /> */}
        <View style={styles.innerList}>
          <SectionList
            sections={workInfo}
            keyExtractor={(_, index) => `worklist-${index}`}
            stickySectionHeadersEnabled
            renderSectionHeader={renderSection}
            renderItem={renderSectionItem}
            ListFooterComponent={ListFooterComponent}
          />
        </View>
      </View>
      <View>
        <CapacityArea storageUse={storageUse} storageMax={storageMax} onPress={onPlanManage} />
      </View>
    </Animated.View>
  )
}

export default LayoutSideNav

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    overflow: 'hidden',
    maxWidth: 220,
    flex: 1,
    flexDirection: 'column',
    ...Platform.select({
      web: {
        height: '100%',
      },
      native: {
        height: '100%',
      },
    }),
    width: 220,
    backgroundColor: COLOR.darkmode.warm800,
    paddingTop: 12,
  },
  topArea: {
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  teamInfo: {
    padding: 12,
    backgroundColor: 'rgba(0,0,0, .5)',
    borderRadius: 4,
    marginBottom: 8,
  },
  teamName: {
    paddingBottom: 8,
  },
  listArea: {
    flex: 1,
    position: 'relative',
  },
  innerList: {
    flexGrow: 0,
    flexBasis: 'auto',
    ...Platform.select({
      web: {
        flex: 1,
      },
      native: {
        height: '100%',
        paddingBottom: SECTION_END_CONTAINER_HEIGHT,
      },
    }),
  },
})
