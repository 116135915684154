import React from 'react'
import { View } from 'react-native'
import Item from './item'
import { RuleListItemType } from './types'

interface Props {
  list: RuleListItemType[]
}

const List: React.FC<Props> = ({ list }) => {
  return (
    <View>
      {list.map((item, idx) => (
        <Item key={`item-${idx}`} item={item} />
      ))}
    </View>
  )
}

export default List
