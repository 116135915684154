import { useContext } from 'react'

import { ContextProps } from './types'
import { AuthContext } from '.'

export function useAuth() {
  const context = useContext<ContextProps | undefined>(AuthContext)

  if (context === undefined) throw new Error('There must be a AuthProvider as Ancestor of all Auth Hooks and HOCs')

  return context
}
