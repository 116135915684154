import React from 'react'
import { StyleSheet, View } from 'react-native'
import { SkeletonBar, SkeletonCircle, SkeletonSquare } from '../SkeletomParts'
import { COLOR } from '@rocket-mono/libs'

const SkeletonBoardCard: React.FC = () => {
  return(
    <View style={styles.wrap}>
      <View style={styles.flexbox}>
        <SkeletonBar width={'40%'} height={20} />
        <SkeletonCircle width={20} height={20} color={'gray.g200'} />
      </View>
      <View style={[styles.flexbox, styles.summary]}>
        <SkeletonBar width={'80%'} height={12} />
        <SkeletonSquare width={34} height={20} color={'gray.g100'}/>
      </View>
    </View>
  )
}

export default SkeletonBoardCard

const styles = StyleSheet.create({
  wrap:{
    width:'100%',
    backgroundColor:COLOR.mono.white,
    borderRadius: 8,
    padding: 12
  },
  flexbox:{
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'center'
  },
  summary:{
    marginTop: 8
  }
})