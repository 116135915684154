import { Text } from '@rocket-atoms/text'
import React from 'react'
import { Text as RNText } from 'react-native'

export const MENTION_TEMPLATE = '<m chatRoomMemberNo=(?<chatRoomMemberNo>\\d+|all), memberName="(?<memberName>[^"]+)">'

export type MessageTextReplacer = (
  source: string,
  alias: string,
  index: number,
  ref?: React.RefObject<RNText>,
) => JSX.Element

export const mentionReplacer: MessageTextReplacer = (source, alias, index) => {
  console.log('mentionReplacer', source, alias, index)
  const { chatRoomMemberNo: memberNo, memberName } = new RegExp(MENTION_TEMPLATE).exec(source)?.groups ?? {
    chatRoomMemberNo: '',
    memberName: '',
  }

  if (memberNo && memberName) {
    return (
      <Text key={`mention-${memberNo}-${alias}-${index}`} fontName="chatTextBold" fontColor="primary.blue500">
        @{memberName}
      </Text>
    )
  } else {
    return <Text key={`token-${alias}-${index}`}>{source}</Text>
  }
}
