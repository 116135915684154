import type { IChatMessageReply, IFile, IUser, MessageType } from '@rocket-mono/libs'
import * as React from 'react'
import type { ImageSourcePropType, ViewStyle } from 'react-native'
import type { AppList } from '../AddPopup'
import FileItem from './item/file'
import MsgItem from './item/msg'
import NoticeItem from './item/notice'
import WhisperItem from './item/whisper'
import { ItemMoreButtonType } from './types'

interface Props {
  type: MessageType
  image: ImageSourcePropType
  userName: string
  mentionUserName?: string
  message: string
  messageSymbol?: string | null
  containerStyle?: ViewStyle
  replyContainerStyle?: ViewStyle
  moreList?: AppList[]
  regDate: string
  chatMessageReply?: IChatMessageReply[]
  file?: IFile
  onPopupState: (state: string) => void
  onPopupReplyState?: ({
    parentIndex,
    index,
    state,
    item,
  }: {
    parentIndex: number
    index: number
    state: string
    item?: IChatMessageReply
  }) => void
  onFileShare?: (item?: IFile) => void
  onFileExport?: (item?: IFile) => void
  onFileDownload?: (item?: IFile) => void
  onFilePreview?: (item?: IFile) => void
  writer?: IUser
  recipient?: IUser
  deleted: boolean
  selected: boolean
  replySelectedIndex?: string
  onLinkPress?: (url: string, text?: string | undefined) => void
  index: number
  onPressMoreButton?: (payload: ItemMoreButtonType) => void
}

const DiscussionItem: React.FC<Props> = ({
  type,
  image,
  userName,
  mentionUserName,
  message,
  messageSymbol,
  containerStyle,
  replyContainerStyle,
  moreList,
  regDate,
  chatMessageReply,
  file,
  onPopupState,
  onPopupReplyState,
  onFileShare,
  onFileExport,
  onFileDownload,
  onFilePreview,
  writer,
  recipient,
  deleted,
  selected,
  replySelectedIndex,
  onLinkPress,
  index,
  onPressMoreButton,
}) => {
  if (deleted) {
    return (
      <MsgItem
        image={image}
        userName={userName}
        onPopupState={onPopupState}
        onPopupReplyState={onPopupReplyState}
        mentionUserName={mentionUserName}
        message={message}
        messageSymbol={messageSymbol}
        regDate={regDate}
        moreList={moreList}
        containerStyle={containerStyle}
        replyContainerStyle={replyContainerStyle}
        chatMessageReply={chatMessageReply}
        deleted={deleted}
        selected={selected}
        replySelectedIndex={replySelectedIndex}
        onLinkPress={onLinkPress}
        topIndex={index}
        onPressMoreButton={onPressMoreButton}
      />
    )
  }
  if (type === 'msg' && recipient) {
    return (
      <WhisperItem
        image={image}
        userName={userName}
        onPopupState={onPopupState}
        onPopupReplyState={onPopupReplyState}
        message={message}
        regDate={regDate}
        moreList={moreList}
        containerStyle={containerStyle}
        replyContainerStyle={replyContainerStyle}
        chatMessageReply={chatMessageReply}
        writer={writer}
        recipient={recipient}
        selected={selected}
        replySelectedIndex={replySelectedIndex}
        onLinkPress={onLinkPress}
        topIndex={index}
        onPressMoreButton={onPressMoreButton}
      />
    )
  } else if (type === 'msg') {
    return (
      <MsgItem
        image={image}
        userName={userName}
        onPopupState={onPopupState}
        onPopupReplyState={onPopupReplyState}
        mentionUserName={mentionUserName}
        message={message}
        messageSymbol={messageSymbol}
        regDate={regDate}
        moreList={moreList}
        containerStyle={containerStyle}
        replyContainerStyle={replyContainerStyle}
        chatMessageReply={chatMessageReply}
        deleted={deleted}
        selected={selected}
        replySelectedIndex={replySelectedIndex}
        onLinkPress={onLinkPress}
        topIndex={index}
        onPressMoreButton={onPressMoreButton}
      />
    )
  } else if (type === 'notice') {
    return <NoticeItem message={message} messageSymbol={messageSymbol} containerStyle={containerStyle} />
  } else if (type === 'file') {
    return (
      <FileItem
        image={image}
        userName={userName}
        onPopupState={onPopupState}
        onPopupReplyState={onPopupReplyState}
        mentionUserName={mentionUserName}
        message={message}
        regDate={regDate}
        moreList={moreList}
        file={file}
        containerStyle={containerStyle}
        replyContainerStyle={replyContainerStyle}
        chatMessageReply={chatMessageReply}
        onFileShare={onFileShare}
        onFileExport={onFileExport}
        onFileDownload={onFileDownload}
        onFilePreview={onFilePreview}
        selected={selected}
        replySelectedIndex={replySelectedIndex}
        onLinkPress={onLinkPress}
        topIndex={index}
        onPressMoreButton={onPressMoreButton}
      />
    )
  } else {
    return <></>
  }
}

export default DiscussionItem
