import { Tooltip } from '@rui/atoms'
import { COLOR } from '@rui/foundations'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { SwitchIconArchive, SwitchIconBoard } from './icon'

interface Props {
  onSwitch: () => void
  activated: boolean
  mode: string
  tooltip: string
  theme?: 'LIGHT' | 'DARK'
}

export const Handler: React.FC<Props> = ({ onSwitch, activated, mode, tooltip, theme }) => {
  return (
    <View>
      <Tooltip
        // gab={5}
        // darkType={true}
        direction={'bottom'}
        align="center"
        text={tooltip}
        // textStyle={{ ...FONT.txtXs }}
        // containerStyle={styles.tooltip}
        onPress={onSwitch}
      >
        <View
          style={[
            styles.switchHandler,
            theme === 'DARK' && { backgroundColor: COLOR.opacity.blk30 },
            !activated && styles.switchHandlerActived,
          ]}
        >
          <View>
            {mode === 'board' && (
              <SwitchIconBoard
                color={
                  theme === 'DARK' && activated
                    ? COLOR.gray.g450
                    : theme === 'LIGHT' && activated
                    ? '#D3D7DC'
                    : '#ffffff'
                }
              />
            )}
            {mode === 'archive' && (
              <SwitchIconArchive
                color={
                  theme === 'DARK' && activated
                    ? COLOR.gray.g450
                    : theme === 'LIGHT' && activated
                    ? '#D3D7DC'
                    : '#ffffff'
                }
              />
            )}
          </View>
        </View>
      </Tooltip>
    </View>
  )
}

const styles = StyleSheet.create({
  tooltip: {
    // ...Platform.select({
    //   web: { maxWidth: 'max-content' },
    // }),
  },
  switchHandler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    borderRadius: 5,
    backgroundColor: 'transparent',
  },
  switchHandlerActived: {
    backgroundColor: COLOR.main.blue,
  },
})
