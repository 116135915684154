import {
  AuthProvider,
  CurrentUserProvider,
  ModalBackdropProvider,
  ModalDialogProvider,
  ModalProvider,
  WorkProvider,
} from '@rocket-mono/providers'
import { Skeleton } from '@rocket/skeleton'
import { ToastProvider } from '@rui/atoms'
import * as React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { authTokenState, isAnonymousState } from '../recoils'
import AuthProviders from './auth'
import BaseProviders from './base'
import ComponentsProviders from './components'
import { options } from './env'

interface Props {
  children?: JSX.Element | JSX.Element[]
}

const RootProviders: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { channelRoomId } = useParams()
  const [authToken, setAuthToken] = useRecoilState(authTokenState)
  const setIsAnonymousState = useSetRecoilState(isAnonymousState)

  const handleCurrentUserFailed = React.useCallback(() => {
    setAuthToken('')
    localStorage.removeItem('authToken')
    navigate('/error/expired', { replace: true })
  }, [])

  const astroOption = React.useMemo(() => {
    return options
  }, [])

  // FIXME: API 호출 SDK로 이동
  const getAnonymousToken = () => {
    return new Promise<string>((resolve, reject) => {
      // const uri = astroOption.cardBaseUrl + `/api/cards/${cardId}/anonymous-token`
      // console.log('anonymous', uri)
      const uri = astroOption.authBaseUrl + '/api/auth/anonymous-token'
      const method = 'POST'
      const headers = {
        'Access-Control-Allow-Headers': 'X-AUTH-TOKEN',
      }
      fetch(uri, { method, headers })
        .then((res) => {
          if (res.status === 200) {
            return res.headers.get('X-AUTH-TOKEN') ?? ''
          } else {
            throw 'no token'
          }
        })
        .then((token) => resolve(token))
        .catch((e) => reject(e))
    })
  }
  const getAnonymousChannelToken = () => {
    return new Promise<string>((resolve, reject) => {
      const body = new FormData()
      body.append('roomId', channelRoomId ?? '')

      const uri = astroOption.talkApiLegacyUrl + `/api/auth/anonymous-token`
      const method = 'POST'
      const headers = {
        'Access-Control-Allow-Headers': 'X-AUTH-TOKEN',
      }
      fetch(uri, { method, headers, body })
        .then((res) => res.text())
        .then((token) => resolve(token))
        .catch((e) => reject(e))
    })
  }

  React.useEffect(() => {
    const paramToken = searchParams.get('token')
    const localToken = localStorage.getItem('authToken') ?? ''
    const token = authToken || localToken || paramToken

    const { pathname, origin } = location
    const redirectUrl = origin + (pathname !== '/' ? pathname : '')

    console.log('RootProviders useEffect', token, { authToken, localToken, paramToken, pathname })
    // http://localhost:3000/tableorder/d924ed5a-5817-11ee-ac66-bb1ccc5f2e85/order/da70f80c-5817-11ee-88ca-a792b9ced645/customer
    // if (pathname.endsWith('/customer')) {
    //   setAuthToken(pathname.split('/').reverse()[1])
    //   setIsAnonymousState(true)
    // } else
    if (!token && pathname.startsWith('/card/')) {
      // const todoId = undefined
      // const todoId = search.startsWith('?todoId=') ? search.replace('?todoId=', '') : undefined
      getAnonymousToken()
        .then((token) => {
          setAuthToken(token)
          setIsAnonymousState(true)
          if (paramToken !== null) {
            navigate(pathname, { replace: true })
          }
        })
        .catch(() => {
          // navigate(`/error/card/unauthorized?redirect_url=${encodeURIComponent(redirectUrl + search)}`)
        })

      // if (todoId) {
      //   getAnonymousChannelToken()
      //     .then((token) => {
      //       setAuthToken(token)
      //       setIsAnonymousState(true)
      //       if (paramToken !== null) {
      //         // navigate(pathname, { replace: true })
      //       }
      //     })
      //     .catch(() => {
      //       // navigate(`/error/card/unauthorized?redirect_url=${encodeURIComponent(redirectUrl + search)}`)
      //     })
      // } else {

      // }
    } else if (!token && pathname.startsWith('/channel/')) {
      if (paramToken) {
        console.log('d', paramToken)
      } else {
        getAnonymousChannelToken()
          .then((token) => {
            setAuthToken(token)
            setIsAnonymousState(true)
            if (paramToken !== null) {
              // navigate(pathname, { replace: true })
            }
          })
          .catch(() => {
            // navigate(`/error/card/unauthorized?redirect_url=${encodeURIComponent(redirectUrl + search)}`)
          })
      }
    } else if (!token && pathname === '/') {
      location.href = '/info'
    } else if (!token) {
      location.href = `/login?redirect_url=${encodeURIComponent(redirectUrl)}`
    } else {
      localStorage.setItem('authToken', token)
      setAuthToken(token)
      setIsAnonymousState(false)
      if (paramToken !== null) {
        // navigate(pathname, { replace: true })
        location.href = pathname
      }
    }
  }, [])

  if (!authToken) {
    return <></>
  }

  return (
    <BaseProviders>
      <CurrentUserProvider onFailed={handleCurrentUserFailed} fallback={<Skeleton path={location.pathname} />}>
        <AuthProvider>
          <ModalBackdropProvider>
            <ModalDialogProvider>
              <WorkProvider>
                <ModalProvider>
                  <ComponentsProviders>
                    <ToastProvider>
                      <>{children}</>
                    </ToastProvider>
                  </ComponentsProviders>
                </ModalProvider>
              </WorkProvider>
            </ModalDialogProvider>
          </ModalBackdropProvider>
        </AuthProvider>
      </CurrentUserProvider>
    </BaseProviders>
  )
}

export default RootProviders

export { AuthProviders, BaseProviders }
