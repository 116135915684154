export function getFileSize(x: number) {
  const s = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'] as const
  const e = Math.floor(Math.log(x) / Math.log(1024))
  return {
    size: (x / 1024 ** e).toFixed(0),
    unit: s[e],
  }
}

export function printFileSize(x: number): string {
  if (x > 0) {
    const { size, unit } = getFileSize(x)
    return `${size}${unit}`
  } else {
    return '0bytes'
  }
}
