import { useContext } from 'react'

import Context from './context'
import type { ContextProps } from './types'

export const useWorkProject = (projectId?: string) => {
  const context = useContext<ContextProps | undefined>(Context)

  if (context === undefined) throw new Error('There must be a WorkProjectProvider as Ancestor of all Hooks and HOCs')

  if (projectId) context.setCurrentProjectId(projectId)

  return context
}
