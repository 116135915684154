import { useCallback, useContext, useMemo } from 'react'

import { Modal } from '@rocket-mono/foundations'
import Context from './context'
import type { ContextProps } from './types'

export function useModal(id: string, backdrop: boolean = true) {
  const context = useContext<ContextProps | undefined>(Context)

  if (context === undefined) throw new Error('There must be a ModalProvider as Ancestor of all Modal Hooks and HOCs')

  const open = useCallback(() => {
    console.log('backdrop', backdrop)
    context.show(id, backdrop)
  }, [id])
  const close = useCallback(() => {
    context.hide(id)
  }, [id])

  const visible = useMemo(
    () => (context.visibleList.findIndex((key) => key === id) < 0 ? false : true),
    [context.visibleList],
  )

  return {
    Modal,
    visible,
    open,
    close,
  }
}
