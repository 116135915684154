import * as React from 'react'
import { Platform, StyleSheet } from 'react-native'

import { COLOR } from '@rocket-mono/libs'
interface Props {
  setMoveX: (x: number) => void
}

export const DargHandler: React.FC<Props> = ({ setMoveX }) => {
  const [isDrag, setIsDrag] = React.useState(false)

  React.useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDrag) {
        setMoveX(e.pageX)
      }
    }

    const handleMouseUp = () => {
      setIsDrag(false)
    }

    if (Platform.OS === 'web') {
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
    }

    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('mousemove', handleMouseMove)
        window.removeEventListener('mouseup', handleMouseUp)
      }
    }
  }, [isDrag])

  return (
    <div
      style={styles.handler}
      onMouseDown={(e) => {
        setIsDrag(true)
      }}
    />
  )
}

const styles = StyleSheet.create({
  handler: {
    left: 0,
    alignItems: 'center',
    ...Platform.select({
      native: {
        display: 'none',
      },
      web: {
        width: 16,
        height: '100%',
        backgroundColor: COLOR.transparent.base,
        // transform: [{ translateX: 8 }],
        cursor: 'ew-resize',
        zIndex: 999,
      },
    }),
  },
})
