import { Checkbox } from '@rocket-atoms/checkbox'
import { Icon } from '@rocket-atoms/icon'
import { Avatar } from '@rui/atoms'
import React, { useCallback, useState } from 'react'
import { Platform, Pressable, StyleSheet, Text, View } from 'react-native'
import OpinionForm from './OpinionForm'

import { COLOR, FONT } from '@rocket-mono/libs'
import { useWorkCard, useWorkChannel } from '@rocket-mono/providers'
import { ClickOutside } from '@rui/foundations'
import { Combobox } from '@rui/molecules'
import { useTranslation } from 'react-i18next'
import { CardGatheringItemType } from '.'
import FileItem from '../../../../components/FileItem'

interface ItemProps {
  item: CardGatheringItemType
  collectionId: string
  isAnonymous: boolean
}

export const Item = ({ item, collectionId, isAnonymous }: ItemProps) => {
  const { t } = useTranslation()

  const { currentChannelMember } = useWorkChannel()
  const { deleteGatheringPiece } = useWorkCard()
  const [isTabHover, setIsTabHover] = React.useState(false)
  const [vislbleIdx, setVisibleIdx] = useState<number | null>(null)
  const [checked, setChecked] = React.useState(item.complete)
  const [fold, setFold] = React.useState(() => {
    if (item.type === 'FILE') {
      return item.file && item.file.length === 0
    } else if (item.type === 'OPINION') {
      return item.content.length === 0
    } else {
      return false
    }
  })

  const handleDelete = useCallback(
    (idx) => {
      if (item.file) {
        const file = item.file[idx]
        console.log('currentCard.handleDelete', collectionId, item, file)

        if (file && file.id !== undefined) {
          deleteGatheringPiece(collectionId, file.id)
        }
      }
    },
    [item, collectionId],
  )

  const handlePressEtc = useCallback((idx: number) => {
    setVisibleIdx((prev) => {
      if (prev === idx) return null
      return idx
    })
  }, [])
  React.useEffect(() => {
    item.file && item.file.length !== 0 && setFold(false)
    item.content && item.content.length !== 0 && setFold(false)
  }, [item.file, item.content])

  if (isAnonymous || (!item.isWriter && currentChannelMember === undefined))
    return (
      <View style={[styles.container, styles.anonymousContainer]}>
        <View style={styles.cardTab}>
          <View style={styles.flexBox}>
            <Avatar size="xsmall" />
            <Text style={styles.userName}>{t('cardgathering.noname')}</Text>
          </View>
        </View>
      </View>
    )

  return (
    <View style={[styles.container, isTabHover && { borderColor: COLOR.gray.g200 }]}>
      <View style={[styles.flexBox, styles.cardHeader]}>
        <View style={styles.checkboxArea}>
          <Pressable
          // onPress={() => {
          //   // setChecked(!checked)
          // }}
          >
            <Checkbox checked={checked} size={18} type="TODO" />
          </Pressable>
        </View>
        <Pressable
          style={styles.cardTab}
          onPress={() => setFold(!fold)}
          onHoverIn={() => setIsTabHover(true)}
          onHoverOut={() => setIsTabHover(false)}
        >
          <View style={styles.flexBox}>
            <Avatar size="xxsmall" profileUrl={item.profileImage} />
            <Text style={styles.userName}>{item.user}</Text>
          </View>
          {(item.file !== undefined && item.file.length > 0) || item.content.length > 0 ? (
            <View style={styles.flexBox}>
              {item.type === 'FILE' && (
                <View style={styles.flexBox}>
                  <View style={[styles.flexBox, { marginLeft: 8 }]}>
                    <Icon name="attachment" size={12} color={'gray.g700'} />
                    <Text style={styles.labelCount}>{item.file?.length || 0}</Text>
                  </View>
                </View>
              )}
              <View style={styles.foldButton}>
                <Icon name={fold ? 'angle-down' : 'angle-up'} size={12} />
              </View>
            </View>
          ) : (
            <></>
          )}
        </Pressable>
      </View>
      {fold ? (
        <></>
      ) : (
        <View style={styles.cardBody}>
          <View>
            {item.type === 'OPINION' && (
              <OpinionForm
                id={item.id}
                collectionId={collectionId}
                groupId={item.groupId}
                content={item.content}
                isWriter={item.isWriter}
                isPrivate={!item.permission}
              />
            )}
            {item.type === 'FILE' && (
              <View>
                {vislbleIdx !== null && (
                  <ClickOutside onClickOutside={() => setVisibleIdx(null)}>
                    <View style={{ position: 'absolute', top: vislbleIdx * 58 + 38, right: 16, zIndex: 14 }}>
                      <Combobox visible>
                        <Combobox.Body>
                          <Combobox.Item
                            text={t('cardauthority.delete')}
                            onPress={() => {
                              handleDelete(vislbleIdx)
                              setVisibleIdx(null)
                            }}
                          />
                        </Combobox.Body>
                      </Combobox>
                    </View>
                  </ClickOutside>
                )}
                {item.file?.map((file, idx) => (
                  <View key={`fileItem-${idx}`} style={{ marginBottom: 4 }}>
                    <FileItem border {...file} onPressEtc={item.permission ? () => handlePressEtc(idx) : undefined} />
                  </View>
                ))}
              </View>
            )}
          </View>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    borderWidth: 1,
    padding: 8,
    marginTop: 4,
    borderColor: COLOR.gray.g200,
    backgroundColor: COLOR.mono.white,
  },
  anonymousContainer: {
    height: 40,
    borderWidth: 0,
    backgroundColor: COLOR.gray.g050,
    padding: 8,
  },
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardHeader: {},
  checkboxArea: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    marginRight: 4,
  },
  cardTab: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  foldButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    marginLeft: 4,
  },
  labelCount: {
    ...FONT.txt2Xs,
    color: COLOR.gray.g700,
    marginLeft: 2,
  },
  cardBody: {
    paddingTop: 8,
    paddingLeft: 28,
  },
  userName: {
    ...FONT.txtSm,
    marginLeft: 4,
    ...Platform.select({
      web: {
        marginTop: -2,
      },
    }),
  },
})
