import { COLOR, FONT } from '@rui/foundations'
import React, { useMemo } from 'react'
import { SectionList, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import SearchLayerSectionHeader from './Header'
import SearchLayerSectionItem from './Item'
import type { SearchLayerSectionList, SearchLayerSectionListItem, SearchLayerSectionListProps } from './types'
import { useTranslation } from 'react-i18next'

const SearchLayerSectionListView: React.FC<SearchLayerSectionListProps> = ({
  data,
  onFold,
  onSectionSelect,
  onItemSelect,
}) => {
  const { t } = useTranslation()
  const { height } = useWindowDimensions()

  const maxHeight = useMemo(() => {
    const min = 200
    const max = height - 400
    return max < min ? min : max
  }, [height])

  if (data === undefined)
    return (
      <View>
        <Text>data undefined</Text>
      </View>
    )

  if (data.length === 0) {
    return (
      <View style={styles.blank}>
        <Text style={styles.blankText}>{t('nocontentsicon.search.text')}</Text>
      </View>
    )
  } else {
    return (
      <View style={{ flex: 1 }}>
        <SectionList
          sections={data}
          keyExtractor={(item) => `SectionList-item-${item.id}`}
          renderItem={({ item, section }) => (
            <SearchLayerSectionItem item={item} section={section} onItemSelect={onItemSelect} />
          )}
          renderSectionHeader={({ section }) => (
            <SearchLayerSectionHeader section={section} data={data} onFold={onFold} onSectionSelect={onSectionSelect} />
          )}
          renderSectionFooter={({ section }) => (!section.isFold ? <View style={styles.divLine} /> : <></>)}
          stickySectionHeadersEnabled
          style={{ maxHeight }}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  comboHeaderStyle: {
    padding: 8,
    borderBottomWidth: 0,
  },
  comboFooterStyle: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 8,
    borderTopWidth: 1,
    borderColor: COLOR.gray.g200,
  },
  blank: {
    minHeight: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
  blankText: {
    ...FONT.txtSm,
    color: COLOR.gray.g500,
  },
  divLine: {
    height: 4,
    backgroundColor: COLOR.gray.g150,
  },
})

export default SearchLayerSectionListView

export type { SearchLayerSectionList, SearchLayerSectionListItem, SearchLayerSectionListProps }
