import * as React from 'react'

import '@toast-ui/editor/dist/toastui-editor.css'
import { Editor as ToastEditor } from '@toast-ui/react-editor'

import { TriggerCardLinkType, TriggerMentionType, TriggerType } from '@rui/rich-text-field'
import { useTranslation } from 'react-i18next'
import { triggerConvert } from './utils'
import { RefProps } from '../types'

const DEFAULT_TRIGGER_STATE = {
  state: 'idle',
}

interface Props {
  autofocus?: boolean
  height?: number
  language?: string
  initialValue: string
  onChangeContent?: (content: string) => void
  placeholder?: string
  onLoadFile?: (file: Blob | File, text?: string) => Promise<string>
  triggerList?: TriggerType[]
}

const reWidgetRule = /\[(#\S+)\]\{([^)]+)\}\((\S+)\)/

const Editor = React.forwardRef<RefProps, Props>(
  ({ autofocus = false, height, initialValue, placeholder, onChangeContent, onLoadFile, triggerList = [] }, ref) => {
    const { i18n } = useTranslation()
    const editorRef = React.useRef<ToastEditor>(null)

    const [triggerState, setTriggerState] = React.useState<{
      state: string
      key?: string
      caretPosition?: number
      text?: string
    }>(DEFAULT_TRIGGER_STATE)

    const resetTriggerState = React.useCallback(() => {
      const trigged = triggerList.find((o) => o.key === triggerState.key)
      if (trigged && trigged.onDismiss) {
        trigged.onDismiss()
      }
      setTriggerState(DEFAULT_TRIGGER_STATE)
    }, [triggerState, triggerList])

    const handleChange = React.useCallback(() => {
      if (editorRef.current && onChangeContent) onChangeContent(editorRef.current?.getInstance().getHTML())
    }, [editorRef, onChangeContent])

    React.useEffect(() => {
      if (editorRef.current && autofocus) {
        editorRef.current?.getInstance().focus()
        setTimeout(() => {
          editorRef.current?.getInstance().moveCursorToEnd(true)
        }, 200)
      }
    }, [editorRef, autofocus])

    React.useEffect(() => {
      const editor = editorRef.current?.getInstance()
      editor?.on('keyup', (_editorType, ev) => {
        const key = ev.key

        if (key.length === 1) {
          const trigged = triggerList.find((o) => o.key === key)

          if (trigged && trigged.onTrigger) {
            setTimeout(() => {
              setTriggerState((prev) => ({ ...prev, key }))
            }, 100)
          }
          if (triggerState.state === 'matched') {
            if (key === ' ') resetTriggerState()
          }
        }
      })

      return () => {
        editor?.off('keyup')
      }
    }, [triggerList, triggerState, resetTriggerState])

    React.useEffect(() => {
      const { state, caretPosition, text } = triggerState

      console.log('triggerState', triggerState)

      if (state === 'matched' && caretPosition !== undefined && text !== undefined) {
        editorRef.current?.getInstance().replaceSelection(text + '\u00A0', caretPosition - 1, caretPosition)
        // editorRef.current?.getInstance().replaceWithWidget(caretPosition - 1, caretPosition, text + '\u00A0')
        setTimeout(() => {
          editorRef.current?.getInstance().replaceSelection('\u00A0', caretPosition, caretPosition + 1)
          // editorRef.current?.getInstance().replaceSelection('\u00A0', caretPosition + 1, caretPosition + 1)
          // editorRef.current?.getInstance().moveCursorToEnd(true)
          // editorRef.current?.getInstance().move
        }, 200)
        // setTimeout(() => {
        //   // editorRef.current?.getInstance().replaceSelection(text + ' ', caretPosition - 1, caretPosition)
        //   // editorRef.current?.getInstance().moveCursorToEnd(true)
        // }, 500)

        //
        resetTriggerState()
      }
    }, [triggerState, resetTriggerState])

    React.useEffect(() => {
      setTimeout(() => {
        const selection = editorRef.current?.getInstance().getSelection()
        if (selection) {
          const [start] = selection
          const caretPosition = typeof start === 'number' ? start : start[0]

          console.log('caretPosition', caretPosition, editorRef.current?.getInstance().getMarkdown())
          if (triggerState.state === 'idle') {
            const trigged = triggerList.find((o) => o.key === triggerState.key)
            if (trigged && trigged.onTrigger) {
              console.log('trigged', trigged, caretPosition)
              // if (prevText === ' ' || prevText === '' || caretPosition === 1) {
              setTriggerState((prev) => ({
                ...prev,
                state: 'matched',
                caretPosition,
              }))
              trigged.onTrigger()
            }
          }
        }
      }, 100)
    }, [triggerState, triggerList])

    React.useImperativeHandle(ref, () => {
      return {
        reset: () => {
          resetTriggerState()
        },
        onTrigger: (res: TriggerMentionType | TriggerCardLinkType) => {
          const text = triggerConvert(res)
          setTriggerState((prev) => ({ ...prev, text }))
        },
        focus: () => {
          editorRef.current?.getInstance().moveCursorToEnd(true)
        },
        blur: () => {
          editorRef.current?.getInstance().blur()
        },
      }
    })

    return (
      <>
        <style>{`
        .toastui-editor-contents {
          font-size: 16px;
        }
        .toastui-editor-contents p {
          margin: 0;
        }
        .toastui-editor-contents table {
          width: 100%;
        }
        .toastui-editor-contents .tableWrapper {
          width: 100%;
          overflow: auto;
        }
        .toastui-editor-contents .toastui-editor-ww-code-block:after {
          content: none;
        }
        .toastui-editor-contents :not(table) {
          line-height: 170%;
        }
        @media only screen and (max-width: 480px) {
          .toastui-editor-popup {
            max-width: 300px;
            margin-left: 0 !important;
          }

          .toastui-editor-dropdown-toolbar {
            max-width: none;
            min-height: 46px;
            height: auto !important;
            flex-wrap: wrap;
          }
        }
        .card-link {
          display: inline;
          background-color: rgba(42, 50, 55, 0.6);
          padding-left: 4px;
          padding-right: 4px;
          border-radius: 4px;
        }
        .card-link > a {
          color: white;
          text-decoration: none;
          line-height: 20px;
          font-size: 14px;
          font-weight: 500;
        }
        .card-link > a:hover {
          color: white;
        }
      `}</style>
        <ToastEditor
          ref={editorRef}
          height={`${height}px`}
          hideModeSwitch
          initialEditType="wysiwyg"
          usageStatistics={false}
          autofocus={false}
          language={i18n.language}
          initialValue={initialValue}
          placeholder={placeholder}
          onChange={handleChange}
          hooks={{
            addImageBlobHook(blob, callback) {
              console.log('addImageBlobHook', blob)
              onLoadFile && onLoadFile(blob).then(callback)
            },
          }}
          widgetRules={[
            {
              rule: reWidgetRule,
              toDOM(text) {
                const rule = reWidgetRule
                const matched = text.match(rule)
                const span = document.createElement('span')
                span.className = 'card-link'
                span.dataset.code = text
                console.log('matched', text, reWidgetRule, matched)

                if (matched) {
                  span.innerHTML = `<a href="${window.location.origin}${matched[3]}" target="_blank" title="${matched[2]}">${matched[1]} ${matched[2]}</a>`
                }

                return span
              },
            },
            {
              rule: /\[(@\S+)\]\((\S+)\)/,
              toDOM(text) {
                const rule = /\[(@\S+)\]\((\S+)\)/
                const matched = text.match(rule)
                const span = document.createElement('span')
                console.log('matched', reWidgetRule, matched)

                if (matched) span.innerHTML = `<a class="widget-anchor" href="${matched[2]}">${matched[1]}</a>`
                return span
              },
            },
            // {
            //   rule: /\[(#\S+)\]\((\S+)\)/,
            //   toDOM(text) {
            //     const rule = /\[(#\S+)\]\((\S+)\)/
            //     const matched = text.match(rule)
            //     const span = document.createElement('span')

            //     console.log('matched', reWidgetRule, matched)

            //     if (matched) span.innerHTML = `<a class="widget-anchor" href="${matched[2]}">${matched[1]}</a>`
            //     return span
            //   },
            // },
          ]}
        />
      </>
    )
  },
)

export default Editor
