import React from 'react'
import { View } from 'react-native'

import { COLOR } from '@rocket-mono/libs'
import { SkeletonBar, SkeletonSquare } from '../SkeletomParts'

const SkeletonPlaceSearch: React.FC = () => {
  return (
    <View>
      <View style={{ paddingHorizontal: 20, paddingVertical: 12 }}>
        <SkeletonSquare width={'100%'} height={36} color="gray.g150" />
      </View>
      <View>
        <View style={{ paddingHorizontal: 20, borderTopWidth: 2, borderColor: COLOR.gray.g050 }}>
          <View style={{ paddingVertical: 16 }}>
            <View style={{ marginBottom: 4 }}>
              <SkeletonBar width={'40%'} height={16} />
            </View>
            <SkeletonBar width={'80%'} height={12} />
          </View>
          <View style={{ paddingVertical: 16, opacity: 0.8 }}>
            <View style={{ marginBottom: 4 }}>
              <SkeletonBar width={'40%'} height={16} />
            </View>
            <SkeletonBar width={'89%'} height={12} />
          </View>
          <View style={{ paddingVertical: 16, opacity: 0.7 }}>
            <View style={{ marginBottom: 4 }}>
              <SkeletonBar width={'30%'} height={16} />
            </View>
            <SkeletonBar width={'70%'} height={12} />
          </View>
          <View style={{ paddingVertical: 16, opacity: 0.6 }}>
            <View style={{ marginBottom: 4 }}>
              <SkeletonBar width={'60%'} height={16} />
            </View>
            <SkeletonBar width={'90%'} height={12} />
          </View>
          <View style={{ paddingVertical: 16, opacity: 0.5 }}>
            <View style={{ marginBottom: 4 }}>
              <SkeletonBar width={'20%'} height={16} />
            </View>
            <SkeletonBar width={'75%'} height={12} />
          </View>
        </View>
      </View>
    </View>
  )
}

export default SkeletonPlaceSearch
