import type { IChatMessageReply, IFile } from '@rocket-mono/libs'
import { FONT, getRealColor } from '@rocket-mono/libs'
import moment from 'moment'
import 'moment/min/locales'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Image, ImageSourcePropType, Platform, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native'
import type { AppList } from '../../AddPopup'
import ButtonIcon from '../../ButtonIcon'
import FileView from '../../FileView'
import MoreButton from '../Components/MoreButton'
import { ItemMoreButtonType } from '../types'
import ReplyList from './reply'

interface Props {
  image: ImageSourcePropType
  userName: string
  mentionUserName?: string
  message?: string
  containerStyle?: ViewStyle
  replyContainerStyle?: ViewStyle
  moreList?: AppList[]
  regDate: string
  file?: IFile
  chatMessageReply?: IChatMessageReply[]
  onPopupState: (state: string) => void
  onPopupReplyState?: ({
    parentIndex,
    index,
    state,
    item,
  }: {
    parentIndex: number
    index: number
    state: string
    item?: IChatMessageReply
  }) => void
  onFileShare?: (item?: IFile) => void
  onFileExport?: (item?: IFile) => void
  onFileDownload?: (item?: IFile) => void
  onFilePreview?: (item?: IFile) => void
  selected: boolean
  replySelectedIndex?: string
  onLinkPress?: (url: string, text?: string | undefined) => void
  topIndex: number
  onPressMoreButton?: (payload: ItemMoreButtonType) => void
}

const FileItem = ({
  image,
  userName,
  containerStyle,
  replyContainerStyle,
  moreList,
  regDate,
  file,
  chatMessageReply = [],
  onPopupReplyState,
  onFileShare,
  onFileExport,
  onFileDownload,
  onFilePreview,
  selected,
  replySelectedIndex,
  onLinkPress,
  topIndex,
  onPressMoreButton,
}: Props) => {
  const { t, i18n } = useTranslation()
  moment.locale(i18n.resolvedLanguage)
  const isImage = React.useMemo(() => {
    return file ? file.mimeType.indexOf('image') > -1 : false
  }, [file])
  return (
    <View style={[styles.container]}>
      <View
        style={[
          styles.commentContainer,
          containerStyle,
          {
            backgroundColor: selected ? '#e4eef9' : getRealColor('mono.white'),
          },
        ]}
      >
        {/* image container */}
        <View style={styles.imageContainer}>
          <Image source={image} style={styles.image} />
        </View>
        <View style={styles.rightContainer}>
          <View style={styles.topContainer}>
            <View style={styles.usernameContainer}>
              <Text style={styles.userName}>{userName}</Text>
              <Text style={styles.time}>{t('format.date.LLL', { date: regDate })}</Text>
            </View>
            {moreList && moreList.length > 0 && (
              <View style={styles.moreButton}>
                <MoreButton
                  onPress={(layoutPayload) => {
                    onPressMoreButton?.({
                      parentIndex: topIndex,
                      moreList,
                      layoutPayload,
                    })
                  }}
                />
              </View>
            )}
          </View>
          {/* <View style={styles.messageContainer}>
            <Text>
              {mentionUserName && (
                <Text style={styles.mentionUserName}>
                  {`@${mentionUserName}  `}
                </Text>
              )}
              <Text style={styles.message}>{message}</Text>
            </Text>
          </View> */}
          <View style={styles.fileContainer}>
            {isImage ? (
              <View style={styles.fileImageContainer}>
                <TouchableOpacity
                  onPress={() => {
                    onFilePreview && onFilePreview(file)
                  }}
                >
                  <Image source={{ uri: file?.url }} style={styles.fileImage} />
                </TouchableOpacity>
                <View style={styles.fileImageButtonsContainer}>
                  {onFileShare && (
                    <ButtonIcon
                      iconName="share-alt-o"
                      rounded={true}
                      backgroundColor={'mono.pale'}
                      iconColor={'mono.paleBlack'}
                      containerSize={28}
                      iconSize={16}
                      onPress={() => {
                        onFileShare(file)
                      }}
                      style={styles.fileImageButton}
                    />
                  )}
                  {onFileDownload && (
                    <ButtonIcon
                      iconName="download"
                      rounded={true}
                      backgroundColor={'mono.pale'}
                      iconColor={'mono.paleBlack'}
                      containerSize={28}
                      iconSize={16}
                      onPress={() => {
                        onFileDownload(file)
                      }}
                      style={styles.fileImageButton}
                    />
                  )}
                </View>
              </View>
            ) : (
              <FileView
                file={file}
                onFileShare={onFileShare}
                onFileExport={onFileExport}
                onFileDownload={onFileDownload}
              />
            )}
          </View>
        </View>
      </View>
      <ReplyList
        chatMessageReply={chatMessageReply}
        replySelectedIndex={replySelectedIndex}
        topIndex={topIndex}
        onPopupReplyState={onPopupReplyState}
        replyContainerStyle={replyContainerStyle}
        onLinkPress={onLinkPress}
        onPressMoreButton={onPressMoreButton}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: getRealColor('mono.white'),
  },
  commentContainer: {
    flexDirection: 'row',
  },
  imageContainer: {
    marginRight: 10,
  },
  replyImageContainer: {
    flexDirection: 'row',
    marginRight: 10,
  },
  image: {
    width: 40,
    height: 40,
    borderRadius: 25,
  },
  fileContainer: {
    marginTop: 4,
  },
  fileImageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  fileImageButtonsContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  fileImageButton: {
    marginLeft: 10,
  },
  rightContainer: {
    flex: 1,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  usernameContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: 19,
  },
  moreButton: {
    width: 35,
  },
  userName: {
    ...FONT.subTextBold,
    lineHeight: Platform.select({
      native: 19.24,
      default: 12.64,
    }),
    color: getRealColor('mono.black'),
    marginRight: 5,
  },
  time: {
    ...FONT.subTextThin,
    color: getRealColor('mono.darkGray'),
  },
  messageContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 3,
  },
  mentionUserName: {
    ...FONT.textRegular,
    color: getRealColor('main.blue'),
  },
  message: {
    ...FONT.textRegular,
    color: getRealColor('mono.paleBlack'),
  },
  fileImage: {
    width: 244,
    height: 154,
    resizeMode: 'contain',
  },
})

export default FileItem
