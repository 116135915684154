import { AssigneeResponse } from '@rocket/types'
import React, { useCallback, useEffect, useState } from 'react'
import { useAstro } from '../../AstroProvider'
import { useSubscription } from '../../StompProvider'
import Context from './context'
import type { ProviderProps } from './types'

export const WorkAssigneeProvider: React.FC<ProviderProps> = ({
  domain: relatedDomain,
  domainId,
  children,
}: ProviderProps) => {
  const { astro } = useAstro()
  const [assigneeList, setAssigneeList] = useState<AssigneeResponse[]>([])

  const fetchAssignee = useCallback(() => {
    if (domainId) {
      astro
        .readAssignee(relatedDomain, domainId)
        .then(setAssigneeList)
        .catch(() => setAssigneeList([]))
    }
  }, [domainId])

  const createAssignee = useCallback(
    (relatedDomainId: string, { userId, userEmail }: { userId?: number; userEmail?: string }) => {
      astro.createAssignee({ userId, userEmail, relatedDomain, relatedDomainId })
    },
    [],
  )
  const deleteAssignee = useCallback((deleteIds: { id: string }[]) => {
    astro.deleteAssignees(deleteIds)
  }, [])

  useEffect(() => {
    if (domainId) fetchAssignee()
  }, [relatedDomain, domainId])

  useSubscription(`/subscribe/v2/card/${domainId}/assignee/created`, () => {
    fetchAssignee()
  })

  useSubscription(`/subscribe/${relatedDomain}/${domainId}/update/`, () => {
    setTimeout(() => {
      fetchAssignee()
    }, 500)
  })

  useEffect(() => {
    console.debug('provider-WorkAssigneeProvider', { assigneeList })
  }, [assigneeList])

  return <Context.Provider value={{ assigneeList, createAssignee, deleteAssignee }}>{children}</Context.Provider>
}

export * from './hooks'
