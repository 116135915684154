import React from 'react'
import { StyleSheet, View } from 'react-native'

import { useTranslation } from 'react-i18next'

import { Text } from '@rocket-atoms/text'
import { Switch } from '@rocket-atoms/toggle'

const listData = [
  {
    label: '모든 기간',
    code: 'ALL',
  },
  {
    label: '생성 기간',
    code: 'CREATE',
  },
  {
    label: '프로젝트 기간',
    code: 'PROJECT',
  },
]
interface Props {
  isSwitchOn: boolean
  onChangeSwitchOn?: (is: boolean) => void
  onChangeCode?: (code: string) => void
  defaultIndex: number
}

const ActivitySearchSectionSortWork: React.FC<Props> = (
  { isSwitchOn, onChangeSwitchOn }, // onChangeCode, defaultIndex
) => {
  const { t } = useTranslation()
  // const [actived, setActived] = React.useState(false)
  // const [buttonStandard, setButtonStandard] = React.useState<{ width: DimensionValue; height: number }>({
  //   width: 0,
  //   height: 0,
  // })
  // const [selected, setSelected] = React.useState<DropdownItemProps[]>([listData[defaultIndex]])

  // useEffect(() => {
  //   if (selected.length > 0) onChangeCode && onChangeCode(selected[0].code)
  // }, [selected, onChangeCode])

  return (
    <View style={styles.container}>
      {/* <View style={styles.dropdownArea}>
        <DropdownButton
          type={'DEFAULT'}
          configuration={'DEFAULT'}
          size={'md'}
          label={selected.length !== 0 ? selected[0].label : '초기 값'}
          buttonWidth={140}
          activated={actived}
          setActivated={setActived}
          setButtonStandard={setButtonStandard}
        />
        {actived && (
          <ClickOutside onClickOutside={() => setActived(false)}>
            <DropdownList
              list={listData}
              configuration={'DEFAULT-BORDER'}
              itemType={'DEFAULT'}
              itemVariant={'DEFAULT'}
              selected={selected}
              setSelected={(args) => {
                console.log('args', args)
                setSelected(args)
              }}
              buttonStandard={buttonStandard}
              setActived={setActived}
            />
          </ClickOutside>
        )}
      </View> */}
      <View></View>
      <View style={styles.switchArea}>
        <Switch activated={isSwitchOn} size={'sm'} onChange={(is) => onChangeSwitchOn && onChangeSwitchOn(is)} />
        <Text fontName="txtXsMedium" fontColor="gray.g800" style={styles.switchLabel}>
          {t('search.result.includearchive')}
        </Text>
      </View>
    </View>
  )
}

export default ActivitySearchSectionSortWork

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: 20,
    marginBottom: 8,
  },
  dropdownArea: {
    width: '30%',
  },
  switchArea: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  switchLabel: {
    marginLeft: 4,
  },
})
