import TextPlane from './Text'
import TextArea from './TextArea'
import TextField from './TextField'
import SearchTextField from './SearchTextField'
import TextFieldGroup from './TextFieldGroup'

const Text = Object.assign(TextPlane, {
  Field: TextField,
})

export { Text, TextArea, TextField, SearchTextField, TextFieldGroup }
