import { COLOR } from '@rocket-mono/libs'
import React from 'react'
import { FlatList, StyleSheet, View } from 'react-native'
import ChatPreviewFileItem from './Item'
import { ChatPreviewFileType, Props } from './types'

const ChatPreviewFileList: React.FC<Props> = ({ files, onRemove }) => {
  return (
    <View style={styles.container}>
      <FlatList
        horizontal
        data={files}
        renderItem={({ item, index }) => (
          <ChatPreviewFileItem
            {...item}
            isFirst={index === 0}
            isLast={index === files.length - 1}
            onRemove={() => onRemove?.(index)}
          />
        )}
        keyExtractor={(_, index) => `item-${index}`}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 68,
    backgroundColor: COLOR.gray.g050,
    borderTopColor: COLOR.gray.g150,
    borderTopWidth: 1,
    paddingTop: 8,
  },
})

export { ChatPreviewFileList }
export type { ChatPreviewFileType }
