import * as React from 'react'
import { StyleSheet } from 'react-native'

import { COLOR } from '@rocket-mono/libs'
import View from '../View'
import Text from '../Text'

interface Props {
  type?: 'DEFAULT' | 'PRIMARY' | 'ADDED' | 'REMOVED'
  count: number
}

/**
 * @deprecated
 */
const Badge: React.FC<Props> = ({ type = 'DEFAULT', count }) => {
  return (
    <View
      style={[
        styles.container,
        type === 'DEFAULT' && { backgroundColor: COLOR.main.red },
        type === 'PRIMARY' && { backgroundColor: COLOR.primary.blue500 },
        type === 'ADDED' && { backgroundColor: COLOR.gray.g050 },
        type === 'REMOVED' && { backgroundColor: COLOR.gray.g050 },
      ]}
    >
      <Text
        fontName="txt2XsBold"
        fontColor="mono.white"
        style={[
          type === 'ADDED' && { backgroundColor: COLOR.primary.blue500 },
          type === 'REMOVED' && { backgroundColor: COLOR.main.red },
        ]}
      >
        {count}
      </Text>
    </View>
  )
}

export default React.memo(Badge)

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 4,
    minWidth: 16,
    height: 16,
    borderRadius: 20,
  },
})
