import { DropdownItemProps } from '@rocket-atoms/dropdown'
import { ListMember, SectionAccordionType, SelectorBoardType, SelectorWorkType } from '@rocket-molecules/popselector'
import React from 'react'
import { RuleEventType } from '../../RuleScreen/types'
import RuleBoardSelectView from '../RuleBoardSelectView'
import RuleCardSelectView from '../RuleCardSelectView'
import RuleUserSelectView from '../RuleUserSelectView'
import RuleWorkSelectView from '../RuleWorkSelectView'

interface Props {
  event?: RuleEventType
  cardList: DropdownItemProps[]
  defaultCardListIndex?: string
  userList: ListMember[]
  boardList: SelectorBoardType[]
  selectedBoardList: string[]
  workList: SelectorWorkType[]
  selectedWork?: string
  boardListBySelectedWork: SelectorBoardType[]
  selectedBoardListOfSelectedWork: string[]
  onSelectedUserList: (list: ListMember[]) => void
  onSelectedCardList: (item: DropdownItemProps) => void
  onSelectedBoardList: (list: string[]) => void
  onSelectedBoardListBySelectedWork: (item: SectionAccordionType) => void
  onSelectedBoardListOfSelectedWork: (list: string[]) => void
}

const RuleBody: React.FC<Props> = ({
  event,
  cardList,
  defaultCardListIndex,
  userList,
  boardList,
  selectedBoardList,
  workList,
  selectedWork,
  boardListBySelectedWork,
  selectedBoardListOfSelectedWork,
  onSelectedUserList,
  onSelectedCardList,
  onSelectedBoardList,
  onSelectedBoardListBySelectedWork,
  onSelectedBoardListOfSelectedWork,
}) => {
  if (event === 'CARD') {
    return (
      <RuleCardSelectView cardList={cardList} defaultIndex={defaultCardListIndex} onSelected={onSelectedCardList} />
    )
  }
  if (event === 'SPECIFIC_USER') {
    return <RuleUserSelectView userList={userList} onSelected={onSelectedUserList} />
  }
  if (event === 'SPECIFIC_BOARD') {
    return (
      <RuleBoardSelectView
        boardList={boardList}
        selectedBoardList={selectedBoardList}
        onSelectedBoardList={onSelectedBoardList}
      />
    )
  }
  if (event === 'ANOTHER_WORK_BOARD') {
    return (
      <RuleWorkSelectView
        workList={workList}
        selectedWork={selectedWork}
        boardList={boardListBySelectedWork}
        onSelectedBoardListBySelectedWork={onSelectedBoardListBySelectedWork}
        selectedBoardList={selectedBoardListOfSelectedWork}
        onSelectedBoardList={onSelectedBoardListOfSelectedWork}
      />
    )
  }
  return <></>
}

export default RuleBody
