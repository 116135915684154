import React, { useMemo, useState } from 'react'
import { Platform, Pressable, StyleSheet, TextStyle, View, ViewStyle } from 'react-native'

import { FAIcon } from '@rocket-atoms/icon'
import { Text } from '@rocket-atoms/text'
import { COLOR } from '@rocket-mono/libs'

import { faClose } from '@fortawesome/free-solid-svg-icons'

interface Props {
  size?: 'SMALL' | 'MEDIUM' | 'LARGE'
  name: string
  disabled?: boolean
  children?: JSX.Element
  onPress?: () => void
  onRemove?: () => void
  textStyle?: TextStyle
  containerStyle?: ViewStyle
}

const Tag: React.FC<Props> = ({
  name,
  size = 'SMALL',
  disabled = false,
  children,
  onPress,
  onRemove,
  textStyle,
  containerStyle,
}) => {
  const [isHover, setIsHover] = useState(false)
  const [isPress, setIsPress] = useState(false)

  const sizeValue = useMemo(() => (size === 'MEDIUM' ? 28 : 24), [size])

  return (
    <Pressable
      disabled={disabled}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      onPressIn={() => setIsPress(true)}
      onPressOut={() => setIsPress(false)}
      style={[
        styles.container,
        { height: sizeValue },
        onPress && {
          backgroundColor: COLOR.gray.g050,
        },
        onPress &&
          isHover && {
            backgroundColor: COLOR.gray.g100,
          },
        onPress &&
          isPress && {
            backgroundColor: COLOR.gray.g050,
          },
        onRemove && {
          backgroundColor: COLOR.gray.g050,
          paddingRight: 0,
        },
        disabled && {
          backgroundColor: COLOR.transparent.base,
          borderColor: COLOR.transparent.base,
        },
        containerStyle,
      ]}
      onPress={onPress}
    >
      {children && <View style={{ marginRight: 4 }}>{children}</View>}
      <Text
        fontColor="gray.g900"
        fontName={size === 'SMALL' ? 'txt2Xs' : size === 'MEDIUM' ? 'txtXs' : 'txtSm'}
        ellipsizeMode="tail"
        numberOfLines={1}
        style={[styles.textArea, textStyle]}
      >
        {name}
      </Text>
      {onRemove && (
        <Pressable
          onPress={onRemove}
          style={{ height: sizeValue, width: sizeValue, alignItems: 'center', justifyContent: 'center' }}
        >
          <FAIcon iconName={faClose} color="gray.g450" size={'xs'} />
        </Pressable>
      )}
    </Pressable>
  )
}

export default Tag

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    borderRadius: 4,
    padding: 4,
  },
  textArea: {
    ...(Platform.OS === 'web' ? { flex: 1 } : {}),
    maxWidth: 100,
  },
})
