import React from 'react'
import { Platform, Pressable, StyleSheet, View } from 'react-native'

import { faEllipsis, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons'
import { AvatarGroup } from '@rui/atoms'
import { Progress } from '@rocket-atoms/progress'
import { Button } from '@rocket-mono/foundations'
import { COLOR, ClickOutside, getRealColor } from '@rocket-mono/libs'
import { FAIcon, NoContentsIcon, TextField } from '@rocket/atoms'
import { useTranslation } from 'react-i18next'
import { Axis } from '../AddPopup'
import AxisContainer from '../AxisContainer'
import BoardHeader from '../BoardHeader'
import LabelPopup from '../LabelPopup'
import NoneButton from '../NoneButton'
import RuleButton from '../RuleButton'
import SelectBox from '../SelectBox'
import SlashMark from '../icons/slashMark'
import { Props } from './types'

export function BoardLayout({
  showLabel = false,
  title,
  onTitle,
  onAddMember,
  boardLabel,
  ruleCount,
  onLabelColor,
  children,
  containerStyle,
  iconCount,
  onIconPress,
  onClickChatButton,
  appList,
  onAppsPress,
  moreList,
  moreList2,
  onMorePress,
  moreBottomText,
  totalCardCount,
  doneCardCount,
  isAuthority,
  onPopupState,
  onPopupMoreState,
  onInviteMemberModal,
  manager,
  relatedManager,
  projectType,
  etcButton,
  handlePressBoardRole,
}: Props) {
  const { t } = useTranslation()

  const [value, setValue] = React.useState(title)

  const [isTitleUpdate, setIsTitleUpdate] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const [isMoreOpen, setIsMoreOpen] = React.useState(false)
  const [isLabelOpen, setIsLabelOpen] = React.useState(false)
  const [axis, setAxis] = React.useState<Axis>({ x: 0, y: 0 })

  // React.useEffect(() => {
  //   if (inputRef.current) inputRef.current.focus()
  // }, [isTitleUpdate, inputRef.current])

  const progress = React.useMemo(() => {
    if (totalCardCount === 0) return 0
    else if (doneCardCount > totalCardCount) return 0
    else if (doneCardCount == totalCardCount) return 100
    else return Math.round((doneCardCount / totalCardCount) * 100)
  }, [totalCardCount, doneCardCount])

  const memberGroupList = React.useMemo(() => {
    const list: any[] = []
    if (manager) {
      list.push({ userName: manager.name, profileUrl: manager.url })
    }
    if (relatedManager) {
      relatedManager.map((m) => list.push({ userName: m.name, profileUrl: m.url, onPress: onInviteMemberModal }))
    }
    return list.length > 0 ? list : undefined
  }, [manager, relatedManager, onInviteMemberModal])

  const MoreView = React.useMemo(() => {
    if (!moreList) return <></>
    return (
      <View>
        <Button
          style={{
            width: 24,
            height: 24,
            minWidth: 24,
            borderWidth: 0,
            paddingHorizontal: 4,
            backgroundColor: getRealColor('transparent'),
          }}
          onPress={() => setIsMoreOpen(true)}
        >
          <FAIcon iconName={faEllipsis} size={'xs'} color={'gray.g500'} />
        </Button>
        {isMoreOpen ? (
          <ClickOutside onClickOutside={() => setIsMoreOpen(false)}>
            <SelectBox style={{ right: 5, zIndex: 100, minWidth: 210 }}>
              {moreList.map((item) => (
                <SelectBox.Item
                  text={item.title}
                  onPress={() => {
                    setIsMoreOpen(false)
                    onPopupMoreState(item.state)
                  }}
                />
              ))}
              {moreList2 && moreList2.length > 0 ? <SelectBox.DividingLine /> : <></>}
              {moreList2 && moreList2.length > 0 ? (
                moreList2.map((item) => (
                  <SelectBox.Item
                    text={item.title}
                    color="main.red"
                    beforeIcon={faTrash}
                    beforeIconColor="main.red"
                    onPress={() => {
                      setIsMoreOpen(false)
                      onPopupMoreState(item.state)
                    }}
                  />
                ))
              ) : (
                <></>
              )}
            </SelectBox>
          </ClickOutside>
        ) : (
          <></>
        )}
      </View>
    )
  }, [moreList, moreList2, isMoreOpen])

  React.useEffect(() => setValue(title), [title])

  return (
    <View style={[styles.container, containerStyle]}>
      <View
        style={[
          {
            borderTopColor: getRealColor(boardLabel),
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 2,
          },
          Platform.select({
            web: {
              height: 4,
              borderTopWidth: 4,
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
            },
            native: {
              height: 6,
              borderTopWidth: 6,
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
            },
          }),
        ]}
      />
      {!isAuthority && (
        <SlashMark
          width={containerStyle && containerStyle.width ? (containerStyle.width as number) : 300}
          height={containerStyle && containerStyle.height ? (containerStyle.height as number) : 700}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      )}
      {isTitleUpdate ? (
        <View style={styles.headerContainer}>
          <View style={{ flex: 1 }}>
            <TextField
              value={title}
              placeholder=""
              onChangeValue={setValue}
              onBlur={() => {
                setIsTitleUpdate(false)
                onTitle(value)
              }}
              onSubmitEditing={() => {
                setIsTitleUpdate(false)
                onTitle(value)
              }}
              style={{ borderColor: 'transparent' }}
            />
          </View>
          <Pressable onPress={() => setIsTitleUpdate(false)}>
            <FAIcon iconName={faXmark} size={'sm'} color={'gray.g500'} />
          </Pressable>
        </View>
      ) : (
        <BoardHeader
          showLabel={showLabel}
          boardName={title}
          iconCount={iconCount}
          // onPressTitle={() => setIsTitleUpdate(true)}
          // iconColor={onLabelColor && labelColor}
          boardLabel={boardLabel}
          boardLabelMaxWidth={projectType === 'WFP' || etcButton ? 170 : 180}
          iconOnPress={() => {
            onIconPress()
            // setIsLabelOpen(() => true)
          }}
          onAxis={(a) => setAxis(a)}
          onClickChatButton={onClickChatButton}
          containerStyle={{
            justifyContent: 'space-between',
          }}
          renderExtraComponent={() => (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              {/* 워크스페이스인 경우에 보이는 규칙 */}
              {projectType === 'WFP' ? <RuleButton count={ruleCount} onBoardRole={handlePressBoardRole} /> : <></>}
              {etcButton}

              {/* {!!moreList && (
                <AxisContainer
                  containerStyle={{ width: 24 }}
                  isOpen={isMoreOpen}
                  axisIconName={faEllipsis}
                  setIsOpen={setIsMoreOpen}
                  appList={moreList}
                  onAppsPress={onMorePress}
                  addText={t(`board.setsettings`)}
                  buttonAddButtonStyle={{
                    width: 24,
                    height: 24,
                    borderWidth: 0,
                    paddingHorizontal: 4,
                    backgroundColor: getRealColor('transparent'),
                    marginLeft: 4,
                  }}
                  bottomText={moreBottomText}
                  onPopupState={(state) => {
                    setIsMoreOpen(false)
                    onPopupMoreState(state)
                  }}
                  popupContainerStyle={{
                    backgroundColor: COLOR.mono.white,
                  }}
                  popAxisPosition={'left'}
                  isIconOnly
                />
              )} */}
              {MoreView}
            </View>
          )}
        />
      )}

      <View style={styles.battomContainer}>
        <View style={styles.todoGaugeContainer}>
          <Progress
            progress={progress}
            showText={`${doneCardCount}/${totalCardCount}`}
            progressStyle={{ backgroundColor: COLOR.gray.g200 }}
          />
        </View>
        <View style={styles.memberContainer}>
          {memberGroupList ? (
            <Pressable onPress={onInviteMemberModal}>
              <AvatarGroup type={'STACK'} list={memberGroupList} size={'xxsmall'} />
            </Pressable>
          ) : (
            <NoneButton text={`+${t(`board.members`)}`} onPress={() => onAddMember?.()} />
          )}
        </View>
      </View>
      <AxisContainer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        appList={appList}
        onAppsPress={onAppsPress}
        buttonAddButtonStyle={{
          height: 34,
          borderStyle: 'dashed',
          borderWidth: 1,
          flex: 1,
          justifyContent: 'center',
        }}
        addPopupMarginTop={-5}
        // bottomText={'앱스 추가'}
        onPopupState={onPopupState}
        AxisWide={true}
        containerStyle={{ flex: 1 }}
      >
        {children ? (
          children
        ) : (
          <View style={styles.noContentArea}>
            <NoContentsIcon
              type={'board-card'}
              textSize={'medium'}
              text={t('nocontentsicon.boardcard.text')}
              subText={t('nocontentsicon.boardcard.subtext')}
              iconWidth={90}
            />
          </View>
        )}
      </AxisContainer>
      {onLabelColor && (
        <LabelPopup
          isOpen={isLabelOpen}
          setIsOpen={setIsLabelOpen}
          axis={axis}
          labelColor={boardLabel}
          onLabelColor={onLabelColor}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: `#EDEFF0`,
    // overflow: 'hidden',
    paddingHorizontal: 12,
    ...Platform.select({
      web: {
        paddingTop: 16,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      },
      native: {
        paddingTop: 18,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
      },
    }),
  },
  battomContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 12,
    marginBottom: 8,
  },
  todoGaugeContainer: {
    flex: 1,
  },
  title: {
    textAlign: 'center',
  },
  headerContainer: {
    marginTop: 4,
    marginBottom: 4,
    marginLeft: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  memberContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: 90,
    marginLeft: 8,
  },
  noContentArea: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
