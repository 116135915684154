import React from 'react'
import { View } from 'react-native'
import Item from './Item'
import type { TodoItemType } from './type'

const Items = ({
  isAnonymous,
  onAnonymousPress,
  item,
  onCardLinkPress,
  onMentionPress,
  onLinkPress,
  onPressItem,
}: {
  isAnonymous: boolean
  onAnonymousPress: () => void
  item: TodoItemType
  onCardLinkPress?: (cardNo: string, cardName: string, channelNo: string) => void
  onMentionPress?: (memberNo: string, memberName: string) => void
  onLinkPress?: (url: string, text?: string) => void

  onPressItem?: (id: string) => void
}) => {
  return (
    <Item
      isAnonymous={isAnonymous}
      onAnonymousPress={onAnonymousPress}
      item={item}
      onCardLinkPress={onCardLinkPress}
      onMentionPress={onMentionPress}
      onLinkPress={onLinkPress}
      onPressItem={onPressItem}
    />
  )
}

interface Props {
  isAnonymous: boolean
  onAnonymousPress: () => void
  list: TodoItemType[]
  onCardLinkPress?: (cardNo: string, cardName: string, channelNo: string) => void
  onMentionPress?: (memberNo: string, memberName: string) => void
  onLinkPress?: (url: string, text?: string) => void
  onPressItem?: (id: string) => void
}
const TodoItem: React.FC<Props> = ({
  isAnonymous,
  onAnonymousPress,
  list,
  onCardLinkPress,
  onMentionPress,
  onLinkPress,
  onPressItem,
}) => {
  return (
    <View>
      {list.map((item, idx) => (
        <View style={{ marginTop: 8 }} key={`todoItems-${idx}`}>
          <Items
            isAnonymous={isAnonymous}
            onAnonymousPress={onAnonymousPress}
            item={item}
            onCardLinkPress={onCardLinkPress}
            onMentionPress={onMentionPress}
            onLinkPress={onLinkPress}
            onPressItem={onPressItem}
          />
        </View>
      ))}
    </View>
  )
}

export default TodoItem
export type { TodoItemType }
