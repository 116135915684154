import React from 'react'
import { LayoutChangeEvent, Text, TextStyle, View, ViewStyle } from 'react-native'

import { Icon } from '@rocket-atoms/icon'
import { Tooltip } from '@rocket-atoms/tooltip'
import { COLOR, FONT, IconName } from '@rocket-mono/libs'

interface Props {
  title: string | JSX.Element
  count?: string | number
  infoTooltipTitle?: string
  infoTooltipRender?: () => JSX.Element
  iconName?: IconName
  children?: JSX.Element
  iconSize?: number
  titleSize?: number
  containerStyle?: ViewStyle
  headerContainerStyle?: ViewStyle
  iconContainerStyle?: ViewStyle
  titleStyle?: TextStyle
  titleWide?: boolean
  childrenContainerStyle?: ViewStyle
  renderExtraComponent?: JSX.Element
  layoutWidth?: number
  marginTop?: number
  onLayout?: (event: LayoutChangeEvent) => void
}

export const CardRowGroup: React.FC<Props> = ({
  title,
  count,
  infoTooltipTitle,
  infoTooltipRender,
  iconName,
  children,
  iconSize = 16,
  titleSize = 14,
  containerStyle,
  headerContainerStyle,
  iconContainerStyle,
  titleStyle,
  titleWide,
  childrenContainerStyle,
  renderExtraComponent,
  layoutWidth,
  marginTop = 20,
  onLayout,
}) => {
  return (
    <View onLayout={onLayout} style={[{ marginTop }, containerStyle]}>
      <View
        style={[
          {
            flexDirection: layoutWidth && layoutWidth < 480 ? 'column' : 'row',
            marginBottom: 8,
            justifyContent: 'space-between',
          },
          headerContainerStyle,
        ]}
      >
        <View
          style={[
            {
              width: children ? 200 : 140,
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 4,
            },
            titleWide && { width: '100%' },
            iconContainerStyle,
          ]}
        >
          {iconName && <Icon name={iconName} size={iconSize} color="gray.g300" style={{ marginRight: 4 }} />}

          <Text
            style={[
              {
                // fontSize: titleSize,
                ...FONT.txtSm,
                color: COLOR.gray.g600,
                alignItems: 'center',
              },
              titleStyle,
            ]}
          >
            {title}
            {count && Number(count) > 0 ? <Text style={{ color: COLOR.main.red }}> {count}</Text> : <></>}
            {infoTooltipRender && (
              <View>
                <Tooltip
                  text={infoTooltipTitle}
                  textStyle={{ ...FONT.txtSmMedium }}
                  direction="bottom"
                  render={infoTooltipRender}
                >
                  {(props) => (
                    <View {...props} style={{ marginLeft: 4 }}>
                      <Icon name="help" size={16} color={'gray.g700'} />
                    </View>
                  )}
                </Tooltip>
              </View>
            )}
          </Text>
        </View>
        {renderExtraComponent}
      </View>
      {children && (
        <View
          style={[
            {
              width: '100%',
              flexDirection: 'row',
            },
            childrenContainerStyle,
          ]}
        >
          {children}
        </View>
      )}
    </View>
  )
}
