import { Icon } from '@rocket-atoms/icon'
import { COLOR, LayoutPayload } from '@rocket-mono/libs'
import { RichTextField, RichTextFieldProvider, TriggerType } from '@rui/rich-text-field'
import * as React from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  Droppable,
} from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { Platform, Pressable, StyleSheet, View, ViewStyle } from 'react-native'
import { CardTodoAssigneeModal, CardTodoDateModal, CardTodoFileModal } from '../CardTodoEdit/modal'
import { ModalType, ShowModal } from '../CardTodoEdit/types'
import Checkbox from '../Checkbox'
import { CardLinkTargetPicker, MentionTargetPicker } from '../SendTargetPicker'
import { TodoItemOptionButton } from '../TodoItemOptionButton'
import { CardLinkProvider } from '../cardLink'
import { MentionProvider } from '../mention'
import { useWorkCardTodo } from '../providers'

export const MENTION_TEMPLATE =
  /<m\s(type="(?<type>[^"]+)"\s)?chatRoomMemberNo=(?<chatRoomMemberNo>\d+|all),? memberName="(?<memberName>[^"]+)"\/?>/

export const CARD_LINK_TEMPLATE =
  /<m\s(type="(?<type>[^"]+)"\s)?cardNo=(?<cardNo>\d+|all),? cardName="(?<cardName>[^"]+)",? channelNo=(?<channelNo>\d+|all)\/?>/

interface TodoItemEdit {
  key: string
  content?: string
  assignment?: string
  fileUpload?: boolean
  startDate?: Date
  endDate?: Date
  placeholder: string
  isCheck: boolean
  onChangeTodo: (content: string) => void
  onChangeCheck: () => void
  onPressUser: (payload: LayoutPayload) => void
  onPressDate: (payload: LayoutPayload) => void
  onPressFile: (payload: LayoutPayload) => void
  onPressMore: (payload: LayoutPayload) => void
  progress: string
  completeTodo: boolean
  isAssigneeCheck: boolean
  isDateCheck: boolean
  isFileCheck: boolean
  modal?: ShowModal
}

interface Props {
  list: TodoItemEdit[]
  containerStyle?: ViewStyle
  onShowModal?: (key: string, modalType: ModalType, payload: LayoutPayload) => void
  onChangeItemAtIndex: (from: number, to: number) => void
  editorRef?: React.RefObject<any>
  triggerList?: TriggerType[]
  onChangeEditIndex?: (index: number) => void
}

const Item = ({
  item,
  index,
  onShowModal,
  dragElement,
  editorRef,
  triggerList,
  onChangeEditIndex,
}: {
  item: TodoItemEdit
  index: number
  onShowModal?: (key: string, modalType: ModalType, payload: LayoutPayload) => void
  dragElement?: JSX.Element
  editorRef?: React.RefObject<any>
  triggerList: TriggerType[]
  onChangeEditIndex?: (index: number) => void
}) => {
  const { t } = useTranslation()
  const viewRef = React.useRef<View>(null)
  const [isFocused] = React.useState(false)
  const { projectMemberEntries, cardEntries } = useWorkCardTodo()

  const contents = React.useMemo(() => {
    let source = item.content ?? ''
    const matcher = new RegExp(/<m(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+(\/?)>/, 'g')
    const matches = source.match(matcher)
    const elements: string[] = []

    if (matches) {
      matches.forEach((match) => {
        const [before, _] = source.split(match)
        if (before) {
          elements.push(before)
        }

        console.log('replaceValue', match)

        const ttt = [MENTION_TEMPLATE, CARD_LINK_TEMPLATE]
          .map((o) => {
            return new RegExp(o, 'g').exec(match)?.groups
          })
          .find((o) => o !== undefined)

        if (ttt !== undefined) {
          let { type, cardNo, memberName } = ttt

          if (type === undefined && cardNo !== undefined) {
            type = 'CARD_LINK'
          } else if (type === undefined && memberName !== undefined) {
            type = 'MENTION'
          }

          if (type === 'CARD_LINK') {
            const { cardNo } = ttt
            elements.push(`#${cardNo}`)
          } else if (type === 'MENTION') {
            const { memberName } = ttt
            elements.push(`@${memberName}`)
          } else {
            elements.push(match)
          }
        }

        source = _ ?? ''
      })
      return elements.join('')
    } else {
      return source
    }
  }, [item.content])

  const period = React.useMemo(() => {
    if (item.startDate && item.endDate) {
      return `${t('format.date.L', { date: new Date(item.startDate) })} - ${t('format.date.L', {
        date: new Date(item.endDate),
      })}`
    } else if (item.startDate) {
      return `${t('format.date.L', { date: new Date(item.startDate) })} -`
    } else if (item.endDate) {
      return `- ${t('format.date.L', { date: new Date(item.endDate) })}`
    }
    return undefined
  }, [item.startDate, item.endDate, t])

  return (
    <View>
      <MentionProvider entries={projectMemberEntries}>
        <CardLinkProvider entries={cardEntries}>
          <View style={styles.wrap} ref={viewRef}>
            {dragElement ? (
              dragElement
            ) : (
              <Pressable style={styles.dragArea}>
                <Icon name={'drag-vertical'} size={16} color={'gray.g700'} />
              </Pressable>
            )}
            <Pressable onPress={() => item.onChangeCheck()} style={styles.checkboxArea}>
              <Checkbox checked={item.isCheck} size={18} type="TODO" />
            </Pressable>
            <View style={[styles.container, isFocused && styles.containerFocused]}>
              <View
                style={styles.contents}
                ref={(ref) => {
                  if (ref && Platform.OS === 'web') {
                    const $ref = ref as unknown as HTMLDivElement
                    $ref.style.setProperty('z-index', '999', 'important')
                  }
                }}
              >
                <RichTextFieldProvider
                  ref={(ref) => {
                    if (editorRef) {
                      editorRef.current[index] = ref
                    }
                  }}
                >
                  <RichTextField
                    value={contents}
                    onChangeValue={(text) => item.onChangeTodo(text)}
                    onFocus={() => onChangeEditIndex && onChangeEditIndex(index)}
                    triggerList={triggerList}
                  />
                </RichTextFieldProvider>
                {/* <TodoInput
                  inputRef={inputRef}
                  value={item.content || ''}
                  onChangeText={(text) => {
                    item.onChangeTodo(text)
                  }}
                /> */}
                <View style={styles.optionArea}>
                  <View style={styles.buttonList}>
                    <>
                      {item.isAssigneeCheck && item.assignment ? (
                        <TodoItemOptionButton
                          icon={'user-o'}
                          iconColor={'gray.g700'}
                          text={item.assignment}
                          onPress={() => {
                            if (viewRef.current) {
                              viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                                onShowModal &&
                                  onShowModal(item.key, 'assignee', {
                                    x,
                                    y,
                                    width,
                                    height,
                                    pageX,
                                    pageY,
                                  })
                              })
                            }
                          }}
                        />
                      ) : (
                        <TodoItemOptionButton
                          icon={'user-o'}
                          iconSize={20}
                          iconColor={'gray.g300'}
                          onPress={() => {
                            if (viewRef.current) {
                              viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                                onShowModal &&
                                  onShowModal(item.key, 'assignee', {
                                    x,
                                    y,
                                    width,
                                    height,
                                    pageX,
                                    pageY,
                                  })
                              })
                            }
                          }}
                          stateOff
                        />
                      )}
                    </>
                    <>
                      {item.isDateCheck && period ? (
                        <TodoItemOptionButton
                          icon={'time-o'}
                          iconColor={'gray.g700'}
                          text={period}
                          onPress={() => {
                            if (viewRef.current) {
                              viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                                onShowModal &&
                                  onShowModal(item.key, 'date', {
                                    x,
                                    y,
                                    width,
                                    height,
                                    pageX,
                                    pageY,
                                  })
                              })
                            }
                          }}
                        />
                      ) : (
                        <TodoItemOptionButton
                          icon={'time-o'}
                          iconSize={20}
                          iconColor={'gray.g300'}
                          onPress={() => {
                            if (viewRef.current) {
                              viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                                onShowModal &&
                                  onShowModal(item.key, 'date', {
                                    x,
                                    y,
                                    width,
                                    height,
                                    pageX,
                                    pageY,
                                  })
                              })
                            }
                          }}
                          stateOff
                        />
                      )}
                    </>
                    <>
                      {item.isFileCheck && item.fileUpload ? (
                        <TodoItemOptionButton
                          icon={'cloud-upload-o'}
                          iconColor={'gray.g700'}
                          onPress={() => {
                            if (viewRef.current) {
                              viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                                onShowModal &&
                                  onShowModal(item.key, 'file', {
                                    x,
                                    y,
                                    width,
                                    height,
                                    pageX,
                                    pageY,
                                  })
                              })
                            }
                          }}
                        />
                      ) : (
                        <TodoItemOptionButton
                          icon={'cloud-upload-o'}
                          iconSize={20}
                          iconColor={'gray.g300'}
                          onPress={() => {
                            if (viewRef.current) {
                              viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                                onShowModal &&
                                  onShowModal(item.key, 'file', {
                                    x,
                                    y,
                                    width,
                                    height,
                                    pageX,
                                    pageY,
                                  })
                              })
                            }
                          }}
                          stateOff
                        />
                      )}
                    </>
                  </View>
                  <Pressable
                    style={styles.buttonArea}
                    onPress={() => {
                      if (viewRef.current) {
                        viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                          item.onPressMore({
                            x,
                            y,
                            width,
                            height,
                            pageX,
                            pageY,
                          })
                        })
                      }
                    }}
                  >
                    <Icon name="ellipsis-h" size={14} />
                  </Pressable>
                </View>
              </View>
              <View
                style={[
                  styles.todoGauge,
                  item.completeTodo && {
                    width: Number(item.progress), // 완료 시 풀 게이지
                    backgroundColor: COLOR.todo.completeBar,
                  },
                ]}
              />
            </View>
          </View>
          <MentionTargetPicker
            direction="downside"
            marginLeft={42}
            marginRight={0}
            marginBottom={0}
            // whisperHeight={190}
          />
          <CardLinkTargetPicker
            direction="downside"
            marginLeft={50}
            marginRight={8}
            marginBottom={0}
            // whisperHeight={190}
          />
        </CardLinkProvider>
      </MentionProvider>
      {item.modal && item.modal.type === 'assignee' ? (
        <View style={styles.modalContainer}>
          <CardTodoAssigneeModal {...item.modal} />
        </View>
      ) : (
        <></>
      )}
      {item.modal && item.modal.type === 'date' ? (
        <View style={styles.modalContainer}>
          <CardTodoDateModal {...item.modal} />
        </View>
      ) : (
        <></>
      )}
      {item.modal && item.modal.type === 'file' ? (
        <View style={styles.modalContainer}>
          <CardTodoFileModal {...item.modal} />
        </View>
      ) : (
        <></>
      )}
    </View>
  )
}

const TodoItemEdit = ({
  list,
  containerStyle,
  onShowModal,
  onChangeItemAtIndex,
  editorRef,
  triggerList,
  onChangeEditIndex,
}: Props) => {
  const renderItem = React.useCallback(
    (provided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: DraggableRubric) => {
      const style = {
        ...provided.draggableProps.style,
        opacity: snapshot.isDragging ? 0.5 : 1,
      }
      return (
        <div ref={provided.innerRef} {...provided.draggableProps} style={style}>
          <View style={styles.itemWrap}>
            <Item
              item={list[rubric.source.index]}
              index={rubric.source.index}
              onShowModal={onShowModal}
              onChangeEditIndex={onChangeEditIndex}
              editorRef={editorRef}
              triggerList={triggerList ?? []}
              dragElement={
                <div {...provided.dragHandleProps}>
                  <View style={styles.dragArea}>
                    <Icon name={'drag-vertical'} size={16} color={'gray.g700'} />
                  </View>
                </div>
              }
            />
          </View>
        </div>
      )
    },
    [list, onShowModal],
  )
  return (
    <View style={containerStyle}>
      <DragDropContext
        onDragEnd={(result) => {
          if (result.destination) onChangeItemAtIndex(result.source.index, result.destination.index)
        }}
      >
        <Droppable droppableId="droppable" renderClone={renderItem}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {list.map((item, index) => (
                <Draggable key={`todoEdit-${item.key}`} draggableId={`${item.key}`} index={index}>
                  {renderItem}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </View>
  )
}

export default TodoItemEdit

const styles = StyleSheet.create({
  wrap: {
    flexDirection: 'row',
    zIndex: 100,
  },
  itemWrap: {
    marginTop: 8,
  },
  contents: {
    zIndex: 999,
  },
  todoGauge: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    backgroundColor: COLOR.gray.g150,
    zIndex: 99,
  },
  container: {
    overflow: 'hidden',
    flex: 1,
    borderRadius: 4,
    backgroundColor: COLOR.gray.g050,
    padding: 8,
  },
  containerFocused: {
    backgroundColor: COLOR.gray.g100,
  },
  checkboxArea: {
    paddingTop: 8,
    paddingRight: 8,
  },
  optionArea: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 8,
  },
  buttonArea: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 28,
  },
  buttonList: {
    flexDirection: 'row',
  },
  // textarea: {
  //   marginTop: 0,
  //   paddingTop: 4,
  //   ...Platform.select({
  //     web: {
  //       outlineWidth: 0,
  //     },
  //   }),
  // },
  dragArea: {
    paddingVertical: 8,
    paddingHorizontal: 4,
  },
  // wrapDragPress: {
  //   opacity: 0.5,
  // },
  modalContainer: {
    marginLeft: 50,
  },
})
