import React, { useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'

import { AstroProvider, PreviewProvider, StompProvider, ThemeProvider } from '@rocket-mono/providers'
import { Skeleton } from '@rocket/skeleton'
import { Platform } from 'react-native'
import { useRecoilValue } from 'recoil'
import i18nInstance from '../i18n'
import { authTokenState } from '../recoils'
import { options, previewUrl, stompUrl } from './env'

interface Props {
  children?: JSX.Element | JSX.Element[]
}

const BaseProviders: React.FC<Props> = ({ children }) => {
  const authToken = useRecoilValue(authTokenState)
  useEffect(() => {
    console.debug('BaseProviders-useEffect')
  }, [])
  return (
    <React.Suspense fallback={Platform.OS === 'web' ? <Skeleton path={location.pathname} /> : <></>}>
      <I18nextProvider i18n={i18nInstance}>
        <ThemeProvider>
          <AstroProvider token={authToken} option={options}>
            <StompProvider url={stompUrl} platform="web">
              <PreviewProvider previewUrl={previewUrl}>
                <>{children}</>
              </PreviewProvider>
            </StompProvider>
          </AstroProvider>
        </ThemeProvider>
      </I18nextProvider>
    </React.Suspense>
  )
}

export default BaseProviders
