import { useContext } from 'react'

import Context from './context'
import type { ContextProps } from './types'

export const useWorkmodel = () => {
  const context = useContext<ContextProps | undefined>(Context)

  if (context === undefined)
    throw new Error('There must be a WorkModelProvider as Ancestor of all Astro Hooks and HOCs')

  return context
}
