import * as React from 'react'
import { IColors, getRealColor } from '@rocket-mono/libs'
import Svg, { Path } from 'react-native-svg'

interface Props{
  size?: number
  fill?: boolean
  color?: IColors
}

const IconBoard = ({
  size = 24,
  fill,
  color
}: Props) => {
  return(
    fill ?
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.45996 4.75263C2.45996 3.61899 3.12896 3 4.26259 3H6.31522C7.44886 3 8.16663 3.61899 8.16663 4.75263V13.9895C8.16663 15.1231 7.44886 15.7067 6.31522 15.7067H4.26259C3.12896 15.7067 2.45996 15.1231 2.45996 13.9895V4.75263Z"
        fill={color? getRealColor(color) : '#5D6D78'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9166 4.75263C15.9166 3.61899 16.5856 3 17.7193 3H19.7719C20.9055 3 21.6233 3.61899 21.6233 4.75263V11.9895C21.6233 13.1231 20.9055 13.7067 19.7719 13.7067H17.7193C16.5856 13.7067 15.9166 13.1231 15.9166 11.9895V4.75263Z"
        fill={color? getRealColor(color) : '#5D6D78'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16663 4.95932C9.16663 3.82568 9.90671 3.00671 11.0404 3.00671H13.093C14.2266 3.00671 14.8733 3.82568 14.8733 4.95932V18.3014C14.8733 19.4351 14.2266 20.1567 13.093 20.1567H11.0404C9.90671 20.1567 9.16663 19.4351 9.16663 18.3014V4.95932Z"
        fill={color? getRealColor(color) : '#5D6D78'}
      />
    </Svg>
    :
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.45996 4.75263C2.45996 3.61899 3.12896 3 4.26259 3H6.31522C7.44886 3 8.16663 3.61899 8.16663 4.75263V13.9895C8.16663 15.1231 7.44886 15.7067 6.31522 15.7067H4.26259C3.12896 15.7067 2.45996 15.1231 2.45996 13.9895V4.75263ZM6.31522 4.75263L4.26259 4.75263V13.9895H6.31522V4.75263Z"
        fill={color? getRealColor(color) : '#5D6D78'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9166 4.75263C15.9166 3.61899 16.5856 3 17.7193 3H19.7719C20.9055 3 21.6233 3.61899 21.6233 4.75263V11.9895C21.6233 13.1231 20.9055 13.7067 19.7719 13.7067H17.7193C16.5856 13.7067 15.9166 13.1231 15.9166 11.9895V4.75263ZM19.7719 4.75263L17.7193 4.75263V11.9895H19.7719V4.75263Z"
        fill={color? getRealColor(color) : '#5D6D78'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16663 4.95932C9.16663 3.82568 9.90671 3.00671 11.0404 3.00671H13.093C14.2266 3.00671 14.8733 3.82568 14.8733 4.95932V18.3014C14.8733 19.4351 14.2266 20.1567 13.093 20.1567H11.0404C9.90671 20.1567 9.16663 19.4351 9.16663 18.3014V4.95932ZM13.093 4.95932H11.0404V18.3014H13.093V4.95932Z"
        fill={color? getRealColor(color) : '#5D6D78'}
      />
    </Svg>
  )
}

export default IconBoard