import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { COLOR, FONT } from '@rocket-mono/libs'
import { FAIcon } from '@rocket/atoms'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import RuleStepIcon from './RuleStepIcon'

export type RuleType = 'Applyboard' | 'Triggers' | 'Rules' | 'Action'

interface Props {
  type: RuleType
  active?: boolean
  onPress?: () => void
  onCancel?: () => void
  children?: React.ReactNode
}

const RuleStep: React.FC<Props> = ({ type, active, onPress, onCancel, children }) => {
  const { t } = useTranslation()
  const [isHover, setIsHover] = useState(false)

  const name = React.useMemo(() => {
    if (type === 'Applyboard') {
      return t('rule.modal.target') // 'Applyboard'
    } else if (type === 'Triggers') {
      return t('rule.modal.trigger') // 'Triggers'
    } else if (type === 'Rules') {
      return t('rule.modal.rule') // 'Rules'
    } else if (type === 'Action') {
      return t('rule.modal.automation') // 'Automation'
    } else return type
  }, [type])

  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      style={[
        styles.container,
        isHover && styles.hoverContainer,
        active && type === 'Applyboard' && { backgroundColor: '#F5FBF8' },
        active && type === 'Triggers' && { backgroundColor: '#EDF6FF' },
        active && type === 'Rules' && { backgroundColor: '#FFF6F7' },
        active && type === 'Action' && { backgroundColor: '#F4F3FA' },
      ]}
      onPress={onPress}
    >
      <RuleStepIcon type={type} active={active} />
      <View style={styles.contentsWrap}>
        <Text
          style={[styles.labelName, active && styles.labelNameActive, active && !!children && styles.labelNameActived]}
        >
          {name}
        </Text>
        {children ? <View style={styles.contents}>{children}</View> : <></>}
      </View>
      {active && children && (
        <Pressable onPress={() => onCancel?.()} style={styles.initButton}>
          <FAIcon iconName={faXmark} size={'xs'} color={'gray.g600'} />
        </Pressable>
      )}
    </Pressable>
  )
}

export default RuleStep

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: '#F5F6F7',
    borderRadius: 4,
    padding: 14,
    borderWidth: 2,
    borderColor: COLOR.transparent.base,
  },
  hoverContainer: {
    borderColor: COLOR.opacity.blk05,
  },
  contentsWrap: {
    flex: 1,
    marginLeft: 8,
  },
  contents: {
    flex: 1,
    backgroundColor: COLOR.mono.white,
    borderRadius: 4,
    paddingHorizontal: 12,
    paddingVertical: 8,
    marginTop: 4,
  },
  labelName: {
    ...FONT.txtSmBold,
    color: COLOR.gray.g700,
  },
  labelNameActive: {
    color: COLOR.mono.black,
  },
  labelNameActived: {
    ...FONT.txtXsBold,
  },
  initButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 99,
  },
})
