import * as React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { COLOR, FONT } from '@rocket-mono/libs'

interface Props {
  render?: () => JSX.Element
  children: React.ReactNode
}
const TooltipPreview = ({ render, children }: Props) => {
  return (
    <View style={styles.tooltipContainer}>
      <Text style={styles.tooltipText}>{render && render()}</Text>
      <View style={styles.previewArea}>{children}</View>
    </View>
  )
}

export default TooltipPreview

const styles = StyleSheet.create({
  tooltipContainer: {
    flexDirection: 'column',
    maxWidth: 290,
    backgroundColor: COLOR.gray.g050,
    padding: 12,
    paddingBottom: 0,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  tooltipText: {
    ...FONT.txtXs,
    paddingBottom: 12,
  },
  previewArea: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
})
