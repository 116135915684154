import * as React from 'react'
import { StyleSheet, Pressable, Text, View, DimensionValue } from 'react-native'
import { FAIcon } from '@rocket-atoms/icon'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { COLOR, FONT } from '@rocket-mono/libs'

interface Props {
  type: 'COMMON' | 'WHITE'
  width?: DimensionValue
  text?: string
  onPress?: () => void
}

const AddBarButton = ({ type, width, text, onPress }: Props) => {
  const [isHover, setIsHover] = React.useState(false)

  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      onPress={onPress}
      style={[
        styles.container,
        isHover && styles.hoverContainer,
        type === 'WHITE' && { backgroundColor: COLOR.mono.white },
        width ? { width } : {},
      ]}
    >
      <FAIcon iconName={faPlus} size={'xs'} color={'gray.g450'} />
      {text && <Text style={styles.textStyle}>{text}</Text>}
    </Pressable>
  )
}

export default AddBarButton

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 32,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: COLOR.gray.g300,
    backgroundColor: COLOR.gray.g100,
    borderRadius: 4,
  },
  hoverContainer: {
    backgroundColor: COLOR.gray.g150,
    borderColor: COLOR.gray.g400,
  },
  textStyle: {
    marginLeft: 4,
    ...FONT.txtSmMedium,
    color: COLOR.gray.g700,
  },
})
