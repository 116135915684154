import React from 'react'
import { Pressable, StyleSheet, ViewStyle } from 'react-native'
import { View } from '@rocket-mono/foundations'
import { COLOR } from '@rui/foundations'
import { Handler } from './handler'

export interface SelectSwitchTextProps {
  containerStyle?: ViewStyle
  activated: boolean
  scale?: number
  leftMode: string
  rightMode: string
  leftTooltip: string
  rightTooltip: string
  onChange: () => void
  theme?: 'LIGHT' | 'DARK'
}

const WorkBoardSwitch: React.FC<SelectSwitchTextProps> = ({
  containerStyle,
  activated,
  scale = 1,
  leftMode,
  rightMode,
  leftTooltip,
  rightTooltip,
  onChange,
  theme,
}) => {
  return (
    <View style={containerStyle}>
      <Pressable onPress={onChange} hitSlop={hitSlop}>
        <View
          style={[
            styles.container,
            theme === 'DARK' && styles.darkContainer,
            {
              transform: [{ scale }],
            },
          ]}
        >
          <Handler onSwitch={onChange} activated={activated} mode={leftMode} tooltip={leftTooltip} theme={theme} />
          {/* <Handler onSwitch={onChange} activated={!activated} mode={rightMode} tooltip={rightTooltip} theme={theme} /> */}
        </View>
      </Pressable>
    </View>
  )
}

export default WorkBoardSwitch

const hitSlop = {
  top: 22,
  left: 22,
  right: 22,
  bottom: 22,
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-start',
    alignItems: 'center',
    paddingHorizontal: 3,
    paddingVertical: 3,
    borderRadius: 4,
    backgroundColor: COLOR.gray.g100,
  },
  darkContainer: {
    backgroundColor: COLOR.darkmode.cool800,
  },
})
