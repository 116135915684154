import React, { useEffect, useState } from 'react'
import { View, Text } from 'react-native'
import { useTranslation } from 'react-i18next'

import { Breadcrumbs } from '@rui/atoms'
import { FAIcon, RNSvg, FAFreeSolidSvgIcons, WorkIcon, BoardLabel } from '@rui/icons'
import { COLOR, FONT, IColors } from '@rui/foundations'

import { Tooltip } from '@rocket/atoms'

import { styles } from '../styles'

const { Path, Svg } = RNSvg
const { faHouseFlag, faPen } = FAFreeSolidSvgIcons

interface Props {
  workType?: 'DSP' | 'WFP' | 'CSP' | 'TOP'
  workName: string
  boardName: string
  boardColor?: IColors
  created: string
  userCreated: string
  lastEdited?: string
  userEdited?: string
  isDone?: boolean
}
const CardInfoBox = ({
  workType,
  workName,
  boardName,
  boardColor,
  created,
  userCreated,
  lastEdited,
  userEdited,
  isDone,
}: Props) => {
  const [flexTypeColumn, setFlexTypeColumn] = useState(false)
  const [boxWidth, setBoxWidth] = useState(0)
  const [baseHistoryWidth, setBaseHistoryWidth] = useState(0)
  const [archiveHistoryWidth, setArchiveHistoryWidth] = useState(0)

  useEffect(() => {
    baseHistoryWidth + archiveHistoryWidth > boxWidth ? setFlexTypeColumn(true) : setFlexTypeColumn(false)
  }, [boxWidth])

  const { t } = useTranslation()

  return (
    <View>
      <View style={{ marginBottom: 8 }} onLayout={(e) => setBoxWidth(e.nativeEvent.layout.width)}>
        <Breadcrumbs
          list={[
            { text: workName, children: <WorkIcon type={workType as string} size={16} /> },
            { text: boardName, children: <BoardLabel color={boardColor} size={16} /> },
          ]}
        />
      </View>
      <View
        style={[
          styles.cardInfoWrap,
          flexTypeColumn
            ? { flexDirection: 'column', justifyContent: 'flex-start' }
            : { flexDirection: 'row', justifyContent: 'space-between' },
        ]}
      >
        <View
          style={[styles.cardInfo, { flexShrink: 10 }]}
          onLayout={(e) => setBaseHistoryWidth(e.nativeEvent.layout.width)}
        >
          <View style={{ flexDirection: 'row', flexShrink: 1 }}>
            <Tooltip gab={0} darkType={true} direction={'bottom'} text={t('card.created')}>
              {(props) => (
                <View {...props} style={styles.iconWrap}>
                  <FAIcon iconName={faHouseFlag} size={'2xs'} nativeSize={13} color={'gray.g600'} />
                </View>
              )}
            </Tooltip>
            <Text style={styles.cardInfoText}>{created}</Text>
            <View style={[styles.cardInfoUserText, { flexShrink: 1 }]}>
              <Tooltip gab={0} darkType={true} direction={'bottom'} text={userCreated}>
                {(props) => (
                  <View {...props}>
                    <Text numberOfLines={1} ellipsizeMode="tail" style={[styles.cardInfoText, styles.cardInfoUserText]}>
                      @{userCreated}
                    </Text>
                  </View>
                )}
              </Tooltip>
            </View>
          </View>
          {lastEdited !== undefined && userEdited !== undefined && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                flexShrink: 2,
              }}
            >
              <View style={styles.divLine} />
              <Tooltip gab={0} darkType={true} direction={'bottom'} text={t('card.lastEdited')}>
                {(props) => (
                  <View {...props} style={styles.iconWrap}>
                    <FAIcon iconName={faPen} size={'2xs'} nativeSize={10} color={'gray.g600'} />
                  </View>
                )}
              </Tooltip>
              <Text style={styles.cardInfoText}>{lastEdited}</Text>
              <View style={[styles.cardInfoUserText, { flexShrink: 1 }]}>
                <Tooltip gab={0} darkType={true} direction={'bottom'} text={userEdited}>
                  {(props) => (
                    <View {...props}>
                      <Text
                        numberOfLines={1}
                        ellipsizeMode="tail"
                        style={[styles.cardInfoText, styles.cardInfoUserText]}
                      >
                        @{userEdited}
                      </Text>
                    </View>
                  )}
                </Tooltip>
              </View>
            </View>
          )}
        </View>
        {isDone && (
          <View
            style={{ flexDirection: 'row', alignItems: 'center' }}
            onLayout={(e) => setArchiveHistoryWidth(e.nativeEvent.layout.width)}
          >
            <Tooltip gab={0} darkType={true} direction={'bottom'} text={t('card.archive')}>
              {(props) => (
                <View {...props} style={[styles.iconWrap, { backgroundColor: COLOR.main.red }]}>
                  <Svg width={12} height={12} viewBox="0 0 26 26" fill="none">
                    <Path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.38149 4.42578H6.53422C5.78161 4.42578 5.4053 4.42578 5.11991 4.57689C4.88961 4.69883 4.70127 4.88717 4.57933 5.11747C4.42822 5.40286 4.42822 5.77917 4.42822 6.53178V19.4626C4.42822 20.2153 4.42822 20.5916 4.57933 20.8769C4.70127 21.1073 4.88961 21.2956 5.11991 21.4175C5.4053 21.5686 5.78161 21.5686 6.53422 21.5686H9.38149V4.42578ZM10.5243 21.5686H15.4773V4.42578H10.5243V21.5686ZM16.6202 4.42578V21.5686H19.4651C20.2177 21.5686 20.594 21.5686 20.8794 21.4175C21.1097 21.2956 21.298 21.1073 21.42 20.8769C21.5711 20.5916 21.5711 20.2153 21.5711 19.4626V6.53178C21.5711 5.77917 21.5711 5.40286 21.42 5.11747C21.298 4.88717 21.1097 4.69883 20.8794 4.57689C20.594 4.42578 20.2177 4.42578 19.4651 4.42578H16.6202ZM7.85633 8.23875C7.85633 8.65953 7.51521 9.00065 7.09442 9.00065C6.67364 9.00065 6.33252 8.65953 6.33252 8.23875C6.33252 7.81796 6.67364 7.47684 7.09442 7.47684C7.51521 7.47684 7.85633 7.81796 7.85633 8.23875ZM12.9998 9.00065C13.4206 9.00065 13.7617 8.65953 13.7617 8.23875C13.7617 7.81796 13.4206 7.47684 12.9998 7.47684C12.579 7.47684 12.2379 7.81796 12.2379 8.23875C12.2379 8.65953 12.579 9.00065 12.9998 9.00065ZM19.6672 8.23875C19.6672 8.65953 19.326 9.00065 18.9053 9.00065C18.4845 9.00065 18.1433 8.65953 18.1433 8.23875C18.1433 7.81796 18.4845 7.47684 18.9053 7.47684C19.326 7.47684 19.6672 7.81796 19.6672 8.23875ZM8.23851 15.8599H5.57185V16.8123H8.23851V15.8599ZM11.6677 15.8599H14.3344V16.8123H11.6677V15.8599ZM20.4281 15.8599H17.7614V16.8123H20.4281V15.8599ZM5.57185 17.5717H8.23851V18.5241H5.57185V17.5717ZM14.3342 17.5717H11.6676V18.5241H14.3342V17.5717ZM17.7613 17.5717H20.428V18.5241H17.7613V17.5717Z"
                      fill={COLOR.mono.white}
                    />
                  </Svg>
                </View>
              )}
            </Tooltip>
            <Text style={[styles.cardInfoText, { ...FONT.txt2XsMedium, color: COLOR.main.red }]}>Oct/12/2023</Text>
          </View>
        )}
      </View>
    </View>
  )
}

export default CardInfoBox
