import { COLOR } from '@rui/foundations'
import { DropdownListItem } from '@rocket/atoms'
import React from 'react'
import { StyleSheet } from 'react-native'
import { SearchLayerSectionItemProps } from './types'

const SearchLayerSectionItem: React.FC<SearchLayerSectionItemProps> = ({ item, section, onItemSelect }) => {
  if (section.isFold) return <></>
  return (
    <DropdownListItem
      itemType="CHECKED"
      item={{
        label: item.title,
        code: item.id,
        beforeWorkIcon: item.type,
      }}
      selected={
        item.isSelected
          ? [
              {
                label: item.title,
                code: item.id,
                beforeWorkIcon: item.type,
              },
            ]
          : []
      }
      itemVariant={'DEFAULT'}
      onPressItem={() => onItemSelect?.(section.key, item)}
      itemStyle={styles.itemStyle}
    />
  )
}

const styles = StyleSheet.create({
  itemStyle: {
    paddingLeft: 16,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g100,
  },
})

export default SearchLayerSectionItem
