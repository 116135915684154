import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { View } from '@rocket-mono/foundations'
import { IColors } from '@rocket-mono/libs'
import { Text, FAIcon } from '@rocket/atoms'
import * as React from 'react'
import { Pressable, StyleSheet } from 'react-native'

interface LinkbuttonType {
  text: string
  iconName?: IconProp
  size?: number
  color?: IColors
  onPress?: () => void
}

export const InfoLinkButton = ({ text, iconName, color, onPress }: LinkbuttonType) => {
  const [isHover, setIsHover] = React.useState(false)

  return (
    <Pressable
      onPress={onPress}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      style={[styles.flexBox, isHover && { opacity: 0.8 }]}
    >
      {iconName && (
        <View style={styles.iconArea}>
          <FAIcon
            iconName={iconName}
            // size={size}
            color={color}
          />
        </View>
      )}
      <Text fontName="txt2Xs" fontColor="gray.g200" style={styles.textStyle}>
        {text}
      </Text>
      <FAIcon
        iconName={faAngleRight}
        //  size={10}
        color={'gray.g400'}
      />
    </Pressable>
  )
}

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  iconArea: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 12,
    height: 12,
    marginRight: 4,
  },
  textStyle: {
    marginRight: 8,
  },
})
