import * as React from 'react'
import { View, StyleSheet, Pressable, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import { faAngleRight, faCheck } from '@fortawesome/free-solid-svg-icons'
import { LayoutTopWorkListType } from './types'
import { COLOR, FONT } from '@rocket-mono/libs'
import { FAIcon, WorkIcon } from '@rocket/atoms'

const WorkItem = ({ item }: { item: LayoutTopWorkListType }) => {
  const { t } = useTranslation()
  const Item = ({ item }: { item: { active?: boolean; type: string; name: string; onPress: () => void } }) => {
    const [isHover, setIsHover] = React.useState(false)
    return (
      <Pressable
        style={[
          styles.inner,
          isHover && { backgroundColor: COLOR.gray.g050 },
          item.active && {
            backgroundColor: COLOR.primary.blue05,
            borderColor: COLOR.primary.blue100,
          },
        ]}
        onHoverIn={() => setIsHover(true)}
        onHoverOut={() => setIsHover(false)}
        onPress={item.onPress}
      >
        <View style={[styles.workArea]}>
          <View style={{ width: 16 }}>
            <WorkIcon type={item.type} size={16} />
          </View>
          <Text
            style={[styles.workName, item.active && { color: COLOR.primary.blue500 }]}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {item.name}
          </Text>
        </View>
        <FAIcon
          iconName={item.active ? faCheck : faAngleRight}
          size={'xs'}
          color={item.active ? 'primary.blue500' : 'gray.g500'}
        />
      </Pressable>
    )
  }
  return (
    <View style={{ marginBottom: 4 }}>
      <Text
        style={styles.groupName}
        numberOfLines={1}
        ellipsizeMode="tail"
      >
        {t('layouttopworklist.group')}
      </Text>

      {item.work.map((el, idx) => (
        <Item item={el} key={`item-el${idx}`} />
      ))}
    </View>
  )
}

export default WorkItem

const styles = StyleSheet.create({
  groupName: {
    marginBottom: 8,
    ...FONT.txtXsMedium,
    color: COLOR.gray.g500
  },
  workName: {
    ...FONT.txtSm,
    marginLeft: 8,
  },
  inner: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 32,
    paddingHorizontal: 8,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: COLOR.gray.g150,
    marginBottom: 4,
  },
  workArea: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
})
