import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { LayoutSideNavWorkItemType, Props } from './types'
import LayoutSideNav from './components/LayoutSideNav'

export const LayoutNavView: React.FC<Props> = ({
  userId,
  width,
  projectList,
  archiveList,
  guestList,
  onPressWork,
  onPressGuest,
  onPressWorkCreate,
}) => {
  const { t } = useTranslation()

  const storageUse = useMemo(() => {
    const list = projectList.filter(({ members }) => {
      const memberAuth = members.find((o) => o.userId === userId)?.auth || ''
      return ['OWNER'].includes(memberAuth) ? true : false
    })
    return list.length > 0 ? list.map(({ storageUse }) => storageUse).reduce((prev, cur) => prev + cur, 0) : 0
  }, [userId, projectList])

  const workInfo = useMemo(() => {
    const workList = projectList.filter(({ members }) => {
      const memberAuth = members.find((o) => o.userId === userId)?.auth || ''
      return ['OWNER', 'MANAGER'].includes(memberAuth) ? true : false
    })
    const sharedList = projectList.filter(({ members }) => {
      const memberAuth = members.find((o) => o.userId === userId)?.auth || ''
      return ['MEMBER'].includes(memberAuth) ? true : false
    })

    return [
      {
        key: 'work',
        isShow: true,
        label: t('sidenavscreen.work'), // '나의 WORK',
        data: workList.map((o) => ({
          id: Number(o.id),
          type: o.type.code,
          name: o.title,
        })),
      },
      {
        key: 'shared',
        isShow: true,
        label: t('sidenavscreen.shared'), // '공유받은 WORK',
        data: sharedList.map((o) => ({
          id: Number(o.id),
          type: o.type.code,
          name: o.title,
        })),
      },
      {
        key: 'archive',
        isShow: false,
        label: t('sidenavscreen.archive'), // '종료된 WORK',
        data: archiveList.map((o) => ({
          id: Number(o.id),
          type: o.type.code,
          name: o.title,
        })),
      },
    ]
  }, [t, userId, projectList, archiveList])

  const handlePressWork = useCallback(
    (work: LayoutSideNavWorkItemType) => {
      onPressWork && onPressWork(String(work.id))
    },
    [onPressWork],
  )

  return (
    <LayoutSideNav
      width={width}
      workInfo={workInfo}
      guestList={guestList}
      storageUse={storageUse}
      storageMax={-1}
      onWork={handlePressWork}
      onGuest={onPressGuest}
      onWorkCreate={onPressWorkCreate}
    />
  )
}
