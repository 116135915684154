import React, { useState, useRef, useEffect } from 'react'
import { Pressable, StyleSheet, View, Animated } from 'react-native'

import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { COLOR } from '@rocket-mono/libs'
import { Badge } from '@rocket-mono/foundations'
import { FAIcon } from '@rocket/atoms'

interface Props {
  theme?: 'dark' | 'light'
  code: string
  icon?: JSX.Element
  iconName?: IconProp
  isAcitve?: boolean
  isUnread?: boolean
  isCount?: number
  onPress?: () => void
}
const ActivityMenu: React.FC<Props> = ({ theme, icon, iconName, isAcitve, isUnread, isCount, onPress }) => {
  const [isHover, setIsHover] = useState(false)
  const colorAnim = useRef(new Animated.Value(0)).current

  useEffect(() => {
    handleAnimation()
  }, [theme])

  const handleAnimation = () => {
    if (theme === 'dark') {
      Animated.timing(colorAnim, {
        toValue: 1,
        duration: 300,
        useNativeDriver: false,
      }).start()
    } else {
      Animated.timing(colorAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      }).start()
    }
  }

  const colorChange = colorAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [`rgba(255,255,255, 1)`, `rgba(46, 47, 50, 1)`],
  })

  return (
    <Pressable onHoverIn={() => setIsHover(true)} onHoverOut={() => setIsHover(false)} onPress={onPress}>
      <Animated.View
        style={[
          styles.container,
          { backgroundColor: colorChange },
          isAcitve && { backgroundColor: COLOR.primary.blue05 },
          isHover && { backgroundColor: COLOR.gray.g050 },
          theme === 'dark' && isHover && { backgroundColor: COLOR.darkmode.warm800 },
        ]}
      >
        {icon !== undefined && <>{icon}</>}
        {iconName && (
          <FAIcon
            iconName={iconName}
            color={isAcitve ? 'primary.blue500' : theme === 'dark' ? 'gray.g400' : 'gray.g800'}
          />
        )}

        {isUnread && (
          <View style={styles.unread}>
            <View style={styles.dot}></View>
          </View>
        )}

        {isCount && (
          <View style={styles.badge}>
            <Badge type={'DEFAULT'} count={isCount} />
          </View>
        )}
      </Animated.View>
    </Pressable>
  )
}

export default ActivityMenu

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    marginLeft: 8,
    borderRadius: 8,
  },
  unread: {
    position: 'absolute',
    top: 2,
    right: 2,
  },
  dot: {
    width: 6,
    height: 6,
    backgroundColor: COLOR.main.red,
    borderRadius: 12,
  },
  badge: {
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'center',
    minWidth: 28,
    bottom: -2,
    right: -6,
  },
})
