import { LayoutNaviView } from './view'
import Providers from '../providers'
import React from 'react'

interface Props {
  type?: 'DEFAULT' | 'TABLEORDER' | 'GUEST'
  side?: 'left' | 'right'
  state?: string
  onState?: (state: string) => void
  onSide?: (side: string) => void
  iconHeight?: number
}

export const LayoutNavi: React.FC<Props> = ({ ...props }) => {
  return (
    <Providers>
      <LayoutNaviView {...props} />
    </Providers>
  )
}
