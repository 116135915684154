import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { COLOR, FONT } from '@rocket-mono/libs'
import { IconBoard } from '@rocket-atoms/icon'
import { Tag } from '@rocket/atoms'
interface Props {
  archive?: boolean
  current?: boolean
  boardTitle: string
}

const CardSearchBoardTab = ({ archive, current, boardTitle }: Props) => {
  return (
    <View style={[styles.container, archive && { backgroundColor: COLOR.gray.g050 }]}>
      <IconBoard size={16} fill color={current ? 'primary.blue500' : 'gray.g400'} />
      {archive && (
        <Tag
          name="Archive"
          containerStyle={{ backgroundColor: COLOR.gray.g700, marginLeft: 4 }}
          textStyle={{ color: COLOR.mono.white }}
        />
      )}
      <Text style={styles.nameText}>{boardTitle}</Text>
    </View>
  )
}

export default CardSearchBoardTab

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 4,
    paddingHorizontal: 12,
    borderTopWidth: 1,
    borderColor: COLOR.gray.g150,
    backgroundColor: COLOR.mono.white,
  },
  nameText: {
    ...FONT.txtSmBold,
    color: COLOR.gray.g900,
    marginLeft: 4,
  },
})
