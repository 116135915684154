import {
  ActionRequest,
  AutomationResponse,
  Card,
  Channel,
  ChannelMember,
  Project,
  ActionType as RTActionType,
  AutomationRequest as RTAutomationRequest,
  RuleType as RTRuleType,
  ScheduleType as RTScheduleType,
  TriggerType as RTTriggerType,
  RuleRequest,
  TriggerRequest,
} from '@rocket/types'
import { TFunction } from 'i18next'
import { RuleValueIsTimeProps } from '../components/RuleStep/RuleValueTemplate'
import { Action, ActionType, Rule, RuleEventType, Trigger, TriggerType } from './types'
import { RuleListItemType } from './view/ListView'

type AutomationRequest = RTAutomationRequest & {
  id?: string
}

const pad = (n: number, size = 2) => {
  return String(n).padStart(size, '0')
}

const convertTriggerType = (type: RTTriggerType): TriggerType => {
  switch (type) {
    case 'WHEN_CARD_CREATED':
      return 'NEWCARD'
    case 'WHEN_TIME_REACHED':
      return 'TIME'
    default:
      return 'NEWCARD'
  }
}

const convertWeekly = (weekly: number) => {
  switch (weekly) {
    case 1:
      return 'MON'
    case 2:
      return 'TUE'
    case 3:
      return 'WED'
    case 4:
      return 'THU'
    case 5:
      return 'FRI'
    case 6:
      return 'SAT'
    case 7:
      return 'SUN'
  }
}

const convertTrigger = (trigger: TriggerRequest, t: TFunction): Trigger => {
  const type = convertTriggerType(trigger.type)
  let name = ''
  if (type === 'NEWCARD') {
    name = t('rule.trigger.title')
  } else if (type === 'TIME') {
    name = t('rule.repeat.title')
  }
  let isTime: RuleValueIsTimeProps | undefined = undefined
  if (trigger.scheduleType === 'EVERY_WEEK' && trigger.dayOfTheWeek !== undefined) {
    const week = convertWeekly(trigger.dayOfTheWeek)
    if (week) {
      isTime = {
        weekly: [week],
        time: trigger.hourAndMinute,
        timezone: trigger.timezone,
      }
    }
  } else if (trigger.scheduleType === 'EVERY_MONTH' && trigger.day !== undefined) {
    isTime = {
      monthly: pad(trigger.day),
      time: trigger.hourAndMinute,
      timezone: trigger.timezone,
    }
  } else if (trigger.scheduleType === 'EVERY_YEAR' && trigger.month !== undefined && trigger.day !== undefined) {
    isTime = {
      yearly: {
        month: pad(trigger.month),
        day: pad(trigger.day),
      },
      time: trigger.hourAndMinute,
      timezone: trigger.timezone,
    }
  }
  return {
    type,
    name,
    isTime,
  }
}

const convertRuleEvent = (rule: RuleRequest): RuleEventType => {
  if (rule.type === 'TO_SPECIFIC_CARD') {
    return 'CARD'
  } else if (rule.type === 'TO_SPECIFIC_USER') {
    return 'SPECIFIC_USER'
  } else if (rule.type === 'TO_SPECIFIC_BOARD') {
    if (rule.workId) {
      return 'ANOTHER_WORK_BOARD'
    } else {
      return 'SPECIFIC_BOARD'
    }
  } else {
    return 'CARD'
  }
}

const convertRule = (
  rule: RuleRequest,
  projectList?: Project[],
  cardList?: Card[],
  memberList?: ChannelMember[],
  archiveBoardList?: Channel[],
  unArchiveBoardList?: Channel[],
): Rule => {
  let event: RuleEventType = 'CARD'
  let selectedUsers: ChannelMember[] | undefined = undefined
  let selectedWork: Project | undefined = undefined
  let selectedBoard: Channel[] | undefined = undefined
  let selectedCard: Card | undefined = undefined

  if (rule) {
    event = convertRuleEvent(rule)
  }
  if (event === 'CARD' && cardList && rule.cardIdList) {
    selectedCard = cardList.filter((card) => rule.cardIdList?.includes(Number(card.cardId)))[0]
  } else if (event === 'SPECIFIC_USER' && memberList && rule.userIdList) {
    selectedUsers = memberList.filter((member) => rule.userIdList?.includes(Number(member.userId)))
  } else if (event === 'SPECIFIC_BOARD' && archiveBoardList && unArchiveBoardList && rule.boardIdList) {
    selectedBoard = [...archiveBoardList, ...unArchiveBoardList].filter((board) =>
      rule.boardIdList?.includes(Number(board.id)),
    )
  } else if (
    event === 'ANOTHER_WORK_BOARD' &&
    projectList &&
    archiveBoardList &&
    unArchiveBoardList &&
    rule.workId &&
    rule.boardIdList
  ) {
    selectedWork = projectList.filter((project) => project.id === String(rule.workId))[0]
    selectedBoard = [...archiveBoardList, ...unArchiveBoardList].filter((board) =>
      rule.boardIdList?.includes(Number(board.id)),
    )
  }
  return {
    event,
    selectedUsers,
    selectedWork,
    selectedBoard,
    selectedCard,
  }
}

const convertActionEvent = (action: ActionRequest): ActionType => {
  if (action.type === 'NOTIFICATION_VIA_EMAIL') {
    return 'MAIL'
  } else if (action.type === 'CHANGE_ASSIGNEE') {
    return 'INCHARGE'
  } else if (action.type === 'COPY_CARD_TO_SPECIFIC_BOARD') {
    return 'COPY'
  } else if (action.type === 'COPY_CARD') {
    return 'COPY'
  } else if (action.type === 'SHARE_CARD') {
    return 'COOP'
  }
  return 'MAIL'
}

const convertAction = (
  action: ActionRequest,
  projectList?: Project[],
  archiveBoardList?: Channel[],
  unArchiveBoardList?: Channel[],
): Action => {
  const event = convertActionEvent(action)
  let selectedWork: Project | undefined = undefined
  let selectedBoard: Channel[] | undefined = undefined
  let copyOption: 'CONTENTS' | 'FORM' | undefined = undefined
  if (event === 'COPY') {
    if (action.isDeep) {
      copyOption = 'CONTENTS'
    } else {
      copyOption = 'FORM'
    }
  }
  if (action.workId) {
    selectedWork = projectList?.filter((project) => project.id === String(action.workId))[0]
  }
  if (action.boardIdList && archiveBoardList && unArchiveBoardList) {
    selectedBoard = [...archiveBoardList, ...unArchiveBoardList].filter((board) =>
      action.boardIdList?.includes(Number(board.id)),
    )
  }
  return {
    event,
    selectedWork,
    selectedBoard,
    copyOption,
  }
}

type ConvertRuleType = {
  automation: AutomationResponse
  cardList?: Card[]
  memberList?: ChannelMember[]
  archiveBoardList?: Channel[]
  unArchiveBoardList?: Channel[]
  projectList?: Project[]
  onExtraData?: () => void
  t: TFunction
}

export const convertAutomation = ({
  automation,
  cardList,
  memberList,
  archiveBoardList,
  unArchiveBoardList,
  projectList,
  t,
}: ConvertRuleType): RuleListItemType => {
  return {
    id: automation.id,
    applyBoard: String(automation.applyBoardId),
    triggers: convertTrigger(automation.trigger, t),
    rules: convertRule(automation.rule, projectList, cardList, memberList, archiveBoardList, unArchiveBoardList),
    action: convertAction(automation.action, projectList, archiveBoardList, unArchiveBoardList),
    cardList,
    memberList,
    archiveBoardList,
    unArchiveBoardList,
    isActive: automation.isEnabled,
  }
}

const revertTriggerType = (type: TriggerType): RTTriggerType => {
  switch (type) {
    case 'NEWCARD':
      return 'WHEN_CARD_CREATED'
    case 'TIME':
      return 'WHEN_TIME_REACHED'
    default:
      return 'WHEN_CARD_CREATED'
  }
}

const revertWeekly = (weekOfDay: string) => {
  switch (weekOfDay) {
    case 'MON':
      return 1
    case 'TUE':
      return 2
    case 'WED':
      return 3
    case 'THU':
      return 4
    case 'FRI':
      return 5
    case 'SAT':
      return 6
    case 'SUN':
      return 7
  }
}

const revertTrigger = (trigger?: Trigger) => {
  let type: RTTriggerType = 'WHEN_CARD_CREATED'
  let scheduleType: RTScheduleType | undefined = undefined
  let dayOfTheWeek: number | undefined = undefined
  let month: number | undefined = undefined
  let day: number | undefined = undefined
  let hourAndMinute: string | undefined = undefined
  let timezone: string | undefined = undefined
  if (trigger) {
    const { type: $time, isTime } = trigger
    if ($time) {
      type = revertTriggerType($time)
    }
    if (isTime) {
      timezone = isTime.timezone ? isTime.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
      if (isTime.weekly) {
        scheduleType = 'EVERY_WEEK'
        dayOfTheWeek = revertWeekly(isTime.weekly[0])
        hourAndMinute = isTime.time
      } else if (isTime.monthly) {
        scheduleType = 'EVERY_MONTH'
        day = Number(isTime.monthly)
        hourAndMinute = isTime.time
      } else if (isTime.yearly) {
        scheduleType = 'EVERY_YEAR'
        month = Number(isTime.yearly.month)
        day = Number(isTime.yearly.day)
        hourAndMinute = isTime.time
      }
    }
  }
  return {
    type,
    scheduleType,
    dayOfTheWeek,
    month,
    day,
    hourAndMinute,
    timezone,
  }
}

const revertRuleEvent = (rule: Rule): RTRuleType => {
  if (rule.event === 'CARD') {
    return 'TO_SPECIFIC_CARD'
  } else if (rule.event === 'SPECIFIC_USER') {
    return 'TO_SPECIFIC_USER'
  } else if (rule.event === 'SPECIFIC_BOARD' || rule.event === 'ANOTHER_WORK_BOARD') {
    return 'TO_SPECIFIC_BOARD'
  }
  return 'TO_SPECIFIC_CARD'
}

const revertRule = (rule?: Rule): RuleRequest => {
  let type: RTRuleType = 'TO_SPECIFIC_CARD'
  let cardIdList: number[] | undefined = undefined
  let userIdList: number[] | undefined = undefined
  let workId: number | undefined = undefined
  let boardIdList: number[] | undefined = undefined
  if (rule) {
    type = revertRuleEvent(rule)
    if (rule.selectedCard) {
      cardIdList = [Number(rule.selectedCard.cardId)]
    }
    if (rule.selectedUsers) {
      userIdList = rule.selectedUsers.map((user) => Number(user.userId))
    }
    if (rule.selectedWork) {
      workId = Number(rule.selectedWork.id)
    }
    if (rule.selectedBoard) {
      boardIdList = rule.selectedBoard.map((board) => Number(board.id))
    }
  }
  return {
    type,
    cardIdList,
    userIdList,
    workId,
    boardIdList,
  }
}

const revertActionEvent = (action: Action): RTActionType => {
  if (action.event === 'MAIL') {
    return 'NOTIFICATION_VIA_EMAIL'
  } else if (action.event === 'INCHARGE') {
    return 'CHANGE_ASSIGNEE'
  } else if (action.event === 'COPY') {
    if (action.selectedWork && action.selectedBoard) {
      return 'COPY_CARD_TO_SPECIFIC_BOARD'
    } else {
      return 'COPY_CARD'
    }
  } else if (action.event === 'COOP') {
    return 'SHARE_CARD'
  }
  return 'NOTIFICATION_VIA_EMAIL'
}

const revertAction = (action?: Action): ActionRequest => {
  let type: RTActionType = 'NOTIFICATION_VIA_EMAIL'
  let workId: number | undefined = undefined
  let boardIdList: number[] | undefined = undefined
  let isDeep: boolean | undefined = undefined
  if (action) {
    type = revertActionEvent(action)
    if (action.selectedWork) {
      workId = Number(action.selectedWork.id)
    }
    if (action.selectedBoard) {
      boardIdList = action.selectedBoard.map((board) => Number(board.id))
    }
    if (action.copyOption === 'CONTENTS') {
      isDeep = true
    } else {
      isDeep = false
    }
  }
  return {
    type,
    workId,
    boardIdList,
    isDeep,
  }
}

type RevertRuleType = {
  rule: RuleListItemType
  workId: number
  isEnabled?: boolean
}

export const revertAutomation = ({ rule, workId, isEnabled = false }: RevertRuleType): AutomationRequest => {
  return {
    id: rule.id,
    workId,
    applyBoardId: Number(rule.applyBoard),
    trigger: revertTrigger(rule.triggers),
    rule: revertRule(rule.rules),
    action: revertAction(rule.action),
    isEnabled,
  }
}
