import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CardContentsView } from '@rui/screen-card'

import { useWork } from '@rocket-mono/providers'
import { useAstro, usePreview, useWorkCard, useWorkChannel, useWorkProject } from '@rocket-mono/providers'
import { WorkCardEditorProvider } from './provider'

interface Props {
  offset?: number
  readonly?: boolean
  content?: string
  onChangeValue?: (content: string) => void
}
const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const WorkCardEditor: React.FC<Props> = ({ offset, readonly = false, content, onChangeValue }) => {
  const { t } = useTranslation()

  const { astro, option } = useAstro()
  const { currentProject, projectMemberList } = useWorkProject()
  const { currentCard } = useWorkCard()
  const { currentChannel } = useWorkChannel()
  const { openPreview } = usePreview()

  const [parseContent, setParseContent] = useState<string>()

  const currentChannelName = useMemo(() => {
    if (currentChannel) {
      return currentChannel.roomName
    }
  }, [currentChannel])

  const currentCardName = useMemo(() => {
    if (currentCard) {
      return currentCard.title
    }
  }, [currentCard])

  const currentCardRegDate = useMemo(() => {
    if (currentCard) {
      return t('format.date.LLL', { date: new Date(currentCard.regDate) })
    }
  }, [currentCard])

  const cardCreatedName = useMemo(() => {
    if (currentCard && currentCard.user && currentCard.user.no) {
      const member = projectMemberList?.find((o) => String(o.userId) === String(currentCard.user?.no))
      return member !== undefined ? member.name : currentCard.user.userName
    }
    return currentCard?.user?.userName ?? ''
  }, [currentCard, projectMemberList])

  const cardCreatedProfile = useMemo(() => {
    if (currentCard && currentCard.user && currentCard.user.userEmail) {
      return `${option.secureCdnUrl}/profile/${currentCard.user.userEmail}`
    }
  }, [currentCard, option])

  const contentParsing = useCallback(async (content: string) => {
    const div: HTMLDivElement = document.createElement('div')
    div.innerHTML = content

    if (div) {
      const imgTags = div.getElementsByTagName('img')
      for await (const imgTag of imgTags) {
        const url = new URL(imgTag.src)
        const path = url.pathname.split('/').slice(-3).join('/')
        const presignedUrl = await astro.readFilePresignedUrl(path)
        imgTag.src = presignedUrl
      }

      const aTags = div.getElementsByTagName('a')
      for (const aTag of aTags) {
        aTag.target = '_blank'
      }

      const tableTags = div.getElementsByTagName('table')
      for (const tableTag of tableTags) {
        const wrapTag = document.createElement('div')
        wrapTag.className = 'tableWrapper'
        wrapTag.innerHTML = tableTag.outerHTML
        tableTag.outerHTML = wrapTag.outerHTML
      }
      setParseContent(div.innerHTML)
    }
  }, [])

  useEffect(() => {
    if (content !== undefined && parseContent === undefined) {
      contentParsing(content)
    }
  }, [content, parseContent])

  useEffect(() => {
    if (!readonly) return
    if (!currentProject) return
    if (!currentChannelName) return
    if (!currentCardName) return
    if (!currentCardRegDate) return
    if (!cardCreatedName) return
    if (!cardCreatedProfile) return
    const eventHandler = (e: Event) => {
      const target = e.target as HTMLElement
      if (target.nodeName === 'IMG') {
        const imgElemList = document.querySelectorAll('div.toastui-editor-contents img')
        const imgList: {
          id: string
          title: string
          uri: string
          uploadDate: string
          uploaderName: string
          uploaderProfile: string
          mimeType: string
          workType: string
          workName: string
          boardName?: string
          cardName?: string
        }[] = []
        imgElemList.forEach((img) => {
          const elemImage = img as HTMLImageElement
          const url = new URL(elemImage.src)
          imgList.push({
            id: uuid(),
            title: url.pathname.split('/').pop() || '',
            uri: elemImage.src,
            uploadDate: currentCardRegDate,
            uploaderName: cardCreatedName,
            uploaderProfile: cardCreatedProfile,
            mimeType: 'image',
            workType: currentProject.type.code,
            workName: currentProject.title,
            boardName: currentChannelName,
            cardName: currentCardName,
          })
        })
        const img = target as HTMLImageElement
        const url = new URL(img.src)
        const [fileKey, filename] = url.pathname.split('/').slice(2)
        const index = imgList.findIndex((item) => item.uri === img.src)
        openPreview('contents', fileKey, filename, imgList, currentProject.type.code, currentProject.title, index)
      }
    }
    const elem = document.querySelector('div.toastui-editor-contents')
    elem?.addEventListener('click', eventHandler)
    return () => {
      elem?.removeEventListener('click', eventHandler)
    }
  }, [parseContent, readonly, currentProject, currentChannelName, currentCardName, currentCardRegDate])

  console.log('parseContent', { readonly, currentProject, currentChannel, parseContent })

  return (
    <>
      <WorkCardEditorProvider>
        {parseContent !== undefined && (
          <CardContentsView
            editable={!readonly}
            title={t('board.card.desc')}
            defaultValue={parseContent}
            onChangeValue={onChangeValue}
            isAnonymous={false}
            textareaStyle={{}}
          />
        )}
      </WorkCardEditorProvider>
    </>
  )
}

export { WorkCardEditor }
