import React from 'react'

import { RNSvg } from '@rocket/atoms'
import { IColors, getRealColor } from '@rocket-mono/libs'

const { Svg, Path } = RNSvg

interface Props {
  iconSize: number
  getIconColor: IColors
}

export const IconNavBoard: React.FC<Props> = ({ iconSize, getIconColor }) => {
  return (
    <Svg width={iconSize} height={iconSize} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.46 4.75263C2.46 3.61899 3.12899 3 4.26263 3H6.31526C7.4489 3 8.16666 3.61899 8.16666 4.75263V13.9895C8.16666 15.1231 7.4489 15.7067 6.31526 15.7067H4.26263C3.12899 15.7067 2.46 15.1231 2.46 13.9895V4.75263ZM6.31526 4.75263L4.26263 4.75263V13.9895H6.31526V4.75263Z"
        fill={getRealColor(getIconColor)}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9167 4.75263C15.9167 3.61899 16.5857 3 17.7193 3H19.7719C20.9056 3 21.6233 3.61899 21.6233 4.75263V11.9895C21.6233 13.1231 20.9056 13.7067 19.7719 13.7067H17.7193C16.5857 13.7067 15.9167 13.1231 15.9167 11.9895V4.75263ZM19.7719 4.75263L17.7193 4.75263V11.9895H19.7719V4.75263Z"
        fill={getRealColor(getIconColor)}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16666 4.95932C9.16666 3.82568 9.90675 3.00671 11.0404 3.00671H13.093C14.2267 3.00671 14.8733 3.82568 14.8733 4.95932V18.3014C14.8733 19.4351 14.2267 20.1567 13.093 20.1567H11.0404C9.90675 20.1567 9.16666 19.4351 9.16666 18.3014V4.95932ZM13.093 4.95932H11.0404V18.3014H13.093V4.95932Z"
        fill={getRealColor(getIconColor)}
      />
    </Svg>
  )
}

export const IconNavBoardFill: React.FC<Props> = ({ iconSize, getIconColor }) => {
  return (
    <Svg width={iconSize} height={iconSize} viewBox="0 0 20 20" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.0498 3.96052C2.0498 3.01583 2.6073 2.5 3.552 2.5H5.26252C6.20722 2.5 6.80536 3.01583 6.80536 3.96052V11.6579C6.80536 12.6026 6.20722 13.0889 5.26252 13.0889H3.552C2.6073 13.0889 2.0498 12.6026 2.0498 11.6579V3.96052Z"
        fill={getRealColor(getIconColor)}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2637 3.96052C13.2637 3.01583 13.8212 2.5 14.7659 2.5H16.4764C17.4211 2.5 18.0192 3.01583 18.0192 3.96052V9.99122C18.0192 10.9359 17.4211 11.4222 16.4764 11.4222H14.7659C13.8212 11.4222 13.2637 10.9359 13.2637 9.99122V3.96052Z"
        fill={getRealColor(getIconColor)}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63867 4.13254C7.63867 3.18785 8.25541 2.50537 9.20011 2.50537H10.9106C11.8553 2.50537 12.3942 3.18785 12.3942 4.13254V15.251C12.3942 16.1957 11.8553 16.797 10.9106 16.797H9.20011C8.25541 16.797 7.63867 16.1957 7.63867 15.251V4.13254Z"
        fill={getRealColor(getIconColor)}
      />
    </Svg>
  )
}
