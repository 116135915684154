import * as React from 'react'

import { User } from '@rocket/types'
import { TriggerType } from '@rui/rich-text-field'
import {
  CardEntryType,
  WorkCardTodo,
  WorkCardTodoAstroType,
  WorkCardTodoParam,
  WorkCardTodoProvider,
} from '../providers'
import { useCardTodoEdit } from './hooks'
import Provider from './provider'
import type { AstroType, File as CardTodoEditFile } from './types'
import View from './view'

interface Props {
  astro: AstroType & WorkCardTodoAstroType
  currentUser: User
  param: WorkCardTodoParam
  onParam: (state: WorkCardTodoParam) => void
  relatedDomain?: string
  relatedDomainId?: string
  channelId: string
  projectId?: number
  boardRoomId?: string
  fileLoad?: globalThis.File
  onFileLoad?: () => void
  window?: boolean
  saveCacheTodo?: (list?: WorkCardTodo[]) => Promise<void>
  readCacheTodo?: () => Promise<WorkCardTodo[]>
  clearCacheTodo?: () => Promise<void>
  onPressNewTodo?: () => void
  onPressOldTodo?: (item: WorkCardTodo) => void
  triggerList?: TriggerType[]
  cardEntries?: CardEntryType[]
  onChangeEditIndex?: (index?: number) => void
  inputRef?: React.RefObject<any>
  editRef?: React.RefObject<any>
  children?: JSX.Element | JSX.Element[]
}

export const CardTodoEdit: React.FC<Props> = ({
  astro,
  currentUser,
  param,
  onParam,
  relatedDomain,
  relatedDomainId,
  channelId,
  projectId,
  boardRoomId,
  fileLoad,
  onFileLoad,
  window,
  saveCacheTodo,
  readCacheTodo,
  clearCacheTodo,
  onPressNewTodo,
  onPressOldTodo,
  triggerList,
  cardEntries,
  onChangeEditIndex,
  inputRef,
  editRef,
  children,
}) => {
  return (
    <WorkCardTodoProvider
      astro={astro}
      projectId={projectId}
      param={param}
      onParam={onParam}
      relatedDomain={relatedDomain}
      relatedDomainId={relatedDomainId}
      window={window}
      saveCacheTodo={saveCacheTodo}
      readCacheTodo={readCacheTodo}
      clearCacheTodo={clearCacheTodo}
      cardEntryList={cardEntries}
    >
      <Provider
        astro={astro}
        currentUser={currentUser}
        channelId={channelId}
        relatedDomain={relatedDomain}
        relatedDomainId={relatedDomainId}
        projectId={projectId}
        boardRoomId={boardRoomId}
        fileLoad={fileLoad}
        onFileLoad={onFileLoad}
        onPressNewTodo={onPressNewTodo}
        onChangeEditIndex={onChangeEditIndex}
        triggerList={triggerList}
        inputRef={inputRef}
      >
        <View
          onPressTodo={onPressOldTodo}
          triggerList={triggerList}
          editorRef={editRef}
          onChangeEditIndex={onChangeEditIndex}
        />
        <>{children}</>
      </Provider>
    </WorkCardTodoProvider>
  )
}

export { useCardTodoEdit }
export type { CardTodoEditFile }
