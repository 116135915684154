import React from 'react'
import { View, StyleSheet } from 'react-native'

import { faCheck } from '@fortawesome/free-solid-svg-icons'

import { COLOR } from '@rocket-mono/libs'
import { FAIcon } from '@rocket-atoms/icon'

interface Props {
  checked?: boolean
  disabled?: boolean
  size?: number
  type?: 'DEFAULT' | 'TODO' | 'SCHEDULE' | 'GATHERING'
  variant?: 'DEFAULT' | 'CIRCLE' | 'CHECKMARKS'
}

const Checkbox: React.FC<Props> = ({ checked = false, disabled = false, type, variant }) => {
  if (disabled) {
    return <View style={[styles.base, variant === 'CIRCLE' && styles.circleBase, styles.disabledStyle]}></View>
  }
  if (checked) {
    return (
      <View
        style={[
          styles.base,
          { borderWidth: 0, backgroundColor: COLOR.primary.blue500 },
          type === 'TODO' && { backgroundColor: COLOR.main.turquoise },
          type === 'SCHEDULE' && { backgroundColor: COLOR.main.yellow },
          type === 'GATHERING' && { backgroundColor: COLOR.sub.purple },
          variant === 'CIRCLE' && styles.circleBaseChecked,
        ]}
      >
        <FAIcon iconName={faCheck} size={'xs'} color={'mono.white'} />
      </View>
    )
  }

  return <View style={[styles.base, variant === 'CIRCLE' && styles.circleBase]}></View>
}

export default Checkbox

const styles = StyleSheet.create({
  base: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 18,
    height: 18,
    borderWidth: 1,
    borderColor: COLOR.gray.g300,
    borderRadius: 4,
    backgroundColor: COLOR.mono.white,
  },
  circleBase: {
    borderRadius: 20,
  },
  circleBaseChecked: {
    borderRadius: 20,
    backgroundColor: COLOR.sub.darkNavy,
  },
  disabledStyle: {
    backgroundColor: COLOR.opacity.blk10,
  },
})
