import { IconProp } from '@fortawesome/fontawesome-svg-core'
import * as React from 'react'
import { StyleSheet, TextStyle, View, ViewStyle } from 'react-native'
import AddPopup, { AppList, Axis } from './AddPopup'
import AxisButton from './AxisButton'

interface Props {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  appList?: AppList[]
  onPopupState?: (state: string) => void
  onAppsPress?: () => void
  bottomText?: string
  containerStyle?: ViewStyle
  buttonContainerStyle?: ViewStyle
  popupContainerStyle?: ViewStyle
  children?: React.ReactNode
  onCopy?: () => void
  onMove?: () => void
  onCoop?: () => void
  isWeb?: boolean
  addText?: string
  addTextStyle?: TextStyle
  buttonAddButtonStyle?: ViewStyle
  axisIconName?: IconProp
  iconSize?: number
  addPopupMarginTop?: number
  addPopupMarginRight?: number
  isIconOnly?: boolean
  popAxisPosition?: string
  AxisWide?: boolean
}

function AxisContainer({
  isOpen,
  setIsOpen,
  appList,
  onPopupState,
  onAppsPress,
  bottomText,
  containerStyle,
  popupContainerStyle,
  children,
  isWeb,
  addText,
  addTextStyle,
  buttonAddButtonStyle,
  axisIconName,
  iconSize,
  addPopupMarginTop,
  addPopupMarginRight,
  isIconOnly = false,
  popAxisPosition,
  AxisWide,
}: Props) {
  const [axis, setAxis] = React.useState<Axis>({ x: 0, y: 0 })
  const [buttonStandard, setButtonStandard] = React.useState(0)

  return (
    <View testID="addContainer.view" style={containerStyle}>
      {appList ? (
        <View style={[styles.buttonbox]} onLayout={(e) => setButtonStandard(e.nativeEvent.layout.width - 20)}>
          <AxisButton
            iconName={axisIconName}
            iconSize={iconSize}
            onPress={() => {
              console.log('press', appList)
              setIsOpen(true)
            }}
            onAxis={setAxis}
            style={{ ...styles.addbutton, ...buttonAddButtonStyle }}
            isWeb={isWeb}
            addText={addText}
            addTextStyle={addTextStyle}
            wide={true}
            isIconOnly={isIconOnly}
          />
        </View>
      ) : (
        <></>
      )}
      {children}

      <AddPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        appList={appList}
        onPopupState={onPopupState}
        onAppsPress={onAppsPress}
        bottomText={bottomText}
        containerStyle={popupContainerStyle}
        axis={axis}
        popAxisPosition={popAxisPosition}
        marginTop={addPopupMarginTop}
        marginRight={addPopupMarginRight}
        AxisWide={AxisWide ? buttonStandard : 0}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  buttonbox: {
    flexDirection: 'row',
  },
  addbutton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
  },
})

export default React.memo(AxisContainer)
