import React, { useEffect, useMemo } from 'react'

import { DropdownButton, DropdownItemProps, DropdownList } from '@rocket-atoms/dropdown'
import { COLOR, ClickOutside, FONT } from '@rui/foundations'
import { CardType } from '@rocket/types'
import { useTranslation } from 'react-i18next'
import { DimensionValue, StyleSheet, View } from 'react-native'

interface Props {
  // cardFilterData: DropdownItemProps[]
  defaultIndex: number
  // searchResultList: DropdownItemProps[]
  onChangeCode?: (code: string) => void
}

const ActivitySearchSectionSortCard = ({ defaultIndex, onChangeCode }: Props) => {
  const { t } = useTranslation()

  const listData = useMemo(
    () => [
      {
        beforeCardIcon: 'TOTAL' as CardType,
        label: t('workmember.table.auth.all'),
        code: 'ALL',
      },
      {
        beforeCardIcon: 'MISSION' as CardType,
        label: t('system.atask'),
        code: 'MISSION',
      },
      {
        beforeCardIcon: 'MEETING' as CardType,
        label: t('system.ameeting'),
        code: 'MEETING',
      },
      {
        beforeCardIcon: 'COLLECTION' as CardType,
        label: t('system.asurvey'),
        code: 'COLLECTION',
      },
    ],
    [t],
  )

  const [actived, setActived] = React.useState(false)
  const [buttonStandard, setButtonStandard] = React.useState<{ width: DimensionValue; height: number }>({
    width: 0,
    height: 0,
  })
  const [selected, setSelected] = React.useState<DropdownItemProps[]>([listData[defaultIndex]])

  useEffect(() => {
    if (selected.length > 0) onChangeCode && onChangeCode(selected[0].code)
  }, [selected, onChangeCode])

  // const [isCombobox, setIsCombobox] = useState(false)
  // const [comboSelected, setComboSelected] = React.useState<DropdownItemProps[]>(
  //   defaultIndex !== undefined && typeof defaultIndex === 'number'
  //     ? [cardFilterData[defaultIndex]]
  //     : cardFilterData.filter((_, idx) => defaultIndex?.includes(idx)),
  // )

  return (
    <View style={styles.container}>
      <View style={styles.buttonArea}>
        <DropdownButton
          type={'DEFAULT'}
          configuration={'DEFAULT'}
          size={'md'}
          label={selected.length !== 0 ? selected[0].label : '초기 값'}
          buttonWidth={140}
          activated={actived}
          setActivated={setActived}
          setButtonStandard={setButtonStandard}
        />
        {actived && (
          <ClickOutside onClickOutside={() => setActived(false)}>
            <DropdownList
              list={listData}
              configuration={'DEFAULT-BORDER'}
              itemType={'DEFAULT'}
              itemVariant={'DEFAULT'}
              itemStyle={{ minWidth: 200, width: 200 }}
              selected={selected}
              setSelected={setSelected}
              buttonStandard={buttonStandard}
              setActived={setActived}
            />
          </ClickOutside>
        )}
      </View>
      {/* <View>
        <Button type={'SECONDARY'} beforeIcon={faTag} onPress={() => setIsCombobox(true)} />
        {isCombobox && (
          <ClickOutside onClickOutside={() => setIsCombobox(false)}>
            <DropdownList
              list={cardFilterData}
              configuration={'DEFAULT-BORDER'}
              itemType={'DEFAULT'}
              itemVariant={'DEFAULT'}
              ifRightShow={true}
              selected={comboSelected}
              setSelected={setComboSelected}
              buttonStandard={buttonStandard}
              setActived={setIsCombobox}
              width={150}
            />
          </ClickOutside>
        )}
      </View> */}
    </View>
  )
}

export default ActivitySearchSectionSortCard

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 20,
    marginBottom: 8,
  },
  buttonArea: {
    flex: 1,
  },
  comboHeaderStyle: {
    padding: 8,
  },
  labelText: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g900,
    paddingBottom: 8,
  },
  comboFooterStyle: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 8,
    borderTopWidth: 1,
    borderColor: COLOR.gray.g200,
  },
})
