import React from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'
import { FAIcon } from '@rocket-atoms/icon'
import { faCircle, faHourglassStart, faMoon, faArrowsRotate, faCheck } from '@fortawesome/free-solid-svg-icons'
import { COLOR } from '@rocket-mono/libs'
import { TodoStepType } from '../../../type'

interface Props{
  step: TodoStepType
  active: boolean
  done: boolean
  style?: ViewStyle
}
export const StateCircle = ({step, active, done, style}: Props) => {
  return(
    <View style={[
        styles.circleStyle, 
        active && styles.circleOnStyle,
        style
      ]}>
      {active ? 
        <FAIcon iconName={
          done ? 
          faCheck :
          step === 'waiting' ? 
          faCircle : 
          step === 'scheduled' ? 
          faHourglassStart : 
          step === 'progress' ? 
          faArrowsRotate : 
          step === 'completed' ? 
          faCheck : 
          step === 'waitingPending' ? 
          faMoon : 
          step === 'progressPending' ? 
          faMoon : faHourglassStart
        } size={'xs'} color={'mono.white'} />
        :
        <View style={styles.normalNode}/>
      }
    </View>
  )
}

const styles = StyleSheet.create({
  circleStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    borderRadius: 20,
    backgroundColor: COLOR.gray.g200
  },
  circleOnStyle:{
    backgroundColor: COLOR.main.turquoise
  },
  normalNode: {
    width: 8,
    height: 8,
    borderRadius: 6,
    backgroundColor: COLOR.gray.g050
  },
})