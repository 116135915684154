import React, { useCallback, useEffect, useState } from 'react'

import { Combobox } from '@rui/molecules'
import { COLOR, FONT, removeItemAtIndex } from '@rui/foundations'
import { ScrollView, StyleSheet, View } from 'react-native'
import { DropdownItemProps, DropdownListItem } from '@rocket-atoms/dropdown'
import { SearchTextField, Text } from '@rocket-atoms/text'
import { Button } from '@rocket-atoms/button'
import { useTranslation } from 'react-i18next'

interface Props {
  defaultIndex?: number[]
  resultList: DropdownItemProps[]
  onPressClear?: () => void
  onPressApply?: (codes: string[]) => void
}

export const SearchCombobox = ({ defaultIndex = [], resultList, onPressClear, onPressApply }: Props) => {
  const { t } = useTranslation()
  const [searchKeyword, setSearchKeyword] = useState('')

  const [selected, setSelected] = React.useState<DropdownItemProps[]>(
    resultList.filter((_, idx) => defaultIndex.includes(idx)),
  )

  const handlePressClear = useCallback(() => {
    onPressClear && onPressClear()
    setSelected([])
  }, [onPressClear])

  const handlePressApply = useCallback(() => {
    onPressApply && onPressApply(selected.map(({ code }) => code))
  }, [selected, onPressApply])

  useEffect(() => {
    setSelected(resultList.filter((_, idx) => defaultIndex.includes(idx)))
  }, [defaultIndex])

  return (
    <Combobox visible={true}>
      <Combobox.Header style={styles.comboHeaderStyle}>
        <Text style={styles.labelText}>{t('search.file.filter')}</Text>
        <SearchTextField
          value={searchKeyword}
          placeholder={t('common.search.input')}
          onChangeText={setSearchKeyword}
          autoFocus={true}
        />
      </Combobox.Header>
      <Combobox.Body style={styles.comboBodyStyle}>
        <ScrollView style={{ maxHeight: 260 }}>
          {resultList ? (
            resultList.map((el, idx) => {
              if (searchKeyword.length > 0 && el.label.toLowerCase().indexOf(searchKeyword.toLowerCase()) < 0) {
                return <View key={idx}></View>
              }

              return (
                <View
                  key={idx}
                  style={[
                    idx !== 0 && {
                      borderTopWidth: 1,
                      borderColor: COLOR.gray.g100,
                    },
                  ]}
                >
                  <DropdownListItem
                    item={el}
                    itemType="DEFAULT"
                    selected={selected}
                    onPressItem={(item) => {
                      setSelected((prev) => {
                        const idx = prev.findIndex(({ code }) => code === item.code)
                        return idx < 0 ? [...prev, item] : removeItemAtIndex(prev, idx)
                      })
                    }}
                  />
                </View>
              )
            })
          ) : (
            <View style={styles.noContents}>
              <Text style={{ ...FONT.txtSm, color: COLOR.gray.g500, textAlign: 'center' }}>
                {t('nocontentsicon.search.text')}
              </Text>
            </View>
          )}
        </ScrollView>
      </Combobox.Body>
      <Combobox.Footer>
        <View style={styles.comboFooterStyle}>
          <Button
            type={'SECONDARYWHITE'}
            size={'sm'}
            text={t('filelist.uploadpicker.clear')}
            onPress={handlePressClear}
          />
          <Button size={'sm'} text={t('filelist.uploadpicker.apply')} onPress={handlePressApply} />
        </View>
      </Combobox.Footer>
    </Combobox>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonArea: {
    flex: 1,
  },
  comboHeaderStyle: {
    padding: 8,
    borderBottomWidth: 0,
  },
  labelText: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g900,
    paddingBottom: 8,
  },
  comboBodyStyle: {
    borderTopWidth: 1,
    borderColor: COLOR.gray.g200,
  },
  comboFooterStyle: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 8,
    borderTopWidth: 1,
    borderColor: COLOR.gray.g200,
  },
  noContents: {
    flexDeriction: 'row',
    justifyContent: 'center',
    paddingVertical: 20,
  },
})
