import * as React from 'react'
import { Pressable, StyleSheet } from 'react-native'

import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import { IColors, getRealColor } from '@rocket-mono/libs'
import { FAIcon } from '@rocket/atoms'
import { IconNavBoard } from '../../components/IconNavBoard'

interface Props {
  isBoard?: boolean
  iconName: IconProp
  iconSize?: SizeProp
  backgroundColor?: IColors
  onPress?: () => void
  selected?: boolean
}

export const ItemButton: React.FC<Props> = ({
  isBoard,
  iconName,
  iconSize = '16',
  backgroundColor = 'main.blue',
  onPress,
  selected,
}) => {
  const getBackgroundColor = React.useMemo(() => {
    if (typeof selected === 'boolean') {
      return selected ? getRealColor('primary.blue500') : getRealColor('mono.white')
    }
    return getRealColor(backgroundColor)
  }, [selected, backgroundColor])

  return (
    <Pressable
      onPress={onPress}
      style={[
        styles.buttonStyle,
        backgroundColor && {
          backgroundColor: getBackgroundColor,
        },
      ]}
    >
      {isBoard ? (
        <IconNavBoard iconSize={Number(iconSize)} getIconColor={selected ? 'mono.white' : 'gray.g500'} />
      ) : (
        <FAIcon iconName={iconName} size={'sm'} color={selected ? 'mono.white' : 'gray.g500'} />
      )}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  buttonStyle: {
    borderRadius: 8,
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
