import { Tag } from '@rui/atoms'
import * as React from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { ListMember } from './types'

interface Props {
  item: ListMember
  onPress?: () => void
  onRemove?: () => void
}

const TagUser: React.FC<Props> = ({ item, onPress, onRemove }) => {
  return (
    <View style={styles.container}>
      <Tag
        text={item.name}
        icon={<Image source={{ uri: item.uri }} style={styles.image} />}
        size="md"
        onPress={onPress && onPress}
        onPressClose={onRemove && onRemove}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginRight: 4,
    marginVertical: 2,
    height: 28,
  },
  image: {
    width: 16,
    height: 16,
    borderRadius: 16,
  },
})

export default TagUser
