import { Button } from '@rocket-atoms/button'
import { COLOR, getBoxShadow, removeItemAtIndex } from '@rocket-mono/libs'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, StyleSheet, View } from 'react-native'
import PopListSectionTabs from '../Components/PopListSectionTabs'
import SelectList from '../Components/SelectList'
import { Props, SelectorBoardType } from './types'

const SelectorBoard = ({ board, selectedItemList, onPressEdit, width }: Props) => {
  const { t } = useTranslation()
  const [allChecked, setAllChecked] = React.useState({
    archive: false,
    unArchive: false,
  })
  const [selected, setSelected] = React.useState<string[]>(selectedItemList)

  const dimensions = Dimensions.get('window')
  const popoverRef = React.useRef<any>(null)

  useEffect(() => {
    setSelected(selectedItemList)
  }, [selectedItemList])

  const valueChange = useMemo(
    () =>
      selectedItemList.filter((o) => !selected.includes(o)).length > 0 ||
      selected.filter((o) => !selectedItemList.includes(o)).length > 0,
    [selectedItemList, selected],
  )

  const handleAllCheckedUnArchive = React.useCallback(
    (
      inputBoard: {
        id: number
        title: string
        code: string
        isArchive: boolean
      }[],
    ) => {
      setAllChecked((prev) => ({ ...prev, unArchive: !allChecked.unArchive }))

      const setInputSection = inputBoard.map((el) => el.code)
      const maintainList = inputBoard[0].isArchive ? unArchiveBoardList : archiveBoardList

      const maintainCode = maintainList.map((el) => el.code).filter((el) => selected.includes(el))

      if (allChecked.unArchive) {
        setSelected([...maintainCode])
      } else {
        setSelected([...maintainCode, ...setInputSection])
      }
    },
    [allChecked, selected],
  )

  const handleAllCheckedArchive = React.useCallback(
    (
      inputBoard: {
        id: number
        title: string
        code: string
        isArchive: boolean
      }[],
    ) => {
      setAllChecked((prev) => ({ ...prev, archive: !allChecked.archive }))

      const setInputSection = inputBoard.map((el) => el.code)
      const maintainList = inputBoard[0].isArchive ? unArchiveBoardList : archiveBoardList

      const maintainCode = maintainList.map((el) => el.code).filter((el) => selected.includes(el))

      if (allChecked.archive) {
        setSelected([...maintainCode])
      } else {
        setSelected([...maintainCode, ...setInputSection])
      }
    },
    [allChecked, selected],
  )

  const unArchiveBoardList = useMemo(() => {
    return board.filter(({ isArchive }) => !isArchive)
  }, [board])

  const archiveBoardList = useMemo(() => {
    return board.filter(({ isArchive }) => isArchive)
  }, [board])

  const handlePressItem = useCallback(
    (code: string) => {
      setSelected((prev) => {
        const idx = prev.findIndex((o) => o === code)
        return idx < 0 ? [...prev, code] : removeItemAtIndex(prev, idx)
      })
    },
    [selected],
  )

  const [archiveSelected, setArchiveSelected] = React.useState(0)
  const [unArchiveSelected, setUnArchiveSelected] = React.useState(0)

  useEffect(() => {
    setArchiveSelected(archiveBoardList.filter((el) => selected.includes(el.code)).length)

    setUnArchiveSelected(unArchiveBoardList.filter((el) => selected.includes(el.code)).length)

    archiveSelected === archiveBoardList.length
      ? setAllChecked((prev) => ({ ...prev, archive: true }))
      : setAllChecked((prev) => ({ ...prev, archive: false }))

    unArchiveSelected === unArchiveBoardList.length
      ? setAllChecked((prev) => ({ ...prev, unArchive: true }))
      : setAllChecked((prev) => ({ ...prev, unArchive: false }))

    console.log(unArchiveSelected, unArchiveBoardList.length)
  }, [archiveSelected, unArchiveSelected, selected])

  return (
    <View ref={popoverRef} style={styles.popover}>
      <View style={{ overflow: 'scroll', maxHeight: dimensions.height / 3 }}>
        <PopListSectionTabs
          label={t('workmember.table.board.process')}
          itemCount={board.filter(({ isArchive }) => !isArchive).length}
          countTextColor={'main.red'}
          allChecked={allChecked.unArchive}
          onPressAllCheck={() => handleAllCheckedUnArchive(unArchiveBoardList)}
        >
          <SelectList
            initialSelectedIndexes={unArchiveBoardList
              .map(({ code }, index) => ({ code, index }))
              .filter(({ code }) => selected.includes(code))
              .map(({ index }) => index)}
            // initialDisabledIndexes={[1]} //비활성
            // disabledReason={t('rule.boards.triggerrepeat')} //비활성이유
            onPressItem={(index) => handlePressItem(unArchiveBoardList[index].code)}
            isCheckbox={true}
          >
            {unArchiveBoardList.map(({ code, title, disabled }) => (
              <SelectList.Item
                key={code}
                selected={selected.includes(code)}
                disabled={disabled} //disabled 객체에 임의의 값
                text={title}
              />
            ))}
          </SelectList>
        </PopListSectionTabs>

        <PopListSectionTabs
          label={t('workmember.table.board.archive')}
          itemCount={board.filter(({ isArchive }) => isArchive).length}
          countTextColor={'gray.g450'}
          allChecked={archiveBoardList && archiveBoardList.length > 0 ? allChecked.archive : false}
          onPressAllCheck={() => handleAllCheckedArchive(archiveBoardList)}
          divStyle
        >
          <SelectList
            initialSelectedIndexes={archiveBoardList
              .map(({ code }, index) => ({ code, index }))
              .filter(({ code }) => selected.includes(code))
              .map(({ index }) => index)}
            // initialDisabledIndexes={[1]} //비활성
            // disabledReason={t('rule.boards.triggerrepeat')} //비활성이유
            selectedBackground={'opacity.blk05'}
            isCheckbox={true}
            onPressItem={(index) => handlePressItem(archiveBoardList[index].code)}
          >
            {archiveBoardList.map(({ code, title, disabled }) => (
              <SelectList.Item key={code} selected={selected.includes(code)} disabled={disabled} text={title} />
            ))}
          </SelectList>
        </PopListSectionTabs>
      </View>
      <View style={{ padding: 8, backgroundColor: COLOR.gray.g100 }}>
        <Button
          wide={true}
          type={valueChange ? 'PRIMARY' : 'SECONDARY'}
          variant={valueChange ? 'LINE' : undefined}
          disabled={valueChange ? false : true}
          onPress={() => {
            onPressEdit && onPressEdit(selected)
          }}
          text={t('workmember.table.board.button')}
        />
      </View>
    </View>
  )
}

export default SelectorBoard
export type { SelectorBoardType }

export const styles = StyleSheet.create({
  cellBox: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  popover: {
    width: '100%',
    position: 'absolute',
    backgroundColor: COLOR.mono.white,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    paddingVertical: 4,
    ...getBoxShadow(0, 0, 0, 0.05, 7, 0, 0, 0),
    zIndex: 100,
  },
})
