import { DropdownButton } from '@rocket-atoms/dropdown'
import { SectionAccordionType, SelectorBoard, SelectorWork } from '@rocket-molecules/popselector'
import { COLOR, ClickOutside, FONT } from '@rocket-mono/libs'
import { ProjectType } from '@rocket-mono/providers'
import { BoardLabel, WorkIcon } from '@rocket/atoms'
import { Channel } from '@rocket/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { useRule } from '../../RuleScreen/hooks'
import { commonStyles } from '../style'

const zIndex = 100

interface Props {
  workList?: ProjectType[]
  selectedWork: string
  unArchiveBoardList?: Channel[]
  archiveBoardList?: Channel[]
  selectedBoardList: string[]
  onSelectedWork: (item: SectionAccordionType) => void
  onSelectedBoardList: (list: string[]) => void
}

const FlowRuleWorkView: React.FC<Props> = ({
  workList,
  selectedWork,
  unArchiveBoardList,
  archiveBoardList,
  selectedBoardList,
  onSelectedWork,
  onSelectedBoardList,
}) => {
  const { t } = useTranslation()
  const { getIcon, getLabel, elementList } = useRule()

  const [workActivated, setWorkActivated] = React.useState(false)
  const [boardActivated, setBoardActivated] = React.useState(false)
  const [labelWrapHover, setLabelWrapHover] = React.useState(false)
  const [labelWrapBoardHover, setLabelWrapBoardHover] = React.useState(false)

  const isWFP = React.useMemo(() => {
    if (!workList || !selectedWork) return false
    const work = workList.find((item) => item.id === selectedWork)
    return work?.type.code === 'WFP'
  }, [workList, selectedWork])

  const previewWorkList = React.useMemo(() => {
    if (!workList) return []
    return [
      {
        sectionList: workList.map((item) => {
          return {
            beforeWorkIcon: getIcon(item.type.code),
            label: item.title,
            code: item.id,
          }
        }),
      },
    ]
  }, [workList])

  const workSelected = React.useMemo(() => {
    const selectedWorkItem: SectionAccordionType[] = []
    if (selectedWork) {
      previewWorkList.map((work) =>
        work.sectionList.map((section: SectionAccordionType) => {
          if (section.code === selectedWork) {
            selectedWorkItem.push(section)
          }
        }),
      )
    }
    return selectedWorkItem
  }, [previewWorkList, selectedWork])

  const boardList = React.useMemo(() => {
    if (!unArchiveBoardList || !archiveBoardList) return []
    return [
      ...unArchiveBoardList.map((board) => {
        const element = elementList.find((e) => e.id === board.projectElementId)
        return {
          id: Number(board.id),
          title: board.roomName,
          code: board.id,
          isArchive: false,
          beforeBoardLabel: getLabel({ isWFP, element }),
        }
      }),
      ...archiveBoardList.map((board) => {
        const element = elementList.find((e) => e.id === board.projectElementId)
        return {
          id: Number(board.id),
          title: board.roomName,
          code: board.id,
          isArchive: true,
          beforeBoardLabel: getLabel({ isWFP, element }),
        }
      }),
    ]
  }, [unArchiveBoardList, archiveBoardList, isWFP, elementList])

  const selectedPreviewBoardList = React.useMemo(() => {
    return boardList.filter((b) => selectedBoardList.includes(b.code))
  }, [boardList, selectedBoardList])
  return (
    <View style={{ flexDirection: 'row' }}>
      <View style={styles.container}>
        {unArchiveBoardList && archiveBoardList ? (
          <Pressable 
            onPress={()=> setWorkActivated(true)}
            onHoverIn={()=>setLabelWrapHover(true)}
            onHoverOut={()=>setLabelWrapHover(false)}
            style={[commonStyles.labelWrap, labelWrapHover && commonStyles.labelWrapHover]}
          >
            <View style={styles.labelArea}>
              {workSelected.length !== 0 ? (
                <>
                  <WorkIcon type={getIcon(workSelected[0].beforeWorkIcon || 'DSP')} size={16} />
                  <Text style={{fontSize:12, marginLeft: 6 }}>{workSelected[0] ? workSelected[0].label : 'work'}</Text>
                </>
              ) : (
                <Text style={commonStyles.valueText}>{t('dropdown.initcode')}</Text>
              )}
            </View>
          </Pressable>
        ) : (
          <Text style={commonStyles.valueText}>{t('rule.boards.otherwork')}</Text>
        )}
        {workActivated && (
          <ClickOutside onClickOutside={() => setWorkActivated(false)}>
            <View style={{ position: 'absolute', zIndex }}>
              <SelectorWork
                list={previewWorkList}
                defaultIndex={selectedWork}
                checkedType={'RADIOBOX'}
                width={300}
                bodyHeight={200}
                onPressSave={(item) => {
                  if (item) {
                    onSelectedWork(item)
                    setWorkActivated(false)
                  }
                }}
                onPressCancel={() => {
                  setWorkActivated(false)
                }}
              />
            </View>
          </ClickOutside>
        )}
      </View>
      <View style={styles.container}>
        {unArchiveBoardList && archiveBoardList ? (
          <View style={styles.dropButtonContainer}>
            <Pressable 
              onPress={()=> setBoardActivated(true)}
              onHoverIn={()=>setLabelWrapBoardHover(true)}
              onHoverOut={()=>setLabelWrapBoardHover(false)}
              style={[commonStyles.labelWrap, labelWrapBoardHover && commonStyles.labelWrapHover, {marginLeft: 4}]}
            >
              <View style={styles.labelArea}>
                {selectedPreviewBoardList.length !== 0 ? (
                  <>
                    <BoardLabel
                      color={
                        selectedPreviewBoardList[0].beforeBoardLabel
                          ? selectedPreviewBoardList[0].beforeBoardLabel
                          : 'sub.pink'
                      }
                      size={16}
                    />
                    <Text style={styles.labelText}>{selectedPreviewBoardList[0].title}</Text>
                    {selectedPreviewBoardList.length - 1 > 0 && (
                      <Text style={commonStyles.valueText}>
                        {t('rule.boards.others', { count: selectedPreviewBoardList.length - 1 })}
                      </Text>
                    )}
                  </>
                ) : (
                  <Text style={commonStyles.valueText}>{t('rule.boards.select')}</Text>
                )}
              </View>
            </Pressable>
            <Text style={styles.dropdownButtonSideText}>{t('rule.rules.toboard')}</Text>
          </View>
        ) : (
          <Text style={commonStyles.valueText}>{t('rule.boards.otherboard')}</Text>
        )}
        {boardActivated && (
          <ClickOutside onClickOutside={() => setBoardActivated(false)}>
            <View style={{ position: 'absolute', zIndex }}>
              <SelectorBoard
                board={boardList}
                selectedItemList={selectedBoardList}
                width={300}
                onPressEdit={(selectedItemList) => {
                  onSelectedBoardList(selectedItemList)
                  setBoardActivated(false)
                }}
                onClose={() => setBoardActivated(false)}
              />
            </View>
          </ClickOutside>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  dropButtonContainer: {
    flexDirection: 'row',
  },
  labelArea: {
    overflow: 'hidden',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelText: {
    position: 'relative',
    ...FONT.txtXsMedium,
    color: COLOR.gray.g900,
    marginLeft: 4,
    zIndex: 2,
  },
  dropdownButtonSideText: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g800,
    marginLeft: 4,
  },
})

export default FlowRuleWorkView
