import { Icon } from '@rocket-atoms/icon'
import { COLOR, LayoutPayload } from '@rocket-mono/libs'
import { RichTextField, RichTextFieldProvider, TriggerType } from '@rui/rich-text-field'
import React from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { TodoItemOptionButton } from '../TodoItemOptionButton'
// import TodoInput from '../TodoInput'

interface InputTodoV2Type {
  inputRef?: React.RefObject<any>
  contents: string
  users?: number
  date?: string
  isFileUpload: boolean
  onChangeTodo: (arg0: string) => void
  onPressUser: (payload: LayoutPayload) => void
  onPressDate: (payload: LayoutPayload) => void
  onPressFile: (payload: LayoutPayload) => void
  onPressSend: () => void
  onPressInputEnterKey?: (text: string) => void
  isAssigneeCheck: boolean
  isDateCheck: boolean
  isFileCheck: boolean
  triggerList?: TriggerType[]
}

const InputTodoV2 = ({
  inputRef,
  contents,
  users,
  date,
  isFileUpload,
  onChangeTodo,
  onPressDate,
  onPressFile,
  onPressSend,
  onPressInputEnterKey,
  onPressUser,
  isAssigneeCheck,
  isDateCheck,
  isFileCheck,
  triggerList,
}: InputTodoV2Type) => {
  const viewRef = React.useRef<View>(null)
  // const [isFocused, setIsFocused] = React.useState(false)

  return (
    <View
      style={[
        styles.container,
        // isFocused && styles.containerFocused
      ]}
      ref={viewRef}
    >
      <RichTextFieldProvider ref={inputRef}>
        <RichTextField
          value={contents}
          placeholder="placeholder"
          onChangeValue={onChangeTodo}
          onPressEnter={onPressInputEnterKey}
          triggerList={triggerList ?? []}
        />
      </RichTextFieldProvider>
      {/* {children ? (
        children
      ) : (
        <TodoInput
          inputRef={inputRef}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          value={contents}
          // placeholder="입력하세요."
          onChangeText={(text) => onChangeTodo(text)}
          onPressInputEnterKey={onPressInputEnterKey}
        />
      )} */}

      <View style={styles.optionArea}>
        <View style={styles.flexBox}>
          <>
            {isAssigneeCheck && users ? (
              <TodoItemOptionButton
                icon={'user-o'}
                iconColor={'gray.g700'}
                text={`@${users}`}
                onPress={() => {
                  if (viewRef && viewRef.current) {
                    viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                      onPressUser({ x, y, width, height, pageX, pageY })
                    })
                  }
                }}
                isValued
              />
            ) : (
              <TodoItemOptionButton
                icon={'user-o'}
                iconSize={20}
                iconColor={'gray.g300'}
                onPress={() => {
                  if (viewRef && viewRef.current) {
                    viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                      onPressUser({ x, y, width, height, pageX, pageY })
                    })
                  }
                }}
                stateOff
              />
            )}
          </>

          <>
            {isDateCheck && date ? (
              <TodoItemOptionButton
                icon={'time-o'}
                iconColor={'gray.g700'}
                text={date}
                onPress={() => {
                  if (viewRef && viewRef.current) {
                    viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                      onPressDate({ x, y, width, height, pageX, pageY })
                    })
                  }
                }}
                isValued
              />
            ) : (
              <TodoItemOptionButton
                icon={'time-o'}
                iconSize={20}
                iconColor={'gray.g300'}
                onPress={() => {
                  if (viewRef && viewRef.current) {
                    viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                      onPressDate({ x, y, width, height, pageX, pageY })
                    })
                  }
                }}
                stateOff
              />
            )}
          </>

          <>
            {isFileCheck && isFileUpload ? (
              <TodoItemOptionButton
                icon={'cloud-upload-o'}
                iconColor={'gray.g700'}
                onPress={() => {
                  if (viewRef && viewRef.current) {
                    viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                      onPressFile({ x, y, width, height, pageX, pageY })
                    })
                  }
                }}
                isValued
              />
            ) : (
              <TodoItemOptionButton
                icon={'cloud-upload-o'}
                iconSize={20}
                iconColor={'gray.g300'}
                onPress={() => {
                  if (viewRef && viewRef.current) {
                    viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                      onPressFile({ x, y, width, height, pageX, pageY })
                    })
                  }
                }}
                stateOff
              />
            )}
          </>
        </View>
        <Pressable
          style={styles.sendbutton}
          onPress={() => {
            onPressSend()
            // if (inputRef) {
            //   inputRef.current?.clear()
            //   inputRef.current?.focus()
            // }
          }}
        >
          <Icon name="plus" size={14} color="mono.white" />
        </Pressable>
      </View>
    </View>
  )
}

export default InputTodoV2

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: COLOR.gray.g150,
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
  containerFocused: {
    borderColor: COLOR.gray.g300,
  },
  optionArea: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 8,
  },
  // textarea: {
  //   marginTop: 0,
  //   padding: 2,
  //   ...Platform.select({
  //     web: {
  //       outlineWidth: 0,
  //     },
  //   }),
  // },
  sendbutton: {
    backgroundColor: COLOR.main.blue,
    height: 28,
    width: 28,
    borderRadius: 5.6,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
