import * as React from 'react'

import { Icon } from '@rocket-atoms/icon'
import { Text } from '@rocket-atoms/text'
import { COLOR, getBoxShadow } from '@rocket-mono/libs'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, View } from 'react-native'
import Context from './context'
import type { ProviderProps, StateType } from './types'

const Provider = ({ children }: ProviderProps) => {
  const { t } = useTranslation()
  const [selectedItems, setSelectedItems] = React.useState<string[]>([])
  const [visible, setVisible] = React.useState<boolean>(false)
  const [state, setState] = React.useState<StateType>('idle')

  const onClose = React.useCallback(() => {
    setVisible(false)
  }, [])
  const open = React.useCallback(() => {
    setVisible(true)
  }, [])
  const onChange = React.useCallback((list: string[]) => {
    setSelectedItems(list)
  }, [])
  const onState = React.useCallback((state: StateType) => {
    setState(state)
  }, [])

  return (
    <Context.Provider value={{ open, onClose, onChange, state, onState }}>
      <View style={styles.container}>{children}</View>
      {visible ? (
        <View style={styles.modalContainer}>
          <View style={styles.headerContainer}>
            <Pressable onPress={onClose} style={styles.closeButton}>
              <Icon name="close" style={styles.closeIcon} />
            </Pressable>
            <Text fontName="txtMd" fontColor="gray.g900">
              {t('workcardoptionheader.selecteditem', {
                count: selectedItems.length,
              })}
            </Text>
          </View>
          <View style={styles.headerBorder} />
          <View style={styles.bodyContainer}>
            {/* <View style={styles.description}>
              <Text fontName="txtSm" fontColor="gray.g700">
                {t('workcardoptionmodal.description')}
              </Text>
            </View> */}
            <View style={styles.buttonGroup}>
              {/* <Pressable style={styles.button} onPress={() => onState('move')}>
                <Icon name="share" size={16} color="gray.g450" />
                <View style={styles.buttonInnerWidth} />
                <Text>{t('workcardoptionmodal.move')}</Text>
              </Pressable> */}
              {/* <View style={styles.buttonWidth} /> */}
              <Pressable style={styles.button} onPress={() => onState('copy')}>
                <Icon name="library-books-o" size={16} color="gray.g450" />
                <View style={styles.buttonInnerWidth} />
                <Text>{t('workcardoptionheader.copy')}</Text>
              </Pressable>
              <View style={styles.buttonWidth} />
              <Pressable style={styles.button} onPress={() => onState('delete')}>
                <Icon name="trash-o" size={16} color="gray.g450" />
                <View style={styles.buttonInnerWidth} />
                <Text>{t('workcardoptionheader.delete')}</Text>
              </Pressable>
            </View>
            {/* <View style={styles.buttonGroupHeight} />
            <View style={styles.buttonGroup}>
              <Pressable
                style={styles.button}
                onPress={() => onState('newCard')}
              >
                <Icon name="plus" size={16} color="gray.g450" />
                <View style={styles.buttonInnerWidth} />
                <Text>{t('workcardoptionmodal.newcard')}</Text>
              </Pressable>
            </View> */}
          </View>
        </View>
      ) : (
        <></>
      )}
    </Context.Provider>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  modalContainer: {
    backgroundColor: COLOR.mono.white,
    height: 225,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    ...getBoxShadow(0, 0, 0, 0.05, 10, 0, -3, 3),
  },
  headerContainer: {
    paddingVertical: 16,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 55,
    width: 55,
  },
  closeIcon: {
    top: 16,
    left: 16,
  },
  headerBorder: {
    borderTopWidth: 1,
    borderTopColor: COLOR.gray.g150,
  },
  bodyContainer: {
    paddingTop: 16,
    paddingHorizontal: 18.65,
  },
  description: {
    marginBottom: 20,
  },
  buttonGroup: {
    flexDirection: 'row',
  },
  button: {
    flexDirection: 'row',
    height: 36,
    flex: 1,
    borderWidth: 1,
    borderColor: COLOR.gray.g300,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonWidth: {
    width: 8,
  },
  buttonInnerWidth: {
    width: 4,
  },
  buttonGroupHeight: {
    height: 9,
  },
})

export default Provider
