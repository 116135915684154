import { IconName, removeItemAtIndex, useForm } from '@rocket-mono/libs'
import {
  AssigneeIdType,
  CardFormType,
  CardGatheringFormType,
  LocationType,
  WorkCardTodoParam,
  useCurrentUser,
  useWork,
  useWorkAssignee,
  useWorkCard,
  useWorkChannel,
  useWorkFile,
  useWorkProject,
} from '@rocket-mono/providers'
import { CardShareType, Project } from '@rocket/types'
import { useToast, Text } from '@rui/atoms'
import { ClickOutside } from '@rui/foundations'
import { Combobox, useModal } from '@rui/molecules'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { CardRowGroup } from '../../components/CardRowGroup'
import Dropdown from '../../components/Dropdown'
import FileUploadView from '../../components/FileUploadView'
import { WorkCardEditor } from '../../components/WorkCardEditor'
import { WorkCardLayout } from '../../components/WorkCardLayout'
import { WorkCardEditBottomOptionType } from '../../components/WorkCardOptionList'
import { WorkCardCollectionScreen } from '../CardCollectionScreen'
import { WorkCardScheduleScreen } from '../CardScheduleScreen'
import { WorkCardTodoScreen } from '../CardTodoScreen'
import { CardFormValidationProps, FileType, Props } from './types'

const CardEditView: React.FC<Props> = ({
  currentProjectId,
  cardType: defaultCardType,
  onUnauthorized,
  onPressClose,
  onSuccess,
  onFail: onFailed,
  onPressWindow,
  ...layoutProps
}) => {
  const discussionView = <></>
  const { t } = useTranslation()

  const [init, setInit] = useState(false)
  const [visible, setVisible] = useState(false)

  const { show: showToastMessage } = useToast()
  const { showModalDialog, hideModal } = useModal()

  const { currentUser } = useCurrentUser()

  const { projectList, archiveList } = useWork()
  const currentProject = useMemo(
    () => [...(projectList ?? []), ...(archiveList ?? [])].find(({ id }) => id === currentProjectId),
    [currentProjectId],
  )
  const { currentProjectMember } = useWorkProject()
  const { currentChannel, currentChannelMember, currentChannelMembers } = useWorkChannel()
  const {
    cardId,
    currentCard,
    deleteCard,
    unshareCard,
    saveTodo,
    saveSchedule,
    saveGathering,
    deleteGathering,
    saveGatheringCard,
    cacheCard,
    readCacheCard,
    clearCacheCard,
    clearCacheTodo,
  } = useWorkCard()

  const { assigneeList, createAssignee, deleteAssignee } = useWorkAssignee()

  const { fileList: workFileList, createFile, deleteFile } = useWorkFile()

  const [location, setLocation] = useState<LocationType>()
  const [gatherings, setGatherings] = useState<CardGatheringFormType[]>([])
  const [todoParam, setTodoParam] = useState<WorkCardTodoParam>({ state: 'IDLE' })
  const [assigneeIds, setAssigneeIds] = useState<AssigneeIdType[]>([])

  const [fileList, setFileList] = useState<FileType[]>([])

  const files = useMemo(() => {
    return fileList.filter((o) => o.no === undefined)
  }, [fileList])

  const [imageList, setImageList] = useState<FileType[]>([])

  // const contentFiles = useMemo(() => {
  //   return imageList.filter((o) => o.no === undefined)
  // }, [imageList])

  const onTodoParam = (state: WorkCardTodoParam) => {
    setTodoParam(state)
  }

  const onTodoSave = (relatedDomainId: string) => {
    setTodoParam({
      state: 'SAVE',
      relatedDomain: 'card',
      relatedDomainId: relatedDomainId,
    })
  }

  const SHARE_LIST: { key: CardShareType; label: string }[] = [
    { key: 'BOARD_MEMBER', label: t(`card.share.onlytoboardmembers`) },
    {
      key: 'WORK_MEMBER',
      label: t(`card.share.toallmembersinworklist`),
    },
    { key: 'EVERYONE', label: t(`card.share.toanyone`) },
  ]

  const { values, setValues, handleChange, handleSubmit, isChangeValue } = useForm<
    CardFormType,
    CardFormValidationProps
  >({
    initialValues:
      currentCard === null
        ? {
            cardShareType: 'WORK_MEMBER',
            title: '',
            content: '',
            cardDate: {},
            deletedFileIds: [],
            deletedAssigneeIds: [],
            isPublic: true,
          }
        : {
            cardShareType: currentCard.cardShare?.type ?? 'WORK_MEMBER',
            title: currentCard.title,
            content: currentCard.content ?? '',
            cardDate: {
              startDate: currentCard.fromPeriodDate ? new Date(currentCard.fromPeriodDate) : undefined,
              endDate: currentCard.toPeriodDate ? new Date(currentCard.toPeriodDate) : undefined,
              isStartTime: currentCard.isFromPeriodDateTime !== null ? currentCard.isFromPeriodDateTime : false,
              isEndTime: currentCard.isToPeriodDateTime !== null ? currentCard.isToPeriodDateTime : false,
            },
            deletedFileIds: [],
            deletedAssigneeIds: [],
            isPublic: currentCard.isPublic,
          },
    onSubmit: (values: CardFormType) => {
      const { cardShareType, title, isPublic } = values
      const roomId = currentChannel?.roomId || ''

      // const fileFormData = new FormData()
      // for (const file of contentFiles) if (file.blob) fileFormData.append('contentFiles', file.blob)
      // for (const file of files) {
      // if (file.blob) fileFormData.append('files', file.blob)
      // }

      if (title.length === 0) {
        showToastMessage({ title: t('card.title.placeholder'), type: 'Danger', position: 'TOP_CENTER' })
        return Promise.reject('')
      }

      // let deletedFileIds = values.deletedFileIds

      // if (!isFile && fileList.length > 0) {
      //   deletedFileIds = fileList.filter(({ no }) => no !== undefined).map(({ no }) => no) as number[]
      // }

      let content = values.content
      if (!isContent) {
        content = ''
      }

      let cardDate = values.cardDate
      if (!isTime) {
        cardDate = {}
      }

      return new Promise<string>((resolve, reject) => {
        if (cardType === 'MISSION') {
          const payload = {
            roomId,
            title,
            isPublic,
            content: isContent ? (content === '<p><br></p>' ? '' : content) : '',
            files: [],
            deletedFileIds: [],
            fileFormData: undefined,
            cardShareType: isShare ? cardShareType : undefined,
            fromPeriodDate: cardDate.startDate,
            toPeriodDate: cardDate.endDate,
            isFromPeriodDateTime: cardDate.isStartTime,
            isToPeriodDateTime: cardDate.isEndTime,
          }

          console.log('payload', payload)

          if (currentCard) {
            const update = (isGenerateSystemMessage: boolean) => {
              hideModal()
              saveTodo({ ...payload, id: String(currentCard.no), isGenerateSystemMessage }).then((cardId) => {
                onTodoSave(cardId)
                resolve(cardId)
              })
            }
            showModalDialog({
              children: (
                <View style={{ minHeight: 40, maxWidth: 320 }}>
                  <Text fontName="H7Medium">{t('card.update.title')}</Text>
                  <Text>{t(`card.update.message`)}</Text>
                </View>
              ),
              dialogButtons: [
                {
                  text: t(`card.update.yes`),
                  noWrapper: true,
                  onPress: () => update(true),
                },
              ],
              dialogCancelButton: {
                text: t(`card.update.no`),
                noWrapper: true,
                onPress: () => update(false),
              },
              onPressClose: hideModal,
            })
          } else {
            saveTodo({ ...payload, id: '' }).then((cardId) => {
              onTodoSave(cardId)
              resolve(cardId)
            })
          }
        } else if (['MEETING', 'ATTEND'].includes(cardType)) {
          const payload = {
            roomId,
            title,
            isPublic,
            content: content === '<p><br></p>' ? '' : content,
            deletedFileIds: [],
            fileFormData: undefined,
            cardShareType: isShare ? cardShareType : undefined,
            fromPeriodDate: cardDate.startDate,
            toPeriodDate: cardDate.endDate,
            isFromPeriodDateTime: cardDate.isStartTime,
            isToPeriodDateTime: cardDate.isEndTime,
            ...location,
          }
          if (currentCard) {
            const update = (isGenerateSystemMessage: boolean) => {
              hideModal()
              saveSchedule({ ...payload, id: String(currentCard.no), isGenerateSystemMessage }).then(resolve)
            }

            showModalDialog({
              children: (
                <View style={{ minHeight: 40, maxWidth: 320 }}>
                  <Text fontName="H7Medium">{t('card.update.title')}</Text>
                  <Text>{t(`card.update.message`)}</Text>
                </View>
              ),
              dialogButtons: [
                {
                  text: t(`card.update.yes`),
                  noWrapper: true,
                  onPress: () => update(true),
                },
              ],
              dialogCancelButton: {
                text: t(`card.update.no`),
                noWrapper: true,
                onPress: () => update(false),
              },
              onPressClose: hideModal,
            })
          } else {
            saveSchedule({ ...payload, id: '' }).then(resolve)
          }
        } else if (cardType === 'COLLECTION') {
          console.log('saveGatherging', gatherings)
          const payload = {
            id: currentCard ? String(currentCard.no) : undefined,
            title,
            roomId,
            isPublic,
            content,
            cardShareType,
            deletedFileIds: [],
            fileFormData: undefined,
            fromPeriodDate: cardDate.startDate,
            toPeriodDate: cardDate.endDate,
            isFromPeriodDateTime: cardDate.isStartTime,
            isToPeriodDateTime: cardDate.isEndTime,
          }

          if (currentCard) {
            const update = (isGenerateSystemMessage: boolean) => {
              hideModal()
              saveGatheringCard({ ...payload, id: String(currentCard.no), isGenerateSystemMessage })
                .then((card) => {
                  if (currentCard?.gatherings) {
                    const deletedGathering = currentCard.gatherings.filter(
                      ({ id }) => !gatherings.map(({ id }) => id).includes(id),
                    )
                    return Promise.all(deletedGathering.map(({ id }) => deleteGathering(id))).then(() => card)
                  } else {
                    return card
                  }
                })
                .then((card) =>
                  Promise.all(
                    gatherings.map((o, sequenceNumber) => {
                      return saveGathering(card.id, { ...o, sequenceNumber }, o.assigneeIds)
                    }),
                  ).then(() => resolve(card.id)),
                )
            }

            showModalDialog({
              children: (
                <View style={{ minHeight: 40, maxWidth: 320 }}>
                  <Text fontName="H7Medium">{t('card.update.title')}</Text>
                  <Text>{t(`card.update.message`)}</Text>
                </View>
              ),
              dialogButtons: [
                {
                  text: t(`card.update.yes`),
                  noWrapper: true,
                  onPress: () => update(true),
                },
              ],
              dialogCancelButton: {
                text: t(`card.update.no`),
                noWrapper: true,
                onPress: () => update(false),
              },
              onPressClose: hideModal,
            })
          } else {
            saveGatheringCard(payload).then((card) =>
              Promise.all(
                gatherings.map((o, sequenceNumber) => saveGathering(card.id, { ...o, sequenceNumber }, o.assigneeIds)),
              ).then(() => resolve(card.id)),
            )
          }
        } else {
          reject('')
        }
      })
    },
    validate: () => {
      const errors: CardFormValidationProps = {}
      return errors
    },
  })

  const isEditAuth = useMemo(() => {
    if (currentCard === null && currentChannelMember?.joined === true) return true
    if (currentCard && currentCard.user && currentCard.user.no === currentUser.id) return true
    if (currentChannel?.roomType === 'D') return false
    if (currentProject?.isArchive) return false

    if (currentChannelMember === undefined && currentProjectMember === undefined) return false
    if (currentCard && currentCard.isPublic) return true

    return currentChannelMember?.isOwner || ['OWNER', 'MANAGER'].includes(currentProjectMember?.auth || '')
  }, [currentUser, currentCard, currentChannel, currentProject, currentChannelMember, currentProjectMember])

  const handlePressDelete = useCallback(() => {
    if (currentCard !== null && currentChannel) {
      console.log('handlePressDelete', currentCard, currentChannel)
      const roomIdList = currentCard.roomIds.split(',')
      if (roomIdList.length > 0) {
        let children, dialogButtons
        if (roomIdList.length === 1) {
          children = (
            <View style={{ minHeight: 40 }}>
              <Text fontName="H7Medium">{t('system.delete')}</Text>
            </View>
          )
          dialogButtons = [
            {
              text: t('todolist.delete'),
              noWrapper: true,
              onPress: () => {
                hideModal()
                deleteCard(String(currentCard.no)).then(() => {
                  setVisible(false)
                  onPressClose && onPressClose()
                })
              },
            },
          ]
        } else {
          children = (
            <View style={{ minHeight: 40 }}>
              <Text fontName="H7Medium">{t('card.dialog.shareddelete.title')}</Text>
            </View>
          )
          dialogButtons = [
            {
              text: t('card.dialog.shareddelete.all'),
              noWrapper: true,
              onPress: () => {
                hideModal()
                deleteCard(String(currentCard.no)).then(() => {
                  setVisible(false)
                  onPressClose && onPressClose()
                })
              },
            },
            {
              name: t('card.dialog.shareddelete.here'),
              noWrapper: true,
              onPress: () => {
                hideModal()
                unshareCard(String(currentCard.no), String(currentChannel.id)).then(() => {
                  setVisible(false)
                  onPressClose && onPressClose()
                })
              },
            },
          ]
        }

        setVisible(false)

        showModalDialog({
          children,
          dialogButtons,
          onPressClose: hideModal,
        })
      }
    }
  }, [currentCard, currentChannel])

  const handlePressEtc = useMemo(() => {
    if (currentProject?.isArchive) return undefined
    if (currentChannelMember === undefined && currentProjectMember === undefined) return undefined
    return cardId !== undefined ? () => setVisible(true) : undefined
  }, [])

  const handlePressClose = useCallback(() => {
    if (isChangeValue) {
      showModalDialog({
        children: (
          <View style={{ minHeight: 40, maxWidth: 320 }}>
            <Text fontName="H7Medium">{t(`screen.workmodelupdate.dialog.title`)}</Text>
          </View>
        ),
        dialogButtons: [
          {
            text: t(`screen.workmodelupdate.dialog.exit`),
            noWrapper: true,
            onPress: () => {
              hideModal()
              onPressClose?.()
              clearCacheCard()
            },
          },
          {
            text: t(`screen.workmodelupdate.dialog.save`),
            noWrapper: true,
            onPress: () => {
              hideModal()
              handleSubmit()
            },
          },
        ],
        dialogCancelButton: {
          text: t('screen.workmodelupdate.dialog.cancel'),
          noWrapper: true,
          onPress: () => {
            hideModal()
          },
        },
        onPressClose: hideModal,
      })
    } else {
      onPressClose && onPressClose()
      clearCacheCard()
    }
  }, [isChangeValue, showModalDialog, hideModal, onPressClose, clearCacheCard, handleSubmit])

  const handlePressWindow = useCallback(() => {
    cacheCard({ values, location, gatherings, todoParam, assigneeIds, fileList, imageList })
    if (defaultCardType === 'MISSION') {
      onTodoParam({ state: 'ARCHIVE' })
    } else {
      onPressWindow && onPressWindow()
    }
  }, [values, location, gatherings, todoParam, assigneeIds, fileList, imageList])

  useEffect(() => {
    if (layoutProps.window)
      readCacheCard().then((data) => {
        console.log('cacheCard', data)
        if (data) {
          const { values, location, gatherings, todoParam, assigneeIds, fileList, imageList } = data
          setValues(values)

          const cardDate = {
            startDate: values.cardDate.startDate ? new Date(values.cardDate.startDate) : undefined,
            endDate: values.cardDate.endDate ? new Date(values.cardDate.endDate) : undefined,
          }
          handleChange('cardDate', cardDate)
          if (location) setLocation(location)
          if (gatherings) setGatherings(gatherings)
          setTodoParam(todoParam)
          setAssigneeIds(assigneeIds)
          // setFileList(fileList)
          // setImageList(imageList)

          if (values.content) setIsContent(true)
          if (fileList.length > 0) setIsFile(true)
        }
        setInit(true)
        clearCacheCard()
      })
    else {
      setInit(true)
      clearCacheCard()
    }
  }, [readCacheCard, clearCacheCard])

  useEffect(() => {
    if (!isEditAuth) {
      showToastMessage({ type: 'Danger', title: t('workboardmemberview.toast.nopermission'), position: 'TOP_CENTER' })
      onUnauthorized && onUnauthorized()
    }
  }, [currentCard, isEditAuth])

  const [cardType, setCardType] = useState(defaultCardType)

  const [isShare] = useState(true)
  const [isTime, setIsTime] = useState(false)
  const [isContent, setIsContent] = useState(false)
  const [isFile, setIsFile] = useState(false)

  useEffect(() => {
    if (currentCard && currentCard.content) setIsContent(true)

    if (currentCard && (currentCard.fromPeriodDate || currentCard.toPeriodDate)) {
      setIsTime(true)
    }

    // if (currentCard && currentCard.files && currentCard.files.length > 0) {
    //   setIsFile(true)
    //   setFileList(
    //     currentCard.files.map((o) => ({
    //       no: o.no,
    //       file: o,
    //       uri: `${option.appApiLegacyUrl}/downloads/${o.fileKey}/${o.filename}?time=${new Date()}`,
    //       name: o.originalFilename,
    //       type: o.mimeType,
    //       size: o.fileSize,
    //     })),
    //   )
    // }

    // if (currentCard && currentCard.contentFiles && currentCard.contentFiles.length > 0) {
    //   setImageList(
    //     currentCard.contentFiles.map((o) => ({
    //       no: o.no,
    //       uri: `${option.appApiLegacyUrl}/downloads/${o.fileKey}/${o.filename}?time=${new Date()}`,
    //       name: o.originalFilename,
    //       type: o.mimeType,
    //       size: o.fileSize,
    //     })),
    //   )
    // }
  }, [currentCard])
  useEffect(() => {
    if (workFileList.length > 0) {
      setIsFile(true)
      setFileList(
        workFileList.map((o) => ({
          no: o.id,
          uri: `${o.presignedUrl}`,
          name: o.originalName || '',
          userName: o.user?.userName || '',
          type: o.mimeType || '',
          size: Number(o.byteSize),
        })),
      )
    }
  }, [workFileList])
  useEffect(() => {
    if (todoParam.state === 'COMPLETE') {
      onSuccess && onSuccess(todoParam.relatedDomainId || '')
    } else if (todoParam.state === 'ARCHIVE_COMPLETE') {
      onPressWindow?.()
    } else if (todoParam.state === 'READ_COMPLETE') {
      clearCacheTodo?.()
    }
  }, [todoParam, onPressWindow])

  const cardOptionList = useMemo(() => {
    const list: WorkCardEditBottomOptionType[] = []

    list.push({
      name: 'paper-o' as IconName,
      tooltip: t('card.todo.desc'),
      checked: isContent,
      onPress: () => setIsContent((is) => !is),
    })

    if (['MISSION', 'COLLECTION'].includes(cardType)) {
      list.push({
        name: 'clock-o' as IconName,
        tooltip: t('card.todo.period'),
        checked: isTime,
        onPress: () => setIsTime((is) => !is),
      })
    }

    list.push({
      name: 'attachment' as IconName,
      tooltip: t('card.todo.file'),
      checked: isFile,
      onPress: () => setIsFile((is) => !is),
    })

    return list
  }, [cardType, isContent, isTime, isFile])

  const previewList = useMemo(() => {
    return imageList.map((o, index) => ({
      id: String(index),
      key: index,
      uri: o.uri,
      onPressRemoveButton: () => {
        if (o.no !== undefined) {
          const deletedFileIds = [...values.deletedFileIds, o.no]
          handleChange('deletedFileIds', deletedFileIds)
        }
        setImageList((prev) => removeItemAtIndex(prev, index))
      },
    }))
  }, [values, imageList])

  useEffect(() => {
    setIsTime((is) => (['ATTEND', 'MEETING'].includes(cardType) ? true : is))
  }, [cardType])

  useEffect(() => {
    const filteredList = assigneeList.filter(
      (o, index) => assigneeList.map((o) => o.userId).indexOf(o.userId) === index,
    )
    setAssigneeIds(
      filteredList.map(({ id, userId, userEmail }) => ({
        id,
        userId: userId ? String(userId) : '',
        userEmail: userEmail ?? '',
      })),
    )
  }, [assigneeList])

  const handleSuccess = useCallback(
    (cardId: string) => {
      currentChannelMembers
        .filter(({ userId: id }) =>
          assigneeIds
            .filter(({ id }) => id === undefined)
            .map(({ userId }) => userId)
            .includes(id),
        )
        .map(({ userId, email: userEmail }) => {
          createAssignee(cardId, { userId: Number(userId), userEmail })
        })

      createFile(files.map(({ blob }) => blob as globalThis.File))
      deleteFile(values.deletedFileIds)

      const deleteIds = assigneeList
        .filter(({ id, userId }) => {
          if (id !== undefined) {
            return !assigneeIds.map(({ id }) => id).includes(id)
          } else {
            return !assigneeIds.map(({ userId }) => String(userId)).includes(String(userId))
          }
        })
        .map(({ id }) => ({ id }))

      console.log('deleteIds', { assigneeList, assigneeIds, deleteIds })

      if (deleteIds.length > 0) {
        deleteAssignee(deleteIds)
      }

      if (['ATTEND', 'MEETING', 'COLLECTION', 'TASKREPORT'].includes(cardType)) {
        onSuccess && onSuccess(cardId)
      }
    },
    [
      onSuccess,
      createAssignee,
      deleteAssignee,
      currentChannelMembers,
      assigneeIds,
      assigneeList,
      createFile,
      deleteFile,
      files,
    ],
  )

  if (!init) return <></>

  if (currentProject === null) return <></>
  return (
    <>
      <WorkCardLayout
        {...layoutProps}
        edit
        currentProject={currentProject as Project}
        isTime={isTime}
        cardType={cardType}
        onChangeCardType={setCardType}
        title={values.title}
        assigneeIds={assigneeIds}
        cardDate={values.cardDate}
        optionList={cardOptionList}
        onChangeTitle={(title) => handleChange('title', title)}
        onChangeDate={(cardDate) => handleChange('cardDate', cardDate)}
        onChangeAssigneeIds={setAssigneeIds}
        onPressPublish={() => handleSubmit({ onSuccess: handleSuccess, onFailed })}
        discussionView={discussionView}
        onPressEtc={handlePressEtc}
        onPressClose={handlePressClose}
        onPressWindow={handlePressWindow}
      >
        {() => (
          <View>
            {isContent && (
              <WorkCardEditor
                // offset={layoutProps.window === true ? 200 : -20}
                content={values.content}
                onChangeValue={(content) => {
                  console.log('handleChange-content', content)
                  handleChange('content', content)
                }}
              />
            )}

            {cardType === 'MISSION' ? (
              <WorkCardTodoScreen.Edit
                opened
                window={layoutProps.window}
                isFold={false}
                currentProject={currentProject as Project}
                discussionView={discussionView}
                todoParam={todoParam}
                onTodoParam={onTodoParam}
              />
            ) : cardType === 'MEETING' || cardType === 'ATTEND' ? (
              <WorkCardScheduleScreen.Edit location={location} setLocation={setLocation} />
            ) : cardType === 'COLLECTION' ? (
              <WorkCardCollectionScreen.Edit
                offset={isContent ? 592 : 0}
                gatherings={gatherings}
                setGatherings={setGatherings}
              />
            ) : (
              <></>
            )}
            {isFile && (
              <FileUploadView
                title={t(`card.todo.file`)}
                fileSource={
                  cardType === 'ATTEND'
                    ? 'MEETING'
                    : cardType === 'COLLECTION'
                    ? 'GATHERING'
                    : cardType === 'TASKREPORT' || cardType === 'TODO'
                    ? undefined
                    : cardType
                }
                fileList={fileList}
                onChangeFilelist={setFileList}
                addDeleteFilelist={(fileId) => {
                  const deletedFileIds = [...values.deletedFileIds, fileId]
                  handleChange('deletedFileIds', deletedFileIds)
                }}
              />
            )}

            {isShare && (
              <CardRowGroup
                title={t('card.share.title')}
                containerStyle={{ marginBottom: 150 }}
                iconName="share-alt-o"
                iconContainerStyle={{
                  alignItems: 'stretch',
                }}
              >
                <Dropdown
                  containerStyle={{ width: '100%' }}
                  label={SHARE_LIST.find((o) => o.key === values.cardShareType)?.label || ''}
                  list={SHARE_LIST}
                  onSelect={(item) => {
                    handleChange('cardShareType', item.key)
                  }}
                />
              </CardRowGroup>
            )}
          </View>
        )}
      </WorkCardLayout>

      {visible && (
        <ClickOutside onClickOutside={() => setVisible(false)}>
          <View style={{ top: layoutProps.window ? 50 : 100, right: 20, position: 'absolute' }}>
            <Combobox visible>
              <Combobox.Body style={{ width: 200 }}>
                <Combobox.Item text={t('todolist.delete')} onPress={handlePressDelete} />
              </Combobox.Body>
            </Combobox>
          </View>
        </ClickOutside>
      )}
    </>
  )
}

export default CardEditView
