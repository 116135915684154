import * as React from 'react'

import { ScrollView, StyleSheet, View } from 'react-native'
import Button, { ButtonProps } from '../Button'

interface Props {
  type?: 'PRIMARY'
  list: ButtonProps[]
  checked: string[]
  setChecked: (checked: string[]) => void
}
const ButtonGroupRadio: React.FC<Props> = ({ type, list, checked, setChecked }) => {
  //외부 useState 예시 : const [checked, setChecked] = React.useState<string[]>([])

  const handlerChecked = (code) => {
    return setChecked([code])
  }

  return (
    <ScrollView style={styles.container} horizontal>
      {list.map((item, idx) => (
        <View style={idx !== 0 && styles.itemWrap} key={`buttonGroupRadio-${idx}`}>
          <Button
            type={checked.indexOf(item.code ? item.code : '') !== -1 ? 'PRIMARY' : 'NOTSELECT'}
            variant={checked.indexOf(item.code ? item.code : '') === -1 ? 'FILL' : undefined}
            text={item.text}
            onPress={() => handlerChecked(item.code ? item.code : '')}
          />
        </View>
      ))}
    </ScrollView>
  )
}

export default ButtonGroupRadio

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  itemWrap: {
    marginLeft: 4,
  },
})
