import React, { useState } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { ClickOutside } from '@rocket-mono/libs'
import { TFunction } from 'i18next'
import { SelectedDate } from '@rocket-molecules/date'
import { useTranslation } from 'react-i18next'
import { LabelField } from '@rocket/atoms'
import { DatePicker } from '@rocket-molecules/date'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { commonStyles } from '../style'

interface Props {
  defaultValue?: string[]
  isAnonymous: boolean
  onPressAnonymous: () => void
}

const TodoDateView = ({ defaultValue, isAnonymous, onPressAnonymous }: Props) => {
  const { t } = useTranslation()
  const [isStartDatePicker, setIsStartDatePicker] = useState(false)
  const [isEndDatePicker, setIsEndDatePicker] = useState(false)
  const [isStartDate, setIsStartDate] = useState<SelectedDate>(null)
  const [isEndDate, setIsEndDate] = useState<SelectedDate>(null)

  const getDatePretty = (t: TFunction, date: SelectedDate) => {
    if (!date) {
      return ''
    }
    return `${t('format.date.L', { date })}`
  }

  return (
    <View style={commonStyles.conContainer}>
      <View style={commonStyles.labelArea}>
        <Text style={commonStyles.labelText}>{t('card.period')}</Text>
      </View>
      <View style={commonStyles.contentsArea}>
        <View style={styles.horibox}>
          <View style={styles.feildArea}>
            <LabelField
              label={'시작일'}
              iconName={faCalendar}
              value={getDatePretty(t, isStartDate)}
              placeholder={'입력하세요.'}
              onPress={isAnonymous ? onPressAnonymous : () => setIsStartDatePicker(true)}
            />
            <View style={[styles.pickerArea, isStartDatePicker && { zIndex: 9 }]}>
              {isStartDatePicker && (
                <ClickOutside onClickOutside={() => setIsStartDatePicker(false)}>
                  <DatePicker type={'date'} setExportPicker={setIsStartDate} />
                </ClickOutside>
              )}
            </View>
          </View>
          <View style={styles.feildArea}>
            <LabelField
              label={'마감일'}
              iconName={faCalendar}
              value={getDatePretty(t, isEndDate)}
              placeholder={'입력하세요.'}
              onPress={isAnonymous ? onPressAnonymous : () => setIsEndDatePicker(true)}
            />
            <View style={[styles.pickerArea, isStartDatePicker && { zIndex: 9 }]}>
              {isEndDatePicker && (
                <ClickOutside onClickOutside={() => setIsEndDatePicker(false)}>
                  <DatePicker type={'date'} setExportPicker={setIsEndDate} />
                </ClickOutside>
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default TodoDateView

const styles = StyleSheet.create({
  selectArea: {
    position: 'absolute',
    top: 40,
    left: 0,
  },
  horibox: {
    flexDirection: 'row',
  },
  feildArea: {
    position: 'relative',
  },
  pickerArea: {
    position: 'absolute',
    top: 72,
    left: 0,
  },
})
