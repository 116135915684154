import React, { ReactNode } from 'react'
import { i18n } from 'i18next'

import { AstroSearchParam, CardType, Project, SearchMethod } from '@rocket/types'
import { SearchCountType, SearchDateType, SearchParam, SearchType, TabType } from '../types'
import { IColors } from '@rui/foundations'
import { User } from '@rocket/types'
import { SearchLayerSectionList } from '../SearchLayerView'
import { SubscribeType } from '@rocket-mono/libs'
import { LayoutChangeEvent } from 'react-native'

export type SearchStateType = 'ready' | 'searching' | 'done' | 'last'
export type SearchPayloadType = { searchType: string; searchKeyword: string; searchProjectId: string[] }

type ProjectTypeCode = 'DSP' | 'WFP' | 'CSP' | 'TOP'
export type FileIconType =
  | 'DOC'
  | 'XLS'
  | 'PPT'
  | 'PDF'
  | 'IMG'
  | 'MP4'
  | 'ZIP'
  | 'ETC'
  | 'UNKNOWN'
  | 'ERROR-DANGER'
  | 'ERROR-CAUTION'
  | 'ALL'

export interface SearchViewProps {
  bottomSheetOffset?: number
  // openProject?: (payload: string) => void
  // openChat?: (payload: string) => void
  // openCard?: (payload: string) => void
  // openCardPreview?: (
  //   type: 'contents' | 'card',
  //   fileList: PreviewFile[],
  //   workType: string,
  //   workName: string,
  //   index: number,
  // ) => void
  // onDownload?: (url: string, saveTo: string) => void
}

export type PreviewFile = {
  id: string
  uri: string
  title: string
  uploadDate: string
  uploaderName: string
  uploaderProfile: string
  mimeType: string
  workType: string
  workName: string
  boardName?: string
  cardName?: string
}

export type SearchCardType = {
  indexName: 'todos' | 'cards'
  data: IndexedCardType | IndexedTodoType
}

export type SearchFileType = {
  indexName: 'chat_files' | 'files'
  data: IndexedChatFileType | IndexedFileV2Type
}

export type IndexedBoardType = {
  id: number
  roomId: string
  roomName: string
  roomType: string
  color: IColors
  isClosed: boolean
  attenderCount: number
  isDiscusstionRoom: boolean

  projectId: number
  projectTitle: string
  projectTypeCode: ProjectTypeCode
  projectIsArchive: boolean
}

export type IndexedBoardMemberType = {
  id: number
  boardId: number
  name: string
  username: string
}

export type IndexedCardType = {
  indexName: string
  id: number
  type: CardType
  title: string
  content?: string
  assigneeCount: number
  isDone: boolean
  fromPeriodDate: Date
  toPeriodDate: Date

  projectId: number
  projectTitle: string
  projectTypeCode: ProjectTypeCode
  projectIsArchive: boolean

  boardId: number
  boardRoomId: string
  boardRoomType: string
  boardRoomName: string
  boardColor: IColors
  boardClosed: boolean
  boardIsDiscussionRoom: boolean
}

export type IndexedChatFileType = {
  id: number
  fileKey: string

  ext: string
  fileSize: string
  filename: string
  originalFilename: string
  fileWidth: number
  fileHeight: number
  mimeType: string

  uploaderId: number
  uploaderName: string
  uploaderEmail: string

  projectId: number
  projectTitle: string
  projectTypeCode: ProjectTypeCode
  projectIsArchive: boolean

  boardId: number
  boardRoomId: string
  boardRoomType: string
  boardRoomName: string
  boardColor: IColors
  boardAttenderCount: number
  boardClosed: boolean
  boardIsDiscussionRoom: boolean

  createdAt: Date
}

export type IndexedChatMessageType = {
  id: string

  type: string
  message: string
  messageSymbol: string
  recipientId: number

  userId: number
  username: string
  projectMemberName: string
  profile: string

  createdAt: Date
  updatedAt: Date

  projectId: number
  projectTypeCode: ProjectTypeCode
  projectTitle: string
  projectIsArchive: boolean

  boardId: number
  boardRoomId: string
  boardRoomType: string
  boardRoomName: string
  boardColor: IColors
  boardAttenderCount: number
  boardClosed: boolean

  boardIsDiscussionRoom: boolean
}

export type IndexedFileV2Type = {
  id: string
  relatedDomain: string
  relatedDomainId: string
  name: string
  originalName: string
  extension: string
  path: string
  mimeType: string
  byteSize: number
  width: number
  height: number
  isDeleted: boolean

  createdAt: Date
  updatedAt: Date

  userId: number
  uploaderName: string
  uploaderEmail: string

  projectId: number
  projectTypeCode: ProjectTypeCode
  projectTitle: string
  projectIsArchive: boolean

  boardId: number
  boardRoomId: string
  boardRoomType: string
  boardRoomName: string
  boardColor: IColors
  boardAttenderCount: number
  boardClosed: boolean

  boardIsDiscussionRoom: boolean
}

export type IndexedProjectType = {
  id: number
  typeCode: ProjectTypeCode
  title: string
  goal: string
  isArchived: boolean
}

type PeriodOption = {
  fromPeriodDate: Date
  isFromTime: boolean
  toPeriodDate: Date
  isToTile: boolean
}

export type IndexedTodoType = {
  id: number
  relatedDomain: string
  relatedDomainId: string

  content: string
  assigneeCount: number
  isDone: boolean
  periodOption: PeriodOption

  projectId: number
  projectTitle: string
  projectTypeCode: ProjectTypeCode
  projectIsArchive: boolean

  boardId: number
  boardRoomId: string
  boardRoomType: string
  boardRoomName: string
  boardColor: IColors
  boardAttenderCount: number
  boardCloase: boolean

  createdAt: Date
}

export interface AstroProps {
  search(params: AstroSearchParam): Promise<void>
  readFilePresignedUrl: (uploadedPath: string) => Promise<string>
}

interface CommonProps {
  defaultProjectId?: string
  cdnUrl: string
  astro: AstroProps
  openProject: (payload: { projectId: string }) => void
  openChat: (payload: { channelRoomId: string; messageId?: string }) => void
  openCard: (payload: {
    isWindow: boolean
    isEdit: boolean
    isFold: boolean
    cardId: string
    cardType: CardType
    channelId: string
    projectId: string
  }) => void
  openPreview: (
    type: 'contents' | 'card',
    fileKey: string,
    filename: string,
    fileList: PreviewFile[],
    workType: string,
    workName: string,
    index: number,
  ) => void
  onDownload: (url: string, saveTo: string) => void
}

export interface ProviderProps extends CommonProps {
  defaultTab?: TabType
  currentUser: User
  projectList?: Project[]
  i18n: i18n
  subscribe?: SubscribeType
  fallback?: ReactNode
  children: ReactNode
}
export interface ContextProps extends CommonProps {
  tab: TabType
  searchDate?: SearchDateType
  searchType: SearchType
  searchAllState: SearchStateType
  searchProjectId: string[]
  searchFieldData: SearchLayerSectionList[]
  searchCount: SearchCountType

  searchWorkState: SearchStateType
  searchWorkList?: IndexedProjectType[]
  searchChatState: SearchStateType
  searchChatList?: IndexedBoardType[]
  searchMessageState: SearchStateType
  searchMessageList?: IndexedChatMessageType[]
  searchCardState: SearchStateType
  searchCardList?: SearchCardType[]
  searchFileState: SearchStateType
  searchFileList?: SearchFileType[]

  projectNameList: string[]
  noContents: boolean
  onChangeTab: (tab: TabType) => void
  onChangeSearchType: (type: SearchType) => void
  onChangeSearchCount: (key: string, count?: number) => void
  onChangeSearchProjectId: (ids: string[]) => void
  onSearch: (search: SearchParam, date?: SearchDateType) => void
  onSearchNext: (method: SearchMethod, page: number) => void
  onSearchClear: () => void
  onChangeDate: (date: SearchDateType) => void
  resetCalendar: () => void
  onChangeVisibleCalendar: (visible: boolean) => void
  onLayoutCalendar: (e: LayoutChangeEvent) => void
}

export const Context = React.createContext<ContextProps | undefined>(undefined)
