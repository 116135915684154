import { COLOR, FONT } from '@rocket-mono/libs'
import React from 'react'
import { Platform, StyleSheet, TextInput } from 'react-native'

interface Props {
  value: string
  placeholder?: string
  setTitle?: (arg0: string) => void
}

const InputTitle: React.FC<Props> = ({ value, placeholder, setTitle }) => {
  return (
    <TextInput
      value={value}
      placeholder={placeholder !== undefined ? placeholder : '제목을 입력하세요.'}
      placeholderTextColor={COLOR.gray.g200}
      onChangeText={setTitle}
      style={styles.input}
    />
  )
}

export default InputTitle

const styles = StyleSheet.create({
  input: {
    ...FONT.H7Bold,
    height: 26,
    ...Platform.select({
      android: {
        paddingVertical: 0,
      },
    }),
  },
})
