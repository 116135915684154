import { COLOR } from '@rocket-mono/libs'
import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import TodoItemEdit from '../TodoItemEdit'
import WorkCardOptionHeader from '../WorkCardOptionHeader'
import { useCardTodoEdit } from './hooks'
import { ViewProps } from './types'

const CardTodoEditView: React.FC<ViewProps> = ({ editorRef, triggerList, onChangeEditIndex }) => {
  const { list, onAllCheck, onDialogCopy, onDialogDelete, onShowModal, handleChangeItemAtIndex } = useCardTodoEdit()

  if (list === undefined) return <></>

  return (
    <View>
      <View style={styles.line} />
      <WorkCardOptionHeader
        selectedItems={list.filter((item) => item.isCheck).map((item) => item.key || '')}
        totalItems={list.length === 0 ? undefined : list.length}
        onCheckAll={onAllCheck}
        onCopy={() => {
          const selectedItems = list.filter((item) => item.isCheck)
          if (selectedItems.length === 0) return
          onDialogCopy()
        }}
        onDelete={() => {
          const selectedItems = list.filter((item) => item.isCheck)
          if (selectedItems.length === 0) return
          onDialogDelete()
        }}
      />
      <View style={styles.br} />
      <TodoItemEdit
        list={list.map((item) => ({
          ...item,
          key: item.key || '',
          assignment: item.assignees && item.assignees.length > 0 ? String(item.assignees.length) : undefined,
          startDate: item.periodOption?.fromPeriodDate,
          endDate: item.periodOption?.toPeriodDate,
        }))}
        onShowModal={onShowModal}
        onChangeItemAtIndex={(from, to) => {
          handleChangeItemAtIndex(from, to)
        }}
        onChangeEditIndex={onChangeEditIndex}
        editorRef={editorRef}
        triggerList={triggerList}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  line: {
    borderTopColor: COLOR.mono.pale,
    borderTopWidth: 1,
    marginVertical: 12,
  },
  br: {
    height: 12,
  },
})

export default CardTodoEditView
