import { Avatar } from '@rui/atoms'
import { DropdownButton } from '@rocket-atoms/dropdown'
import { ListMember, SelectorUser } from '@rocket-molecules/popselector'
import { COLOR, ClickOutside, FONT } from '@rocket-mono/libs'
import { Tag } from '@rocket/atoms'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DimensionValue, StyleSheet, Text, View } from 'react-native'
import { commonStyles } from './style'

const zIndex = 1

interface Props {
  userList: ListMember[]
  onSelected?: (list: ListMember[]) => void
}

const RuleUserSelectView = ({ userList, onSelected }: Props) => {
  const { t } = useTranslation()
  const [activated, setActivated] = React.useState(false)
  const [buttonStandard, setButtonStandard] = React.useState<{ width: DimensionValue; height: number }>({
    width: 0,
    height: 0,
  })

  const selected = React.useMemo(() => {
    return userList.filter((u) => u.selected)
  }, [userList])

  return (
    <View>
      <Text style={commonStyles.labelText}>{t('rule.users.select')}</Text>
      <View style={styles.container}>
        <DropdownButton
          type="SECONDARY"
          configuration="DEFAULT"
          size="md"
          disabled={false}
          activated={activated}
          setActivated={setActivated}
          setButtonStandard={setButtonStandard}
          isArrow={false}
        >
          <View style={styles.boardLabel}>
            {selected.length !== 0 ? (
              <>
                {selected.slice(0, 3).map((item, idx) => (
                  <View key={`member-${idx}`} style={{ marginRight: 2 }}>
                    <Tag name={item.name} size="MEDIUM" onPress={() => setActivated(true)}>
                      <Avatar profileUrl={item.uri} size={'xxsmall'} />
                    </Tag>
                  </View>
                ))}
                {selected.length - 3 > 0 && (
                  <Text style={commonStyles.valueText}>
                    {t('rule.automation.others', { count: selected.length - 3 })}
                  </Text>
                )}
              </>
            ) : (
              <Text style={commonStyles.initText}>{t('rule.automation.incharge')}</Text>
            )}
          </View>
        </DropdownButton>
        {activated && (
          <ClickOutside onClickOutside={() => setActivated(false)}>
            <View style={{ position: 'absolute', top: buttonStandard.height + 4, zIndex }}>
              <SelectorUser
                tagListStyle={{ maxHeight: 55 }}
                listMember={userList}
                visible
                onSave={() => setActivated(false)}
                onClose={() => setActivated(false)}
                onChangeUsers={(changeUsers) => onSelected?.(changeUsers)}
              />
            </View>
          </ClickOutside>
        )}
      </View>
    </View>
  )
}

export default RuleUserSelectView

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  boardLabel: {
    overflow: 'hidden',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 4,
    paddingRight: 6,
    borderRadius: 4,
  },
  valueText: {
    position: 'relative',
    ...FONT.txtSm,
    color: COLOR.gray.g900,
  },
})
