import React, { useEffect, useState } from 'react'
import Provider from './provider'
import CardView from './view'
import { Props } from './types'
import { useWorkCard } from '@rocket-mono/providers'

const CardEditScreen: React.FC<Props> = ({ i18n, ...screenProps }) => {
  const [visible, setVisible] = useState(false)

  const { cardId } = useWorkCard()

  useEffect(() => {
    setTimeout(() => {
      setVisible(true)
    }, 100)

    return () => {
      setVisible(false)
    }
  }, [cardId])

  if (!visible) return null

  return (
    <Provider i18n={i18n}>
      <CardView i18n={i18n} {...screenProps} />
    </Provider>
  )
}

export default CardEditScreen
