import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { COLOR } from '@rui/foundations'
import { FAIcon } from '@rocket/atoms'
import * as React from 'react'
import { Animated, Pressable, StyleSheet, View, ViewStyle } from 'react-native'
import { Svg, Rect, LinearGradient, Defs, Stop } from 'react-native-svg'
import WorkCard, { WorkCardType } from '../WorkCard'

interface Props {
  skeleton?: boolean
  mobileView?: boolean
  setInnerWidth?: (arg0: number) => void
  list: WorkCardType[]
  shortcuts?: boolean
  sliderMode?: boolean
  containerStyle?: ViewStyle
  workItemStyle?: ViewStyle
}
const WorkCardList = ({
  skeleton,
  mobileView,
  setInnerWidth,
  list,
  shortcuts,
  sliderMode,
  containerStyle,
  workItemStyle,
}: Props) => {
  const workItemWidth = 168 //워크 아이템 가로 크기
  const [sliderListWidth, setSliderListWidth] = React.useState(list.length * workItemWidth) //next버튼을 숨기기 위한 배열의 가로 길이
  const [sliderX, setSliderX] = React.useState(0)
  const sliderFix = React.useRef(new Animated.Value(sliderX)).current

  React.useEffect(() => {
    setSliderListWidth(list.length * workItemWidth)
  }, [list])

  React.useEffect(() => {
    Animated.timing(sliderFix, {
      toValue: sliderX,
      duration: 400,
      useNativeDriver: true,
    }).start()
  }, [sliderX])

  return mobileView ? (
    <View>
      <View
        style={[shortcuts && styles.shortcutsContainer, styles.shortcutsMobileContainer]}
        onLayout={(e) => {
          setInnerWidth && setInnerWidth(e.nativeEvent.layout.width)
        }}
      >
        <Animated.View style={{ flexDirection: 'row', transform: [{ translateX: sliderFix }] }}>
          {list.map((item, idx) => (
            <View key={idx} style={[styles.wrap, shortcuts && styles.shortcutsWrap]}>
              <WorkCard index={idx} item={item} key={`workCard-${idx}`} style={workItemStyle} />
            </View>
          ))}
        </Animated.View>
      </View>
      {sliderMode && (
        <>
          {sliderX !== 0 && (
            <Pressable onPress={() => setSliderX(sliderX + workItemWidth)} style={styles.arrowsWrap}>
              <View style={styles.slideArrows}>
                <FAIcon iconName={faAngleLeft} color="gray.g400" />
              </View>
              <View style={styles.grdBack}>
                <Svg width={32} height={78} viewBox="0 0 16 68" fill="none">
                  <Rect
                    x={16}
                    y={68}
                    width={32}
                    height={78}
                    transform="rotate(180 16 68)"
                    fill="url(#paint0_linear_6391_237318)"
                  />
                  <Defs>
                    <LinearGradient
                      id="paint0_linear_6391_237318"
                      x1={32}
                      y1={102}
                      x2={16}
                      y2={102}
                      gradientUnits="userSpaceOnUse"
                    >
                      <Stop stopColor="#19191B" />
                      <Stop offset={1} stopColor="#19191B" stopOpacity={0} />
                    </LinearGradient>
                  </Defs>
                </Svg>
              </View>
            </Pressable>
          )}
          {sliderListWidth - Math.abs(sliderX) <= workItemWidth * 2 ? (
            <></>
          ) : (
            <Pressable
              onPress={() => setSliderX(sliderX - workItemWidth)}
              style={[
                styles.arrowsWrap,
                {
                  right: -16,
                  left: 'auto',
                },
              ]}
            >
              <View style={styles.slideArrows}>
                <FAIcon iconName={faAngleRight} color="gray.g400" />
              </View>
              <View style={styles.grdBack}>
                <Svg width={32} height={78} viewBox="0 0 16 68" fill="none">
                  <Rect width={32} height={78} fill="url(#paint0_linear_6391_237312)" />
                  <Defs>
                    <LinearGradient
                      id="paint0_linear_6391_237312"
                      x1={32}
                      y1={34}
                      x2={8.34463e-7}
                      y2={34}
                      gradientUnits="userSpaceOnUse"
                    >
                      <Stop stopColor="#19191B" />
                      <Stop offset={1} stopColor="#19191B" stopOpacity={0} />
                    </LinearGradient>
                  </Defs>
                </Svg>
              </View>
            </Pressable>
          )}
        </>
      )}
    </View>
  ) : (
    <View style={shortcuts && styles.shortcutsContainer}>
      {list.map((item, idx) => (
        <View key={idx} style={[styles.wrap, shortcuts && styles.shortcutsWrap, containerStyle]}>
          <WorkCard index={idx} item={item} key={`workCard-${item.id}`} style={workItemStyle} />
        </View>
      ))}
    </View>
  )
}

export default WorkCardList

const styles = StyleSheet.create({
  container: {
    width: 'auto',
    flexWrap: 'wrap',
    height: 310,
  },
  wrap: {
    marginBottom: 8,
  },
  shortcutsContainer: {
    flexDirection: 'row',
  },
  shortcutsWrap: {
    marginRight: 8,
    marginBottom: 0,
  },
  arrowsWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: -16,
    width: 32,
    height: '100%',
  },
  shortcutsMobileContainer: {
    overflow: 'hidden',
  },
  slideArrows: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    backgroundColor: COLOR.mono.white,
    borderRadius: 50,
  },
  grdBack: {
    position: 'absolute',
    top: 0,
  },
})
