import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Checkbox } from '@rocket-atoms/checkbox'
import { FAIcon } from '@rocket-atoms/icon'
import { COLOR, FONT, IColors } from '@rocket-mono/libs'
import React from 'react'
import { Platform, StyleProp, Text, TextProps, TextStyle, View } from 'react-native'

export interface Props extends Omit<TextProps, 'style'> {
  iconName?: IconProp
  iconColor?: IColors
  rightIcon?: JSX.Element
  isCheckbox?: boolean
  selected?: boolean
  disabled?: boolean
  selectedBackground?: IColors
  text?: string
  textColor?: IColors
  idleStyle?: StyleProp<TextStyle>
  selectedStyle?: StyleProp<TextStyle>
}

const BaseItem: React.FC<Props> = ({
  iconName,
  iconColor,
  rightIcon,
  isCheckbox,
  selected = false,
  disabled = false,
  selectedBackground,
  text,
  textColor,
  selectedStyle,
  idleStyle,
  numberOfLines = 1,
  ...props
}) => {
  return (
    <View style={{ flexDirection: 'row', minWidth: 120, ...(Platform.OS === 'web' ? { alignItems: 'center' } : {}) }}>
      {isCheckbox && (
        <View style={{ marginRight: 4 }}>
          <Checkbox type={'DEFAULT'} checked={selected} disabled={disabled} />
        </View>
      )}
      {iconName && <FAIcon iconName={iconName} size={'xs'} color={iconColor ?? 'gray.g700'} />}
      <Text
        numberOfLines={numberOfLines}
        style={[
          {
            ...FONT.txtSm,
            flex: 1,
            color: COLOR.gray.g900,
            ...Platform.select({ web: { paddingBottom: 2 } }),
          },
          disabled && { color: COLOR.gray.g450 },
          textColor && { color: textColor },
          idleStyle,
          selectedStyle,
        ]}
        {...props}
      >
        {text}
      </Text>
      {rightIcon}
    </View>
  )
}

export default BaseItem
