import * as React from 'react'
import { View, Image as RNImage, ImageProps as RNImageProps, ImageStyle, ViewStyle } from 'react-native'

type ImageSizeProps = { width: number; height: number }

interface ImageProps extends RNImageProps {
  containerStyle?: ViewStyle
  size: ImageSizeProps
  style?: ImageStyle
  headers?: Record<string, string>
}
/**
 * @deprecated
 */
const Image = ({ containerStyle, style, size, ...imageProps }: ImageProps) => {
  return (
    <View style={containerStyle}>
      <RNImage {...imageProps} style={[style, size]} />
    </View>
  )
}

export default React.memo(Image)
