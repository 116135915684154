import * as React from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'

import { ButtonIcon } from '@rocket-mono/foundations'
import { Avatar } from '@rui/atoms'

import { Icon } from '@rocket-atoms/icon'
import { COLOR } from '@rocket-mono/libs'
import { ParserRule } from '../../Message/types'
import { MENTION_TEMPLATE, mentionReplacer } from '../../utils/mention'
import { parseMessage } from '../../utils/message'

export interface ReplyProps {
  thumbnail?: string
  userName: string
  text?: string
  onPressCloseButton: () => void
}

const Reply = ({ thumbnail, userName, text, onPressCloseButton }: ReplyProps) => {
  const getMessageParse = React.useCallback(
    (bodyText: string) => {
      console.log('getMessageParse', bodyText)
      const replacements: ParserRule[] = [
        {
          format: '<b>(.*?)<\\/b>',
          replacer: (source, timestamp, index) => (
            <Text key={`token-${timestamp}-${index}`}>{source.replace(/<b>(.*?)<\/b>/g, '$1')}</Text>
          ),
        },
        // NOTE: 사용자 멘션 마크업에 대한 처리
        // example: <m chatRoomMemberNo=20905, memberName="김 로켓">
      ]
      if (MENTION_TEMPLATE)
        replacements.push({
          format: MENTION_TEMPLATE,
          replacer: mentionReplacer,
        })
      return parseMessage(bodyText, replacements, {
        fontColor: 'mono.white',
      })
    },
    [MENTION_TEMPLATE, mentionReplacer],
  )

  const parseText = React.useMemo(() => {
    if (!text) return ''
    const jsxElements: JSX.Element[] = getMessageParse(text)
    return jsxElements.length > 0 ? jsxElements : text
  }, [text, getMessageParse])

  return (
    <View style={styles.container}>
      <Avatar profileUrl={thumbnail} userName={userName} size="small" />
      <Text numberOfLines={1} style={styles.text}>
        {parseText}
      </Text>
      <View>
        <ButtonIcon icon={<Icon name="close" color="mono.white" size={16} />} onPress={onPressCloseButton} />
      </View>
    </View>
  )
}

export default Reply

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    paddingLeft: 10,
    paddingRight: 5,
    backgroundColor: COLOR.gray.g900,
  },
  text: {
    flex: 1,
    paddingHorizontal: 10,
    color: COLOR.mono.white,
    ...Platform.select({
      native: {
        paddingTop: 5,
      },
    }),
  },
})
