import * as React from 'react'
import { Dimensions, LayoutChangeEvent, Modal, Platform, Pressable, StyleSheet, Text, View } from 'react-native'
import { COLOR, FONT, getRealColor } from '@rocket-mono/libs'
import { DialogButton } from './button'
import type { ViewProps } from './types'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import { FAIcon } from '@rocket/atoms'

const DialogView: React.FC<ViewProps> = ({
  visible,
  title,
  titleRender,
  message,
  messageRender,
  cancelText,
  onCancel,
  onClose,
  onDismiss,
  list,
  subList,
  height = '80%',
  children,
  modalContainerStyle
}) => {
  const [top, setTop] = React.useState(0)
  const dimensions = Dimensions.get('window')
  const onLayout = React.useCallback(
    (e: LayoutChangeEvent) => {
      const { layout } = e.nativeEvent
      setTop((dimensions.height - layout.height) / 2)
    },
    [dimensions],
  )

  return (
    <Modal animationType="fade" transparent visible={visible}>
      {Platform.OS !== 'web' && (
        <View
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            backgroundColor: COLOR.mono.black,
            opacity: 0.7,
          }}
        />
      )}
      <View
        onLayout={onLayout}
        style={[
          styles.container,
          { top, alignSelf: 'center' },
          {
            ...Platform.select({ web: { height } }),
          },
        ]}
      >
        <View style={[{ overflow: 'hidden', paddingTop: 5 }, modalContainerStyle]}>
          <View style={[styles.modalView]}>
            <View
              style={[
                styles.headerContainer,
                {
                  width: 35,
                },
              ]}
            >
              <Pressable
                onPress={() => {
                  onDismiss()
                }}
                style={{ zIndex: 1 }}
              >
                <FAIcon iconName={faXmark} size={'lg'} color={'gray.g600'} />
              </Pressable>
            </View>
            <View style={[styles.messageTextContainer, children && { minHeight: 'auto', paddingVertical: 12 }]}>
              <Text style={styles.messageTitleText}>{titleRender ? titleRender() : title}</Text>
              <Text style={styles.messageText}>{messageRender ? messageRender() : message}</Text>
            </View>
            <View>
              <View style={styles.confirmButtonContainer}>
                <>
                  {children && <View style={{ minHeight: 160 }}>{children}</View>}
                  {list &&
                    list.map((item, idx) => (
                      <DialogButton
                        key={`confirm-btn-${idx}`}
                        buttonColor="main.blue"
                        name={item.name}
                        onPress={() => {
                          onClose()
                          item.action && item.action()
                        }}
                      />
                    ))}
                  {subList &&
                    subList &&
                    subList.map((item, idx) => (
                      <DialogButton
                        key={`subconfirm-btn-${idx}`}
                        buttonColor="mono.white"
                        name={item.name}
                        onPress={() => {
                          onClose()
                          item.action && item.action()
                        }}
                      />
                    ))}
                </>
              </View>
              {cancelText && onCancel && (
                <View style={styles.cancelButtonContainer}>
                  <Pressable
                    onPress={() => {
                      onCancel()
                      onClose()
                    }}
                    // backgroundColor="mono.white"
                    // color="main.blue"
                    style={[styles.buttonCancel]}
                  >
                    <Text style={(styles.buttonText, styles.buttonCancelText)}>{cancelText}</Text>
                  </Pressable>
                </View>
              )}
            </View>
          </View>
        </View>
      </View>
    </Modal>
  )
}

export default DialogView

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    flex: 1,
  },
  modalView: {
    ...Platform.select({
      web: {
        width: 375,
      },
      native: {
        width: 340,
      },
    }),

    // minHeight: 430,
    justifyContent: 'space-between',
    paddingVertical: 20,
    paddingBottom: 20,
    paddingHorizontal: 24,
    borderRadius: 8,
    backgroundColor: getRealColor('mono.white'),
  },
  headerContainer: {
    position: 'absolute',
    top: 20,
    right: 16,
    zIndex: 9,
  },
  confirmButtonContainer: {
    ...Platform.select({
      web: {
        paddingBottom: 20,
      },
      native: {
        paddingBottom: 10,
      },
    }),
  },
  cancelButtonContainer: {
    borderTopWidth: 1,
    borderTopColor: COLOR.mono.lightGray,
  },
  messageTextContainer: {
    flex: 1,
    ...Platform.select({
      web: {
        minHeight: 170,
      },
      native: {
        minHeight: 140,
      },
    }),
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 16,
    paddingHorizontal: 16,
    textAlign: 'center',
  },
  messageTitleText: {
    ...Platform.select({
      web: {
        ...FONT.H6Bold,
        marginBottom: 8,
      },
      native: {
        ...FONT.H7Bold,
        marginBottom: 4,
      },
    }),
    color: getRealColor('mono.black'),
  },
  messageText: {
    ...Platform.select({
      web: {
        ...FONT.txtMd,
      },
      native: {
        ...FONT.txtSm,
      },
    }),
    color: getRealColor('gray.g800'),
  },
  buttonCancel: {
    width: '100%',
    marginTop: 12,
  },
  buttonCancelText: {
    ...Platform.select({
      web: {
        ...FONT.txtMd,
      },
      native: {
        ...FONT.txtSm,
      },
    }),
    color: getRealColor('main.blue'),
  },
  buttonText: {
    ...FONT.txtMd,
    fontWeight: '400',
    lineHeight: 19.24,
    color: getRealColor('mono.black'),
  },
})
