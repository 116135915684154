import * as React from 'react'

import {
  WorkAssigneeProvider,
  WorkFileProvider,
  useCurrentUser,
  useWorkCard,
  useWorkChannel,
} from '@rocket-mono/providers'
import Providers from '../../providers'

import { i18n } from 'i18next'

interface Props {
  i18n: i18n
  children?: JSX.Element | JSX.Element[]
}
const Provider: React.FC<Props> = ({ i18n, children }) => {
  // const { astro } = useAstro()

  const { currentChannel } = useWorkChannel()
  const { cardId } = useWorkCard()
  const { currentUser } = useCurrentUser()

  return (
    <Providers i18n={i18n}>
      {/* <BackdropProvider>
        <Modal.Provider>
          <ModalDialog.Provider>
            <Toast.Provider>
              <WorkCardProvider astro={astro} channelId={channelId} cardId={cardId}> */}
      <WorkAssigneeProvider domain="card" domainId={cardId}>
        <WorkFileProvider
          domain="card"
          domainId={cardId}
          workId={Number(currentChannel?.projectId)}
          boardRoomId={currentChannel?.roomId || ''}
          userId={currentUser.id}
        >
          {children || <></>}
        </WorkFileProvider>
      </WorkAssigneeProvider>
      {/* </WorkCardProvider>
            </Toast.Provider>
          </ModalDialog.Provider>
        </Modal.Provider>
      </BackdropProvider> */}
    </Providers>
  )
}

export default Provider
