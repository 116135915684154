import * as React from 'react'
import { View } from 'react-native'
import { Outlet } from 'react-router-dom'

import Provider from './provider'
import { TOP_SIZE } from './top'

const LayoutBase = () => {
  return (
    <View style={{ flex: 1, height: '100%' }}>
      <Provider>
        <Outlet />
      </Provider>
    </View>
  )
}

export default LayoutBase
export { TOP_SIZE }
