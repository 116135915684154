import React, { useState } from 'react'
import { COLOR, FONT } from '@rocket-mono/libs'
import { StyleSheet, Pressable, Text, View } from 'react-native'

interface Props {
  placeHolder?: string
  children?: React.ReactNode
  isChildren?: boolean
  onPress: () => void
}

const TodoViewInput = ({placeHolder, children, isChildren, onPress}:Props) => {
  const [isHover, setIsHover] = useState(false)

  return(
    <Pressable
      onHoverIn={()=>setIsHover(true)}
      onHoverOut={()=>setIsHover(false)}
      onPress={onPress}
      style={[
        styles.container,
        isHover && styles.hoverContainer
      ]}
    >
      {isChildren ? 
        children
      :
        <View style={styles.placeholderWrap}>
          <Text style={styles.label}>{placeHolder}</Text>
        </View>
      }
    </Pressable>
  )
}

export default TodoViewInput

const styles = StyleSheet.create({
  container:{
    padding: 8,
    borderRadius: 4,
  },
  hoverContainer:{
    backgroundColor: COLOR.gray.g050
  },
  label:{
    ...FONT.txtSm,
    color: COLOR.gray.g400
  },
  placeholderWrap:{
    flexDirection:'row',
    alignItems:'center',
    minHeight: 24
  },
})