import * as React from 'react'
import { useState } from 'react'
import { Modal, StyleSheet, Dimensions } from 'react-native'
import { FONT, getRealColor } from '@rocket-mono/libs'

import type { ViewProps } from '../types'
import View from '../../View'
import Text from '../../Text'
import Button from '../../Button'
import { FAIcon } from '@rocket-atoms/icon'
/**
 * @deprecated
 */
const DialogBottomView: React.FC<ViewProps> = ({
  visible,
  title,
  cancelText,
  onCancel,
  onDismiss,
  list,
  subList,
  bottom,
}) => {
  const [containerWidth, setContainerWidth] = useState(0)
  const [buttonAreaWidth, setbuttonAreaWidth] = useState(0)
  const [messageWidth, setMessageWidth] = useState(0)
  const chartWidth = Dimensions.get('window').width
  const maxMessageWidth = 310

  return (
    <Modal animationType="slide" transparent visible={visible}>
      <View style={styles.container}>
        <View style={{ overflow: 'hidden', paddingTop: 5 }}>
          <View
            style={[
              styles.modalView,
              { bottom },
              chartWidth > maxMessageWidth && messageWidth > maxMessageWidth && styles.modalViewV2,
              containerWidth <= 400 && styles.modalViewV2,
              buttonAreaWidth >= containerWidth / 2 && styles.modalViewV2,
            ]}
            onLayout={(e) => {
              setContainerWidth(e.nativeEvent.layout.width)
            }}
          >
            <View
              style={[
                styles.messageTextContainer,
                chartWidth > maxMessageWidth && messageWidth > maxMessageWidth && styles.messageTextContainerV2,
                containerWidth <= 400 && styles.messageTextContainerV2,
                buttonAreaWidth >= containerWidth / 2 && styles.messageTextContainerV2,
              ]}
            >
              <Text
                style={styles.messageText}
                onLayout={(e) => {
                  setMessageWidth(e.nativeEvent.layout.width)
                }}
              >
                {title}
              </Text>
            </View>
            <View
              style={[
                styles.buttonContainerWrap,
                chartWidth > maxMessageWidth && messageWidth > maxMessageWidth && styles.buttonContainerWrapV2,
                containerWidth <= 400 && styles.buttonContainerWrapV2,
              ]}
              onLayout={(e) => {
                setbuttonAreaWidth(e.nativeEvent.layout.width)
              }}
            >
              <View style={styles.buttonContainer}>
                {list &&
                  list.map((item, idx) => (
                    <Button key={`confirm-btn-${idx}`} onPress={item.action} style={[styles.button, styles.nextButton]}>
                      <Text fontName="subTextMedium" fontColor="mono.white">
                        {item.icon && (
                          <View style={styles.innericon}>
                            <FAIcon size={'sm'} iconName={item.icon} color={'mono.white'} />
                          </View>
                        )}
                        {item.name}
                      </Text>
                    </Button>
                  ))}
                {subList &&
                  subList.map((item, idx) => (
                    <Button
                      color="mono.darkGray"
                      backgroundColor="mono.lightGray"
                      key={`btn-${idx}`}
                      onPress={item.action}
                      style={[styles.button, styles.nextButton]}
                    >
                      <Text fontName="subTextMedium" fontColor="mono.darkGray">
                        {item.icon && (
                          <View style={styles.innericon}>
                            <FAIcon size={'sm'} iconName={item.icon} color={'mono.darkGray'} />
                          </View>
                        )}
                        {item.name}
                      </Text>
                    </Button>
                  ))}
              </View>
              {onCancel && (
                <View style={styles.buttonContainer}>
                  <Button
                    style={styles.button}
                    onPress={() => {
                      onCancel()
                      onDismiss()
                    }}
                    backgroundColor="transparent.base"
                  >
                    <Text fontName="subTextMedium" fontColor="gray.g600">
                      {cancelText || '취소'}
                    </Text>
                  </Button>
                </View>
              )}
            </View>
          </View>
        </View>
      </View>
    </Modal>
  )
}

export default DialogBottomView

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    maxWidth: 800,
    justifyContent: 'flex-end',
    alignSelf: 'center',
    margin: 20,
    paddingHorizontal: 10,
    paddingBottom: 30,
  },
  modalView: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 4,
    paddingHorizontal: 20,
    paddingVertical: 8,
    backgroundColor: getRealColor('mono.white'),
  },
  modalViewV2: {
    flexDirection: 'column',
  },
  buttonContainerWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainerWrapV2: {
    justifyContent: 'flex-end',
    marginRight: -8,
  },
  messageTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  messageTextContainerV2: {
    paddingVertical: 4,
    marginBottom: 8,
  },
  messageText: {
    ...FONT.txtSm,
    lineHeight: 20.72,
    color: getRealColor('mono.black'),
  },
  button: {
    paddingHorizontal: 12,
    paddingVertical: 7,
  },
  innericon: {
    marginRight: 2,
  },
  nextButton: {
    marginHorizontal: 5,
  },
  // buttonText: {
  //   ...FONT.subTextMedium,
  //   fontWeight: '400',
  //   lineHeight: 19.24,
  //   color: getRealColor('mono.black'),
  // },
})
