import { DropdownButton, DropdownItemProps, DropdownList } from '@rocket-atoms/dropdown'
import { ListMember, SectionAccordionType } from '@rocket-molecules/popselector'
import { COLOR, ClickOutside, FONT } from '@rocket-mono/libs'
import { Button } from '@rocket/atoms'
import { Card, ChannelMember } from '@rocket/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, Text, View } from 'react-native'
import { useRule } from '../../RuleScreen/hooks'
import { Rule, RuleEventType, TriggerType } from '../../RuleScreen/types'
import RuleStepIcon from '../RuleStep/RuleStepIcon'
import { commonStyles } from '../style'
import RuleBody from './body'

const getInitialUserList = ({
  memberListOfSelectedBoard,
  selectedRule,
  getProfileUrl,
}: {
  memberListOfSelectedBoard?: ChannelMember[]
  selectedRule?: Rule
  getProfileUrl: (email: string) => string
}) => {
  if (!memberListOfSelectedBoard) return []
  return memberListOfSelectedBoard.map((member) => {
    return {
      id: String(member.userId),
      name: member.name,
      uri: getProfileUrl(member.email),
      selected: selectedRule?.selectedUsers?.some((user) => user.userId === member.userId) || false,
    }
  })
}

const getInitialCard = ({ selectedRule }: { selectedRule?: Rule }) => {
  if (selectedRule && selectedRule.selectedCard) {
    return selectedRule.selectedCard
  }
  return undefined
}

const getInitialBoardList = ({ selectedRule }: { selectedRule?: Rule }) => {
  if (selectedRule && selectedRule.selectedBoard) {
    return selectedRule.selectedBoard.map((board) => board.id)
  }
  return []
}

const getInitialWork = ({ selectedRule }: { selectedRule?: Rule }) => {
  if (selectedRule && selectedRule.selectedWork) {
    return selectedRule.selectedWork.id
  }
  return undefined
}

const getInitialBoardListOfSelectedWork = ({ selectedRule }: { selectedRule?: Rule }) => {
  if (selectedRule && selectedRule.selectedWork && selectedRule.selectedBoard) {
    return selectedRule.selectedBoard.map((board) => board.id)
  }
  return []
}

interface Props {
  triggerType?: TriggerType
  isMobile: boolean
  onPressCancel: () => void
  onPressNext: () => void
}

const RuleView: React.FC<Props> = ({ triggerType, isMobile, onPressCancel, onPressNext }) => {
  const { t } = useTranslation()
  const {
    viewTypeCodeList,
    projectList,
    selectedBoard,
    boardList: unArchiveBoardList,
    archiveBoardList,
    memberListOfSelectedBoard,
    cardListOfSelectedBoard,
    selectedRule,
    unArchiveBoardListOfSelectedWork,
    archiveBoardListOfSelectedWork,
    getProfileUrl,
    onSelectedRule,
    onRuleBoardBySelectedWork,
  } = useRule()
  const [activated, setActivated] = React.useState(false)
  // const [selected, setSelected] = React.useState<DropdownItemProps[]>([])
  const [userList, setUserList] = React.useState<ListMember[]>(
    getInitialUserList({ memberListOfSelectedBoard, selectedRule, getProfileUrl }),
  )
  const [selectedCard, setSelectedCard] = React.useState<Card | undefined>(getInitialCard({ selectedRule }))
  const [selectedBoardList, setSelectedBoardList] = React.useState<string[]>(getInitialBoardList({ selectedRule }))

  const [selectedWork, setSelectedWork] = React.useState<string | undefined>(getInitialWork({ selectedRule }))
  const [selectedBoardListOfSelectedWork, setSelectedBoardListOfSelectedWork] = React.useState<string[]>(
    getInitialBoardListOfSelectedWork({ selectedRule }),
  )

  const ruleList = React.useMemo(() => {
    const list: DropdownItemProps[] = []
    if (triggerType === 'NEWCARD') {
      list.push({ label: t('rule.users.title'), code: 'SPECIFIC_USER' })
      list.push({ label: t('rule.boards.title'), code: 'SPECIFIC_BOARD' })
      list.push({ label: t('rule.works.title'), code: 'ANOTHER_WORK_BOARD' })
    } else if (triggerType === 'TIME') {
      list.push({ label: t('rule.cards.title'), code: 'CARD' })
    } else if (triggerType === 'TODO') {
      console.log('TODO')
    } else if (triggerType === 'SCHEDULE') {
      console.log('SCHEDULE')
    }
    return list
  }, [triggerType])
  const selected = React.useMemo(() => {
    return ruleList.filter((item) => item.code === selectedRule?.event)
  }, [selectedRule])
  const event = React.useMemo(() => {
    return selectedRule?.event
  }, [selectedRule])

  const cardList = React.useMemo(() => {
    if (!cardListOfSelectedBoard) return []
    return cardListOfSelectedBoard.map((card) => {
      return {
        label: card.cardTitle,
        code: String(card.id),
        beforeCardIcon: card.cardType === 'ATTEND' ? 'MEETING' : card.cardType,
        selected: selectedRule?.selectedCard?.id === card.id,
      }
    })
  }, [cardListOfSelectedBoard, selectedRule])

  const defaultCardListIndex = React.useMemo(() => {
    if (!cardListOfSelectedBoard) return undefined
    if (!selectedRule) return undefined
    const { selectedCard } = selectedRule
    if (!selectedCard) return undefined
    return String(cardListOfSelectedBoard.findIndex((card) => card.id === selectedCard.id))
  }, [cardListOfSelectedBoard, selectedRule])

  const boardList = React.useMemo(() => {
    return [
      ...unArchiveBoardList
        .filter((board) => (selectedBoard ? selectedBoard.id !== board.id : true))
        .map((board) => {
          return {
            id: Number(board.id),
            title: board.roomName,
            code: board.id,
            isArchive: false,
          }
        }),
      ...archiveBoardList
        .filter((board) => (selectedBoard ? selectedBoard.id !== board.id : true))
        .map((board) => {
          return {
            id: Number(board.id),
            title: board.roomName,
            code: board.id,
            isArchive: true,
          }
        }),
    ]
  }, [unArchiveBoardList, archiveBoardList, selectedBoard])

  const getIcon = (code?: string) => {
    if (!code) return 'DSP' as const
    if (code === 'TOP') return 'TOP' as const
    if (code === 'CSP') return 'CSP' as const
    if (code === 'WFP') return 'WFP' as const
    if (code === 'DSP') return 'DSP' as const
    return 'DSP' as const
  }

  const workList = React.useMemo(() => {
    if (!projectList) return []
    return [
      {
        sectionList: projectList
          .filter((item) => viewTypeCodeList.includes(item.type.code))
          .map((item) => {
            return {
              beforeWorkIcon: getIcon(item.type.code),
              label: item.title,
              code: item.id,
            }
          }),
      },
    ]
  }, [projectList])

  const boardListBySelectedWork = React.useMemo(() => {
    return [
      ...unArchiveBoardListOfSelectedWork.map((board) => {
        return {
          id: Number(board.id),
          title: board.roomName,
          code: board.id,
          isArchive: false,
        }
      }),
      ...archiveBoardListOfSelectedWork.map((board) => {
        return {
          id: Number(board.id),
          title: board.roomName,
          code: board.id,
          isArchive: true,
        }
      }),
    ]
  }, [unArchiveBoardListOfSelectedWork, archiveBoardListOfSelectedWork])

  const isView = React.useMemo(() => {
    if (Platform.OS === 'web') return true
    return !activated
  }, [activated])

  const onSelectedRuleList = React.useCallback((list: DropdownItemProps[]) => {
    if (list.length > 0) {
      const item = list[0]
      const event = item.code as RuleEventType
      onSelectedRule({ event })
    }
  }, [])
  const onSelectedUserList = React.useCallback((list: ListMember[]) => {
    setUserList(list)
  }, [])

  const onSelectedCardList = React.useCallback((item: DropdownItemProps) => {
    if (item) {
      const card = cardListOfSelectedBoard?.find((card) => String(card.id) === item.code)
      setSelectedCard(card)
    }
  }, [])
  const onSelectedBoardList = React.useCallback((list: string[]) => {
    setSelectedBoardList(list)
  }, [])

  const onSelectedWork = React.useCallback((item: SectionAccordionType) => {
    onRuleBoardBySelectedWork(item.code)
    setSelectedWork(item.code)
    setSelectedBoardListOfSelectedWork([])
  }, [])

  const onSelectedBoardListOfSelectedWork = React.useCallback((list: string[]) => {
    setSelectedBoardListOfSelectedWork(list)
  }, [])

  const disabled = React.useMemo(() => {
    if (!selectedRule || !selectedRule.event) return true
    if (selectedRule.event === 'SPECIFIC_USER') {
      const memberIdList = userList.filter((member) => member.selected).map((member) => Number(member.id))
      if (memberIdList.length === 0) return true
      else return false
    }
    if (selectedRule.event === 'CARD' && selectedCard) return false
    if (selectedRule.event === 'SPECIFIC_BOARD' && selectedBoardList.length > 0) return false
    if (selectedRule.event === 'ANOTHER_WORK_BOARD' && selectedWork && selectedBoardListOfSelectedWork.length > 0)
      return false
    return true
  }, [selectedRule, selectedCard, selectedWork, userList, selectedBoardList, selectedBoardListOfSelectedWork])

  const handleNext = React.useCallback(() => {
    if (selectedRule?.event === 'SPECIFIC_USER') {
      if (!memberListOfSelectedBoard) return
      const memberIdList = userList.filter((member) => member.selected).map((member) => member.id)
      const selectedUsers = memberListOfSelectedBoard.filter((member) => memberIdList.includes(member.userId))
      onSelectedRule({ ...selectedRule, selectedUsers })
    }
    if (selectedRule?.event === 'CARD') {
      if (!selectedCard) return
      onSelectedRule({ ...selectedRule, selectedCard })
    }
    if (selectedRule?.event === 'SPECIFIC_BOARD') {
      const selectedBoard = [
        ...unArchiveBoardList.filter((board) => selectedBoardList.includes(board.id)),
        ...archiveBoardList.filter((board) => selectedBoardList.includes(board.id)),
      ]
      onSelectedRule({ ...selectedRule, selectedBoard })
    }
    if (selectedRule?.event === 'ANOTHER_WORK_BOARD') {
      const work = projectList?.find((project) => project.id === selectedWork)
      const selectedBoard = [
        ...unArchiveBoardListOfSelectedWork.filter((board) => selectedBoardListOfSelectedWork.includes(board.id)),
        ...archiveBoardListOfSelectedWork.filter((board) => selectedBoardListOfSelectedWork.includes(board.id)),
      ]
      onSelectedRule({ ...selectedRule, selectedWork: work, selectedBoard })
    }
    onPressNext()
  }, [
    selectedRule,
    selectedCard,
    selectedWork,
    memberListOfSelectedBoard,
    userList,
    selectedBoardList,
    unArchiveBoardList,
    archiveBoardList,
    unArchiveBoardListOfSelectedWork,
    archiveBoardListOfSelectedWork,
    selectedBoardListOfSelectedWork,
  ])

  React.useEffect(() => {
    if (selectedRule === undefined) {
      setUserList(getInitialUserList({ memberListOfSelectedBoard, selectedRule, getProfileUrl }))
    }
  }, [memberListOfSelectedBoard, selectedRule, getProfileUrl])

  return (
    <View style={commonStyles.container}>
      <View style={commonStyles.contents}>
        {isMobile ? (
          <></>
        ) : (
          <View style={commonStyles.titleArea}>
            <RuleStepIcon type="Rules" size={20} active />
            <Text style={commonStyles.title}>{t('rule.modal.rule')}</Text>
          </View>
        )}
        <View>
          <View>
            <DropdownButton
              type="DEFAULT"
              configuration="DEFAULT"
              size="md"
              disabled={false}
              activated={activated}
              setActivated={setActivated}
              isButtonBackgroundColorWhite
            >
              <View style={styles.boardLabel}>
                {selected.length > 0 ? (
                  <Text style={styles.labelText}>{selected[0].label}</Text>
                ) : (
                  <Text style={commonStyles.initText}>{t('rule.event.select')}</Text>
                )}
              </View>
            </DropdownButton>
            {activated && (
              <ClickOutside onClickOutside={() => setActivated(false)}>
                <DropdownList
                  list={ruleList}
                  configuration="DEFAULT-BORDER"
                  itemType="DEFAULT"
                  itemVariant="DEFAULT"
                  selected={selected}
                  setSelected={onSelectedRuleList}
                  setActived={setActivated}
                  width="100%"
                />
              </ClickOutside>
            )}
          </View>
        </View>
        {isView ? (
          <View>
            <RuleBody
              event={event}
              cardList={cardList}
              defaultCardListIndex={defaultCardListIndex}
              userList={userList}
              boardList={boardList}
              selectedBoardList={selectedBoardList}
              workList={workList}
              selectedWork={selectedWork}
              boardListBySelectedWork={boardListBySelectedWork}
              selectedBoardListOfSelectedWork={selectedBoardListOfSelectedWork}
              onSelectedUserList={onSelectedUserList}
              onSelectedCardList={onSelectedCardList}
              onSelectedBoardList={onSelectedBoardList}
              onSelectedBoardListBySelectedWork={onSelectedWork}
              onSelectedBoardListOfSelectedWork={onSelectedBoardListOfSelectedWork}
            />
          </View>
        ) : (
          <></>
        )}
      </View>
      {isView ? (
        <View style={commonStyles.buttonArea}>
          <Button type={'SECONDARY'} variant={'FILL'} text={t('workmodal.basic.before')} onPress={onPressCancel} />
          <View style={commonStyles.ctaButton}>
            <Button type="PRIMARY" text={t('workmodal.basic.next')} disabled={disabled} onPress={handleNext} />
          </View>
        </View>
      ) : (
        <></>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  boardLabel: {
    overflow: 'hidden',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 4,
    paddingRight: 6,
    borderRadius: 4,
  },
  labelText: {
    position: 'relative',
    ...FONT.txtSmMedium,
    color: COLOR.gray.g900,
    marginLeft: 4,
    zIndex: 2,
  },
})

export default RuleView
