import * as React from 'react'

import BoardScreen from './components/BoardScreen'

interface Props {
  projectId?: string
}

const ProjectBoardView: React.FC<Props> = ({ projectId }) => {
  return <BoardScreen projectId={projectId} />
}

export default ProjectBoardView
