import * as React from 'react'
import { StyleSheet, View, Text, Pressable } from 'react-native'
import { COLOR, FONT, getBoxShadow } from '@rocket-mono/libs'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FAIcon } from '@rocket-atoms/icon'

interface Props{
  title: string
  onPressClose: () => void
  children: React.ReactNode
}

const BottomInfoModal = ({title, onPressClose, children}: Props) => {
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>{title}</Text>
        <Pressable onPress={onPressClose}>
          <FAIcon iconName={faXmark} size={'1x'} nativeSize={20} color={'gray.g500'}/>
        </Pressable>
      </View>
      <View style={styles.body}>
        {children}
      </View>
    </View>
  )
}

export default BottomInfoModal

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR.darkmode.warm700,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    ...getBoxShadow(0, 0, 0, 0.15, 10, 0, -3, 0),
  },
  header:{
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 18
  },
  title:{
    ...FONT.txtMdMedium,
    color: COLOR.gray.g300
  },
  body:{}
})
