import * as React from 'react'
import { StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'

import { View } from '@rocket-mono/foundations'
import { COLOR, printFileSize } from '@rocket-mono/libs'
import { Text } from '@rocket/atoms'
import Gauge from './Gauge'

interface CapacityAreaType {
  storageUse: number
  storageMax: number
  onPress?: () => void
}

export const CapacityArea = ({ storageUse, storageMax }: CapacityAreaType) => {
  const { t } = useTranslation()

  const progressValue = React.useMemo(() => {
    if (storageMax < 0) return 0
    return Math.floor((storageUse * 100) / storageMax)
  }, [storageMax, storageUse])

  return (
    <View style={styles.container}>
      <View style={styles.tit}>
        <Text fontName="txtXs" fontColor="mono.white">
          {t('capacityarea.capacity')}
        </Text>
        {/* <Text fontName="txtXs" fontColor="mono.white">{progressValue}%</Text> */}
        <Text fontName="txtXs" fontColor="mono.white">
          {printFileSize(storageUse)}
        </Text>
      </View>
      <Gauge
        progress={progressValue}
        rounded
        showText={false}
        fillColor={progressValue > 80 ? 'main.red' : 'gray.g300'}
        unfillColor={'gray.g100'}
        progressStyle={{ height: 4 }}
      />
      {/* <View style={styles.infoLink}>
        <InfoLinkButton text={t('capacityarea.manage')} onPress={onPress} />
      </View> */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 16,
    paddingHorizontal: 12,
    backgroundColor: COLOR.opacity.w10,
  },
  tit: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 4,
  },
  infoLink: {
    marginTop: 8,
  },
})
