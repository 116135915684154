import React from 'react'

import { LayoutPayload } from '@rocket-mono/libs'
import { StyleSheet, View } from 'react-native'
import ButtonIcon from '../../ButtonIcon'

interface Props {
  onPress: (layoutPayload: LayoutPayload) => void
}

const MoreButton: React.FC<Props> = ({ onPress }) => {
  const viewRef = React.useRef<View>(null)
  return (
    <View ref={viewRef} style={styles.container}>
      <ButtonIcon
        iconName="ellipsis-h"
        onPress={() => {
          if (viewRef.current) {
            viewRef.current.measure((x, y, width, height, pageX, pageY) => {
              onPress({ x, y, width, height, pageX, pageY })
            })
          }
        }}
        style={styles.button}
        iconSize={15}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 10,
  },
  button: {
    height: 15,
  },
})

export default MoreButton
