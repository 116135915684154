import { Loader } from '@googlemaps/js-api-loader'
import { COLOR, ICON_PATH } from '@rocket-mono/libs'
import * as React from 'react'
import type { MapProps } from '../types'

const GoogleMap: React.FC<MapProps> = ({ zoom, latitude, longitude, label }): JSX.Element => {
  const [map, setMap] = React.useState<google.maps.Map>()

  React.useEffect(() => {
    const mapOptions = {
      disableDefaultUI: true,
      center: {
        lat: Number(latitude),
        lng: Number(longitude),
      },
      zoom,
    }

    const loader = new Loader({
      apiKey: 'AIzaSyAw2VvjB9KVwe-TTDfJyIfgr0E2l5boWvw',
      version: 'weekly',
      libraries: ['places'],
    })

    loader
      .load()
      .then((google) => {
        const map = new google.maps.Map(document.getElementById('map') as HTMLElement, mapOptions)

        new google.maps.Marker({
          position: mapOptions.center,
          map,
          icon: {
            path: ICON_PATH.maker,
            rotation: 180,
            fillColor: COLOR.main.blue,
            fillOpacity: 1,
            anchor: new google.maps.Point(
              600, // width
              50, // height
            ),
            strokeWeight: 0,
            scale: 0.05,
          },
        })

        /**
         * A customized popup on the map.
         */
        class Popup extends google.maps.OverlayView {
          position: google.maps.LatLng
          containerDiv: HTMLDivElement

          constructor(position: google.maps.LatLng, content: HTMLElement) {
            super()
            this.position = position

            // const content = document.createElement('p')
            content.classList.add('popup-bubble')
            // content.style.backgroundColor = COLOR.main.blue
            // content.style.padding = '5px'

            // content.innerHTML = 'test'

            // This zero-height div is positioned at the bottom of the bubble.
            const bubbleAnchor = document.createElement('div')

            // bubbleAnchor.classList.add('popup-bubble-anchor')
            bubbleAnchor.appendChild(content)

            // This zero-height div is positioned at the bottom of the tip.
            this.containerDiv = document.createElement('div')
            this.containerDiv.classList.add('popup-container')
            this.containerDiv.style.maxWidth = '30px;'
            this.containerDiv.appendChild(bubbleAnchor)

            // Optionally stop clicks, etc., from bubbling up to the map.
            Popup.preventMapHitsAndGesturesFrom(this.containerDiv)
          }

          /** Called when the popup is added to the map. */
          onAdd() {
            this.getPanes()!.floatPane.appendChild(this.containerDiv)
          }

          /** Called when the popup is removed from the map. */
          onRemove() {
            if (this.containerDiv.parentElement) {
              this.containerDiv.parentElement.removeChild(this.containerDiv)
            }
          }

          /** Called each frame when the popup needs to draw itself. */
          draw() {
            const divPosition = this.getProjection().fromLatLngToDivPixel(this.position)!

            // Hide the popup when it is far out of view.
            const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none'

            if (display === 'block') {
              this.containerDiv.style.left = divPosition.x + 5 + 'px'
              this.containerDiv.style.top = divPosition.y - 45 + 'px'
            }

            if (this.containerDiv.style.display !== display) {
              this.containerDiv.style.display = display
            }
          }
        }

        const popup = new Popup(
          new google.maps.LatLng(latitude, longitude),
          document.getElementById('content') as HTMLElement,
        )
        popup.setMap(map)

        setMap(map)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [])

  React.useEffect(() => {
    map?.setZoom(zoom)
  }, [map, zoom])

  return (
    <>
      <style>
        {`.popup-bubble{position:absolute;top:0;left:0;transform:translate(-50%,-100%);background-color:${COLOR.main.blue};color:${COLOR.mono.white};padding:5px;border-radius:5px;overflow-y:auto;max-height:60px;}.popup-bubble-anchor{position:absolute;width:100%;bottom:8px;left:0}.popup-bubble-anchor::after{content:"";position:absolute;top:0;left:0;transform:translate(-50%,0);width:0;height:0;border-left:6px solid transparent;border-right:6px solid transparent;border-top:8px solid #fff}.popup-container{cursor:auto;height:0;position:absolute;width:200px}`}
      </style>
      <div id="map" style={{ width: '100%', height: '100%' }}></div>
      <div id="content" style={{ fontSize: 12 }}>
        {label}
      </div>
    </>
  )
}

export default GoogleMap
