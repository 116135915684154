import { Button } from '@rocket-atoms/button'
import { DropdownButton, DropdownItemProps, DropdownList } from '@rocket-atoms/dropdown'
import { COLOR, ClickOutside, FONT, getRealColor } from '@rocket-mono/libs'
import { BoardLabel } from '@rocket/atoms'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DimensionValue, Platform, StyleSheet, Text, View } from 'react-native'
import RuleStepIcon from './RuleStep/RuleStepIcon'
import { commonStyles } from './style'

const getInitialData = (boardList: DropdownItemProps[], defaultIndex?: number) => {
  if (defaultIndex !== undefined) {
    return [boardList[defaultIndex]]
  }
  return []
}

interface Props {
  boardList: DropdownItemProps[]
  onItemPress?: (item: DropdownItemProps) => void
  onPressNext: () => void
  defaultIndex?: number
  isMobile: boolean
}

const ApplyBoardView = ({ boardList, onItemPress, onPressNext, defaultIndex, isMobile }: Props) => {
  const { t } = useTranslation()
  const [activated, setActivated] = React.useState(false)
  const [buttonStandard, setButtonStandard] = React.useState<{ width: DimensionValue; height: number }>({
    width: 0,
    height: 0,
  })
  const [selected, setSelected] = React.useState(getInitialData(boardList, defaultIndex))

  const isView = React.useMemo(() => {
    if (Platform.OS === 'web') return true
    return !activated
  }, [activated])

  return (
    <View style={commonStyles.container}>
      <View style={commonStyles.contents}>
        {/* 컨텐츠 시작 */}
        {isMobile ? (
          <></>
        ) : (
          <View style={commonStyles.titleArea}>
            <RuleStepIcon type="Applyboard" size={20} active />
            <Text style={commonStyles.title}>{t('rule.modal.target')}</Text>
          </View>
        )}
        <View>
          <View>
            <DropdownButton
              type={'DEFAULT'}
              configuration={'DEFAULT'}
              size={'md'}
              disabled={false}
              activated={activated}
              setActivated={setActivated}
              setButtonStandard={setButtonStandard}
              isButtonBackgroundColorWhite
            >
              <View style={styles.boardLabel}>
                {selected.length > 0 ? (
                  <>
                    <BoardLabel
                      color={selected[0].beforeBoardLabel ? selected[0].beforeBoardLabel : 'sub.pink'}
                      size={16}
                    />
                    <Text style={styles.labelText}>{selected[0].label}</Text>
                    <View
                      style={[
                        styles.colorBack,
                        {
                          backgroundColor: getRealColor(
                            selected[0].beforeBoardLabel ? selected[0].beforeBoardLabel : 'sub.pink',
                          ),
                        },
                      ]}
                    ></View>
                  </>
                ) : (
                  <Text style={commonStyles.initText}>{t('rule.boards.select')}</Text>
                )}
              </View>
            </DropdownButton>
            {activated && (
              <ClickOutside onClickOutside={() => setActivated(false)}>
                <DropdownList
                  list={boardList}
                  configuration={'DEFAULT-BORDER'}
                  itemType={'DEFAULT'}
                  itemVariant={'DEFAULT'}
                  selected={selected}
                  setSelected={setSelected}
                  buttonStandard={buttonStandard}
                  setActived={setActivated}
                  width={'100%'}
                  onSelect={(item) => {
                    onItemPress && onItemPress(item)
                  }}
                />
              </ClickOutside>
            )}
          </View>
        </View>
        {/* 컨텐츠 종료 */}
      </View>
      {isView ? (
        <View style={commonStyles.buttonArea}>
          <View style={commonStyles.ctaButton}>
            <Button type={'PRIMARY'} text={t('workmodal.basic.next')} onPress={onPressNext} />
          </View>
        </View>
      ) : (
        <></>
      )}
    </View>
  )
}

export default ApplyBoardView

const styles = StyleSheet.create({
  boardLabel: {
    overflow: 'hidden',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 4,
    paddingRight: 6,
    borderRadius: 4,
  },
  labelText: {
    position: 'relative',
    ...FONT.txtXsMedium,
    color: COLOR.gray.g900,
    marginLeft: 4,
    zIndex: 2,
  },
  colorBack: {
    ...(Platform.OS === 'web' ? { width: '100%' } : {}),
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    opacity: 0.15,
  },
})
