import * as React from 'react'
import { StyleSheet, View, Pressable } from 'react-native'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IColors } from '@rocket-mono/libs'
import { COLOR } from '@rocket-mono/libs'
import { FAIcon } from '@rocket-atoms/icon'

interface Props {
  type: 'CIRCLE' | 'CIRCLELINE' | 'ROUNDSQUARE'
  darkmode: boolean
  size: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge'
  iconName: IconProp
  iconColor?: IColors
  onPress?: () => void
}
/**
 * @deprecated
 */
const IconButton = ({ type, darkmode, size, iconName, iconColor, onPress }: Props) => {
  const [isHover, setIsHover] = React.useState(false)
  const [sizePack, setSizePack] = React.useState({ iconSize: 10, buttonSize: 16 })

  React.useEffect(() => {
    SizeOutput()
  }, [size])
  const SizeOutput = () => {
    return size === 'xsmall'
      ? setSizePack({ iconSize: 6, buttonSize: 12 })
      : size === 'small'
      ? setSizePack({ iconSize: 8, buttonSize: 16 })
      : size === 'medium'
      ? setSizePack({ iconSize: 10, buttonSize: 20 })
      : size === 'large'
      ? setSizePack({ iconSize: 12, buttonSize: 24 })
      : size === 'xlarge'
      ? setSizePack({ iconSize: 16, buttonSize: 32 })
      : 16
  }

  return (
    <Pressable
      onPress={onPress && onPress}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      style={[
        styles.container,
        { width: sizePack.buttonSize, height: sizePack.buttonSize },
        type === 'CIRCLE' && styles.roundSquare,
        type === 'CIRCLELINE' && styles.roundSquare,
        type === 'ROUNDSQUARE' && styles.roundSquare,
        type === 'ROUNDSQUARE' && darkmode && styles.roundSquareDarkmode,
        darkmode && isHover && { backgroundColor: COLOR.gray.g800 },
      ]}
    >
      <View style={[styles.container, { width: sizePack.iconSize, height: sizePack.iconSize }]}>
        <FAIcon iconName={iconName} color={iconColor ? iconColor : 'gray.g450'} />
      </View>
    </Pressable>
  )
}

export default IconButton

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  roundSquare: {
    borderRadius: 8,
  },
  roundSquareDarkmode: {
    backgroundColor: COLOR.gray.g900,
  },
})
