import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { COLOR, FONT } from '@rocket-mono/libs'
import { BoardLabel, FAIcon } from '@rocket-atoms/icon'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { Tag } from '@rocket/atoms'

interface Props {
  archive?: boolean
  isActived: boolean
  boardTitle: string
}

const CardSearchBoardDepth = ({ archive, isActived, boardTitle }: Props) => {
  return (
    <View style={[styles.container]}>
      <View style={styles.buttonArea}>
        <FAIcon iconName={isActived ? faCaretUp : faCaretDown} size={'xs'} color={'gray.g400'} />
      </View>
      <BoardLabel color={'sub.pink'} size={16} />

      {archive && (
        <Tag
          name="Archive"
          containerStyle={{ backgroundColor: COLOR.gray.g700, marginLeft: 4 }}
          textStyle={{ color: COLOR.mono.white }}
        />
      )}
      <Text style={styles.nameText}>{boardTitle}</Text>
    </View>
  )
}

export default CardSearchBoardDepth

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
  nameText: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g700,
    marginLeft: 4,
  },
  buttonArea: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    borderRadius: 4,
  },
})
