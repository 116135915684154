import React, { useCallback } from 'react'
import {
  GestureResponderEvent,
  Platform,
  Pressable,
  PressableProps,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from 'react-native'

import { COLOR, IColors, getRealColor } from '@rocket-mono/libs'
import View from '../View'
import Text from '../Text'

const getBorderRadius = (containerSize: number) => {
  return containerSize * 0.2
}

interface Props extends Omit<PressableProps, 'style'> {
  containerSize?: number
  icon: JSX.Element
  // iconName: IconName | JSX.Element
  // iconSize?: number
  // iconColor?: IColors
  // iconViewbox?: string
  backgroundColor?: IColors
  rounded?: boolean
  style?: StyleProp<ViewStyle>
  hoverStyle?: StyleProp<ViewStyle>
  pressedStyle?: StyleProp<ViewStyle>
  disabledStyle?: StyleProp<ViewStyle>
  badgeCount?: number
}
/**
 * @deprecated
 */
const ButtonIcon: React.FC<Props> = ({
  containerSize = 35,
  icon,
  // iconName,
  // iconSize = containerSize * 0.5,
  // iconColor = 'mono.black',
  // iconViewbox = '0 -40 1000 1000',
  backgroundColor = 'transparent',
  rounded = false,
  onPressIn,
  onPressOut,
  style,
  hoverStyle,
  pressedStyle,
  disabledStyle,
  badgeCount = 0,
  disabled,
  ...props
}) => {
  const _onPressIn = useCallback(
    (event: GestureResponderEvent) => {
      onPressIn && onPressIn(event)
    },
    [onPressIn],
  )

  const _onPressOut = useCallback(
    (event: GestureResponderEvent) => {
      onPressOut && onPressOut(event)
    },
    [onPressOut],
  )

  const onStyle = useCallback(
    // @ts-ignore hovered only used in web
    ({ hovered, pressed }: PressableStateCallbackType) => {
      return [
        styles.pressable,
        {
          borderRadius: rounded ? containerSize : getBorderRadius(containerSize),
          backgroundColor: getRealColor(backgroundColor),
        },
        !disabled && hovered && (hoverStyle ?? styles.hover),
        !disabled && pressed && (pressedStyle ?? styles.pressed),
        disabled && (disabledStyle ?? styles.disabled),
      ] as ViewStyle[]
    },
    [containerSize, rounded, backgroundColor, hoverStyle, pressedStyle, disabledStyle, disabled],
  )
  return (
    <View
      style={[
        styles.container,
        {
          height: containerSize,
          width: containerSize,
          borderRadius: rounded ? containerSize : getBorderRadius(containerSize),
        },
        style,
      ]}
    >
      <Pressable style={onStyle} onPressIn={_onPressIn} onPressOut={_onPressOut} disabled={disabled} {...props}>
        {/* <Icon name={iconName} size={iconSize} color={iconColor} viewBox={iconViewbox} /> */}
        {icon}
        {badgeCount > 0 && (
          <View style={styles.iconCountContainer}>
            <Text fontName="textRegular" fontColor="mono.white">
              {badgeCount}
            </Text>
          </View>
        )}
      </Pressable>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
  },
  pressable: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
    height: '100%',
    width: '100%',
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
    }),
  },
  hover: {
    opacity: 0.8,
  },
  pressed: {
    opacity: 0.5,
  },
  disabled: {
    backgroundColor: COLOR.mono.lightGray,
  },
  iconCountContainer: {
    backgroundColor: COLOR.main.red,
    borderRadius: 15,
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
  },
})

export default ButtonIcon
