import React, { useCallback } from 'react'

import { WorkCardEditScreen } from '@rocket-mono/screen-card'
import { useCardLayout } from '../../layout'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const CardEdit: React.FC = () => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const { projectId, cardId, cardType } = useCardLayout()

  const handleSuccess = useCallback((cardId: string) => {
    navigate(`/card/${cardId}`)
  }, [])
  const handleFail = useCallback(() => {
    // window.close()
  }, [])

  const handlePressClose = useCallback(() => {
    navigate(`/card/${cardId}`)
  }, [])
  return (
    <WorkCardEditScreen
      i18n={i18n}
      isFold={false}
      window
      currentProjectId={projectId}
      opened={false}
      cardType={cardType}
      onSuccess={handleSuccess}
      onFail={handleFail}
      onPressClose={handlePressClose}
    />
  )
}

export default CardEdit
