import { COLOR } from '@rocket-mono/libs'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import RuleStep, { RuleType } from './RuleStep'
import RuleValueTemplate, {
  RuleValueIsTimeProps,
  RuleValueToBoardProps,
  RuleValueToCardProps,
  RuleValueToUserProps,
  RuleValueToWorkProps,
} from './RuleValueTemplate'

interface RuleStepperProps {
  applyBoardValue?: RuleValueToBoardProps[]
  triggersValue?: {
    name?: string
    isTime?: RuleValueIsTimeProps
  }
  rulesValue?: {
    name?: string
    toUser?: RuleValueToUserProps[]
    toWork?: RuleValueToWorkProps
    toBoard?: RuleValueToBoardProps[]
    toCard?: RuleValueToCardProps
  }
  actionValue?: {
    toName?: string
    toWork?: RuleValueToWorkProps
    toBoard?: RuleValueToBoardProps[]
    name?: string
  }
  onPress?: (type: RuleType) => void
  onCancel?: (type: RuleType) => void
}

const RuleStepperView: React.FC<RuleStepperProps> = ({
  applyBoardValue,
  triggersValue,
  rulesValue,
  actionValue,
  onPress,
  onCancel,
}) => {
  const [stepOneHeight, setStepOneHeight] = useState(0)
  const [stepTwoHeight, setStepTwoHeight] = useState(0)
  const [stepThreeHeight, setStepThreeHeight] = useState(0)

  return (
    <View style={styles.container}>
      <View style={[styles.con, { zIndex: 5 }]}>
        <View onLayout={(e) => setStepOneHeight(e.nativeEvent.layout.height)}>
          <RuleStep
            type="Applyboard"
            active={applyBoardValue ? true : false}
            onPress={() => onPress?.('Applyboard')}
            onCancel={() => onCancel?.('Applyboard')}
          >
            {applyBoardValue && applyBoardValue.length > 0 && <RuleValueTemplate toBoard={applyBoardValue} />}
          </RuleStep>
        </View>
        <View style={[styles.dashed, { height: stepOneHeight - 12 }]} />
      </View>

      <View style={[styles.con, { zIndex: 4 }]}>
        <View onLayout={(e) => setStepTwoHeight(e.nativeEvent.layout.height)}>
          <RuleStep
            type="Triggers"
            active={triggersValue ? true : false}
            onPress={() => onPress?.('Triggers')}
            onCancel={() => onCancel?.('Triggers')}
          >
            {triggersValue && triggersValue.name ? (
              <RuleValueTemplate rulesName={triggersValue.name} isTime={triggersValue.isTime} />
            ) : undefined}
          </RuleStep>
        </View>
        <View style={[styles.dashed, { height: stepTwoHeight - 12 }]} />
      </View>
      <View style={[styles.con, { zIndex: 3 }]}>
        <View onLayout={(e) => setStepThreeHeight(e.nativeEvent.layout.height)}>
          <RuleStep
            type="Rules"
            active={rulesValue ? true : false}
            onPress={() => onPress?.('Rules')}
            onCancel={() => onCancel?.('Rules')}
          >
            {rulesValue && rulesValue.name && (
              <RuleValueTemplate
                rulesName={rulesValue?.name}
                toUser={rulesValue.toUser}
                toWork={rulesValue.toWork}
                toBoard={rulesValue.toBoard}
                toCard={rulesValue.toCard}
              />
            )}
          </RuleStep>
        </View>
        <View style={[styles.dashed, { height: stepThreeHeight - 12 }]} />
      </View>
      <View>
        <View>
          <RuleStep
            type="Action"
            active={actionValue ? true : false}
            onPress={() => onPress?.('Action')}
            onCancel={() => onCancel?.('Action')}
          >
            {actionValue && (actionValue.name || actionValue.toName) ? (
              actionValue.toWork && actionValue.toBoard ? (
                <>
                  <RuleValueTemplate
                    rulesName={actionValue.toName}
                    toWork={actionValue.toWork}
                    toBoard={actionValue.toBoard}
                  />
                  <RuleValueTemplate rulesName={actionValue.name} />
                </>
              ) : (
                <RuleValueTemplate rulesName={actionValue.name} />
              )
            ) : undefined}
          </RuleStep>
        </View>
      </View>
    </View>
  )
}

export default RuleStepperView

const styles = StyleSheet.create({
  container: {},
  con: {
    marginBottom: 12,
  },
  dashed: {
    position: 'absolute',
    top: 38,
    left: 25,
    width: 1,
    borderLeftWidth: 1,
    borderColor: COLOR.gray.g200,
    borderStyle: 'dashed',
  },
})
