import React from 'react'

import { COLOR, FONT, getBoxShadow } from '@rocket-mono/libs'
import { useAstro } from '@rocket-mono/providers'
import { Button, Icon, Text, TextField } from '@rocket/atoms'
import { useTranslation } from 'react-i18next'
import { Modal, Platform, Pressable, StyleSheet, View } from 'react-native'

interface Props {
  onLogin?: () => void
  onClose: () => void
}

type StepType = 'INPUT' | 'SEND'

const CloseButton = ({ onPress }: { onPress: () => void }) => {
  return (
    <Pressable onPress={onPress} style={styles.closeButton}>
      <Icon name="close" color="mono.paleBlack" />
    </Pressable>
  )
}

const Body = ({
  step,
  onStep,
  onLogin,
  onClose,
}: {
  step: StepType
  onStep: (step: StepType) => void
  onLogin?: () => void
  onClose: () => void
}) => {
  const { t } = useTranslation()
  const { astro } = useAstro()

  const [email, setEmail] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState<string>()

  const onEmail = () => {
    if (!email) return
    astro.sendMailIssueTempPassword(email).then((response) => {
      if (response.status === 200) {
        onStep('SEND')
      } else {
        response.json().then(({ errorType }: { errorType: string }) => {
          if (errorType === 'INVALID_EMAIL_FORMAT') {
            setErrorMessage(t('findpasswordmodal.email.invalid'))
          } else if (errorType === 'NOT_ACCOUNT') {
            setErrorMessage(t('findpasswordmodal.email.notaccount'))
          }
        })
      }
    })
  }

  switch (step) {
    case 'INPUT': {
      return (
        <View style={[styles.baseContainer, styles.inputContainer]}>
          <View style={styles.inputHeaderContainer}>
            <Text fontName="listBold" fontColor="mono.black">
              {t('findpasswordmodal.title')}
            </Text>
          </View>
          <View style={styles.inputDescContainer}>
            <Text fontName="subTextRegular" fontColor="mono.paleBlack">
              {t('findpasswordmodal.desc1')}
            </Text>
            <Text fontName="subTextRegular" fontColor="mono.paleBlack">
              {t('findpasswordmodal.desc2')}
            </Text>
          </View>
          <View>
            <TextField
              value={email}
              placeholder={t('findpasswordmodal.placeholder')}
              onChangeValue={(text) => setEmail(text)}
              onSubmitEditing={onEmail}
            />
            {errorMessage ? (
              <View style={styles.errorMessageContainer}>
                <Icon name="info" color="status.busy" size="16" />
                <Text style={styles.errorMessage}>{errorMessage}</Text>
              </View>
            ) : (
              <></>
            )}
          </View>

          <View style={styles.br} />
          <Button wide text={t('findpasswordmodal.emailcheck')} onPress={onEmail} />
          <View style={styles.inputFooterContainer}>
            <Pressable onPress={onClose}>
              <Text fontName="subTextBold" style={styles.blueColor}>
                {t('findpasswordmodal.footer.login')}
              </Text>
            </Pressable>
            <Text fontName="subTextMedium">{t('findpasswordmodal.footer.back')}</Text>
          </View>
          <CloseButton onPress={onClose} />
        </View>
      )
    }
    case 'SEND': {
      return (
        <View style={[styles.baseContainer, styles.sendContainer]}>
          <View style={styles.sendBodyContainer}>
            <Text fontName="subTitleBold" fontColor="mono.black">
              {t('findpasswordmodal.sendtemppassword')}
            </Text>
            <View style={{ height: 8 }} />
            <Text fontName="listBold" style={styles.blueColor}>
              {email}
            </Text>
            <View style={{ height: 4 }} />
            <Text fontName="textRegular" fontColor="mono.black">
              {t('findpasswordmodal.senddesc')}
            </Text>
          </View>
          <View style={{ height: 24 }} />
          <Button
            wide
            text={t('findpasswordmodal.loginback')}
            onPress={() => {
              onLogin && onLogin()
              onClose()
            }}
          />
          <CloseButton onPress={onClose} />
        </View>
      )
    }
    default: {
      return <></>
    }
  }
}

const FindPasswordModal: React.FC<Props> = ({ onLogin, onClose }) => {
  const [step, setStep] = React.useState<StepType>('INPUT')

  const onStep = (step: StepType) => {
    setStep(step)
  }

  return (
    <>
      <Modal transparent animationType="fade">
        {Platform.OS === 'ios' || Platform.OS === 'android' ? <View style={styles.dimmed} /> : <></>}
        <View style={styles.container}>
          <Body step={step} onStep={onStep} onLogin={onLogin} onClose={onClose} />
        </View>
      </Modal>
      {Platform.OS === 'web' ? <View style={styles.dimmed} /> : <></>}
    </>
  )
}

const styles = StyleSheet.create({
  dimmed: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  container: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  baseContainer: {
    borderRadius: 14,
    ...getBoxShadow(0, 0, 0, 0.15, 15, 0, 0, 0),
  },
  inputContainer: {
    width: 320,
    backgroundColor: COLOR.mono.white,
    paddingHorizontal: 24,
    paddingTop: 18,
  },
  inputHeaderContainer: {
    marginBottom: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputDescContainer: {
    marginBottom: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputFooterContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 20,
  },
  br: {
    height: 8,
  },
  blueColor: {
    color: '#3067A8',
  },
  sendContainer: {
    width: 370,
    backgroundColor: COLOR.mono.white,
    paddingHorizontal: 27,
    paddingVertical: 40,
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
  metaText: {
    ...FONT.txtSm,
    color: COLOR.gray.g700,
  },
  errorMessageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 3,
  },
  errorMessage: {
    ...FONT.txtXsMedium,
    color: COLOR.status.busy,
  },
  sendBodyContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default FindPasswordModal
