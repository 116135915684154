import { Platform, StyleSheet } from 'react-native'

export const COLOR = {
  mono: {
    realBlack: '#000',
    black: '#333',
    paleBlack: '#666',
    darkGray: '#999',
    gray: '#CCC',
    lightGray: '#DDD',
    pale: '#EEE',
    paleWhite: '#F7F7F7',
    white: '#FFF',
    originGray: '#949494',
    whiteGray: '#ECECEC',
    brightBlue: '#376eb2',
    paleBlue: '#EDF2F9',
    paleBlueDark: '#D0DCEB',
  },
  gray: {
    g050: '#F6F8F9',
    g100: '#EDEFF0',
    g150: '#E6E9ED',
    g200: '#D3D7DC',
    g300: '#B4BEC5',
    g400: '#97A4AC',
    g450: '#80909B',
    g500: '#6A7C87',
    g600: '#5D6D78',
    g700: '#4D5A61',
    g800: '#3C464D',
    g900: '#2A3237',
  },
  darkmode: {
    cool900: '#19191B',
    cool800: '#282F33',
    cool700: '#2A3237',
    cool600: '#4C5357',
    cool500: '#80909B',
    cool400: '#97A4AC',
    cool300: '#CCD2D9',
    warm900: '#1B1919',
    warm800: '#1E1E1F',
    warm700: '#2E2F32',
    warm600: '#2F3134',
    warm500: '#353638',
    warm400: '#4A4E52',
    warm300: '#8D909B',
  },
  main: {
    red: '#F85A5E',
    redHsb: '#DE5154',
    blue: '#357DE5',
    navy: '#001B57',
    darkNavy: '#1F2940',
    yellow: '#F7C954',
    turquoise: '#5EBCCA',
    pink: '#F97F90',
    lightCyan: '#55B3C2',
  },
  sub: {
    green: '#92DCA9',
    green200: '#E9F8EE',
    green500: '#237A4B',
    sky: '#58C5E3',
    sky200: '#DEF3F9',
    sky500: '#3A98B1',
    darkPurple: '#6258BA',
    darkPurple200: '#E0DEF1',
    ocean: '#99EAED',
    ocean200: '',
    pink: '#EF91AA',
    pink200: '#FCE9EE',
    pink500: '#F26D80',
    paleYellow: '#EABF80',
    paleYellow200: '#FBF2E6',
    paleYellow500: '#9C7439',
    purple: '#B48BD3',
    purple200: '#F0E8F6',
    purple500: '#8F66AE',
    lightGreen: '#BBDA85',
    lightGreen200: '#F1F8E7',
    lightGreen500: '#618423',
    paleBlue: '#A3B7ED',
    paleBlue200: '#EDF1FB',
    paleBlue500: '#6181D7',
    paleSky: '#7FB2D6',
    paleSky200: '#E5F0F7',
    paleSky500: '#5992B5',
    lightYellow: '#FFE81D',
    darkNavy: '#233148',
    darkNavy200: '#D3D6DA',
    lightBlue: '#4695EC',
  },
  primary: {
    blue05: '#F0F7FF',
    blue100: '#C2E0FF',
    blue200: '#99CCF3',
    blue300: '#66B2FF',
    blue400: '#3399FF',
    blue500: '#007FFF',
    blue600: '#0072E5',
    blue700: '#0059B2',
    blue800: '#004FC9',
    blue900: '#003A75',
  },
  status: {
    active: '#37EB5B',
    inactive: '#AAAAAA',
    unavailable: '#EBE537',
    busy: '#EB3737',
    off: '#666666',
  },
  ect: {
    sns: {
      naver: '#2AB404',
      facebook: '#3B599B',
      kakaobg: '#F7E106',
      kakaotext: '#402021',
      google: '#DA2915',
      apple: '#222',
      email: '#666',
    },
    botInner: {
      inner: 'rgba(77, 63, 42, 0.07)',
      border: 'rgba(0, 0, 0, 0.03)',
    },
    sherlock: '#5C3FD3',
  },
  todo: {
    completeBar: '#D8EDF3',
  },
  opacity: {
    mainBlue: 'rgba(48, 103, 168, 0.1)',
    w10: 'rgba(255, 255, 255, 0.1)',
    w20: 'rgba(255, 255, 255, 0.2)',
    w30: 'rgba(255, 255, 255, 0.3)',
    w40: 'rgba(255, 255, 255, 0.4)',
    w50: 'rgba(255, 255, 255, 0.5)',
    w60: 'rgba(255, 255, 255, 0.6)',
    w70: 'rgba(255, 255, 255, 0.7)',
    w80: 'rgba(255, 255, 255, 0.8)',
    w90: 'rgba(255, 255, 255, 0.9)',
    blk05: 'rgba(0, 0, 0, 0.05)',
    blk10: 'rgba(0, 0, 0, 0.1)',
    blk20: 'rgba(0, 0, 0, 0.2)',
    blk30: 'rgba(0, 0, 0, 0.3)',
    blk40: 'rgba(0, 0, 0, 0.4)',
    blk50: 'rgba(0, 0, 0, 0.5)',
    blk60: 'rgba(0, 0, 0, 0.6)',
    blk70: 'rgba(0, 0, 0, 0.7)',
    blk80: 'rgba(0, 0, 0, 0.8)',
    blk90: 'rgba(0, 0, 0, 0.9)',
  },
  transparent: {
    base: 'rgba(0, 0, 0, 0)',
  },
  dimmed: {
    a60: 'rgba(42,50,55, 0.6)',
    a40: 'rgba(42,50,55, 0.4)',
  },
}

export const FONT = StyleSheet.create({
  pretendardBold: {
    fontSize: 20,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Bold',
    }),
  },
  pretendardMedium: {
    fontSize: 20,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
  },
  pretendardRegular: {
    fontSize: 20,
    fontWeight: '400',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Regular',
    }),
  },
  pretendardLight: {
    fontSize: 20,
    fontWeight: '300',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Light',
    }),
  },

  titleBold: {
    fontSize: 24,
    fontWeight: '700',
    lineHeight: 30,
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Black',
    }),
  },
  titleMedium: {
    fontSize: 20,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Black',
    }),
  },
  titleRegular: {
    fontSize: 20,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Bold',
    }),
  },
  subTitleBold: {
    fontSize: 18,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Black',
    }),
  },
  listBold: {
    fontSize: 16,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Black',
    }),
  },
  listMedium: {
    fontSize: 16,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
  },
  listRegular: {
    fontSize: 16,
    fontWeight: '400',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
  },
  textBold: {
    fontSize: 14,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Black',
    }),
  },
  textMedium: {
    fontSize: 14,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
  },
  textRegular: {
    fontSize: 14,
    fontWeight: '400',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
  },
  textThin: {
    fontSize: 14,
    fontWeight: '300',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Light',
    }),
  },
  subTextBold: {
    fontSize: 13,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Bold',
    }),
  },
  subTextMedium: {
    fontSize: 13,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
  },
  subTextRegular: {
    fontSize: 12,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
  },
  subTextThin: {
    fontSize: 10,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
  },
  chatTextBold: {
    fontSize: 14,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Black',
    }),
  },
  chatTextMedium: {
    fontSize: 14,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Bold',
    }),
  },
  chatTextRegular: {
    fontSize: 14,
    fontWeight: '400',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
  },
  chatSubTextThin: {
    fontSize: 10,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
  },
  chatSubTextBold: {
    fontSize: 13,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Bold',
    }),
  },

  H1: {
    fontSize: 44,
    lineHeight: 56,
    fontWeight: '400',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Regular',
    }),
    letterSpacing: -0.82,
  },
  H2: {
    fontSize: 36,
    lineHeight: 44,
    fontWeight: '400',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Regular',
    }),
    letterSpacing: -0.68,
  },
  H3: {
    fontSize: 32,
    lineHeight: 40,
    fontWeight: '400',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Regular',
    }),
    letterSpacing: -0.6,
  },
  H4: {
    fontSize: 28,
    lineHeight: 36,
    fontWeight: '400',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Regular',
    }),
    letterSpacing: -0.52,
  },
  H5: {
    fontSize: 24,
    lineHeight: 32,
    fontWeight: '400',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Regular',
    }),
    letterSpacing: -0.45,
  },
  H6: {
    fontSize: 20,
    lineHeight: 28,
    fontWeight: '400',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Regular',
    }),
    letterSpacing: -0.38,
  },
  H7: {
    fontSize: 18,
    lineHeight: 24,
    fontWeight: '400',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Regular',
    }),
    letterSpacing: -0.3,
  },
  H1Medium: {
    fontSize: 44,
    lineHeight: 56,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
    letterSpacing: -0.82,
  },
  H2Medium: {
    fontSize: 36,
    lineHeight: 44,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
    letterSpacing: -0.68,
  },
  H3Medium: {
    fontSize: 32,
    lineHeight: 40,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
    letterSpacing: -0.6,
  },
  H4Medium: {
    fontSize: 28,
    lineHeight: 36,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
    letterSpacing: -0.52,
  },
  H5Medium: {
    fontSize: 24,
    lineHeight: 32,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
    letterSpacing: -0.45,
  },
  H6Medium: {
    fontSize: 20,
    lineHeight: 28,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
    letterSpacing: -0.38,
  },
  H7Medium: {
    fontSize: 18,
    lineHeight: 24,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
    letterSpacing: -0.3,
  },
  H1Bold: {
    fontSize: 44,
    lineHeight: 56,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Bold',
    }),
    letterSpacing: -0.82,
  },
  H2Bold: {
    fontSize: 36,
    lineHeight: 44,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Bold',
    }),
    letterSpacing: -0.68,
  },
  H3Bold: {
    fontSize: 32,
    lineHeight: 40,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'NotoSansKR-Bold',
    }),
    letterSpacing: -0.6,
  },
  H4Bold: {
    fontSize: 28,
    lineHeight: 36,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Bold',
    }),
    letterSpacing: -0.52,
  },
  H5Bold: {
    fontSize: 24,
    lineHeight: 32,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Bold',
    }),
    letterSpacing: -0.45,
  },
  H6Bold: {
    fontSize: 20,
    lineHeight: 28,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Bold',
    }),
    letterSpacing: -0.38,
  },
  H7Bold: {
    fontSize: 18,
    lineHeight: 24,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Bold',
    }),
    letterSpacing: -0.3,
  },

  txt2Xl: {
    fontSize: 28,
    lineHeight: 40,
    fontWeight: '400',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Regular',
    }),
    letterSpacing: -0.19,
  },
  txtXl: {
    fontSize: 24,
    lineHeight: 34,
    fontWeight: '400',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Regular',
    }),
    letterSpacing: -0.21,
    paddingBottom: 2,
  },
  txtLg: {
    fontSize: 20,
    lineHeight: 32,
    fontWeight: '400',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Regular',
    }),
    letterSpacing: -0.22,
  },
  txtMd: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '400',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Regular',
    }),
    letterSpacing: -0.26,
    paddingBottom: 1,
  },
  txtSm: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '400',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Regular',
    }),
    letterSpacing: -0.3,
  },
  txtXs: {
    fontSize: 12,
    lineHeight: 16,
    fontWeight: '400',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Regular',
    }),
    letterSpacing: -0.38,
  },
  txt2Xs: {
    fontSize: 11,
    lineHeight: 16,
    fontWeight: '400',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Regular',
    }),
    letterSpacing: -0.45,
  },
  txt3Xs: {
    fontSize: 10,
    lineHeight: 14,
    fontWeight: '400',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Regular',
    }),
    letterSpacing: -0.52,
  },
  txt2XlMedium: {
    fontSize: 28,
    lineHeight: 40,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
    letterSpacing: -0.19,
  },
  txtXlMedium: {
    fontSize: 24,
    lineHeight: 36,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
    letterSpacing: -0.21,
    paddingBottom: 2,
  },
  txtLgMedium: {
    fontSize: 20,
    lineHeight: 32,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
    letterSpacing: -0.22,
  },
  txtMdMedium: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
    letterSpacing: -0.26,
    paddingBottom: 1,
  },
  txtSmMedium: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
    letterSpacing: -0.3,
  },
  txtXsMedium: {
    fontSize: 12,
    lineHeight: 16,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
    letterSpacing: -0.38,
  },
  txt2XsMedium: {
    fontSize: 11,
    lineHeight: 16,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
    letterSpacing: -0.45,
  },
  txt3XsMedium: {
    fontSize: 10,
    lineHeight: 14,
    fontWeight: '500',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Medium',
    }),
    letterSpacing: -0.52,
  },
  txt2XlBold: {
    fontSize: 28,
    lineHeight: 40,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Bold',
    }),
    letterSpacing: -0.19,
  },
  txtXlBold: {
    fontSize: 24,
    lineHeight: 36,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Bold',
    }),
    letterSpacing: -0.21,
    paddingBottom: 2,
  },
  txtLgBold: {
    fontSize: 20,
    lineHeight: 32,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Bold',
    }),
    letterSpacing: -0.22,
  },
  txtMdBold: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Bold',
    }),
    letterSpacing: -0.26,
    paddingBottom: 1,
  },
  txtSmBold: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Bold',
    }),
    letterSpacing: -0.3,
  },
  txtXsBold: {
    fontSize: 12,
    lineHeight: 16,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Bold',
    }),
    letterSpacing: -0.38,
  },
  txt2XsBold: {
    fontSize: 11,
    lineHeight: 16,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Bold',
    }),
    letterSpacing: -0.45,
  },
  txt3XsBold: {
    fontSize: 10,
    lineHeight: 14,
    fontWeight: '700',
    fontFamily: Platform.select({
      web: 'Pretendard',
      native: 'Pretendard-Bold',
    }),
    letterSpacing: -0.52,
  },
})
