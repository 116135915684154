import { Text } from '@rocket-atoms/text'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import Button from '../Button'

interface Props {
  email: string
  whenDone: () => void
}

export const RecoveryDone: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  // const buttonText =
  //   t(`widget.login.returnto.prefix`) +
  //   t(`widget.login.title.login`) +
  //   t(`widget.login.returnto.suffix`)
  return (
    <>
      <View style={style.inputWrap}>
        <Text fontName="subTitleBold" style={[style.textCenter]}>
          {t(`findpasswordmodal.sendtemppassword`)}
        </Text>
        <View style={{ height: 8 }} />
        <Text fontName="listBold" fontColor="main.blue" style={[style.textCenter]}>
          {props.email}
        </Text>
        <View style={{ height: 8 }} />
        <Text fontName="textRegular" style={[style.textCenter]}>
          {t(`findpasswordmodal.senddesc`)}
        </Text>
      </View>
      <View style={{ height: 24 }} />
      <View style={style.inputWrap}>
        <Button
          wide={true}
          onPress={() => {
            if (props.whenDone) {
              props.whenDone()
            }
          }}
        >
          {t('findpasswordmodal.loginback')}
        </Button>
      </View>
    </>
  )
}

const style = StyleSheet.create({
  inputWrap: {
    marginVertical: 5,
  },
  textCenter: {
    textAlign: 'center',
  },
})
