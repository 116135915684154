import { COLOR, FONT } from '@rocket-mono/libs'
import * as React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import CheckIcon from '../CheckIcon'

interface DropdownItemFilled {
  label: string
  children: React.ReactNode
  selected: boolean
  isCheck: boolean
  fontSize?: 'SM' | 'MD'
  paddingHorizontal?: number
  onChangeSelected?: (arg0: boolean) => void
}
const DropdownItemFilled = ({
  label,
  children,
  selected,
  isCheck,
  fontSize,
  paddingHorizontal,
  onChangeSelected,
}: DropdownItemFilled) => {
  const [isHover, setIsHover] = React.useState(false)

  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      onPress={() => onChangeSelected && onChangeSelected(!selected)}
      style={[
        styles.container,
        selected && { backgroundColor: COLOR.primary.blue500 },
        !!paddingHorizontal && { paddingHorizontal },
        !selected && isHover && { backgroundColor: COLOR.gray.g050 },
      ]}
    >
      <View style={styles.inner}>
        {children}
        <Text
          style={[
            styles.labelText,
            fontSize ? { ...FONT.txtMd } : { ...FONT.txtSm },
            selected && { color: COLOR.mono.white },
            !!children && { marginLeft: 8 },
          ]}
          numberOfLines={1}
        >
          {label}
        </Text>
      </View>
      {isCheck && (
        <View>
          <CheckIcon
            variant={'circleSmall'}
            checked={selected}
            iconColor={selected ? 'mono.white' : 'transparent.base'}
          />
        </View>
      )}
    </Pressable>
  )
}

export default DropdownItemFilled

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 36,
    padding: 8,
  },
  inner: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelText: {
    color: COLOR.gray.g800,
  },
})
