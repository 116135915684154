import * as React from 'react'

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Pressable, StyleSheet, Text } from 'react-native'
import { SelectListType } from '.'
import { FAIcon } from '@rocket-atoms/icon'
import { COLOR, FONT } from '@rocket-mono/libs'

interface Props {
  item: SelectListType
  itemCode: string
  onPressButton: (code: string) => void
  setIsOpen: (arg0: boolean) => void
}

const LanguageSelectItem = ({ item, itemCode, onPressButton, setIsOpen }: Props) => {
  const [isHover, setIsHover] = React.useState(false)
  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      onPress={() => {
        onPressButton(item.itemCode)
        setIsOpen(false)
      }}
      style={[styles.wrap, isHover && { backgroundColor: COLOR.gray.g050 }]}
    >
      <Text style={[styles.labelStyle, itemCode === item.itemCode && { color: COLOR.primary.blue500 }]}>
        {item.label}
      </Text>
      <FAIcon
        iconName={item.afterIconName ? item.afterIconName : faCheck}
        size={'xs'}
        color={itemCode === item.itemCode ? 'primary.blue500' : 'mono.white'}
      />
    </Pressable>
  )
}

export default LanguageSelectItem

const styles = StyleSheet.create({
  wrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 36,
    paddingHorizontal: 16,
  },
  labelStyle: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g800,
  },
})
