import * as React from 'react'

import { SubscribeType } from '@rocket-mono/libs'
import { File, User } from '@rocket/types'
import Provider from './provider'
import type { AstroType, FileLoad, Preview } from './types'
import View from './view'

interface Props {
  isAnonymous: boolean
  onAnonymousPress: () => void
  astro: AstroType
  subscribe: SubscribeType
  relatedDomain?: string
  relatedDomainId?: string
  channelId: string
  projectId?: number
  boardRoomId?: string
  currentUser: User
  onCardLinkPress?: (cardNo: string, cardName: string, channelNo: string) => void
  onMentionPress?: (memberNo: string, memberName: string) => void
  onLinkPress?: (url: string, text?: string) => void
  fileLoad?: FileLoad
  onFileLoad?: (todoId: string) => void
  clearFileLoad?: () => void
  onPreview?: (previewList: Preview[], file: File) => void
  onPressDownload?: (file: File) => void
  onPressFileOpen?: (file: File) => void
  isExists?: (file: File) => Promise<boolean>
  onPressTodo?: (id: string) => void
}

export const CardTodo: React.FC<Props> = ({
  isAnonymous,
  onAnonymousPress,
  astro,
  subscribe,
  relatedDomain,
  relatedDomainId,
  channelId,
  projectId,
  boardRoomId,
  currentUser,
  onCardLinkPress,
  onMentionPress,
  onLinkPress,
  fileLoad,
  onFileLoad,
  onPreview,
  onPressDownload,
  onPressFileOpen,
  isExists,
  onPressTodo,
}) => {
  return (
    <Provider
      astro={astro}
      subscribe={subscribe}
      channelId={channelId}
      relatedDomain={relatedDomain}
      relatedDomainId={relatedDomainId}
      projectId={projectId}
      boardRoomId={boardRoomId}
      currentUser={currentUser}
      fileLoad={fileLoad}
      onFileLoad={onFileLoad}
      onPreview={onPreview}
      onPressDownload={onPressDownload}
      onPressFileOpen={onPressFileOpen}
      isExists={isExists}
    >
      <View
        isAnonymous={isAnonymous}
        onAnonymousPress={onAnonymousPress}
        onCardLinkPress={onCardLinkPress}
        onMentionPress={onMentionPress}
        onLinkPress={onLinkPress}
        onPressTodo={onPressTodo}
      />
    </Provider>
  )
}

export { AstroType as CardTodoAstroType, FileLoad as CardTodoFileLoad, Preview as CardTodoPreview }
