import React from 'react'
import { GestureResponderEvent, Pressable } from 'react-native'

interface Props {
  onShow: () => void
  onPress: (e: GestureResponderEvent) => void
  onLayout: () => void
  children?: React.ReactNode
}

const TargetDetector: React.FC<Props> = ({ onShow, onPress, onLayout, children }) => {
  const handleShow = () => {
    onLayout()
    onShow()
  }
  return (
    <Pressable onLongPress={handleShow} onPress={onPress} onHoverIn={handleShow}>
      <>{children}</>
    </Pressable>
  )
}

export default TargetDetector
