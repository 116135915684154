import { Icon } from '@rocket-atoms/icon'
import { View } from '@rocket-mono/foundations'
import { COLOR, IColors, IconName, useMobileView } from '@rocket-mono/libs'
import { AssigneeIdType, CardDateType, useAstro, useCurrentUser, useWorkChannel } from '@rocket-mono/providers'
import { CardType } from '@rocket/types'
import React, { ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutChangeEvent, NativeScrollEvent, NativeSyntheticEvent, Platform, StyleSheet } from 'react-native'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  discussChannelIdState,
  discussSelectIndexState,
  replyDiscussMessageState,
  replyDiscussSelectedIndexState,
  updateDiscussMessageState,
} from '../../recoil'
import CardAssignee from '../CardAssignee'
import CardDateInput from '../CardDateInput'
import { ChatInput } from '../ChatInput'
import DiscussEdit from '../DiscussEdit'
import Dropdown from '../Dropdown'
import WorkCardCalendarView from '../WorkCardCalendarView'
import WorkCardOptionList, { WorkCardEditBottomOptionType } from '../WorkCardOptionList'
import { DynamicScrollView } from './components/DynamicScrollView'
import InputTitle from './components/InputTitle'

export type DropdownItemType = {
  labelIcon?: { name: IconName; size: number; color: IColors }
  label: string
  key: string
}

interface Props {
  edit: boolean
  isTime: boolean
  cardDate: CardDateType
  onChangeDate?: (cardDate: CardDateType) => void
  iconList?: WorkCardEditBottomOptionType[]
  cardType?: string | CardType
  onChangeType?: (arg0: DropdownItemType) => void
  dataIcon: DropdownItemType[]
  title: string
  assigneeIds?: AssigneeIdType[]
  onChangeTitle?: (arg0: string) => void
  onChangeAssigneeIds?: (ids: AssigneeIdType[]) => void
  children?: React.ReactNode
  wrapWidth: number
  sideHeader?: ReactNode
  sideView?: ReactNode
  discussionView?: ReactNode
  onScroll?: (e: NativeSyntheticEvent<NativeScrollEvent>) => void
  onDiscussViewScroll?: (e: NativeSyntheticEvent<NativeScrollEvent>) => void
}

export const WorkCardLayoutBody: React.FC<Props> = ({
  edit,
  isTime,
  cardDate,
  onChangeDate,
  iconList,
  cardType,
  onChangeType,
  dataIcon,
  title,
  assigneeIds,
  onChangeTitle,
  onChangeAssigneeIds,
  children,
  wrapWidth,
  sideHeader,
  sideView,
  discussionView,
  onScroll,
  onDiscussViewScroll,
}) => {
  const { t } = useTranslation()
  const chatInputRef = useRef(null)
  const blockRow = useMemo(() => {
    if (edit) return true

    return wrapWidth <= 800
  }, [edit, wrapWidth])

  const mobileView = useMobileView(500)
  const { astro, option } = useAstro()

  const { currentUser } = useCurrentUser()
  const { currentChannelMembers } = useWorkChannel()

  const discussChannelId = useRecoilValue(discussChannelIdState)
  const setDiscussSelectIndex = useSetRecoilState(discussSelectIndexState)
  const setReplyDiscussSelectIndex = useSetRecoilState(replyDiscussSelectedIndexState)
  const [updateDiscussMessage, setUpdateDiscussMessage] = useRecoilState(updateDiscussMessageState)
  const [replyDiscussMessage, setReplyDiscussMessage] = useRecoilState(replyDiscussMessageState)
  const onUpdateDiscuss = useCallback(
    (message: string) => {
      if (updateDiscussMessage && discussChannelId !== null)
        astro.updateCardDiscussionMessage(discussChannelId, String(updateDiscussMessage.no), { message }).then(() => {
          setUpdateDiscussMessage(null)
        })
    },
    [updateDiscussMessage, discussChannelId],
  )

  const [message, setMessage] = useState('')

  const sendMessage = useCallback(() => {
    if (message.trim().length === 0) {
      return
    }

    const formatted = astro.formatMention(
      message,
      currentChannelMembers
        .map((m) => ({
          displayName: m.name ?? '',
          username: m.name ?? '',
          channelMember: {
            userId: `${m.userId ?? 0}`,
          },
        }))
        .filter((o) => o.channelMember.userId !== `${currentUser?.id}`),
    )

    const messageKey = new Date().getTime() + '-' + replyDiscussMessage?.channelNo
    const targetNo = replyDiscussMessage?.recipient ? String(replyDiscussMessage.recipient.no) : ''
    astro
      .createChannelMessage(
        replyDiscussMessage?.roomId || '',
        String(currentUser?.id ?? ''),
        currentUser?.userName,
        formatted,
        messageKey,
        targetNo,
        replyDiscussMessage?.no,
      )
      .then(() => {
        setReplyDiscussMessage(null)
        setDiscussSelectIndex(null)
        setReplyDiscussSelectIndex(undefined)
        setMessage('')
      })
      .catch(() => {
        setReplyDiscussMessage(null)
        setDiscussSelectIndex(null)
        setReplyDiscussSelectIndex(undefined)
        setMessage('')
      })
  }, [replyDiscussMessage, currentUser, message, currentChannelMembers])

  const [contentsWidth, setContentsWidth] = useState<number>()
  const onLayout = useCallback(
    (e: LayoutChangeEvent) => {
      const { layout } = e.nativeEvent
      setContentsWidth(layout.width)
      console.log(contentsWidth)
    },
    [wrapWidth],
  )

  // console.log('assigneeIds', assigneeIds)

  // if (contentsWidth === undefined) return null
  return (
    <DynamicScrollView containerStyle={styles.container} onScroll={onScroll} scrollEnabled={blockRow}>
      <View
        style={[
          styles.container,
          blockRow && {
            ...Platform.select({
              web: {
                flexDirection: 'column',
              },
            }),
          },
        ]}
      >
        <DynamicScrollView
          onLayout={onLayout}
          containerStyle={[
            styles.cloumnPanel,
            !blockRow && {
              ...Platform.select({
                web: {
                  flex: 1,
                },
              }),
            },
          ]}
          scrollEnabled={Platform.OS !== 'web'}
        >
          <DynamicScrollView
            scrollEnabled={!blockRow}
            containerStyle={[
              styles.contentsArea,
              {
                ...Platform.select({
                  web: {
                    maxWidth: 980,
                  },
                }),
              },
              { alignSelf: 'center' },
              {
                ...Platform.select({
                  web: {
                    paddingRight: edit ? (mobileView ? 20 : 64) : 20,
                    width: contentsWidth ?? '100%',
                  },
                }),
              },
            ]}
          >
            <View style={{ width: '100%' }}>
              {cardType !== 'TODO' && (
                <>
                  <View>
                    {edit ? (
                      <View style={styles.labelDropdown}>
                        <Dropdown
                          readonly={onChangeType === undefined}
                          label={'Select Item'}
                          list={dataIcon}
                          currentIndex={dataIcon.findIndex(({ key }) => key === cardType)}
                          onSelect={onChangeType}
                          listType={'line'}
                          buttonWidth={Platform.OS === 'ios' || Platform.OS === 'android' ? 200 : undefined}
                          divide
                        />
                      </View>
                    ) : (
                      <></>
                      // cardType && (
                      //   <View style={styles.labelArea}>
                      //     <CardTypeIcon type={cardType} size={28} />
                      //   </View>
                      // )
                    )}
                    {edit && (
                      <View>
                        <InputTitle value={title} placeholder={t('card.title.placeholder')} setTitle={onChangeTitle} />
                      </View>
                    )}
                    {isTime ? (
                      edit ? (
                        <CardDateInput
                          cardType={cardType}
                          readonly={false}
                          date={cardDate}
                          onChangeDate={onChangeDate}
                        />
                      ) : cardType && ['MEETING', 'ATTEND'].includes(cardType) ? (
                        <WorkCardCalendarView date={cardDate} />
                      ) : (
                        <CardDateInput cardType={cardType} readonly={true} date={cardDate} />
                      )
                    ) : (
                      <></>
                    )}

                    {((!edit && assigneeIds && assigneeIds.length > 0) || edit) && (
                      <CardAssignee
                        readonly={!edit}
                        assigneeIds={assigneeIds}
                        onChangeAssigneeIds={onChangeAssigneeIds}
                      />
                    )}
                  </View>
                  {(edit || (!edit && isTime)) && (
                    <View
                      style={{
                        width: '100%',
                        height: 1,
                        marginTop: 24,
                        marginBottom: 12,
                        backgroundColor: COLOR.gray.g150,
                      }}
                    />
                  )}
                </>
              )}
              {children}
            </View>
          </DynamicScrollView>
          <View style={[styles.onlyWeb, styles.option]}>
            {iconList && iconList.length > 0 && (
              <View style={styles.optionList}>
                <View style={styles.infoIcon}>
                  <Icon name={'plus-circle-o'} size={18} color={'gray.g300'} />
                </View>
                <View>
                  <View>
                    <WorkCardOptionList
                      iconList={iconList}
                      flexDirection={{ flexDirection: 'column' }}
                      gap={{ marginBottom: 4 }}
                    />
                  </View>
                </View>
              </View>
            )}
          </View>
        </DynamicScrollView>
        {!edit && (
          <View style={[styles.onlyWeb, styles.sideContents, blockRow && styles.sideContentColumn]}>
            {!blockRow && sideHeader}
            <DynamicScrollView scrollEnabled={!blockRow} onScroll={onDiscussViewScroll}>
              {!blockRow && sideView}
              {discussionView}
            </DynamicScrollView>

            <View
              style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
              }}
            >
              {updateDiscussMessage && (
                <DiscussEdit
                  text={updateDiscussMessage.message}
                  visible={updateDiscussMessage !== null}
                  onCancel={() => {
                    setUpdateDiscussMessage(null)
                    setDiscussSelectIndex(null)
                    setReplyDiscussSelectIndex(undefined)
                  }}
                  onSubmit={(text) => {
                    onUpdateDiscuss(text)
                    setDiscussSelectIndex(null)
                    setReplyDiscussSelectIndex(undefined)
                  }}
                />
              )}
              {replyDiscussMessage && (
                <ChatInput
                  inputRef={chatInputRef}
                  value={message}
                  reply={{
                    thumbnail: `${option.secureCdnUrl}/profile/${replyDiscussMessage.userEmail}`,
                    userName: replyDiscussMessage.userName,
                    text: replyDiscussMessage.message,
                    onPressCloseButton: () => {
                      setReplyDiscussMessage(null)
                      setDiscussSelectIndex(null)
                      setReplyDiscussSelectIndex(undefined)
                      setMessage('')
                    },
                  }}
                  writerNames={[]}
                  onChangeText={(msg) => {
                    setMessage(msg)
                  }}
                  onPressSendButton={sendMessage}
                  onPressInputEnterKey={sendMessage}
                />
              )}
            </View>
          </View>
        )}
      </View>
    </DynamicScrollView>
  )
}

const styles = StyleSheet.create({
  onlyWeb: {
    ...Platform.select({
      native: {
        display: 'none',
      },
    }),
  },
  container: {
    flex: 1,
    // height: '100%',
    flexDirection: 'row',
  },
  sideContents: {
    width: 340,
    borderLeftWidth: 1,
    borderColor: COLOR.gray.g200,
  },
  sideContentColumn: {
    width: '100%',
    borderLeftWidth: 0,
    borderTopWidth: 1,
  },
  cloumnPanel: {
    alignItems: 'center', //README: 크롬이 업데이트 되면서 컴포넌트가 가운데로 몰리는 현상이 있어서 임시로 주석처리
  },
  contentsArea: {
    flex: 1,
    padding: 20,
    width: '100%',
  },
  labelArea: { marginBottom: 8 },
  labelDropdown: {
    alignSelf: 'flex-start',
    marginBottom: 12,
  },
  option: { position: 'absolute', top: 12, right: 4 },
  optionList: {
    justifyContent: 'center',
    backgroundColor: COLOR.primary.blue05,
    borderRadius: 8,
    paddingHorizontal: 8,
    paddingTop: 4,
    paddingBottom: 12,
  },
  infoIcon: {
    width: '100%',
    height: 36,
    marginBottom: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g200,
  },
})
