import { Text } from '@rocket-atoms/text'
import type { IChatMessageReply } from '@rocket-mono/libs'
import { FONT, getRealColor } from '@rocket-mono/libs'
import moment from 'moment'
import 'moment/min/locales'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Dimensions,
  Image,
  ImageSourcePropType,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import Hyperlink from 'react-native-hyperlink'
import { MessageTextReplacer, ParserRule } from '../../../components/Message/types'
import type { AppList } from '../../AddPopup'
import { MENTION_TEMPLATE, parseMessage } from '../../utils/message'
import MoreButton from '../Components/MoreButton'
import type { ItemMoreButtonType } from '../types'
import { parseSymbolicMessage } from './helper'
import ReplyList from './reply'

interface Props {
  image: ImageSourcePropType
  userName: string
  mentionUserName?: string
  message: string
  messageSymbol?: string | null
  containerStyle?: ViewStyle
  replyContainerStyle?: ViewStyle
  moreList?: AppList[]
  regDate: string
  chatMessageReply?: IChatMessageReply[]
  onPopupState: (state: string) => void
  onPopupReplyState?: ({
    parentIndex,
    index,
    state,
    item,
  }: {
    parentIndex: number
    index: number
    state: string
    item?: IChatMessageReply
  }) => void
  deleted: boolean
  replySelectedIndex?: string
  selected: boolean
  onLinkPress?: (url: string, text?: string | undefined) => void
  topIndex: number
  onPressMoreButton?: (payload: ItemMoreButtonType) => void
}

const MsgItem = ({
  image,
  userName,
  mentionUserName,
  message: bodyText,
  messageSymbol,
  containerStyle,
  replyContainerStyle,
  moreList,
  regDate,
  chatMessageReply,
  onPopupReplyState,
  deleted,
  selected,
  replySelectedIndex,
  onLinkPress,
  topIndex,
  onPressMoreButton,
}: Props) => {
  const { i18n, t } = useTranslation()
  const isSymbol = !!messageSymbol
  const { key, args } = parseSymbolicMessage(messageSymbol)
  moment.locale(i18n.resolvedLanguage)

  const mentionReplacer: MessageTextReplacer = React.useCallback((source, alias, index) => {
    console.log('mentionReplacer', source, alias, index)
    const { chatRoomMemberNo: memberNo, memberName } = new RegExp(MENTION_TEMPLATE).exec(source)?.groups ?? {
      chatRoomMemberNo: '',
      memberName: '',
    }

    if (memberNo && memberName) {
      return (
        <TouchableOpacity
          key={`mention-${memberNo}-${alias}-${index}-activator`}
          onPress={() => {
            // NOOP
          }}
          style={[
            styles.mentionContainer,
            {
              ...Platform.select({
                native: {
                  marginTop: -2.5,
                },
              }),
            },
          ]}
        >
          <Text key={`mention-${memberNo}-${alias}-${index}`} fontName="chatTextBold" fontColor="primary.blue500">
            @{memberName}
          </Text>
        </TouchableOpacity>
      )
    } else {
      return <Text key={`token-${alias}-${index}`}>{source}</Text>
    }
  }, [])

  const getMessageParse = React.useCallback(
    (bodyText: string) => {
      console.log('getMessageParse', bodyText)
      const replacements: ParserRule[] = [
        {
          format: '<b>(.*?)<\\/b>',
          replacer: (source, timestamp, index) => (
            <Text key={`token-${timestamp}-${index}`} fontName="chatTextBold">
              {source.replace(/<b>(.*?)<\/b>/g, '$1')}
            </Text>
          ),
        },
        // NOTE: 사용자 멘션 마크업에 대한 처리
        // example: <m chatRoomMemberNo=20905, memberName="김 로켓">
      ]
      if (MENTION_TEMPLATE)
        replacements.push({
          format: MENTION_TEMPLATE,
          replacer: mentionReplacer,
        })
      return parseMessage(bodyText, replacements)
    },
    [MENTION_TEMPLATE, mentionReplacer],
  )

  const message = React.useMemo(() => {
    if (!bodyText) return ''
    const jsxElements: JSX.Element[] = getMessageParse(bodyText)
    return jsxElements.length > 0 ? jsxElements : bodyText
  }, [bodyText, getMessageParse])

  if (chatMessageReply && !deleted) {
    return (
      <View style={[styles.container]}>
        <View
          style={[
            styles.commentContainer,
            {
              backgroundColor: selected ? '#e4eef9' : getRealColor('mono.white'),
            },
            containerStyle,
          ]}
        >
          {/* image container */}
          <View style={styles.imageContainer}>
            <Image source={image} style={styles.image} />
          </View>
          <View style={styles.rightContainer}>
            <View style={styles.topContainer}>
              <View style={styles.usernameContainer}>
                <Text style={styles.userName}>{userName}</Text>
                <Text style={styles.time}>{t('format.date.LLL', { date: regDate })}</Text>
              </View>
              {moreList && moreList.length > 0 && (
                <View style={styles.moreButton}>
                  <MoreButton
                    onPress={(layoutPayload) => {
                      onPressMoreButton?.({
                        parentIndex: topIndex,
                        moreList,
                        layoutPayload,
                      })
                    }}
                  />
                </View>
              )}
            </View>
            <View style={styles.messageContainer}>
              <View style={{ flex: 1, width: '100%' }}>
                <Hyperlink
                  linkStyle={{
                    color: '#2980b9',
                    textDecorationLine: 'underline',
                    ...Platform.select({
                      web: {
                        cursor: 'pointer',
                      },
                    }),
                  }}
                  onPress={onLinkPress}
                >
                  <Text style={[styles.message, selected ? { ...FONT.textBold } : { ...FONT.textRegular }]}>
                    {message}
                  </Text>
                </Hyperlink>
              </View>
            </View>
          </View>
        </View>
        <ReplyList
          chatMessageReply={chatMessageReply}
          replySelectedIndex={replySelectedIndex}
          topIndex={topIndex ?? 0}
          onPopupReplyState={onPopupReplyState}
          replyContainerStyle={replyContainerStyle}
          onLinkPress={onLinkPress}
          onPressMoreButton={onPressMoreButton}
        />
      </View>
    )
  }

  return (
    <View
      style={[
        styles.container,
        containerStyle,
        {
          backgroundColor: selected ? '#e4eef9' : getRealColor('mono.white'),
        },
      ]}
    >
      <View style={styles.commentContainer}>
        {/* image container */}
        <View style={styles.imageContainer}>
          <Image source={image} style={styles.image} />
        </View>
        <View style={styles.rightContainer}>
          <View style={styles.topContainer}>
            <View style={styles.usernameContainer}>
              <Text style={styles.userName}>{userName}</Text>
              <Text style={styles.time}>{t('format.date.LLL', { date: regDate })}</Text>
            </View>
            {moreList && moreList.length > 0 && !deleted && (
              <View style={styles.moreButton}>
                <MoreButton
                  onPress={(layoutPayload) => {
                    onPressMoreButton?.({
                      parentIndex: topIndex,
                      moreList,
                      layoutPayload,
                    })
                  }}
                />
              </View>
            )}
          </View>
          <View style={styles.messageContainer}>
            <View style={{ flex: 1, width: '100%' }}>
              {isSymbol ? (
                <Text style={styles.message}>{t(key, args)}</Text>
              ) : (
                <Hyperlink
                  linkStyle={{
                    color: '#2980b9',
                    textDecorationLine: 'underline',
                    ...Platform.select({
                      web: {
                        cursor: 'pointer',
                      },
                    }),
                  }}
                  onPress={onLinkPress}
                >
                  <Text style={styles.message}>
                    {mentionUserName && <Text style={styles.mentionUserName}>{`@${mentionUserName}  `}</Text>}

                    <Text style={[selected ? { ...FONT.textBold } : { ...FONT.textRegular }]}>{message}</Text>
                  </Text>
                </Hyperlink>
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: getRealColor('mono.white'),
  },
  commentContainer: {
    flexDirection: 'row',
  },
  replyContainer: {
    marginTop: 10,
    paddingLeft: 10,
    flexDirection: 'row',
  },
  imageContainer: {
    marginRight: 10,
  },
  replyImageContainer: {
    flexDirection: 'row',
    marginRight: 10,
  },
  image: {
    width: 40,
    height: 40,
    borderRadius: 25,
  },
  replyIconContainer: {
    height: 28,
    justifyContent: 'center',
  },
  replyIcon: {
    marginRight: 5,
  },
  replyImage: {
    width: 28,
    height: 28,
    borderRadius: 25,
    alignSelf: 'flex-start',
  },
  rightContainer: {
    flex: 1,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  usernameContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: 19,
  },
  moreButton: {
    width: 35,
  },
  userName: {
    ...FONT.subTextBold,
    lineHeight: Platform.select({
      native: 19.24,
      default: 12.64,
    }),
    color: getRealColor('mono.black'),
    marginRight: 5,
  },
  time: {
    ...FONT.subTextThin,
    color: getRealColor('mono.darkGray'),
  },
  messageContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 3,
  },
  mentionUserName: {
    ...FONT.textRegular,
    color: getRealColor('main.blue'),
  },
  message: {
    ...FONT.textRegular,
    color: getRealColor('mono.paleBlack'),
    ...Platform.select({
      web: {
        width: '100%',
        wordBreak: 'break-all',
      },
      android: {
        width: Dimensions.get('window').width - 100,
      },
    }),
  },
  mentionContainer: {
    backgroundColor: 'transparent',
  },
})

export default MsgItem
