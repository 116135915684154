import React, { useEffect } from 'react'
import { useAstro } from '@rocket-mono/providers'

const NaverLoginScreen: React.FC = () => {
  const { astro } = useAstro()
  useEffect(() => {
    astro.naverCallback().then(() => {
      window.close()
    })
  }, [astro])

  return <></>
}

export default NaverLoginScreen
