import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Text } from '@rocket-atoms/text'
import { COLOR, getRealColor } from '@rocket-mono/libs'
import React from 'react'
import { Image, Pressable, StyleSheet, View } from 'react-native'
import FileIcon from './FileIcon'
import { ItemProps } from './types'

const ITEM_WIDTH = 52
const ITEM_HEIGHT = 52

const ChatPreviewFileItem: React.FC<ItemProps> = ({ url, mimeType = '', name, isFirst, isLast, onRemove }) => {
  const containerMarginStyle = React.useMemo(() => {
    if (isFirst) {
      return { marginLeft: 7, marginRight: 4 }
    } else if (isLast) {
      return { marginRight: 6 }
    } else {
      return { marginRight: 4 }
    }
  }, [isFirst, isLast])
  return (
    <View style={[styles.container, containerMarginStyle]}>
      {mimeType.includes('image') ? (
        <Image source={{ uri: url }} style={styles.image} resizeMode="cover" />
      ) : (
        <View style={styles.item}>
          <FileIcon mimeType={mimeType} />
          <View style={styles.nameContainer}>
            <Text fontName="txt2XsMedium" fontColor="gray.g700" numberOfLines={1}>
              {name}
            </Text>
          </View>
        </View>
      )}
      {onRemove ? (
        <Pressable
          // eslint-disable-next-line react-native/no-inline-styles
          style={[styles.removeContainer, { zIndex: 1 }]}
          onPress={onRemove}
        >
          <FontAwesomeIcon icon={faClose} size={18} color={getRealColor('mono.white')} />
        </Pressable>
      ) : (
        <></>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: ITEM_HEIGHT,
    width: ITEM_WIDTH,
  },
  image: {
    height: ITEM_HEIGHT,
    width: ITEM_WIDTH,
    borderColor: COLOR.gray.g150,
    borderWidth: 1,
    borderRadius: 4,
  },
  item: {
    height: ITEM_HEIGHT,
    width: ITEM_WIDTH,
    borderColor: COLOR.gray.g150,
    borderWidth: 1,
    borderRadius: 4,
  },
  nameContainer: {
    marginTop: -10,
    marginLeft: 4,
    marginRight: 3,
  },
  removeContainer: {
    position: 'absolute',
    top: 5,
    right: 5,
    height: 26,
    width: 26,
    borderRadius: 4,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default ChatPreviewFileItem
