import { useContext } from 'react'

import { PreviewContext } from '.'
import { ContextProps } from './types'

export function usePreview() {
  const context = useContext<ContextProps | undefined>(PreviewContext)

  if (context === undefined) throw new Error('There must be a PreviewProvider as Ancestor of all Astro Hooks and HOCs')

  return context
}
