import { Icon, Text } from '@rocket/atoms'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { RuleListItemType } from '../../RuleScreen/view/ListView'
import ActionView from './flowActionView'
import RuleView from './flowRuleView'

interface Props {
  item: RuleListItemType
}

const ListFlowItem: React.FC<Props> = ({ item }) => {
  const { triggers, action } = item
  return (
    <View style={styles.container}>
      <Text fontName="txtSmMedium" fontColor="gray.g800">
        {triggers?.name}
      </Text>
      <View style={styles.icon}>
        <Icon name="angle-right" size={12} color="gray.g300" />
      </View>
      <RuleView item={item} />
      <View style={styles.icon}>
        <Icon name="angle-right" size={12} color="gray.g300" />
      </View>
      <ActionView action={action} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  icon: {
    marginHorizontal: 4,
    marginVertical: 2,
  },
})

export default ListFlowItem
