import React, { useCallback, useState } from 'react'
import { View, LayoutChangeEvent } from 'react-native'

import { Outlet } from 'react-router-dom'

import Provider from './provider'
import Nav from '../base/nav'

const Layout: React.FC = () => {
  const [maxWidth, setMaxWidth] = useState(0)

  const handleLayout = useCallback((e: LayoutChangeEvent) => {
    setMaxWidth(e.nativeEvent.layout.width)
  }, [])
  return (
    <Provider maxWidth={maxWidth}>
      <Nav />
      <View onLayout={handleLayout} style={{ flex: 1 }}>
        <Outlet />
      </View>
    </Provider>
  )
}

export default Layout
