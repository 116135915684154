import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, useWindowDimensions } from 'react-native'

import { View } from '@rocket-mono/foundations'
import { COLOR } from '@rui/foundations'

import { Text } from '@rocket-atoms/text'
import { useAstro, useModalDialog, useWork } from '@rocket-mono/providers'
import { ProjectInvitation, ProjectInvitationAddon } from '@rocket/types'
import { useToast } from '@rui/atoms'
import { DragDropContext, DragStart, DragUpdate, DropResult, ResponderProvided } from 'react-beautiful-dnd-grid-support'
import { useSetRecoilState } from 'recoil'
import MemberInviteModal from './components/MemberInviteModal'
import { draggingIdState } from './recoil'
import { Props } from './types'
import {
  DROPABLE_CREATE_VIEW_ID,
  DROPABLE_FAVORITE_VIEW_ID,
  DROPABLE_GROUP_CARD_ID,
  DROPABLE_GROUP_VIEW_ID,
} from './utils'
import WorkspaceMainView from './view/WorkspaceMainView'
import WorkspaceTopView from './view/WorkspaceTopView'

type MemberInvitation = ProjectInvitation & {
  inviteInfoToken: string
  addon?: ProjectInvitationAddon
  projectTitle?: string
  requestUserUserName?: string
}

const WorkspaceView: React.FC<Props> = ({ onPressProject, onPressCreateWork }) => {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const setDraggingId = useSetRecoilState(draggingIdState)
  const {
    groupList,
    mergeProject,
    changeGroupList,
    createProjectPosition,
    updateProjectPositionList,
    deleteProjectPosition,
    addFavorite,
    changeFavoriteList,
    newMemberInviteModal,
    onNewMemberInviteModal,
  } = useWork()

  const handleDragStart = useCallback((start: DragStart, provided: ResponderProvided) => {
    console.debug('handleDragStart', { start, provided })

    setDraggingId(start.source.droppableId)
  }, [])

  const { astro } = useAstro()
  const { showDialogMessage } = useModalDialog()
  const { show: showToastMessage } = useToast()

  const handleDragEnd = useCallback(
    (result: DropResult, provided: ResponderProvided) => {
      console.debug('handleDragEnd', { result, provided })
      setDraggingId('')
      const { combine, source, destination, draggableId } = result

      if (destination === null && source.droppableId !== DROPABLE_CREATE_VIEW_ID) {
        const groupId = source.droppableId.replace(DROPABLE_GROUP_CARD_ID, '')
        deleteProjectPosition(groupId, draggableId)
      } else if (combine) {
        const projectId1 = combine.draggableId
        const projectId2 = draggableId
        mergeProject([projectId1, projectId2])
      } else {
        if (source.droppableId === DROPABLE_GROUP_VIEW_ID && destination?.droppableId === DROPABLE_GROUP_VIEW_ID) {
          changeGroupList(source.index, destination.index)
        } else if (
          source.droppableId === DROPABLE_FAVORITE_VIEW_ID &&
          destination?.droppableId === DROPABLE_FAVORITE_VIEW_ID
        ) {
          changeFavoriteList(source.index, destination.index)
        } else if (
          source.droppableId === DROPABLE_CREATE_VIEW_ID &&
          destination?.droppableId.startsWith(DROPABLE_GROUP_CARD_ID)
        ) {
          const groupId = destination.droppableId.replace(DROPABLE_GROUP_CARD_ID, '').split('-').pop()
          createProjectPosition(groupId, draggableId, destination.index)
        } else if (
          source.droppableId.startsWith(DROPABLE_GROUP_CARD_ID) &&
          destination?.droppableId.startsWith(DROPABLE_GROUP_CARD_ID)
        ) {
          const sourceGroup = source.droppableId.replace(DROPABLE_GROUP_CARD_ID, '').split('-')
          const destinationGroup = destination.droppableId.replace(DROPABLE_GROUP_CARD_ID, '').split('-')

          const sourceGroupId = sourceGroup.length > 1 ? sourceGroup[1] : sourceGroup[0]
          const destinationGroupId = destinationGroup.length > 1 ? destinationGroup[1] : destinationGroup[0]
          const sourceIndex = sourceGroup.length > 1 ? sourceGroup[0] * 4 + source.index : source.index
          const destinationIndex =
            destinationGroup.length > 1 ? destinationGroup[0] * 4 + destination.index : destination.index

          const from = { groupId: sourceGroupId, index: sourceIndex }
          const to = { groupId: destinationGroupId, index: destinationIndex }
          updateProjectPositionList(from, to)
        } else if (
          (source.droppableId.startsWith(DROPABLE_GROUP_CARD_ID) || source.droppableId === DROPABLE_CREATE_VIEW_ID) &&
          destination?.droppableId === DROPABLE_FAVORITE_VIEW_ID
        ) {
          addFavorite(draggableId).catch((e) => {
            const message =
              e.status === 400
                ? t('workspace.favoriteovertoast')
                : e.status === 409
                ? t('workspace.favoritealreadytoast')
                : t('workspace.favoritealerrortoast')
            showToastMessage({
              title: message,
              type: 'Danger',
              position: 'BOTTOM_CENTER',
            })
          })
        }
      }
    },
    [groupList, mergeProject, changeGroupList, updateProjectPositionList],
  )

  const handleDragUpdate = useCallback((update: DragUpdate, provided: ResponderProvided) => {
    console.debug('handleDragUpdate', { update, provided })
  }, [])

  useEffect(() => {
    const memberInvitation = localStorage.getItem('MemberInvitation')
    if (memberInvitation) {
      const $memberInvitation = JSON.parse(memberInvitation) as MemberInvitation

      showDialogMessage({
        title: t('workinvite.otheraccount.title'),
        messageRender: () => (
          <View style={styles.dialogBodyContainer}>
            <Text fontName="txtMdMedium">{$memberInvitation.projectTitle}</Text>
            <View style={{ height: 4 }} />
            <Text fontName="txtSm">{t('workinvite.otheraccount.message')}</Text>
          </View>
        ),
        list: [
          {
            name: t('workinvite.otheraccount.apply'),
            action: () => {
              astro.createJoinRequestByInvitedInfoToken($memberInvitation.inviteInfoToken).then((res) => {
                if (res) {
                  localStorage.removeItem('MemberInvitation')
                  showToastMessage({
                    title: t('workinvite.otheraccount.invitetoast'),
                    type: 'Success',
                    position: 'BOTTOM_CENTER',
                  })
                  setTimeout(() => {
                    onPressProject(res.projectId)
                  }, 2000)
                }
              })
            },
          },
        ],
        cancelText: t('workinvite.otheraccount.cancel'),
        onCancel: () => {
          localStorage.removeItem('MemberInvitation')
        },
      })
    }
  }, [])

  return (
    <div style={{ overflowY: 'scroll', height: '100%', backgroundColor: COLOR.darkmode.warm800 }}>
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragUpdate={handleDragUpdate}>
        <View style={styles.container}>
          <View style={[styles.subContainer, { width: '100%' }]}>
            <WorkspaceTopView onPressProject={onPressProject} onPressCreateWork={onPressCreateWork} />
            <WorkspaceMainView onPressProject={onPressProject} onPressCreateWork={onPressCreateWork} />
          </View>
        </View>
      </DragDropContext>
      {newMemberInviteModal ? (
        <MemberInviteModal
          projectId={newMemberInviteModal}
          onClose={() => {
            onNewMemberInviteModal && onNewMemberInviteModal(undefined)
          }}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default WorkspaceView

const styles = StyleSheet.create({
  container: {
    height: '100%',
    flex: 1,
    alignItems: 'center',
    backgroundColor: COLOR.darkmode.warm800,
  },
  subContainer: { maxWidth: 1920, paddingHorizontal: 20, paddingVertical: 30, backgroundColor: COLOR.darkmode.warm800 },
  dialogBodyContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
})
