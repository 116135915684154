import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FAIcon } from '@rocket-atoms/icon'
import * as React from 'react'
import { DimensionValue, Pressable, Text, View } from 'react-native'
import { styles } from './style'

interface Props {
  type?: 'DEFAULT' | 'SECONDARY' | 'QUIET'
  configuration?: 'DEFAULT' | 'ONE'
  size?: 'md' | 'sm'
  icon?: IconProp
  label?: string
  buttonWidth?: DimensionValue
  ifGroup?: boolean
  disabled?: boolean
  activated: boolean
  children?: React.ReactNode
  setActivated: (activated: boolean) => void
  setButtonStandard?: (arg0: { width: number; height: number }) => void
  isArrow?: boolean
  isButtonBackgroundColorWhite?: boolean
}

const DropdownButton: React.FC<Props> = ({
  type,
  configuration,
  size,
  icon,
  label,
  buttonWidth,
  ifGroup,
  disabled,
  activated,
  children,
  setActivated,
  setButtonStandard,
  isArrow = true,
  isButtonBackgroundColorWhite = false,
}) => {
  const [isHover, setIsHover] = React.useState(false)

  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      onPress={() => !disabled && setActivated(!activated)}
      style={[
        styles.buttonStyle,
        isHover && styles.buttonHoverStyle,
        activated && styles.buttonOpenStyle,
        ifGroup && styles.buttonIfGorupStyle,
        activated && configuration === 'ONE' && styles.configurationOneButton,
        type === 'SECONDARY' && styles.buttonTypeSecondaryStyle,
        type === 'SECONDARY' && isHover && styles.buttonTypeSecondaryHoverStyle,
        activated && type === 'SECONDARY' && styles.buttonTypeSecondaryOpenStyle,
        type === 'QUIET' && styles.buttonTypeQuietStyle,
        type === 'QUIET' && isHover && styles.buttonTypeQuietHoverStyle,
        activated && type === 'QUIET' && styles.buttonTypeQuietOpenStyle,
        size === 'sm' && styles.buttonSizeSm,
        disabled && styles.buttonDisabledStyle,
        buttonWidth ? { width: buttonWidth } : {},
        isButtonBackgroundColorWhite ? styles.buttonBackgroundColorWhite : {},
      ]}
      onLayout={(e) =>
        setButtonStandard &&
        setButtonStandard({
          width: e.nativeEvent.layout.width,
          height: e.nativeEvent.layout.height,
        })
      }
    >
      <View style={styles.inner}>
        {children ? (
          children
        ) : (
          <>
            {icon && (
              <View style={{ marginRight: 4 }}>
                <FAIcon iconName={icon} size={'xs'} color={'gray.g500'} />
              </View>
            )}
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={[styles.textStyle, size === 'sm' && styles.textSizeSm, disabled && styles.textDisabled]}
            >
              {label}
            </Text>
          </>
        )}
      </View>
      {isArrow ? (
        <View style={styles.arrowArea}>
          <FAIcon iconName={activated ? faAngleUp : faAngleDown} size="xs" nativeSize={11} color={disabled ? 'gray.g400' : 'gray.g800'} />
        </View>
      ) : (
        <></>
      )}
    </Pressable>
  )
}

export default DropdownButton
