import * as React from 'react'

import { removeItemAtIndex, replaceItemAtIndex } from '@rocket-mono/libs'

import type {
  Card,
  CardCollection,
  CardLinkEntry,
  CardShareType,
  FileCreation,
  SaveCardCollectionPayload,
  SaveCardCollectionPieceGroupPayload,
  SaveCardCollectionPiecePayload,
  SaveCardCollectionRegacyPayload,
} from '@rocket/types'
import { Todo } from '@rocket/types'
import { useAstro } from '../../AstroProvider'
import { useSubscription } from '../../StompProvider'
import storage from '../../storage'
import { useWorkChannel } from '../ChannelProvider'
import Context from './context'
import type {
  AssigneeIdType,
  CardCollectionPieceGroupType,
  CardCollectionType,
  ProviderProps,
  SaveSchedulePayload,
  SaveTodoPayload,
  WorkCardGathering,
  WorkCardType,
} from './types'

export interface CardEntryType extends CardLinkEntry {
  source: string
}

export const WorkCardProvider = ({ cardId, children, onUnauthorized, onDelete, fallback = <></> }: ProviderProps) => {
  const { astro } = useAstro()

  const { channelId, currentChannel, currentChannelMembers } = useWorkChannel()

  const [currentCard, setCurrentCard] = React.useState<WorkCardType | null>()
  const [workCardList, setWorkCardList] = React.useState<WorkCardGathering[]>()

  const [todoList, setTodoList] = React.useState<Todo[]>()

  const [cardEntryList, setCardEntryList] = React.useState<CardEntryType[]>([])
  const addCardEntry = React.useCallback((entry: CardEntryType) => {
    setCardEntryList((prev) => {
      const idx = prev.findIndex((o) => o.cardNo === entry.cardNo)

      return idx < 0 ? [...prev, entry] : prev
    })
  }, [])

  const cardEntries = React.useMemo(() => {
    if (!workCardList) return []
    console.log({ cardEntries: [...workCardList, ...cardEntryList] })
    return [
      ...workCardList
        .filter((card) => card.cardIsDel === 'N')
        .map((card) => ({
          cardNo: card.cardId,
          cardName: card.cardTitle,
          channelNo: card.channelId,
          source: card.cardType,
        })),
      ...cardEntryList,
    ]
  }, [workCardList, cardEntryList])

  const updateCardList = React.useCallback(
    (card: Card) => {
      console.log('updateCardList', currentCard, card.id, cardId, String(currentCard?.no) === cardId)
      if (card.id === cardId) {
        fetchCard(cardId)
      }
      setWorkCardList((prev) => {
        if (prev === undefined) return [card]
        const idx = prev.findIndex(({ id }) => id === card.id)
        return idx < 0 ? [...prev, card] : replaceItemAtIndex(prev, idx, card)
      })
    },
    [cardId],
  )

  const deleteCardList = React.useCallback((cardId: string) => {
    setWorkCardList((prev) => {
      if (prev === undefined) return prev
      const idx = prev.findIndex(({ id }) => id === cardId)
      return idx < 0 ? prev : removeItemAtIndex(prev, idx)
    })
  }, [])

  const saveTodo = React.useCallback(
    (cardPayload: SaveTodoPayload) =>
      Promise.resolve(
        cardPayload.id !== undefined ? astro.updateCardTodo(cardPayload) : astro.createCardTodo(cardPayload),
      ).then((card) => {
        console.log('saveTodo', card)
        updateCardList(card)
        return card.id
      }),
    [updateCardList],
  )
  const readTodo = React.useCallback(
    (cardId: string) => Promise.resolve(workCardList?.find(({ id }) => id === cardId) || null),
    [workCardList],
  )
  const saveSchedule = React.useCallback(
    (cardPayload: SaveSchedulePayload) =>
      Promise.resolve(
        cardPayload.id !== undefined ? astro.updateCardMetting(cardPayload) : astro.createCardMetting(cardPayload),
      ).then((card) => {
        updateCardList(card)
        return card.id
      }),
    [updateCardList],
  )
  const readSchedule = React.useCallback(
    (cardId: string) => Promise.resolve(workCardList?.find(({ id }) => id === cardId) || null),
    [],
  )

  const saveGatheringCard = React.useCallback(
    (cardPayload: SaveCardCollectionRegacyPayload): Promise<WorkCardGathering> => {
      return astro.createCardCollectionLegacy(cardPayload).then((card) => {
        updateCardList(card)
        return card
      })
    },
    [updateCardList],
  )

  const saveGathering = React.useCallback(
    async (
      cardId: string,
      gatheringPayload: Omit<SaveCardCollectionPayload, 'cardId'>,
      assigneeIds: AssigneeIdType[],
    ): Promise<CardCollectionType> => {
      const ids = gatheringPayload.title
        .split(' ')
        .filter((o) => o.startsWith('#'))
        .map((o) => o.slice(1).trim())

      const list = await Promise.all(
        ids.map((id) =>
          astro.readCard(id).then((card) => {
            const roomId = card.roomIds.split(',')[0]
            return astro.readChannel(roomId).then((channel) => {
              console.log('gatheringPayload', ids, channel)

              return { cardNo: String(card.no), cardName: card.title, channelNo: channel.id, source: card.type }
            })
          }),
        ),
      )

      //@ts-ignore
      let title = astro.formatCardLink(gatheringPayload.title, [...cardEntries, ...list])

      return new Promise<CardCollection>((resolve) => {
        if (gatheringPayload.id) astro.updateCardCollection({ ...gatheringPayload, title, cardId }).then(resolve)
        else astro.createCardCollection({ ...gatheringPayload, title, cardId }).then(resolve)
      })
        .then((o) => {
          return astro.readAssignee('card.collection', o.id).then((list) => {
            return Promise.all([
              astro.deleteAssignees(list),
              ...assigneeIds.map(({ userId, userEmail }) => {
                if (o.targetOption === 'PUBLIC') return true
                return astro.createAssignee({
                  relatedDomain: 'card.collection',
                  relatedDomainId: o.id,
                  userId: Number(userId),
                  userEmail,
                })
              }),
            ]).then((res) => {
              console.log('createAssignee', res)
              return o
            })
          })
        })
        .then((o) => {
          fetchCard(cardId)
          return o
        })
    },
    [currentChannelMembers, cardEntries],
  )

  const readGatheringPieces = React.useCallback((collectionId: string): Promise<CardCollectionPieceGroupType[]> => {
    return astro.readCardCollectionPieceListGroup(collectionId).then((groupList) => {
      return Promise.all(
        groupList.map((group) => {
          return astro
            .readCardCollectionPieceList(collectionId, group.id)
            .then((list) =>
              Promise.all(
                list.map((o) => {
                  return o.userId
                    ? astro
                        .readUser(String(o.userId))
                        .then(({ userName, userEmail }) => ({ ...o, userName, userEmail }))
                    : o
                }),
              ),
            )
            .then((pieces) => {
              console.log('pieces', pieces)

              return Promise.all(
                pieces.map((piece) => {
                  if (piece.type === 'FILE') {
                    return astro
                      .readFile('card.collection.piece', piece.id)
                      .then(async (files) => {
                        if (files.length === 0) return undefined
                        const file = files[0]
                        const url = await astro.readFilePresignedUrl(file.path || '')
                        const user = await astro.readUser(String(file.userId))
                        return { ...file, presignedUrl: url, user }
                      })
                      .then((file) => {
                        return file ? { ...piece, file } : piece
                      })
                  }
                  return Promise.resolve(piece)
                }),
              )
            })
            .then((list) => ({ ...group, list }))
        }),
      )
        .then((pieces) => {
          console.log('readGatheringPieces', groupList, pieces)
          return pieces
        })
        .catch(() => [])
    })
  }, [])

  const readGathering = React.useCallback(
    async (cardId: string): Promise<WorkCardGathering | null> => {
      let card = workCardList?.find(({ id }) => id === cardId)
      if (card === undefined) return Promise.reject(null)
      const gatherings = await astro
        .readCardCollectionList(cardId)
        .then((gatherings) => {
          return Promise.all(gatherings.map((o) => readGatheringPieces(o.id).then((pieces) => ({ ...o, pieces }))))
        })
        .then((gatherings) => {
          return Promise.all(
            gatherings.map((o) =>
              astro
                .readAssignee('card.collection', o.id)
                .then((list) =>
                  Promise.all(
                    list.map((o) => {
                      return o.userId
                        ? astro.readUser(String(o.userId)).then((user) => ({ ...o, userName: user.userName }))
                        : o
                    }),
                  ),
                )
                .then((assigneeList) => ({ ...o, assigneeList })),
            ),
          )
        })
        .catch(() => [])
      return Promise.resolve({ ...card, gatherings })
    },
    [workCardList],
  )

  const deleteGathering = React.useCallback((id: string) => {
    return astro.deleteCardCollection(id)
  }, [])

  const saveGatheringPieceGroup = React.useCallback(
    (payload: SaveCardCollectionPieceGroupPayload) => {
      if (payload.id === undefined)
        return astro.createCardCollectionPieceGroup(payload).then((piece) => {
          if (cardId) fetchCard(cardId)
          return piece
        })
      else
        return astro.updateCardCollectionPieceGroup(payload).then((piece) => {
          if (cardId) fetchCard(cardId)
          return piece
        })
    },
    [cardId],
  )

  const saveGatheringPiece = React.useCallback(
    (payload: SaveCardCollectionPiecePayload) => {
      if (payload.id === undefined) {
        const { collectionId, userId, type, groupId, fileFormData } = payload

        if (groupId) {
          return astro
            .createCardCollectionPiece({ ...payload, groupId, fileFormData: undefined })
            .then((piece) => {
              if (type === 'FILE' && fileFormData) {
                const params: FileCreation = {
                  relatedDomain: 'card.collection.piece',
                  relatedDomainId: piece.id,
                  projectId: Number(currentChannel?.projectId),
                  boardRoomId: currentChannel?.roomId,
                  userId: Number(userId),
                  fileFormData,
                }
                return astro.createFile(params).then((file) => ({ ...piece, file }))
              } else {
                return piece
              }
            })
            .then((piece) => {
              if (cardId) fetchCard(cardId)
              return piece
            })
        } else
          return astro.createCardCollectionPieceGroup({ collectionId, userId, type, isDone: true }).then((group) => {
            return astro
              .createCardCollectionPiece({ ...payload, groupId: group.id, fileFormData: undefined })
              .then((piece) => {
                if (type === 'FILE' && fileFormData) {
                  const params: FileCreation = {
                    relatedDomain: 'card.collection.piece',
                    relatedDomainId: piece.id,
                    projectId: Number(currentChannel?.projectId),
                    boardRoomId: currentChannel?.roomId,
                    userId: Number(userId),
                    fileFormData,
                  }
                  return astro.createFile(params).then((file) => ({ ...piece, file }))
                } else {
                  return piece
                }
              })
              .then((piece) => {
                if (cardId) fetchCard(cardId)
                return piece
              })
          })
      } else
        return astro
          .updateCardCollectionPiece({ ...payload, fileFormData: undefined })
          .then((piece) => {
            const { type, userId, fileFormData } = payload
            if (type === 'FILE' && fileFormData) {
              const params: FileCreation = {
                relatedDomain: 'card.collection.piece',
                relatedDomainId: piece.id,
                projectId: Number(currentChannel?.projectId),
                boardRoomId: currentChannel?.roomId,
                userId: Number(userId),
                fileFormData,
              }
              return astro.createFile(params).then((file) => ({ ...piece, file }))
            } else {
              return piece
            }
          })
          .then((piece) => {
            if (cardId) fetchCard(cardId)
            return piece
          })
    },
    [cardId, currentChannel],
  )

  const deleteGatheringPiece = React.useCallback(
    (collectionId: string, id: string) => {
      let fileId = ''
      currentCard?.gatherings?.forEach((gathering) => {
        if (gathering.id === collectionId) {
          gathering.pieces?.forEach((piece) => {
            piece.list?.forEach((o) => {
              if (o.id === id && o.type === 'FILE') fileId = o.file?.id || ''
            })
          })
        }
      })
      Promise.all([astro.deleteCardCollectionPiece(collectionId, id), astro.deleteFile(fileId)]).then(() => {
        if (cardId) fetchCard(cardId)
      })
    },
    [cardId, currentCard],
  )

  // 카드 캐시
  const cacheCard = React.useCallback((data) => {
    storage.save({ key: 'card-cache', data }).catch((res) => console.error('cacheCard', res))
  }, [])

  const readCacheCard = React.useCallback(() => {
    return storage.load({ key: 'card-cache' }).catch(() => null)
  }, [])
  const clearCacheCard = React.useCallback(() => {
    return storage.remove({ key: 'card-cache' })
  }, [])

  // 할일 캐시
  const saveCacheTodo = React.useCallback((data) => {
    return storage.save({ key: 'todo-cache', data }).catch((res) => console.error('cacheTodo', res))
  }, [])
  const readCacheTodo = React.useCallback(() => {
    return storage.load({ key: 'todo-cache' }).catch(() => null)
  }, [])
  const clearCacheTodo = React.useCallback(() => {
    return storage.remove({ key: 'todo-cache' })
  }, [])

  const fetchCard = React.useCallback(
    (cardId: string) => {
      astro
        .readCard(cardId, channelId)
        .then((legacyCard) => {
          console.log('fetchCard- legacyCard', legacyCard)
          if (legacyCard.type === 'COLLECTION') {
            return astro
              .readCardCollectionList(cardId)
              .then((gatherings) =>
                Promise.all(
                  gatherings.map((o) =>
                    readGatheringPieces(o.id).then((pieces) => ({
                      ...o,
                      pieces,
                    })),
                  ),
                ),
              )
              .then((gatherings) => {
                return Promise.all(
                  gatherings.map((o) =>
                    astro
                      .readAssignee('card.collection', o.id)
                      .then((list) =>
                        Promise.all(
                          list.map((o) => {
                            return o.userId
                              ? astro.readUser(String(o.userId)).then((user) => ({ ...o, userName: user.userName }))
                              : o
                          }),
                        ),
                      )
                      .then((assigneeList) => ({ ...o, assigneeList })),
                  ),
                )
              })
              .then((gatherings) => ({ ...legacyCard, gatherings }))
              .catch(() => ({ ...legacyCard, gatherings: [] }))
          } else {
            if (legacyCard.type === 'MISSION') {
              getTodoList(legacyCard)
            }
            return Promise.resolve(legacyCard)
          }
        })
        .then((res) => {
          console.log('WorkCardProvider:fetchCard', res)
          setCurrentCard(res)
        })
        .catch((err) => {
          console.error('WorkCardProvider:fetchCard', err)
          if (err.status === 403) {
            onUnauthorized()
          }
        })
    },
    [channelId],
  )

  const updateCard = React.useCallback(
    (cardId: string, payload: { isPublic?: boolean; cardShareType?: CardShareType }) => {
      return astro.updateCard(cardId, payload).then(updateCardList)
    },
    [updateCardList],
  )

  const deleteCard = React.useCallback((cardId: string) => {
    return astro.deleteCard(cardId).then(() => deleteCardList(cardId))
  }, [])

  const unshareCard = React.useCallback((cardId: string, boardId) => {
    return astro.unshareCard({ cardId, boardId }).then(() => deleteCardList(cardId))
  }, [])

  const doneCard = React.useCallback((cardId: string, done: boolean) => {
    return new Promise<Card>((resolve) => {
      if (done) astro.doneCard(cardId).then(resolve)
      else astro.undoneCard(cardId).then(resolve)
    }).then((card) => {
      updateCardList(card)
    })
  }, [])

  React.useEffect(() => {
    astro
      .readCardList(channelId)
      .then(setWorkCardList)
      .catch(() => setWorkCardList([]))
  }, [channelId])

  React.useEffect(() => {
    if (cardId) {
      setCurrentCard(undefined)
      fetchCard(cardId)
    } else {
      setCurrentCard(null)
    }
  }, [cardId])

  React.useEffect(() => {
    console.log('CardProvider', channelId, cardId, currentCard)
  }, [currentCard])

  useSubscription([`/subscribe/card/${cardId}/update`, `/subscribe/card/${cardId}/update/`], () => {
    if (cardId) {
      fetchCard(cardId)
    }
  })

  useSubscription(`/subscribe/card/${cardId}/delete`, () => {
    if (cardId) {
      const duration = 1000
      // showToastMessage({
      //   message: t('cardtodoedit.toast.delete'),
      //   viewPosition: 'TOP_RIGHT',
      //   duration,
      // })
      setTimeout(() => onDelete(cardId), duration)
    }
  })

  useSubscription(`/subscribe/cards/${cardId}/unshared-from-board`, () => {
    if (cardId) deleteCardList(cardId)
  })

  const getTodoList = (card: WorkCardType) => {
    if (card) astro.readTodoList('card', String(card.no)).then(setTodoList)
  }

  useSubscription(`/subscribe/v2/card/${String(currentCard?.no ?? '')}/todo/updated`, ({ body }: any) => {
    console.debug('CardTodo subscribe', body)
    if (currentCard) getTodoList(currentCard)
  })

  if (currentCard === undefined) {
    return fallback
    // if (Platform.OS === 'web') {
    // if (location.pathname.endsWith('/board')) {
    //   return <SkeletonWorkCardNewTabView isModal />
    // } else {
    //   return <Skeleton path={location.pathname} />
    // }
    // } else {
    //   return <></>
    // }
  }

  return (
    <Context.Provider
      value={{
        cardId: currentCard ? String(currentCard.no) : undefined,
        currentCard,
        todoList,
        workCardList,
        cacheCard,
        readCacheCard,
        clearCacheCard,
        updateCard,
        deleteCard,
        unshareCard,
        doneCard,
        saveGatheringCard,
        saveGathering,
        readGathering,
        deleteGathering,
        saveGatheringPiece,
        saveGatheringPieceGroup,
        deleteGatheringPiece,
        saveTodo,
        readTodo,
        saveSchedule,
        readSchedule,
        saveCacheTodo,
        readCacheTodo,
        clearCacheTodo,
        addCardEntry,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export * from './hooks'
export type {
  AssigneeIdType,
  CardDateType,
  CardFormType,
  CardGatheringFormType,
  LocationType,
  WorkCardTodoParam,
  WorkCardTodoState,
} from './types'
