import * as React from 'react'

import View from './view'
import EditView from './edit'
import { EditProps, ViewProps } from './types'
const ViewScreen: React.FC<ViewProps> = (props) => {
  return <View {...props} />
}

const EditScreen: React.FC<EditProps> = (props) => {
  return <EditView {...props} />
}

const WorkCardScheduleScreen = Object.assign(ViewScreen, {
  Edit: EditScreen,
})

export { WorkCardScheduleScreen }
