import React from 'react'
import { Pressable, GestureResponderEvent } from 'react-native'
import type { Dimensions } from '../types'

interface Props {
  dimensions: Dimensions
  onHover?: () => void
  onPress?: null | ((e: GestureResponderEvent) => void)
}

const CopiedTarget: React.FC<Props> = ({ dimensions, onHover, onPress }) => {
  return (
    <Pressable
      onHoverIn={onHover}
      onPress={onPress}
      style={{
        position: 'absolute',
        top: dimensions.pageY,
        left: dimensions.pageX,
        width: dimensions.width,
        height: dimensions.height,
      }}
    />
  )
}

export default CopiedTarget
