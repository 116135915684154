import React from 'react'

import { FONT } from '@rocket-mono/libs'
import InputBase, { InputBaseProps } from './base'

import { TextInput } from '@rocket-mono/foundations'
import { getRealColor } from '@rocket-mono/libs'
import RichTextInput from '../RichTextInput/index.web'

interface Props extends Omit<InputBaseProps, 'inputRef'> {
  inputRef: React.RefObject<HTMLDivElement> | undefined
  riched?: boolean
  onKeyPress?: (e: any) => void
  onKeyUp?: (e: any) => void
  onKeyDown?: (e: any) => void
  onFocus?: (e: any) => void
  onBlur?: (e: any) => void
  onInput?: (e: any) => void
  onCompositionUpdate?: (e: any) => void
  onCompositionEnd?: (e: any) => void
  onEnterSubmit?: () => void
}

class Input extends InputBase<Props> {
  constructor(props: Props) {
    super(props)
  }

  focus() {
    //
  }
  renderInputComponent(props: Props) {
    const {
      riched = true,
      inputRef,
      color = 'mono.black',
      placeholder,
      placeholderTextColor = 'mono.gray',
      multiline,
      textContentType,
      onChangeText,
      editable = true,
      height = 40,
      onKeyPress,
      onKeyUp,
      onKeyDown,
      onFocus,
      onBlur,
      onInput,
      onCompositionUpdate,
      onCompositionEnd,
      onEnterSubmit,
    } = props
    const inputStyle: React.CSSProperties = {
      flex: 1,
      ...FONT.textRegular,
      paddingLeft: 12,
      // paddingVertical: 0,
      outlineWidth: 0,
      height,
      color: getRealColor(color),
    }

    return riched ? (
      <RichTextInput
        value={props.value}
        inputRef={inputRef}
        editable={editable}
        multiline={multiline}
        textContentType={textContentType}
        placeholder={placeholder}
        placeholderTextColor={getRealColor(placeholderTextColor)}
        onChangeText={onChangeText}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        onBlur={onBlur}
        onInput={onInput}
        onEnterSubmit={onEnterSubmit}
        onCompositionUpdate={onCompositionUpdate}
        onCompositionEnd={onCompositionEnd}
        style={inputStyle}
      />
    ) : (
      <TextInput
        style={{
          flex: 1,
          ...FONT.textRegular,
          paddingLeft: 12,
          // paddingVertical: 0,
          // outlineWidth: 0,
          height,
          color: getRealColor(color),
        }}
        value={props.value}
        // inputRef={inputRef}
        editable={editable}
        multiline={multiline}
        textContentType={textContentType}
        placeholder={placeholder}
        placeholderTextColor={getRealColor(placeholderTextColor)}
        onChangeText={onChangeText}
        onKeyPress={onKeyPress}
        // onKeyDown={onKeyDown}
        // onKeyUp={onKeyUp}
        onBlur={onBlur}
        onSubmitEditing={onEnterSubmit}
        // onInput={onInput}
        // onCompositionUpdate={onCompositionUpdate}
        // onCompositionEnd={onCompositionEnd}
      />
    )
  }
}

export default Input
