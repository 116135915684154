import * as React from 'react'
import { StyleProp, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native'

import { faCommentDots } from '@fortawesome/free-regular-svg-icons'
import { COLOR, FONT, IColors, getRealColor } from '@rocket-mono/libs'
import { BoardLabel, FAIcon, Tooltip } from '@rocket/atoms'
import type { Axis } from '../AddPopup'

interface Props {
  showLabel?: boolean
  boardName: string
  boardLabel?: IColors
  boardLabelMaxWidth?: number
  iconCount?: number
  iconOnPress: () => void
  containerStyle?: StyleProp<ViewStyle>
  onClickChatButton?: () => void
  renderExtraComponent?: () => JSX.Element
  onAxis?: (axis: Axis) => void
  onPressTitle?: () => void
}

const BoardHeader = ({
  showLabel = false,
  boardName,
  boardLabel,
  boardLabelMaxWidth,
  iconCount,
  iconOnPress,
  containerStyle,
  onClickChatButton,
  renderExtraComponent,
  onAxis,
  onPressTitle,
}: Props) => {
  const iconButtonRef = React.useRef<TouchableOpacity>(null)

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.innerLeftContainer}>
        {showLabel && boardLabel && (
          <TouchableOpacity
            style={styles.labelArea}
            ref={iconButtonRef}
            onPress={iconOnPress}
            onLayout={() => {
              if (onAxis) {
                iconButtonRef?.current?.measureInWindow((x, y) => {
                  onAxis({
                    x: x,
                    y: y,
                  })
                })
              }
            }}
          >
            <BoardLabel color={boardLabel} size={20} />
          </TouchableOpacity>
        )}
        <Tooltip darkType={true} text={boardName} direction="bottom" onPressTarget={onPressTitle}>
          {(props) => (
            <Text
              ellipsizeMode="tail"
              numberOfLines={1}
              style={[styles.boardName, { maxWidth: boardLabelMaxWidth }]}
              {...props}
            >
              {boardName}
            </Text>
          )}
        </Tooltip>
        {onClickChatButton && (
          <TouchableOpacity hitSlop={chatButtonHitSlop} style={styles.chatButton} onPress={onClickChatButton}>
            <FAIcon iconName={faCommentDots} size={'sm'} color={'gray.g600'} />
            {typeof iconCount !== 'undefined' && iconCount !== 0 && <View style={styles.iconCountContainer} />}
          </TouchableOpacity>
        )}
      </View>
      <View>{renderExtraComponent && renderExtraComponent()}</View>
    </View>
  )
}

export default BoardHeader

const chatButtonHitSlop = { top: 10, left: 10, right: 10, bottom: 10 }

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 15,
  },
  innerLeftContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    flex: 1,
    // ...Platform.select({ web: { maxWidth: 'max-content' } }),
  },
  labelArea: {
    marginRight: 4,
  },
  boardName: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g900,
  },
  chatButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 16,
    height: 16,
    marginLeft: 4,
    paddingBottom: 3,
  },
  iconCountContainer: {
    backgroundColor: getRealColor('main.red'),
    borderRadius: 15,
    width: 4,
    height: 4,
    position: 'absolute',
    top: -2,
    right: -2,
  },
})
