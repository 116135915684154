import React, { useEffect } from 'react'
import { StyleSheet, Pressable, Text } from 'react-native'
import { COLOR, FONT } from '@rocket-mono/libs'
import { SearchTextField } from '@rocket-atoms/text'
import { Combobox } from '@rui/molecules'
import { DropdownItemProps, DropdownList } from '@rocket-atoms/dropdown'
import { FAIcon } from '@rocket-atoms/icon'
import { faGear } from '@fortawesome/free-solid-svg-icons'

interface Props {
  labelList: DropdownItemProps[]
  defaultIndex?: number | number[]
  width?: number
  setLabeSelect?: (arg0: DropdownItemProps[]) => void
  onSelected?: (arg0: DropdownItemProps) => void
}

const SelectorLabel: React.FC<Props> = ({ labelList, defaultIndex, width, setLabeSelect, onSelected }) => {
  const [searchKeyword, setSearchKeyword] = React.useState('')
  const [selected, setSelected] = React.useState<DropdownItemProps[]>(
    defaultIndex !== undefined && typeof defaultIndex === 'number'
      ? [labelList[defaultIndex]]
      : labelList.filter((_, idx) => defaultIndex?.includes(idx)),
  )

  //외부에 값 담아주기
  useEffect(() => {
    setLabeSelect && setLabeSelect(selected)
  }, [selected])

  return (
    <Combobox visible={true} width={width}>
      <Combobox.Header style={{ padding: 8 }}>
        <SearchTextField
          value={searchKeyword}
          placeholder={'입력하세요'}
          onChangeText={setSearchKeyword}
          onPressSubmit={() => {
            console.log('검색')
          }}
          autoFocus={true}
        />
      </Combobox.Header>
      <Combobox.Body>
        <DropdownList
          list={labelList}
          configuration={'NONE'}
          itemType={'CHECKED'}
          itemVariant={'DEFAULT'}
          selected={selected}
          setSelected={setSelected}
          setActived={() => {
            console.log('test')
          }}
        />
      </Combobox.Body>
      <Combobox.Footer style={styles.footerArea}>
        <Pressable onPress={() => console.log('라벨 설정')} style={styles.footerButton}>
          <FAIcon iconName={faGear} size={'sm'} color={'gray.g400'} />
          <Text style={styles.labelText}>라벨 설정</Text>
        </Pressable>
      </Combobox.Footer>
    </Combobox>
  )
}

export default SelectorLabel

const styles = StyleSheet.create({
  footerArea: {
    borderTopWidth: 1,
    borderColor: COLOR.gray.g200,
  },
  footerButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 12,
  },
  labelText: {
    ...FONT.txtMd,
    marginLeft: 4,
  },
})
