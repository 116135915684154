import * as React from 'react'

import { AstroOption, ListPage, File as RFile, User } from '@rocket/types'
import { I18nextProviderProps, useTranslation } from 'react-i18next'
import { Platform } from 'react-native'
import { useAstro } from '../AstroProvider'
import { ChannelFile, ContextProps, PreviewFile, PreviewState } from './types'

const WIDTH = '900'
const HEIGHT = '750'

const MIME_TYPE = [
  'image/apng',
  'image/bmp',
  'image/gif',
  'image/vnd.microsoft.icon',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
  'image/x-icon',
  'image/x-png',
  'image/x-tiff',
  'image/x-windows-bmp',
  'application/pdf',
]

export const PreviewContext = React.createContext<ContextProps | undefined>(undefined)

interface Props {
  previewUrl: string
  children?: JSX.Element | JSX.Element[]
}

const getMobileCheck = () => {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || (window as any).opera)
  return check
}

const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const getRegDate = (date: string | Date, i18n: I18nextProviderProps['i18n']) => {
  if (typeof date === 'string') {
    return i18n.t('format.date.LLL', { date: new Date(date) })
  } else {
    return undefined
  }
}

const channelFileConvert = (
  i18n: I18nextProviderProps['i18n'],
  file: ChannelFile | undefined,
  option: AstroOption,
  workType: string,
  workName: string,
  boardName?: string,
) => {
  if (file === undefined) return undefined
  const uploaderName = file.user?.userName
  return {
    id: file.id,
    uri: file.presignedUrl,
    title: file.originalName,
    uploadDate: getRegDate(file.createdAt, i18n),
    uploaderName,
    uploaderProfile: `${option.secureCdnUrl}/profile/${file.user?.userEmail || undefined}`,
    mimeType: file.mimeType,
    workType,
    workName,
    boardName,
  }
}

export const PreviewProvider = ({ previewUrl, children }: Props) => {
  const { i18n } = useTranslation()
  const { astro, option } = useAstro()
  const [windowOpener, setWindowOpener] = React.useState<Window | null>(null)
  const [state, setState] = React.useState<PreviewState>()

  const extraData = React.useCallback(async (file: RFile): Promise<ChannelFile> => {
    let user: User | undefined = undefined
    let presignedUrl: string | undefined = undefined
    if (file.userId !== undefined) {
      user = await astro.readUser(String(file.userId))
    }
    if (file.path !== undefined) {
      try {
        presignedUrl = await astro.readFilePresignedUrl(file.path)
      } catch (error) {
        console.error('failed to get presigned url')
      }
    }

    return {
      ...file,
      user,
      presignedUrl,
    }
  }, [])

  React.useEffect(() => {
    const eventHandler = (event) => {
      const { action, payload } = event.data
      // console.log('PreviewProvider', action, payload)
      if (action === 'openPopupPreview') {
        const { file, channelRoomId, type, workType, workName, boardName } = payload
        const key = file.fileKey || file.relatedDomain
        const filename = file.filename || file.name
        const opener = window.open(
          `${previewUrl}/preview/${key}/${filename}`,
          `popupView-${getMobileCheck() ? uuid() : 'preview'}`,
          `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${WIDTH},height=${HEIGHT},left=100,top=100`,
        )
        setWindowOpener(opener)
        setState({ file, channelRoomId, type, page: 0, workType, workName, boardName })
      } else if (action === 'onLoad') {
        if (state && typeof state.page !== 'undefined' && state.type === 'channel' && state.channelRoomId) {
          astro
            .readFileByBoardPage(state.channelRoomId, undefined, state.page, 2, MIME_TYPE.join(','))
            .then((res: ListPage<RFile>) => {
              return Promise.all(res.content.map(extraData))
            })
            .then((res: ChannelFile[]) => {
              const fileList = res
                .filter((file) => file.mimeType && (file.mimeType.includes('image') || file.mimeType.includes('pdf')))
                .filter((file) => state.file && file.name !== state.file.name)
                .map((file) => channelFileConvert(i18n, file, option, state.workType, state.workName, state.boardName))
              const list =
                state.page === 0
                  ? [
                      channelFileConvert(i18n, state.file, option, state.workType, state.workName, state.boardName),
                      ...fileList,
                    ]
                  : fileList

              if (windowOpener === null) return
              windowOpener.window.postMessage({ action: 'openPreview', payload: { list } }, '*')
            })
        } else if (state && (state.type === 'contents' || state.type === 'card')) {
          if (windowOpener === null) return
          windowOpener.window.postMessage(
            { action: 'openPreview', payload: { list: state.fileList, index: state.fileListIndex } },
            '*',
          )
        }
      } else if (action === 'onNextSwipe') {
        if (state && state.page !== undefined && state.type === 'channel' && state.channelRoomId) {
          astro
            .readFileByBoardPage(state.channelRoomId, undefined, state.page + 1, 2, MIME_TYPE.join(','))
            .then((res: ListPage<RFile>) => {
              return Promise.all(res.content.map(extraData))
            })
            .then((res: ChannelFile[]) => {
              const fileList = res
                .filter((file) => file.mimeType && (file.mimeType.includes('image') || file.mimeType.includes('pdf')))
                .filter((file) => state.file && file.name !== state.file.name)
                .map((file) => channelFileConvert(i18n, file, option, state.workType, state.workName, state.boardName))
              const list = [...fileList]

              if (windowOpener === null) return
              windowOpener.window.postMessage({ action: 'openPreview', payload: { list } }, '*')
            })
          setState({ ...state, page: state.page + 1 })
        }
      }
    }
    if (Platform.OS === 'web') window.addEventListener('message', eventHandler)

    return () => {
      if (Platform.OS === 'web') window.removeEventListener('message', eventHandler)
    }
  }, [windowOpener, state])

  const openPreview = React.useCallback(
    (
      type: 'contents' | 'card',
      fileKey: string,
      filename: string,
      fileList: PreviewFile[],
      workType: string,
      workName: string,
      index: number,
    ) => {
      if (type === 'contents') {
        const opener = window.open(
          `${previewUrl}/preview/${fileKey}/${filename}`,
          `popupView-${getMobileCheck() ? uuid() : 'preview'}`,
          `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${WIDTH},height=${HEIGHT},left=100,top=100`,
        )
        setWindowOpener(opener)
        setState({
          type: 'contents',
          fileList,
          workName,
          workType,
          fileListIndex: index,
        })
      } else if (type === 'card') {
        const opener = window.open(
          `${previewUrl}/preview/${fileKey}/${filename}`,
          `popupView-${getMobileCheck() ? uuid() : 'preview'}`,
          `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${WIDTH},height=${HEIGHT},left=100,top=100`,
        )
        setWindowOpener(opener)
        setState({
          type: 'card',
          fileList,
          workName,
          workType,
          fileListIndex: index,
        })
      }
    },
    [],
  )

  // React.useEffect(() => {
  //   if (windowOpener === null) return
  //   const eventHandler = (event) => {
  //     const { action, payload } = event.data
  //     if (action === 'onLoad') {
  //       console.log('windowOpener onLoad', payload)
  //     }
  //   }
  //   windowOpener.addEventListener('message', eventHandler)
  //   return () => {
  //     windowOpener.removeEventListener('message', eventHandler)
  //   }
  // }, [windowOpener])

  return <PreviewContext.Provider value={{ openPreview }}>{children}</PreviewContext.Provider>
}
export * from './hooks'
