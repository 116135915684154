import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { faUser } from '@fortawesome/free-regular-svg-icons'
import { DropdownButton, DropdownItemProps, DropdownList } from '@rocket-atoms/dropdown'
import { FAIcon } from '@rocket-atoms/icon'
import { COLOR, ClickOutside, FONT } from '@rui/foundations'
import { CardType } from '@rocket/types'
import { useTranslation } from 'react-i18next'
import { DimensionValue, Platform, Pressable, StyleSheet, View } from 'react-native'
import { SearchCombobox } from './SearchCombobox'

interface Props {
  defaultIndex: number
  searchMemberList: DropdownItemProps[]
  isSearchMember: boolean
  onChangeCode?: (code: string) => void
  onChangeMember?: (codes: string[]) => void
}

const ActivitySearchSectionSortMessage: React.FC<Props> = ({
  defaultIndex,
  searchMemberList,
  isSearchMember,
  onChangeCode,
  onChangeMember,
}) => {
  const { t } = useTranslation()

  const listData = useMemo(
    () => [
      {
        beforeCardIcon: 'TOTAL' as CardType,
        label: t('workmember.table.auth.all'),
        code: 'ALL',
      },
      {
        beforeCardIcon: 'MESSAGE' as CardType,
        label: t('screen.chats.title'),
        code: 'MESSAGE',
      },
      {
        beforeCardIcon: 'DISCUSSION' as CardType,
        label: t('discussion.disc'),
        code: 'DISCUSS',
      },
    ],
    [t],
  )

  const [actived, setActived] = useState(false)
  const [memberIndex, setMemberIndex] = useState<number[]>([])
  const [buttonStandard, setButtonStandard] = useState<{ width: DimensionValue; height: number }>({
    width: 0,
    height: 0,
  })
  const [selected, setSelected] = useState<DropdownItemProps[]>([listData[defaultIndex]])

  const [isCombobox, setIsCombobox] = useState(false)

  const handlePressClear = useCallback(() => {
    setMemberIndex([])
    onChangeMember && onChangeMember([])
    setIsCombobox(false)
  }, [onChangeMember])

  const handlePressApply = useCallback(
    (codes) => {
      setMemberIndex(
        searchMemberList
          .map(({ code }, idx) => ({ code, idx }))
          .filter(({ code }) => codes.includes(code))
          .map(({ idx }) => idx),
      )
      onChangeMember && onChangeMember(codes)
      setIsCombobox(false)
    },
    [searchMemberList, onChangeMember],
  )

  useEffect(() => {
    if (selected.length > 0) onChangeCode && onChangeCode(selected[0].code)
  }, [selected, onChangeCode])

  return (
    <View style={styles.container}>
      <View style={styles.buttonArea}>
        <DropdownButton
          type={'DEFAULT'}
          configuration={'DEFAULT'}
          size={'md'}
          label={selected.length !== 0 ? selected[0].label : '초기 값'}
          buttonWidth={140}
          activated={actived}
          setActivated={setActived}
          setButtonStandard={setButtonStandard}
        />
        {actived && (
          <ClickOutside onClickOutside={() => setActived(false)}>
            <DropdownList
              list={listData}
              configuration={'DEFAULT-BORDER'}
              itemType={'DEFAULT'}
              itemVariant={'DEFAULT'}
              itemStyle={{ minWidth: 200, width: 200 }}
              selected={selected}
              setSelected={setSelected}
              buttonStandard={buttonStandard}
              setActived={setActived}
            />
          </ClickOutside>
        )}
      </View>
      {searchMemberList.length > 0 && (
        <View style={{ position: 'relative' }}>
          <Pressable
            onPress={() => setIsCombobox(true)}
            style={[
              {
                width: 36,
                height: 36,
                borderWidth: 1,
                borderRadius: 4,
                borderColor: COLOR.gray.g400,
                alignItems: 'center',
                justifyContent: 'center',
              },
              isSearchMember && { borderColor: COLOR.primary.blue500 },
            ]}
          >
            <FAIcon iconName={faUser} size={'sm'} color={isSearchMember ? 'primary.blue500' : 'gray.g600'} />
          </Pressable>
          {isCombobox && (
            <ClickOutside onClickOutside={() => setIsCombobox(false)}>
              <View
                style={{
                  position: 'absolute',
                  minWidth: 244,
                  top: Platform.OS === 'web' ? 40 : 8,
                  right: 0,
                  zIndex: 99,
                }}
              >
                <SearchCombobox
                  defaultIndex={memberIndex}
                  resultList={searchMemberList}
                  onPressClear={handlePressClear}
                  onPressApply={handlePressApply}
                />
              </View>
            </ClickOutside>
          )}
        </View>
      )}
    </View>
  )
}

export default ActivitySearchSectionSortMessage

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 20,
    marginBottom: 8,
  },
  buttonArea: {
    // flex: 1,
  },
  comboHeaderStyle: {
    padding: 8,
  },
  labelText: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g900,
    paddingBottom: 8,
  },
  comboFooterStyle: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 8,
    borderTopWidth: 1,
    borderColor: COLOR.gray.g200,
  },
})
