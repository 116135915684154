import React from 'react'
import Svg, { Path } from 'react-native-svg'

interface Props {
  color?: string
}

const ArchiveIcon: React.FC<Props> = ({ color = '#B4BEC5' }) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.38149 0.42857H2.53422C1.78161 0.42857 1.4053 0.42857 1.11991 0.579674C0.889609 0.701615 0.701268 0.889956 0.579327 1.12026C0.428223 1.40565 0.428223 1.78195 0.428223 2.53457V15.4654C0.428223 16.218 0.428223 16.5943 0.579327 16.8797C0.701268 17.11 0.889609 17.2984 1.11991 17.4203C1.4053 17.5714 1.78161 17.5714 2.53422 17.5714H5.38149V0.42857ZM6.52434 17.5714H11.4773V0.42857H6.52434V17.5714ZM12.6202 0.42857V17.5714H15.4651C16.2177 17.5714 16.594 17.5714 16.8794 17.4203C17.1097 17.2984 17.298 17.11 17.42 16.8797C17.5711 16.5943 17.5711 16.218 17.5711 15.4654V2.53457C17.5711 1.78196 17.5711 1.40565 17.42 1.12026C17.298 0.889956 17.1097 0.701615 16.8794 0.579674C16.594 0.42857 16.2177 0.42857 15.4651 0.42857H12.6202ZM3.85633 4.24153C3.85633 4.66232 3.51521 5.00344 3.09442 5.00344C2.67364 5.00344 2.33252 4.66232 2.33252 4.24153C2.33252 3.82075 2.67364 3.47963 3.09442 3.47963C3.51521 3.47963 3.85633 3.82075 3.85633 4.24153ZM8.99984 5.00344C9.42063 5.00344 9.76174 4.66232 9.76174 4.24153C9.76174 3.82075 9.42063 3.47963 8.99984 3.47963C8.57905 3.47963 8.23793 3.82075 8.23793 4.24153C8.23793 4.66232 8.57905 5.00344 8.99984 5.00344ZM15.6672 4.24153C15.6672 4.66232 15.326 5.00344 14.9053 5.00344C14.4845 5.00344 14.1433 4.66232 14.1433 4.24153C14.1433 3.82075 14.4845 3.47963 14.9053 3.47963C15.326 3.47963 15.6672 3.82075 15.6672 4.24153ZM4.23851 11.8627H1.57185V12.8151H4.23851V11.8627ZM7.66769 11.8627H10.3344V12.8151H7.66769V11.8627ZM16.4281 11.8627H13.7614V12.8151H16.4281V11.8627ZM1.57185 13.5745H4.23851V14.5269H1.57185V13.5745ZM10.3342 13.5745H7.66757V14.5269H10.3342V13.5745ZM13.7613 13.5745H16.428V14.5269H13.7613V13.5745Z"
      fill={color}
    />
  </Svg>
)
export default ArchiveIcon
