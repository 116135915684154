import { BackdropProvider, FoundationProvider, Modal, ModalBottom, ModalDialog, Toast } from '@rocket/components'
import { ToastProvider } from '@rui/atoms'
import * as React from 'react'
import i18nInstance from '../i18n'

interface Props {
  children?: JSX.Element
}

const ComponentsBaseProviders: React.FC<Props> = ({ children }) => {
  return (
    <FoundationProvider language={i18nInstance.language}>
      <BackdropProvider>
        <Modal.Provider>
          <ModalDialog.Provider>
            <Toast.Provider>
              <ToastProvider>
                <ModalBottom.Provider>
                  <>{children}</>
                </ModalBottom.Provider>
              </ToastProvider>
            </Toast.Provider>
          </ModalDialog.Provider>
        </Modal.Provider>
      </BackdropProvider>
    </FoundationProvider>
  )
}

export default ComponentsBaseProviders
