export function removeItemAtIndex<T>(arr: T[], index: number): T[] {
  return [...arr.slice(0, index), ...arr.slice(index + 1)]
}

export function replaceItemAtIndex<T>(arr: T[], index: number, newValue: T): T[] {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)]
}

export function changeItemAtIndex<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export function addItem<T>(list: T[] | undefined, item: T): T[] {
  if (list === undefined) return [item]
  return [...list, item]
}

export function addItemAtIndex<T>(list: T[], index: number, item: T): T[] {
  const result = Array.from(list)
  return [...result.slice(0, index), item, ...result.slice(index)]
}
