import { FONT, getRealColor } from '@rocket-mono/libs'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View, ViewStyle } from 'react-native'
import { parseSymbolicMessage } from './helper'

interface Props {
  message: string
  messageSymbol?: string | null
  containerStyle?: ViewStyle
}

const NoticeItem: React.FC<Props> = ({ message, messageSymbol, containerStyle }) => {
  const { t } = useTranslation()
  const isSymbol = !!messageSymbol
  const { key, args } = parseSymbolicMessage(messageSymbol)
  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.messageContainer}>
        {isSymbol && <Text style={styles.message}>{t(key, args)}</Text>}
        {!isSymbol && <Text style={styles.message}>{message}</Text>}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: getRealColor('mono.white'),
    justifyContent: 'center',
  },
  imageContainer: {
    marginRight: 10,
  },
  messageContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 3,
    backgroundColor: getRealColor('mono.pale'),
    paddingHorizontal: 14,
    paddingVertical: 7,
    borderRadius: 15,
  },
  message: {
    ...FONT.textMedium,
    fontSize: 14,
    color: getRealColor('mono.paleBlack'),
  },
})

export default NoticeItem
