import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { LayoutBase, CardLayout, ProjectLayout, WorkspaceLayout } from './layout'

import Home from './pages/Home'
import ProjectBoard from './pages/Project/Board'
import ProjectMember from './pages/Project/Member'
import ErrorPage from './error-page'
import { CardEdit, CardView } from './pages/Card'
import TableorderBoard from './pages/Tableorder/Board'
import TableorderMenu from './pages/Tableorder/Menu'
import TableorderOrderAdditional from './pages/Tableorder/OrderAdditional'
import TableorderOrderCustomer from './pages/Tableorder/OrderCustomer'
import WorkSettingScreen from './pages/Settings'
import CardUnAuthorized from './pages/Card/unauthorized'
import RootProviders, { AuthProviders, BaseProviders } from './providers'

// auth
import { NaverLoginScreen, SignOutScreen, SigninScreen, SignupScreen } from './pages/Auth'
import LayoutEmpty from './layout/base/empty'
import ComponentsBaseProviders from './providers/components-base'
import ChannelView from './pages/Channel'
import AuthExpiredScreen from './pages/Auth/expired'
import AuthExternalServerScreen from './pages/Auth/external'

import Error403 from './pages/Error/403'
import Error404 from './pages/Error/404'

const router = createBrowserRouter([
  {
    path: '',
    element: (
      <RootProviders>
        <LayoutBase />
      </RootProviders>
    ),
    errorElement: <Error404 />,
    children: [
      {
        path: '',
        element: <WorkspaceLayout />,
        children: [
          { index: true, element: <Home /> },
          {
            path: 'project',
            element: <ProjectLayout />,
            children: [
              { path: ':projectId/calendar', element: <ErrorPage /> },

              { path: ':projectId/user', element: <ProjectMember /> },
              { path: ':projectId/board', element: <ProjectBoard /> },
              { path: ':projectId/roadmap', element: <ErrorPage /> },

              { path: ':projectId/member', element: <ProjectMember /> },
              { path: ':projectId/pos', element: <TableorderBoard /> },
              { path: ':projectId/menu', element: <TableorderMenu /> },
            ],
          },
          {
            path: 'guest',
            element: <ProjectLayout />,
            children: [
              { path: 'calendar', element: <ErrorPage /> },
              { path: 'board', element: <ProjectBoard /> },
            ],
          },
        ],
      },
      {
        path: 'settings',
        element: <WorkSettingScreen />,
      },
      {
        path: 'card',
        element: <CardLayout />,
        children: [
          { path: ':cardId', element: <CardView /> },
          { path: 'edit', element: <CardEdit /> },
          { path: 'edit/:cardId', element: <CardEdit /> },
          { path: 'access/:cardId', element: <CardView /> },
          { path: 'unauthorized', element: <CardUnAuthorized /> },
        ],
      },
      {
        path: 'channel/:channelRoomId',
        element: <ChannelView />,
      },
    ],
  },
  {
    path: 'tableorder/:shopId/order',
    element: (
      <BaseProviders>
        <ComponentsBaseProviders>
          <LayoutEmpty />
        </ComponentsBaseProviders>
      </BaseProviders>
    ),
    children: [
      { path: ':tableId/additional', element: <TableorderOrderAdditional /> },
      { path: ':tableId/customer', element: <TableorderOrderCustomer /> },
    ],
  },
  {
    path: 'auth/login/naver',
    element: (
      <AuthProviders>
        <NaverLoginScreen />
      </AuthProviders>
    ),
  },
  {
    path: 'login',
    element: (
      <AuthProviders>
        <SigninScreen />
      </AuthProviders>
    ),
  },
  {
    path: 'logout',
    element: (
      <AuthProviders>
        <SignOutScreen />
      </AuthProviders>
    ),
  },
  {
    path: 'join',
    element: (
      <AuthProviders>
        <SignupScreen />
      </AuthProviders>
    ),
  },

  {
    path: 'error',
    children: [
      {
        path: 'card/unauthorized',
        element: (
          <BaseProviders>
            <CardUnAuthorized />
          </BaseProviders>
        ),
      },
      {
        path: '404',
        element: <Error404 />,
      },
      {
        path: '403',
        element: <Error403 />,
      },
      {
        path: 'expired',
        element: <AuthExpiredScreen />,
      },
      {
        path: 'external',
        element: <AuthExternalServerScreen />,
      },
    ],
  },
])

export default router
