import { Channel, ChannelMember } from '@rocket/types'
import React, { useEffect, useMemo, useState } from 'react'

import Context from './context'
import type { ProviderProps } from './types'

import { useAstro } from '../../AstroProvider'
import { useCurrentUser } from '../../CurrentUserProvider'

export const WorkChannelProvider: React.FC<ProviderProps> = ({
  channelId,
  children,
  fallback = <></>,
}: ProviderProps) => {
  const { astro } = useAstro()
  const { currentUser } = useCurrentUser()

  const [currentChannel, setCurrentChannel] = useState<Channel>()
  const [currentChannelMembers, setCurrentChannelMembers] = useState<ChannelMember[]>()

  const currentChannelMember = useMemo(
    () => currentChannelMembers?.find(({ userId }) => userId === String(currentUser.id)),
    [currentUser, currentChannelMembers],
  )

  useEffect(() => {
    if (channelId) {
      astro.readChannel(Number(channelId)).then(setCurrentChannel)
      astro
        .readChannelMemberList(channelId)
        .then(setCurrentChannelMembers)
        .catch(() => setCurrentChannelMembers([]))
    }
  }, [channelId])

  useEffect(() => {
    console.debug('provider-WorkChannelProvider', { channelId, currentChannel, currentChannelMembers })
  }, [currentChannel, currentChannelMembers])

  if (currentChannel === undefined || currentChannelMembers === undefined) {
    return fallback
    // if (Platform.OS === 'web') {
    // if (location.pathname.endsWith('/board')) {
    //   return <SkeletonWorkCardNewTabView isModal />
    // } else {
    //   return <Skeleton path={location.pathname} />
    // }
    // } else {
    //   return <></>
    // }
  }

  return (
    <Context.Provider value={{ channelId, currentChannel, currentChannelMember, currentChannelMembers }}>
      {children}
    </Context.Provider>
  )
}

export * from './hooks'
