import * as React from 'react'
import { Defs, LinearGradient, Stop, Path, Svg } from 'react-native-svg'
import type { WorkIconProps } from './types'

export const WorkIconTOP: React.FC<WorkIconProps> = ({ size = 359 }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 359 359">
      <Path
        d="M0 80C0 35.8172 35.8172 0 80 0H279C323.183 0 359 35.8172 359 80V279C359 323.183 323.183 359 279 359H80C35.8172 359 0 323.183 0 279V80Z"
        fill="#6258BA"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M274.909 95.33C276.121 96.46 276.187 98.3583 275.057 99.5701L242.029 134.988C240.522 136.604 240.611 139.135 242.226 140.642L244.077 142.367C245.692 143.874 248.223 143.785 249.73 142.17L282.758 106.751C283.888 105.54 285.786 105.473 286.998 106.603L291.997 111.265C293.15 112.34 293.274 114.124 292.282 115.348L258.896 156.535C249.52 168.103 233.429 170.791 221.015 163.875L123.256 276.327C117.595 282.839 107.663 283.359 101.353 277.475C95.0374 271.586 94.8688 261.632 100.981 255.533L206.392 150.341C198.429 138.416 199.929 122.011 210.896 111.774L249.654 75.5972C250.806 74.5217 252.594 74.5212 253.747 75.5962L258.877 80.3804C260.089 81.5104 260.155 83.4087 259.025 84.6204L226.48 119.522C224.973 121.137 225.061 123.668 226.677 125.175L228.527 126.9C230.143 128.407 232.674 128.319 234.181 126.703L266.727 91.8019C267.857 90.5901 269.755 90.5238 270.967 91.6538L274.909 95.33Z"
        fill="url(#paint0_linear_2288_117240)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M155.228 150.76C165.2 136.564 165.304 118.262 150.316 102.19C132.598 83.1899 100.498 74.5005 84.6646 89.2655C68.831 104.031 75.26 136.659 92.978 155.659C107.775 171.527 125.766 172.872 140.519 164.284L237.78 276.163C243.441 282.675 253.373 283.195 259.683 277.311C265.999 271.422 266.167 261.468 260.055 255.369L155.228 150.76Z"
        fill="white"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_2288_117240"
          x1={274.954}
          y1={92.4272}
          x2={101.91}
          y2={277.995}
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset={0.380208} stopColor="white" />
          <Stop offset={0.541676} stopColor="#6258BA" />
          <Stop offset={0.6875} stopColor="white" />
        </LinearGradient>
      </Defs>
    </Svg>
  )
}
