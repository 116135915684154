import { RocketWorkLogo } from '@rocket-atoms/icon'
import { COLOR, useMobileView } from '@rocket-mono/libs'
import { useAstro, useModalDialog, useSystem } from '@rocket-mono/providers'
import { SocialJoinPayload, SocialLoginPayload } from '@rocket/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, View } from 'react-native'
import FindPasswordModal from './FindPasswordModal'
import { commonStyle } from './SignInScreen'
import SignUpView from './SignUpView'
import { TermsModal } from './components/TermsModal'
import { SnsType, SocialJoinType, Terms } from './types'

interface Props {
  invitedEmail?: string
  socialList: SocialJoinType[]
  onPressLogin?: () => void
  onOuterTermsPress?: (type: string) => void
  onJoinToLogin?: (token: string) => void
}

const SignUpScreen: React.FC<Props> = ({
  socialList,
  invitedEmail,
  onPressLogin,
  onOuterTermsPress,
  onJoinToLogin,
}) => {
  const mobileView = useMobileView(500)
  const { language } = useSystem()
  const { astro } = useAstro()
  const { i18n } = useTranslation()
  const { showDialogMessage } = useModalDialog()

  const [languageList, setLanguageList] = React.useState([
    {
      itemCode: 'en',
      label: 'English',
      active: false,
    },
    {
      itemCode: 'ko',
      label: '한국어',
      active: true,
    },
    {
      itemCode: 'es',
      label: 'Español',
      active: false,
    },
    {
      itemCode: 'de',
      label: 'Deutsch',
      active: false,
    },
    {
      itemCode: 'vi',
      label: 'Tiếng Việt',
      active: false,
    },
    {
      itemCode: 'mn',
      label: 'Монгол',
      active: false,
    },
    {
      itemCode: 'ja',
      label: '日本語',
      active: false,
    },
  ])

  const [snsSelect, setSnsSelect] = React.useState<SnsType>()
  const [userInfo, setUserInfo] = React.useState<SocialLoginPayload | SocialJoinPayload>()
  const [terms, setTerms] = React.useState<Terms>()
  const [lang, setLang] = React.useState('ko')
  const [emailPayload, setEmailPayload] = React.useState<{ email: string; password: string; name: string }>()
  const [isFindPassword, setIsFindPassword] = React.useState(false)

  const onMemberInvitation = (joinEmail?: string, inviteEmail?: string) => {
    if (joinEmail && inviteEmail && joinEmail === inviteEmail) {
      localStorage.removeItem('MemberInvitation')
    }
  }

  const onSnsJoin = (data: SocialLoginPayload | SocialJoinPayload, snsType: SnsType) => {
    astro.loginBySocial(data, lang).then((res: Response) => {
      if (res.status === 200) {
        onMemberInvitation(data.email, invitedEmail)
        onLoginSuccess(res)
      } else {
        setSnsSelect(snsType)
        setUserInfo(data)
        astro.joinBySocial(data, lang).then(onLoginSuccess)
      }
    })
  }

  const onLoginSuccess = (response: Response) => {
    const token = response.headers.get('X-Auth-Token')
    if (token) {
      if (onJoinToLogin) {
        onJoinToLogin(token)
      } else {
        localStorage.setItem('authToken', token)
        location.href = '/'
      }
    }
  }

  const onEmailLogin = (response: Response) => {
    // onLogin(response, 'EMAIL')
    if (response.status === 200) {
      response.json().then((data) => {
        onMemberInvitation(data.userEmail, invitedEmail)
        onLoginSuccess(response)
      })
    } else {
      response.json().then((data) => {
        if (data.status === 409) {
          showDialogMessage({
            title: i18n.t('signup.conflict.title'),
            message: i18n.t('signup.conflict.text'),
            list: [
              {
                name: i18n.t('signup.conflict.findpassword'), // 'Find Password',
                action: () => {
                  setIsFindPassword(true)
                },
              },
            ],
            subList: [
              {
                name: i18n.t('signup.conflict.retry'), // 'Retry',
                action: () => {
                  if (emailPayload) {
                    astro.joinByEmail({ type: 'pro', ...emailPayload }, lang).then(onEmailLogin)
                  }
                },
              },
            ],
          })
        }
      })
    }
  }

  const onLanguage = (lang: string) => {
    const newList = languageList.map((item) => {
      if (item.itemCode === lang) {
        return { ...item, active: true }
      } else {
        return { ...item, active: false }
      }
    })
    setLanguageList(newList)
    i18n.changeLanguage(lang)
    if (Platform.OS === 'web') {
      document.cookie = `APPLICATION_LOCALE=${lang};`
    }
    setLang(lang)
  }

  const onSingUp = (email: string, password: string, name: string) => {
    if (snsSelect && userInfo) {
      onMemberInvitation(userInfo.email, invitedEmail)
      astro.joinBySocial(userInfo, lang).then(onLoginSuccess)
    } else {
      setEmailPayload({ email, password, name })
      astro.joinByEmail({ type: 'pro', email, password, name }, lang).then(onEmailLogin)
    }
  }

  const onNavigate = (type: string) => {
    if (type === 'login') {
      onPressLogin && onPressLogin()
    }
  }

  const onTerms = (type: string) => {
    if (onOuterTermsPress) {
      onOuterTermsPress(type)
    } else {
      if (type === 'terms') {
        setTerms({
          title: i18n.t('terms.title'),
          contents: i18n.t('terms.contents'),
        })
      } else if (type === 'privacy') {
        setTerms({
          title: i18n.t('privacy.title'),
          contents: i18n.t('privacy.contents'),
        })
      } else {
        console.log('not supported type')
      }
    }
  }

  React.useEffect(() => {
    onLanguage(language)
  }, [language])

  return (
    <View style={[styles.container, mobileView && { backgroundColor: COLOR.mono.white }]}>
      {!mobileView && (
        <View style={commonStyle.logoArea}>
          <RocketWorkLogo width={160} height={30} />
        </View>
      )}
      <View style={[styles.signUpContainer, mobileView && styles.appSignUpContainer]}>
        <SignUpView
          invitedEmail={invitedEmail}
          languageList={languageList}
          socialList={socialList}
          onSocialPress={onSnsJoin}
          snsSelect={snsSelect}
          onSignUpPress={onSingUp}
          onLanguagePress={onLanguage}
          onNavigatePress={onNavigate}
          onTermsPress={onTerms}
        />
      </View>
      {terms ? <TermsModal title={terms.title} contents={terms.contents} onClose={() => setTerms(undefined)} /> : <></>}
      {isFindPassword ? <FindPasswordModal onLogin={onPressLogin} onClose={() => setIsFindPassword(false)} /> : <></>}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLOR.gray.g050,
  },
  headerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  height8: {
    height: 8,
  },
  signUpContainer: {
    width: 420,
    backgroundColor: COLOR.mono.white,
    paddingHorizontal: 55,
    paddingVertical: 50,
    borderRadius: 12,
  },
  appSignUpContainer: {
    width: '100%',
    paddingHorizontal: 40,
    paddingVertical: 0,
    borderRadius: 0,
  },
})

export default SignUpScreen
