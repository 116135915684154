import React, { useMemo } from 'react'
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  SectionList,
  SectionListRenderItemInfo,
  useWindowDimensions,
} from 'react-native'
import { useTranslation } from 'react-i18next'
import { COLOR, FONT, useMobileView } from '@rocket-mono/libs'
import { Icon, NoContentsIcon, SearchTextField, Switch } from '@rocket/atoms'
import { CardBoardType, CardListType } from '../type'
import CardSearchWorkTab from '../CardSearchWorkTab'
import SectionBoardList from './SectionBoardList'
import { CardType } from '@rocket/types'

export type CardItemType = { boardId: string; cardId: string; cardTitle: string; cardType: CardType; closed: boolean }

interface Props {
  keyword: string
  onChangeKeyword: (keyword: string) => void
  onPressSearch: () => void
  list: CardListType[]
  onPressItem: (card: CardItemType) => void
  onPressProject: (id: string) => void
  onPressClose?: () => void
}

// 이 컴포넌트는 여러곳에 공통으로 쓰입니다..!
const BoardSearchView = ({
  keyword,
  onChangeKeyword,
  onPressSearch,
  list,
  onPressItem,
  onPressProject,
  onPressClose,
}: Props) => {
  const { width } = useWindowDimensions()
  const { t } = useTranslation()
  const mobileView = useMobileView(400)
  const [archiveActived, setArchiveActived] = React.useState(false)

  const filteredList = useMemo(
    () =>
      list
        .map((o) => {
          const data = o.data.filter(({ boardTitle }) => boardTitle.indexOf(keyword) >= 0)

          console.log('data', data)
          const showData = data.filter(({ closed }) => !closed)
          let show = true

          let isFold = o.isFold

          if (keyword.length > 0) {
            isFold = false
            show = data.length === 0 ? false : true
            if (archiveActived === false && showData.length === 0) show = false
          }

          return { ...o, data, show, isFold }
        })
        .filter((o) => {
          if (archiveActived) return true
          return o.isArchive === false
        }),
    [list, archiveActived, keyword],
  )

  const renderSection = React.useCallback(
    ({ section }: { section: CardListType }) => {
      if (section.show === false) return <></>
      return (
        <CardSearchWorkTab
          onPress={() => onPressProject && onPressProject(section.projectId)}
          workTitle={section.projectTitle}
          isFold={section.isFold}
        />
      )
    },
    [filteredList],
  )

  const renderSectionItem = React.useCallback(
    ({ section, item }: SectionListRenderItemInfo<CardBoardType, CardListType>) => {
      if (section.isFold) return <></>
      if (!archiveActived && item.closed) return <></>
      return <SectionBoardList item={item} archive={item.closed} onPressItem={onPressItem} />
    },
    [archiveActived, filteredList],
  )

  return (
    <View style={[styles.container, { width: width * 0.8 }]}>
      <View style={styles.header}>
        <View style={[styles.sort, mobileView ? {} : { justifyContent: 'flex-start' }]}>
          <Text style={styles.title}>{t('placesearch.search')}</Text>
          <View style={styles.flexbox}>
            <Switch size={'sm'} activated={archiveActived} onChange={setArchiveActived} />
            <Pressable onPress={() => setArchiveActived(!archiveActived)}>
              <Text style={styles.labelText}>{t('search.result.includearchive')}</Text>
            </Pressable>
          </View>
          {onPressClose !== undefined && (
            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Pressable onPress={onPressClose}>
                <Icon name="close" />
              </Pressable>
            </View>
          )}
        </View>
        <SearchTextField
          value={keyword}
          placeholder={t('inputtodo.placeholder')}
          onChangeText={onChangeKeyword}
          onPressSubmit={onPressSearch}
          autoFocus={true}
        />
      </View>
      <View style={{ flex: 1 }}>
        <SectionList
          sections={filteredList}
          keyExtractor={(item) => `card-result-${item.boardId}`}
          renderItem={renderSectionItem}
          renderSectionHeader={renderSection}
          stickySectionHeadersEnabled
          ListEmptyComponent={
            <View style={{ marginTop: 50 }}>
              <NoContentsIcon
                type="search"
                textSize={'medium'}
                text={''}
                subText={t('nocontentsicon.search.text')}
                iconWidth={115}
              />
            </View>
          }
        />
      </View>
    </View>
  )
}

export default BoardSearchView

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLOR.mono.white,
    paddingBottom: 12,
    borderRadius: 8,
    maxWidth: 600,
    minWidth: 400,
  },
  flexbox: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    paddingBottom: 12,
    paddingHorizontal: 16,
  },
  title: {
    ...FONT.txtMdMedium,
    color: COLOR.gray.g800,
    marginRight: 12,
  },
  sort: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
    marginTop: 8,
  },
  labelText: {
    marginHorizontal: 4,
    ...FONT.txtSmMedium,
    color: COLOR.gray.g800,
  },
})
