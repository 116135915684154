import { COLOR, getBoxShadow } from '@rocket-mono/libs'
import { ClickOutside } from '@rui/foundations'
import * as React from 'react'
import { FlatList, ListRenderItemInfo, Modal, Pressable, StyleSheet, View } from 'react-native'
import DropdownItem from './item'
import DropdownBasicItem from './itemBasic'
import type { DropdownItemType, DropdownMeasureType } from './types'

interface Props {
  selected: DropdownItemType[]
  data: DropdownItemType[]
  dropdownMeasure: DropdownMeasureType
  visible: boolean
  onPressItem: (item: DropdownItemType) => void
  closeDropdown: () => void
  divide?: boolean
  listFullWide?: boolean
  listType?: 'line' | 'fill' | 'basic'
  isItemIconCheck?: boolean
  relativeList?: boolean
}

const DropdownList: React.FC<Props> = ({
  selected,
  data,
  listType,
  dropdownMeasure,
  visible,
  onPressItem,
  closeDropdown,
  divide,
  listFullWide,
  isItemIconCheck = true,
  relativeList,
}) => {
  const styleProps = React.useMemo(() => {
    const { width, height, pageX: left, pageY } = dropdownMeasure
    const top = pageY + height
    return divide ? { top, left } : { width, top, left }
  }, [dropdownMeasure])

  const renderItem = React.useCallback(
    ({ item }: ListRenderItemInfo<DropdownItemType>) => {
      const isActive = selected.find((o) => o.key === item.key) ? true : false
      return (
        <>
          {listType === 'basic' ? (
            <DropdownBasicItem item={item} isActive={isActive} onPress={() => onPressItem(item)} />
          ) : (
            <DropdownItem
              item={item}
              isActive={isActive}
              listType={listType}
              onPress={() => onPressItem(item)}
              isIconCheck={isItemIconCheck}
            />
          )}
        </>
      )
    },
    [onPressItem, selected],
  )

  return relativeList ? (
    visible ? (
      <ClickOutside onClickOutside={closeDropdown}>
        <Pressable onPress={closeDropdown}>
          <View
            style={[
              styles.container,
              styleProps,
              divide && styles.divideType,
              listType === 'basic' && { paddingTop: 1, paddingBottom: 0 },
              listFullWide && { width: dropdownMeasure.width },
              { zIndex: 999, top: 18, left: -9 },
            ]}
          >
            <FlatList data={data} renderItem={renderItem} keyExtractor={(item) => item.key} />
          </View>
        </Pressable>
      </ClickOutside>
    ) : (
      <></>
    )
  ) : (
    <Modal visible={visible} transparent animationType="none">
      <Pressable style={styles.overlay} onPress={closeDropdown}>
        <View
          style={[
            styles.container,
            styleProps,
            divide && styles.divideType,
            listType === 'basic' && { paddingTop: 1, paddingBottom: 0 },
            listFullWide && { width: dropdownMeasure.width },
          ]}
        >
          <FlatList data={data} renderItem={renderItem} keyExtractor={(item) => item.key} />
        </View>
      </Pressable>
    </Modal>
  )
}

export default DropdownList

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    backgroundColor: COLOR.mono.white,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g200,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    paddingBottom: 4,
    ...getBoxShadow(0, 0, 0, 0.05, 5, 0, 2, 0),
  },
  overlay: {
    width: '100%',
    height: '100%',
  },
  divideType: {
    minWidth: 180,
    borderTopWidth: 1,
    marginTop: 2,
    paddingTop: 4,
    borderRadius: 4,
  },
})
