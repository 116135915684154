import { COLOR, FONT } from '@rocket-mono/libs'
import * as React from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'

export interface Props {
  text?: string
}

const DisabledItem: React.FC<Props> = ({ text }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{text}</Text>
    </View>
  )
}

export default DisabledItem

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR.mono.lightGray,
    paddingVertical: 10,
    paddingHorizontal: 15,
    ...Platform.select({ web: { cursor: 'not-allowed' } }),
  },
  text: {
    ...FONT.subTextRegular,
    color: COLOR.mono.darkGray,
    fontSize: 13,
    ...Platform.select({ native: { paddingTop: 3 } }),
  },
})
