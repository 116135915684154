import { DropdownButton, DropdownItemProps } from '@rocket-atoms/dropdown'
import { SelectorCard } from '@rocket-molecules/popselector'
import { ClickOutside } from '@rocket-mono/libs'
import { CardIcon } from '@rocket/atoms'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { commonStyles } from './style'

const zIndex = 1

interface Props {
  cardList: DropdownItemProps[]
  defaultIndex?: string
  placeholder?: string
  onSelected: (item: DropdownItemProps) => void
}

const RuleCardSelectView = ({ cardList, defaultIndex, placeholder, onSelected }: Props) => {
  const { t } = useTranslation()
  const [activated, setActivated] = React.useState(false)
  const [selected, setSelected] = React.useState(defaultIndex ? [cardList[Number(defaultIndex)]] : [])
  const [buttonStandard, setButtonStandard] = React.useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  })

  return (
    <View>
      <Text style={commonStyles.labelText}>{t('rule.cards.select')}</Text>
      <View style={styles.container}>
        <DropdownButton
          type={'DEFAULT'}
          configuration={'DEFAULT'}
          size={'md'}
          disabled={false}
          activated={activated}
          setActivated={setActivated}
          setButtonStandard={setButtonStandard}
          isButtonBackgroundColorWhite
        >
          <View style={styles.labelArea}>
            {selected.length !== 0 ? (
              <>
                <CardIcon
                  type={
                    selected[0].beforeCardIcon && selected[0].beforeCardIcon !== 'TODO'
                      ? selected[0].beforeCardIcon
                      : 'MISSION'
                  }
                  variant={'ROUNDSQUARE'}
                  size={'sm'}
                />
                <Text style={commonStyles.valueText}>{selected[0].label}</Text>
              </>
            ) : (
              <Text style={commonStyles.initText}>{placeholder ? placeholder : t('rule.cards.select')}</Text>
            )}
          </View>
        </DropdownButton>
        {activated && (
          <ClickOutside onClickOutside={() => setActivated(false)}>
            <View style={{ position: 'absolute', top: buttonStandard.height + 4, zIndex }}>
              <SelectorCard
                placeholder={t('inputtodo.placeholder')}
                cardList={cardList}
                onSelected={(item) => {
                  setActivated(false)
                  setSelected([item])
                  onSelected?.(item)
                }}
                onClose={() => setActivated(false)}
                defaultIndex={defaultIndex}
                width={buttonStandard.width}
              />
            </View>
          </ClickOutside>
        )}
      </View>
    </View>
  )
}

export default RuleCardSelectView

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  labelArea: {
    overflow: 'hidden',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 4,
    paddingRight: 6,
    borderRadius: 4,
  },
})
