import { COLOR, FONT } from '@rocket-mono/libs'
import { Radio } from '@rocket/atoms'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { ActionCopyOption } from '../RuleScreen/types'
import { commonStyles } from './style'

const Item = ({
  state,
  current,
  label,
  description,
  onPress,
}: {
  state: ActionCopyOption
  current: ActionCopyOption
  label: string
  description: string
  onPress?: () => void
}) => {
  return (
    <Pressable style={styles.itemContainer} onPress={onPress}>
      <View>
        <Radio checked={state === current} />
      </View>
      <View style={{ width: 8 }} />
      <View>
        <Text style={styles.labelText}>{label}</Text>
        <Text style={styles.descriptionText}>{description}</Text>
      </View>
    </Pressable>
  )
}

interface Props {
  current: ActionCopyOption
  onPress: (option: ActionCopyOption) => void
}

const RuleCopySelectView: React.FC<Props> = ({ current, onPress }) => {
  const { t } = useTranslation()
  return (
    <View>
      <Text style={commonStyles.labelText}>{t('rule.automation.copyoptions')}</Text>
      {/* <Item
        label={t('rule.automation.copyoptions1')}
        description={t('rule.automation.copyoptions1des')}
        state="CONTENTS"
        current={current}
        onPress={() => onPress('CONTENTS')}
      />
      <View style={{ height: 20 }} /> */}
      <Item
        label={t('rule.automation.copyoptions2')}
        description={t('rule.automation.copyoptions2des')}
        state="FORM"
        current={current}
        onPress={() => onPress('FORM')}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  labelText: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g900,
  },
  descriptionText: {
    ...FONT.txtXsMedium,
    color: COLOR.gray.g600,
  },
  itemContainer: {
    flexDirection: 'row',
  },
})

export default RuleCopySelectView
