import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, useWindowDimensions } from 'react-native'

import { View } from '@rocket-mono/foundations'
import { useCurrentUser, useWork } from '@rocket-mono/providers'
import { COLOR } from '@rui/foundations'

import GuideComment from '../../components/GuideComment'
import WorkspaceTitleTab from '../../components/WorkspaceTitleTab'
import { ShortcutsSkeleton } from './SkeletonParts'
import WorkspaceFavoriteView from './WorkspaceFavoriteView'
import WorkspaceMainCreateView from './WorkspaceMainCreateView'
import WorkspaceMainGroupView from './WorkspaceMainGroupView'
import WorkspaceRecentView from './WorkspaceRecentView'

interface Props {
  onPressCreateWork: (type?: string) => void
  onPressProject: (projectId: string) => void
  skeleton?: boolean
}

const WorkspaceMainView: React.FC<Props> = ({ onPressCreateWork, onPressProject, skeleton = false }) => {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const mobileWebView = width < 768 //브라우저 웹:모바일 뷰 크기

  const { currentUser } = useCurrentUser()
  const { groupList, isWorkGuide, closeWorkGuide, favoriteList } = useWork()

  const [workspaceShow, setWorkspaceShow] = useState(true) //워크스페이스 접힘·펼침

  const [worspaceContentsWidth, setWorspaceContentsWidth] = useState(0) //워크스페이스 영역 넓이: shortcuts넓이에 쓰기 위함
  const [shortcutsLatestContentsWidth, setShortcutsLatestContentsWidth] = useState(0) //최근 열어본 워크 리스트 영역 넓이
  const [shortcutsFavoriteContentsWidth, setShortcutsFavoriteContentsWidth] = useState(0) //즐겨찾기 워크 리스트 영역 넓이
  const [latestSliderMode, setLatestSliderMode] = useState(false) // 최근 열어본 워크 슬라이드 여부
  const [favoritSliderMode, setFavoritSliderMode] = useState(false) // 즐겨찾기 워크 슬라이드 여부

  useEffect(() => {
    if (width - 104 <= shortcutsLatestContentsWidth) {
      setLatestSliderMode(true)
    } else {
      setLatestSliderMode(false)
    }

    if (width - 104 <= shortcutsFavoriteContentsWidth) {
      setFavoritSliderMode(true)
    } else {
      setFavoritSliderMode(false)
    }
  }, [worspaceContentsWidth])

  return (
    <>
      {typeof favoriteList === 'undefined' ? (
        <View style={styles.shortcutsArea}>
          <ShortcutsSkeleton />
        </View>
      ) : (
        <View style={[styles.shortcutsArea, mobileWebView && { flexDirection: 'column' }]}>
          <WorkspaceRecentView
            onPressProject={onPressProject}
            worspaceContentsWidth={worspaceContentsWidth}
            setShortcutsLatestContentsWidth={setShortcutsLatestContentsWidth}
            latestSliderMode={latestSliderMode}
          />
          <WorkspaceFavoriteView
            onPressProject={onPressProject}
            worspaceContentsWidth={worspaceContentsWidth}
            setShortcutsFavoriteContentsWidth={setShortcutsFavoriteContentsWidth}
            favoritSliderMode={favoritSliderMode}
          />
        </View>
      )}
      {isWorkGuide && (
        <View style={styles.sortArea}>
          {!skeleton && (
            <>
              <View>
                <GuideComment guide={t('workspace.groupguide')} onPressClose={closeWorkGuide} />
              </View>

              {/* TODO: 이번 마일스톤 제외 */}
              {/* <ListType type={listTypes} onPress={setListTypes} /> */}
            </>
          )}
        </View>
      )}
      <View
        style={styles.workspaceContainer}
        onLayout={({ nativeEvent }) => setWorspaceContentsWidth(nativeEvent.layout.width)}
      >
        <View style={[styles.workspaceTab, !workspaceShow && { marginBottom: 0 }]}>
          <WorkspaceTitleTab
            skeleton={groupList === undefined}
            mobileView={mobileWebView}
            type={'MY'}
            title={`${currentUser.userName} ${t('workspace.myworkspace')}`}
            isFold={workspaceShow}
            onPressFold={setWorkspaceShow}
            learnMoreList={[]}
          />
        </View>
        {workspaceShow && (
          <>
            <WorkspaceMainGroupView onPressProject={onPressProject} />
            <WorkspaceMainCreateView
              onPressCreateWork={() => onPressCreateWork && onPressCreateWork('')}
              onPressProject={onPressProject}
              skeleton={skeleton}
            />
          </>
        )}
      </View>
    </>
  )
}

export default WorkspaceMainView

const styles = StyleSheet.create({
  sortArea: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 32,
    marginBottom: 12,
  },

  workspaceContainer: {
    backgroundColor: COLOR.darkmode.cool900,
    borderRadius: 8,
    paddingHorizontal: 20,
    paddingTop: 16,
    paddingBottom: 20,
  },
  workspaceTab: {
    marginBottom: 8,
  },
  shortcutsArea: {
    // flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
})
