import React, { useState } from 'react'

import { COLOR } from '@rocket-mono/libs'
import { NativeSyntheticEvent, StyleSheet, TextInput, TextInputKeyPressEventData, ViewStyle } from 'react-native'

export interface TextFieldProps {
  inputRef?: React.RefObject<TextInput> | undefined
  value: string
  placeholder: string
  disabled?: boolean
  secureTextEntry?: boolean
  style?: ViewStyle
  onChangeValue?: (value: string) => void
  onSubmitEditing?: () => void
  onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void
  onBlur?: () => void
  autoFocus?: boolean
}

const TextField: React.FC<TextFieldProps> = ({
  inputRef,
  value,
  placeholder,
  disabled,
  secureTextEntry,
  style,
  onChangeValue,
  onSubmitEditing,
  onKeyPress,
  onBlur,
  autoFocus,
}) => {
  const [isFocus, setIsFocus] = useState(false)
  return (
    <TextInput
      ref={inputRef}
      value={value}
      secureTextEntry={secureTextEntry}
      placeholder={placeholder}
      placeholderTextColor={COLOR.gray.g400}
      onChangeText={(text) => onChangeValue && onChangeValue(text)}
      editable={disabled ? false : true}
      selectTextOnFocus={false}
      onFocus={() => setIsFocus(true)}
      onBlur={() => {
        setIsFocus(false)
        onBlur && onBlur()
      }}
      onKeyPress={onKeyPress}
      style={[styles.commonStyle, isFocus && styles.focusStyle, disabled && styles.disableStyle, style]}
      onSubmitEditing={onSubmitEditing}
      blurOnSubmit={false}
      autoFocus={autoFocus}
    />
  )
}

export default TextField

const styles = StyleSheet.create({
  commonStyle: {
    height: 36,
    padding: 8,
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: COLOR.gray.g200,
  },
  focusStyle: {
    backgroundColor: COLOR.gray.g050,
    borderColor: COLOR.gray.g300,
  },
  disableStyle: {
    backgroundColor: COLOR.opacity.blk10,
    borderColor: COLOR.gray.g100,
    color: COLOR.gray.g400,
  },
})
