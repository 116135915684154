import { DropdownButton, DropdownItemProps } from '@rocket-atoms/dropdown'
import { SelectorCard } from '@rocket-molecules/popselector'
import { COLOR, ClickOutside, FONT } from '@rocket-mono/libs'
import { CardIcon } from '@rocket/atoms'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { useRule } from '../../RuleScreen/hooks'
import { commonStyles } from '../style'
import { Pressable } from 'react-native'

const zIndex = 100

interface Props {
  cardList: DropdownItemProps[]
  defaultIndex?: string
  placeholder?: string
  onSelected: (item: DropdownItemProps) => void
}

const FlowRuleCardView: React.FC<Props> = ({ cardList, defaultIndex, placeholder, onSelected }) => {
  const { t } = useTranslation()
  const { isAuth } = useRule()
  const [activated, setActivated] = React.useState(false)
  const [selected, setSelected] = React.useState(defaultIndex ? [cardList[Number(defaultIndex)]] : [])
  const [labelWrapHover, setLabelWrapHover] = React.useState(false)
  
  return (
    <View>
      <View style={styles.container}>
        <View style={styles.dropButtonContainer}>
        <Pressable 
          onPress={()=> isAuth && setActivated(true)}
          onHoverIn={()=>setLabelWrapHover(true)}
          onHoverOut={()=>setLabelWrapHover(false)}
          style={[commonStyles.labelWrap, labelWrapHover && commonStyles.labelWrapHover]}
        >
            <View style={styles.labelArea}>
              {selected.length !== 0 ? (
                <>
                  <CardIcon
                    type={
                      selected[0].beforeCardIcon && selected[0].beforeCardIcon !== 'TODO'
                        ? selected[0].beforeCardIcon
                        : 'MISSION'
                    }
                    variant={'ROUNDSQUARE'}
                    size={'xs'}
                  />
                  <Text style={commonStyles.valueText}>{selected[0].label}</Text>
                </>
              ) : (
                <Text style={commonStyles.initText}>{placeholder ? placeholder : t('rule.cards.select')}</Text>
              )}
            </View>
          </Pressable>
          <Text style={styles.dropdownButtonSideText}>{t('rule.rules.tocard')}</Text>
        </View>
        {activated && (
          <ClickOutside onClickOutside={() => setActivated(false)}>
            <View style={{ position: 'absolute', zIndex }}>
              <SelectorCard
                cardList={cardList}
                onSelected={(item) => {
                  setActivated(false)
                  setSelected([item])
                  onSelected?.(item)
                }}
                defaultIndex={defaultIndex}
                width={300}
                onClose={() => setActivated(false)}
                // onSelected={setSelected}
                // placeholder={t('rule.cards.title')}
              />
            </View>
          </ClickOutside>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  dropButtonContainer: {
    flexDirection: 'row',
  },
  labelArea: {
    overflow: 'hidden',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdownButtonSideText: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g800,
    marginLeft: 4,
  },
})

export default FlowRuleCardView
