import React, {useState} from 'react'
import { StyleSheet, View, Text, Pressable} from 'react-native'
import { FONT, COLOR, getBoxShadow } from '@rocket-mono/libs'
import TriggerIcon, { TriggerIconProps } from './TriggerIcon'
import { useMobileView } from '@rocket-mono/libs'

interface Props extends TriggerIconProps{
  title: string
  text: string
  selected?: boolean
  onPress: () => void
}

const TriggerItem = ({type, title, text, selected, onPress}: Props) => {
  const mobileView = useMobileView(500)
  const [isHover, setIsHover] = useState(false)
  const [isSelected, setIsSelected] = useState(selected)

  return (
    <Pressable
      onPress={onPress}
      onHoverIn={()=>setIsHover(true)}
      onHoverOut={()=>setIsHover(false)}
      onPressIn={()=>setIsSelected(true)}
      onPressOut={()=>setIsSelected(false)}
      style={[
        styles.container,
        isHover && styles.hoverContainer,
        selected || isSelected ? styles.selectedContainer : {},
        mobileView && {borderColor: COLOR.gray.g150}
      ]}
    >
      <TriggerIcon type={type} size={mobileView? 40 : 50}/>
      <View style={styles.info}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.text}>{text}</Text>
      </View>
    </Pressable>
  )
}

export default TriggerItem

const styles = StyleSheet.create({
  container:{
    flexDirection: 'row',
    alignItems: 'center',
    padding: 12,
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderColor: COLOR.mono.white,
    borderRadius: 8
  },
  hoverContainer:{
    borderColor: COLOR.gray.g150,
    ...getBoxShadow(0,0,0,0.1,2,0,1,0)
  },
  selectedContainer:{
    borderColor: COLOR.primary.blue500,
  },
  info:{
    marginLeft: 12
  },
  title:{
    ...FONT.txtMdMedium,
  },
  text:{
    ...FONT.txtXs,
    color: COLOR.gray.g600
  }
})