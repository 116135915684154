import { Tooltip } from '@rocket-atoms/tooltip'
import { FONT, IconName } from '@rocket-mono/libs'
import React from 'react'
import { View, ViewStyle } from 'react-native'
import { WorkCardOptionButton } from '../WorkCardOptionButton'

export interface WorkCardEditBottomOptionType {
  name: IconName
  tooltip: string
  checked: boolean
  onPress?: () => void
}

export interface WorkCardOpionListType {
  iconList: WorkCardEditBottomOptionType[]
  flexDirection?: ViewStyle
  gap?: ViewStyle
}

const WorkCardOptionList: React.FC<WorkCardOpionListType> = ({ iconList, flexDirection, gap }) => {
  const OptionItem = ({ item }: { item: WorkCardEditBottomOptionType }) => {
    return (
      <View style={gap}>
        <WorkCardOptionButton iconName={item.name} checked={item.checked} onPress={item.onPress} />
      </View>
    )
  }
  return (
    <View style={flexDirection}>
      {iconList.map((item, idx) => (
        <Tooltip
          key={`option-item${idx}`}
          text={item.tooltip}
          textStyle={{ ...FONT.txtSmMedium }}
          direction="left"
          onPressTarget={item.onPress}
        >
          {(props) => (
            <View {...props}>
              <OptionItem item={item} />
            </View>
          )}
        </Tooltip>
      ))}
    </View>
  )
}

export default WorkCardOptionList
