import * as React from 'react'
import { View, ViewStyle } from 'react-native'
import type { UserState } from '@rocket/types'
import { COLOR } from '@rocket-mono/libs'
interface Props {
  state: UserState
  style?: ViewStyle
  sizing?: number
}
const IconStateCircle: React.FC<Props> = ({ state, style, sizing }) => {
  const size = sizing ? sizing : 16
  const sizeProps = React.useMemo(() => {
    return { width: size, height: size, borderRadius: size }
  }, [size])

  const backgroundColor = React.useMemo(() => {
    const colorState = state === 'away' ? 'unavailable' : state === 'invisible' ? 'inactive' : state

    return COLOR.status[colorState]
  }, [state])
  return (
    <View
      style={[
        style,
        {
          ...sizeProps,
          backgroundColor,
          borderWidth: 2,
          borderColor: COLOR.mono.white,
        },
      ]}
    />
  )
}

export default React.memo(IconStateCircle)
