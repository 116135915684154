import { COLOR } from '@rocket-mono/libs'
import { Icon, Text } from '@rocket/atoms'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, View } from 'react-native'
import { useRule } from '../hooks'
import { HeaderProps } from './types'

const Header: React.FC<HeaderProps> = ({ onPressClose }) => {
  const { isMobile, step } = useRule()
  const { t } = useTranslation()
  const title = React.useMemo(() => {
    if (step === 'VIEW') {
      return t('board.rule.title')
    } else if (step === 'CREATE') {
      return t('rule.modal.maketitle')
    } else {
      return ''
    }
  }, [step])
  const size = React.useMemo(() => {
    return 62
  }, [isMobile])
  return (
    <View style={[styles.headerContainer, { height: size }]}>
      <Text fontName="H7Medium" fontColor="mono.black">
        {title}
      </Text>
      <Pressable style={[styles.closeButton, { width: size, height: size }]} onPress={onPressClose}>
        <Icon name="close" size={24} color="mono.paleBlack" />
      </Pressable>
    </View>
  )
}

const styles = StyleSheet.create({
  headerContainer: {
    width: '100%',
    height: 61,
    paddingLeft: 24,
    flexDirection: 'row',
    borderBottomColor: COLOR.gray.g150,
    borderBottomWidth: 1,
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    width: 61,
    height: 61,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default Header
