import React, { useCallback } from 'react'
import { LayoutChangeEvent, View } from 'react-native'
import { useTranslation } from 'react-i18next'

import { moment, COLOR } from '@rui/foundations'
import { Tag, Text } from '@rui/atoms'
import { FAIcon, XEIcon, RNSvg } from '@rui/icons'

import { HorizontalTabGroup } from './HorizontalTabGroup'
import { TabItem } from './TabItem'
import { faList, faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { faCommentDots, faCalendar } from '@fortawesome/free-regular-svg-icons'
import { useSearchScreen } from '../../provider'

interface Props {
  countWork?: number
  countBoard?: number
  countMessage?: number
  countCard?: number
  countFile?: number
}

export const SearchResultCategoryView: React.FC<Props> = ({
  countWork = 0,
  countBoard = 0,
  countMessage = 0,
  countCard = 0,
  countFile = 0,
}) => {
  const { t } = useTranslation()
  const {
    tab,
    onChangeTab,
    searchDate,
    projectNameList,
    resetCalendar,
    searchType,
    onChangeSearchType,
    onChangeSearchProjectId,
    onChangeVisibleCalendar,
    onLayoutCalendar,
  } = useSearchScreen()

  const handlePressClose = useCallback(() => {
    if (searchType === 'WORK') onChangeSearchType('WORKSPACE')
    onChangeSearchProjectId([])
  }, [searchType, onChangeSearchType, onChangeSearchProjectId])

  return (
    <View
      style={{ paddingHorizontal: 12, paddingBottom: 12, borderBottomWidth: 1, borderColor: COLOR.gray.g200 }}
      onLayout={onLayoutCalendar}
    >
      <View style={{ flexDirection: 'row' }}>
        {projectNameList.length > 0 && (
          <View style={{ marginRight: 8 }}>
            <Tag
              text={`${projectNameList[0]}${
                projectNameList.length > 1 ? ` ${t('search.result.number', { count: projectNameList.length - 1 })}` : ''
              }`}
              onPressClose={handlePressClose}
            />
          </View>
        )}
        {searchDate && searchDate.startDate && searchDate.endDate && (
          <View style={{ marginRight: 8 }}>
            <Tag
              text={`${t('format.date.L', { date: searchDate.startDate })}~${t('format.date.L', {
                date: searchDate.endDate,
              })}`}
              onPressClose={resetCalendar}
            />
          </View>
        )}
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 8, marginBottom: 8 }}>
        <Text fontName="txtXsMedium" fontColor="gray.g900">
          {t('search.result.title')}
        </Text>
      </View>
      <View>
        <HorizontalTabGroup
          iconName={faCalendar}
          onPress={() => onChangeVisibleCalendar(true)}
          actived={searchDate && searchDate.startDate !== null && searchDate.endDate !== null}
        >
          <TabItem currentTab={tab} tab="ALL" onPress={onChangeTab}>
            {(isActive) => (
              <Text fontColor={isActive ? 'mono.white' : 'gray.g600'} fontName="txtSmMedium">
                {' '}
                ALL{' '}
              </Text>
            )}
          </TabItem>
          <TabItem currentTab={tab} tab="PROJECT" onPress={onChangeTab} count={countWork}>
            {(isActive) => (
              <View style={{ marginTop: -2, marginLeft: -1 }}>
                <XEIcon name="apps" size={17} color={isActive ? 'mono.white' : 'gray.g600'} />
              </View>
            )}
          </TabItem>
          <TabItem currentTab={tab} tab="BOARD" onPress={onChangeTab} count={countBoard}>
            {(isActive) => <FAIcon iconName={faList} size={'sm'} color={isActive ? 'mono.white' : 'gray.g600'} />}
          </TabItem>
          <TabItem currentTab={tab} tab="CHAT_MESSAGE" onPress={onChangeTab} count={countMessage}>
            {(isActive) => (
              <FAIcon iconName={faCommentDots} size={'sm'} color={isActive ? 'mono.white' : 'gray.g600'} />
            )}
          </TabItem>
          <TabItem currentTab={tab} tab="CARD" onPress={onChangeTab} count={countCard}>
            {(isActive) => (
              <RNSvg.Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                <RNSvg.Path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.87381 2.295V6.14269H14.1262V2.295H1.87381ZM0.988095 0.816406C0.580457 0.816406 0.25 1.11731 0.25 1.48849V6.9492C0.25 7.32038 0.580456 7.62128 0.988095 7.62128H15.0119C15.4195 7.62128 15.75 7.32038 15.75 6.9492V1.48849C15.75 1.11731 15.4195 0.816406 15.0119 0.816406H0.988095Z"
                  fill={isActive ? 'white' : '#6A7C87'}
                />
                <RNSvg.Circle cx="3.65258" cy="4.21813" r="1.13415" fill={isActive ? 'white' : '#6A7C87'} />
                <RNSvg.Path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.87381 9.85554V13.7032H14.1262V9.85554H1.87381ZM0.988095 8.37695C0.580457 8.37695 0.25 8.67786 0.25 9.04904V14.5097C0.25 14.8809 0.580456 15.1818 0.988095 15.1818H15.0119C15.4195 15.1818 15.75 14.8809 15.75 14.5097V9.04904C15.75 8.67786 15.4195 8.37695 15.0119 8.37695H0.988095Z"
                  fill={isActive ? 'white' : '#6A7C87'}
                />
                <RNSvg.Circle cx="3.65258" cy="11.7787" r="1.13415" fill={isActive ? 'white' : '#6A7C87'} />
              </RNSvg.Svg>
            )}
          </TabItem>
          <TabItem currentTab={tab} tab="FILE" onPress={onChangeTab} count={countFile}>
            {(isActive) => <FAIcon iconName={faPaperclip} size={'sm'} color={isActive ? 'mono.white' : 'gray.g600'} />}
          </TabItem>
        </HorizontalTabGroup>
      </View>
    </View>
  )
}
