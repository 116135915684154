import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { WorkProjectProvider } from '@rocket/components'
import { useAstro, useCurrentUser, useWork } from '@rocket-mono/providers'
import React, { useCallback, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { authTokenState, cardLayoutPayloadState, sideLayoutTypeState } from '../../recoils'
import { useModalDialog } from '@rocket-mono/providers'
import { CardType } from '@rocket/types'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@rocket/skeleton'

interface Props {
  children: JSX.Element | JSX.Element[]
}

const Provider: React.FC<Props> = ({ children }) => {
  const { t, i18n } = useTranslation()
  const { projectId } = useParams()
  const { currentUser } = useCurrentUser()

  const { projectList, addRecent } = useWork()

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const authToken = useRecoilValue(authTokenState)
  const setCardLayoutPayload = useSetRecoilState(cardLayoutPayloadState)
  const setSideLayoutType = useSetRecoilState(sideLayoutTypeState)

  const { showDialogMessage } = useModalDialog()

  const { astro, astroNav, option } = useAstro()

  const openChat = useCallback(
    (channelRoomId: string, messageId?: string) => {
      const uri = option.talkApiLegacyUrl + '/api/talk/token'
      const formData = new FormData()
      formData.append('roomId', channelRoomId)
      formData.append('language', i18n.language)
      if (messageId) {
        formData.append('messageId', messageId)
      }
      const method = 'POST'
      const headers = { 'X-AUTH-TOKEN': authToken }
      const body = formData

      const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=400,height=800,left=100,top=100`
      const win = window.open('', 'popupView-' + channelRoomId, params)
      console.log('popUrl', channelRoomId, messageId)
      fetch(uri, { method, headers, body })
        .then((res) => res.text())
        .then((data) => {
          const popUrl = option.talkUrl + '/chat/' + data
          console.log('popUrl2', win?.location.href, channelRoomId, messageId)
          try {
            if (win?.location.href === 'about:blank') {
              win.location = popUrl
              if (win !== null && typeof win !== 'undefined') {
                win.focus()
              }
            }
          } catch (e) {
            console.log('focus')
          }
        })
        .catch((err) => console.error(err))
    },
    [i18n],
  )

  const openDirectChat = useCallback(
    (memberId: string) => {
      const uri = option.projectApiLegacyUrl + `/api/channel`
      const method = 'POST'
      const headers = { 'X-AUTH-TOKEN': authToken }
      const body = new FormData()
      body.append('roomType', 'D')
      body.append('userNo', String(currentUser.id))
      body.append('memberIds', memberId)

      fetch(uri, { method, headers, body })
        .then((res) => res.json())
        .then((data) => {
          console.log('data', data)
          openChat(data.roomId)
        })
    },
    [currentUser, openChat],
  )

  const openCard = useCallback(
    (payload: {
      isWindow: boolean
      isFold: boolean
      isEdit: boolean
      projectId: string
      channelId: string
      cardType: CardType
      cardId?: string
    }) => {
      if (payload.isWindow) {
        const cardUrl = `/card${payload.isEdit ? '/edit' : ''}${
          payload.cardId !== undefined ? `/${payload.cardId}` : ''
        }`
        const cardType = payload.cardId === undefined ? `&cardType=${payload.cardType}` : ''
        window.open(`${cardUrl}?channelId=${payload.channelId}${cardType}`)
      } else {
        const timeout = 50
        setSideLayoutType('')
        setTimeout(() => {
          setCardLayoutPayload((prev) => {
            if (prev === undefined) return payload

            if (prev.isEdit || prev.isFold) {
              const title = t('card.alert.title')
              const message = t('card.alert.sub')
              const list = [
                {
                  name: t('card.alert.new'),
                  action: () => {
                    const cardUrl = `/card${payload.isEdit ? '/edit' : ''}${
                      payload.cardId !== undefined ? `/${payload.cardId}` : ''
                    }`
                    const cardType = payload.cardId === undefined ? `&cardType=${payload.cardType}` : ''
                    window.open(`${cardUrl}?channelId=${payload.channelId}${cardType}`)
                  },
                },
              ]
              showDialogMessage({
                title,
                message,
                list,
                cancelText: t('card.alert.close'),
                onCancel: () => {
                  setCardLayoutPayload(undefined)
                  setTimeout(() => setCardLayoutPayload(payload), 50)
                },
              })
              return prev
            }
            return payload
          })
        }, timeout)
      }
    },
    [],
  )

  const projectPath = useCallback(
    (naviType: string) => {
      const path = pathname.split('/').pop() || ''
      if (naviType === 'TABLEORDER') return ['member', 'pos', 'menu'].includes(path) ? path : 'pos'
      return ['user', 'board', 'calendar'].includes(path) ? path : 'board'
    },
    [pathname],
  )

  const openProject = useCallback(
    (projectId: string, path?: string) => {
      const type = projectList?.find(({ id }) => id === projectId)?.type.code === 'TOP' ? 'TABLEORDER' : 'DEFAULT'

      if (projectId) {
        addRecent(projectId)
        navigate(`/project/${projectId}/${path ?? projectPath(type)}`)
      }
    },
    [projectList],
  )

  useEffect(() => {
    astroNav.on('openProject', ({ payload }) => {
      console.log('astroNav.on-openProject', { payload })
      openProject(payload.projectId)
    })
    astroNav.on('openChat', ({ payload }) => {
      console.log('astroNav.on-openChat', payload)
      openChat(payload.channelRoomId, payload.messageId)
    })
    astroNav.on('openDirectChat', ({ payload }) => {
      console.log('astroNav.on-openDirectChat', payload)
      openDirectChat(payload.userId)
    })
    astroNav.on('openCard', ({ payload }) => {
      console.log('astroNav.on-openCard', { payload })
      openCard(payload)
    })
  }, [])

  const handleDeleteWork = useCallback(() => {
    // navigate('/')
  }, [])

  return (
    <WorkProjectProvider
      astro={astro}
      projectId={projectId}
      handleDeleteWork={handleDeleteWork}
      fallback={<Skeleton path={location.pathname} />}
    >
      <>{children}</>
    </WorkProjectProvider>
  )
}

export default Provider
