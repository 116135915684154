import { COLOR, useMobileView } from '@rui/foundations'
import { useTranslation } from 'react-i18next'

import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { View } from '@rocket-mono/foundations'

import { useWork } from '@rocket-mono/providers'
import WorkCardList from '../../../components/WorkCardList'
import WorkcardBookmarkGroup from '../../../components/WorkcardBookmarkGroup'

interface Props {
  onPressProject?: (projectId: string) => void
  worspaceContentsWidth: number
  setShortcutsLatestContentsWidth?: (width: number) => void
  latestSliderMode: boolean
}

const WorkspaceRecentView: React.FC<Props> = ({
  onPressProject,
  worspaceContentsWidth,
  setShortcutsLatestContentsWidth,
  latestSliderMode,
}) => {
  const { t } = useTranslation()
  const mobileWebView = useMobileView(768) //브라우저 웹:모바일 뷰 크기

  const { projectList, favoriteList, recentList, deleteFavorite } = useWork()

  const recentWorkList = useMemo(() => {
    return recentList.map((item) => {
      const project = projectList?.find((o) => o.id === item.id)
      const isFavoriteMark = favoriteList !== undefined && favoriteList.map(({ refKey }) => refKey).includes(item.id)
      return {
        id: item.id,
        isShared: false,
        isFavorite: false,
        isLatest: true,
        isFavoriteMark,
        workspaceName: `My WorkSpace`,
        workType: item.type.code,
        workName: item.title,
        onPressCard: () => {
          onPressProject && onPressProject(item.id)
        },
        onPressFavorite: () => {
          if (isFavoriteMark) {
            deleteFavorite(item.id)
          }
        },
        isLearnMoreSelect: false,
        learnMoreList: [],
        lastActivityAt: project ? project.lastActivityAt : item.lastActivityAt,
      }
    })
  }, [projectList, recentList, favoriteList, deleteFavorite])

  return (
    <>
      {recentList.length > 0 && (
        <View style={[styles.latestWork, mobileWebView && { width: worspaceContentsWidth }]}>
          <WorkcardBookmarkGroup
            sectionName={t('workspace.recent')}
            containerStyle={mobileWebView ? { alignSelf: 'auto', width: '100%' } : {}}
          >
            <WorkCardList
              mobileView={mobileWebView}
              setInnerWidth={setShortcutsLatestContentsWidth}
              sliderMode={latestSliderMode}
              list={recentWorkList}
              workItemStyle={{ width: 160 }}
              shortcuts
            />
          </WorkcardBookmarkGroup>
        </View>
      )}
    </>
  )
}

export default WorkspaceRecentView

const styles = StyleSheet.create({
  viewContainer: {
    paddingHorizontal: 20,
    paddingVertical: 30,
    backgroundColor: COLOR.darkmode.warm800,
  },
  topArea: {
    marginBottom: 8,
    zIndex: 99,
  },
  searchArea: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 12,
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: COLOR.darkmode.warm500,
  },
  searchResultArea: {
    maxHeight: 230,
  },
  switchArea: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  guideArea: {
    marginBottom: 16,
  },
  shortcutsArea: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  latestWork: {
    marginRight: 12,
    marginBottom: 8,
    zIndex: 9,
  },
  sortArea: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 32,
    marginBottom: 12,
  },

  workspaceContainer: {
    backgroundColor: COLOR.darkmode.cool900,
    borderRadius: 8,
    paddingHorizontal: 20,
    paddingTop: 16,
    paddingBottom: 20,
  },
  workspaceTab: {
    marginBottom: 8,
  },
  workGroupArea: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: -12,
  },
  workArea: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 4,
  },
  workAreaMobile: {
    flexDirection: 'column-reverse',
  },
})
