import { Text } from '@rocket-atoms/text'
import React from 'react'
import { TouchableOpacity, StyleSheet } from 'react-native'

interface Props {
  text: string
  wide?: boolean
  onPress: () => void
}

export const TextButton: React.FC<Props> = (props) => {
  return (
    <TouchableOpacity
      style={[
        style.container,
        {
          width: props.wide === true ? '100%' : 'auto',
        },
      ]}
      onPress={props.onPress}
    >
      <Text fontName="subTextRegular" fontColor="mono.paleBlack">
        {props.text}
      </Text>
    </TouchableOpacity>
  )
}

const style = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    textAlign: 'center',
    minHeight: 30,
    justifyContent: 'center',
  },
})
