export function codeFromEvent(event: React.KeyboardEvent<HTMLDivElement>) {
  return event.nativeEvent.key
}

export function isEscapeKey(code: string) {
  return code === 'Escape'
}

export function isCompleteKey(code: string) {
  return code === 'Tab' || code === 'Enter' || code === 'Space'
}

export function isDirectionalKey(code: string) {
  return (
    code === 'ArrowUp' ||
    code === 'ArrowDown' ||
    code === 'ArrowLeft' ||
    code === 'ArrowRight'
  )
}

export function getCurrentCaretPosition(target: HTMLDivElement) {
  const selection = window.getSelection()
  if (selection) {
    const range = selection.getRangeAt(0)
    const preCaretRange = range.cloneRange()
    preCaretRange.selectNodeContents(target)
    preCaretRange.setEnd(range.endContainer, range.endOffset)
    return preCaretRange.toString().length
  }
  return -1
}

export function restoreCaretPosition(
  target: HTMLDivElement,
  caretPosition?: number,
) {
  if (caretPosition && caretPosition > -1) {
    const selection = window.getSelection()
    const t = target.childNodes[0]
    if (!t?.textContent) return
    const destination = caretPosition
    if (selection) {
      const range = document.createRange()
      if (destination > t?.textContent?.length) {
        console.warn('destination is out of range', {
          destination,
          length: t?.textContent?.length,
        })
      }

      range.setStart(
        t,
        destination > t?.textContent?.length
          ? t?.textContent?.length
          : destination,
      )
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }
}
