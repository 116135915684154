import { useTranslation } from 'react-i18next'

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useToast } from '@rui/atoms'

import { View } from '@rocket-mono/foundations'
import { CardGatheringFormType, useModal, useWorkCard, useWorkChannel } from '@rocket-mono/providers'

import { Select } from '@rocket-mono/foundations'
import { LayoutPayload, changeItemAtIndex, removeItemAtIndex, replaceItemAtIndex } from '@rocket-mono/libs'
import { ClickOutside } from '@rui/foundations'
import { CardRowGroup } from '../../components/CardRowGroup'
import CardGatheringCreateModal from './components/CardGatheringCreateModal'
import CardGatheringEdit from './components/CardGatheringEdit'
import type { EditProps } from './types'

export type UpdateGatheringType = {
  visible: boolean
  layout: LayoutPayload
  gathering: CardGatheringFormType
  index: number
}

const ScreenView: React.FC<EditProps> = ({ offset, gatherings, setGatherings }) => {
  const { t } = useTranslation()

  const { show: showToastMessage } = useToast()

  const { currentChannelMembers } = useWorkChannel()
  const { currentCard, addCardEntry } = useWorkCard()

  const { Modal, visible, open, close } = useModal('card-gathering-add')

  const [updateGathering, setUpdateGathering] = useState<UpdateGatheringType>()

  const addGathering = useCallback(
    (item: CardGatheringFormType) => {
      setGatherings([...gatherings, item])
    },
    [gatherings],
  )

  // const getContents = useCallback((text) => {
  //   let source = text ?? ''
  //   const matcher = new RegExp(/<m(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+(\/?)>/, 'g')
  //   const matches = source.match(matcher)
  //   const elements: string[] = []

  //   if (matches) {
  //     matches.forEach((match) => {
  //       const [before, _] = source.split(match)
  //       if (before) {
  //         elements.push(before)
  //       }

  //       console.log('replaceValue', match)

  //       const ttt = [MENTION_TEMPLATE, CARD_LINK_TEMPLATE]
  //         .map((o) => {
  //           return new RegExp(o, 'g').exec(match)?.groups
  //         })
  //         .find((o) => o !== undefined)

  //       if (ttt !== undefined) {
  //         let { type, cardNo, memberName } = ttt

  //         if (type === undefined && cardNo !== undefined) {
  //           type = 'CARD_LINK'
  //         } else if (type === undefined && memberName !== undefined) {
  //           type = 'MENTION'
  //         }

  //         if (type === 'CARD_LINK') {
  //           const { cardNo } = ttt
  //           elements.push(`#${cardNo}`)
  //         } else if (type === 'MENTION') {
  //           const { memberName } = ttt
  //           elements.push(`@${memberName}`)
  //         } else {
  //           elements.push(match)
  //         }
  //       }

  //       source = _ ?? ''
  //     })
  //     return elements.join('')
  //   } else {
  //     return source
  //   }
  // }, [])

  const gatheringEditList = useMemo(() => {
    return gatherings?.map((o, index) => {
      console.log('gathering', o)

      // const contents = getContents(o.title)
      const contents = o.title
      return {
        id: o.id,
        contents,
        type: o.type,
        isPublic: o.viewPermission === 'EVERYONE',
        memberCount:
          o.targetOption === 'PUBLIC' ? t('cardGathering.edit.item.option.member.public') : o.assigneeIds?.length ?? 0,
        onPressMore: (layout) => {
          setUpdateGathering({ visible: true, layout, gathering: o, index })
        },
        onPressEdit: (layout) => {
          setUpdateGathering({ visible: false, layout, gathering: o, index })
          open()
        },
      }
    })
  }, [gatherings, currentChannelMembers])

  useEffect(() => {
    if (currentCard && currentCard.gatherings) {
      console.log('currentCard.gatherings', currentCard.gatherings)
      setGatherings(
        currentCard.gatherings
          .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
          .map(({ id, title, type, targetOption, viewPermission, assigneeList }, sequenceNumber) => ({
            id,
            title,
            sequenceNumber,
            type,
            targetOption,
            viewPermission,
            assigneeList: assigneeList ?? [],
            assigneeIds:
              assigneeList?.map(({ id, userId, userEmail }) => ({
                id,
                userId: String(userId),
                userEmail: userEmail ?? '',
              })) ?? [],
          })),
      )
    }

    return () => {
      setGatherings([])
    }
  }, [currentCard])

  const [isDismiss, setIsDismiss] = useState(true)
  const handleDismiss = useCallback(() => {
    if (isDismiss) close()
  }, [isDismiss])

  const handleChangeItemAtIndex = useCallback(
    (from: number, to: number) => {
      setGatherings(changeItemAtIndex(gatherings, from, to))
    },
    [gatherings],
  )

  const handlePressSave = useCallback(
    (gathering) => {
      if (updateGathering === undefined) {
        addGathering(gathering)
      } else {
        setGatherings(replaceItemAtIndex(gatherings, updateGathering.index, gathering))
        showToastMessage({
          type: 'Success',
          title: t('toast.confirmed'),
          position: 'TOP_CENTER',
        })
      }
    },
    [gatherings, addGathering, updateGathering],
  )

  const handlePressClose = useCallback(() => {
    setUpdateGathering(undefined)
    close()
  }, [])

  console.log('gatheringEditList', gatherings, gatheringEditList)

  return (
    <>
      <Modal
        visible={visible}
        onDismiss={handleDismiss}
        containerStyle={{ overflow: 'hidden', borderRadius: 8, minWidth: 400 }}
      >
        <CardGatheringCreateModal
          updateGathering={updateGathering}
          setIsDismiss={setIsDismiss}
          onPressClose={handlePressClose}
          onPressSave={handlePressSave}
        />
      </Modal>
      <CardRowGroup
        title={t('card.collect.id')}
        iconName="file-check-o"
        iconContainerStyle={{
          alignItems: 'stretch',
        }}
      >
        <View style={{ flex: 1 }}>
          <CardGatheringEdit list={gatheringEditList} onChangeItemAtIndex={handleChangeItemAtIndex} onPressAdd={open} />
        </View>
      </CardRowGroup>
      {updateGathering && updateGathering.visible && (
        <View
          style={{
            position: 'absolute',
            top: updateGathering.layout.y + offset + 32 ?? 0,
            right: updateGathering.layout.x ?? 0,
            zIndex: 99,
          }}
        >
          <ClickOutside
            onClickOutside={() => {
              setUpdateGathering(undefined)
            }}
          >
            <Select
              visible
              mode={'LIGHT'}
              list={[
                {
                  beforeDiv: false,
                  afterDiv: true,
                  group: [
                    {
                      type: 'COMMON',
                      label: t('card.collectunit.edit'),
                      onPress: () => {
                        setUpdateGathering((prev) => (prev !== undefined ? { ...prev, visible: false } : undefined))
                        open()
                      },
                    },
                    {
                      type: 'COMMON',
                      label: t('cardtodoedit.dropdown.delete'),
                      onPress: () => {
                        if (updateGathering) setGatherings(removeItemAtIndex(gatherings, updateGathering.index))
                        setUpdateGathering(undefined)
                      },
                    },
                  ],
                },
              ]}
            />
          </ClickOutside>
        </View>
      )}
    </>
  )
}

export default ScreenView
