/**
* 이벤트 발생시(복사, 삭제) 한번 더 묻는 모달
ModalBottom - APP
ModalDialog - WEB
 */

import * as React from 'react'

import { DialogView } from '@rocket-mono/foundations'
import { LayoutPayload, removeItemAtIndex } from '@rocket-mono/libs'
import { ChannelMember } from '@rocket/types'
import { useTranslation } from 'react-i18next'
import { Modal, StyleSheet, View } from 'react-native'
import DropdownListGroupV2 from '../DropdownListGroup'
import InputTodoV2 from '../InputTodoV2'
// import { CardLinkTargetPicker, MentionTargetPicker } from '../SendTargetPicker'
import { CardLinkProvider } from '../cardLink'
import { MentionProvider } from '../mention'
import { useWorkCardTodo } from '../providers'
import Context from './context'
import { CardTodoAssigneeModal, CardTodoDateModal, CardTodoFileModal } from './modal'
import type { Dialog, DropDown, ModalType, ProviderProps, ShowModal } from './types'
import { CardLinkTargetPicker } from '../SendTargetPicker/CardLinkTargetPicker'
import { MentionTargetPicker } from '../SendTargetPicker/MentionTargetPicker'

const initTodo = () => ({
  content: '',
  fileOption: {
    isUploadable: true,
    accessPermission: 'EVERYONE' as const,
  },
  files: [],
  isAssigneeCheck: false,
  isDateCheck: false,
  isFileCheck: false,
})

const Provider = ({
  astro,
  currentUser,
  channelId,
  relatedDomain,
  relatedDomainId,
  projectId,
  boardRoomId,
  triggerList,
  inputRef,
  // onChangeEditIndex,
  children,
}: ProviderProps) => {
  const { i18n, t } = useTranslation()
  const {
    list: workCardTodoList,
    add,
    copy,
    change,
    changeIndex,
    deleteTodo,
    projectMemberEntries,
    cardEntries,
    newTodo,
    setNewTodo,
  } = useWorkCardTodo()
  const [selectedTodoKey, setSelectedTodoKey] = React.useState<string[]>([])
  const [showModal, setShowModal] = React.useState<ShowModal>()
  const [dropdownList, setDropdownList] = React.useState<DropDown>()
  const [channelList, setChannelList] = React.useState<ChannelMember[]>([])
  const [dialog, setDialog] = React.useState<Dialog>()
  const [todoListShowModal, setTodoListShowModal] = React.useState<ShowModal[]>([])

  React.useEffect(() => {
    void astro
      .readChannelMemberList(channelId)
      .then((channelList) => setChannelList(channelList.filter((member) => member.joined)))
  }, [astro, channelId])

  const newTodoDate = React.useMemo(() => {
    if (!newTodo || !newTodo.periodOption) return undefined
    if (newTodo.periodOption.fromPeriodDate && newTodo.periodOption.toPeriodDate) {
      const from = new Date(newTodo.periodOption.fromPeriodDate)
      const to = new Date(newTodo.periodOption.toPeriodDate)
      return `${t('format.date.L', { date: from })} - ${t('format.date.L', {
        date: to,
      })}`
    } else if (newTodo.periodOption.fromPeriodDate) {
      const from = new Date(newTodo.periodOption.fromPeriodDate)
      return `${t('format.date.L', { date: from })} - `
    } else if (newTodo.periodOption.toPeriodDate) {
      const to = new Date(newTodo.periodOption.toPeriodDate)
      return ` - ${t('format.date.L', {
        date: to,
      })}`
    } else {
      return undefined
    }
  }, [newTodo, t])

  const list = React.useMemo(() => {
    if (!workCardTodoList) return
    return workCardTodoList.map((todo, index) => ({
      ...todo,
      placeholder: '',
      isCheck: selectedTodoKey.filter((key) => key === todo.key).length > 0,
      fileUpload: !!todo.files,
      completeTodo: todo.isDone ? todo.isDone : false,
      progress: todo.isDone ? (todo.isDone ? '100%' : '0%') : '0%',
      onChangeTodo: (content: string) => {
        change({ ...todo, content }, index)
      },
      onChangeCheck: () => {
        if (selectedTodoKey.includes(todo.key ?? '')) {
          setSelectedTodoKey(selectedTodoKey.filter((key) => key !== todo.key))
        } else {
          setSelectedTodoKey([...selectedTodoKey, todo.key ?? ''])
        }
      },
      modal: todoListShowModal.find((item) => item.todo.key === todo.key),
      onPressUser: () => {
        // console.log('onPressUser', payload)
      },
      onPressDate: () => {
        // console.log('onPressDate', payload)
      },
      onPressFile: () => {
        // console.log('onPressFile', payload)
      },
      onPressMore: (payload: LayoutPayload) => {
        // console.log('onPressMore', payload)
        setDropdownList({
          layoutPayload: payload,
          selectedKeyList: [todo.key || ''],
          dropdownList: [
            {
              group: [
                // {
                //   id: 1,
                //   title: t('cardtodoedit.dropdown.move'),
                //   code: 'move',
                // },
                {
                  id: 2,
                  title: t('cardtodoedit.dropdown.copy'),
                  code: 'copy',
                },
                // {
                //   id: 3,
                //   title: t('cardtodoedit.dropdown.newcard'),
                //   code: 'newcard',
                // },
              ],
            },
            {
              group: [
                {
                  id: 4,
                  title: t('cardtodoedit.dropdown.delete'),
                  code: 'delete',
                },
              ],
            },
          ],
        })
      },
    }))
  }, [workCardTodoList, selectedTodoKey, change, todoListShowModal, t])

  const onAllCheck = React.useCallback(() => {
    if (!workCardTodoList) return
    if (selectedTodoKey.length === workCardTodoList.length) {
      setSelectedTodoKey([])
    } else {
      setSelectedTodoKey(workCardTodoList.map((todo) => todo.key || ''))
    }
  }, [workCardTodoList, selectedTodoKey])

  const onDialogCopy = React.useCallback(() => {
    setDialog({
      title: t('cardtodoedit.dialog.copy.title'),
      message: t('cardtodoedit.dialog.copy.message'),
      list: [
        {
          name: t('cardtodoedit.dialog.copy.button'),
          action: () => {
            copy(selectedTodoKey)
            setSelectedTodoKey([])
          },
        },
      ],
      cancelText: t('cardtodoedit.dialog.cancel'),
      onCancel: () => {
        console.log('onCancel')
      },
    })
  }, [selectedTodoKey, t, copy])

  const onDialogDelete = React.useCallback(() => {
    setDialog({
      title: t('cardtodoedit.dialog.delete.title'),
      message: t('cardtodoedit.dialog.delete.message'),
      list: [
        {
          name: t('cardtodoedit.dialog.delete.button'),
          action: () => {
            if (workCardTodoList) {
              const todoList = workCardTodoList.filter((item) => selectedTodoKey.includes(item.key || ''))
              deleteTodo(todoList)
            }
          },
        },
      ],
      cancelText: t('cardtodoedit.dialog.cancel'),
      onCancel: () => {
        console.log('onCancel')
      },
    })
  }, [selectedTodoKey, t, deleteTodo, workCardTodoList])

  const onShowModal = React.useCallback(
    (key: string, modalType: ModalType, payload: LayoutPayload) => {
      // console.log('onShowModal', key, modalType)
      const $todo = workCardTodoList?.find((item) => item.key === key)
      const $todoIndex = workCardTodoList?.findIndex((item) => item.key === key)
      const $showModalIndex = todoListShowModal.findIndex((item) => item.todo.key === key)
      if (!$todo) return
      if ($todoIndex === undefined || $todoIndex === -1) return
      if ($showModalIndex > -1) {
        setTodoListShowModal((prev) => {
          const index = prev.findIndex((item) => item.todo.key === key)
          if (index > -1) {
            return removeItemAtIndex(prev, index)
          } else {
            return prev
          }
        })
      }
      if (modalType === 'assignee') {
        if (!$todo.isAssigneeCheck) {
          setTodoListShowModal((prev) => {
            const newTodoListShowModal = [...prev]
            newTodoListShowModal.push({
              astro,
              type: 'assignee',
              layoutPayload: payload,
              todo: $todo,
              users: channelList,
              relatedDomain,
              relatedDomainId,
              i18n,
              currentUserId: currentUser.id,
              onClose: () => {
                setTodoListShowModal((prev) => {
                  const index = prev.findIndex((item) => item.todo.key === key)
                  if (index > -1) {
                    return removeItemAtIndex(prev, index)
                  } else {
                    return prev
                  }
                })
              },
              onSave: ($$todo) => {
                change($$todo, $todoIndex)
              },
            })
            return newTodoListShowModal
          })
        } else {
          change({ ...$todo, isAssigneeCheck: false }, $todoIndex)
        }
      } else if (modalType === 'date') {
        if (!$todo.isDateCheck) {
          setTodoListShowModal((prev) => {
            const newTodoListShowModal = [...prev]
            newTodoListShowModal.push({
              astro,
              type: 'date',
              layoutPayload: payload,
              todo: $todo,
              users: channelList,
              relatedDomain,
              relatedDomainId,
              i18n,
              currentUserId: currentUser.id,
              onClose: () => {
                setTodoListShowModal((prev) => {
                  const index = prev.findIndex((item) => item.todo.key === key)
                  if (index > -1) {
                    return removeItemAtIndex(prev, index)
                  } else {
                    return prev
                  }
                })
              },
              onSave: ($$todo) => {
                change($$todo, $todoIndex)
              },
            })
            return newTodoListShowModal
          })
        } else {
          change({ ...$todo, isDateCheck: false }, $todoIndex)
        }
      } else if (modalType === 'file') {
        if (!$todo.isFileCheck) {
          setTodoListShowModal((prev) => {
            const newTodoListShowModal = [...prev]
            newTodoListShowModal.push({
              astro,
              type: 'file',
              layoutPayload: payload,
              todo: $todo,
              users: channelList,
              relatedDomain,
              relatedDomainId,
              projectId,
              boardRoomId,
              i18n,
              currentUserId: currentUser.id,
              onClose: () => {
                setTodoListShowModal((prev) => {
                  const index = prev.findIndex((item) => item.todo.key === key)
                  if (index > -1) {
                    return removeItemAtIndex(prev, index)
                  } else {
                    return prev
                  }
                })
              },
              onSave: ($$todo) => {
                change($$todo, $todoIndex)
              },
            })
            return newTodoListShowModal
          })
        } else {
          change({ ...$todo, isFileCheck: false }, $todoIndex)
        }
      }
    },
    [
      astro,
      boardRoomId,
      change,
      channelList,
      currentUser,
      i18n,
      projectId,
      relatedDomain,
      relatedDomainId,
      todoListShowModal,
      workCardTodoList,
    ],
  )

  const handleChangeItemAtIndex = React.useCallback(
    (from: number, to: number) => {
      changeIndex(from, to)
    },
    [changeIndex],
  )

  // targetPicker를 띄우기 위한 인풋 박스 높이 state
  const [inputHeight, setInputHeight] = React.useState(0)

  if (list === undefined) return <></>

  return (
    <Context.Provider
      value={{
        list,
        onAllCheck,
        onDialogCopy,
        onDialogDelete,
        onShowModal,
        handleChangeItemAtIndex,
        newTodo,
        onChangeNewTodo: setNewTodo,
      }}
    >
      <MentionProvider entries={projectMemberEntries}>
        <CardLinkProvider entries={cardEntries}>
          <View style={{ position: 'relative' }}>
            <View onLayout={(e) => setInputHeight(e.nativeEvent.layout.height)}>
              <InputTodoV2
                inputRef={inputRef}
                contents={newTodo.content ?? ''}
                users={newTodo.assignees ? newTodo.assignees.length : 0}
                date={newTodoDate}
                isFileUpload={!!newTodo.files}
                onChangeTodo={(content) => {
                  setNewTodo((prev) => ({ ...prev, content }))
                }}
                triggerList={triggerList}
                onPressUser={(payload: LayoutPayload) => {
                  // console.log('멤버', payload)
                  if (!newTodo.isAssigneeCheck) {
                    setShowModal({
                      astro,
                      type: 'assignee',
                      layoutPayload: payload,
                      todo: newTodo,
                      users: channelList,
                      relatedDomain,
                      relatedDomainId,
                      i18n,
                      currentUserId: currentUser.id,
                      onClose: () => {
                        setShowModal(undefined)
                      },
                      onSave: (todo) => {
                        setNewTodo(todo)
                        setShowModal(undefined)
                      },
                    })
                  } else {
                    setNewTodo((prev) => ({
                      ...prev,
                      isAssigneeCheck: false,
                    }))
                  }
                }}
                onPressDate={(payload: LayoutPayload) => {
                  // console.log('일자', payload)
                  if (!newTodo.isDateCheck) {
                    setShowModal({
                      astro,
                      type: 'date',
                      layoutPayload: payload,
                      todo: newTodo,
                      users: channelList,
                      relatedDomain,
                      relatedDomainId,
                      i18n,
                      currentUserId: currentUser.id,
                      onClose: () => {
                        setShowModal(undefined)
                      },
                      onSave: ($todo) => {
                        setNewTodo($todo)
                        setShowModal(undefined)
                      },
                    })
                  } else {
                    setNewTodo((prev) => ({
                      ...prev,
                      isDateCheck: false,
                    }))
                  }
                }}
                onPressFile={(payload: LayoutPayload) => {
                  // console.log('파일 여부', payload)
                  if (!newTodo.isFileCheck) {
                    setShowModal({
                      astro,
                      type: 'file',
                      layoutPayload: payload,
                      todo: newTodo,
                      users: channelList,
                      relatedDomain,
                      relatedDomainId,
                      projectId,
                      boardRoomId,
                      i18n,
                      currentUserId: currentUser.id,
                      onClose: () => {
                        setShowModal(undefined)
                      },
                      onSave: ($todo) => {
                        setNewTodo($todo)
                        setShowModal(undefined)
                      },
                    })
                  } else {
                    setNewTodo((prev) => ({
                      ...prev,
                      isFileCheck: false,
                    }))
                  }
                }}
                onPressSend={() => {
                  // console.log('입력')
                  // add(`새로운 할일 - ${new Date().getTime()}`, false)
                  const {
                    content,
                    assignees,
                    files,
                    periodOption,
                    fileOption,
                    isAssigneeCheck,
                    isDateCheck,
                    isFileCheck,
                  } = newTodo
                  add(
                    content ?? '',
                    !!assignees,
                    isAssigneeCheck,
                    isDateCheck,
                    isFileCheck,
                    assignees,
                    files,
                    periodOption,
                    fileOption,
                  )
                  setNewTodo(initTodo())
                }}
                onPressInputEnterKey={() => {
                  const {
                    content,
                    assignees,
                    files,
                    periodOption,
                    fileOption,
                    isAssigneeCheck,
                    isDateCheck,
                    isFileCheck,
                  } = newTodo
                  add(
                    content ?? '',
                    !!assignees,
                    isAssigneeCheck,
                    isDateCheck,
                    isFileCheck,
                    assignees,
                    files,
                    periodOption,
                    fileOption,
                  )
                  setNewTodo(initTodo())
                }}
                isAssigneeCheck={newTodo.isAssigneeCheck}
                isDateCheck={newTodo.isDateCheck}
                isFileCheck={newTodo.isFileCheck}
              />
            </View>
            <View
              style={{
                position: 'absolute',
                top: inputHeight - 40,
                left: 0,
                width: '100%',
                zIndex: 999,
              }}
            >
              <MentionTargetPicker
                direction="downside"
                marginLeft={26}
                marginRight={26}
                marginBottom={0}
                // marginTop={-190}
                // whisperHeight={190}
              />
              <CardLinkTargetPicker
                direction="downside"
                marginLeft={26}
                marginRight={26}
                marginBottom={0}
                // marginTop={-190}
                // whisperHeight={190}
              />
            </View>
          </View>
        </CardLinkProvider>
      </MentionProvider>
      {showModal && showModal.type === 'assignee' ? <CardTodoAssigneeModal {...showModal} /> : <></>}
      {showModal && showModal.type === 'date' ? <CardTodoDateModal {...showModal} /> : <></>}
      {showModal && showModal.type === 'file' ? <CardTodoFileModal {...showModal} /> : <></>}
      {children}
      {dropdownList ? (
        <DropdownListGroupV2
          code={''}
          isOpen={true}
          layoutPayload={dropdownList.layoutPayload}
          dropdownList={dropdownList.dropdownList}
          onCode={(code) => {
            // setDropDowncode(code)
            const keyList = [...dropdownList.selectedKeyList]
            if (code === 'delete') {
              setDialog({
                title: t('cardtodoedit.dialog.delete.title'),
                message: t('cardtodoedit.dialog.delete.message'),
                list: [
                  {
                    name: t('cardtodoedit.dialog.delete.button'),
                    action: () => {
                      if (workCardTodoList) {
                        const todoList = workCardTodoList.filter((item) => keyList.includes(item.key || ''))
                        deleteTodo(todoList)
                      }
                    },
                  },
                ],
                cancelText: t('cardtodoedit.dialog.cancel'),
                onCancel: () => {
                  console.log('onCancel')
                },
              })
            } else if (code === 'move') {
              console.log('이동')
            } else if (code === 'copy') {
              setDialog({
                title: t('cardtodoedit.dialog.copy.title'),
                message: t('cardtodoedit.dialog.copy.message'),
                list: [
                  {
                    name: t('cardtodoedit.dialog.copy.button'),
                    action: () => {
                      copy(keyList)
                    },
                  },
                ],
                cancelText: t('cardtodoedit.dialog.cancel'),
                onCancel: () => {
                  console.log('onCancel')
                },
              })
            } else if (code === 'newcard') {
              console.log('새카드로 만들기')
            }
            setDropdownList(undefined)
          }}
          onDismiss={() => {
            setDropdownList(undefined)
          }}
        />
      ) : (
        <></>
      )}
      {dialog ? (
        <Modal transparent>
          <View style={styles.dimmed}>
            <DialogView
              title={dialog.title}
              message={dialog.message}
              list={dialog.list}
              subList={dialog.subList}
              visible={!!dialog}
              cancelText={dialog.cancelText}
              onCancel={dialog.onCancel}
              onClose={() => {
                setDialog(undefined)
              }}
              onDismiss={() => setDialog(undefined)}
            />
          </View>
        </Modal>
      ) : (
        <></>
      )}
    </Context.Provider>
  )
}

const styles = StyleSheet.create({
  dimmed: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
})

export default Provider
