import * as React from 'react'
import { View, Text, StyleSheet, Platform } from 'react-native'
import { COLOR, FONT } from '@rocket-mono/libs'

export interface Props {
  text?: string
}

const DisabledItem = ({ text }: Props) => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{text}</Text>
    </View>
  )
}

export default DisabledItem

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR.mono.lightGray,
    paddingVertical: 10,
    paddingHorizontal: 15,
    ...Platform.select({ web: { cursor: 'not-allowed' } }),
  },
  text: {
    ...FONT.subTextRegular,
    color: COLOR.mono.darkGray,
    fontSize: 13,
    ...Platform.select({ native: { paddingTop: 3 } }),
  },
})
