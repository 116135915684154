import React, { useState } from 'react'
import { Pressable, StyleSheet } from 'react-native'

import { Icon } from '@rocket-atoms/icon'
import { COLOR, IconName } from '@rocket-mono/libs'

interface Props {
  iconName: IconName
  checked: boolean
  onPress?: () => void
}

export const WorkCardOptionButton: React.FC<Props> = ({ iconName, checked, onPress }) => {
  const [isPressed, setIsPressed] = useState(false)
  return (
    <Pressable
      style={[
        styles.optionButton,
        checked && { backgroundColor: COLOR.primary.blue700 },
        isPressed && styles.optionButtonPressed,
      ]}
      onPress={onPress}
      onPressIn={() => setIsPressed(true)}
      onPressOut={() => setIsPressed(false)}
    >
      <Icon name={iconName} size={18} color={checked ? 'mono.white' : 'gray.g400'} style={{ marginTop: -2 }} />
    </Pressable>
  )
}

const styles = StyleSheet.create({
  optionButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    backgroundColor: COLOR.mono.white,
    borderRadius: 8,
  },
  optionButtonPressed: { borderWidth: 1, borderColor: COLOR.primary.blue100 },
})
