import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FlatList, View } from 'react-native'

import {
  ActivitySearchList,
  ActivitySearchSectionSortFile,
  ActivitySearchItemFile,
  ActivitySearchItemFileProps,
} from '@rocket-molecules/activity'

import SearchIcon from '../../../components/SearchIcon'

import { FileIconType, ResultViewProps } from './types'
import { DropdownItemProps } from '@rocket/atoms'
import { SearchResultSkeleton } from './skeleton'
import { useTranslation } from 'react-i18next'
import { useSearchScreen } from '../../provider'
import { IndexedChatFileType, IndexedFileV2Type, PreviewFile, SearchFileType } from '../../types'
import { getFileType } from '../../utils'
import { printFileSize } from '@rocket-mono/libs'

interface Props extends ResultViewProps {
  list?: SearchFileType[]
}

export const SearchResultFileView: React.FC<Props> = ({ defaultCount, searchState, isFilter = false, list = [] }) => {
  const { t } = useTranslation()

  const { cdnUrl, astro, onSearchNext, onChangeTab, openPreview, onDownload, onChangeSearchCount } = useSearchScreen()

  const convert = useCallback(
    (value: SearchFileType): ActivitySearchItemFileProps => {
      let boardType: 'BOARD' | 'DIRECT' | 'DISCUSS' = 'BOARD'
      let isMe = false
      if (value.data.boardRoomType === 'M') isMe = true
      if (value.data.boardRoomType === 'D') boardType = 'DIRECT'
      if (value.data.boardIsDiscussionRoom) boardType = 'DISCUSS'

      const work = { type: value.data.projectTypeCode, name: value.data.projectTitle }
      const board = {
        color: value.data.boardColor,
        count: value.data.boardAttenderCount,
        name: isMe
          ? t('screen.chats.tome')
          : boardType === 'DIRECT'
          ? value.data.uploaderName
          : value.data.boardRoomName,
        type: boardType,
      }

      if (value.indexName === 'chat_files') {
        const data = value.data as IndexedChatFileType
        const type: FileIconType = getFileType(data.ext)

        return {
          work,
          board,
          file: {
            type,
            name: data.originalFilename,
            size: printFileSize(Number(data.fileSize)),
            date: t('format.date.LLL', { date: new Date(data.createdAt) }),
          },
          user: {
            id: String(data.uploaderId),
            name: data.uploaderName,
            profile: `${cdnUrl}/profile/${data.uploaderEmail}`,
          },

          onPress: ['PDF', 'IMG'].includes(type)
            ? () => {
                const { id, fileKey, filename, originalFilename } = data
                const file: PreviewFile = {
                  id: String(id),
                  title: originalFilename,
                  uri: `${cdnUrl}/images/${fileKey}/${filename}`,
                  uploadDate: t('format.date.LLL', { date: new Date(data.createdAt) }),
                  uploaderName: data.uploaderName,
                  uploaderProfile: `${cdnUrl}/profile/${data.uploaderEmail}`,
                  mimeType: data.ext,
                  workType: data.projectTypeCode,
                  workName: data.projectTitle,
                  boardName: data.boardRoomName,
                }
                openPreview('contents', fileKey, filename, [file], data.projectTypeCode, data.projectTitle, 0)
                //   if (openCardPreview) {
                //     openCardPreview('contents', [file], data.projectTypeCode, data.projectTitle, 0)
                //   } else {
                //     openPreview('contents', fileKey, filename, [file], data.projectTypeCode, data.projectTitle, 0)
                //   }
              }
            : undefined,
          onPressDownload: () => {
            const { fileKey, filename, originalFilename } = data
            onDownload(`${cdnUrl}/images/${fileKey}/${filename}`, originalFilename)
            // if (onDownload) {
            //   const { fileKey, filename, originalFilename } = data
            //   onDownload(`${option.secureCdnUrl}/images/${fileKey}/${filename}`, originalFilename)
            // } else {
            //   const { fileKey, filename, originalFilename } = data
            //   astro.fileDownload({ fileKey, filename, originalFilename })
            // }
          },
        }
      } else {
        const data = value.data as IndexedFileV2Type
        const type: FileIconType = getFileType(data.extension)
        return {
          work,
          board,
          file: {
            type,
            name: data.originalName,
            size: printFileSize(data.byteSize),
            date: t('format.date.LLL', { date: new Date(data.createdAt) }),
          },
          user: {
            id: String(data.userId),
            name: data.uploaderName,
            profile: `${cdnUrl}/profile/${data.uploaderEmail}`,
          },

          onPress: ['PDF', 'IMG'].includes(type)
            ? () => {
                const fileKey = data.relatedDomain || ''
                const filename = data.name || ''
                astro.readFilePresignedUrl(data.path).then((uri) => {
                  const { id } = data
                  const file: PreviewFile = {
                    id: String(id),
                    title: data.originalName,
                    uri,
                    uploadDate: t('format.date.LLL', { date: new Date(data.createdAt) }),
                    uploaderName: data.uploaderName,
                    uploaderProfile: `${cdnUrl}/profile/${data.uploaderEmail}`,
                    mimeType: data.mimeType,
                    workType: data.projectTypeCode,
                    workName: data.projectTitle,
                    boardName: data.boardRoomName,
                  }
                  openPreview('card', fileKey, filename, [file], data.projectTypeCode, data.projectTitle, 0)
                })

                //   if (openCardPreview) {
                //     openCardPreview('card', [file], data.projectTypeCode, data.projectTitle, 0)
                //   } else {
                //     openPreview('card', fileKey, filename, [file], data.projectTypeCode, data.projectTitle, 0)
                //   }
                // })
              }
            : undefined,
          onPressDownload: () => {
            astro.readFilePresignedUrl(data.path).then((url) => {
              onDownload(url, data.originalName)

              // if (Platform.OS === 'web') {

              // } else {
              //   console.log('onPressItem mobile')
              //   onDownload && onDownload(url, data.originalName)
              // }
            })
          },
        }
      }
    },
    [cdnUrl, astro, onDownload],
  )

  const [showCount, setShowCount] = useState<number | undefined>(defaultCount)
  const [searchType, setSearchType] = useState<string>('ALL')
  const [searchMember, setSearchMember] = useState<string[]>([])

  const filteredList = useMemo(
    () =>
      list
        .map(convert)
        .filter(({ file }) => {
          if (searchType === 'ALL') return true
          if (file) return file.type === searchType
          return false
        })
        .filter(({ user }) => {
          if (searchMember.length === 0) return true
          if (user && searchMember.length > 0) return searchMember.includes(user.id)
          return false
        }),
    [list, searchType, searchMember],
  )

  useEffect(() => {
    console.log('filteredList-file', filteredList)
    onChangeSearchCount('file', filteredList.length)
  }, [onChangeSearchCount, filteredList.length])

  const searchFileList = useMemo(() => {
    if (!list) return []

    const files: FileIconType[] = ['ALL']

    list.map(convert).map(({ file }) => {
      const is = files.filter((type) => file && type === file.type).length > 0
      if (!is && file !== undefined) files.push(file.type)
    })

    return files
  }, [list])
  const searchMemberList = useMemo(() => {
    if (!list) return []

    const users: DropdownItemProps[] = []

    list.map(convert).map(({ user }) => {
      const is = users.filter(({ code }) => user && code === user.id).length > 0
      if (!is && user !== undefined) users.push({ code: user.id, label: user.name, beforeProfileImage: user.profile })
    })

    return users
  }, [list])

  const isMore = useMemo(() => {
    if (showCount === undefined) return false
    return (filteredList?.length ?? 0) > showCount
  }, [showCount, filteredList])

  const handlePressMore = useCallback(() => {
    onChangeTab('FILE')
  }, [onChangeTab])

  const [page, setPage] = useState(0)
  useEffect(() => {
    if (page > 0) {
      onSearchNext('FILE', page)
    }
  }, [page, onSearchNext])

  return (
    <ActivitySearchList type="FILE" onPressMore={isMore ? handlePressMore : undefined}>
      <View style={{ zIndex: 10 }}>
        {isFilter && (
          <View
          // style={['done', 'last'].includes(searchState) && isFilter && { height: 100 }}
          >
            <ActivitySearchSectionSortFile
              defaultIndex={0}
              searchFileList={searchFileList}
              searchMemberList={searchMemberList}
              isSearchMember={searchMember.length > 0}
              onChangeCode={setSearchType}
              onChangeMember={setSearchMember}
            />
          </View>
        )}
      </View>

      <FlatList
        scrollEnabled={isFilter}
        contentContainerStyle={[!isFilter && { flex: 1 }, isFilter && { paddingBottom: 24 }]}
        data={filteredList.slice(0, showCount)}
        renderItem={({ item }) => {
          return <ActivitySearchItemFile {...item} />
        }}
        onEndReached={() => {
          if (isFilter && searchState === 'done') setPage((prev) => prev + 1)
        }}
        onEndReachedThreshold={1}
        ListEmptyComponent={
          <>
            {searchState === 'ready' && (
              <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <SearchIcon type={'searching'} textSize={'medium'} />
              </View>
            )}
            {['done', 'last'].includes(searchState) && isFilter && (
              <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <SearchIcon type="file" text={t('nocontentsicon.search.text')} />
              </View>
            )}
          </>
        }
        ListFooterComponent={
          <>
            {searchState === 'searching' && (
              <SearchResultSkeleton filteredList={filteredList} defaultCount={defaultCount} searchState={searchState}>
                <ActivitySearchItemFile />
              </SearchResultSkeleton>
            )}
          </>
        }
      />
    </ActivitySearchList>
  )
}
