import * as React from 'react'
import type { LayoutChangeEvent } from 'react-native'
import { useTranslation } from 'react-i18next'

const WHISPER_BUTTON_WIDTH = 84
const FONT_WIDTH = 12

export const useEllipsis = (writerNames: string[]) => {
  const { t } = useTranslation()
  const [parentWidth, setParentWidth] = React.useState(0)
  const childWidth = React.useRef(0)
  const onLayoutParent = ({
    nativeEvent: {
      layout: { width },
    },
  }: LayoutChangeEvent) => {
    setParentWidth(width - WHISPER_BUTTON_WIDTH)
  }

  const onLayoutChild = ({
    nativeEvent: {
      layout: { width },
    },
  }: LayoutChangeEvent) => {
    childWidth.current = width
  }

  const typings = `${printWriters()}${t(getI18nKey(), {
    othersCount: writerNames.slice(2).length,
  })}`

  function getI18nKey() {
    return `chatinput.typing${getPlural()}`

    function getPlural() {
      if (writerNames.length === 1) {
        return 'one'
      }

      if (writerNames.length === 2) {
        return 'two'
      }

      return 'others'
    }
  }

  function printWriters() {
    const [firstWriterName, secondWriterName] = writerNames

    return join(firstWriterName, secondWriterName)

    function join(string1: string, string2: string) {
      if (!string2) {
        return string1
      }

      return `${string1}, ${string2}`
    }
  }

  const isEllipsis = parentWidth <= typings.length * FONT_WIDTH

  return { isEllipsis, onLayoutChild, onLayoutParent }
}
