import { SkeletonWorkflowRuleList } from '@rocket/skeleton'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import EmptyView from '../../components/EmptyView'
import { useRule } from '../hooks'
import CreateView from '../view/CreateView'
import ListView from '../view/ListView'

const Body: React.FC = () => {
  const { isMobile, ruleList, step, onCreateRule } = useRule()

  if (!ruleList)
    return (
      <View style={styles.skeletonContainer}>
        <SkeletonWorkflowRuleList />
      </View>
    )

  const emptyContainer = React.useMemo(() => {
    if (isMobile) {
      return {
        marginTop: 66,
      }
    } else {
      return {
        marginTop: 103,
      }
    }
  }, [isMobile])

  if (step === 'VIEW' && ruleList.length === 0) {
    return (
      <View style={emptyContainer}>
        <EmptyView onPress={onCreateRule} />
      </View>
    )
  } else if (step === 'VIEW' && ruleList.length > 0) {
    return <ListView />
  } else if (step === 'CREATE') {
    return <CreateView />
  } else {
    return <></>
  }
}

const styles = StyleSheet.create({
  skeletonContainer: {
    paddingVertical: 16,
    paddingHorizontal: 20,
  },
})

export default Body
