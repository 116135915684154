import * as React from 'react'

import { useRecoilState } from 'recoil'
import { bottomAlertMessageState, qrLinkState } from '../recoil'

import { CurrentUserProvider, Dimmed, ModalBottomAlert, View, WorkBoardScreen } from '@rocket/components'

import { useAstro, useWorkProject } from '@rocket-mono/providers'

import BoardView from './BoardView'
import LinkInviteModal from './LinkInviteModal'

import { RuleScreen } from '@rocket-screens/rule'

interface Props {
  projectId?: string
}
const BoardScreen: React.FC<Props> = ({ projectId }) => {
  const { astro, astroNav } = useAstro()
  const { currentProjectMember, totalCardCount, doneCardCount, currentAutomationList } = useWorkProject()
  const [bottomAlertMessage, setBottomAlertMessage] = useRecoilState(bottomAlertMessageState)

  const [qrLink, setQrLink] = useRecoilState(qrLinkState)

  const [selectedBoardId, setSelectedBoardId] = React.useState<string>()
  const [ruleScreenVisible, setRuleScreenVisible] = React.useState(false)

  const handlePressWorkEdit = React.useCallback(() => {
    if (projectId) {
      const action = 'project'
      const params = {
        projectId,
      }
      console.log('handlePressWorkEdit', action)
      astroNav.emit(action, {
        type: 'update_position',
        params,
      })
    }
  }, [projectId])

  const handlePressRoleEdit = React.useCallback(() => {
    if (projectId) {
      setRuleScreenVisible(true)
    }
  }, [projectId])

  const handlePressRuleClose = React.useCallback(() => {
    setRuleScreenVisible(false)
    setSelectedBoardId(undefined)
  }, [])

  const handlePressBoardRole = React.useCallback((selectedBoardId: string) => {
    setSelectedBoardId(selectedBoardId)
    setRuleScreenVisible(true)
  }, [])

  /**
   * CREATE_CARD onboarding
   */
  React.useEffect(() => {
    if (currentProjectMember && currentProjectMember.auth === 'OWNER') {
      astroNav.emit('onboarding', { type: 'CREATE_CARD' })
    }
  }, [astroNav, currentProjectMember])

  const ruleCount = React.useMemo(() => {
    return currentAutomationList?.filter((automation) => automation.isEnabled).length
  }, [currentAutomationList])

  return (
    <View style={{ width: '100%', height: '100%' }}>
      {qrLink && <LinkInviteModal url={qrLink} onDismiss={() => setQrLink(null)} />}

      <CurrentUserProvider astro={astro}>
        <WorkBoardScreen
          astro={astro}
          projectId={projectId}
          memberId={currentProjectMember?.id}
          progressCount={totalCardCount - doneCardCount}
          doneCount={doneCardCount}
          ruleCount={ruleCount}
          onPressWorkEdit={handlePressWorkEdit}
          onPressRoleEdit={handlePressRoleEdit}
        >
          <View style={{ width: '100%', height: '100%' }}>
            <BoardView handlePressBoardRole={handlePressBoardRole} />
          </View>
        </WorkBoardScreen>
      </CurrentUserProvider>
      {!!bottomAlertMessage && (
        <Dimmed
          backgroundColor="mono.black"
          opacity={0.75}
          onDismiss={() => {
            console.log('dimmed')
          }}
        >
          <ModalBottomAlert
            message={bottomAlertMessage.message}
            list={[
              {
                name: bottomAlertMessage.apply.text,
                action: bottomAlertMessage.apply.action,
              },
            ]}
            submitText={bottomAlertMessage.apply.text}
            cancelText={bottomAlertMessage.cancel.text}
            visible={!!bottomAlertMessage}
            onDismiss={() => setBottomAlertMessage(undefined)}
            onCancel={bottomAlertMessage.cancel.action}
            onClose={() => setBottomAlertMessage(undefined)}
          />
        </Dimmed>
      )}
      {projectId ? (
        <RuleScreen
          visible={ruleScreenVisible}
          projectId={projectId}
          selectedBoardId={selectedBoardId}
          onPressClose={handlePressRuleClose}
        />
      ) : (
        <></>
      )}
    </View>
  )
}

export default BoardScreen
