import { useParams } from 'react-router-dom'
import ProjectBoardScreen from '@rocket-mono/screen-project-board'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ProjectBoard: React.FC = () => {
  const { projectId } = useParams()
  const { i18n } = useTranslation()

  return <ProjectBoardScreen i18n={i18n} projectId={projectId} />
}

export default ProjectBoard
