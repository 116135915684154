import React from 'react'
import { StyleSheet } from 'react-native'
import { COLOR } from '@rui/foundations'
import { View } from '@rocket-mono/foundations'

export const ShortcutsSkeleton: React.FC = () => {
  return <View style={styles.item}></View>
}

const styles = StyleSheet.create({
  item: {
    width: 200,
    height: 128,
    backgroundColor: COLOR.darkmode.cool900,
  },
})
