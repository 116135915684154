import * as React from 'react'
import { StyleSheet, Text, TextInput, View } from 'react-native'

import ButtonIcon from '../ButtonIcon'
import Input from './Input'
import Reply, { ReplyProps } from './Reply'
import Typings from './Typings'
import WhisperButton, { WhisperTargetState } from './WhisperButton'

import { useEllipsis } from './useEllipsis'

import { Icon } from '@rocket-atoms/icon'
import { COLOR, FONT } from '@rocket-mono/libs'
import { useTranslation } from 'react-i18next'
import type { MessageTextReplacer } from '../Message/types'
import { JSXToPlainText, MENTION_TEMPLATE, parseMessage } from '../utils/message'
import { ChatPreviewFileList, ChatPreviewFileType } from './ChatPreviewFileList'

const MAX_FILE_COUNT = 6

interface Props {
  value: string
  writerNames?: string[]
  userOfWhisper?: { name: string; status: WhisperTargetState }
  reply?: ReplyProps | null
  mentionList?: string[]
  clearWhenSubmit?: boolean
  onPressWhisperButton?: () => void
  onChangeText: (value: string) => void
  onPressInputEnterKey?: (text: string) => void
  onPressSendButton: (text: string) => void
  onPressUploadButton?: () => void
  onFocus?: () => void
  onBlur?: () => void
  onCancel?: () => void
  inputRef: React.RefObject<TextInput>
  chatPreviewFileList?: ChatPreviewFileType[]
  onChatPreviewFileRemove?: (index: number) => void
}

const ChatInput = ({
  writerNames = [],
  userOfWhisper,
  reply,
  onPressWhisperButton,
  onPressSendButton,
  onPressUploadButton,
  onCancel,
  inputRef,
  chatPreviewFileList,
  onChatPreviewFileRemove,
  ...inputProps
}: Props) => {
  const { t } = useTranslation()
  const { isEllipsis, onLayoutParent } = useEllipsis(writerNames)
  const [value, setValue] = React.useState(inputProps.value)

  const mentionReplacer: MessageTextReplacer = React.useCallback((source, alias, index) => {
    const { chatRoomMemberNo: memberNo, memberName } = new RegExp(MENTION_TEMPLATE).exec(source)?.groups ?? {
      chatRoomMemberNo: '',
      memberName: '',
    }

    if (memberNo && memberName) {
      console.debug({ memberNo, memberName })
      return <Text key={`mention-${alias}-${index}`}>@{memberName}</Text>
    } else {
      return <Text key={`token-${alias}-${index}`}>{source}</Text>
    }
  }, [])

  const sanitizedValue = React.useMemo(() => {
    return parseMessage(
      value,
      [
        {
          format: MENTION_TEMPLATE,
          replacer: mentionReplacer,
        },
      ],
      {},
    )
      .map((elm) => JSXToPlainText(elm))
      .join('')
  }, [value, mentionReplacer])

  const onChangeText = React.useCallback(
    (text: string) => {
      console.debug('onChangeText', { text })
      inputProps.onChangeText?.(text)
      setValue(text)
    },
    [inputProps],
  )
  React.useEffect(() => {
    console.debug({ sanitizedValue })
  }, [sanitizedValue])

  const isChatPreviewFileList = React.useMemo(() => {
    return chatPreviewFileList && chatPreviewFileList.length > 0
  }, [chatPreviewFileList])

  const height = React.useMemo(() => {
    return isChatPreviewFileList ? 121 + 68 : 121
  }, [isChatPreviewFileList])

  const badgeCountWidth = React.useMemo(() => {
    if (chatPreviewFileList === undefined) return 0
    const countStr = String(chatPreviewFileList.length)
    return countStr.length * 7
  }, [chatPreviewFileList])

  const backMaxCountWidth = React.useMemo(() => {
    if (MAX_FILE_COUNT === undefined) return 0
    const countStr = String(MAX_FILE_COUNT)
    return countStr.length * 7
  }, [])

  const uploadButtonWidth = React.useMemo(() => {
    if (chatPreviewFileList && chatPreviewFileList.length > 0) {
      return 28 + badgeCountWidth + backMaxCountWidth + 4
    } else {
      return 28
    }
  }, [badgeCountWidth, backMaxCountWidth, chatPreviewFileList])

  return (
    <View style={[styles.container, { height }]}>
      <View style={[styles.topContainer, { backgroundColor: onCancel ? COLOR.mono.paleBlack : '#F1F2F3' }]}>
        <View style={styles.topInnerLeftContainer} onLayout={onLayoutParent}>
          {onCancel && <Text style={styles.messageModifyText}>{t('chat.edit')}</Text>}
          {onPressWhisperButton && !onCancel && (
            <WhisperButton
              userName={userOfWhisper?.name}
              status={userOfWhisper?.status}
              onPress={onPressWhisperButton}
            />
          )}
          {writerNames.length > 0 && !onCancel && (
            <View style={styles.typings}>
              <Typings writerNames={writerNames} isEllipsis={isEllipsis} />
            </View>
          )}
          {reply && <Reply {...reply} />}
        </View>
        {onCancel && (
          <View style={styles.topInnerRightContainer}>
            <ButtonIcon
              iconName="close"
              iconColor="mono.white"
              iconSize={16}
              containerSize={28}
              rounded
              onPress={onCancel}
              style={styles.cancelButton}
            />
          </View>
        )}
        {onPressUploadButton && (
          <View style={[styles.topInnerRightContainer, { width: uploadButtonWidth }]}>
            <ButtonIcon
              rounded
              iconName="cloud-upload-o"
              backgroundColor="mono.white"
              iconColor="mono.paleBlack"
              containerSize={28}
              iconSize={16}
              iconViewbox="0 -100 1000 1000"
              onPress={onPressUploadButton}
              badgeSide="left"
              badgeType="MAX_COUNT_AND_CURRENT_COUNT"
              badgeCount={chatPreviewFileList?.length}
              badgeMaxCount={MAX_FILE_COUNT}
            />
          </View>
        )}
      </View>
      {chatPreviewFileList && chatPreviewFileList.length > 0 ? (
        <ChatPreviewFileList files={chatPreviewFileList} onRemove={onChatPreviewFileRemove} />
      ) : (
        <></>
      )}
      <View
        style={{
          borderTopWidth: 1,
          borderTopColor: '#E6E6E6',
        }}
      />
      <View style={styles.bottomContainer}>
        {reply && (
          <View style={{ width: 20, height: '100%' }}>
            <Icon name="subdirectory-arrow" color="mono.paleBlack" size={13} style={styles.replyIcon} />
          </View>
        )}
        <Input
          key={onCancel ? 'modify' : 'normal'}
          {...inputProps}
          defaultValue={sanitizedValue}
          inputRef={inputRef}
          onChangeText={onChangeText}
        />
        <View style={styles.sendButton}>
          <ButtonIcon
            iconName="send"
            iconSize={24}
            iconColor="mono.paleBlack"
            onPress={() => {
              console.debug('onPress', {
                value,
              })
              onPressSendButton?.(value)
              setValue('')
              inputRef.current?.clear()
              inputRef.current?.focus()
            }}
            style={styles.sendButtonIcon}
          />
        </View>
      </View>
    </View>
  )
}

export { ChatInput }
export type { ChatPreviewFileType }

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 121,
    backgroundColor: COLOR.mono.white,
  },
  topContainer: {
    flex: 1,
    height: 42,
    backgroundColor: '#F1F2F3',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  topInnerLeftContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    height: 42,
    paddingLeft: 12,
  },
  topInnerRightContainer: {
    width: 28,
    marginRight: 12,
    flexDirection: 'row',
  },
  messageModifyText: {
    ...FONT.textRegular,
    color: COLOR.mono.white,
  },
  cancelButton: {
    marginRight: 12,
  },
  typings: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 80,
    paddingLeft: 12,
    paddingTop: 8,
  },
  sendButton: {
    height: 80,
    width: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 12,
  },
  sendButtonIcon: {
    height: 24,
    width: 24,
  },
  replyIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  previewFileCount: {
    ...FONT.txtXsMedium,
  },
})
