import { Text } from '@rocket-atoms/text'
import { COLOR, FONT } from '@rui/foundations'
import React from 'react'
import { StyleSheet, View } from 'react-native'

const MetaText: React.FC<{ label: string; afterDivLine: boolean }> = ({ label, afterDivLine }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{label}</Text>
      <View style={[styles.divStyle, { height: 8, marginHorizontal: 4 }]} />
    </View>
  )
}

export default MetaText

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    ...FONT.txt2Xs,
    color: COLOR.gray.g500,
  },
  divStyle: {
    width: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
})
