import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, View } from 'react-native'

import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FAIcon } from '@rocket-atoms/icon'
import { COLOR, FONT, getBoxShadow, useMobileView } from '@rocket-mono/libs'

interface Props {
  onPressLogin: () => void
  onPressSignup: () => void
}

const CardUnAuthorizedScreen: React.FC<Props> = ({ onPressLogin }) => {
  const { t } = useTranslation()
  const mobileView = useMobileView(440)

  return (
    <View style={styles.wrap}>
      <View style={styles.body}>
        <View style={[styles.formArea, mobileView && { width: '80%' }]}>
          <View style={styles.contents}>
            <View style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: 280 }}>
              <View style={styles.thumbArea}>
                <img
                  src="https://rocket.is/files/file_box/c1c1d7cf1d4b480f892e6c58735ad9e4.png"
                  style={{ width: 58 }}
                />
                <View style={styles.thumbLabel}>
                  <View style={{ marginTop: -1.5, marginRight: -1 }}>
                    <FAIcon iconName={faLock} size={'xs'} color={'mono.white'} />
                  </View>
                </View>
              </View>
              <Text style={styles.title}>{t(`workboardmemberview.toast.nopermission`)}</Text>
            </View>
          </View>
          <Pressable
            style={{
              width: '100%',
              height: 40,
              alignSelf: 'center',
              backgroundColor: COLOR.primary.blue500,
              borderRadius: 4,
              justifyContent: 'center',
              paddingHorizontal: 12,
            }}
            onPress={onPressLogin}
          >
            <Text style={{ color: COLOR.mono.white, textAlign: 'center' }}>{t(`widget.login.dologin`)}</Text>
          </Pressable>
        </View>
      </View>
    </View>
  )
}

export default CardUnAuthorizedScreen

const styles = StyleSheet.create({
  wrap: {
    width: '100%',
    height: '100%',
    flex: 1,
    backgroundColor: COLOR.gray.g050,
  },
  body: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  formArea: {
    justifyContent: 'center',
    alignContent: 'center',
    width: 380,
    padding: 32,
    marginBottom: 100,
    borderRadius: 20,
    textAlign: 'center',
  },
  thumbArea: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    width: 73,
    height: 73,
    borderRadius: 20,
    backgroundColor: COLOR.mono.white,
    ...getBoxShadow(132, 177, 197, 0.3, 10, 0, 2, 0),
  },
  thumbLabel: {
    position: 'absolute',
    right: -4,
    bottom: -4,
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    backgroundColor: COLOR.sub.darkNavy,
    borderRadius: 30,
    ...getBoxShadow(0, 0, 0, 0.15, 3, 2, 3, 0),
  },
  contents: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    width: '100%',
    ...FONT.H6Bold,
    color: COLOR.gray.g800,
    marginTop: 28,
    paddingBottom: 40,
    textAlign: 'center',
  },
  signGroup: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  signText: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g800,
  },
  loginButton: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 36,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    borderRadius: 4,
  },
})
