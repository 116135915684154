import * as React from 'react'
import { Defs, LinearGradient, Stop, Path, Rect, Svg } from 'react-native-svg'
import type { WorkIconProps } from './types'

export const WorkIconDSP: React.FC<WorkIconProps> = ({ size = 359 }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 359 359">
      <Rect width="359" height="359" rx={80} fill="#001B57" />
      <Path
        d="M77 230.64C77 225.868 80.8683 222 85.64 222H122.36C127.132 222 131 225.868 131 230.64V267.36C131 272.132 127.132 276 122.36 276H85.64C80.8683 276 77 272.132 77 267.36V230.64Z"
        fill="white"
      />
      <Path
        d="M152 230.64C152 225.868 155.868 222 160.64 222H197.36C202.132 222 206 225.868 206 230.64V267.36C206 272.132 202.132 276 197.36 276H160.64C155.868 276 152 272.132 152 267.36V230.64Z"
        fill="white"
      />
      <Path
        d="M227 230.64C227 225.868 230.868 222 235.64 222H272.36C277.132 222 281 225.868 281 230.64V267.36C281 272.132 277.132 276 272.36 276H235.64C230.868 276 227 272.132 227 267.36V230.64Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M189.199 166.472V129.468H167.781V166.472H111.059C102.233 166.472 95.0786 173.607 95.0786 182.409V222.569H114.256V185.596H167.781V239.623H189.199V185.596H244.193V222.569H263.37V182.409C263.37 173.607 256.215 166.472 247.389 166.472H189.199Z"
        fill="url(#paint0_linear_2280_123680)"
      />
      <Path
        d="M149.573 83.6666C149.573 78.8802 153.453 75 158.24 75H200.85C205.637 75 209.517 78.8802 209.517 83.6666V126.277C209.517 131.064 205.637 134.944 200.85 134.944H158.24C153.453 134.944 149.573 131.064 149.573 126.277V83.6666Z"
        fill="white"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_2280_123680"
          x1="196.346"
          y1="191.597"
          x2="197.009"
          y2="241.094"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="white" />
          <Stop offset="0.672668" stopColor="white" stopOpacity="0.36" />
        </LinearGradient>
      </Defs>
    </Svg>
  )
}
