import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TextInput, View } from 'react-native'
import Button from '../Button'

interface Props {
  whenPressRecovery: (email: string) => void
}

export const RecoveryForm: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const [email, setEmail] = useState<string>('')
  return (
    <>
      <View style={style.inputWrap}>
        <TextInput
          placeholder={t(`findpasswordmodal.placeholder`)}
          onChangeText={setEmail}
          onSubmitEditing={() => {
            if (props.whenPressRecovery) {
              props.whenPressRecovery(email)
            }
          }}
        />
      </View>
      <View style={style.inputWrap}>
        <Button
          wide={true}
          onPress={() => {
            if (props.whenPressRecovery) {
              props.whenPressRecovery(email)
            }
          }}
        >
          {t(`findpasswordmodal.emailcheck`)}
        </Button>
      </View>
    </>
  )
}

const style = StyleSheet.create({
  inputWrap: {
    marginVertical: 5,
  },
})
