import React from 'react'
import { useCallback } from 'react'
import { StyleSheet, View, Text, Pressable } from 'react-native'
import GoogleLogo from './GoogleLogo'
import { Icon } from '@rocket-atoms/icon'
import { COLOR, getBoxShadow } from '@rocket-mono/libs'

type LoginProvider = 'google' | 'apple'
interface Props {
  provider: LoginProvider
  onPress: () => void
}

export const LoginButton = (props: Props) => {
  const [isHover, setIsHover] = React.useState(false)
  const ProviderIcon = useCallback(() => {
    switch (props.provider) {
      case 'google':
        return (
          <View>
            <GoogleLogo />
          </View>
        )
      case 'apple':
        return <Icon color={'ect.sns.apple'} name={'apple'} />
      default:
        return <Icon color={'mono.paleWhite'} name={'error'} />
    }
  }, [props.provider])

  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      style={[
        {
          position: 'relative',
          height: 40,
          justifyContent: 'center',
          alignItems: 'center',
          borderWidth: 1,
          borderColor: COLOR.gray.g300,
          borderRadius: 4,
          backgroundColor: COLOR.mono.white,
        },
        isHover && {
          borderColor: COLOR.gray.g400,
          ...getBoxShadow(0, 0, 0, 0.1, 5, 0, 2, 0),
        },
      ]}
      onPress={() => {
        if (props.onPress) props.onPress()
      }}
    >
      <View style={styles.iconArea}>{ProviderIcon()}</View>
      <Text>
        {props.provider === 'google' && 'Google'}
        {props.provider === 'apple' && 'Apple'}
      </Text>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  iconArea: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    top: 9,
    left: 16,
    width: 20,
    height: 20,
  },
  apple: {
    color: '#000000',
  },
})
