import * as React from 'react'
import { Defs, LinearGradient, Stop, Path, Rect, Svg, G } from 'react-native-svg'
import type { WorkIconProps } from './types'

export const WorkIconWFP: React.FC<WorkIconProps> = ({ size = 359 }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 359 359">
      <Rect width="359" height="359" rx={80} fill="#F85A5E" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M258 121.5C263.247 121.5 267.5 125.753 267.5 131V174.5C267.5 183.06 260.56 190 252 190H110.5V234C110.5 239.247 106.247 243.5 101 243.5C95.7533 243.5 91.5 239.247 91.5 234V186.5C91.5 177.94 98.4396 171 107 171H248.5V131C248.5 125.753 252.753 121.5 258 121.5Z"
        fill="url(#paint0_linear_2288_116791)"
      />
      <G filter="url(#filter0_f_2288_116791)">
        <Path
          d="M74 231.64C74 226.868 77.8683 223 82.64 223H119.36C124.132 223 128 226.868 128 231.64V268.36C128 273.132 124.132 277 119.36 277H82.64C77.8683 277 74 273.132 74 268.36V231.64Z"
          fill="#D14347"
        />
      </G>
      <Path
        d="M73.5 231.64C73.5 226.868 77.3683 223 82.14 223H118.86C123.632 223 127.5 226.868 127.5 231.64V268.36C127.5 273.132 123.632 277 118.86 277H82.14C77.3683 277 73.5 273.132 73.5 268.36V231.64Z"
        fill="white"
      />
      <Path
        d="M163 164.36C163 160.295 165.436 157 168.44 157H191.56C194.564 157 197 160.295 197 164.36V195.64C197 199.705 194.564 203 191.56 203H168.44C165.436 203 163 199.705 163 195.64V164.36Z"
        fill="white"
      />
      <G filter="url(#filter1_f_2288_116791)">
        <Path
          d="M231 89.64C231 84.8683 234.868 81 239.64 81H276.36C281.132 81 285 84.8683 285 89.64V126.36C285 131.132 281.132 135 276.36 135H239.64C234.868 135 231 131.132 231 126.36V89.64Z"
          fill="#D14347"
        />
      </G>
      <Path
        d="M231 89.64C231 84.8683 234.868 81 239.64 81H276.36C281.132 81 285 84.8683 285 89.64V126.36C285 131.132 281.132 135 276.36 135H239.64C234.868 135 231 131.132 231 126.36V89.64Z"
        fill="white"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_2288_116791"
          x1="258"
          y1="146.5"
          x2="98"
          y2="202.5"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.0520833" stopColor="white" />
          <Stop offset="0.182292" stopColor="white" />
          <Stop offset="0.501052" stopColor="#F85A5E" />
          <Stop offset="0.8125" stopColor="white" />
        </LinearGradient>
      </Defs>
    </Svg>
  )
}
