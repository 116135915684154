import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Keyboard, StyleSheet, View } from 'react-native'

import { Portal } from '@gorhom/portal'
import { COLOR, LayoutPayload } from '@rocket-mono/libs'
import { useAstro, useCurrentUser } from '@rocket-mono/providers'
import { Button } from '@rocket/atoms'
import { ChannelMember, Assignee as RNAssignee, File as RNFile, Todo as RNTodo } from '@rocket/types'
import { BottomSheet } from '@rui/molecules'
import { RichTextField, RichTextFieldProvider, TriggerType } from '@rui/rich-text-field'
import { useTranslation } from 'react-i18next'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useCardTodoEdit } from '../components/CardTodoEdit/hooks'
import { TodoItemOptionButton } from './TodoItemOptionButton'
import { useWorkCardTodo } from './providers'

export type Assignee = Omit<RNAssignee, 'id' | 'createdAt' | 'updatedAt'> & {
  id?: string
}
export type File = Omit<RNFile, 'id' | 'createdAt' | 'updatedAt' | 'isDeleted'> & {
  id?: string
  key?: string
  createdAt?: Date
  localPath?: string
  // localFile?: globalThis.File;
}

export type Todo = Omit<RNTodo, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy'> & {
  id?: string
  key?: string
  assignees?: Assignee[]
  files?: File[]
  isAssigneeCheck: boolean
  isDateCheck: boolean
  isFileCheck: boolean
}
interface Props {
  todo: Todo
  onChangeTodo: (todo?: Todo) => void
  visible: boolean
  onChangeVisible: (visible: boolean) => void
  channelId: string
  relatedDomain?: string
  relatedDomainId?: string
  fileLoad: globalThis.File
  onFileLoad: () => void
  inputRef: React.RefObject<any>
  triggerList: TriggerType[]
  onChange?: () => void
}
const TodoInputEdit: FC<Props> = ({
  todo: oldTodo,
  onChangeTodo,
  visible,
  onChangeVisible,
  channelId,
  relatedDomain,
  relatedDomainId,
  fileLoad,
  onFileLoad,
  triggerList,
  inputRef,
  onChange,
}) => {
  const { t, i18n } = useTranslation()
  const { astro } = useAstro()
  const { currentUser } = useCurrentUser()

  const { list, change } = useWorkCardTodo()
  const { setShowModal } = useCardTodoEdit()

  const viewRef = React.useRef<View>(null)
  // const [isFocused, setIsFocused] = React.useState(false);

  const [channelList, setChannelList] = React.useState<ChannelMember[]>([])

  React.useEffect(() => {
    void astro
      .readChannelMemberList(channelId)
      .then((channelList) => setChannelList(channelList.filter((member) => member.joined && member.userId)))
    // setChannelList(channelMemberListDummy as unknown as ChannelMember[])
  }, [])

  const [todo, setTodo] = useState<Todo>(oldTodo)

  const period = React.useMemo(() => {
    if (!todo || !todo.periodOption) return undefined
    if (todo.periodOption.fromPeriodDate && todo.periodOption.toPeriodDate) {
      const from = new Date(todo.periodOption.fromPeriodDate)
      const to = new Date(todo.periodOption.toPeriodDate)
      return `${t('format.date.L', { date: from })} - ${t('format.date.L', {
        date: to,
      })}`
    } else if (todo.periodOption.fromPeriodDate) {
      const from = new Date(todo.periodOption.fromPeriodDate)
      return `${t('format.date.L', { date: from })} - `
    } else if (todo.periodOption.toPeriodDate) {
      const to = new Date(todo.periodOption.toPeriodDate)
      return ` - ${t('format.date.L', {
        date: to,
      })}`
    } else {
      return undefined
    }
  }, [todo, t])

  const handleSave = useCallback(() => {
    if (!list) return
    const idx = list.findIndex(({ key }) => key === todo.key)

    console.log('handleSave', idx, todo)

    if (idx >= 0) {
      change(todo, idx)
      onChange?.()
    }

    onChangeTodo()
  }, [change, todo, list, change])

  const insets = useSafeAreaInsets()
  const [keyboardHeight, setKeyboardHeight] = useState(insets.bottom)

  useEffect(() => {
    const keyboardShowListener = Keyboard.addListener('keyboardWillShow', (e) => {
      setKeyboardHeight(e.endCoordinates.height)
    })
    const keyboardHideListener = Keyboard.addListener('keyboardWillHide', () => {
      setKeyboardHeight(insets.bottom)
    })
    return () => {
      keyboardShowListener.remove()
      keyboardHideListener.remove()
    }
  }, [])

  const users = useMemo(() => (todo.assignees ? todo.assignees.length : 0), [todo.assignees])

  const isFileUpload = useMemo(() => !!todo.files, [todo.files])

  const handlePressUser = useCallback(
    (payload: LayoutPayload) => {
      if (todo.isAssigneeCheck) {
        setTodo({
          ...todo,
          isAssigneeCheck: false,
        })
      } else {
        onChangeVisible(false)
        setTimeout(() => {
          setShowModal &&
            setShowModal({
              astro,
              type: 'assignee',
              layoutPayload: payload,
              todo,
              users: channelList,
              relatedDomain,
              relatedDomainId,
              i18n,
              currentUserId: Number(currentUser?.id ?? 0),
              onClose: () => {
                setShowModal(undefined)
                setTimeout(() => onChangeVisible(true), 100)
              },
              onSave: (todo) => {
                setTodo(todo)
                setShowModal(undefined)
                setTimeout(() => onChangeVisible(true), 100)
              },
            })
        }, 100)
      }
    },
    [todo, channelList, currentUser],
  )

  const handlePressDate = useCallback(
    (payload: LayoutPayload) => {
      if (todo.isDateCheck) {
        setTodo({
          ...todo,
          isDateCheck: false,
        })
      } else {
        onChangeVisible(false)
        setTimeout(() => {
          setShowModal &&
            setShowModal({
              astro,
              type: 'date',
              layoutPayload: payload,
              todo,
              users: channelList,
              relatedDomain,
              relatedDomainId,
              i18n,
              currentUserId: Number(currentUser?.id ?? 0),
              onClose: () => {
                setShowModal(undefined)
                setTimeout(() => onChangeVisible(true), 100)
              },
              onSave: (todo) => {
                setTodo(todo)
                setShowModal(undefined)
                setTimeout(() => onChangeVisible(true), 100)
              },
            })
        }, 100)
      }
    },
    [todo, channelList, currentUser],
  )
  const handlePressFile = useCallback(
    (payload: LayoutPayload) => {
      if (!fileLoad) return
      if (todo.isFileCheck) {
        setTodo({
          ...todo,
          isFileCheck: false,
        })
      } else {
        onChangeVisible(false)
        setTimeout(() => {
          setShowModal &&
            setShowModal({
              astro,
              type: 'file',
              layoutPayload: payload,
              todo,
              users: channelList,
              relatedDomain,
              relatedDomainId,
              i18n,
              currentUserId: Number(currentUser?.id ?? 0),
              onClose: () => {
                setShowModal(undefined)
                setTimeout(() => onChangeVisible(true), 100)
              },
              onSave: (todo) => {
                setTodo(todo)
                setShowModal(undefined)
                setTimeout(() => onChangeVisible(true), 100)
              },
              fileLoad,
              onFileLoad,
            })
        }, 100)
      }
    },
    [todo, channelList, currentUser, fileLoad],
  )

  return (
    <Portal hostName="TodoEditInputPortal">
      <BottomSheet
        dragable
        headerVariant="COMMON"
        visible={visible}
        onClose={() => {
          onChangeTodo()
        }}
        buttonChild={
          <View
            style={{
              backgroundColor: COLOR.mono.white,
              // margin: 10,
              marginTop: 0,
            }}
          >
            <View style={styles.flexBox}>
              <TodoItemOptionButton
                icon={'user-o'}
                iconColor={'gray.g700'}
                text={users && todo.isAssigneeCheck ? `@${users}` : undefined}
                onPress={() => {
                  if (viewRef && viewRef.current) {
                    viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                      handlePressUser({ x, y, width, height, pageX, pageY })
                    })
                  }
                }}
                isValued={todo.isAssigneeCheck}
                stateOff={!todo.isAssigneeCheck}
              />
              <TodoItemOptionButton
                icon={'time-o'}
                iconColor={'gray.g700'}
                text={period}
                onPress={() => {
                  if (viewRef && viewRef.current) {
                    viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                      handlePressDate({ x, y, width, height, pageX, pageY })
                    })
                  }
                }}
                isValued={todo.isDateCheck}
                stateOff={!todo.isDateCheck}
              />
              <TodoItemOptionButton
                icon={'cloud-upload-o'}
                iconColor={'gray.g700'}
                onPress={() => {
                  if (viewRef && viewRef.current) {
                    viewRef.current.measure((x, y, width, height, pageX, pageY) => {
                      handlePressFile({ x, y, width, height, pageX, pageY })
                    })
                  }
                }}
                isValued={todo.isFileCheck && isFileUpload}
                stateOff={!(todo.isFileCheck && isFileUpload)}
              />
            </View>
            <Button onPress={handleSave}>{t('board.save')}</Button>
          </View>
        }
      >
        <RichTextFieldProvider ref={inputRef}>
          <View ref={viewRef} style={{ margin: 12 }}>
            <RichTextField
              autoFocus
              placeholder={t('inputtodo.placeholder')}
              value={todo.content ?? ''}
              onChangeValue={(content: string) => {
                setTodo({ ...todo, content })
              }}
              triggerList={triggerList}
            />
          </View>
        </RichTextFieldProvider>
      </BottomSheet>
    </Portal>
  )
}

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 8,
  },
})

export default TodoInputEdit
