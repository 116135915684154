import { COLOR } from '@rocket-mono/libs'
import { Icon, Text } from '@rocket/atoms'
import React from 'react'
import { Modal, Platform, Pressable, StyleSheet, View, ViewStyle } from 'react-native'
import RenderHTML from 'react-native-render-html'

interface Props {
  title: string
  contents: string
  onClose: () => void
}

export const TermsModal: React.FC<Props> = ({ title, contents, onClose }) => {
  return Platform.OS === 'web' ? (
    <View style={styles.rootContainer}>
      <Pressable
        style={[styles.backdrop, Platform.OS === 'web' ? ({ cursor: 'default' } as ViewStyle) : null]}
        onPress={onClose}
      />
      <View style={styles.container}>
        <View style={styles.header}>
          <Text fontName="listRegular">{title}</Text>
          <Pressable onPress={onClose}>
            <Icon name="close" />
          </Pressable>
        </View>
        <View style={styles.contents}>
          <RenderHTML
            source={{
              html: contents,
            }}
          />
        </View>
      </View>
    </View>
  ) : (
    <Modal transparent animationType="fade">
      <View style={styles.rootContainer}>
        <Pressable style={styles.backdrop} onPress={onClose} />
        <View style={styles.mobileContainer}>
          <View style={styles.header}>
            <Text fontName="listRegular">{title}</Text>
            <Pressable onPress={onClose}>
              <Icon name="close" />
            </Pressable>
          </View>
          <View style={styles.contents}>
            <RenderHTML
              source={{
                html: contents,
              }}
            />
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  rootContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 10000,
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: COLOR.mono.realBlack,
    opacity: 0.8,
  },
  container: {
    width: 400,
    height: 400,
    marginHorizontal: 10,
    backgroundColor: COLOR.mono.white,
    zIndex: 10100,
    borderRadius: 10,
  },
  mobileContainer: {
    width: '90%',
    height: 400,
    marginHorizontal: 10,
    backgroundColor: COLOR.mono.white,
    zIndex: 10100,
    borderRadius: 10,
  },
  header: {
    margin: 10,
    height: 35,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contents: {
    overflow: 'scroll',
    marginHorizontal: 10,
    padding: 15,
    height: 300,
    borderWidth: 1,
    borderColor: COLOR.mono.pale,
    borderRadius: 8,
  },
})
