import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { StyleSheet, View, Text, Modal, Pressable } from 'react-native'
import { COLOR, FONT } from '@rui/foundations'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FAIcon } from '@rocket/atoms'
import { TextInput } from '@rocket-mono/foundations'

import { useCurrentUser } from '@rocket-mono/providers'
interface Props {
  isShow: boolean
  setIsShow?: () => void
  children: React.ReactNode
  containerMarginTop?: number
  containerMarginLeft?: number
  containerMaxWidth: number
  searchKeyword: string
  setSearchKeyword: (keyword: string) => void
}

const SearchSuggestions = ({
  isShow,
  setIsShow,
  children,
  containerMaxWidth,
  containerMarginTop = 65,
  containerMarginLeft = 3,
  searchKeyword,
  setSearchKeyword,
}: Props) => {
  const { t } = useTranslation()

  const { currentUser } = useCurrentUser()

  return (
    <Modal transparent visible={isShow}>
      <View
        style={[
          styles.container,
          {
            zIndex: 1,
            width: `${containerMaxWidth}%`,
            marginTop: containerMarginTop,
            marginLeft: containerMarginLeft,
          },
        ]}
      >
        <View style={styles.headerWrap}>
          <View style={styles.inputWrap}>
            <View style={styles.inputLeftIcon}>
              <FAIcon iconName={faSearch} color={'gray.g600'} />
            </View>
            <TextInput
              value={searchKeyword}
              onChangeText={(text) => setSearchKeyword(text)}
              borderType={'solid'}
              borderColor={'darkmode.warm500'}
              placeholder={t('workspace.search')}
              placeholderTextColor={COLOR.gray.g400}
              style={styles.input}
              containerStyle={{ backgroundColor: COLOR.darkmode.cool900 }}
              inputStyle={{ color: COLOR.mono.white }}
            />
          </View>
        </View>
        <View>
          <Text style={styles.workSectionTit}>{t('workspace.mywork', { userName: currentUser.userName })}</Text>
          {children}
        </View>
      </View>
      <Pressable style={styles.dimmedCover} onPress={setIsShow && setIsShow}></Pressable>
    </Modal>
  )
}

export default SearchSuggestions

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 8,
    paddingBottom: 16,
    backgroundColor: COLOR.darkmode.cool900,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: COLOR.darkmode.cool600,
  },
  headerWrap: {
    paddingVertical: 16,
    paddingHorizontal: 8,
  },
  workSectionTit: {
    paddingLeft: 8,
    marginBottom: 8,
    ...FONT.txtXsMedium,
    color: COLOR.darkmode.cool400,
  },
  inputWrap: {
    position: 'relative',
  },
  inputLeftIcon: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    height: 36,
    paddingLeft: 12,
    paddingRight: 8,
    zIndex: 99,
  },
  input: {
    height: 36,
    borderColor: COLOR.darkmode.warm500,
    paddingLeft: 36,
  },
  dimmedCover: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: COLOR.dimmed.a60,
  },
})
