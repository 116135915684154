import { File, FileCreation } from '@rocket/types'
import React, { useCallback, useEffect, useState } from 'react'
import { useAstro } from '../../AstroProvider'
import Context from './context'
import type { ProviderProps, WorkFile } from './types'

export const WorkFileProvider: React.FC<ProviderProps> = ({
  domain: relatedDomain,
  domainId,
  workId,
  boardRoomId,
  userId,
  children,
}: ProviderProps) => {
  const { astro } = useAstro()
  const [fileList, setFileList] = useState<WorkFile[]>([])

  const fetchFile = useCallback((relatedDomain: string, domainId: string) => {
    astro
      .readFile(relatedDomain, domainId)
      .then((res: File[]) => Promise.all(res.map(extraFile)))
      .then(setFileList)
      .catch(() => setFileList([]))
  }, [])

  const createFile = useCallback((file: globalThis.File[]) => {
    return new Promise<File[]>((resolve, reject) => {
      Promise.all(
        file.map((o) => {
          const fileFormData = new FormData()
          fileFormData.append('file', o)
          const astroFile: FileCreation = {
            relatedDomain,
            relatedDomainId: domainId || '',
            projectId: workId,
            boardRoomId,
            userId,
            fileFormData,
          }
          return astro.createFile(astroFile)
        }),
      )
        .then(resolve)
        .catch(reject)
    })
  }, [])
  const deleteFile = useCallback((deleteIds: string[]) => {
    return new Promise<Response[]>((resolve, reject) => {
      Promise.all(deleteIds.map((id) => astro.deleteFile(id)))
        .then(resolve)
        .catch(reject)
    })
  }, [])

  const extraFile = useCallback(async (file: File) => {
    const presignedUrl = await astro.readFilePresignedUrl(file.path || '')
    const user = await astro.readUser(String(file.userId))
    return { ...file, presignedUrl, user }
  }, [])

  const fileDownload = useCallback(async (file: WorkFile) => {
    fetch(file.presignedUrl || '')
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file.originalName || '')
        document.body.appendChild(link)
        link.click()

        // cleanup
        link.remove()
        window.URL.revokeObjectURL(url)
      })
  }, [])

  useEffect(() => {
    if (domainId) fetchFile(relatedDomain, domainId)
  }, [fetchFile, relatedDomain, domainId])

  useEffect(() => {
    console.debug('provider-WorkFileProvider', { fileList })
  }, [fileList])

  return (
    <Context.Provider value={{ fileList, fetchFile, createFile, deleteFile, fileDownload }}>
      {children}
    </Context.Provider>
  )
}

export * from './hooks'
export * from './types'
