import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { COLOR, FONT } from '@rocket-mono/libs'
import { Button } from '@rocket/atoms'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import Svg, { G, Mask, Path } from 'react-native-svg'

import { useTranslation } from 'react-i18next'

const EmptyView: React.FC<{ onPress: () => void }> = ({ onPress }) => {
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <View style={styles.inner}>
        <Svg width={169} height={110} viewBox="0 0 169 110" fill="none">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 35.0312V33.5H20V35.0312H17ZM17 41.1562V38.0938H20V41.1562H17ZM124.5 43.5C124.064 43.5 123.639 43.5427 123.23 43.6238L122.648 40.6808C123.248 40.562 123.867 40.5 124.5 40.5H124.562V43.5H124.5ZM124.688 43.5V40.5H126.021V43.5H124.688ZM131.104 43.5H128.562V40.5H131.104V43.5ZM136.188 43.5H133.646V40.5H136.188V43.5ZM140 43.5H138.729V40.5H140V43.5ZM120.89 44.5939C120.18 45.0689 119.569 45.6801 119.094 46.3896L116.601 44.7206C117.294 43.6853 118.185 42.7942 119.221 42.101L120.89 44.5939ZM17 47.2812V44.2188H20V47.2812H17ZM118.124 48.7298C118.043 49.1394 118 49.564 118 50V52H115V50C115 49.3675 115.062 48.7479 115.181 48.1476L118.124 48.7298ZM17 53.4062V50.3438H20V53.4062H17ZM118 56V58C118 58.6325 117.938 59.2521 117.819 59.8524L114.876 59.2702C114.957 58.8606 115 58.436 115 58V56H118ZM17 58V56.4688H20V58C20 58.436 20.0427 58.8606 20.1238 59.2702L17.1808 59.8524C17.062 59.2521 17 58.6325 17 58ZM116.399 63.2794C115.706 64.3147 114.815 65.2058 113.779 65.899L112.11 63.4061C112.82 62.9311 113.431 62.3199 113.906 61.6104L116.399 63.2794ZM21.2206 65.899C20.1853 65.2058 19.2942 64.3147 18.601 63.2794L21.0939 61.6104C21.5689 62.3199 22.1801 62.9311 22.8896 63.4061L21.2206 65.899ZM26.5 67.5C25.8675 67.5 25.2479 67.438 24.6476 67.3192L25.2298 64.3762C25.6394 64.4573 26.064 64.5 26.5 64.5H27.9643V67.5H26.5ZM110.352 67.3192C109.752 67.438 109.133 67.5 108.5 67.5H107.036V64.5H108.5C108.936 64.5 109.361 64.4573 109.77 64.3762L110.352 67.3192ZM33.8214 67.5H30.8929V64.5H33.8214V67.5ZM39.6786 67.5H36.75V64.5H39.6786V67.5ZM45.5357 67.5H42.6071V64.5H45.5357V67.5ZM51.3929 67.5H48.4643V64.5H51.3929V67.5ZM57.25 67.5H54.3214V64.5H57.25V67.5ZM63.1071 67.5H60.1786V64.5H63.1071V67.5ZM68.9643 67.5H66.0357V64.5H68.9643V67.5ZM74.8214 67.5H71.8929V64.5H74.8214V67.5ZM80.6786 67.5H77.75V64.5H80.6786V67.5ZM86.5357 67.5H83.6071V64.5H86.5357V67.5ZM92.3928 67.5H89.4643V64.5H92.3928V67.5ZM98.25 67.5H95.3214V64.5H98.25V67.5ZM104.107 67.5H101.179V64.5H104.107V67.5Z"
            fill="#E6E9ED"
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M90.5 101.5C90.064 101.5 89.6394 101.457 89.2298 101.376L88.6476 104.319C89.2479 104.438 89.8675 104.5 90.5 104.5H90.5625V101.5H90.5ZM90.6875 101.5V104.5H92.1771V101.5H90.6875ZM97.8854 101.5H95.0312V104.5H97.8854V101.5ZM103.594 101.5H100.74V104.5H103.594V101.5ZM109.302 101.5H106.448V104.5H109.302V101.5ZM115.01 101.5H112.156V104.5H115.01V101.5ZM120.719 101.5H117.865V104.5H120.719V101.5ZM125 101.5H123.573V104.5H125V101.5ZM86.8896 100.406C86.1801 99.9311 85.5689 99.3199 85.0939 98.6104L82.601 100.279C83.2942 101.315 84.1853 102.206 85.2206 102.899L86.8896 100.406ZM84.1238 96.2702C84.0427 95.8606 84 95.436 84 95V93.6667H81V95C81 95.6325 81.062 96.2521 81.1808 96.8524L84.1238 96.2702ZM84 91V88.3333H81V91H84ZM84 85.6667V83H81V85.6667H84ZM84 80.3333V79H81V80.3333H84Z"
            fill="#E6E9ED"
          />
          <Path
            d="M0 8C0 3.58172 3.58172 0 8 0H30C34.4183 0 38 3.58172 38 8V30C38 34.4183 34.4183 38 30 38H8C3.58172 38 0 34.4183 0 30V8Z"
            fill="white"
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 2H8C4.68629 2 2 4.68629 2 8V30C2 33.3137 4.68629 36 8 36H30C33.3137 36 36 33.3137 36 30V8C36 4.68629 33.3137 2 30 2ZM8 0C3.58172 0 0 3.58172 0 8V30C0 34.4183 3.58172 38 8 38H30C34.4183 38 38 34.4183 38 30V8C38 3.58172 34.4183 0 30 0H8Z"
            fill="#D3D7DC"
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.0352 86.7561C62.4165 90.8194 65.8368 93.9992 69.9998 93.9992H101C105.418 93.9992 109 90.4174 109 85.9992V54.9992C109 51.2588 106.433 48.1179 102.964 47.2422C102.988 47.4914 103 47.7439 103 47.9992V78.9992C103 83.4174 99.4181 86.9992 94.9998 86.9992H63.9998C63.3219 86.9992 62.6637 86.9148 62.0352 86.7561Z"
            fill="#D3D7DC"
          />
          <Path
            d="M135 33C135 28.5817 138.582 25 143 25H161C165.418 25 169 28.5817 169 33V51C169 55.4183 165.418 59 161 59H143C138.582 59 135 55.4183 135 51V33Z"
            fill="white"
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M161 27H143C139.686 27 137 29.6863 137 33V51C137 54.3137 139.686 57 143 57H161C164.314 57 167 54.3137 167 51V33C167 29.6863 164.314 27 161 27ZM143 25C138.582 25 135 28.5817 135 33V51C135 55.4183 138.582 59 143 59H161C165.418 59 169 55.4183 169 51V33C169 28.5817 165.418 25 161 25H143Z"
            fill="#D3D7DC"
          />
          <Path
            d="M58 50C58 45.5817 61.5817 42 66 42H97C101.418 42 105 45.5817 105 50V81C105 85.4183 101.418 89 97 89H66C61.5817 89 58 85.4183 58 81V50Z"
            fill="white"
          />
          <Path
            d="M58 50C58 45.5817 61.5817 42 66 42H97C101.418 42 105 45.5817 105 50V81C105 85.4183 101.418 89 97 89H66C61.5817 89 58 85.4183 58 81V50Z"
            fill="white"
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M97 44H66C62.6863 44 60 46.6863 60 50V81C60 84.3137 62.6863 87 66 87H97C100.314 87 103 84.3137 103 81V50C103 46.6863 100.314 44 97 44ZM66 42C61.5817 42 58 45.5817 58 50V81C58 85.4183 61.5817 89 66 89H97C101.418 89 105 85.4183 105 81V50C105 45.5817 101.418 42 97 42H66Z"
            fill="#007FFF"
          />
          <Path
            d="M6 9C6 7.89543 6.89543 7 8 7H30C31.1046 7 32 7.89543 32 9V17C32 18.1046 31.1046 19 30 19H8C6.89543 19 6 18.1046 6 17V9Z"
            fill="#D3D7DC"
          />
          <Path
            opacity={0.5}
            d="M6 22C6 21.4477 6.44772 21 7 21H20C20.5523 21 21 21.4477 21 22V24C21 24.5523 20.5523 25 20 25H7C6.44772 25 6 24.5523 6 24V22Z"
            fill="#D3D7DC"
          />
          <Path
            opacity={0.5}
            d="M6 28C6 27.4477 6.44772 27 7 27H26C26.5523 27 27 27.4477 27 28V30C27 30.5523 26.5523 31 26 31H7C6.44772 31 6 30.5523 6 30V28Z"
            fill="#D3D7DC"
          />
          <Path
            d="M156 37C156 37.5625 156.438 38 157 38H160V44.5C160 45.3438 159.312 46 158.5 46H152.5C151.656 46 151 45.3438 151 44.5V35.5C151 34.6875 151.656 34 152.5 34H156V37ZM157 34L160 37H157V34ZM150 45C150 46.125 150.875 47 152 47H153V48.5C153 49.3438 152.312 50 151.5 50H145.5C144.656 50 144 49.3438 144 48.5V39.5C144 38.6875 144.656 38 145.5 38H150V45Z"
            fill="#D3D7DC"
          />
          <Mask id="mask0_7107_785784" maskUnits="userSpaceOnUse" x={62} y={46} width={39} height={39}>
            <Path
              d="M62 51C62 48.2386 64.2386 46 67 46H96C98.7614 46 101 48.2386 101 51V80C101 82.7614 98.7614 85 96 85H67C64.2386 85 62 82.7614 62 80V51Z"
              fill="white"
            />
          </Mask>
          <G mask="url(#mask0_7107_785784)">
            <Path
              opacity={0.7}
              d="M84 58C84 62.4183 80.4183 66 76 66C71.5817 66 68 62.4183 68 58C68 53.5817 71.5817 50 76 50C80.4183 50 84 53.5817 84 58Z"
              fill="#007FFF"
            />
            <Path
              opacity={0.5}
              d="M59 83C59 74.7157 65.7157 68 74 68H78C86.2843 68 93 74.7157 93 83V90C93 98.2843 86.2843 105 78 105H74C65.7157 105 59 98.2843 59 90V83Z"
              fill="#007FFF"
            />
          </G>
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M98.4881 69.6638C99.2261 70.4856 99.1581 71.7501 98.3363 72.4881L89.4272 80.4881C88.6421 81.1931 87.4444 81.1669 86.6909 80.4283L82.1 75.9283C81.3112 75.1551 81.2985 73.8888 82.0717 73.1C82.8449 72.3112 84.1112 72.2985 84.9 73.0717L88.1508 76.2582L95.6638 69.5119C96.4856 68.7739 97.7501 68.8419 98.4881 69.6638Z"
            fill="#007FFF"
          />
          <Path
            d="M117 99.5C117 97.2909 118.791 95.5 121 95.5H127C129.209 95.5 131 97.2909 131 99.5V105.5C131 107.709 129.209 109.5 127 109.5H121C118.791 109.5 117 107.709 117 105.5V99.5Z"
            fill="#E2E5EA"
          />
        </Svg>
        <View style={styles.conArea}>
          <Text style={[styles.txt, styles.title]}>{t('rule.modal.nocontents')}</Text>
          <Text style={[styles.txt, styles.subText]}>{t('rule.modal.description')}</Text>
        </View>
        <View style={styles.buttonArea}>
          <Button type="PRIMARY" text={t('rule.modal.make')} beforeIcon={faPlus} onPress={onPress} />
        </View>
      </View>
    </View>
  )
}

export default EmptyView

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inner: {
    alignItems: 'center',
  },
  conArea: {
    marginTop: 20,
    marginBottom: 24,
  },
  txt: {
    textAlign: 'center',
  },
  title: {
    ...FONT.H7Medium,
    color: COLOR.gray.g800,
  },
  subText: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g500,
  },
  buttonArea: {
    minWidth: 240,
  },
})
