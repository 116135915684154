import React from 'react'

interface Props {
  id?: string
  children: React.ReactNode
  onClickOutside: (event: React.MouseEvent) => void
}
/**
 * @deprecated
 */
const ClickOutside: React.FC<Props> = ({ id, children, onClickOutside }) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const handleClickOutside = (event: unknown) => {
    const mouseEvent = event as React.MouseEvent
    if (ref.current && !ref.current.contains(mouseEvent.target as Node)) {
      onClickOutside(mouseEvent)
    }
  }
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
  return (
    <div ref={ref} id={id} style={{ width: '100%', height: '100%' }}>
      {children}
    </div>
  )
}

export default ClickOutside
