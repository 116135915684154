import { faShareNodes } from '@fortawesome/free-solid-svg-icons'
import { FAIcon } from '@rocket/atoms'
import { COLOR, FONT } from '@rui/foundations'
import React from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'

interface Props {
  shared?: boolean
  workname: string
  onHoverIn?: () => void
}
const WorkName: React.FC<Props> = ({ shared = false, workname, onHoverIn }) => {
  return (
    <View style={styles.workName}>
      {shared && (
        <View style={styles.shareLabel}>
          <View style={styles.iconWrap}>
            <FAIcon iconName={faShareNodes} size={'2xs'} color={'darkmode.cool500'} />
          </View>
        </View>
      )}
      <View style={{ flex: 1 }}>
        {/* <Tooltip onHoverTarget={onHoverIn} gab={0} darkType={true} direction={'bottom'} text={workname}>
          {(props) => (
            <Text style={styles.nameText} numberOfLines={2} ellipsizeMode="tail" {...props}>
              {shared && <Text style={styles.ifShareArea}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>}
              {workname}
            </Text>
          )}
        </Tooltip> */}
        <Text style={styles.nameText} numberOfLines={2} ellipsizeMode="tail">
          {shared && <Text style={styles.ifShareArea}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>}
          {/* <= 공유아이콘 자리를 위한 여백 */}
          {workname}
        </Text>
      </View>
    </View>
  )
}

export default WorkName

const styles = StyleSheet.create({
  workName: {
    position: 'relative',
    flex: 1,
    flexDirection: 'row',
  },
  shareLabel: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    width: 14,
    height: 14,
    borderRadius: 2,
    paddin: 4,
    backgroundColor: `rgba(0,0,0, .75)`,
  },
  ifShareArea: {
    width: 24,
  },
  iconWrap: {
    width: 8,
    height: 8,
    marginTop: -1,
  },
  nameText: {
    ...Platform.select({
      web: {
        flex: 1,
      },
    }),
    ...FONT.txtSmMedium,
    color: COLOR.mono.white,
    lineHeight: 16,
  },
})
