import React from 'react'
import { DimensionValue } from 'react-native'
import Svg, { Path, G, Defs, ClipPath, Rect } from 'react-native-svg'

interface Props{
  width?: number
  height?: number
}
const RocketWorkLogo = ({width, height}: Props) => {
  return(
    <Svg
    width={width}
    height={height}
    viewBox="0 0 612 79"
    fill="none"
  >
    <G clipPath="url(#clip0_13_11566)">
      <Path
        d="M139.925 69.9853L127.702 52.3392H114.213V69.9853H99.5503V6.65125H126.979C132.589 6.65125 137.461 7.58734 141.592 9.45952C145.724 11.3317 148.912 13.984 151.138 17.4245C153.372 20.8651 154.489 24.9379 154.489 29.643C154.489 34.3481 153.356 38.4045 151.097 41.8122C148.838 45.2199 145.618 47.8311 141.461 49.6376L155.672 69.9935H139.925V69.9853ZM136.212 21.4481C133.92 19.5513 130.569 18.5987 126.166 18.5987H114.222V40.6708H126.166C130.569 40.6708 133.92 39.7101 136.212 37.7722C138.504 35.8426 139.654 33.1246 139.654 29.6266C139.654 26.1286 138.504 23.3367 136.212 21.4399V21.4481Z"
        fill="#020118"
      />
      <Path
        d="M171.584 67.4972C167.509 65.3541 164.33 62.3815 162.039 58.5879C159.747 54.7861 158.597 50.4751 158.597 45.6468C158.597 40.8186 159.739 36.5076 162.039 32.7058C164.33 28.9039 167.518 25.9314 171.584 23.7965C175.658 21.6533 180.259 20.5858 185.384 20.5858C190.51 20.5858 195.102 21.6533 199.144 23.7965C203.185 25.9396 206.356 28.9121 208.648 32.7058C210.94 36.5076 212.09 40.8186 212.09 45.6468C212.09 50.4751 210.94 54.7861 208.648 58.5879C206.356 62.3898 203.185 65.3623 199.144 67.4972C195.102 69.6404 190.51 70.7079 185.384 70.7079C180.259 70.7079 175.658 69.6404 171.584 67.4972ZM194.305 55.4676C196.63 53.0288 197.788 49.7525 197.788 45.655C197.788 41.5576 196.622 38.2813 194.305 35.8343C191.981 33.3955 189.007 32.172 185.393 32.172C181.778 32.172 178.788 33.3955 176.431 35.8343C174.073 38.2813 172.898 41.5494 172.898 45.655C172.898 49.7607 174.073 53.0288 176.431 55.4676C178.788 57.9146 181.77 59.1299 185.393 59.1299C189.015 59.1299 191.981 57.9064 194.305 55.4676Z"
        fill="#020118"
      />
      <Path
        d="M229.332 67.4972C225.2 65.3541 221.972 62.3815 219.647 58.5879C217.323 54.7861 216.164 50.4751 216.164 45.6468C216.164 40.8186 217.323 36.5076 219.647 32.7058C221.972 28.9039 225.2 25.9314 229.332 23.7965C233.464 21.6533 238.13 20.5858 243.313 20.5858C248.497 20.5858 252.925 21.6533 256.753 23.7965C260.58 25.9396 263.373 29.0024 265.123 32.9767L254.173 38.8561C251.635 34.3973 247.988 32.1638 243.223 32.1638C239.543 32.1638 236.495 33.3709 234.08 35.785C231.665 38.1991 230.458 41.4837 230.458 45.6468C230.458 49.81 231.665 53.0945 234.08 55.5087C236.495 57.9228 239.543 59.1299 243.223 59.1299C248.053 59.1299 252.974 55.3198 255.455 50.8528L264.425 59.8114C262.084 63.4244 260.408 65.0995 256.761 67.448C252.924 69.6157 248.448 70.7079 243.322 70.7079C238.196 70.7079 233.472 69.6404 229.34 67.4972H229.332Z"
        fill="#020118"
      />
      <Path
        d="M290.104 50.8939L283.318 57.5862V69.9853H269.198V2.84937H283.318V40.8515L302.886 21.3085H319.717L299.937 41.2128L322.592 69.9853H305.481L290.096 50.8939H290.104Z"
        fill="#020118"
      />
      <Path
        d="M354.621 58.2678C356.584 57.5123 358.408 56.3217 360.1 54.6958L367.616 62.8415C363.033 68.0885 356.33 70.7162 347.524 70.7162C342.028 70.7162 337.173 69.6487 332.951 67.5055C328.729 65.3624 325.467 62.3899 323.176 58.5962C320.884 54.7944 319.734 50.4834 319.734 45.6551C319.734 40.8269 320.867 36.5898 323.126 32.7633C325.385 28.9368 328.499 25.9479 332.45 23.8048C336.401 21.6616 340.853 20.5941 345.798 20.5941C350.448 20.5941 354.67 21.5713 358.474 23.5338C362.277 25.4963 365.308 28.3292 367.567 32.0407C369.826 35.7523 370.96 40.1371 370.96 45.2035L334.939 52.1667C335.966 54.5809 337.576 56.3874 339.785 57.5944C341.987 58.8015 344.69 59.4009 347.885 59.4009C350.423 59.4009 352.666 59.0232 354.629 58.2678H354.621ZM337.107 34.7012C334.873 36.9922 333.698 40.1617 333.575 44.2017L357.291 39.587C356.625 37.0496 355.27 35.0297 353.216 33.527C351.163 32.0161 348.69 31.2689 345.79 31.2689C342.233 31.2689 339.334 32.4185 337.099 34.7094L337.107 34.7012Z"
        fill="#020118"
      />
      <Path
        d="M404.631 67.6368C403.243 68.6632 401.534 69.4351 399.514 69.9442C397.493 70.4533 395.398 70.7161 393.221 70.7161C387.364 70.7161 382.871 69.238 379.733 66.2819C376.595 63.3259 375.026 58.982 375.026 53.2505V10.5433H389.147V22.3923H401.181V33.2477H389.147V53.0617C389.147 55.1145 389.656 56.6993 390.683 57.8078C391.71 58.9246 393.188 59.4829 395.119 59.4829C397.287 59.4829 399.218 58.8835 400.91 57.6764L404.623 67.6286L404.631 67.6368Z"
        fill="#020118"
      />
      <Path
        d="M492.971 13.524L473.954 69.9113H467.67L451.15 21.8257L434.549 69.9113H428.347L409.33 13.524H415.458L431.731 62.012L448.571 13.524H454.132L470.734 62.2584L487.253 13.524H492.971Z"
        fill="#020118"
      />
      <Path
        d="M501.744 67.3248C498.171 65.3048 495.361 62.513 493.316 58.941C491.27 55.3691 490.244 51.3373 490.244 46.8293C490.244 42.3213 491.27 38.2895 493.316 34.7176C495.361 31.1456 498.171 28.3702 501.744 26.3831C505.317 24.3959 509.326 23.4023 513.77 23.4023C518.214 23.4023 522.231 24.3959 525.796 26.3831C529.37 28.3702 532.162 31.1539 534.183 34.7176C536.204 38.2895 537.214 42.3213 537.214 46.8293C537.214 51.3373 536.204 55.3691 534.183 58.941C532.162 62.513 529.37 65.3048 525.796 67.3248C522.223 69.3448 518.214 70.3548 513.77 70.3548C509.326 70.3548 505.309 69.3448 501.744 67.3248ZM522.601 62.5869C525.205 61.0924 527.242 58.9739 528.704 56.2231C530.166 53.4723 530.897 50.3438 530.897 46.8293C530.897 43.3149 530.166 40.1863 528.704 37.4355C527.242 34.6847 525.205 32.5662 522.601 31.0717C519.997 29.5773 517.056 28.8383 513.778 28.8383C510.501 28.8383 507.56 29.5855 504.956 31.0717C502.352 32.5662 500.306 34.6847 498.811 37.4355C497.316 40.1863 496.569 43.3149 496.569 46.8293C496.569 50.3438 497.316 53.4723 498.811 56.2231C500.306 58.9739 502.352 61.0924 504.956 62.5869C507.56 64.0813 510.501 64.8204 513.778 64.8204C517.056 64.8204 519.997 64.0731 522.601 62.5869Z"
        fill="#020118"
      />
      <Path
        d="M553.8 25.7589C556.699 24.1824 560.281 23.3859 564.553 23.3859V29.4458L563.057 29.3555C558.194 29.3555 554.391 30.85 551.639 33.8307C548.887 36.8114 547.516 40.9992 547.516 46.3776V69.9031H541.281V23.7472H547.253V32.7879C548.715 29.684 550.9 27.3437 553.791 25.7672L553.8 25.7589Z"
        fill="#020118"
      />
      <Path
        d="M585.335 46.2463L574.862 56.7486V69.9114H568.627V4.78723H574.862V48.9314L599.99 23.739H607.802L589.738 41.7958L612 69.9032H604.36L585.335 46.2381V46.2463Z"
        fill="#020118"
      />
      <Path
        d="M258.724 42.5512L251.462 41.4755L264.671 28.2799L265.969 33.3135L258.724 42.5512Z"
        fill="white"
      />
    </G>
    <G clipPath="url(#clip1_13_11566)">
      <Path
        d="M73.6889 0H58.3647L22.8413 35.6124V56.1014H43.2792L78.8026 20.4891V5.12654C78.8026 2.2984 76.51 0 73.6889 0Z"
        fill="#020118"
      />
      <Path
        d="M0 57.9128H21.0345V79H5.11373C2.29266 79 0 76.7102 0 73.8735V57.9128Z"
        fill="#020118"
      />
      <Path
        d="M0 20.5059L20.7618 0.00830078H40.8327V9.39748L18.9109 31.3742H0.0255687H0V20.5059Z"
        fill="#020118"
      />
      <Path
        d="M58.3563 79L78.8027 58.1863V38.0651H69.437L47.5151 60.0418V78.9744V79H58.3563Z"
        fill="#020118"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_13_11566">
        <Rect
          width={608.93}
          height={75.9221}
          fill="white"
          transform="translate(3.07019)"
        />
      </ClipPath>
      <ClipPath id="clip1_13_11566">
        <Rect width={78.8027} height={79} fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
  )
}

export default RocketWorkLogo