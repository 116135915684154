import { COLOR } from '@rocket-mono/libs'
import React, { useCallback } from 'react'
import { Pressable } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { FONT } from '@rocket-mono/libs'

const AuthExpiredScreen: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handlePressLogin = useCallback(() => {
    navigate(`/login`)
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLOR.gray.g050,
        width: '100vw',
        height: '100vh',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <div style={{ marginTop: -100 }}>
          <img src="https://rocket.is/files/file_box/38d5b986678344a6860e922efe6d3e0b.svg"></img>
        </div>
        <div style={{ fontFamily: 'Pretendard', fontWeight: 600, fontSize: 28, color: '#3C464D', marginTop: 8 }}>
          Oopss!
        </div>
        <div style={{ fontFamily: 'Pretendard', fontSize: 18, fontWeight: 500, color: '#3C464D', marginTop: 14 }}>
          {t('error.sessionout.description')}
        </div>
        <div style={{ marginTop: 24 }}>
          <Pressable
            style={{
              ...FONT.txtMdBold,
              height: 40,
              alignSelf: 'center',
              backgroundColor: COLOR.main.blue,
              borderRadius: 5,
              borderWidth: 1,
              borderColor: COLOR.gray.g200,
              justifyContent: 'center',
              paddingHorizontal: 12,
            }}
            onPress={handlePressLogin}
          >
            <span
              style={{
                color: COLOR.mono.white,
                fontSize: 15,
              }}
            >
              {t(`widget.login.dologin`)}
            </span>
          </Pressable>
        </div>
      </div>
    </div>
  )
}

export default AuthExpiredScreen
