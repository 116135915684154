import type { IChatMessageReply, IDiscussion, IFile } from '@rocket-mono/libs'
import * as React from 'react'
import { FlatList, ViewStyle } from 'react-native'
import DiscussionItem from './discussionItem'
import { ListMoreButtonType } from './types'

interface Props {
  list: IDiscussion[]
  onPopupState: ({ index, state, item }: { index: number; state: string; item?: IDiscussion }) => void
  onPopupReplyState?: ({
    parentIndex,
    index,
    state,
    item,
  }: {
    parentIndex: number
    index: number
    state: string
    item?: IChatMessageReply
  }) => void
  onFileShare?: (item?: IFile) => void
  onFileExport?: (item?: IFile) => void
  onFileDownload?: (item?: IFile) => void
  onFilePreview?: (item?: IFile) => void
  itemContainerStyle?: ViewStyle
  itemReplyContainerStyle?: ViewStyle
  selectedIndex?: number
  replySelectedIndex?: string
  onLinkPress?: (url: string, text?: string | undefined) => void
  onScroll?: () => void
  onPressMoreButton?: (payload: ListMoreButtonType) => void
}

const DiscussionList = ({
  list,
  onPopupState,
  onPopupReplyState,
  onFileShare,
  onFileExport,
  onFileDownload,
  onFilePreview,
  itemContainerStyle,
  itemReplyContainerStyle,
  selectedIndex = -1,
  replySelectedIndex = '',
  onLinkPress,
  onPressMoreButton,
  onScroll,
}: Props) => {
  const renderItem = React.useCallback(
    ({ item, index }: { item: IDiscussion; index: number }) => (
      <DiscussionItem
        type={item.type}
        image={{ uri: item.imageUrl }}
        userName={item.username}
        onPopupState={(state) => onPopupState({ index, state, item })}
        onPopupReplyState={onPopupReplyState}
        mentionUserName={item.mentionUserName}
        message={item.message}
        messageSymbol={item.messageSymbol}
        regDate={item.regDate}
        moreList={item.moreList}
        containerStyle={itemContainerStyle}
        replyContainerStyle={itemReplyContainerStyle}
        chatMessageReply={item.chatMessageReply}
        file={item.file}
        onFileShare={onFileShare}
        onFileExport={onFileExport}
        onFileDownload={onFileDownload}
        onFilePreview={onFilePreview}
        writer={item.writer}
        recipient={item.recipient}
        deleted={item.deleted}
        selected={selectedIndex === index}
        replySelectedIndex={replySelectedIndex}
        onLinkPress={onLinkPress}
        onPressMoreButton={(payload) => {
          onPressMoreButton?.({ ...payload, item })
        }}
        index={index}
      />
    ),
    [list, onPopupState],
  )

  const keyExtractor = React.useCallback((item: IDiscussion) => `discuss-item-${item.no}`, [list, onPopupState])
  return <FlatList data={list} renderItem={renderItem} keyExtractor={keyExtractor} onScroll={onScroll} />
}

export default DiscussionList
