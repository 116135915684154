import React, { useEffect } from 'react'
import { LayoutNavProvider, useCurrentUser } from '@rocket-mono/providers'
import { WorkmodelCreateModal } from '../../modal'
import { useTranslation } from 'react-i18next'
interface Props {
  children: JSX.Element | JSX.Element[]
}

const Provider: React.FC<Props> = ({ children }) => {
  const { i18n } = useTranslation()
  const { currentUser } = useCurrentUser()

  useEffect(() => {
    i18n.changeLanguage(currentUser.lang ?? 'en')
  }, [currentUser.lang])
  return (
    <LayoutNavProvider>
      <>
        {children}
        <WorkmodelCreateModal />
      </>
    </LayoutNavProvider>
  )
}

export default Provider
