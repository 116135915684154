import { Text } from '@rocket-atoms/text'
import { SwitchLabel } from '@rocket-atoms/toggle'
import { COLOR } from '@rocket-mono/libs'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, Pressable, StyleSheet, View } from 'react-native'
import FileUploader from '../FileUploader'
import { Props } from './types'

// const MODAL_MAX_HEIGHT = 430

const WorkCardTodoFileModalView = ({
  visible,
  onClose,
  fileList,
  onPress,
  onLoadFile,
  onDropFile,
  onPasteFile,
  isFileUpload,
  isFileDownloadAuth,
  onIsFileUpload,
  onIsFileDownloadAuth,
}: Props) => {
  const { t } = useTranslation()

  const onPaste = React.useCallback(
    (e: ClipboardEvent) => {
      if (e.clipboardData) {
        onPasteFile && onPasteFile(e.clipboardData.files)
      }
    },
    [onPasteFile],
  )

  React.useEffect(() => {
    if (Platform.OS === 'web' && visible) {
      window.addEventListener('paste', onPaste)
    }
    return () => {
      window.removeEventListener('paste', onPaste)
    }
  }, [visible, onPaste])

  return (
    <View>
      <Pressable style={styles.backdrop} onPress={onClose} />
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <SwitchLabel
            label={t('workcardtodofilemodal.addfiledescription')}
            activated={isFileUpload}
            setActivated={(activated) => onIsFileUpload && onIsFileUpload(activated)}
            justifyContent="space-between"
          />
          <SwitchLabel
            label={t('workcardtodofilemodal.addfiledescription1')}
            activated={!isFileUpload}
            setActivated={(activated) => onIsFileUpload && onIsFileUpload(!activated)}
            justifyContent="space-between"
          />
          <View style={styles.headerBorder} />
          <Text fontName="txtXs" fontColor="gray.g700">
            {t('workcardtodofilemodal.fileviewpermission')}
          </Text>
          <SwitchLabel
            label={t('workcardtodofilemodal.everyone')}
            activated={isFileDownloadAuth}
            setActivated={(activated) => onIsFileDownloadAuth && onIsFileDownloadAuth(activated)}
            justifyContent="space-between"
          />
          <SwitchLabel
            label={t('workcardtodofilemodal.meandpermission')}
            activated={!isFileDownloadAuth}
            setActivated={(activated) => onIsFileDownloadAuth && onIsFileDownloadAuth(!activated)}
            justifyContent="space-between"
          />
        </View>
        <View style={styles.bodyBorder} />
        <FileUploader
          list={fileList}
          isMultiple={true}
          onPress={onPress}
          onLoadFile={onLoadFile}
          onDropFile={onDropFile}
          onPasteFile={onPasteFile}
          isBorder={false}
          backgroundColor={COLOR.transparent.base}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  container: {
    width: 360,
    height: 430,
    backgroundColor: COLOR.mono.white,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
  },
  headerContainer: {
    paddingTop: 9,
    paddingHorizontal: 18,
  },
  headerBorder: {
    borderTopColor: COLOR.mono.pale,
    borderTopWidth: 1,
    marginTop: 9,
    marginBottom: 17,
  },
  bodyBorder: {
    borderTopColor: COLOR.mono.pale,
    borderTopWidth: 1,
    marginTop: 9,
    marginBottom: 4.5,
  },
})

export default WorkCardTodoFileModalView
