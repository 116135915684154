import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { COLOR, FONT, IColors } from '@rocket-mono/libs'
import { Avatar } from '@rui/atoms'
import { BoardLabel, CardIcon, FAIcon, Tag, WorkIcon } from '@rocket/atoms'
import { CardType } from '@rocket/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'

export interface RuleValueIsTimeProps {
  weekly?: string[]
  monthly?: string
  yearly?: {
    month: string
    day: string
  }
  time?: string
  timezone?: string
}

export interface RuleValueToUserProps {
  profile?: string
  name: string
}

export interface RuleValueToWorkProps {
  type: string
  name: string
}

export interface RuleValueToBoardProps {
  name: string
  color: string
}

export interface RuleValueToCardProps {
  type: CardType
  name: string
}

interface Props {
  rulesName?: string
  isTime?: RuleValueIsTimeProps
  toUser?: RuleValueToUserProps[]
  toWork?: RuleValueToWorkProps
  toBoard?: RuleValueToBoardProps[]
  toCard?: RuleValueToCardProps
}

const RuleValueTemplate: React.FC<Props> = ({ rulesName, isTime, toUser, toWork, toBoard, toCard }) => {
  const { t } = useTranslation()

  return (
    <View style={styles.wrap}>
      <Text style={styles.rulesName}>{rulesName}</Text>
      <View style={styles.ruleContents}>
        {/* 특정시간 - 매주 */}
        {isTime && isTime.weekly && (
          <View style={styles.ruleInner}>
            <Text style={[styles.txt, styles.txtTitle]}>{t(`rule.repeat.everyweek`)}</Text>

            {isTime.weekly.map((day, idx) => (
              <View style={styles.block} key={`trigger-weekly-${idx}`}>
                <Text style={styles.blockText}>{day}</Text>
              </View>
            ))}
          </View>
        )}

        {/* 특정시간 - 매월 */}
        {isTime && isTime.monthly ? (
          <View style={styles.ruleInner}>
            <Text style={[styles.txt, styles.txtTitle]}>{t(`rule.repeat.everymonth`)}</Text>

            <View style={styles.block}>
              <Text style={styles.blockText}>{isTime.monthly}</Text>
            </View>
            <Text style={styles.txt}>{t(`rule.repeat.date`)}</Text>
          </View>
        ) : (
          <></>
        )}

        {/* 특정시간 - 매년 */}
        {isTime && isTime.yearly ? (
          <View style={styles.ruleInner}>
            <Text style={[styles.txt, styles.txtTitle]}>{t(`rule.repeat.everyyear`)}</Text>

            <View style={styles.block}>
              <Text style={styles.blockText}>{isTime.yearly.month}</Text>
            </View>
            <Text style={styles.txt}>{t('ruel.repeat.month')}</Text>
            <View style={styles.block}>
              <Text style={styles.blockText}>{isTime.yearly.day}</Text>
            </View>
            <Text style={styles.txt}>{t('rule.repeat.date')}</Text>
          </View>
        ) : (
          <></>
        )}

        {/* 특정시간 - 시간 */}
        {isTime && isTime.time ? (
          <View style={styles.ruleInner}>
            <Text style={[styles.txt, styles.txtTitle]}>{t(`rule.repeat.time`)}</Text>

            <View style={styles.block}>
              <Text style={styles.blockText}>{isTime.time}</Text>
            </View>
          </View>
        ) : (
          <></>
        )}

        {/* 특정유저에게 */}
        {toUser && (
          <View style={styles.ruleInner}>
            {toUser.map((item, idx) => (
              <View style={styles.tagWrap} key={`touser-${idx}`}>
                <Tag name={item.name} size={'MEDIUM'}>
                  <Avatar size={'xxsmall'} profileUrl={item.profile} />
                </Tag>
              </View>
            ))}
          </View>
        )}

        {/* 특정워크로 */}
        {toWork && (
          <View>
            <View style={styles.tagWrap}>
              <Tag name={toWork.name} size={'MEDIUM'}>
                <WorkIcon type={toWork.type} size={16} />
              </Tag>
            </View>

            {toWork && toBoard && (
              <View style={styles.nextBar}>
                <FAIcon iconName={faArrowDown} size={'2xs'} color={'gray.g400'} />
              </View>
            )}
          </View>
        )}

        {/* 특정보드로 or 특정워크의 보드로 */}
        {toBoard && (
          <View style={styles.ruleInner}>
            {toBoard.map((item, idx) => (
              <View style={styles.tagWrap} key={`toboard-${idx}`}>
                <Tag name={item.name} size={'MEDIUM'}>
                  <BoardLabel size={16} color={item.color as IColors} />
                </Tag>
              </View>
            ))}
          </View>
        )}

        {/* 선택된 카드를 */}
        {toCard && toCard.type !== 'TODO' && (
          <View style={styles.tagWrap}>
            <Tag name={toCard.name} size={'MEDIUM'}>
              <CardIcon variant="ROUNDSQUARE" type={toCard.type} size={'xs'} />
            </Tag>
          </View>
        )}
      </View>
    </View>
  )
}

export default RuleValueTemplate

const styles = StyleSheet.create({
  wrap: {},
  rulesName: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g700,
  },
  gab: {
    width: 4,
  },
  block: {
    alignItems: 'center',
    minWidth: 24,
    padding: 4,
    paddingRight: 4,
    marginLeft: 4,
    backgroundColor: COLOR.gray.g050,
  },
  blockText: {
    ...FONT.txtXsMedium,
    color: COLOR.gray.g900,
  },
  ruleContents: {},
  ruleInner: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 2,
    flexWrap: 'wrap',
  },
  txt: {
    ...FONT.txtXsMedium,
    color: COLOR.gray.g900,
    paddingLeft: 4,
  },
  txtTitle: {
    marginRight: 4,
    paddingLeft: 0,
  },
  tagWrap: {
    alignSelf: 'flex-start',
    marginRight: 4,
  },
  nextBar: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLOR.gray.g050,
    borderRadius: 4,
    padding: 2,
    paddingVertical: 4,
    marginVertical: 4,
  },
})
