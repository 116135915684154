import { Text } from '@rocket-atoms/text'
import { Tooltip } from '@rocket-atoms/tooltip'
import { COLOR, FONT, IColors } from '@rocket-mono/libs'
import * as React from 'react'
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native'
import Hyperlink from 'react-native-hyperlink'
import { CARD_LINK_TEMPLATE, MENTION_TEMPLATE, parseMessage } from '../utils/message'
import { MessageTextReplacer, ParserRule } from './types'

interface Props {
  content?: string
  fontColor: IColors
  onCardLinkPress?: (cardNo: string, cardName: string, channelNo: string) => void
  onMentionPress?: (memberNo: string, memberName: string) => void
  onLinkPress?: (url: string, text?: string) => void
}

const TodoText = ({ content, fontColor, onCardLinkPress, onMentionPress, onLinkPress }: Props) => {
  const mentionReplacer: MessageTextReplacer = React.useCallback((source, alias, index) => {
    // console.log('mentionReplacer', source, alias, index)
    const { chatRoomMemberNo: memberNo, memberName } = new RegExp(MENTION_TEMPLATE).exec(source)?.groups ?? {
      chatRoomMemberNo: '',
      memberName: '',
    }

    if (memberNo && memberName) {
      return (
        <TouchableOpacity
          key={`mention-${memberNo}-${alias}-${index}-activator`}
          onPress={() => {
            onMentionPress && onMentionPress(memberNo, memberName)
          }}
          style={[
            styles.mentionContainer,
            {
              ...Platform.select({
                native: {
                  marginTop: -2.5,
                },
              }),
            },
          ]}
        >
          <Text key={`mention-${memberNo}-${alias}-${index}`} fontName="txtSmMedium" fontColor="primary.blue500">
            @{memberName}
          </Text>
        </TouchableOpacity>
      )
    } else {
      return <Text key={`token-${alias}-${index}`}>{source}</Text>
    }
  }, [])

  const cardLinkReplacer: MessageTextReplacer = React.useCallback((source, alias, index) => {
    const { cardNo, cardName, channelNo } = new RegExp(CARD_LINK_TEMPLATE).exec(source)?.groups ?? {
      cardNo: '',
      cardName: '',
      channelNo: '',
    }

    if (cardNo && cardName && channelNo) {
      return (
        <Tooltip
          text={cardName}
          direction="bottom"
          darkType
          onPressTarget={() => {
            onCardLinkPress && onCardLinkPress(cardNo, cardName, channelNo)
          }}
        >
          {(props) => (
            <TouchableOpacity
              key={`cardlink-${cardNo}-${alias}-${index}-activator`}
              onPress={() => {
                onCardLinkPress && onCardLinkPress(cardNo, cardName, channelNo)
              }}
              style={[
                styles.cardLinkContainer,
                {
                  ...Platform.select({
                    native: {
                      marginTop: -2.5,
                    },
                  }),
                },
              ]}
              {...props}
            >
              <Text key={`cardlink-${cardNo}-${alias}-${index}`} fontName="txtSmMedium" fontColor="mono.white">
                #{cardNo}
              </Text>
            </TouchableOpacity>
          )}
        </Tooltip>
      )
    } else {
      return <Text key={`token-${alias}-${index}`}>{source}</Text>
    }
  }, [])

  const getMessageParse = React.useCallback(
    (content: string) => {
      const replacements: ParserRule[] = [
        // NOTE: 사용자 멘션 마크업에 대한 처리
        // example: <m chatRoomMemberNo=20905, memberName="김 로켓">
        {
          format: MENTION_TEMPLATE,
          replacer: mentionReplacer,
        },
        {
          format: CARD_LINK_TEMPLATE,
          replacer: cardLinkReplacer,
        },
      ]
      const message = parseMessage(content, replacements, {
        fontColor,
      })
      console.log('message', message)
      return message
    },
    [fontColor, mentionReplacer, cardLinkReplacer],
  )

  const message = React.useMemo(() => {
    if (!content) return ''
    const jsxElements: JSX.Element[] = getMessageParse(content)
    if (jsxElements.length > 0) {
      return jsxElements.map((element) => {
        return typeof element.props.children === 'string' ? (
          <Hyperlink onPress={onLinkPress} linkStyle={[styles.link, FONT.txtMd]}>
            <Text>{element.props.children}</Text>
          </Hyperlink>
        ) : (
          element
        )
      })
    } else {
      return content
    }
  }, [content, getMessageParse])

  return (
    <View nativeID="selectable-text" style={styles.container}>
      <Text fontName="txtMd" fontColor="mono.black">
        {message}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  mentionContainer: {
    borderRadius: 4,
    backgroundColor: COLOR.mono.white,
    paddingHorizontal: 3,
  },
  cardLinkContainer: {
    borderRadius: 4,
    backgroundColor: COLOR.dimmed.a60,
    paddingHorizontal: 4,
  },
  link: { color: COLOR.mono.black, textDecorationLine: 'underline' },
})

export default TodoText
