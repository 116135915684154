import React from 'react'
import { StyleSheet, View } from 'react-native'

import { SkeletonBar, SkeletonSquare } from '../SkeletomParts'

const SkeletonLinkInviteModal: React.FC = () => {
  return (
    <View>
      <View>
        <SkeletonBar width={'40%'} height={20} />
      </View>
      <View style={{ marginTop: 12 }}>
        <SkeletonBar width={'100%'} height={14} />
      </View>
      <View style={{ marginTop: 2 }}>
        <SkeletonBar width={'40%'} height={14} />
      </View>
      <View style={{ marginTop: 20 }}>
        <SkeletonSquare width={'100%'} height={36} />
      </View>
      <View style={{ alignItems: 'flex-end', marginTop: 4 }}>
        <SkeletonSquare width={50} height={36} />
      </View>
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          height: 250,
        }}
      >
        <SkeletonSquare width={140} height={140} color={'gray.g100'} />
      </View>
    </View>
  )
}

export default SkeletonLinkInviteModal

const styles = StyleSheet.create({
  rowFlexbox: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingLeft: 28,
    paddingVertical: 8,
    marginVertical: 2,
  },
})
