import React from 'react'
import { Platform, Pressable, StyleSheet, View } from 'react-native'

import { COLOR, FONT } from '@rocket-mono/libs'
import { useAstro, useModalDialog, useSystem } from '@rocket-mono/providers'

import { Text } from '@rocket/atoms'

import { RocketWorkLogo } from '@rocket-atoms/icon'
import { useMobileView } from '@rocket-mono/libs'
import { i18n } from 'i18next'
import FindPasswordModal from './FindPasswordModal'
import SignInView from './SignInView'
import { TermsModal } from './components/TermsModal'
import Providers from './providers'
import { SocialLoginType, Terms } from './types'

type IncorrectPasswordArg = { lockCount: number; maxCount: number }
type ErrorSymbolArgs = IncorrectPasswordArg
type LoginFail = { errorMessage: string; errorSymbol: string; errorType: string; errorSymbolArgs: ErrorSymbolArgs }
type LoginType = 'SNS' | 'EMAIL'
type DialogListType = { name: string; action: () => void }

interface Props {
  i18n: i18n
  socialList: SocialLoginType[]
  invitedEmail?: string
  onLoginSuccess: (token: string) => void
  onPressJoin: (email?: string) => void
  onPressLogo?: () => void
  onError?: () => void
  onOuterTermsPress?: (type: string) => void
  onReload?: (callback?: () => void) => void
}

const SignInScreen: React.FC<Props> = ({
  i18n,
  invitedEmail,
  socialList,
  onLoginSuccess,
  onPressJoin,
  onPressLogo,
  onError,
  onOuterTermsPress,
  onReload,
}) => {
  const mobileView = useMobileView(500)
  const { astro } = useAstro()

  const { language } = useSystem()
  const { showDialogMessage } = useModalDialog()

  // const navigate = useNavigate()

  const [emailPayload, setEmailPayload] = React.useState<{ email: string; password: string }>()

  const [languageList, setLanguageList] = React.useState([
    {
      itemCode: 'en',
      label: 'English',
      active: false,
    },
    {
      itemCode: 'ko',
      label: '한국어',
      active: true,
    },
    {
      itemCode: 'es',
      label: 'Español',
      active: false,
    },
    {
      itemCode: 'de',
      label: 'Deutsch',
      active: false,
    },
    {
      itemCode: 'vi',
      label: 'Tiếng Việt',
      active: false,
    },
    {
      itemCode: 'mn',
      label: 'Монгол',
      active: false,
    },
    {
      itemCode: 'ja',
      label: '日本語',
      active: false,
    },
  ])

  const [terms, setTerms] = React.useState<Terms>()
  const [isFindPassword, setIsFindPassword] = React.useState(false)

  const [isReset, setIsReset] = React.useState(false)

  const onSnsLogin = (response: Response) => {
    onLogin(response, 'SNS')
  }

  const onEmailLogin = (response: Response) => {
    onLogin(response, 'EMAIL')
  }

  const onLogin = (response: Response, loginType: LoginType) => {
    if (response.status === 200) {
      const token = response.headers.get('X-Auth-Token')
      astro.token(token ?? '')
      astro.readMe().then((data) => {
        if (data.userEmail === invitedEmail) {
          localStorage.removeItem('MemberInvitation')
        }
        onLoginSuccess(token ?? '')
      })
    } else {
      onLoginFail(response, loginType)
    }
  }
  const onLoginFail = (response: Response, loginType: LoginType) => {
    if (loginType === 'EMAIL') {
      response
        .json()
        .then((data: LoginFail) => {
          let titleRender: (() => JSX.Element) | undefined = undefined
          let messageRender: (() => JSX.Element) | undefined = undefined
          let list: DialogListType[] = []
          let subList: DialogListType[] = []
          let cancelText: string | undefined = undefined
          let onCancel: (() => void) | undefined = undefined
          if (data.errorType === 'NOT_ACCOUNT') {
            titleRender = () => <Text style={styles.messageTitleText}>{i18n.t('signin.notaccount.title')}</Text> // 'No account found'
            messageRender = () => <Text style={styles.messageText}>{i18n.t('signin.notaccount.text')}</Text>
            list = [
              {
                name: i18n.t('signin.notaccount.signup'), // 'Sign Up',
                action: () => onPressJoin(emailPayload?.email),
              },
            ]
            subList = [
              {
                name: i18n.t('signin.notaccount.retry'), // 'Retry',
                action: () => {
                  if (emailPayload) {
                    astro.loginByEmail({ type: 'pro', ...emailPayload }).then(onEmailLogin)
                  }
                },
              },
            ]
          } else if (data.errorType === 'INCORRECT_PASSWORD') {
            titleRender = () => <Text style={styles.messageTitleText}>{i18n.t('signin.incorrectpassword.title')}</Text> // 'Please Check your ID or Password again'
            if (data.errorSymbol && data.errorSymbolArgs) {
              const { lockCount, maxCount } = data.errorSymbolArgs
              messageRender = () => (
                <Text style={[styles.messageText, styles.colorRed]}>
                  {i18n.t(data.errorSymbol, { lockCount, maxCount })}
                </Text>
              )
            } else {
              messageRender = () => <Text style={[styles.messageText, styles.colorRed]}>{data.errorMessage}</Text>
            }
            list = [
              {
                name: i18n.t('signin.incorrectpassword.confirm'), // 현기능은 재시도로 변경됨
                action: () => {
                  if (emailPayload) {
                    astro.loginByEmail({ type: 'pro', ...emailPayload }).then(onEmailLogin)
                  }
                },
              },
            ]
            cancelText = i18n.t('signin.incorrectpassword.cancel') // 현기능은 임시 패스워드로 발송으로 변경됨
            onCancel = () => {
              setIsFindPassword(true)
            }
          } else if (data.errorType === 'ACCOUNT_LOCKED') {
            titleRender = () => <Text style={styles.messageTitleText}>{i18n.t('signin.accountlocked.title')}</Text> // 'Please Check your ID or Password again'
            messageRender = () => <Text style={styles.messageText}>{i18n.t('signin.accountlocked.text')}</Text>
            list = [
              {
                name: i18n.t('signin.accountlocked.findpassword'), // 'Find Password',
                action: () => {
                  setIsFindPassword(true)
                },
              },
            ]
            subList = [
              {
                name: i18n.t('signin.accountlocked.retry'), // 현기능은 다른 계정으로 로그인으로 변경됨
                action: () => {
                  if (onReload) {
                    onReload(() => {
                      setIsReset(true)
                      setTimeout(() => {
                        setIsReset(false)
                      }, 1000)
                    })
                  } else {
                    location.reload()
                  }
                },
              },
            ]
          } else {
            onError && onError()
          }
          showDialogMessage({
            title: '',
            titleRender,
            messageRender,
            list,
            subList,
            cancelText,
            onCancel,
          })
        })
        .catch(() => {
          onError && onError()
        })
    } else {
      console.log('not supported login type')
    }
  }

  const onLanguage = (lang: string) => {
    const newList = languageList.map((item) => {
      if (item.itemCode === lang) {
        return { ...item, active: true }
      } else {
        return { ...item, active: false }
      }
    })
    setLanguageList(newList)
    i18n.changeLanguage(lang)
    // document.cookie = `APPLICATION_LOCALE=${lang};`
  }

  const onFindPasswordPress = () => {
    setIsFindPassword(true)
  }

  const onTermsPress = (type: string) => {
    if (onOuterTermsPress) {
      onOuterTermsPress(type)
    } else {
      if (type === 'terms') {
        setTerms({
          title: i18n.t('terms.title'),
          contents: i18n.t('terms.contents'),
        })
      } else if (type === 'privacy') {
        setTerms({
          title: i18n.t('privacy.title'),
          contents: i18n.t('privacy.contents'),
        })
      } else {
        console.log('not supported type')
      }
    }
  }

  React.useEffect(() => {
    console.log('useEffect-onLanguage', language)
    onLanguage(language)
  }, [language])

  if (invitedEmail === undefined) return <></>

  return (
    <Providers i18n={i18n}>
      <View style={[styles.container, mobileView && { backgroundColor: COLOR.mono.white }]}>
        <View style={[styles.contentsWrap]}>
          <Pressable onPress={onPressLogo} style={[commonStyle.logoArea, mobileView && commonStyle.appLogoArea]}>
            <RocketWorkLogo width={mobileView ? 210 : 160} height={30} />
          </Pressable>
          <View style={[styles.signInContainer, mobileView && styles.appSignInContainer]}>
            <SignInView
              initialEmail={invitedEmail}
              languageList={languageList}
              socialList={socialList}
              onSocialPress={onSnsLogin}
              onEmailPress={(email, password) => {
                setEmailPayload({ email, password })
                astro.loginByEmail({ type: 'pro', email, password }).then(onEmailLogin)
              }}
              onLanguagePress={onLanguage}
              onJoinPress={onPressJoin}
              onFindPasswordPress={onFindPasswordPress}
              onTermsPress={onTermsPress}
              mobileView={mobileView}
              isReset={isReset}
            />
          </View>
          {terms ? (
            <TermsModal title={terms.title} contents={terms.contents} onClose={() => setTerms(undefined)} />
          ) : (
            <></>
          )}
          {isFindPassword ? <FindPasswordModal onClose={() => setIsFindPassword(false)} /> : <></>}
        </View>

        {mobileView && (
          <View style={styles.copyrightArea}>
            <Text style={styles.copyText}>2024 ⓒ RocketWork</Text>
          </View>
        )}
      </View>
    </Providers>
  )
}

export const commonStyle = StyleSheet.create({
  logoArea: {
    position: 'absolute',
    top: 35,
    left: 40,
  },
  appLogoArea: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    marginBottom: 24,
  },
})

const styles = StyleSheet.create({
  contentsWrap: {
    flex: 1,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  copyrightArea: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingVertical: 32,
  },
  copyText: {
    ...FONT.txt2Xs,
    color: COLOR.gray.g700,
  },
  container: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLOR.gray.g050,
  },
  signInContainer: {
    width: 420,
    backgroundColor: COLOR.mono.white,
    paddingHorizontal: 55,
    paddingVertical: 50,
    borderRadius: 12,
  },
  appSignInContainer: {
    width: '100%',
    paddingHorizontal: 40,
    paddingVertical: 0,
    borderRadius: 0,
  },
  messageTitleText: {
    ...Platform.select({
      web: {
        ...FONT.H5Bold,
        marginBottom: 8,
      },
      native: {
        ...FONT.H6Bold,
        marginBottom: 4,
      },
    }),
    color: COLOR.mono.black,
  },
  messageText: {
    ...Platform.select({
      web: {
        ...FONT.txtMd,
      },
      native: {
        ...FONT.txtSm,
      },
    }),
    color: COLOR.mono.black,
  },
  colorRed: {
    color: COLOR.main.red,
  },
})

export default SignInScreen
