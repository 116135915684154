import * as React from 'react'
import { StyleSheet, Pressable, Text, View } from 'react-native'
import { COLOR, FONT, getBoxShadow } from '@rocket-mono/libs'
import Svg, { Path, G,Defs, ClipPath, Rect } from 'react-native-svg'



interface Props {
  onPress: ()=>void
  provider: "GOOGLE" | "NAVER" | "APPLE" | "KAKAO" | "FACEBOOK"
  appType?: boolean
}

const GoogleIcon = () => {
  return(
  <Svg
    width={19}
    height={19}
    viewBox="0 0 19 19"
    fill="none"
  >
    <G clipPath="url(#clip0_8154_44764)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8272 9.3577C18.8272 8.70863 18.7677 8.08541 18.6585 7.48633H9.92285V11.0257H14.915C14.7001 12.1695 14.0464 13.1384 13.0643 13.7875V16.0829H16.0624C17.8161 14.49 18.828 12.1445 18.828 9.3577H18.8272Z"
        fill="#3E82F1"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.92292 18.3C12.4273 18.3 14.5271 17.4803 16.0616 16.083L13.0635 13.7875C12.2325 14.3366 11.1708 14.6607 9.92205 14.6607C7.50592 14.6607 5.46116 13.0514 4.73151 10.8887H1.63379V13.2591C3.16037 16.2494 6.29654 18.3 9.92292 18.3Z"
        fill="#32A753"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.73261 10.8887C4.54736 10.3397 4.44162 9.7535 4.44162 9.15011C4.44162 8.54672 4.54736 7.96057 4.73261 7.41148V5.04102H1.63401C1.00573 6.27624 0.647461 7.67353 0.647461 9.15011C0.647461 10.6267 1.00573 12.024 1.63401 13.2592L4.73261 10.8887Z"
        fill="#F9BB00"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.92292 3.63931C11.2843 3.63931 12.5077 4.10134 13.4689 5.00729L16.1297 2.38253C14.5228 0.906811 12.4229 0 9.92292 0C6.29654 0 3.16037 2.05067 1.63379 5.0409L4.73238 7.41137C5.46203 5.24864 7.50679 3.63931 9.92292 3.63931Z"
        fill="#E74133"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_8154_44764">
        <Rect
          width={18.18}
          height={18.3}
          fill="white"
          transform="translate(0.647461)"
        />
      </ClipPath>
    </Defs>
  </Svg>
  )
}

const AppleIcon = () => {
  return(
    <Svg
      width={18}
      height={21}
      viewBox="0 0 18 21"
      fill="none"
    >
      <Path
        d="M16.1996 17.9262C15.7611 18.567 15.2803 19.1517 14.7574 19.6804C14.2514 20.193 13.6358 20.4574 12.9105 20.4734C12.1852 20.4894 11.6117 20.3612 11.19 20.0889C10.7852 19.8005 10.1779 19.6564 9.36829 19.6564C8.54178 19.6564 7.91768 19.7925 7.496 20.0649C7.07431 20.3212 6.51768 20.4654 5.82612 20.4974C5.11768 20.5294 4.48515 20.2651 3.92853 19.7044C3.38877 19.1277 2.89118 18.527 2.43576 17.9022C1.98033 17.2614 1.55865 16.5165 1.1707 15.6674C0.799612 14.8023 0.529732 13.9052 0.361058 12.9761C0.192383 12.0309 0.150214 11.0937 0.234551 10.1646C0.318889 9.21943 0.597202 8.35435 1.06949 7.56938C1.54178 6.78441 2.17431 6.16764 2.96708 5.71909C3.75985 5.25451 4.61166 5.01421 5.5225 4.99819C6.21407 4.98217 6.8719 5.12635 7.496 5.43073C8.12009 5.73511 8.65142 5.8873 9.08997 5.8873C9.51166 5.8873 10.0936 5.71108 10.8358 5.35864C11.5779 5.0062 12.3623 4.87004 13.1888 4.95014C13.543 4.96616 14.1249 5.09431 14.9346 5.33461C15.7611 5.55889 16.4864 6.11157 17.1105 6.99267C17.0767 7.02471 16.6803 7.36112 15.9213 8.00192C15.1623 8.62669 14.7912 9.58788 14.808 10.8855C14.8249 12.4394 15.3056 13.5208 16.2502 14.1295C17.1948 14.7222 17.6755 15.0266 17.6924 15.0426C17.6755 15.0747 17.549 15.3871 17.3129 15.9798C17.0936 16.5725 16.7225 17.2213 16.1996 17.9262ZM10.0514 1.53791C10.4394 1.10537 10.9285 0.744923 11.5189 0.456566C12.1093 0.168208 12.6743 0.0160199 13.2141 0C13.2815 0.592735 13.2056 1.17746 12.9864 1.75417C12.7671 2.33089 12.4719 2.83551 12.1008 3.26805C11.7297 3.70059 11.2574 4.06103 10.6839 4.34939C10.1105 4.63775 9.52009 4.7579 8.91286 4.70984C8.82853 4.13312 8.90443 3.55641 9.14058 2.97969C9.39359 2.40298 9.6972 1.92238 10.0514 1.53791Z"
        fill="#333333"
      />
    </Svg>
  )
}


const AuthButton = ({onPress, provider, appType}: Props) => {
  const [isHover, setIsHover] = React.useState(false)

  return(
    <Pressable
      onPress={onPress}
      onHoverIn={()=>setIsHover(true)}
      onHoverOut={()=>setIsHover(false)}
      style={[
        styles.snsTextButton,
        isHover && styles.snsTextButtonHover,
        appType && styles.snsTextbuttonAppType
      ]}
    >
      <View style={styles.iconArea}>
        {provider === 'GOOGLE' && <GoogleIcon />}  
        {provider === 'APPLE' && <AppleIcon />}  
      </View>
      <Text style={styles.text}>{provider.charAt(0) + provider.slice(1).toLowerCase()}</Text>
    </Pressable>
  )
}

export default AuthButton

const styles = StyleSheet.create({
  snsTextButton:{
    position: 'relative',
    flexDirection: 'row',
    justifyContent:'center',
    alignItems:'center',
    height: 44,
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderColor: COLOR.gray.g300,
    borderRadius: 4,
    marginBottom: 8
  },
  snsTextbuttonAppType:{
    height: 48,
    borderRadius: 8,
    borderColor: COLOR.gray.g200,
    marginBottom: 12
  },
  iconArea:{
    position: 'absolute',
    width: '100%',
    height:' 100%',
    justifyContent: 'center',
    top: 0,
    right: 0,
    left: 20,
    bottom: 0,
  },
  snsTextButtonHover:{
    ...getBoxShadow(0, 0, 0, 0.1, 4, 0, 0, 0),
  },
  text:{
    ...FONT.txtMdMedium,
    color: COLOR.gray.g800,
  },
})