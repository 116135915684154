import { IColors, getRealColor, FONT } from '@rocket-mono/libs'
import React from 'react'
import { Text, StyleSheet, ViewStyle } from 'react-native'

interface Props {
  style?: ViewStyle
  children: React.ReactNode
  underline?: boolean
  color?: IColors
  onPress?: () => void
}

export const TextLink: React.FC<Props> = ({ color = 'main.blue', underline = false, onPress, ...props }) => {
  return (
    <Text
      style={[
        props.style,
        style.text,
        {
          textDecorationLine: underline ? 'underline' : 'none',
          color: getRealColor(color),
        },
      ]}
      onPress={onPress}
    >
      {props.children}
    </Text>
  )
}

const style = StyleSheet.create({
  container: {},
  text: {
    ...FONT.txtXs,
  },
})
