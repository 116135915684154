import React, {useState} from 'react'
import { StyleSheet } from 'react-native'
import Svg, { Path } from 'react-native-svg'


export interface TriggerIconProps{
  type: 'NEWCARD-APPEAR' | 'TODO-COMPLETED' | 'SCHEDULE-PASSES' | 'TIME-CERTAIN'
  size?: number
}
const TriggerIcon = ({type, size = 50}: TriggerIconProps) => {
  return(
    type === 'NEWCARD-APPEAR' ?
      <Svg
        width={size}
        height={size}
        viewBox="0 0 51 50"
        fill="none"
      >
        <Path
          d="M0.983398 8C0.983398 3.58172 4.56512 0 8.9834 0H42.9834C47.4017 0 50.9834 3.58172 50.9834 8V42C50.9834 46.4183 47.4017 50 42.9834 50H8.9834C4.56512 50 0.983398 46.4183 0.983398 42V8Z"
          fill="white"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.9834 1H8.9834C5.11741 1 1.9834 4.13401 1.9834 8V42C1.9834 45.866 5.11741 49 8.9834 49H42.9834C46.8494 49 49.9834 45.866 49.9834 42V8C49.9834 4.13401 46.8494 1 42.9834 1ZM8.9834 0C4.56512 0 0.983398 3.58172 0.983398 8V42C0.983398 46.4183 4.56512 50 8.9834 50H42.9834C47.4017 50 50.9834 46.4183 50.9834 42V8C50.9834 3.58172 47.4017 0 42.9834 0H8.9834Z"
          fill="#E6E9ED"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.793 20.793C14.793 18.86 16.36 17.293 18.293 17.293H36.4999C38.4329 17.293 39.9999 18.86 39.9999 20.793V32.4826C39.9999 34.4156 38.4329 35.9826 36.4999 35.9826H18.293C16.36 35.9826 14.793 34.4156 14.793 32.4826V20.793ZM18.293 18.293C16.9123 18.293 15.793 19.4123 15.793 20.793V32.4826C15.793 33.8633 16.9123 34.9826 18.293 34.9826H36.4999C37.8806 34.9826 38.9999 33.8633 38.9999 32.4826V20.793C38.9999 19.4123 37.8806 18.293 36.4999 18.293H18.293Z"
          fill="#3C464D"
        />
        <Path
          d="M12.5 18C12.5 16.3431 13.8431 15 15.5 15H33.7069C35.3638 15 36.7069 16.3431 36.7069 18V29.6897C36.7069 31.3465 35.3638 32.6897 33.7069 32.6897H15.5C13.8431 32.6897 12.5 31.3465 12.5 29.6897V18Z"
          fill="white"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 18C12 16.067 13.567 14.5 15.5 14.5H33.7069C35.6399 14.5 37.2069 16.067 37.2069 18V29.6897C37.2069 31.6226 35.6399 33.1897 33.7069 33.1897H15.5C13.567 33.1897 12 31.6227 12 29.6897V18ZM15.5 15.5C14.1193 15.5 13 16.6193 13 18V29.6897C13 31.0704 14.1193 32.1897 15.5 32.1897H33.7069C35.0876 32.1897 36.2069 31.0704 36.2069 29.6897V18C36.2069 16.6193 35.0876 15.5 33.7069 15.5H15.5Z"
          fill="#3C464D"
        />
        <Path
          d="M16.2246 25.7067C16.2246 25.4496 16.433 25.2412 16.6901 25.2412H32.5177C32.7748 25.2412 32.9832 25.4496 32.9832 25.7067C32.9832 25.9638 32.7748 26.1722 32.5177 26.1722H16.6901C16.433 26.1722 16.2246 25.9638 16.2246 25.7067Z"
          fill="white"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.5177 26.1722C32.7748 26.1722 32.9832 25.9638 32.9832 25.7067C32.9832 25.4496 32.7748 25.2412 32.5177 25.2412H16.6901C16.433 25.2412 16.2246 25.4496 16.2246 25.7067C16.2246 25.9638 16.433 26.1722 16.6901 26.1722H32.5177Z"
          fill="#3C464D"
        />
        <Path
          d="M16.2246 27.569C16.2246 27.3119 16.433 27.1035 16.6901 27.1035H32.5177C32.7748 27.1035 32.9832 27.3119 32.9832 27.569C32.9832 27.8261 32.7748 28.0346 32.5177 28.0346H16.6901C16.433 28.0346 16.2246 27.8261 16.2246 27.569Z"
          fill="white"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.5177 28.0346C32.7748 28.0346 32.9832 27.8261 32.9832 27.569C32.9832 27.3119 32.7748 27.1035 32.5177 27.1035H16.6901C16.433 27.1035 16.2246 27.3119 16.2246 27.569C16.2246 27.8261 16.433 28.0346 16.6901 28.0346H32.5177Z"
          fill="#3C464D"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.1211 19.1553C31.3972 19.1553 31.6211 19.3791 31.6211 19.6553V23.3794C31.6211 23.6556 31.3972 23.8794 31.1211 23.8794C30.845 23.8794 30.6211 23.6556 30.6211 23.3794V19.6553C30.6211 19.3791 30.845 19.1553 31.1211 19.1553Z"
          fill="#3C464D"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.7588 21.5176C28.7588 21.2414 28.9826 21.0176 29.2588 21.0176L32.9829 21.0176C33.2591 21.0176 33.4829 21.2414 33.4829 21.5176C33.4829 21.7937 33.2591 22.0176 32.9829 22.0176L29.2588 22.0176C28.9826 22.0176 28.7588 21.7937 28.7588 21.5176Z"
          fill="#3C464D"
        />
      </Svg>
      :
    type === 'TODO-COMPLETED' ?
      <Svg
        width={size}
        height={size}
        viewBox="0 0 51 50"
        fill="none"
      >
        <Path
          d="M0.983398 8C0.983398 3.58172 4.56512 0 8.9834 0H42.9834C47.4017 0 50.9834 3.58172 50.9834 8V42C50.9834 46.4183 47.4017 50 42.9834 50H8.9834C4.56512 50 0.983398 46.4183 0.983398 42V8Z"
          fill="white"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.9834 1H8.9834C5.11741 1 1.9834 4.13401 1.9834 8V42C1.9834 45.866 5.11741 49 8.9834 49H42.9834C46.8494 49 49.9834 45.866 49.9834 42V8C49.9834 4.13401 46.8494 1 42.9834 1ZM8.9834 0C4.56512 0 0.983398 3.58172 0.983398 8V42C0.983398 46.4183 4.56512 50 8.9834 50H42.9834C47.4017 50 50.9834 46.4183 50.9834 42V8C50.9834 3.58172 47.4017 0 42.9834 0H8.9834Z"
          fill="#E6E9ED"
        />
        <Path
          d="M13 18.5C13 16.8431 14.3431 15.5 16 15.5H34.2667C35.9235 15.5 37.2667 16.8431 37.2667 18.5V30.2333C37.2667 31.8902 35.9235 33.2333 34.2667 33.2333H16C14.3431 33.2333 13 31.8902 13 30.2333V18.5Z"
          fill="white"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 18.5C12.5 16.567 14.067 15 16 15H34.2667C36.1997 15 37.7667 16.567 37.7667 18.5V30.2333C37.7667 32.1663 36.1997 33.7333 34.2667 33.7333H16C14.067 33.7333 12.5 32.1663 12.5 30.2333V18.5ZM16 16C14.6193 16 13.5 17.1193 13.5 18.5V30.2333C13.5 31.614 14.6193 32.7333 16 32.7333H34.2667C35.6474 32.7333 36.7667 31.614 36.7667 30.2333V18.5C36.7667 17.1193 35.6474 16 34.2667 16H16Z"
          fill="#3C464D"
        />
        <Path
          d="M16.7334 26.2333C16.7334 25.9755 16.9423 25.7666 17.2001 25.7666H33.0667C33.3245 25.7666 33.5334 25.9755 33.5334 26.2333C33.5334 26.491 33.3245 26.6999 33.0667 26.6999H17.2001C16.9423 26.6999 16.7334 26.491 16.7334 26.2333Z"
          fill="#6A7C87"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.0667 26.6999C33.3245 26.6999 33.5334 26.491 33.5334 26.2333C33.5334 25.9755 33.3245 25.7666 33.0667 25.7666H17.2001C16.9423 25.7666 16.7334 25.9755 16.7334 26.2333C16.7334 26.491 16.9423 26.6999 17.2001 26.6999C17.2001 26.6999 17.2001 26.6999 17.2001 26.6999H33.0667Z"
          fill="#3C464D"
        />
        <Path
          d="M16.7334 28.1005C16.7334 27.8427 16.9423 27.6338 17.2001 27.6338H33.0667C33.3245 27.6338 33.5334 27.8427 33.5334 28.1005C33.5334 28.3582 33.3245 28.5671 33.0667 28.5671H17.2001C16.9423 28.5671 16.7334 28.3582 16.7334 28.1005Z"
          fill="#6A7C87"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.0667 28.5671C33.3245 28.5671 33.5334 28.3582 33.5334 28.1005C33.5334 27.8427 33.3245 27.6338 33.0667 27.6338H17.2001C16.9423 27.6338 16.7334 27.8427 16.7334 28.1005C16.7334 28.3582 16.9423 28.5671 17.2001 28.5671C17.2001 28.5671 17.2001 28.5671 17.2001 28.5671H33.0667Z"
          fill="#3C464D"
        />
        <Path
          d="M41.0002 29.0338C41.0002 33.673 37.2394 37.4338 32.6002 37.4338C27.961 37.4338 24.2002 33.673 24.2002 29.0338C24.2002 24.3946 27.961 20.6338 32.6002 20.6338C37.2394 20.6338 41.0002 24.3946 41.0002 29.0338Z"
          fill="white"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6002 36.4338C36.6871 36.4338 40.0002 33.1207 40.0002 29.0338C40.0002 24.9469 36.6871 21.6338 32.6002 21.6338C28.5133 21.6338 25.2002 24.9469 25.2002 29.0338C25.2002 33.1207 28.5133 36.4338 32.6002 36.4338ZM32.6002 37.4338C37.2394 37.4338 41.0002 33.673 41.0002 29.0338C41.0002 24.3946 37.2394 20.6338 32.6002 20.6338C27.961 20.6338 24.2002 24.3946 24.2002 29.0338C24.2002 33.673 27.961 37.4338 32.6002 37.4338Z"
          fill="#3C464D"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.2199 26.8134C36.4152 27.0087 36.4152 27.3253 36.2199 27.5205L32.1366 31.6039C32.0428 31.6976 31.9156 31.7503 31.783 31.7503C31.6504 31.7503 31.5232 31.6976 31.4295 31.6039L28.9795 29.1539C28.7842 28.9586 28.7842 28.642 28.9795 28.4468C29.1747 28.2515 29.4913 28.2515 29.6866 28.4468L31.783 30.5432L35.5128 26.8134C35.7081 26.6182 36.0246 26.6182 36.2199 26.8134Z"
          fill="#3C464D"
        />
      </Svg>
      :
    type === 'SCHEDULE-PASSES' ?
      <Svg
        width={size}
        height={size}
        viewBox="0 0 50 50"
        fill="none"
      >
        <Path
          d="M0 8C0 3.58172 3.58172 0 8 0H42C46.4183 0 50 3.58172 50 8V42C50 46.4183 46.4183 50 42 50H8C3.58172 50 0 46.4183 0 42V8Z"
          fill="white"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42 1H8C4.13401 1 1 4.13401 1 8V42C1 45.866 4.13401 49 8 49H42C45.866 49 49 45.866 49 42V8C49 4.13401 45.866 1 42 1ZM8 0C3.58172 0 0 3.58172 0 8V42C0 46.4183 3.58172 50 8 50H42C46.4183 50 50 46.4183 50 42V8C50 3.58172 46.4183 0 42 0H8Z"
          fill="#E6E9ED"
        />
        <Path
          d="M12.5 17.4429C12.5 15.786 13.8431 14.4429 15.5 14.4429H34.5C36.1569 14.4429 37.5 15.786 37.5 17.4429V32.5967C37.5 34.2536 36.1569 35.5967 34.5 35.5967H15.5C13.8431 35.5967 12.5 34.2536 12.5 32.5967V17.4429Z"
          fill="white"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 17.4429C12 15.5099 13.567 13.9429 15.5 13.9429H34.5C36.433 13.9429 38 15.5099 38 17.4429V32.5967C38 34.5297 36.433 36.0967 34.5 36.0967H15.5C13.567 36.0967 12 34.5297 12 32.5967V17.4429ZM15.5 14.9429C14.1193 14.9429 13 16.0622 13 17.4429V32.5967C13 33.9774 14.1193 35.0967 15.5 35.0967H34.5C35.8807 35.0967 37 33.9774 37 32.5967V17.4429C37 16.0622 35.8807 14.9429 34.5 14.9429H15.5Z"
          fill="#3C464D"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.5 19.0293H12.5V18.0293H37.5V19.0293Z"
          fill="#3C464D"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.7886 16.3853C17.5124 16.3853 17.2886 16.1614 17.2886 15.8853V13.0006C17.2886 12.7245 17.5124 12.5006 17.7886 12.5006C18.0647 12.5006 18.2886 12.7245 18.2886 13.0006V15.8853C18.2886 16.1614 18.0647 16.3853 17.7886 16.3853Z"
          fill="#3C464D"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.2114 16.3853C31.9353 16.3853 31.7114 16.1614 31.7114 15.8853V13.0006C31.7114 12.7245 31.9353 12.5006 32.2114 12.5006C32.4876 12.5006 32.7114 12.7245 32.7114 13.0006V15.8853C32.7114 16.1614 32.4876 16.3853 32.2114 16.3853Z"
          fill="#3C464D"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.9231 31.3083C26.3349 31.5677 25.6844 31.7118 25.0002 31.7118C22.3662 31.7118 20.231 29.5765 20.231 26.9426C20.231 24.3086 22.3662 22.1733 25.0002 22.1733C27.6342 22.1733 29.7694 24.3086 29.7694 26.9426C29.7694 27.1049 29.7613 27.2654 29.7455 27.4236H30.7497C30.7627 27.265 30.7694 27.1045 30.7694 26.9426C30.7694 23.7563 28.1864 21.1733 25.0002 21.1733C21.8139 21.1733 19.231 23.7563 19.231 26.9426C19.231 30.1288 21.8139 32.7118 25.0002 32.7118C25.6744 32.7118 26.3216 32.5961 26.9231 32.3836V31.3083Z"
          fill="#3C464D"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.6977 25.7147C27.8752 25.5031 28.1906 25.4755 28.4021 25.653L30.1025 27.0798L31.5292 25.3795C31.7067 25.1679 32.0221 25.1403 32.2336 25.3178C32.4452 25.4953 32.4728 25.8107 32.2953 26.0222L30.5471 28.1056C30.4619 28.2072 30.3398 28.2708 30.2077 28.2823C30.0756 28.2939 29.9443 28.2525 29.8427 28.1672L27.7593 26.4191C27.5478 26.2416 27.5202 25.9262 27.6977 25.7147Z"
          fill="#3C464D"
        />
        <Path
          d="M27.6001 31.7002C27.6001 31.9763 27.2668 32.2002 27.1001 32.2548C26.6001 32.5335 26.6001 31.9763 26.6001 31.7002C26.6001 31.4241 26.824 31.2002 27.1001 31.2002C27.3762 31.2002 27.6001 31.4241 27.6001 31.7002Z"
          fill="#3C464D"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.7541 31.3392C26.7655 31.3283 26.7774 31.3179 26.7898 31.3081C26.875 31.2405 26.9829 31.2002 27.1001 31.2002C27.3762 31.2002 27.6001 31.4241 27.6001 31.7002C27.6001 31.7347 27.5949 31.7685 27.5854 31.8011C27.5823 31.7931 27.5787 31.7845 27.5746 31.7754C27.5658 31.7559 27.5525 31.7299 27.5339 31.7002C27.4674 31.5938 27.3335 31.4402 27.1001 31.3675C27.0841 31.3625 27.0677 31.3579 27.0507 31.3537C26.9528 31.3295 26.8672 31.3271 26.7981 31.3336C26.7826 31.3351 26.7679 31.337 26.7541 31.3392ZM27.2702 32.1705C27.2171 32.1897 27.1598 32.2002 27.1001 32.2002C27.0941 32.2002 27.0881 32.2001 27.0821 32.1999C27.0777 32.1997 27.0734 32.1995 27.0691 32.1993C26.8074 32.1833 26.6001 31.9659 26.6001 31.7002C26.6001 31.9763 26.6001 32.5335 27.1001 32.2548C27.1472 32.2394 27.2077 32.2104 27.2702 32.1705Z"
          fill="#3C464D"
        />
      </Svg>
      :
    type === 'TIME-CERTAIN' ?
      <Svg
        width={size}
        height={size}
        viewBox="0 0 50 50"
        fill="none"
      >
        <Path
          d="M0 8C0 3.58172 3.58172 0 8 0H42C46.4183 0 50 3.58172 50 8V42C50 46.4183 46.4183 50 42 50H8C3.58172 50 0 46.4183 0 42V8Z"
          fill="white"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42 1H8C4.13401 1 1 4.13401 1 8V42C1 45.866 4.13401 49 8 49H42C45.866 49 49 45.866 49 42V8C49 4.13401 45.866 1 42 1ZM8 0C3.58172 0 0 3.58172 0 8V42C0 46.4183 3.58172 50 8 50H42C46.4183 50 50 46.4183 50 42V8C50 3.58172 46.4183 0 42 0H8Z"
          fill="#E6E9ED"
        />
        <Path
          d="M38 25C38 32.1797 32.1797 38 25 38C17.8203 38 12 32.1797 12 25C12 17.8203 17.8203 12 25 12C32.1797 12 38 17.8203 38 25Z"
          fill="white"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25 37C31.6274 37 37 31.6274 37 25C37 18.3726 31.6274 13 25 13C18.3726 13 13 18.3726 13 25C13 31.6274 18.3726 37 25 37ZM25 38C32.1797 38 38 32.1797 38 25C38 17.8203 32.1797 12 25 12C17.8203 12 12 17.8203 12 25C12 32.1797 17.8203 38 25 38Z"
          fill="#3C464D"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25 18C25.2761 18 25.5 18.2239 25.5 18.5V25.5196L29.9089 28.291C30.1427 28.4379 30.2131 28.7466 30.0662 28.9804C29.9192 29.2142 29.6106 29.2846 29.3768 29.1376L24.7339 26.2192C24.5883 26.1277 24.5 25.9679 24.5 25.7959V18.5C24.5 18.2239 24.7239 18 25 18Z"
          fill="#3C464D"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25 14.5C25.4142 14.5 25.75 14.8358 25.75 15.25L25.75 15.5286C25.75 15.9428 25.4142 16.2786 25 16.2786C24.5858 16.2786 24.25 15.9428 24.25 15.5286L24.25 15.25C24.25 14.8358 24.5858 14.5 25 14.5Z"
          fill="#3C464D"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25 33.7217C25.4142 33.7217 25.75 34.0575 25.75 34.4717L25.75 34.7503C25.75 35.1645 25.4142 35.5003 25 35.5003C24.5858 35.5003 24.25 35.1645 24.25 34.7503L24.25 34.4717C24.25 34.0575 24.5858 33.7217 25 33.7217Z"
          fill="#3C464D"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4077 25C14.4077 24.5858 14.7435 24.25 15.1577 24.25L15.4363 24.25C15.8505 24.25 16.1863 24.5858 16.1863 25C16.1863 25.4142 15.8505 25.75 15.4363 25.75L15.1577 25.75C14.7435 25.75 14.4077 25.4142 14.4077 25Z"
          fill="#3C464D"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.7217 25C33.7217 24.5858 34.0575 24.25 34.4717 24.25L34.7503 24.25C35.1645 24.25 35.5003 24.5858 35.5003 25C35.5003 25.4142 35.1645 25.75 34.7503 25.75L34.4717 25.75C34.0575 25.75 33.7217 25.4142 33.7217 25Z"
          fill="#3C464D"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4077 25C14.4077 24.5858 14.7435 24.25 15.1577 24.25L15.4363 24.25C15.8505 24.25 16.1863 24.5858 16.1863 25C16.1863 25.4142 15.8505 25.75 15.4363 25.75L15.1577 25.75C14.7435 25.75 14.4077 25.4142 14.4077 25Z"
          fill="#3C464D"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.7217 25C33.7217 24.5858 34.0575 24.25 34.4717 24.25L34.7503 24.25C35.1645 24.25 35.5003 24.5858 35.5003 25C35.5003 25.4142 35.1645 25.75 34.7503 25.75L34.4717 25.75C34.0575 25.75 33.7217 25.4142 33.7217 25Z"
          fill="#3C464D"
        />
      </Svg>
    :
    <></>
  )
}

export default TriggerIcon

const styles = StyleSheet.create({

})