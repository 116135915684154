import type { IChatMessageReply, IDiscussion, IFile } from '@rocket-mono/libs'
import { getRealColor } from '@rocket-mono/libs'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TextInput, View, ViewStyle } from 'react-native'
import Input from '../Input/index.web'
import type {
  TextInputCompositionEvent,
  TextInputFocusEvent,
  TextInputFormEvent,
  TextInputKeyboardEvent,
} from '../RichTextInput/types.web'
import { MentionTargetPicker } from '../SendTargetPicker'
import type { TopTabItem } from '../TopTab'
import TopTab from '../TopTab'
import { useMention } from '../hooks/useMention.web'
import { clearTextInput, getTextFromFormEvent, getTextFromTextInput } from '../utils/input.web'
import { getCurrentCaretPosition, isDirectionalKey, restoreCaretPosition } from '../utils/keyboard.web'
import DiscussionBorderBoard from './discussionBorderBoard'
import { DiscussionContext } from './discussionContext'
import DiscussionItem from './discussionItem'
import DiscussionList from './discussionList'
import { DiscussionProvider } from './discussionProvider'
import DiscussionTab from './discussionTab'
import DiscusstionInvisible from './discusstionInvisible'
import { ListMoreButtonType as DiscussionListMoreButtonType } from './types'
import { useDiscussionUser } from './useDiscussionUser'

interface Props {
  isAnonymous: boolean
  onPressAnonymous: () => void
  mentionDirection?: 'upside' | 'downside'
  discussionCount?: number
  historyCount?: number
  initState?: string
  isArchive?: boolean
  onState?: (state: string) => void
  list: IDiscussion[]
  onPopupState: (index: number, state: string, item?: IDiscussion) => void
  onPopupReplyState?: (parentIndex: number, index: number, state: string, item?: IChatMessageReply) => void
  itemContainerStyle: ViewStyle
  itemReplyContainerStyle?: ViewStyle
  onText?: (text: string) => void
  onSubmit?: (text: string) => void
  onFileupload?: () => void
  onSend?: () => void
  onFileShare?: (item?: IFile) => void
  onFileExport?: (item?: IFile) => void
  onFileDownload?: (item?: IFile) => void
  onFilePreview?: (item?: IFile) => void
  selectedIndex?: number
  replySelectedIndex?: string
  onLinkPress?: (url: string, text?: string | undefined) => void
  onScroll?: () => void
  onPressMoreButton?: (payload: DiscussionListMoreButtonType) => void
}

const DiscussionBoard = React.forwardRef(
  (
    {
      isAnonymous,
      onPressAnonymous,
      mentionDirection = 'downside',
      discussionCount,
      historyCount,
      initState,
      onState,
      isArchive = false,
      list,
      onPopupState,
      onPopupReplyState,
      itemContainerStyle,
      itemReplyContainerStyle,
      onSubmit,
      onFileupload,
      onSend,
      onText,
      onFileShare,
      onFileExport,
      onFileDownload,
      onFilePreview,
      selectedIndex,
      replySelectedIndex,
      onLinkPress,
      onScroll,
      onPressMoreButton,
    }: Props,
    ref,
  ): JSX.Element => {
    const { t } = useTranslation()
    const [state, setState] = React.useState(initState ?? 'discussion')
    const inputRef = React.useRef<HTMLDivElement>(null)

    React.useImperativeHandle(ref, () => {
      return {
        clear: () => {
          if (inputRef.current) inputRef.current.innerText = ''
          onText && onText('')
          clearTextInput(inputRef)
        },
      }
    })

    const onSubmitHandler = React.useCallback(() => {
      console.log('onSubmitHandler')
      if (inputRef !== null) {
        const text = getTextFromTextInput(inputRef)
        if (onSubmit && text) {
          if (inputRef.current) inputRef.current.innerText = ''
          onSubmit(text)
          clearTextInput(inputRef)
        }
      }
    }, [onSubmit, inputRef])

    const onSendHandler = React.useCallback(() => {
      console.log('onSendHandler')
      if (onSend) {
        if (inputRef.current) inputRef.current.innerText = ''
        onSend()
      }
    }, [onSend, inputRef])

    const TabList: TopTabItem[] = [
      {
        name: t('discussion.disc'),
        icon: 'forum-o',
        ea: String(discussionCount) ?? '',
        code: 'discussion',
        isShow: true,
      },
      {
        name: t('discussion.hist'),
        icon: 'timer-sand-o',
        ea: String(historyCount) ?? '',
        code: 'history',
        isShow: false,
      },
    ]

    const dynamicStyles = StyleSheet.create({
      flexIfDiscussion: { display: state === 'discussion' ? 'flex' : 'none' },
      flexIfHistory: { display: state === 'history' ? 'flex' : 'none' },
    })

    // FIXME: src/components/ChatInput/Input/index.web.tsx 로부터 복사된 코드
    const {
      startMention,
      closeMention,
      finishMention,
      mentionListOpen,
      forwardKeyEvent,
      forwardFormEvent,
      forwardCompositionEvent,
      forwardBlurEvent,
      mentionList,
      state: mentionState,
      matched,
      startMatching,
    } = useMention()

    const onKeyPress = React.useCallback(
      (e: React.KeyboardEvent<TextInput>) => {
        const { keyCode, altKey, ctrlKey, shiftKey, metaKey } = e.nativeEvent
        if (e.key == '@' && !mentionListOpen) {
          if (inputRef) startMention('', mentionList.map(({ name }) => name) ?? [], inputRef)
        }

        if (keyCode === 13 && !altKey && !ctrlKey && !shiftKey && !metaKey) {
          if (mentionListOpen) {
            closeMention()
          } else {
            onSubmitHandler()
          }
          e.preventDefault()
        }
      },
      [mentionListOpen, startMention, mentionList, closeMention, onSubmitHandler],
    )

    React.useEffect(() => {
      if (!inputRef.current) return

      if (mentionState === 'start') {
        startMatching()
      } else if (mentionState === 'matched') {
        const insertion = getCurrentCaretPosition(inputRef.current)

        const htmlDivCurrent = inputRef.current as unknown as HTMLDivElement
        htmlDivCurrent.innerText =
          htmlDivCurrent.innerText.substring(0, insertion) +
          matched[0].name +
          '\u00A0' +
          htmlDivCurrent.innerText.substring(insertion)

        restoreCaretPosition(inputRef.current, insertion + matched[0].name.length + 1)

        // insert matched[0] to text at lastCaretPosition
        // if (onChangeText) onChangeText(ref.current.innerText)

        closeMention()
      } else if (mentionState === 'finish') {
        // insert matched[0] to text at lastCaretPosition
        finishMention()
      }
    }, [closeMention, finishMention, matched, mentionState, startMatching])

    const blockingHandler = React.useCallback(() => {
      console.log('blockingHandler', isAnonymous)
      onPressAnonymous && onPressAnonymous()
    }, [isAnonymous])

    return (
      <View>
        <TopTab
          list={TabList}
          initCode="discussion"
          onCode={(state) => {
            setState(state)
            onState && onState(state)
          }}
        />
        <View style={dynamicStyles.flexIfDiscussion}>
          <View style={styles.inputContainer}>
            {mentionDirection === 'upside' && <MentionTargetPicker direction={'upside'} />}

            <Input
              multiline
              inputRef={inputRef}
              placeholder={isArchive ? t('discussion.archive') : t('discussion.enter')}
              editable={!isArchive}
              onEnterSubmit={onSubmitHandler}
              height={96}
              blurOnSubmit={false}
              rightIcon={
                !isAnonymous && onFileupload
                  ? {
                      name: 'cloud-upload-o',
                      color: 'mono.paleBlack',
                      onPress: onFileupload,
                    }
                  : undefined
              }
              rightIconSize={16}
              rightIconStyle={styles.rightIcon}
              rightIcon2={
                !isAnonymous && onSend
                  ? {
                      name: 'send',
                      color: 'mono.paleBlack',
                      onPress: onSendHandler,
                    }
                  : undefined
              }
              rightIconSize2={18}
              rightIconStyle2={styles.rightIcon2}
              onFocus={() => {
                if (isAnonymous) {
                  onPressAnonymous()
                  inputRef.current?.blur()
                }
              }}
              onKeyPress={(e) => {
                const keyboardEvent = e as React.KeyboardEvent<TextInput>
                onKeyPress(keyboardEvent)
              }}
              onKeyDown={(e) => {
                const keyboardEvent = e as React.KeyboardEvent<TextInput>
                if (mentionListOpen && isDirectionalKey(keyboardEvent.code)) {
                  keyboardEvent.preventDefault()
                }
              }}
              onKeyUp={(e) => {
                const keyboardEvent = e as TextInputKeyboardEvent
                if (mentionListOpen) {
                  forwardKeyEvent(keyboardEvent)
                }
              }}
              onBlur={(e) => {
                const focusEvent = e as TextInputFocusEvent
                if (mentionListOpen) {
                  forwardBlurEvent(focusEvent)
                }
              }}
              onInput={(e) => {
                const formEvent = e as TextInputFormEvent
                if (mentionListOpen) {
                  forwardFormEvent(formEvent)
                } else {
                  if (onText) {
                    onText(getTextFromFormEvent(formEvent))
                  }
                }
              }}
              onCompositionUpdate={(e) => {
                const compositionEvent = e as TextInputCompositionEvent
                // console.debug('onCompositionUpdate', compositionEvent)
                // CJK IME인 경우 IME에 의해 문자를 입력중인 경우, 키 입력에 대해 onCompositionUpdate 이벤트가 발생함
                if (mentionListOpen) {
                  // NOTE: composition 시에는 키 입력을 모두 포워딩한다
                  forwardCompositionEvent(compositionEvent, true)
                }
              }}
              onCompositionEnd={(e) => {
                const compositionEvent = e as TextInputCompositionEvent
                // console.debug('onCompositionEnd', compositionEvent)
                if (mentionListOpen) {
                  // NOTE: composition 시에는 키 입력을 모두 포워딩한다
                  forwardCompositionEvent(compositionEvent, false)
                }
              }}
            />
          </View>
          {mentionDirection === 'downside' && (
            <MentionTargetPicker
              containerStyle={{
                marginTop: -15,
                paddingLeft: 12,
                paddingRight: 12,
              }}
              direction={'downside'}
            />
          )}

          {isAnonymous ? (
            <DiscusstionInvisible discussionCount={discussionCount ?? 0} onPress={blockingHandler} />
          ) : (
            <DiscussionList
              list={list}
              onPopupState={({ index, state, item }) => onPopupState(index, state, item)}
              onPopupReplyState={({ parentIndex, index, state, item }) =>
                onPopupReplyState && onPopupReplyState(parentIndex, index, state, item)
              }
              itemContainerStyle={itemContainerStyle}
              itemReplyContainerStyle={itemReplyContainerStyle}
              onFileShare={onFileShare}
              onFileExport={onFileExport}
              onFileDownload={onFileDownload}
              onFilePreview={onFilePreview}
              selectedIndex={selectedIndex}
              replySelectedIndex={replySelectedIndex}
              onLinkPress={onLinkPress}
              onScroll={onScroll}
              onPressMoreButton={onPressMoreButton}
            />
          )}
        </View>
        <View style={dynamicStyles.flexIfHistory}>
          <Text>히스토리</Text>
        </View>
      </View>
    )
  },
)

const styles = StyleSheet.create({
  inputContainer: {
    marginVertical: 20,
    marginHorizontal: 12,
  },
  rightIcon: {
    height: 26,
    width: 26,
    backgroundColor: getRealColor('mono.pale'),
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 10,
    paddingBottom: 5,
    marginBottom: 8,
  },
  rightIcon2: {
    paddingBottom: 2,
    marginBottom: 8,
  },
})

export {
  DiscussionBoard,
  DiscussionBorderBoard,
  DiscussionContext,
  DiscussionItem,
  DiscussionList,
  DiscussionProvider,
  DiscussionTab,
  useDiscussionUser,
}

export type { DiscussionListMoreButtonType }
