import * as React from 'react'

import { LayoutPayload } from '@rocket-mono/libs'
import { useToast } from '@rui/atoms'
import { ClickOutside } from '@rui/foundations'
import { Platform } from 'react-native'
import DropdownListGroup from '../../DropdownListGroup'
import WorkCardTodoFileModalView from '../../WorkCardTodoFileModalView'
import { WorkCardTodo } from '../../providers'
import { uuidV4 } from '../../utils'
import type { DropDown, ShowModal } from '../types'

const EXT_MAP = {
  doc: ['txt', 'doc', 'hwp'],
  ppt: ['ppt', 'pptx'],
  xls: ['xls', 'xlsx'],
  zip: ['zip', 'rar', 'alz', '7z'],
  pdf: ['pdf'],
}

const fileType = (mimeType?: string, extension?: string) => {
  if (!mimeType) return ''
  if (!extension) return ''
  if (mimeType.startsWith('image')) {
    return 'preview'
  } else if (EXT_MAP.doc.includes(extension)) {
    return 'doc'
  } else if (EXT_MAP.ppt.includes(extension)) {
    return 'ppt'
  } else if (EXT_MAP.xls.includes(extension)) {
    return 'xls'
  } else if (mimeType.startsWith('video')) {
    return 'video'
  } else if (EXT_MAP.zip.includes(extension)) {
    return 'zip'
  } else if (EXT_MAP.pdf.includes(extension)) {
    return 'pdf'
  } else {
    return ''
  }
}

const addFile = (file: File, currentUserId: number, boardRoomId?: string, projectId?: number) => {
  const extension = file.name.split('.').pop()
  return {
    key: uuidV4(),
    originalName: file.name,
    extension: extension || '',
    mimeType: file.type,
    byteSize: String(file.size),
    createdAt: new Date(),
    localPath: URL.createObjectURL(file),
    localFile: file,
    userId: currentUserId,
    projectId,
    boardRoomId,
  }
}

type LocalPathType = { id: string; url: string }

const getRemotePath = (paths: LocalPathType[], id: string) => {
  const index = paths.findIndex((path) => path.id === id)
  if (index > -1) {
    return paths[index].url
  } else {
    return ''
  }
}

const initFileOption = (todo: WorkCardTodo) => {
  return {
    ...todo,
    fileOption: {
      isUploadable: todo.fileOption?.isUploadable ? todo.fileOption?.isUploadable : true,
      accessPermission: todo.fileOption?.accessPermission ? todo.fileOption?.accessPermission : 'EVERYONE',
    },
  }
}

const CardTodoFileModal = ({
  astro,
  layoutPayload,
  type,
  todo: modalTodo,
  i18n,
  projectId,
  boardRoomId,
  currentUserId,
  onClose,
  onSave,
}: ShowModal) => {
  const [todo, setTodo] = React.useState<WorkCardTodo>(initFileOption(modalTodo))
  const [fileEtcDropdown, setFileEtcDropdown] = React.useState<DropDown>()
  // const [fileList, setFileList] = React.useState<FileItemType[]>(
  //   initialFileList(todo.files ?? [], astro),
  // )
  const [localPathList, setLocalPathList] = React.useState<LocalPathType[]>([])

  const { show: showToastMessage } = useToast()

  React.useEffect(() => {
    if (!todo.files) return
    for (const file of todo.files) {
      if (file.id) {
        void astro.downloadFile(file.id).then(async (response) => {
          const blob = await response.blob()
          setLocalPathList((prev) => [...prev, { id: file.id || '', url: URL.createObjectURL(blob) }])
        })
      }
    }
  }, [todo.files, astro])

  const previewFileList = React.useMemo(() => {
    if (!todo.files) return []
    return todo.files.map((item) => ({
      small: true,
      access: true,
      download: false,
      border: true,
      id: item.id,
      fileUrl: item.id ? getRemotePath(localPathList, item.id) : item.localPath || '',
      fileType: fileType(item.mimeType, item.extension),
      fileSize: Number(item.byteSize),
      fileName: item.originalName || '',
      fileSource: 'MISSION' as const,
      date: new Date(item.createdAt || '') || new Date(),
      uploader: '',
      onPressItem: () => {
        console.log('onPressItem')
      },
      onPressEtc: (payload: LayoutPayload) => {
        setFileEtcDropdown({
          layoutPayload: payload,
          selectedKeyList: [item.key || ''],
          dropdownList: [
            {
              group: [
                {
                  id: 1,
                  title: i18n.t('cardtodoedit.dropdown.delete'),
                  code: 'delete',
                },
              ],
            },
          ],
        })
      },
    }))
  }, [todo.files, localPathList, i18n])
  const isFileDownloadAuth = React.useMemo(() => {
    if (!todo.fileOption || !todo.fileOption.accessPermission) return false
    return todo.fileOption.accessPermission.includes('EVERYONE')
  }, [todo])
  return (
    <ClickOutside
      onClickOutside={() => {
        if (!fileEtcDropdown) {
          onClose()
          if (Platform.OS === 'web') {
            onSave &&
              onSave({
                ...todo,
                isFileCheck: !!todo.files,
              })
          }
        }
      }}
    >
      <>
        <WorkCardTodoFileModalView
          layoutPayload={layoutPayload}
          visible={type === 'file'}
          onClose={() => {
            onClose()
            if (Platform.OS === 'web') {
              onSave &&
                onSave({
                  ...todo,
                  isFileCheck: !!todo.files,
                })
            }
          }}
          onSave={() => {
            onSave &&
              onSave({
                ...todo,
                isFileCheck: !!todo.files,
              })
          }}
          fileList={previewFileList}
          isFileUpload={todo.fileOption?.isUploadable ?? false}
          isFileDownloadAuth={isFileDownloadAuth}
          onIsFileUpload={(isUploadable) => {
            setTodo({
              ...todo,
              fileOption: {
                ...todo.fileOption,
                isUploadable,
              },
              isFileCheck: true,
            })
          }}
          onIsFileDownloadAuth={(accessPermission) => {
            setTodo({
              ...todo,
              fileOption: {
                ...todo.fileOption,
                accessPermission: accessPermission ? 'EVERYONE' : 'UPLOADER_OR_AUTHORITY',
              },
            })
          }}
          onLoadFile={(files) => {
            console.log('onLoadFile', files)
            const isFileSizeOver = Array.from(files).some((file) => file.size > 1024 * 1024 * 50)
            for (let i = 0; i < files.length; i++) {
              const $file = files.item(i)
              if ($file) {
                if ($file.size < 1024 * 1024 * 50) {
                  setTodo((prev) => {
                    return {
                      ...prev,
                      files: [addFile($file, currentUserId, boardRoomId, projectId), ...(prev.files ?? [])],
                      isFileCheck: true,
                    }
                  })
                }
              }
            }
            if (isFileSizeOver) {
              showToastMessage({
                type: 'Danger',
                title: i18n.t('toast.file.size.over'),
                position: 'TOP_CENTER',
              })
            }
          }}
          onDropFile={(files) => {
            console.log('onDropFile', files)
            const isFileSizeOver = Array.from(files).some((file) => file.size > 1024 * 1024 * 50)
            for (let i = 0; i < files.length; i++) {
              const $file = files.item(i)
              if ($file) {
                if ($file.size < 1024 * 1024 * 50) {
                  setTodo((prev) => {
                    return {
                      ...prev,
                      files: [addFile($file, currentUserId, boardRoomId, projectId), ...(prev.files ?? [])],
                      isFileCheck: true,
                    }
                  })
                }
              }
            }
            if (isFileSizeOver) {
              showToastMessage({
                type: 'Danger',
                title: i18n.t('toast.file.size.over'),
                position: 'TOP_CENTER',
              })
            }
          }}
          onPasteFile={(files) => {
            console.log('onPasteFile', files)
            for (let i = 0; i < files.length; i++) {
              const $file = files.item(i)
              if ($file) {
                setTodo((prev) => {
                  return {
                    ...prev,
                    files: [addFile($file, currentUserId, boardRoomId, projectId), ...(prev.files ?? [])],
                    isFileCheck: true,
                  }
                })
              }
            }
          }}
        />
        {fileEtcDropdown ? (
          <DropdownListGroup
            code={''}
            isOpen={true}
            layoutPayload={fileEtcDropdown.layoutPayload}
            dropdownList={fileEtcDropdown.dropdownList}
            onCode={(code) => {
              if (code === 'delete') {
                console.log('삭제')
                if (todo.files) {
                  const [key] = fileEtcDropdown.selectedKeyList
                  const item = todo.files.find((item) => item.key === key)
                  if (item && item.id) {
                    void astro.deleteFile(item.id)
                  }
                }
                setTodo((prev) => ({
                  ...prev,
                  files: prev.files
                    ? prev.files.filter((item) => !fileEtcDropdown.selectedKeyList.includes(item.key || ''))
                    : undefined,
                }))
                setFileEtcDropdown(undefined)
              }
            }}
            onDismiss={() => {
              setFileEtcDropdown(undefined)
            }}
          />
        ) : (
          <></>
        )}
      </>
    </ClickOutside>
  )
}

export default CardTodoFileModal
