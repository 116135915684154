import * as React from 'react'

import { Pressable, Text, StyleSheet, View, ViewStyle, StyleProp } from 'react-native'
import { COLOR, FONT } from '@rocket-mono/libs'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FAIcon } from '@rocket-atoms/icon'
export interface ButtonProps {
  type?: 'PRIMARY' | 'SECONDARY' | 'SECONDARYWHITE' | 'NEGATIVE' | 'BOTTOMBAR' | 'NOTSELECT'
  variant?: 'FILL' | 'LINE'
  size?: 'sm' | 'md' | 'lg'
  wide?: boolean
  disabled?: boolean
  beforeIcon?: IconProp
  afterIcon?: IconProp
  text?: string | JSX.Element
  onPress?: () => void
  code?: string
  style?: StyleProp<ViewStyle>
  children?: string | JSX.Element
}

const Button: React.FC<ButtonProps> = ({
  type = 'PRIMARY',
  variant,
  size = 'md',
  wide,
  disabled,
  beforeIcon,
  afterIcon,
  text,
  onPress,
  style,
  children,
}: ButtonProps) => {
  return (
    <Pressable
      style={[
        styles.commonStyle,
        wide && { width: '100%' },
        type === 'SECONDARY' && styles.secondaryStyle,
        type === 'SECONDARY' && variant === 'FILL' && styles.secondaryFillStyle,
        type === 'SECONDARYWHITE' && styles.secondaryWhiteStyle,
        type === 'NEGATIVE' && styles.negativeStyle,
        type === 'BOTTOMBAR' && styles.bottomBardStyle,
        type === 'NOTSELECT' && styles.notSelectStyle,
        size === 'sm' && styles.smallStyle,
        size === 'md' && styles.mediumStyle,
        size === 'lg' && styles.largeStyle,
        disabled && styles.disableStyle,
        style,
      ]}
      onPress={onPress}
      disabled={disabled}
    >
      {beforeIcon && (
        <View style={styles.icon}>
          <FAIcon iconName={beforeIcon} color={type === 'PRIMARY' ? 'mono.white' : 'gray.g600'} />
        </View>
      )}
      {typeof text === 'string' ? (
        <Text
          style={[
            styles.commonTextStyle,
            size === 'sm' && styles.smallTextStyle,
            type === 'SECONDARY' || type === 'SECONDARYWHITE' ? { color: COLOR.gray.g800 } : {},
            type === 'NOTSELECT' && { color: COLOR.gray.g400 },
            disabled && styles.diabledTextStyle,
          ]}
        >
          {text}
        </Text>
      ) : (
        <>{text}</>
      )}
      {typeof children === 'string' ? (
        <Text
          style={[
            styles.commonTextStyle,
            size === 'sm' && styles.smallTextStyle,
            type === 'SECONDARY' || type === 'SECONDARYWHITE' ? { color: COLOR.gray.g800 } : {},
            type === 'NOTSELECT' && { color: COLOR.gray.g400 },
            disabled && styles.diabledTextStyle,
          ]}
        >
          {children}
        </Text>
      ) : (
        <>{children}</>
      )}
      {afterIcon && (
        <View style={styles.icon}>
          <FAIcon iconName={afterIcon} color={type === 'PRIMARY' ? 'mono.white' : 'gray.g600'} />
        </View>
      )}
    </Pressable>
  )
}

export default Button

const styles = StyleSheet.create({
  commonStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: COLOR.primary.blue500,
    backgroundColor: COLOR.primary.blue500,
  },
  commonTextStyle: {
    ...FONT.txtSm,
    color: COLOR.mono.white,
    marginHorizontal: 4,
  },
  smallTextStyle: {
    ...FONT.txtXs,
  },
  secondaryStyle: {
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    backgroundColor: COLOR.mono.white,
  },
  secondaryFillStyle: {
    borderColor: COLOR.gray.g100,
    backgroundColor: COLOR.gray.g100,
  },
  secondaryWhiteStyle: {
    borderColor: COLOR.mono.white,
    backgroundColor: COLOR.mono.white,
  },
  notSelectStyle: {
    borderColor: COLOR.gray.g200,
    backgroundColor: COLOR.gray.g100,
  },
  negativeStyle: {
    backgroundColor: COLOR.main.red,
    borderColor: COLOR.main.red,
  },
  bottomBardStyle: {},
  smallStyle: {
    minWidth: 44,
    padding: 4,
  },
  mediumStyle: {
    minWidth: 56,
  },
  largeStyle: {},
  disableStyle: {
    borderColor: COLOR.transparent.base,
    backgroundColor: COLOR.gray.g200,
  },
  diabledTextStyle: {
    color: COLOR.gray.g500,
  },
  icon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 18,
    height: 18,
  },
})
