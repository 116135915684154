import { COLOR } from '@rocket-mono/libs'
import * as React from 'react'
import { StyleSheet, View } from 'react-native'

const DividingLine: React.FC = () => {
  return <View style={styles.line}></View>
}

export default DividingLine

const styles = StyleSheet.create({
  line: {
    borderBottomColor: COLOR.gray.g150,
    borderBottomWidth: 1,
  },
})
