import * as React from 'react'
import { View } from 'react-native'
import { Defs, Stop, LinearGradient, Rect, Svg, G, Ellipse } from 'react-native-svg'

const CardLine: React.FC = () => {
  return (
    <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', height: 8}}>
      <Svg
        width={268}
        height={14}
        viewBox="0 0 268 14"
        fill="none"
        preserveAspectRatio="none"
      >
        <G filter="url(#filter0_f_1326_42608)">
          <Ellipse cx={131} cy={7} rx={111} ry={1} fill="#47C8FF" />
        </G>
        <Rect y={6} width={268} height={2} fill="url(#paint0_linear_1326_42608)" />
        <Defs>
          <LinearGradient
            id="paint0_linear_1326_42608"
            x1={5.88913e-7}
            y1={6.99989}
            x2={268}
            y2={6.99997}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#EDEFF0" />
            <Stop offset={0.38} stopColor="#007FFF" />
            <Stop offset={0.635} stopColor="#007FFF" />
            <Stop offset={1} stopColor="#007FFF" stopOpacity={0} />
            <Stop offset={1} stopColor="#EDEFF0" />
          </LinearGradient>
        </Defs>
      </Svg>
    </View>
  )
}

export default CardLine
