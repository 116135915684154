import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet } from 'react-native'

import { COLOR, useMobileView } from '@rui/foundations'
import { useCurrentUser, useWork } from '@rocket-mono/providers'
import { FAIcon, NoContentsIcon, Text } from '@rocket/atoms'

import { faSwatchbook } from '@fortawesome/free-solid-svg-icons'
import { Button, View } from '@rocket-mono/foundations'
import SearchInput from '../../components/SearchInput'
import SearchSuggestions from '../../components/SearchSuggestions'
import SearchSuggestionsItem from '../../components/SearchSuggestionsItem'
import TemplateGuide from '../../components/TemplateGuide'

interface Props {
  onPressProject?: (id: string) => void
  onPressCreateWork?: (type: string) => void
}
const WorkspaceTopView: React.FC<Props> = ({ onPressProject, onPressCreateWork }) => {
  const { t } = useTranslation()
  const skeleton = false
  const mobileWebView = useMobileView(768) //브라우저 웹:모바일 뷰 크기
  // const mobileView = width < 500 //브라우저 모바일 뷰 크기

  const [isSuggestions, setIsSuggestions] = useState(false) //검색창 모달
  const [searchKeyword, setSearchKeyword] = useState('') //검색창 키워드 state
  // const [activated, setActivated] = useState(false) //진행중-아카이브 전환

  const { currentUser } = useCurrentUser()
  const { templateList, projectList, isTemplateGuide, openTemplateGuide, closeTemplateGuide } = useWork()

  const suggestionsItem = useMemo(() => {
    if (projectList === undefined || searchKeyword === '') return undefined
    return projectList
      .filter(({ title }) => title.indexOf(searchKeyword) >= 0)
      .map((o) => {
        const type = o.type.code
        const workName = o.title
        const workspaceName = t('search.suggestions.byusername', { userName: currentUser.userName })
        const onPress = () => {
          onPressProject && onPressProject(o.id)
        }
        return { type, workName, workspaceName, onPress }
      })
  }, [searchKeyword, projectList, currentUser, t])

  return (
    <View>
      <View style={styles.topArea}>
        <View style={styles.searchArea}>
          <SearchInput width={mobileWebView ? '60%' : 320} onPress={() => setIsSuggestions(true)} />
          {!skeleton && (
            <View style={styles.switchArea}>
              {!isTemplateGuide && (
                <Button
                  onPress={openTemplateGuide}
                  backgroundColor="transparent.base"
                  hoverStyle={{ backgroundColor: COLOR.opacity.blk50 }}
                  style={{ flexDirection: 'row', paddingHorizontal: 12, marginRight: 8 }}
                >
                  <FAIcon iconName={faSwatchbook} color={'gray.g400'} />
                  <Text style={{ marginLeft: 4, color: COLOR.gray.g400 }}>{t('workspace.workmodel')}</Text>
                </Button>
              )}
              {/* <WorkBoardSwitch
                activated={activated}
                onChange={() => setActivated(!activated)}
                leftMode="board"
                leftTooltip={t('screen.board.tooltipswitch.progress')}
                rightMode="archive"
                rightTooltip={t('screen.board.tooltipswitch.archive')}
                theme={'DARK'}
              /> */}
            </View>
          )}
          {isSuggestions && (
            <SearchSuggestions
              isShow={isSuggestions}
              setIsShow={() => setIsSuggestions(false)}
              containerMaxWidth={70}
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            >
              <ScrollView style={styles.searchResultArea}>
                {suggestionsItem === undefined ? (
                  <></>
                ) : suggestionsItem.length > 0 ? (
                  suggestionsItem.map((item, idx) => (
                    <SearchSuggestionsItem item={item} key={`suggestions-result${idx}`} />
                  ))
                ) : (
                  <NoContentsIcon
                    type={'search'}
                    textSize={'medium'}
                    text={''}
                    subText={t('nocontentsicon.search.text')}
                    iconWidth={115}
                  />
                )}
              </ScrollView>
            </SearchSuggestions>
          )}
        </View>
      </View>

      {isTemplateGuide && templateList && (
        <View style={styles.guideArea}>
          <TemplateGuide
            skeleton={skeleton}
            mediaUrl={''}
            templateList={templateList.map((o) => {
              const { templateType: type, templateLabel: label, templateTitle: title, templateUrl: imgUrl } = o
              const onPress = () => {
                {
                  if (type !== 'MEDIA') {
                    onPressCreateWork && onPressCreateWork(type)
                  }
                }
              }
              return {
                type,
                label,
                title,
                imgUrl,
                onPress,
              }
            })}
            onPressClose={closeTemplateGuide}
            size={mobileWebView ? 'sm' : 'md'}
          />
        </View>
      )}
    </View>
  )
}

export default WorkspaceTopView

const styles = StyleSheet.create({
  topArea: {
    marginBottom: 8,
    zIndex: 99,
  },
  searchArea: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 12,
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: COLOR.darkmode.warm500,
  },
  switchArea: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchResultArea: {
    maxHeight: 230,
  },

  guideArea: {
    marginBottom: 16,
  },
  shortcutsArea: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  //   latestWork: {
  //     marginRight: 12,
  //     marginBottom: 8,
  //     zIndex: 9,
  //   },
  //   sortArea: {
  //     flexDirection: 'row',
  //     justifyContent: 'space-between',
  //     alignItems: 'center',
  //     height: 32,
  //     marginBottom: 12,
  //   },

  //   workspaceContainer: {
  //     backgroundColor: COLOR.darkmode.cool900,
  //     borderRadius: 8,
  //     paddingHorizontal: 20,
  //     paddingTop: 16,
  //     paddingBottom: 20,
  //   },
  //   workspaceTab: {
  //     marginBottom: 8,
  //   },
  //   workGroupArea: {
  //     flexDirection: 'row',
  //     flexWrap: 'wrap',
  //     marginLeft: -12,
  //   },
  //   workArea: {
  //     flexDirection: 'row',
  //     flexWrap: 'wrap',
  //     marginTop: 4,
  //   },
  //   workAreaMobile: {
  //     flexDirection: 'column-reverse',
  //   },
})
