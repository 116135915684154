import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import { IColors } from '@rocket-mono/libs'
import React from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import FAIcon from '../FAIcon'

interface Props {
  iconName: IconProp
  iconSize: SizeProp | number
  iconColor: IColors
  onPress?: () => void
}
const IconButton: React.FC<Props> = ({ iconName, iconSize, iconColor, onPress }) => {
  const [isHover, setIsHover] = React.useState(false)

  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      onPress={onPress}
      style={[styles.wrap]}
    >
      <View style={isHover && { opacity: 0.75 }}>
        {typeof iconSize === 'number' ? (
          <FAIcon iconName={iconName} nativeSize={iconSize} color={iconColor} />
        ) : (
          <FAIcon iconName={iconName} size={iconSize} color={iconColor} />
        )}
      </View>
    </Pressable>
  )
}

export default IconButton

const styles = StyleSheet.create({
  wrap: {
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
