import * as React from 'react'
import { StyleSheet, View, Pressable } from 'react-native'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { COLOR } from '@rui/foundations'
import { FAIcon } from '@rocket/atoms'

const GuideCloseButton = ({ onPress }: { onPress: () => void }) => {
  const [isHover, setIsHover] = React.useState(false)

  return (
    <Pressable
      onPress={onPress}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      style={[styles.container, isHover && { backgroundColor: COLOR.darkmode.cool300 }]}
    >
      <View style={styles.iconWrap}>
        <FAIcon
          iconName={faClose}
          // size={10}
          color={'darkmode.cool800'}
        />
      </View>
    </Pressable>
  )
}

export default GuideCloseButton

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 14,
    height: 14,
    borderRadius: 2,
    backgroundColor: COLOR.darkmode.warm300,
  },
  iconWrap: {
    width: 8,
  },
})
