import React from 'react'

import Editor from './Editor'
import Viewer from './Viewer'
import { RefProps } from './types'
import { TriggerType } from '@rui/rich-text-field'

export interface TextAreaProps {
  autofocus?: boolean
  readonly?: boolean
  height?: number
  language?: string
  initialValue: string
  onChangeContent?: (content: string) => void
  placeholder?: string
  onLoadFile?: (file: Blob | File, text?: string) => Promise<string>
  triggerList?: TriggerType[]
}

export const TextArea = React.forwardRef<RefProps, TextAreaProps>(({ readonly = false, ...props }, ref) => {
  return readonly ? <Viewer {...props} /> : <Editor ref={ref} {...props} />
})

export default TextArea
