import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { Image } from '@rocket-mono/foundations'

import aiIcon from './assets/ai.gif'
import aiOffIcon from './assets/ai_off.png'

interface Props {
  active: boolean
}

const LayoutTopAIIcon: React.FC<Props> = ({ active }) => {
  return (
    <View style={[styles.container]}>
      {active ? (
        <Image size={{ width: 34, height: 34 }} source={aiIcon} />
      ) : (
        <Image size={{ width: 34, height: 34 }} source={aiOffIcon} />
      )}
    </View>
  )
}

export default LayoutTopAIIcon

const styles = StyleSheet.create({
  container: {
    alignSelf: 'flex-start',
  },
})
