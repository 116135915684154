import { Text } from '@rocket-atoms/text'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import { COLOR } from '@rocket-mono/libs'
import { DatePicker } from './picker'

export type RangeDateType = {
  startDate?: Date
  endDate?: Date
}

export interface DateRangePickerProps {
  height?: number
  rangeDate: RangeDateType
  onChangeDate?: (rangeDate: RangeDateType) => void
}

export const DateRangePicker = ({ height, rangeDate, onChangeDate }: DateRangePickerProps) => {
  const { t } = useTranslation()

  const setStartDate = React.useCallback(
    (startDate?: Date) => {
      console.log('startDate', startDate)
      onChangeDate && onChangeDate({ ...rangeDate, startDate })
    },
    [rangeDate, onChangeDate],
  )
  const setEndDate = React.useCallback(
    (endDate?: Date) => {
      console.log('endDate', endDate)
      onChangeDate && onChangeDate({ ...rangeDate, endDate })
    },
    [rangeDate, onChangeDate],
  )

  const startDateReset = React.useCallback(() => {
    setStartDate(undefined)
  }, [setStartDate])
  const endDateReset = React.useCallback(() => {
    setEndDate(undefined)
  }, [setEndDate])

  return (
    <View style={[styles.container, !!height && { height }]}>
      <View style={{ flex: 1 }}>
        <DatePicker
          height={height}
          date={rangeDate.startDate}
          maxDate={rangeDate.endDate}
          placeholder={t('workmodal.basic.startdate')}
          onChangeDate={setStartDate}
          onResetDate={startDateReset}
        />
      </View>
      <View style={{ width: 10 }}>
        <Text fontName="txtMd" fontColor="mono.black">
          ~
        </Text>
      </View>
      <View style={{ flex: 1 }}>
        <DatePicker
          height={height}
          date={rangeDate.endDate}
          minDate={rangeDate.startDate}
          placeholder={t('workmodal.basic.duedate')}
          onChangeDate={setEndDate}
          onResetDate={endDateReset}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    height: 44,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    borderRadius: 8,
  },
})
