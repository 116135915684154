import { faCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { DropdownButton, DropdownItemProps, DropdownList } from '@rocket-atoms/dropdown'
import { SectionAccordionType } from '@rocket-molecules/popselector'
import { COLOR, ClickOutside, FONT } from '@rocket-mono/libs'
import { Button, FAIcon } from '@rocket/atoms'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, Text, View } from 'react-native'
import { useRule } from '../../RuleScreen/hooks'
import { ActionCopyOption, ActionType } from '../../RuleScreen/types'
import RuleCopySelectView from '../RuleCopySelectView'
import RuleStepIcon from '../RuleStep/RuleStepIcon'
import RuleWorkSelectView from '../RuleWorkSelectView'
import { commonStyles } from '../style'

interface Props {
  isMobile: boolean
  onPressCancel: () => void
  onPressCreate: () => void
}

const ActionView: React.FC<Props> = ({ isMobile, onPressCancel, onPressCreate }) => {
  const { t } = useTranslation()
  const {
    viewTypeCodeList,
    errorBottomText,
    projectList,
    selectedBoard,
    selectedRule,
    selectedAction,
    unArchiveBoardListOfSelectedWorkByAction,
    archiveBoardListOfSelectedWorkByAction,
    isActionViewRender,
    getIcon,
    onSelectedAction,
    onActionBoardBySelectedWork,
  } = useRule()
  const [activated, setActivated] = React.useState(false)

  const selectedWork = React.useMemo(() => {
    if (selectedAction && selectedAction.selectedWork) {
      return selectedAction.selectedWork.id
    }
    return undefined
  }, [selectedAction])

  const selectedBoardList = React.useMemo(() => {
    if (selectedAction && selectedAction.selectedBoard) {
      return selectedAction.selectedBoard.map((board) => board.id)
    }
    return []
  }, [selectedAction])

  const selectedCopyOption = React.useMemo(() => {
    if (selectedAction && selectedAction.copyOption) {
      return selectedAction.copyOption
    }
    return 'CONTENTS'
  }, [selectedAction])

  const workList = React.useMemo(() => {
    if (!projectList) return []
    return [
      {
        sectionList: projectList
          .filter((item) => viewTypeCodeList.includes(item.type.code))
          .map((item) => {
            return {
              beforeWorkIcon: getIcon(item.type.code),
              label: item.title,
              code: item.id,
            }
          }),
      },
    ]
  }, [projectList])

  const boardListBySelectedWork = React.useMemo(() => {
    return [
      ...unArchiveBoardListOfSelectedWorkByAction
        .filter((board) => (selectedBoard ? selectedBoard.id !== board.id : true))
        .map((board) => {
          return {
            id: Number(board.id),
            title: board.roomName,
            code: board.id,
            isArchive: false,
          }
        }),
      ...archiveBoardListOfSelectedWorkByAction
        .filter((board) => (selectedBoard ? selectedBoard.id !== board.id : true))
        .map((board) => {
          return {
            id: Number(board.id),
            title: board.roomName,
            code: board.id,
            isArchive: true,
          }
        }),
    ]
  }, [unArchiveBoardListOfSelectedWorkByAction, archiveBoardListOfSelectedWorkByAction])

  const automationList = React.useMemo(() => {
    if (!selectedRule) return []
    const { event } = selectedRule
    const eventList: { label: string; code: string }[] = []
    if (event === 'CARD') {
      eventList.push({ label: t('rule.automation.copy'), code: 'COPY' })
    } else if (event === 'SPECIFIC_USER') {
      eventList.push({ label: t('rule.automation.mail'), code: 'MAIL' })
      // eventList.push({ label: t('rule.automation.push'), code: 'PUSH' })
      eventList.push({ label: t('rule.automation.incharge'), code: 'INCHARGE' })
    } else if (event === 'SPECIFIC_BOARD') {
      eventList.push({ label: t('rule.automation.copy'), code: 'COPY' })
      eventList.push({ label: t('addcontainer.coop'), code: 'COOP' })
    } else if (event === 'ANOTHER_WORK_BOARD') {
      eventList.push({ label: t('rule.automation.copy'), code: 'COPY' })
      eventList.push({ label: t('addcontainer.coop'), code: 'COOP' })
    } else {
      eventList.push({ label: t('rule.automation.mail'), code: 'MAIL' })
      eventList.push({ label: t('rule.automation.push'), code: 'PUSH' })
      eventList.push({ label: t('rule.automation.incharge'), code: 'INCHARGE' })
      eventList.push({ label: t('rule.automation.copy'), code: 'COPY' })
      eventList.push({ label: t('addcontainer.coop'), code: 'COOP' })
    }
    return eventList
  }, [selectedRule])
  const selected = React.useMemo(() => {
    return automationList.filter((item) => item.code === selectedAction?.event)
  }, [selectedAction])

  const disabled = React.useMemo(() => {
    if (selectedRule?.event === 'CARD') {
      return (
        selectedWork === undefined ||
        selectedAction?.event === undefined ||
        selectedAction?.selectedBoard === undefined ||
        selectedAction?.selectedBoard.length === 0
      )
    }
    return selectedAction?.event === undefined
  }, [selectedRule, selectedWork, selectedAction])

  const isView = React.useMemo(() => {
    if (Platform.OS === 'web') return true
    return !activated
  }, [activated])

  const onSelectedActionList = React.useCallback(
    (list: DropdownItemProps[]) => {
      if (list.length > 0) {
        const item = list[0]
        const event = item.code as ActionType
        if (event === 'COPY') {
          onSelectedAction({ ...selectedAction, event, copyOption: 'FORM' })
        } else {
          onSelectedAction({ ...selectedAction, event, copyOption: undefined })
        }
      }
    },
    [selectedAction],
  )

  const onSelectedBoardListBySelectedWork = React.useCallback(
    (item: SectionAccordionType) => {
      onActionBoardBySelectedWork(item.code)
      const work = projectList?.find((project) => project.id === item.code)
      onSelectedAction({ ...selectedAction, selectedWork: work, selectedBoard: [] })
    },
    [selectedAction, projectList],
  )

  const onSelectedBoardList = React.useCallback(
    (list: string[]) => {
      const selectedBoard = [
        ...unArchiveBoardListOfSelectedWorkByAction.filter((board) => list.includes(board.id)),
        ...archiveBoardListOfSelectedWorkByAction.filter((board) => list.includes(board.id)),
      ]
      onSelectedAction({ ...selectedAction, selectedBoard })
    },
    [selectedAction, unArchiveBoardListOfSelectedWorkByAction, archiveBoardListOfSelectedWorkByAction],
  )

  const onCopyOption = React.useCallback(
    (copyOption: ActionCopyOption) => {
      onSelectedAction({ ...selectedAction, copyOption })
    },
    [selectedAction],
  )

  const handleCreate = React.useCallback(() => {
    onPressCreate()
  }, [onPressCreate])

  if (!isActionViewRender) return <></>

  return (
    <View style={commonStyles.container}>
      <View style={commonStyles.contents}>
        {isMobile ? (
          <></>
        ) : (
          <View style={commonStyles.titleArea}>
            <RuleStepIcon type="Action" size={20} active />
            <Text style={commonStyles.title}>{t('rule.modal.automation')}</Text>
          </View>
        )}
        <View>
          {selectedRule?.event === 'CARD' ? (
            <RuleWorkSelectView
              workList={workList}
              selectedWork={selectedWork}
              boardList={boardListBySelectedWork}
              onSelectedBoardListBySelectedWork={onSelectedBoardListBySelectedWork}
              selectedBoardList={selectedBoardList}
              onSelectedBoardList={onSelectedBoardList}
              isTopLabelMarginTop={false}
            />
          ) : (
            <></>
          )}
        </View>
        <View>
          <View>
            <Text
              style={[commonStyles.labelText, selectedRule?.event === 'CARD' ? { marginTop: 20 } : { marginTop: 0 }]}
            >
              {t('rule.event.select')}
            </Text>
            <View style={styles.container}>
              <DropdownButton
                type="DEFAULT"
                configuration="DEFAULT"
                size="md"
                disabled={false}
                activated={activated}
                setActivated={setActivated}
                isButtonBackgroundColorWhite
              >
                <View style={styles.boardLabel}>
                  {selected.length > 0 ? (
                    <Text style={styles.labelText}>{selected[0].label}</Text>
                  ) : (
                    <Text style={commonStyles.initText}>{t('rule.event.select')}</Text>
                  )}
                </View>
              </DropdownButton>
              {activated && (
                <ClickOutside onClickOutside={() => setActivated(false)}>
                  <DropdownList
                    list={automationList}
                    configuration="DEFAULT-BORDER"
                    itemType="DEFAULT"
                    itemVariant="DEFAULT"
                    selected={selected}
                    setSelected={onSelectedActionList}
                    setActived={setActivated}
                    width="100%"
                  />
                </ClickOutside>
              )}
            </View>
          </View>
        </View>
        <View>
          {isView && selectedAction?.event === 'COPY' ? (
            <RuleCopySelectView current={selectedCopyOption} onPress={onCopyOption} />
          ) : (
            <></>
          )}
        </View>
      </View>
      {errorBottomText ? (
        <View style={styles.bottomErrorContainer}>
          <FAIcon iconName={faCircleExclamation} color="main.red" />
          <View style={{ width: 6 }} />
          <Text style={styles.bottomErrorText}>{errorBottomText}</Text>
        </View>
      ) : (
        <></>
      )}
      {isView ? (
        <View style={commonStyles.buttonArea}>
          <Button type={'SECONDARY'} variant={'FILL'} text={t('workmodal.basic.before')} onPress={onPressCancel} />
          <View style={commonStyles.ctaButton}>
            <Button
              type={disabled ? 'NOTSELECT' : 'PRIMARY'}
              beforeIcon={faCheck}
              text={t('rule.automation.create')}
              disabled={disabled}
              onPress={handleCreate}
            />
          </View>
        </View>
      ) : (
        <></>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  boardLabel: {
    overflow: 'hidden',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 4,
    paddingRight: 6,
    borderRadius: 4,
  },
  labelText: {
    position: 'relative',
    ...FONT.txtSmMedium,
    color: COLOR.gray.g900,
    marginLeft: 4,
  },
  bottomErrorContainer: {
    height: 40,
    backgroundColor: COLOR.mono.white,
    borderRadius: 8,
    padding: 10,
    marginBottom: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  bottomErrorText: {
    ...FONT.txtSmMedium,
    color: COLOR.main.red,
  },
})

export default ActionView
