import { COLOR } from '@rocket-mono/libs'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { ExternalServerView } from '@rocket-screens/error'

const AuthExternalServerScreen: React.FC = () => {
  const navigate = useNavigate()
  const handlePressLogin = useCallback(() => {
    navigate(-1)
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLOR.gray.g050,
        width: '100vw',
        height: '100vh',
      }}
    >
      <ExternalServerView onBack={handlePressLogin} />
    </div>
  )
}

export default AuthExternalServerScreen
