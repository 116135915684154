import { useContext, useEffect, useRef } from 'react'

import { ContextProps } from './types'
import { StompContext } from '.'
import { StompHeaders, messageCallbackType } from '@stomp/stompjs'

export function useStomp() {
  const context = useContext<ContextProps | undefined>(StompContext)

  if (context === undefined) throw new Error('There must be a StompProvider as Ancestor of all Stomp Hooks and HOCs')

  const { client } = context

  return { client }
}

export function useSubscription(
  destinations: string | string[],
  onMessage: messageCallbackType,
  headers: StompHeaders = {},
) {
  const stompContext = useContext(StompContext)

  if (stompContext === undefined)
    throw new Error('There must be a StompProvider as Ancestor of all Stomp Hooks and HOCs')

  const callbackRef = useRef<messageCallbackType>(onMessage)
  const _destinations = Array.isArray(destinations) ? destinations : [destinations]

  callbackRef.current = onMessage

  useEffect(() => {
    const cleanUpFunctions: (() => void)[] = []

    _destinations.forEach((_destination) =>
      cleanUpFunctions.push(
        stompContext.subscribe(
          _destination,
          (message) => {
            callbackRef.current(message)
          },
          headers,
        ),
      ),
    )

    return () => {
      cleanUpFunctions.forEach((_cleanUpFunction) => {
        _cleanUpFunction()
      })
    }
  }, [Object.values(_destinations).toString(), Object.values(headers).toString()])
}
