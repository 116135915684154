import * as React from 'react'

import { BoardLayout } from './components/BoardLayout'
import Providers from './providers'
import ProjectBoardView from './view'

import { i18n } from 'i18next'

interface Props {
  i18n: i18n
  projectId?: string
}

const ProjectBoardScreen: React.FC<Props> = ({ i18n, projectId }) => {
  return (
    <Providers i18n={i18n}>
      <ProjectBoardView projectId={projectId} />
    </Providers>
  )
}

export default ProjectBoardScreen

export { BoardLayout }
export * from './components'
