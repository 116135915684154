import * as React from 'react'

import { Client, messageCallbackType, Stomp, StompConfig, StompHeaders } from '@stomp/stompjs'
import SockJS from 'sockjs-client'

import { ContextProps, stompHeaders, StompSessionSubscription } from './types'
import { useAstro } from '../AstroProvider'

export const StompContext = React.createContext<ContextProps | undefined>(undefined)
interface Props extends StompConfig {
  url: string
  platform: 'mobile' | 'web'
  children?: JSX.Element | JSX.Element[]
}

export const StompProvider = ({ url, platform, children, ...stompOptions }: Props) => {
  const [client, setClient] = React.useState<Client>()
  const subscriptionRequests = React.useRef(new Map())

  const { astro, token } = useAstro()

  React.useEffect(() => {
    const _client = Stomp.over(() => new SockJS(url))
    _client.reconnectDelay = 1000
    _client.heartbeatIncoming = 1000
    _client.heartbeatOutgoing = 1000
    _client.onWebSocketClose = function (event) {
      if (stompOptions.onWebSocketClose) stompOptions.onWebSocketClose(event)
      setClient(undefined)
    }

    if (!stompOptions.onStompError) {
      _client.onStompError = function (frame) {
        throw frame
      }
    }

    console.log('astro', astro.option, token)

    astro
      .readMe()
      .then((currentUser) => {
        console.log('astro.readMe', currentUser)
        _client.connectHeaders = {
          userNo: String(currentUser.id),
          userState: String(
            currentUser.userState === 'active'
              ? 1
              : currentUser.userState === 'away'
              ? 2
              : currentUser.userState === 'busy'
              ? 3
              : currentUser.userState === 'off'
              ? 4
              : 1,
          ),
          platform: platform,
        }

        _client.onConnect = (frame) => {
          if (stompOptions.onConnect) stompOptions.onConnect(frame)
          subscriptionRequests.current.forEach((value) => {
            value.subscription = _client.subscribe(value.destination, value.callback, value.headers)
          })

          _client.publish({
            destination: '/publish/info',
            body: JSON.stringify({
              userNo: currentUser.id,
            }),
            headers: stompHeaders,
          })
        }
      })
      .catch((err) => {
        console.error('StompClient-useEffect-readMe', err)
      })
      .finally(() => {
        console.debug('StompClient-useEffect-readMe-finally')
        setClient(_client)
        _client.activate()
      })

    return () => {
      _client.deactivate()
    }
  }, [])

  const subscribe = (destination: string, callback: messageCallbackType, headers: StompHeaders = {}) => {
    const subscriptionId = Math.random().toString(36).substr(2, 9)
    const subscriptionRequest: StompSessionSubscription = {
      destination,
      callback,
      headers,
    }

    subscriptionRequests.current.set(subscriptionId, subscriptionRequest)

    if (client && client.connected) {
      subscriptionRequest.subscription = client.subscribe(destination, callback, headers)
    }

    return () => {
      const subscriptionData = subscriptionRequests.current.get(subscriptionId)

      if (subscriptionData.subscription) {
        subscriptionData.subscription.unsubscribe()
      }

      subscriptionRequests.current.delete(subscriptionId)
    }
  }

  return <StompContext.Provider value={{ client, subscribe }}>{children}</StompContext.Provider>
}

export * from './hooks'
