import React from 'react'
import { StyleSheet, View } from 'react-native'
import { SkeletonBar, SkeletonCircle, SkeletonSquare } from '../SkeletomParts'

const SkeletonWorkCardEdit: React.FC = () => {
  return (
    <View>
      <View style={{ marginBottom: 26 }}>
        <SkeletonBar width={'100%'} height={32}/>
      </View>

      <View style={{ marginBottom: 26 }}>
        <View style={{ marginBottom: 6 }}>
          <SkeletonBar width={48} height={16} color='gray.g150'/>
        </View>
        <View style={{ flexDirection:'row', marginBottom: 6 }}>
          <SkeletonSquare width={'49.5%'} height={58} color='gray.g100'/>
          <View style={{width:'1%'}} />
          <SkeletonSquare width={'49.5%'} height={58} color='gray.g100'/>
        </View>
      </View>

      <View style={{ marginBottom: 26 }}>
        <View style={{ marginBottom: 6 }}>
          <SkeletonBar width={48} height={16} color='gray.g150'/>
        </View>
        <View>
          <SkeletonBar width={'100%'} height={32}/>
        </View>
      </View>

      <View style={{ marginBottom: 26 }}>
        <View style={{ marginBottom: 6 }}>
          <SkeletonBar width={48} height={16} color='gray.g150'/>
        </View>
        <View>
          <SkeletonBar width={'100%'} height={170} color='gray.g050'/>
        </View>
      </View>

      <View style={{ marginBottom: 26 }}>
        <View style={{ marginBottom: 6 }}>
          <SkeletonBar width={48} height={16} color='gray.g150'/>
        </View>
        <View style={{ marginBottom: 6, opacity:1 }}>
          <SkeletonBar width={'100%'} height={36} />
        </View>
        <View style={{ marginBottom: 6, opacity:.9}}>
          <SkeletonBar width={'100%'} height={36} />
        </View>
        <View style={{ marginBottom: 6, opacity:.7 }}>
          <SkeletonBar width={'100%'} height={36} />
        </View>
        <View style={{ marginBottom: 6, opacity:.5 }}>
          <SkeletonBar width={'100%'} height={36} />
        </View>
        <View style={{ marginBottom: 6, opacity:.3 }}>
          <SkeletonBar width={'100%'} height={36} />
        </View>
      </View>
    </View>
  )
}

export default SkeletonWorkCardEdit

const styles = StyleSheet.create({
  rowFlexbox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
