import { Text } from '@rocket/atoms'
import React from 'react'
import { View } from 'react-native'
import { useRule } from '../../RuleScreen/hooks'
import { RuleListItemType } from '../../RuleScreen/view/ListView'
import FlowRuleBoardView from './flowRuleBoardView'
import FlowRuleCardView from './flowRuleCardView'
import FlowRuleUserView from './flowRuleUserView'
import FlowRuleWorkView from './flowRuleWorkView'

interface Props {
  item: RuleListItemType
}

const FlowRuleView: React.FC<Props> = ({ item }) => {
  const { rules, cardList, memberList, unArchiveBoardList, archiveBoardList, applyBoard } = item

  const { projectList, getProfileUrl, onRuleListChange, onRuleListChangeByAnotherWork } = useRule()

  const flowCardList = React.useMemo(() => {
    if (!cardList) return []
    return cardList.map((card) => {
      return {
        label: card.cardTitle,
        code: String(card.id),
        beforeCardIcon: card.cardType === 'ATTEND' ? 'MEETING' : card.cardType,
        selected: rules?.selectedCard?.id === card.id,
      }
    })
  }, [cardList, rules])

  const flowCardIndex = React.useMemo(() => {
    if (!cardList) return undefined
    if (!rules) return undefined
    const { selectedCard } = rules
    if (!selectedCard) return undefined
    return String(cardList.findIndex((card) => card.id === selectedCard.id))
  }, [cardList, rules])

  const flowUserList = React.useMemo(() => {
    if (!memberList) return []
    return memberList.map((member) => {
      return {
        id: member.userId,
        name: member.name,
        uri: getProfileUrl(member.email),
        selected: rules?.selectedUsers?.some((user) => user.userId === member.userId) || false,
      }
    })
  }, [memberList, rules])

  const flowBoardList = React.useMemo(() => {
    if (rules && rules.selectedBoard) {
      return rules.selectedBoard.map((board) => board.id)
    } else {
      return []
    }
  }, [rules])

  const flowSelectedWork = React.useMemo(() => {
    if (rules && rules.selectedWork) {
      return rules.selectedWork.id
    } else {
      return ''
    }
  }, [rules])

  if (!rules) return <></>

  if (rules.event === 'CARD') {
    return (
      <View>
        <FlowRuleCardView
          cardList={flowCardList}
          defaultIndex={flowCardIndex}
          onSelected={(selected) => {
            const selectedCard = cardList?.find((card) => card.id === selected.code)
            if (selectedCard) {
              onRuleListChange({
                ...item,
                rules: {
                  ...rules,
                  selectedCard,
                },
              })
            }
          }}
        />
      </View>
    )
  } else if (rules.event === 'SPECIFIC_USER') {
    return (
      <View>
        <FlowRuleUserView
          userList={flowUserList}
          onSelected={(userList) => {
            const memberIdList = userList.filter((member) => member.selected).map((member) => member.id)
            const selectedUsers = memberList?.filter((member) => memberIdList.includes(member.userId))
            const { rules } = item
            onRuleListChange({
              ...item,
              rules: {
                ...rules,
                selectedUsers,
              },
            })
          }}
        />
      </View>
    )
  } else if (rules.event === 'SPECIFIC_BOARD') {
    return (
      <View>
        <FlowRuleBoardView
          selectedApplyBoard={applyBoard}
          unArchiveBoardList={unArchiveBoardList}
          archiveBoardList={archiveBoardList}
          selectedBoardList={flowBoardList}
          onSelectedBoardList={(list) => {
            if (!unArchiveBoardList || !archiveBoardList) return
            const selectedBoard = [
              ...unArchiveBoardList.filter((board) => list.includes(board.id)),
              ...archiveBoardList.filter((board) => list.includes(board.id)),
            ]

            onRuleListChange({
              ...item,
              rules: {
                ...item.rules,
                selectedBoard,
              },
            })
          }}
        />
      </View>
    )
  } else if (rules.event === 'ANOTHER_WORK_BOARD') {
    return (
      <View>
        <FlowRuleWorkView
          workList={projectList}
          selectedWork={flowSelectedWork}
          unArchiveBoardList={unArchiveBoardList}
          archiveBoardList={archiveBoardList}
          selectedBoardList={flowBoardList}
          onSelectedWork={(work) => {
            const selectedWork = projectList?.find((project) => project.id === work.code)
            onRuleListChangeByAnotherWork(
              {
                ...item,
                rules: {
                  ...item.rules,
                  selectedWork,
                  selectedBoard: [],
                },
              },
              work.code,
            )
          }}
          onSelectedBoardList={(list) => {
            if (!unArchiveBoardList || !archiveBoardList) return
            const selectedBoard = [
              ...unArchiveBoardList.filter((board) => list.includes(board.id)),
              ...archiveBoardList.filter((board) => list.includes(board.id)),
            ]

            onRuleListChange({
              ...item,
              rules: {
                ...item.rules,
                selectedBoard,
              },
            })
          }}
        />
      </View>
    )
  } else {
    return (
      <View>
        <Text fontName="txtSmMedium" fontColor="gray.g800">
          {rules.event}
        </Text>
      </View>
    )
  }
}

export default FlowRuleView
