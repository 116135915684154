import React from 'react'
import { StyleSheet, View } from 'react-native'

import { SkeletonBar, SkeletonSquare } from '../SkeletomParts'

const SkeletonSideNav: React.FC = () => {
  return (
    <View>
      <View style={styles.rowFlexbox}>
        <SkeletonSquare width={16} height={16} color="opacity.w30" />
        <View style={{ width: 4 }} />
        <SkeletonBar width={'80%'} height={14} color="opacity.w10" />
      </View>
      <View style={styles.rowFlexbox}>
        <SkeletonSquare width={16} height={16} color="opacity.w30" />
        <View style={{ width: 4 }} />
        <SkeletonBar width={'60%'} height={14} color="opacity.w10" />
      </View>
      <View style={styles.rowFlexbox}>
        <SkeletonSquare width={16} height={16} color="opacity.w30" />
        <View style={{ width: 4 }} />
        <SkeletonBar width={'70%'} height={14} color="opacity.w10" />
      </View>
      <View style={styles.rowFlexbox}>
        <SkeletonSquare width={16} height={16} color="opacity.w30" />
        <View style={{ width: 4 }} />
        <SkeletonBar width={'50%'} height={14} color="opacity.w10" />
      </View>
      <View style={styles.rowFlexbox}>
        <SkeletonSquare width={16} height={16} color="opacity.w30" />
        <View style={{ width: 4 }} />
        <SkeletonBar width={'74%'} height={14} color="opacity.w10" />
      </View>
      <View style={styles.rowFlexbox}>
        <SkeletonSquare width={16} height={16} color="opacity.w30" />
        <View style={{ width: 4 }} />
        <SkeletonBar width={'80%'} height={14} color="opacity.w10" />
      </View>
      <View style={styles.rowFlexbox}>
        <SkeletonSquare width={16} height={16} color="opacity.w30" />
        <View style={{ width: 4 }} />
        <SkeletonBar width={'60%'} height={14} color="opacity.w10" />
      </View>
      <View style={styles.rowFlexbox}>
        <SkeletonSquare width={16} height={16} color="opacity.w30" />
        <View style={{ width: 4 }} />
        <SkeletonBar width={'70%'} height={14} color="opacity.w10" />
      </View>
      <View style={[styles.rowFlexbox, { opacity: 0.9 }]}>
        <SkeletonSquare width={16} height={16} color="opacity.w30" />
        <View style={{ width: 4 }} />
        <SkeletonBar width={'74%'} height={14} color="opacity.w10" />
      </View>
      <View style={[styles.rowFlexbox, { opacity: 0.9 }]}>
        <SkeletonSquare width={16} height={16} color="opacity.w30" />
        <View style={{ width: 4 }} />
        <SkeletonBar width={'74%'} height={14} color="opacity.w10" />
      </View>
      <View style={[styles.rowFlexbox, { opacity: 0.8 }]}>
        <SkeletonSquare width={16} height={16} color="opacity.w30" />
        <View style={{ width: 4 }} />
        <SkeletonBar width={'80%'} height={14} color="opacity.w10" />
      </View>
      <View style={[styles.rowFlexbox, { opacity: 0.7 }]}>
        <SkeletonSquare width={16} height={16} color="opacity.w30" />
        <View style={{ width: 4 }} />
        <SkeletonBar width={'60%'} height={14} color="opacity.w10" />
      </View>
      <View style={[styles.rowFlexbox, { opacity: 0.6 }]}>
        <SkeletonSquare width={16} height={16} color="opacity.w30" />
        <View style={{ width: 4 }} />
        <SkeletonBar width={'70%'} height={14} color="opacity.w10" />
      </View>
      <View style={[styles.rowFlexbox, { opacity: 0.5 }]}>
        <SkeletonSquare width={16} height={16} color="opacity.w30" />
        <View style={{ width: 4 }} />
        <SkeletonBar width={'50%'} height={14} color="opacity.w10" />
      </View>
      <View style={[styles.rowFlexbox, { opacity: 0.4 }]}>
        <SkeletonSquare width={16} height={16} color="opacity.w30" />
        <View style={{ width: 4 }} />
        <SkeletonBar width={'80%'} height={14} color="opacity.w10" />
      </View>
      <View style={[styles.rowFlexbox, { opacity: 0.2 }]}>
        <SkeletonSquare width={16} height={16} color="opacity.w20" />
        <View style={{ width: 4 }} />
        <SkeletonBar width={'60%'} height={14} color="opacity.w10" />
      </View>
      <View style={[styles.rowFlexbox, { opacity: 0.2 }]}>
        <SkeletonSquare width={16} height={16} color="opacity.w20" />
        <View style={{ width: 4 }} />
        <SkeletonBar width={'60%'} height={14} color="opacity.w10" />
      </View>
    </View>
  )
}

export default SkeletonSideNav

const styles = StyleSheet.create({
  rowFlexbox: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingLeft: 28,
    paddingVertical: 8,
    marginVertical: 2,
  },
})
