import { Icon } from '@rocket-atoms/icon'
import type { IFile } from '@rocket-mono/libs'
import { FONT, getRealColor } from '@rocket-mono/libs'
import * as React from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'
import ButtonIcon from '../ButtonIcon'

interface Props {
  file?: IFile
  onFileShare?: (item?: IFile) => void
  onFileExport?: (item?: IFile) => void
  onFileDownload?: (item?: IFile) => void
}

const FileView = ({ file, onFileShare, onFileExport, onFileDownload }: Props) => {
  const filename = React.useMemo(() => {
    if (file) {
      const extLastIndex = file.originalFilename.lastIndexOf('.')
      if (extLastIndex > 0) {
        return file.originalFilename.substring(0, extLastIndex)
      } else {
        return file.originalFilename
      }
    } else {
      return ''
    }
  }, [file])
  const buttonsWidth = React.useMemo(() => {
    let count = 0
    if (onFileShare) count++
    if (onFileExport) count++
    if (onFileDownload) count++
    return count * 35
  }, [onFileShare, onFileExport, onFileDownload])
  return (
    <View style={styles.container}>
      <View style={styles.leftContainer}>
        <View style={styles.fileIcon}>
          <Icon name="file-zip" color="mono.darkGray" />
        </View>
        <View style={styles.filenameWrapContainer}>
          <View style={styles.filenameContainer}>
            <Text numberOfLines={1} style={styles.filename}>
              {filename}
            </Text>
            {file?.ext && <Text style={styles.filename}>.{file?.ext}</Text>}
          </View>
          <Text style={styles.filesize}>{printFileSize(file?.fileSize ?? 0)}</Text>
        </View>
      </View>
      {(Platform.OS === 'ios' || Platform.OS === 'android') && (
        <View>{file?.ext && <Text style={{ opacity: 0 }}>{file?.ext}</Text>}</View>
      )}
      <View style={[styles.fileImageButtonsContainer, { width: buttonsWidth }]}>
        {onFileShare && (
          <ButtonIcon
            iconName="share-alt-o"
            rounded={true}
            backgroundColor={'mono.white'}
            iconColor={'mono.paleBlack'}
            containerSize={28}
            iconSize={16}
            onPress={() => {
              onFileShare(file)
            }}
            style={styles.fileImageButton}
          />
        )}
        {onFileExport && (
          <ButtonIcon
            iconName="external-link"
            rounded={true}
            backgroundColor={'mono.white'}
            iconColor={'mono.paleBlack'}
            containerSize={28}
            iconSize={16}
            onPress={() => {
              onFileExport(file)
            }}
            style={styles.fileImageButton}
          />
        )}
        {onFileDownload && (
          <ButtonIcon
            iconName="download"
            rounded={true}
            backgroundColor={'mono.white'}
            iconColor={'mono.paleBlack'}
            containerSize={28}
            iconSize={16}
            onPress={() => {
              onFileDownload(file)
            }}
            style={styles.fileImageButton}
          />
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    minWidth: 250,
    height: 48,
    backgroundColor: getRealColor('mono.pale'),
    flexDirection: 'row',
    borderRadius: 5,
    justifyContent: 'space-between',
    display: 'flex',
    flex: 1,
  },
  leftContainer: {
    flexDirection: 'row',
    marginVertical: 5,
    marginLeft: 5,
    display: 'flex',
    flex: 1,
  },
  fileIcon: {
    height: 38,
    width: 38,
    backgroundColor: getRealColor('mono.lightGray'),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    marginRight: 10,
  },
  filenameWrapContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  filenameContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 20,
  },
  fileImageButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 120,
    height: 48,
    display: 'flex',
    marginRight: 10,
  },
  fileImageButton: {
    alignSelf: 'center',
    marginLeft: 5,
  },
  filename: {
    ...FONT.textMedium,
    color: getRealColor('mono.black'),
  },
  filesize: {
    ...FONT.subTextThin,
    color: getRealColor('mono.paleBlack'),
  },
})

function getFileSize(x: number) {
  const s = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'] as const
  const e = Math.floor(Math.log(x) / Math.log(1024))
  return {
    size: (x / 1024 ** e).toFixed(0),
    unit: s[e],
  }
}

function printFileSize(x: number): string {
  const { size, unit } = getFileSize(x)

  return `${size}${unit}`
}

export default FileView
