import * as React from 'react'
import { Text as RNText, TextProps as RNTextProps } from 'react-native'

import { FontName, IColors, getFontStyle, getRealColor } from '@rocket-mono/libs'

export interface TextProps extends RNTextProps {
  fontName?: FontName
  fontColor?: IColors
}
/**
 * @deprecated
 */
const Text: React.FC<TextProps> = ({
  children,
  fontName = 'textRegular',
  fontColor = 'mono.black',
  style,
  ...textProps
}) => {
  return (
    <RNText {...textProps} style={[getFontStyle(fontName), { color: getRealColor(fontColor) }, style]}>
      {children}
    </RNText>
  )
}

export default Text
