import React from 'react'
import { View } from 'react-native'
import { LayoutPayload } from '@rocket-mono/libs'
import { Item } from './item'

export type FileSourceType = 'MESSAGE' | 'MISSION' | 'MEETING' | 'DISCUSSION' | 'GATHERING' | 'TODO' | undefined

export interface FileItemType {
  id?: string
  small: boolean
  access?: boolean
  border?: boolean
  download?: boolean
  fileUrl: string
  fileType: string
  filePath?: string
  fileName: string
  fileSize: number
  fileSource: FileSourceType
  date: string | Date
  errorMessage?: string
  uploader: string
  onPressItem: () => void
  onPressEtc?: (payload: LayoutPayload) => void
  onPressDownload?: () => void
  onPressFileOpen?: () => void
  isExists?: () => Promise<boolean>
  progress?: number
}

export interface CardGatheringItemType {
  id: string
  groupId: string
  userId: string
  type: string
  user: string
  complete: boolean
  profileImage: string
  isWriter: boolean
  content: string
  file?: FileItemType[]
  permission: boolean
}
interface Props {
  list: CardGatheringItemType[]
  collectionId: string
  isAnonymous: boolean
  onPressDownload: (file: FileItemType) => void
}

const CardGatheringItem = ({ list, collectionId, isAnonymous }: Props) => {
  return (
    <View>
      {list.map((item, idx) => (
        <Item item={item} collectionId={collectionId} isAnonymous={isAnonymous} key={`gathering-item${idx}`} />
      ))}
    </View>
  )
}

export default CardGatheringItem
