import React from 'react'
import { Pressable, StyleSheet } from 'react-native'

import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import { FAIcon } from '@rocket-atoms/icon'
import { COLOR } from '@rui/foundations'

interface Props {
  onPress?: () => void
}

const DownloadIcon: React.FC<Props> = ({ onPress }) => {
  const [isHover, setIsHover] = React.useState(false)
  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      onPress={onPress}
      style={[styles.wrap, isHover && { backgroundColor: COLOR.gray.g100 }]}
    >
      <FAIcon iconName={faFileArrowDown} size={'xs'} color={isHover ? 'gray.g700' : 'gray.g600'} />
    </Pressable>
  )
}

export default DownloadIcon

const styles = StyleSheet.create({
  wrap: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 28,
    backgroundColor: COLOR.gray.g050,
    borderRadius: 30,
  },
})
