import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { faHourglassStart, faMoon, faArrowsRotate, faCheck } from '@fortawesome/free-solid-svg-icons'
import StateView from './StateView'
import ChangeButton from './ChangeButton'
import type { TodoStepType } from '../type'

interface Props {
 onChageStep: (arg0: TodoStepType) => void
}

const TodoStepView: React.FC<Props> = ({onChageStep}) => {
  const { t } = useTranslation()
  const [currentStep, setCurrenStep] = useState<TodoStepType>('waiting')

  //외부에 현재 상태 담아주기
  useEffect(()=>{
    onChageStep(currentStep)
  },[])

  return(
    <View style={styles.container}>
      <StateView currentStep={currentStep}/>
      <View style={[
        styles.buttonArea,
        currentStep === 'progress' || currentStep === 'progressPending' ? { justifyContent:'center' } : {},
        currentStep === 'completed' && { justifyContent:'flex-end' }
      ]}>
        {/* 대기 */}
        {currentStep === 'waiting' &&
          <>
            <ChangeButton iconName={faHourglassStart} text={t('subtask.status.scheduled')} onPress={()=>setCurrenStep('scheduled')}/>
            <ChangeButton iconName={faMoon} text={t('subtask.status.holding')} onPress={()=>setCurrenStep('waitingPending')}/>
            <ChangeButton iconName={faArrowsRotate} text={t('subtask.status.doing')} onPress={()=>setCurrenStep('progress')}/>
          </>
        }

        {/* 진행예정 */}
        {currentStep === 'scheduled' &&
          <>
            <ChangeButton iconName={faMoon} text={t('subtask.status.holding')} onPress={()=>setCurrenStep('waitingPending')}/>
            <ChangeButton iconName={faArrowsRotate} text={t('subtask.status.doing')} onPress={()=>setCurrenStep('progress')}/>
          </>
        }

        {/* 진행 */}
        {currentStep === 'progress' &&
          <>
            <ChangeButton iconName={faMoon} text={t('subtask.status.holding')} onPress={()=>setCurrenStep('progressPending')}/>
            <ChangeButton iconName={faCheck} text={t('subtask.status.complete')} onPress={()=>setCurrenStep('completed')}/>
          </>
        }

        {/* 완료 */}
        {currentStep === 'completed' &&
          <>
            <ChangeButton iconName={faHourglassStart} text={t('subtask.status.scheduled')} onPress={()=>setCurrenStep('scheduled')}/>
            <ChangeButton iconName={faArrowsRotate} text={t('subtask.status.doing')} onPress={()=>setCurrenStep('progress')}/>
          </>
        }

        {/* 대기중 보류 */}
        {currentStep === 'waitingPending' &&
          <>
            <ChangeButton iconName={faArrowsRotate} text={t('subtask.status.doing')} onPress={()=>setCurrenStep('progress')}/>
            <ChangeButton iconName={faCheck} text={t('subtask.status.complete')} onPress={()=>setCurrenStep('completed')}/>
          </>
        }

        {/* 진행중 보류 */}
        {currentStep === 'progressPending' &&
          <>
            <ChangeButton iconName={faArrowsRotate} text={t('subtask.status.doing')} onPress={()=>setCurrenStep('progress')}/>
            <ChangeButton iconName={faCheck} text={t('subtask.status.complete')} onPress={()=>setCurrenStep('completed')}/>
          </>
        }
      </View>
    </View>
  )
}

export default TodoStepView


const styles = StyleSheet.create({
  container:{

  },
  buttonArea:{
    flexDirection: 'row',
    marginTop: 24,
    marginLeft: -8,
    marginRight: -8,
  },
})