import React from 'react'
import Svg, { Path } from 'react-native-svg'

interface Props {
  color?: string
}

const WorkspaceIcon: React.FC<Props> = ({ color = '#B4BEC5' }) => (
  <Svg width={18} height={19} viewBox="0 0 18 19" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.07968 2.17316C4.42709 0.993523 6.12708 0.210118 7.99982 0.00364403V1.92354C6.64942 2.11324 5.42057 2.68407 4.42441 3.52436L3.07968 2.17316ZM9.94415 -0.000938416V1.9177C13.4213 2.38468 16.1033 5.37695 16.1033 8.99863C16.1033 12.9443 12.92 16.1429 8.99323 16.1429C7.49314 16.1429 6.10155 15.6761 4.95448 14.8792L3.59631 16.2439C5.10036 17.3769 6.96879 18.048 8.99323 18.048C13.9672 18.048 17.9993 13.9965 17.9993 8.99863C17.9993 4.3235 14.4711 0.476401 9.94415 -0.000938416ZM3.55043 13.5957C2.80122 12.7011 2.26648 11.6191 2.02582 10.4297H0.099098C0.370019 12.1432 1.11959 13.6964 2.2058 14.9468L3.55043 13.5957ZM1.89855 8.52455C1.9852 7.19557 2.4335 5.96578 3.14552 4.93359L1.78758 3.56912C0.745286 4.96349 0.0945152 6.67022 -0.000732422 8.52455H1.89855ZM7.86051 12.6489L14.1077 7.35037L12.8847 5.89453L7.96908 10.0637L5.63445 7.71784L4.29376 9.06497L7.86051 12.6489Z"
      fill={color}
    />
  </Svg>
)
export default WorkspaceIcon
