import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { COLOR, ClickOutside } from '@rocket-mono/libs'
import { FAIcon, Text } from '@rocket/atoms'
import { debounce } from 'lodash'
import React from 'react'
import { FlatList, Pressable, StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native'

const pad = (n: number, size = 2) => {
  return String(n).padStart(size, '0')
}

const zIndex = 1

interface Props {
  selectedNumber: string
  number?: number
  onNumberChange?: (day: string) => void
}

const DropNumberPicker: React.FC<Props> = ({ selectedNumber, number = 20, onNumberChange }) => {
  const [visible, setVisible] = React.useState(false)
  const [numberPickerHover, setNumberPickerHover] = React.useState<string>()
  const numberFlatListRef = React.useRef<FlatList<string>>(null)

  const numbers = React.useMemo(() => {
    return Array.from(Array(number).keys()).map((num) => `${pad(num + 1)}`)
  }, [number])

  const NumberPickerItem = ({ column, onChange }: { column: string; onChange: (column: string) => void }) => {
    const buttonStyle: StyleProp<ViewStyle> = [styles.numberpickerItem]
    const textStyle: StyleProp<TextStyle> = [styles.numberpickerItemText]

    if (numberPickerHover === `${column}`) {
      buttonStyle.push(styles.numberpickerItemHover)
      textStyle.push(styles.numberpickerItemHoverText)
    }

    if (selectedNumber === column) {
      buttonStyle.push(styles.numberpickerItemActive)
      textStyle.push(styles.numberpickerItemActiveText)
    }
    return (
      <Pressable
        style={buttonStyle}
        onPress={() => onChange(column)}
        onHoverIn={() => setNumberPickerHover(`${column}`)}
        onHoverOut={() => setNumberPickerHover(undefined)}
      >
        <Text fontName="txtSmMedium" style={textStyle}>
          {column}
        </Text>
      </Pressable>
    )
  }

  return (
    <View style={styles.rootContainer}>
      <Pressable
        style={[styles.pickerStyle, styles.pickerStyleV2, styles.pickerStyleV3]}
        onPress={() => {
          const numberIndex = numbers.findIndex((number) => number === selectedNumber)
          setTimeout(() => {
            if (numberFlatListRef.current) {
              if (numberIndex > -1 && numberIndex - 2 > -1) {
                numberFlatListRef.current.scrollToIndex({
                  index: numberIndex - 2,
                  animated: false,
                })
              }
            }
          }, 200)
          setVisible(true)
        }}
      >
        <Text fontName="txtSm">{selectedNumber}</Text>
        {visible ? (
          <FAIcon iconName={faChevronUp} size="sm" color="mono.realBlack" />
        ) : (
          <FAIcon iconName={faChevronDown} size="sm" color="mono.realBlack" />
        )}
      </Pressable>
      {visible ? (
        <View style={[styles.container, { zIndex }]}>
          <ClickOutside onClickOutside={() => setVisible(false)}>
            <View style={styles.numberpickerContainer}>
              <View style={styles.numberpickerBodyContainer}>
                <View style={styles.numberpickerColumnContainer}>
                  <FlatList
                    ref={numberFlatListRef}
                    onLayout={() => {
                      const numberIndex = numbers.findIndex((number) => number === selectedNumber)
                      if (numberFlatListRef.current) {
                        if (numberIndex > -1 && numberIndex - 2 > -1) {
                          numberFlatListRef.current.scrollToOffset({
                            offset: (numberIndex - 2) * 32,
                            animated: false,
                          })
                        }
                      }
                    }}
                    onScrollToIndexFailed={debounce(() => {
                      const numberIndex = numbers.findIndex((number) => number === selectedNumber)
                      if (numberFlatListRef.current) {
                        if (numberIndex > -1 && numberIndex - 2 > -1) {
                          numberFlatListRef.current.scrollToIndex({
                            index: numberIndex - 2,
                            animated: false,
                          })
                        }
                      }
                    }, 100)}
                    data={numbers}
                    keyExtractor={(item) => `number-${item}`}
                    renderItem={({ item }: { item: string }) => (
                      <NumberPickerItem
                        column={item}
                        onChange={(number) => {
                          // setSelectedNumber(number)
                          onNumberChange?.(`${number}`)
                        }}
                      />
                    )}
                  />
                </View>
              </View>
              {/* <View style={styles.daypickerFooterContainer}>
                <Text fontName="txtSmMedium" fontColor="gray.g800">
                  {day}
                </Text>
              </View> */}
            </View>
          </ClickOutside>
        </View>
      ) : (
        <></>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  pickerStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 124,
    height: 36,
    borderWidth: 1,
    borderColor: COLOR.transparent.base,
    backgroundColor: COLOR.opacity.blk05,
    borderRadius: 4,
    marginTop: 6,
  },
  pickerStyleV2: {
    width: 72,
    paddingHorizontal: 12,
    borderColor: COLOR.gray.g200,
    backgroundColor: COLOR.mono.white,
  },
  pickerStyleV3: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rootContainer: {
    flexDirection: 'column',
  },
  container: {
    height: 200,
    width: 72,
    marginTop: 0,
    marginBottom: -200,
  },
  numberpickerContainer: {
    height: 200,
    width: 72,
    backgroundColor: COLOR.mono.white,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    flex: 1,
    marginBottom: 200,
  },
  numberpickerBodyContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  numberpickerColumnContainer: {
    flex: 1,
  },
  numberpickerItem: {
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  numberpickerItemActive: {
    backgroundColor: COLOR.primary.blue600,
  },
  numberpickerItemHover: {
    backgroundColor: COLOR.gray.g100,
  },
  numberpickerItemText: {
    color: COLOR.gray.g500,
  },
  numberpickerItemActiveText: {
    color: COLOR.mono.white,
  },
  numberpickerItemHoverText: {
    color: COLOR.gray.g800,
  },
  // numberpickerFooterContainer: {
  //   height: 40,
  //   borderTopWidth: 1,
  //   borderTopColor: COLOR.gray.g200,
  //   justifyContent: 'center',
  //   alignItems: 'center',
  // },
})

export default DropNumberPicker
