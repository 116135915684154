type ArgsType = { [key: string]: unknown }

export function parseSymbolicMessage(message?: string | null): {
  key: string
  args: ArgsType
} {
  try {
    if (!message) {
      throw Error('symbol이 존재하지 않습니다.')
    }
    const _message = message.replace(/&quot;/g, '"')
    const parsed: unknown = JSON.parse(_message)
    if (!parsed) {
      throw Error('JSON.parse 결과 null 입니다.')
    }
    return parsed as { key: string; args: ArgsType }
  } catch {
    return { key: '', args: {} }
  }
}
