import React from 'react'
import { StyleSheet } from 'react-native'

import { View } from '@rocket-mono/foundations'
import { useWork } from '@rocket-mono/providers'
import { useMobileView } from '@rui/foundations'
import { Draggable, Droppable } from 'react-beautiful-dnd-grid-support'
import { useRecoilValue } from 'recoil'
import { draggingIdState } from '../../../recoil'
import { DROPABLE_GROUP_CARD_ID, DROPABLE_GROUP_VIEW_ID } from '../../../utils'
import WorkspaceMainGroup from './group'
import { Props } from './types'

const WorkspaceMainGroupView: React.FC<Props> = ({ onPressProject }) => {
  const mobileView = useMobileView(500)
  const mobileWebView = useMobileView(768)
  const draggingId = useRecoilValue(draggingIdState)

  const { groupList, positionList } = useWork()

  if (groupList === undefined) return <></>

  return (
    <Droppable
      isDropDisabled={![DROPABLE_GROUP_VIEW_ID].includes(draggingId)}
      droppableId={DROPABLE_GROUP_VIEW_ID}
      direction={mobileWebView ? 'vertical' : 'grid'}
    >
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <View
            style={[
              mobileWebView ? { flexWrap: 'wrap', flexDirection: 'row', marginLeft: '-2%' } : styles.workGroupArea,
              mobileView && { flexDirection: 'column', marginLeft: 'auto' },
            ]}
          >
            {groupList.map((group, idx) => {
              const size = positionList?.filter(({ groupId }) => groupId === String(group.groupNo)).length ?? 0
              return (
                <Draggable
                  key={DROPABLE_GROUP_CARD_ID + group.no}
                  draggableId={DROPABLE_GROUP_CARD_ID + group.no}
                  index={idx}
                >
                  {(provided, snapshot) => {
                    return (
                      <div ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                        <View
                          style={[
                            {
                              width: mobileView
                                ? '100%'
                                : mobileWebView && size <= 5
                                ? '50%'
                                : mobileWebView && size > 5
                                ? '100%'
                                : '',
                            },
                            {
                              width: mobileView ? '100%' : 'auto',
                              marginLeft: mobileWebView && size > 5 ? '0' : '',
                            },
                            snapshot.isDragging && {
                              transform: 'rotate(0.01turn)',
                            },
                          ]}
                        >
                          <WorkspaceMainGroup group={group} onPressProject={onPressProject} />
                        </View>
                      </div>
                    )
                  }}
                </Draggable>
              )
            })}
            {provided.placeholder}
          </View>
        </div>
      )}
    </Droppable>
  )
}

export default WorkspaceMainGroupView

const styles = StyleSheet.create({
  workGroupArea: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: -12,
  },
})
