import React, { useMemo } from 'react'

import { View, StyleSheet, Pressable } from 'react-native'

import { Breadcrumbs, Text, SkeletonBar } from '@rui/atoms'
import { BoardLabel, WorkIcon } from '@rui/icons'

import { COLOR, FONT } from '@rui/foundations'
import { FileThumbnail } from '@rocket-atoms/icon'

import DownloadIcon from './DownloadIcon'
import { ItemBoardType, ItemFileType, ItemUserType, ItemWorkType } from '../types'

import Container from '../container'
import MetaText from './MetaText'
import MessageTypeIcon from '../Message/MessageTypeIcon'

import { faComments } from '@fortawesome/free-regular-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'

export interface ActivitySearchItemFileProps {
  work?: ItemWorkType
  board?: ItemBoardType
  user?: ItemUserType
  file?: ItemFileType
  onPress?: () => void
  onPressDownload?: () => void
}

const ActivitySearchItemFile = ({ work, board, user, file, onPress, onPressDownload }: ActivitySearchItemFileProps) => {
  const [isHover, setIsHover] = React.useState(false)

  const breadcrumsList = React.useMemo(() => {
    const list: any[] = []

    if (work !== undefined && !!work.name && !!work.type) {
      list.push({
        text: work.name,
        children: <WorkIcon type={work.type} size={16} />,
      })
    }

    if (board !== undefined && !!board.name) {
      list.push({
        text: board.name,
        children: <BoardLabel color={board.color ?? undefined} size={16} />,
      })
    }

    return list
  }, [work, board])

  const filename = useMemo(() => {
    let ext = ''
    let name = ''
    if (file) {
      const arr = file.name.split('.')
      ext = arr.pop() ?? ''
      name = arr.join('.')
    }

    return { name, ext }
  }, [file])

  if (!file)
    return (
      <View style={styles.container}>
        <View style={styles.typeArea}>
          <FileThumbnail thumbnailType={'DEFAULT'} type={'ALL'} isSkeleton={true} size={'md'} />
        </View>
        <View style={styles.contentsArea}>
          <Breadcrumbs loading={true} list={breadcrumsList} />
          <View style={styles.fileTitleArea}>
            <SkeletonBar width={'45%'} height={18} />
          </View>
          <View style={[styles.metaTextArea, { marginTop: 8 }]}>
            <SkeletonBar width={'70%'} height={8} />
          </View>
        </View>
      </View>
    )

  return (
    <Pressable
      onHoverIn={() => {
        if (onPress !== undefined) setIsHover(true)
      }}
      onHoverOut={() => setIsHover(false)}
    >
      <Container onPress={onPress} style={[styles.container, isHover && { backgroundColor: COLOR.gray.g050 }]}>
        {file.type && (
          <View style={styles.typeArea}>
            <FileThumbnail thumbnailType={'DEFAULT'} type={file.type} size={'md'} />
          </View>
        )}
        <View style={styles.contentsArea}>
          {board && (
            <>
              {board.type !== 'BOARD' ? (
                <View style={[styles.flexBox, styles.boardInfo]}>
                  <View style={styles.boardTypeIcon}>
                    {board.type === 'DIRECT' && <MessageTypeIcon icon={faUser} />}
                    {board.type === 'DISCUSS' && <MessageTypeIcon icon={faComments} />}
                  </View>

                  <View style={styles.flexBox}>
                    {!!board.name && <Text style={styles.boardText}>{board.name}</Text>}
                  </View>
                </View>
              ) : (
                <Breadcrumbs list={breadcrumsList} />
              )}
            </>
          )}
          <View style={styles.fileTitleArea}>
            <Text numberOfLines={1} ellipsizeMode="tail" style={styles.fileTitle}>
              {filename.name}
            </Text>
            <Text style={styles.fileType}>.{filename.ext.toLowerCase()}</Text>
          </View>
          <View style={styles.metaTextArea}>
            {user && <MetaText label={user.name} afterDivLine={true} />}
            <MetaText label={file.size} afterDivLine={true} />
            <MetaText label={file.date} afterDivLine={true} />
          </View>
        </View>
        <View style={styles.funcArea}>
          <DownloadIcon onPress={onPressDownload} />
        </View>
      </Container>
    </Pressable>
  )
}

export default ActivitySearchItemFile

export const styles = StyleSheet.create({
  flexBox: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    flexDirection: 'row',
    position: 'relative',
    backgroundColor: COLOR.mono.white,
    paddingHorizontal: 20,
    paddingVertical: 12,
  },
  contentsArea: {
    flex: 1,
  },
  typeArea: {
    marginRight: 8,
  },
  funcArea: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginLeft: 12,
  },
  fileTitleArea: {
    flexDirection: 'row',
    marginTop: 2,
  },
  fileTitle: {
    ...FONT.txtSmMedium,
  },
  fileType: {
    ...FONT.txtSmMedium,
  },
  metaTextArea: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTOp: 2,
  },
  boardInfo: {
    flex: 1,
  },
  boardTypeIcon: {
    marginRight: 4,
  },
  boardText: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g900,
    marginRight: 4,
  },
})
