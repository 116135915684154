import { IconStateCircle } from '@rocket-atoms/icon'
import { Text } from '@rocket-atoms/text'
import { COLOR } from '@rocket-mono/libs'
import type { CardType, TargetUserState, UserState } from '@rocket/types'
import { Avatar } from '@rui/atoms'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, Pressable, ScrollView, StyleSheet, View } from 'react-native'
import CardTypeIcon from '../CardTypeIcon'
import Input from '../Input'
import { IconNavBoardFill } from './IconNavBoardFill'

interface WhisperUser {
  id: string
  thumbnail?: string
  name: string
  state?: TargetUserState
  invisibleProfile?: boolean
  source?: CardType
}

type WhisperType = 'mention' | 'whisper' | 'cardLink'

const isMobile = Platform.OS === 'ios' || Platform.OS === 'android'

interface Props {
  type: WhisperType
  height?: number
  users: WhisperUser[]
  selectedUserId?: string
  setSelectedUserId: (id: string) => void
  onHoverInUser?: (user: WhisperUser) => void
  searchText?: string
  setSearchText?: (arg0: string) => void
}

const CardSourceIcon = ({ source }: { source?: CardType }) => {
  if (!source) return <></>
  if (source === 'ATTEND' || source === 'MEETING') return <CardTypeIcon type={source} size={20} />
  else if (source === 'MISSION') return <CardTypeIcon type={source} size={20} />
  else if (source === 'COLLECTION') return <CardTypeIcon type={source} size={20} />
  else if (source === 'TASKREPORT') return <></>
  else return <></>
}

const Whisper: React.FC<Props> = ({
  type,
  height = 230,
  users,
  selectedUserId = '',
  setSelectedUserId,
  onHoverInUser,
  searchText,
  setSearchText,
}): JSX.Element => {
  const { t } = useTranslation()
  // NOTE: 귓속말 대상과, 유저가 설정할 수 있는 유저 상태는 분리돼야 함
  // https://gitlab.wncoms.com/rocket/v2/rocket-types/-/blob/master/user.d.ts
  const convertState = React.useCallback((s: TargetUserState) => {
    switch (s) {
      case 'inactive':
        return 'invisible' as UserState
      case 'unavailable':
        return 'off' as UserState
      default:
        return s as UserState
    }
  }, [])

  const scrollViewRef = React.useRef<ScrollView>(null)
  const currentSection = true

  const selectedUserIndex = React.useMemo(() => {
    return users.findIndex((user) => user.id === selectedUserId)
  }, [users, selectedUserId])

  React.useEffect(() => {
    if (scrollViewRef.current && selectedUserIndex > -1) {
      // if selectedUserIndex > 0, and selected user item is out of view, scroll to selected user item
      const { scrollTop, scrollHeight } = scrollViewRef.current.getScrollableNode()
      const currentScrollY = scrollTop
      // platform specific
      const currentScrollHeight = scrollHeight
      const currentScrollBottom = currentScrollY + currentScrollHeight
      const selectedUserItemBottom = (selectedUserIndex + 2) * (40 + 4)
      const selectedUserItemTop = selectedUserIndex * (40 + 4)

      console.debug({
        currentScrollY,
        currentScrollHeight,
        currentScrollBottom,
        selectedUserItemBottom,
      })
      const viewportHeight = 200
      if (selectedUserItemTop < currentScrollY) {
        scrollViewRef.current.scrollTo({
          y: selectedUserIndex * styles.item.minHeight,
          animated: true,
        })
      } else if (selectedUserItemBottom > currentScrollY + viewportHeight) {
        scrollViewRef.current.scrollTo({
          y: selectedUserItemBottom - viewportHeight,
          animated: true,
        })
      }
    }
  }, [selectedUserIndex])

  return (
    <View style={[styles.container, { height }]}>
      <View style={styles.headerContainer}>
        <Text fontName="txtXs" fontColor="gray.g800">
          {t(`chatuserlist.${type}`)}
        </Text>
        {setSearchText && (
          <View style={styles.headerSearch}>
            <Input
              placeholder="카드명 혹은 태그 검색"
              value={searchText}
              height={36}
              rightIcon={
                searchText
                  ? {
                      name: 'close-circle',
                      color: 'gray.g400',
                      onPress: () => setSearchText(''),
                    }
                  : undefined
              }
              rightIconSize={16}
              rightIcon2={{ name: 'search', color: 'gray.g700' }}
              rightIconSize2={16}
              rightIconStyle2={{ marginTop: -2, marginLeft: 8 }}
              onChangeText={(text) => {
                setSearchText(text)
              }}
            />
          </View>
        )}
      </View>

      <ScrollView ref={scrollViewRef} style={styles.scrollViewHeight}>
        {/* if type === 'cardLink' && 카드가 포함된 보드탭 출력 */}
        {/* 할일을 작성중인 보드인 경우 currentSection true: line73에 임의의 변수를 생성해두었습니다.*/}
        {type === 'cardLink' && (
          <View style={styles.sectionTab}>
            <IconNavBoardFill iconSize={16} getIconColor={currentSection ? 'primary.blue500' : 'gray.g400'} />
            <Text fontName="txtXsBold" fontColor={currentSection ? 'gray.g900' : 'gray.g600'} style={styles.sectionTit}>
              BoardName00
            </Text>
          </View>
        )}
        <>
          {searchText ? (
            <View style={styles.searchBody}>
              <Text fontName="txtSm">
                &quot;{searchText}&quot; {t(`nocontentsicon.search.text`)}
              </Text>
            </View>
          ) : (
            users.map((user, index) => {
              return (
                <Pressable
                  key={`${user.id}-${index}`}
                  style={[
                    styles.item,
                    selectedUserId === user.id && {
                      backgroundColor: COLOR.mono.paleWhite,
                    },
                  ]}
                  onHoverIn={() => onHoverInUser && onHoverInUser(user)}
                  onPress={() => setSelectedUserId(user.id)}
                >
                  {!user.invisibleProfile && (
                    <View style={styles.profile}>
                      <Avatar size="small" userName={user.name} profileUrl={user.thumbnail} />
                      {!!user.state && (
                        <IconStateCircle
                          style={{
                            position: 'absolute',
                            bottom: -4,
                            right: -4,
                          }}
                          state={convertState(user.state)}
                        />
                      )}
                    </View>
                  )}
                  {type === 'cardLink' ? (
                    <View style={styles.iconContainer}>
                      <CardSourceIcon source={user.source} />
                      <View style={{ width: 4 }} />
                      {isMobile ? (
                        <Text
                          fontName="txtSmMedium"
                          fontColor="gray.g500"
                          style={{ maxWidth: 100, marginRight: 4 }}
                          numberOfLines={1}
                          ellipsizeMode="middle"
                        >
                          #{user.id}
                        </Text>
                      ) : (
                        <Text
                          fontName="txtSmMedium"
                          fontColor="gray.g500"
                          style={{ maxWidth: 100, marginRight: 4 }}
                          numberOfLines={1}
                          ellipsizeMode="middle"
                        >
                          #{user.id}
                        </Text>
                      )}
                    </View>
                  ) : (
                    <></>
                  )}
                  <View style={{ flex: 1 }}>
                    {isMobile ? (
                      <Text fontName="txtMdMedium" fontColor="gray.g900" numberOfLines={1}>
                        {user.name}
                      </Text>
                    ) : (
                      <Text fontName="txtMdMedium" fontColor="gray.g900" numberOfLines={1}>
                        {user.name}
                      </Text>
                    )}
                  </View>
                </Pressable>
              )
            })
          )}
        </>
      </ScrollView>
    </View>
  )
}

export default Whisper

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 380,
    backgroundColor: COLOR.mono.white,
    shadowColor: '#000',
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 8,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
  },
  headerContainer: {
    justifyContent: 'center',
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderBottomWidth: 1,
    borderBottomColor: COLOR.gray.g150,
  },
  headerSearch: {
    marginTop: 8,
  },
  // topContainer: {
  //   height: 44,
  //   justifyContent: 'center',
  //   paddingHorizontal: 6,
  //   marginBottom: 8,
  //   borderBottomWidth: 1,
  //   borderBottomColor: COLOR.mono.pale,
  // },
  sectionTab: {
    flexDirection: 'row',
    paddingHorizontal: 12,
    paddingTop: 8,
    paddingBottom: 4,
  },
  sectionTit: {
    marginLeft: 4,
  },
  scrollViewHeight: {
    flex: 1,
  },
  item: {
    minHeight: 40,
    paddingVertical: 8,
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  profile: {
    marginRight: 4,
  },
  icon: {
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 99,
    padding: 4,
    marginRight: 4,
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchBody: {
    padding: 12,
  },
})
