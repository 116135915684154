import React from 'react'
import { StyleSheet, View } from 'react-native'
import { WorkIcon } from '@rocket-atoms/icon'
import { COLOR, FONT } from '@rui/foundations'
import { Text } from '@rocket-atoms/text'

interface Props {
  type: string
  label: string
}
const WorkLabel = ({ type, label }: Props) => {
  return (
    <View
      style={[
        styles.wrap,
        type === 'DSP' && { backgroundColor: 'rgba(0, 27, 87, 0.1)' },
        type === 'WFP' && { backgroundColor: 'rgba(248, 91, 95, 0.1)' },
        type === 'CSP' && { backgroundColor: 'rgba(0, 8, 178, 0.1)' },
        type === 'TOP' && { backgroundColor: 'rgba(98, 88, 186, 0.1)' },
      ]}
    >
      <View style={styles.iconArea}>
        <WorkIcon type={type} size={16} />
      </View>
      <Text
        style={[
          styles.text,
          type === 'DSP' && { color: '#001441' },
          type === 'WFP' && { color: '#37090A' },
          type === 'CSP' && { color: '#1E1A43' },
          type === 'TOP' && { color: '#012549' },
        ]}
      >
        {label}
      </Text>
    </View>
  )
}

export default WorkLabel

const styles = StyleSheet.create({
  wrap: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 2,
    paddingRight: 4,
    borderRadius: 4,
  },
  iconArea: {
    marginRight: 4,
  },
  text: {
    ...FONT.txt2XsMedium,
  },
})
