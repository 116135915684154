import * as React from 'react'
import { ColorValue } from 'react-native'

import { Path, Svg, SvgProps } from 'react-native-svg'
import { IColors, IconName, getIconPath, getRealColor } from '@rocket-mono/libs'

export interface Props extends Omit<SvgProps, 'color' | 'width' | 'height'> {
  color?: IColors
  realColor?: ColorValue
  size?: number | string
  name: IconName
}

const XEIcon = React.forwardRef<Svg, Props>(
  ({ name, color = 'mono.black', size = 20, style, viewBox = '0 0 1000 1000', ...props }, ref): JSX.Element => {
    // glyph 는 path가 거꾸로 작성되었기 때문에 180 도를 회전하여 표시해야 합니다.
    return (
      <Svg
        width={size}
        height={size}
        viewBox={viewBox}
        style={[style, { transform: [{ rotateX: '180deg' }] }]}
        accessibilityRole="image"
        ref={ref}
        color={props.realColor ? props.realColor : getRealColor(color)}
        {...props}
      >
        <Path fill="currentColor" d={getIconPath(name)} />
      </Svg>
    )
  },
)

export default XEIcon
