import React, { useCallback, useEffect, useState } from 'react'

import {
  WorkAssigneeProvider,
  WorkFileProvider,
  useAstro,
  useCurrentUser,
  useSubscription,
  useWorkCard,
  useWorkChannel,
} from '@rocket-mono/providers'
import { ToastProvider } from '@rui/atoms'
import Providers from '../../providers'

import { i18n } from 'i18next'

interface Props {
  i18n: i18n
  todoId?: string
  onUnAuthorized?: () => void
  children?: JSX.Element | JSX.Element[]
}
const Provider: React.FC<Props> = ({ i18n, todoId, onUnAuthorized, children }) => {
  const [visible, setVisible] = useState(false)
  const { currentChannel, channelId } = useWorkChannel()
  const { cardId } = useWorkCard()
  const { currentUser } = useCurrentUser()

  useEffect(() => {
    console.debug('CardViewScreen-useEffect-provider', { channelId, cardId })
  }, [channelId, cardId])

  // const SkeletonComponent = React.useMemo(() => {
  //   if (Platform.OS === 'web') {
  //     if (location.pathname.endsWith('/board')) {
  //       return <SkeletonWorkCardNewTabView isModal />
  //     } else {
  //       return <Skeleton path={location.pathname} />
  //     }
  //   } else {
  //     return <SkeletonWorkCardNewTabView />
  //   }
  // }, [])

  const { astro } = useAstro()

  const authority = useCallback(() => {
    // console.log('permissionView', { isAnonymous, currentCard, currentTodo })
    if (cardId && todoId === undefined) {
      astro
        .readCardAuthority(cardId)
        .then((card) => {
          console.log('permissionView', todoId, card.cardShare)
          setVisible(true)
        })
        .catch((err) => {
          console.log('permissionView', todoId, err)
          onUnAuthorized?.()
        })
    } else {
      setVisible(true)
    }
  }, [cardId, todoId, onUnAuthorized])

  useEffect(() => {
    authority()
  }, [])

  useSubscription([`/subscribe/card/${cardId}/update/`], () => {
    authority()
  })

  if (visible === false) return <></>

  return (
    <Providers i18n={i18n}>
      <ToastProvider>
        <WorkAssigneeProvider domain="card" domainId={cardId}>
          <WorkFileProvider
            domain="card"
            domainId={cardId}
            workId={Number(currentChannel?.projectId)}
            boardRoomId={currentChannel?.roomId || ''}
            userId={currentUser.id}
          >
            <>{children}</>
          </WorkFileProvider>
        </WorkAssigneeProvider>
      </ToastProvider>
    </Providers>
  )
}

export default Provider
