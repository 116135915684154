import React, { useEffect, useRef, useState } from 'react'
import { StyleSheet, Pressable, Animated, Platform, View } from 'react-native'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { FAIcon } from '@rocket/atoms'
import { COLOR } from '@rocket-mono/libs'
interface Props {
  theme?: 'dark' | 'light'
  isFold: boolean
  setIsFold: (is: boolean) => void
}

const AngleIconButton: React.FC<Props> = ({ theme, isFold, setIsFold }) => {
  const [isHover, setIsHover] = useState(false)
  const colorAnim = useRef(new Animated.Value(0)).current

  useEffect(() => {
    handleAnimation()
  }, [theme])

  const handleAnimation = () => {
    if (theme === 'dark') {
      Animated.timing(colorAnim, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: false,
      }).start()
    } else {
      Animated.timing(colorAnim, {
        toValue: 0,
        duration: 1000,
        useNativeDriver: false,
      }).start()
    }
  }

  const colorChange = colorAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [`rgba(246,248,249, 1)`, `rgba(0, 0, 0, .4)`],
  })
  return (
    <Pressable
      onPress={() => setIsFold(!isFold)}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
    >
      <Animated.View
        style={[
          styles.buttonContainer,
          { backgroundColor: colorChange },
          isHover && { backgroundColor: COLOR.gray.g100 },
          theme === 'dark' && isHover && { backgroundColor: COLOR.opacity.blk60 },
        ]}
      >
        <View style={ styles.iconWrap}>
          <FAIcon iconName={isFold ? faCaretUp : faCaretDown} size={'xs'} color={'gray.g600'} />
        </View>
      </Animated.View>
    </Pressable>
  )
}

export default AngleIconButton

const styles = StyleSheet.create({
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    borderRadius: 4,
    backgroundColor: COLOR.gray.g050,
  },
  iconWrap: {
    ...Platform.select({
      native: { 
        marginTop:-2,
        marginLeft:-2
      }
     })
  },
})
