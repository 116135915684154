import { Icon } from '@rocket-atoms/icon'
import { COLOR } from '@rocket-mono/libs'
import React from 'react'
import { Pressable, StyleSheet } from 'react-native'

interface Props {
  active: boolean
  onPress?: () => void
}

const SubmitButton: React.FC<Props> = ({ active, onPress }) => {
  return (
    <Pressable style={[styles.buttonContainer, active && { backgroundColor: COLOR.primary.blue500 }]} onPress={onPress}>
      <Icon name={'plus'} size={16} color={active ? 'mono.white' : 'gray.g300'} style={{ marginTop: -2 }} />
    </Pressable>
  )
}

export default SubmitButton

const styles = StyleSheet.create({
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 28,
    borderRadius: 50,
    backgroundColor: COLOR.gray.g050,
  },
})
