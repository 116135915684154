import React from 'react'
import {
  Platform,
  Pressable,
  TextInputProps as RNTextInputProps,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native'

import type { IColors } from '@rocket-mono/libs'

import { Icon } from '@rocket-atoms/icon'
import { Button } from '@rocket-mono/foundations'
import { COLOR, FONT, cutByteString, getByteLength, getRealColor } from '@rocket-mono/libs'
import type { IButton, IIcon } from './types'

export interface InputBaseProps extends Omit<RNTextInputProps, 'placeholderTextColor'> {
  height?: number
  backgroundColor?: IColors
  borderColor?: IColors
  borderType?: 'solid' | 'underline' | 'round' | 'group' | 'none'
  color?: IColors
  placeholderTextColor?: IColors
  button?: IButton
  leftIcon?: IIcon
  leftIconStyle?: ViewStyle
  leftIconSize?: number
  rightIcon?: IIcon
  rightIconStyle?: ViewStyle
  rightIconSize?: number
  rightIcon2?: IIcon
  rightIconStyle2?: ViewStyle
  rightIconSize2?: number
  maxByteLength?: number
  errorMessage?: string | boolean

  // 이벤트는 unkonwn으로 넘기고, 플랫폼 별로 캐스팅해서 사용
  onKeyPress?: (e: unknown) => void
  onKeyUp?: (e: unknown) => void
  onKeyDown?: (e: unknown) => void
  onBlur?: (e: unknown) => void
  onInput?: (e: unknown) => void
  onCompositionUpdate?: (e: unknown) => void
  onCompositionEnd?: (e: unknown) => void
  onChangeText?: (text: string) => void
}

class InputBase<T extends InputBaseProps> extends React.Component<T> {
  constructor(props: T) {
    super(props)
  }

  renderInputComponent(props: T) {
    return <Text {...props}>InputBase is abstract component</Text>
  }

  render() {
    const {
      multiline,
      borderColor = 'gray.g200',
      backgroundColor = 'mono.white',
      borderType = 'solid',
      editable = true,
      height = 36,
      button,
      leftIcon,
      leftIconStyle,
      leftIconSize,
      rightIcon,
      rightIconStyle,
      rightIconSize,
      rightIcon2,
      rightIconStyle2,
      rightIconSize2,
      maxByteLength = 0,
      errorMessage,
      value,
    } = this.props
    // ref?: React.LegacyRef<TextInput>
    const dynamicStyles = StyleSheet.create({
      wrap: {
        flexDirection: 'row',
        flex: 1,
        alignItems: multiline ? 'flex-end' : 'center',
        paddingRight: 10,
        borderColor: errorMessage ? getRealColor('status.busy') : getRealColor(borderColor),
        backgroundColor: getRealColor(editable ? backgroundColor : 'mono.lightGray'),
        ...Platform.select({
          // web: { height: 'fit-content' },
          native: { height: height },
        }),
      },
      roundBorder: {
        borderRadius: height,
        borderWidth: 1,
      },
      groupBorder: {
        borderWidth: 1,
        borderLeftWidth: 0,
        borderRadius: 4,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    })

    const onChange = (text: string) => {
      if (this.props.onChangeText)
        this.props.onChangeText(maxByteLength > 0 ? cutByteString(text, maxByteLength) : text)
    }

    return (
      <>
        <View style={{ ...Platform.select({ native: { height } }) }}>
          <View style={[styles.container]}>
            <View
              style={[
                dynamicStyles.wrap,
                borderType === 'solid'
                  ? styles.solidBorder
                  : borderType === 'underline'
                  ? styles.underlineBorder
                  : borderType === 'round'
                  ? dynamicStyles.roundBorder
                  : borderType === 'group'
                  ? dynamicStyles.groupBorder
                  : undefined,
              ]}
            >
              {leftIcon &&
                (leftIcon.onPress !== undefined ? (
                  <Pressable onPress={leftIcon.onPress} style={leftIconStyle}>
                    <Icon
                      name={leftIcon.name}
                      color={leftIcon.color}
                      size={leftIconSize ? leftIconSize : FONT.textRegular.fontSize}
                    />
                  </Pressable>
                ) : (
                  <Icon
                    name={leftIcon.name}
                    color={leftIcon.color}
                    size={leftIconSize ? leftIconSize : FONT.textRegular.fontSize}
                    style={leftIconStyle}
                  />
                ))}

              <View style={{ flex: 1 }}>
                {this.renderInputComponent({
                  ...this.props,
                  onChangeText: onChange,
                })}
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {rightIcon &&
                  (rightIcon.onPress !== undefined ? (
                    <Pressable onPress={rightIcon.onPress} style={rightIconStyle}>
                      <Icon
                        name={rightIcon.name}
                        color={rightIcon.color}
                        size={rightIconSize ? rightIconSize : FONT.textRegular.fontSize}
                        style={{ marginTop: -2 }}
                      />
                    </Pressable>
                  ) : (
                    <Icon
                      name={rightIcon.name}
                      color={rightIcon.color}
                      size={rightIconSize ? rightIconSize : FONT.textRegular.fontSize}
                      style={rightIconStyle}
                    />
                  ))}
                {rightIcon2 &&
                  (rightIcon2.onPress !== undefined ? (
                    <Pressable onPress={rightIcon2.onPress} style={rightIconStyle2}>
                      <Icon
                        name={rightIcon2.name}
                        color={rightIcon2.color}
                        size={rightIconSize2 ? rightIconSize2 : FONT.textRegular.fontSize}
                      />
                    </Pressable>
                  ) : (
                    <Icon
                      name={rightIcon2.name}
                      color={rightIcon2.color}
                      size={rightIconSize2 ? rightIconSize2 : FONT.textRegular.fontSize}
                      style={rightIconStyle2}
                    />
                  ))}
              </View>
            </View>
            {button && (
              <Button
                style={[styles.button, button.style]}
                color={button.textColor}
                borderColor={button.borderColor}
                backgroundColor={button.buttonColor}
                onPress={button.onPress}
              >
                {button.icon && <Icon name={button.icon} size={16} color={button.textColor} />}
                {button.text !== undefined && (
                  <Text style={[{ color: getRealColor(button.textColor) }, !!button.text && { marginLeft: 4 }]}>
                    {button.text}
                  </Text>
                )}
              </Button>
            )}
          </View>
          {maxByteLength > 0 && (
            <View style={[styles.alignFlexEnd]}>
              <Text style={[FONT.subTextRegular, { color: COLOR.mono.paleBlack }]}>{`( ${getByteLength(
                value || '',
              )} / ${maxByteLength} )`}</Text>
            </View>
          )}
        </View>
        <View>
          {!!errorMessage && (
            <View style={styles.errText}>
              <Icon name={'info'} size={16} color={'status.busy'} />
              <Text style={{ ...FONT.txtXsMedium, color: COLOR.status.busy }}>{errorMessage}</Text>
            </View>
          )}
        </View>
      </>
    )
  }
}

export default InputBase

const styles = StyleSheet.create({
  container: {
    flex: 1,
    ...Platform.select({
      // web: { height: 'fit-content' },
    }),
    flexDirection: 'row',
  },
  button: {
    flexDirection: 'row',
    height: '100%',
    marginLeft: 4,
    paddingHorizontal: 8,
  },
  solidBorder: {
    borderRadius: 6,
    borderWidth: 1,
  },
  underlineBorder: {
    borderBottomWidth: 1,
  },
  alignFlexEnd: { alignItems: 'flex-end' },
  errText: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
  },
})
