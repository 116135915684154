import React, { useEffect, useMemo, useState } from 'react'
import { MenuScreen } from '@rocket-mono/screen-tableorder'
import { useParams, unstable_useBlocker as useBlocker } from 'react-router-dom'
import { useAstro } from '@rocket-mono/providers'
import { Helmet } from 'react-helmet-async'

const TableorderMenu: React.FC = () => {
  const [isBlock, setIsBlock] = useState(true)
  const blocker = useBlocker(isBlock)
  const blocked = useMemo(() => (blocker.state === 'blocked' ? true : false), [blocker])

  const { projectId } = useParams()

  const { astro } = useAstro()

  const [shopId, setShopId] = useState<string>()
  console.log('shopId', shopId)

  useEffect(() => {
    if (projectId)
      astro
        .readShopByProjectId(projectId)
        .then(({ id }) => {
          setShopId(id)
        })
        .catch((err) => {
          console.error(err)
        })
  }, [projectId])

  if (shopId === undefined) return <></>
  return (
    <>
      <Helmet>
        <title>베어페이</title>
        <meta name="description" content="APP설치없이 주문하는 초간단 주문시스템" />
        <meta property="og:title" content="베어페이" />
        <meta property="og:description" content="APP설치없이 주문하는 초간단 주문시스템" />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="400" />
        <meta property="og:image" content="https://work.rocket.is/assets/search-card.png" />
        <meta property="og:site_name" content="베어페이" />
      </Helmet>
      <MenuScreen
        shopId={shopId}
        blocked={blocked}
        onResetBlock={blocker.reset}
        onProceedBlock={blocker.proceed}
        onChangeBlock={setIsBlock}
      />
    </>
  )
}

export default TableorderMenu
