import { faEllipsis, faIndent, faWindowRestore, faXmark } from '@fortawesome/free-solid-svg-icons'
import { IconButton } from '@rocket-atoms/icon'
import { Text } from '@rocket-atoms/text'
import { Tooltip } from '@rocket-atoms/tooltip'
import { Button, View } from '@rocket-mono/foundations'
import { COLOR, FONT, getRealColor, useMobileView } from '@rocket-mono/libs'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { WorkCardEditHeaderType } from './types'

const WorkCardEditHeader = ({
  window,
  projectType,
  projectName,
  channelName,
  channelColor,
  onPress,
  onPressClose,
  onPressHide,
  onPressWindow,
  onPressEtc,
}: WorkCardEditHeaderType) => {
  const { t } = useTranslation()
  const mobileView = useMobileView(960)

  const isAgent = React.useMemo(() => navigator.userAgent === 'RNWorkWebView', [navigator])

  const handlePressClose = useCallback(() => {
    onPressClose && onPressClose()

    if (isAgent) {
      // @ts-ignore
      window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'close' }))
    }
  }, [isAgent, onPressClose])

  return (
    <View style={[styles.container, mobileView && { alignItems: 'flex-start' }]}>
      <View style={[styles.flexbox, { flex: 1 }]}>
        {!window && <IconButton iconName={faIndent} iconSize={'lg'} iconColor={'gray.g400'} onPress={onPressHide} />}
        <View style={[styles.flexbox, { flex: 1, marginLeft: 4 }]}>
          {/* <Tooltip gab={10} darkType={true} direction={'bottom'} text={projectName}>
            {(props) => (
              <View {...props}>
                <WorkIcon type={projectType} size={20} />
              </View>
            )}
          </Tooltip> */}
          {/* <Icon
            name={'angle-right'}
            size={12}
            color={'gray.g300'}
            style={{
              ...Platform.select({
                web: {
                  paddingHorizontal: 4,
                },
                native: {
                  paddingHorizontal: 12,
                },
              }),
            }}
          /> */}
          <View style={[styles.flexbox, styles.boardTitleArea]}>
            {channelColor !== undefined && channelColor !== null && (
              <View style={[styles.boardLabel, { backgroundColor: getRealColor(channelColor) }]} />
            )}
            <View style={{ flex: 1 }}>
              <Tooltip gab={0} darkType={true} direction={'bottom'} text={channelName}>
                {(props) => (
                  <View {...props}>
                    <Text fontName="txtMdBold" numberOfLines={1} ellipsizeMode="tail">
                      {channelName}
                    </Text>
                  </View>
                )}
              </Tooltip>
            </View>
          </View>
        </View>
      </View>
      <View style={[styles.flexbox, styles.controlArea, mobileView && styles.controlMobileArea]}>
        <View style={styles.flexbox}>
          <View style={[styles.controlButton]}>
            <Button size="small" onPress={onPress} style={{ paddingHorizontal: 4 }}>
              <Text fontName="txtSm" fontColor="mono.white">
                {t('screen.workmodelupdate.dialog.save')}
              </Text>
            </Button>
          </View>
          {onPressEtc && (
            <Tooltip
              text={t('explainworkmodel.more')}
              textStyle={{ ...FONT.txtSmMedium }}
              direction="bottom-center"
              onPressTarget={onPressEtc}
            >
              {(props) => (
                <View {...props} style={styles.controlButton}>
                  <IconButton iconName={faEllipsis} iconSize={'sm'} iconColor={'gray.g450'} onPress={onPressEtc} />
                </View>
              )}
            </Tooltip>
          )}
          {!window && (
            <Tooltip
              text={t('card.tooltip.window')}
              textStyle={{ ...FONT.txtSmMedium }}
              direction="bottom-center"
              onPressTarget={onPressWindow}
            >
              {(props) => (
                <View {...props} style={styles.controlButton}>
                  <IconButton
                    iconName={faWindowRestore}
                    iconSize={'sm'}
                    iconColor={'gray.g450'}
                    onPress={onPressWindow}
                  />
                </View>
              )}
            </Tooltip>
          )}

          <View style={styles.controlButton}>
            <IconButton iconName={faXmark} iconSize={'sm'} iconColor={'gray.g450'} onPress={handlePressClose} />
          </View>
        </View>
        {/* {viewUser && (
          <View
            style={[
              styles.avatarArea,
              mobileView && { marginRight: 0 },
              !window ? mobileView && { marginTop: 8 } : mobileView && { marginTop: 12 },
            ]}
          >
            <AvatarGroup list={viewUser} />
          </View>
        )} */}
      </View>
    </View>
  )
}

export default WorkCardEditHeader

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g150,
  },
  flexbox: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  controlArea: {
    flexDirection: 'row-reverse',
  },
  controlMobileArea: {
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  controlButton: {
    marginLeft: 4,
  },
  boardTitleArea: {
    flex: 1,
    justifyContent: 'flex-start',
    paddingRight: 20,
  },
  boardLabel: {
    width: 20,
    height: 20,
    borderRadius: 4,
    marginRight: 4,
  },
  avatarArea: {
    marginRight: 8,
  },
})
