import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'

import { CardRowGroup } from '../CardRowGroup'
import { useAstro, useWorkAssignee, useWorkChannel } from '@rocket-mono/providers'
import CardAssigneeView from '../CardAssigneeView'
import { AssigneeIdType } from '@rocket-mono/providers'

interface Props {
  readonly?: boolean
  assigneeIds?: AssigneeIdType[]
  onChangeAssigneeIds?: (o: AssigneeIdType[]) => void
}
const CardAssignee: React.FC<Props> = ({ ...viewProps }) => {
  const { t } = useTranslation()
  const { option } = useAstro()
  const { currentChannelMembers } = useWorkChannel()
  const { assigneeList } = useWorkAssignee()

  const members = useMemo(() => {
    return currentChannelMembers.map(({ userId: id, name, email }) => {
      const uri = option.secureCdnUrl + '/profile/' + email
      const selected =
        viewProps.assigneeIds !== undefined
          ? viewProps.assigneeIds.filter(({ userId }) => String(userId) === String(id)).length > 0
          : assigneeList.map(({ userId }) => userId).filter((o) => o === Number(id)).length > 0
      return { id: String(id), name: name, uri, selected }
    })
  }, [currentChannelMembers, assigneeList, viewProps.assigneeIds])

  const filteredMembers = useMemo(() => members.filter(({ selected }) => selected), [members])

  return (
    <CardRowGroup
      title={t('peopleincharge.incharge')}
      count={filteredMembers.length > 0 ? filteredMembers.length : 0}
      iconName="user-o"
      iconContainerStyle={{
        alignItems: 'stretch',
        flexDirection: 'row',
        marginTop: 8,
      }}
      marginTop={12}
    >
      <CardAssigneeView {...viewProps} />
    </CardRowGroup>
  )
}

export default CardAssignee

const styles = StyleSheet.create({
  container: {
    marginRight: 4,
    marginVertical: 2,
    height: 28,
  },
  inputStyle: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 36,
    borderRadius: 4,
  },
  inputValueStyle: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    padding: 4,
  },
  inputPlaceHolderStyle: {
    height: 36,
    lineHeight: 36,
    paddingHorizontal: 12,
  },
})
