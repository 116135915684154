import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { authTokenState } from '../../recoils'

import { WorkSettingScreen } from '@rocket-mono/screen-work'
import { useTranslation } from 'react-i18next'

const Settings: React.FC = () => {
  const navigate = useNavigate()
  const { i18n } = useTranslation()

  const setAuthToken = useSetRecoilState(authTokenState)

  const handlePressBack = useCallback(() => {
    console.log('handlePressBack')
    navigate(-1)
  }, [])

  const handleLogout = useCallback(() => {
    console.log('handleLogout')
    localStorage.clear()
    sessionStorage.clear()
    setAuthToken('')
    location.href = '/'
  }, [])

  return <WorkSettingScreen i18n={i18n} onPressBack={handlePressBack} onLogout={handleLogout} />
}

export default Settings
