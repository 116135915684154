import React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { FAIcon } from '@rocket-atoms/icon'
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { COLOR, FONT, IColors, getRealColor } from '@rocket-mono/libs'
import { Checkbox } from '@rocket-atoms/checkbox'

interface PopsListSectionTabsProps {
  label: string
  itemCount: number
  countTextColor?: IColors
  children?: React.ReactNode
  divStyle?: boolean
  allChecked?: boolean
  onPressAllCheck?: () => void
}
const PopListSectionTabs = ({
  label,
  itemCount,
  countTextColor = 'gray.g450',
  children,
  divStyle,
  allChecked,
  onPressAllCheck,
}: PopsListSectionTabsProps) => {
  const [isShow, setIsShow] = React.useState(true)
  const [isHover, setIsHover] = React.useState(true)
  return (
      <View style={styles.wrap}>
        <View
          style={[
            styles.flexbox,
            styles.container,
            divStyle && styles.divSection,
          ]}
        >
        <Pressable onPress={onPressAllCheck} style={{ marginRight: 4 }}>
          <Checkbox type={'DEFAULT'} checked={allChecked} />
        </Pressable>
        <Pressable
          style={[{ flex: 1 }, styles.flexbox]}
          onPress={() => setIsShow(!isShow)}
          onHoverIn={() => setIsHover(true)}
          onHoverOut={() => setIsHover(false)}
        >
          <View style={styles.nameArea}>
            <Text style={styles.sectionTitle}>{label}</Text>
            <Text
              style={[styles.itemCount, { color: getRealColor(countTextColor) }]}
            >
              {itemCount}
            </Text>
          </View>
          <FAIcon iconName={isShow ? faAngleUp : faAngleDown } size={'2xs'} color={isHover ? 'gray.g300' : 'gray.g450'}/>
        </Pressable>
      </View>
      {isShow && <View>{children}</View>}
    </View>
  )
}

export default PopListSectionTabs

export const styles = StyleSheet.create({
  wrap: {
    position: 'relative',
  },
  flexbox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  container: {
    justifyContent: 'flex-start',
    padding: 8,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g200,
  },
  divSection: {
    borderTopWidth: 4,
    borderColor: COLOR.gray.g150,
  },
  nameArea: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  sectionTitle: {
    ...FONT.txt2Xs,
  },
  itemCount: {
    ...FONT.txt2Xs,
    marginLeft: 4,
    color: COLOR.main.red,
  },
})