import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { TFunction } from 'i18next'
import { debounce } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, Modal, Platform, Pressable, StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native'

import { Text } from '@rocket/atoms'
import { getFontStyle, getRealColor } from '@rocket-mono/libs'

import SwitchLabel from '../Switch/SwitchLabel'
import { DateScaleType, Props, SelectedDate, TimePosition, TimeType } from './types'

const CALENDAR_DATE_HEIGHT = 32
const CALENDAR_DATE_WIDTH = 32

const CALENDAR_MONTH_HEIGHT = 37

const getDatePretty = (t: TFunction, date: SelectedDate) => {
  if (!date) {
    return ''
  }
  return `${t('format.date.L', { date })}`
}

const initialDate = (date?: string) => {
  if (!date) {
    return null
  }
  const $date = new Date(date)
  $date.setHours(0, 0, 0, 0)
  return $date
}

const pad = (n: number, size = 2) => {
  return String(n).padStart(size, '0')
}

const getInitialTime = (date?: string) => {
  if (!date) {
    return {
      hour: '12',
      minute: '00',
    }
  }
  const timeSplit = date.split(':')
  if (timeSplit.length !== 2) {
    return {
      hour: '12',
      minute: '00',
    }
  }
  return {
    hour: timeSplit[0],
    minute: timeSplit[1],
  }
}

const initialCurrentYear = (startDate?: string, minDate?: string, maxDate?: string, scale?: DateScaleType) => {
  const $date = startDate ? new Date(startDate) : new Date()
  $date.setHours(0, 0, 0, 0)

  if (scale === 'start') {
    if (maxDate) {
      const max = new Date(maxDate)
      max.setHours(0, 0, 0, 0)
      if ($date > max) {
        return max.getFullYear()
      } else {
        return $date.getFullYear()
      }
    } else {
      return $date.getFullYear()
    }
  } else if (scale === 'end') {
    if (minDate) {
      const min = new Date(minDate)
      min.setHours(0, 0, 0, 0)
      if ($date < min) {
        return min.getFullYear()
      } else {
        return $date.getFullYear()
      }
    } else {
      return $date.getFullYear()
    }
  } else {
    $date.setHours(0, 0, 0, 0)
    return $date.getFullYear()
  }
}

const initialCurrentMonth = (startDate?: string, minDate?: string, maxDate?: string, scale?: DateScaleType) => {
  const $date = startDate ? new Date(startDate) : new Date()
  $date.setHours(0, 0, 0, 0)

  if (scale === 'start') {
    if (maxDate) {
      const max = new Date(maxDate)
      max.setHours(0, 0, 0, 0)
      if ($date > max) {
        return max.getMonth()
      } else {
        return $date.getMonth()
      }
    } else {
      return $date.getMonth()
    }
  } else if (scale === 'end') {
    if (minDate) {
      const min = new Date(minDate)
      min.setHours(0, 0, 0, 0)
      if ($date < min) {
        return min.getMonth()
      } else {
        return $date.getMonth()
      }
    } else {
      return $date.getMonth()
    }
  } else {
    $date.setHours(0, 0, 0, 0)
    return $date.getMonth()
  }
}

const DateRangePickerV2 = ({
  type = 'range',
  scale,
  min = '1970-01-01',
  max = '2038-01-18',
  onChangeStart,
  onChangeEnd,
  onChangeTime,
  onChangeIsTime,
  isDisplay = true,
  initialStart,
  initialEnd,
  initialTime,
  initialIsTime = false,
}: Props) => {
  const { t } = useTranslation()
  const [currentYear, setCurrentYear] = React.useState(initialCurrentYear(initialStart, min, max, scale))
  const [currentMonth, setCurrentMonth] = React.useState(initialCurrentMonth(initialStart, min, max, scale))
  const [selectedStartDate, setSelectedStartDate] = React.useState<SelectedDate>(initialDate(initialStart))
  const [selectedEndDate, setSelectedEndDate] = React.useState<SelectedDate>(initialDate(initialEnd))
  const [isMonth, setIsMonth] = React.useState(false)
  const [currentHoverMonth, setCurrentHoverMonth] = React.useState<number>(-1)

  const timePickerButtonRef = React.useRef<View>(null)
  const [time, setTime] = React.useState<TimeType>(getInitialTime(initialTime))
  const [isTime, setIsTime] = React.useState(initialIsTime)
  const [timePickerPosition, setTimePickerPosition] = React.useState<TimePosition>()
  const [timePickerHover, setTimePickerHover] = React.useState<string>()
  const hourFlatListRef = React.useRef<FlatList<string>>(null)
  const minuteFlatListRef = React.useRef<FlatList<string>>(null)

  const handlePrevYear = () => {
    setCurrentYear((prevYear) => prevYear - 1)
  }

  const handleNextYear = () => {
    setCurrentYear((prevYear) => prevYear + 1)
  }

  const handlePrevMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11)
      setCurrentYear((prevYear) => prevYear - 1)
    } else {
      setCurrentMonth((prevMonth) => prevMonth - 1)
    }
  }

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0)
      setCurrentYear((prevYear) => prevYear + 1)
    } else {
      setCurrentMonth((prevMonth) => prevMonth + 1)
    }
  }

  const handleDatePress = (date: Date) => {
    if (type === 'date') {
      setSelectedStartDate(date)
      onChangeStart && onChangeStart(date)
    } else {
      if (!selectedStartDate || (selectedStartDate && selectedEndDate)) {
        setSelectedStartDate(date)
        setSelectedEndDate(null)
        onChangeStart && onChangeStart(date)
        onChangeEnd && onChangeEnd(null)
      } else if (selectedStartDate && !selectedEndDate) {
        if (date >= selectedStartDate) {
          setSelectedEndDate(date)
          onChangeEnd && onChangeEnd(date)
        } else {
          const temp = selectedStartDate
          setSelectedStartDate(date)
          setSelectedEndDate(temp)
          onChangeStart && onChangeStart(date)
          // onChangeEnd && onChangeEnd(temp)
        }
      }
    }
  }

  const handleIsTime = (isTime: boolean) => {
    setIsTime(isTime)
    onChangeIsTime && onChangeIsTime(isTime)
  }

  const isDateDisabled = (date: Date) => {
    const minDate = new Date(min)
    const maxDate = new Date(max)
    return date < minDate || date > maxDate
  }

  const getMonthYearString = (year: number, month: number) => {
    const monthNames = [
      t('daterangepicker.monthNames.january'),
      t('daterangepicker.monthNames.february'),
      t('daterangepicker.monthNames.march'),
      t('daterangepicker.monthNames.april'),
      t('daterangepicker.monthNames.may'),
      t('daterangepicker.monthNames.june'),
      t('daterangepicker.monthNames.july'),
      t('daterangepicker.monthNames.august'),
      t('daterangepicker.monthNames.september'),
      t('daterangepicker.monthNames.october'),
      t('daterangepicker.monthNames.november'),
      t('daterangepicker.monthNames.december'),
    ]
    return `${t('daterangepicker.header', {
      year,
      month: monthNames[month],
    })}`
  }

  const renderHeader = () => {
    return (
      <View style={styles.headerContainer}>
        <Pressable onPress={handlePrevYear}>
          <FontAwesomeIcon
            icon={faAnglesLeft}
            size={15}
            color={getRealColor('gray.g700')}
            style={styles.headerButton}
          />
        </Pressable>
        <Pressable onPress={handlePrevMonth}>
          <FontAwesomeIcon icon={faAngleLeft} size={15} color={getRealColor('gray.g700')} style={styles.headerButton} />
        </Pressable>
        <Pressable onPress={() => setIsMonth(!isMonth)}>
          <Text fontName="txtSmMedium" fontColor="mono.black">
            {getMonthYearString(currentYear, currentMonth)}
          </Text>
        </Pressable>
        <Pressable onPress={handleNextMonth}>
          <FontAwesomeIcon
            icon={faAngleRight}
            size={15}
            color={getRealColor('gray.g700')}
            style={styles.headerButton}
          />
        </Pressable>
        <Pressable onPress={handleNextYear}>
          <FontAwesomeIcon
            icon={faAnglesRight}
            size={15}
            color={getRealColor('gray.g700')}
            style={styles.headerButton}
          />
        </Pressable>
      </View>
    )
  }

  const renderDaysOfWeek = () => {
    const daysOfWeek = [
      t('daterangepicker.daysOfWeek.su'),
      t('daterangepicker.daysOfWeek.mo'),
      t('daterangepicker.daysOfWeek.tu'),
      t('daterangepicker.daysOfWeek.we'),
      t('daterangepicker.daysOfWeek.th'),
      t('daterangepicker.daysOfWeek.fr'),
      t('daterangepicker.daysOfWeek.sa'),
    ]
    return (
      <View style={styles.daysOfWeekContainer}>
        {daysOfWeek.map((day, index) => (
          <View key={index} style={styles.dayOfWeek}>
            <Text fontName="txt2XsMedium" fontColor="gray.g450">
              {day}
            </Text>
          </View>
        ))}
      </View>
    )
  }

  const renderCalendar = () => {
    const startDateOfMonth = new Date(currentYear, currentMonth, 1)
    const endDateOfMonth = new Date(currentYear, currentMonth + 1, 0)
    const totalDays = endDateOfMonth.getDate()
    const startOffset = startDateOfMonth.getDay()

    const dates: any[] = []

    let day = 1

    for (let i = 0; i < 6; i++) {
      const week: any[] = []
      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < startOffset) {
          const previousMonthEndDate = new Date(currentYear, currentMonth, 0).getDate()
          const previousMonthStartDate = previousMonthEndDate - (startOffset - j - 1)
          const previousMonthDate = new Date(currentYear, currentMonth - 1, previousMonthStartDate)
          week.push({
            date: previousMonthDate,
            isPreviousMonth: true,
            isNextMonth: false,
          })
        } else if (day <= totalDays) {
          const currentDate = new Date(currentYear, currentMonth, day)
          week.push({
            date: currentDate,
            isPreviousMonth: false,
            isNextMonth: false,
          })
          day++
        } else {
          const nextMonthStartDate = day - totalDays
          const nextMonthDate = new Date(currentYear, currentMonth + 1, nextMonthStartDate)
          week.push({
            date: nextMonthDate,
            isPreviousMonth: false,
            isNextMonth: true,
          })
          day++
        }
      }
      dates.push(week)
    }

    return dates.map((week, index) => (
      <View key={index} style={styles.weekContainer}>
        {week.map(({ date, isPreviousMonth, isNextMonth }) => {
          const isSelectedStart = selectedStartDate && date && selectedStartDate.getTime() === date.getTime()
          const isSelectedEnd = selectedEndDate && date && selectedEndDate.getTime() === date.getTime()
          const isInRange =
            selectedStartDate && selectedEndDate && date && date >= selectedStartDate && date <= selectedEndDate
          const isOutOfRange = date && isDateDisabled(date)

          const dateStyle: StyleProp<TextStyle> = [styles.dateText]
          const buttonStyle: StyleProp<ViewStyle> = [styles.dateButton]

          const weekendDateText: StyleProp<TextStyle> = [
            isDateDisabled(date) ? styles.weekendDateOutText : styles.weekendDateText,
          ]

          if (isPreviousMonth || isNextMonth) {
            dateStyle.push(styles.outOfMonthDateText)
            buttonStyle.push(styles.outOfMonthDateButton)
          }
          if (isSelectedStart || isSelectedEnd) {
            dateStyle.push(styles.selectedDateText)
            buttonStyle.push(styles.selectedDateButton)
            if (isSelectedStart && isInRange) {
              buttonStyle.push(styles.selectedStartDateButton)
            } else if (isSelectedEnd && isInRange) {
              buttonStyle.push(styles.selectedEndDateButton)
            }
          } else if (isInRange) {
            dateStyle.push(styles.inRangeDateText)
            buttonStyle.push(styles.inRangeDateButton)
          } else if (isOutOfRange) {
            dateStyle.push(styles.disabledDateText)
            buttonStyle.push(styles.disabledDateButton)
          }

          // Check if the date is Saturday or Sunday
          const isWeekend = date && !isPreviousMonth && !isNextMonth && date.getDay() === 0

          const rightContainerStyles = [
            {
              flex: 1,
            },
            (isSelectedStart && isSelectedEnd) || isInRange ? { backgroundColor: getRealColor('primary.blue05') } : {},
            isSelectedStart ? { backgroundColor: getRealColor('mono.white') } : {},
          ]
          const leftContainerStyles = [
            {
              flex: 1,
            },
            (isSelectedStart && isSelectedEnd) || isInRange ? { backgroundColor: getRealColor('primary.blue05') } : {},
            isSelectedEnd ? { backgroundColor: getRealColor('mono.white') } : {},
          ]

          return (
            <View style={styles.dateContainerButton} key={date.getTime()}>
              <View style={rightContainerStyles} />
              <View style={leftContainerStyles} />
              <Pressable style={buttonStyle} onPress={() => handleDatePress(date)} disabled={isOutOfRange}>
                <Text style={[dateStyle, isWeekend ? weekendDateText : null]}>{date ? date.getDate() : ''}</Text>
              </Pressable>
            </View>
          )
        })}
      </View>
    ))
  }

  const renderMonth = () => {
    const months = [
      [
        t('daterangepicker.monthNames.january'),
        t('daterangepicker.monthNames.february'),
        t('daterangepicker.monthNames.march'),
      ],
      [
        t('daterangepicker.monthNames.april'),
        t('daterangepicker.monthNames.may'),
        t('daterangepicker.monthNames.june'),
      ],
      [
        t('daterangepicker.monthNames.july'),
        t('daterangepicker.monthNames.august'),
        t('daterangepicker.monthNames.september'),
      ],
      [
        t('daterangepicker.monthNames.october'),
        t('daterangepicker.monthNames.november'),
        t('daterangepicker.monthNames.december'),
      ],
    ]
    return months.map((row, rowIndex) => (
      <View style={styles.monthRowContainer} key={rowIndex}>
        {row.map((month, colIndex) => {
          const index = rowIndex * 3 + colIndex
          const monthButton: StyleProp<ViewStyle> = [
            styles.monthButton,
            {
              width: Number(t('daterangepicker.monthbuttonwidth')),
            },
            index === currentHoverMonth ? styles.monthHoverButton : {},
            index === currentMonth ? styles.monthActiveButton : {},
          ]
          const monthText: StyleProp<TextStyle> = [
            styles.monthText,
            index === currentMonth ? styles.monthActiveText : {},
          ]
          return (
            <View style={styles.monthColumnContainer} key={index}>
              <Pressable
                style={monthButton}
                onHoverIn={() => setCurrentHoverMonth(index)}
                onHoverOut={() => setCurrentHoverMonth(-1)}
                onPress={() => {
                  setCurrentMonth(index)
                  setIsMonth(false)
                }}
              >
                <Text style={monthText} fontName="txtSmMedium">
                  {month}
                </Text>
              </Pressable>
            </View>
          )
        })}
      </View>
    ))
  }

  const renderCalendarDateDisplay = () => {
    return (
      <View style={styles.calendarDisplayContainer}>
        <View style={styles.calendarDisplayViewContainer}>
          <Text>{getDatePretty(t, selectedStartDate)}</Text>
        </View>
        {isTime ? (
          <>
            <View style={styles.dateDir} />
            <Pressable
              ref={timePickerButtonRef}
              style={styles.calendarDisplayViewContainer}
              onPress={onTimePickerPositionChange}
            >
              <Text>
                {time.hour}:{time.minute}
              </Text>
            </Pressable>
          </>
        ) : (
          <></>
        )}
      </View>
    )
  }

  const renderCalendarRangeDisplay = () => {
    return (
      <View style={styles.calendarDisplayContainer}>
        <View style={styles.calendarDisplayViewContainer}>
          <Text>{`${getDatePretty(t, selectedStartDate)} - ${getDatePretty(t, selectedEndDate)}`}</Text>
        </View>
      </View>
    )
  }

  const renderCalendarDisplay = () => {
    return (
      <View style={styles.calendarRootDisplayContainer}>
        {type === 'date' ? renderCalendarDateDisplay() : <></>}
        {type === 'range' ? renderCalendarRangeDisplay() : <></>}
      </View>
    )
  }

  const renderCalendarTimeHeader = () => {
    return (
      <View>
        <SwitchLabel
          label={t('daterangepicker.timepicker.timesetting')}
          activated={isTime}
          setActivated={handleIsTime}
          justifyContent="space-between"
        />
      </View>
    )
  }

  const hours = Array.from(Array(24).keys()).map((hour) => `${pad(hour)}`)
  const minutes = Array.from(Array(60).keys()).map((minute) => `${pad(minute)}`)

  const onTimePickerPositionChange = React.useCallback(() => {
    if (timePickerButtonRef.current) {
      timePickerButtonRef.current.measure((_x, _y, width, _height, pageX, pageY) => {
        setTimePickerPosition({
          top: pageY - 10,
          left: pageX,
          width: width,
        })
        const hourIndex = hours.findIndex((hour) => hour === time.hour)
        const minuteIndex = minutes.findIndex((minute) => minute === time.minute)

        setTimeout(() => {
          if (hourFlatListRef.current) {
            if (hourIndex > -1 && hourIndex - 2 > -1) {
              hourFlatListRef.current.scrollToIndex({
                index: hourIndex - 2,
                animated: false,
              })
            }
          }
        }, 500)
        setTimeout(() => {
          if (minuteFlatListRef.current) {
            if (minuteIndex > -1 && minuteIndex - 2 > -1) {
              minuteFlatListRef.current.scrollToIndex({
                index: minuteIndex - 2,
                animated: false,
              })
            }
          }
        }, 500)
      })
    }
  }, [timePickerButtonRef, hourFlatListRef, minuteFlatListRef, hours, minutes, time])

  const onResize = React.useCallback(() => {
    if (!timePickerPosition) return

    const { width } = document.body.getBoundingClientRect()
    if (width < 800) {
      const fragmentsElem = document.getElementById('rightFragmentsLayout')
      if (fragmentsElem) {
        setTimePickerPosition(undefined)
      } else {
        onTimePickerPositionChange()
      }
    } else {
      onTimePickerPositionChange()
    }
  }, [timePickerPosition, onTimePickerPositionChange])

  React.useEffect(() => {
    if (Platform.OS === 'web') {
      window.addEventListener('resize', onResize)
    }
    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('resize', onResize)
      }
    }
  }, [onResize])

  const TimePickerItem = ({
    type,
    column,
    onChange,
  }: {
    type: string
    column: string
    onChange: (column: string) => void
  }) => {
    const buttonStyle: StyleProp<ViewStyle> = [styles.timepickerItem]
    const textStyle: StyleProp<TextStyle> = [styles.timepickerItemText]

    if (timePickerHover === `${type}-${column}`) {
      buttonStyle.push(styles.timepickerItemHover)
      textStyle.push(styles.timepickerItemHoverText)
    }

    if (type === 'hour') {
      if (time.hour === column) {
        buttonStyle.push(styles.timepickerItemActive)
        textStyle.push(styles.timepickerItemActiveText)
      }
    }
    if (type === 'minute') {
      if (time.minute === column) {
        buttonStyle.push(styles.timepickerItemActive)
        textStyle.push(styles.timepickerItemActiveText)
      }
    }
    return (
      <Pressable
        style={buttonStyle}
        onPress={() => onChange(column)}
        onHoverIn={() => setTimePickerHover(`${type}-${column}`)}
        onHoverOut={() => setTimePickerHover(undefined)}
      >
        <Text fontName="txtSmMedium" style={textStyle}>
          {column}
        </Text>
      </Pressable>
    )
  }

  const renderCalendarTimePicker = ({ top, left, width }: TimePosition) => {
    return (
      <Modal transparent>
        <Pressable style={styles.timepickerBackdrop} onPress={() => setTimePickerPosition(undefined)} />
        <View style={[styles.timepickerContainer, { top, left, width }]}>
          <View style={styles.timepickerBodyContainer}>
            <View style={styles.timepickerColumnContainer}>
              <FlatList
                ref={hourFlatListRef}
                onLayout={() => {
                  const hourIndex = hours.findIndex((hour) => hour === time.hour)
                  if (hourFlatListRef.current) {
                    if (hourIndex > -1 && hourIndex - 2 > -1) {
                      hourFlatListRef.current.scrollToOffset({
                        offset: (hourIndex - 2) * 32,
                        animated: false,
                      })
                    }
                  }
                }}
                onScrollToIndexFailed={debounce(() => {
                  const hourIndex = hours.findIndex((hour) => hour === time.hour)
                  if (hourFlatListRef.current) {
                    if (hourIndex > -1 && hourIndex - 2 > -1) {
                      hourFlatListRef.current.scrollToIndex({
                        index: hourIndex - 2,
                        animated: false,
                      })
                    }
                  }
                }, 100)}
                data={hours}
                keyExtractor={(item) => `hours-${item}`}
                renderItem={({ item }: { item: string }) => (
                  <TimePickerItem
                    type="hour"
                    column={item}
                    onChange={(hour) => {
                      setTime((prev) => ({ ...prev, hour }))
                      onChangeTime && onChangeTime(`${hour}:${time.minute}`)
                    }}
                  />
                )}
              />
            </View>
            <View style={styles.timepickerColumnContainer}>
              <FlatList
                ref={minuteFlatListRef}
                onLayout={() => {
                  const minuteIndex = minutes.findIndex((minute) => minute === time.minute)
                  if (minuteFlatListRef.current) {
                    if (minuteIndex > -1 && minuteIndex - 2 > -1) {
                      minuteFlatListRef.current.scrollToOffset({
                        offset: (minuteIndex - 2) * 32,
                        animated: false,
                      })
                    }
                  }
                }}
                onScrollToIndexFailed={debounce(() => {
                  const minuteIndex = minutes.findIndex((minute) => minute === time.minute)
                  if (minuteFlatListRef.current) {
                    if (minuteIndex > -1 && minuteIndex - 2 > -1) {
                      minuteFlatListRef.current.scrollToIndex({
                        index: minuteIndex - 2,
                        animated: false,
                      })
                    }
                  }
                }, 100)}
                data={minutes}
                keyExtractor={(item) => `minutes-${item}`}
                renderItem={({ item }) => (
                  <TimePickerItem
                    type="minute"
                    column={item}
                    onChange={(minute) => {
                      setTime((prev) => ({ ...prev, minute }))
                      onChangeTime && onChangeTime(`${time.hour}:${minute}`)
                    }}
                  />
                )}
              />
            </View>
          </View>
          <View style={styles.timepickerFooterContainer}>
            <Text fontName="txtSmMedium" fontColor="gray.g800">
              {time.hour}:{time.minute}
            </Text>
          </View>
        </View>
      </Modal>
    )
  }

  return (
    <>
      <View style={styles.container}>
        {renderHeader()}
        {!isMonth ? renderDaysOfWeek() : <></>}
        {!isMonth ? renderCalendar() : <></>}
        {isMonth ? renderMonth() : <></>}
        {!isMonth && isDisplay ? renderCalendarDisplay() : <></>}
        {!isMonth && type === 'date' ? renderCalendarTimeHeader() : <></>}
      </View>
      {timePickerPosition ? renderCalendarTimePicker(timePickerPosition) : <></>}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  headerButton: {
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
      native: {},
    }),
  },
  daysOfWeekContainer: {
    flexDirection: 'row',
    marginBottom: 8,
  },
  dayOfWeek: {
    flex: 1,
    alignItems: 'center',
  },
  weekContainer: {
    flexDirection: 'row',
    marginBottom: 8,
  },
  dateContainerButton: {
    flex: 1,
    flexDirection: 'row',
    height: CALENDAR_DATE_HEIGHT,
    justifyContent: 'center',
  },
  dateButton: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
    // paddingVertical: 8,
    // backgroundColor: '#fff',
    backgroundColor: getRealColor('transparent'),
    height: CALENDAR_DATE_HEIGHT,
    width: CALENDAR_DATE_WIDTH,
  },
  outOfMonthDateButton: {
    // backgroundColor: '#f2f2f2',
  },
  dateText: {
    // fontSize: 14,
    // fontWeight: 'bold',
    // color: '#000',
    ...getFontStyle('txtSmMedium'),
    color: getRealColor('gray.g800'),
  },
  outOfMonthDateText: {
    // color: '#999',
    color: getRealColor('gray.g300'),
  },
  selectedDateButton: {
    // backgroundColor: '#007AFF',
    backgroundColor: getRealColor('primary.blue600'),
  },
  selectedStartDateButton: {
    borderTopLeftRadius: 100,
    borderBottomLeftRadius: 100,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  selectedEndDateButton: {
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  inRangeDateButton: {
    // backgroundColor: '#007AFF',
    // backgroundColor: getRealColor('primary.blue600'),
    // opacity: 0.5,
  },
  disabledDateButton: {
    backgroundColor: '#fff',
  },
  selectedDateText: {
    // color: '#fff',
    color: getRealColor('mono.white'),
  },
  inRangeDateText: {
    // color: '#fff',
    // backgroundColor: '#007AFF',
    // opacity: 0.5,
  },
  disabledDateText: {
    color: '#ccc',
  },
  // weekendButton: {
  //   backgroundColor: '#f2f2f2',
  // },
  weekendDateText: {
    color: '#e73438',
    // fontWeight: 'bold',
  },
  weekendDateOutText: {
    color: '#fad6d7',
    // fontWeight: 'bold',
  },
  monthRowContainer: {
    flexDirection: 'row',
    marginBottom: 4,
  },
  monthColumnContainer: {
    flex: 1,
    alignItems: 'center',
  },
  monthButton: {
    height: CALENDAR_MONTH_HEIGHT,
    // width: CALENDAR_MONTH_WIDTH,
    alignItems: 'center',
    // paddingVertical: 8,
    justifyContent: 'center',
    borderRadius: 4,
  },
  monthHoverButton: {
    backgroundColor: getRealColor('gray.g100'),
  },
  monthActiveButton: {
    backgroundColor: getRealColor('primary.blue600'),
  },
  monthText: {
    color: getRealColor('gray.g800'),
  },
  monthActiveText: {
    color: getRealColor('mono.white'),
  },
  calendarRootDisplayContainer: {
    height: 36,
  },
  calendarDisplayContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  calendarDisplayViewContainer: {
    flex: 1,
    borderWidth: 1,
    borderColor: getRealColor('gray.g150'),
    backgroundColor: '#f0f0f0',
    height: 36,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dateDir: {
    width: 8,
  },
  timepickerBackdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  timepickerContainer: {
    position: 'absolute',
    height: 200,
    backgroundColor: getRealColor('mono.white'),
    borderRadius: 4,
    borderWidth: 1,
    borderColor: getRealColor('gray.g200'),
    flex: 1,
  },
  timepickerBodyContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  timepickerColumnContainer: {
    flex: 1,
  },
  timepickerItem: {
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  timepickerItemActive: {
    backgroundColor: getRealColor('primary.blue600'),
  },
  timepickerItemHover: {
    backgroundColor: getRealColor('gray.g100'),
  },
  timepickerItemText: {
    color: getRealColor('gray.g500'),
  },
  timepickerItemActiveText: {
    color: getRealColor('mono.white'),
  },
  timepickerItemHoverText: {
    color: getRealColor('gray.g800'),
  },
  timepickerFooterContainer: {
    height: 40,
    borderTopWidth: 1,
    borderTopColor: getRealColor('gray.g200'),
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default DateRangePickerV2
