import { FONT, getRealColor } from '@rocket-mono/libs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, View } from 'react-native'

interface Props {
  discussionCount: number
  historyCount: number
  initState: string
  historyEnabled: boolean
  onState: (state: string) => void
}

const DiscussionTab = ({ discussionCount, historyCount, initState, historyEnabled, onState }: Props) => {
  const [t] = useTranslation()
  const [state, setState] = React.useState(initState)
  return (
    <View style={styles.container}>
      <Pressable
        onPress={() => {
          setState('discussion')
          onState('discussion')
        }}
        style={[
          styles.button,
          state === 'discussion'
            ? {
                borderTopColor: getRealColor('mono.black'),
                borderRightColor: getRealColor('mono.black'),
                borderLeftColor: getRealColor('mono.white'),
                borderBottomColor: getRealColor('mono.white'),
                backgroundColor: getRealColor('mono.white'),
              }
            : {
                borderBottomColor: getRealColor('mono.black'),
                borderRightColor: getRealColor('mono.black'),
                borderTopColor: getRealColor('mono.paleWhite'),
                borderLeftColor: getRealColor('mono.paleWhite'),
                backgroundColor: getRealColor('mono.paleWhite'),
              },
        ]}
      >
        <Text style={[styles.text, state === 'discussion' ? { ...FONT.listBold } : { ...FONT.listRegular }]}>
          {t('discussion.disc')}
        </Text>
        <Text style={styles.count}>{discussionCount}</Text>
      </Pressable>
      {historyEnabled ? (
        <Pressable
          onPress={() => {
            setState('history')
            onState('history')
          }}
          style={[
            styles.button,
            state === 'history'
              ? {
                  borderTopColor: getRealColor('mono.black'),
                  borderRightColor: getRealColor('mono.white'),
                  borderBottomColor: getRealColor('mono.white'),
                  borderLeftWidth: 0,
                  backgroundColor: getRealColor('mono.white'),
                }
              : {
                  borderTopColor: getRealColor('mono.paleWhite'),
                  borderRightColor: getRealColor('mono.paleWhite'),
                  borderBottomColor: getRealColor('mono.black'),
                  borderLeftWidth: 0,
                  backgroundColor: getRealColor('mono.paleWhite'),
                },
          ]}
        >
          <Text style={[styles.text, state === 'history' ? { ...FONT.listBold } : { ...FONT.listRegular }]}>
            {t('discussion.hist')}
          </Text>
          <Text style={styles.count}>{historyCount}</Text>
        </Pressable>
      ) : (
        <View
          style={[
            styles.button,
            {
              borderTopColor: getRealColor('mono.paleWhite'),
              borderRightColor: getRealColor('mono.paleWhite'),
              borderBottomColor: getRealColor('mono.black'),
              borderLeftWidth: 0,
              backgroundColor: getRealColor('mono.paleWhite'),
            },
          ]}
        ></View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  button: {
    height: 50,
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
  },
  text: {
    color: getRealColor('mono.black'),
    marginRight: 12,
  },
  count: {
    ...FONT.subTextRegular,
    color: getRealColor('main.red'),
  },
})

export default DiscussionTab
