import { Icon } from '@rocket-atoms/icon'
import { SwitchLabel } from '@rocket-atoms/toggle'
import { COLOR, FONT } from '@rocket-mono/libs'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import Button from '../Button'
import { CardRowGroup } from '../CardRowGroup'
// import Dropdown from '../Dropdown'
import type { HandlerProps } from '../WorkCardAuthorityModal'
import { Dropdown } from '@rui/atoms'
// const tootipContents = () => {
//   return <Text>내용</Text>
// }

const WorkCardAuthorityView: React.FC<HandlerProps> = ({
  isAuth = true,
  cardType,
  authorityData,
  shareKey,
  shareData,
  onChangeAuthorityData,
  onChangeShareData,
  onPressCopy,
  onPressDelete,
}) => {
  const { t } = useTranslation()

  const list = React.useMemo(
    () =>
      shareData.map(({ key: id, label }) => ({
        id,
        label,
        actived: id === shareKey,
      })),
    [shareData, shareKey],
  )
  return (
    <View>
      <View>
        {cardType !== 'TODO' && (
          <View style={styles.formGroup}>
            <CardRowGroup
              title={t('cardauthority.authority.title')}
              containerStyle={styles.cardRowTitle}
              titleStyle={{ ...FONT.txtMd }}
            />
            <SwitchLabel
              label={t('cardauthority.authority.all')}
              // labelStyle={{...FONT.txtMd}}
              activated={authorityData.isPublic}
              setActivated={() => {
                onChangeAuthorityData &&
                  onChangeAuthorityData({
                    ...authorityData,
                    isPublic: !authorityData.isPublic,
                  })
              }}
              justifyContent="space-between"
            />
            {/* <SwitchLabel
            label={'참여자에게만 카드 노출'}
            activated={false}
            setActivated={() => console.log('챡')}
            justifyContent="space-between"
          /> */}
          </View>
        )}
        {isAuth && (
          <View style={styles.formGroup}>
            <CardRowGroup
              title={t('cardauthority.linkshare.title')}
              containerStyle={styles.cardRowTitle}
              titleStyle={{ ...FONT.txtMd }}
              // infoTooltipTitle={'링크 공유 설정'}
              // infoTooltipRender={tootipContents}
            />
            <View style={styles.formDropdownButton}>
              {/* <Dropdown
              list={shareData}
              label={shareData.find(({ key }) => key === shareKey)?.label ?? 'Select'}
              currentIndex={shareData.findIndex(({ key }) => key === shareKey)}
              onSelect={onChangeShareData}
              listFullWide={true}
              divide
            /> */}
              <Dropdown
                size="md"
                width={360}
                label={shareData.find(({ key }) => key === shareKey)?.label ?? 'Select'}
                list={list}
                filled
                type="default"
                variant="Common"
                configuration="default"
                onPressItem={(id) => {
                  const data = shareData.find(({ key }) => key === id)
                  if (data && onChangeShareData) onChangeShareData(data)
                }}
              />
            </View>
            <Button
              wide
              size={'large'}
              backgroundColor={'transparent.base'}
              style={{ flexDirection: 'row', justifyContent: 'flex-start' }}
              onPress={onPressCopy}
            >
              <Icon name="external-link" size={16} color={'gray.g700'} style={{ marginTop: -2, marginRight: 4 }} />
              <Text style={{ ...FONT.txtSm }}>{t('cardauthority.copy')}</Text>
            </Button>
          </View>
        )}
      </View>
      <View style={styles.formSection}>
        <Button
          wide
          size={'large'}
          backgroundColor={'transparent.base'}
          style={{ flexDirection: 'row', justifyContent: 'flex-start' }}
          onPress={onPressDelete}
        >
          <Icon name="trash-o" size={16} color={'main.red'} style={{ marginTop: -2, marginRight: 4 }} />
          <Text style={{ ...FONT.txtSm, color: COLOR.main.red }}>{t('cardauthority.delete')}</Text>
        </Button>
      </View>
    </View>
  )
}

export default WorkCardAuthorityView

const styles = StyleSheet.create({
  cardRowTitle: {
    marginTop: 0,
  },
  formSection: {
    paddingVertical: 4,
    paddingHorizontal: 16,
  },
  formGroup: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g150,
  },
  formDropdownButton: {
    paddingTop: 4,
    paddingBottom: 8,
  },
})
