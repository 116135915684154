import { Text } from '@rocket-atoms/text'
import * as React from 'react'
import { Modal, Pressable, StyleSheet, View } from 'react-native'

import { Icon } from '@rocket-atoms/icon'
import { ButtonIcon } from '@rocket-mono/foundations'
import { COLOR } from '@rocket-mono/libs'
import { useTranslation } from 'react-i18next'

import moment from 'moment'
import Picker from '../DatePicker'

interface Props {
  height?: number
  date?: Date
  minDate?: Date
  maxDate?: Date
  placeholder: string
  onChangeDate?: (date?: Date) => void
  onResetDate?: () => void
}

export const DatePicker: React.FC<Props> = ({
  height,
  date,
  minDate,
  maxDate,
  placeholder = '',
  onChangeDate,
  onResetDate,
}) => {
  const { t } = useTranslation()
  const [showCalendar, setShowCalendar] = React.useState(false)

  const min = React.useMemo(() => {
    return minDate ? moment(minDate).format('YYYY-MM-DD') : undefined
  }, [minDate])

  const max = React.useMemo(() => {
    return maxDate ? moment(maxDate).format('YYYY-MM-DD') : undefined
  }, [maxDate])

  const onPress = React.useCallback(() => {
    setShowCalendar(true)
  }, [])

  const onChangeDateHandler = React.useCallback(
    (date: Date | null) => {
      console.log('onChangeDateHandler', date)
      if (date) {
        onChangeDate && onChangeDate(date)
      }
    },
    [onChangeDate],
  )

  return (
    <>
      <View style={styles.container}>
        <ButtonIcon
          icon={<Icon name="calendar" size={height === 37 ? 16 : 20} color="gray.g700" />}
          onPress={onPress}
        />
        <Pressable
          style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onPress={onPress}
        >
          <Text fontName={height === 37 ? 'txtSm' : 'txtMd'} fontColor={date ? 'gray.g900' : 'gray.g400'}>
            {date ? t('format.date.L', { date }) : placeholder}
          </Text>
          {!!date && onResetDate && (
            <ButtonIcon
              style={{ marginLeft: 8, alignSelf: 'center' }}
              icon={<Icon name="close" size={10} color="mono.white" onPress={onResetDate} />}
              containerSize={16}
              rounded
              backgroundColor="mono.darkGray"
              onPress={onResetDate}
            />
          )}
        </Pressable>
      </View>
      {showCalendar ? (
        <Modal transparent>
          <View style={styles.modalContainer}>
            <Pressable style={styles.backdropContainer} onPress={() => setShowCalendar(false)} />
            <Picker
              type="date"
              // visible={showCalendar}
              // onDateChange={onChangeDateHandler}
              min={min}
              max={max}
              setExportPicker={onChangeDateHandler}
            />
          </View>
        </Modal>
      ) : (
        <></>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: COLOR.mono.white,
  },
  backdropContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
