import React from 'react'
import { StyleSheet, View } from 'react-native'

import { faComments } from '@fortawesome/free-regular-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { COLOR, FONT, getMessageParse } from '@rui/foundations'
import { SkeletonBar, SkeletonCircle } from '@rocket/skeleton'

import { Text } from '@rocket-atoms/text'
import { Avatar } from '@rui/atoms'
import WorkLabel from '../../WorkLabel'
import MessageTypeIcon from './MessageTypeIcon'
import Container from '../container'
import { ItemBoardType, ItemMessageType, ItemUserType, ItemWorkType } from '../types'

export interface ActivitySearchItemMessageProps {
  work?: ItemWorkType
  board?: ItemBoardType
  message?: ItemMessageType
  user?: ItemUserType
  onPress?: () => void
}

const ActivitySearchItemMessage: React.FC<ActivitySearchItemMessageProps> = ({
  work,
  board,
  message,
  user,
  onPress,
}) => {
  const parseMessage = React.useMemo(() => {
    if (!message) return ''
    const jsxElements: JSX.Element[] = getMessageParse(message.message)
    return jsxElements.length > 0 ? jsxElements : message.message
  }, [message, getMessageParse])

  if (message === undefined) {
    return (
      <View style={[styles.wrap]}>
        <View style={styles.profileArea}>
          <SkeletonCircle width={36} height={36} />
        </View>
        <View style={[styles.bodyArea, { marginTop: 4 }]}>
          <View style={[styles.titleArea, styles.flexBox]}>
            <View style={[styles.flexBox, styles.boardInfo]}>
              <SkeletonBar width={60} height={18} />
            </View>
            <View>
              <SkeletonBar width={32} height={16} />
            </View>
          </View>
          <View style={[styles.bodyBottom, { marginTop: 4 }]}>
            <SkeletonBar width={'80%'} height={8} />
          </View>
        </View>
      </View>
    )
  }

  return (
    <Container onPress={onPress} style={styles.wrap}>
      {user && (
        <View style={styles.profileArea}>
          <Avatar size="medium" profileUrl={user.profile} userName={user.name} />
        </View>
      )}
      <View style={styles.bodyArea}>
        <View style={[styles.titleArea, styles.flexBox]}>
          <View style={[styles.flexBox, styles.boardInfo]}>
            {board && (
              <>
                {board.type !== 'BOARD' && (
                  <View style={styles.boardTypeIcon}>
                    {board.type === 'DIRECT' && <MessageTypeIcon icon={faUser} />}
                    {board.type === 'DISCUSS' && <MessageTypeIcon icon={faComments} />}
                  </View>
                )}
                <View style={styles.flexBox}>
                  {!!board.name && <Text style={styles.boardText}>{board.name}</Text>}
                  {board.count && board.count > 0 && <Text style={styles.countText}>{board.count}</Text>}
                </View>
              </>
            )}
          </View>
          <Text style={styles.timeText}>{message.date}</Text>
        </View>
        {!!parseMessage && <Text style={styles.messageText}>{parseMessage}</Text>}
        {work && (
          <View style={styles.bodyBottom}>
            <WorkLabel type={work.type} label={work.name} />
          </View>
        )}
      </View>
    </Container>
  )
}

export default ActivitySearchItemMessage

const styles = StyleSheet.create({
  flexBox: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrap: {
    flexDirection: 'row',
    paddingVertical: 12,
    paddingHorizontal: 20,
  },
  bodyArea: {
    flex: 1,
  },
  profileArea: {
    marginRight: 8,
  },
  titleArea: {
    width: '100%',
  },
  boardTypeIcon: {
    marginRight: 4,
  },
  boardInfo: {
    flex: 1,
  },
  boardText: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g900,
    marginRight: 4,
  },
  countText: {
    ...FONT.txtXsMedium,
    color: COLOR.gray.g450,
  },
  messageText: {
    ...FONT.txtXs,
    color: COLOR.gray.g500,
  },
  timeText: {
    ...FONT.txtXs,
    color: COLOR.gray.g450,
  },
  bodyBottom: {
    alignItems: 'flex-start',
    marginTop: 4,
  },
})
