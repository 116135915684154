import * as React from 'react'

import { ContextProps, ThemeType } from './types'

export const ThemeContext = React.createContext<ContextProps | undefined>(undefined)
interface Props {
  children?: JSX.Element | JSX.Element[]
}

export const ThemeProvider = ({ children }: Props) => {
  const [theme, setTheme] = React.useState<ThemeType>('LIGHT')
  const changeTheme = React.useCallback((theme: ThemeType) => setTheme(theme), [])
  return <ThemeContext.Provider value={{ theme, changeTheme }}>{children}</ThemeContext.Provider>
}
export * from './hooks'
