import * as React from 'react'
import { getRealColor } from '@rocket-mono/libs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Svg, { Path } from 'react-native-svg'

import { Props } from './types'

const FAIcon: React.FC<Props> = ({ iconName, size, ifSearchSize = 16, color, style }) => {
  return (
    iconName === faSearch ?
    <Svg
      width={ifSearchSize}
      height={ifSearchSize}
      viewBox="0 0 17 17"
      fill="none"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5072 10.6626L12.3214 10.9378L12.5577 11.171L16.2131 14.7788C16.5748 15.1704 16.5761 15.8235 16.217 16.217C15.8237 16.5759 15.1711 16.5748 14.7794 16.2136L11.2066 12.5591L10.9761 12.3232L10.7006 12.5046C9.36983 13.381 7.75339 13.8326 6.00782 13.6069C3.03552 13.1858 0.641939 10.7583 0.254005 7.82123C-0.29494 3.42285 3.42285 -0.29494 7.82123 0.254005C10.7583 0.641939 13.1858 3.03552 13.6069 6.00782C13.8327 7.75427 13.3804 9.36895 12.5072 10.6626ZM6.91314 2.1289C4.25831 2.1289 2.1289 4.29663 2.1289 6.91314C2.1289 9.56593 4.26034 11.6974 6.91314 11.6974C9.52964 11.6974 11.6974 9.56796 11.6974 6.91314C11.6974 4.2946 9.53168 2.1289 6.91314 2.1289Z"
        fill={getRealColor(color ?? 'mono.black')}
      />
    </Svg>
    :
    <FontAwesomeIcon style={style} icon={iconName} size={size} color={getRealColor(color ?? 'mono.black')} />
  )
}

export default FAIcon
