import { Icon } from '@rocket-atoms/icon'
import { Text } from '@rocket-atoms/text'
import React from 'react'
import { TouchableOpacity, StyleSheet, View } from 'react-native'

interface Props {
  text: string
  underline?: boolean
  bordered?: boolean
  opaqueIcon?: boolean
  checked?: boolean
  onText?: () => void
  onPress?: () => void
}

export const AcknowledgeTermButton: React.FC<Props> = ({ text, underline, bordered, checked, onText, onPress }) => {
  return (
    <View
      style={[
        style.container,
        {
          borderColor: bordered ? '#eee' : 'transparent',
        },
      ]}
    >
      <TouchableOpacity style={{ width: 220 }}>
        <Text
          fontName="subTextRegular"
          fontColor="mono.paleBlack"
          style={{
            textDecorationLine: underline === true ? 'underline' : 'none',
          }}
          onPress={onText}
        >
          {text}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={{
          width: 23,
          height: 23,
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onPress={onPress}
      >
        <Icon size={13} color={checked === true ? 'main.blue' : 'mono.lightGray'} name="check" />
      </TouchableOpacity>
    </View>
  )
}

const style = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    minHeight: 34,
    flexDirection: 'row',
    // paddingVertical: 7,
    paddingHorizontal: 12,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderWidth: 1,
    borderRadius: 5,
  },
})
