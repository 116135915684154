import React from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import FAIconNative from '../FAIcon/FAIconNative'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IColors } from '@rocket-mono/libs'

interface Props{
  iconName: IconProp
  iconSize: number
  iconColor: IColors
  onPress?: () => void
}
const IconButtonNative: React.FC<Props> = ({
  iconName, 
  iconSize, 
  iconColor, 
  onPress,
}) => {
  const [isHover, setIsHover] = React.useState(false)

  return(
    <Pressable 
      onHoverIn={()=>setIsHover(true)}
      onHoverOut={()=>setIsHover(false)}
      onPress={onPress}
      style={[
        styles.wrap,
      ]}
    >
      <View style={isHover && {opacity:.75}}>
        <FAIconNative iconName={iconName} size={iconSize} color={iconColor} />
      </View>
    </Pressable>
  )
}

export default IconButtonNative

const styles = StyleSheet.create({
  wrap: {
    width:32,
    height:32,
    justifyContent:'center',
    alignItems:'center'
  }
})